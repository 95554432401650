import { handleActions } from 'redux-actions'
import * as profileSearchesActions from './profile-searches-actions'

const getInitialState = () => ({
  isLoading: false,
  category: null,
  isProspectCategory: false, // 'client' and 'prospect' are both handled on client page
  categoryLabel: null,
  categoryLabelPlural: null,
  isFirmLibraryGroup: false,
  editSearchId: null,
  searchIds: [],
  fullyFetchedSearchIds: [],
  searchResults: {},
  saveData: {
    searchId: null,
    name: undefined,
    scope: undefined,
    category: undefined,
    coreSearchValues: null, // starts as null to identify that no changes have been made; set to an array when changes made
    coreSearchRawValue: null,
    filters: null, // starts as null to identify that no changes have been made; set to an array when changes made

    noticeConfig: {
      frequency: '',
      maxItems: {},
    },
  },

  // this stores the state of the SavedSearchFilters component so that it is maintained when tabbing back and forth.
  queryComponentState: {},

  firmLibrarySearchIdsByCategory: {},
  globalSearchIdsByCategory: {},
  featuredSearchIdsByCategory: {},
  browseModalData: {
    type: null, // BROWSE_TYPE_GLOBAL, etc..
    selectedIds: [],

    // removed ids are stored so we can update the main nav after updates are applied
    removedIds: [],
  }
})


export default handleActions(
  {
    [profileSearchesActions.init]: (state, action) => ({
      ...state,
      category: action.payload.category,
      categoryLabel: action.payload.categoryLabel,
      categoryLabelPlural: action.payload.categoryLabelPlural,
      isFirmLibraryGroup: action.payload.isFirmLibraryGroup,

      // copy user search ids from global state to local so that they can be easily updated after changes
      searchIds: state.profileSearches,
    }),
    [profileSearchesActions.setIsProspectCategory]: (state, action) => ({
      ...state,
      isProspectCategory: action.payload,
    }),
    [profileSearchesActions.hideLoader]: (state, action) => ({
      ...state,
      isLoading: false,
    }),
    [profileSearchesActions.showEditSearchModal]: (state, action) => ({
      ...state,
      editSearchId: action.payload,
    }),
    [profileSearchesActions.hideEditSearchModal]: (state, action) => ({
      ...state,
      editSearchId: null,
      saveData: getInitialState().saveData,
      queryComponentState: getInitialState().queryComponentState,
    }),
    [profileSearchesActions.fetchSearch]: (state, action) => ({
      ...state,
      editSearchId: action.payload,
      isLoading: true,
    }),
    [profileSearchesActions.fetchSearchComplete]: (state, action) => ({
      ...state,
      fullyFetchedSearchIds: [
        ...state.fullyFetchedSearchIds,
        action.payload,
      ],
    }),
    [profileSearchesActions.fetchSearchResults]: (state, action) => ({
      ...state,
      isLoading: true,
      searchResults: {},
    }),
    [profileSearchesActions.fetchSearchResultsComplete]: (state, action) => ({
      ...state,
      isLoading: false,
      searchResults: action.payload,
    }),
    [profileSearchesActions.setQueryComponentState]: (state, action) => ({
      ...state,
      queryComponentState: action.payload,
    }),
    [profileSearchesActions.setSaveData]: (state, action) => ({
      ...state,
      saveData: {
        ...state.saveData,
        ...action.payload,
      },
    }),
    [profileSearchesActions.updateSearch]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [profileSearchesActions.updateSearchComplete]: (state, action) => ({
      ...state,
      isLoading: false,
      editSearchId: null,
      saveData: getInitialState().saveData,
      queryComponentState: getInitialState().queryComponentState,
    }),
    [profileSearchesActions.createSearches]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [profileSearchesActions.deleteSearches]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [profileSearchesActions.fetchBrowseModalSearches]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [profileSearchesActions.setBrowseModalType]: (state, action) => ({
      ...state,
      browseModalData: {
        ...state.browseModalData,
        type: action.payload,
      }
    }),
    [profileSearchesActions.setBrowseModalSelectedIds]: (state, action) => ({
      ...state,
      browseModalData: {
        ...state.browseModalData,
        selectedIds: action.payload,
      }
    }),
    [profileSearchesActions.setBrowseModalRemovedIds]: (state, action) => ({
      ...state,
      browseModalData: {
        ...state.browseModalData,
        removedIds: action.payload,
      }
    }),
    [profileSearchesActions.resetBrowseModalData]: (state, action) => ({
      ...state,
      browseModalData: getInitialState().browseModalData,
    }),
    [profileSearchesActions.setFirmLibrarySearchIdsByCategory]: (state, action) => ({
      ...state,
      firmLibrarySearchIdsByCategory: {
        ...state.firmLibrarySearchIdsByCategory,
        [action.payload.category]: action.payload.ids,
      }
    }),
    [profileSearchesActions.setGlobalSearchIdsByCategory]: (state, action) => ({
      ...state,
      globalSearchIdsByCategory: {
        ...state.globalSearchIdsByCategory,
        [action.payload.category]: action.payload.ids,
      }
    }),
    [profileSearchesActions.setFeaturedSearchIdsByCategory]: (state, action) => ({
      ...state,
      featuredSearchIdsByCategory: {
        ...state.featuredSearchIdsByCategory,
        [action.payload.category]: action.payload.ids,
      }
    }),
    [profileSearchesActions.applyBrowseModalSearches]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [profileSearchesActions.removeExcludedFeedsFromSearch]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
  },
  getInitialState(),
)
