import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import * as notifications from 'app/global/notifications'
import LoadingOverlay from 'app/common/LoadingOverlay'

import UserProfile from './UserProfile'
import GroupProfile from './GroupProfile'

import { getProfileAccount } from './profile-account-selectors'
import {
  createMembership,
  deleteMembership,
  save,
  setCurrentPassword,
} from './profile-account-actions'

import './ProfileAccount.less'


@connect(
  state => {
    const profileAccount = getProfileAccount(state)

    return {
      currentUserRole: state.currentUserRole,
      isInitializing: profileAccount.isInitializing,
      isLoading: profileAccount.isLoading,
      saveData: profileAccount.saveData,
      currentPassword: profileAccount.currentPassword,
    }
  },
  {
    createMembership,
    deleteMembership,
    save,
    setCurrentPassword,
    showNotification: notifications.actions.showNotification,
  },
)
export default class ProfileAccount extends Component {
  render() {
    const {
      currentUserRole,
      isInitializing,
      isLoading,
    } = this.props

    const loader =
      isLoading || isInitializing
      ? <div className={classNames('loading-container', {initializing: isInitializing})}>
          <LoadingOverlay/>
        </div>
      : null

    const content =
      currentUserRole === 'group'
        ? <GroupProfile
            onSave={() => this.onSave()}
          />
        : <UserProfile
            onSave={() => this.onSave()}
          />

    return (
      <div className="profile-account">
        {loader}

        {content}
      </div>
    )
  }

  onSave() {
    const saveData = {...this.props.saveData, currentPassword: this.props.currentPassword}
    let ok = true
    if (saveData.password && saveData.password !== saveData.confirmPassword) {
      this.props.showNotification({
        type: 'error',
        message: 'Passwords must match.',
      })
      ok = false
    }
    const requiredFields = {
      timezone: 'Timezone',
      role: 'Role',
      locationId: 'Country',
    }
    Object.keys(requiredFields).forEach(key => {
      if (saveData.hasOwnProperty(key) && !saveData[key]) {
        this.props.showNotification({
          type: 'error',
          message: `${requiredFields[key]} is required.`,
        })
        ok = false
      }
    })
    if (!ok) {
      return
    }
    delete saveData.confirmPassword
    this.props.setCurrentPassword('')
    this.props.save({...saveData})
  }
}
