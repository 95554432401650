import { createAction } from 'redux-actions'

const PREFIX = 'PROFILE_ACCOUNT'
const action = name => createAction(`${PREFIX}_${name}`)

export const init = action('INIT')
export const initComplete = action('INIT_COMPLETE')
export const setIsLoading = action('SET_IS_LOADING')
export const fetchUser = action('FETCH_USER')
export const fetchStaticDataComplete = action('FETCH_STATIC_DATA_COMPLETE')
export const createMembership = action('CREATE_MEMBERSHIP')
export const deleteMembership = action('DELETE_MEMBERSHIP')
export const setSaveData = action('SET_SAVE_DATA')
export const save = action('SAVE')
export const toggleMembershipIsManager = action('TOGGLE_MEMBERSHIP_IS_MANAGER')
export const setPasswordConfirmModalOpen = action('SET_PASSWORD_CONFIRM_MODAL_OPEN')
export const setCurrentPassword = action('SET_CURRENT_PASSWORD')
