import React, { Component } from 'react'
import { connect } from 'react-redux'

import Orm from 'app/framework/Orm'
import { User } from 'app/models'

import Button from 'app/common/Button'
import Modal from 'app/common/Modal'
import { TabbedContainer, Tab } from 'app/common/TabbedContainer'
import DetailsContent from './DetailsContent'
import SearchesContent from './SearchesContent'
import NotificationsContent from './NotificationsContent'
import MembershipContent from './MembershipContent'

import { getUsersAdmin } from '../users-admin-selectors'
import * as usersAdminActions from '../users-admin-actions'

import './EditModal.less'


@connect(
  state => {
    const orm = Orm.withEntities(state.entities)
    const usersAdmin = getUsersAdmin(state)
    return {
      isFirmGroupSignupEnabled: state.currentFirmIsGroupSignupEnabled,

      firmLocations: usersAdmin.firmLocations,
      countries: usersAdmin.countries,
      user: orm.getById(User, usersAdmin.userModalData.userId),
      isGroupPage: usersAdmin.isGroupPage,

      ...usersAdmin,
    }
  },
  {
    ...usersAdminActions,
  },
)
export default class EditUserModal extends Component {
  render() {
    if (!this.props.userModalData.isOpen) {
      return null
    }

    const { user } = this.props

    return (
      <Modal
        isOpen={true}
        onClose={() => this.props.hideUserModal()}
        id="edit-user-modal"
        className="users-admin-style"
        outsideExit={false}
      >
        <div id={'firm-admin-edit-user'} className="header">
          <div className="user-name font-size-xl">{user.displayName}</div>

          <div>
            {
              user.isActive &&
              <a className="button" href={`/masquerade/as/?user_id=${user.id}`}>Login As</a>
            }

            {
              user.role !== 'group' && user.isActive &&
              <span>
                <Button
                  label="Send Password Reset"
                  onClick={() => this.passwordReset()}
                />

                <Button
                  label="Resend Welcome Email"
                  onClick={() => this.sendWelcomeEmail()}
                />

                <Button
                  label="Send Refresh Email"
                  onClick={() => this.sendRefreshEmail()}
                />

              </span>
            }

            {
              user.isActive ?
                <Button
                  label="Deactivate"
                  isDestructive
                  onClick={() => this.deactivateAccount()}
                />
                : <Button
                    label="Activate"
                    onClick={() => this.activateAccount()}
                  />
            }
          </div>
        </div>

        <TabbedContainer defaultTabName="details" useUpdatedCss>
          <Tab name="details" label="Details">
            <DetailsContent/>
          </Tab>

          {
            user.isActive &&
            <Tab name="searches" label="Searches and Trusted Sources">
              <SearchesContent/>
            </Tab>
          }

          {
            user.isActive && !user.isFirmLibraryGroup && !user.isPortalGroup &&
            <Tab name="notifications" label="Email Notifications">
              <NotificationsContent/>
            </Tab>
          }

          {
            user.isActive &&
            <Tab name="groups" label={user.role === 'group' ? 'Members' : 'Group Membership'}>
              <MembershipContent/>
            </Tab>
          }
        </TabbedContainer>
      </Modal>
    )
  }

  passwordReset() {
    this.props.bulkPasswordReset([this.props.userModalData.userId])
  }

  sendWelcomeEmail() {
    this.props.bulkSendWelcomeEmail([this.props.userModalData.userId])
  }

  sendRefreshEmail() {
    this.props.bulkSendRefreshEmail([this.props.userModalData.userId])
  }

  activateAccount() {
    this.props.bulkActivateAccounts({
      userIds: [this.props.userModalData.userId],
      action: 'activate',
      isGroupPage: this.props.isGroupPage,
    })
  }

  deactivateAccount() {
    this.props.bulkActivateAccounts({
      userIds: [this.props.userModalData.userId],
      action: 'deactivate',
      isGroupPage: this.props.isGroupPage,
    })
  }
}
