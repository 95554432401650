import classNames from 'classnames'
import invariant from 'invariant'
import PropTypes from 'prop-types'
import React from 'react'
import {anchor, Arrow, ToggleLayer, useHover} from 'react-laag'
import {CSSTransition} from 'react-transition-group'

import {useResizeObserver} from 'app/utils/hooks'
import * as palette from 'palette'

import styles from './Tooltip.less'


/**
 * Adds a hover tooltip to its children.
 *
 * For a basic, text-only tooltip:
 *   <Tooltip label="This is the tooltip text">
 *     <Button label="Hover over me!" />
 *   </Tooltip>
 *
 * You may also pass a `render` prop to render arbitrary tooltip contents:
 *   <Tooltip render={() => <div>This is an advanced tooltip</div>}>
 *     <Button label="Hover over me!" />
 *   </Tooltip>
 */
export default function Tooltip(
  {
    children,
    label,
    render,
    disabled,
    direction = 'top',
    className,
    style = {},
    containerClassName,
    noBorder = false,
  },
) {
  // TODO: Consolidate these into a single prop.
  invariant(
    label || render,
    "You must pass either a `label` or a `render` prop to Tooltip.",
  )

  const [isOpen, hoverProps] = useHover({delayEnter: 0, delayLeave: 100})

  const ResizeObserver = useResizeObserver()
  if (!ResizeObserver) {
    // While we're loading the polyfill, show children without the tooltip.
    return children
  }
  const usesPolyfill = ResizeObserver !== window.ResizeObserver

  const tooltipAnchor = {
    top: anchor.BOTTOM_CENTER,
    'top-left': anchor.BOTTOM_LEFT,
    'top-right': anchor.BOTTOM_RIGHT,

    bottom: anchor.TOP_CENTER,
    right: anchor.LEFT_CENTER,
    left: anchor.RIGHT_CENTER,
  }[direction]
  return (
    <ToggleLayer
      placement={{anchor: tooltipAnchor, autoAdjust: true, triggerOffset: 10}}
      fixed
      isOpen={disabled ? false : isOpen}
      renderLayer={({layerProps, isOpen, arrowStyle, layerSide}) =>
        <CSSTransition
          in={isOpen}
          classNames={{
            enter: styles.transition,
            exit: styles.transition,
          }}
          timeout={250}
          unmountOnExit
        >
          <div
            ref={layerProps.ref}
            style={{...layerProps.style, ...style, borderColor: noBorder ? '#fff' : palette['main-color'] }}
            className={classNames(styles.tooltip, className)}
          >
            {render ? render() : label}
            {!usesPolyfill && (
              // We don't want to render the arrow in IE because it's all wonky.
              <Arrow
                style={arrowStyle}
                layerSide={layerSide}
                size={10}
                borderWidth={2}
                borderColor={noBorder ? '#fff' : palette['main-color']}
              />
            )}
          </div>
        </CSSTransition>
      }
      ResizeObserver={ResizeObserver}
    >
      {({triggerRef}) => (
        <span ref={triggerRef} className={containerClassName} {...hoverProps}>
          {children}
        </span>
      )}
    </ToggleLayer>
  )
}
Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  render: PropTypes.func,
  disabled: PropTypes.bool,
  direction: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  containerClassName: PropTypes.string,
}
