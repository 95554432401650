import React from 'react'

import InlineSvg from 'app/common/InlineSvg'
import Tooltip from 'app/common/Tooltip'
import * as styles from 'app/esg/EsgScoreInfo/EsgScoreInfo.less'

function EsgScoreInfo() {
  return (
    <Tooltip
      label='ESG score calculation relies on the World Economic Forum’s International Business Council (IBC) ESG metrics outlined in their recent whitepaper on the “Principles of Governance: Planet, People, and Prosperity.”'
      direction='bottom'
      className={styles.esgTooltip}
      containerClassName={styles.esgTooltipContainer}
    >
                <span className={styles.esgInfo}>
                  How is the ESG Score calculated? <img src="/media/img/infoCircle.svg"/>
                </span>
    </Tooltip>
  )
}

export default EsgScoreInfo
