import React from 'react'
import deepCopy from 'deepcopy'

import SearchTerm from 'app/reusable/SearchTerm'

import {
  BROWSE_SECTIONS,
  BROWSE_FILTERS_TEMPLATE,
  ADVANCED_FILTER_LABELS,
} from './constants'
import {
  getSearchLabels,
  getBrowseSections,
} from './utils'

export default function SummaryContent({
  currentUser,
  currentFirmLibraryName,
  articleAnySelectedOptions,
  articleAllSelectedOptions,
  articleExcludeSelectedOptions,
  articleExactSelectedOptions,
  headlineAnySelectedOptions,
  headlineAllSelectedOptions,
  headlineExcludeSelectedOptions,
  headlineExactSelectedOptions,
  introAnySelectedOptions,
  introAllSelectedOptions,
  introExcludeSelectedOptions,
  introExactSelectedOptions,
  selectedBrowseFilters,
  getSearchById,
  getFirmSourceLabelById,
  removeSummaryFilter,
}) {
  const searchLabels = getSearchLabels(currentUser.firm)
  const browseSectionsData = getBrowseSections(currentFirmLibraryName)
  const browseSections = {
    ...deepCopy(BROWSE_FILTERS_TEMPLATE),
    articleAny: articleAnySelectedOptions,
    articleAll: articleAllSelectedOptions,
    articleExclude: articleExcludeSelectedOptions,
    articleExact: articleExactSelectedOptions,
    headlineAny: headlineAnySelectedOptions,
    headlineAll: headlineAllSelectedOptions,
    headlineExclude: headlineExcludeSelectedOptions,
    headlineExact: headlineExactSelectedOptions,
    introAny: introAnySelectedOptions,
    introAll: introAllSelectedOptions,
    introExclude: introExcludeSelectedOptions,
    introExact: introExactSelectedOptions,
  }
  Object.entries(selectedBrowseFilters).forEach(([key, sectionFilters]) => {
    sectionFilters.forEach(filter => {
      filter = {...filter}
      let ss = null
      if (filter.searchName) {
          filter.label = filter.searchName
      } else if (key === 'searches') {
        if (filter.searchId) {
          ss = getSearchById(filter.searchId)
          filter.label = ss.name
          filter = {...ss, ...filter}
        } else if (filter.filterGroupSpecial) {
          /**
           * this component does not support all special filter group categories (ex. domestic, courts, etc).
           * if not supported, we ignore them here.
           */
          if (!searchLabels.hasOwnProperty(filter.filterGroupSpecial)) {
            return
          }
          filter.label = 'All ' + searchLabels[filter.filterGroupSpecial].label
        }
      } else if (key === BROWSE_SECTIONS.FIRM_LIBRARY_SEARCHES) {
        ss = getSearchById(filter.searchId)
        filter.label = ss.name
        filter.notes = ss.notes
        filter.isFirmLibrary = true
      } else if (key === BROWSE_SECTIONS.FIRM_SOURCE_LABELS) {
        const fsl = getFirmSourceLabelById(filter.firmSourceLabelId)
        filter.label = fsl.name
        filter.firmSourceLabelId = fsl.id
      } else {
        ss = getSearchById(filter.searchId)
        if (ss) {
          filter.label = ss.name
          filter.notes = ss.notes
          if (ss.owner && ss.owner.id === 1) {
            // some filter searches have a scope of 'hidden'
            // but if they're owned by user 1, we want to display them as global.
            filter.scope = 'global'
          }
        } else {
          filter.label = filter.searchId
        }
      }
      browseSections[key].push(filter)
    })
  })
  const cells = []
  let rowCounter = 0
  Object.keys(browseSections).forEach(key => {
    const original_categoryItems = browseSections[key]
     //removing duplicates from filter
    const categoryItems = []
    original_categoryItems.forEach(item=>{
      if(!categoryItems.some(ele=>ele.label == item.label)){
        categoryItems.push(item)
      }
    })
    if (categoryItems.length > 0) {
      rowCounter++
      const categoryLabel = browseSectionsData[key]
        ? browseSectionsData[key].filterLabel
        : ADVANCED_FILTER_LABELS[key]

      if (cells.length) {
        cells.push(
          <div key={'filter-summary-category-and-' + key} className="filter-summary-and" style={{msGridRow: rowCounter}}>AND</div>
        )
        rowCounter++
      }

      cells.push(
        <div key={'filter-summary-label-' + key} className="filter-summary-cell no-wrap left" style={{msGridRow: rowCounter}}>{categoryLabel}:</div>
      )

      cells.push(
        <div key={'filter-summary-cell-' + key} className="filter-summary-cell right" style={{msGridRow: rowCounter}}>
          {categoryItems.map((filter, idx) => {
            const andOr = (['articleAll', 'headlineAll', 'introAll'].indexOf(key) === -1? 'OR' : 'AND')
            const connector = (idx === categoryItems.length - 1 ? '' : andOr)
            return (
              <span key={'filter-summary-label-' + key + '-' + idx}>
                <SearchTerm
                  label={filter.label}
                  isFreeText={!filter.searchId && !filter.filterGroupSpecial && !filter.firmSourceLabelId}
                  isRemovable={true}
                  isFirmLibrary={filter.isFirmLibrary || false}
                  isFirmLibraryChild={filter.isFirmLibraryChild || false}
                  isSpecial={!!filter.filterGroupSpecial}
                  isFirmSourceLabel={!!filter.firmSourceLabelId}
                  scope={filter.scope}
                  notes={filter.notes}
                  ownerName={filter.ownerName}
                  onRemove={() => removeSummaryFilter(filter, key)}
                  className="filter-summary-cell-name"
                />
                <span className="filter-summary-cell-connector">
                  {connector}
                </span>
              </span>
            )
          })}
        </div>
      )
    }
  })

  return (
    <div className="filter-summary-grid">{cells}</div>
  )
}
