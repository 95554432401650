import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import Orm from 'app/framework/Orm'
import {SavedSearch} from 'app/models'
import EditSearchModal from 'app/reusable/EditSearchModal'
import {getEntities} from 'app/entities/entities-selectors'
import {getAvailableFilters} from 'app/global/global-selectors'

import {getProfileSearches} from './profile-searches-selectors'
import {
  hideEditSearchModal,
  fetchSearch,
  fetchSearchResults,
  setQueryComponentState,
  setSaveData,
  updateSearch,
  removeExcludedFeedsFromSearch,
} from './profile-searches-actions'


@connect(
  createSelector(
    [
      getEntities,
      getAvailableFilters,
      getProfileSearches,
    ],
    (
      entities,
      availableFilters,
      profileSearches,
    ) => {
      const orm = Orm.withEntities(entities)
      return {
        editSearchId: profileSearches.editSearchId,
        isLoading: profileSearches.isLoading,
        search: orm.getById(SavedSearch, profileSearches.editSearchId),
        fullyFetchedSearchIds: profileSearches.fullyFetchedSearchIds,
        searchResults: profileSearches.searchResults,
        saveData: profileSearches.saveData,
        queryComponentState: profileSearches.queryComponentState,
        availableFiltersByCategory: availableFilters,
      }
    }
  ),
  {
    fetchSearch,
    hideEditSearchModal,
    fetchSearchResults,
    setQueryComponentState,
    setSaveData,
    updateSearch,
    removeExcludedFeedsFromSearch,
  },
)
export default class ProfileEditSearchModal extends Component {
  componentDidMount() {
    if (!this.props.fullyFetchedSearchIds.includes(this.props.editSearchId)) {
      this.props.fetchSearch(this.props.editSearchId)
    }
  }

  render() {
    const modal =
      this.props.fullyFetchedSearchIds.includes(this.props.editSearchId) &&
      this.props.availableFiltersByCategory &&
      <EditSearchModal
        defaultTab="filters"
        hide={this.props.hideEditSearchModal}
        savedSearch={this.props.search}
        isLoading={this.props.isLoading}
        searchResults={this.props.searchResults}
        onUpdateSearchResults={this.props.fetchSearchResults}
        saveData={this.props.saveData}
        setSaveData={this.props.setSaveData}
        queryComponentState={this.props.queryComponentState}
        setQueryComponentState={this.props.setQueryComponentState}
        onSave={this.props.updateSearch}
        onRemoveExcludedFeeds={(feedIds) => this.handleRemoveExcludedFeeds(feedIds)}
      />

    return (
      <div>
        {modal}
      </div>
    )
  }

  handleRemoveExcludedFeeds(feedIds) {
    this.props.removeExcludedFeedsFromSearch({feedIds, searchId: this.props.editSearchId})
  }
}
