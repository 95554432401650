import {graphqlQuery} from 'app/api'
import {args, gql} from 'app/graphql'


export function fetchLanguages() {
  return graphqlQuery(
    gql`
      query {
        firmLanguages() {
          id
          name
          nativeName
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchFirmLocations() {
  return graphqlQuery(
    gql`
      query {
        firmLocations() {
          id
          description
          countryId
          timezone
          users {
            id
          }
          languages {
            id
            name
            nativeName
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchDepartments() {
  return graphqlQuery(
    gql`
      query {
        departments() {
          id
          name
          users {
            id
            fullName
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchTeams() {
  return graphqlQuery(
    gql`
      query {
        teams() {
          id
          name
          users {
            id
            fullName
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function addLanguage(id) {
  return graphqlQuery(
    gql`
      mutation {
        addFirmLanguage(${args({id})}) {
          boolean
      }
    `
  ).then(({body: {data}}) => data)
}

export function createFirmLocation({description, countryId, timezone}) {
  return graphqlQuery(
    gql`
      mutation {
        createFirmLocation(${args({description, countryId, timezone})}) {
          id
      }
    `
  ).then(({body: {data}}) => data)
}

export function createDepartment({name}) {
  return graphqlQuery(
    gql`
      mutation {
        createDepartment(${args({name})}) {
          success
      }
    `
  ).then(({body: {data}}) => data)
}

export function createTeam({name}) {
  return graphqlQuery(
    gql`
      mutation {
        createTeam(${args({name})}) {
          success
      }
    `
  ).then(({body: {data}}) => data)
}

export function saveFirmLocation({id, description, countryId, timezone}) {
  return graphqlQuery(
    gql`
      mutation {
        saveFirmLocation(${args({id, description, countryId, timezone})}) {
          id
      }
    `
  ).then(({body: {data}}) => data)
}

export function saveDepartment({id, name}) {
  return graphqlQuery(
    gql`
      mutation {
        saveDepartment(${args({id, name})}) {
          id
      }
    `
  ).then(({body: {data}}) => data)
}

export function saveTeam({id, name}) {
  return graphqlQuery(
    gql`
      mutation {
        saveTeam(${args({id, name})}) {
          id
      }
    `
  ).then(({body: {data}}) => data)
}

export function saveLocationAssignments({id, userIds, groupIdsForMembers}) {
  return graphqlQuery(
    gql`
      mutation {
        saveLocationAssignments(${args({id, userIds, groupIdsForMembers})}) {
          id
      }
    `
  ).then(({body: {data}}) => data)
}

export function saveDepartmentAssignments({id, userIds, groupIdsForMembers}) {
  return graphqlQuery(
    gql`
      mutation {
        saveDepartmentAssignments(${args({id, userIds, groupIdsForMembers})}) {
          id
      }
    `
  ).then(({body: {data}}) => data)
}

export function saveTeamAssignments({id, userIds, groupIdsForMembers}) {
  return graphqlQuery(
    gql`
      mutation {
        saveTeamAssignments(${args({id, userIds, groupIdsForMembers})}) {
          id
      }
    `
  ).then(({body: {data}}) => data)
}

export function removeLanguage(id) {
  return graphqlQuery(
    gql`
      mutation {
        removeFirmLanguage(${args({id})}) {
          boolean
      }
    `
  ).then(({body: {data}}) => data)
}

export function deleteDepartments(departmentIds) {
  const query = departmentIds.map(departmentId => {
    return (`
      mutation {
        deleteDepartment(
          id: ${departmentId},
        ) {
          success
        }
      }
    `)
  }).join('')
  return graphqlQuery(
    query
  ).then(({body: {data}}) => data)
}

export function deleteTeams(teamIds) {
  const query = teamIds.map(teamId => {
    return (`
      mutation {
        deleteTeam(
          id: ${teamId},
        ) {
          success
        }
      }
    `)
  }).join('')
  return graphqlQuery(
    query
  ).then(({body: {data}}) => data)
}

export function deleteFirmLocations(ids) {
  const query = ids.map(firmLocationId => {
    return (`
      mutation {
        deleteFirmLocation(
          id: ${firmLocationId},
        ) {
          success
        }
      }
    `)
  }).join('')
  return graphqlQuery(
    query
  ).then(({body: {data}}) => data)
}

export function addFirmLocationLanguage(locationId, languageId) {
  return graphqlQuery(
    gql`
      mutation {
        addFirmLocationLanguage(${args({firmLocationId: locationId, languageId})})
      }
    `
  ).then(({body: {data}}) => data)
}

export function removeFirmLocationLanguage(locationId, languageId) {
  return graphqlQuery(
    gql`
      mutation {
        removeFirmLocationLanguage(${args({firmLocationId: locationId, languageId})})
      }
    `
  ).then(({body: {data}}) => data)
}
