import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {getEntities} from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import {User} from 'app/models'
import Button from 'app/common/Button'
import BulkUserAssignment from 'app/reusable/BulkUserAssignment'
import ShareSearchBase from 'app/reusable/ShareSearchBase'

import {setShareModalData, fetchAssignees, fetchUsers} from '../searches-admin-actions'
import {getSearchesAdmin} from '../searches-admin-selectors'

import './ShareModal.less'


@connect(
  // Map state to props
  createSelector(
    [getEntities, getSearchesAdmin],
    (entities, searchesAdmin) => {
      const orm = Orm.withEntities(entities)
      return {
        users: orm.getByIds(User, searchesAdmin.userIds),
        isAssigneesFetched: searchesAdmin.isAssigneesFetched,
        departments: searchesAdmin.departments,
        teams: searchesAdmin.teams,
        firmLocations: searchesAdmin.firmLocations,
        assigneeIdsBySection: searchesAdmin.shareModalData.assigneeIdsBySection,
        areAllUsersFetched: searchesAdmin.areAllUsersFetched,
        isLargeFirm: searchesAdmin.isLargeFirm,
        activeUserCount: searchesAdmin.activeUserCount,
        activeGroupCount: searchesAdmin.activeGroupCount,
      }
    }
  ),
  {
    setShareModalData,
    fetchAssignees,
    fetchUsers,
  }
)
export default class ShareSearchContainer extends ShareSearchBase {
  componentDidMount() {
    if (!this.props.isAssigneesFetched) {
      this.props.fetchAssignees()
    }
  }

  render() {
    if (!this.props.isAssigneesFetched) {
      return null
    }

    const shareCount = this.shareWithAssigneeCount()
    const shareSummary =
      shareCount ?
        <p>{shareCount} Selected: {this.shareWithAssigneeNames()}</p>
        : null

    return (
      <div>
        <h2>Step 1: Select Users to Add Search To</h2>

        {shareSummary}

        <BulkUserAssignment
          users={this.props.users}
          departments={this.props.departments}
          teams={this.props.teams}
          firmLocations={this.props.firmLocations}
          onFetchUsers={this.props.fetchUsers}
          onSectionChange={section => this.setSelectedSection(section)}
          onSelectedIdsChange={ids => this.setSelectedAssigneeIds(ids)}
          selectedIdsBySection={this.props.assigneeIdsBySection}
          showDepartmentMembers={true}
          showIndividualMembers={true}
          showTeamMembers={true}
          showFirmLocations={true}
          isLargeFirm={this.props.isLargeFirm}
          activeUserCount={this.props.activeUserCount}
          activeGroupCount={this.props.activeGroupCount}
          areAllUsersFetched={this.props.areAllUsersFetched}
        />

        <div className="new-search-buttons-container">
          <div className="new-search-buttons">
            <div className="buttons right">
              <Button
                label="Next Step"
                onClick={() => this.nextStep()}
                disabled={shareCount === 0}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  nextStep() {
    this.props.setShareModalData({step: 2})
  }

  setSelectedAssigneeIds(ids) {
    let assigneeIdsBySection = {...this.props.assigneeIdsBySection}
    assigneeIdsBySection[this.state.selectedSection] = ids
    this.props.setShareModalData({assigneeIdsBySection: assigneeIdsBySection})
  }
}
