import {handleActions} from 'redux-actions'

import * as actions from './routing-actions'
import * as utils from './routing-utils'

const getInitialState = () => ({
  pathname: window.location.pathname,
  query: utils.searchParamsToQuery(new URLSearchParams(window.location.search)),
  previous: null,
})

export default handleActions(
  {
    [actions.locationChanged]: (state, action) =>({
      ...action.payload,
      previous: {
        pathname: state.pathname,
        query: state.query,
      },
    }),
  },
  getInitialState(),
)
