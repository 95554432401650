import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

import InlineSvg from 'app/common/InlineSvg'
import * as urls from 'app/urls'

import styles from './Header.less'
import { MENU_ICONS, getAppLogo, MAX_BADGE_COUNT, screenWidthForHamburgerMenu, screenWidthForFullHamburgerMenu } from 'app/constants'
import Tooltip from 'app/common/Tooltip'
import useWindowSize from 'app/custom-hooks/useWindowSize'
import _ from 'lodash'
import ResponsiveMenu from 'app/global/header/ResponsiveMenu'
import { TEXT_MAX_LENGTH } from 'app/firm-admin/usage/usage-constants'
import SearchBar from "app/global/NavMenu/SearchBar";

// At some point, replace this with a Redux-based route check.
const iconStyle = { height: "22px", width: "26px"}
const doesUrlMatch = url => window.location.pathname === url
const withSourceQueryParams =
  url => urls.withQueryParams(url, {usrc: 'nav', usrc_s: 'comfort'})

const IconLink = ({url, label, icon, badgeCount, onClick,
   matchingUrls = [], noTooltip = false, highlight = false,
   matchURL = doesUrlMatch}) => {
  matchingUrls = [...matchingUrls, url]
  const active = matchingUrls.some(url => matchURL(url))
  const getUpdatedBadgeCount = (badgeCount) => Number(badgeCount) > MAX_BADGE_COUNT ? '99+' : badgeCount
  return (
    <Tooltip label={label} disabled={noTooltip}>
      <div
        className={classNames(
          styles.link,
          {
            [styles.active]: active,
            [styles.highlight]: highlight
          }
        )} >
          <a href={withSourceQueryParams(url)} onClick={onClick} className={styles.badgeContainer}>
            {icon ? <InlineSvg src={icon} style={iconStyle}/> : label}
            {badgeCount ? <div className={styles.badge}>{getUpdatedBadgeCount(badgeCount)}</div> : null}
          </a>
      </div>
    </Tooltip>
  )
}
IconLink.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func,
}

const Separator = () => <div className={classNames(styles.link, {[styles.separator]: true})}>
    <InlineSvg src={MENU_ICONS.separator}  style={iconStyle}/>
  </div>

export default function HeaderGeneric(
  {
    isStaff,
    isFirmAdmin,
    isGroupManager,
    isGroup,
    isPortalGroup,
    hasPublishV3Access,
    shouldHideInsightsLink,
    displayName,
    firmName,
    flaggedArticleCount,
    jwt,
    originalUserIsStaff,
    shouldHideMisLink,
    hasPublishAccess,
    publishAccessForNonadmins,
    isDiligent,
    rootInsightsUrl,
    adminConsoleRoot,
    firmLogoWebHeader,
    navBarOrigin,
    isMasqueradePresent
  }
) {
  const navBar = useRef(null)
  const searchBarRef = useRef(null);
  const [isMenuActive, setIsMenuActive] = useState(false) 
  const [isResponsiveMenuActive, setIsResponsiveMenuActive] = useState(false) 
  const showMenu = () => setIsMenuActive(true)
  const hideMenu = () => setIsMenuActive(false) 

  const [screenWidth, screenHeight] = useWindowSize();
  const showHalfResponsiveMenu = screenWidth <= screenWidthForHamburgerMenu
  const showFullResponsiveMenu = screenWidth <= screenWidthForFullHamburgerMenu
  const showAdminMenu = !showHalfResponsiveMenu
  const showPrimaryMenu = !showFullResponsiveMenu
  const showHamburgerMenu = showHalfResponsiveMenu
  const hamburgerIconClick = ()=> setIsResponsiveMenuActive(!isResponsiveMenuActive)
  const showNewSearchModal = () => {
    if (searchBarRef.current) {
        searchBarRef.current.showModal();
    }
  }

  const adminUrls = [
    urls.adminUsers(),
    urls.adminGroups(),
    urls.adminSources(),
    urls.adminSearches(),
    urls.adminSettings(),
    urls.adminUsage(),
    urls.adminServices(),
  ]
  let adminLink
  if (isStaff || isFirmAdmin) {
    adminLink = <IconLink 
                  url={urls.adminUsers()}  
                  matchingUrls={adminUrls} 
                  icon={MENU_ICONS.admin}
                  label="Admin"
                />
  } else if (isGroupManager) {
    adminLink = <IconLink 
                  url={urls.manageGroups()} 
                  matchingUrls={adminUrls} 
                  label="Manage Groups" 
                />
  } else {
    adminLink = null
  }

  const onHelpClick = e => {
    if (window.helpPanel) {
      window.helpPanel()
      e.preventDefault()
    }
  }
  const isMis = window.location.pathname.startsWith(urls.mis.home())
  const isEsg = window.location.pathname.startsWith(urls.esg.home())

  // hiding for all DGI for now
  const misProductLink = !shouldHideMisLink &&
    <a
      href={urls.mis.home()}
      className={classNames(styles.link, {[styles.active]:isMis})}
    >
      {isDiligent ? 'Intel Now' : 'MIS'}
    </a>

  const insightsLinkLabel = isDiligent
    ? 'Analytics'
    : 'Insights'

  const appLogo = getAppLogo(true)    
  const headerLogo = firmLogoWebHeader
    ? <img
        src={firmLogoWebHeader} alt={firmName}
        className={styles.firmLogoHeader}
      />
    : <InlineSvg
        src={appLogo}
        className={styles.logo}
      />

  let nonAdminsPublish = true;
  if (!isFirmAdmin) {
    if (publishAccessForNonadmins) {
      nonAdminsPublish = true
    } else {
      nonAdminsPublish = false
    }
  }
  
  const showOldPublish = hasPublishAccess && !isDiligent 
  const showNewPublish = hasPublishV3Access && nonAdminsPublish 
  const publishTooltip = "Publications"

  const oldPublish = <IconLink 
      url={urls.publish()} 
      icon={MENU_ICONS.publish}
      label={publishTooltip}
    />

  const matchNewPublishURL = url => window.location.pathname.startsWith(url)
  const newPublish = <IconLink 
      url={urls.publish3()} 
      icon={MENU_ICONS.publish}
      label={publishTooltip}
      matchURL={matchNewPublishURL}
    />

  const allPublishLinks = (
    <div onMouseEnter={showMenu} onMouseLeave={hideMenu}>
      <IconLink 
        url={urls.publish3()} 
        matchingUrls={[urls.publish()]} 
        icon={MENU_ICONS.publish}
        noTooltip
        label="Publish" 
        highlight={isMenuActive}
        matchURL={matchNewPublishURL}
      />
      <div className={classNames(styles.dropMenuContainer, {[styles.dropMenuActive]: isMenuActive})}>
        <Tooltip label={publishTooltip}>
          <a href={urls.publish()} className={styles.dropMenu}>
            Publish
          </a>
        </Tooltip>
        <Tooltip label={`${publishTooltip} NEW`}>
          <a href={urls.publish3()} className={styles.dropMenu}>
            Publish <div className={styles.newTag}>New</div>
          </a>
        </Tooltip>
      </div>
    </div>
  )

  const getPublishLink = () => {
    if (showOldPublish && showNewPublish) {
      return allPublishLinks
    }
    else if (showOldPublish){
      return oldPublish
    }
    else if (showNewPublish){
      return newPublish
    }
    else {
      return null
    }
  }

  const isProfileActive = window.location.pathname.localeCompare(urls.profile()) === 0
  const userName = _.startCase(_.camelCase(displayName))

  const logo = <>
          <a href={urls.home()} className={styles.logoSection}>
            {headerLogo}
          </a>
        </>

  const getFormattedText = (text, maxLength) => {
    if (text && text.trim().length > maxLength) {
        const croppedText = `${text.substring(0, maxLength)}...`
        return croppedText
    }
    else {
        return text
    }
  }

  const userLabel = screenWidth <= 1192 && screenWidth >= 1169 ? getFormattedText(`${userName}`, 5) :
  getFormattedText(`${userName}`, TEXT_MAX_LENGTH.profileName)

  const primaryMenu = <>
          <div id="primaryMenu" className={styles.logoAndProducts}>
              {/* <a href={urls.home()} className={styles.logoSection}>
                {headerLogo}
              </a> */}
              <div className={styles.productLinks}>
                <a
                  href={urls.home()}
                  className={classNames(styles.link, {[styles.active]: (!isMis && !isEsg)})}
                >
                  Base
                </a>

                {!shouldHideInsightsLink && (
                  <a
                    href={urls.withQueryParams(rootInsightsUrl, {token: jwt})}
                    className={styles.link}
                  >
                    {insightsLinkLabel}
                  </a>

                )}
                {misProductLink}
                <a
                  href={urls.esg.home()}
                  className={classNames(styles.link, {[styles.active]: isEsg})}
                >
                  ESG DASHBOARD
                </a>
              </div>
            </div>
        </>

  const adminMenu =  <>
            <div id="adminMenu" className={styles.profileSection}>

            <IconLink 
                url={urls.help()} 
                icon={MENU_ICONS.help}
                label="Help" 
                onClick={onHelpClick} 
              />

            <IconLink
              url={urls.flagged()}
              icon={MENU_ICONS.flag}
              label="Flagged Items"
              badgeCount={flaggedArticleCount}
            />

            {getPublishLink()}

            <Separator/>
            {adminLink}

            {!isPortalGroup && (
              <IconLink 
                url={urls.emailSettings()} 
                icon={MENU_ICONS.emailNotifications}
                label="Email Notifications" 
              />
            )}

            <div
              className={classNames(
                styles.link,
                {
                  [styles.active]: isProfileActive
                },
              )}
            >
              <Tooltip label={`My Profile - ${userName}`}>
                <a href={urls.profile()} className={styles.userInfo}>
                  <div className={styles.userNameLink}>
                    <div className={styles.userName}>{userLabel}</div>
                    <div className={styles.firmName}>{firmName}</div> 
                  </div>
                </a>
              </Tooltip>
            </div>

            {/* Left for easy access to Admin Page */}
            {/* {isStaff && (
              <Link 
                url={adminConsoleRoot} 
                label="Admin Console" 
              />
            )} */}

            <IconLink 
              url={urls.logout()} 
              icon={MENU_ICONS.logout} 
              label="Sign Out" 
            />

          </div>
        </>

  const hamburgerMenu = <> 
    <ResponsiveMenu 
      isGroupManager={isGroupManager} 
      rootInsightsUrl={rootInsightsUrl}
      jwt={jwt}
      isDiligent={isDiligent}
      shouldHideInsightsLink={shouldHideInsightsLink}
      shouldHideMisLink={shouldHideMisLink}
      showOldPublish={showOldPublish}
      showNewPublish={showNewPublish}
      flaggedArticleCount={flaggedArticleCount}
      displayName={displayName}
      firmName={firmName}
      isStaff={isStaff}
      isFirmAdmin={isFirmAdmin}
      navBarOrigin={navBarOrigin}
      isPortalGroup={isPortalGroup}
      showPrimaryMenu={showPrimaryMenu}
      showAdminMenu={showAdminMenu}
      hideMenu={()=>setIsResponsiveMenuActive(false)}
      showNewSearchModal={showNewSearchModal}
    /> 
  </>

  const navBarYEnd = navBar.current ? (navBar.current.getBoundingClientRect().y + navBar.current.getBoundingClientRect().height) : 60
  const windowHeight = window.innerHeight - navBarYEnd
  const hamburgerIcon = <>
          <div className={classNames(styles.hamburgerIcon, {[styles.active]: isResponsiveMenuActive})}>
            <InlineSvg 
              onClick={hamburgerIconClick}
              src={MENU_ICONS.hamburger}/>
          </div>
          <div  id="hamburger-menu" className={classNames(styles.hamburgerMenuContainer, {[styles.active]: isResponsiveMenuActive})}
            style={{top: navBarYEnd, height: windowHeight}}
            >
            <div className={styles.wrapper}>
              <div className={classNames(styles.slider, {[styles.slideLeft]: isResponsiveMenuActive})}>
                {hamburgerMenu}
              </div>
            </div>
          </div>
        </>

  return (
    <>
      <div className={styles.header}>
        <div ref={navBar} className={styles.container}>

          {logo}
          {showPrimaryMenu ? primaryMenu : null}
          {showAdminMenu ? adminMenu : null}
          {showHamburgerMenu ? hamburgerIcon : null}
        </div>
      </div>
      <SearchBar
        appName={isDiligent ? "Diligent" : "Manzama"}
        ref={searchBarRef}
        showButton={false}
      />
    </>
  )
}
HeaderGeneric.propTypes = {
  isStaff: PropTypes.bool.isRequired,
  isFirmAdmin: PropTypes.bool.isRequired,
  isGroupManager: PropTypes.bool.isRequired,
  isGroup: PropTypes.bool.isRequired,
  isPortalGroup: PropTypes.bool.isRequired,
  hasPublishV3Access: PropTypes.bool.isRequired,
  shouldHideInsightsLink: PropTypes.bool,
  displayName: PropTypes.string.isRequired,
  firmName: PropTypes.string.isRequired,
  flaggedArticleCount: PropTypes.number.isRequired,
  jwt: PropTypes.string.isRequired,
  originalUserIsStaff: PropTypes.bool.isRequired,
  hasPublishAccess: PropTypes.bool.isRequired,
  publishAccessForNonadmins: PropTypes.bool.isRequired,
  isDiligent: PropTypes.bool,
  firmLogoWebHeader: PropTypes.string,
}
