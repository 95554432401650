import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import * as notifications from 'app/global/notifications'
import Overlay from 'app/common/Modal'
import TextBox from 'app/common/TextBox'
import Tooltip from "app/common/Tooltip"

import {fetchFeeds, showLoader, hideLoader} from '../sources-admin-actions'
import {searchManzamaSources} from '../sources-admin-api'
import {getSourcesAdmin} from '../sources-admin-selectors'
import {createSources} from '../sources-admin-actions'
import BrowseSourcesTable from './table'
import { TWITTER_TOOLTIP_MESSAGE } from 'app/constants'
import Dropdown from 'app/common/Dropdown'
import Option from 'app/common/Dropdown/Option'
import * as R from 'ramda'
import snakeCase from 'snake-case'
import { formatNumber } from 'app/utils'

@connect(
  createSelector(
    [getSourcesAdmin],
    (sourcesAdmin) => {
      return {
        feedIdsForSourcesBeingCreated: sourcesAdmin.feedIdsForSourcesBeingCreated,
        feedIdsForCreatedSources: sourcesAdmin.feedIdsForCreatedSources,
      }
    }
  ),
  {
    fetchFeeds,
    showNotification: notifications.actions.showNotification,
    createSources,
    showLoader, 
    hideLoader
  }
)
export default class BrowseSourcesOverlay extends Component {
  static propTypes = {
    appName: PropTypes.string.isRequired,
    appNameFull: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  state = {
    nameFilter: '',
    selectedFeedIds: [],
    feeds: [],
    currentPage: 1,
    totalCount: 0,
    sortField: '',
    sortDirection: '',
    ignoreSort: false,
  }

  render() {
    const header = `Browse ${this.props.appNameFull} Sources`
    const visibleFeedIds = this.state.feeds ? this.state.feeds.map(feed => feed.id) : 0
    const selectAllShouldBeChecked = (
      this.state.selectedFeedIds && this.state.selectedFeedIds.length > 0 &&
      R.intersection(this.state.selectedFeedIds, visibleFeedIds).length === visibleFeedIds.length
    )
    const selectedCount = this.state.selectedFeedIds.length
    const selectedCountDisplay = formatNumber(selectedCount)
    const totalCountDisplay = formatNumber(this.state.totalCount)
    const selectedCountText =
      totalCountDisplay ?
        <span className="selected-count-text">
          {
            `${selectedCountDisplay} of ${totalCountDisplay} sources selected`
          }
        </span>
        : null

    return (
      <Overlay
        id="browse-manzama-sources-modal"
        className="firm-source-overlay"
        isOpen={true}
        onClose={this.props.onClose}
      >
        <h1 className="feed-name">{header}</h1>

        <div className="header">
          <label className="search">
            <div className='search-box'>
              <span className="label">Search for Source Name</span>
              <Tooltip label={TWITTER_TOOLTIP_MESSAGE}><div className='tooltip help tooltip-browse'/></Tooltip>
            </div>
            <TextBox
              placeholder="Start typing a source name..."
              onChange={this.onSearchChange}
            />
          </label>
        </div>
        <div className="checkbox-container">
          <div className="checkbox-dropdown">
            <Dropdown value={''} onChange={(value) => this.onChangeSelectAll(value)}>
              <Option
                value=''
                isInvisible={true}
                label={
                  <input
                    type="checkbox"
                    onClick={e => e.preventDefault()}
                    onChange={e => e.preventDefault()}
                    checked={selectAllShouldBeChecked}
                  />
                }
              />
              <Option label={`Select Visible (${visibleFeedIds.length})`} value="visible" />
              <Option label="Deselect All" value="none" />
            </Dropdown>
          </div>
          <div className="selected-count">
            {selectedCountText}
          </div>
        </div>
          <BrowseSourcesTable
            feeds={this.state.feeds}
            selectedFeedIds={this.state.selectedFeedIds}
            onFeedSelectStateChange={this.onFeedSelectStateChange}
            onFeedsSelectAll={this.onFeedsSelectAll}
            onFeedsUnselectAll={this.onFeedsUnselectAll}
            onPageChange={this.onPageChange.bind(this)}
            onSortChange={this.onSortChange.bind(this)}
            currentPage={this.state.currentPage}
            totalCount={this.state.totalCount}
            ignoreSort={this.state.ignoreSort}
            sortField={this.state.sortField}
            sortDirection={this.state.sortDirection}
          />
        

        <div className="buttons">
          <div className="left">
            <button
              className="button add-source"
              onClick={this.onAddClick}
              disabled={!this.state.selectedFeedIds.length}
            >
              Add to Source List
            </button>
          </div>

          <div className="right">
          </div>
        </div>
      </Overlay>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const curState = this.state
    if ( prevState.nameFilter !== curState.nameFilter) {
      this.searchFeeds()
    } 
  }

  // Event handlers

  onSearchChange = (event) => {
    const searchText = event.target.value
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(function () {
      this.setState({ nameFilter: searchText, currentPage: 1, sortField: '', sortDirection: 'asc', ignoreSort: true })
    }.bind(this), 300);
  }

  onFeedSelectStateChange = (feedID, selected) => {
    if (selected) {
      this.setState({selectedFeedIds: [...this.state.selectedFeedIds, feedID]})
    } else {
      this.setState({
        selectedFeedIds: this.state.selectedFeedIds.filter(id => id !== feedID),
      })
    }
  }

  onFeedsSelectAll = () => {
    let selected_feeds = []
    this.state.feeds.map(feed =>{
      selected_feeds.push(feed.id)
    })
    this.setState({selectedFeedIds: [...selected_feeds]})
  }

  onFeedsUnselectAll = () => {
    this.setState({selectedFeedIds: []})
  }

  onAddClick = () => {
    this.addSelectedFeeds()
  }

  // State management

  searchFeeds() {
    const nameFilter = this.state.nameFilter
    const currentPage = this.state.currentPage
    const sortField = this.state.sortField
    const sortDirection = this.state.sortDirection
    this.props.showLoader()
    searchManzamaSources(
      {
        name: nameFilter,
        exclude: true,
        currentPage: currentPage,
        sortField: sortField,
        sortDirection: sortDirection,
      },
      (err, res) => {
        if (err) {
          // TODO: Show a real error message here
        } else {
          if (this.state.nameFilter !== nameFilter) {
            return
          }else if(nameFilter===''){
            this.setState({
              feeds: [],
              totalCount: 0,
            },()=>this.props.hideLoader())
            return
          }
          this.setState({
            feeds: res.body.matches,
            totalCount: res.body.totalCount,
          },()=>this.props.hideLoader())
        }
      }
    )
  }

  addSelectedFeeds() {
    this.props.createSources(this.state.selectedFeedIds)
  }

  onPageChange(page) {
    this.setState({ currentPage: page }, () => this.searchFeeds())
  }

  onSortChange(sortOptions) {
    this.setState(prevState => {
      let direction = sortOptions.direction
      let column = sortOptions.column
      if (prevState.sortDirection === "desc") {
        direction = ''
        column = ''
      } else if (prevState.sortDirection === '') {
        direction = 'asc'
      }
      return { sortField: column, sortDirection: direction, ignoreSort: false }
    }, () => this.searchFeeds())
  }

  onChangeSelectAll(value) {
    this.setState({ selectedFeedIds: [] })
    if (value === 'visible') {
       this.onFeedsSelectAll()
    }
  }

}
