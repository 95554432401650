import invariant from 'invariant'

import {Feed} from 'app/models'

import {SORT_OPTIONS} from './search-results-page-constants'

export function publicationTabLabel(publicationTabType) {
  const string = {
    [Feed.PUBLICATION_TYPE_CATEGORIES.NEWS]: 'News',
    [Feed.PUBLICATION_TYPE_CATEGORIES.FILINGS]: 'Filings',
    [Feed.PUBLICATION_TYPE_CATEGORIES.TWITTER]: 'Twitter',
    [Feed.PUBLICATION_TYPE_CATEGORIES.EVENTS]: 'Events',
    [Feed.PUBLICATION_TYPE_CATEGORIES.MY_SOURCES]: 'My Sources',
  }[publicationTabType]
  invariant(string, `Unknown publication tab type ${publicationTabType}.`)
  return string
}

export function publicationTypeLabel(publicationType) {
  const string = {
    [Feed.PUBLICATION_TYPES.BLOG]: 'Blogs',
    [Feed.PUBLICATION_TYPES.CASE_FILING]: 'Case Filings',
    [Feed.PUBLICATION_TYPES.DOJ_FILING]: 'DOJ Filings',
    [Feed.PUBLICATION_TYPES.NEWS]: 'Articles',
    [Feed.PUBLICATION_TYPES.PATENT_FILING]: 'Patent Filings',
    [Feed.PUBLICATION_TYPES.PRESS_RELEASE]: 'Firm Press Releases',
    [Feed.PUBLICATION_TYPES.PUBLICATION]: 'Firm Publications',
    [Feed.PUBLICATION_TYPES.REGULATORY_FILING]: 'Regulatory Filings',
    [Feed.PUBLICATION_TYPES.TWITTER]: 'Twitter',
    [Feed.PUBLICATION_TYPES.EVENT]: 'Events',
    [Feed.PUBLICATION_TYPES.MY_SOURCES]: 'My Sources',
  }[publicationType]
  invariant(string, `Unknown publication type ${publicationType}.`)
  return string
}

export function filterGroupFilterFieldLabelAbbrev(filterField) {
  return {
    text_exclude: 'Exclude',
    content_exact: 'Exact',
    headline_any: 'HL',
    headline_all: 'HL',
    headline_exclude: 'HL Excl',
    headline_exact: 'HL Exact',
    intro_any: 'HL+Lead',
    intro_all: 'HL+Lead',
    intro_exclude: 'HL+Lead Excl',
    intro_exact: 'HL+Lead Exact',
  }[filterField]
}

export function filterGroupFilterFieldLabelFull(filterField) {
  return {
    text_exclude: 'Exclude',
    content_exact: 'Exact',
    headline_any: 'Headline',
    headline_all: 'Headline',
    headline_exclude: 'Headline Exclude',
    headline_exact: 'Headline Exact',
    intro_any: 'Headline + Leading Words',
    intro_all: 'Headline + Leading Words',
    intro_exclude: 'Headline + Leading Words Exclude',
    intro_exact: 'Headline + Leading Words Exact',
  }[filterField]
}

export function sortOptionLabel(sortOption) {
  const string = {
    [SORT_OPTIONS.RELEVANCE]: 'Relevance',
    [SORT_OPTIONS.DATE_DESC]: 'Newest first',
    [SORT_OPTIONS.DATE_ASC]: 'Oldest first',
  }[sortOption]
  invariant(string, `Unknown sort option '${sortOption}'.`)
  return string
}
