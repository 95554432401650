import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import Highlighter from 'react-highlight-words'

import LoadingOverlay from 'app/common/LoadingOverlay'
import TextBox from 'app/common/TextBox'
import Button from 'app/common/Button'

import {
  getAppNameFull,
} from 'app/global/global-selectors'

import {
  fetchTopics,
  fetchSearchResults,
  setSearchResults,
} from './help-actions'
import {getHelp} from './help-selectors'

import * as styles from './Help.less'


@connect(
  createSelector(
    [getAppNameFull, getHelp],
    (appNameFull, help) => {
      return {
        appNameFull,
        isLoading: help.isLoading,
        topics: help.topics,
        searchResults: help.searchResults,
      }
    }
  ),
  {
    fetchTopics,
    fetchSearchResults,
    setSearchResults,
  },
)
export default class Help extends Component {
  state = {
    searchTerm: '',
  }

  componentDidMount() {
    if (this.props.topics === null) {
      this.props.fetchTopics();
    }
  }

  render() {
    const topics = this.props.topics || []

    return (
      <React.Fragment>
        {this.props.isLoading && <LoadingOverlay/>}

        <div className={styles.intro}>
          <h1>Help Center</h1>
          <span>Start with these {this.props.appNameFull} training tutorials to gain a working knowledge of the system.</span>
        </div>

        <div className={styles.actions}>
          <TextBox
            placeholder="Search within the Help Center"
            value={this.state.searchTerm}
            onChange={(event) => this.handleSearchTermChange(event.target.value)}
          />

          <div>
            <Button
              label="Search"
              isPrimary
              onClick={() => this.handleSearchButtonClick()}
            />

            <Button
              label="Reset"
              onClick={() => this.handleResetButtonClick()}
            />
          </div>
        </div>

        {
          this.props.searchResults
            ? <div className={styles.searchResults}>
                {
                  this.props.searchResults.length === 0  &&
                  <p className={styles.noResults}>No results found, please try another search or <a href="mailto:support@manzama.com" target="_blank">contact Support</a> for additional assistance.</p>
                }
                {
                  this.props.searchResults.map((result, index) =>
                    <div>
                      <h2 key={index}>
                        <a href={result.topic.url}>
                          <Highlighter
                            textToHighlight={result.topic.name}
                            searchWords={[this.state.searchTerm]}
                            highlightClassName={styles.highlight}
                          />
                        </a>
                      </h2>
                      {
                        result.question &&
                        <React.Fragment>
                          <h3 className={styles.question}>
                            <a
                              key={index}
                              className={styles.item}
                              href={`${result.topic.url}#${result.question.slug}`}
                            >
                              <Highlighter
                                textToHighlight={result.question.question}
                                searchWords={[this.state.searchTerm]}
                                highlightClassName={styles.highlight}
                              />
                            </a>
                          </h3>
                          {
                            result.answerSnippet &&
                            <p className={styles.snippet}>
                              <Highlighter
                                textToHighlight={result.answerSnippet}
                                searchWords={[this.state.searchTerm]}
                                highlightClassName={styles.highlight}
                              />
                            </p>
                          }
                        </React.Fragment>
                      }
                    </div>
                  )
                }
              </div>
            : <div className={styles.grid}>
                {
                  topics.map((topic, index) =>
                    <div key={index} className={styles.card}>
                      <h2><a href={topic.url}>{topic.name}</a></h2>
                      {
                        topic.questions.map((question, index) =>
                          <a
                            key={index}
                            className={styles.item}
                            href={`${topic.url}#${question.slug}`}
                          >{question.question}</a>
                        )
                      }
                    </div>
                  )
                }
              </div>
        }

        <div className={styles.emailFooter}>
          <h3>Still have questions, or need to schedule training?</h3>
          <a href="mailto:support@manzama.com" target="_blank" className="button">Contact Support</a>
        </div>

        {/*<div className={styles.phoneFooter}>*/}
        {/*  <div>*/}
        {/*    <h4>Phone Support</h4>*/}
        {/*    7:00am - 5:00pm PT*/}
        {/*  </div>*/}

        {/*  <div>*/}
        {/*    <h4>USA &amp; Canada <img src="/media/img/emojione-flag-usa-16x16@2x.png"/><img*/}
        {/*      src="/media/img/emojione-flag-can-16x16@2x.png"/></h4>*/}
        {/*    +1 (541) 706-9393*/}
        {/*  </div>*/}

        {/*  <div>*/}
        {/*    <h4>UK / Europe <img src="/media/img/emojione-flag-uk-16x16@2x.png"/><img*/}
        {/*      src="/media/img/emojione-flag-eu-16x16@2x.png"/></h4>*/}
        {/*    +44 (0) 203 282 1633*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*<div dangerouslySetInnerHTML={{__html: '<!-- Emoji provided free by http://emojione.com -->'}}/>*/}
      </React.Fragment>
    )
  }

  handleSearchTermChange(searchTerm) {
    this.props.setSearchResults(null)
    this.setState({searchTerm})
  }

  handleSearchButtonClick() {
    this.props.fetchSearchResults(this.state.searchTerm)
  }

  handleResetButtonClick() {
    this.props.setSearchResults(null)
    this.setState({searchTerm: ''})
  }
}
