import { createAction } from 'redux-actions'

const createAdvancedSearchAction = name => createAction(`ADVANCED_SEARCH_${name}`)

export const getSearchResults = createAdvancedSearchAction('GET_SEARCH_RESULTS')
export const hideLoader = createAdvancedSearchAction('HIDE_LOADER')
export const shouldShowTermFrequencyFilter = createAdvancedSearchAction(
  'SHOULD_SHOW_TERM_FREQUENCY_FILTER')
export const changeTermFrequencyArray = createAdvancedSearchAction(
  'CHANGE_TERM_FREQUENCY_ARRAY')
export const setTermFrequencyArray = createAdvancedSearchAction(
  'SET_TERM_FREQUENCY_ARRAY')
export const setTermFrequencyFrequency = createAdvancedSearchAction(
  'SET_TERM_FREQUENCY_FREQUENCY')
export const submitTermFrequencyFilter = createAdvancedSearchAction(
  'SUBMIT_TERM_FREQUENCY_FILTER')
export const initializeTermFrequencyFilter = createAdvancedSearchAction(
  'INITIALIZE_TERM_FREQUENCY_FILTER')
export const resetTermFrequencyFilter = createAdvancedSearchAction(
  'RESET_TERM_FREQUENCY_FILTER')
export const setTermFrequencyTargetField = createAdvancedSearchAction(
  'SET_TERM_FREQUENCY_TARGET_FIELD')
export const setPendingTermFrequencyFilters = createAdvancedSearchAction(
  'SET_PENDING_TERM_FREQUENCY_FILTERS')
export const initializePendingTermFrequencyFilters = createAdvancedSearchAction(
  'INITIALIZE_PENDING_TERM_FREQUENCY_FILTERS')
export const removePendingTermFrequencyFilter = createAdvancedSearchAction(
  'REMOVE_PENDING_TERM_FREQUENCY_FILTER')
