import PropTypes from 'prop-types'
import React from 'react'

import Dropdown, { Option } from 'app/common/Dropdown'


export default function AlertMaxItemsDropdown({
  hasZeroOption = true,
  ...restProps
}) {
  const choices = [0, 3, 5, 10, 20, 40].slice(hasZeroOption ? 0 : 1)
  return (
    <Dropdown {...restProps}>
      {
        choices.map(v => <Option value={v} label={v.toString()} key={v}/>)
      }
    </Dropdown>
  )
}
AlertMaxItemsDropdown.propTypes = {
  hasZeroOptions: PropTypes.bool,
  ...Dropdown.propTypes,
}
