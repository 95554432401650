import { createAction } from 'redux-actions'
import { createRequestActions } from '../../actions/utils'

// Prefix action names with DNBSEARCH_
const createDnbAction = name => createAction(`DNBSEARCH_${name}`)
const createDnbRequestActions = name => createRequestActions(`DNBSEARCH_${name}`)

export const searchDnbData = createDnbRequestActions('FETCH_DATA')
export const setIsLoading = createDnbAction('LOADING')
export const saveParms = createDnbAction('SAVE_PARMS')
export const addInitialData = createDnbAction('ADD_INITIAL,DATA')
export const countryChange = createDnbAction('COUNTRY_CHANGE')
