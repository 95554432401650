import { apiCall } from 'app/api'
import * as urls from 'app/urls'


export function fetchData(data, callback) {
  return apiCall({
    url: urls.dunAndBradstreet.getcompanies(),
    method: 'GET',
    data,
  })
}
