import React, { Component } from 'react'
import classNames from 'classnames'
import InputBlock from 'app/common/InputBlock'
import TextBox from 'app/common/TextBox'
import Button from 'app/common/Button'

export default class BulkAction extends Component {

    render() {
        return (
            <div className="bulk-remove-checkbox">
                <InputBlock
                    label={`Select All`}
                    isInline
                    className="list-header"
                >
                    <input
                        type="checkbox"
                        checked={this.props.checked}
                        onChange={evt => this.props.onChange(evt.target.checked)}
                        className="checkbox"
                    />
                </InputBlock>
                <Button
                    label="Remove"
                    isDestructive
                    disabled={this.props.disabled}
                    onClick={() => this.props.onClick()}
                />
            </div>
        )
    }

}