import React from 'react'

import {truncateText} from "app/utils"
import styles from './style.less'
import * as urls from "app/urls"
import PropTypes from "prop-types"
import Tooltip from 'app/common/Tooltip'


export default function DocumentFeedDisplay(props) {
  const {document, isGrouped = false} = {...props}
  const feedLabel = truncateText(document.feed.name, isGrouped ? 30 : 50)
  return (
    <span className={styles.feed}>
      {document.feed.id === 1 ? ( // Twitter
        <span className={styles.feed}>{feedLabel}</span>
      ) : (
        <a href={urls.feed(document.feed.id)} className={styles.feed}>
          {feedLabel}
        </a>
      )}
      {!isGrouped && (
        <React.Fragment>
          {document.feed.isProprietary && document.feed.credentials && (
            <span className={styles.credentials}>
              {truncateText(document.feed.credentials, 50)}
            </span>
          )}
          {document.feed.isSubscriptionRequired && (
            <span className={styles.subscriptionRequired}>
              &nbsp;(May Require Subscription)
            </span>
          )}
        </React.Fragment>
      )}
      {document.feed.editUrl && (
        <a href={document.feed.editUrl} className={styles.staff}>
          (edit feed)
        </a>
      )}
      {
       document.isNlaArticle && <Tooltip  label={'NLA Media Publisher'}>
       <span className={styles.subscriptionRequired}>&nbsp;(NLA)</span>
       </Tooltip>
      }
    </span>
  )
}
DocumentFeedDisplay.propTypes = {
  document: PropTypes.object.isRequired,
  isGrouped: PropTypes.bool,
}
