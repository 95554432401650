import {graphqlQuery} from 'app/api'
import {args, gql} from 'app/graphql'


export function fetchTopics() {
  return graphqlQuery(
    gql`
      query {
        topics: helpTopics() {
          id
          name
          url
          questions {
            question
            slug
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchSearchResults(searchTerm) {
  return graphqlQuery(
    gql`
      query {
        searchResults: helpSearch(${args({searchTerm})}) {
          topic {
            id
            name
            url
          }
          question {
            question
            slug
          }
          answerSnippet
        }
      }
    `
  ).then(({body: {data}}) => data)
}
