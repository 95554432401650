import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './TextSeparator.less'


const TextSeparator = ({className, ...restProps}) => (
  <span
    className={classNames(styles.separator, className)}
    {...restProps}
  />
)
TextSeparator.propTypes = {
  className: PropTypes.string,
}
export default TextSeparator
