import invariant from 'invariant'
import is from 'is'


export default class Orm {
  // For caching purposes
  static currentEntities = null
  static currentModelCache = null

  constructor(entities, modelCache = {}) {
    this.entities = entities
    this.modelCache = modelCache
  }

  /**
   * Returns a new instance of ORM with a shared modelCache for the given
   * entities object.
   */
  static withEntities(entities) {
    if (entities !== this.currentEntities) {
      this.currentEntities = entities
      this.currentModelCache = {}
    }
    return new Orm(entities, this.currentModelCache)
  }

  /**
   * Builds a Model instance with the given ID from the given entities
   * object. Automatically wires up other models with foreign keys
   */
  static getById(Model, id, entities, modelCache = {}) {
    return _getById(Model, id, entities, modelCache)
  }

  static getByIds(Model, ids, entities, modelCache = {}) {
    return ids.map(id => this.getById(Model, id, entities, modelCache))
  }

  /**
   * Updates the entities with changes to a specific model instance. This only
   * updates the fields that are provided; other fields are left as-is.
   */
  static updateById(Model, id, updates, entities) {
    const {entityKey} = Model
    const existingObj = entities[entityKey][id] || {}
    const newObj = {
      ...existingObj,
      ...updates,
    }
    entities[entityKey][id] = newObj
    _updateIndexes(Model, newObj, entities)
  }

  /**
   * Bulk update on multiple IDs.
   */
  static updateByIds(Model, updatesById, entities) {
    const ids = Object.keys(updatesById)
    ids.forEach(id => this.updateById(Model, id, updatesById[id], entities))
  }

  /**
   * Replace the entities with the data given.
   */
  static replaceById(Model, id, newObj, entities) {
    entities[Model.entityKey][id] = newObj
    _updateIndexes(Model, newObj, entities)
  }

  /**
   * Bulk replace on multiple IDs.
   */
  static replaceByIds(Model, updatesById, entities) {
    const ids = Object.keys(updatesById)
    ids.forEach(id => this.updateById(Model, id, updatesById[id], entities))
  }

  /**
   * Removes the entity with the given ID
   */
  static deleteById(Model, id, entities) {
    const {entityKey} = Model
    const existingObj = entities[entityKey][id]
    delete entities[entityKey][id]

    // Update indexes if needed
    if (Model.indexes) {
      _removeFromIndexes(Model, existingObj, entities)
    }
  }

  /**
   * Bulk delete on multiple IDs
   */
  static deleteByIds(Model, ids, entities) {
    ids.forEach(id => this.deleteById(Model, id, entities))
  }

  /**
   * Convenience methods for use with an ORM instance.
   */

  getById(Model, id) {
    return this.constructor.getById(
      Model,
      id,
      this.entities,
      this.modelCache,
    )
  }

  getByIds(Model, ids) {
    return this.constructor.getByIds(
      Model,
      ids,
      this.entities,
      this.modelCache,
    )
  }

  updateById(Model, id, updates) {
    return this.constructor.updateById(Model, id, updates, this.entities)
  }

  updateByIds(Model, updatesById) {
    return this.constructor.updateByIds(Model, updatesById, this.entities)
  }

  replaceById(Model, id, updates) {
    return this.constructor.replaceById(Model, id, updates, this.entities)
  }

  replaceByIds(Model, updatesById) {
    return this.constructor.replaceByIds(Model, updatesById, this.entities)
  }

  deleteById(Model, id) {
    return this.constructor.deleteById(Model, id, this.entities)
  }

  deleteByIds(Model, ids) {
    return this.constructor.deleteByIds(Model, ids, this.entities)
  }

  /**
   * Convenience wrapper for `updateByIds` that builds the updates
   * object automatically, so that you can just pass model instances
   * without having to juggle IDs.
   */
  updateMany(Model, updates) {
    const updatesById = updates.reduce((updatesById, obj) => {
      updatesById[obj.id] = obj
      return updatesById
    }, {})
    return this.updateByIds(Model, updatesById)
  }

  /**
   * Same as `updateMany`, but replaces the entities entirely.
   */
  replaceMany(Model, updates) {
    const updatesById = updates.reduce((updatesById, obj) => {
      updatesById[obj.id] = obj
      return updatesById
    }, {})
    return this.replaceByIds(Model, updatesById)
  }

  /**
   * Same as `updateMany`, but for deletes.
   */
  deleteMany(Model, objs) {
    const ids = objs.map(obj => obj.id)
    return this.deleteByIds(Model, ids)
  }
}


function _getById(Model, id, entities, modelCache = {}) {
  const {entityKey} = Model

  // We've already cached this model. This (hypothetically) prevents
  // infinite loops with recursive models.
  if (modelCache[entityKey] && modelCache[entityKey][id]) {
    return modelCache[entityKey][id]
  }

  const entity = entities[entityKey][id]
  if (!entity) return undefined

  return _instantiateModel(Model, entity, entities, modelCache)
}


function _instantiateModel(Model, obj, entities, modelCache) {
  const {entityKey} = Model
  const modelInstance = new Model(obj)

  // Cache this model instance
  modelCache[entityKey] = modelCache[entityKey] || {}
  modelCache[entityKey][obj.id] = modelInstance

  _loadForeignModels(modelInstance, entities, modelCache)

  // Since there's no reason to ever mutate a model instance once its
  // relationships have been loaded, we freeze the object to avoid
  // potential errors
  return Object.freeze(modelInstance)
}


function _loadForeignModels(modelInstance, entities, modelCache) {
  const Model = modelInstance.constructor

  Object.entries(Model.fields).forEach(([key, field]) => {

    if (field.__foreignKey) {

      if (field.__isArray) {
        const keyId = `${key}Ids`
        const ids = modelInstance[keyId]

        // The model has already validated all attributes at this point,
        // so if this is undefined, it means that it's not required and
        // we can just skip it
        if (typeof ids == 'undefined') return

        const ForeignModel = field.__foreignKey
        const foreignInstances =
          ids.map(id => _getById(ForeignModel, id, entities, modelCache))
        // TODO: Make sure none of the instances are undefined.
        invariant(
          foreignInstances.length === ids.length || !field.__isRequired,
          `The foreign key ID field ${keyId} on model ${Model.name} has values (${ids}), but ${ForeignModel.name} models with those IDs are missing.`,
        )
        modelInstance[key] = foreignInstances
      }

      else {
        const keyId = `${key}Id`
        const id = modelInstance[keyId]
        if (typeof id == 'undefined') return

        const ForeignModel = field.__foreignKey
        const foreignInstance = _getById(ForeignModel, id, entities, modelCache)
        invariant(
          foreignInstance || !field.__isRequired,
          `The foreign key ID field ${keyId} on model ${Model.name} has a value (${id}), but there is no ${ForeignModel.name} model with that ID.`,
        )
        modelInstance[key] = foreignInstance
      }
    }

    else if (field.__hasOne || field.__hasMany) {
      const ForeignModel = field.__hasOne || field.__hasMany
      const foreignKeyName =
        field.__foreignKeyField
        || _findReferencingForeignKey(Model, ForeignModel)
      const foreignModelInstances = _findModelInstancesByField(
        ForeignModel,
        entities,
        modelCache,
        foreignKeyName,
        modelInstance.id,
      )

      if (field.__hasOne) {
        invariant(
          foreignModelInstances[0] || !field.__isRequired,
          `The model ${Model.name} has a required field ${key} which hasOne(${ForeignModel.name}), but a ${ForeignModel.name} instance with ${foreignKeyName}Id == ${modelInstance.id} was not found.`,
        )
        modelInstance[key] = foreignModelInstances[0]
      } else {
        invariant(
          foreignModelInstances.length || !field.__isRequired,
          `The model ${Model.name} has a required field ${key} which hasMany(${ForeignModel.name}), but a ${ForeignModel.name} instance with ${foreignKeyName}Id == ${modelInstance.id} was not found.`,
        )
        modelInstance[key] = foreignModelInstances
      }
    }
  })
}


/*
 * Find an instance of the foreign model that points to this model with
 * a foreign key
 */
function _findReferencingForeignKey(Model, ForeignModel) {
  const foreignKeys = Object.entries(ForeignModel.fields)
    .filter(([_, field]) => field.__foreignKey === Model)
    // We just want the keys
    .map(([key, field]) => key)

  invariant(
    foreignKeys.length <= 1,
    `The model ${Model.name} expects a field on ${ForeignModel.name} that references it, but ${ForeignModel.name} has more than one foreign key to ${Model.name}. Specify a foreign key by setting the \`foreignKeyField\` option to a string containing the field name to use.`,
  )

  invariant(
    foreignKeys.length,
    `The model ${Model.name} expects a field on ${ForeignModel.name} that references it, but ${ForeignModel.name} does not have a foreign key to ${Model.name}.`,
  )

  return foreignKeys[0]
}


function _findModelInstancesByField(Model, entities, modelCache, field, value) {
  const {entityKey} = Model
  invariant(
    typeof entities[entityKey] != 'undefined',
    `Model ${Model.name} has entityKey '${entityKey}', but that key is missing in \`entities\`.`,
  )

  const idField = field + 'Id'
  let objs = []
  if (
    Model.indexes
    && Model.indexes.includes(field)
    && entities.indexes[entityKey]
  ) {
    objs = entities.indexes[entityKey][idField][value] || []
  } else {
    objs = Object.values(entities[entityKey])
      .filter(obj => obj[idField] === value)
  }

  return objs.map(obj => _instantiateModel(Model, obj, entities, modelCache))
}


function _updateIndexes(Model, newObj, entities) {
  const {entityKey, indexes} = Model
  if (!indexes) return

  indexes.forEach(key => {
    const modelField = Model.fields[key]
    if (modelField.__foreignKey) {
      key = `${key}Id`
    }

    const value = newObj[key]
    const entityIndexes = entities.indexes[entityKey]
    entityIndexes[key][value] = entityIndexes[key][value] || []
    const existingIndex = entityIndexes[key][value]
      .findIndex(obj => obj.id === newObj.id)

    if (existingIndex > -1) {
      entityIndexes[key][value][existingIndex] = newObj
    } else {
      entityIndexes[key][value].push(newObj)
    }
  })
}


function _removeFromIndexes(Model, oldObj, entities) {
  const {entityKey, indexes} = Model
  indexes.forEach(key => {
    const modelField = Model.fields[key]
    if (modelField.__foreignKey) {
      key = `${key}Id`
    }

    const value = oldObj[key]
    const entityIndexes = entities.indexes[entityKey]
    entityIndexes[key][value] = entityIndexes[key][value] || []
    const index = entityIndexes[key][value]
      .findIndex(obj => obj.id === oldObj.id)
    entityIndexes[key][value].splice(index, 1)
  })
}
