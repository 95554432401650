export const STATE_PROP_NAME = 'sources'

export const FILTER_FIELD_ALL = 'all'
export const FILTER_FIELD_URL = 'url'
export const FILTER_FIELD_NAME = 'name'
export const FILTER_FIELD_LABELS = 'labels'
export const FILTER_FIELDS = {
  [FILTER_FIELD_ALL]: 'All',
  [FILTER_FIELD_URL]: 'URL',
  [FILTER_FIELD_NAME]: 'Name',
  [FILTER_FIELD_LABELS]: 'Labels',
}
export const FILTER_FIELDS_WITHOUT_URL = {
  [FILTER_FIELD_ALL]: 'All',
  [FILTER_FIELD_NAME]: 'Name',
  [FILTER_FIELD_LABELS]: 'Labels',
}
export const FEED_SELECTION_LIMIT = 10
export const FEED_SELECTION_TIMEOUT = 2500
export const FEED_TYPE = {
  PROMOTED: 'promoted',
  TRUSTED: 'trusted',
  EXCLUDE: 'exclude',
  DEMOTED: 'demoted',
  TRUSTED_FOLDER: 'trusted-folder'
}

