import {handleActions} from 'redux-actions'
import * as actions from './comparison-actions'
import {TYPE_SEGMENT_CHART, TYPE_LINE_CHART, SHARE_CHART_TYPE_BAR} from "./comparison-constants"
import immerProduce from "immer"
import deepCopy from "deepcopy"
import {assocPath, mergeDeepLeft} from "ramda"


let produce = immerProduce
if (!window.Proxy) {
  // Hack alert! See app/root-reducer.js for an explanation of this. The only
  // difference here is we do a deep copy since we have nested objects.
  // TODO: Properly figure out the IE issue.
  function ieProduce(base, producer) {
    const newBase = deepCopy(base)
    producer(newBase)
    return newBase
  }
  produce = ieProduce
}

const getInitialState = () => ({
  showFirstTimePage: true,
  startNewComparison: true,
  activeComparisonId: null,
  newComparisonId: null,
  activeComparisonTitle: null,
  comparisonCategory: null,
  comparisonGroups: [],
  categorySearchIds: [],
  displayChartTypes: [TYPE_SEGMENT_CHART],
  timeFrame: '2w',
  defaultTimeFrame: '2w',
  selectedSearchIds: [],
  deleteComparison: {},
  isLoading: false,
  loadingSearchIds: [],
  activeSidebarSearchId: null,
  activeSidebarGroupTitle: null,
  comparisonDocumentIds: {},
  pendingComparison: {
    category: null,
    title: null,
    searchIds: [],
  },
  chartData: {
    line: null,
    pie: null,
  },
  activeFilterKey: null,
  fetchingFilterKey: false,
  activeFilterItems: [],
  isStaggeredLoading: false,
  cachedLargerTimeFrames: [],
  showEditComparisonModal: {
    show: false,
    group: {},
  },
  shareOfVoiceChartType: SHARE_CHART_TYPE_BAR
})


export default handleActions(
  {
    [actions.init]: (state, action) => {
      return {
        ...state,
        comparisonGroups: action.payload.comparisonGroups,
        categorySearchIds: action.payload.categorySearchIds,
        comparisonCategory: action.payload.comparisonCategory,
        defaultTimeFrame: action.payload.defaultTimeFrame,
      }
    },

    [actions.restoreStateFromHistory]: (state, action) => {
      let restoreState = {...action.payload}
      restoreState.comparisonDocumentIds = {}
      return {
        ...restoreState
      }
    },

    [actions.setBuildNewComparison]: (state, action) => {
      const {comparisonGroups, categorySearchIds, comparisonCategory, comparisonDocumentIds,
        defaultTimeFrame, cachedLargerTimeFrames} = state
      return {
        ...getInitialState(),
        comparisonGroups: comparisonGroups,
        categorySearchIds: categorySearchIds,
        comparisonCategory: comparisonCategory,
        comparisonDocumentIds: comparisonDocumentIds,
        defaultTimeFrame: defaultTimeFrame,
        cachedLargerTimeFrames: cachedLargerTimeFrames,
      }
    },

    [actions.setActiveComparison]: (state, action) => {
      const {activeComparisonId, selectedSearchIds, isLoading, activeComparisonTitle,
        startNewComparison, pendingComparison, activeFilterKey, activeFilterItems,
        timeFrame, displayChartTypes, shareOfVoiceChartType} = action.payload
      return {
        ...state,
        selectedSearchIds: selectedSearchIds,
        activeComparisonId: activeComparisonId,
        isLoading: isLoading,
        activeComparisonTitle: activeComparisonTitle,
        startNewComparison: startNewComparison,
        activeFilterKey: activeFilterKey,
        activeFilterItems: activeFilterItems,
        timeFrame: timeFrame || getInitialState().defaultTimeFrame,
        displayChartTypes: displayChartTypes,
        shareOfVoiceChartType: shareOfVoiceChartType,
        pendingComparison: pendingComparison,
      }
    },

    [actions.setSearchResults]: (state, action) => produce(
      state,
      newState => {
        newState.comparisonDocumentIds[action.payload.searchId] =
          action.payload.comparisonDocumentIds[action.payload.searchId]
      }
    ),
    [actions.setActiveSidebarNav]: (state, action) => {
      return {
        ...state,
        activeSidebarSearchId: action.payload.searchId,
        activeSidebarGroupTitle: action.payload.groupTitle,
      }
    },
    [actions.setActiveSidebarGroupTitle]: (state, action) => ({
      ...state,
      activeSidebarGroupTitle: action.payload,
    }),
    [actions.setDisplayChartTypes]: (state, action) => ({
      ...state,
      displayChartTypes: action.payload.displayChartTypes,
    }),
    [actions.setComparisonGroups]: (state, action) => ({
      ...state,
      comparisonGroups: action.payload.comparisonGroups,
    }),
    [actions.setNewComparisonId]: (state, action) => ({
      ...state,
      newComparisonId: action.payload,
      pendingComparison: getInitialState().pendingComparison,
    }),
    [actions.setShowFirstTimePage]: (state, action) => ({
      ...state,
      showFirstTimePage: action.payload,
    }),
    [actions.setSelectedSearchIds]: (state, action) => ({
      ...state,
      selectedSearchIds: action.payload,
    }),
    [actions.setDeleteComparison]: (state, action) => ({
      ...state,
      deleteComparison: action.payload,
    }),
    [actions.setNewComparisonTitle]: (state, action) => ({
      ...state,
      activeComparisonTitle: action.payload,
    }),
    [actions.setChartData]: (state, action) => {
      const {merge, chartType} = action.payload
      let {data} = action.payload
      if (merge && chartType === TYPE_LINE_CHART) {
        const currentLineChartData = deepCopy(state.chartData[chartType])
        const newInitMaxRange = Math.max.apply(Math, [currentLineChartData.initMaxRange,
          data.initMaxRange])

        const newSeriesData = []
        currentLineChartData.seriesData.forEach((dataPoint, idx) => {
          const mergedData = mergeDeepLeft(dataPoint, data.seriesData[idx])
          newSeriesData.push(mergedData)
        })

        const newSeriesKeys = mergeDeepLeft(currentLineChartData.seriesKeys, data.seriesKeys)
        data.initMaxRange = newInitMaxRange
        data.seriesData = newSeriesData
        data.seriesKeys = newSeriesKeys
      }
      return assocPath(
        ['chartData', chartType],
        data,
        state,
      )
    },
    [actions.initChartData]: (state, action) => ({
      ...state,
      chartData: getInitialState().chartData,
    }),
    [actions.setLoadingSearchIds]: (state, action) => ({
      ...state,
      loadingSearchIds: action.payload,
    }),
    [actions.refreshComparisonInit]: (state, action) => ({
      ...state,
      timeFrame: action.payload.timeFrame,
      activeFilterKey: action.payload.activeFilterKey,
      activeFilterItems: action.payload.activeFilterItems,
      loadingSearchIds: action.payload.loadingSearchIds,
      chartData: getInitialState().chartData,
    }),
    [actions.setFetchingFilterKey]: (state, action) => ({
      ...state,
      fetchingFilterKey: action.payload,
    }),
    [actions.setActiveFilterItems]: (state, action) => {
      return {
        ...state,
        activeFilterItems: action.payload,
      }
    },
    [actions.setIsStaggeredLoading]: (state, action) => ({
      ...state,
      isStaggeredLoading: action.payload,
    }),
    [actions.setShowEditComparisonModal]: (state, action) => ({
      ...state,
      showEditComparisonModal: action.payload,
    }),
    [actions.setShareOfVoiceChartType]: (state, action) => ({
      ...state,
      shareOfVoiceChartType: action.payload,
    }),
    [actions.setCachedLargerTimeFrames]: (state, action) => ({
      ...state,
      cachedLargerTimeFrames: action.payload,
    }),
  },

  getInitialState(),
)
