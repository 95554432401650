import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'

import store from 'app/store'

import ModalsContainer from './ModalsContainer'


export function initModals() {
  document.addEventListener('DOMContentLoaded', () => {
    const container = document.getElementById('global-modals')
    if (container) {
      const component =
        <Provider store={store}>
          <ModalsContainer />
        </Provider>
      render(component, container)
    }
  })
}
