import {changeCaseObject} from 'app/utils'
import React from 'react'
import { render } from 'react-dom'
import store from 'app/store'

import SourcesAdmin from './Container'
import * as constants from './sources-admin-constants'
import reducer from './sources-admin-reducer'
import saga from './sources-admin-saga'
import {init as initSourcesAdmin} from './sources-admin-actions'

import './style.less'
import AppWrapper from "app/global/app-wrapper"


export function init(data) {
  data = changeCaseObject.camelCase(data)
  store.dispatch(initSourcesAdmin(data))
  const component =
    <AppWrapper>
      <SourcesAdmin/>
    </AppWrapper>
  const container = document.getElementById('sources-admin-wrapper')
  render(component, container)
}

export {SourcesAdmin, constants, reducer, saga}
