import * as profileBuilderApi from './profile-builder-api'
import * as profileBuilderActions from './profile-builder-actions'
import { all, takeLatest, call, put, delay } from 'redux-saga/effects'
import { extractErrorMessage } from 'app/utils/errors'
import * as notifications from 'app/global/notifications'


function* hideMessage() {
  yield delay(5000)
  yield put(profileBuilderActions.hideMessage())
}

function* handleFetchSearches(action) {
  yield delay(200)
  const { authors, category } = action.payload
  try {
    const response = yield(profileBuilderApi.fetchSearches({authors, categories: [category]}))
    const searches = response.data.items.map(item => item.search)
    yield put(profileBuilderActions.showBrowseModal({searches, category}))
  } catch(e) {
    yield put(
      notifications.actions.showNotification({
        type: 'error',
        message: extractErrorMessage(error),
      }),
    )
  }
}

function* handleSaveUser(action) {
  try {
    yield profileBuilderApi.saveUser(action.payload)
    yield put(profileBuilderActions.showMessage({
      type: 'success',
      text: 'User saved successfully.',
    }))
    yield put(profileBuilderActions.saveUserSuccess())
  } catch(error) {
    yield put(profileBuilderActions.showMessage({
      type: 'error',
      text: extractErrorMessage(error),
    }))
  }
}

function* handleSkip(action) {
  try {
    yield profileBuilderApi.saveUser({id: action.payload, forceProfileBuilder: false})
    window.location.href = '/profile'
  } catch(error) {
    yield put(profileBuilderActions.showMessage({
      type: 'error',
      text: extractErrorMessage(error),
    }))
  }
}

function* handleShowMessage(action) {
  yield call(hideMessage)
}

export default function*() {
  yield all([
    takeLatest(
      profileBuilderActions.showMessage,
      handleShowMessage,
    ),
    takeLatest(
      profileBuilderActions.fetchSearches,
      handleFetchSearches,
    ),
    takeLatest(
      profileBuilderActions.saveUser,
      handleSaveUser,
    ),
    takeLatest(
      profileBuilderActions.skip,
      handleSkip,
    ),
  ])
}
