import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from 'app/common/Button'

import {formatDate} from './utils'


export default class SampleSearchResults extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    search: PropTypes.object.isRequired,
    searchResults: PropTypes.object.isRequired,
    setCurrentView: PropTypes.func.isRequired,
  }

  state = {
    displayedGroupInfoArticleId: null,
  }

  render() {
    const {isLoading, searchResults} = this.props

    const haveDocuments = searchResults.documents && searchResults.documents.length > 0

    const descriptionClickText = this.props.search
      ? '"Save"'
      : '"Next Step"'

    const descriptionText = `This is a sample of the results that you will receive from your search query. Click 
      "Return to Search" and then click ${descriptionClickText} if you are happy with your results.`

    const description = !isLoading && haveDocuments &&
      <p className="sample-results-description">{descriptionText}</p>

    const headerText =
      isLoading
        ? 'Running search...'
        : haveDocuments
          ? 'Sample Search Results'
          : 'No Results Found'

    const headerContainer =
      <div className="sample-results-header-container">
        <div className="sample-results-header">
          <h3 className="sample-results-title">{headerText}</h3>

          <div>
            <Button
              label="&lt;&lt;Return to Search"
              isPlainText={true}
              onClick={() => this.props.setCurrentView('build')}
            />
          </div>
        </div>

        {description}
      </div>

    const resultsList = !isLoading && haveDocuments &&
      <React.Fragment>
        <div className="article-list-container">
          <div className="sample-results-total">
            {searchResults.resultCount} Total Results over the last 6 months.
          </div>

          <ul className="article-list">
            {searchResults.documents.map((article, index) => {
              if (index > 24) {
                return null
              }

              const isGroupInfoShown = article.id === this.state.displayedGroupInfoArticleId

              return (
                <li key={article.id} className="article article-headline-container">
                  <div className="article-details">
                    <div className="article-meta-wrapper">
                      <div>
                        <a href={article.link} target="_blank" title={article.headline}
                           className="title">{article.headline}</a>&nbsp;
                      </div>

                      <div className="meta">
                        <span className="date">{formatDate(article.publishedAt)}</span> via {article.feed.name}
                      </div>

                      <div className="description" dangerouslySetInnerHTML={{__html: article.snippet}}/>
                    </div>
                  </div>
                  {
                    article.groupCount &&
                    <div className="group-info">
                      <a href="#" className="group-info-toggle" onClick={() => this.toggleGroupInfoArticles(article.id)}>
                        <i className={classNames('fa', 'fa-fw', {'fa-angle-right': !isGroupInfoShown, 'fa-angle-down': isGroupInfoShown})}/>
                        <img src="/media/img/grouping-icon-01.png"/>
                        {article.groupCount} similar article(s)
                      </a>
                      {
                        isGroupInfoShown &&
                        <div className="group-info-articles">
                        {article.groupDocs.map(doc => {
                          return (
                            <div key={article.id + '-' + doc.id} className="group-article">
                              <span>
                                <a href={doc.link} target="_blank" title={doc.headline}>
                                  <span>{this.truncateString(doc.headline, 42)}</span>
                                </a>
                              </span>

                              <span className="feed-link">
                                <a href={'/feed_display/' + doc.feed.id} target="_blank">{doc.feed.name}</a>
                              </span>
                            </div>
                          )
                        })}
                      </div>
                      }
                    </div>
                  }
                </li>
              )
            })}
          </ul>
        </div>

        <div className="sample-results-bottom-return">
          <div>
            <Button
              label="&lt;&lt;Return to Search"
              isPlainText={true}
              onClick={() => this.props.setCurrentView('build')}
            />
          </div>
        </div>
      </React.Fragment>

    return (
      <div className="sample-results-container">
        {headerContainer}
        {resultsList}
      </div>
    )
  }

  toggleGroupInfoArticles(articleId) {
    const {displayedGroupInfoArticleId} = this.state
    const newValue = articleId === displayedGroupInfoArticleId ? null : articleId
    this.setState({displayedGroupInfoArticleId: newValue})
  }

  truncateString = (string, length) => {
    if (string.length > length) {
      return string.substr(0, length) + ' ...'
    }
    return string
  }
}
