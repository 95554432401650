import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Loader from '../LoadingSpinner'

import './style.less'

const TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  LOADING: 'loading',
}


export default class Message extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    type: PropTypes.string,
  }

  static defaultProps = {
    type: TYPES.SUCCESS,
  }

  render() {
    const {text, type} = this.props
    const contents = type == TYPES.LOADING
      ? (
        <div>
          <Loader />
          <div className="text">
            <p>{text}</p>
          </div>
        </div>
      )
      : text
    return (
      // The 'new' classname is temporary as we transition the existing
      // messages throughout the system
      <div className={classNames('message', 'new', type)}>{contents}</div>
    )
  }
}

Message.TYPES = TYPES
