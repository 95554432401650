import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import HealthScore from 'app/common/HealthScore'
import * as strings from 'app/strings'

import * as styles from './SubfactorHealthCard.less'

const SubfactorHealthCard = ({healthData, subfactor, className}) => {
  const factorHealth = healthData.subfactors[subfactor] || {}
  const healthScore = factorHealth.healthScore

  return (
    <div className={classNames(styles.subfactorHealthCard, className)}>
      <div className={styles.header}>
        {strings.subfactorDisplayName(subfactor)}
        <HealthScore score={healthScore} className={styles.healthScore} />
      </div>
    </div>
  )
}
SubfactorHealthCard.propTypes = {
  healthData: PropTypes.object.isRequired,
  subfactor: PropTypes.string.isRequired,
  className: PropTypes.string,
}
export default SubfactorHealthCard
