import {all, takeEvery, takeLatest, put} from 'redux-saga/effects'
import { handleSagaError } from 'app/utils/errors'
import * as actions from './django-interaction-bar-actions'
import * as entitiesActions from "../entities/entities-actions"
import * as api from "./django-interaction-bar-api"
import * as profileSourcesActions from "app/profile/sources/profile-sources-actions"


function* handleInitPromotedAndExcludedFeeds() {
  yield put(profileSourcesActions.fetchPromotedFeeds())
  yield put(profileSourcesActions.fetchExcludedFeeds())
  yield put(actions.fetchPromotedAndExcludedFeedsComplete(true))
}

function* handleInitBar(action) {
  const {feedId, documentId, flaggedIds} = action.payload
  if (!feedId || !documentId) {return}

  try {
    const response = yield api.fetchFeedsByIds([feedId])
    const feedEntities = {}
    response.feeds.items.forEach(f => {
      feedEntities[f.id] = f
    })
    yield put(entitiesActions.update({feeds: feedEntities}))
  } catch (error) {
    yield* handleSagaError(error)
  }

  try {
    const response = yield api.fetchDocuments([documentId])
    const documentEntities = {}
    response.documents.forEach(d => {
      documentEntities[d.id] = d
    })
    yield put(entitiesActions.update({documents: documentEntities}))
  } catch (error) {
    yield* handleSagaError(error)
  }

  yield put(actions.initBarComplete({
    documentIds: [documentId],
    feedIds: [feedId],
    flaggedIds: flaggedIds
  }))

}

export default function*() {
  yield all([
    takeEvery(
      actions.initBar,
      handleInitBar,
    ),
    takeLatest(
      actions.initPromotedAndExcludedFeeds,
      handleInitPromotedAndExcludedFeeds,
    ),
  ])
}
