import React from 'react'


export default ({onModalClose}) => (
  <div>
    <p>Base can import content from websites if the content is structured as a list of article headlines with links. Contact support with the website that you want to import to get started.</p>

    <p><a href="/media/img/screenshot-add-source-website.png" target="_blank">Click here to see an example.</a></p>

    <div className="buttons left">
      <a
        className="contact-content-team button"
        href="mailto:content@manzama.com"
      >
        Contact Support
      </a>
      <a className="cancel button" onClick={onModalClose}>
        Cancel
      </a>
    </div>
  </div>
)
