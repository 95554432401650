import {createAction} from 'redux-actions'

const PREFIX = 'DJANGO_INTERACTION_BAR'
const action = name => createAction(`${PREFIX}_${name}`)

export const initBar = action('INIT_INTERACTION_BAR')
export const initPromotedAndExcludedFeeds = action('INIT_PROMOTED_AND_EXCLUDED_FEEDS')
export const fetchPromotedAndExcludedFeedsComplete =
  action('FETCH_PROMOTED_AND_EXCLUDED_FEEDS_COMPLETE')
export const flaggedIdsInitializedComplete = action('FLAGGED_IDS_INITIALIZED_COMPLETE')
export const initBarComplete = action('INIT_INTERACTION_BAR_COMPLETE')
