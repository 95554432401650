import classNames from 'classnames'
import React, {useEffect, useState} from 'react'
import {Portal} from 'react-portal'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import Orm from 'app/framework/Orm'
import {Document, Feed, SavedSearch, User} from 'app/models'
import * as selectors from './comparison-selectors'
import * as styles from './Comparison.less'
import * as loadingStyles from './ComparisonLoading.less'
import * as entitiesActions from 'app/entities/entities-actions'
import { getEntities } from 'app/entities/entities-selectors'
import ComparisonSidebar from "./comparison-sidebar/ComparisonSidebar"
import ComparisonNarrow from "./comparison-narrow/ComparisonNarrow"
import ComparisonSearchList from "./comparison-search-list/ComparisonSearchList"
import { getCurrentUserId, getCurrentFirmLibraryName,
  getCurrentFirmShowAlternateDateFormat } from 'app/global/global-selectors'
import { activeSidebarNavChange, chartPointClick, setShowFirstTimePage, setSelectedSearchIds,
  setNewComparisonTitle, timeFrameChange, setDeleteComparison, deleteComparisonSearchGroup,
  removeDocumentsForExcludedFeedId, setActiveComparison, setBuildNewComparison,
  setDisplayChartTypes, saveComparisonSearchGroup, setShareOfVoiceChartType,
  restoreStateFromHistory} from "app/comparison-page/comparison-actions"
import MzLineChart from "app/common/charts/MzLineChart"
import MzBarChart from "app/common/charts/MzBarChart"
import LoadingSpinner from "app/common/LoadingSpinner"
import TextBox from "app/common/TextBox"
import {TYPE_SEGMENT_CHART, TYPE_LINE_CHART, SHARE_CHART_TYPE_BAR, SHARE_CHART_TYPE_PIE}
  from "./comparison-constants"
import Button from "app/common/Button"
import TokenInput from "app/common/TokenInput"
import {titleCase} from 'title-case'
import * as dateFns from 'date-fns'
import TimeFrameSelector from 'app/search-results-page/time-frame-selector'
import ConfirmationModal from "app/common/modals/ConfirmationModal"
import {fromPairs, map, pipe} from "ramda"
import Tooltip from "app/common/Tooltip"
import {shouldDisableLineChart} from 'app/comparison-page/comparison-utils'
import ComparisonEditModal from "./comparison-edit-modal/ComparisonEditModal"
import ComparisonSearchSelect from "./comparison-search-select"
import InputBlock from "app/common/InputBlock"
import MzPieChart from "app/common/charts/MzPieChart"


// The distance you need to scroll before the "Back to Top" button appears.
const BACK_TO_TOP_SCROLL_THRESHOLD = 700


function BackToTopLink() {
  const [shouldShowBackToTop, setShouldShowBackToTop] = useState(false)
  const onScroll = event => {
    const el = event.target

    if (!shouldShowBackToTop && el.scrollTop > BACK_TO_TOP_SCROLL_THRESHOLD) {
      setShouldShowBackToTop(true)
    }
    else if (
      shouldShowBackToTop
      && el.scrollTop <= BACK_TO_TOP_SCROLL_THRESHOLD
    ) {
      setShouldShowBackToTop(false)
    }
  }
  useEffect(
    () => {
      document
        .getElementById('body')
        .addEventListener('scroll', onScroll)
      return () => {
        document
          .getElementById('body')
          .removeEventListener('scroll', onScroll)
      }
    },
    [shouldShowBackToTop],
  )
  const goToTop = () => {
    document.getElementById('body').scrollTo(0, 0)
  }
  return (
    <Portal>
      <div
        className={classNames(
          styles.backToTop,
          {[styles.visible]: shouldShowBackToTop},
        )}
        onClick={goToTop}
      >
        Back to Top
      </div>
    </Portal>
  )
}


class Comparison extends React.Component {

  state = {
    filterSearchText: '',
  }

  componentDidMount() {
    document
      .getElementById('body')
      .addEventListener('scroll', this.handleScroll)

    window.onbeforeunload = () => {
      history.pushState(this.props.currentComparisonState, '', window.location.href)
    }

    if (history.state && 'activeComparisonTitle' in history.state) {
      this.props.restoreStateFromHistory(history.state)
      window.history.pushState(null, '', window.location.href)
    }

  }

  componentWillUnmount() {
    document
      .getElementById('body')
      .removeEventListener('scroll', this.handleScroll)
  }

  render() {
    const {
      pendingComparison,
      isLoading,
      categorySearches,
      comparisonGroups,
      comparisonCategory,
      timeFrame,
      showAlternateDateFormat,
      displayChartTypes,
      chartData,
      showFirstTimePage,
      selectedSearchIds,
      deleteComparison,
      startNewComparison,
      activeComparisonTitle,
      isStaggeredLoading,
      cachedLargerTimeFrames,
      showEditComparisonModal,
      displayCategory,
      displaySingularCategory,
      shareOfVoiceChartType,

      // Actions
      activeNavChange,
      updateDocuments,
      updateFeeds,
      removeDocumentsForExcludedFeed,
      timeFrameChange,

    } = this.props

    const hasComparisonGroups = comparisonGroups && comparisonGroups.length > 0

    const pendingSearches = categorySearches
      .filter(ss => selectedSearchIds.includes(ss.id)).map(ss => {
        return ({
          label: ss.name,
          searchId: ss.id,
          })
        })

    const pendingSearchTokens =
      <TokenInput
        disableInput={true}
        tokenItems={pendingSearches}
        updateTokensFunc={(token) => this.removePendingSearch(token.searchId)}
      />

    const addMoreSearches =
      <Button
        label={`Add ${displayCategory}`}
        isPrimary={false}
        onClick={() => this.handleAddNewSearchOnClick(comparisonCategory)}
      />

    const renderShareOfVoiceChart = shareOfVoiceChartType === SHARE_CHART_TYPE_BAR
      ? <MzBarChart
          chartData={chartData.pie}
          className={styles.comparisonChartWrapper}
          chartMargin={{top: 40, right: 40, bottom: 20, left: 0}}
        />
      : <MzPieChart
          chartData={chartData.pie}
          className={styles.comparisonChartWrapper}
          responsiveClassMaxWidth={false}
        />

    const shareOfVoiceChart = chartData.pie
      ? <div className={styles.shareOfVoiceChartWrapper}>
          <div className={styles.shareOfVoiceChartType}>
            <div className={styles.chartTypeText}>
              Chart Type
            </div>
            <InputBlock
              isInline={true}
              label={'Bar'}
            >
              <input
                checked={shareOfVoiceChartType === SHARE_CHART_TYPE_BAR}
                type="radio"
                onChange={() => {this.handleShareOfVoiceChartTypeOnChange()}}
              />
            </InputBlock>
            <InputBlock
              isInline={true}
              label={'Pie'}
            >
              <input
                checked={shareOfVoiceChartType === SHARE_CHART_TYPE_PIE}
                type="radio"
                onChange={() => {this.handleShareOfVoiceChartTypeOnChange()}}
              />
            </InputBlock>
          </div>
          {renderShareOfVoiceChart}
        </div>
      : <div
          className={classNames(styles.loading,)}
        >
          <LoadingSpinner className={styles.spinner} />
          Loading Share of Voice Chart...
        </div>

    const shouldLineChartBeDisabled = shouldDisableLineChart(timeFrame,
      cachedLargerTimeFrames, selectedSearchIds)

    const timeLineChart = chartData.line && !shouldLineChartBeDisabled
      ? <MzLineChart
          chartData={chartData.line}
          className={styles.comparisonChartWrapper}
          onChartClickFunc={(clickData) =>
            this.handleChartPointOnClick(clickData, comparisonCategory)}
          onClickType={'plot'}
          chartMargin={{top: 40, right: 40, bottom: 20, left: 0}}
          isStaggeredLoading={isStaggeredLoading}
        />
      : !shouldLineChartBeDisabled
        ? <div
            className={classNames(styles.loading,)}
          >
            <LoadingSpinner className={styles.spinner} />
            Loading Timeline Chart...
          </div>
        : null

    const comparisonHeader = startNewComparison
      ? <div className={styles.comparisonDashboardHeaderWrapper}>
          <div className={styles.comparisonDashboardHeader}>
            {displaySingularCategory} Dashboard
          </div>
        </div>
      : <div className={styles.comparisonDashboardHeaderWrapper}>
          <div className={styles.comparisonDashboardHeader}>
            {displaySingularCategory} Dashboard
            { !hasComparisonGroups &&
              <div className={styles.upperButtonWrapper}>
                <Button
                  className={styles.buildNewComparison}
                  label={'New Comparison'}
                  isPrimary={false}
                  onClick={() => this.handleBuildNewComparisonOnClick()}
                />
                <Button
                  className={styles.saveButton}
                  label={'Save Comparison'}
                  isPrimary={true}
                  onClick={() => this.props.saveComparisonSearchGroup(pendingComparison)}
                />
              </div>
            }
          </div>
        </div>

    const hasCategorySearches = categorySearches && categorySearches.length > 1
    const newComparison = (hasComparisonGroups || !showFirstTimePage) && startNewComparison
      ? <React.Fragment>
          <div className={styles.newComparison}>
            {`New ${displaySingularCategory} Comparison`}
          </div>

          {hasCategorySearches
            ? <React.Fragment>
                <div className={styles.activeComparisonTitle}>
                  <div className={styles.titleLabel}>Title</div>
                  <TextBox
                    autoFocus={true}
                    className={styles.titleInput}
                    placeholder="New Comparison Title..."
                    onChange={(e) => this.handleNewComparisonTitleOnChange(e.target.value)}
                  />
                </div>
                <div className={styles.sectionHeader}>
                  {`Select the ${displayCategory} you would like to 
                compare or add new ${displayCategory} to your profile`}
                </div>
                <div className={styles.newComparisonSelectSearch}>
                  <ComparisonSearchSelect
                    handleOnSearchClick={(id) => this.handleSelectSearchRowClick(id)}
                    selectedSearchIds={selectedSearchIds}
                  />
                  <div className={styles.searchesToCompareContainer}>
                    {selectedSearchIds.length > 0 &&
                      <div className={styles.tokenizedContainer}>
                        <div className={classNames(styles.searchesToCompareHeader,)}>
                          {`Selected ${displayCategory} to compare`}
                        </div>
                        <div className={styles.searchesToCompare}>
                          {pendingSearchTokens}
                        </div>
                      </div>
                    }
                    <div className={styles.innerButtonWrapper}>
                      <div>
                        {addMoreSearches}
                      </div>
                      <div>
                        <Button
                          className={styles.buttonPadding}
                          label={'Compare'}
                          disabled={selectedSearchIds.length < 2 || isLoading}
                          isPrimary={true}
                          onClick={() => this.handleCompareOnClick(pendingSearches)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            : <div>
                <span className={styles.noSearches}>
                  {`There are no ${displayCategory} 
                  saved in your profile.`}
                </span>
                {addMoreSearches}
              </div>
          }
        </React.Fragment>

      : <React.Fragment>
          {comparisonHeader}
          { !startNewComparison
            ? <React.Fragment>
                <div className={styles.comparisonTitle} id={activeComparisonTitle}>
                  {titleCase(activeComparisonTitle)}
                </div>
                <div className={styles.resultsOptionsContainer}>
                  <div className={styles.optionsContainer}>

                    {/*chart options*/}
                    <div className={styles.chartOptions}>
                      <div
                        className={styles.shareOfVoiceOption}
                        // Pie and Bar charts use the same data
                        onClick={() => this.handleChartOptionOnClick(TYPE_SEGMENT_CHART)}
                      >
                        <input
                          className="share-of-voice-checkbox"
                          type="checkbox"
                          checked={displayChartTypes.includes(TYPE_SEGMENT_CHART)}
                          readOnly={true}
                        />
                        <span className={styles.chartOptionLabel}>
                          Share of Voice
                          <span className={styles.iconContainer}>
                            <Tooltip label="Plots each search's volume, allowing you to see how
                              they compare to other searches and see what their volume is as a
                              percentage of all the searches' volume included in the comparison."
                            >
                              <a className="tooltip help"/>
                            </Tooltip>
                          </span>
                        </span>
                      </div>
                      <div
                        className={classNames(
                          styles.timelineOption,
                          {[styles.lineChartDisabled]: shouldLineChartBeDisabled},
                        )}
                        onClick={() => this.handleChartOptionOnClick(TYPE_LINE_CHART)}
                      >
                        <input
                          className="timeline-chart-checkbox"
                          type="checkbox"
                          checked={displayChartTypes.includes(TYPE_LINE_CHART)}
                          readOnly={true}
                          disabled={shouldLineChartBeDisabled}
                        />
                        <span
                          className={classNames(
                            styles.chartOptionLabel,
                            {[styles.lineChartDisabled]: shouldLineChartBeDisabled},
                          )}
                        >
                          Timeline Chart
                          <span className={styles.iconContainer}>
                            <Tooltip label='Plots the number of articles for each search in your
                              comparison.'
                            >
                              <a className="tooltip help"/>
                            </Tooltip>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className={styles.timeFrameLabel}>Time Range</div>
                    <div className={styles.timeFrameOption}>
                      <TimeFrameSelector
                        value={timeFrame}
                        onChange={timeFrameChange}
                        className={styles.timeFrameSelector}
                      />
                    </div>
                  </div>

                  <div className={styles.narrowByContainer}>
                    <ComparisonNarrow/>
                  </div>
                </div>
                <div
                  className={classNames(
                    styles.chartWrapper,
                    {[styles.showBorder]: displayChartTypes.includes(TYPE_SEGMENT_CHART)},
                  )}
                >
                  { displayChartTypes.includes(TYPE_SEGMENT_CHART)
                    ? shareOfVoiceChart
                    : null
                  }
                </div>

                <div
                  className={classNames(
                    styles.chartWrapper,
                    {[styles.showBorder]: displayChartTypes.includes(TYPE_LINE_CHART)},
                  )}
                >
                  { displayChartTypes.includes(TYPE_LINE_CHART)
                    ? timeLineChart
                    : null
                  }
                </div>

                <ComparisonSearchList
                  activeNavChange={activeNavChange}
                  showAlternateDateFormat={showAlternateDateFormat}
                  updateDocuments={updateDocuments}
                  updateFeeds={updateFeeds}
                  removeDocumentsForExcludedFeed={removeDocumentsForExcludedFeed}
                  viewMoreOnClickFunc={(evt, searchHref) =>
                    this.handleViewMoreOnClick(evt, searchHref
                  )}
                />
                <BackToTopLink
                  isLoading={isLoading}
                />
              </React.Fragment>
            : <React.Fragment>
                <div>
                  Build comparisons of your <b> {displayCategory} </b>
                  by selecting the desired saved searches and see how they compare. For future and
                  repeat viewing, save the Comparison. If only a one time view, you can discard
                  without saving.
                </div>
                <div
                  className={classNames(
                    styles.buttonWrapper,
                    {[styles.startNewComparison]: startNewComparison},
                  )}
                >
                  {addMoreSearches}
                  <Button
                    className={styles.buttonPadding}
                    label={'New Comparison'}
                    isPrimary={true}
                    onClick={() => this.handleNewComparisonOnClick()}
                  />
                </div>
                <div className={styles.examplesWrapper}>
                  <div className={styles.imageContainer}>
                    <div className={styles.sampleBarImage}/>
                  </div>
                  <div className={styles.imageContainer}>
                    <div className={styles.sampleLineImage}/>
                  </div>
                </div>
              </React.Fragment>
          }
        </React.Fragment>

    return (
      <div
        className={classNames(
          styles.comparisonPage,
          loadingStyles.skeleton,
          'comparison-page', // for Pendo
        )}
      >
        <div
          className={classNames(
            styles.comparisonWrapper,
            {[styles.startNewComparison]: startNewComparison},
          )}
        >
          {/*sidebar nav*/}
          <div
            className={classNames(
              styles.sidebarContainer,
              {[styles.hasGroups]: hasComparisonGroups},
              'comparison-sidebar', // for Pendo
            )}
            id={'comparisonSidebarNav'}
          >
            { hasComparisonGroups
              ? <ComparisonSidebar
                  activeNavChange={activeNavChange}
                />
              : null
            }
          </div>

          {/*comparison body loading/new/content */}
          <div
            className={classNames(
              styles.comparisonBody,
              'comparison-body', // for Pendo
            )}
          >
            {newComparison}
          </div>
        </div>

        {deleteComparison && deleteComparison.comparisonGroupId &&
          <ConfirmationModal
            message={`Are you sure you want to delete your ${deleteComparison.title} comparison?`}
            confirmButtonText="Delete"
            onConfirm={() => this.props.deleteComparisonSearchGroup(deleteComparison)}
            onClose={() => this.props.setDeleteComparison({})}
            isDestructive={true}
          />
        }
        {showEditComparisonModal && showEditComparisonModal.show &&
          <ComparisonEditModal
            comparisonGroup={showEditComparisonModal.group}
          />
        }
      </div>
    )
  }

  handleViewMoreOnClick(evt, searchHref){
    evt.preventDefault()
    history.pushState(this.props.currentComparisonState, '', window.location.href)
    window.location.href = searchHref
  }

  handleChartOptionOnClick(chartType) {
    let displayChartTypes = [...this.props.displayChartTypes]
    let fetchData = true
    if (displayChartTypes.includes(chartType)) {
      displayChartTypes = displayChartTypes.filter(ct => ct !== chartType)
      fetchData = false
    } else {
      displayChartTypes.push(chartType)
    }
    this.props.setDisplayChartTypes({displayChartTypes, chartType, fetchData})
  }
  handleChartPointOnClick(clickData, category) {
    const {plotPk, plotDateString} = clickData
    this.props.chartPointClick({category: category, searchId: plotPk, dateString: plotDateString})
  }
  handleAddNewSearchOnClick(comparisonCategory) {
    window.location.href = `/profile/${comparisonCategory}/`
  }
  handleBuildNewComparisonOnClick() {
    this.props.setBuildNewComparison()
  }
  handleNewComparisonOnClick() {
    this.props.setShowFirstTimePage(false)
  }
  handleNewComparisonTitleOnChange(string) {
    this.props.setNewComparisonTitle(string)
  }
  handleSelectSearchRowClick(searchId) {
    let selectedSearchIds = [...this.props.selectedSearchIds]
    if (selectedSearchIds.includes(searchId)) {
      selectedSearchIds = selectedSearchIds.filter(id => id !== searchId)
    } else {
      selectedSearchIds.push(searchId)
    }
    this.props.setSelectedSearchIds(selectedSearchIds)
  }
  removePendingSearch(searchId) {
    let selectedSearchIds = [...this.props.selectedSearchIds]
    selectedSearchIds = selectedSearchIds.filter(id => id !== searchId)
    this.props.setSelectedSearchIds(selectedSearchIds)
  }
  handleCompareOnClick(pendingSearches) {
    let {activeComparisonTitle, comparisonCategory, defaultTimeFrame} = this.props
    if (activeComparisonTitle === null) {
      const dateTime = dateFns.format(new Date(), 'yyyy-MMM-dd HH:mm:ss')
      const searchNames = pendingSearches.map(ss => ss.label).join(', ')
      activeComparisonTitle = `Comparison: ${searchNames} -- ${dateTime}`
    }
    const pendingSearchIds = pendingSearches.map(ss => ss.searchId)
    activeComparisonTitle = titleCase(activeComparisonTitle)
    this.props.setActiveComparison({
      selectedSearchIds: pendingSearchIds,
      activeComparisonId: null,
      isLoading: true,
      activeComparisonTitle: activeComparisonTitle,
      startNewComparison: false,
      activeFilterKey: null,
      activeFilterItems: [],
      timeFrame: defaultTimeFrame,
      displayChartTypes: [TYPE_SEGMENT_CHART],
      shareOfVoiceChartType: SHARE_CHART_TYPE_BAR,
      pendingComparison: {
        category: comparisonCategory,
        title: activeComparisonTitle,
        searchIds: pendingSearchIds,
      }
    })
  }
  handleShareOfVoiceChartTypeOnChange() {
    const chartType = this.props.shareOfVoiceChartType === SHARE_CHART_TYPE_BAR
      ? SHARE_CHART_TYPE_PIE
      : SHARE_CHART_TYPE_BAR
    this.props.setShareOfVoiceChartType(chartType)
  }
}
export default connect(
  createSelector(
    [
      selectors.getComparisonState,
      getCurrentFirmShowAlternateDateFormat,
      getCurrentFirmLibraryName,
      getEntities,
      getCurrentUserId,
      selectors.getDisplayCategory,
      selectors.getSingularDisplayCategory,
    ],
    (
      comparisonState,
      showAlternateDateFormat,
      firmLibraryName,
      entities,
      currentUserId,
      displayCategory,
      displaySingularCategory,
    ) => {
      const orm = Orm.withEntities(entities)
      const searches = (ssIds) => {return(orm.getByIds(SavedSearch, ssIds))}
      const categorySearches = searches(comparisonState.categorySearchIds)
      return {
        comparisonGroups: comparisonState.comparisonGroups,
        comparisonCategory: comparisonState.comparisonCategory,
        timeFrame: comparisonState.timeFrame,
        defaultTimeFrame: comparisonState.defaultTimeFrame,
        showFirstTimePage: comparisonState.showFirstTimePage,
        displayChartTypes: comparisonState.displayChartTypes,
        selectedSearchIds: comparisonState.selectedSearchIds,
        deleteComparison: comparisonState.deleteComparison,
        activeComparisonTitle: comparisonState.activeComparisonTitle,
        startNewComparison: comparisonState.startNewComparison,
        isLoading: comparisonState.isLoading,
        activeSidebarGroupTitle: comparisonState.activeSidebarGroupTitle,
        activeSidebarSearchId: comparisonState.activeSidebarSearchId,
        pendingComparison: comparisonState.pendingComparison,
        chartData: comparisonState.chartData,
        currentComparisonState: comparisonState,
        currentUser: orm.getById(User, currentUserId),
        showAlternateDateFormat: showAlternateDateFormat,
        isStaggeredLoading: comparisonState.isStaggeredLoading,
        cachedLargerTimeFrames: comparisonState.cachedLargerTimeFrames,
        categorySearches,
        showEditComparisonModal: comparisonState.showEditComparisonModal,
        displayCategory: displayCategory,
        displaySingularCategory: displaySingularCategory,
        shareOfVoiceChartType: comparisonState.shareOfVoiceChartType,
      }
    },
  ),
  {
    setShowFirstTimePage,
    setBuildNewComparison,
    setSelectedSearchIds,
    setDeleteComparison,
    setActiveComparison,
    setNewComparisonTitle,
    setDisplayChartTypes,
    setShareOfVoiceChartType,
    saveComparisonSearchGroup,
    deleteComparisonSearchGroup,
    timeFrameChange,
    restoreStateFromHistory,

    updateDocuments: documents => entitiesActions.update({
      [Document.entityKey]: pipe(
        map(document => [document.id, document]),
        fromPairs,
      )(documents),
    }),
    updateFeeds: feeds => entitiesActions.update({
      [Feed.entityKey]: pipe(
        map(feed => [feed.id, feed]),
        fromPairs,
      )(feeds),
    }),
    removeDocumentsForExcludedFeed: feedAndSearchIds =>
      removeDocumentsForExcludedFeedId({feedAndSearchIds}),

    activeNavChange: (waypoint, searchId, groupTitle) =>
      activeSidebarNavChange({waypoint, searchId, groupTitle}),
    chartPointClick,
  },
)(Comparison)
