import PropTypes from 'prop-types'
import React, {Component} from 'react'

import TextBox from 'app/common/TextBox'
import classNames from 'classnames'

import * as styles from './style.less'


export default class Assignees extends Component {
  static propTypes = {
    assignees: PropTypes.arrayOf(PropTypes.object).isRequired,
    type: PropTypes.string.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    isSingleColumn: PropTypes.bool,
    // (Number, Boolean) -> ()
    onIdSelectChange: PropTypes.func.isRequired,
    // (Boolean) -> ()
    onSelectAllStateChange: PropTypes.func.isRequired,
    // (String) => ()
    onNameFilterChange: PropTypes.func.isRequired,
    limitOne: PropTypes.bool
  }

  state = {
    nameFilter: '',
  }

  get areAllSelected() {
    const assignees = this.getFilteredAssignees()
    return assignees.filter(a => this.props.selectedIds.includes(a.id)).length === assignees.length
  }

  render() {
    const assignees = this.getFilteredAssignees()

    return (
      <React.Fragment>
        <div>
          <TextBox
            className={styles.search}
            placeholder={`Search for ${this.props.type} by name...`}
            onChange={(event) => this.onFilterChange(event.target.value)}
          />
        </div>

        {
          assignees.length > 0 && !this.props.limitOne &&
          <div className={styles.assigneeListSelectAll}>
            <label className={styles.label}>
              <input
                type="checkbox"
                className="checkbox"
                checked={this.areAllSelected}
                onChange={this.onSelectAllStateChange}
              />
              <span className={classNames(styles.name, 'select-all')}>
              </span>
            </label>
          </div>
        }

        <ul
          className={classNames(styles.assigneeList,
            {[styles.singleColumn]: this.props.isSingleColumn})}
        >
          {assignees.map(this.renderAssignee)}
        </ul>
      </React.Fragment>
    )
  }

  renderAssignee = (assignee) => {
    const isDisabled = this.props.limitOne && this.props.selectedIds.length >= 1 &&
      !this.props.selectedIds.includes(assignee.id)

    return (
      <li className="user" key={assignee.id}>
        <label className={styles.label}>
          <input
            type="checkbox"
            className="checkbox"
            checked={this.props.selectedIds.includes(assignee.id)}
            onChange={this.onIdSelectChange.bind(this, assignee)}
            disabled={isDisabled}
          />
          <span className={classNames(styles.name, {[styles.disabled]: isDisabled})}>
            { assignee.name }
          </span>
        </label>
      </li>
    )
  }

  // Event handlers

  onFilterChange = (nameFilter) => {
    this.setState({nameFilter}, () => this.props.onNameFilterChange(nameFilter))
  }

  onSelectAllStateChange = (event) => {
    const assignees = this.getFilteredAssignees()
    const filteredIds = assignees.map(a => a.id)
    this.props.onSelectAllStateChange(filteredIds, event.target.checked)
  }

  onIdSelectChange = (assignee, event) => {
    this.props.onIdSelectChange(assignee.id, event.target.checked)
  }

  getFilteredAssignees = () => {
    const filter = this.state.nameFilter.toLowerCase()
    return this.props.assignees
      .filter(a => a.name.toLowerCase().includes(filter))
      .sort((a1, a2) => {
        const name1 = a1.name.trim().toLowerCase()
        const name2 = a2.name.trim().toLowerCase()
        return name1.localeCompare(name2)
      })
  }
}
