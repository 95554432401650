import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { Component } from 'react'
import cn from 'classnames'
import Button from 'app/common/Button'


export default class PageSelector extends Component {
  static propTypes = {
    currentPage: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    containerClassName: PropTypes.any,
    makeActiveButtonNarrow: PropTypes.bool,
  }

  render() {
    const {currentPage, pageCount, containerClassName, makeActiveButtonNarrow} = this.props
    if (pageCount <= 1 || isNaN(pageCount)) return null

    const pagesToShow = R.range(
      Math.max(1, currentPage - 2),
      Math.min(currentPage + 2, pageCount) + 1,
    )
    const isFirstPage = currentPage === 1
    const isLastPage = currentPage === pageCount
    return (
      <div className={cn("page-selector", containerClassName)}>
        {!isFirstPage &&
          <Button
            label="&lt;"
            isPlainText
            onClick={() => this.props.onPageChange(currentPage - 1)}
          />
        }

        {pagesToShow[0] !== 1 &&
          <span>
            <PageButton
              number={1}
              onClick={() => this.props.onPageChange(1)}
            />
            {pagesToShow[0] !== 2 &&
              <Ellipses />}
          </span>
        }

        {pagesToShow.map(num =>
          <PageButton
            number={num}
            isActive={num === currentPage}
            isNarrow={num === currentPage && makeActiveButtonNarrow}
            onClick={() => this.props.onPageChange(num)}
            key={num}
          />
        )}

        {pagesToShow[pagesToShow.length - 1] !== pageCount &&
          <span>
            {pagesToShow[pagesToShow.length - 1] !== (pageCount - 1) &&
              <Ellipses />}
            <PageButton
              number={pageCount}
              onClick={() => this.props.onPageChange(pageCount)}
            />
          </span>
        }

        {!isLastPage &&
          <Button
            label="&gt;"
            isPlainText
            onClick={() => this.props.onPageChange(currentPage + 1)}
          />
        }
      </div>
    )
  }
}


const PageButton = ({number, isActive = false, isNarrow = false, ...restProps}) => {
  return (
    <Button
      label={number}
      isPlainText={!isActive}
      isNarrow={isNarrow}
      {...restProps}
    />
  )
}


const Ellipses = () => <span style={{margin: '0 0.25em'}}>...</span>