export const STATE_PROP_NAME = 'advancedSearch'

export const TERM_FREQUENCY_TARGET_FIELDS = {
  TEXT: 'text',
  INTRO: 'intro',
  HEADLINE: 'headline',
}

export const TERM_FREQUENCY_TARGET_FIELD_LABELS = {
  TEXT: 'Headline, Lead, and Body',
  INTRO: 'Headline and Lead',
  HEADLINE: 'Headline Only',
}
