import classNames from 'classnames'
import React, { Component } from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import PropTypes from 'prop-types'

import { apiCall } from 'app/api'
import ConfirmationModal from 'app/common/modals/ConfirmationModal'
import { adjustFlaggedArticleCount } from 'app/flagging/flagging-actions'
import * as globalSelectors from 'app/global/global-selectors'
import * as notifications from 'app/global/notifications'
import { ShareModal } from 'app/reusable/ShareModal/index'
import { handleError } from 'app/utils/errors'

import './style.less'
import FlagModal from 'app/reusable/FlagModal'

const EXCLUDE_TYPE = {
  PROFILE: 'profile',
  SEARCH: 'search',
}


@connect(
  createSelector(
    [
      globalSelectors.getAppNameFull,
      globalSelectors.getInsightsLabel,
      globalSelectors.getCurrentUserId,
      globalSelectors.getCurrentUserFullName,
      globalSelectors.getSiteUrl,
      globalSelectors.getAppUrl,
    ],
    (
      appNameFull,
      insightsLabel,
      userId,
      userFullName,
      siteUrl,
      appUrl,
    ) => ({
      appNameFull,
      insightsLabel,
      userId,
      userFullName,
      siteUrl,
      appUrl,
    }),
  ),
  {
    adjustFlaggedArticleCount,
    showNotification: notifications.actions.showNotification,
  },
)
export default class InteractionBar extends Component {
  static propTypes = {
    search: PropTypes.object,
    article: PropTypes.object.isRequired,
    isFlagged: PropTypes.bool.isRequired,
    isPromoted: PropTypes.bool.isRequired,
    isSignalsEnabled: PropTypes.bool,
    isFlaggedPage: PropTypes.bool,
    isSharepoint: PropTypes.bool,
    includeExcludeFromSearchOption: PropTypes.bool,
    className: PropTypes.string,

    onFlaggingStateChange: PropTypes.func,
    onPromoteStateChange: PropTypes.func,
    onExclude: PropTypes.func,  // exclude from profile or search
  }

  state = {
    doShowConfirmModal: false,
    isFlagModalOpen: false,
    isShareModalOpen: false,
    excludeOptionsIsShown: false,
    selectedExcludeOption: null,
  }

  render() {
    const mailToSubject = encodeURIComponent('An article shared from ' + this.props.appNameFull + ': ' + this.props.article.displayHeadlineShort);
    const mailToBody = encodeURIComponent(this.props.userFullName + ' has sent you the following article from ' + this.props.appNameFull + ':') +
      '%0A%0A' + encodeURIComponent(this.props.article.displayHeadlineShort) + '%0A' + encodeURIComponent(this.props.article.snippet) +
      '%0A%0A' + encodeURIComponent('http:' + this.props.siteUrl + '/article/v/' + this.props.article.directorId + '/' + this.props.userId + '/') +
      '%0A%0A--------------------%0A%0A' + encodeURIComponent('Learn more about ' + this.props.appNameFull + ' by visiting: ' + this.props.appUrl);
    const mailToLink = 'mailto:?subject=' + mailToSubject + '&body=' + mailToBody;

    const interactionIcons =
      <div
        className={'interaction-icons'}
      >
        <a
          className="mail-article interaction-link"
          title="Email Article" href={mailToLink}
          target="_blank"
        >
          <span className="int-icon"/>
        </a>

        <a className="share-article interaction-link" title="Share Article" onClick={() => this.showShareModal()}>
          <span className="int-icon"/>
        </a>

        <a
          className={classNames(
            'interaction-link',
            'flag-article',
            'context-toggle',
            'flag-toggle',
            {selected: this.props.isFlagged},
          )}
          title={this.props.isFlagged ? 'Unflag Article' : 'Flag Article'}
          onClick={() => this.openFlagModal()}
        >
          <span className="int-icon"/>
        </a>

        <div
            className="context-menu flagged-menu" id={'flag-modal-' + this.props.article.directorId}
            title={this.props.article.directorId}
            onMouseLeave={() => this.hideFlagModal()}
        >
          <p className="loading-text">Loading...</p>
        </div>

        {
          this.props.onPromoteStateChange && this.props.article.feedId && !this.props.isSharepoint &&
          <a
            className={'interaction-link promote-source '
            + (this.props.isPromoted ? 'selected' : '')}
            title={(this.props.isPromoted ? 'Demote' : 'Promote') + ' Source'}
            onClick={() => this.togglePromoteFeed()}
          >
            <span className="int-icon"/>
          </a>
        }

        {
          this.props.onExclude && this.props.article.feedId && !this.props.isSharepoint && !this.props.isFlaggedPage &&
          (
            this.props.includeExcludeFromSearchOption && this.props.search.id && this.props.search.id !== -1
            ? <a
                className="interaction-link exclude-source no-pointer"
                onMouseEnter={() => this.setState({excludeOptionsIsShown: true})}
                onMouseLeave={() => this.handleExcludeIconMouseLeave()}
              >
                <span className="int-icon"/>
                {
                  this.state.excludeOptionsIsShown &&
                  <div className="exclude-options">
                    <div
                      className="exclude-option"
                      onClick={() => this.showExcludeConfirmModal(EXCLUDE_TYPE.PROFILE)}
                    >Exclude Source from Profile</div>

                    <div
                      className="exclude-option"
                      onClick={() => this.showExcludeConfirmModal(EXCLUDE_TYPE.SEARCH)}
                    >Exclude Source from this Search</div>
                  </div>
                }
              </a>
            : <a
                className="interaction-link exclude-source" title="Exclude Source"
                onClick={() => this.showExcludeConfirmModal(EXCLUDE_TYPE.PROFILE)}
              >
                <span className="int-icon"/>
              </a>
          )
        }

        {
          this.props.isSignalsEnabled && this.props.article.signals &&
          <a
            className="interaction-link go-to-signals"
            href={this.props.article.signalsInterimUrl}
            title={`Open in ${this.props.appNameFull} ${this.props.insightsLabel}`}
            target="_blank"
          >
            <span className="int-icon"/>
          </a>
        }
      </div>

    const confirmModal = this.state.doShowConfirmModal && (
      <ConfirmationModal
        message={
          `Are you sure you want to exclude ${this.props.article.source} from 
          ${this.state.selectedExcludeOption === EXCLUDE_TYPE.SEARCH ? 'this search' : 'your profile'}?`
        }
        secondaryMessage={
          <span>
            By excluding {this.props.article.source}, you will no longer see results from {this.props.article.source} in
            your search results or in your email alerts for {this.state.selectedExcludeOption === EXCLUDE_TYPE.SEARCH ? 'this search' : 'your profile'}.
            {
              this.state.selectedExcludeOption === EXCLUDE_TYPE.PROFILE &&
              <span> You can manage your sources in the <a href="/profile/trusted_sources/" target="_blank">sources area</a> of your user profile.</span>
            }
          </span>
        }
        confirmButtonText="Exclude"
        onConfirm={() => this.excludeFeed()}
        onClose={() => this.hideExcludeConfirmModal()}
        isDestructive={true}
      />
    )

    const shareModal =
      this.state.isShareModalOpen &&
      <ShareModal
        article={this.props.article}
        onClose={() => this.hideShareModal()}
      />

    return (
      <div className={this.props.className}>
        {interactionIcons}
        {shareModal}
        {confirmModal}
        {this.state.isFlagModalOpen && (
          <FlagModal
            documentIds={[this.props.article.directorId.toString()]}
            onFlaggingStateChange={this.props.onFlaggingStateChange}
            onClose={() => this.closeFlagModal()}
            feedId={this.props.article.feedId}
          />
        )}
      </div>
    )
  }

  handleExcludeIconMouseLeave() {
    window.setTimeout(() => {
      this.setState({excludeOptionsIsShown: false})
    }, 250);
  }

  showShareModal() {
    this.setState({isShareModalOpen: true})
  };

  hideShareModal() {
    this.setState({isShareModalOpen: false})
  };

  hideFlagModal() {
    const $flagModal = $('#flag-modal-' + this.props.article.directorId);
    $flagModal.hide();
  };

  togglePromoteFeed() {
    const data = {
      feed_id: this.props.article.feedId
    };
    apiCall({
      url: '/profile/trusted_sources/promoted/add/',
      method: 'POST',
      data,
    }).then(response => {
      this.props.showNotification({
        type: 'success',
        message: response.body.msg,
      })
      if (this.props.onPromoteStateChange) {
        this.props.onPromoteStateChange({
          feedId: this.props.article.feedId,
          isPromoted: response.body.added,
        })
      }
    }).catch(error => {
      handleError(error, {showNotification: this.props.showNotification})
    })
  };

  showExcludeConfirmModal(selectedExcludeOption) {
    this.setState({doShowConfirmModal: true, selectedExcludeOption})
  }

  hideExcludeConfirmModal() {
    this.setState({doShowConfirmModal: false, selectedExcludeOption: null})
  }

  excludeFeed() {
    const {feedId} = this.props.article

    const data = {
      feed_id: this.props.article.feedId
    }

    if (this.state.selectedExcludeOption === EXCLUDE_TYPE.SEARCH) {
      data.search_id = this.props.search.id
    }

    this.hideExcludeConfirmModal()

    apiCall({
      url: '/exclude_feed/',
      method: 'POST',
      data,
    }).then(response => {
      this.props.showNotification({
        type: response.body.success ? 'success' : 'error',
        message: response.body.msg,
      })
      if (response.body.success && this.props.onExclude) {
        this.props.onExclude({feedId, searchId: data.search_id})
      }
    }).catch(error => {
      handleError(error, {showNotification: this.props.showNotification})
    })
  }

  openFlagModal() {
    this.setState({isFlagModalOpen: true})
  }

  closeFlagModal() {
    this.setState({isFlagModalOpen: false})
  }
}
