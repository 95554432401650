import { createAction } from 'redux-actions'

// Generic suffixes
export const REQUEST = 'REQUEST'
export const SUCCESS = 'SUCCESS'
export const FAILURE = 'FAILURE'

/**
 * Creates an object with three properties, each representing one part
 * of a backend API call: request, success, and failure.
 * @param baseName: String - The base name of the action
 */
export function createRequestActions(baseName) {
  return [REQUEST, SUCCESS, FAILURE].reduce((obj, type) => {
    obj[type.toLowerCase()] = createAction(`${baseName}_${type}`)
    return obj
  }, {})
}
