import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import InlineSvg from 'app/common/InlineSvg'
import * as styles from './ManzamaLogo.less'
import { APP_LOGO } from 'app/constants'

export const COLORS = {
  LIGHT: 'light',
  DARK: 'dark',
}

export default function ManzamaLogo({
  color = COLORS.DARK,
  className,
  ...restProps
}) {
  return (
    <InlineSvg
      src={APP_LOGO.MANZAMA}
      className={classNames(styles.logo, className, {
        [styles.light]: color === COLORS.LIGHT,
        [styles.dark]: color === COLORS.DARK,
      })}
      {...restProps}
    />
  )
}
ManzamaLogo.propTypes = {
  color: PropTypes.oneOf(Object.values(COLORS)),
  className: PropTypes.string,
}
ManzamaLogo.COLORS = COLORS
