export const STATE_PROP_NAME = 'profileRefresh'

export const PROFILE_REFRESH_INTERVAL_OPTIONS = [
  {key: 1, value: '1 Month'},
  {key: 3, value: '3 Months'},
  {key: 6, value: '6 Months'},
  {key: 12, value: '12 Months'},
  {key: 0, value: "Never"},
]

export const CATEGORIES = [
  {category: 'practice', label: 'practice', plural: 'practices', authors: ['firm-library', 'manzama'],},
  {category: 'industry', label: 'industry', plural: 'industries',authors: ['firm-library', 'manzama'],},
  {category: 'client', label: 'company', plural: 'companies', authors: ['firm-library'],},
  {category: 'prospect', label: 'prospect', plural: 'prospects', authors: []},
  {category: 'firm', label: 'competitor', plural: 'competitors', authors: ['firm-library'],},
  {category: 'tracker', label: 'topic', plural: 'topics', authors: ['firm-library'],},
  {category: 'source', label: 'trusted source', plural: 'trusted sources', authors: []},
  {category: 'agency', label: 'agency', plural: 'agencies', authors: []},
]
