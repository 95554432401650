import React from 'react'
import classNames from 'classnames'
import * as styles from './Chip.less'

/**
 * A chip which can be toggled on and off.
 * @param label A string to be displayed on the Chip.
 * @param onClick A callback to call when a user clicks on the Chip. Will receive the selected attribute's value.
 * @param selected Controls whether the Chip is selected or not.
 * @param className Additional CSS classes, which are optional.
 */
export default function Chip({ label, onClick, selected, className = null }) {
  return (
    <div onClick={() => onClick(selected)}
         className={classNames(styles.chip, { [styles.selected]: selected }, className)}>{label}</div>
  )
}
