export const CHART_COLORS = [
  // various app palette colors
  '#035C8B',
  '#EB7100',
  '#7ED285',
  '#921A1D',
  '#00A4E4',
  '#0e7019',
  '#e18b9a',
  // palette colors lightened 20%
  '#368FBE',
  '#FFA433',
  '#B1FFB8',
  '#C54D50',
  '#33D7FF',
  '#41A34C',
  '#FFBECD',
  // palette colors darkened 20%
  '#002958',
  '#B83E00',
  '#4B9F52',
  '#5F0000',
  '#0071B1',
  '#003D00',
  '#AE5867',
]

// Chart colors above lightened 10%
export const CHART_COLORS_HOVER = [
  '#1D76A5',
  '#FF8B1A',
  '#98EC9F',
  '#AC3437',
  '#1ABEFE',
  '#288A33',
  '#FBA5B4',
  '#50A9D8',
  '#FFBE4D',
  '#CBFFD2',
  '#DF676A',
  '#4DF1FF',
  '#5BBD66',
  '#FFD8E7',
  '#1A4372',
  '#D2581A',
  '#65B96C',
  '#791A1A',
  '#1A8BCB',
  '#1A571A',
  '#C87281',
]

export const EXCLUDED_COLOR = '#666666'

export const ON_CLICK_TYPES = {
  CHART: 'chart',
  PLOT: 'plot',
  NONE: 'none',
}

export const DEFAULT_BAR_WIDTH = 40
export const DEFAULT_TOOL_TIP = 'Share of Voice'

export const CHART_TYPES = {
  BAR: 'bar',
  LINE: 'line',
  PIE: 'pie'
}

export const DATA_COUNT_ROTATE_THRESHOLD = 8
export const LINE_CHART_KEY_LENGTH = 4
