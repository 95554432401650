// Global tooltip library

import './style.less'

document.addEventListener('DOMContentLoaded', () => {
  // If jQuery isn't loaded, this is probably a page that doesn't extend
  // base.html
  if (!window.$) return

  // Array of [origin, tooltip] pairs, where `origin` is the element
  // that the tooltip originated from (i.e. the element that the user
  // hovered over)
  const tooltips = []
  const selector = '[data-mz-tooltip-text], [data-mz-tooltip-id]'

  $(document.body).on('mouseover', selector, (e) => {
    const origin = e.target
    const $origin = $(origin)

    let $tooltip = tooltips.find(([origin, tooltip]) => origin === e.target)
    if (!$tooltip) {
      $tooltip = $(`<div class="mz-tooltip"></div>`)
      tooltips.push([origin, $tooltip])
    }

    let tooltipContent = $origin.attr('data-mz-tooltip-text')
    if (typeof tooltipContent != 'undefined') {
      $tooltip.text(tooltipContent)
    } else {
      const id = $origin.attr('data-mz-tooltip-id')
      const target = document.getElementById(id).cloneNode(true)
      $tooltip.append(target)
    }

    const originWidth = origin.offsetWidth
    const pos = $origin.offset()
    const left = pos.left + (originWidth / 2)
    $tooltip.css('left', left).css('top', pos.top + 25).fadeIn()

    $tooltip.append(`<div class="arrow"></div>`)
    $tooltip.appendTo(document.body)
  })

  $(document.body).on('mouseout', selector, (e) => {
    const index = tooltips.findIndex(([origin, tooltip]) => origin === e.target)
    if (index > -1) {
      const $tooltip = tooltips[index][1]
      $tooltip.remove()
      tooltips.splice(index, 1)
    }
  })
})
