import React, { Component } from 'react'
import { connect } from 'react-redux'

import Orm from 'app/framework/Orm'
import { User } from 'app/models'
import Dropdown from 'app/common/Dropdown'
import TextBox from 'app/common/TextBox'
import Button from 'app/common/Button'
import InputBlock from 'app/common/InputBlock'
import * as notifications from 'app/global/notifications'

import { getUsersAdmin } from '../users-admin-selectors'
import { setUserUpdates, saveUser, fetchUser } from '../users-admin-actions'
import {titleCase} from 'title-case'

import './DetailsContent.less'
import * as dateFns from 'date-fns'
import Tooltip from "../../../common/Tooltip";


@connect(
  state => {
    const orm = Orm.withEntities(state.entities)
    const usersAdmin = getUsersAdmin(state)
    return {
      isFirmGroupSignupEnabled: state.currentFirmIsGroupSignupEnabled,
      isCurrentFirmInsightsEnabled: state.currentFirmHasInsights,
      insightsLabel: state.insightsLabel,

      firmLocations: usersAdmin.firmLocations,
      countries: usersAdmin.countries,
      timezones: usersAdmin.timezones,
      roles: usersAdmin.roles,
      userModalData: usersAdmin.userModalData,
      user: orm.getById(User, usersAdmin.userModalData.userId),
      isUserFetched: usersAdmin.isUserFetched,
    }
  },
  {
    setUserUpdates,
    showNotification: notifications.actions.showNotification,
    saveUser,
    fetchUser,
  },
)
export default class DetailsContent extends Component {
  componentDidMount() {
    if (!this.props.isUserFetched) {
      this.props.fetchUser(this.props.userModalData.userId)
    }
  }

  render() {
    const { user, isFirmGroupSignupEnabled, firmLocations, countries, timezones, roles } = this.props

    const { userUpdates } = this.props.userModalData

    const firmLocationOptions = [{value: 0, label: 'All Locations'}].concat(
      firmLocations.map(l => {return {value: l.id, label: l.description}})
    )

    const topCountries = [
      countries.find(c => c.name === 'Australia'),
      countries.find(c => c.name === 'Canada'),
      countries.find(c => c.name === 'United Kingdom'),
      countries.find(c => c.name === 'United States'),
      {id: 0, name: '----------'}
    ]

    const countryOptions = topCountries.concat(countries).map(c => {return {value: c.id, label: c.name}})

    const timezoneOptions = timezones.map(tz => {return {value: tz[0], label: tz[1]}})

    const roleOptions = roles.map(r => {return {value: r[0], label: r[1]}}).filter(r => r.value !== 'group')

    // Dropdown does not support true/false values.
    const booleanOptions = [
      {
        value: 'yes',
        label: 'Yes',
      },
      {
        value: 'no',
        label: 'No',
      },
    ]

    const enableInsightsLabel = `Enable ${titleCase(this.props.insightsLabel)}`
    return (
      <div
        id="user-details-content"
      >
        <div className="column">
          {
            user.role === 'group' ?
              <div>
                <InputBlock label="Group Name">
                  <TextBox
                    value={this.getCurrentUserValue('firstName')}
                    onChange={evt => this.handleTextBoxChange(evt, 'firstName')}
                  />
                </InputBlock>

                <InputBlock label="Group Notes" className="stacked firm-admin-text-area">
                  <TextBox
                    multiline
                    className="group-notes"
                    cols="40"
                    rows="10"
                    placeholder="add notes for the group here..."
                    value={this.getCurrentUserValue('groupNotes')}
                    onChange={evt => this.handleTextBoxChange(evt, 'groupNotes')}
                  />
                </InputBlock>

                <InputBlock label="Send Email To Group Address" className="stacked firm-admin-text-area">
                  <Dropdown
                    options={booleanOptions}
                    value={this.getCurrentUserValue('sendEmailToGroupAddress', true)}
                    onChange={value => this.handleDropdownChange(value, 'sendEmailToGroupAddress')}
                  />
                </InputBlock>

                {
                  isFirmGroupSignupEnabled ?
                    <InputBlock label="Make Available In Portal Signup">
                      <Dropdown
                        options={booleanOptions}
                        value={this.getCurrentUserValue('includeWithGroupSignup', true)}
                        onChange={value => this.handleDropdownChange(value, 'includeWithGroupSignup')}
                      />
                    </InputBlock>
                    : null
                }

                <InputBlock label="Make this a private group" className="checkbox-container" isInline>
                  <input
                    type="checkbox"
                    checked={this.getCurrentUserValue('isPrivateGroup')}
                    onChange={evt => this.handleCheckboxChange(evt, 'isPrivateGroup')}
                  />
                </InputBlock>

              </div>
              : <div>
                  <InputBlock label="First Name">
                    <TextBox
                      value={this.getCurrentUserValue('firstName')}
                      onChange={evt => this.handleTextBoxChange(evt, 'firstName')}
                    />
                  </InputBlock>

                  <InputBlock label="Last Name">
                    <TextBox
                      value={this.getCurrentUserValue('lastName')}
                      onChange={evt => this.handleTextBoxChange(evt, 'lastName')}
                    />
                  </InputBlock>

                  <InputBlock label="Role">
                    <Dropdown
                      options={roleOptions}
                      value={this.getCurrentUserValue('role') || ''}
                      onChange={value => this.handleDropdownChange(value, 'role')}
                    />
                  </InputBlock>
                </div>
          }

          {
            user.role === 'group' ?
              null
              : <div>
                  <InputBlock label="Administrator" className="checkbox-container" isInline>
                    <input
                      type="checkbox"
                      checked={this.getCurrentUserValue('isFirmAdmin')}
                      onChange={evt => this.handleCheckboxChange(evt, 'isFirmAdmin')}
                    />
                  </InputBlock>
                  {
                    this.props.isCurrentFirmInsightsEnabled ?
                      <InputBlock
                        label={<span>{enableInsightsLabel}<span className="signals-enabled-profile-icon"/></span>}
                        className="checkbox-container"
                        isInline
                      >
                        <input
                          type="checkbox"
                          checked={this.getCurrentUserValue('hasInsights')}
                          onChange={evt => this.handleCheckboxChange(evt, 'hasInsights')}
                        />
                      </InputBlock>
                      : null
                  }
                  <InputBlock label={
                    <>
                      Change Password
                      <Tooltip render={() =>
                        <div>
                          <ul>
                            <li>Password needs to be between 8-128 characters.</li>
                            <li>It can not be too similar to your username, email address, first or last name.</li>
                            <li>The new password can not match the last 5 passwords.</li>
                          </ul>
                        </div>}>
                        <a className="password tooltip help label-tooltip"/>
                      </Tooltip>
                    </>
                  }>
                    <input
                      type="password"
                      autoComplete="new-password"
                      className="text-box"
                      minLength="5"
                      maxLength="20"
                      value={userUpdates.password || ''}
                      onChange={evt => this.handleTextBoxChange(evt, 'password')}
                    />
                    <div className="helptext">Leave blank to keep the current password.</div>
                  </InputBlock>

                  <InputBlock label="Confirm Password">
                    <input
                      type="password"
                      autoComplete="new-password"
                      className="text-box"
                      value={userUpdates.confirmPassword || ''}
                      onChange={evt =>  this.handleTextBoxChange(evt, 'confirmPassword')}
                    />
                  </InputBlock>
                </div>
          }

          <div className="pad-l-s pad-t-l">
            <Button
              isPrimary={true}
              label="Save"
              onClick={() => this.save()}
            />
          </div>
        </div>

        <div className="column">
          <InputBlock label={user.role === 'group' ? 'Username' : 'Email Address'}>
            <TextBox
              value={this.getCurrentUserValue('username')}
              onChange={evt => this.handleTextBoxChange(evt, 'username')}
            />
          </InputBlock>

          {
            user.role === 'group' ?
              null
              : <InputBlock label="Phone">
                  <TextBox
                    value={this.getCurrentUserValue('phone')}
                    onChange={evt => this.handleTextBoxChange(evt, 'phone')}
                  />
                </InputBlock>
          }

          <InputBlock label="Timezone">
            <Dropdown
              options={timezoneOptions}
              value={this.getCurrentUserValue('timezone')}
              onChange={value => this.handleDropdownChange(value, 'timezone')}
            />
          </InputBlock>

          <InputBlock label="Country">
            <Dropdown
              options={countryOptions}
              value={this.getCurrentUserValue('locationId')}
              onChange={value => this.handleDropdownChange(value, 'locationId')}
            />
          </InputBlock>

          {
            user.role !== 'group' ?
              <InputBlock label="Office Location">
                <Dropdown
                  options={firmLocationOptions}
                  value={this.getCurrentUserValue('firmLocationId') || 0}
                  onChange={value => this.handleDropdownChange(value, 'firmLocationId')}
                />
              </InputBlock>
              : null
          }
          {
            user.authToken ?
              <InputBlock label="Authentication Token / API key">
                <span className="auth-field">{user.authToken}</span>
              </InputBlock>
              : null
          }
          {user.nlaProfile ? <InputBlock label="Restrict NLA articles viewing" className="checkbox-container" isInline>
            <input
                      type="checkbox"
                      checked={this.getCurrentUserValue('restrictNlaFeeds')}
                      onChange={evt => this.handleCheckboxChange(evt, 'restrictNlaFeeds')}
                    />
          </InputBlock>:null
          }
          {
            user.lastRefreshAt || user.nextRefreshEmailAt || user.refreshCompletedAt
              ? <div className={'refresh-container'}>
                {
                  user.lastRefreshAt
                  ? <InputBlock label="Refresh Email Sent On">
                      <span>{this.formatRefreshDate(user.lastRefreshAt)}</span>
                    </InputBlock>
                  : null
                }
                {
                  user.refreshCompletedAt
                  ? <InputBlock label="Refresh Completed On">
                      <span>{this.formatRefreshDate(user.refreshCompletedAt)}</span>
                    </InputBlock>
                  : null
                }
                {
                  user.nextRefreshEmailAt
                  ? <InputBlock label="Next Refresh Email Will Be Sent On">
                      <span>{this.formatRefreshDate(user.nextRefreshEmailAt)}</span>
                    </InputBlock>
                  : null
                }
                </div>
              : null
          }
        </div>

        <div className="column">
          <div className="classification-container">
            <h4 className="classification-header">Departments</h4>

            <div>
              {
                user.departments &&
                (
                  user.departments.length > 0
                    ? user.departments.map(d => {
                        return (
                          <div key={d.id}>{d.name}</div>
                        )
                      })
                    : <div className="no-classifications">None assigned</div>
                )
              }
            </div>
          </div>

          <div className="classification-container">
            <h4 className="classification-header">Teams</h4>

            <div>
              {
                user.teams &&
                (
                  user.teams.length > 0
                    ? user.teams.map(t => {
                        return (
                          <div key={t.id}>{t.name}</div>
                        )
                      })
                    : <div className="no-classifications">None assigned</div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  formatRefreshDate(refreshDate) {
    const DATE_FORMAT = this.props.currentFirmShowAlternateDateFormat
      ? 'do MMM yyyy'
      : 'MMM do yyyy'
    if (refreshDate !== null) {
      return (dateFns.format(refreshDate, DATE_FORMAT))
    } else {
      return null
    }
  }

  getCurrentUserValue(fieldName, convertBooleanForDropdown = false) {
    let { userUpdates } = this.props.userModalData
    if (fieldName in userUpdates) {
      return userUpdates[fieldName]
    }
    let value = this.props.user[fieldName]
    if (convertBooleanForDropdown) {
      return value === true ? 'yes' : 'no'
    }
    return value
  }

  handleTextBoxChange(evt, fieldName) {
    let userUpdates = {...this.props.userModalData.userUpdates}
    userUpdates[fieldName] = evt.target.value
    this.props.setUserUpdates(userUpdates)
  }

  handleCheckboxChange(evt, fieldName) {
    let userUpdates = {...this.props.userModalData.userUpdates}
    userUpdates[fieldName] = evt.target.checked
    this.props.setUserUpdates(userUpdates)
  }

  handleDropdownChange(value, fieldName) {
    let { userUpdates } = this.props.userModalData
    userUpdates[fieldName] = value
    this.props.setUserUpdates(userUpdates)
  }

  save() {
    let userUpdates = {...this.props.userModalData.userUpdates}
    // convert boolean dropdown 'yes'/'no' to true/false
    if ('sendEmailToGroupAddress' in userUpdates) {
      userUpdates.sendEmailToGroupAddress = userUpdates.sendEmailToGroupAddress === 'yes'
    }
    if ('includeWithGroupSignup' in userUpdates) {
      userUpdates.includeWithGroupSignup = userUpdates.includeWithGroupSignup === 'yes'
    }
    if (userUpdates.password && (userUpdates.password !== userUpdates.confirmPassword)) {
      this.props.showNotification({
        type: 'error',
        message: 'Passwords must match.'
      })
      return
    }
    // if password modified, then cleared, just remove from save data
    if ('password' in userUpdates && userUpdates.password === '') {
      delete userUpdates.password
    }
    delete userUpdates.confirmPassword
    userUpdates.id = this.props.userModalData.userId
    this.props.saveUser(userUpdates)
  }
}
