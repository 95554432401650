import {handleActions} from 'redux-actions'

import * as actions from './notifications-actions'

// Shape: {id, type, message, duration}
// Duration is in seconds.
const getInitialState = () => []

export default handleActions(
  {
    [actions.showNotification]: (state, action) => [
      ...state,
      {
        id: action.payload.id,
        type: action.payload.type,
        message: action.payload.message,
        duration: action.payload.duration,
      },
    ],
    [actions.hideNotification]: (state, action) => state.filter(notification =>
      notification.id !== action.payload
    ),
  },
  getInitialState(),
)
