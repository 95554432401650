import classNames from 'classnames'
import {range} from 'ramda'
import React from 'react'

import * as sidebarStyles from './DashboardSidebar.less'
import * as styles from './DashboardSidebarLoading.less'


function Category() {
  return (
    <div className={styles.dashboardSidebar}>
      <div className={classNames(sidebarStyles.sidebarWrapper)}>
        <div className={classNames(sidebarStyles.sidebar)}>
          <div className={classNames(styles.searchCategory, sidebarStyles.searchCategory)} />
          <div className={classNames(styles.searchNamesContainer, sidebarStyles.searchNamesContainer)}>
            <div className={classNames(styles.searchName, sidebarStyles.searchName)} />
            <div className={classNames(styles.searchName, sidebarStyles.searchName)} />
            <div className={classNames(styles.searchName, sidebarStyles.searchName)} />
          </div>
        </div>
      </div>
    </div>
  )
}


export default function DashboardSidebarLoading() {
  return range(0, 4).map(index => <Category key={index} />)
}
