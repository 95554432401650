import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {getCurrentUserId, getInsightsEnabled} from 'app/global/global-selectors'
import Dropdown from 'app/common/Dropdown'
import {ConfirmModal} from 'app/reusable/ConfirmModal'
import Modal from 'app/common/Modal'
import Button from 'app/common/Button'
import LoadingOverlay from 'app/common/LoadingOverlay'

import {getFlaggedItems} from './flagged-items-selectors'
import {
  showCreateCategoryModal,
  hideCreateCategoryModal,
  createCategory,
  setSelectedCategoryIds,
  setBulkAction,
  deleteCategories,
  clearCategories,
  setSideBarCategories,
  setFoldersOpened
} from './flagged-items-actions'
import FlaggedSidebarItem from './FlaggedSidebarItem'
import ArticleList from './ArticleList'

const BULK_ACTIONS = {
  DELETE: 'delete',
  CLEAR: 'clear,'
}


@connect(
  createSelector(
    [getFlaggedItems, getCurrentUserId, getInsightsEnabled],
    (flaggedItems, currentUserId, currentUserHasInsights) => {
      return {
        currentUserId,
        currentUserHasInsights,
        isLoading: flaggedItems.isLoading,
        createCategoryModalData: flaggedItems.createCategoryModalData,
        categories: flaggedItems.categories,
        bulkAction: flaggedItems.bulkAction,
        selectedCategoryIds: flaggedItems.selectedCategoryIds,
        sideBarCategories: flaggedItems.sideBarCategories,
        foldersOpened: flaggedItems.foldersOpened,
        pageVersion: flaggedItems.pageVersion
      }
    }
  ),
  {
    showCreateCategoryModal,
    hideCreateCategoryModal,
    createCategory,
    setSelectedCategoryIds,
    setBulkAction,
    deleteCategories,
    clearCategories,
    setSideBarCategories,
    setFoldersOpened
  },
)
export default class FlaggedItems extends Component {
  state = {
    sidebarFilterValue: null,
    sidebarIncludeEmpty: true,
    newCategoryName: null,
    enableExpandCollapse: false,
  }

  render() {
    const categories = this.getSortedFilteredCategories()    
    let sidebarItems = <></>
    if(this.props.pageVersion == 'old'){
      sidebarItems = categories.map(category => {
        return (
          <FlaggedSidebarItem
            key={`flagged-sidebar-item${category.id}`}
            category={category}
            checked={this.props.selectedCategoryIds.includes(category.id)}
            onChange={(categoryId, checked) => this.handleCategorySelect(categoryId, checked)}
            handleCreateFolderLinkClick={parentId => this.props.showCreateCategoryModal(parentId)}
          />
        )
      })
    } else {
      const sideBarValues = this.getSortedFilteredSideBarCategories()
      sidebarItems = sideBarValues.map(category => {
        return (
          category.show ? <FlaggedSidebarItem
            key={`flagged-sidebar-item${category.id}`}
            category={category}
            checked={this.props.selectedCategoryIds.includes(category.id)}
            onChange={(categoryId, checked) => this.handleCategorySelect(categoryId, checked)}
            handleCreateFolderLinkClick={parentId => this.props.showCreateCategoryModal(parentId)}
            sideBarValues={sideBarValues}
            handleArrowClick={(id, sideBarValues) => this.handleArrowClick(id, sideBarValues)}
          /> :  null
        )
      })
    }

    const sidebarList =
      <div>
        <ul className="plain tight list" id="flagged-sidebar-items">
          {sidebarItems}
        </ul>
      </div>

    const categoryActionOptions = [
      {label: "Selected Folder Actions", value: '', isInvisible: true},
      {
        isGroup: true,
        label: "Content Actions",
        options: [
          {label: "Clear Items within Folder(s)", value: BULK_ACTIONS.CLEAR},
        ],
      },
      {
        isGroup: true,
        label: "Folder Actions",
        options: [
          {label: "Delete Folder(s)", value: BULK_ACTIONS.DELETE},
        ],
      },
    ]

    const sidebarFilter = (this.props.pageVersion== 'old' ? this.props.categories.length > 8 : this.props.sideBarCategories.length > 8) &&
      <div className="flagged-sidebar-search-holder">
        <input
          type="text"
          className="flagged-search margin-v-s"
          placeholder="Filter Folders..."
          onChange={evt => this.handleSidebarFilterChange(evt)}
        />

        <div>
          <img
            src={`/media/img/${this.state.sidebarIncludeEmpty ? 'hide_off.png' : 'show_off.png'}`}
            className="include-empty-folders-toggle"
            title="Hide/Show Empty Folders"
            alt="Hide/Show Empty Folders"
            onClick={() => this.handleShowEmptyFoldersClick()}
          />
        </div>
      </div>

    const createFlagFolderModal =
      <Modal
        isOpen={this.props.createCategoryModalData.isOpen}
        onClose={() => this.props.hideCreateCategoryModal()}
        outsideExit={true}
        className={'create-new-flagged-folder'}
      >
        <div className="new-folder-header">
          Enter the New {this.props.createCategoryModalData.parentId ? 'Sub-Folder': 'Folder'} Name
        </div>
        <input
          type="text"
          className={'new-folder-name-input'}
          onChange={event => this.handleCreateFolderNameChange(event.target.value)}
          autoFocus
        />
        <div className={'button-container'}>
          <Button
            label={'Save'}
            className={'button primary'}
            onClick={() => this.handleCreateFolderOkClick()}
            disabled={!this.state.newCategoryName}
          />
          <a className="cancel cancel-action"
             onClick={() => this.props.hideCreateCategoryModal()}
          >
            Cancel
          </a>
        </div>
      </Modal>

    const sidebar =
      <div className="col col1">
        <h3 className="dark">Folders</h3>
        <div className="border margin-r-m flagged-sidebar">
          <div className="category-action-holder pad-a-s">
            <div className="yui3-u-1-12">
              <input
                className="select-all category-select-all font-size-xs xsmall margin-r-s float-left"
                type="checkbox"
                checked={this.areAllVisibleSelected()}
                onChange={(event) => this.handleSelectAllCategories(event.target.checked)}
              />
            </div>

            <div className="yui3-u-11-12">
              <Dropdown
                value=''
                options={categoryActionOptions}
                onChange={value => this.handleActionSelectChange(value)}
                disabled={this.props.selectedCategoryIds.length === 0}
              />
            </div>
          </div>
          <div className="create-new-category">
            <a onClick={() => this.props.showCreateCategoryModal()}>
              <span className="add-icon">+</span><span>Create New Folder</span>
            </a>
          </div>
          {this.props.pageVersion == 'new' && <div className="create-new-category">
            <input
                className="category-select-all font-size-xs xsmall margin-r-s float-left"
                type="checkbox"
                checked={this.state.enableExpandCollapse}
                onChange={(event) => {
                  this.setState(
                  {enableExpandCollapse: event.target.checked},
                  () => this.handleExpandAllFolders(this.state.enableExpandCollapse, this.props.sideBarCategories)
                )}}
            />
            <span>Expand All Folders</span>
          </div>}
          {sidebarFilter}
          {sidebarList}
        </div>
      </div>

    const categoryList =
      categories
        .filter(category => category.flaggings.length > 0)
        .map(category => (
          <li key={`flagged-category-${category.id}`} className="flagged-section">
            {/* anchors for sidebar links */}
            <a id={`flagged-category-${category.id}`}/>

            <div className="margin-b-m flag-category-wrapper">
              <div className="yui3-g">
                <div className="yui3-u-5-8">
                  <h3 className="dark category-name">
                    {category.name}
                    {category.parent && <span className="category-parent-name quiet font-size-s no-bold">({category.parent.name})</span>}
                  </h3>
                </div>

                <div className="yui3-u-3-8">
                  <div className="font-size-xs float-right">
                    <div className="margin-t-l">
                      <strong className="quiet">Subscribe via </strong>
                      <a href={`/article/flag_rss/${this.props.currentUserId}/${category.id}/?token=${category.rssKey}`}>
                        <img src="/media/img/ico_rss.png" height="15" alt="RSS" className="rnd-a-s" style={{verticalAlign: 'bottom'}}/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <ArticleList
                category={category}
                onFlaggingStateChange={() => window.location.reload()}
              />
            </div>
          </li>
        ))
    
    const isEmptyCategoriesSelected = this.props?.categories?.every(category => category?.flaggings?.length === 0)

    const content =
      <div id="flagged-container">
        {sidebar}

        {
          this.props.pageVersion == 'old' ?
          <div className="col col2">
          {
            // if there's only one, it's the uncategorized folder. if it's empty, show the video.
            this.props.categories.length === 1 && this.props.categories[0].flaggings.length === 0 ?
              <div className="flagged-intro copy">
                <h2>How To Use Flagged Items</h2>
                <p className="font-size-l">Flagging items within the platform lets you save the articles for later
                  reference. This could be to read later or to use to publish custom newsletters via <a href="/publish">Publish
                  </a>. Once you've flagged some articles you can access those flagged articles on this page.</p>
                <p className="font-size-l">For more information on how to flag articles, please watch the short video
                  below.</p>
                <iframe src="https://fast.wistia.net/embed/iframe/drpbqf2ymn?" allowTransparency="true" scrolling="no"
                        className="wistia_embed" name="wistia_embed" allowFullScreen="allowfullscreen"
                        mozallowfullscreen="mozallowfullscreen" webkitallowfullscreen="webkitallowfullscreen"
                        oallowfullscreen="oallowfullscreen" msallowfullscreen="msallowfullscreen" frameBorder="0"
                        height="404" width="644"/>
              </div>
              : <div className="pad-l-s flagged-items">
                  <ul className="list">
                    {categoryList}
                  </ul>
                </div>
          }
          </div> : 
          <div className="col col2">
            {
              // if there's only one, it's the uncategorized folder. if it's empty, show the video.
              this.props.sideBarCategories.length === 1 && this.props.categories[0]?.id == 0 && this.props.categories[0]?.flaggings?.length == 0
                ?
                <div className="flagged-intro copy">
                  <h2>How To Use Flagged Items</h2>
                  <p className="font-size-l">Flagging items within the platform lets you save the articles for later
                    reference. This could be to read later or to use to publish custom newsletters via <a href="/publish">Publish
                    </a>. Once you've flagged some articles you can access those flagged articles on this page.</p>
                  <p className="font-size-l">For more information on how to flag articles, please watch the short video
                    below.</p>
                  <iframe src="https://fast.wistia.net/embed/iframe/drpbqf2ymn?" allowTransparency="true" scrolling="no"
                    className="wistia_embed" name="wistia_embed" allowFullScreen="allowfullscreen"
                    mozallowfullscreen="mozallowfullscreen" webkitallowfullscreen="webkitallowfullscreen"
                    oallowfullscreen="oallowfullscreen" msallowfullscreen="msallowfullscreen" frameBorder="0"
                    height="404" width="644" />
                </div>
                :
                this.props.categories?.length == 0 ?
                  (<p className="flag-select"> Select any folders from the list to view the flaggings </p>)
                  :
                  (isEmptyCategoriesSelected ? <p className="flag-select">Empty Folder(s) Selected</p>
                    :
                    <div className="pad-l-s flagged-items">
                      <ul className="list">
                        {categoryList}
                      </ul>
                    </div>)
            }
          </div>
        }
      </div>

    const confirmModalMessage =
      <div>
        <div>{`Are you sure you want to ${this.props.bulkAction} these selected folders?`}</div>
        <div>This is an irreversible action.</div>
        {
          this.props.bulkAction === BULK_ACTIONS.DELETE && this.props.selectedCategoryIds.includes(0) &&
          <div>Please note that the "Uncategorized" folder can't be deleted, but will be cleared.</div>
        }
      </div>

    const confirmModal = this.props.bulkAction &&
      <ConfirmModal
        message={confirmModalMessage}
        okHandler={() => this.handleActionOkClick()}
        cancelHandler={() => this.props.setBulkAction(null)}
      />

    const loader = this.props.isLoading &&
      <LoadingOverlay/>

    return (
      <div>
        {loader}
        {content}
        {confirmModal}
        {createFlagFolderModal}
      </div>
    )
  }

  handleCreateFolderNameChange(newCategoryName) {
    this.setState({newCategoryName})
  }

  handleCreateFolderOkClick() {
    this.props.createCategory({
      name: this.state.newCategoryName,
      parentId: this.props.createCategoryModalData.parentId || undefined,
    })
  }

  handleSidebarFilterChange(evt) {
    const value = evt.target.value
    this.setState(
      {sidebarFilterValue: value},
      () => this.deselectHiddenCategories()
    )
  }

  handleShowEmptyFoldersClick() {
    const {sidebarIncludeEmpty} = this.state
    this.setState(
      {sidebarIncludeEmpty: !sidebarIncludeEmpty},
      () => this.deselectHiddenCategories()
    )
  }

  handleSelectAllCategories(checked) {
    if (this.props.pageVersion == 'old') {
      const filteredCategories = this.getFilteredCategories()
      let ids = []
      if (checked) {
        ids = filteredCategories.map(category => category.id)
      }
      this.props.setSelectedCategoryIds(ids)
    } else {
      const filteredCategories = this.getFilteredSideBarCategories()
      let ids = []
      if (checked) {
        ids = filteredCategories.map(category => category.id)
      }
      this.props.setSelectedCategoryIds(ids)
    }
  }

  handleCategorySelect(categoryId, checked) {
    let ids = [...this.props.selectedCategoryIds]
    if (checked) {
      ids.push(categoryId)
    } else {
      ids = ids.filter(id => id !== categoryId)
    }
    this.props.setSelectedCategoryIds(ids)
  }

  handleActionSelectChange(actionName) {
    this.props.setBulkAction(actionName)
  }

  handleActionOkClick() {
    const {bulkAction} = this.props
    if (bulkAction === BULK_ACTIONS.DELETE) {
      this.props.deleteCategories(this.props.selectedCategoryIds)
    } else if (bulkAction === BULK_ACTIONS.CLEAR) {
      this.props.clearCategories(this.props.selectedCategoryIds)
    }
  }

  categoryMatchesFilter(category) {
    return (
      !this.state.sidebarFilterValue ||
      category.name.toLowerCase().indexOf(this.state.sidebarFilterValue.toLowerCase()) !== -1
    )
  }

  deselectHiddenCategories() {
    if (this.props.pageVersion == 'old') {
      const filteredCategories = this.getFilteredCategories()
      const filteredCategoryIds = filteredCategories.map(c => c.id)
      let ids = [...this.props.selectedCategoryIds].filter(id => filteredCategoryIds.includes(id))
      this.props.setSelectedCategoryIds(ids)
    } else {
      const filteredCategories = this.getFilteredSideBarCategories()
      const filteredCategoryIds = filteredCategories.map(c => c.id)
      let ids = [...this.props.selectedCategoryIds].filter(id => filteredCategoryIds.includes(id))
      this.props.setSelectedCategoryIds(ids)
    }
  }

  getFilteredCategories() {
    return this.props.categories.filter(category => {
      return (
        this.categoryMatchesFilter(category) &&
        (
          this.state.sidebarIncludeEmpty ||
          category.flaggings.length > 0
        )
      )
    })
  }

  areAllVisibleSelected() {
    if (this.props.pageVersion == 'old') {
      const filteredCategories = this.getFilteredCategories()
      const { selectedCategoryIds } = this.props
      return filteredCategories.length === selectedCategoryIds.length
    } else {
      const filteredCategories = this.getFilteredSideBarCategories()
      const { selectedCategoryIds } = this.props
      return filteredCategories.length === selectedCategoryIds.length
    }    
  }

  getSortedFilteredCategories() {
    if (this.props.pageVersion == 'old') {
      let categories = []
      this.getFilteredCategories().forEach(category => {
        if (!category.parent) {
          categories.push(category)
          const children = this.getChildCategories(category.id)
          categories.push(...children)
        }
      })
      return categories
    } else {
      let values = []
      const data = this.getFilteredCategories()
      data.forEach(category => {
        if (!category.parent) {
          values.push(category)
          const children = this.getChildCategories(category.id, data)
          values.push(...children)
        }
        else {
          const idToCheck = category.parent && category.parent.id
          const categoryExists = data.some(cat => cat.id === idToCheck)
          if (!categoryExists) {
            values.push(category)
          }
        }
      })
      return values
    }
  }

  getChildCategories(parentId, data) {
    if (this.props.pageVersion == 'old') {
      return this.getFilteredCategories().filter(category => {
        return category.parent && category.parent.id === parentId
      })
    } else {
      const result = data.filter(category => {
        return category.parent && category.parent.id === parentId
      })

      return result
    }
  } 

  getFilteredSideBarCategories() {
    if (this.props.pageVersion == 'old') {
      return this.props.sideBarCategories.filter(category => {
        return (
          this.categoryMatchesFilter(category) &&
          (
            this.state.sidebarIncludeEmpty ||
            category.flaggingsCount > 0
          )
        )
      })
    } else {
      return this.props.sideBarCategories.filter(category => {
        return (
          this.categoryMatchesFilter(category) &&
          (
            this.state.sidebarIncludeEmpty ||
            category.flaggingsCount > 0
          )
        )
      })
    }
  }

  getChildSideBarCategories(parentId) {
    return this.getFilteredSideBarCategories().filter(category => {
      return category.parent && category.parent.id === parentId
    })
  }

  getSortedFilteredSideBarCategories() {
    let categories = []
    this.getFilteredSideBarCategories().forEach(category => {
      if (!category.parent) {
        categories.push(category)
        const children = this.getChildSideBarCategories(category.id)
        categories.push(...children)
      }
    })
    let data = this.getSideBarCategoriesHasChild(categories)
    return data
  }

  combineById(combined, opened) {
    const combinedMap = new Map(combined?.map(item => [item.id, item]));
    opened?.forEach(folder => {
      combinedMap?.set(folder.id, { ...combinedMap?.get(folder.id), ...folder });
    });
    return Array.from(combinedMap?.values());
  }

  getSideBarCategoriesHasChild(data) {
    const modifiedData = [];
    data.forEach(item => {
      const newItem = Object.assign({}, item);
      newItem.hasChild = data.some(child => child.parent && child.parent.id === item.id);
      modifiedData.push(newItem);
    });
    return modifiedData;
}

  handleArrowClick(id, sideBarValues) {
    const data = sideBarValues
    const folderStatus = {}
    const folderStatus2 = []
    folderStatus.id = id
    data.forEach(item => {
      if (item.parent && item.parent.id === id) {
        folderStatus2.push({ "id": item.id, "show": !item.show })
        item.show = !item.show
      }
      if (item.id == id) {
        folderStatus.isArrowOpen = !item.isArrowOpen
        item.isArrowOpen = !item.isArrowOpen
      }
    });
    const combinedFolderStatus = [folderStatus, ...folderStatus2];
    const combinedResult = this.combineById(combinedFolderStatus, this.props.foldersOpened);
    this.props.setFoldersOpened(combinedResult)
    this.props.setSideBarCategories(data)
  }

  handleExpandAllFolders(checked, sideBarValues){
    const data = sideBarValues
    if (checked) {
      const folderStatus = []
      data.forEach(category => {
        if (!category.parent) {
          folderStatus.push({ ...category, "show": true, "isArrowOpen": true })
        }
        else {
          folderStatus.push({ ...category, "show": true })
        }
      });
      this.props.setSideBarCategories(folderStatus)
    } else {
      const folderStatus = []
      data.forEach(category => {
        if (!category.parent) {
          folderStatus.push({ ...category, "show": true, "isArrowOpen": false })
        }
        else {
          folderStatus.push({ ...category, "show": false })
        }
      });
      this.props.setSideBarCategories(folderStatus)
    }
  }
}