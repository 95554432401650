import deepCopy from 'deepcopy'
import {all, put, takeLatest, select} from 'redux-saga/effects'

import * as notifications from 'app/global/notifications'
import {handleSagaError} from 'app/utils/errors'
import {actionApiRequest} from 'app/utils/sagas'

import * as api from './email-notifications-api'
import * as actions from './email-notifications-actions'
import * as constants from './email-notifications-constants'
import * as selectors from "./email-notifications-selectors"
import { getChunkMessage, getValidandInvalidEmailList } from 'app/utils'

function* saveEmailDelivery(action) {
  yield* actionApiRequest(action, api.callSaveEmailDelivery)
}
function* addEmailPrefixSuffix(action) {
  yield* actionApiRequest(action, api.callAddEmailPrefixSuffix)
}
function* applyEmailDeliverySettings(action) {
  yield* actionApiRequest(action, api.callApplyEmailDeliverySettings)
}

function* applyCategoryOrdersSettings(action) {
  yield* actionApiRequest(action, api.callApplyCategoryOrdersSettings)
}
function* saveEmailAlertSettings(action) {
  yield* actionApiRequest(action, api.callSaveEmailAlertSettings)
}
function* getCCEmailsInsights(action) {
  yield* actionApiRequest(action, api.callGetCCEmailsInsights)
}
function* getCCEmailsEsg(action) {
  yield* actionApiRequest(action, api.callGetCCEmailsEsg)
}
function* getCCEmailsBase(action) {
  yield* actionApiRequest(action, api.callGetCCEmailsBase)
}
function* updateCCEmails(action) {
  yield* actionApiRequest(action, api.callUpdateCCEmails)
}
function* getInsightsNoticeConfigs(action) {
  yield* actionApiRequest(action, api.callGetInsightsNoticeConfigs)
}
function* getEsgNoticeConfigs(action) {
  yield* actionApiRequest(action, api.callGetEsgNoticeConfigs)
}
function* saveInsightsNoticeConfigs(action) {
  yield* actionApiRequest(action, api.callSaveInsightsNoticeConfigs)
}
function* saveEsgNoticeConfigs(action) {
  yield* actionApiRequest(action, api.callSaveEsgNoticeConfigs)
}
function* getBaseNoticeConfigs(action) {
  yield* actionApiRequest(action, api.callGetBaseNoticeConfigs)
}
function* getEmailAlertSettings(action) {
  yield* actionApiRequest(action, api.callGetEmailAlertSettings)
}
function* saveBaseNoticeConfigs(action) {
  yield* actionApiRequest(action, api.callSaveBaseNoticeConfigs)
}
function* updateCategoryOrder(action) {
  yield* actionApiRequest(action, api.callUpdateCategoryOrder)
}
function* updateCategoryDefaults(action) {
  yield* actionApiRequest(action, api.callUpdateCategoryDefaults)
}
function* updateContentSettings(action) {
  yield* actionApiRequest(action, api.callUpdateContentSettings)
}
function* refreshBaseNoticeConfigs(action) {
  yield* removeSelectedConfigs(action)
  yield put(actions.api.getEmailAlertSettings.request(action.payload.requestData))
}
function* removeSelectedConfigs(action) {
  const reqData = action.payload.requestData
  const configIds = reqData && reqData.configIds
    ? reqData.configIds
    : null
  const frequencyChange = reqData && reqData.field
    ? reqData.field === constants.FREQUENCY
    : false
  const firmAdminSelectedConfigIds = yield select(selectors.getFirmAdminSelectedConfigIds)
  if (frequencyChange && firmAdminSelectedConfigIds.length){
    const configIdsIntersection = firmAdminSelectedConfigIds.filter(id => configIds.includes(id))
    yield put(actions.removeSelectedConfigs(configIdsIntersection))
  }
  yield* successNotification(action)
}

function* changeSendSampleTokens(action) {

  if (!action.payload.label) return

  const emailAddress = action.payload.label
  let recipientsToSend = [...action.payload.recipients]

  if (action.payload.action === 'delete') {
    recipientsToSend = action.payload.recipients.filter(
      email => email !== emailAddress)
  } else {
    recipientsToSend.push(...emailAddress.split(',').map(email => email.trim()))
    let [validEmails,invalidEmails,repeatingIds] = getValidandInvalidEmailList(recipientsToSend)
    recipientsToSend = validEmails
    if (repeatingIds && repeatingIds.length > 0) {
      let message = getChunkMessage(repeatingIds)
      message = "[" + message + "] " + "has been already added to your send sample email alerts";
      yield put(
        notifications.actions.showNotification({
          type: "error",
          message: message,
        }),
      );
    }
    if (invalidEmails && invalidEmails.length > 0) {
      let message = getChunkMessage(invalidEmails)
      message = "[" + message + "] " + "is not valid email address";
      yield put(
        notifications.actions.showNotification({
          type: "error",
          message: message,
        }),
      );
    }
  }
  yield put(actions.changeSendSampleRecipients(recipientsToSend))

}

function* sendSampleEmail(action) {

  //TODO: fix so emails in array aren't altered with underscores replacing dots, @, etc...
  // for now just put in object to preserve characters

  if (!action.payload.recipients) return

  let recipientsList = []
  action.payload.recipients.map(email => {
    recipientsList.push({email:email})
  })
  const actionCopy = deepCopy(action)
  actionCopy.payload.recipients = recipientsList

  yield* actionApiRequest(actionCopy, api.callSendSampleEmail)
}

function* successNotification(action) {
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: action.payload.response.body.message,
    })
  )
}
function* failureNotification(action) {
  yield* handleSagaError(action.payload)
}

function* refreshNoticeConfigs(action) {
  if (!action.payload.requestData.type
    && !action.payload.requestData.noticeFrequency
    && !action.payload.response.body) return

  if (action.payload.response.body.notificationType ===
    constants.INSIGHTS_NOTIFICATION_TYPE) {
    yield put(
      actions.api.getInsightsNoticeConfigs.request(action.payload.requestData)
    )
    yield put(
      actions.api.getCCEmailsInsights.request(action.payload.requestData)
    )
  } else if (action.payload.response.body.notificationType ===
    constants.BASE_NOTIFICATION_TYPE){
    yield put(
      actions.api.getBaseNoticeConfigs.request(action.payload.requestData)
    )
    yield put(
      actions.api.getCCEmailsBase.request(action.payload.requestData)
    )
  } else if (action.payload.response.body.notificationType === constants.ESG_NOTIFICATION_TYPE) {
    yield put(
      actions.api.getEsgNoticeConfigs.request(action.payload.requestData)
    )
    yield put(
      actions.api.getCCEmailsEsg.request(action.payload.requestData)
    )
  }
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: action.payload.response.body.message,
    })
  )
}
function* getNotificationSettings(action) {
  yield* actionApiRequest(action, api.callGetNotificationSettings)
}
function* saveNotificationSettings(action) {
  yield* actionApiRequest(action, api.callSaveNotificationSettings)
}
export default function*() {
  yield all([
    takeLatest(actions.changeSendSampleTokens, changeSendSampleTokens),
    takeLatest(actions.api.saveEmailDelivery.request, saveEmailDelivery),
    takeLatest(actions.api.saveEmailDelivery.success, successNotification),
    takeLatest(actions.api.saveEmailDelivery.failure, failureNotification),
    takeLatest(actions.api.addEmailPrefixSuffix.request, addEmailPrefixSuffix),
    takeLatest(actions.api.addEmailPrefixSuffix.success, successNotification),
    takeLatest(actions.api.addEmailPrefixSuffix.failure, failureNotification),
    takeLatest(actions.api.applyEmailDeliverySettings.request, applyEmailDeliverySettings),
    takeLatest(actions.api.applyEmailDeliverySettings.success, successNotification),
    takeLatest(actions.api.applyEmailDeliverySettings.failure, failureNotification),
    takeLatest(actions.api.applyCategoryOrdersSettings.request, applyCategoryOrdersSettings),
    takeLatest(actions.api.applyCategoryOrdersSettings.success, successNotification),
    takeLatest(actions.api.applyCategoryOrdersSettings.failure, failureNotification),
    takeLatest(actions.api.saveEmailAlertSettings.request,
      saveEmailAlertSettings),
    takeLatest(actions.api.saveEmailAlertSettings.success,
      refreshNoticeConfigs),
    takeLatest(actions.api.saveEmailAlertSettings.failure,
      failureNotification),
    takeLatest(actions.api.getCCEmailsInsights.request, getCCEmailsInsights),
    takeLatest(actions.api.getCCEmailsInsights.failure, failureNotification),
    takeLatest(actions.api.getCCEmailsEsg.request, getCCEmailsEsg),
    takeLatest(actions.api.getCCEmailsEsg.failure, failureNotification),
    takeLatest(actions.api.getCCEmailsBase.request, getCCEmailsBase),
    takeLatest(actions.api.getCCEmailsBase.failure, failureNotification),
    takeLatest(actions.api.sendSampleEmail.request, sendSampleEmail),
    takeLatest(actions.api.sendSampleEmail.success, successNotification),
    takeLatest(actions.api.sendSampleEmail.failure, failureNotification),
    takeLatest(actions.api.updateCCEmails.request, updateCCEmails),
    takeLatest(actions.api.updateCCEmails.success, successNotification),
    takeLatest(actions.api.updateCCEmails.failure, failureNotification),
    takeLatest(actions.api.getInsightsNoticeConfigs.request, getInsightsNoticeConfigs),
    takeLatest(actions.api.getInsightsNoticeConfigs.failure, failureNotification),
    takeLatest(actions.api.getEsgNoticeConfigs.request, getEsgNoticeConfigs),
    takeLatest(actions.api.getEsgNoticeConfigs.failure, failureNotification),
    takeLatest(actions.api.getBaseNoticeConfigs.request, getBaseNoticeConfigs),
    takeLatest(actions.api.getBaseNoticeConfigs.failure, failureNotification),
    takeLatest(actions.api.getEmailAlertSettings.request, getEmailAlertSettings),
    takeLatest(actions.api.getEmailAlertSettings.failure, failureNotification),
    takeLatest(actions.api.saveInsightsNoticeConfigs.request, saveInsightsNoticeConfigs),
    takeLatest(actions.api.saveInsightsNoticeConfigs.success, successNotification),
    takeLatest(actions.api.saveInsightsNoticeConfigs.failure, failureNotification),
    takeLatest(actions.api.saveEsgNoticeConfigs.request, saveEsgNoticeConfigs),
    takeLatest(actions.api.saveEsgNoticeConfigs.success, successNotification),
    takeLatest(actions.api.saveEsgNoticeConfigs.failure, failureNotification),
    takeLatest(actions.api.saveBaseNoticeConfigs.request, saveBaseNoticeConfigs),
    takeLatest(actions.api.saveBaseNoticeConfigs.success, refreshBaseNoticeConfigs),
    takeLatest(actions.api.saveBaseNoticeConfigs.failure, failureNotification),
    takeLatest(actions.api.updateCategoryOrder.request, updateCategoryOrder),
    takeLatest(actions.api.updateCategoryOrder.success, successNotification),
    takeLatest(actions.api.updateCategoryOrder.failure, failureNotification),
    takeLatest(actions.api.updateCategoryDefaults.request, updateCategoryDefaults),
    takeLatest(actions.api.updateCategoryDefaults.success, successNotification),
    takeLatest(actions.api.updateCategoryDefaults.failure, failureNotification),
    takeLatest(actions.api.updateContentSettings.request, updateContentSettings),
    takeLatest(actions.api.updateContentSettings.success, successNotification),
    takeLatest(actions.api.updateContentSettings.failure, failureNotification),
    takeLatest(actions.api.getNotificationSettings.request, getNotificationSettings),
    takeLatest(actions.api.getNotificationSettings.failure, failureNotification),
    takeLatest(actions.api.changeNotificationSettings.request, saveNotificationSettings),
    takeLatest(actions.api.changeNotificationSettings.success, successNotification),
    takeLatest(actions.api.changeNotificationSettings.failure, failureNotification),
  ])
}
