import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import styles from "./EsgInsights.less"
import classNames from "classnames"
import Dropdown from 'app/common/Dropdown'
import InputBlock from "app/common/InputBlock"
import Table from "app/common/Table"
import EsgDocumentList from './EsgDocumentList'
import MzBarChart from "app/common/charts/MzBarChart"
import {useSelector} from "react-redux"
import * as globalSelectors from 'app/global/global-selectors'
import * as selectors from 'app/search-results-page/search-results-page-selectors'

// TODO: implement skeleton loading for article sections

export default function EsgInsights({
  savedSearch,
  updateEsgDocuments,
}) {

  const appName = useSelector(globalSelectors.getAppName)
  const insightsLabel = useSelector(globalSelectors.getInsightsLabel)
  const userIsInsightsEnabled = useSelector(globalSelectors.getInsightsEnabled)
  const currentUser = useSelector(globalSelectors.getCurrentUser)
  const esgData = useSelector(selectors.getEsgData)
  const {pillars, companyScores, industryChartData, companyPillarStories, industryPillarStories, sidebarContent,
    staticSidebarContent} = esgData

  const [pillarFilterValue, setPillarFilterValue] = useState(8)
  const [docSortValue, setDocSortValue] = useState('sortVolume')

  // const blankOption = {
  //   label: '--',
  //   value: null,
  // }

  const pillarOptions = pillars
    ? [
        // blankOption,
        ...pillars.map(
          p => {
            const pillarInfo = p.split('-')
            return {value: parseInt(pillarInfo[0]), label: pillarInfo[1]}
          }
        )
      ]
    : [{
      label: 'Planet',
      value: 8,
    }]

  const sortOptions =  [
    {value: 'sortVolume', label: 'Volume'},
    {value: 'sortRecency', label: 'Recency'},
  ]

  const handlePillarFilterChange = (pillar, type) => {
    // These are combined for now anyway, so this is irrelevant
    if (type === 'industry') {
      setPillarFilterValue(pillar)
    }
    if (type === 'company') {
      setPillarFilterValue(pillar)
    }
  }

  // TODO: include a few other companies in the table to compare with?
  const esgTableData = companyScores
    ? [
        {
          name: companyScores.companyName,
          industry: companyScores.industryName,
          overallScore: companyScores.healthScore,
        },
      ]
    : null

  const esgTable = userIsInsightsEnabled && esgTableData
    ? <Table
        data={esgTableData}
      >
        <Table.Column
          name="name"
          label="Company"
          baseWidth={'23%'}
          growRatio={1}
          shrinkRatio={1}
          cellContents={company => company.name}
        />
        <Table.Column
          name="industry"
          label="Industry"
          baseWidth={'24%'}
          growRatio={1}
          shrinkRatio={1}
          cellContents={company => company.industry}
        />
        <Table.Column
          name="overall"
          label="Over&shy;all"
          baseWidth={'9%'}
          growRatio={0}
          shrinkRatio={0}
          cellContents={company => company.overallScore}
        />
        {
          companyScores.companyPillarScores.map((item, idx) => (
            <Table.Column
              key={idx}
              name={item.pillar.toLowerCase()}
              label={item.pillar}
              baseWidth={'11%'}
              minWidth='119px'
              growRatio={0}
              shrinkRatio={0}
              cellContents={item.score}
            />
          ))
        }
      </Table>
    : null

  const industryChart = industryChartData
    ? <MzBarChart
        chartData={industryChartData}
        className={styles.esgChartWrapper}
        chartMargin={{top: 20, right: 20, bottom: 20, left: 40}}
        defaultBarWidth={20}
        toolTipText={'ESG Health Score'}
        showPercentage={false}
        isVerticalLayout={true}
      />
    : null

  const industryTitle = companyScores
    ? `${companyScores.industryName} ESG Health`
    : null

  const percentileText = companyScores
    ? `${companyScores.percentileText}`
    : null

  const industryNewsTitle = companyScores
    ? `${companyScores.industryName} News`
    : null

  // TODO: pull a few of the most recent articles from the topic esg url below
  //  (maybe there is an api for it?)
  const makeSidebarSection = content => {

    const stuff = content.map((item, idx) => (
      <div
        key={idx}
      >
        <div className={classNames(styles.title)}>{item.title}</div>
        <div className={classNames(styles.content)}>{item.content}</div>
        <div className={classNames(styles.link)}>
          <a target='_blank' href={item.link}>{item.linkText}</a>
        </div>
      </div>
    ))

    return (<React.Fragment> {stuff} </React.Fragment>)
  }

  const dynamicSidebarSection = makeSidebarSection(sidebarContent)
  const staticSidebarSection = makeSidebarSection(staticSidebarContent)

  const getEsgDocs = pillarStories => {
    if (pillarStories === null) {
      return []
    }

    const esgDocuments = pillarStories
      .filter(pillarStory => {
        const filterIsBlank = pillarFilterValue === null
        const topicIdIsRight = pillarStory.topicId === pillarFilterValue
        return filterIsBlank || topicIdIsRight
      })
      .map(pillarStory => pillarStory.document)
      .filter(doc => doc && doc.id)
      .map(doc => ({...doc, publishedAt: new Date(doc.publishedAt)}))

    if (docSortValue === 'sortRecency') {
      esgDocuments.sort((a, b) => b.publishedAt - a.publishedAt)
    }

    return esgDocuments
  }
  const companyEsgDocuments = getEsgDocs(companyPillarStories)
  const industryEsgDocuments = getEsgDocs(industryPillarStories)

  const insightsEnabledSection = userIsInsightsEnabled
    ? <React.Fragment>
        <div className={classNames(styles.title)}>
          {`${savedSearch.name} News`}
        </div>
        <div className={classNames(styles.newsSection)}>
          <InputBlock label="Filter by Pillar"
          >
            <Dropdown
              options={pillarOptions}
              onChange={(value) => handlePillarFilterChange(value, 'company')}
              value={pillarFilterValue}
            />
          </InputBlock>
          <InputBlock label="Sort Stories"
          >
            <Dropdown
              options={sortOptions}
              onChange={(value) => setDocSortValue(value)}
              value={docSortValue}
            />
          </InputBlock>
          <div className={classNames(styles.articleSection)}>
            <EsgDocumentList
              search={savedSearch}
              topLevelDocuments={companyEsgDocuments}
              onFlaggingStateChange={updateEsgDocuments}
            />
          </div>
        </div>
        <div className={classNames(styles.esgTable)}>
          {esgTable}
        </div>
      </React.Fragment>
    : <div className={classNames(styles.insightsEsgTeaser)}>
        <div>{`ESG Company Scores and Trends are currently only available with access to our
          ${appName} ${insightsLabel} product.`}
        </div>
        <div className={classNames(styles.link)}>
          <span><a href="https://insights.manzama.com/">Click here</a></span>
          <span> to learn more.</span>
        </div>
      </div>

  return (
    <div className={classNames(styles.esgPage, 'esg-page')}>
      <div className={styles.headerBar}>
        ESG News & Trends
      </div>

      <div className={classNames(styles.esgWrapper)}>

        {/*Main body content*/}
        <div className={classNames(styles.esgBody)}>

          {/*Industry Health Section*/}
          <div className={classNames(styles.bodySection)}>
            <div className={classNames(styles.percentileText)}>
              {percentileText}
            </div>

            <div className={classNames(styles.title)}>
              {industryTitle}
            </div>
            <div>
              {industryChart}
            </div>
          </div>

          {/*News Section*/}
          <div className={classNames(styles.bodySection)}>
            <div className={classNames(styles.title)}>
              {industryNewsTitle}
            </div>
            <div className={classNames(styles.newsSection)}>
              <InputBlock label="Filter by Pillar">
                <Dropdown
                  options={pillarOptions}
                  onChange={(value) => handlePillarFilterChange(value, 'industry')}
                  value={pillarFilterValue}
                />
              </InputBlock>
              <InputBlock label="Sort Stories"
              >
                <Dropdown
                  options={sortOptions}
                  onChange={(value) => setDocSortValue(value)}
                  value={docSortValue}
                />
              </InputBlock>
              <div className={classNames(styles.articleSection)}>
                <EsgDocumentList
                  search={savedSearch}
                  topLevelDocuments={industryEsgDocuments}
                  onFlaggingStateChange={updateEsgDocuments}
                />
              </div>
            </div>
          </div>

          <div className={classNames(styles.bodySection)}>
            {insightsEnabledSection}
          </div>

        </div>

        {/*Right Sidebar*/}
        <div className={classNames(styles.esgSidebar)}>
          <div className={classNames(styles.sidebarSection)}>
            {dynamicSidebarSection}
          </div>
          <div className={classNames(styles.sidebarSection)}>
            {staticSidebarSection}
          </div>
        </div>

      </div>
    </div>
  )
}
EsgInsights.propTypes = {
  savedSearch: PropTypes.object.isRequired,
  updateEsgDocuments: PropTypes.func.isRequired,
}
