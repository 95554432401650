import classNames from 'classnames'
import PropTypes from 'prop-types'
import {groupBy, uniq} from 'ramda'
import React from 'react'

import LoadingSpinner from 'app/common/LoadingSpinner'

import styles from './ProfilePreferencesChecklist.less'

const NO_GROUP = 'NO_GROUP'
const itemGroup = item => item.group || NO_GROUP


export default function Checklist({items}) {
  const groupedItems = groupBy(itemGroup, items)
  // Make sure we show groups in the correct order. Non-grouped items are always
  // displayed first.
  let groupNames = uniq(items.map(itemGroup))
  if (groupNames.includes(NO_GROUP)) {
    groupNames = [
      NO_GROUP,
      ...groupNames.filter(group => group !== NO_GROUP),
    ]
  }
  return (
    <div className={styles.checklist}>
      {groupNames.map(group =>
        <React.Fragment key={group}>
          {group !== NO_GROUP &&
            <div className={classNames(styles.row, styles.group)}>{group}</div>
          }
          {groupedItems[group].map(item =>
            <label className={styles.row} key={item.key}>
              {item.isLoading ? (
                <LoadingSpinner className={styles.spinner} />
              ) : (
                <input
                  type="checkbox"
                  checked={item.isChecked}
                  onChange={event => item.onChange(event.target.checked)}
                />
              )}
              <span>{item.label}</span>
            </label>
          )}
        </React.Fragment>
      )}
    </div>
  )
}
Checklist.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any.isRequired,
      label: PropTypes.node.isRequired,
      isGroup: PropTypes.bool,
      isLoading: PropTypes.bool,
      isChecked: PropTypes.bool,
      onChange: PropTypes.func,
    }).isRequired
  ).isRequired,
}
