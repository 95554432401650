import { createAction } from 'redux-actions'

// Prefix action names with SEARCH_LOOKUP
const createSearchLookupAction = name => createAction(`SEARCH_LOOKUP_${name}`)

export const setSearchKeywords = createSearchLookupAction('SET_SEARCH_KEYWORDS')
export const setSelectedSearchCategories = createSearchLookupAction('SET_SELECTED_SEARCH_CATEGORIES')
export const setSelectedBrowseCategory = createSearchLookupAction('SET_SELECTED_BROWSE_CATEGORY')
export const setSelectedSearchIds = createSearchLookupAction('SET_SELECTED_SEARCH_IDS')
export const setNameMatchOnly = createSearchLookupAction('SET_NAME_MATCH_ONLY')
export const setExactMatch = createSearchLookupAction('SET_EXACT_MATCH')
export const resetSearch = createSearchLookupAction('RESET_SEARCH')
export const setQueryModalData = createSearchLookupAction('SET_QUERY_MODAL_DATA')

export const fetchSearches = createSearchLookupAction('FETCH_SEARCHES')
export const fetchSearchesComplete = createSearchLookupAction('FETCH_SEARCHES_COMPLETE')
export const fetchSearch = createSearchLookupAction('FETCH_SEARCH')
