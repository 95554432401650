import PropTypes from 'prop-types'
import React from 'react'
import styles from './DashboardSidebar.less'
import classNames from "classnames"
import LoadingSpinner from "app/common/LoadingSpinner"
import {useSelector} from "react-redux"
import * as selectors from 'app/dashboard/dashboard-selectors'
import * as globalSelectors from 'app/global/global-selectors'
import {getFirmCategoryLabel} from 'app/global/firm-category-label'

function DashboardSidebar({
  sections,
  searches,
  activeSectionName,
  activeSearchId,
  activeNavChange,
}) {

  const getSearchesForCategory = (categories, searchData) => {
    const searchesForCategory = searches.filter(ss => categories.includes(ss.category))
    const searchIdsWithDocuments = searchData.map(sd => sd.savedSearchId)
    return (
      searchesForCategory.filter(ss => searchIdsWithDocuments.includes(ss.id))
    )
  }

  const searchNames = (searchesForCategory, sectionName) => {
    return(
      searchesForCategory.map(ss =>
      <div
        key={`sidebar-search-${ss.id}`}
        className={classNames(styles.searchName, {[styles.activeSearch]: ss.id === activeSearchId})}
        onClick={() => activeNavChange(null, ss.id, sectionName)}
      >
        <a href={`#${ss.name}`}>{ss.name}</a>
      </div>
      )
    )
  }

  const areAllSectionsLoaded = useSelector(selectors.getAreAllSectionsLoaded)
  const currentFirm = useSelector(globalSelectors.getCurrentFirm)
  const sidebarSections = sections.map(sec => {
    if (!sec.searchData){return}
    const searchesForCategory = getSearchesForCategory(sec.categories, sec.searchData)
    const firstSearchId = searchesForCategory.map(ss => ss.id)[0]
    return (
      <div
        key={`sidebar-section-${sec.section}`}
        className={styles.sidebar}
        id={`sidebar-section-${sec.section}`}
      >
        <div
          className={classNames(styles.searchCategory,
            {[styles.activeSectionName]: sec.sectionName === activeSectionName},
          )}
          onClick={() => activeNavChange(null, firstSearchId, sec.sectionName)}
        >
          <a href={`#${sec.sectionName}`}>
            {getFirmCategoryLabel({category: sec.sectionName, currentFirm})}
          </a>
        </div>
        { sec.sectionName === activeSectionName &&(
          <div className={styles.searchNamesContainer}>
            {searchNames(searchesForCategory, sec.sectionName)}
          </div>
        )}
      </div>
    )
  })

  return (
      <div className={styles.sidebarWrapper}>
        {sidebarSections}
        {!areAllSectionsLoaded &&(
          <LoadingSpinner className={styles.spinner} />
        )}
      </div>
  )
}
DashboardSidebar.propTypes = {
  sections: PropTypes.array.isRequired,
  searches: PropTypes.array.isRequired,
  activeSectionName: PropTypes.string,
  activeSearchId: PropTypes.number,
}
export default DashboardSidebar
