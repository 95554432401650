export const STATE_PROP_NAME = 'dashboard'


export const CHART_CATEGORIES = [
  'client', 'tracker'
]

export const CHART_CATEGORY_LOOKUP = {
  'Company': 'client',
  'Topic': 'tracker'
}
