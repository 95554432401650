import Promise from 'bluebird'

import { init as headerInit } from './global/header'
import { init as profileSearchesInit } from './profile/searches'
import { init as profileSourcesInit } from './profile/sources'
import { init as profileAccountInit } from './profile/account'
import { init as profilePreferencesInit } from './profile/preferences'
import { init as profileGroupMembersInit } from './profile/group-members'
import { init as navMenuInit } from './global/NavMenu'
import { init as additionalServicesInit } from
    './firm-admin/additional-services'
import { init as searchResultsActionsInit } from
    './reusable/SearchResultsActions'
import { init as homeSearchBarInit } from './home/search-bar'
import { init as helpInit } from './help'

// admin pages
import { init as sourcesAdminInit } from './firm-admin/sources'
import { init as searchesAdminInit } from './firm-admin/searches'
import { init as usersAdminInit } from './firm-admin/users'
import { init as usageAdminInit } from './firm-admin/usage'
import { init as settingsAdminInit } from './firm-admin/settings'

import { init as tier2Init } from './tier2'
import { init as emailDeliveryInit } from
    './email-notifications/email-delivery/EmailDelivery'
import { init as baseNotificationsInit } from
    './email-notifications/base-notifications/BaseNotifications'
import { init as insightsEmailSettingsInit } from
    './email-notifications/insights-email-settings/InsightsEmailSettings'
import { init as dunAndBradstreetSearchInit } from './one-off/dandb-search'
import { init as advancedSearchInit } from './advanced-search'
import { init as djangoInteractionBarInit } from './django-interaction-bar'
import { init as flaggedItemsInit } from './flagged-items'
import { init as misInit } from './mis'
import { init as esgInit } from './esg'
import { init as searchResultsInit } from './search-results-page'
import { init as dashboardInit } from './dashboard'
import { init as comparisonInit } from './comparison-page'
import { init as profileBuilderInit } from './profile-builder'
import { init as profileRefreshInit } from './profile-refresh'
import notificationsInit from './global/notifications/notifications-init'
import { init as pendoStatsInit } from './firm-stats/pendo-stats'
import { init as esgEmailSettingsInit } from 'app/email-notifications/esg-email-settings/EsgEmailSettings'

import { initModals } from './global/modals'

import { adjustFlaggedArticleCount } from './flagging'
import { notifyMock } from './global/notifications/notifications-legacy'

import './global/tooltips'
import './global/error-tracking'
import './global/metrics'

import 'css/base.less'

// Configure Bluebird
Promise.config({
  warnings: process.env.NODE_ENV !== 'production',
  longStackTraces: true,
  cancellation: true,
})

window.mzHeaderInit = headerInit
window.mzProfileSearchesInit = profileSearchesInit
window.mzProfileSourcesInit = profileSourcesInit
window.mzProfilePreferencesInit = profilePreferencesInit
window.mzProfileAccountInit = profileAccountInit
window.mzProfileGroupMembersInit = profileGroupMembersInit
window.mzNavMenuInit = navMenuInit
window.mzAdditionalServicesInit = additionalServicesInit
window.mzSearchResultsActionsInit = searchResultsActionsInit
window.mzHomeSearchBarInit = homeSearchBarInit
window.mzSourcesAdminInit = sourcesAdminInit
window.mzSearchesAdminInit = searchesAdminInit
window.mzUsersAdminInit = usersAdminInit
window.mzUsageAdminInit = usageAdminInit
window.mzSettingsAdminInit = settingsAdminInit
window.mzBaseNotificationsInit = baseNotificationsInit
window.mzTier2Init = tier2Init
window.mzEmailDeliveryInit = emailDeliveryInit
window.mzInsightsEmailSettingsInit = insightsEmailSettingsInit
window.mzDunAndBradstreetSearchInit = dunAndBradstreetSearchInit
window.mzAdvancedSearchInit = advancedSearchInit
window.mzDjangoInteractionBarInit = djangoInteractionBarInit
window.mzFlaggedItemsInit = flaggedItemsInit
window.mzMisInit = misInit
window.mzEsgInit = esgInit
window.mzSearchResultsInit = searchResultsInit
window.mzDashboardInit = dashboardInit
window.mzComparisonInit = comparisonInit
window.mzProfileBuilderInit = profileBuilderInit
window.mzProfileRefreshInit = profileRefreshInit
window.mzNotificationsInit = notificationsInit
window.mzPendoStatsInit = pendoStatsInit
window.mzHelpInit = helpInit
window.mzEsgEmailSettingsInit = esgEmailSettingsInit

window.mzNotify = notifyMock

initModals()

// Miscellaneous functions that need to be accessible from legacy code.
window.mzGlobalFunctions = {
  adjustFlaggedArticleCount,
}
