import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import InputBlock from 'app/common/InputBlock'
import SavedSearchInput from 'app/common/SavedSearchInput'
import TokenInput from 'app/common/TokenInput'
import LoadingOverlay from 'app/common/LoadingOverlay'
import { flattenFilterData, queryFromTerms } from 'app/utils/searches'

import { getSearchResults } from '../advanced-search-actions'
import { getAdvancedSearch } from '../advanced-search-selectors'

import './FilteredSearch.less'


@connect(
  createSelector(
    [getAdvancedSearch],
    (advancedSearch) => {
      return {
        isLoading: advancedSearch.isLoading,
      }
    }
  ),
  {
    getSearchResults,
  }
)
export default class FilteredSearch extends Component {
  state = {
    filters: {
      textAny: [],
      textAll: [],
      textExclude: [],
      contentExact: [],
      headlineAny: [],
      headlineAll: [],
      headlineExclude: [],
      introAny: [],
      introAll: [],
      introExclude: [],
    },
  }

  render() {
    const loader =
      this.props.isLoading
      ? <LoadingOverlay/>
      : null

    return (
      <div id="filtered-search">
        {loader}

        <div className="meta">
          <div className="col col1 border-right">Article Contents</div>
          <div className="col col2">Headlines</div>
        </div>

        <div className="col col1 border-right">
          <InputBlock label="Any of (or):">
            <SavedSearchInput
              onChange={values => this.handleSearchInputChange('textAny', values)}
            />
          </InputBlock>

          <InputBlock label="All of (and):">
            <SavedSearchInput
              onChange={values => this.handleSearchInputChange('textAll', values)}
            />
          </InputBlock>

          <InputBlock label="Exclude (not):">
            <SavedSearchInput
              onChange={values => this.handleSearchInputChange('textExclude', values)}
            />
          </InputBlock>

          <TokenInput
            inputLabel="Exact words or phrases:"
            tokenItems={this.state.filters.contentExact}
            updateTokensFunc={token => this.handleTokenInputChange('contentExact', token)}
            delimiters={['Enter', 'Tab', ',']}
            inputWidth={null}
          />
        </div>
        <div className="col col2">
          <TokenInput
            inputLabel="Any exact words/phrases (or):"
            tokenItems={this.state.filters.headlineAny}
            updateTokensFunc={token => this.handleTokenInputChange('headlineAny', token)}
            delimiters={['Enter', 'Tab', ',']}
            inputWidth={null}
          />

          <TokenInput
            inputLabel="All exact words/phrases (and):"
            tokenItems={this.state.filters.headlineAll}
            updateTokensFunc={token => this.handleTokenInputChange('headlineAll', token)}
            delimiters={['Enter', 'Tab', ',']}
            inputWidth={null}
          />

          <TokenInput
            inputLabel="Exclude (not):"
            tokenItems={this.state.filters.headlineExclude}
            updateTokensFunc={token => this.handleTokenInputChange('headlineExclude', token)}
            delimiters={['Enter', 'Tab', ',']}
            inputWidth={null}
          />
        </div>

        <div className="meta">
          <div className="col col1 border-right">&nbsp;</div>
          <div className="col col2">Headlines + Leading Text</div>
        </div>

        <div className="col col1">&nbsp;</div>

        <div className="col col2 border-left">
          <TokenInput
            inputLabel="Any exact words/phrases (or):"
            tokenItems={this.state.filters.introAny}
            updateTokensFunc={token => this.handleTokenInputChange('introAny', token)}
            delimiters={['Enter', 'Tab', ',']}
            inputWidth={null}
          />

          <TokenInput
            inputLabel="All exact words/phrases (and):"
            tokenItems={this.state.filters.introAll}
            updateTokensFunc={token => this.handleTokenInputChange('introAll', token)}
            delimiters={['Enter', 'Tab', ',']}
            inputWidth={null}
          />

          <TokenInput
            inputLabel="Exclude (not):"
            tokenItems={this.state.filters.introExclude}
            updateTokensFunc={token => this.handleTokenInputChange('introExclude', token)}
            delimiters={['Enter', 'Tab', ',']}
            inputWidth={null}
          />
        </div>

        <div className="buttons">
          <button
            className="button primary"
            onClick={() => this.runSearch()}
            disabled={this.filterCount() === 0}
          >Search</button>
        </div>
      </div>
    )
  }

  filterCount() {
    return Object.keys(this.state.filters).reduce((count, key) => {
      return count + this.state.filters[key].length
    }, 0)
  }

  handleSearchInputChange(key, values) {
    const filters = {...this.state.filters}
    filters[key] = values
    this.setState({filters})
  }

  handleTokenInputChange(key, token) {
    const filters = {...this.state.filters}
    if ('action' in token && token.action === 'delete') {
      filters[key] = filters[key].filter(t => t.label !== token.label)
    } else {
      filters[key].push({...token})
    }
    this.setState({filters})
  }

  getSaveData() {
    /**
     * queryType is `any` unless `textAll` is set but `textAny` is not, in which
     * case it's `all`.
     */
    let queryType = 'any'
    const searchFilters = {
      textAny: [],
      textAll: [],
      textExclude: [],
    }
    const tokenFilters = {
      contentExact: [],
      headlineAny: [],
      headlineAll: [],
      headlineExclude: [],
      introAny: [],
      introAll: [],
      introExclude: [],
    }
    const filters = {}
    Object.keys(searchFilters).forEach(key => {
      this.state.filters[key].forEach(item => {
        if (!(filters.hasOwnProperty(key))) {
          filters[key] = []
        }
        filters[key].push({
          searchId: item.isFreeText ? null : item.value,
          value: item.value,
        })
      })
    })
    Object.keys(tokenFilters).forEach(key => {
      this.state.filters[key].forEach(item => {
        if (!(key in filters)) {
          filters[key] = []
        }
        filters[key].push({
          value: item.label,
        })
      })
    })
    /**
     * The advanced search UI doesn't have core terms.
     * If textAny/textAll filters are set, we move one into core terms and set
     * the query type appropriately, with textAny taking precedence.
     */
    let coreTerms = []
    if (filters.hasOwnProperty('textAny')) {
      const textAny = [...filters.textAny]
      delete filters.textAny
      coreTerms = textAny.map(f => f.value)
    } else if (filters.hasOwnProperty('textAll')) {
      const textAll = [...filters.textAll]
      delete filters.textAll
      coreTerms = textAll.map(f => f.value)
      queryType = 'all'
    }
    return {
      filters: flattenFilterData(filters),
      query: queryFromTerms(coreTerms),
      queryType,
    }
  }

  runSearch() {
    const data = this.getSaveData()
    this.props.getSearchResults(data)
  }
}
