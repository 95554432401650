import React from 'react'

import SearchesAdminAddNewModal from 'app/firm-admin/searches/AddNewModal'
import SearchesAdminEditSearchModal from 'app/firm-admin/searches/EditModal'
import SearchesAdminEditSourceFolderModal from 'app/firm-admin/searches/EditSourceFolderModal'
import SearchesAssignModal from 'app/firm-admin/searches/ShareModal'
import SourcesAssignModal from 'app/firm-admin/sources/AssignModal'
import {CglyticsTeaserModal} from 'app/search-results/cglytics-teaser'

// This is really just a host for all modals. Each modal is responsible for its
// own rendering.
export default () => (
  <div>
    <SearchesAdminAddNewModal />
    <SearchesAdminEditSearchModal />
    <SearchesAdminEditSourceFolderModal />
    <SearchesAssignModal />
    <SourcesAssignModal />
    <CglyticsTeaserModal />
  </div>
)
