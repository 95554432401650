import React, { Component } from 'react'


export default class CustomReports extends Component {
  render() {
    const reportData = [
      {
        type: 'company',
        header: 'Company Sample Report: Amazon Overview Report',
        image: 'mis-custom-350-company.png',
        description: 'Always stay in the know of what’s happening with a company or client. Manzama’s Intelligence ' +
          'Team can report and provide analysis on:',
        content: (
          <ul>
            <li>Current News</li>
            <li>Key Stock and Financial Performance Data</li>
            <li>Executive and Legal Leadership Team</li>
            <li>Key executive movements</li>
            <li>Mergers and acquisitions</li>
            <li>Deal activity and rumors and whispers</li>
            <li>Investigations and pending litigation</li>
            <li>Topical and regulatory issues relevant to the organization</li>
          </ul>
        ),
        link: 'https://storage.googleapis.com/manzama-marketing/html/amazon-example-report.html'
      },
      {
        type: 'client',
        header: 'Client Sample Report: South Africa Banks Report',
        image: 'mis-custom-350-client.png',
        description: 'An example of our customized client reports, this past report focuses on the South African ' +
          'banking market. Through careful analysis of Nedbank and other industry activities and news, our ' +
          'analysts synthesized results from Manzama’s targeted searches to highlight the most relevant ' +
          'data–identifying areas for growth, key executive movements and other events that served as an ' +
          'advantageous competitive intelligence tool.',
        content: null,
        link: 'https://storage.googleapis.com/manzama-marketing/html/south-africa-banks-report-example.html'
      },
      {
        type: 'topic',
        header: 'Topic Sample Report: Medical Devices Issues and Litigation',
        image: 'mis-custom-350-topic.png',
        description: 'Track the developments and trends within local and international topical areas, product ' +
          'categories and more. Here’s just some of the topics Manzama’s Intelligence Team can report and provide ' +
          'analysis on:',
        content: (
          <ul>
            <li>Political and governmental issues and developments</li>
            <li>Product category and subcategory issues and regulations</li>
            <li>Topical and subtopical issues and trends within an industry or practice</li>
          </ul>
        ),
        link: 'https://storage.googleapis.com/manzama-marketing/html/medical-report.html'
      },
      {
        type: 'practice',
        header: 'Practice Sample Report: Admiralty and Maritime Law',
        image: 'mis-custom-350-practice.png',
        description: 'Get the latest news and analysis regarding regulatory developments and decisions. Manzama’s ' +
          'Intelligence Team can report on practices and regulatory developments on a state/local, federal/national ' +
          'and international levels.',
        content: null,
        link: 'https://storage.googleapis.com/manzama-marketing/html/south-china-sea-ruling.html'
      },
      {
        type: 'industry',
        header: 'Industry Sample Report: Oil and Gas – Canada',
        image: 'mis-custom-350-industry.png',
        description: 'Always stay on top of issues and developments impacting industries locally and globally. ' +
          'Manzama’s Intelligence Team can report and provide analysis on:',
        content: (
          <ul>
            <li>Key executive movements</li>
            <li>Emerging topics and trends</li>
            <li>Deal activity, rumors and whispers</li>
            <li>Investigations and pending litigation</li>
            <li>Key company developments</li>
          </ul>
        ),
        link: 'https://storage.googleapis.com/manzama-marketing/html/oil-and-gas-canada.html'
      },
      {
        type: 'regional',
        header: 'Regional Sample Report: South Africa and Sub-Saharan Africa',
        image: 'mis-custom-350-regional.png',
        description: 'Monitor developments around topics, clients, industries and firms within specific region of ' +
          'the world. Manzama’s Intelligence Team can report on:',
        content: (
          <ul>
            <li>Key movements within firms and companies</li>
            <li>Financially distressed organizations</li>
            <li>Local regulatory developments that might impact an industry or practice</li>
          </ul>
        ),
        link: 'https://storage.googleapis.com/manzama-marketing/html/south-africa-newsletter.html'
      }
    ]

    const content = reportData.map(item => {
      return (
        <div
          key={`mis-custom-reports-${item.type}`}
          className="section-row"
        >
          <div className="section section-image">
            <img src={this.props.imageUrl + item.image}/>
          </div>

          <div className="section section-content">
            <div className="content-header">{item.header}</div>
            <div>{item.description}</div>

            {item.content}

            <div className="buttons">
              <a
                className="button"
                href={item.link}
                target="_blank"
              >View Sample</a>
              <a
                className="button"
                href="mailto:manzamaintel@manzama.com"
                target="_blank"
              >Request a Quote</a>
            </div>
          </div>
        </div>
      )
    })

    return (
      <div>
        {content}
      </div>
    )
  }
}
