import * as R from 'ramda'

import { STATE_PROP_NAME } from './profile-searches-constants'
import {
  STATE_PROP_NAME as FIRM_ADMIN_PROP_NAME
} from '../profile-constants'

export const getProfileSearches = R.path([FIRM_ADMIN_PROP_NAME, STATE_PROP_NAME])
export const getIsLoading = state => getProfileSearches(state).isLoading
export const getBrowseModalData = state => getProfileSearches(state).browseModalData
export const getCategory = state => getProfileSearches(state).category
export const getCategoryLabel = state => getProfileSearches(state).categoryLabel
export const getCategoryLabelPlural = state => getProfileSearches(state).categoryLabelPlural
export const getIsFirmLibraryGroup = state => getProfileSearches(state).isFirmLibraryGroup
export const getIsProspectCategory = state => getProfileSearches(state).isProspectCategory
export const getGlobalSearchIdsByCategory = state => getProfileSearches(state).globalSearchIdsByCategory
export const getFirmLibrarySearchIdsByCategory = state => getProfileSearches(state).firmLibrarySearchIdsByCategory
export const getFeaturedSearchIdsByCategory = state => getProfileSearches(state).featuredSearchIdsByCategory
export const getEditSearchId = state => getProfileSearches(state).editSearchId
