import { takeLatest } from 'redux-saga/effects'
import { actionApiRequest } from 'app/utils/sagas'
import * as dnbApi from './api'
import * as dnbActions from './dandb-search-actions'


function* fetchDnbData(action) {
  yield* actionApiRequest(action, dnbApi.fetchData)
}

export default function*() {
  yield takeLatest(dnbActions.searchDnbData.request, fetchDnbData)
}
