if (process.env.MANZAMA_BRAND === 'diligent') {
  // Official Diligent colors
  const redBright = '#EE213E'
  const redMedium = '#CF2026'
  const redMuted = '#BB2025'
  const redDark = '#921A1D'
  const grayExtremeLight = '#EEF1F1'
  const grayVeryLight = '#A4BCC4'
  const grayLight = '#768693'
  const grayMedium = '#415464'
  const grayDark = '#131E29'
  const blueLight = '#18A9D6'
  const mainOrange = '#F38A00'

  const linkColor = '#1C73A2'
  const activeColor = '#44ACE2'
  const activeColorHover = '#378DB9'
  const textColorGeneral = '#444444'
  const autocompleteBrand = '/media/img/autocomplete_diligent_logo.png'
  const autocompleteBrandHover = '/media/img/autocomplete_diligent_logo_hover.png'
  const insightsIcon = '/media/img/diligent-insights-icon.svg'
  const goToInsights = '/media/img/diligent-go-to-insights.svg'

  module.exports = {
    // Primary colors
    'main-color': grayMedium,
    'main-color-active': grayLight,
    'main-color-dark': grayMedium,
    'main-orange': mainOrange,

    'background-color-very-light': '#F2F2F2',
    'background-color-light': '#E2E2E2',
    'background-color-medium': '#888888',
    'background-color-dark': grayDark,
    'background-color-soft': '#E2ECF2',

    'active-color': activeColor,
    'active-color-hover': activeColorHover,

    // Default color for text
    'text-color-general': textColorGeneral,
    'text-color-very-light': '#E8E9E5',
    'text-color-light': '#888888',
    'text-color-medium': grayMedium,
    'text-color-dark': '#333333',
    // Used for faded text, like a disabled button
    'text-color-faded': '#B1B1B1',

    'link-color': linkColor,
    'link-hover-color': activeColorHover,
    'article-link-color': linkColor,
    'article-link-hover-color': activeColorHover,
    'article-link-visited-color': grayLight,

    'heading-color': textColorGeneral,

    'tab-background-color': grayMedium,
    'tab-hover-background-color': activeColor,

    'destructive-color': redMedium,
    'destructive-hover-color': redBright,

    'autocomplete-brand': autocompleteBrand,
    'autocomplete-brand-hover': autocompleteBrandHover,
    'insights-icon': insightsIcon,
    'insights-icon-alt': insightsIcon,
    'go-to-insights': goToInsights,
  }
}
// Default is Manzama
else {
  const mainColor = '#035C8B'
  const mainColorActive = '#0074b4'
  const backgroundColorDark = '#222222'
  const activeColor = '#00AEC6'
  const textColorGeneral = '#444444'
  const mainOrange = '#EB7100'
  const autocompleteBrand = '/media/img/autocomplete_mz_logo.png'
  const autocompleteBrandHover = '/media/img/autocomplete_mz_logo_hover.png'
  const insightsIcon = '/media/img/signals-icon.svg'
  const insightsIconAlternate = '/media/img/signals-a-icon.svg'
  const goToInsights = '/media/img/go-to-signals.svg'

  module.exports = {
    // Primary colors
    'main-color': mainColor,
    'main-color-active': mainColorActive,
    'main-color-dark': '#013653',
    'main-orange': mainOrange,

    'background-color-very-light': '#F2F2F2',
    'background-color-light': '#E2E2E2',
    'background-color-medium': '#888888',
    'background-color-dark': backgroundColorDark,
    'background-color-soft': '#E2ECF2',

    'active-color': activeColor,
    'active-color-hover': '#006471',

    // Default color for text
    'text-color-general': textColorGeneral,
    'text-color-very-light': '#E8E9E5',
    'text-color-light': '#888888',
    'text-color-medium': '#666666',
    'text-color-dark': '#333333',
    // Used for faded text, like a disabled button
    'text-color-faded': '#B1B1B1',

    'link-color': mainColor,
    'link-hover-color': mainColorActive,
    'article-link-color': mainColor,
    'article-link-hover-color': mainColorActive,
    'article-link-visited-color': '#849299',

    'heading-color': textColorGeneral,

    'tab-background-color': mainColor,
    'tab-hover-background-color': activeColor,

    'destructive-color': '#DB3100',
    'destructive-hover-color': '#B12800',

    'autocomplete-brand': autocompleteBrand,
    'autocomplete-brand-hover': autocompleteBrandHover,
    'insights-icon': insightsIcon,
    'insights-icon-alt': insightsIconAlternate,
    'go-to-insights': goToInsights,
  }
}
