import PropTypes from 'prop-types'
import React from 'react'

import Button from 'app/common/Button'
import SplitButtonDropdown from 'app/common/SplitButtonDropdown'


export default function SaveSearchButton({
  search,
  isSaving,
  hasUnsavedChanges,
  onClick,
  includeFirmLibraryOption,
  currentFirmLibraryName,
}) {
  const label = isSaving
    ? 'Saving...'
    : search.isSaved
      ? 'Save Search'
      : 'Save as New Search'
  const buttonDisabled = search.isSaved && !hasUnsavedChanges
  const options = []
  if (search.isSaved) {
    options.push({label: 'Save as New', value: 'new'})
    if (includeFirmLibraryOption) {
      const firmLibraryOptionLabel = hasUnsavedChanges
        ? `Save and Move into ${currentFirmLibraryName}`
        : `Move into ${currentFirmLibraryName}`
      options.push({label: firmLibraryOptionLabel, value: 'firm-library'})
    }
  } else {
    if (includeFirmLibraryOption) {
      options.push({label: `Save into ${currentFirmLibraryName}`, value: 'firm-library'})
    }
  }
  if (options.length === 0) {
    return (
      <Button
        label={label}
        disabled={buttonDisabled}
        onClick={onClick}
        isPrimary={true}
        className="save-search-button" // for Pendo
      />
    )
  }
  return (
    <SplitButtonDropdown
      label={label}
      buttonDisabled={buttonDisabled}
      onClick={onClick}
      onChange={onClick}
      isPrimary={true}
      options={options}
      mainButtonClassName="save-search-button" // for Pendo
    />
  )
}
SaveSearchButton.propTypes = {
  search: PropTypes.object.isRequired,
  isSaving: PropTypes.bool.isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  includeFirmLibraryOption: PropTypes.bool,
}
