import React, {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'
import DjangoCSRFToken from 'django-react-csrftoken'

import Orm from 'app/framework/Orm'
import {User} from 'app/models'
import LoadingOverlay from 'app/common/LoadingOverlay'
import Dropdown from 'app/common/Dropdown'
import ConfirmationModal from 'app/common/modals/ConfirmationModal'

import EditUserModal from './EditModal'
import Actions from './Actions'
import AddUser from './AddUser'
import Filters from './Filters'
import InsightsModal from './InsightsModal'
import PublicationsModal from './PublicationsModal'
import UsersTable from './UsersTable'
import {getUsersAdmin} from './users-admin-selectors'
import {
  hideDeleteUserConfirmationModal,
  setSelectedUserIds,
  setSelectedModalUserIds,
  bulkPasswordReset,
  bulkSendWelcomeEmail,
  bulkSendRefreshEmail,
  bulkActivateAccounts,
  showDeleteUserConfirmationModal,
  deleteUsers,
  showInsightsModal,
  setNewUser,
  saveNewUser,
  saveNewGroup,
  setInsightsModalAction,
  bulkEnableInsights,
  showUserModal,
  fetchUsers,
  refreshSummarySignup,
  makeSelectedGroupsPrivate,
  fetchAllUsers,
  changeCurrentPage,
  changeSort,
  changeIsFilter,
  selectAllUsersCheckbox,
  showSelectedGroupsColumns,
  showPublicationsModal,
  setPublicationsModalType,
  getRecurringPublicationsData,
  getTemplatePublicationsData,
  saveBulkUsersEmailDeliverySettings,
  saveBulkNlaArticleViewingSettings,
  showRefreshIntervalModal,
  setRefreshIntervalModalAction,
  bulkManageRefreshInterval
} from './users-admin-actions'
import { ACTIVE_FILTER, SELECT_TYPE, PUBLICATIONS_ITEMS_PER_PAGE } from './users-admin-constants'
import RefreshIntervalModal from './RefreshIntervalModal';


@connect(
  // Map state to props
  state => {
    const orm = Orm.withEntities(state.entities)
    const usersAdmin = getUsersAdmin(state)
    return {
      currentUserIsStaff: state.currentUserIsStaff,
      currentUserIsClientAdmin: state.currentUserIsClientAdmin,
      currentFirmHasInsights: state.currentFirmHasInsights,
      currentFirmShowAlternateDateFormat: state.currentFirmShowAlternateDateFormat,
      isDiligent: state.isDiligent,
      currentUser: orm.getById(User, state.currentUser),
      insightsLabel: state.insightsLabel,

      isGroupPage: usersAdmin.isGroupPage,
      shouldAutoFetchProfiles: usersAdmin.shouldAutoFetchProfiles,
      areAllProfilesFetched: usersAdmin.areAllProfilesFetched,
      activeProfileCount: usersAdmin.activeProfileCount,
      activeAdminProfileCount: usersAdmin.activeAdminProfileCount,
      inactiveProfileCount: usersAdmin.inactiveProfileCount,
      availableGroups: usersAdmin.availableGroups,
      managedGroupIds: usersAdmin.managedGroupIds,
      firmLocations: usersAdmin.firmLocations,
      departments: usersAdmin.departments,
      teams: usersAdmin.teams,
      users: orm.getByIds(User, usersAdmin.userIds),
      newUser: usersAdmin.newUser,
      isInsightsModalDisplayed: usersAdmin.isInsightsModalDisplayed,
      insightsModalAction: usersAdmin.insightsModalAction,
      selectedModalUserIds: usersAdmin.selectedModalUserIds,
      deleteUserConfirmationModalData: usersAdmin.deleteUserConfirmationModalData,
      userModalData: usersAdmin.userModalData,
      selectedUserIds: usersAdmin.selectedUserIds,
      isLoading: usersAdmin.isLoading,
      isProcessing: usersAdmin.isProcessing,
      filters: usersAdmin.filters,
      currentPage: usersAdmin.currentPage,
      totalCount: usersAdmin.totalCount,
      visibleIds: usersAdmin.visibleIds,
      selectType: usersAdmin.selectType,
      allSelectedUsers: usersAdmin.allSelectedUsers,
      selectedColumns: usersAdmin.selectedColumns,
      countries: usersAdmin.countries,
      publicationsModalDisplayed: usersAdmin.publicationsModalDisplayed,
      publicationModalType: usersAdmin.publicationModalType,
      recurringPublicationData: usersAdmin.recurringPublicationData,
      templatePublicationData: usersAdmin.templatePublicationData,
      publicationData: usersAdmin.publicationData,
      isNlaFirm: usersAdmin.isNlaFirm,
      isRefreshIntervalModalDisplayed: usersAdmin.isRefreshIntervalModalDisplayed,
      refreshIntervalModalAction: usersAdmin.refreshIntervalModalAction
    }
  },

  // Map dispatch to props
  {
    hideDeleteUserConfirmationModal,
    setSelectedUserIds,
    setSelectedModalUserIds,
    bulkPasswordReset,
    bulkSendWelcomeEmail,
    bulkSendRefreshEmail,
    bulkActivateAccounts,
    showDeleteUserConfirmationModal,
    deleteUsers,
    showInsightsModal,
    setNewUser,
    saveNewUser,
    saveNewGroup,
    setInsightsModalAction,
    bulkEnableInsights,
    showUserModal,
    fetchUsers,
    refreshSummarySignup,
    makeSelectedGroupsPrivate,
    fetchAllUsers,
    changeCurrentPage,
    changeSort,
    changeIsFilter,
    selectAllUsersCheckbox,
    showSelectedGroupsColumns,
    showPublicationsModal,
    setPublicationsModalType,
    getRecurringPublicationsData,
    getTemplatePublicationsData,
    saveBulkUsersEmailDeliverySettings,
    saveBulkNlaArticleViewingSettings,
    showRefreshIntervalModal,
    setRefreshIntervalModalAction,
    bulkManageRefreshInterval
  },
)
export default class UsersAdmin extends Component {
  state = {
    activeFilter: ACTIVE_FILTER.ACTIVE,
    modalUsers: [],
    modalNameFilter: '',
    showRefreshDetails: false,
    isSubmitting: false,
    publicationsPageNo: 1
  }

  componentDidMount() {
      this.fetchUsers({isActive: true}, true)
  }

  render() {
    const filteredUsers = this.getFilteredUsers()

    const addUserArea =
      <AddUser
        currentUserIsStaff={this.props.currentUserIsStaff}
        currentUserIsClientAdmin={this.props.currentUserIsClientAdmin}
        isGroupPage={this.props.isGroupPage}
        newUser={this.props.newUser}
        setNewUserFirstName={(firstName) => this.setNewUserFirstName(firstName)}
        setNewUserLastName={(lastName) => this.setNewUserLastName(lastName)}
        setNewUserEmailAddress={(emailAddress) => this.setNewUserEmailAddress(emailAddress)}
        setNewUserWelcomeEmail={(checked) => this.setNewUserWelcomeEmail(checked)}
        saveNewGroup={() => this.saveNewGroup()}
        saveNewUser={() => this.saveNewUser()}
      />

    const insightsModal =
      <InsightsModal
        isOpen={this.props.isInsightsModalDisplayed}
        users={[...this.getFilteredSelectedUsers()]}
        selectedUserIds={this.props.selectedModalUserIds}
        insightsLabel={this.props.insightsLabel}
        action={this.props.insightsModalAction}
        setAction={(action) => this.props.setInsightsModalAction(action)}
        handleUserCheckbox={(evt) => this.handleModalUserCheckbox(evt)}
        hide={() => this.hideInsightsModal()}
        apply={(userIds) => this.insightsModalApply(userIds)}
      />

    const refreshIntervalModal =
      <RefreshIntervalModal
        isOpen={this.props.isRefreshIntervalModalDisplayed}
        users={[...this.getFilteredSelectedUsers()]}
        selectedUserIds={this.props.selectedModalUserIds}
        action={this.props.refreshIntervalModalAction}
        setAction={(action) => this.props.setRefreshIntervalModalAction(action)}
        handleUserCheckbox={(evt) => this.handleModalUserCheckbox(evt)}
        hide={() => this.hideRefreshIntervalModal()}
        apply={(userIds) => this.refreshIntervalModalApply(userIds)}
      />

    const publicationsModal =
      <PublicationsModal
        isOpen={this.props.publicationsModalDisplayed}
        hide={() => this.props.showPublicationsModal(false)}
        publicationModalType={this.props.publicationModalType}
        publicationData={this.props.publicationData}
        onPageChange={this.onPageChange}
        publicationsPageNo={this.state.publicationsPageNo}
      />

    const exportReportsDisplay =
      <span className={'export-reports-display'}>
        <i className="fa icon-off fa-download fa-lg" style={{marginRight: '15px'}}/>
        Export Reports...
      </span>

    const exportReportsOptions = [
      {value: 'none', label: exportReportsDisplay},
      {value: 'emailUserReport', label: 'Email User Report'},
      {value: 'userReport', label: 'Download User Report'},
      {value: 'refreshReport', label: 'Download Refresh Summary'},
      {value: 'refreshSummarySignup',
        label: this.props.currentUser.receiveMonthlyRefreshSummary
          ? 'Stop Receiving Monthly Refresh Summary'
          : 'Signup for Monthly Refresh Summary'}

    ]

    const exportReports =
      this.props.isGroupPage ?
        <div className="float-right margin-r-m margin-t-l">
          <form method="post" action="/profile/firm/groups/export/">
            <DjangoCSRFToken/>
            <button
              className="export-button"
              type="submit"
            >
              <i className="fa icon-off fa-download fa-lg"/>
              <span>Export Group Report</span>
            </button>
          </form>
        </div>
        : <div className="export-reports-container">
            <Dropdown
              value={'none'}
              options={exportReportsOptions}
              className="export-report-option"
              onChange={(value) => this.handleExportReportsOnChange(value)}
              disabled={this.state.isSubmitting}
            />
          </div>

    const exportUserReportForm = !this.props.isGroupPage
      ? <form
          id={'download-user-report-form'}
          method="post"
          action="/profile/firm/accounts/export/"
        >
          <DjangoCSRFToken/>
          <input
            type="hidden"
            name="export_user_status"
            value={this.state.activeFilter === ACTIVE_FILTER.INACTIVE ? ACTIVE_FILTER.INACTIVE : ACTIVE_FILTER.ACTIVE}
          />
        </form>
      : null

    const emailUserReportForm = !this.props.isGroupPage
      ? <form
          id={'email-user-report-form'}
          method="post"
          action="/profile/firm/accounts/export/?email=true"
        >
          <DjangoCSRFToken/>
          <input
            type="hidden"
            name="email_user_status"
            value={this.state.activeFilter === ACTIVE_FILTER.INACTIVE ? ACTIVE_FILTER.INACTIVE : ACTIVE_FILTER.ACTIVE}
          />
        </form>
      : null

    const exportRefreshReportForm = !this.props.isGroupPage
      ? <form
          id={'download-refresh-report-form'}
          method="post"
          action="/profile/firm/accounts/refresh-export/"
        >
          <DjangoCSRFToken/>
        </form>
      : null

    const heading =
      <h4 className="group-heading">{this.props.isGroupPage ? 'Group' : 'User'} Listing</h4>

    const userModal = <EditUserModal/>

    const userType = this.props.isGroupPage ? 'Group' : 'User'

    const deleteMessagePart = this.getDeleteUserIds().length > 1
      ? `these ${userType}s`
      : `this ${userType}`

    const deleteConfirmationModal =
      this.props.deleteUserConfirmationModalData.isOpen && (
        <ConfirmationModal
          message={`Are you sure you want to delete ${deleteMessagePart}?`}
          secondaryMessage="This action cannot be undone!"
          confirmButtonText="Delete"
          onConfirm={() => {
            this.deleteUsers()
            this.props.hideDeleteUserConfirmationModal()
          }}
          onClose={() => this.props.hideDeleteUserConfirmationModal()}
          isDestructive={true}
        />
      )

    const loader = this.props.isLoading &&
      <div className={classNames('loading-container', {'is-modal': this.props.userModalData.isOpen})}>
        <LoadingOverlay/>
      </div>

    const totalProfileCount = this.props.activeProfileCount + this.props.inactiveProfileCount

    const profileCount =
      this.state.activeFilter === ACTIVE_FILTER.ACTIVE
        ? this.props.activeProfileCount
        : this.state.activeFilter === ACTIVE_FILTER.INACTIVE
          ? this.props.inactiveProfileCount
          : this.props.activeAdminProfileCount

    const clickToLoadAllMessage =
      `click here to load all ${totalProfileCount} ${this.props.isGroupPage ? 'groups' : 'users'} 
      (${profileCount} ${this.state.activeFilter})`

    const handlePageChange = (page) => {
      this.props.changeCurrentPage({page, forceFetch: true})
    }
    const handleSortChange = (sort) => {
      const field = 'name'
      this.props.changeSort({ field: sort.column, direction: sort.direction, forceFetch: true })
    }

    return (
      <div className="users-admin-style">
        <h1>Manage {this.props.isGroupPage ? 'Groups' : 'Users'} for {this.props.currentUser?.firm?.name}</h1>

        {loader}

        {insightsModal}

        {publicationsModal}

        {userModal}

        {deleteConfirmationModal}

        {addUserArea}

        {exportReports}

        {emailUserReportForm}

        {exportUserReportForm}

        {exportRefreshReportForm}

        {refreshIntervalModal}

        {heading}

        <div>
          <Filters
            isGroupPage={this.props.isGroupPage}
            availableGroups={this.props.availableGroups}
            firmLocations={this.props.firmLocations}
            departments={this.props.departments}
            teams={this.props.teams}
            activeFilter={this.state.activeFilter}
            fetchUsers={(params) => this.fetchUsers(params)}
            setActiveFilter={(activeFilter) => this.setActiveFilter(activeFilter)}
            fetchGroups={(params) => this.fetchGroups(params)}
            countries={this.props.countries}
          />

          <Actions
            isGroupPage={this.props.isGroupPage}
            currentFirmHasInsights={this.props.currentFirmHasInsights}
            insightsLabel={this.props.insightsLabel}
            showRefreshDetails={this.state.showRefreshDetails}
            selectedUserIds={this.props.selectedUserIds}
            allFilteredUsersAreSelected={this.allFilteredUsersAreSelected()}
            activeFilter={this.state.activeFilter}
            handleSelectAllUsersCheckbox={(checked, selectType) => this.handleSelectAllUsersCheckbox(checked, selectType)}
            resetPassword={() => this.resetPassword()}
            sendWelcomeEmail={() => this.sendWelcomeEmail()}
            sendRefreshEmail={() => this.sendRefreshEmail()}
            showInsightsModal={() => this.showInsightsModal()}
            activateAccounts={() => this.activateAccounts()}
            deactivateAccounts={() => this.deactivateAccounts()}
            setShowRefreshDetails={(checked) => this.setShowRefreshDetails(checked)}
            makeSelectedGroupsPrivate={() => this.props.makeSelectedGroupsPrivate()}
            showDeleteUserConfirmationModal={(evt, userIds) => this.showDeleteUserConfirmationModal(evt, userIds, true)}
            totalCount={this.props.totalCount}
            visibleIds={this.props.visibleIds}
            selectedColumns={this.props.selectedColumns}
            showSelectedGroupsColumns={this.props.showSelectedGroupsColumns}
            setPersonalAlerts={() => this.setPersonalAlerts()}
            setGroupAlerts={() => this.setGroupAlerts()}
            setDisableAlerts={() => this.setDisableAlerts()}
            setNlaArticleViewing={(action) => this.setNlaArticleViewing(action)}
            isNlaFirm={this.props.isNlaFirm}
            showRefreshIntervalModal={()=>this.showRefreshIntervalModal()}
          />

          <UsersTable
            isGroupPage={this.props.isGroupPage}
            currentFirmHasInsights={this.props.currentFirmHasInsights}
            insightsLabel={this.props.insightsLabel}
            users={filteredUsers}
            currentFirmShowAlternateDateFormat={this.props.currentFirmShowAlternateDateFormat}
            shouldShowRefreshDetails={this.state.showRefreshDetails}
            selectedUserIds={this.props.selectedUserIds}
            handleUserCheckbox={(evt) => this.handleUserCheckbox(evt)}
            showUserModal={(evt, userId) => this.showUserModal(evt, userId)}
            showDeleteUserConfirmationModal={(evt, userIds) => this.showDeleteUserConfirmationModal(evt, userIds, false)}
            currentPage={this.props.currentPage}
            pageCount={Math.ceil(this.props.totalCount / 20)}
            onSortChange={handleSortChange}
            onPageChange={handlePageChange}
            selectedColumns={this.props.selectedColumns}
            showPublicationsModal={(type, user) => this.showPublicationsModal(type, user)}
          />

          {
            !this.props.isLoading && !this.props.isProcessing && filteredUsers.length === 0 &&
            (
              Object.keys(this.props.filters).length === 0
              ? <h3>
                  <p>
                    <span>Please filter for results or </span>
                    <a
                      onClick={() => this.fetchUsers({isActive: true}, true)}
                    >{clickToLoadAllMessage}</a>
                  </p>
                </h3>
              : <h3>No users</h3>
            )
          }
        </div>
      </div>
    )
  }

  handleUserCheckbox(evt) {
    const id = parseInt(evt.target.value)
    let ids = [...this.props.selectedUserIds]
    if (evt.target.checked) {
      ids.push(id)
    } else {
      const idx = ids.indexOf(id)
      ids.splice(idx, 1)
    }
    this.props.setSelectedUserIds(ids)
  }

  handleModalUserCheckbox(evt) {
    const id = parseInt(evt.target.value)
    let ids = [...this.props.selectedModalUserIds]
    if (evt.target.checked) {
      ids.push(id)
    } else {
      const idx = ids.indexOf(id)
      ids.splice(idx, 1)
    }
    this.props.setSelectedModalUserIds(ids)
  }

  handleSelectAllUsersCheckbox(checked, selectType) {
    if (checked && selectType == 'all') {
      this.props.fetchAllUsers()
      this.props.selectAllUsersCheckbox(selectType)
    } else if (checked && selectType == 'visible') {
      this.props.setSelectedUserIds(this.props.visibleIds)
      this.props.selectAllUsersCheckbox(selectType)
    } else {
      this.props.setSelectedUserIds([])
      this.props.selectAllUsersCheckbox(selectType)
    } 
  }

  resetPassword() {
    this.props.bulkPasswordReset(this.getFilteredSelectedUserIds())
  }

  sendWelcomeEmail() {
    this.props.bulkSendWelcomeEmail(this.getFilteredSelectedUserIds())
  }

  sendRefreshEmail() {
    this.props.bulkSendRefreshEmail(this.getFilteredSelectedUserIds())
  }

  activateAccounts() {
    this.props.bulkActivateAccounts({
      userIds: this.getFilteredSelectedUserIds(),
      action: 'activate',
      isGroupPage: this.props.isGroupPage,
    })
  }

  deactivateAccounts() {
    this.props.bulkActivateAccounts({
      userIds: this.getFilteredSelectedUserIds(),
      action: 'deactivate',
      isGroupPage: this.props.isGroupPage,
    })
  }

  showDeleteUserConfirmationModal(evt, ids, isBulk) {
    evt.preventDefault()
    this.props.showDeleteUserConfirmationModal({ids, isBulk})
  }

  getDeleteUserIds() {
    const filteredSelectedUserIds = this.getFilteredSelectedUserIds()
    const deleteUserIds = this.props.deleteUserConfirmationModalData.ids
    // when using bulk delete, we only want to include visible users
    if (this.props.deleteUserConfirmationModalData.isBulk) {
      return deleteUserIds.filter(id => filteredSelectedUserIds.includes(id))
    }
    return deleteUserIds
  }

  deleteUsers() {
    this.props.deleteUsers({
      ids: this.getDeleteUserIds(),
      isGroupPage: this.props.isGroupPage,
    })
  }

  showInsightsModal() {
    const modalUsers = [...this.getFilteredSelectedUsers()]
    const selectedModalUserIds = modalUsers.map(user => user.id)
    this.props.showInsightsModal(true)
    this.props.setSelectedModalUserIds(selectedModalUserIds)
  }

  showRefreshIntervalModal() {
    const modalUsers = [...this.getFilteredSelectedUsers()]
    const selectedModalUserIds = modalUsers.map(user => user.id)
    this.props.showRefreshIntervalModal(true)
    this.props.setSelectedModalUserIds(selectedModalUserIds)
  }

  showPublicationsModal(type, user) {
    const data = { page: 1, itemsPerPage: PUBLICATIONS_ITEMS_PER_PAGE, groupId: user.id }
    this.setState({ publicationsPageNo: 1 })
    this.props.showPublicationsModal(true)
    this.props.setPublicationsModalType({ type, user })
    if (type == 'Recurring') {
      this.props.getRecurringPublicationsData(data)
    } else {
      this.props.getTemplatePublicationsData(data)
    }
  }

  onPageChange = (page) => {
    const { type, user } = this.props.publicationModalType
    const data = { page: page, itemsPerPage: PUBLICATIONS_ITEMS_PER_PAGE, groupId: user.id }
    this.setState({ publicationsPageNo: page })
    if (type == 'Recurring') {
      this.props.getRecurringPublicationsData(data)
    } else {
      this.props.getTemplatePublicationsData(data)
    }
  }

  hideInsightsModal() {
    this.props.showInsightsModal(false)
  }

  hideRefreshIntervalModal() {
    this.props.showRefreshIntervalModal(false)
  }

  setNewUserFirstName(firstName) {
    this.props.setNewUser({...this.props.newUser, firstName})
  }

  setNewUserLastName(lastName) {
    this.props.setNewUser({...this.props.newUser, lastName})
  }

  setNewUserEmailAddress(emailAddress) {
    this.props.setNewUser({...this.props.newUser, emailAddress})
  }

  setNewUserWelcomeEmail(checked) {
    this.props.setNewUser({...this.props.newUser, shouldSendWelcomeEmail: checked})
  }

  setShowRefreshDetails(checked) {
    this.setState({showRefreshDetails: checked})
  }

  saveNewUser() {
    this.props.saveNewUser(this.props.newUser)
  }

  saveNewGroup() {
    this.props.saveNewGroup(this.props.newUser.firstName)
  }

  insightsModalApply(userIds) {
    this.props.bulkEnableInsights({
      userIds: userIds,
      action: this.props.insightsModalAction,
      isGroupPage: this.props.isGroupPage,
    })
  }

  refreshIntervalModalApply(userIds) {
    this.props.bulkManageRefreshInterval({
      userIds: userIds,
      action: this.props.refreshIntervalModalAction,
      isGroupPage: this.props.isGroupPage,
    })
  }

  getFilteredUsers() {   
    return this.props.users;
  }

  getFilteredSelectedUsers() {
    const allUsers = this.props.allSelectedUsers? this.props.allSelectedUsers.length : 0
    if (this.props.selectType === SELECT_TYPE.ALL && this.props.selectedUserIds.length === allUsers ) {
      return this.props.allSelectedUsers
    } else {
      return this.getFilteredUsers().filter(user => {
        return this.props.selectedUserIds.includes(user.id)
      })
    }
  }

  getFilteredSelectedUserIds() {
    return this.getFilteredSelectedUsers().map(user => user.id)
  }

  allFilteredUsersAreSelected() {
    if (this.props.selectedUserIds.length === 0) {
      return false
    }
    let allAreSelected = true
    this.getFilteredUsers().forEach(user => {
      if (!this.props.selectedUserIds.includes(user.id)) {
        allAreSelected = false
      }
    })
    return allAreSelected
  }

  showUserModal(evt, userId) {
    evt.preventDefault()
    this.props.showUserModal(userId)
  }

  fetchUsers(newFilters, forceFetch = false) {
    let filters = {}
    filters = Object.entries({...this.props.filters, ...newFilters}).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {})
    this.props.fetchUsers({filters, forceFetch: true})
  }

  fetchGroups(newFilters, forceFetch = false) {
    let filters = {}
    filters = Object.entries({ ...this.props.filters, ...newFilters }).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {})
    this.props.fetchUsers({ filters, forceFetch: true })
  }

  setActiveFilter(activeFilter){
    let filters = {}
    if (activeFilter == 'active') {
      filters = {isActive: true}
    } else if (activeFilter == 'inactive') {
      filters = {isActive: false}
    } else if (activeFilter == 'admin') {
      filters = {isFirmAdmin: true}
    }

    this.props.changeIsFilter({filters, forceFetch: true})
    this.setState({activeFilter})
  }

  handleExportReportsOnChange(value) {
    this.setState({isSubmitting: true})
    if (value === 'userReport') {
      const userReportForm = document.getElementById('download-user-report-form')
      userReportForm.submit()
      notify.success("Preparing User Report for Download.")
    }
    if (value === 'emailUserReport') {
      const userReportForm = document.getElementById('email-user-report-form')
      userReportForm.submit()
      notify.success("The User report will be emailed shortly.")
    }
    if (value === 'refreshReport') {
      const refreshReportForm = document.getElementById('download-refresh-report-form')
      refreshReportForm.submit()
      notify.success("Preparing Profile Refresh Summary for Download.")
    }
    if (value === 'refreshSummarySignup') {
      this.props.refreshSummarySignup(!this.props.currentUser.receiveMonthlyRefreshSummary)
    }
    setTimeout(() => {this.setState({isSubmitting: false})}, 3000)
  }

  setPersonalAlerts() {
    this.props.saveBulkUsersEmailDeliverySettings({
      option: "personal_alerts",
      isSelected: true,
      editUserIds: this.props.selectedUserIds,
      message: "Personal Alerts"
    })
  }

  setGroupAlerts() {
    this.props.saveBulkUsersEmailDeliverySettings({
      option: "group_alerts",
      isSelected: true,
      editUserIds: this.props.selectedUserIds,
      message: "Group Alerts"
    })
  }

  setDisableAlerts() {
    this.props.saveBulkUsersEmailDeliverySettings({
      option: "disable_alerts",
      isSelected: true,
      editUserIds: this.props.selectedUserIds,
      message: "Disable"
    })
  }

  setNlaArticleViewing(action) {
    this.props.saveBulkNlaArticleViewingSettings({users: this.getFilteredSelectedUserIds(), setting: action})
  }
}
