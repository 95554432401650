import * as R from 'ramda'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Button from 'app/common/Button'
import InputBlock from 'app/common/InputBlock'
import TextBox from 'app/common/TextBox'
import CategoryDropdown from 'app/common/CategoryDropdown'
import Orm from 'app/framework/Orm'
import { SavedSearch, User } from 'app/models'

import { addNewModalSetStep, setSaveData } from '../searches-admin-actions'
import { getSearchesAdmin } from "../searches-admin-selectors";


@connect(
  // Map state to props
  state => {
    const searchesAdmin = getSearchesAdmin(state)
    const orm = Orm.withEntities(state.entities)
    return {
      firmUsers: orm.getByIds(User, searchesAdmin.userIds),
      saveData: searchesAdmin.saveData,
      isPracticesEnabled: state.currentFirmIsPracticesEnabled,
      currentFirmLibraryName: state.currentFirmLibraryName,
    }
  },
  {
    setStep: addNewModalSetStep,
    setSaveData: setSaveData,
  },
)
export default class EnterSearchDetails extends Component {
  state = {
    name: this.props.saveData.name || '',
    category: this.props.saveData.category || SavedSearch.CATEGORIES.TOPIC,
    isFirmLibrary: this.props.saveData.isFirmLibrary,
    scope: this.props.saveData.scope || SavedSearch.SCOPES.SHARED,
  }

  render() {
    const {CATEGORIES} = SavedSearch

    const {currentFirmLibraryName} = this.props

    const omittedCategories = [CATEGORIES.TRUSTED, CATEGORIES.TRUSTED_UNCATEGORIZED]

    if (!this.props.isPracticesEnabled) {
      omittedCategories.push(CATEGORIES.PRACTICE)
    }

    const description =
      this.state.isFirmLibrary
        ? this.state.scope === SavedSearch.SCOPES.PERSONAL
          ? <p>“Private” searches will not be searchable in Base or browsable as part of the {currentFirmLibraryName} by non
              admin users in your account. Set your search to “Private” when you are still working on the search and do
              not want it to be shared out with users in your account, or if you want it to remain only accessible to
              you forever. You will be able to share this search later by Editing from the Searches Administration
              page.</p>
          : <p>Set your search to “Shared” when it is ready to be used by all of the users in your account. A “shared”
              search will be searchable in Base, and be available as part of the {currentFirmLibraryName}. Copies of this search
              will be added to any user or group profiles you select. Their search copies will be tied to this firm
              library search - any updates you make to the firm library search will apply to those copies.</p>
        : this.state.scope === SavedSearch.SCOPES.PERSONAL
          ? <p>Copies of this search will be added to any user or group profiles you select with the privacy setting
              set to 'private.' Other users will not be able to find this search and this search will not be tied to a
              parent search. This search is tied to individual users or groups and will appear as individual rows in
              the searches administration table.</p>
          : <p>Set your search to “Shared” when it is ready to be used by all of the users in your account. A “shared”
              search will be searchable in Base. Copies of this search will be added to any user or group profiles
              you select. Their search copies will be tied to this search and any updates you make to this search will
              apply to those copies.</p>

    return (
      <div className="enter-search-details">
        <h2>Step 2: Enter Search Details</h2>

        <div className="row">
          <InputBlock label="Search Name" className="search-name">
            <TextBox
              value={this.state.name}
              onChange={(evt) => this.setName(evt)}
            />
          </InputBlock>

          <InputBlock label="Category" className="category">
            <CategoryDropdown
              value={this.state.category}
              onChange={this.setCategory.bind(this)}
              omittedCategories={omittedCategories}
            />
          </InputBlock>
        </div>

        <div>
          {/* Hacky way to get the label styling by itself */}
          <InputBlock label="Search Owner" />
        </div>

        <div className="row search-type">
          <InputBlock label={currentFirmLibraryName} isInline>
            <input
              type="radio"
              name="owner"
              checked={this.state.isFirmLibrary}
              onChange={() => this.setIsFirmLibrary(true)}
            />
          </InputBlock>

          <InputBlock label="My Profile" isInline>
            <input
              type="radio"
              name="owner"
              checked={!this.state.isFirmLibrary}
              onChange={() => this.setIsFirmLibrary(false)}
            />
          </InputBlock>
        </div>

        <div>
          {/* Hacky way to get the label styling by itself */}
          <InputBlock label="Search Status" />
        </div>

        <div className="row search-type">
          <InputBlock label="Shared" isInline>
            <input
              type="radio"
              name="scope"
              checked={this.state.scope === SavedSearch.SCOPES.SHARED}
              onChange={() => this.setScope(SavedSearch.SCOPES.SHARED)}
            />
          </InputBlock>

          <InputBlock label="Private" isInline>
            <input
              type="radio"
              name="scope"
              checked={this.state.scope === SavedSearch.SCOPES.PERSONAL}
              onChange={() => this.setScope(SavedSearch.SCOPES.PERSONAL)}
            />
          </InputBlock>
        </div>

        <div className="row">
          {description}
        </div>

        <div className="new-search-buttons-container">
          <div className="new-search-buttons">
            <div className="buttons left">
              <Button
                label="Previous Step"
                onClick={() => this.previousStep()}
              />
            </div>

            <div className="buttons right">
              <Button
                label="Next Step"
                isPrimary
                disabled={!this.canProceed()}
                onClick={() => this.nextStep()}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  // State management

  setName(evt) {
    const name = evt.target.value
    this.setState({name: name})
  }

  setCategory(category) {
    this.setState({category: category})
  }

  setIsFirmLibrary(isFirmLibrary) {
    this.setState({isFirmLibrary})
  }

  setScope(scope) {
    this.setState({scope: scope})
  }

  canProceed() {
    return !!this.state.name
  }

  previousStep() {
    this.setDetails()
    this.props.setStep(1)
  }

  nextStep() {
    this.setDetails()
    this.props.setStep(3)
  }

  setDetails() {
    const { name, isFirmLibrary, scope, category } = this.state
    this.props.setSaveData({
      name: name,
      isFirmLibrary: isFirmLibrary,
      scope: scope,
      category: category,
    })
  }
}
