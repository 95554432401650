import React, { Component } from 'react'
import { connect } from 'react-redux'

import Modal from 'app/common/Modal'

import ShareSearchContainer from './Container'
import { hideAssignModal } from '../searches-admin-actions'
import { getSearchesAdmin } from '../searches-admin-selectors'


@connect(
  state => {
    const searchesAdmin = getSearchesAdmin(state)
    return {
      shouldShow: searchesAdmin.shouldShowAssignModal,
      assignType: searchesAdmin.assignType,
      isLoading: searchesAdmin.isLoading,
    }
  },
  {
    hideAssignModal
  },
)
export default class ShareSearchesModal extends Component {
  render() {
    if (!this.props.shouldShow) {
      return null
    }

    return (
      <Modal
        id="share-searches-modal"
        isOpen={true}
        onClose={this.props.hideAssignModal}
      >
        <ShareSearchContainer/>
      </Modal>
    )
  }
}
