import React from 'react'

import { TabbedContainer, Tab } from 'app/common/TabbedContainer'

import Languages from './Languages'
import Locations from './locations/Locations'
import Classification, { CLASSIFICATION_TYPES } from './Classification'


export default function FirmSettings() {
  return (
    <div>
      <TabbedContainer defaultTabName="languages">
        <Tab name="languages" label="Languages">
          <Languages/>
        </Tab>

        <Tab name="locations" label="Office Locations">
          <Locations/>
        </Tab>

        <Tab name="departments" label="Departments">
          <Classification type={CLASSIFICATION_TYPES.DEPARTMENT} key={CLASSIFICATION_TYPES.DEPARTMENT}/>
        </Tab>

        <Tab name="teams" label="Teams">
          <Classification type={CLASSIFICATION_TYPES.TEAM} key={CLASSIFICATION_TYPES.TEAM}/>
        </Tab>
      </TabbedContainer>
    </div>
  )
}
