import classNames from 'classnames'
import * as dateFns from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'

import styles from './DateRangePicker.less'

const parseDate = date => dateFns.parseISO(date)
const formatDate = date => dateFns.format(date, 'M/d/yyyy')
const normalizeDate = dateFns.startOfDay


export default class DateRangePicker extends React.Component {
  static propTypes = {
    value: PropTypes.shape({
      start: PropTypes.object.isRequired,
      end: PropTypes.object.isRequired,
    }),
    onChange: PropTypes.func,
    autoFocusStart: PropTypes.bool,
    className: PropTypes.string,
    monthsBack: PropTypes.number,
  }

  state = {
    start: this.props.value ? this.props.value.start : null,
    end: this.props.value ? this.props.value.end : null,

    // Which input is focused: 'start', 'end', or null
    inputFocused: null,

    // The in-progress text typed into the focused input. This gets reset to
    // null once focus has been lost for the input.
    inputText: null,
  }

  render() {
    const {start, end, inputFocused, inputText} = this.state
    const modifiers = {start, end}
    const now = new Date
    const earliestPossibleDate = this.props.monthsBack
      ? dateFns.subMonths(now, this.props.monthsBack)
      : dateFns.subDays(now, 180)

    const startInputValue = (inputFocused === 'start' && inputText) || formatDate(start)
    const endInputValue = (inputFocused === 'end' && inputText) || formatDate(end)

    return (
      <div
        className={classNames(styles.dateRangePicker, this.props.className)}
        ref={el => this.container = el}
      >
        <input
          value={startInputValue}
          onChange={this.handleInputChange}
          onFocus={this.handleStartInputFocus}
          onBlur={this.handleInputBlur}
          autoFocus={this.props.autoFocusStart}
          className={styles.dateInput}
        />
        <span className={styles.separator}>—</span>
        <input
          value={endInputValue}
          onChange={this.handleInputChange}
          onFocus={this.handleEndInputFocus}
          onBlur={this.handleInputBlur}
          ref={input => this.endInput = input}
          className={styles.dateInput}
        />
        {inputFocused && (
          <div className={styles.datePicker}>
            <DayPicker
              selectedDays={[start, {from: start, to: end}]}
              disabledDays={{
                before: earliestPossibleDate,
                after: now,
              }}
              month={start}
              fromMonth={earliestPossibleDate}
              toMonth={now}
              numberOfMonths={2}
              onDayClick={this.handleDayChange}
              modifiers={modifiers}
            />
          </div>
        )}
      </div>
    )
  }

  componentDidMount() {
    document.addEventListener('click', this.handleGlobalClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleGlobalClick)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const curState = this.state
    if (prevState.start !== curState.start || prevState.end !== curState.end) {
      this.triggerChangeIfNeeded()
    }
  }

  handleInputChange = event => {
    this.setState({inputText: event.target.value})
  }

  handleStartInputFocus = () => {
    this.setState({
      inputFocused: 'start',
      inputText: null,
    })
  }

  handleEndInputFocus = () => {
    this.setState({
      inputFocused: 'end',
      inputText: null,
    })
  }

  handleInputBlur = () => {
    // If the user typed in a valid date, set the correct side to that date.
    // Otherwise, leave it as-is and let the input reset to the current value.
    this.setState(state => {
      const {inputFocused, inputText} = state
      let {start, end} = state
      if (inputText) {
        let date = parseDate(inputText)
        if (!isNaN(date)) {
          date = normalizeDate(date)
          if (inputFocused === 'start') {
            start = date
          }
          else {
            end = date
          }
        }
      }
      return {
        ...state,
        inputText: null,
        start,
        end,
      }
    })
  }

  handleDayChange = (date, modifiers, event) => {
    // Disallow selection of disabled days.
    if (modifiers.disabled) {
      event.preventDefault()
      event.stopPropagation()
      return
    }
    date = normalizeDate(date)
    if (this.state.inputFocused === 'start') {
      const newState = {start: date}
      // If we selected a start date after the end, set both start and end date to the selected date.
      if (dateFns.isAfter(date, this.state.end)) {
        newState.end = date
      }
      this.setState(newState, () => this.endInput.focus())
    } else if (this.state.inputFocused === 'end') {
      // If we selected an end date before the start, treat it as if we selected the start date.
      const dateToSet = dateFns.isBefore(date, this.state.start) ? 'start' : 'end'
      this.setState({
        [dateToSet]: date,
        inputFocused: null,
        inputText: null,
      })
    }
  }

  handleGlobalClick = event => {
    const {container} = this
    // Hide the date picker if the user clicks outside of it.
    if (container && !container.contains(event.target)) {
      this.setState({inputFocused: null, inputText: null})
    }
  }

  triggerChangeIfNeeded() {
    const {start, end} = this.state
    if (start && end && this.props.onChange) {
      this.props.onChange({start, end})
    }
  }
}
