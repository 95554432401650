import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import * as actions from '../../profile-preferences-actions'
import * as selectors from '../../profile-preferences-selectors'
import {Checklist} from '../common/checklist'


export default function QuickFilters() {
  const filters = useSelector(selectors.getQuickFilters)
  const selectedIds = useSelector(selectors.getSelectedQuickFilterIds)
  const loadingIds = useSelector(selectors.getQuickFilterLoadingIds)
  const dispatch = useDispatch()
  const items = filters.map(filter => ({
    key: filter.id,
    isChecked: selectedIds.includes(filter.id),
    isLoading: loadingIds.includes(filter.id),
    label: filter.name,
    group: filter.category === 'court' ? 'Circuit & Appellate Courts' : 'States',
    onChange: isSelected => {
      dispatch(
        actions.changeQuickFilterSelectedRequest({id: filter.id, isSelected})
      )
    },
  }))
  return <Checklist items={items} />
}
