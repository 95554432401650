import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import snakeCase from 'snake-case'
import DjangoCSRFToken from 'django-react-csrftoken'

import { Feed, User } from 'app/models'
import Orm from 'app/framework/Orm'
import { formatNumber } from 'app/utils'
import { getEntities } from 'app/entities/entities-selectors'
import {
  getCurrentUserId,
  getAppName,
  getAppNameFull,
  getCurrentFirmEnableSourceLastContentDisplay,
} from 'app/global/global-selectors'
import * as notifications from 'app/global/notifications'

import AddSourceModal from './AddSourceModal'
import BrowseModal from './BrowseModal'
import BulkActions from './BulkActions'
import EditModal from './EditModal'
import { TABS as EDIT_FEED_OVERLAY_TABS } from './EditModal/Content'
import FeedsTable from './FeedsTable'
import Header from './Header'
import ManageLabelsModal from './ManageLabelsModal/ManageLabelsModal'
import LabelModal from './LabelModal/LabelModal'
import LoadingOverlay from 'app/common/LoadingOverlay'
import ConfirmationModalRedesign from 'app/common/modals/ConfirmationModalRedesign'


import {
  setFilters,
  setSort,
  fetchFeeds,
  selectAllFeeds,
  showAssignModal,
  hideAssignModal,
  selectFeed,
  deselectFeed,
  showEditModal,
  hideEditModal,
  setSelectedFeedIds,
  setPageNumber,
  setIsManageLabelsModalShown,
  setIsLabelModalShown,
  promoteFeeds,
  unpromoteFeeds,
  demoteFeeds,
  undemoteFeeds,
  excludeFeeds,
  removeFeeds,
  setShouldShowSelfAddModal,
  setShouldShowBrowseModal,
  sendCsvEmail,
  setReportModalVisibility,
  setFeedIdsToReport,
  reportFeeds
} from './sources-admin-actions'
import { getSourcesAdmin } from './sources-admin-selectors'
import ManageLabelsSection from './ManageLabelsSection';
import ReportModal from './ReportModal'


@connect(
  // Map state to props
  createSelector(
    [getEntities, getCurrentUserId, getAppName, getAppNameFull, getCurrentFirmEnableSourceLastContentDisplay, getSourcesAdmin],
    (entities, currentUserId, appName, appNameFull, currentFirmEnableSourceLastContentDisplay, sourcesAdmin) => {
      const orm = Orm.withEntities(entities)
      return {
        currentUser: orm.getById(User, currentUserId),
        feedIds: sourcesAdmin.feedIds,
        feeds: orm.getByIds(Feed, sourcesAdmin.feedIds),
        itemsPerPage: sourcesAdmin.itemsPerPage,
        totalCount: sourcesAdmin.totalCount,
        currentPage: sourcesAdmin.currentPage,
        appName,
        appNameFull,
        currentFirmEnableSourceLastContentDisplay,
        selectedFeedIds: sourcesAdmin.selectedFeedIds,
        shouldShowEditModal: sourcesAdmin.shouldShowEditModal,
        assignModalData: sourcesAdmin.assignModalData,
        editModalData: sourcesAdmin.editModalData,
        isLoading: sourcesAdmin.isLoading,
        isManageLabelsModalShown: sourcesAdmin.isManageLabelsModalShown,
        isLabelModalShown: sourcesAdmin.isLabelModalShown,
        shouldShowBrowseModal: sourcesAdmin.shouldShowBrowseModal,
        shouldShowSelfAddModal: sourcesAdmin.shouldShowSelfAddModal,
        selectedColumns : sourcesAdmin.selectedColumns,
        isReportModalVisible: sourcesAdmin.isReportModalVisible
      }
    }
  ),
  // Map dispatch to props
  {
    setFilters,
    setSort,
    fetchFeeds,
    selectAllFeeds,
    promoteFeeds,
    unpromoteFeeds,
    demoteFeeds,
    undemoteFeeds,
    excludeFeeds,
    removeFeeds,
    showAssignModal,
    hideAssignModal,
    selectFeed,
    deselectFeed,
    showEditModal,
    hideEditModal,
    setSelectedFeedIds,
    setPageNumber,
    showNotification: notifications.actions.showNotification,
    setIsManageLabelsModalShown,
    setIsLabelModalShown,
    setShouldShowSelfAddModal,
    setShouldShowBrowseModal,
    sendCsvEmail,
    setReportModalVisibility,
    setFeedIdsToReport,
    reportFeeds
  },
)
export default class SourcesAdmin extends Component {
  state = {
    // Set this to an initial value so that we can automatically open an edit
    // modal on page load in certain situations
    editModalData: this.initialEditModalData,

    // can be the selected feeds or just the one relating to the row delete button
    deleteConfirmationFeedIds: [],
  }

  componentDidMount() {
    this.props.fetchFeeds()
  }

  showReportFeedModal(feedIds) {
    this.props.setFeedIdsToReport(feedIds)
    this.props.setReportModalVisibility(true)
  }

  render() {
    const {
      appName,
      appNameFull,
      feedIds,
      feeds,
      selectedFeedIds,
      totalCount,
      shouldShowBrowseModal,
      shouldShowSelfAddModal,
    } = this.props
    const {firm} = this.props.currentUser
    const pageCount = Math.ceil(this.props.totalCount / this.props.itemsPerPage)

    let modal
    if (shouldShowBrowseModal) {
      modal =
        <BrowseModal
          appName={appName}
          appNameFull={appNameFull}
          onClose={() => this.closeBrowseModal()}
        />
    } else if (shouldShowSelfAddModal) {
      modal =
        <AddSourceModal
          appName={appName}
          appNameFull={appNameFull}
          onClose={this.closeAddSourceModal}
        />
    } else if (this.props.editModalData.isVisible) {
      modal =
        <EditModal
          feed={this.getFeedByID(this.props.editModalData.feedId)}
          firm={firm}
          onClose={this.closeEditModal}
          defaultTab={this.props.editModalData.tab || EDIT_FEED_OVERLAY_TABS.DETAILS}
        />
    } else if (this.props.isManageLabelsModalShown) {
      modal = <ManageLabelsModal />
    } else if(this.props.isLabelModalShown){
      modal = <LabelModal/>
    }

    const isModalOpen =
      this.props.assignModalData.isVisible ||
      this.props.editModalData.isVisible ||
      this.props.isManageLabelsModalShown ||
      this.props.isLabelModalShown ||
      shouldShowSelfAddModal ||
      shouldShowBrowseModal

    const loadingOverlay = this.props.isLoading
      ? <div className={classNames('loading-container', {'is-modal': isModalOpen})}>
          <LoadingOverlay/>
        </div>
      : null

    const selectedCount = selectedFeedIds.length
    const selectedCountDisplay = formatNumber(selectedCount)
    const totalCountDisplay = formatNumber(totalCount)
    const selectedCountText =
      selectedCount ?
        <span className="selected-count-text">
          {
            selectedCount === totalCount ?
              `All ${selectedCountDisplay} searches selected`
              : `${selectedCountDisplay} of ${totalCountDisplay} searches selected`
          }
        </span>
        : null

    const selectAllLink =
      selectedCount === totalCount ?
        null
        : <a href="#" onClick={() => this.onChangeSelectAll('all')}>Select All {totalCountDisplay}</a>

      const deleteConfirmationMessage =
      (
        this.state.deleteConfirmationFeedIds.length === 1 ?
        'This source' : `The ${this.state.deleteConfirmationFeedIds.length} selected sources`
      ) + ' will be removed from your firm and will no longer bring content into your environment. '

    const deleteFeedConfirmationModal =
      this.state.deleteConfirmationFeedIds.length > 0
      ? <ConfirmationModalRedesign      
          message={deleteConfirmationMessage}
          confirmButtonText={`Delete`}
          onConfirm={() => this.removeFeeds(this.state.deleteConfirmationFeedIds)}
          onClose={() => this.hideDeleteConfirmationModal()}
          isDestructive={true}
          showExit={false}
        />
      : null

    return (
      <div id="sources-admin">
        <div id="firm-sources-main-wrapper">
          <ManageLabelsSection
            onManageLabels={() => this.props.setIsManageLabelsModalShown(true)}
          />
          <Header
            firm={firm}
            appName={appName}
            appNameFull={appNameFull}
            onFilterStateChange={this.setFilters}
            onBrowseSources={this.onBrowseSources}
            onAddSource={this.onAddSource}
          />

          <div className="sources-module">
            <div id="firm-sources-header-wrapper">
              <div className="selected-count">
                {selectedCountText}{selectAllLink}
              </div>

              <BulkActions
                firm={firm}
                selectedFeedIds={selectedFeedIds}
                visibleFeedIds={feedIds}
                totalCount={this.props.totalCount}
                visibleCount={feeds.length}
                onAssign={this.showAssignModal}
                onFeature={this.showFeatureModal}
                onPromote={this.promoteSelectedFeeds}
                onDemote={this.demoteSelectedFeeds}
                onExclude={this.excludeSelectedFeeds}
                onLabel={() => this.props.setIsLabelModalShown(true)}
                onRemove={() => this.showDeleteConfirmationModal(selectedFeedIds)}
                onExport={this.exportSelectedFeeds}
                onChangeSelectAll={(value) => this.onChangeSelectAll(value)}
                onReport={()=> this.showReportFeedModal(selectedFeedIds)}
                enableSourceLastContentDisplay={this.props.currentFirmEnableSourceLastContentDisplay}
              />
            </div>

            <div id="firm-sources-container">
              <FeedsTable
                feeds={feeds}
                firm={firm}
                selectedFeedIds={selectedFeedIds}
                onFeedSelectStateChange={this.setFeedSelectState}
                onEdit={this.showEditModal}
                onRemove={(feed) => this.showDeleteConfirmationModal([feed.id])}
                onPromotedChange={this.onPromotedChange}
                onDemotedChange={this.onDemotedChange}
                appName={this.props.appName}
                appNameFull={this.props.appNameFull}
                enableSourceLastContentDisplay={this.props.currentFirmEnableSourceLastContentDisplay}
                pageCount={pageCount}
                currentPage={this.props.currentPage}
                onPageChange={(page) => this.onPageChange(page)}
                onSortChange={(sortOptions) => this.onSortChange(sortOptions)}
                selectedColumns={this.props.selectedColumns}
                onReportClick={(feed) => this.showReportFeedModal([feed.id])}
              />
            </div>
          </div>
        </div>

        {modal}

        {deleteFeedConfirmationModal}

        <ReportModal/>

        {loadingOverlay}

        <form id="download-csv-form" method="post" action="/profile/firm/source-csv-export/">
          <DjangoCSRFToken/>
          <input id="csv_feed_ids" name="feed_ids"/>
        </form>
      </div>
    )
  }

  showDeleteConfirmationModal(feedIds) {
    this.setState({
      deleteConfirmationFeedIds: feedIds,
    })
  }

  hideDeleteConfirmationModal() {
    this.setState({
      deleteConfirmationFeedIds: [],
    })
  }

  // Event handlers

  onPromotedChange = (feed, promoted) => {
    if (promoted) {
      this.props.promoteFeeds([feed.id])
    } else {
      this.props.unpromoteFeeds([feed.id])
    }
  }

  promoteSelectedFeeds = () => {
    this.props.promoteFeeds(this.props.selectedFeedIds)
  }

  onDemotedChange = (feed, demoted) => {
    if (demoted) {
      this.props.demoteFeeds([feed.id])
    } else {
      this.props.undemoteFeeds([feed.id])
    }
  }

  demoteSelectedFeeds = () => {
    this.props.demoteFeeds(this.props.selectedFeedIds)
  }

  excludeSelectedFeeds = () => {
    this.props.excludeFeeds(this.props.selectedFeedIds)
  }

  /**
   * exports of 20 or less are processed immediately.
   * others are processed by a background task which emails the csv to the user.
   */
  exportSelectedFeeds = () => {
    const {selectedFeedIds} = this.props
    if (selectedFeedIds.length > 20) {
      this.props.sendCsvEmail()
    } else {
      document.getElementById('csv_feed_ids').value = selectedFeedIds.join(',')
      const csvForm = document.getElementById('download-csv-form')
      csvForm.submit()
    }
  }

  onBrowseSources = () => {
    this.props.setShouldShowBrowseModal(true)
  }

  onAddSource = () => {
    this.props.setShouldShowSelfAddModal(true)
  }

  onPageChange(page) {
    this.props.setPageNumber(page)
  }

  onSortChange(sortOptions) {
    const field = snakeCase(sortOptions.column)
    this.props.setSort({field, direction: sortOptions.direction})
  }

  // State management

  getFeedByID(id) {
    return this.props.feeds.find(fs => fs.id === id)
  }

  onChangeSelectAll(value) {
    this.props.setSelectedFeedIds([])
    if (value === 'all') {
      this.props.selectAllFeeds()
    } else if (value === 'visible') {
      this.props.setSelectedFeedIds(this.props.feedIds)
    }
  }

  setFeedSelectState = (id, selected) => {
    if (selected) {
      this.props.selectFeed(id)
    } else {
      this.props.deselectFeed(id)
    }
  }

  setFilters = (filters) => {
    this.props.setFilters(filters)
  }

  showAssignModal = () => {
    this.props.showAssignModal({feedIds: this.props.selectedFeedIds})
  }

  showFeatureModal = () => {
    this.props.showAssignModal({feedIds: this.props.selectedFeedIds, isFeaturing: true})
  }

  // Modals

  showEditModal = (feed, tab = EDIT_FEED_OVERLAY_TABS.DETAILS) => {
    this.props.showEditModal({feed: {...feed}, tab})
  }

  closeEditModal = () => {
    this.props.hideEditModal()
  }

  closeBrowseModal = () => {
    this.props.setShouldShowBrowseModal(false)
  }

  closeAddSourceModal = () => {
    this.props.setShouldShowSelfAddModal(false)
  }

  removeFeeds(feedIds) {
    this.props.removeFeeds(feedIds)
    this.hideDeleteConfirmationModal()
  }

  get initialEditModalData() {
    const {search} = window.location
    if (!search) return null

    // Slice to get rid of the '?'
    const params = new URLSearchParams(search.slice(1))
    if (!params.has('initial-feed-id')) return null

    const feedId = parseInt(params.get('initial-feed-id'), 10)
    const feed = this.getFeedByID(feedId)
    // We want to actually check to make sure the feed is here; otherwise, we
    // have no way of displaying it
    if (!feed) return null

    return {feedId}
  }
}
