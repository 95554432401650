import * as R from 'ramda'

import {STATE_PROP_NAME} from './searches-admin-constants'
import {
  STATE_PROP_NAME as FIRM_ADMIN_PROP_NAME
} from '../firm-admin-constants'

export const getSearchesAdmin = R.path([FIRM_ADMIN_PROP_NAME, STATE_PROP_NAME])
export const canShowRelevanceFilter = state => getSearchesAdmin(state).canShowRelevanceFilter
export const getFirmSourceLabels = state => getSearchesAdmin(state).firmSourceLabels
