import {all, delay, take, takeLatest, put, call, select} from 'redux-saga/effects'

import * as entitiesActions from 'app/entities/entities-actions'
import * as notifications from 'app/global/notifications'
import {logErrorEvent} from 'app/global/error-tracking'
import {handleSagaError} from 'app/utils/errors'
import {ignorePromise} from 'app/utils/promises'

import * as profileSourcesApi from './profile-sources-api'
import * as profileSourcesActions from './profile-sources-actions'
import {getProfileSources} from './profile-sources-selectors'
import {FILTER_FIELD_LABELS} from './profile-sources-constants'


function* handleInit(action) {
  try {
    yield put(profileSourcesActions.fetchFeaturedSources())
    yield put(profileSourcesActions.fetchFeeds())
    yield put(profileSourcesActions.fetchFeedAssignments())
    yield put(profileSourcesActions.fetchPromotedFeeds())
    yield put(profileSourcesActions.fetchTrustedFeeds())
    yield put(profileSourcesActions.fetchTrustedFolders())
    yield put(profileSourcesActions.fetchExcludedFeeds())
    yield put(profileSourcesActions.fetchDemotedFeeds())
    yield all([
      take(profileSourcesActions.fetchFeaturedSourcesComplete),
      take(profileSourcesActions.fetchFeedsComplete),
      take(profileSourcesActions.fetchFeedAssignmentsComplete),
      take(profileSourcesActions.fetchPromotedFeedsComplete),
      take(profileSourcesActions.fetchTrustedFeedsComplete),
      take(profileSourcesActions.fetchTrustedFoldersComplete),
      take(profileSourcesActions.fetchExcludedFeedsComplete),
      take(profileSourcesActions.fetchDemotedFeedsComplete),
    ])
    yield put(profileSourcesActions.initComplete())
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleFetchFeaturedSources(action) {
  try {
    const response = yield profileSourcesApi.fetchFeaturedSources()
    yield put(profileSourcesActions.fetchFeaturedSourcesComplete(response.featuredSources.map(s => s.feedId)))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleFetchFeeds(action) {
  try {
    const response = yield profileSourcesApi.fetchFeeds()
    const feedIds = []
    const feedEntities = {}
    response.feeds.items.forEach(f => {
      feedIds.push(f.id)
      feedEntities[f.id] = f
    })
    const entityData = {
      feeds: feedEntities,
    }
    yield put(entitiesActions.update(entityData))
    yield put(profileSourcesActions.fetchFeedsComplete({
      feedIds,
      excludedFirmWideFeedIds: response.feeds.excludedFeedIds,
    }))
    yield put(profileSourcesActions.fetchNlaLicense(
      response.feeds.nlaLicenseExpired 
    ))
    yield put(profileSourcesActions.fetchNlaFeedIds(
      response.feeds.nlaFeedIds
    ))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleFetchFeedAssignments(action) {
  try {
    const response = yield profileSourcesApi.fetchFeedAssignments()
    yield put(profileSourcesActions.fetchFeedAssignmentsComplete(response.sourceAssignments.map(sa => sa.feedId)))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleFetchPromotedFeeds(action) {
  try {
    const response = yield profileSourcesApi.fetchPromotedFeeds()
    const feedIds = []
    const feedEntities = {}
    response.feeds.forEach(f => {
      feedIds.push(f.id)
      feedEntities[f.id] = f
    })
    const entityData = {
      feeds: feedEntities,
    }
    yield put(entitiesActions.update(entityData))
    yield put(profileSourcesActions.fetchPromotedFeedsComplete(feedIds))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleFetchTrustedFeeds(action) {
  try {
    // note, trusted feeds are stored as saved searches.
    const response = yield profileSourcesApi.fetchTrustedFeeds()
    const searchIds = []
    const searchEntities = {}
    const feedEntities = {}
    response.searches.forEach(ss => {
      // prevent invalid trusted sources from breaking the page; log error.
      if (ss.aboutSources.length === 0) {
        ignorePromise(
          logErrorEvent({
            message: `Trusted Source SS (id:${ss.id}) has no About Sources.`
          })
        )
        return
      }
      ss.aboutFeedsIds = []
      ss.aboutSources.forEach(f => {
        ss.aboutFeedsIds.push(f.id)
        feedEntities[f.id] = f
      })
      searchIds.push(ss.id)
      searchEntities[ss.id] = ss
    })
    const entityData = {
      searches: searchEntities,
      feeds: feedEntities,
    }
    yield put(entitiesActions.update(entityData))
    yield put(profileSourcesActions.fetchTrustedFeedsComplete(searchIds))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleFetchTrustedFolders(action) {
  try {
    // note, trusted folders are stored as saved searches.
    const response = yield profileSourcesApi.fetchTrustedFolders()
    const searchIds = []
    const searchEntities = {}
    const feedEntities = {}
    response.searches.forEach(ss => {
      ss.aboutFeedsIds = []
      ss.aboutSources.forEach(f => {
        ss.aboutFeedsIds.push(f.id)
        feedEntities[f.id] = f
      })
      searchIds.push(ss.id)
      searchEntities[ss.id] = ss
    })
    const entityData = {
      searches: searchEntities,
      feeds: feedEntities,
    }
    yield put(entitiesActions.update(entityData))
    yield put(profileSourcesActions.fetchTrustedFoldersComplete(searchIds))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleFetchExcludedFeeds(action) {
  try {
    const response = yield profileSourcesApi.fetchExcludedFeeds()
    const feedIds = []
    const feedEntities = {}
    response.feeds.forEach(f => {
      feedIds.push(f.id)
      feedEntities[f.id] = f
    })
    const entityData = {
      feeds: feedEntities,
    }
    yield put(entitiesActions.update(entityData))
    yield put(profileSourcesActions.fetchExcludedFeedsComplete(feedIds))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleFetchDemotedFeeds(action) {
  try {
    const response = yield profileSourcesApi.fetchDemotedFeeds()
    const feedIds = []
    const feedEntities = {}
    response.feeds.forEach(f => {
      feedIds.push(f.id)
      feedEntities[f.id] = f
    })
    const entityData = {
      feeds: feedEntities,
    }
    yield put(entitiesActions.update(entityData))
    yield put(profileSourcesActions.fetchDemotedFeedsComplete(feedIds))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleFilterChange(action) {
  const profileSources = yield select(getProfileSources)
  const {filter, filterField} = profileSources
  if (!filter || filterField === FILTER_FIELD_LABELS) {
    yield put(profileSourcesActions.setGlobalFeedIds([]))
    return
  }
  yield delay(200)
  try {
    yield put(profileSourcesActions.setIsLoading(false))
    const response = yield profileSourcesApi.fetchGlobalFeeds({filter, filterField})
    const feedIds = []
    const feedEntities = {}
    response.globalFeeds.forEach(f => {
      feedIds.push(f.id)
      feedEntities[f.id] = f
    })
    const entityData = {
      feeds: feedEntities,
    }
    yield put(entitiesActions.update(entityData))
    yield put(profileSourcesActions.setGlobalFeedIds(feedIds))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handlePromoteSelectedFeeds(action) {
  const profileSources = yield select(getProfileSources)
  const feedIds = profileSources.selectedFirmFeedIds.concat(profileSources.selectedGlobalFeedIds)
  try {
    const response = yield profileSourcesApi.promoteFeeds(feedIds)
    yield put(profileSourcesActions.promoteSelectedFeedsComplete())
    yield put(profileSourcesActions.fetchPromotedFeeds())
    yield put(profileSourcesActions.fetchDemotedFeeds())
    yield put(notifications.actions.showNotification({
      type: 'success',
      message: 'Promoted Source(s) added successfully',
    }))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleTrustSelectedFeeds(action) {
  const profileSources = yield select(getProfileSources)
  const feedIds = profileSources.selectedFirmFeedIds.concat(profileSources.selectedGlobalFeedIds)
  try {
    let response
    if (feedIds.length <= 10) {
      response = yield profileSourcesApi.trustFeeds(feedIds)
      yield put(profileSourcesActions.trustSelectedFeedsComplete())
      yield put(profileSourcesActions.fetchTrustedFeeds())
      yield put(notifications.actions.showNotification({
        type: 'success',
        message: 'Trusted Source(s) added successfully',
      }))
    }
    else {
      yield put(notifications.actions.showNotification({
        type: 'success',
        message: 'Trusted Source(s) will be added in sometime',
      }))
      yield delay(5000)
      yield put(profileSourcesActions.setIsTrustedLoader())
      yield put(profileSourcesActions.trustSelectedFeedsComplete())
      response = yield profileSourcesApi.trustFeeds(feedIds)
      yield put(profileSourcesActions.fetchTrustedFeeds())
      yield put(notifications.actions.showNotification({
        type: 'success',
        message: 'Trusted Source(s) added successfully',
      }))
    }
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleExcludeSelectedFeeds(action) {
  const profileSources = yield select(getProfileSources)
  const feedIds = profileSources.selectedFirmFeedIds.concat(profileSources.selectedGlobalFeedIds)
  try {
    const response = yield profileSourcesApi.excludeFeeds(feedIds)
    yield put(profileSourcesActions.excludeSelectedFeedsComplete())
    yield put(profileSourcesActions.fetchExcludedFeeds())
    yield put(notifications.actions.showNotification({
      type: 'success',
      message: 'Excluded Source(s) added successfully',
    }))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleDemoteSelectedFeeds(action) {
  let feedIds = action.payload
  if (!feedIds) {
    const profileSources = yield select(getProfileSources)
    feedIds = profileSources.selectedFirmFeedIds.concat(profileSources.selectedGlobalFeedIds)
  }

  try {
    yield profileSourcesApi.demoteFeeds(feedIds)
    yield put(profileSourcesActions.demoteSelectedFeedsComplete())
    yield put(profileSourcesActions.fetchDemotedFeeds())
    yield put(profileSourcesActions.fetchPromotedFeeds())
    yield put(notifications.actions.showNotification({
      type: 'success',
      message: 'Source(s) demoted successfully.',
    }))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleCreateFolder(action) {
  try {
    const response = yield profileSourcesApi.createFolder(action.payload)
    yield put(profileSourcesActions.fetchTrustedFolders())
    yield put(profileSourcesActions.createFolderComplete())
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleAddFeedsToFolders(action) {
  try {
    const response = yield profileSourcesApi.addFeedsToFolders(action.payload)
    yield put(profileSourcesActions.fetchTrustedFolders())
    yield put(profileSourcesActions.addFeedsToFoldersComplete())
    if (response.addSourceToTrustedFolder){
    yield put(notifications.actions.showNotification({
      type: 'success',
      message: 'Source(s) added to Trusted Source Folder(s) successfully',
    }))
    }
    else {
      yield put(notifications.actions.showNotification({
        type: 'error',
        message: 'Source(s) already exist in Trusted Source Folder(s)',
      }))
    }
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleDeletePromotedFeed(action) {
  try {
    const response = yield profileSourcesApi.deletePromotedFeed(action.payload)
    yield put(profileSourcesActions.fetchPromotedFeeds())
    yield put(profileSourcesActions.deletePromotedFeedComplete())
    yield put(profileSourcesActions.setSelectedPromotedFeedIds([]))
    if (!action.payload.bypassNotification) {
      yield put(notifications.actions.showNotification({
        type: 'success',
        message: 'Promoted Source(s) removed successfully',
      }))
    }
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleDeleteTrustedFeed(action) {
  try {
    const response = yield profileSourcesApi.deleteTrustedFeed(action.payload)
    yield put(profileSourcesActions.fetchTrustedFeeds())
    yield put(profileSourcesActions.deleteTrustedFeedComplete())
    yield put(profileSourcesActions.setSelectedTrustedFolderIds([]))
    yield put(profileSourcesActions.setSelectedTrustedFeedIds([]))
    if (!action.payload.bypassNotification) {
      yield put(notifications.actions.showNotification({
        type: 'success',
        message: 'Trusted Source(s) removed successfully',
      }))
    }
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleDeleteTrustedFeedsAndFolders(action) {
  try {
    const { feedIds, folderIds } = action.payload
    const deleteTrustedFeed = yield profileSourcesApi.deleteTrustedFeed({ id: feedIds })
    const deleteTrustedFolder = yield profileSourcesApi.deleteTrustedFolder({ id: folderIds })
    yield put(profileSourcesActions.fetchTrustedFeeds())
    yield put(profileSourcesActions.fetchTrustedFolders())
    yield put(profileSourcesActions.deleteTrustedFeedsAndFoldersComplete())
    yield put(profileSourcesActions.setSelectedTrustedFolderIds([]))
    yield put(profileSourcesActions.setSelectedTrustedFeedIds([]))
    if (!action.payload.bypassNotification) {
      yield put(notifications.actions.showNotification({
        type: 'success',
        message: 'Trusted Source Feed(s) and Folder(s) removed successfully',
      }))
    }
  } catch (error) {
    yield* handleSagaError(error)
  }
}

function* handleDeleteTrustedFolder(action) {
  try {
    const response = yield profileSourcesApi.deleteTrustedFolder(action.payload)
    yield put(profileSourcesActions.fetchTrustedFolders())
    yield put(profileSourcesActions.deleteTrustedFolderComplete())
    yield put(profileSourcesActions.setSelectedTrustedFolderIds([]))
    yield put(profileSourcesActions.setSelectedTrustedFeedIds([]))
    if (!action.payload.bypassNotification) {
      yield put(notifications.actions.showNotification({
        type: 'success',
        message: 'Trusted Source Folder(s) removed successfully',
      }))
    }
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleDeleteExcludedFeed(action) {
  try {
    const response = yield profileSourcesApi.deleteExcludedFeed(action.payload)
    yield put(profileSourcesActions.fetchExcludedFeeds())
    yield put(profileSourcesActions.deleteExcludedFeedComplete())
    yield put(profileSourcesActions.setSelectedExcludedFeedIds([]))
    if (!action.payload.bypassNotification) {
      yield put(notifications.actions.showNotification({
        type: 'success',
        message: 'Excluded Source(s) removed successfully',
      }))
    }
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleDeleteDemotedFeed(action) {
  try {
    yield profileSourcesApi.deleteDemotedFeed(action.payload)
    yield put(profileSourcesActions.fetchDemotedFeeds())
    yield put(profileSourcesActions.deleteDemotedFeedComplete())
    yield put(profileSourcesActions.setSelectedDemotedFeedIds([]))
    if (!action.payload.bypassNotification) {
      yield put(notifications.actions.showNotification({
        type: 'success',
        message: 'Demoted Source(s) removed successfully',
      }))
    }
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleRemoveFeedFromTrustedFolder(action) {
  try {
    const response = yield profileSourcesApi.removeFeedFromFolder(action.payload)
    yield put(profileSourcesActions.fetchTrustedFolders())
    yield put(profileSourcesActions.removeFeedFromTrustedFolderComplete())
    if (!action.payload.bypassNotification) {
      yield put(notifications.actions.showNotification({
        type: 'success',
        message: 'Trusted Source removed successfully',
      }))
    }
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleSubmitLimitedSeatRequest(action) {
  try {
    const response = yield profileSourcesApi.submitLimitedSeatRequest(action.payload)
    yield put(profileSourcesActions.submitLimitedSeatRequestComplete())
    yield put(notifications.actions.showNotification({
      type: 'success',
      message: 'Limited Seat request submitted successfully',
    }))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handlePromoteFeed(action) {
  try {
    const response = yield profileSourcesApi.promoteFeed(action.payload)
    yield put(profileSourcesActions.fetchPromotedFeeds())
    yield put(profileSourcesActions.fetchDemotedFeeds())
    yield put(notifications.actions.showNotification({
      type: 'success',
      message: 'Promoted Source added successfully',
    }))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleTrustFeed(action) {
  try {
    const response = yield profileSourcesApi.trustFeed(action.payload)
    yield put(profileSourcesActions.fetchTrustedFeeds())
    yield put(notifications.actions.showNotification({
      type: 'success',
      message: 'Trusted Source added successfully',
    }))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleExcludeFeed(action) {
  try {
    const response = yield profileSourcesApi.excludeFeed(action.payload)
    yield put(profileSourcesActions.fetchExcludedFeeds())
    yield put(notifications.actions.showNotification({
      type: 'success',
      message: 'Excluded Source added successfully',
    }))
  } catch(error) {
    yield* handleSagaError(error)
  }
}


function* handleAddFeedToFolder(action) {
  try {
    const response = yield profileSourcesApi.addFeedToFolder(action.payload)
    yield put(profileSourcesActions.fetchTrustedFolders())
    yield put(notifications.actions.showNotification({
      type: 'success',
      message: 'Trusted Source added successfully',
    }))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleSaveFolderName(action) {
  const userId = yield select(state => state.currentUser)
  try {
    const response = yield profileSourcesApi.saveFolderName({userId, ...action.payload})
    yield put(profileSourcesActions.fetchTrustedFolders())
    yield put(profileSourcesActions.saveFolderNameComplete())
    yield put(notifications.actions.showNotification({
      type: 'success',
      message: 'Trusted Source Folder name saved successfully',
    }))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleMoveFeed(action) {
  try {
    /**
     * destinationId is only used for moving feed into folder.
     * originId is only used for moving trusted feeds (ss id).
     */
    const {id, destination, origin, destinationId, originId} = action.payload
    const deleteMethods = {
      promoted: profileSourcesApi.deletePromotedFeed,
      trusted: profileSourcesApi.deleteTrustedFeed,
      'trusted-folder': profileSourcesApi.removeFeedFromFolder,
      excluded: profileSourcesApi.deleteExcludedFeed,
      demoted: profileSourcesApi.deleteDemotedFeed,
    }
    const createMethods = {
      promoted: profileSourcesApi.promoteFeed,
      trusted: profileSourcesApi.trustFeed,
      'trusted-folder': profileSourcesApi.addFeedToFolder,
      excluded: profileSourcesApi.excludeFeed,
      demoted: profileSourcesApi.demoteFeeds,
    }
    const fetchMethods = {
      promoted: profileSourcesActions.fetchPromotedFeeds,
      trusted: profileSourcesActions.fetchTrustedFeeds,
      'trusted-folder': profileSourcesActions.fetchTrustedFolders,
      excluded: profileSourcesActions.fetchExcludedFeeds,
      demoted: profileSourcesActions.fetchDemotedFeeds,
    }
    const deleteMethod = deleteMethods[origin]
    const createMethod = createMethods[destination]
    const fetchMethodDestination = fetchMethods[destination]
    const fetchMethodOrigin = fetchMethods[origin]
    let deleteParams = {
      id,
      bypassNotification: true,
    }
    let createParams = {
      id,
    }
    if (origin === 'trusted-folder') {
      deleteParams.searchId = originId
    } else if (origin === 'trusted') {
      deleteParams.id = originId
    }
    if (destination === 'trusted-folder') {
      createParams.searchId = destinationId
    }
    yield all([
      deleteMethod(deleteParams),
      createMethod(createParams),
    ])
    yield all([
      put(fetchMethodDestination()),
      put(fetchMethodOrigin()),
    ])
    yield put(notifications.actions.showNotification({
      type: 'success',
      message: 'Source moved successfully',
    }))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

export default function*() {
  yield all([
    takeLatest(
      profileSourcesActions.init,
      handleInit
    ),
    takeLatest(
      profileSourcesActions.fetchFeaturedSources,
      handleFetchFeaturedSources
    ),
    takeLatest(
      profileSourcesActions.fetchFeeds,
      handleFetchFeeds
    ),
    takeLatest(
      profileSourcesActions.fetchFeedAssignments,
      handleFetchFeedAssignments
    ),
    takeLatest(
      profileSourcesActions.fetchPromotedFeeds,
      handleFetchPromotedFeeds
    ),
    takeLatest(
      profileSourcesActions.fetchTrustedFeeds,
      handleFetchTrustedFeeds
    ),
    takeLatest(
      profileSourcesActions.fetchTrustedFolders,
      handleFetchTrustedFolders
    ),
    takeLatest(
      profileSourcesActions.fetchExcludedFeeds,
      handleFetchExcludedFeeds
    ),
    takeLatest(
      profileSourcesActions.fetchDemotedFeeds,
      handleFetchDemotedFeeds
    ),
    takeLatest(
      profileSourcesActions.setFilter,
      handleFilterChange
    ),
    takeLatest(
      profileSourcesActions.setFilterField,
      handleFilterChange
    ),
    takeLatest(
      profileSourcesActions.promoteSelectedFeeds,
      handlePromoteSelectedFeeds
    ),
    takeLatest(
      profileSourcesActions.trustSelectedFeeds,
      handleTrustSelectedFeeds
    ),
    takeLatest(
      profileSourcesActions.excludeSelectedFeeds,
      handleExcludeSelectedFeeds
    ),
    takeLatest(
      profileSourcesActions.demoteSelectedFeeds,
      handleDemoteSelectedFeeds
    ),
    takeLatest(
      profileSourcesActions.createFolder,
      handleCreateFolder
    ),
    takeLatest(
      profileSourcesActions.addFeedsToFolders,
      handleAddFeedsToFolders
    ),
    takeLatest(
      profileSourcesActions.deletePromotedFeed,
      handleDeletePromotedFeed
    ),
    takeLatest(
      profileSourcesActions.deleteTrustedFeed,
      handleDeleteTrustedFeed
    ),
    takeLatest(
      profileSourcesActions.deleteTrustedFolder,
      handleDeleteTrustedFolder
    ),
    takeLatest(
      profileSourcesActions.deleteExcludedFeed,
      handleDeleteExcludedFeed
    ),
    takeLatest(
      profileSourcesActions.deleteDemotedFeed,
      handleDeleteDemotedFeed
    ),
    takeLatest(
      profileSourcesActions.removeFeedFromTrustedFolder,
      handleRemoveFeedFromTrustedFolder
    ),
    takeLatest(
      profileSourcesActions.submitLimitedSeatRequest,
      handleSubmitLimitedSeatRequest
    ),
    takeLatest(
      profileSourcesActions.promoteFeed,
      handlePromoteFeed
    ),
    takeLatest(
      profileSourcesActions.trustFeed,
      handleTrustFeed
    ),
    takeLatest(
      profileSourcesActions.excludeFeed,
      handleExcludeFeed
    ),
    takeLatest(
      profileSourcesActions.demoteFeeds,
      handleDemoteSelectedFeeds
    ),
    takeLatest(
      profileSourcesActions.addFeedToFolder,
      handleAddFeedToFolder
    ),
    takeLatest(
      profileSourcesActions.saveFolderName,
      handleSaveFolderName
    ),
    takeLatest(
      profileSourcesActions.moveFeed,
      handleMoveFeed
    ),
    takeLatest(
      profileSourcesActions.deleteTrustedFeedsAndFolders,
      handleDeleteTrustedFeedsAndFolders
    ),
  ])
}
