import { createAction } from 'redux-actions'

// Prefix action names with SETTINGS_
const createFsAction = name => createAction(`SETTINGS_${name}`)

export const init = createFsAction('INIT')
export const setIsLoading = createFsAction('SET_IS_LOADING')
export const updateAssignModalData = createFsAction('UPDATE_ASSIGN_MODAL_DATA')
export const resetAssignModalData = createFsAction('RESET_ASSIGN_MODAL_DATA')

// language actions
export const fetchLanguages = createFsAction('FETCH_LANGUAGES')
export const setLanguages = createFsAction('SET_LANGUAGES')
export const addLanguage = createFsAction('ADD_LANGUAGE')
export const removeLanguage = createFsAction('REMOVE_LANGUAGE')

// firm location actions
export const fetchLocations = createFsAction('FETCH_LOCATIONS')
export const setLocations = createFsAction('SET_LOCATIONS')
export const setNewLocationDescription = createAction('SET_NEW_LOCATION_DESCRIPTION')
export const setNewLocationCountryId = createAction('SET_NEW_LOCATION_COUNTRY_ID')
export const setNewLocationTimezone = createAction('SET_NEW_LOCATION_TIMEZONE')
export const setEditLocationModalData = createFsAction('SET_EDIT_LOCATION_MODAL_DATA')
export const hideEditLocationModal = createFsAction('HIDE_EDIT_LOCATION_MODAL')
export const showDeleteLocationModal = createFsAction('SHOW_DELETE_MODAL')
export const deleteLocations = createFsAction('DELETE_LOCATIONS')
export const hideDeleteLocationModal = createFsAction('HIDE_DELETE_LOCATION_MODAL')
export const addSelectedLocationId = createFsAction('ADD_SELECTED_LOCATION_ID')
export const removeSelectedLocationId = createFsAction('REMOVE_SELECTED_LOCATION_ID')
export const setSelectedLocationIds = createFsAction('SET_SELECTED_LOCATION_IDS')
export const saveLocation = createFsAction('UPDATE_LOCATION')
export const saveLocationAssignments = createFsAction('SAVE_LOCATION_ASSIGNMENTS')
export const addLocationLanguage = createFsAction('ADD_LOCATION_LANGUAGE')
export const removeLocationLanguage = createFsAction('REMOVE_LOCATION_LANGUAGE')

// classification actions
export const fetchDepartments = createFsAction('FETCH_DEPARTMENTS')
export const setDepartments = createFsAction('SET_DEPARTMENTS')
export const fetchTeams = createFsAction('FETCH_TEAMS')
export const setTeams = createFsAction('SET_TEAMS')
export const deleteDepartments = createFsAction('DELETE_DEPARTMENTS')
export const deleteTeams = createFsAction('DELETE_TEAMS')
export const setEditClassificationModalData = createFsAction('SET_EDIT_CLASSIFICATION_MODAL_DATA')
export const hideEditClassificationModal = createFsAction('HIDE_EDIT_CLASSIFICATION_MODAL')
export const saveDepartment = createFsAction('SAVE_DEPARTMENT')
export const saveTeam = createFsAction('SAVE_TEAM')
export const saveDepartmentAssignments = createFsAction('SAVE_DEPARTMENT_ASSIGNMENTS')
export const saveTeamAssignments = createFsAction('SAVE_TEAM_ASSIGNMENTS')
export const setSelectedClassificationIds = createFsAction('SET_SELECTED_CLASSIFICATION_IDS')
export const setNewClassificationItemName = createFsAction('SET_NEW_CLASSIFICATION_ITEM_NAME')
export const setDeleteClassificationIds = createFsAction('SET_DELETE_CLASSIFICATION_IDS')
