import {apiCall} from 'app/api'
import {graphqlQuery} from 'app/api'
import {args, gql} from 'app/graphql'

export function searchManzamaSources(data, callback) {
  return apiCall({
    url: '/frontend-api/feed/autocomplete/',
    method: 'GET',
    data: {
      q: data.name,
      exclude: data.exclude,
      _: +new Date,
      currentPage: data.currentPage,
      sortField: data.sortField,
      sortDirection: data.sortDirection,
      browse_sources: true,
    },
    callback,
  })
}

const userFields = () => {
  return `
    id
    username
    firstName
    lastName
    role
  `
}

export function fetchFeeds({
  page,
  itemsPerPage,
  sortField,
  sortDirection,
  name,
  label,
  scope,
  publicationTypes,
  isPromoted,
  isDemoted,
  isExcluded,
  isLimitedSeat,
  isAssigned,
  status,
} = {}) {
  return graphqlQuery(
    gql`
      query {
        feeds (
          ${args({
            page,
            itemsPerPage,
            sortField,
            sortDirection,
            name,
            label,
            scope,
            publicationTypes,
            isPromoted,
            isDemoted,
            isExcluded,
            isLimitedSeat,
            isAssigned,
            status,
          })}
        ) {
          totalCount
          excludedFeedIds
          items {
            id
            name
            isProprietary
            sourceType
            url
            createdAt
            createdBy {
              ${userFields}
            }
            updatedAt
            updatedBy {
              ${userFields}
            }
            sourceNotes
            isLimitedSeat
            isPromoted
            isPromotedFirmwide
            isDemoted
            isDemotedFirmwide
            assignedToIndividualsCount
            publicationType
            lastContentAt
            status
            reported
            labels {
              id
              name
            }
            language {
              name
            }
          }
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function fetchFeedIds({
  name,
  label,
  scope,
  publicationTypes,
  isPromoted,
  isDemoted,
  isExcluded,
  isLimitedSeat,
  isAssigned,
  status,
} = {}) {
  return graphqlQuery(
    gql`
      query {
        feeds(${args({
          isPaged: false,
          name,
          label,
          scope,
          publicationTypes,
          isPromoted,
          isDemoted,
          isExcluded,
          isLimitedSeat,
          isAssigned,
          status,
        })}) {
          items {
            id
            status
            reported
          }
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function fetchFeed(id) {
  return graphqlQuery(
    gql`
      query {
        feed(${args({id})}) {
          assignments {
            id
            feedId
            isPromoted
            isDemoted
            isTrusted
            isExcluded
            assignmentType
            assignedTo {
              ${userFields}
              groupMemberships {
                id
                user {
                  ${userFields}
                }
                group {
                  ${userFields}
                }
                isManager
              }
              userMemberships {
                id
                user {
                  ${userFields}
                }
                group {
                  ${userFields}
                }
                isManager
              }
            }
          }
          featuredForUsers {
            ${userFields}
          }
          featuredForDepartments {
            id
            name
          }
          featuredForTeams {
            id
            name
          }
          reportedOn {
            date
          }
          sourceNotes
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function fetchAllUsersAndGroups(isActive = null) {
  return graphqlQuery(
    gql`
      query {
        users: usersAndGroups(${args({isActive})}) {
          ${userFields}
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function promoteFeeds(feedIds) {
  return graphqlQuery(
    feedIds.map(
      feedId =>
        gql`
        mutation {
          promoteSourceFirmWide(${args({feedId})})
        }
      `,
    ),
  ).then(({body: {data}}) => data)
}

export function unpromoteFeeds(feedIds) {
  return graphqlQuery(
    feedIds.map(
      feedId =>
        gql`
        mutation {
          unpromoteSourceFirmWide(${args({feedId})})
        }
      `,
    ),
  ).then(({body: {data}}) => data)
}

export function demoteFeeds(feedIds) {
  return graphqlQuery(
    feedIds.map(
      feedId =>
        gql`
        mutation {
          demoteSourceFirmWide(${args({feedId})})
        }
      `,
    ),
  ).then(({body: {data}}) => data)
}

export function undemoteFeeds(feedIds) {
  return graphqlQuery(
    feedIds.map(
      feedId =>
        gql`
        mutation {
          undemoteSourceFirmWide(${args({feedId})})
        }
      `,
    ),
  ).then(({body: {data}}) => data)
}

export function excludeFeeds(feedIds) {
  return graphqlQuery(
    feedIds.map(
      feedId =>
        gql`
        mutation {
          excludeSourceFirmWide(${args({feedId})})
        }
      `,
    ),
  ).then(({body: {data}}) => data)
}

export function removeFeeds(feedIds) {
  return graphqlQuery(
    feedIds.map(
      feedId =>
        gql`
        mutation {
          deleteSource(${args({feedId})})
        }
      `,
    ),
  ).then(({body: {data}}) => data)
}

export function saveFeed({
  feedId,
  name,
  url,
  isPromoted,
  isDemoted,
  isExcluded,
  isLimitedSeat,
  sourceNotes
}) {
  return graphqlQuery(
    gql`
      mutation {
        saveSource(${args({
          feedId,
          name,
          url,
          isPromoted,
          isDemoted,
          isExcluded,
          isLimitedSeat,
          sourceNotes
        })})
      }
    `,
  ).then(({body: {data}}) => data)
}

export function createSources({feedIds}) {
  return graphqlQuery(
    feedIds.map(
      feedId =>
        gql`
        mutation {
          createFirmSource(${args({
            feedId,
          })})
        }
      `,
    ),
  ).then(({body: {data}}) => data)
}

export function validateFeed({url}) {
  return graphqlQuery(
    gql`
      query {
        validateFeed(${args({
          url,
        })}) {
          isExisting
          feeds {
            id
            name
            url
            isAdded
            documents {
              headline
              link
            }
          }
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function createProprietaryFeed({
  name,
  url,
  languageId,
  notes,
  isSubscriptionRequired,
  isLimitedSeat,
}) {
  return graphqlQuery(
    gql`
      mutation {
        createProprietaryFeed(${args({
          name,
          url,
          languageId,
          notes,
          isSubscriptionRequired,
          isLimitedSeat,
        })})
      }
    `,
  ).then(({body: {data}}) => data)
}

export function createTwitterFeed({handle, languageId}) {
  return graphqlQuery(
    gql`
      mutation {
        createTwitterFeed(${args({
          handle,
          languageId,
        })})
      }
    `,
  ).then(({body: {data}}) => data)
}

export function addSourceAssignments({
  feedIds,
  userIds,
  groupIds,
  groupIdsForMembers,
  departmentIdsForMembers,
  teamIdsForMembers,
  firmLocationIdsForMembers,
  isPromoted,
  isDemoted,
  isTrusted,
  isExcluded,
  noticeConfig,
}) {
  return graphqlQuery(
    gql`
      mutation {
        addSourceAssignments(${args({
          feedIds,
          userIds,
          groupIds,
          groupIdsForMembers,
          departmentIdsForMembers,
          teamIdsForMembers,
          firmLocationIdsForMembers,
          isPromoted,
          isDemoted,
          isTrusted,
          isExcluded,
          noticeConfig,
        })})
      }
    `,
  ).then(({body: {data}}) => data)
}

export function updateSourceAssignments({
  ids,
  isPromoted,
  isDemoted,
  isTrusted,
  isExcluded,
}) {
  return graphqlQuery(
    gql`
      mutation {
        updateSourceAssignments(${args({
          ids,
          isPromoted,
          isDemoted,
          isTrusted,
          isExcluded,
        })})
      }
    `,
  ).then(({body: {data}}) => data)
}

export function removeSourceAssignments(ids) {
  return graphqlQuery(
    gql`
      mutation {
        removeSourceAssignments(${args({ids})})
      }
    `,
  ).then(({body: {data}}) => data)
}

export function fetchProfileSearches() {
  return graphqlQuery(
    gql`
      query {
        currentUser {
          savedSearches {
            id
            name
            slug
            category
          }
        }
      }
    `,
  ).then(({body: {data}}) => data.currentUser.savedSearches)
}

export function fetchLabels() {
  return graphqlQuery(
    gql`
      query {
        labels: firmSourceLabelsPaged() {
          items {
            id
            name
            parentId
            createdOn
          }
          totalCount
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function createLabel({name, parentId}) {
  return graphqlQuery(
    gql`
      mutation {
        createFirmSourceLabel(${args({
          name,
          parentId,
        })})
      }
    `,
  ).then(({body: {data}}) => data)
}

export function saveLabel({id, name, parentId}) {
  return graphqlQuery(
    gql`
      mutation {
        saveFirmSourceLabel(${args({
          id,
          name,
          parentId,
        })})
      }
    `,
  ).then(({body: {data}}) => data)
}

export function deleteLabel(id) {
  return graphqlQuery(
    gql`
      mutation {
        deleteFirmSourceLabel(${args({id})})
      }
    `,
  ).then(({body: {data}}) => data)
}

export function addLabels({feedIds, labelIds}) {
  return graphqlQuery(
    gql`
      mutation {
        addFirmSourceLabels(${args({feedIds, labelIds})})
      }
    `,
  ).then(({body: {data}}) => data)
}

export function removeLabels({feedIds, labelIds}) {
  return graphqlQuery(
    feedIds.map(feedId =>
      labelIds.map(
        labelId =>
          gql`
          mutation {
            removeFirmSourceLabel(${args({feedId, labelId})})
          }
        `,
      ),
    ),
  ).then(({body: {data}}) => data)
}

export function addFeaturedSources({
  feedIds,
  userIds,
  groupIds,
  groupIdsForMembers,
  departmentIds,
  departmentIdsForMembers,
  teamIds,
  teamIdsForMembers,
  firmLocationIdsForMembers,
}) {
  return graphqlQuery(
    gql`
      mutation {
        addFeaturedSources(${args({
          feedIds,
          userIds,
          groupIds,
          groupIdsForMembers,
          departmentIds,
          departmentIdsForMembers,
          teamIds,
          teamIdsForMembers,
          firmLocationIdsForMembers,
        })})
      }
    `,
  ).then(({body: {data}}) => data)
}

export function removeFeaturedSources({feedId, fromItems}) {
  return graphqlQuery(
    fromItems.map(
      item =>
        gql`
        mutation {
          removeFeaturedSource(${args({
            feedId,
            fromId: item.id,
            fromType: item.type,
          })})
        }
      `,
    ),
  ).then(({body: {data}}) => data)
}

export function sendSourcesCsvEmail(feedIds) {
  return graphqlQuery(
    gql`
      mutation {
        sendSourcesCsvEmail(${args({feedIds})})
      }
    `,
  ).then(({body: {data}}) => data)
}


export function fetchAllParentLabels() {
  return graphqlQuery(
    gql`
    query {
      parentFirmSourceLabels(){
        id
        name
      }
    `,
  ).then(({body: {data}}) => data)
}

export function fetchManageLabels(params) {
  return  apiCall({
    url: '/profile/firm/firm_source_manage_labels',
    method: 'GET',
    data : {...params},
  })
}

export function reportFeeds(payload) {
  return graphqlQuery(
    gql`
      mutation {
        firmSourceReport(${args(
          {
            userId: payload.userId,
            firmId: payload.firmId,
            feeds: payload.feeds,
            feedIds: payload.feedIds,
            feedUrls:payload.feedUrls,
            reportedByUserId: payload.reportedByUserId,
            reportComment: payload.reportComment,
          })})
      }
    `,
  ).then(({body: {data}}) => data)
}
