import {graphqlQuery} from 'app/api'
import {args, gql} from 'app/graphql'
import {apiCall} from 'app/api'


const searchFields = () => {
  return (`
    id
    owner {
      id
      firstName
      lastName
      username
      role
      firmLocationId
      firmLocationName
      userMemberships {
        id
        user {
          id
          firstName
          lastName
          username
        }
        group {
          id
          firstName
          lastName
          username
        }
      }
    }
    childSearches {
      id
      name
      query
      copyOfId
      owner {
        id
        firstName
        lastName
        username
        role
        isActive
        userMemberships {
          id
          user {
            id
            firstName
            lastName
            username
          }
          group {
            id
            firstName
            lastName
            username
          }
        }
      }
    }
    parentSearches {
      id
      name
      isFirmLibrary
      isFirmLibraryChild
      scope
      owner {
        id
        firstName
        lastName
        username
      }
    }
    usedAsFilterBy {
      id
      name
      owner {
        id
        firstName
        lastName
        isActive
      }
    }
    name
    isFirmLibrary
    isFirmLibraryChild
    category
    scope
    notes
    query
    queryType
    hasFilters
    usedCount
    copyOfId
    createdAt
    updatedAt
    solrSearchField
    groupingLevel
    isFirmLibrary
    isFirmLibraryChild
    filterGroups {
      id
      category: categoryId
      special: specialType
      filterField
      isFirmSourceLabelCategory
      filters {
        id
        active
        queryTerm
        search {
          id
          name
          scope
          category
          isFirmLibrary
          isFirmLibraryChild
          owner {
            id
            firstName
            lastName
            username
          }
        }
        firmSourceLabel {
          id
          name
        }
      }
    }
    termFrequencyFilters {
      termArray
      frequency
      targetField
    }
    excludedFeeds {
      id
      name
    }
    featuredForUsers {
      id
      username
      firstName
      lastName
      role
    }
    featuredForDepartments {
      id
      name
    }
    featuredForTeams {
      id
      name
    }
    actionLog {
      id
      user {
        id
        username
      }
      originalUser {
        id
        username
      }
      createdAt
      objectType
      objectId
      action
      arguments
    }
  `)
}

export function fetchSearchesPaged({page, itemsPerPage, sortField, sortDirection, name, ownerId, label, scope, category, team, department, firmLocations, includeTrustedSources}) {
  return graphqlQuery(
    gql`
      query {
        searches: savedSearchesPaged(${args({page, itemsPerPage, sortField, sortDirection, name, label, ownerId, scope, category, team, department, firmLocations, includeTrustedSources})}) {
          items {
            id
            owner {
              id
              firstName
              lastName
              username
              role
              firmLocationName
              firmDepartmentName
              firmTeamName
            }
            name
            category
            scope
            createdAt
            updatedAt
            hasFilters
            usedCount
            copyOfId
            numberOfLinkedProfiles
            solrSearchField
            groupingLevel
            isFirmLibrary
            isFirmLibraryChild
            aboutSources {
              id
              name
            }
            childSearches {
              id
              name
              copyOfId
              owner {
                id
                firstName
                lastName
                username
                role
              }
            }
            shouldShowRelevanceFilter
            labels {
              id
              name
            }
          }
          totalCount
        }
      }
    `
  ).then(({body: {data}}) => data)
}


export function fetchAllSearchIds({name, ownerId, scope, category, team, department, firmLocations, includeTrustedSources, label}) {
  /**
   * including category here so we can disable `Add to` if any sources are selected.
   * name is required for entities.
   */
  return graphqlQuery(
    gql`
      query {
        searches: savedSearches(${args({name, ownerId, scope, category, team, department, firmLocations, isFromAdminSearches: true, includeTrustedSources, label})}) {
          id
          name
          category
        }
      }
    `
  ).then(({body: {data}}) => data)
}


export function fetchUserSearches(ownerId, firmLocations) {
  return graphqlQuery(
    gql`
      query {
        searches: savedSearches(${args({ownerId, firmLocations, isFromAdminSearches: true})}) {
          id
          name
          category
          owner {
            id
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}


export function fetchSearch(id) {
  return graphqlQuery(
    gql`
      query {
        search: savedSearch(${args({id})}) {
          ${searchFields}
        }
      }
    `
  ).then(({body: {data}}) => data)
}


export function fetchUsers({nameFilter, onlyUserManagedGroups} = {}) {
  return graphqlQuery(
    gql`
      query {
        users: usersAndGroups(${args({
          isActive: true, name: nameFilter, onlyUserManagedGroups: onlyUserManagedGroups
        })}) {
          id
          firstName
          lastName
          username
          role
        }
      }
    `
  ).then(({body: {data}}) => data)
}


export function updateSearch({
  id,
  userId,
  isMakeChild,
  isMakeCopy,
  name,
  isCascadeName,
  isFirmLibrary,
  scope,
  notes,
  category,
  queryType,
  searchPhrase,
  filters,
  noticeConfig,
  solrSearchField,
  groupingLevel,
  termFrequencyFilters,
}) {
  const params = {
    id,
    userId,
    isMakeChild,
    isMakeCopy,
    name,
    isCascadeName,
    isFirmLibrary,
    scope,
    notes,
    category,
    queryType,
    searchPhrase,
    filters,
    noticeConfig,
    solrSearchField,
    groupingLevel,
    termFrequencyFilters,
  }
  if (isMakeChild || isMakeCopy) {
    params.allowDuplicates = false
  }
  return graphqlQuery(
    gql`
      mutation {
        search: saveSearch(${args(params)}) {
          ${searchFields}
        }
      }
    `
  ).then(({body: {data}}) => data)
}


export function deleteSearches(searchIds) {
  const query = searchIds.map(searchId => {
    return (
      `mutation {
        deleteSearch(id: ${searchId}) {
          success
        }
      }`
    )
  }).join('')
  return graphqlQuery(
    query
  ).then(({body: {data}}) => data)
}


export function createSearch({
  searchId,
  userId,
  isMakeChild,
  isMakeCopy,
  name,
  isFirmLibrary,
  scope,
  category,
  queryType,
  searchPhrase,
  filters,
  noticeConfig,
  termFrequencyFilters,
  solrSearchField,
  groupingLevel,
}) {
  const params = {
    id: searchId,
    userId,
    isMakeChild,
    isMakeCopy,
    name,
    isFirmLibrary,
    scope,
    category,
    queryType,
    searchPhrase,
    filters,
    noticeConfig,
    termFrequencyFilters,
    solrSearchField,
    groupingLevel,
  }
  if (isMakeChild || isMakeCopy) {
    params.allowDuplicates = false
  }
  if (isMakeChild) {
    params.termFrequencyFilters = []
  }
  return graphqlQuery(
    gql`
      mutation {
        search: saveSearch(${args(params)}) {
          ${searchFields}
        }
      }
    `
  ).then(({body: {data}}) => data)
}


export function sendSearchesCsvEmail({searchIds}) {
  return graphqlQuery(
    gql`
      mutation {
        sendSearchesCsvEmail(${args({searchIds})}) {
          success
        }
      }
    `
  ).then(({body: {data}}) => data)
}


export function getSearchResults({id, query, queryType, filters, termFrequencyFilters}) {
  return graphqlQuery(
    gql`
      query {
        savedSearch(${args({id, query, queryType, filters, termFrequencyFilters})}) {
          results {
            documents {
              id
              link
              headline
              publishedAt
              snippet
              feed {
                id
                name
              }
              link
              groupCount
              groupDocs {
                id
                headline
                feed {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchCanShowRelevanceFilterIds(searchIds) {
  return graphqlQuery(
    gql`
      query {
        canShowRelevanceFilterIds(${args({searchIds})})
      }
    `
  ).then(({body: {data}}) => data)
}

export function updateSearchFilters(searchIds, filters, isAddOnly) {
  return graphqlQuery(
    searchIds.map(id =>
      gql`
        mutation {
          saveSearch(${args({id, filters, filtersAddOnly: isAddOnly})})
        }
      `
    )
  ).then(({body: {data}}) => data)
}

export function addFeaturedSearches({
  searchIds,
  userIds,
  groupIds,
  groupIdsForMembers,
  departmentIds,
  departmentIdsForMembers,
  teamIds,
  teamIdsForMembers,
  firmLocationIdsForMembers,
}) {
  return graphqlQuery(
    gql`
      mutation {
        addFeaturedSearches(${args({
          searchIds, userIds, groupIds, groupIdsForMembers, departmentIds, departmentIdsForMembers, teamIds,
          teamIdsForMembers, firmLocationIdsForMembers
        })})
      }
    `
  ).then(({body: {data}}) => data)
}

export function removeFeaturedSearches({searchId, fromItems}) {
  return graphqlQuery(
    fromItems.map(item =>
      gql`
        mutation {
          removeFeaturedSearch(${args({
            searchId,
            fromId: item.id,
            fromType: item.type,
          })})
        }
      `
    ),
  ).then(({body: {data}}) => data)
}

export function fetchSearchesParentLabels() {
  return graphqlQuery(
    gql`
    query {
      parentSavedSearchLabels(){
        id
        name
      }
    `,
  ).then(({body: {data}}) => data)
}

export function fetchSearchesManageLabels(params) {
  return  apiCall({
    url: 'savedsearch-managed-labels',
    method: 'GET',
    data : {...params},
  })
}

export function createSearchesLabel({name, parentId}) {
  return graphqlQuery(
    gql`
      mutation {
        createSavedSearchLabel(${args({
          name,
          parentId,
        })})
      }
    `,
  ).then(({body: {data}}) => data)
}

export function saveSearchesLabel({id, name, parentId}) {
  return graphqlQuery(
    gql`
      mutation {
        saveSavedSearchLabel(${args({
          id,
          name,
          parentId,
        })})
      }
    `,
  ).then(({body: {data}}) => data)
}

export function deleteSearchesLabel(id) {
  return graphqlQuery(
    gql`
      mutation {
        deleteSavedSearchLabel(${args({id})})
      }
    `,
  ).then(({body: {data}}) => data)
}


export function addSearchesLabels({searchIds, labelIds}) {
  return graphqlQuery(
    gql`
      mutation {
        addSavedSearchLabels(${args({searchIds, labelIds})})
      }
    `,
  ).then(({body: {data}}) => data)
}

export function removeSearchesLabels({searchIds, labelIds}) {
  return graphqlQuery(
    searchIds.map(searchId =>
      labelIds.map(
        labelId =>
          gql`
          mutation {
            removeSavedSearchLabel(${args({searchId, labelId})})
          }
        `,
      ),
    ),
  ).then(({body: {data}}) => data)
}

export function createCopiedSearch(params) {
  const query1 = params.map((param, index) => {
    return (
      gql`
        mutation {
          search${index}: saveSearch(${args({...param})}) {
            ${searchFields}
          }
        }
      `
    )
  }).join('')
  return graphqlQuery(query1).then(({ body: { data } }) => data)
}


