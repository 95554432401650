import {handleActions} from 'redux-actions'
import * as actions from './profile-sources-actions'
import {FILTER_FIELD_ALL} from './profile-sources-constants'
import {fetchNlaFeedIds} from "./profile-sources-actions";


const getInitialState = () => ({
  isInitializing: true,
  isLoading: false,
  firmFeedIds: [],
  globalFeedIds: [],
  promotedFeedIds: [],
  demotedFeedIds: [],
  limitedSeatAssignmentFeedIds: [],
  excludedFirmWideFeedIds: [],

  // trusted feeds/folders are stored as saved searches, so these are saved search ids.
  trustedFeedIds: [],
  trustedFolderIds: [],

  excludedFeedIds: [],
  featuredSourceFeedIds: [],
  filter: '',
  filterField: FILTER_FIELD_ALL,
  selectedFirmFeedIds: [],
  selectedGlobalFeedIds: [],
  addToFolderModalData: {
    isShown: false,
    isCreateFolderVisible: false,
    selectedFolderIds: [],
  },

  // this is for the inline add-folder options, as apposed to the add-to-folder modal one above.
  isCreateFolderShown: false,

  // this is used by both the add-folder-modal, the inline add-folder option, and the edit folder name option.
  newFolderName: '',

  limitedSeatModalFeedId: null,
  limitedSeatModalNotes: null,
  deleteFolderConfirmationModalSearchId: null,
  editFolderSearchId: null,
  loaderTrusted: false,
  selectedPromotedFeedIds: [],
  selectedExcludedFeedIds: [],
  selectedDemotedFeedIds: [],
  selectedTrustedFeedIds: [],
  selectedTrustedFolderIds: [],
  nlaLicenseExpired: false,
  nlaFeedIds: [],
})

export default handleActions({
  [actions.init]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.initComplete]: (state, action) => ({
    ...state,
    isInitializing: false,
  }),
  [actions.fetchFeaturedSources]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.fetchFeaturedSourcesComplete]: (state, action) => ({
    ...state,
    featuredSourceFeedIds: action.payload,
  }),
  [actions.fetchFeeds]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.fetchFeedsComplete]: (state, action) => ({
    ...state,
    firmFeedIds: action.payload.feedIds,
    excludedFirmWideFeedIds: action.payload.excludedFirmWideFeedIds,
  }),
  [actions.fetchFeedAssignments]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.fetchFeedAssignmentsComplete]: (state, action) => ({
    ...state,
    limitedSeatAssignmentFeedIds: action.payload,
  }),
  [actions.fetchPromotedFeeds]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.fetchPromotedFeedsComplete]: (state, action) => ({
    ...state,
    promotedFeedIds: action.payload,
    isLoading: false,
  }),
  [actions.fetchDemotedFeeds]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.fetchDemotedFeedsComplete]: (state, action) => ({
    ...state,
    demotedFeedIds: action.payload,
    isLoading: false,
  }),
  [actions.fetchTrustedFeeds]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.fetchTrustedFeedsComplete]: (state, action) => ({
    ...state,
    trustedFeedIds: action.payload,
    isLoading: false,
    loaderTrusted: false
  }),
  [actions.fetchTrustedFolders]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.fetchTrustedFoldersComplete]: (state, action) => ({
    ...state,
    trustedFolderIds: action.payload,
    isLoading: false,
  }),
  [actions.fetchExcludedFeeds]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.fetchExcludedFeedsComplete]: (state, action) => ({
    ...state,
    excludedFeedIds: action.payload,
    isLoading: false,
  }),
  [actions.fetchNlaLicense]: (state, action) => ({
    ...state,
    nlaLicenseExpired: action.payload,
    isLoading: false,
  }),
  [actions.fetchNlaFeedIds]: (state, action) => ({
    ...state,
    nlaFeedIds: action.payload,
    isLoading: false,
  }),
  [actions.setIsLoading]: (state, action) => ({
    ...state,
    isLoading: action.payload,
  }),
  [actions.setFilter]: (state, action) => ({
    ...state,
    filter: action.payload,
    selectedFirmFeedIds: [],
    selectedGlobalFeedIds: [],
  }),
  [actions.setFilterField]: (state, action) => ({
    ...state,
    filterField: action.payload,
    selectedFirmFeedIds: [],
    selectedGlobalFeedIds: [],
  }),
  [actions.setGlobalFeedIds]: (state, action) => ({
    ...state,
    isLoading: false,
    globalFeedIds: action.payload,
  }),
  [actions.setSelectedFirmFeedIds]: (state, action) => ({
    ...state,
    selectedFirmFeedIds: action.payload,
  }),
  [actions.setSelectedGlobalFeedIds]: (state, action) => ({
    ...state,
    selectedGlobalFeedIds: action.payload,
  }),
  [actions.promoteSelectedFeeds]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.promoteSelectedFeedsComplete]: (state, action) => ({
    ...state,
    selectedFirmFeedIds: [],
    selectedGlobalFeedIds: [],
  }),
  [actions.demoteSelectedFeeds]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.demoteSelectedFeedsComplete]: (state, action) => ({
    ...state,
    selectedFirmFeedIds: [],
    selectedGlobalFeedIds: [],
  }),
  [actions.trustSelectedFeeds]: (state, action) => ({
    ...state,
    isLoading: true,
    loaderTrusted: true,
  }),
  [actions.trustSelectedFeedsComplete]: (state, action) => ({
    ...state,
    selectedFirmFeedIds: [],
    selectedGlobalFeedIds: [],
  }),
  [actions.excludeSelectedFeeds]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.excludeSelectedFeedsComplete]: (state, action) => ({
    ...state,
    selectedFirmFeedIds: [],
    selectedGlobalFeedIds: [],
  }),
  [actions.showAddToFolderModal]: (state, action) => ({
    ...state,
    addToFolderModalData: {
      ...state.addToFolderModalData,
      isShown: true,
    },
  }),
  [actions.hideAddToFolderModal]: (state, action) => ({
    ...state,
    addToFolderModalData: getInitialState().addToFolderModalData,
  }),
  [actions.showModalCreateFolder]: (state, action) => ({
    ...state,
    addToFolderModalData: {
      ...state.addToFolderModalData,
      isCreateFolderVisible: true,
    },
  }),
  [actions.hideModalCreateFolder]: (state, action) => ({
    ...state,
    addToFolderModalData: {
      ...state.addToFolderModalData,
      isCreateFolderVisible: false,
    },
    newFolderName: '',
  }),
  [actions.setNewFolderName]: (state, action) => ({
    ...state,
    newFolderName: action.payload,
  }),
  [actions.setSelectedFolderIds]: (state, action) => ({
    ...state,
    addToFolderModalData: {
      ...state.addToFolderModalData,
      selectedFolderIds: action.payload,
    },
  }),
  [actions.createFolder]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.createFolderComplete]: (state, action) => ({
    ...state,
    isLoading: false,
    isCreateFolderShown: false,
    newFolderName: '',
    addToFolderModalData: {
      ...state.addToFolderModalData,
      isCreateFolderVisible: false,
    },
  }),
  [actions.addFeedsToFolders]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.addFeedsToFoldersComplete]: (state, action) => ({
    ...state,
    isLoading: false,
    addToFolderModalData: getInitialState().addToFolderModalData,
    selectedFirmFeedIds: [],
    selectedGlobalFeedIds: [],
    newFolderName: '',
  }),
  [actions.addFeedsToFolders]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.deletePromotedFeed]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.deletePromotedFeedComplete]: (state, action) => ({
    ...state,
    isLoading: false,
  }),
  [actions.deleteDemotedFeed]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.deleteDemotedFeedComplete]: (state, action) => ({
    ...state,
    isLoading: false,
  }),
  [actions.deleteTrustedFolder]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.deleteTrustedFolderComplete]: (state, action) => ({
    ...state,
    isLoading: false,
    deleteFolderConfirmationModalSearchId: null,
  }),
  [actions.deleteTrustedFeed]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.deleteTrustedFeedComplete]: (state, action) => ({
    ...state,
    isLoading: false,
  }),
  [actions.deleteExcludedFeed]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.deleteExcludedFeedComplete]: (state, action) => ({
    ...state,
    isLoading: false,
  }),
  [actions.setLimitedSeatModalFeedId]: (state, action) => ({
    ...state,
    limitedSeatModalFeedId: action.payload,
  }),
  [actions.setLimitedSeatModalNotes]: (state, action) => ({
    ...state,
    limitedSeatModalNotes: action.payload,
  }),
  [actions.submitLimitedSeatRequest]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.submitLimitedSeatRequestComplete]: (state, action) => ({
    ...state,
    isLoading: false,
    limitedSeatModalFeedId: null,
  }),
  [actions.showCreateFolder]: (state, action) => ({
    ...state,
    isCreateFolderShown: true,
    newFolderName: '',
  }),
  [actions.hideCreateFolder]: (state, action) => ({
    ...state,
    isCreateFolderShown: false,
  }),
  [actions.promoteFeed]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.demoteFeeds]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.trustFeed]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.excludeFeed]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.addFeedToFolder]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.setDeleteFolderConfirmationModalSearchId]: (state, action) => ({
    ...state,
    deleteFolderConfirmationModalSearchId: action.payload,
  }),
  [actions.setEditFolderSearchId]: (state, action) => ({
    ...state,
    editFolderSearchId: action.payload,
    newFolderName: '',
  }),
  [actions.saveFolderName]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.saveFolderNameComplete]: (state, action) => ({
    ...state,
    editFolderSearchId: null,
    newFolderName: '',
  }),
  [actions.moveFeed]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.setIsTrustedLoader]: (state, action) => ({
    ...state,
    isLoading: false,
    loaderTrusted: false,
  }),
  [actions.setSelectedPromotedFeedIds]: (state, action) => ({
    ...state,
    selectedPromotedFeedIds: action.payload,
  }),
  [actions.setSelectedExcludedFeedIds]: (state, action) => ({
    ...state,
    selectedExcludedFeedIds: action.payload,
  }),
  [actions.setSelectedDemotedFeedIds]: (state, action) => ({
    ...state,
    selectedDemotedFeedIds: action.payload,
  }),
  [actions.setSelectedTrustedFeedIds]: (state, action) => ({
    ...state,
    selectedTrustedFeedIds: action.payload,
  }),
  [actions.setSelectedTrustedFolderIds]: (state, action) => ({
    ...state,
    selectedTrustedFolderIds: action.payload,
  }),
  [actions.deleteTrustedFeedsAndFolders]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.deleteTrustedFeedsAndFoldersComplete]: (state, action) => ({
    ...state,
    isLoading: false,
    deleteFolderConfirmationModalSearchId: null,
  }),

}, getInitialState())
