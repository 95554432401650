import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, {useState} from 'react'
import styles from './ExportChart.less'
import chartStyles from './MzChart.less'
import InlineSvg from "app/common/InlineSvg"
import domtoimage from 'dom-to-image-more'
import saveFile from 'save-as-file'
import * as notifications from 'app/global/notifications'
import {useDispatch} from "react-redux"
import HelpIconTooltip from "app/common/HelpIconTooltip"
import TextBox from "app/common/TextBox"
import Button from "app/common/Button"
import * as actions from "app/common/charts/charts-actions"
import {CHART_TYPES} from "./constants"


export default function ExportChart({
  currentChart,
  chartType,
  textBoxWidth,
}) {
  const dispatch = useDispatch()
  const [showExportChartOptions, setShowExportChartOptions] = useState(false)
  const [showSaveChartOption, setShowSaveChartOption] = useState(false)
  const [saveAsName, setSaveAsName] = useState('')

  const saveChartAs = (e) => {
    e.preventDefault()
    const chartToDownload = currentChart.container
    domtoimage.toPng(chartToDownload, {
      bgcolor: '#ffffff', filter: (node) => node.className !== 'export-options-wrapper'
    }).then((dataUrl) => {
      dispatch(actions.saveChart({chartFile: dataUrl, chartName: `${saveAsName}.png`}))
      setShowSaveChartOption(false)
      setSaveAsName('')
    })
  }

  const exportChartOptions = showExportChartOptions && !showSaveChartOption
    ? <span
        className={classNames(styles.exportChartOptions, chartStyles.toolTipWrapper)}
      >
          <span className={styles.downloadType} onClick={() => exportChart('png')}>
            PNG
          </span>
          <span className={styles.downloadType} onClick={() => exportChart('jpeg')}>
            JPEG
          </span>
          <span className={styles.downloadType} onClick={() => exportChart('svg')}>
            SVG
          </span>
          <span className={classNames(styles.downloadType, styles.cancel)}>
            <InlineSvg
              onClick={() => setShowExportChartOptions(false)}
              className={styles.closeIcon}
              src="/media/img/close-icon.svg"
            />
          </span>
      </span>
    : !showSaveChartOption
      ? <span
          className={'export-chart-icon'}
          onClick={() => setShowExportChartOptions(true)}
        >
          <span>
            <i className="fa icon-off fa-download fa-lg"/>
            <HelpIconTooltip label="Download chart as PNG, JPEG, or SVG." />
          </span>
        </span>
      : null

  const saveChartOption = showSaveChartOption && !showExportChartOptions
    ? <form onSubmit={(e) => saveChartAs(e)}>
        <div
          className={classNames(styles.saveChartOption, chartStyles.toolTipWrapper)}
        >
            <div className={styles.saveChartAs}>
              <TextBox
                autoFocus={true}
                className={styles.saveAsTextBox}
                style={{width: textBoxWidth}}
                placeholder="Save as..."
                onChange={(e) => setSaveAsName(e.target.value)}
              />
            </div>
            <div>
              <Button
                className={styles.saveAsButton}
                label={'Save'}
                disabled={saveAsName.length < 1}
              />
            </div>
            <div className={classNames(styles.saveChartAs, styles.cancel)}>
              <InlineSvg
                onClick={() => setShowSaveChartOption(false)}
                className={styles.closeIcon}
                src="/media/img/close-icon.svg"
              />
            </div>
        </div>
      </form>
    : !showExportChartOptions
      ? <span
          className={styles.saveChartIcon}
          onClick={() => setShowSaveChartOption(true)}
        >
          <span>
            <i className="fa icon-off fa-plus fa-lg"/>
            <HelpIconTooltip label="Save chart for use in Publish." />
          </span>
        </span>
      : null

  const exportChart = (saveAsType) => {
    if (currentChart) {
      const dateString = new Date().toISOString().split('.')[0]
      const fileName = `${chartType}_chart_${dateString}`
      const chartToDownload = currentChart.container

      try {
        if (saveAsType === 'png') {
          domtoimage.toPng(chartToDownload, {
            bgcolor: '#ffffff', filter: (node) => node.className !== 'export-options-wrapper'
          }).then((dataUrl) => {saveFile(dataUrl, `${fileName}.${saveAsType}`)})
        }
        if (saveAsType === 'svg') {
          domtoimage.toSvg(chartToDownload, {
            filter: (node) => node.className !== 'export-options-wrapper'
          }).then((dataUrl) => {saveFile(dataUrl, `${fileName}.${saveAsType}`)})
        }
        if (saveAsType === 'jpeg') {
          domtoimage.toJpeg(chartToDownload, {
            bgcolor: '#ffffff', filter: (node) => node.className !== 'export-options-wrapper'
          }).then((dataUrl) => {saveFile(dataUrl, `${fileName}.${saveAsType}`)})
        }
      } catch(e) {
        dispatch(notifications.actions.showNotification({
          type: 'error',
          message: 'An error occurred trying to download your chart.'
        }))
      }
      setTimeout(() => {setShowExportChartOptions(false)}, 500)
    }
  }

  return (
    <React.Fragment>
      <li
        className={classNames('export-options-wrapper')}
      >
        <span className={styles.exportOptions}>
          { exportChartOptions }
          { saveChartOption }
        </span>
      </li>
    </React.Fragment>
  )
}
ExportChart.propTypes = {
  currentChart: PropTypes.object,
  chartType: PropTypes.string,
  textBoxWidth: PropTypes.string
}
ExportChart.defaultProps = {
  currentChart: {},
  chartType: CHART_TYPES.LINE,
  textBoxWidth: '16em',
}
