import React, { Component } from 'react'
import {titleCase} from 'title-case'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import SavedSearchInput from 'app/common/SavedSearchInput'
import Button from 'app/common/Button'
import {
  getAppName,
  getAppNameFull,
  getInsightsLabel,
  getCurrentFirmLibraryName,
  getInsightsEnabled,
} from 'app/global/global-selectors'

import {
  getCategory,
  getCategoryLabel,
  getCategoryLabelPlural,
  getIsFirmLibraryGroup,
  getIsProspectCategory,
  getBrowseModalData,
  getFeaturedSearchIdsByCategory,
  getIsLoading,
} from './profile-searches-selectors'
import {
  setIsProspectCategory,
  createSearches,
  setBrowseModalType,
} from './profile-searches-actions'
import {
  BROWSE_TYPE_GLOBAL,
  BROWSE_TYPE_FIRM_LIBRARY,
  BROWSE_TYPE_FEATURED,
} from './profile-searches-constants'
import BrowseModal from './BrowseModal'


@connect(
  createSelector(
    [
      getAppName,
      getAppNameFull,
      getInsightsLabel,
      getCurrentFirmLibraryName,
      getInsightsEnabled,
      getCategory,
      getCategoryLabel,
      getCategoryLabelPlural,
      getIsFirmLibraryGroup,
      getIsProspectCategory,
      getBrowseModalData,
      getFeaturedSearchIdsByCategory,
      getIsLoading,
    ],
    (
      appName,
      appNameFull,
      insightsLabel,
      currentFirmLibraryName,
      insightsEnabled,
      category,
      categoryLabel,
      categoryLabelPlural,
      isFirmLibraryGroup,
      isProspectCategory,
      browseModalData,
      featuredSearchIdsByCategory,
      isLoading,
    ) => {
      return {
        appName,
        appNameFull,
        insightsLabel,
        currentFirmLibraryName,
        insightsEnabled,
        isLoading,
        category,
        categoryLabel,
        categoryLabelPlural,
        isFirmLibraryGroup,
        isProspectCategory,
        browseModalData,
        featuredSearchIdsByCategory,
      }
    }
  ),
  {
    setIsProspectCategory,
    createSearches,
    setBrowseModalType,
  }
)
export default class AddSearches extends Component {
  state = {
    // Tokenization in the SavedSearchInput happens asynchronously, so
    // this keeps track of whether we're waiting on that to happen
    isTokenizing: false,
  }

  render() {
    const {category} = this.props
    let categoryLabelPlural = this.props.categoryLabelPlural
    let autocompleteCategories = [category]

    if (this.props.isProspectCategory) {
      categoryLabelPlural = 'Prospects'
    }

    if (['firm', 'client'].includes(category)) {
      autocompleteCategories = ['firm', 'client']
    } else if (category === 'tracker') {
      autocompleteCategories = ['tracker', 'firm', 'client', 'practice', 'industry', 'state', 'filter', 'agency']
    }

    const clientSection =
      category === 'client' &&
        <div className="search-category text">
          <label>
            <input
              type="radio"
              name="ss_category"
              value="client"
              defaultChecked={true}
              onChange={() => this.props.setIsProspectCategory(false)}
            />
            {this.props.categoryLabel}
          </label>
          <label>
            <input
              type="radio"
              name="ss_category"
              value="prospect"
              onChange={() => this.props.setIsProspectCategory(true)}
            />
            Prospect
          </label>
        </div>

    const browseManzamaButton =
      ['practice', 'industry'].includes(category) &&
      <Button
        label={`Browse ${this.props.appNameFull}`}
        onClick={() => this.props.setBrowseModalType(BROWSE_TYPE_GLOBAL)}
      />

    const browseFirmLibraryButton =
      !this.props.isFirmLibraryGroup &&
      <Button
        label={`Browse ${this.props.currentFirmLibraryName}`}
        onClick={() => this.props.setBrowseModalType(BROWSE_TYPE_FIRM_LIBRARY)}
      />

    const browseFeaturedButton = this.props.featuredSearchIdsByCategory.hasOwnProperty(category) &&
        this.props.featuredSearchIdsByCategory[category].length > 0
      ? <Button
          label={`Featured ${categoryLabelPlural}`}
          onClick={() => this.props.setBrowseModalType(BROWSE_TYPE_FEATURED)}
        />
      : null

    const browseButtonsConnector =
      <div className="browse-buttons-connector">OR</div>

    const sectionLabel =
      this.props.insightsEnabled && category === 'client' && !this.props.isProspectCategory
        ? categoryLabelPlural + ` to Base and ${titleCase(this.props.insightsLabel)}`
        : categoryLabelPlural

    const browseModal = !!this.props.browseModalData.type && <BrowseModal/>

    return (
      <React.Fragment>
        <h4 className="group-heading">Add {sectionLabel}</h4>

        <div
          id="add-search"
          className="field-group"
        >
          <div className="input-group">
            <SavedSearchInput
              onChange={this.inputChanged}
              options={{category: autocompleteCategories}}
              ref={ref => this.input = ref}
            />
          </div>

          <div className="button-group margin-t-s">
            {clientSection}

            <div className="buttons">
              <button
                id="add-new"
                type="submit"
                className="button primary"
                onClick={this.addButtonClicked}
              >
                Add
              </button>
              {browseButtonsConnector}
              <div>
                {browseFirmLibraryButton}
                {browseManzamaButton}
                {browseFeaturedButton}
              </div>
            </div>
          </div>
        </div>

        {browseModal}
      </React.Fragment>
    )
  }

  addButtonClicked = () => {
    // If the input still has untokenized free text, we first turn it
    // into a "free text" token and wait for the component to notify
    // us that the change has been made via inputChanged (since the
    // update is asynchronous). Otherwise, we add the searches
    // synchronously.
    if (this.input.hasUntokenizedText) {
      this.setState({isTokenizing: true})
      this.input.tokenizeFreeText()
    } else {
      this.createSearches()
    }
  }

  inputChanged = () => {
    if (this.state.isTokenizing) {
      this.setState({isTokenizing: false}, () => this.createSearches())
    }
  }

  createSearches() {
    if (!this.input.values.length) return

    const postParams = {
      category: this.props.category,
      searchIds: this.input.values.filter(v => !isNaN(v.value)).map(v => v.value),
      searchPhrases: this.input.values.filter(v => isNaN(v.value)).map(v => v.value),
    }
    // handle client/prospect which use the same page (category is 'client' for both).
    if (this.props.category === 'client' && this.props.isProspectCategory) {
      postParams.category = 'prospect'
    }
    this.props.createSearches(postParams)
    this.input.clear()
  }
}
