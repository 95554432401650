import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {getEntities} from 'app/entities/entities-selectors'
import Button from 'app/common/Button'
import Orm from 'app/framework/Orm'
import {User} from 'app/models'
import BulkUserAssignment from 'app/reusable/BulkUserAssignment'
import ShareSearchBase from 'app/reusable/ShareSearchBase'


import {
  addNewModalSetStep,
  setSaveData,
  fetchUsers,
} from '../searches-admin-actions'
import { getSearchesAdmin } from '../searches-admin-selectors'


@connect(
  // Map state to props
  createSelector(
    [getEntities, getSearchesAdmin],
    (entities, searchesAdmin) => {
      const orm = Orm.withEntities(entities)
      return {
        users: orm.getByIds(User, searchesAdmin.userIds),
        departments: searchesAdmin.departments,
        teams: searchesAdmin.teams,
        firmLocations: searchesAdmin.firmLocations,
        assigneeIdsBySection: searchesAdmin.saveData.assigneeIdsBySection,
        shareOnlyId: searchesAdmin.saveData.shareOnlyId,
        areAllUsersFetched: searchesAdmin.areAllUsersFetched,
        isLargeFirm: searchesAdmin.isLargeFirm,
        activeUserCount: searchesAdmin.activeUserCount,
        activeGroupCount: searchesAdmin.activeGroupCount,
      }
    }
  ),
  {
    setStep: addNewModalSetStep,
    setSaveData,
    fetchUsers,
  },
)
export default class ShareSearch extends ShareSearchBase {
  render() {
    const shareCount = this.shareWithAssigneeCount()
    const shareSummary =
      shareCount ?
        <p>{shareCount} Selected: {this.shareWithAssigneeNames()}</p>
        : null
    const step = this.props.shareOnlyId ? 2 : 3

    return (
      <div>
        <h2>Step {step}: Select Users to Add Search To</h2>
        {shareSummary}
        <BulkUserAssignment
          users={this.props.users}
          departments={this.props.departments}
          teams={this.props.teams}
          firmLocations={this.props.firmLocations}
          onFetchUsers={this.props.fetchUsers}
          onSectionChange={section => this.setSelectedSection(section)}
          onSelectedIdsChange={ids => this.setSelectedAssigneeIds(ids)}
          selectedIdsBySection={this.props.assigneeIdsBySection}
          showDepartmentMembers={true}
          showTeamMembers={true}
          showFirmLocations={true}
          isLargeFirm={this.props.isLargeFirm}
          activeUserCount={this.props.activeUserCount}
          activeGroupCount={this.props.activeGroupCount}
          areAllUsersFetched={this.props.areAllUsersFetched}
          showIndividualMembers={true}
        />

        <div className="new-search-buttons-container">
          <div className="new-search-buttons">
            <div className="buttons left">
              <Button
                label="Back to Previous Step"
                onClick={() => this.previousStep()}
              />
            </div>

            <div className="buttons right">
              <Button
                label="Next Step"
                onClick={() => this.nextStep()}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  previousStep() {
    const step = this.props.shareOnlyId ? 1 : 2
    this.props.setStep(step)
  }

  nextStep() {
    this.props.setStep(4)
  }

  setSelectedAssigneeIds(ids) {
    let assigneeIdsBySection = {...this.props.assigneeIdsBySection}
    assigneeIdsBySection[this.state.selectedSection] = ids
    this.props.setSaveData({assigneeIdsBySection})
  }
}
