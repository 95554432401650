import * as R from 'ramda'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {titleCase} from 'title-case'

import Button from 'app/common/Button'
import InputBlock from 'app/common/InputBlock'
import Message from 'app/common/Message'
import Table from 'app/common/Table'

import {
  selectCompanies,
  deselectCompanies,
  deselectAllCompanies,
  changeNameFilter,
  toggleCompaniesList,
  showConfirmation,
} from './dun-and-bradstreet-actions'
import { getDunAndBradstreet } from './dun-and-bradstreet-selectors'

const COLUMNS = {
  CHECKBOX: 'checkbox',
  NAME: 'name',
  CEO: 'ceo',
  LINE_OF_BUSINESS: 'lineOfBusiness',
  COUNTRY_NAME: 'countryName',
  ANNUAL_SALES: 'annualSalesUs',
  EMPLOYEES: 'employees',
}

const MAX_ALLOWED_FOR_ADD = 10


@connect(
  state => getDunAndBradstreet(state).content,
  {
    selectCompanies,
    deselectCompanies,
    deselectAllCompanies,
    changeNameFilter,
    toggleCompaniesList,
    showConfirmation,
  },
)
export default class DunAndBradstreetContent extends Component {
  render() {
    if (!this.props.data) {
      const loadingText = "Loading Dun & Bradstreet data for this search..."
      return (
        <div className="dnb-loading">
          <h1>Loading...</h1>
          <Message type={Message.TYPES.LOADING} text={loadingText} />
        </div>
      )
    }

    const companyName = this.props.data.companyName
    const ceoName = this.props.data.ceoName
    const lineOfBusiness = this.props.data.lineOfBusiness
    const countryName = this.props.data.countryName
    const annualSalesUs = this.props.data.annualSalesUs
    const employees = this.props.data.employees
    const association = this.props.data.association
    const allCompanies = this.props.data.mzFamily
    const companiesAvailableToRemove = R.filter(R.whereEq({includedInSearch: true}), allCompanies)
    const companiesAvailableToAdd = R.filter(R.whereEq({includedInSearch: false}), allCompanies)
    const {nameFilter, companiesToggle} = this.props
    const displayAddedCompanies = !companiesAvailableToRemove.length > 0
      ? false
      : companiesToggle
    const activeCompaniesList = displayAddedCompanies
      ? companiesAvailableToRemove
      : companiesAvailableToAdd

    const filteredCompanies = activeCompaniesList.filter(company => {
      if (
        nameFilter &&
        !company.companyName
          .toLowerCase()
          .includes(nameFilter.toLowerCase())
      ) {
        return false
      }
      return true
    })

    const addRemoveButton = displayAddedCompanies
    ? <div className="inline">
        <Button className="add-remove-button"
          label={this.props.isUpdating ? 'Removing...' : 'Remove from Search'}
          onClick={this.updateSearchSubmit.bind(this, 'remove')}
          disabled={!this.props.selectedEntityIds.length
            || this.props.isUpdating
          }
        />
      </div>
    : <div className="inline">
        <Button className="add-remove-button"
          label={this.props.isUpdating ? 'Adding...' : 'Add to Search'}
          onClick={this.updateSearchSubmit.bind(this, 'add')}
          disabled={!this.props.selectedEntityIds.length
            || this.props.isUpdating
          }
        />
      </div>

    const additional = displayAddedCompanies
      ? null
      : <span>&nbsp;Additional</span>

    const listDescription = displayAddedCompanies
      ? <span className="value">
          The {association[1]} you have added to your search are listed below.
        </span>
      : <span className="value">
          Select up to {MAX_ALLOWED_FOR_ADD} {association[1]} at one time to add to your main search to broaden your results.
        </span>

    const displayBackLink = !displayAddedCompanies && companiesAvailableToRemove.length > 0
      ? <div className="back-link-container">
          <a className="back-link" onClick={this.toggleCompanies.bind(this)}>&lt;&lt; Back</a>
        </div>
      : null

    const displayAddMoreButton = displayAddedCompanies && companiesAvailableToAdd.length > 0
      ? <div className="added-companies">
          <Button className='add-more-button' label={"View and Add Additional " + titleCase(association[1])} onClick={this.toggleCompanies.bind(this)}/>
        </div>
      : null

    const companiesHaveBeenSelected = this.props.selectedEntityIds.length > 0
    ? <div className="selected-count">{this.props.selectedEntityIds.length}
        {additional}&nbsp;
        {this.props.selectedEntityIds.length > 1
          ? titleCase(association[1])
          : titleCase(association[0])} Selected
      </div>
    : null

    const filterAdditionalCompanies = !displayAddedCompanies
      ? <div className="filter-by-container">
          <InputBlock label={"Filter by " + titleCase(association[0]) + " Name"}
                      className="filter-label">
            <input className="filter-input"
              type="text"
              onChange={this.createFilterChangeHandler('value')}
            />
          </InputBlock>
        </div>
      : <div className="filter-by-container"></div>

    const bulkSelect = filteredCompanies.length
    ? <div className="bulk-select-container">
        {addRemoveButton}
        {displayAddMoreButton}
      </div>
    : null

    return (
      <div className="dnb-data">
        <h1>{companyName}</h1>

        <div className="basic-info">
          <InputBlock label="CEO" className="input-label">
            <span className="value">{ceoName}</span>
          </InputBlock>

          <InputBlock label="Line of Business" className="input-label">
            <span className="value">{lineOfBusiness}</span>
          </InputBlock>

          <InputBlock label="Country" className="input-label">
            <span className="value">{countryName}</span>
          </InputBlock>

          <InputBlock label="Annual Sales U.S." className="input-label">
            <span className="value">{accounting.formatMoney(annualSalesUs)}</span>
          </InputBlock>

          <InputBlock label="Employees" className="input-label">
            <span className="value">{formatNumber(employees)}</span>
          </InputBlock>
        </div>

        <div className="available-companies">
          {displayBackLink}
          <InputBlock className="available-companies-label"
                      label={displayAddedCompanies ? "Added " + titleCase(association[1]) : "Additional " + titleCase(association[1])}
          >
            {listDescription}
          </InputBlock>
        </div>

        <div>
          {filterAdditionalCompanies}
          {companiesHaveBeenSelected}
          {bulkSelect}
        </div>

        <div className="dnb-table-container">
          <Table
            data={filteredCompanies}
            defaultSort={{column: COLUMNS.ANNUAL_SALES, direction: 'desc'}}
            getKeyForItem={R.prop('entityId')}
          >
            <Table.Column
              name={COLUMNS.CHECKBOX}
              baseWidth={40}
              growRatio={0}
              cellContents={company => {
                const {selectedEntityIds} = this.props
                const checked = selectedEntityIds.includes(company.entityId)
                return (
                  <input
                    type="checkbox"
                    className="select-company"
                    checked={checked}
                    disabled={(selectedEntityIds.length >= MAX_ALLOWED_FOR_ADD
                      && !checked
                      && !displayAddedCompanies)}
                    onChange={this.onSelectStateChange.bind(this, company.entityId)}
                  />
                )
              }}
            />

            <Table.Column
              name={COLUMNS.NAME}
              label={titleCase(association[0]) + " Name"}
              isSortable
              sortBy={R.prop('companyName')}
              baseWidth={120}
              growRatio={1}
              cellContents={R.prop('companyName')}
            />

            <Table.Column
              name={COLUMNS.CEO}
              label="CEO"
              isSortable
              sortBy={R.prop('ceoName')}
              baseWidth={120}
              growRatio={1}
              cellContents={R.prop('ceoName')}
            />

            <Table.Column
              name={COLUMNS.LINE_OF_BUSINESS}
              label="Line of Business"
              isSortable
              sortBy={R.prop('lineOfBusiness')}
              baseWidth={120}
              growRatio={1}
              cellContents={R.prop('lineOfBusiness')}
            />

            <Table.Column
              name={COLUMNS.COUNTRY_NAME}
              label="Country"
              isSortable
              sortBy={R.prop('countryName')}
              baseWidth={90}
              growRatio={1}
              cellContents={R.prop('countryName')}
            />

            <Table.Column
              name={COLUMNS.ANNUAL_SALES}
              label="Annual Sales U.S."
              isSortable
              sortBy={R.prop('annualSalesUs')}
              baseWidth={100}
              growRatio={1}
              cellContents={R.pipe(R.prop('annualSalesUs'),
                accounting.formatMoney)}
            />

            <Table.Column
              name={COLUMNS.EMPLOYEES}
              label="Employees"
              isSortable
              sortBy={R.prop('employees')}
              baseWidth={60}
              growRatio={1}
              cellContents={R.prop('employees')}
            />
          </Table>
        </div>

        <div className="footer">
          <span className='dnb-logo'></span>
        </div>
      </div>
    )
  }

  // State management

  onSelectStateChange(entity, event) {
    if (event.target.checked) {
      this.props.selectCompanies([entity])
    } else {
      this.props.deselectCompanies([entity])
    }
  }

  createFilterChangeHandler(eventAttrName) {
    return event => {
      this.props.changeNameFilter(event.target[eventAttrName])
    }
  }

  updateSearchSubmit(action) {
    const {selectedEntityIds, isUpdating} = this.props
    if (isUpdating || selectedEntityIds.length < 1) return
    const entities = selectedEntityIds.map(id =>
      this.props.data.mzFamily.find(R.whereEq({entityId: id}))
    )
    if (action === 'add') {
      this.props.onAddEntities(entities)
    }
    else {
      this.props.onRemoveEntities(entities)
    }
    this.props.showConfirmation()
  }

  toggleCompanies() {
    this.props.deselectAllCompanies()
    this.props.toggleCompaniesList()
  }

}

function formatNumber(number) {
  if (window.Intl && window.Intl.NumberFormat) {
    return (new Intl.NumberFormat).format(number)
  }
  // If the browser doesn't support the international number format API (IE11),
  // just show the number as-is.
  return number
}
