import {apiCall, graphqlQuery} from 'app/api'
import * as urls from 'app/urls'
import {args, gql} from "app/graphql"

function makeApiCall(url, method, data, callback) {
  return apiCall({
    url: url,
    method: method,
    data,
    callback,
    options: {dataFormat: 'json', useCsrfCookie: true},
  })
}

export function callSaveEmailDelivery(data, callback) {
  return makeApiCall(urls.emailNotifications.saveDelivery(),
    'POST', data, callback)
}

export function callAddEmailPrefixSuffix(data, callback) {
  return makeApiCall(urls.emailNotifications.addPrefixSuffix(),
    'POST', data, callback)
}

export function callApplyEmailDeliverySettings(data, callback) {
  return makeApiCall(urls.emailNotifications.applyEmailDeliverySettings(),
    'POST', data, callback)
}

export function callApplyCategoryOrdersSettings(data, callback) {
  return makeApiCall(urls.emailNotifications.applyCategoryOrdersSettings(),
    'POST', data, callback)
}

export function callSaveEmailAlertSettings(data, callback) {
  return makeApiCall(urls.emailNotifications.saveSettings(),
    'POST', data, callback)
}

export function callGetCCEmailsInsights(data) {
  return makeApiCall(urls.emailNotifications.getCCEmailsInsights(),
    'GET', data)
}

export function callGetCCEmailsEsg(data) {
  return makeApiCall(urls.emailNotifications.getCCEmailsEsg(),
    'GET', data)
}

export function callGetCCEmailsBase(data) {
  return makeApiCall(urls.emailNotifications.getCCEmailsBase(),
    'GET', data)
}

export function callUpdateCCEmails(data, callback) {
  return makeApiCall(urls.emailNotifications.updateCCEmails(),
    'POST', data, callback)
}

export function callGetInsightsNoticeConfigs(data) {
  return makeApiCall(urls.emailNotifications.getInsightsNoticeConfigs(),
    'GET', data)
}

export function callGetEsgNoticeConfigs(data) {
  return makeApiCall(urls.emailNotifications.getEsgNoticeConfigs(),
    'GET', data)
}

export function callGetBaseNoticeConfigs(data) {
  return makeApiCall(urls.emailNotifications.getBaseNoticeConfigs(),
    'GET', data)
}

export function callGetEmailAlertSettings(data) {
  return makeApiCall(urls.emailNotifications.getEmailAlertSettings(),
    'GET', data)
}

export function callSaveInsightsNoticeConfigs(data, callback) {
  return makeApiCall(urls.emailNotifications.saveInsightsNoticeConfigs(),
    'POST', data, callback)
}

export function callSaveEsgNoticeConfigs(data, callback) {
  return makeApiCall(urls.emailNotifications.saveEsgNoticeConfigs(),
    'POST', data, callback)
}

export function callSaveBaseNoticeConfigs(data, callback) {
  return makeApiCall(urls.emailNotifications.saveBaseNoticeConfigs(),
    'POST', data, callback)
}

export function callSendSampleEmail(data) {
  return makeApiCall(urls.emailNotifications.sendSampleEmail(),
    'POST', data)
}

export function callUpdateCategoryOrder(data, callback) {
  return makeApiCall(urls.emailNotifications.updateCategoryOrder(),
    'POST', data, callback)
}

export function callUpdateCategoryDefaults(data, callback) {
  return makeApiCall(urls.emailNotifications.updateCategoryDefaults(),
    'POST', data, callback)
}

export function callUpdateContentSettings(data, callback) {
  return makeApiCall(urls.emailNotifications.updateContentSettings(),
    'POST', data, callback)
}

export function callSaveNotificationSettings(data, callback) {
  return makeApiCall(urls.emailNotifications.saveNotificationSettings({editUserId: data.editUserId}),
    'POST', data, callback)
}
export function callGetNotificationSettings(data) {
  return makeApiCall(urls.emailNotifications.getNotificationSettings(),
    'GET', data)
}

export function fetchCategoryDefaults(category) {
  return graphqlQuery(
    gql`
      query {
        emailSettingsCategoryDefaults(${args({category})}) {
          noticeFreq
          noticeMaxStories
          twitterMaxItems
          eventMaxItems
          filingMaxItems
        }
      }
    `
  ).then(({body: {data}}) => data)
}
