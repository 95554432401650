import React, { Component } from 'react'
import { connect } from 'react-redux'

import Button from 'app/common/Button'

import { showAddNewModal } from './searches-admin-actions'


@connect(undefined, {showAddNewModal})
export default class AddNewSearch extends Component {
  render() {
    return (
      <div className="add-new-search">
        <Button
          label="Add Search"
          isPrimary
          onClick={this.showAddNewModal.bind(this)}
        />
      </div>
    )
  }

  showAddNewModal() {
    this.props.showAddNewModal()
  }
}
