import invariant from 'invariant'

import * as constants from './search-grouping-filter-constants'

export function groupingLevel(level) {
  const string = {
    [constants.FOCUS_LEVEL_LIMITED]: 'Least',
    [constants.FOCUS_LEVEL_LESS]: 'Less',
    [constants.FOCUS_LEVEL_STANDARD]: 'Standard',
    [constants.FOCUS_LEVEL_MORE]: 'More',
  }[level]
  invariant(
    string,
    `Invalid grouping level "${string}".`,
  )
  return string
}
