import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import InlineSvg from 'app/common/InlineSvg'

import './style.less'


export default class Modal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    showExit: PropTypes.bool,
    showDownload: PropTypes.bool,
    outsideExit: PropTypes.bool,
    onDownload: PropTypes.func,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    closeIconClassName: PropTypes.string,
  }

  static defaultProps = {
    isOpen: true,
    showExit: true,
    outsideExit: true,
    showDownload: false,
  }
  render() {
    if (!this.props.isOpen) return null

    const {children, id, className, showExit, showDownload, innerClassName, contentClassName, closeIconClassName} = this.props
    return (
      <div className="modal-component">
        <div
          className={classNames('modal-mask', className)}
          id={id}
          onClick={this.props.outsideExit ? this.overlayClicked : null}
        >
          <div className={classNames("modal-box", innerClassName)} ref={ref => this.overlayBox = ref}>
            <div className="modal-icons">
              {showDownload &&
                <div className="modal-download" onClick={this.modalDownload}>
                  <InlineSvg
                    className="download-icon"
                    src="/media/img/downloadicon.svg"
                  />
                </div>
              }
              {showExit &&
                <div className={classNames("modal-exit", closeIconClassName)} onClick={this.closeModal}>
                  <InlineSvg
                    className="close-icon"
                    src="/media/img/close-icon.svg"
                  />
                </div>
              }
            </div>
            <div className={classNames("modal-content", contentClassName)}>
              {children}
            </div>
          </div>
        </div>
      </div>
    )
  }

  // Event handlers

  overlayClicked = (event) => {
    // Only close the modal if we clicked outside of it
    if (!this.overlayBox.contains(event.target)) {
      this.closeModal()
    }
  }

  closeModal = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }
  modalDownload = () => {
    if (this.props.onDownload) {
      this.props.onDownload()
    }
  }
}