import immerProduce from 'immer'
import * as R from 'ramda'

import * as actions from 'app/actions'
import * as routing from 'app/global/routing'
import * as flagging from 'app/flagging'
import entitiesReducer from 'app/entities/entities-reducer'
import * as firmAdmin from 'app/firm-admin'
import * as searchResults from 'app/search-results'
import * as searchResultsPage from 'app/search-results-page'
import * as dandbSearch from 'app/one-off/dandb-search'
import * as emailNotifications from 'app/email-notifications'
import * as profile from 'app/profile'
import * as errorHandling from 'app/error-handling'
import * as advancedSearch from 'app/advanced-search'
import * as djangoInteractionBar from 'app/django-interaction-bar'
import * as dashboard from 'app/dashboard'
import * as comparisonPage from 'app/comparison-page'
import * as flaggedItems from 'app/flagged-items'
import * as mis from 'app/mis'
import * as profileBuilder from 'app/profile-builder'
import * as profileRefresh from 'app/profile-refresh'
import * as cglyticsTeaser from 'app/search-results/cglytics-teaser'
import * as notifications from 'app/global/notifications'
import * as helpQuestions from 'app/global/help-questions'
import * as firmStats from 'app/firm-stats'
import * as help from 'app/help'
import * as factorHealthBadge from 'app/reusable/health/health-badge/factor'
import * as subfactorHealthBadge from 'app/reusable/health/health-badge/subfactor'
import * as esg from 'app/esg'

let produce = immerProduce
if (!window.Proxy) {
  // Hack alert! IE appears to have issues with Immer, so for now, we're just
  // going to do a shallow copy on the entire state.
  // TODO: Figure out why IE is having issues even though the library docs say
  // it should work.
  function ieProduce(base, producer) {
    const newBase = {...base}
    producer(newBase)
    return newBase
  }
  produce = ieProduce
}


const getInitialState = () => ({
  // We want this here to avoid a specific undefined error. It should be removed
  // at some point.
  firmFeeds: [],
})


export default function rootReducer(state = getInitialState(), action) {
  return produce(
    state,
    newState => {
      const {payload} = action

      // Generically add normalized data returned from Django. This probably
      // isn't the most elegant solution to the problem, but it beats having
      // to manually add each type of entity every time you handle a
      // response.
      if (
        action.type === actions.addData.toString() ||
        action.type === actions.replaceData.toString() ||
        action.type.startsWith('INIT_')
      ) {
        Object.keys(payload.result).forEach(key => {
          const value = payload.result[key]
          const oldValue = newState[key]
          let newValue = value
          if (Array.isArray(value)) {
            if (action.type === actions.addData.toString())  {
              // Combine the array values, removing duplicates
              newValue = R.uniq(value.concat(oldValue || []))
            } else {
              newValue = value
            }
          }
          newState[key] = newValue
        })
      }

      // Saved searches

      if (action.type === actions.setAvailableFilters.toString()) {
        newState.filters = payload
      }

      else if (action.type === actions.setQuickFilterOptions.toString()) {
        newState.quickFilterOptions = payload
      }

      else if (action.type === actions.setFirmSourceLabels.toString()) {
        newState.firmSourceLabels = payload
      }

      // Profile searches

      else if (action.type === actions.setProfileSearchIds.toString()) {
        newState.profileSearches = action.payload
      }

      else if (action.type === actions.addProfileSearchIds.toString()) {
        newState.profileSearches = R.uniq([
          ...newState.profileSearches,
          ...action.payload,
        ])
      }

      else if (action.type === actions.removeProfileSearches.toString()) {
        const ids = action.payload.map(R.prop('id'))
        newState.profileSearches = newState.profileSearches.filter(
          id => !ids.includes(id)
        )
      }

      newState.entities = entitiesReducer(newState.entities, action)
      newState[routing.constants.STATE_PROP_NAME] = routing.reducer(
        newState[routing.constants.STATE_PROP_NAME],
        action,
      )
      newState[firmAdmin.constants.STATE_PROP_NAME] = firmAdmin.reducer(
        newState[firmAdmin.constants.STATE_PROP_NAME],
        action,
      )
      newState[searchResults.constants.STATE_PROP_NAME] = searchResults.reducer(
        newState[searchResults.constants.STATE_PROP_NAME],
        action,
      )
      newState[dandbSearch.constants.DNB_PROP_NAME] = dandbSearch.reducer(
        newState[dandbSearch.constants.DNB_PROP_NAME],
        action,
      )
      newState[emailNotifications.constants.STATE_PROP_NAME] = emailNotifications.reducer(
        newState[emailNotifications.constants.STATE_PROP_NAME],
        action,
      )
      newState[profile.constants.STATE_PROP_NAME] = profile.reducer(
        newState[profile.constants.STATE_PROP_NAME],
        action,
      )
      newState[flagging.constants.STATE_PROP_NAME] = flagging.reducer(
        newState[flagging.constants.STATE_PROP_NAME],
        action,
      )
      newState[errorHandling.constants.STATE_PROP_NAME] = errorHandling.reducer(
        newState[errorHandling.constants.STATE_PROP_NAME],
        action,
      )
      newState[advancedSearch.constants.STATE_PROP_NAME] = advancedSearch.reducer(
        newState[advancedSearch.constants.STATE_PROP_NAME],
        action,
      )
      newState[djangoInteractionBar.constants.STATE_PROP_NAME] = djangoInteractionBar.reducer(
        newState[djangoInteractionBar.constants.STATE_PROP_NAME],
        action,
      )
      newState[dashboard.constants.STATE_PROP_NAME] = dashboard.reducer(
        newState[dashboard.constants.STATE_PROP_NAME],
        action,
      )
      newState[comparisonPage.constants.STATE_PROP_NAME] = comparisonPage.reducer(
        newState[comparisonPage.constants.STATE_PROP_NAME],
        action,
      )
      newState[flaggedItems.constants.STATE_PROP_NAME] = flaggedItems.reducer(
        newState[flaggedItems.constants.STATE_PROP_NAME],
        action,
      )
      newState[mis.constants.STATE_PROP_NAME] = mis.reducer(
        newState[mis.constants.STATE_PROP_NAME],
        action,
      )
      newState[profileBuilder.constants.STATE_PROP_NAME] = profileBuilder.reducer(
        newState[profileBuilder.constants.STATE_PROP_NAME],
        action,
      )
      newState[profileRefresh.constants.STATE_PROP_NAME] = profileRefresh.reducer(
        newState[profileRefresh.constants.STATE_PROP_NAME],
        action,
      )
      newState[searchResultsPage.constants.STATE_PROP_NAME] = searchResultsPage.reducer(
        newState[searchResultsPage.constants.STATE_PROP_NAME],
        action,
      )
      newState[cglyticsTeaser.constants.STATE_PROP_NAME] = cglyticsTeaser.reducer(
        newState[cglyticsTeaser.constants.STATE_PROP_NAME],
        action,
      )
      newState[notifications.constants.STATE_PROP_NAME] = notifications.reducer(
        newState[notifications.constants.STATE_PROP_NAME],
        action,
      )
      newState[helpQuestions.constants.STATE_PROP_NAME] = helpQuestions.reducer(
        newState[helpQuestions.constants.STATE_PROP_NAME],
        action,
      )
      newState[firmStats.constants.STATE_PROP_NAME] = firmStats.reducer(
        newState[firmStats.constants.STATE_PROP_NAME],
        action,
      )
      newState[help.constants.STATE_PROP_NAME] = help.reducer(
        newState[help.constants.STATE_PROP_NAME],
        action,
      )
      newState[factorHealthBadge.constants.STATE_PROP_NAME] = factorHealthBadge.reducer(
        newState[factorHealthBadge.constants.STATE_PROP_NAME],
        action,
      )
      newState[subfactorHealthBadge.constants.STATE_PROP_NAME] = subfactorHealthBadge.reducer(
        newState[subfactorHealthBadge.constants.STATE_PROP_NAME],
        action,
      )
      newState[esg.constants.STATE_PROP_NAME] = esg.reducer(
        newState[esg.constants.STATE_PROP_NAME],
        action,
      )
    },
  )
}
