import classNames from 'classnames'
import invariant from 'invariant'
import PropTypes from 'prop-types'
import {pipe, prepend, reduceWhile, sortBy, take} from 'ramda'
import React, {useState} from 'react'
import {useSelector} from 'react-redux'

import Tooltip from 'app/common/Tooltip'
import * as entitiesSelectors from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import * as globalSelectors from 'app/global/global-selectors'
import {SavedSearch, User} from 'app/models'
import SearchTerm from 'app/reusable/SearchTerm'
import * as strings from 'app/strings'
import {insertBetweenItems} from 'app/utils'
import {coreTermsForSearch} from 'app/utils/searches'

import * as searchResultsStrings from '../search-results-page-strings'
import * as utils from '../search-results-page-utils'

import styles from './SearchQuery.less'
import {TERM_FREQUENCY_TARGET_FIELDS, TERM_FREQUENCY_TARGET_FIELD_LABELS} from
    "app/advanced-search/advanced-search-constants"

const MAX_TERMS_TO_SHOW = 5
const MAX_BOOLEAN_CHARS_TO_SHOW = 110


/**
 * Utility function to count the total number of filters in the given filter
 * groups.
 */
function countFiltersForFilterGroups(filterGroups) {
  return filterGroups.reduce(
    (count, filterGroup) => count + filterGroup.filters.length,
    0,
  )
}


function MoreTerms({count, onClick}) {
  return (
    <a onClick={onClick} className={styles.moreTerms}>
      {count} Other Search Term{count > 1 && 's'}
    </a>
  )
}


function SearchTermGroup(
  {
    terms,
    delimiter,
    labelAbbrev,
    labelFull,
    canWrapWithBrackets = true,
    termCountToShow = terms.length,
    onMoreTermsClick,
  },
) {
  termCountToShow = Math.min(termCountToShow, terms.length)
  const moreFiltersCount = terms.length - termCountToShow
  const termsToShow = moreFiltersCount ? take(termCountToShow, terms) : terms
  const termsUi = termsToShow
    .map((
      {
        label,
        value,
        isFreeText,
        isFirmLibrary,
        isFirmLibraryChild,
        isSpecial,
        isFeed,
        isFirmSourceLabel,
        scope,
        notes,
        ownerName,
      },
      index,
    ) =>
      <SearchTerm
        label={label}
        value={value}
        isFreeText={isFreeText}
        isRemovable={false}
        isFirmLibrary={isFirmLibrary}
        isFirmLibraryChild={isFirmLibraryChild}
        isSpecial={isSpecial}
        isFeed={isFeed}
        isFirmSourceLabel={isFirmSourceLabel}
        scope={scope}
        notes={notes}
        ownerName={ownerName}
        className={styles.term}
        key={`term-${index}`}
      />
    )
  if (moreFiltersCount) {
    termsUi.push(
      <MoreTerms
        count={moreFiltersCount}
        onClick={onMoreTermsClick}
        key="more-filters"
      />
    )
  }
  let termsUiWithDelimiters = insertBetweenItems(
    termsUi,
    insertIndex => (
      <span className={styles.delimiter} key={`delimiter-${insertIndex}`}>
        {delimiter}
      </span>
    ),
  )
  if (labelAbbrev) {
    const isAbbrev = labelAbbrev !== labelFull
    termsUiWithDelimiters = prepend(
      <Tooltip
        label={labelFull}
        direction="top-left"
        disabled={!isAbbrev}
        containerClassName={classNames(styles.groupLabel, {[styles.abbrev]: isAbbrev})}
        key="group-label"
      >
        <span>
          {labelAbbrev}:
        </span>
      </Tooltip>,
      termsUiWithDelimiters,
    )
  }
  const bracketed = canWrapWithBrackets && (labelAbbrev || terms.length > 1)
  return (
    <div
      className={classNames(styles.termGroup, {[styles.bracketed]: bracketed})}
    >
      {termsUiWithDelimiters}
    </div>
  )
}
SearchTermGroup.propTypes = {
  terms: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  delimiter: PropTypes.string.isRequired,
  labelAbbrev: PropTypes.string,
  labelFull: PropTypes.string,
  canWrapWithBrackets: PropTypes.bool,
  termCountToShow: PropTypes.number,
  onMoreTermsClick: PropTypes.func.isRequired,
}


function SearchTerms({search, isExpanded, expand}) {
  const currentFirm = useSelector(globalSelectors.getCurrentFirm)
  const entities = useSelector(entitiesSelectors.getEntities)
  const orm = Orm.withEntities(entities)
  const terms = coreTermsForSearch(search)
  const coreTerms = terms
    // Filter out any terms that aren't loaded into the ORM yet. There is a
    // possibility that the parent searches aren't yet loaded into the entity
    // store at any given moment, so we just hide them temporarily.
    .filter(({value, label, isFreeText}) =>
      isFreeText || label || orm.getById(SavedSearch, value)
    )
    .map(({value, label, isFreeText}) => {
      const ss = isFreeText ? null : orm.getById(SavedSearch, value)
      const ownerName = ss && ss.owner
        ? ss.owner.displayNameLastFirst
        : ''
      return {
        label:
          label
          || (isFreeText ? value : ss.name),
        value,
        isFreeText,
        isFirmLibrary: ss && ss.isFirmLibrary,
        isFirmLibraryChild: ss && ss.isFirmLibraryChild,
        isFeed: ss && ss.isFeed,
        scope: ss ? ss.scope : SavedSearch.SCOPES.PERSONAL,
        notes: ss && ss.notes,
        ownerName: ownerName,
      }
    })

  const {filterGroups} = search
  const totalFilterCount = countFiltersForFilterGroups(filterGroups)
  const coreTermCount = coreTerms.length
  const totalTermCount = totalFilterCount + coreTermCount
  const totalFilterCountToShow = isExpanded
    ? totalTermCount
    : MAX_TERMS_TO_SHOW - coreTermCount
  const filterGroupsToShow = pipe(
    // Sort shorter term groups first.
    sortBy(fg => fg.filters.length),
    // Reduce to only enough filter groups to show the maximum number of
    // filters.
    reduceWhile(
      (filterGroupsToShow, filterGroup) =>
        countFiltersForFilterGroups(filterGroupsToShow) < totalFilterCountToShow,
      (filterGroupsToShow, filterGroup) => [
        ...filterGroupsToShow,
        filterGroup,
      ],
      [],
    ),
  )(filterGroups)

  const filterTermCount = countFiltersForFilterGroups(filterGroupsToShow)
  const filterTermGroups = filterGroupsToShow
    .filter(item => !item.special)
    .map((filterGroup, index) => {
      const previousFilterGroups = filterGroupsToShow.slice(0, index)
      const previousFilterCount = countFiltersForFilterGroups(previousFilterGroups)
      const filterCountToShow = totalFilterCountToShow - previousFilterCount

      const { filterField } = filterGroup
      const delimiter = filterField.endsWith('_all') ? 'AND' : 'OR'
      const terms = filterGroup.filters.map(eachItem => {
        const value =
          eachItem.firmSourceLabel
            ? eachItem.firmSourceLabel.id
            : (eachItem.search
              ? eachItem.search.id
              : eachItem.displayName)
        const term = {
          label: eachItem.displayName,
          value,
          isFreeText: !eachItem.firmSourceLabel && !eachItem.search && !filterGroup.special,
          isSpecial: !!filterGroup.special,
          isFirmSourceLabel: !!eachItem.firmSourceLabel,
          scope: eachItem.search && eachItem.search.scope ? eachItem.search.scope : SavedSearch.SCOPES.PERSONAL,
          notes: eachItem.search && eachItem.search.notes,
          isFirmLibrary: eachItem.search && eachItem.search.isFirmLibrary,
          isFirmLibraryChild: eachItem.search && eachItem.search.isFirmLibraryChild,
        }
        if (eachItem.search && eachItem.search.owner) {
          // This is a bit of a hack, but the owner data may or may not
          // exist in the entity store, so we just create a model instance
          // on the fly so that we can access its display name.
          const owner = new User(eachItem.search.owner)
          term.ownerName = owner.displayNameLastFirst
        }
        return term
      })
      //removing duplicates from filter
      const termsUnique = []
      terms.forEach(item => {
        if (!termsUnique.some(ele => ele.label == item.label)) {
          termsUnique.push(item)
        }
      })
      return (
        <SearchTermGroup
          terms={termsUnique}
          delimiter={delimiter}
          labelAbbrev={searchResultsStrings.filterGroupFilterFieldLabelAbbrev(filterField)}
          labelFull={searchResultsStrings.filterGroupFilterFieldLabelFull(filterField)}
          termCountToShow={filterCountToShow}
          onMoreTermsClick={expand}
          key={index}
        />
      )
    })

  const SpecialTerms = filterGroupsToShow
    .filter(item => item.special)
    .map((filterGroup, index) => {
      const terms = filterGroup.filters.map(eachItem => {
        let label = `All My ${strings.searchCategory(filterGroup.special, { plural: true, currentFirm })}`
        const value =
          eachItem.firmSourceLabel
            ? eachItem.firmSourceLabel.id
            : (eachItem.search
              ? eachItem.search.id
              : eachItem.displayName)
        const term = {
          label,
          value,
          isFreeText: !eachItem.firmSourceLabel && !eachItem.search && !filterGroup.special,
          isSpecial: !!filterGroup.special,
          isFirmSourceLabel: !!eachItem.firmSourceLabel,
          scope: eachItem.search && eachItem.search.scope ? eachItem.search.scope : SavedSearch.SCOPES.PERSONAL,
          notes: eachItem.search && eachItem.search.notes,
          isFirmLibrary: eachItem.search && eachItem.search.isFirmLibrary,
          isFirmLibraryChild: eachItem.search && eachItem.search.isFirmLibraryChild,
        }
        if (eachItem.search && eachItem.search.owner) {
          // This is a bit of a hack, but the owner data may or may not
          // exist in the entity store, so we just create a model instance
          // on the fly so that we can access its display name.
          const owner = new User(eachItem.search.owner)
          term.ownerName = owner.displayNameLastFirst
        }
        return term
      })
      return terms[0]
    })

  const filterField = 'text_any'
  const delimiter = 'OR'

  const specialTermGroups = SpecialTerms && SpecialTerms.length > 0 ? [<SearchTermGroup
    terms={SpecialTerms}
    delimiter={delimiter}
    labelAbbrev={searchResultsStrings.filterGroupFilterFieldLabelAbbrev(filterField)}
    labelFull={searchResultsStrings.filterGroupFilterFieldLabelFull(filterField)}
    key={Math.random().toString()}
  />] : []

  let searchTermGroups = []
  if (coreTerms.length) {
    searchTermGroups.push(
      <SearchTermGroup
        terms={coreTerms}
        delimiter={search.queryType === 'all' ? 'AND' : 'OR'}
        canWrapWithBrackets={
          // If the delimiter is AND, then we don't need brackets around the
          // core terms since they will blend with everything else.
          search.queryType !== 'all'
          // We don't need brackets if there are no filters.
          && filterGroups.length > 0
        }
        termCountToShow={isExpanded ? undefined : MAX_TERMS_TO_SHOW}
        onMoreTermsClick={expand}
        key="core"
      />
    )
  }
  searchTermGroups = [
    ...searchTermGroups,
    ...filterTermGroups,
    ...specialTermGroups,
  ]

  if (!isExpanded && totalTermCount > MAX_TERMS_TO_SHOW) {
    const moreCount = totalTermCount - filterTermCount - coreTermCount
    if (moreCount > 0) {
      searchTermGroups.push(
        <MoreTerms
          count={moreCount}
          onClick={expand}
          key="more-terms"
        />
      )
    }
  }

  return insertBetweenItems(
    searchTermGroups,
    index => (
      <span className={styles.delimiter} key={`delimiter-${index}`}>AND</span>
    ),
  )
}
SearchTerms.propTypes = {
  search: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  expand: PropTypes.func.isRequired,
}


function SearchTermsLoading() {
  return (
    <div className={styles.loading}>
      <div className={styles.term} />
      <div className={styles.delimiter} />
      <div className={styles.term} />
      <div className={styles.delimiter} />
      <div className={styles.term} />
      <div className={styles.delimiter} />
      <div className={styles.term} />
    </div>
  )
}


function BooleanQuery({search}) {
  const [isExpanded, setIsExpanded] = useState(false)
  let {query} = search
  const {termFrequencyFilters} = search

  const termFrequencyTerms = (target) => {
    const targetFilter = termFrequencyFilters.filter(tff => tff.targetField === target)
    const targetTerms = targetFilter.length > 0
      ? targetFilter.map(tff => `[${tff.termArray.join(', ')}]: ${tff.frequency}`).join(', ')
      : null
    const targetText = targetTerms
      ? `${TERM_FREQUENCY_TARGET_FIELD_LABELS[target.toUpperCase()]}: ${targetTerms}`
      : null
    return(targetText)
  }

  let termFrequencyTargets = [
    termFrequencyTerms(TERM_FREQUENCY_TARGET_FIELDS.TEXT),
    termFrequencyTerms(TERM_FREQUENCY_TARGET_FIELDS.INTRO),
    termFrequencyTerms(TERM_FREQUENCY_TARGET_FIELDS.HEADLINE)
  ]
  termFrequencyTargets = termFrequencyTargets.filter(tft => tft != null)

  query = termFrequencyTargets.length > 0
    ? query + '\n' + 'Term Frequency Filters: ' + termFrequencyTargets.join(', ')
    : query

  const hasFilters = !!search.filterGroups.length
  const canExpand = query.length > MAX_BOOLEAN_CHARS_TO_SHOW
  if (!isExpanded && canExpand) {
    query = query.slice(0, MAX_BOOLEAN_CHARS_TO_SHOW) + ' ...'
  }

  query = query.split('\n').map((q, index) => {
    return<p key={index}>{q}</p>
  })

  return (
    <React.Fragment>
      <h4>Boolean Query</h4>
      <div className={styles.booleanQuery}>
        <div className={styles.queryText}>
          {query}
        </div>
      </div>
      {hasFilters && isExpanded && (
        <React.Fragment>
          <h4>Filters</h4>
          <div className={styles.termGroup}>
            <SearchTerms
              search={search}
              isExpanded={isExpanded}
              expand={() => setIsExpanded(true)}
            />
          </div>
        </React.Fragment>
      )}
      {(canExpand || hasFilters) && (
        isExpanded ? (
          <a onClick={() => setIsExpanded(false)} className={styles.collapse}>
            Show Less
          </a>
        ) : (
          <a onClick={() => setIsExpanded(true)} className={styles.expand}>
            Show More
          </a>
        )
      )}
    </React.Fragment>
  )
}
BooleanQuery.propTypes = {
  search: PropTypes.object.isRequired,
}


export default class SearchQuery extends React.PureComponent {
  static propTypes = {
    search: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
  }

  static defaultProps = {
    isLoading: false,
  }

  state = {
    isExpanded: false,
  }

  constructor(props) {
    invariant(
      props.search || props.isLoading,
      "The `search` prop may only be omitted if `isLoading` is true."
    )
    super(props)
  }

  render() {
    const {search} = this.props
    const queryType = this.props.isLoading ? 'any' : search.queryType
    return (
      <div className={classNames(styles.searchTerms, 'search-query-terms')}>
        {['raw', 'advanced'].includes(queryType) && !utils.isSource(search) ? (
          <BooleanQuery search={this.props.search} />
        ) : (
          <>
            <h4>Search Terms</h4>
            <div className={styles.termGroup}>
              {this.props.isLoading ? (
                <SearchTermsLoading />
              ) : (
                <SearchTerms
                  search={search}
                  isExpanded={this.state.isExpanded}
                  expand={this.expand.bind(this)}
                />
              )}
            </div>
          </>
        )}
        {this.state.isExpanded && (
          <a onClick={this.collapse.bind(this)} className={styles.collapse}>
            Collapse Search Terms
          </a>
        )}
      </div>
    )
  }

  expand() {
    this.setState({isExpanded: true})
  }
  collapse() {
    this.setState({isExpanded: false})
  }
}
