import PropTypes from 'prop-types'
import React from 'react'

import styles from './ComparisonDocumentList.less'
import ResultDocument from "app/reusable/ResultDocument"


function ComparisonDocumentList({
  search,
  topLevelDocuments,
  groupedDocuments,
  onFlaggingStateChange,
  onPromoteStateChange,
  onExclude,
}) {

  const documentComponents = (docs, groupedDocs) => {
    const documents = docs.map((doc, idx) =>
      <ResultDocument
        isComparisonDashboard={true}
        search={search}
        document={doc}
        groupDocuments={groupedDocs[doc.id] || []}
        onFlaggingStateChange={onFlaggingStateChange}
        onPromoteStateChange={onPromoteStateChange}
        onExclude={onExclude}
        selectedIds={[]}
        onSelectChange={() => void(0)}
        key={`document-${idx}-${doc.id}`}
      />
    )
    return(documents)
  }

  return (
    <div className={styles.documentList}>
      <div className={styles.documentListContainer}>
        { topLevelDocuments.length > 0
          ? documentComponents(topLevelDocuments, groupedDocuments)
          : null
        }
      </div>
    </div>
  )
}
ComparisonDocumentList.propTypes = {
  search: PropTypes.object.isRequired,
  topLevelDocuments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  groupedDocuments: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
  ).isRequired,
  onFlaggingStateChange: PropTypes.func.isRequired,
  onPromoteStateChange: PropTypes.func.isRequired,
  onExclude: PropTypes.func.isRequired,
}
export default ComparisonDocumentList
