import {handleActions} from 'redux-actions'

import * as actions from './profile-preferences-actions'


const getInitialState = () => ({
  isLoading: true,

  languages: [],
  languageLoadingIds: [],

  boosters: [],
  boosterLoadingIds: [],
  boosterShowMeModalId: null,

  // Whether the firm's default subscription content setting is set to Show
  // (true) or Hide (false).
  subscriptionContentFirmDefault: true,
  // `null` indicates that the user is using the firm default.
  includeSubscriptionContent: null,
  subscriptionContentLoading: false,

  timeFrame: null,
  timeFrameLoading: false,

  timeFrameMobile: null,
  timeFrameMobileLoading: false,

  searchResultsOrderBy: null,
  searchResultsOrderByLoading: false,

  relevanceFirmDefault: null,
  // `null` indicates that the user is using the firm default.
  relevance: null,
  relevanceLoading: false,
  relevanceApplyingToAll: false,

  groupingFirmDefault: null,
  // `null` indicates that the user is using the firm default.
  grouping: null,
  groupingLoading: false,
  groupingApplyingToAll: false,

  quickFilters: [],
  selectedQuickFilterIds: [],
  quickFilterLoadingIds: [],

  dashboardOrder: null,
  dashboardOrderLoading: false,

  resultsPerPage: 20,
  resultsPerPageLoading: false,

})

export default handleActions(
  {
    [actions.setIsLoading]: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
    [actions.setPreferences]: (state, action) => ({
      ...state,
      ...action.payload,
    }),

    //
    // Language Preferences
    //

    [actions.setLanguagePreference]: (state, {payload: {id, isPreferred}}) => ({
      ...state,
      languages: state.languages.map(language =>
        language.id === id ? {...language, isPreferred} : language
      ),
    }),
    [actions.setLanguageLoading]: (state, {payload: {id, isLoading}}) => ({
      ...state,
      languageLoadingIds: isLoading
        ? [...state.languageLoadingIds, id]
        : state.languageLoadingIds.filter(loadingId => loadingId !== id),
    }),

    //
    // Content Boosters
    //

    [actions.setBoosterState]: (state, {payload: {id, isApplied}}) => ({
      ...state,
      boosters: state.boosters.map(booster =>
        booster.id === id ? {...booster, isApplied} : booster
      ),
    }),
    [actions.setBoosterLoading]: (state, {payload: {id, isLoading}}) => ({
      ...state,
      boosterLoadingIds: isLoading
        ? [...state.boosterLoadingIds, id]
        : state.boosterLoadingIds.filter(loadingId => loadingId !== id),
    }),
    [actions.setBoosterShowMeModalId]: (state, {payload}) => ({
      ...state,
      boosterShowMeModalId: payload,
    }),

    //
    // Dashboard Search Order
    //

    [actions.setDashboardOrder]: (state, {payload}) => ({
      ...state,
      dashboardOrder: payload,
    }),
    [actions.setDashboardOrderLoading]: (state, {payload}) => ({
      ...state,
      dashboardOrderLoading: payload,
    }),

    //
    // Subscription Content
    //

    [actions.setSubscriptionContentSetting]: (state, {payload}) => ({
      ...state,
      includeSubscriptionContent: payload,
    }),
    [actions.setSubscriptionContentLoading]: (state, {payload}) => ({
      ...state,
      subscriptionContentLoading: payload,
    }),

    //
    // Default Time Frame
    //

    [actions.setTimeFrame]: (state, {payload}) => ({
      ...state,
      timeFrame: payload,
    }),
    [actions.setTimeFrameLoading]: (state, {payload}) => ({
      ...state,
      timeFrameLoading: payload,
    }),

    //
    // Default Mobile Time Frame
    //

    [actions.setTimeFrameMobile]: (state, {payload}) => ({
      ...state,
      timeFrameMobile: payload,
    }),
    [actions.setTimeFrameMobileLoading]: (state, {payload}) => ({
      ...state,
      timeFrameMobileLoading: payload,
    }),

    //
    // Default Search Results Order
    //

    [actions.setSearchResultsOrderBy]: (state, {payload}) => ({
      ...state,
      searchResultsOrderBy: payload,
    }),
    [actions.setSearchResultsOrderByLoading]: (state, {payload}) => ({
      ...state,
      searchResultsOrderByLoading: payload,
    }),

    //
    // Default Search Relevance Filter
    //

    [actions.setRelevance]: (state, {payload}) => ({
      ...state,
      relevance: payload,
    }),
    [actions.setRelevanceLoading]: (state, {payload}) => ({
      ...state,
      relevanceLoading: payload,
    }),
    [actions.setRelevanceApplyingToAll]: (state, {payload}) => ({
      ...state,
      relevanceApplyingToAll: payload,
    }),

    //
    // Default Search Results Grouping
    //

    [actions.setGrouping]: (state, {payload}) => ({
      ...state,
      grouping: payload,
    }),
    [actions.setGroupingLoading]: (state, {payload}) => ({
      ...state,
      groupingLoading: payload,
    }),
    [actions.setGroupingApplyingToAll]: (state, {payload}) => ({
      ...state,
      groupingApplyingToAll: payload,
    }),

    //
    // Quick Filters
    //

    [actions.setQuickFilterSelected]: (state, {payload: {id, isSelected}}) => ({
      ...state,
      selectedQuickFilterIds: isSelected
        ? [...state.selectedQuickFilterIds, id]
        : state.selectedQuickFilterIds.filter(selectedId => selectedId !== id),
    }),
    [actions.setQuickFilterLoading]: (state, {payload: {id, isLoading}}) => ({
      ...state,
      quickFilterLoadingIds: isLoading
        ? [...state.quickFilterLoadingIds, id]
        : state.quickFilterLoadingIds.filter(loadingId => loadingId !== id),
    }),

    //
    // Default Number Of Search Results 
    //

    [actions.setresultsPerPage]: (state, {payload}) => ({
      ...state,
      resultsPerPage: payload,
    }),
    [actions.setresultsPerPageLoading]: (state, {payload}) => ({
      ...state,
      resultsPerPageLoading: payload,
    }),
  },
  getInitialState(),
)
