import classNames from 'classnames'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {createSelector} from 'reselect'

import {SavedSearch} from 'app/models'
import Button from 'app/common/Button'
import Modal from 'app/common/Modal'
import InputBlock from 'app/common/InputBlock'
import TextBox from 'app/common/TextBox'
import {Tab, TabbedContainer} from 'app/common/TabbedContainer'
import CategoryDropdown from 'app/common/CategoryDropdown'
import SavedSearchFilters from 'app/reusable/SavedSearchFilters'
import AlertFrequencyDropdown from 'app/reusable/AlertFrequencyDropdown'
import AlertMaxItemsDropdown from 'app/reusable/AlertMaxItemsDropdown'
import ExcludedFeedsList from 'app/reusable/ExcludedFeedsList'
import {fetchEmailAlertCategoryDefaults} from 'app/email-notifications/email-notifications-actions'
import * as constants from 'app/search-results-page/search-results-page-constants'
import {
  getCurrentFirmIsPracticesEnabled,
  getCurrentFirmLibraryName,
  getCurrentUserIsFirmLibraryGroup,
} from 'app/global/global-selectors'
import {pendingTermFrequencyFilters} from 'app/advanced-search/advanced-search-selectors'

import './EditSearchModal.less'

export const TABS = {
  FILTERS: 'filters',
  EXCLUDED_FEEDS: 'excluded-feeds',
  SETTINGS: 'settings',
}


@connect(
  createSelector(
    [
      getCurrentFirmIsPracticesEnabled,
      getCurrentFirmLibraryName,
      getCurrentUserIsFirmLibraryGroup,
      pendingTermFrequencyFilters,
    ],
    (
      currentFirmIsPracticesEnabled,
      currentFirmLibraryName,
      currentUserIsFirmLibraryGroup,
      pendingTermFrequencyFilters,
    ) => {
      return {
        currentFirmIsPracticesEnabled,
        currentFirmLibraryName,
        currentUserIsFirmLibraryGroup,
        pendingTermFrequencyFilters,
      }
    }
  ),
  {
    fetchEmailAlertCategoryDefaults: fetchEmailAlertCategoryDefaults,
  },
)
export default class EditSearchModal extends Component {
  static propTypes = {
    defaultTab: PropTypes.string.isRequired,
    savedSearch: PropTypes.object.isRequired,
    saveData: PropTypes.object.isRequired,
    queryComponentState: PropTypes.object.isRequired,

    searchResults: PropTypes.object,
    isLoading: PropTypes.bool,
    isTier3: PropTypes.bool,

    hide: PropTypes.func.isRequired,
    setQueryComponentState: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onSaveAs: PropTypes.func,
    setSaveData: PropTypes.func.isRequired,
    onUpdateSearchResults: PropTypes.func.isRequired,
    onRemoveExcludedFeeds: PropTypes.func.isRequired,
  }

  static defaultProps = {
    searchResults: {},
    isLoading: false,
    isTier3: false,
  }

  state = {
    isChanged: false,
    isSaveAsPromptShowing: false,
    isSaveAs: false,
    saveAsName: null,
    saveAsIsPrivate: true,
    currentTab: this.props.defaultTab,
    excludedFeedIdToBeRemoved: null,
  }

  render() {
    const {CATEGORIES} = SavedSearch

    const omittedCategories = [CATEGORIES.TRUSTED, CATEGORIES.TRUSTED_UNCATEGORIZED]

    if (!this.props.isPracticesEnabled) {
      omittedCategories.push(CATEGORIES.PRACTICE)
    }

    let name = this.props.saveData.name !== undefined ? this.props.saveData.name : this.props.savedSearch.name
    if (name === null) {
      const params = new URLSearchParams(window.location.search)
      name = params.get('query')
    }

    let category = this.props.saveData.category || this.props.savedSearch.category
    // trusted categories are not supported here; default to topic.
    if ([CATEGORIES.TRUSTED_UNCATEGORIZED, CATEGORIES.TRUSTED].includes(category)) {
      category = 'tracker'
    }

    let alertFrequency = (
      this.props.saveData.noticeConfig.frequency ||
      this.props.savedSearch.noticeConfig.frequency
    )
    if (alertFrequency === 'priority_trigger') {
      // real-time alerts are no longer supported
      alertFrequency = 'none'
    }

    const alertMaxItems = type =>
      this.props.saveData.noticeConfig.maxItems.hasOwnProperty(type)
        ? this.props.saveData.noticeConfig.maxItems[type]
        : this.props.savedSearch.noticeConfig.maxItems.hasOwnProperty(type)
          ? this.props.savedSearch.noticeConfig.maxItems[type]
          : 0

    const alertPubTypeMaxItems = type => {
      const maxItems = alertMaxItems(type)
      return(
        maxItems < 500
          ? maxItems
          : maxItems > 0
            ? 40
            : 0
      )
    }

    const scope = this.props.saveData.scope
      ? this.props.saveData.scope
      : this.props.savedSearch.scope

    /**
     * when searching on tier3 for a shared (incl. FL) search, you get a, personal, unsaved, child search to work with.
     * so, a parent FL search can be identified here by isFirmLibraryChild and being unsaved.
     * unless you're logged in as a FL user; then simply isFirmLibrary will work.
     */
    const firmLibraryIndicator =
      (this.props.savedSearch.isFirmLibrary || (this.props.savedSearch.isFirmLibraryChild && !this.props.savedSearch.isSaved)) &&
      <div className="tier3-indicator firm-library-search results-tooltip" data-tooltip-type="firm-library">
        <span className="icon" />
        <span className="label">{this.props.currentFirmLibraryName} Search</span>
      </div>

    const saveButtons =
      !this.props.isLoading && this.state.currentTab !== TABS.EXCLUDED_FEEDS &&
      <div className="buttons-row save-buttons-row">
        <Button
          type="button" // do not remove; prevents bubble up to form submit
          label="Save as New Search"
          className={classNames(
            'button-right',
            'edit-modal-save-as-button', // for Pendo
          )}
          onClick={() => this.saveAs()}
        />
        {
          /**
           * on tier3 if there's no search id, we are on a new unsaved search.
           * when that is the case, we only show the 'save as' button.
           * also `trusted` and `trusted-uncategorized` cannot be edited; only show 'save as'.
           */
          this.props.savedSearch.isSaved && !['trusted', 'trusted-uncategorized'].includes(this.props.savedSearch.category) &&
          <Button
            type="button" // do not remove; prevents bubble up to form submit
            isPrimary={true}
            disabled={!this.isSaveEnabled()}
            label="Save"
            className={classNames(
              'button-right',
              'edit-modal-save-button', // for Pendo
            )}
            onClick={() => this.save(false)}
          />
        }
      </div>

    const emailOptions =
      !this.props.currentUserIsFirmLibraryGroup &&
      <div>
        <div>
          <InputBlock label="Alert Frequency">
            <AlertFrequencyDropdown
              value={alertFrequency}
              onChange={(value) => this.alertFrequencyChangeHandler(value)}
            />
          </InputBlock>
          <p>The timing of which you would like this search to appear in your Base Email Alert.</p>
        </div>

        <div>
          <InputBlock label="Articles">
            <AlertMaxItemsDropdown
              value={alertPubTypeMaxItems('defaultPubTypes')}
              onChange={(value) => this.alertMaxItemsChangeHandler('defaultPubTypes', value)}
            />
          </InputBlock>

          <InputBlock label="Filings">
            <AlertMaxItemsDropdown
              value={alertPubTypeMaxItems('allFilings')}
              onChange={(value) => this.alertMaxItemsChangeHandler('allFilings', value)}
            />
          </InputBlock>

          <InputBlock label="Tweets">
            <AlertMaxItemsDropdown
              value={alertPubTypeMaxItems('twitter')}
              onChange={(value) => this.alertMaxItemsChangeHandler('twitter', value)}
            />
          </InputBlock>

          <InputBlock label="Events">
            <AlertMaxItemsDropdown
              value={alertPubTypeMaxItems('event')}
              onChange={(value) => this.alertMaxItemsChangeHandler('event', value)}
            />
          </InputBlock>

          <p>Maximum number of items to appear in your Base Email Alert for this search.</p>
        </div>
      </div>

    const privateOption =
      this.props.currentUserIsFirmLibraryGroup &&
      <div className="checkbox-input-block">
        <InputBlock isInline label="Private Search">
          <input
            type="checkbox"
            checked={scope === SavedSearch.SCOPES.PERSONAL}
            onChange={(evt) => this.privateCheckboxHandler(evt)}
          />
        </InputBlock>
      </div>

    /**
     * hack alert: the filters tab always needs to be rendered because it has logic that sets saveData in redux
     * which is needed even if the filters tab is never accessed. hence `renderAll`.
     */
    const content =
      <div>
        <TabbedContainer
          defaultTabName={this.props.defaultTab}
          onTabChange={(tabName) => this.setState({currentTab: tabName})}
          renderAll
          useUpdatedCss
        >
          <Tab
            label="Search Filters"
            name={TABS.FILTERS}
            className="edit-modal-search-filters-tab" // for Pendo
          >
            <SavedSearchFilters
              currentFirmLibraryName={this.props.currentFirmLibraryName}
              search={this.props.savedSearch}
              onUpdateSearchResults={this.props.onUpdateSearchResults}
              onSave={this.props.onSave}
              searchResults={this.props.searchResults}
              isLoading={this.props.isLoading}
              includeSaveButton={false}
              saveData={this.props.saveData}
              setSaveData={this.props.setSaveData}
              onChange={() => this.filtersChangeHandler(null)}
              queryComponentState={this.props.queryComponentState}
              setQueryComponentState={this.props.setQueryComponentState}
              isTier3={this.props.isTier3}
            />
          </Tab>

          <Tab
            label="Excluded Sources"
            name={TABS.EXCLUDED_FEEDS}
            className="edit-modal-search-excluded-feeds-tab" // for Pendo
          >
            <ExcludedFeedsList
              search={this.props.savedSearch}
              onDelete={this.props.onRemoveExcludedFeeds}
            />
          </Tab>

          {
            !this.props.isTier3 &&
            <Tab
              label="Alert Settings"
              name={TABS.SETTINGS}
            >
              <div>
                <div>
                  <InputBlock label="Name">
                    <TextBox
                      value={name}
                      onChange={(evt) => this.nameChangeHandler(evt)}
                    />
                  </InputBlock>
                  {firmLibraryIndicator}
                </div>
                <p>Enter a name for the Search you have built.</p>
              </div>

              <div>
                <InputBlock label="Category">
                  <CategoryDropdown
                    value={category}
                    onChange={(value) => this.categoryChangeHandler(value)}
                    omittedCategories={omittedCategories}
                  />
                </InputBlock>
                <p>The category in which you would like your search to appear. This will be reflected only in your user
                  profile.</p>
              </div>

              {emailOptions}
              {privateOption}
            </Tab>
          }
        </TabbedContainer>

        {saveButtons}
        <br className="clear-both"/>
      </div>

    const saveAsPrompt =
      this.state.isSaveAsPromptShowing &&
      <div className="overlay">
        <div className="overlay-content">
          <InputBlock label="New Name for the Search">
            <TextBox
              placeholder="Enter Search Name..."
              value={this.state.saveAsName === null? name : this.state.saveAsName}
              onChange={(evt) => this.saveAsNameChangeHandler(evt)}
            />
          </InputBlock>

          {privateOption}

          <Button
            label="Save"
            disabled={!this.state.saveAsName}
            onClick={() => this.save(true)}
          />

          <Button
            label="Cancel"
            isPlainText={true}
            onClick={() => this.hideSaveAsPrompt()}
          />
        </div>
      </div>

    return (
      <div id="edit-search-modal">
        <Modal
          isOpen={true}
          onClose={() => this.props.hide()}
          outsideExit={false}
        >
          {content}
          {saveAsPrompt}
        </Modal>
      </div>
    )
  }

  isSaveEnabled() {
    const params = new URLSearchParams(window.location.search)
    const duration = params.get('time-frame')
    const resultsOrder = params.get('sort')
    const selectedLanguageIds = this.props.saveData.selectedLanguageIds

    const relevanceChange = this.props.saveData.solrSearchField
      ? this.props.saveData.solrSearchField !== this.props.savedSearch.solrSearchField
      : null
    const groupingChange = this.props.saveData.groupingLevel
      ? this.props.saveData.groupingLevel !== this.props.savedSearch.groupingLevel
      : null
    const durationChange = duration
      ? duration !== this.props.savedSearch.duration && constants.TIME_FRAMES.includes(duration)
      : null
    const resultsOrderChange = resultsOrder
      ? resultsOrder !== constants.REVERSE_SORT_OPTIONS[this.props.savedSearch.resultsOrder]
      : null
    const languageChange = selectedLanguageIds
      ? selectedLanguageIds !== this.props.savedSearch.languageFilters
          .filter(lang => lang.exclude === false).map(lang => lang.id).join(',')
      : null
    const name = this.props.saveData.name !== undefined
      ? this.props.saveData.name
      : this.props.savedSearch.name
    const termFrequencyFiltersChange = this.props.pendingTermFrequencyFilters &&
      this.props.savedSearch.termFrequencyFilters
      ? JSON.stringify(this.props.savedSearch.termFrequencyFilters) !==
        JSON.stringify(this.props.pendingTermFrequencyFilters)
      : null

    return name && (this.state.isChanged || params.get('query')|| params.get('filter-key') ||
      relevanceChange || groupingChange || durationChange || resultsOrderChange ||
      termFrequencyFiltersChange || languageChange)
  }

  filtersChangeHandler() {
    this.setState({isChanged: true})
  }

  nameChangeHandler(evt) {
    const name = evt.target.value
    this.props.setSaveData({name: name})
    this.setState({isChanged: true})
  }

  categoryChangeHandler(value) {
    if (!this.state.isFrequencyOrMaxItemsChanged){
      this.props.fetchEmailAlertCategoryDefaults(value)
    }
    this.props.setSaveData({category: value})
    this.setState({isChanged: true})
  }

  alertFrequencyChangeHandler(value) {
    const maxItems = Object.keys(this.props.saveData.noticeConfig.maxItems).length > 0
        ? this.props.saveData.noticeConfig.maxItems
        : this.props.savedSearch.noticeConfig.maxItems
    const noticeConfig = {
      frequency: value,
      maxItems,
    }
    this.props.setSaveData({noticeConfig})
    this.setState({isChanged: true, isFrequencyOrMaxItemsChanged: true})
  }

  alertMaxItemsChangeHandler(type, value) {
    const frequency = this.props.saveData.noticeConfig.frequency ||
      this.props.savedSearch.noticeConfig.frequency
    const noticeConfig = this.props.saveData.noticeConfig
    noticeConfig.frequency = frequency
    noticeConfig.maxItems[type] = value
    this.props.setSaveData({noticeConfig})
    this.setState({isChanged: true, isFrequencyOrMaxItemsChanged: true})
  }

  privateCheckboxHandler(evt) {
    const scope = evt.target.checked ? SavedSearch.SCOPES.PERSONAL : SavedSearch.SCOPES.SHARED
    this.props.setSaveData({scope: scope})
    this.setState({isChanged: true})
  }

  saveAs() {
    if (this.props.onSaveAs) {
      this.props.onSaveAs()
      return
    }
    if (this.props.savedSearch.id) {
      /**
       * always prompt for name when doing a save-as on an existing search
       */
      this.setState({isSaveAsPromptShowing: true, saveAsName: this.props.savedSearch.name})
    } else {
      /**
       * if the user has not already set the name and we're not on the settings tab (where the name input is),
       * show the name prompt.
       */
      const showPrompt = !this.props.saveData.name && this.state.currentTab !== 'settings'
      let name = this.props.saveData.name || this.props.savedSearch.name
      if (!name) {
        /**
         * we have no name; try to make one.
         */
        const params = new URLSearchParams(window.location.search)
        name = params.get('query')
      }
      if (!name || showPrompt) {
        this.setState({isSaveAsPromptShowing: true, saveAsName: name})
      } else {
        this.setState({saveAsName: name}, () => {
          this.save(true)
        })
      }
    }
  }

  saveAsNameChangeHandler(evt) {
    const name = evt.target.value
    this.setState({saveAsName: name})
  }

  hideSaveAsPrompt() {
    this.setState({isSaveAsPromptShowing: false})
  }

  save(saveAs) {
    const data = {...this.props.saveData}
    if (saveAs) {
      data.name = this.state.saveAsName
      if (data.searchId === -1) {
        delete data.searchId
        if (
          this.props.savedSearch.category === SavedSearch.CATEGORIES.TRUSTED_UNCATEGORIZED
          && this.props.savedSearch.aboutFeeds
          && this.props.savedSearch.aboutFeeds.length === 1
        ) {
          data.feedId = this.props.savedSearch.aboutFeedsIds[0]
        }
      }
      else {
        if (this.props.savedSearch.isSource) {
          // If this is a source or source folder, then we need to reference the
          // original in order to keep the source in the core terms, so we need
          // to make this a child instead of a copy.
          data.isMakeChild = true
        }
        else {
          data.isMakeCopy = true
        }
      }
    } else if (!data.name) {
      data.name = this.props.savedSearch.name
    }
    // We never want to save a search with a category that's not
    // user-selectable. In these cases, let the backend use the default.
    if (
      data.category
      && !Object.values(SavedSearch.USER_SELECTABLE_CATEGORIES).includes(data.category)
    ) {
      delete data.category
    }
    data.queryType = this.props.savedSearch.queryType
    // We never want to save a search as "advanced". If we run into this, it's
    // likely because we're saving a new search based on a feed or source, and
    // in those cases, we want to override it with the default.
    if (data.queryType === 'advanced') {
      data.queryType = 'any'
    }
    const params = new URLSearchParams(window.location.search)
    const duration = params.get('time-frame')
    if (duration && constants.TIME_FRAMES.includes(duration)){
      data.duration = duration
    }
    const resultsOrder = params.get('sort')
    if (resultsOrder){
      data.resultsOrder = constants.SERVER_SORT_OPTIONS[resultsOrder]
    }
    data.termFrequencyFilters = this.props.pendingTermFrequencyFilters

    this.props.onSave(data)
    this.hideSaveAsPrompt();
  }
}
