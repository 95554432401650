import PropTypes from 'prop-types'
import React from 'react'

import styles from './EsgDocumentList.less'
import ResultDocument from "app/reusable/ResultDocument"


function EsgDocumentList({
  search,
  topLevelDocuments,
  onFlaggingStateChange,
  onPromoteStateChange,
  onExclude,
  maxDocs = 5
}) {

  const MAX_DOCS = maxDocs
  const documentComponents = (docs) => {
    const documents = docs.slice(0, MAX_DOCS).map((doc, idx) =>
      <ResultDocument
        isDashboard={true}
        search={search}
        document={doc}
        onFlaggingStateChange={onFlaggingStateChange}
        onPromoteStateChange={onPromoteStateChange}
        onExclude={onExclude}
        key={`document-${idx}-${doc.id}`}
      />
    )
    return(documents)
  }

  return (
    <div className={styles.documentList}>
      <div className={styles.documentListContainer}>
        { topLevelDocuments?.length > 0
          ? documentComponents(topLevelDocuments)
          : null
        }
      </div>
    </div>
  )
}
EsgDocumentList.propTypes = {
  search: PropTypes.object.isRequired,
  topLevelDocuments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  onFlaggingStateChange: PropTypes.func,
  onPromoteStateChange: PropTypes.func,
  onExclude: PropTypes.func,
}
export default EsgDocumentList
