import React from 'react'
import classNames from 'classnames'

import {
  BROWSE_SECTIONS,
} from './constants'


export default function BrowseSectionChecklist({
  search,
  currentBrowseSection,
  currentBrowseSubsectionData,
  browseSectionListFilterValue,
  isFirmSourceLabelSelected,
  isFilterSelected,
  handleBrowseFilterSelection,
  getSearchesFromQuery,
}) {
  if (!currentBrowseSubsectionData) {
    return
  }
  const items = currentBrowseSubsectionData.items
  const selectAllLabel = `All ${currentBrowseSubsectionData.label}`
  const isAllChecked = currentBrowseSection === BROWSE_SECTIONS.FIRM_SOURCE_LABELS
    ? isFirmSourceLabelSelected(currentBrowseSubsectionData.value)
    : isFilterSelected(currentBrowseSubsectionData.value)

  const includeSelectAll = currentBrowseSection !== BROWSE_SECTIONS.FIRM_LIBRARY_SEARCHES &&
      !['court', 'state'].includes(currentBrowseSubsectionData.value)

  const selectAll = includeSelectAll
    ? <li key="browse-category-list-select-all" className="browse-category-list-item">
        <label>
          <input
            type="checkbox"
            value={currentBrowseSubsectionData.value}
            checked={isAllChecked}
            className="browse-category-list-item-checkbox"
            onChange={(event) => handleBrowseFilterSelection(event.target.value, event.target.checked, true)}
          />
          <span>{selectAllLabel}</span>
        </label>
      </li>
    : null

  browseSectionListFilterValue = browseSectionListFilterValue.toLowerCase()

  return (
    <ul className="browse-category-list-container">
      {selectAll}
      {items.filter(
        item => item.name.toLowerCase().indexOf(browseSectionListFilterValue) !== -1
      ).filter(
        item => !search || item.id !== search.id
      ).map(item => {
        const isChecked = isAllChecked || isFilterSelected(item.id)
        const isDisabled = currentBrowseSection === BROWSE_SECTIONS.FIRM_SOURCE_LABELS
        const itemName =
          <span
            className={classNames({
              'category-list-item-selected': isChecked && !isDisabled,
              'category-list-item-disabled': isDisabled,
            })}
          >{item.name}</span>
        return (
          <li key={item.id} className="browse-category-list-item">
            <label>
              {
                !isDisabled &&
                <input
                  type="checkbox"
                  value={item.id}
                  checked={isChecked}
                  className="browse-category-list-item-checkbox"
                  onChange={
                    (event) => handleBrowseFilterSelection(event.target.value, event.target.checked, false)
                  }
                />
              }
              {itemName}
            </label>
            {
              ['court', 'state'].includes(item.category) &&
              <div className="court-state-category">
                {
                  getSearchesFromQuery(item.query).map(ss => {
                    return (
                      <div key={ss.id} className="category-list-item-disabled">{ss.name}</div>
                    )
                  })
                }
              </div>
            }
          </li>
        )
      })}
    </ul>
  )
}
