import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {titleCase} from 'title-case'

import Dropdown, { Option } from 'app/common/Dropdown'
import InputBlock from 'app/common/InputBlock'
import TextBox from 'app/common/TextBox'
import Button from 'app/common/Button'
import {Feed} from 'app/models'


export default class SourcesAdminHeader extends Component {
  static propTypes = {
    appNameFull: PropTypes.string.isRequired,
    firm: PropTypes.object.isRequired,
    onFilterStateChange: PropTypes.func.isRequired,
    onBrowseSources: PropTypes.func.isRequired,
    onAddSource: PropTypes.func.isRequired,
  }

  state = {
    filters: {
      name: '',
      label: '',
      scope: null,
      publicationType: null,
      isPromoted: false,
      isDemoted: false,
      isExcluded: false,
      isLimitedSeat: false,
      isAssigned: false,
      status: null,
    },
  }

  render() {
    const {appNameFull, firm} = this.props
    let {orgType} = firm

    if (orgType === 'Organization') {
      orgType = 'Org.'
    }

    const contentTypeCategories = [{label: 'All', value: null}].concat(
      Object.values(Feed.PUBLICATION_TYPE_CATEGORIES).filter(v => v !== 'sources').map(v => {
        return (
          {
            label: titleCase(v),
            value: v,
          }
        )
      })
    )

    return (
      <div className="header">
        <div className="top top-bottom-style">
          <h1>
            <span
              className="header-with-tooltip"
              data-mz-tooltip-text="These are global and private sources that you are managing for users in your firm."
            >
              Manage Sources for {this.props.firm.name}
            </span>
          </h1>

          <div className="top-right">
            <div className="add-private-source">
              <Button
                label="Add a New Firm Source"
                onClick={this.props.onAddSource}
                isPrimary
              />
            </div>

            <div className="add-source">
              <div className="browse-manzama-sources">
                <Button
                  label={`Browse ${appNameFull} Sources`}
                  onClick={this.props.onBrowseSources}
                  
                />
              </div>


            </div>
          </div>
        </div>

        <div className="filters">
          <InputBlock label="Filter by Status" className="type-dropdown">
            <Dropdown
              value={this.state.filters.serverStatus}
              onChange={(value) => this.handleFilterChange('status', value)}
            >
              <Option label="All" value={null} />
              <Option label="Active" value="Active" />
              <Option label="Dormant" value="Dormant" />
              <Option label="Inactive" value="Inactive" />
            </Dropdown>
          </InputBlock>
          <InputBlock label="Filter by Source Name">
            <TextBox
              value={this.state.filters.name}
              onChange={evt => this.handleFilterChange('name', evt.target.value)}
            />
          </InputBlock>

          <InputBlock label="Filter by Label">
            <TextBox
              value={this.state.filters.label}
              onChange={evt => this.handleFilterChange('label', evt.target.value)}
            />
          </InputBlock>

          <InputBlock label="Filter by Type" className="type-dropdown">
            <Dropdown
              value={this.state.filters.scope}
              onChange={(value) => this.handleFilterChange('scope', value)}
            >
              <Option label="All" value={null} />
              <Option label={appNameFull} value="global" />
              <Option label={`${orgType} Only`} value="proprietary" />
            </Dropdown>
          </InputBlock>

          <InputBlock label="Filter by Content Type" className="type-dropdown">
            <Dropdown
              options={contentTypeCategories}
              value={this.state.filters.publicationType}
              onChange={(value) => this.handleFilterChange('publicationType', value)}
            />
          </InputBlock>

          <div className="stacked-filters">
            <InputBlock label={`Promoted ${orgType} Wide`} isInline>
              <input
                type="checkbox"
                checked={this.state.filters.isPromoted}
                onChange={evt => this.handleFilterChange('isPromoted', evt.target.checked)}
              />
            </InputBlock>

            <InputBlock label={`Demoted ${orgType} Wide`} isInline>
              <input
                type="checkbox"
                checked={this.state.filters.isDemoted}
                onChange={evt => this.handleFilterChange('isDemoted', evt.target.checked)}
              />
            </InputBlock>

            <InputBlock label={`Excluded ${orgType} Wide`} isInline>
              <input
                type="checkbox"
                checked={this.state.filters.isExcluded}
                onChange={evt => this.handleFilterChange('isExcluded', evt.target.checked)}
              />
            </InputBlock>
          </div>

          <div className="stacked-filters">
            <InputBlock label="Limited Seat Source" isInline>
              <input
                type="checkbox"
                checked={this.state.filters.isLimitedSeat}
                onChange={evt => this.handleFilterChange('isLimitedSeat', evt.target.checked)}
              />
            </InputBlock>

            <InputBlock label="Assigned to Individuals" isInline>
              <input
                type="checkbox"
                checked={this.state.filters.isAssigned}
                onChange={evt => this.handleFilterChange('isAssigned', evt.target.checked)}
              />
            </InputBlock>
          </div>
        </div>
      </div>
    )
  }

  handleFilterChange(key, value) {
    const filters = {...this.state.filters, [key]: value}
    this.setState({filters}, () => {
      this.props.onFilterStateChange(filters)
    })
  }
}
