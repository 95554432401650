import { all, takeLatest, put, select } from 'redux-saga/effects'

import * as notifications from 'app/global/notifications'
import * as urls from 'app/urls'
import { handleSagaError } from 'app/utils/errors'

import * as api from './advanced-search-api'
import * as actions from './advanced-search-actions'
import * as selectors from 'app/advanced-search/advanced-search-selectors'


function* handleGetSearchResults(action) {
  try {
    const { filters, queryType } = action.payload
    const termFrequencyFilters =
      yield select(selectors.pendingTermFrequencyFilters)
    let query = action.payload.query
    if (query) {
      // getSearch is only needed to validate the query
      const response = yield api.getSearch({queryType, query})
      const {validationErrorMessage} = response.savedSearch
      if (validationErrorMessage) {
        yield put(
          notifications.actions.showNotification({
            type: 'error',
            message: `Invalid query, errors found: ${validationErrorMessage}.`,
            duration: 10,
          })
        )
        yield put(actions.hideLoader())
        return
      }
      query = encodeURIComponent(query)
    }
    let url = `${urls.tier3Unsaved()}?query=${query}&query-type=${queryType}`
    if (filters && filters.length > 0) {
      let response = yield api.getAppliedFilterKey(filters)
      url = `${url}&filter-key=${response.key}`
    }
    if (termFrequencyFilters && termFrequencyFilters.length > 0) {
      let response = yield api.getTermFrequencyFilterKey(termFrequencyFilters)
      url = `${url}&term-freq=${response.key}`
    }

    window.location.href = url
  } catch(error) {
    yield* handleSagaError(error)
    yield put(actions.hideLoader())
  }
}

function* handleChangeTermFrequencyArray(action) {
  let terms = (yield select(selectors.termFrequencyArray)).slice()
  if ('action' in action.payload && action.payload.action === 'delete') {
    terms = terms.filter(t => t.label !== action.payload.label)
  } else {
    let term = action.payload.label
    term.replace(/(^\s*)|(\s*$)/gi, "")
    term.replace(/[ ]{2,}/gi, " ")
    term.replace(/\n /, "\n");
    if (term.split(' ').length !== 1) {
      const errorMessage = 'Please provide only single word terms.'
      yield put(
        notifications.actions.showNotification({
          type: 'error',
          message: errorMessage,
          duration: 10,
        })
      )
      return
    }
    terms.push(action.payload)
  }
  yield put(actions.setTermFrequencyArray(terms))
}

function* handleSubmitTermFrequencyFilter(action) {
  const frequency = yield select(selectors.termFrequencyFrequency)
  const terms = yield select(selectors.termFrequencyArray)

  if (terms.length < 1) {
    const errorMessage = 'Please provide at least one term.'
    yield put(
      notifications.actions.showNotification({
        type: 'error',
        message: errorMessage,
        duration: 10,
      })
    )
    return
  }

  if (!(frequency >= 1 && frequency <= 99)) {
    const errorMessage = 'Please enter a valid integer for the frequency (1-99)'
    yield put(
      notifications.actions.showNotification({
        type: 'error',
        message: errorMessage,
        duration: 10,
      })
    )
    return
  }

  let pendingTermFrequencyFilters =
    (yield select(selectors.pendingTermFrequencyFilters)).slice()
  const filterData = yield select(selectors.termFrequencyFilter)
  const filterTerms = filterData.termArray.map((term) => (
    term.label
  ))

  const pendingFilterData = {
    'frequency': filterData.frequency,
    'termArray': filterTerms,
    'targetField': filterData.targetField,
  }
  pendingTermFrequencyFilters.push(pendingFilterData)
  yield put(actions.setPendingTermFrequencyFilters(pendingTermFrequencyFilters))
  yield put(actions.resetTermFrequencyFilter())
}

function* handleRemovePendingTermFrequencyFilter(action) {
  const pendingTermFrequencyFilters =
    (yield select(selectors.pendingTermFrequencyFilters)).slice()
  const pendingFilter = action.payload

  if ('action' in pendingFilter && pendingFilter.action === 'delete') {
    const newPendingArray = pendingTermFrequencyFilters.filter(tff => (
      tff.frequency !== pendingFilter.frequency ||
      tff.termArray.map((term) => term).join(', ')+`: ${tff.frequency}` !== pendingFilter.label ||
      tff.targetField !== pendingFilter.targetField
      )
    )
    yield put(actions.setPendingTermFrequencyFilters(newPendingArray))
  }
}

export default function*() {
  yield all([
    takeLatest(
      actions.getSearchResults,
      handleGetSearchResults,
    ),
    takeLatest(
      actions.changeTermFrequencyArray,
      handleChangeTermFrequencyArray,
    ),
    takeLatest(
      actions.submitTermFrequencyFilter,
      handleSubmitTermFrequencyFilter,
    ),
    takeLatest(
      actions.removePendingTermFrequencyFilter,
      handleRemovePendingTermFrequencyFilter,
    ),
  ])
}
