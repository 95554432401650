import {startOfDay, subDays, subHours, subMonths, subWeeks} from 'date-fns'
import {map, pipe, uniq} from 'ramda'

import {feedDisplayRegex, tier3Regex} from 'app/urls'
import {intersectionExists} from 'app/utils'
import {Feed} from 'app/models'

import * as constants from './search-results-page-constants'

export const getTier3SearchOrFeedId = (pathname = window.location.pathname) => {
  if (pathname === '/search/') {
    return -1
  }

  // TODO: Replace this with a proper URL detection scheme.
  let idString = null
  let match = pathname.match(feedDisplayRegex)
  if (match) {
    idString = match[1]
  }
  else {
    match = pathname.match(tier3Regex)
    if (match) {
      idString = match[3]
    }
  }
  if (!idString) {
    return null
  }
  return parseInt(idString, 10)
}

export const isFeedPage = (pathname = window.location.pathname) => {
  return feedDisplayRegex.test(pathname)
}

/**
 * Converts a time frame string (like '2w') to a date range, with right now as
 * the end date.
 */
export const dateRangeFromTimeFrameString = timeFrameString => {
  const now = startOfDay(new Date())
  const unit = timeFrameString.slice(-1)
  const quantity = parseInt(timeFrameString.slice(0, -1), 10)

  let operation
  if (unit === 'h') {
    operation = subHours
  }
  else if (unit === 'd') {
    operation = subDays
  }
  else if (unit === 'w') {
    operation = subWeeks
  }
  else if (unit === 'm') {
    operation = subMonths
  }
  else {
    throw new Error(`Invalid unit on the time frame: '${unit}'.`)
  }

  return {start: operation(now, quantity), end: now}
}

export const isSource = search =>
  !!(search.aboutFeeds && search.aboutFeeds.length)
  || constants.TRUSTED_SOURCE_CATEGORY_TYPES.includes(search.category)

export const isSaved = search => search.id !== -1


/**
 * Given a search, returns the default publication tab that we should show. This
 * will always be "News" unless the search represents one or more sources, and
 * the sources all have a different publication type.
 */
export function defaultActivePublicationTab(search) {
  if (isSource(search)) {
    const allPubTypes = pipe(
      map(feed => feed.publicationType),
      uniq,
    )(search.aboutFeeds || [])
    const validPublicationTabs =
      Object.entries(Feed.PUBLICATION_TYPES_BY_CATEGORY)
        .filter(([tab, pubTypes]) => intersectionExists(allPubTypes, pubTypes))
        .map(([tab, pubTypes]) => tab)
    // Use the first valid tab.
    for (const tab of constants.PUBLICATION_TABS_ORDERED) {
      if (validPublicationTabs.includes(tab)) {
        return tab
      }
    }
  }
  return Feed.PUBLICATION_TYPE_CATEGORIES.NEWS
}

export function resultCountForActivePublicationTypes({activePublicationType,
                                                     activePublicationTab, search,
                                                     publicationCounts, comparePublicationCounts}) {
    const publicationTypes = activePublicationType
      ? [activePublicationType]
      : Feed.PUBLICATION_TYPES_BY_CATEGORY[activePublicationTab ||
        defaultActivePublicationTab(search)]

    const column1Counts = publicationCounts
      .filter(pubCount => publicationTypes.includes(pubCount.name))
      .reduce((sum, pubCount) => sum + pubCount.count, 0)

    const column2Counts = comparePublicationCounts
      ? comparePublicationCounts
        .filter(pubCount => publicationTypes.includes(pubCount.name))
        .reduce((sum, pubCount) => sum + pubCount.count, 0)
      : 0

    const greaterCount = Math.max(column1Counts, column2Counts)
    const compareCountIsGreater = column2Counts > 0 && column2Counts=== greaterCount

    return {greaterCount, compareCountIsGreater}
  }
