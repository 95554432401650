import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import SavedSearchFilters from 'app/reusable/SavedSearchFilters'
import {
  getCurrentFirmLibraryName,
  getAvailableFilters,
  getFirmSourceLabels,
} from 'app/global/global-selectors'
import {getEntities} from 'app/entities/entities-selectors'

import {
  fetchFilters,
  getSearchResults,
  updateSearch,
  setQueryComponentState,
  setSaveData,
} from '../searches-admin-actions'
import {getSearchesAdmin} from '../searches-admin-selectors'


@connect(
  createSelector(
    [
      getEntities,
      getCurrentFirmLibraryName,
      getAvailableFilters,
      getFirmSourceLabels,
      getSearchesAdmin,
    ],
    (
      entities,
      currentFirmLibraryName,
      availableFilters,
      firmSourceLabels,
      searchesAdmin,
    ) => {
      return {
        currentFirmLibraryName,
        searchResults: searchesAdmin.searchResults,
        isLoading: searchesAdmin.isLoading,
        queryComponentState: searchesAdmin.queryComponentState,
        saveData: searchesAdmin.saveData,
        availableFiltersByCategory: availableFilters,
        firmSourceLabels,
      }
    }
  ),
  {
    fetchFilters,
    getSearchResults,
    updateSearch,
    setQueryComponentState,
    setSaveData,
  },
)
export default class FiltersContent extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
  }

  componentDidMount() {
    if (!this.props.availableFiltersByCategory) {
      this.props.fetchFilters();
    }
  }

  render() {
    if (!this.props.availableFiltersByCategory || !this.props.firmSourceLabels) {
      return null
    }

    return (
      <SavedSearchFilters
        currentFirmLibraryName={this.props.currentFirmLibraryName}
        search={this.props.search}
        saveData={this.props.saveData}
        setSaveData={this.props.setSaveData}
        searchResults={this.props.searchResults}
        isLoading={this.props.isLoading}
        queryComponentState={this.props.queryComponentState}
        setQueryComponentState={this.props.setQueryComponentState}
        onUpdateSearchResults={this.props.getSearchResults}
        onSave={this.props.updateSearch}
      />
    )
  }
}
