import {args, gql} from "app/graphql"
import {apiCall, graphqlQuery} from "app/api"

/**
 * @param group_id - This is used in group dashboards to fetch sections for the group user instead
 *     of the active user. If group_id is undefined, the sections of the logged-in user will be fetched by default.
 */
export function fetchDashboardSectionsData(sections, group_id=undefined) {
  return graphqlQuery(
    gql`
      query {
        sections: dashboardSections(${args({sections, group_id})}) {
          section
          categories
          sectionName
          chartSectionName
          searches {
            id
            name
            category
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}

/**
 * @param group_id - This is used in group dashboards to fetch search results for the group user
 *     instead of the active user. If group_id is undefined, the sections of the logged-in user will
 *     be fetched by default.
 *
 */
export function fetchDashboardSectionsResults(sections, group_id=undefined) {
  return graphqlQuery(
    gql`
      query {
        data: dashboardSectionResults(${args({sections, group_id})}) {
          section
          showChart
          chartIds
          searchResults {
            savedSearchId
            trendData {
              displayScore
              displayName
            }
            stockData {
              change
              lastTradePrice
              lastUpdate
              percentChange
              symbol
            }
            insightsEnabled
            searchUrl
            documents {
              id
              isFlagged
              publishedAt
              insights {
                valence
                valenceDisplayName
                factor
                factorDisplayName
                subfactor
                subfactorDisplayName
                isLitigation
                isOpinion
                isRumor
                interimUrl
              }
              headline
              link
              snippet
              feed {
                id
                name
                sourceType
                url
                isPromoted
                isProprietary
                credentials
                isSubscriptionRequired
                editUrl
              }
              staffInfo {
                editUrl
                selectUrl
              }
            }
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}

function fetchAggregateChartData({
  type,
  category,
  ssIds,
  duration,
  startDate,
  endDate,
  alphaSort,
}) {
  const data = {ssIds, duration, startDate, endDate, alphaSort}
  return apiCall({
    url: `/chart/${type}/${category}/`,
    method: 'GET',
    data,
  })
}

export function fetchTimelineChartAggregateData(options) {
  return fetchAggregateChartData({
    type: 'line',
    ...options,
  })
}
