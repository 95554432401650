import PropTypes from 'prop-types'
import React, { Component } from 'react'

import AddPrivateSource from './AddPrivateSource'
import Email from './Email'
import Website from './Website'
import Twitter from './AddTwitterSource'
import Tooltip from "app/common/Tooltip"
import classNames from 'classnames'
import { TWITTER_TOOLTIP_MESSAGE } from 'app/constants'

const SOURCE_TYPES = {
  RSS: 'rss',
  WEBSITE: 'website',
  EMAIL: 'email',
  TWITTER: 'twitter',
}


export default class Container extends Component {
  static propTypes = {
    onModalClose: PropTypes.func.isRequired,
  }

  state = {
    selectedSourceType: SOURCE_TYPES.RSS,
  }

  // React methods

  render() {
    return (
      <div id="add-private-source">
        <h1>Add new...</h1>
        {this.renderSourceTypeOptions()}
        {this.renderContent()}
      </div>
    )
  }

  // Render helpers

  renderSourceTypeOptions() {
    const types = [
      {label: "RSS", value: SOURCE_TYPES.RSS},
      {label: "Website", value: SOURCE_TYPES.WEBSITE},
      {label: "Email", value: SOURCE_TYPES.EMAIL},
      {label: "Twitter", value: SOURCE_TYPES.TWITTER, actionDisabled: true, tooltip: TWITTER_TOOLTIP_MESSAGE},
    ]
    return (
      <div className="source-type">
        {types.map(t => this.renderSourceTypeOption(t))}
      </div>
    )
  }

  renderSourceTypeOption({label, value, actionDisabled, tooltip}) {
    return (
      <label key={value}>
        <input
          type="radio"
          name="source-type"
          value={value}
          checked={this.state.selectedSourceType === value}
          disabled={actionDisabled}
          onChange={this.onSelectSourceType}
        />
        <span className={classNames("label inline", {disabled: actionDisabled})}>{label}</span>
        {tooltip ? <Tooltip label={tooltip}><div className='tooltip help tool-tip'/></Tooltip> : null}
      </label>
    )
  }

  renderContent() {
    const sourceType = this.state.selectedSourceType

    let content
    if (sourceType === SOURCE_TYPES.RSS) {
      content = <AddPrivateSource
        onAdd={this.props.onAdd}
        onModalClose={this.props.onModalClose}
      />
    } else if (sourceType === SOURCE_TYPES.WEBSITE) {
      content = <Website onModalClose={this.props.onModalClose} />
    } else if (sourceType === SOURCE_TYPES.EMAIL) {
      content = <Email onModalClose={this.props.onModalClose} />
    } else if (sourceType === SOURCE_TYPES.TWITTER) {
      content = <Twitter/>
    } else {
      // This shouldn't happen
      content = "There was an error. Please try again."
    }
    return content
  }

  // State management

  selectSourceType(value) {
    this.setState({selectedSourceType: value})
  }

  // Event handlers

  onSelectSourceType = (e) => {
    //Because twitter is disabled so avoiding its selection
    if (e.target.value !== SOURCE_TYPES.TWITTER){
      this.selectSourceType(e.target.value)
    }
  }
}
