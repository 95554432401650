import { handleActions } from 'redux-actions'
import * as actions from './help-actions'


const getInitialState = () => ({
  isLoading: false,

  // start as null to differentiate from potential empty array after fetch.
  topics: null,
  searchResults: null,
})

export default handleActions({
  [actions.setIsLoading]: (state, action) => ({
    ...state,
    isLoading: action.payload,
  }),
  [actions.fetchTopics]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.setTopics]: (state, action) => ({
    ...state,
    topics: action.payload,
  }),
  [actions.fetchSearchResults]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.setSearchResults]: (state, action) => ({
    ...state,
    searchResults: action.payload,
  }),
}, getInitialState())
