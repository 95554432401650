import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import Button from 'app/common/Button'
import InputBlock from 'app/common/InputBlock'
import ConfirmationModal from 'app/common/modals/ConfirmationModal'

import SharedSearchesTable from './Table'
import {
  showAssignModal,
  setDeleteSearchIds,
  deleteSearches,
} from '../../searches-admin-actions'

import * as styles from './style.less'
import {getSearchesAdmin} from "../../searches-admin-selectors";


@connect(
  createSelector(
    [getSearchesAdmin],
    (searchesAdmin) => {
      return {
        deleteSearchIds: searchesAdmin.deleteSearchIds,
      }
    },
  ),
  // Map dispatch to props
  {
    showAssignModal,
    setDeleteSearchIds,
    deleteSearches,
  },
)
export default class EditSearchModalSharingContent extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  state = {
    filters: {
      name: '',
      user: false,
      group: false,
    },
    selectedSearchIds: [],
  }

  render() {
    const copiedBy = this.props.search.copiedBy
    const {filters, selectedSearchIds} = this.state

    const filteredSearches = copiedBy
      .filter(search => {
        if (
          filters.name
          && !search.name.toLowerCase().includes(filters.name.toLowerCase())
        ) {
          return false
        }

        if (filters.user && search.owner.isGroup) {
          return false
        }

        if (filters.group && !search.owner.isGroup) {
          return false
        }

        return true
      })

    const areAllSelected = selectedSearchIds.length === copiedBy.length
    const areAnySelected = !!selectedSearchIds.length

    const deletingSearchesCount = this.props.deleteSearchIds.length
    const deleteConfirmModal = deletingSearchesCount > 0
      ? <ConfirmationModal
          showExit={false}
          message={`
            Are you sure you want to delete
            ${deletingSearchesCount === 1 ? 'this search' : `these ${deletingSearchesCount} searches`}.
            This action cannot be undone.
          `}
          secondaryMessage={`
            The search ${deletingSearchesCount === 1 ? 'owner' : 'owners'} will no longer have access to
            ${deletingSearchesCount === 1 ? 'this search' : `these searches`} in their profile and will no longer 
            receive alert emails for ${deletingSearchesCount === 1 ? 'this search' : `these searches`}.
          `}
          confirmButtonText="Delete"
          onConfirm={() => this.deleteSearches()}
          onClose={() => this.props.setDeleteSearchIds([])}
        />
      : null

    return (
      <div className={styles.sharingContent}>
        {deleteConfirmModal}

        <div className={styles.header}>
          <InputBlock label="Filter by User or Group Name">
            <input
              type="text"
              onChange={this.createFilterChangeHandler('name', 'value')}
            />
          </InputBlock>

          <div className={styles.toggles}>
            <InputBlock isInline label="User">
              <input
                type="checkbox"
                onChange={this.createFilterChangeHandler('user', 'checked')}
                disabled={this.state.filters.group}
              />
            </InputBlock>

            <InputBlock isInline label="Group">
              <input
                type="checkbox"
                onChange={this.createFilterChangeHandler('group', 'checked')}
                disabled={this.state.filters.user}
              />
            </InputBlock>
          </div>

          <div className="inline assign-button">
            <button
              className="save button primary"
              onClick={() => this.showAssignModal([this.props.search.id])}
            >Assign</button>
          </div>
        </div>

        {
          copiedBy.length > 0 &&
          <div className={styles.bulkActions}>
            <input
              className={styles.selectAll}
              type="checkbox"
              onChange={this.onSelectAllChange}
              checked={areAllSelected}
            />

            <Button
              label="Remove"
              className={styles.button}
              isDestructive
              disabled={!areAnySelected}
              onClick={() => this.props.setDeleteSearchIds(selectedSearchIds)}
            />
          </div>
        }

        <div>
          <SharedSearchesTable
            parentSearch={this.props.search}
            searches={filteredSearches}
            selectedSearchIds={selectedSearchIds}
            onSelectStateChange={this.onSelectStateChange}
            onRemove={search => this.props.setDeleteSearchIds([search.id])}
          />
        </div>
      </div>
    )
  }

  // Event handlers

  /*
   * Creates a function that sets the filter property at `filterName` to
   * the value on the `event.target` attribute at `eventAttrName`.
   *
   * For example:
   *
   *   <input onChange={this.createFilterChangeHandler('name', 'value')} />
   *
   * gives the 'name' filter the value `event.target.value`.
   */
  createFilterChangeHandler(filterName, eventAttrName) {
    return event => {
      this.setState({
        filters: {
          ...this.state.filters,
          [filterName]: event.target[eventAttrName],
        },
      })
    }
  }

  onSelectAllChange = (event) => {
    if (event.target.checked) {
      this.setState({
        selectedSearchIds: this.props.search.copiedBy.map(a => a.id),
      })
    } else {
      this.setState({selectedSearchIds: []})
    }
  }

  onSelectStateChange = (search, selected) => {
    const ids = this.state.selectedSearchIds
    if (selected) {
      this.setState({selectedSearchIds: [...ids, search.id]})
    } else {
      this.setState({
        selectedSearchIds: ids.filter(id => id !== search.id),
      })
    }
  }

  showAssignModal(searchIds) {
    this.setState({selectedSearchIds: []})
    this.props.showAssignModal(searchIds)
  }

  deleteSearches() {
    this.setState({selectedSearchIds: []})
    this.props.deleteSearches()
  }
}
