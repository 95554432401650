import Model from 'app/framework/Model'
import { field, foreignKey, hasMany } from 'app/framework/Model/fields'

import Feed from './Feed'
import User from './User'


export default class SourceAssignment extends Model {
  static entityKey = 'sourceAssignments'

  static fields = {
    feed: foreignKey(Feed),
    assignedTo: foreignKey(User),
    assignmentType: field(),
    isPromoted: field(),
    isDemoted: field(),
    isTrusted: field(),
    isExcluded: field(),
  }

  static indexes = ['feed']
}
Feed.fields.assignments = hasMany(SourceAssignment, {required: false})
