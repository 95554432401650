import React, {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'
import {DndProvider} from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import {createSelector} from 'reselect'

import {getEntities} from 'app/entities/entities-selectors'
import {
  getCurrentFirmOrgType,
  getAppName,
} from 'app/global/global-selectors'
import {Feed, SavedSearch} from 'app/models'
import Orm from 'app/framework/Orm'
import LoadingOverlay from 'app/common/LoadingOverlay'
import InputBlock from 'app/common/InputBlock'
import TextBox from 'app/common/TextBox'
import Button from 'app/common/Button'
import Modal from 'app/common/Modal'
import HelpIconTooltip from 'app/common/HelpIconTooltip'
import Tooltip from 'app/common/Tooltip'
import * as notifications from 'app/global/notifications'

import {getProfileSources} from './profile-sources-selectors'
import {
  setFilter,
  setFilterField,
  setSelectedGlobalFeedIds,
  setSelectedFirmFeedIds,
  promoteSelectedFeeds,
  demoteSelectedFeeds,
  trustSelectedFeeds,
  excludeSelectedFeeds,
  showAddToFolderModal,
  deletePromotedFeed,
  deleteDemotedFeed,
  deleteTrustedFeed,
  deleteTrustedFolder,
  deleteExcludedFeed,
  removeFeedFromTrustedFolder,
  setLimitedSeatModalFeedId,
  setLimitedSeatModalNotes,
  submitLimitedSeatRequest,
  showCreateFolder,
  hideCreateFolder,
  createFolder,
  setNewFolderName,
  promoteFeed,
  demoteFeeds,
  trustFeed,
  excludeFeed,
  addFeedToFolder,
  setDeleteFolderConfirmationModalSearchId,
  setEditFolderSearchId,
  saveFolderName,
  moveFeed,
  setSelectedPromotedFeedIds,
  setSelectedExcludedFeedIds,
  setSelectedDemotedFeedIds,
  setSelectedTrustedFeedIds,
  setSelectedTrustedFolderIds,
  deleteTrustedFeedsAndFolders
} from './profile-sources-actions'
import AddToFolderModal from './AddToFolderModal'
import DraggableItem from './DraggableItem'
import DropArea from './DropArea'
import {
  FILTER_FIELD_ALL,
  FILTER_FIELD_URL,
  FILTER_FIELD_NAME,
  FILTER_FIELD_LABELS,
  FILTER_FIELDS,
  FEED_SELECTION_LIMIT,
  FEED_SELECTION_TIMEOUT,
  FEED_TYPE
} from './profile-sources-constants'

import './ProfileSources.less'
import LoadingSpinner from 'app/common/LoadingSpinner'
import BulkAction from './BulkAction'
@connect(
  createSelector(
    [
      getEntities,
      getCurrentFirmOrgType,
      getAppName,
      getProfileSources,
    ],
    (
      entities,
      currentFirmOrgType,
      appName,
      profileSources,
    ) => {
      const orm = Orm.withEntities(entities)
      return {
        orgType: currentFirmOrgType,
        appName: appName,
        firmFeeds: orm.getByIds(Feed, profileSources.firmFeedIds),
        globalFeeds: orm.getByIds(Feed, profileSources.globalFeedIds),
        promotedFeeds: orm.getByIds(Feed, profileSources.promotedFeedIds),
        demotedFeeds: orm.getByIds(Feed, profileSources.demotedFeedIds),
        limitedSeatAssignmentFeedIds: profileSources.limitedSeatAssignmentFeedIds,
        excludedFirmWideFeedIds: profileSources.excludedFirmWideFeedIds,
        trustedSources: orm.getByIds(SavedSearch, profileSources.trustedFeedIds),
        trustedFolders: orm.getByIds(SavedSearch, profileSources.trustedFolderIds),
        excludedSources: orm.getByIds(Feed, profileSources.excludedFeedIds),
        filter: profileSources.filter,
        filterField: profileSources.filterField,
        isLoading: profileSources.isLoading,
        isInitializing: profileSources.isInitializing,
        selectedFirmFeedIds: profileSources.selectedFirmFeedIds,
        selectedGlobalFeedIds: profileSources.selectedGlobalFeedIds,
        limitedSeatModalFeed: profileSources.limitedSeatModalFeedId ?
          orm.getById(Feed, profileSources.limitedSeatModalFeedId) : null,
        limitedSeatModalNotes: profileSources.limitedSeatModalNotes,
        isCreateFolderShown: profileSources.isCreateFolderShown,
        newFolderName: profileSources.newFolderName,
        deleteFolderConfirmationModalSearch: profileSources.deleteFolderConfirmationModalSearchId ?
          orm.getById(SavedSearch, profileSources.deleteFolderConfirmationModalSearchId) : null,
        editFolderSearch: profileSources.editFolderSearchId ?
          orm.getById(SavedSearch, profileSources.editFolderSearchId) : null,
        featuredSourceFeedIds: profileSources.featuredSourceFeedIds,
        loaderTrusted: profileSources.loaderTrusted,
        selectedPromotedFeedIds: profileSources.selectedPromotedFeedIds,
        selectedExcludedFeedIds: profileSources.selectedExcludedFeedIds,
        selectedDemotedFeedIds: profileSources.selectedDemotedFeedIds,
        selectedTrustedFeedIds: profileSources.selectedTrustedFeedIds,
        selectedTrustedFolderIds: profileSources.selectedTrustedFolderIds,
        nlaLicense : profileSources.nlaLicenseExpired,
        nlaFeedIds : profileSources.nlaFeedIds 
      }
    }
  ),
  {
    setFilter,
    setFilterField,
    setSelectedGlobalFeedIds,
    setSelectedFirmFeedIds,
    promoteSelectedFeeds,
    demoteSelectedFeeds,
    trustSelectedFeeds,
    excludeSelectedFeeds,
    showAddToFolderModal,
    deletePromotedFeed,
    deleteDemotedFeed,
    deleteTrustedFeed,
    deleteTrustedFolder,
    deleteExcludedFeed,
    removeFeedFromTrustedFolder,
    setLimitedSeatModalFeedId,
    setLimitedSeatModalNotes,
    submitLimitedSeatRequest,
    showCreateFolder,
    hideCreateFolder,
    createFolder,
    setNewFolderName,
    promoteFeed,
    demoteFeeds,
    trustFeed,
    excludeFeed,
    addFeedToFolder,
    setDeleteFolderConfirmationModalSearchId,
    setEditFolderSearchId,
    saveFolderName,
    moveFeed,
    showNotification: notifications.actions.showNotification,
    setSelectedPromotedFeedIds,
    setSelectedExcludedFeedIds,
    setSelectedDemotedFeedIds,
    setSelectedTrustedFeedIds,
    setSelectedTrustedFolderIds,
    deleteTrustedFeedsAndFolders 
  },
)
export default class ProfileSources extends Component {
  state = {
    expandedFolderId: null,
    limitSelection: false
  }

  render() {
    const {
      orgType,
      appName,
      globalFeeds,
      promotedFeeds,
      demotedFeeds,
      limitedSeatAssignmentFeedIds,
      trustedFolders,
      trustedSources,
      excludedSources,
      filter,
      isLoading,
      isInitializing,
      selectedFirmFeedIds,
      selectedGlobalFeedIds,
      limitedSeatModalFeed,
      isCreateFolderShown,
      newFolderName,
      deleteFolderConfirmationModalSearch,
      editFolderSearch,
      loaderTrusted,
      selectedPromotedFeedIds,
      selectedExcludedFeedIds,
      selectedDemotedFeedIds,
      selectedTrustedFeedIds,
      selectedTrustedFolderIds
    } = this.props

    const loader =
      ((isLoading || isInitializing) && (!loaderTrusted))
      ? <div className={classNames('loading-container', {initializing: isInitializing})}>
          <LoadingOverlay/>
        </div>
      : null

    const filteredFirmFeeds = this.getFilteredFirmFeeds()

    const selectedFeedIds = selectedFirmFeedIds.concat(selectedGlobalFeedIds)

    const selectAllTrustedFeeds = this.areAllTrustedFeedsSelected()

    const firmSourceList =
      filteredFirmFeeds.map(f => {
        return (
          <DraggableItem
            key={f.id}
            id={f.id}
            className="list-row-container"
          >
            <div className="list-row">
              <div>
                {
                  f.isLimitedSeat && !limitedSeatAssignmentFeedIds.includes(f.id)
                  ? <span>
                      <a className="limited-seat-link" onClick={() => this.showLimitedSeatModal(f.id)}>LS</a>
                      <span>{f.name}</span>
                    </span>
                  : <span className="checkbox-container">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={this.isFeedSelected(f.id)}
                        onChange={evt => {this.handleFeedCheckbox('firm', f.id, evt.target.checked)}}
                      />
                      {this.checkNLAFeeds(f.id) ? f.name : <a href={f.absoluteUrl}>{f.name}</a>}
                    </span>
                }
              </div>

              <div>
                {
                  f.labels.map(label => {
                    return <div key={label.id} className="label-token">{label.name}</div>
                  })
                }
              </div>
            </div>
          </DraggableItem>
        )
      })

    const firmSourceListContent =
      <div className="list-container">
        {
          filteredFirmFeeds.length === 0
          ? <p className="list-message">Cant find what you're looking for? Contact
            your {orgType.toLowerCase()} administrator to add sources.</p>
          : firmSourceList
        }
      </div>

    const globalFeedList =
      globalFeeds.map(f => {
        return (
          <DraggableItem
            key={f.id}
            id={f.id}
            className="list-row-container"
          >
            <div className="list-row">
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={this.isFeedSelected(f.id)}
                  onChange={evt => {this.handleFeedCheckbox('global', f.id, evt.target.checked)}}
                />
                {this.checkNLAFeeds(f.id) ? f.name : <a href={f.absoluteUrl}>{f.name}</a>}
              </div>
            </div>
          </DraggableItem>
        )
      })

    const globalFeedListContent =
      <div className="list-container">
        {
          globalFeeds.length === 0
          ? <p className="list-message">Cant find what you're looking for? Contact content@{appName.toLowerCase()}.com to suggest a source.</p>
          : globalFeedList
        }
      </div>

    const globalFeedListContainer =
      filter &&
      <div className="global-feeds">
        <InputBlock
          label={`${appName} Sources`}
          isInline
          className="list-header"
        >
          <input
            type="checkbox"
            checked={this.areAllFeedsSelected('global')}
            onChange={evt => this.handleSelectAllCheckbox('global', evt.target.checked)}
            className="checkbox"
          />
        </InputBlock>

        {globalFeedListContent}
      </div>

    const promotedFeedList =
      promotedFeeds.map(f => {
        return (
          <DraggableItem
            key={f.id}
            origin="promoted"
            id={f.id}
            className="list-row-container"
          >
            <div className="list-row">
              <div className="checkbox-container">
              <input
                  type="checkbox"
                  className="checkbox"
                  checked={selectedPromotedFeedIds.includes(f.id)}
                  onChange={evt => {this.handlePromotedFeedCheckbox(f.id, evt.target.checked)}}
                />
                {this.checkNLAFeeds(f.id) ? f.name : <a href={f.absoluteUrl}>{f.name}</a>}
                {this.feedLabelsTooltip(f)}
              </div>

              <div className="actions">
                <div
                  onClick={() => this.props.deletePromotedFeed({id: f.id})}
                >Remove</div>
              </div>
            </div>
          </DraggableItem>
        )
      })

    const demotedFeedList =
      demotedFeeds.map(f => {
        return (
          <DraggableItem
            key={f.id}
            origin="demoted"
            id={f.id}
            className="list-row-container"
          >
            <div className="list-row">
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={selectedDemotedFeedIds.includes(f.id)}
                  onChange={evt => { this.handleDemotedFeedCheckbox(f.id, evt.target.checked) }}
                />
                {this.checkNLAFeeds(f.id) ? f.name : <a href={f.absoluteUrl}>{f.name}</a>}
                {this.feedLabelsTooltip(f)}
              </div>

              <div className="actions">
                <div
                  onClick={() => this.props.deleteDemotedFeed({id: f.id})}
                >Remove</div>
              </div>
            </div>
          </DraggableItem>
        )
      })

    const trustedSourceFolderList =
      trustedFolders.map(ss => {
        return (
          <DropArea
            key={ss.id}
            destination="trusted-folder"
            destinationId={ss.id}
            className="list-row-container"
            onDrop={
              (
                id,
                destination,
                origin,
                destinationId,
                originId,
              ) => this.handleDropFeedEntry(id, destination, origin, destinationId, originId, trustedFolders)
            }
          >
            <div className="list-row">
              {
                editFolderSearch && ss.id === editFolderSearch.id
                ? <React.Fragment>
                    <div>
                      <TextBox
                        value={newFolderName || editFolderSearch.name}
                        onChange={evt => this.props.setNewFolderName(evt.target.value)}
                      />
                    </div>

                    <div className="buttons">
                      <Button
                        label="Save"
                        disabled={!newFolderName}
                        onClick={() => this.props.saveFolderName({searchId: editFolderSearch.id, name: newFolderName})}
                      />
                      <Button
                        label="Cancel"
                        isPlainText={true}
                        onClick={() => this.props.setEditFolderSearchId(null)}
                      />
                    </div>
                  </React.Fragment>
                : <React.Fragment>
                    <div>
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={selectedTrustedFolderIds.includes(ss.id)}
                        onChange={evt => { this.handleTrustedFolderCheckbox(ss.id, evt.target.checked) }}
                      />
                      <span>{ss.name} ({ss.aboutFeeds.length})</span>
                      {
                        this.state.expandedFolderId === ss.id
                        ? <img
                            src="/media/img/arrow_down.gif"
                            onClick={() => this.setExpandedFolderId(null)}
                          />
                        : <img
                            src="/media/img/arrow_right.gif"
                            onClick={() => this.setExpandedFolderId(ss.id)}
                          />
                      }
                    </div>

                    <div className="actions">
                      <div
                        onClick={() => this.props.setEditFolderSearchId(ss.id)}
                      >Edit</div>
                      <div
                        onClick={() => this.props.setDeleteFolderConfirmationModalSearchId(ss.id)}
                      >Delete</div>
                    </div>
                  </React.Fragment>
              }
            </div>
            {
              this.state.expandedFolderId === ss.id &&
              <div className="list-sub-rows">
                {
                  ss.aboutFeeds.map(f => {
                    return (
                      <DraggableItem
                        key={f.id}
                        origin="trusted-folder"
                        id={f.id}
                        originId={ss.id}
                        // className="list-row-container"
                      >
                        <div className="list-sub-row">
                          <div>
                          {this.checkNLAFeeds(f.id) ? f.name : <a href={f.absoluteUrl}>{f.name}</a>}
                            {this.feedLabelsTooltip(f)}
                          </div>
                          <div className="actions">
                            <div
                              onClick={() => this.props.removeFeedFromTrustedFolder({id: f.id, searchId: ss.id})}
                            >Remove</div>
                          </div>
                        </div>
                      </DraggableItem>
                    )
                  })
                }
              </div>
            }
          </DropArea>
        )
      })

    const trustedSourceList =
      trustedSources.map(ss => {
        const feed = ss.aboutFeeds[0]
        return (
          <DraggableItem
            key={ss.id}
            origin="trusted"
            id={feed.id}
            originId={ss.id}
            className="list-row-container"
          >
            <div className="list-row">
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={selectedTrustedFeedIds.includes(ss.id)}
                  onChange={evt => {this.handleTrustedFeedCheckbox(ss.id, evt.target.checked)}}
                />
                {this.checkNLAFeeds(feed.id) ? ss.name : <a href={feed.absoluteUrl}>{ss.name}</a>}
                {this.feedLabelsTooltip(feed)}
              </div>

              <div className="actions">
                <div
                  onClick={() => this.props.deleteTrustedFeed({id: ss.id})}
                >Remove</div>
              </div>
            </div>
          </DraggableItem>
        )
      })

    const excludedSourceList =
      excludedSources.map(f => {
        return (
          <DraggableItem
            key={f.id}
            origin="excluded"
            id={f.id}
            className="list-row-container"
          >
            <div className="list-row">
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={selectedExcludedFeedIds.includes(f.id)}
                  onChange={evt => {this.handleExcludedFeedCheckbox(f.id, evt.target.checked)}}
                />
                {this.checkNLAFeeds(f.id) ? f.name : <a href={f.absoluteUrl}>{f.name}</a>}
                {this.feedLabelsTooltip(f)}
              </div>

              <div className="actions">
                <div
                  onClick={() => this.props.deleteExcludedFeed({id: f.id})}
                >Remove</div>
              </div>
            </div>
          </DraggableItem>
        )
      })

    const limitedSeatModal =
      limitedSeatModalFeed
      ? <Modal
          isOpen={true}
          showExit={false}
          className="limited-seat-modal"
        >
          <h1>Limited Seat Source</h1>
          <h3>
            <i>{limitedSeatModalFeed.name}</i>
            <span> is designated as a limited seat source, this means access to it is restricted to a limited number of
              individuals.</span>
          </h3>
          <h4>Click the button below to request access from your {orgType} Admin.</h4>
          <div>
            <textarea
              className="limited-seat-request-notes"
              placeholder="Additional notes or comments regarding this request."
              onChange={evt => this.props.setLimitedSeatModalNotes(evt.target.value)}
            />
          </div>
          <div className="buttons">
            <Button
              label="Request Access"
              onClick={() => this.submitLimitedSeatRequest()}
            />
            <Button
              label="Cancel"
              onClick={() => this.hideLimitedSeatModal()}
            />
          </div>
        </Modal>
      : null

    const createFolderContainer =
      isCreateFolderShown
      ? <div className="create-folder-container">
          <div className="text-box-container">
            <TextBox
              value={newFolderName}
              placeholder="Enter folder name"
              onChange={evt => this.props.setNewFolderName(evt.target.value)}
            />
          </div>

          <div>
            <Button
              label="Add"
              className="add-button"
              onClick={() => this.props.createFolder(this.props.newFolderName)}
            />
          </div>

          <div>
            <Button
              label="Cancel"
              onClick={() => this.props.hideCreateFolder()}
              isPlainText={true}
            />
          </div>
        </div>
      : null

    const deleteFolderConfirmationModal =
      deleteFolderConfirmationModalSearch
      ? <Modal
          isOpen={true}
          showExit={false}
        >
          <h1>Delete Trusted Source Folder</h1>
          <h3>Are you sure you want to delete {deleteFolderConfirmationModalSearch.name}?</h3>
          <div className="buttons">
            <Button
              label="Delete"
              isDestructive={true}
              onClick={() => this.props.deleteTrustedFolder({id: deleteFolderConfirmationModalSearch.id})}
            />
            <Button
              label="Cancel"
              isPlainText={true}
              onClick={() => this.props.setDeleteFolderConfirmationModalSearchId(null)}
            />
          </div>
        </Modal>
      : null

    const firmSourcesListHeader =
      `
      ${this.props.filter || this.props.featuredSourceFeedIds.length === 0
        ? ''
        : 'Featured '}${orgType} Sources
      `
    
    const onPromoteClick = () => this.checkFeed(FEED_TYPE.PROMOTED, selectedFeedIds, promotedFeeds)
    const onTrustClick = () => this.checkFeed(FEED_TYPE.TRUSTED, selectedFeedIds, trustedSources)
    const onExcludeClick = () => this.checkFeed(FEED_TYPE.EXCLUDE, selectedFeedIds, excludedSources)
    const onDemoteClick = () => this.checkFeed(FEED_TYPE.DEMOTED, selectedFeedIds, demotedFeeds)
    const handleTrustSelectedFeeds = () => {
      this.props.trustSelectedFeeds()
    }

    return (
      <DndProvider backend={HTML5Backend}>
        <div className="profile-sources-content">
          {loader}

          {limitedSeatModal}

          {deleteFolderConfirmationModal}

          <AddToFolderModal/>

          <div className="profile-source-columns">
            <div className="profile-source-column available-feeds">
              <h4 className="group-heading">
                <div>
                  Available Sources To Add
                  <HelpIconTooltip label="Manage Sources in your Profile to further customize your search results by
                    finding sources on the left, and then moving Sources over to Promoted, Trusted, or Excluded Source
                    areas."/>
                </div>
              </h4>

              <div>
                <TextBox
                  placeholder={`Search ${orgType} or Manzama Sources`}
                  value={filter}
                  onChange={evt => {this.props.setFilter(evt.target.value)}}
                />
              </div>

              <div className="search-by">
                <div className="description">Search By:</div>
                {
                  Object.entries(FILTER_FIELDS).map(([value, label]) => {
                    return (
                      <InputBlock key={value} label={label} isInline>
                        <input
                          type="radio"
                          value={value}
                          checked={this.props.filterField === value}
                          disabled={!this.props.filter}
                          onChange={() => this.props.setFilterField(value)}
                        />
                      </InputBlock>
                    )
                  })
                }
              </div>

              <div className={"bulk-actions-wrapper"}>
                <div className="bulk-actions">
                  <Button
                    label="Promote"
                    disabled={selectedFeedIds.length === 0}
                    onClick={onPromoteClick}
                  />

                  <Button
                    label="Trust"
                    disabled={selectedFeedIds.length === 0}
                    onClick={onTrustClick}
                  />

                  <Button
                    label="Add to Folder"
                    disabled={selectedFeedIds.length === 0}
                    onClick={() => this.props.showAddToFolderModal()}
                  />
                </div>

                <div className={"bulk-actions"}>
                  <Button
                    label="Exclude"
                    disabled={selectedFeedIds.length === 0}
                    isDestructive={true}
                    onClick={onExcludeClick}
                  />

                  <Button
                    label="Demote"
                    disabled={selectedFeedIds.length === 0}
                    isDestructive={true}
                    onClick={onDemoteClick}
                  />
                </div>
              </div>

              <div className="available-feeds-lists">
                <InputBlock
                  label={firmSourcesListHeader}
                  isInline
                  className="list-header"
                >
                  <input
                    type="checkbox"
                    checked={this.areAllFeedsSelected('firm')}
                    onChange={evt => this.handleSelectAllCheckbox('firm', evt.target.checked)}
                    className="checkbox"
                  />
                </InputBlock>

                {firmSourceListContent}

                {globalFeedListContainer}
              </div>
            </div>

            <div className="profile-source-column">
              <h4 className="group-heading">
                <div>
                  Promoted Sources
                  <HelpIconTooltip label="Move sources into this area to have them receive a
                    slight boost in how the algorithm ranks content from these sources. Note that
                    promoted and demoted sources are mutually exclusive."/>
                </div>
              </h4>

              <h6>These sources will be promoted higher within the results.</h6>
              <BulkAction
                checked={promotedFeeds.length > 0 && promotedFeeds.length === selectedPromotedFeedIds.length}
                onChange={(evt) => this.handleSelectAllPromotedCheckbox(evt)}
                disabled={selectedPromotedFeedIds.length === 0}
                onClick={() => this.props.deletePromotedFeed({id: selectedPromotedFeedIds})}
              />
              <div className="list-container promoted">
                <DropArea
                  destination="promoted"
                  onDrop={
                    (
                      id,
                      destination,
                      origin,
                      destinationId,
                      originId,
                    ) => this.handleDropFeedEntry(id, destination, origin, destinationId, originId, promotedFeeds)
                  }
                >
                  {promotedFeedList}
                </DropArea>
              </div>

              <div className="group-heading">
                <div>
                  <h4>
                    <div>
                      Trusted Sources
                      <HelpIconTooltip label="Move sources into this area to allow them to be included in your email
                        alert, or to use these Trusted Sources as Filters, better allowing you to customize your
                        searches."/>
                    </div>
                  </h4>
                </div>

                <div>
                  <a
                    className="new-folder-link"
                    onClick={() => this.props.showCreateFolder()}
                  >
                    <div>
                      <img src="/media/img/folder.png"/>
                    </div>

                    <div>
                      <span>Add Folder</span>
                    </div>
                  </a>
                </div>
              </div>

              {createFolderContainer}

              <h6>By default, all articles from these sources will be included in the email alert.</h6>
              <BulkAction
                checked={selectAllTrustedFeeds}
                onChange={(evt) => this.handleSelectAllTrustedCheckbox(evt)}
                disabled={selectedTrustedFeedIds.length === 0 && selectedTrustedFolderIds.length === 0}
                onClick={() => this.deleteAllTrustedSources()}
              />
              <div className="list-container trusted">
                {loaderTrusted && <div className='trustedcontainer'><LoadingSpinner className="spinner" /></div>}
                {!loaderTrusted && trustedSourceFolderList}
                {!loaderTrusted &&
                  <DropArea
                    destination="trusted"
                    onDrop={
                      (
                        id,
                        destination,
                        origin,
                        destinationId,
                        originId,
                      ) => this.handleDropFeedEntry(id, destination, origin, destinationId, originId, trustedSources)
                    }
                  >
                    {trustedSourceList}
                  </DropArea>
                }
              </div>

              <h4 className="group-heading">
                <div>
                  Excluded Sources
                  <HelpIconTooltip label="Move sources into this area to block their content throughout your entire
                    profile and all of your searches."/>
                </div>
              </h4>

              <h6>All articles from these sources will be excluded from all results.</h6>
              <BulkAction
                checked={excludedSources.length > 0 && excludedSources.length === selectedExcludedFeedIds.length}
                onChange={(evt) => this.handleSelectAllExcludedCheckbox(evt)}
                disabled={selectedExcludedFeedIds.length === 0}
                onClick={() => this.props.deleteExcludedFeed({ id: selectedExcludedFeedIds })}
              />
              <div className="list-container excluded">
                <DropArea
                  destination="excluded"
                  onDrop={
                    (
                      id,
                      destination,
                      origin,
                      destinationId,
                      originId,
                    ) => this.handleDropFeedEntry(id, destination, origin, destinationId, originId, excludedSources)
                  }
                >
                 {excludedSourceList}
                </DropArea>
              </div>

              <h4 className="group-heading">
                <div>
                  Demoted Sources
                  <HelpIconTooltip label="Move sources into this area to have them receive a
                    slight decrease in how the algorithm ranks content from these sources. Note
                    that demoted and promoted sources are mutually exclusive."/>
                </div>
              </h4>

              <h6>These sources will be demoted lower within the results.</h6>
              <BulkAction
                checked={demotedFeeds.length > 0 && demotedFeeds.length === selectedDemotedFeedIds.length}
                onChange={(evt) => this.handleSelectAllDemotedCheckbox(evt)}
                disabled={selectedDemotedFeedIds.length === 0}
                onClick={() => this.props.deleteDemotedFeed({ id: selectedDemotedFeedIds })}
              />
              <div className="list-container demoted">
                <DropArea
                  destination="demoted"
                  onDrop={
                    (
                      id,
                      destination,
                      origin,
                      destinationId,
                      originId,
                    ) => this.handleDropFeedEntry(id, destination, origin, destinationId, originId, demotedFeeds)
                  }
                >
                  {demotedFeedList}
                </DropArea>
              </div>

            </div>
          </div>
        </div>
      </DndProvider>
    )
  }

  checkFeed(type, selectedFeeds, existingFeeds) {
    let selectedFeedsAreUnique = null
    if (type === "trusted") {
      selectedFeedsAreUnique = selectedFeeds.every(feedId => {
        return existingFeeds.findIndex(feed => feed.aboutFeedsIds[0] === feedId) === -1;
      });
    }
    else {
      selectedFeedsAreUnique = selectedFeeds.every(feedId => {
        return existingFeeds.findIndex(feed => feed.id === feedId) === -1;
      });
    }
    let commonFeeds = []
      if (type === "trusted") {
        commonFeeds = existingFeeds.filter(feed => {
          return selectedFeeds.includes(feed.aboutFeedsIds[0]);   
        });
      } else {
        commonFeeds = existingFeeds.filter(feed => {
          return selectedFeeds.includes(feed.id);
        });
      }

    const commonFeedsLength = commonFeeds.length
    const selectFeedsLength = selectedFeeds.length  

    if(!selectedFeedsAreUnique){
        const feedDiffernce = commonFeedsLength !== selectFeedsLength?commonFeedsLength:selectFeedsLength
        let message = feedDiffernce +" Feed(s)"+ "  already added as " + type + " sources";
        this.props.showNotification({
          type: 'error',
          message: message,
        })
      }  
    if( commonFeeds.length !== selectedFeeds.length ){
      if (type === "promoted") {
        this.props.promoteSelectedFeeds()
      }
      else if (type === "trusted") {
        this.props.trustSelectedFeeds()
      }
      else if (type === "exclude") {
        this.props.excludeSelectedFeeds()
      }
      else if (type === "demoted") {
        this.props.demoteSelectedFeeds()
      }}
  }
  
  feedLabelsTooltip(feed) {
    return (
      feed.labels.length > 0 &&
      <Tooltip
        label={feed.labels.map(label => <div key={label.id}>{label.name}</div>)}
        containerClassName="tooltip"
      >
        <i className="fa fa-tags"/>
      </Tooltip>
    )
  }

  handleDropFeedEntry(id, destination, origin = null, destinationId = null, originId = null, feedslist) {
    /**
     * id: the id of the feed item being dragged/dropped.
     * destination: where the item is being dropped (promoted, trusted, trusted-folder, excluded).
     * origin: where the item came from (promoted, trusted-folder, excluded). only included when item needs to be
     *  removed from it's original location (eg. moving from promoted to excluded).
     * originId: used when dragging trusted items (to store their ss id). for uncategorized trusted sources, this is
     *  their ss id. for categorized, it is the ss id of the folder they are in.
     * destinationId: used when dropping on a specific item (ie. trusted-folder).
     */
    if (origin) {
      if (destinationId) {
        // do nothing if trusted feed is being dropped on the folder it's currently in.
        if (originId === destinationId) {
          return
        }
      } else if (origin === destination) {
        // do nothing if item is being dropped on the list it's currently in.
        return
      }
      this.props.moveFeed({id, destination, origin, destinationId, originId})
    } else {
      if (destination === 'promoted') {
        if (this.checkDragSources("promoted", [id], feedslist)){
            this.props.promoteFeed({id})
        }
      } else if (destination === 'demoted') {
        if(this.checkDragSources("demoted", [id], feedslist)){
          this.props.demoteFeeds([id])
        }
      } else if (destination === 'trusted') {
        if (this.checkDragSources("trusted", [id], feedslist)){
          this.props.trustFeed({id})
        }
      } else if (destination === 'trusted-folder') {
         if (this.checkDragSources("trusted-folder", [id], feedslist, destinationId)){
        this.props.addFeedToFolder({id, searchId: destinationId})
         }
      } else if (destination === 'excluded') {
        if (this.checkDragSources("exclude", [id], feedslist)){
          this.props.excludeFeed({id})
        }
      }
    }
  }

  checkDragSources(type, selectedFeeds, existingFeeds, destinationId = null) {
    let selectedFeedsAreUnique = null
    if (type === "trusted") {
      selectedFeedsAreUnique = selectedFeeds.every(feedId => {
        return existingFeeds.findIndex(feed => feed.aboutFeedsIds[0] === feedId) === -1;
      });
    } else if (type === "trusted-folder") {
      selectedFeedsAreUnique = true
      existingFeeds.map(s => {
        if (s.id === destinationId) {
          const x = s.aboutFeedsIds
          let y = x.find(id => id == selectedFeeds[0])
          if (y) {
            selectedFeedsAreUnique = false
          } else {
            selectedFeedsAreUnique = true
          }
        }
      })
    } else {
      selectedFeedsAreUnique = selectedFeeds.every(feedId => {
        return existingFeeds.findIndex(feed => feed.id === feedId) === -1;
      });
    }

    if (!selectedFeedsAreUnique) {
      let message = "Already part of " + type + " sources";
      this.props.showNotification({
        type: 'error',
        message: message,
      })
    }
    return selectedFeedsAreUnique
  }


  setExpandedFolderId(id) {
    this.setState({expandedFolderId: id})
  }

  getFilteredFirmFeeds() {
    const {firmFeeds, featuredSourceFeedIds, filterField} = this.props
    let filter = this.props.filter
    let feeds = firmFeeds.filter(f => !this.props.excludedFirmWideFeedIds.includes(f.id))
    /**
     * if there's a filter or there are no featured sources, show firm sources.
     * otherwise just show featured sources.
     */
    if (filter) {
      filter = filter.toLowerCase()
      feeds = feeds.filter(f => {
        return (
          ([FILTER_FIELD_ALL, FILTER_FIELD_NAME].includes(filterField) && f.name.toLowerCase().includes(filter)) ||
          ([FILTER_FIELD_ALL, FILTER_FIELD_URL].includes(filterField) && f.url.toLowerCase().includes(filter)) ||
          ([FILTER_FIELD_ALL, FILTER_FIELD_LABELS].includes(filterField) && f.labels.map(label => label.name).some(label => label.toLowerCase().includes(filter)))
        )
      })
    } else if (featuredSourceFeedIds.length > 0) {
      feeds = feeds.filter(f => featuredSourceFeedIds.includes(f.id))
    }
    return feeds
  }

  isFeedSelected(feedId) {
    const { selectedFirmFeedIds, selectedGlobalFeedIds } = this.props
    return selectedFirmFeedIds.includes(feedId) || selectedGlobalFeedIds.includes(feedId)
  }

  areAllFeedsSelected(feedType) {
    if (feedType === 'global') {
      return (
        this.props.globalFeeds.length > 0 &&
        this.props.globalFeeds.length === this.props.selectedGlobalFeedIds.length
      )
    } else {
      const filteredFirmFeeds = this.getFilteredFirmFeeds()
      return (
        filteredFirmFeeds.length > 0 &&
        filteredFirmFeeds.length === this.props.selectedFirmFeedIds.length
      )
    }
  }

  areAllTrustedFeedsSelected() {
    const { trustedSources, selectedTrustedFeedIds, trustedFolders, selectedTrustedFolderIds } = this.props
    if (trustedSources.length > 0 && trustedFolders.length > 0) {
      return (trustedSources.length > 0 &&
        trustedSources.length === selectedTrustedFeedIds.length &&
        trustedFolders.length > 0 && trustedFolders.length === selectedTrustedFolderIds.length)

    } else if (trustedSources.length > 0) {
      return (trustedSources.length > 0 &&
        trustedSources.length === selectedTrustedFeedIds.length)
    } else if (trustedFolders.length > 0) {
      return (trustedFolders.length > 0 && trustedFolders.length === selectedTrustedFolderIds.length)
    }
  }

  handleFeedCheckbox(feedType, feedId, isChecked) {
    let selectedFeedIds = feedType === 'global' ? [...this.props.selectedGlobalFeedIds] : [...this.props.selectedFirmFeedIds]
    if (isChecked) {
      if (selectedFeedIds.length < FEED_SELECTION_LIMIT) {
        selectedFeedIds.push(feedId)
      }
      else {
        this.setState({ limitSelection: true })
        setTimeout(() => {
          this.setState({ limitSelection: false })
        }, FEED_SELECTION_TIMEOUT);
      }
    } else {
      selectedFeedIds = selectedFeedIds.filter(id => id !== feedId)
    }
    if (feedType === 'global') {
      this.props.setSelectedGlobalFeedIds(selectedFeedIds)
    } else {
      this.props.setSelectedFirmFeedIds(selectedFeedIds)
    }
  }

  handleSelectAllCheckbox(feedType, isChecked) {
    if (feedType === 'global') {
      if (isChecked) {
        const feedIds = this.props.globalFeeds.map(f => f.id)
        this.props.setSelectedGlobalFeedIds(feedIds)
      } else {
        this.props.setSelectedGlobalFeedIds([])
      }
    } else {
      if (isChecked) {
        const filteredFirmFeeds = this.getFilteredFirmFeeds()
        const feedIds = filteredFirmFeeds.map(f => f.id)
        this.props.setSelectedFirmFeedIds(feedIds)
      } else {
        this.props.setSelectedFirmFeedIds([])
      }
    }
  }

  showLimitedSeatModal(feedId) {
    this.props.setLimitedSeatModalFeedId(feedId)
  }

  hideLimitedSeatModal() {
    this.props.setLimitedSeatModalFeedId(null)
  }

  submitLimitedSeatRequest() {
    this.props.submitLimitedSeatRequest({
      feedId: this.props.limitedSeatModalFeed.id,
      notes: this.props.limitedSeatModalNotes,
    })
  }

  deleteAllTrustedSources() {
    const { selectedTrustedFeedIds, selectedTrustedFolderIds } = this.props
    if (selectedTrustedFeedIds.length > 0 && selectedTrustedFolderIds.length > 0) {
      this.props.deleteTrustedFeedsAndFolders({ feedIds: selectedTrustedFeedIds, folderIds: selectedTrustedFolderIds })
    } else if (selectedTrustedFeedIds.length > 0) {
      this.props.deleteTrustedFeed({ id: selectedTrustedFeedIds })
    } else if (selectedTrustedFolderIds.length > 0) {
      this.props.deleteTrustedFolder({ id: selectedTrustedFolderIds })
    }
  }

  handlePromotedFeedCheckbox(feedId, isChecked) {
    let selectedFeedIds = [...this.props.selectedPromotedFeedIds]
    if (isChecked) {
      selectedFeedIds.push(feedId)
    } else {
      selectedFeedIds = selectedFeedIds.filter(id => id !== feedId)
    }
    this.props.setSelectedPromotedFeedIds(selectedFeedIds)
  }

  handleExcludedFeedCheckbox(feedId, isChecked) {
    let selectedFeedIds = [...this.props.selectedExcludedFeedIds]
    if (isChecked) {
      selectedFeedIds.push(feedId)
    } else {
      selectedFeedIds = selectedFeedIds.filter(id => id !== feedId)
    }
    this.props.setSelectedExcludedFeedIds(selectedFeedIds)
  }

  handleTrustedFeedCheckbox(feedId, isChecked) {
    let selectedFeedIds = [...this.props.selectedTrustedFeedIds]
    if (isChecked) {
      selectedFeedIds.push(feedId)
    } else {
      selectedFeedIds = selectedFeedIds.filter(id => id !== feedId)
    }
    this.props.setSelectedTrustedFeedIds(selectedFeedIds)
  }

  handleDemotedFeedCheckbox(feedId, isChecked) {
    let selectedFeedIds = [...this.props.selectedDemotedFeedIds]
    if (isChecked) {
      selectedFeedIds.push(feedId)
    } else {
      selectedFeedIds = selectedFeedIds.filter(id => id !== feedId)
    }
    this.props.setSelectedDemotedFeedIds(selectedFeedIds)
  }

  handleTrustedFolderCheckbox(folderId, isChecked) {
    let selectedFolderIds = [...this.props.selectedTrustedFolderIds]
    if (isChecked) {
      selectedFolderIds.push(folderId)
    } else {
      selectedFolderIds = selectedFolderIds.filter(id => id !== folderId)
    }
    this.props.setSelectedTrustedFolderIds(selectedFolderIds)
  }

  handleSelectAllPromotedCheckbox(isChecked) {
    if (isChecked) {
      const feedIds = this.props.promotedFeeds.map(f => f.id)
      this.props.setSelectedPromotedFeedIds(feedIds)
    } else {
      this.props.setSelectedPromotedFeedIds([])
    }
  }

  handleSelectAllExcludedCheckbox(isChecked) {
    if (isChecked) {
      const feedIds = this.props.excludedSources.map(f => f.id)
      this.props.setSelectedExcludedFeedIds(feedIds)
    } else {
      this.props.setSelectedExcludedFeedIds([])
    }
  }

  handleSelectAllDemotedCheckbox(isChecked) {
    if (isChecked) {
      const feedIds = this.props.demotedFeeds.map(f => f.id)
      this.props.setSelectedDemotedFeedIds(feedIds)
    } else {
      this.props.setSelectedDemotedFeedIds([])
    }
  }

  handleSelectAllTrustedCheckbox(isChecked) {
    if (isChecked) {
      const feedIds = this.props.trustedSources.map(f => f.id)
      const folderIds = this.props.trustedFolders.map(f => f.id)
      this.props.setSelectedTrustedFeedIds(feedIds)
      this.props.setSelectedTrustedFolderIds(folderIds)
    } else {
      this.props.setSelectedTrustedFeedIds([])
      this.props.setSelectedTrustedFolderIds([])
    }
  }

  checkNLAFeeds(id){
    return this.props.nlaLicense && this.props.nlaFeedIds.includes(id) ? true : false
  }
}
