import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Divider from 'app/common/Divider'
import PageSelector from 'app/common/PaginatedTable/PageSelector'
import Modal from 'app/common/Modal'
import Orm from 'app/framework/Orm'
import { Feed } from 'app/models'
import { useDispatch, useSelector } from 'react-redux'
import { getEntities } from 'app/entities/entities-selectors'
import { getSourcesAdmin } from '../sources-admin-selectors'
import { setReportModalVisibility, reportFeeds } from '../sources-admin-actions'
import { getCurrentFirm, getCurrentUser, getOriginalUserId } from 'app/global/global-selectors'
import ExpandableInput from 'app/common/ExpandableInput'
import Button from 'app/common/Button'
import Accordion from 'app/common/Accordion'
import lodash from 'lodash'
import './styles.less'

const REPORT_SOURCE_PAGE_SIZE = 3
function ReportModal() {
    const dispatch = useDispatch()

    const isVisible = useSelector(getSourcesAdmin).isReportModalVisible
    const entities = useSelector(getEntities)
    const orm = Orm.withEntities(entities)
    const feedIdsToReport = useSelector(getSourcesAdmin).feedIdsToReport
    const currentFirm = useSelector(getCurrentFirm)
    const currentUser = useSelector(getCurrentUser)
    const originalUserId = useSelector(getOriginalUserId)

    const [txtComment, setTxtComment] = useState('')
    const [reportModel, setReportModel] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    const totalPageCount = Math.ceil(reportModel.length / REPORT_SOURCE_PAGE_SIZE)
    const headerTitle = `Report Source${feedIdsToReport.length > 1 ? 's' : ''}`
    const wrongInputInComment = containsSpecialCharacter(txtComment)
    const canSendReport = reportModel.every((item) => item.articleURL) && !wrongInputInComment

    const closeReportModalVisibility = (visibility) => dispatch(setReportModalVisibility(visibility))
    const onArticleURLChange = (index, text) => {
        const _reportModel = [...reportModel]
        _reportModel[index].articleURL = String(text).trim()
        setReportModel(lodash.cloneDeep(_reportModel))
    }
    const closeModal = () => {
        closeReportModalVisibility(false)
    }
    const onReportClick = () => {
        // call API
        const payload = {
            userId: currentUser.id,
            firmId: currentFirm.id,
            feedIds: reportModel.map(i => i.id),
            feedUrls: reportModel.map(i => i.articleURL),
            reportedByUserId: originalUserId,
            reportComment: txtComment
        }
        dispatch(reportFeeds(payload))
        closeModal()
    }
    const onPageChange = (pageNo) => {
        setCurrentPage(pageNo)
    }
    function createData() {
        const selectedFeeds = feedIdsToReport && feedIdsToReport.length > 0 ? orm.getByIds(Feed, feedIdsToReport) : []
        const _reportModel = selectedFeeds.map((item, index) => {
            return {
                index,
                id: item.id,
                name: item.name,
                articleURL: '',
                date: item.updatedAt,
            }
        })
        setReportModel(_reportModel)
    }
    function getPaginatedData() {
        let start = 0, end = 0
        if (currentPage === 1) {
            start = 0
            end = REPORT_SOURCE_PAGE_SIZE
        }
        else if (currentPage === totalPageCount) {
            start = (currentPage - 1) * REPORT_SOURCE_PAGE_SIZE
            end = undefined
        }
        else {
            start = (currentPage - 1) * REPORT_SOURCE_PAGE_SIZE
            end = (currentPage * REPORT_SOURCE_PAGE_SIZE)
        }
        const data = reportModel.slice(start, end)

        return data
    }
    function containsSpecialCharacter(text) {
        const specialChars = "!@#$%^&*()-+[]{}";
        let contains = false
        for (let i = 0; i < specialChars.length; i++) {
            if (text.indexOf(specialChars[i]) > -1) {
                contains = true
                break
            }
        }
        return contains
    }
    function isValidUrl(url) {
        var urlPattern = /^(https?:\/\/)([\w\d-]+\.)+[\w\d]{2,}(\/.*)?$/i;
        return urlPattern.test(url);
    }
    function resetData() {
        setTxtComment('')
        setReportModel([])
    }

    useEffect(() => {
        if (isVisible) {
            createData()
        }
        else {
            resetData()
        }
    }, [isVisible])

    return (
        <>
            <Modal
                id="report-source-modal"
                innerClassName='modal-container'
                contentClassName='modal-content'
                closeIconClassName='modal-close-icon'
                isOpen={isVisible}
                onClose={closeModal}
            >
                <div className='header'>
                    <div className='title'>{headerTitle}</div>
                </div>
                <Divider className='divider' />
                <div className='content'>

                    <div className='help-section'>
                        <div className='icon' />
                        <div className='text'>
                            {"Check the "}
                            <a
                                target="_blank"
                                className='link'
                                href='https://client.manzama.com/help/source-management'>
                                Reporting a source
                            </a>
                            {" help article before you report a source."}
                        </div>
                    </div>

                    <div className='title'>Missing articles</div>
                    <div className='subtitle'>Include the URL of the missing article for each dormant source.</div>

                    {getPaginatedData().map((item) => {
                        return (
                            <div key={item.id} className='source-section'>
                                <div className='name'>{`Source: ${item.name}`}</div>
                                <input className='article-link-input'
                                    placeholder='http://'
                                    value={item.articleURL}
                                    onChange={(event) => onArticleURLChange(item.index, event.target.value)}
                                />
                            </div>
                        )
                    }
                    )}

                    <div className='pagination-section'>
                        <PageSelector
                            currentPage={currentPage}
                            pageCount={totalPageCount}
                            onPageChange={onPageChange}
                            makeActiveButtonNarrow
                            containerClassName='pagination-row'
                        />
                    </div>

                    <Accordion
                        title="Comments (optional)"
                        textClassName='subtitle'
                    >
                        <div className='comment-section'>
                            <div className='description'>Add comments about why you're reporting sources in 500 characters or less.
                                Do not include the following special characters: !@#$%^&*()-+[]{ }</div>
                            <ExpandableInput
                                inputClassName="comment-input"
                                counterClassName="description"
                                value={txtComment}
                                onTextChange={setTxtComment}
                                error={wrongInputInComment}
                                maxLimit={500} />
                        </div>
                    </Accordion>

                </div>
                <Divider className='divider' />
                <div className='footer'>
                    <Button
                        label="Report"
                        isDarkGrey
                        onClick={onReportClick}
                        disabled={!canSendReport}
                    />
                    <Button
                        label="Cancel"
                        onClick={closeModal}
                        isPrimary
                    />
                </div>
            </Modal>
        </>
    )
}

ReportModal.propTypes = {
}

export default ReportModal
