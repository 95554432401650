import React, {Component} from 'react'
import PropTypes from 'prop-types'
import './style.less'
import InlineSvg from "../../common/InlineSvg/InlineSvg"
import classNames from 'classnames'


export default class ItemsSortOrder extends Component {
  static propTypes = {
    itemIndex: PropTypes.number.isRequired,
    sortOrderFunc: PropTypes.func.isRequired,
    itemsArray: PropTypes.array.isRequired,
    noBorders: PropTypes.bool,
    updateOrderFunc: PropTypes.func,
    extraParams: PropTypes.object,
    applyGroupMembers: PropTypes.func,
  }
  static defaultProps = {
    noBorders: false,
    updateOrderFunc: null,
    extraParams: {},
  }
  render() {
    return (
      <div className='sort-order-controls'>
        <div
          className={classNames('control-icon right', {'no-borders': this.props.noBorders})}
          onClick={() => {
            this.onChangeOrderClick('down')
          }}>
          <InlineSvg
            className='move-down-icon'
            src="/media/img/DownArrow.svg"
          />
        </div>
        <div
          className={classNames('control-icon', {'no-borders': this.props.noBorders})}
          onClick={() => {
            this.onChangeOrderClick('up')
          }}>
          <InlineSvg
            className='move-up-icon'
            src="/media/img/UpArrow.svg"
          />
        </div>
      </div>
    )
  }
  changeOrder(toIndex) {
    const items = this.props.itemsArray.slice()
    const poppedItem = items.splice(this.props.itemIndex, 1)[0]
    items.splice(toIndex, 0, poppedItem)

    if (this.props.updateOrderFunc) {
      this.props.sortOrderFunc(items, this.props.updateOrderFunc, this.props.extraParams,
         this.props.applyGroupMembers)
    } else {
      this.props.sortOrderFunc(items)
    }

  }
  onChangeOrderClick(direction) {
    const itemsCount = this.props.itemsArray.length
    let toIndex
    if (direction === 'up') {
      toIndex = this.props.itemIndex-1
      if (toIndex < 0){toIndex=0}
    } else {
      toIndex = this.props.itemIndex+1
      if (toIndex > itemsCount) {toIndex=itemsCount}
    }
    this.changeOrder(toIndex)
  }
}
