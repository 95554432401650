import React from 'react'

import SavedSearchInput from 'app/common/SavedSearchInput'
import TokenInput from 'app/common/TokenInput'


export default function AdvancedFiltersContent({
  search,
  articleAnySelectedOptions,
  articleAllSelectedOptions,
  articleExcludeSelectedOptions,
  articleExactSelectedOptions,
  headlineAnySelectedOptions,
  headlineAllSelectedOptions,
  headlineExcludeSelectedOptions,
  headlineExactSelectedOptions,
  introAnySelectedOptions,
  introAllSelectedOptions,
  introExcludeSelectedOptions,
  introExactSelectedOptions,
  handleArticleAnySearchChange,
  handleArticleAllSearchChange,
  handleArticleExcludeSearchChange,
  handleArticleExactSearchChange,
  handleHeadlineAnySearchChange,
  handleHeadlineAllSearchChange,
  handleHeadlineExcludeSearchChange,
  handleHeadlineExactSearchChange,
  handleIntroAnySearchChange,
  handleIntroAllSearchChange,
  handleIntroExcludeSearchChange,
  handleIntroExactSearchChange,
}) {
  const searchWordDelimiters = ['Enter', 'Tab', ';', ',']
  //removing duplicates from filter
  const articleAnySelectedOptions_filtered = []
  articleAnySelectedOptions.forEach(item => {
    if (
      !articleAnySelectedOptions_filtered.some(ele => ele.label == item.label)
    ) {
      articleAnySelectedOptions_filtered.push(item)
    }
  })
  return (
    <div id="advanced-filters-tab">
      <div>
        <h3 className="advanced-filter-header">Article Contents</h3>

        <div className="advanced-filters-row">
          <div className="left">Any of (OR):</div>
          <div className="right">
            <SavedSearchInput
              selectedValues={articleAnySelectedOptions_filtered}
              omitIds={search ? [search.id] : []}
              onChange={values => handleArticleAnySearchChange(values)}
              options={{include_source_labels: true}}
            />
          </div>
        </div>

        <div className="advanced-filters-row">
          <div className="left">All of (AND):</div>

          <div className="right">
            <SavedSearchInput
              selectedValues={articleAllSelectedOptions}
              omitIds={search ? [search.id] : []}
              onChange={values => handleArticleAllSearchChange(values)}
              options={{include_source_labels: true}}
            />
          </div>
        </div>

        <div className="advanced-filters-row">
          <div className="left">Exclude (NOT):</div>

          <div className="right">
            <SavedSearchInput
              selectedValues={articleExcludeSelectedOptions}
              omitIds={search ? [search.id] : []}
              onChange={values => handleArticleExcludeSearchChange(values)}
              options={{include_source_labels: true}}
            />
          </div>
        </div>

        <div className="advanced-filters-row">
          <div className="left">Exact words or phrases:</div>

          <div className="right">
            <div className="input-container">
              <TokenInput
                tokenItems={articleExactSelectedOptions}
                delimiters={searchWordDelimiters}
                inputWidth="100%"
                updateTokensFunc={(token) => handleArticleExactSearchChange(token)}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <h3 className="advanced-filter-header">Headlines</h3>

        <div className="advanced-filters-row">
          <div className="left">Any of (OR):</div>

          <div className="right">
            <div className="input-container">
              <TokenInput
                tokenItems={headlineAnySelectedOptions}
                delimiters={searchWordDelimiters}
                inputWidth="100%"
                updateTokensFunc={(token) => handleHeadlineAnySearchChange(token)}
              />
            </div>
          </div>
        </div>

        <div className="advanced-filters-row">
          <div className="left">All of (AND):</div>

          <div className="right">
            <div className="input-container">
              <TokenInput
                tokenItems={headlineAllSelectedOptions}
                delimiters={searchWordDelimiters}
                inputWidth="100%"
                updateTokensFunc={(token) => handleHeadlineAllSearchChange(token)}
              />
            </div>
          </div>
        </div>

        <div className="advanced-filters-row">
          <div className="left">Exclude (NOT):</div>

          <div className="right">
            <div className="input-container">
              <TokenInput
                tokenItems={headlineExcludeSelectedOptions}
                delimiters={searchWordDelimiters}
                inputWidth="100%"
                updateTokensFunc={(token) => handleHeadlineExcludeSearchChange(token)}
              />
            </div>
          </div>
        </div>

        <div className="advanced-filters-row">
          <div className="left">Exact words or phrases:</div>

          <div className="right">
            <div className="input-container">
              <TokenInput
                tokenItems={headlineExactSelectedOptions}
                delimiters={searchWordDelimiters}
                inputWidth="100%"
                updateTokensFunc={(token) => handleHeadlineExactSearchChange(token)}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <h3 className="advanced-filter-header">Headlines + Leading Text</h3>

        <div className="advanced-filters-row">
          <div className="left">Any of (OR):</div>

          <div className="right">
            <div className="input-container">
              <TokenInput
                tokenItems={introAnySelectedOptions}
                delimiters={searchWordDelimiters}
                inputWidth="100%"
                updateTokensFunc={(token) => handleIntroAnySearchChange(token)}
              />
            </div>
          </div>
        </div>
        <div className="advanced-filters-row">
          <div className="left">All of (AND):</div>

          <div className="right">
            <div className="input-container">
              <TokenInput
                tokenItems={introAllSelectedOptions}
                delimiters={searchWordDelimiters}
                inputWidth="100%"
                updateTokensFunc={(token) => handleIntroAllSearchChange(token)}
              />
            </div>
          </div>
        </div>

        <div className="advanced-filters-row">
          <div className="left">Exclude (NOT):</div>

          <div className="right">
            <div className="input-container">
              <TokenInput
                tokenItems={introExcludeSelectedOptions}
                delimiters={searchWordDelimiters}
                inputWidth="100%"
                updateTokensFunc={(token) => handleIntroExcludeSearchChange(token)}
              />
            </div>
          </div>
        </div>

        <div className="advanced-filters-row">
          <div className="left">Exact words or phrases:</div>

          <div className="right">
            <div className="input-container">
              <TokenInput
                tokenItems={introExactSelectedOptions}
                delimiters={searchWordDelimiters}
                inputWidth="100%"
                updateTokensFunc={(token) => handleIntroExactSearchChange(token)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
