import React, { Component } from 'react'
import { connect } from 'react-redux'

import Modal from 'app/common/Modal'
import Orm from 'app/framework/Orm'
import { SavedSearch } from 'app/models'

import { hideEditSearchModal } from '../searches-admin-actions'
import { getSearchesAdmin } from '../searches-admin-selectors'
import Content from './Content'

import './style.less'


@connect(
  state => {
    const searchesAdmin = getSearchesAdmin(state)
    if (!searchesAdmin.shouldShowEditModal) return {isOpen: false}

    const orm = Orm.withEntities(state.entities)
    const modalData = searchesAdmin.editModalData
    return {
      isOpen: true,
      search: orm.getById(SavedSearch, modalData.searchId),
      isLoading: searchesAdmin.isLoading,
      isSearchFullyFetched: searchesAdmin.fullyFetchedSearchIds.includes(modalData.searchId),
    }
  },
  {
    hideEditSearchModal,
  },
)
export default class EditSearchModal extends Component {
  static TABS = Content.TABS

  render() {
    if (!this.props.isOpen || !this.props.isSearchFullyFetched) return null

    const {search, solrSearchField, groupingThreshold, isLoading, hideEditSearchModal} = this.props
    return (
      <Modal
        id="edit-search-overlay"
        isOpen={true}
        onClose={hideEditSearchModal}
        outsideExit={false}
      >
        <h1 className="search-name">Edit {`<${search.name}>`} Searches</h1>
        <Content
          search={search}
          solrSearchField={solrSearchField}
          groupingThreshold={groupingThreshold}
          closeModal={hideEditSearchModal}
          isLoading={isLoading}
        />
      </Modal>
    )
  }
}
