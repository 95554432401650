import React, { Component } from 'react'
import { connect } from 'react-redux'
import {createSelector} from 'reselect'

import Modal from 'app/common/Modal'
import * as globalSelectors from 'app/global/global-selectors'

import Content from './Content'
import { getDunAndBradstreet } from './dun-and-bradstreet-selectors'
import MoreInformation from './MoreInformation'
import Confirmation from './Confirmation'

import './style.less'


@connect(
  createSelector(
    [getDunAndBradstreet, globalSelectors.getHasDnbAccess],
    (dnbState, hasDnbAccess) => ({
      ...dnbState,
      hasDnbAccess,
    })
  ),
)
export default class DunAndBradstreetModal extends Component {
  render() {
    const {
      hasDnbAccess,
      moreInfoContact,
      displayConfirmation,
      searchName,
      onClose,
      ...restProps
    } = this.props

    const content = hasDnbAccess
      ? <Content {...restProps} />
      : <MoreInformation moreInfoContact={moreInfoContact} />

    const showModal = displayConfirmation
      ? <Confirmation searchName={searchName} />
      : <div>{ content }</div>

    return (
      <Modal id="dnb-overlay" onClose={onClose}>
        <div id="dun-and-bradstreet-modal-content">
          {showModal}
        </div>
      </Modal>
    )
  }
}
