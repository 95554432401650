import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import Button from 'app/common/Button'
import Dropdown from 'app/common/Dropdown'
import Option from 'app/common/Dropdown/Option'
import { getEntities } from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import { SavedSearch } from 'app/models'
import {
  showAssignModal,
  setAssignType,
  setShouldShowBulkAddFiltersModal,
  setFeatureModalData,
  setBulkEditLabelModalData,
  showSelectedColumns
} from './searches-admin-actions'
import { getSearchesAdmin } from './searches-admin-selectors'
import Tooltip from 'app/common/Tooltip'
import PopoverDropdown from "app/common/PopoverDropdown"
import { COLUMN_OPTIONS, MANAGE_COLUMNS, MAX_ROW_COUNT_FOR_LABELS } from './searches-admin-constants'

@connect(
  createSelector(
    [getEntities, getSearchesAdmin],
    (entities, searchesAdmin) => {
      const orm = Orm.withEntities(entities)
      return {
        selectedSearchIds: searchesAdmin.selectedSearchIds,
        selectedSearches: orm.getByIds(SavedSearch, searchesAdmin.selectedSearchIds),
        totalCount: searchesAdmin.totalCount,
        visibleSearchIds: searchesAdmin.searchIds || [],
        selectedColumns: searchesAdmin.selectedColumns,
      }
    }
  ),
  {
    showAssignModal,
    setAssignType,
    setShouldShowBulkAddFiltersModal,
    setFeatureModalData,
    setBulkEditLabelModalData,
    showSelectedColumns
  }
)
export default class SearchesAdminBulkActions extends Component {
  static propTypes = {
    // Fired off when the corresponding button is clicked
    onShare: PropTypes.func,
    onExport: PropTypes.func,
    onDelete: PropTypes.func,
    onChangeSelectAll: PropTypes.func,
  }

  static defaultProps = {
    onShare() { },
    onExport() { },
    onDelete() { },
  }

  render() {
    const selectAllShouldBeChecked = (R.intersection(this.props.selectedSearchIds, this.props.visibleSearchIds).length
      === this.props.visibleSearchIds.length)

    const areAnySelected = this.props.selectedSearchIds.length > 0
    return (
      <div className="bulk-actions">
        <div className="checkbox-dropdown">
          <Dropdown onChange={(value) => this.props.onChangeSelectAll(value)} value={''}>
            <Option
              value=''
              isInvisible={true}
              label={
                <input
                  className="select-all"
                  type="checkbox"
                  onClick={e => e.preventDefault()}
                  onChange={e => e.preventDefault()}
                  checked={selectAllShouldBeChecked}
                />
              }
            />
            <Option label={`Select All (${this.props.totalCount})`} value="all" />
            <Option label={`Select Visible (${this.props.visibleSearchIds.length})`} value="visible" />
            <Option label="Deselect All" value="none" />
          </Dropdown>
        </div>

        <Button
          label="Add to..."
          disabled={!areAnySelected}
          onClick={() => this.showAssignModal('add')}
        />

        <Button
          label="Copy to..."
          disabled={!areAnySelected}
          onClick={() => this.showAssignModal('copy')}
        />

        <Button
          label="Feature..."
          disabled={!areAnySelected}
          onClick={() => this.props.setFeatureModalData({ isOpen: true })}
        />

        <Tooltip
          label="Labeling multiple searches at a time is limited to 50 searches"
          disabled={this.props.selectedSearchIds && this.props.selectedSearchIds.length <= MAX_ROW_COUNT_FOR_LABELS ? true : false  }
        >
          <Button
            label="Label..."
            disabled={this.props.selectedSearchIds.length > 0 && this.props.selectedSearchIds.length <= MAX_ROW_COUNT_FOR_LABELS ? false : true}
            onClick={() => this.props.setBulkEditLabelModalData(true)}
          />
        </Tooltip>

        <Button
          label="Add Filters..."
          disabled={!areAnySelected}
          onClick={() => this.props.setShouldShowBulkAddFiltersModal(true)}
        />

        <Button
          label="Export"
          disabled={!areAnySelected}
          onClick={this.props.onExport}
        />

        <Button
          label="Delete"
          isDestructive
          disabled={!areAnySelected}
          onClick={this.props.onDelete}
        />

        <PopoverDropdown
          label="Add Columns"
          options={COLUMN_OPTIONS}
          multiSelection={true}
          enableFilter={true}
          enableTooltip={true}
          showSubtitle={false}
          placeholder="Search"
          containerClassName='manage-columns'
          selectedOptions={this.props.selectedColumns}
          onSelection={(items) => this.props.showSelectedColumns(items)}>
          <div>{MANAGE_COLUMNS} <img src="/media/img/ManageColumnsIcon.svg" /></div>
        </PopoverDropdown>
      </div>
    )
  }

  showAssignModal(type) {
    this.props.setAssignType(type)
    this.props.showAssignModal(this.props.selectedSearchIds)
  }
}
