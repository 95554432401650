import React, { Component } from 'react'
import { connect } from 'react-redux'
import { startOfDay, subMonths } from 'date-fns'
import { createSelector } from 'reselect'
import * as dateFns from 'date-fns'
import _ from 'lodash'

import { DATE_FORMAT } from 'app/constants'
import { getEntities } from 'app/entities/entities-selectors'
import { getCurrentUserReceiveMonthlyUsageReport } from 'app/global/global-selectors'
import Orm from 'app/framework/Orm'
import { User, SavedSearch } from 'app/models'
import DateRangePicker from 'app/common/DateRangePicker'
import InputBlock from 'app/common/InputBlock'
import Button from 'app/common/Button'
import LoadingOverlay from 'app/common/LoadingOverlay'
import Modal from 'app/common/Modal'
import Dropdown from 'app/common/Dropdown'

import { getUsage } from './usage-selectors'
import * as actions from './usage-actions'
import Card from './Card'

import './Usage.less'
import ESGSearch from 'app/esg/Pages/Company/ESGSearch'
import PaginatedTable from 'app/common/PaginatedTable'
import Table from 'app/common/Table'
import { INDIVIDUAL_STATISTICS_LIST } from './usage-constants'

const EXPORT_NOW = 'now'
const EXPORT_SCHEDULED = 'scheduled'


@connect(
  // Map state to props
  createSelector(
    [getEntities, getUsage, getCurrentUserReceiveMonthlyUsageReport],
    (entities, usage, currentUserReceiveMonthlyUsageReport) => {
      const orm = Orm.withEntities(entities)
      return {
        isLoading: usage.isLoading,
        isEmailModalOpen: usage.isEmailModalOpen,
        defaultEmailAddress: usage.defaultEmailAddress,
        metrics: usage.metrics,
        topFeeds: usage.topFeeds,
        topArticles: usage.topArticles,
        topPublishers: orm.getByIds(User, usage.topPublisherIds),
        recentSearches: orm.getByIds(SavedSearch, usage.recentSearchIds),
        recentRefreshedProfileUsers: orm.getByIds(User, usage.recentRefreshedProfileUserIds),
        currentUserReceiveMonthlyUsageReport: usage.currentUserReceiveMonthlyUsageReport === null ?
          currentUserReceiveMonthlyUsageReport : usage.currentUserReceiveMonthlyUsageReport,
        isIndividualStatisticsModalOpen: usage.isIndividualStatisticsModalOpen,
        usersDropDownList: usage.usersDropDownList,
        individualStatsData: usage.individualStatsData,
        individualStatsUser: usage.individualStatsUser,
      }
    }
  ),

  // Map dispatch to props
  {
    fetchUsageData: actions.fetchUsageData,
    showEmailModal: actions.showEmailModal,
    hideEmailModal: actions.hideEmailModal,
    showIndividualStatisticsModal: actions.showIndividualStatisticsModal,
    hideIndividualStatisticsModal: actions.hideIndividualStatisticsModal,
    setIndividualStatsData: actions.setIndividualStatsData,
    setIndividualStatsUser: actions.setIndividualStatsUser,
    sendEmail: actions.sendEmail,
    signupForMonthlyReport: actions.signupForMonthlyReport,
    fetchUsersDropDownList: actions.fetchUsersDropDownList,
    getIndividualStatsData: actions.getIndividualStatsData,
  },
)
export default class Usage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dateRange: {
        start: subMonths(new Date(), 1),
        end: startOfDay(new Date()),
      },
      individualStatsDateRange: {
        start: subMonths(new Date(), 1),
        end: startOfDay(new Date()),
      },
      recipientAddresses: this.props.defaultEmailAddress,
      searchValue: '',
      userId: null
    }
    this.updatesearchValue = this.updatesearchValue.bind(this)
  }

  render() {
    console.log('this.props',this.props)
    const loader = this.props.isLoading
      ? <LoadingOverlay />
      : null

    const cardData = [
      {
        value: this.getCountByType('total_users')
          ? this.percentFromDecimal(this.getCountByType('active_users') / this.getCountByType('total_users'))
          : '--',
        numerator: {
          name: 'Active Users',
          value: this.getCountByType('active_users'),
          description: 'are users who have at least one item in their profile or are a member of at least one ' +
            'group in addition to the firm wide group.',
        },
        denominator: {
          name: 'Total Users',
          value: this.getCountByType('total_users'),
          description: 'are all the users in your firm.',
        },
        subText: 'of users are',
        mainText: 'Active',
      },
      {
        value: this.getCountByType('active_users')
          ? this.percentFromDecimal(this.getCountByType('unique_interactions') / this.getCountByType('active_users'))
          : '--',
        numerator: {
          name: 'User Interactions',
          value: this.getCountByType('unique_interactions'),
          description: 'are the number of distinct users who interacted with the site or emails (logged in, clicked, ' +
            'flagged, etc.) within the date range.',
        },
        denominator: {
          name: 'Active Users',
          value: this.getCountByType('active_users'),
          description: 'are users who have at least one item in their profile or are a member of at least one ' +
            'group in addition to the firm wide group.',
        },
        subText: 'of active users',
        mainText: 'Interacted',
      },
      {
        value: this.getCountByType('active_users')
          ? this.percentFromDecimal(this.getCountByType('users_getting_email') / this.getCountByType('active_users'))
          : '--',
        numerator: {
          name: 'Getting Email',
          value: this.getCountByType('users_getting_email'),
          description: 'is the number of distinct users who received a group or individual alert.',
        },
        denominator: {
          name: 'Active Users',
          value: this.getCountByType('active_users'),
          description: 'are users who have at least one item in their profile or are a member of at least one ' +
            'group in addition to the firm wide group.',
        },
        subText: 'of active users',
        mainText: 'Received Email',
      },
      {
        value: this.getCountByType('users_getting_email')
          ? this.percentFromDecimal(this.getCountByType('user_email_clicks') / this.getCountByType('users_getting_email'))
          : '--',
        numerator: {
          name: 'Email Clicks',
          value: this.getCountByType('user_email_clicks'),
          description: 'are users who clicked through at least one article in their emails for the date range. Note, ' +
            'this also includes clicking on articles shared from within the system in addition to the daily emails.',
        },
        denominator: {
          name: 'Getting Email',
          value: this.getCountByType('users_getting_email'),
          description: 'is the number of distinct users who received a group or individual alert.',
        },
        subText: 'of users receiving email',
        mainText: 'Click Through',
      },
      {
        value: this.getCountByType('active_users')
          ? this.percentFromDecimal(this.getCountByType('user_logins') / this.getCountByType('active_users'))
          : '--',
        numerator: {
          name: 'Logged In',
          value: this.getCountByType('user_logins'),
          description: 'is the total number of unique users who logged in during the date range.',
        },
        denominator: {
          name: 'Active Users',
          value: this.getCountByType('active_users'),
          description: 'are users who have at least one item in their profile or are a member of at least one ' +
            'group in addition to the firm wide group.',
        },
        subText: 'of active users',
        mainText: 'Logged In',
      },
      {
        value: this.getCountByType('active_users')
          ? this.percentFromDecimal(this.getCountByType('site_interactions') / this.getCountByType('active_users'))
          : '--',
        numerator: {
          name: 'Site Interactions',
          value: this.getCountByType('site_interactions'),
          description: 'is the total number of unique users who interacted with the content on the site (clicked, ' +
            'flagged, exported, etc.).',
        },
        denominator: {
          name: 'Active Users',
          value: this.getCountByType('active_users'),
          description: 'are users who have at least one item in their profile or are a member of at least one ' +
            'group in addition to the firm wide group.',
        },
        subText: 'of active users',
        mainText: 'Interacted on the Site',
      },
      {
        value: this.getCountByType('publication_count'),
        subText: 'published',
        mainText: 'Reports',
        backTitle: 'Top Publishers',
        backValues: this.props.topPublishers.map(u => `${u.displayName} (${u.publishedReportCount})`),
      },
      {
        value: this.getCountByType('created_search_count'),
        subText: 'newly created',
        mainText: 'Saved Searches',
        backTitle: 'Most Recently Created Searches',
        backValues: this.props.recentSearches.map(ss => `${ss.name} (${ss.owner.displayName})`),
      },
      {
        value: this.getCountByType('profile_refresh_count'),
        subText: 'profiles',
        mainText: 'Refreshed',
        backTitle: 'Most Recently Refreshed Profiles',
        backValues: this.props.recentRefreshedProfileUsers.map(u => `${u.displayName} ${dateFns.format(u.lastRefreshAt, DATE_FORMAT)}`),
      },
    ]

    const emailModal =
      <Modal
        isOpen={this.props.isEmailModalOpen}
        onClose={() => this.props.hideEmailModal()}
        className="usage-email-modal"
      >
        <h2>Email Individual Usage Data</h2>

        <InputBlock label="Recipients:">
          <textarea
            value={this.state.recipientAddresses}
            onChange={(evt) => this.handleRecipientEmailChange(evt.target.value)}
          />
        </InputBlock>

        <p className="input-help">Separate multiple addresses with a comma.</p>

        <div className="modal-buttons">
          <Button label="Send" onClick={() => this.sendEmail()} />
          <Button label="Cancel" isPlainText onClick={() => this.props.hideEmailModal()} />
        </div>
      </Modal>

    const exportDataLabel =
      <span className="export-options-label">
        <i className="fa icon-off fa-download fa-lg" />
        Export Usage Reports...
      </span>

    const signupLabel = this.props.currentUserReceiveMonthlyUsageReport
      ? 'Cancel Monthly Usage Reports'
      : 'Signup for Monthly Usage Reports'

    const onModalClose = ()=> { 
      this.props.hideIndividualStatisticsModal()
      this.props.setIndividualStatsData(INDIVIDUAL_STATISTICS_LIST)
      this.props.setIndividualStatsUser('')
      this.setUser(null)
      this.setState({individualStatsDateRange:{start: subMonths(new Date(), 1) ,end: startOfDay(new Date())}})
    }

    const doNotSearch = !this.state.userId || !this.state.searchValue || (this.props.usersDropDownList?.length == 1 && this.props.usersDropDownList[0].value == -1)
    const debouncedUpdateSearchValue = _.debounce(this.updatesearchValue, 500)

    const individualStatisticsModal =
      <Modal
        isOpen={this.props.isIndividualStatisticsModalOpen}
        onClose={onModalClose}
        className="usage-individual-modal"
      >
        <h2>Individual User/Group Statistics Data</h2>
        <div className='statistics-box'>
        <div className='user-name-container'>
          <p className='user-name'>User Name <span className='required'>*</span></p>
          <ESGSearch
              onChange={(id) => this.setUser(id)}
              updatesearchValue={debouncedUpdateSearchValue}
              options={this.props.usersDropDownList}
              className="dropdown"
          />
        </div>
        <div className='date-range-container'>
            <InputBlock label="Date Range">
              <DateRangePicker
                value={{
                  start: this.state.individualStatsDateRange.start,
                  end: this.state.individualStatsDateRange.end,
                }}
                monthsBack={12}
                onChange={(dateRange) => this.handleIndividualStatsDateRangeChange(dateRange)}
                className="date-range-picker"
              />
            </InputBlock>
            <Button label="Update" disabled={doNotSearch}  onClick={() => this.handleIndividualStatsData()} />
        </div>

        </div>

        <div style={{fontWeight:'bold'}}>{this.props.individualStatsUser ? `Usage Statistics for ${this.props.individualStatsUser.toUpperCase()}` : ''}</div>
        <PaginatedTable
            id="individual-statistics-table"
            data={this.props.individualStatsData}
            pageCount={1}
            currentPage={1}
            tab="Sources"
        >

        <Table.Column
          name={"Statistics"}
          label="Statistics"
          baseWidth={150}
          growRatio={2}
          shrinkRatio={0}
          cellContents={label => label.field}
        />

        <Table.Column
          name={"Value"}
          label="Value"
          baseWidth={100}
          growRatio={2}
          shrinkRatio={0}
          cellContents={label => label.value}
        />
        </PaginatedTable>
        
      </Modal>

    return (
      <div className="firm-usage">
        {loader}

        {emailModal}

        {individualStatisticsModal}

        <div className="usage-header">
          <h4>Firm Wide Usage</h4>


        </div>

        <div>
          <div className="usage-header">
            <div>
              <InputBlock label="Date Range">
                <DateRangePicker
                  value={{
                    start: this.state.dateRange.start,
                    end: this.state.dateRange.end,
                  }}
                  monthsBack={12}
                  onChange={(dateRange) => this.handleDateRangeChange(dateRange)}
                  className="date-range-picker"
                />
              </InputBlock>

              <Button label="Update" onClick={() => this.fetchUsageData()} />
            </div>
            <div>
              <Button label="Individual User/Group Statistics" 
                onClick={() => this.props.showIndividualStatisticsModal()}>
              </Button>
            </div>
            <Dropdown
              options={[
                { label: exportDataLabel, value: null },
                { label: 'Email Usage Report', value: EXPORT_NOW },
                { label: signupLabel, value: EXPORT_SCHEDULED },
              ]}
              value={null}
              onChange={value => this.handleExportOptionChange(value)}
              className="export-options"
            />
          </div>

          <div className="metrics">
            {
              cardData.map((c, idx) => {
                return (
                  <Card
                    key={idx}
                    value={c.value}
                    numerator={c.numerator || null}
                    denominator={c.denominator || null}
                    subText={c.subText}
                    mainText={c.mainText}
                    backTitle={c.backTitle}
                    backValues={c.backValues}
                  />
                )
              })
            }
          </div>

          <div className="usage-top-lists">
            {
              this.props.topFeeds.length > 0 &&
              <div className="usage-top-feeds">
                <h4>Top Viewed Feeds</h4>
                {
                  this.props.topFeeds.map(f => {
                    return (
                      <div key={f.id} className="usage-top-feed">{f.label}</div>
                    )
                  })
                }
              </div>
            }

            {
              this.props.topArticles.length > 0 &&
              <div className="usage-top-articles">
                <h4>Top Viewed Articles</h4>
                {
                  this.props.topArticles.map(a => {
                    return (
                      <div key={a.id} className="usage-top-article">{a.label}</div>
                    )
                  })
                }
              </div>
            }
          </div>
        </div>
      </div>
    )
  }

  getCountByType(type) {
    const u = this.props.metrics.find(u => u.type === type)
    return u ? u.count : 0
  }

  percentFromDecimal(d) {
    return `${Math.round(d * 1000) / 10}%`
  }

  handleDateRangeChange(dateRange) {
    this.setState({ dateRange })
  }

  fetchUsageData() {
    this.props.fetchUsageData(this.state.dateRange)
  }

  handleExportOptionChange(value) {
    if (value === EXPORT_NOW) {
      this.props.showEmailModal()
    } else if (value === EXPORT_SCHEDULED) {
      this.props.signupForMonthlyReport(!this.props.currentUserReceiveMonthlyUsageReport)
    }
  }

  handleRecipientEmailChange(recipientAddresses) {
    this.setState({ recipientAddresses })
  }

  sendEmail() {
    this.props.sendEmail({ ...this.state.dateRange, emailAddresses: this.state.recipientAddresses, reportType: 'users'})
  }

  handleIndividualStatsDateRangeChange(individualStatsDateRange){
    this.setState({ individualStatsDateRange })
  }

  handleIndividualStatsData() {
    console.log('this.state.searchValue', this.state.searchValue)
    this.props.getIndividualStatsData({ ...this.state.individualStatsDateRange, id: this.state.userId })
  }

  setUser(id) {
    this.setState({ ...this.state, userId: id })
    console.log('user',this.state.userId)
  }

  updatesearchValue (search) {
    const searchValue = search
    this.setState({ ...this.state,searchValue:searchValue })
    this.props.fetchUsersDropDownList(searchValue)
  }

}
