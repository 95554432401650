import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Modal from 'app/common/Modal'


export default class Tour extends Component {
  static propTypes = {
    isDiligent: PropTypes.bool,
    appNameFull: PropTypes.string.isRequired,
    siteUrl: PropTypes.string.isRequired,
  }

  state = {
    isModalOpen: false,
  }

  render() {
    const { isDiligent, appNameFull } = this.props
    const siteUrl = `https:${this.props.siteUrl}`
    const tourOf =
      isDiligent ?
        <span>{appNameFull}, powered by Diligent Manzama</span>
        : <span>{appNameFull}</span>

    const videoScreenShot = isDiligent
      ? "/media/img/profile_builder/video_screen_shot_dgi.png"
      : "/media/img/profile_builder/video_screen_shot.png"

    const videoSource = isDiligent
      ? "https://fast.wistia.com/embed/medias/w3jccfsi3e/swatch"
      : "https://fast.wistia.com/embed/medias/wdramsls0p/swatch"

    const wistiaClassNames = isDiligent
      ? "wistia_embed wistia_async_w3jccfsi3e videoFoam=true"
      : "wistia_embed wistia_async_wdramsls0p videoFoam=true"

    const modal =
      <Modal
        isOpen={this.state.isModalOpen}
        onClose={() => this.hideModal()}
      >
        <h1>Overview of {appNameFull}</h1>

        <div className="wistia_responsive_padding" style={{padding:'56.25% 0 0 0',position:'relative'}}>
          <div className="wistia_responsive_wrapper" style={{height:'100%',left:0,position:'absolute',top:0,width:'100%'}}>
            <div className={wistiaClassNames}
                 style={{height:'100%',position:'relative',width:'100%'}}>
              <div className="wistia_swatch"
                   style={{height:'100%',left:0,opacity:0,overflow:'hidden',position:'absolute',top:0,transition:'opacity 200ms',width:'100%'}}>
                <img src={videoSource}
                     style={{filter:'blur(5px)',height:'100%',objectFit:'contain',width:'100%'}} alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>

    return (
      <div className="builder-content-body-container">
        {modal}

        <div className="builder-content-body tour">
          {
            isDiligent ?
              <div className="tour-header">
                <h3>Quick Tour</h3>
                <h3>{tourOf}</h3>
              </div>
              : <div className="tour-header">
                  <h3>{appNameFull} Quick Tour</h3>
                </div>
          }

          <p>In the future, you will log into {appNameFull} by going to <a href={siteUrl}>{siteUrl}</a> and using your email address
            and password. Click below to watch a quick 5 minute video tour of {tourOf}.</p>

          <div className="builder-content-row">
            <img
              src={videoScreenShot}
              className="tour-video-image"
              onClick={() => this.showModal()}
            />
          </div>
        </div>
      </div>
    )
  }

  showModal() {
    this.setState({isModalOpen: true})
  }

  hideModal() {
    this.setState({isModalOpen: false})
  }
}
