import React, { Component } from 'react'
import { connect } from 'react-redux'

import Orm from 'app/framework/Orm'
import { User } from 'app/models'
import { UserMemberships } from './UserMemberships'
import { GroupMemberships } from './GroupMemberships'

import { getUsersAdmin } from '../users-admin-selectors'
import * as usersAdminActions from '../users-admin-actions'

import './MembershipContent.less'


@connect(
  state => {
    const orm = Orm.withEntities(state.entities)
    const usersAdmin = getUsersAdmin(state)

    return {
      isGroupPage: usersAdmin.isGroupPage,
      user: orm.getById(User, usersAdmin.userModalData.userId),
      isUserFetched: usersAdmin.isUserFetched,
    }
  },
  {
    ...usersAdminActions,
  },
)
export default class MembershipContent extends Component {
  componentDidMount() {
    if (!this.props.isUserFetched) {
      this.props.fetchUser(this.props.user.id)
    }
  }

  render() {
    if (!this.props.isUserFetched) {
      return null
    }

    const firmGroupMessage =
      this.props.user.isFirmGroup ?
        <h4>This is the firm wide group, it's an automatically managed group that contains all users within the firm</h4>
        : null

    const content =
      this.props.user.isFirmGroup ?
        null
        : this.props.user.role === 'group' ?
          <UserMemberships/>
          : <GroupMemberships/>

    return (
      <div
        id="user-memberships-content"
        className="users-admin-style"
      >
        {firmGroupMessage}

        {content}
      </div>
    )
  }
}
