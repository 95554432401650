import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import Orm from 'app/framework/Orm'
import {SavedSearch} from 'app/models'
import {getEntities} from 'app/entities/entities-selectors'
import {getCurrentFirmLibraryName, getAvailableFilters} from 'app/global/global-selectors'
import SavedSearchFilters from 'app/reusable/SavedSearchFilters'
import Modal from 'app/common/Modal'
import ConfirmationModal from 'app/common/modals/ConfirmationModal'
import InputBlock from 'app/common/InputBlock'
import Button from 'app/common/Button'

import {
  setQueryComponentState,
  setSaveData,
  setShouldShowBulkAddFiltersModal,
  applyFiltersToSearches,
  fetchFilters,
} from '../searches-admin-actions'
import {getSearchesAdmin} from '../searches-admin-selectors'

import * as styles from './BulkAddFiltersModal.less'


@connect(
  createSelector(
    [getEntities, getCurrentFirmLibraryName, getAvailableFilters, getSearchesAdmin],
    (entities, currentFirmLibraryName, availableFiltersByCategory, searchesAdmin) => {
      const orm = Orm.withEntities(entities)
      return {
        currentFirmLibraryName,
        searchResults: searchesAdmin.searchResults,
        isLoading: searchesAdmin.isLoading,
        queryComponentState: searchesAdmin.queryComponentState,
        saveData: searchesAdmin.saveData,
        shouldShowBulkAddFiltersModal: searchesAdmin.shouldShowBulkAddFiltersModal,
        selectedSearches: orm.getByIds(SavedSearch, searchesAdmin.selectedSearchIds),
        availableFiltersByCategory: availableFiltersByCategory,
        firmLibrarySearches: Object.values(entities.searches).filter(search => search.isFirmLibrary),
      }
    }
  ),
  {
    setQueryComponentState,
    setSaveData,
    setShouldShowBulkAddFiltersModal,
    applyFiltersToSearches,
    fetchFilters,
  },
)
export default class BulkAddFiltersModal extends Component {
  state = {
    shouldRemoveExistingFilters: false,
    shouldShowConfirmationModal: false,
  }

  componentDidMount() {
    if (!this.props.availableFiltersByCategory) {
      this.props.fetchFilters();
    }
  }

  render() {
    if (!this.props.availableFiltersByCategory) {
      return null
    }

    const withFiltersCount = this.props.selectedSearches.filter(ss => ss.hasFilters).length

    const willRemoveFilters = this.state.shouldRemoveExistingFilters && withFiltersCount > 0

    const confirmationMessage =
      <span>Are you sure you want to {willRemoveFilters ? 'update the filters on' : 'add these filters to'} the {this.props.selectedSearches.length === 1 ? 'selected search': `${this.props.selectedSearches.length} selected searches`}?</span>

    const confirmationSecondaryMessage =
      willRemoveFilters &&
      <div>{this.props.selectedSearches.length === 1 ? 'This search already has' : 'Some of these searches already have'} filters
        applied. If you proceed, those filters will be removed before the new ones are applied.</div>

    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.shouldShowBulkAddFiltersModal}
          onClose={() => this.closeModal()}
        >
          <h1>Add Filters to the Selected Searches</h1>

          <SavedSearchFilters
            currentFirmLibraryName={this.props.currentFirmLibraryName}
            saveData={this.props.saveData}
            setSaveData={this.props.setSaveData}
            searchResults={this.props.searchResults}
            isLoading={this.props.isLoading}
            queryComponentState={this.props.queryComponentState}
            setQueryComponentState={this.props.setQueryComponentState}
            onSave={this.props.updateSearch}
            isMulti={true}
          />

          <div className={styles.buttons}>
            <InputBlock
              label="Remove Existing Filters"
              isInline
            >
              <input
                type="checkbox"
                checked={this.state.shouldRemoveExistingFilters}
                onChange={event => this.handleShouldRemoveExistingFiltersCheckbox(event.target.checked)}
              />
            </InputBlock>

            <Button
              label={`${this.state.shouldRemoveExistingFilters ? 'Update' : 'Add'} Filters`}
              isDestructive={this.state.shouldRemoveExistingFilters}
              disabled={!this.props.saveData.filters || this.props.saveData.filters.length === 0}
              onClick={() => this.handleSaveButton()}
            />
          </div>
        </Modal>

        {
          this.state.shouldShowConfirmationModal &&
          <ConfirmationModal
            message={confirmationMessage}
            secondaryMessage={confirmationSecondaryMessage}
            confirmButtonText={`${this.state.shouldRemoveExistingFilters ? 'Update' : 'Add'} Filters`}
            isDestructive={this.state.shouldRemoveExistingFilters}
            onConfirm={() => this.handleSaveConfirmButton()}
            onClose={() => this.setState({shouldShowConfirmationModal: false})}
          />
        }
      </React.Fragment>
    )
  }

  closeModal() {
    this.setState({shouldRemoveExistingFilters: false})
    this.props.setShouldShowBulkAddFiltersModal(false)
  }

  handleShouldRemoveExistingFiltersCheckbox(checked) {
    this.setState({shouldRemoveExistingFilters: checked})
  }

  handleSaveButton() {
    this.setState({shouldShowConfirmationModal: true})
  }

  handleSaveConfirmButton() {
    this.setState({shouldShowConfirmationModal: false})
    this.props.applyFiltersToSearches({shouldRemoveExistingFilters: this.state.shouldRemoveExistingFilters})
  }
}
