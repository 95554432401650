import formatDate from 'date-fns/format'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {Feed} from 'app/models'
import Orm from 'app/framework/Orm'
import {getEntities} from 'app/entities/entities-selectors'
import {getCurrentFirm, getCurrentFirmEnableSourceLastContentDisplay} from 'app/global/global-selectors'
import TextBox from 'app/common/TextBox'
import Button from 'app/common/Button'

import {DATE_FORMAT} from '../../../constants'
import {setEditModalDataDetails, saveFeed} from '../sources-admin-actions'
import {getSourcesAdmin} from '../sources-admin-selectors'


@connect(
  createSelector([
    getEntities,
    getCurrentFirm,
    getCurrentFirmEnableSourceLastContentDisplay,
    getSourcesAdmin,
  ], (
    entities,
    currentFirm,
    currentFirmEnableSourceLastContentDisplay,
    sourcesAdmin,
  ) => {
    const orm = Orm.withEntities(entities)
    return {
      editModalData: sourcesAdmin.editModalData,
      feed: orm.getById(Feed, sourcesAdmin.editModalData.feedId),
      currentFirm,
      currentFirmEnableSourceLastContentDisplay,
    }
  }),
  {
    setEditModalDataDetails,
    saveFeed,
  },
)
export default class DetailsContent extends Component {
  render() {
    const {feed, currentFirm, currentFirmEnableSourceLastContentDisplay, editModalData} = this.props
    const {orgType} = currentFirm

    // the three options are mutually exclusive; only one or none can be selected.
    const isExcluded = typeof editModalData.isExcluded === 'boolean' ? editModalData.isExcluded : feed.isExcluded
    const isLimitedSeat = (
      isExcluded
        ? false
        : typeof editModalData.isLimitedSeat === 'boolean' ? editModalData.isLimitedSeat : feed.isLimitedSeat
    )
    const isPromoted = (
      isExcluded || isLimitedSeat || isDemoted
        ? false
        : typeof editModalData.isPromoted === 'boolean' ? editModalData.isPromoted : feed.isPromoted
    )

    const isDemoted = (
      isExcluded || isLimitedSeat || isPromoted
        ? false
        : typeof editModalData.isDemoted === 'boolean' ? editModalData.isDemoted : feed.isDemoted
    )

    const feedUrl =
      (feed.sourceType === 'rss' || feed.sourceType === 'twitter') &&
      <div>
        <label>
          <span className="label">Source URL</span>
          {this.renderSourceURL()}
        </label>
      </div>


    const sourceNotes = <div>
      <label>
        <span className="label">Source Notes</span>
        <TextBox
          defaultValue={feed.sourceNotes}
          value={this.props.editModalData.sourceNotes}
          onChange={(event) => this.props.setEditModalDataDetails({sourceNotes: event.target.value})}
        />
      </label>
    </div>

    return (
      <div
        className="details-content"
      >
        <h2 className="content-header">Source Details</h2>

        <div className="columns">
          <div className="column">
            <div>
              <label>
                <span className="label">Source Name</span>
                {this.renderSourceName()}
              </label>
            </div>

            {feedUrl}
            {sourceNotes}

            <div>
              <label className="half-width">
                <span className="label">Source Type</span>
                <span className="value">{this.renderSourceType()}</span>
              </label>

              {
                feed.isProprietary &&
                <label className="half-width">
                  <span className="label">Source Language</span>
                  <span className="value">{feed.language ? feed.language.name : '--'}</span>
                </label>
              }
            </div>

            {
              feed.isProprietary &&
              <div>
                <label className="half-width">
                  <span className="label">Created By</span>
                  <span className="value">{feed.createdBy.displayName}</span>
                </label>

                <label className="half-width">
                  <span className="label">Created On</span>
                  <span className="value">{formatDate(feed.createdAt, DATE_FORMAT)}</span>
                </label>
              </div>
            }

            {
              feed.isProprietary && currentFirmEnableSourceLastContentDisplay &&
              <div>
                <label>
                  <span className="label">Last Content On</span>
                  <span className="value">{feed.lastContentAt ? formatDate(feed.lastContentAt, DATE_FORMAT) : 'n/a'}</span>
                </label>
              </div>
            }
          </div>

          <div className="column">
            <label>
              <span className="label">Firm Wide Settings</span>
            </label>

            <div>
              <label>
                <input
                  type="checkbox"
                  checked={isPromoted}
                  disabled={isExcluded || isLimitedSeat || isDemoted}
                  onChange={(event) => this.props.setEditModalDataDetails({isPromoted: event.target.checked})}
                />
                <span className="label inline">Promoted {orgType} Wide</span>
              </label>
            </div>

            <div>
              <label>
                <input
                  type="checkbox"
                  checked={isDemoted}
                  disabled={isExcluded || isLimitedSeat || isPromoted}
                  onChange={(event) =>
                    this.props.setEditModalDataDetails({isDemoted: event.target.checked})}
                />
                <span className="label inline">Demoted {orgType} Wide</span>
              </label>
            </div>

            <div>
              <label>
                <input
                  type="checkbox"
                  checked={isExcluded}
                  disabled={isPromoted || isLimitedSeat || isDemoted}
                  onChange={(event) => this.props.setEditModalDataDetails({isExcluded: event.target.checked})}
                />
                <span className="label inline">Excluded {orgType} Wide</span>
              </label>
            </div>

            <div>
              <label>
                <input
                  type="checkbox"
                  checked={isLimitedSeat}
                  disabled={isPromoted || isExcluded || isDemoted}
                  onChange={(event) => this.props.setEditModalDataDetails({isLimitedSeat: event.target.checked})}
                />
                <span className="label inline">Limited Seat Source</span>
              </label>
            </div>
          </div>
        </div>

        <Button
          label="Save Changes"
          isPrimary
          className="save"
          onClick={() => this.saveChanges()}
        />
      </div>
    )
  }

  renderSourceName() {
    const {feed} = this.props
    if (feed.isProprietary) {
      return (
        <TextBox
          defaultValue={feed.name}
          value={this.props.editModalData.name}
          onChange={(event) => this.props.setEditModalDataDetails({name: event.target.value})}
        />
      )
    }
    // We can't edit it if it's a global source
    return <span className="value">{feed.name}</span>
  }

  renderSourceURL() {
    const {feed} = this.props
    if (feed.isProprietary) {
      return (
        <TextBox
          defaultValue={feed.url}
          value={this.props.editModalData.url}
          onChange={(event) => this.props.setEditModalDataDetails({url: event.target.value})}
        />
      )
    }
    // We can't edit it if it's a global source
    return <span className="value url">{feed.url}</span>
  }

  renderSourceType() {
    const {feed, currentFirm} = this.props
    if (feed.isProprietary) {
      return `${currentFirm.orgType} Only Source`
    }
    return 'Global Source'
  }

  saveChanges() {
    this.props.saveFeed()
  }
}
