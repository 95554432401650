import {LARGER_TIMEFRAMES} from "./comparison-constants"
import is from "is"


export const shouldDisableLineChart = (timeFrame, cachedLargerTimeFrames, selectedSearchIds) => {

  if (!timeFrame || !selectedSearchIds ||
      ((timeFrame === '3m' || timeFrame === '6m') && cachedLargerTimeFrames.length < 1)){
    return true
  }

  if (is.string(timeFrame)) {

    // not disabling un-cached 1 month range currently as it can return in time with staggered
    // loading
    if ((timeFrame === '3m' || timeFrame === '6m') && cachedLargerTimeFrames) {
      let cachedTimeFrames = LARGER_TIMEFRAMES
      let newTimeFrames = (ssId) => {
        const cachedSearchTimeFrames = cachedLargerTimeFrames.find(cs => cs.searchId === ssId)
        return cachedTimeFrames.filter(tf => cachedSearchTimeFrames.timeFrames.includes(tf))
      }
      selectedSearchIds.forEach(ssId => {
        cachedTimeFrames = newTimeFrames(ssId)
      })
      return !cachedTimeFrames.includes(timeFrame)
    } else {
      return timeFrame === '3m' || timeFrame === '6m'
    }

  } else {
    const {start, end} = timeFrame
    if (start && end) {
      return Math.round((end-start)/(1000*60*60*24)) > 31
    } else {
      return false
    }
  }
}
