/**
 * This file contains functions to turn internal values into strings
 * appropriate for user display. In the future this may also handle
 * internationalization.
 */

import invariant from 'invariant'
import {titleCase} from 'title-case'

import {SavedSearch} from 'app/models'
import {FACTORS, SUBFACTORS, VALENCES} from "./constants";


export function searchCategory(
  category,
  {
    currentFirm,
    plural = false,
    useDefaults = true,
  } = {},
) {
  /**
   * this is sort of a mess, because the terms `firm` and `competitor` are not used consistently.
   * saved searches have a category called `firm` but which we generally refer to as `competitor`.
   * our customers are also referred to as `firms` so `currentFirm` is the current customer.
   * `currentFirm` has Site Label fields such as `firm_display_name`, `competitor_display_name`, `tracker_display_name`
   * as well as plural versions with a `_plural` suffix.
   * `firm_display_name` does not refer to a ss category (the way the other Site Labels do) but instead
   * it refers to our customer. `firm_display_name` is no longer used.
   * `competitor_display_name` refers to the `firm` ss category.
   */
  let siteLabelPrefix = category
  if (siteLabelPrefix === 'firm') {
    siteLabelPrefix = 'competitor'
  }
  const key = `${siteLabelPrefix}DisplayName${plural ? 'Plural' : ''}`
  if (currentFirm && currentFirm[key]) {
    // if we have the firms site label, use it.
    return titleCase(currentFirm[key])
  }
  if (!useDefaults) {
    return ''
  }
  const {CATEGORIES} = SavedSearch
  const lookup = {
    [CATEGORIES.COMPETITOR]: 'Competitor',
    [CATEGORIES.COMPANY]: 'Company',
    [CATEGORIES.INDUSTRY]: 'Industry',
    [CATEGORIES.PRACTICE]: 'Practice',
    [CATEGORIES.PROSPECT]: 'Prospect',
    [CATEGORIES.TOPIC]: 'Topic',
    [CATEGORIES.TRUSTED]: 'Trusted Source Folder',
    [CATEGORIES.TRUSTED_UNCATEGORIZED]: 'Trusted Source',
    [CATEGORIES.COURT]: 'Court',
    [CATEGORIES.STATE]: 'State',
  }
  const lookupPlural = {
    [CATEGORIES.COMPANY]: 'Companies',
    [CATEGORIES.INDUSTRY]: 'Industries',
  }
  let label = null
  if (plural) {
    label = lookupPlural[category] || lookup[category] + 's'
  } else {
    label = lookup[category]
  }
  if (!label) {
    throw new Error(`The search category '${category}' does not have a display string.`)
  }
  return label
}


export function searchScope(scope) {
  const {SCOPES} = SavedSearch
  const string = {
    [SCOPES.PERSONAL]: 'Private',
    [SCOPES.SHARED]: 'Shared',
  }[scope]
  if (typeof string == 'undefined') {
    throw new Error(`The search scope '${scope}' does not have a display string.`)
  }
  return string
}


/**
 * convert csv message into bulleted list with header
 */
export function fancyNotification(header, message) {
  return `
    <div>
      <div><strong>${header}:</strong></div>
      <ul>
        ${
          message.split(',').map(
            s => {
              s = s.trim()
              return `<li>${s.charAt(0).toUpperCase() + s.slice(1)}</li>`
            }
          ).join('')
        }
      </ul>
    </div>
  `
}


export function timeFrameDisplay(timeFrame) {
  const text = {
    '24h': '1 day',
    '1w': '1 week',
    '2w': '2 weeks',
    '1m': '1 month',
    '3m': '3 months',
    '6m': '6 months',
  }[timeFrame]
  invariant(text, `Invalid time frame: ${timeFrame}.`)
  return text
}

export function factorDisplayName(factor) {
  const string = {
    [FACTORS.PLANET]: 'Planet',
    [FACTORS.PEOPLE]: 'People',
    [FACTORS.GOVERNANCE]: 'Governance',
    [FACTORS.PROSPERITY]: 'Prosperity',
  }[factor]
  invariant(string, `'${factor}' is not a valid factor.`)
  return string
}

export function subfactorDisplayName(subfactor) {
  const string = {
    [SUBFACTORS.GREENHOUSE_GAS_EMISSIONS]: 'Greenhouse Gas Emissions',
    [SUBFACTORS.CLIMATE_CHANGE]: 'Climate Change',
    [SUBFACTORS.NATURE_LOSS]: 'Nature Loss',
    [SUBFACTORS.RESOURCE_AVAILABILITY]: 'Resource Availability',
    [SUBFACTORS.SOLID_WASTE]: 'Solid Waste',
    [SUBFACTORS.DIVERSITY_AND_INCLUSION]: 'Diversity and Inclusion',
    [SUBFACTORS.WATER_POLLUTION]: 'Water Pollution',
    [SUBFACTORS.DISCRIMINATION_AND_HARASSMENT]: 'Discrimination and Harassment',
    [SUBFACTORS.FORCED_OR_CHILD_LABOR]: 'Forced or Child Labor',
    [SUBFACTORS.AIR_POLLUTION]: 'Air Pollution',
    [SUBFACTORS.FRESHWATER_AVAILABILITY]: 'Freshwater Availability',
    [SUBFACTORS.WAGE_LEVELS]: 'Wage Levels',
    [SUBFACTORS.PAY_EQUALITY]: 'Pay Equality',
    [SUBFACTORS.DIGNITY_AND_EQUALITY]: 'Dignity and Equality',
    [SUBFACTORS.COLLECTIVE_BARGAINING]: 'Collective Bargaining',
    [SUBFACTORS.HAZARDOUS_WASTE]: 'Hazardous Waste',
    [SUBFACTORS.QUALITY_OF_GOVERNING_BODY]: 'Quality of Governing Body',
    [SUBFACTORS.GOVERNANCE_BODY_COMPOSITION]: 'Governance Body Composition',
    [SUBFACTORS.REMUNERATION]: 'Remuneration',
    [SUBFACTORS.PROGRESS_AGAINST_STRATEGIC_MILESTONES]: 'Progress Against Strategic Milestones',
    [SUBFACTORS.GOVERNING_PURPOSE]: 'Governing Purpose',
    [SUBFACTORS.HEALTH_AND_SAFETY]: 'Health and Safety',
    [SUBFACTORS.STAKEHOLDER_ENGAGEMENT]: 'Stakeholder Engagement',
    [SUBFACTORS.ETHICAL_BEHAVIOR]: 'Ethical Behavior',
    [SUBFACTORS.MONETARY_LOSSES_FROM_UNETHICAL_BEHAVIOR]: 'Monetary Losses from Unethical Behavior',
    [SUBFACTORS.EMPLOYEE_WELL_BEING]: 'Employee Well-being',
    [SUBFACTORS.LEAVE_POLICIES]: 'Leave Policies',
    [SUBFACTORS.TRAINING_PROVIDED]: 'Training Provided',
    [SUBFACTORS.SKILLS_SHORTAGE]: 'Skills Shortage',
    [SUBFACTORS.DATA_SECURITY]: 'Data Security',
    [SUBFACTORS.RISK_AND_OPPORTUNITY_OVERSIGHT]: 'Risk and Opportunity Oversight',
    [SUBFACTORS.ANTI_CORRUPTION]: 'Anti-Corruption',
    [SUBFACTORS.RATE_OF_EMPLOYMENT]: 'Rate of Employment',
    [SUBFACTORS.PROTECTED_ETHICS_ADVICE_AND_REPORTING_MECHANISMS]: 'Protected Ethics Advice and Reporting Mechanisms',
    [SUBFACTORS.ALIGNMENT_OF_STRATEGY_AND_POLICIES_TO_LOBBYING]: 'Alignment of Strategy and Policies to Lobbying',
    [SUBFACTORS.COMMUNITY_AND_SOCIAL_VITALITY]: 'Community and Social Vitality',
    [SUBFACTORS.COMMUNITY_AND_SOCIAL_INVESTMENT]: 'Community and Social Investment',
    [SUBFACTORS.INFRASTRUCTURE_INVESTMENTS_AND_SERVICES_SUPPORTED]: 'Infrastructure Investments and Services Supported',
    [SUBFACTORS.TAX_REPORTING]: 'Tax Reporting',
    [SUBFACTORS.INNOVATION_OF_BETTER_PRODUCTS_AND_SERVICES]: 'Innovation of Better Products and Services',
    [SUBFACTORS.R_D_ACTIVITY]: 'R&D Activity',
    [SUBFACTORS.CUSTOMER_EXPERIENCE]: 'Customer Experience',
    [SUBFACTORS.EMPLOYMENT_AND_WEALTH_GENERATION]: 'Employment and Wealth Generation',
    [SUBFACTORS.ECONOMIC_CONTRIBUTION]: 'Economic Contribution',
    [SUBFACTORS.FINANCIAL_INVESTMENT_CONTRIBUTION]: 'Financial Investment Contribution',
    [SUBFACTORS.GOVERNMENT_ASSISTANCE]: 'Government Assistance',
    [SUBFACTORS.HEALTH_AND_WELL_BEING]: 'Health and Well-being',
    [SUBFACTORS.SKILLS_FOR_THE_FUTURE]: 'Skills for the Future',
    [SUBFACTORS.INVESTOR_ACTIVISM]: 'Investor Activism',
    [SUBFACTORS.GREEN_INVESTING]: 'Green Investing',
    [SUBFACTORS.SUPPLY_CHAIN_RISK]: 'Supply Chain Risk',
    [SUBFACTORS.PRODUCT_RISK]: 'Product Risk',
    [SUBFACTORS.DATA_STEWARDSHIP]: 'Data Stewardship',
    [SUBFACTORS.DATA_PRIVACY]: 'Data Privacy',
    [SUBFACTORS.EMERGING_TECHNOLOGY_RISK]: 'Emerging Technology Risk',
    [SUBFACTORS.IMPACT_OF_COVID_ON_CUSTOMER_EXPERIENCE]: 'Impact of COVID on Customer Experience',
    [SUBFACTORS.BIORISK]: 'Biorisk',
    [SUBFACTORS.HUMAN_RIGHTS]: 'Human Rights'
  }[subfactor]
  invariant(string, `'${subfactor}' is not a valid subfactor.`)
  return string
}

export function valenceDisplayName(valence) {
  const string = {
    [VALENCES.POSITIVE]: 'Positive',
    [VALENCES.NEGATIVE]: 'Negative',
    [VALENCES.NEUTRAL]: 'Neutral',
  }[valence]
  invariant(string, `'${valence}' is not a valid valence.`)
  return string
}

