import { combineReducers } from 'redux'

import * as searches from './searches'
import * as sources from './sources'
import * as account from './account'
import * as preferences from './preferences'
import * as groupMembers from './group-members'


export default combineReducers({
  [searches.constants.STATE_PROP_NAME]: searches.reducer,
  [sources.constants.STATE_PROP_NAME]: sources.reducer,
  [account.constants.STATE_PROP_NAME]: account.reducer,
  [preferences.constants.STATE_PROP_NAME]: preferences.reducer,
  [groupMembers.constants.STATE_PROP_NAME]: groupMembers.reducer,
})
