import is from 'is'
import {equals} from 'ramda'
import {eventChannel} from 'redux-saga'
import {all, fork, put, select, take, takeLatest} from 'redux-saga/effects'

import * as actions from './routing-actions'
import * as selectors from './routing-selectors'
import * as utils from './routing-utils'


/**
 * Creates a channel that emits popstate events when the window's location
 * changes.
 * See: https://redux-saga.js.org/docs/advanced/Channels.html
 */
const historyEventChannel = () =>
  eventChannel(emit => {
    const listener = event => {
      emit(event)
    }
    window.addEventListener('popstate', listener)
    return () => {
      window.removeEventListener('popstate', listener)
    }
  })


function* fireLocationChangedEvents() {
  const historyEvents = historyEventChannel()
  while (true) {
    const event = yield take(historyEvents)
    const url = new URL(event.target.location)
    yield put(
      actions.locationChanged({
        pathname: url.pathname,
        query: utils.searchParamsToQuery(url.searchParams)},
      ),
    )
  }
}


function* changeLocation(action, pushOrReplace) {
  const currentLocation = yield select(selectors.getRoutingState)
  const newLocation = utils.normalizeLocationInput(action.payload)
  if (currentLocation.query && newLocation.query && newLocation.persistQuery) {
    newLocation.query = utils.mergeQueries(
      currentLocation.query,
      newLocation.query,
    )
  }
  const locationObj = {
    ...currentLocation,
    ...newLocation,
  }
  if (locationObj.query) {
    // Remove any null or undefined values.
    for (const [key, value] of Object.entries(locationObj.query)) {
      if (is.undefined(value) || value === null) {
        delete locationObj.query[key]
      }
    }
  }
  // If the location is the same as the current one, always replace the history
  // state instead of pushing a new one.
  if (
    currentLocation.pathname === locationObj.pathname
    && equals(currentLocation.query || {}, locationObj.query || {})
  ) {
    pushOrReplace = 'replace'
  }
  locationObj.replace = pushOrReplace === 'replace'
  window.history[`${pushOrReplace}State`](
    null,
    '',
    utils.locationToString(locationObj),
  )
  yield put(actions.locationChanged(locationObj))
}

function* handlePushLocation(action) {
  yield* changeLocation(action, 'push')
}

function* handleReplaceLocation(action) {
  yield* changeLocation(action, 'replace')
}


export default function* routingSaga() {
  yield all([
    fork(fireLocationChangedEvents),
    takeLatest(actions.pushLocation, handlePushLocation),
    takeLatest(actions.replaceLocation, handleReplaceLocation),
  ])
}
