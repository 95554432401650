import React from 'react'
import * as R from 'ramda'
import formatDate from 'date-fns/format'
import {titleCase} from 'title-case'

import {DATE_FORMAT} from 'app/constants'
import Table from 'app/common/Table'

const IGNORE_ARGS = [
  'id',
  'is_firm_library',
  'is_change_owner',
  'is_make_copy',
  'is_make_child',
  'allow_duplicates',
  'is_cascade_name',
  'filters_add_only',
  'applied_filter_key',
  'term_frequency_key',
]

const TRUNCATE_ARGS = [
  'filters',
  'notice_config',
]

export default function ActionLogContent({search}) {
  return (
    <div>
      <Table
        data={search.actionLog}
        defaultSort={{column: 'createdAt', direction: 'desc'}}
      >
        <Table.Column
          name="user"
          label="User"
          isSortable
          baseWidth={150}
          growRatio={1}
          shrinkRatio={0}
          cellContents={entry => {
            return (
              <React.Fragment>
                <div>{entry.originalUser.username}</div>
                {
                  entry.user.username !== entry.originalUser.username &&
                  <div>(logged in as: {entry.user.username})</div>
                }
              </React.Fragment>
            )
          }}
        />

        <Table.Column
          name="createdAt"
          label="Date"
          isSortable
          sortBy={R.prop('createdAt')}
          baseWidth={110}
          growRatio={0}
          shrinkRatio={1}
          cellContents={entry => formatDate(entry.createdAt, DATE_FORMAT)}
        />

        <Table.Column
          name="action"
          label="Action"
          isSortable
          baseWidth={80}
          growRatio={0}
          shrinkRatio={1}
          cellContents={R.prop('action')}
        />

        <Table.Column
          name="details"
          label="Details (all not necessarily changed)"
          baseWidth={180}
          growRatio={2}
          shrinkRatio={0}
          cellContents={entry => {
            return Object.keys(entry.arguments).filter(k => {
              if (IGNORE_ARGS.includes(k)) {
                return false
              } else if (k === 'search_phrase' && entry.arguments.hasOwnProperty('core_terms')) {
                // backwards compatibility; only use 'search_phrase' if 'core_terms' doesn't exist
                return false
              }
              return true
            }).map(k => {
              const name = titleCase(k.split('_').join(' '))
              let value = entry.arguments[k]
              if (Array.isArray(value)) {
                value = value.join(', ')
              }
              if (!value) {
                return null
              }
              if (value.length > 50) {
                value = `${value.substring(0, 50)}...`
              }
              if (TRUNCATE_ARGS.includes(k)) {
                value = '...'
              }
              return <div key={k}><strong>{name}:</strong> {value}</div>
            })
          }}
        />
      </Table>
    </div>
  )
}
