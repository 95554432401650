import React, { useState, useRef } from "react";
import * as urls from 'app/urls'
import { useSelector } from "react-redux"
import * as globalSelectors from 'app/global/global-selectors'
import * as entitiesSelectors from 'app/entities/entities-selectors'
import { SavedSearch, User } from 'app/models'
import Orm from 'app/framework/Orm'
import { urlFromGroup, urlFromSavedSearch } from "app/global/NavMenu/helpers";
import ExpandableMenu from "../../../common/ExpandableMenu";
import './styles.less'
import Divider from "app/common/Divider";

const ResponsiveMenu = ({ isGroupManager,
    rootInsightsUrl,
    jwt,
    isDiligent,
    shouldHideInsightsLink,
    shouldHideMisLink,
    showOldPublish,
    showNewPublish,
    flaggedArticleCount,
    displayName,
    firmName,
    isStaff,
    isFirmAdmin,
    isPortalGroup,
    navBarOrigin,
    showPrimaryMenu,
    showAdminMenu,
    hideMenu,
    showNewSearchModal
}) => {

    let searches, groups = []
    let practice_url, industry_url, client_url, competitor_url, topic_url, sources_url = ""

    const handleNewSearchButtonClick = () => {
        hideMenu?.()
        showNewSearchModal?.()
    };

    if (navBarOrigin !== "publish") {
        const profileSearchIds = useSelector(globalSelectors.getProfileSearchIds)
        const userGroupIds = useSelector(globalSelectors.getCurrentUserGroupIds)
        const entities = useSelector(entitiesSelectors.getEntities)
        const orm = Orm.withEntities(entities)

        searches = orm.getByIds(SavedSearch, profileSearchIds).sort((s1, s2) => {
            const name1 = s1.name.trim()
            const name2 = s2.name.trim()
            return name1.localeCompare(name2)
        })

        groups = orm.getByIds(User, userGroupIds)
            .sort((group1, group2) => {
                const name1 = group1.displayName.trim().toLowerCase()
                const name2 = group2.displayName.trim().toLowerCase()
                return name1.localeCompare(name2)
            })

        practice_url = isPortalGroup ? '.' : '/practice/'
        industry_url = isPortalGroup ? '.' : '/industry/'
        client_url = isPortalGroup ? '.' : '/client/'
        competitor_url = isPortalGroup ? '.' : '/competitor/'
        topic_url = isPortalGroup ? '.' : '/tracker/'
        sources_url = '/profile/trusted_sources/'
    }

    const insightsLinkLabel = isDiligent ? 'Analytics' : 'Insights'
    const misLinkLabel = isDiligent ? 'Intel Now' : 'MIS'

    const fetchItems = (itemCategory) => {
        const items = []
        if (groups.length > 0 && itemCategory == 'group') {
            groups.map(search => {
                const isGroup = !search.category
                const name = isGroup ? search.displayName : search.name
                const url = isGroup ? urlFromGroup(search) : urlFromSavedSearch(search)
                items.push({
                    title: name,
                    url: url,
                    subLevel: 2,
                })
            })
        }
        else {
            let filteredSearches
            if (itemCategory == "trusted") {
                const trustedFolderSearches = searches.map(search => {
                    if (itemCategory === search.category) {
                        return search
                    }
                })

                const trustedSourceSearches = searches.map(search => {
                    if ("trusted-uncategorized" === search.category) {
                        return search
                    }
                })
                filteredSearches = [...trustedFolderSearches, ...trustedSourceSearches]
            }
            else {
                filteredSearches = searches.map(search => {
                    if (itemCategory === search.category) {
                        return search
                    }
                }
                )
            }

            const properFiltered = filteredSearches.filter(val => val)
            if (properFiltered.length > 0) {
                properFiltered.map(search => {
                    if (search) {
                        const isGroup = !search.category
                        const name = isGroup ? search.displayName : search.name
                        const url = isGroup ? urlFromGroup(search) : urlFromSavedSearch(search)
                        const category = search.category
                        items.push({
                            title: name,
                            url: url,
                            leftIconClassName: category === "trusted" ? 'folder-icon' : null
                        })
                    }
                })
            }
        }
        return items;
    };

    const isMISActive = window.location.pathname.startsWith(urls.mis.home())
    const isESGActive = window.location.pathname.startsWith(urls.esg.home())
    const isNewPublishActive = window.location.pathname.includes(urls.publish3())
    const isOldPublishActive = window.location.pathname.includes(urls.publish())
    const isPublishActive = isNewPublishActive || isOldPublishActive

    let publishSubMenuItems = []
    if (showOldPublish && showNewPublish) {
        const oldPublish = { ...urls.responsiveSubMenuItems.oldPublish, isActive: isOldPublishActive }
        const newPublish = { ...urls.responsiveSubMenuItems.newPublish, isActive: isNewPublishActive }
        publishSubMenuItems = [oldPublish, newPublish]
    }

    const esgSubMenuItems = urls.responsiveSubMenuItems.esg.subMenus
    const misSubMenuItems = urls.responsiveSubMenuItems.mis.subMenus

    let publishLink
    if (showOldPublish && showNewPublish) {
        publishLink = null
    }
    else if (showOldPublish) {
        publishLink = urls.publish()
    }
    else if (showNewPublish) {
        publishLink = urls.publish3()
    }
    else {
        publishLink = null
    }

    let adminLink
    let adminLinkLabel
    if (isStaff || isFirmAdmin) {
        adminLink = urls.adminUsers()
        adminLinkLabel = "Admin"
    } else if (isGroupManager) {
        adminLink = urls.manageGroups()
        adminLinkLabel = "Manage Groups"
    } else {
        adminLink = null
    }

    const NewSearchButton = () => {
        return (
            <div className="menuAdditionalChildren">
                <div className="newSearchButton" onClick={handleNewSearchButtonClick}>New Search</div>
            </div>
        )
    }

    const settingsButton = (category, manageURL) => {
        const label = `Setting ${category}`
        return (
            <div className="menuAdditionalChildren">
                <a href={manageURL}><div className="settingsPracticeButton">{label}</div></a>
            </div>
        )
    }

    const zeroSearches = (category, manageURL) => {
        const label = `You have saved no ${category} yet.`
        return (
            <div className="noSubMenuChildren">
                <div className="noSubMenuTitle">{label}</div>
                <a href={manageURL}><div className="noSubMenuAddButton">Add a new search</div></a>
            </div>
        )
    }

    const fetchChildren = (itemCategory, label, subMenuCount) => {
        let children = null
        if (itemCategory == 'group') {
            children = null
        }
        else {
            let manageURL
            if (itemCategory === 'trusted') {
                manageURL = '/profile/trusted_sources/'
            } else if (itemCategory === 'competitor') {
                manageURL = '/profile/firm/'
            } else {
                manageURL = `/profile/${itemCategory}/`
            }

            if (subMenuCount > 0) {
                children = settingsButton(label, manageURL)
            }
            else {
                children = zeroSearches(label, manageURL)
            }
        }

        return children
    }

    let isBaseActive = window.location.pathname == urls.home()
    const baseSubMenuItems = () => {
        let submenu = []
        if (navBarOrigin !== "publish") {
            let groupSubMenu = { ...urls.responsiveSubMenuItems.baseGroup }
            groupSubMenu.subMenus = fetchItems("group")
            groupSubMenu.isActive = window.location.pathname.includes("/groups")
            isBaseActive |= groupSubMenu.isActive

            let practicesSubmenu = { ...urls.responsiveSubMenuItems.basePractices }
            practicesSubmenu.url = practice_url
            practicesSubmenu.subMenus = fetchItems("practice")
            practicesSubmenu.children = fetchChildren("practice", "Practices", practicesSubmenu.subMenus.length)
            practicesSubmenu.isActive = window.location.pathname.includes(practice_url)
            isBaseActive |= practicesSubmenu.isActive

            let industriesSubmenu = { ...urls.responsiveSubMenuItems.baseIndustries }
            industriesSubmenu.url = industry_url
            industriesSubmenu.subMenus = fetchItems("industry")
            industriesSubmenu.children = fetchChildren("industry", "Industries", industriesSubmenu.subMenus.length)
            industriesSubmenu.isActive = window.location.pathname.includes(industry_url)
            isBaseActive |= industriesSubmenu.isActive

            let companiesSubmenu = { ...urls.responsiveSubMenuItems.baseCompanies }
            companiesSubmenu.url = client_url
            companiesSubmenu.subMenus = fetchItems("client")
            companiesSubmenu.children = fetchChildren("client", "Companies", companiesSubmenu.subMenus.length)
            companiesSubmenu.isActive = window.location.pathname.includes(client_url)
            isBaseActive |= companiesSubmenu.isActive

            let competitorsSubmenu = { ...urls.responsiveSubMenuItems.baseCompetitors }
            competitorsSubmenu.url = competitor_url
            competitorsSubmenu.subMenus = fetchItems("firm")
            competitorsSubmenu.children = fetchChildren("firm", "Competitors", competitorsSubmenu.subMenus.length)
            competitorsSubmenu.isActive = window.location.pathname.includes(competitor_url)
            isBaseActive |= competitorsSubmenu.isActive

            let topicsSubmenu = { ...urls.responsiveSubMenuItems.baseTopics }
            topicsSubmenu.url = topic_url
            topicsSubmenu.subMenus = fetchItems("tracker")
            topicsSubmenu.children = fetchChildren("tracker", "Topics", topicsSubmenu.subMenus.length)
            topicsSubmenu.isActive = window.location.pathname.includes(topic_url)
            isBaseActive |= topicsSubmenu.isActive

            let sourcesSubmenu = { ...urls.responsiveSubMenuItems.baseSources }
            sourcesSubmenu.url = sources_url
            sourcesSubmenu.subMenus = fetchItems("trusted")
            sourcesSubmenu.children = fetchChildren("trusted", "Sources", sourcesSubmenu.subMenus.length)
            sourcesSubmenu.isActive = window.location.pathname.includes(sources_url)
            isBaseActive |= sourcesSubmenu.isActive

            submenu = [groupSubMenu, practicesSubmenu, industriesSubmenu, companiesSubmenu, competitorsSubmenu, topicsSubmenu, sourcesSubmenu]
        }
        return submenu
    }

    const baseChildren = navBarOrigin !== "publish" ? <NewSearchButton /> : null

    return (
        <>

            {!showPrimaryMenu ? <>
                <ExpandableMenu
                    title="Base"
                    titleClassName={"primaryMenuStyle"}
                    subMenus={baseSubMenuItems()}
                    url={urls.home()}
                    isActive={isBaseActive}
                    onNavigation={hideMenu}
                >
                    {baseChildren}
                </ExpandableMenu>
                {!shouldHideInsightsLink &&
                    <ExpandableMenu
                        title={insightsLinkLabel}
                        titleClassName={"primaryMenuStyle"}
                        subMenus={urls.responsiveSubMenuItems.insights(rootInsightsUrl)}
                        url={urls.withQueryParams(rootInsightsUrl, { token: jwt })} />
                }
                {!shouldHideMisLink &&
                    <ExpandableMenu
                        title={misLinkLabel}
                        titleClassName={"primaryMenuStyle"}
                        subMenus={misSubMenuItems}
                        url={urls.mis.home()}
                        isActive={isMISActive}
                        onNavigation={hideMenu}
                    />}
                <ExpandableMenu
                    title="ESG Dashboard"
                    titleClassName={"primaryMenuStyle"}
                    subMenus={esgSubMenuItems}
                    url={urls.esg.home()}
                    isActive={isESGActive}
                    onNavigation={hideMenu}
                />
                <Divider className={"divider"} />
            </> : null}
            <ExpandableMenu
                title="Help"
                leftIconClassName={'help-icon'}
                url={urls.help()}
                onNavigation={hideMenu}
            />
            <ExpandableMenu
                title="Flagged Items"
                leftIconClassName={'flag-icon'}
                badgeCount={flaggedArticleCount}
                url={urls.flagged()}
                onNavigation={hideMenu}
            />
            <ExpandableMenu
                title="Publications"
                subMenus={publishSubMenuItems}
                leftIconClassName={'publish-icon'}
                url={publishLink}
                isActive={isPublishActive}
                onNavigation={hideMenu}
            />
            {adminLink &&
                <ExpandableMenu
                    title={adminLinkLabel}
                    leftIconClassName={'admin-icon'}
                    url={adminLink}
                    onNavigation={hideMenu}
                />}
            {!isPortalGroup &&
                <ExpandableMenu
                    title="Email Notifications"
                    leftIconClassName={'mail-icon'}
                    url={urls.emailSettings()}
                    onNavigation={hideMenu}
                />}
            <ExpandableMenu
                title={`${_.startCase(_.camelCase(displayName))}`}
                subTitle={firmName}
                leftIconClassName={'user-icon'}
                url={urls.profile()}
                onNavigation={hideMenu}
            />
            <Divider className={"divider"} />
            <ExpandableMenu
                title="Sign Out"
                leftIconClassName={'signout-icon'}
                url={urls.logout()}
                titleClassName={'signout-title'}
                onNavigation={hideMenu}
            />
            <div className="emptyBottomView" />
        </>
    );
};
export default ResponsiveMenu;
