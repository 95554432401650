import Model from 'app/framework/Model'
import { field } from 'app/framework/Model/fields'
import {parseISO} from "date-fns"


// This model is actually a combination of MzUser and Profile from Django, since
// the distinction isn't important for the frontend.
export default class User extends Model {
  static entityKey = 'users'

  static fields = {
    id: field({required: false}),
    firmId: field({required: false}),
    username: field({required: false}),
    email: field({required: false}),
    firstName: field({required: false}),
    lastName: field({required: false}),
    role: field({required: false}),
    phone: field({required: false}),
    timezone: field({required: false}),
    favoredTimezones: field({required: false}),
    groupNotes: field({required: false}),
    locationId: field({required: false}),
    location: field({required: false}),
    firmLocationId: field({required: false}),
    firmLocationName: field({required: false}),
    firmDepartmentName: field({required: false}),
    firmTeamName: field({required: false}),
    isActive: field({required: false}),
    isPrivateGroup: field({required: false}),
    hasInsights: field({required: false}),
    isStaff: field({required: false}),
    isFirmAdmin: field({required: false}),
    isPortalGroup: field({required: false}),
    hasPublishV3Access: field({required: false}),
    hasPublishAccess: field({required: false}),
    publishAccessForNonadmins: field({required: false}),
    includeWithGroupSignup: field({required: false}),
    sendEmailToGroupAddress: field({required: false}),
    authToken: field({required: false}),
    linkedinToken: field({required: false}),
    twitterToken: field({required: false}),
    groupingLevelDefault: field({required: false}),
    departments: field({required: false}),
    teams: field({required: false}),
    isFirmLibraryGroup: field({required: false}),
    nextRefreshEmailAt: field({required: false}),
    refreshCompletedAt: field({required: false}),
    lastRefreshAt: field({required: false}),
    showSignupWizard: field({required: false}),
    useAlertSettingsSsOrder: field({required: false}),
    receiveMonthlyRefreshSummary: field({required: false}),
    publishedReportCount: field({required: false}),
    refreshInterval: field({required: false}),

    // this is only set when creating a new user
    newUserPassword: field({required: false}),
    recurringPubCount: field({required: false}),
    templatePubCount: field({required: false}),
    emailNotificationCount: field({required: false}),
    recurringPublication: field({required: false}),
    templatePublication: field({required: false}),
    nlaProfile: field({required: false}),
    restrictNlaFeeds: field({required: false})
  }

  constructor(data) {
    super(data)
    const {lastRefreshAt, refreshCompletedAt,  nextRefreshEmailAt} = data
    if (lastRefreshAt && lastRefreshAt.constructor !== Date) {
      this.lastRefreshAt = parseISO(lastRefreshAt)
    }
    if (refreshCompletedAt && refreshCompletedAt.constructor !== Date) {
      this.refreshCompletedAt = parseISO(refreshCompletedAt)
    }
    if (nextRefreshEmailAt && nextRefreshEmailAt.constructor !== Date) {
      this.nextRefreshEmailAt = parseISO(nextRefreshEmailAt)
    }
  }

  get displayName() {
    if (this.lastName) {
      return `${this.firstName} ${this.lastName}`
    }
    if (this.firstName) {
      return this.firstName
    }
    return this.username
  }

  get displayNameLastFirst() {
    if (this.lastName) {
      return `${this.lastName}, ${this.firstName}`
    }
    if (!this.firstName) {
      return this.username
    }
    return this.firstName
  }

  get isGroup() {
    return this.role === 'group'
  }

  get members() {
    if (typeof this.userMemberships === 'undefined') return undefined
    return this.userMemberships.map(membership => membership.user)
  }

  /*
   * Whether this is the firm group for its firm. A value of `undefined`
   * means we aren't sure because the necessary data isn't loaded.
   */
  get isFirmGroup() {
    if (!this.isGroup) return false
    if (!this.firm || !this.firm.firmUserId) return undefined
    return this.firm.firmUserId === this.id
  }
}
