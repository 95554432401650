import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import {createSelector} from 'reselect'

import { ConfirmModal } from 'app/reusable/ConfirmModal'
import Button from 'app/common/Button'
import * as notifications from 'app/global/notifications'
import InlineSvg from 'app/common/InlineSvg'

import {
  setDeleteCategoryId,
  updateEditCategoryData,
  resetEditCategoryData,
  saveCategory,
  deleteCategories,
  setSideBarCategories
} from './flagged-items-actions'
import {getFlaggedItems} from './flagged-items-selectors'


@connect(
  createSelector(
    [getFlaggedItems],
    (flaggedItems) => {
      return {
        deleteCategoryId: flaggedItems.deleteCategoryId,
        editCategoryData: flaggedItems.editCategoryData,
        pageVersion: flaggedItems.pageVersion
      }
    }
  ),
  {
    showNotification: notifications.actions.showNotification,
    setDeleteCategoryId,
    updateEditCategoryData,
    resetEditCategoryData,
    saveCategory,
    deleteCategories,
    setSideBarCategories
  },
)
export default class FlaggedSidebarItem extends Component {
  static propTypes = {
    category: PropTypes.object.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    handleCreateFolderLinkClick: PropTypes.func.isRequired,
    sideBarValues: PropTypes.object
  }

  state = {
    showCopied: false,
  }

  render() {
    const isEditing = this.props.editCategoryData.id === this.props.category.id

    let sortArrow = this.props.category?.isArrowOpen
    ? <img src="/media/img/arrow_down.gif"/>
    : <img src="/media/img/arrow_right.gif"/>

    let categoryName = <></>

    if(this.props.pageVersion == 'old'){
      categoryName = this.props.category.flaggings.length === 0 ?
        <span className="quiet">{this.props.category.name}</span>
        : <span>
          <a href={`#flagged-category-${this.props.category.id}`}>{this.props.category.name}</a>
          <span className="quiet">({this.props.category.flaggings.length})</span>
        </span>
    }
    else{
      if (this.props.category.flaggingsCount === 0) {
        categoryName = <span className="quiet">{this.props.category.name}{this.props.category.hasChild && <span className="sidebar-category-arrow" onClick={() => this.handleArrowClick()}>{sortArrow}</span>}</span>
      }
      else {
        if (this.props.checked) {
          categoryName = <span>
            <a href={`#flagged-category-${this.props.category.id}`}>{this.props.category.name}</a>
            <span className="quiet">({this.props.category.flaggingsCount}){this.props.category.hasChild && <span className="sidebar-category-arrow" onClick={() => this.handleArrowClick()}>{sortArrow}</span>}</span>
          </span>
        }
        else {
          categoryName = <span>
            {this.props.category.name}
            <span className="quiet">({this.props.category.flaggingsCount}){this.props.category.hasChild && <span className="sidebar-category-arrow" onClick={() => this.handleArrowClick()}>{sortArrow}</span>}</span>
          </span>
        }
      }
    }

    const copyFolderId = this.state.showCopied
      ? <span className={'show-copied'}>
          Copied!
        </span>
      : <a
          href="#"
          className="copy-folder-id tooltip action"
          title="Copy Folder ID to Clipboard"
          onClick={() => this.handleCopyFolderIdClick(this.props.category.id)}
        >
          ID
        </a>

    const actions = !isEditing && (this.props.category.id === 0
      ? <span className="folder-actions">
          {copyFolderId}
        </span>
      : <span className="folder-actions">
          {copyFolderId}
          <span>
            <a href="#" className="edit-folder-name tooltip action" title="Edit Folder">
              <img
                alt={'Edit Folder Name'}
                src="/media/img/ico_edit_off.png"
                height="12"
                align="absbottom"
                className="mouseover"
                onClick={() => this.handleRenameFolderClick()}
              />
            </a>
          </span>
          {
            !this.props.category.parent &&
            <a href="#" className="create-flagged-category action" title="Create a Sub-Folder">
              <img
                alt={'Create a Sub-folder'}
                className="tight mouseover-show"
                src="/media/img/ico_new_folder.png"
                height="14px"
                onClick={() => this.handleCreateSubfolderClick()}
              />
            </a>

          }
          <span className="delete action">
            <InlineSvg
              className="delete-icon"
              src="/media/img/delete.svg"
              onClick={() => this.props.setDeleteCategoryId(this.props.category.id)}
            />
          </span>
        </span>
    )

    const categoryNameContainer = !isEditing &&
      <div>
        {categoryName}
      </div>

    const editArea = isEditing &&
      <div className="flag-folder-edit-area">
        <div className="edit-row">
          <input
            type="text"
            value={this.props.editCategoryData.name || this.props.category.name}
            className="folder-field-name"
            onChange={(event) => this.handleNameChange(event.target.value)}
          />
        </div>

        <div className="edit-row checkbox-container">
          <input
            type="checkbox"
            checked={this.props.editCategoryData.isShared === undefined ? this.props.category.isShared : this.props.editCategoryData.isShared}
            onChange={(event) => this.handleIsSharedChange(event.target.checked)}
          />
          <label>Share this Folder</label>
        </div>

        <div className="edit-row">
          <Button
            label="Save"
            onClick={() => this.handleSaveClick()}
            disabled={!this.props.editCategoryData.name && this.props.editCategoryData.isShared === undefined}
          />
          <Button
            label="Cancel"
            isPlainText={true}
            onClick={() => this.handleCancelClick()}
          />
        </div>
      </div>

    const confirmDeleteModalMessage =
      <div>
        <div>This will delete the folder '{this.props.category.name}'.</div>
        <div>Proceed?</div>
      </div>

    const confirmDeleteModal = this.props.deleteCategoryId === this.props.category.id &&
      <ConfirmModal
        message={confirmDeleteModalMessage}
        okHandler={() => this.handleDeleteOkClick()}
        cancelHandler={() => this.props.setDeleteCategoryId(null)}
      />

    return (
      <li className={classNames('pad-t-s', 'flag-sidebar-item',
          {'flag-sidebar-item-child': !!this.props.category.parent},
          {'uncategorized': this.props.category.id === 0},
        )}>
        <span className={'flag-sidebar-checkbox-wrapper'}>
          <span className={classNames('flag-sidebar-item-col', {'invisible': isEditing})}>
            <input
              type="checkbox"
              checked={this.props.checked}
              onChange={(event) => this.props.onChange(this.props.category.id, event.target.checked)}
            />
          </span>
          <span className="flag-sidebar-item-col">
            {categoryNameContainer}
            {editArea}
          </span>
        </span>

        {actions}

        {confirmDeleteModal}
      </li>
    )
  }

  handleCreateSubfolderClick() {
    this.props.handleCreateFolderLinkClick(this.props.category.id)
  }

  handleDeleteOkClick() {
    this.props.deleteCategories([this.props.category.id])
  }

  handleCopyFolderIdClick(id) {
    this.setState({showCopied: true})
    const idElement = document.createElement('input')
    idElement.value = id
    document.body.appendChild(idElement)
    idElement.select()
    document.execCommand('copy')
    document.body.removeChild(idElement)
    setTimeout(() => {this.setState({showCopied: false})}, 2000)
  }

  handleRenameFolderClick() {
    this.props.updateEditCategoryData({id: this.props.category.id})
  }

  handleNameChange(name) {
    this.props.updateEditCategoryData({name})
  }

  handleIsSharedChange(isShared) {
    this.props.updateEditCategoryData({isShared})
  }

  handleSaveClick() {
    this.props.saveCategory()
  }

  handleCancelClick() {
    this.props.resetEditCategoryData()
  }

  handleArrowClick() {
    this.props.handleArrowClick(this.props.category.id, this.props.sideBarValues)
  }
}