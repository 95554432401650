import * as R from 'ramda'

import { STATE_PROP_NAME } from './esg-constants'

export const getESG = R.path([STATE_PROP_NAME]);
export const getCompanyEsgData = (state) => getESG(state)['companyEsgData'];
export const getActiveNavItem = (state) => {
  const visible = getESG(state)['visibleNavItems']
  if (visible.length) return visible[visible.length - 1]
  return null
}
export const getIndustryEsgData = (state) => getESG(state)['industryEsgData'];
export const getSavedSearches = (state) => getESG(state)['savedSearches'];
export const getCompany = (state) => getESG(state)['company'];
export const getIndustry = (state) => getESG(state)['industry'];
export const getEsgCompanyTable = (state) => getESG(state)['esgCompanyTable'];
export const getShowSpinnerOnFeedbackSent = (state) => getESG(state)['showSpinnerOnFeedbackSent'];
export const getSectors = (state) => getESG(state)['sectors'];
export const getSidebarContent = (state) => getESG(state).sidebarContent;
export const getIsLoading = (state) => getESG(state).isLoading;
export const getIndustryTerms = (state) => getIndustryEsgData(state).terms;
export const getCompanyTerms = (state) => getCompanyEsgData(state).terms;

export const getCompanySelectedTerm = (state) => getESG(state).companyStoryFilters.selectedTerm;
export const getCompanySelectedPillar = (state) => getESG(state).companyStoryFilters.pillarId;
export const getCompanySelectedTopic = (state) => getESG(state).companyStoryFilters.topicId;
export const getCompanyStoryOrder = (state) => getESG(state).companyStoryFilters.order;
export const getCompanyStoryPage = (state) => getESG(state).companyStoryFilters.page;

export const getIndustrySelectedTerm = (state) => getESG(state).industryStoryFilters.selectedTerm;
export const getIndustrySelectedPillar = (state) => getESG(state).industryStoryFilters.pillarId;
export const getIndustrySelectedTopic = (state) => getESG(state).industryStoryFilters.topicId;
export const getIndustryStoryOrder = (state) => getESG(state).industryStoryFilters.order;
export const getIndustryStoryPage = (state) => getESG(state).industryStoryFilters.page;

export const getDefaultIndustry = (state) => getESG(state).defaultIndustry;
export const getCompanyStories = (state) => getESG(state).companyStories;
export const getIndustryStories = (state) => getESG(state).industryStories;

export const getEsgCompaniesSearchString = (state) => getESG(state).esgSearchValue;
export const getAllEsgCompaniesOnSearch = (state) => getESG(state)['esgCompaniesOnSearch'];
export const getAllEsgCompaniesData = (state) => getESG(state)['esgAllCompaniesData'];
export const getEsgCompaniesTotalCount = (state) => getESG(state).totalEsgCompanyCount;


