import {apiCall} from 'app/api'

function fetchChartData({
  type,
  id,
  slug,
  duration,
  startDate,
  endDate,
}) {
  const data = {duration, startDate, endDate}
  return apiCall({
    url: `/chart/${type}/${slug}/${id}/`,
    method: 'GET',
    data,
  })
}

export function fetchTimelineChartData(options) {
  return fetchChartData({
    type: 'line',
    ...options,
  })
}

export function fetchShareOfVoiceChartData(options) {
  return fetchChartData({
    type: 'pie',
    ...options,
  })
}

export function fetchStockTimelineChartData(options) {
  // This seems to be unsued
  return fetchChartData({
    type: 'stockchart',
    ...options,
  })
}
