import {
  all,
  put,
  takeLatest,
} from 'redux-saga/effects'
import * as actions from 'app/common/charts/charts-actions'
import * as api from "app/common/charts/charts-api"

import {handleSagaError} from "app/utils/errors"
import {actions as notificationActions} from 'app/global/notifications'


function* handleSaveChart(action) {
  try {
    yield api.saveChart(action.payload)
    yield put(
      notificationActions.showNotification({
        type: 'success',
        message: `Chart saved successfully and is now available in Publish.`,
      })
    )
  } catch(error) {
    yield* handleSagaError(error)
  }
}

export default function* chartSaga() {
  yield all([
    takeLatest(actions.saveChart, handleSaveChart),
  ])
}
