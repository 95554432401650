import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import Dropdown, {Option} from 'app/common/Dropdown'

import * as actions from 'app/profile/preferences/profile-preferences-actions'
import * as selectors from 'app/profile/preferences/profile-preferences-selectors'

import styles from '../common/dropdown.less'

const DEFAULT_VALUE = 'trending'


export default function DashboardOrder() {
  const value = useSelector(selectors.getDashboardOrder)
  const isLoading = useSelector(selectors.getDashboardOrderLoading)
  const dispatch = useDispatch()
  const onChange = value => {
    dispatch(actions.changeDashboardOrderRequest(value))
  }
  return (
    <Dropdown
      value={value || DEFAULT_VALUE}
      disabled={isLoading}
      onChange={onChange}
      className={styles.dropdown}
    >
      <Option value="trending" label="Trending Order (Focused on Recent Activity)" />
      <Option value="email" label="Utilize Email Notification Order" />
    </Dropdown>
  )
}
