import React, {useEffect} from 'react'
import classNames from 'classnames'

import Button from 'app/common/Button'
import * as constants from 'app/email-notifications/email-notifications-constants'
import * as selectors from 'app/email-notifications/email-notifications-selectors'
import * as actions from 'app/email-notifications/email-notifications-actions'
import {useDispatch, useSelector} from 'react-redux'
import TimeOfDay from 'app/email-notifications/TimeOfDay'
import DeliveryDay from 'app/email-notifications/DeliveryDay'
import AdditionalRecipients from 'app/email-notifications/AdditionalRecipients'
import SendZeroResults from 'app/email-notifications/SendZeroResults'
import HideDuplicateArticles from 'app/email-notifications/HideDuplicateArticles'
import {EsgCategoryContent} from 'app/email-notifications/CategoryContent'
import SendOnWeekends from 'app/email-notifications/SendOnWeekends'

export default function EsgFrequencySettings({
  noticeFrequency,
  notificationSettings,
  noticeConfigs,
}) {
  const dispatch = useDispatch()
  const editUserId = useSelector(selectors.getEditUserId)
  const ccEmails = useSelector(selectors.getEsgCCEmails)
  const notificationType = notificationSettings.notificationType
  const jumpLinkId = `${notificationType}-${noticeFrequency}`
  // prettier-ignore
  const frequencyHeader = `${constants.EMAIL_FREQUENCY_MAPPING[notificationType][noticeFrequency]}
                           ${noticeFrequency !== constants.NONE_FREQ ? ' Email' : ''}`
  const frequencySettings = notificationSettings.settings.filter(setting => setting.noticeFrequency === noticeFrequency)
  const activeDeliveryDay = constants.DELIVERY_DAYS[frequencySettings.map(setting => setting.emailDeliveryWeekday)]

  useEffect(() => {
    dispatch(
      actions.api.getCCEmailsEsg.request({
        type: notificationType,
        noticeFrequency: noticeFrequency,
        editUserId: editUserId,
      }),
    )
    dispatch(
      actions.api.getEsgNoticeConfigs.request({
        editUserId: editUserId,
        type: notificationType,
        noticeFrequency: noticeFrequency,
      }),
    )
  }, [])

  const onSendSampleButtonClick = frequencyHeader => {
    dispatch(
      actions.changeSendSampleModal({
        show: true,
        notificationType: notificationType,
        frequencyHeader: frequencyHeader,
        noticeFrequency: noticeFrequency,
        recipients: [notificationSettings.userEmail],
        editUserId: editUserId,
      }),
    )
  }

  let hasFrequencyConfigs = false
  Object.values(noticeConfigs.configs).forEach(fc => {
    if (fc[noticeFrequency]) {
      hasFrequencyConfigs = true
    }
  })
  const ccEmailInputWidth =
    noticeFrequency === constants.DAILY_FREQ ||
    noticeFrequency === constants.WEEKDAY_FREQ
      ? constants.CC_EMAIL_INPUT_WIDTH_WIDER
      : constants.CC_EMAIL_INPUT_WIDTH
  const settingOptions =
    hasFrequencyConfigs && noticeFrequency !== constants.NONE_FREQ ? (
      <div className={'settings-container'}>
        <div>
          <div className={'upper-settings'}>
            <TimeOfDay
              noticeFrequency={noticeFrequency}
              notificationType={notificationType}
            />
            <DeliveryDay
              noticeFrequency={noticeFrequency}
              notificationType={notificationType}
            />
            <AdditionalRecipients
              noticeFrequency={noticeFrequency}
              notificationType={notificationType}
              ccEmails={ccEmails}
              ccEmailInputWidth={ccEmailInputWidth}
            />
          </div>
        </div>
        <div className={'lower-settings'}>
          <SendZeroResults
            noticeFrequency={noticeFrequency}
            notificationType={notificationType}
          />
          <SendOnWeekends
            noticeFrequency={noticeFrequency}
            notificationType={notificationType}
          />
          <HideDuplicateArticles
            noticeFrequency={noticeFrequency}
            notificationType={notificationType}
          />
          <Button
            className="send-sample-button"
            label={`Send Sample ${frequencyHeader}`}
            onClick={() => {
              onSendSampleButtonClick(frequencyHeader)
            }}
          />
        </div>
      </div>
    ) : null

  function getCategoryContent(frequencyHeader) {
    return notificationSettings.searchCategories.map((cat, idx) => (
      <EsgCategoryContent
        key={idx}
        category={cat}
        noticeFrequency={noticeFrequency}
        frequencyHeader={frequencyHeader}
      />
    ))
  }

  return (
    <div className={'signals-email-frequency-container'}>
      <div className={'frequency-header-container'}>
        <div
          className={classNames('frequency-header', {
            'no-bottom-border': noticeFrequency === constants.NONE_FREQ,
          })}
        >
          <a id={jumpLinkId} className={'freq-header-link'}>
            {frequencyHeader}
          </a>
          <span className={'frequency-header-help'}>
            {constants.FREQUENCY_HELP[notificationType][noticeFrequency](activeDeliveryDay)}
          </span>
        </div>
      </div>
      {settingOptions}
      {getCategoryContent(frequencyHeader)}
    </div>
  )
}
