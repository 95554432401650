import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {format as formatDate, parseISO} from 'date-fns'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {
  getCurrentUserId,
  getCurrentUserIsStaff,
  getCurrentUserFullName,
  getCurrentUserRole,
  getInsightsEnabled,
  getSiteUrl,
  getAdminConsoleRoot,
  getAppName,
  getAppNameFull,
  getAppUrl,
} from 'app/global/global-selectors'
import SearchResultsActions from 'app/reusable/SearchResultsActions'
import InteractionBar from 'app/reusable/InteractionBar'
import {FeedListing} from 'app/reusable/FeedListing'
import {ConfirmModal} from 'app/reusable/ConfirmModal'
import FlagModal from 'app/reusable/FlagModal'
import Tooltip from 'app/common/Tooltip'

import {getFlaggedItems} from '../flagged-items-selectors'
import {
  fetchCategories,
  fetchPromotedSearches,
  setDeleteModalCategoryId,
  deleteArticles,
  deleteFlaggings,
  showCreateCategoryModal,
  exportPDF,
  exportDoc,
  exportExcel,
  exportEmail,
} from '../flagged-items-actions'
import './ArticleList.less'
import {truncateWords} from "app/utils/strings"

@connect(
  createSelector(
    [
      getCurrentUserId,
      getCurrentUserIsStaff,
      getCurrentUserFullName,
      getCurrentUserRole,
      getInsightsEnabled,
      getSiteUrl,
      getAdminConsoleRoot,
      getAppName,
      getAppNameFull,
      getAppUrl,
      getFlaggedItems,
    ],
    (
      currentUserId,
      currentUserIsStaff,
      currentUserFullName,
      currentUserRole,
      insightsEnabled,
      siteUrl,
      adminConsoleRoot,
      appName,
      appNameFull,
      appUrl,
      flaggedItems,
    ) => {
      return {
        currentUserId,
        currentUserIsStaff,
        currentUserFullName,
        currentUserRole,
        insightsEnabled,
        siteUrl,
        adminConsoleRoot,
        appName,
        appNameFull,
        appUrl,
        promotedFeedIds: flaggedItems.promotedFeedIds,
        helpQuestions: flaggedItems.helpQuestions,
        sortDirection: flaggedItems.sortDirection,
        deleteModalCategoryId: flaggedItems.deleteModalCategoryId,
      }
    }
  ),
  {
    fetchCategories,
    fetchPromotedSearches,
    setDeleteModalCategoryId,
    deleteArticles,
    deleteFlaggings,
    showCreateCategoryModal,
    exportPDF,
    exportDoc,
    exportExcel,
    exportEmail,
  },
)
export default class ArticleList extends Component {
  static propTypes = {
    category: PropTypes.object.isRequired,
    onExclude: PropTypes.func,
  }

  state = {
    sortOrder: 'desc',
    selectedArticleIds: [],
    shouldShowFlagModal: false,
    moveFromCategoryId: null, // 0 represents "Uncategorized" folder
  }

  render() {
    const sortArrow = this.state.sortOrder === 'desc'
      ? <img src="/media/img/arrow_down.gif"/>
      : <img src="/media/img/arrow_up.gif"/>

    const dateSortLink =
      <a className="date-sort" onClick={() => this.toggleSort()}>
        <strong>Sort by Date</strong>
        {sortArrow}
      </a>

    const actions =
      <div>
        <input
          type="checkbox"
          checked={this.state.selectedArticleIds.length === this.props.category.flaggings.length}
          onChange={() => this.handleSelectAllCheckbox()}
        />
        <SearchResultsActions
          onChange={(val) => this.handleActionSelectChange(val)}
          isFlagPage={true}
          isDisabled={this.state.selectedArticleIds.length === 0}
        />
      </div>

    const flaggingList =
      this.sortedFlaggings.map(flagging => {
        return (
          <li key={`article-${flagging.directorId}`} className="article clear article-headline-container">
            {
              flagging.creditFeed.sourceType === 'sqoop' && this.props.helpQuestions.length > 0 &&
              <h3 className="sqoop-tip">
                <span>Sqoop Filing Alert&nbsp;&nbsp;</span>
                <Tooltip label={this.props.helpQuestions[0].statement}>
                  <a className="tooltip help" />
                </Tooltip>
              </h3>
            }
            {
              <div className="checkbox float-left">
                <input
                  type="checkbox"
                  className="margin-r-xs"
                  checked={this.state.selectedArticleIds.includes(flagging.directorId)}
                  onChange={event => this.handleFlaggingCheckbox(flagging.directorId, event.target.checked)}
                />
              </div>
            }
            <div className="article-details float-left">
              <div className="article-meta-wrapper">
                <div className="article-interaction-container">
                  <div className="article-headline-width">
                    <a href={`/article/v/${flagging.directorId}/${this.props.currentUserId}/?usrc=article&usrc_s=headline`} target="_blank" title={flagging.article.headline} className="title">
                      <span>{flagging.article.headline}</span>
                    </a>&nbsp;
                    {
                      this.props.isUserStaff &&
                      <span className="staff quiet font-size-s">
                        (<a href={flagging.article.changeUrl} className="quiet">edit</a> | <a href={`/moderation/selectedarticle/add/${flagging.directorId}/`} target="_blank" className="quiet">select</a>)
                      </span>
                    }
                    <InteractionBar
                      article={{...flagging.article, directorId: flagging.directorId, id: flagging.directorId, displayHeadlineShort: truncateWords(flagging.article.headline, 10),}}
                      isFlagged={true}
                      isPromoted={this.isPromoted(flagging.article)}
                      userFullname={this.props.currentUserFullName}
                      siteUrl={this.props.siteUrl}
                      appUrl={this.props.appUrl}
                      appName={this.props.appName}
                      appNameFull={this.props.appNameFull}
                      userId={this.props.currentUserId}
                      userRole={this.props.currentUserRole}
                      isSignalsEnabled={this.props.insightsEnabled}
                      isFlaggedPage={true}
                      isSharepoint={false}
                      onFlaggingStateChange={this.handleFlaggingStateChange}
                      onPromoteStateChange={this.handlePromoteStateChange}
                    />
                  </div>

                  <div className="meta">
                    <span className="date">
                      {formatDate(parseISO(flagging.article.publishedAt), 'MMM. do yyyy')}
                    </span>
                    <span>&nbsp;via&nbsp;</span>
                    <FeedListing article={flagging.article} isGrouping={false} groupCount={flagging.article.groupCount}/>
                    <span>
                      &nbsp;
                      <a
                        href={`${this.props.adminConsoleRoot}content/feed/${flagging.article.feed.id}/change/`}
                        className="staff staff-edit quiet font-size-s"
                        target="_blank"
                      >(edit feed)</a>
                    </span>
                  </div>

                  <div className="description">
                    <span>{flagging.article.snippet}</span>
                  </div>
                </div>
              </div>
            </div>

            {
              flagging.article.groupCount > 0 &&
              <div className="article-grouping-container">
                <div className="toggle-holder pad-t-s">
                  <div className="also-published">
                    {
                      this.props.sortDirection ?
                        <a href="#" className="toggle-action grouping-toggle">
                          <i className="fa fa-angle-right fa-fw toggle-icon" aria-hidden="true"/>
                          <span>Published in {flagging.article.groupCount} other source(s)</span>
                        </a>
                        : <a href="#" className="toggle-action grouping-toggle">
                          <i className="fa fa-angle-right fa-fw toggle-icon" aria-hidden="true"/>
                          <img src="/media/img/grouping-icon-01.png" width="12" height="12"
                               style={{verticalAlign: 'middle', paddingRight: '5px'}}/>
                          <span>{flagging.article.groupCount} similar article(s)</span>
                        </a>
                    }
                  </div>
                </div>
              </div>
            }
          </li>
        )
      })

    const flagModal = this.state.shouldShowFlagModal &&
      <FlagModal
        moveFromCategoryId={this.state.moveFromCategoryId}  // 0 represents "Uncategorized" folder
        documentIds={this.state.selectedArticleIds.map(id => id.toString())}
        onFlaggingStateChange={this.handleFlaggingStateChange}
        onClose={() => this.hideFlagModal()}
      />

    const articleDeleteConfirmModal = this.props.category.id === this.props.deleteModalCategoryId &&
      <ConfirmModal
        message="Are you sure you want to completely delete these articles?"
        okHandler={() => this.handleArticleDeleteOkClick()}
        cancelHandler={() => this.hideArticleDeleteConfirmModal()}
      />

    return (
      <div>
        <div className="article_listing">
          <div className="header-holder">
            {actions}
            {dateSortLink}
          </div>
          {flagModal}
          <div className="article-listing">
            <ul className="article-list">
              {flaggingList}
            </ul>
          </div>
        </div>
        {articleDeleteConfirmModal}
      </div>
    )
  }

  get sortedFlaggings() {
    return [...this.props.category.flaggings].sort((a, b) => {
      a = parseISO(a.article.publishedAt)
      b = parseISO(b.article.publishedAt)
      return this.state.sortOrder === 'desc' ? b - a : a - b
    })
  }

  toggleSort() {
    let sortOrder = 'desc'
    if (this.state.sortOrder === 'desc') {
      sortOrder = 'asc'
    }
    this.setState({sortOrder})
  }

  handleActionSelectChange(val) {
    this.setState({moveFromCategoryId: null}, () => {
      let {selectedArticleIds} = this.state
      const orderedArticleIds = this.sortedFlaggings.filter(
        f => selectedArticleIds.includes(f.directorId)
      ).map(f => f.directorId)
      if (val === 'export_pdf') {
        this.props.exportPDF(orderedArticleIds)
      } else if (val === 'export_docx') {
        this.props.exportDoc(orderedArticleIds)
      } else if (val === 'excel') {
        this.props.exportExcel(orderedArticleIds)
      } else if (val === 'email') {
        this.props.exportEmail(orderedArticleIds)
      } else if (val === 'flag') {
        this.showFlagModal()
      } else if (val === 'unflag') {
        this.removeFlags()
      } else if (val === 'delete') {
        this.showArticleDeleteConfirmModal()
      } else if (val === 'move-flag') {
        this.setState({moveFromCategoryId: this.props.category.id}, () => {
          this.showFlagModal()
        })
      }
    })
  }

  handleFlaggingCheckbox(articleId, isChecked) {
    let {selectedArticleIds} = this.state
    if (isChecked) {
      selectedArticleIds.push(articleId)
    } else {
      const idx = selectedArticleIds.indexOf(articleId)
      selectedArticleIds.splice(idx, 1)
    }
    this.setState({selectedArticleIds})
  }

  handleSelectAllCheckbox() {
    let selectedArticleIds = []
    if (this.state.selectedArticleIds.length !== this.props.category.flaggings.length) {
      selectedArticleIds = this.props.category.flaggings.map(article => {
        return article.directorId
      })
    }
    this.setState({selectedArticleIds})
  }

  handleArticleDeleteOkClick() {
    this.props.deleteArticles([...this.state.selectedArticleIds])
    this.setState({selectedArticleIds: []})
  }

  handleFlaggingStateChange = () => {
    this.setState({moveFromCategoryId: null, selectedArticleIds: [], shouldShowFlagModal: false})
    this.props.fetchCategories()
  }

  handlePromoteStateChange = () => {
    this.props.fetchPromotedSearches()
  }

  showArticleDeleteConfirmModal() {
    this.props.setDeleteModalCategoryId(this.props.category.id)
  }

  hideArticleDeleteConfirmModal() {
    this.props.setDeleteModalCategoryId(null);
  }

  isPromoted(article) {
    return article.feed && this.props.promotedFeedIds.includes(article.feed.id)
  }

  showFlagModal() {
    this.setState({shouldShowFlagModal: true})
  }

  hideFlagModal()  {
    this.setState({shouldShowFlagModal: false})
  }

  removeFlags() {
    this.props.deleteFlaggings({categoryId: this.props.category.id, articleIds: this.state.selectedArticleIds})
    this.setState({selectedArticleIds: []})
  }
}
