import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {getAppNameFull, getProp} from 'app/global/global-selectors'
import {getEntities} from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import {Feed, User} from 'app/models'
import BulkUserAssignment, {ASSIGNEE_TYPES} from 'app/reusable/BulkUserAssignment'
import ShareSearchBase from 'app/reusable/ShareSearchBase'

import {joinInSentence} from 'app/utils'
import {
  setAssignModalAssigneeIdsBySection,
  hideAssignModal,
  addAssignments,
  addFeaturedSources,
} from './sources-admin-actions'
import {getSourcesAdmin} from './sources-admin-selectors'
import AlertSettings from './AlertSettings'


@connect(
  createSelector(
    // I don't think it's idiomatic to have one createSelector call
    // as mapStateToProps, but I will leave it this way until it
    // becomes slow or otherwise necessary to change
    [getAppNameFull, getEntities, getSourcesAdmin, getProp('feedIds')],
    (appNameFull, entities, sourcesAdmin, feedIds) => {
      const orm = Orm.withEntities(entities)
      const users = orm.getByIds(User, sourcesAdmin.userIds)
      const feeds = orm.getByIds(Feed, feedIds)
      return {
        appNameFull,
        feedIds,
        users,
        feeds,
        departments: sourcesAdmin.departments,
        teams: sourcesAdmin.teams,
        firmLocations: sourcesAdmin.firmLocations,
        assignModalData: sourcesAdmin.assignModalData,
        assigneeIdsBySection: sourcesAdmin.assignModalData.assigneeIdsBySection,
        name: sourcesAdmin
      }
    }
  ),
  {
    setAssignModalAssigneeIdsBySection,
    hideAssignModal,
    addAssignments,
    addFeaturedSources,
  }
)
export default class AssignSourcesContent extends ShareSearchBase {
  static propTypes = {
    feedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    onBack: PropTypes.func,
  }

  state = {
    selectedSection: ASSIGNEE_TYPES.USER,
    selectedAddToOptions: {
      promoted: false,
      demoted: false,
      trusted: false,
      excluded: false,
    },
    screenNo: 1,
  }

  render() {

    const additional = this.props.feeds.filter(f => !f).length
    const feedNameList = this.props.feeds.filter(f => f).map(feed => feed.name)
    const feedNames = joinInSentence(feedNameList, additional)

    const {appNameFull} = this.props
    const {selectedAddToOptions} = this.state
    const {isFeaturing} = this.props.assignModalData

    const shareCount = this.shareWithAssigneeCount()
    const shareSummary =
      shareCount ?
        <p>{shareCount} Selected: {this.shareWithAssigneeNames()}</p>
        : null

    const backLink = this.props.onBack &&
      <div className="back-link">
        <a href="#" onClick={() => this.props.onBack()}>&lt;&lt; Back to All Assignments</a>
      </div>

    const screenView = this.state.screenNo === 1 ? <div>{backLink}
    {
      isFeaturing
        ? <h1>Make {feedNames} Featured for:</h1>
        : <h1>Assign {feedNames} to:</h1>
    }

    {shareSummary}

    <BulkUserAssignment
      feeds={this.props.feeds}
      users={this.props.users}
      departments={this.props.departments}
      teams={this.props.teams}
      firmLocations={this.props.firmLocations}
      onSectionChange={section => this.setSelectedSection(section)}
      onSelectedIdsChange={ids => this.onSelectedIdsChange(ids)}
      selectedIdsBySection={this.props.assigneeIdsBySection}
      showIndividualMembers={true}
      showDepartments={isFeaturing}
      showDepartmentMembers={true}
      showTeams={isFeaturing}
      showTeamMembers={true}
      showFirmLocations={true}
    />

    {
      !isFeaturing &&
      <div className="add-to">
        <strong className="afs-selected-label">Add to:</strong><br/>

        <label>
          <input
            type="checkbox"
            checked={selectedAddToOptions.promoted}
            disabled={selectedAddToOptions.excluded || selectedAddToOptions.demoted}
            onChange={this.onPromotedSelect}
          />
          <div className="label">
            <span className="title">Promoted Sources</span>
            <p className="description">
              Sources will be added into group or user's promoted sources and will rank higher in search algorithms.
            </p>
          </div>
        </label>

        <label>
          <input
            type="checkbox"
            checked={selectedAddToOptions.demoted}
            disabled={selectedAddToOptions.excluded || selectedAddToOptions.promoted}
            onChange={this.onDemotedSelect}
          />
          <div className="label">
            <span className="title">Demoted Sources</span>
            <p className="description">
              Sources will be added into group or user's demoted sources and will rank lower
              in search algorithms.
            </p>
          </div>
        </label>

        <label>
          <input
            type="checkbox"
            checked={selectedAddToOptions.trusted}
            disabled={selectedAddToOptions.excluded}
            onChange={this.onTrustedSelect}
          />
          <div className="label">
            <span className="title">Trusted Sources</span>
            <p className="description">
              Sources will be added into group or user's trusted sources and will also be included in email alerts.
            </p>
          </div>
        </label>

        <label>
          <input
            type="checkbox"
            checked={selectedAddToOptions.excluded}
            disabled={
              selectedAddToOptions.promoted || selectedAddToOptions.trusted ||
              selectedAddToOptions.demoted
            }
            onChange={this.onExcludedSelect}
          />
          <div className="label">
            <span className="title">Excluded Sources</span>
            <p className="description">
              Excluded sources will be blocked from your search results on {appNameFull}.
            </p>
          </div>
        </label>
      </div>
    }
    {isFeaturing &&
    <div className="buttons">
       <button
        className="save button"
        onClick={() => this.submit()}
        disabled={
          shareCount === 0 ||
          (
            !isFeaturing &&
            !this.isAddToOptionSelected()
          )
        }
      >
        {isFeaturing && 'Feature'}
      </button>
      <button
        className="cancel button"
        onClick={() => this.cancel()}
      >
        Cancel
      </button>
    </div>}
    </div> : !isFeaturing &&
      <div><AlertSettings submit_setting={() => this.submit()}
                          back_screen1={() => this.setState({screenNo: 1})}/>
    </div>
    return (
      <div className="assign-sources-content ">
        {screenView}
        {(!isFeaturing && this.state.screenNo !== 2) &&
          <div className="buttons">
            <button className="button next" onClick={() => this.setState({ screenNo: 2 })} disabled={shareCount === 0 ||
              !this.isAddToOptionSelected()}>Next Step
            </button>
          </div>
        }
      </div>
    )
  }

  addClick = (args) => {
    this.submit()
  }

  onPromotedSelect = (event) => {
    this.setState({
      selectedAddToOptions: {
        ...this.state.selectedAddToOptions,
        promoted: event.target.checked,
      },
    })
  }

    onDemotedSelect = (event) => {
    this.setState({
      selectedAddToOptions: {
        ...this.state.selectedAddToOptions,
        demoted: event.target.checked,
      },
    })
  }

  onTrustedSelect = (event) => {
    this.setState({
      selectedAddToOptions: {
        ...this.state.selectedAddToOptions,
        trusted: event.target.checked,
      },
    })
  }

  onExcludedSelect = (event) => {
    this.setState({
      selectedAddToOptions: {
        ...this.state.selectedAddToOptions,
        excluded: event.target.checked,
      },
    })
  }

  onSelectedIdsChange(ids) {
    let assigneeIdsBySection = {...this.props.assigneeIdsBySection}
    assigneeIdsBySection[this.state.selectedSection] = ids
    this.props.setAssignModalAssigneeIdsBySection(assigneeIdsBySection)
  }

  isAddToOptionSelected() {
    return Object.values(this.state.selectedAddToOptions).some(Boolean)
  }

  buttonname(args){
    this.isAddToOptionSelected()
  }

  submit() {
    const {selectedAddToOptions} = this.state
    const {assigneeIdsBySection, assignModalData} = this.props
    const { noticeConfig } = assignModalData
    const feedIds = this.props.feedIds
    const userIds = assigneeIdsBySection[ASSIGNEE_TYPES.USER]
    const groupIds = assigneeIdsBySection[ASSIGNEE_TYPES.GROUP]
    const groupIdsForMembers = assigneeIdsBySection[ASSIGNEE_TYPES.GROUP_MEMBER]
    const departmentIds = assigneeIdsBySection[ASSIGNEE_TYPES.DEPARTMENT]
    const departmentIdsForMembers = assigneeIdsBySection[ASSIGNEE_TYPES.DEPARTMENT_MEMBER]
    const teamIds = assigneeIdsBySection[ASSIGNEE_TYPES.TEAM]
    const teamIdsForMembers = assigneeIdsBySection[ASSIGNEE_TYPES.TEAM_MEMBER]
    const firmLocationIdsForMembers = assigneeIdsBySection[ASSIGNEE_TYPES.FIRM_LOCATION_MEMBER]
    if (this.props.assignModalData.isFeaturing) {
      this.props.addFeaturedSources({
        feedIds,
        userIds,
        groupIds,
        groupIdsForMembers,
        departmentIds,
        departmentIdsForMembers,
        teamIds,
        teamIdsForMembers,
        firmLocationIdsForMembers,
      })
    } else {
      this.props.addAssignments({
        feedIds,
        userIds,
        groupIds,
        groupIdsForMembers,
        departmentIdsForMembers,
        teamIdsForMembers,
        firmLocationIdsForMembers,
        isPromoted: selectedAddToOptions.promoted,
        isDemoted: selectedAddToOptions.demoted,
        isTrusted: selectedAddToOptions.trusted,
        isExcluded: selectedAddToOptions.excluded,
        noticeConfig,
      })
    }
  }

  cancel() {
    this.props.hideAssignModal()
    if (this.props.onBack) {
      this.props.onBack()
    }
  }
}
