import React from 'react'
import {render} from 'react-dom'
import {Provider} from 'react-redux'

import globalStore from 'app/store'

import Notifications from './Notifications'

export default function init(store = globalStore) {
  const container = document.getElementById('global-notifications')
  if (!container) return
  const component = (
    <Provider store={store}>
      <Notifications />
    </Provider>
  )
  render(component, container)
}
