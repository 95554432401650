import { handleActions } from 'redux-actions'

import * as dnbActions from './dun-and-bradstreet-actions'

const getInitialState = () => ({
  isLoading: false,
  moreInfoContact: undefined,
  data: undefined,
  displayConfirmation: false,
  content: {
    nameFilter: '',
    selectedEntityIds: [],
    isUpdating: false,
    companiesToggle: true,
    displayConfirmation: false,
  },
})

export default handleActions({
  [dnbActions.showDnbModal]: (state, action) => ({
    ...getInitialState(),
    moreInfoContact: action.payload.moreInfoContact,
  }),
  [dnbActions.fetchDnbData.request]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [dnbActions.fetchDnbData.success]: (state, action) => ({
    ...state,
    isLoading: false,
    data: action.payload.response.body.dnbData,
  }),
  [dnbActions.fetchDnbData.failure]: (state, action) => ({
    ...state,
    isLoading: false,
  }),

  // Content modal

  [dnbActions.showConfirmation]: (state, action) => ({
    ...state,
    displayConfirmation: true,
    content: {
      ...state.content,
      isUpdating: false,
    },
  }),
  [dnbActions.selectCompanies]: (state, action) => ({
    ...state,
    content: {
      ...state.content,
      selectedEntityIds: [
        ...state.content.selectedEntityIds,
        ...action.payload,
      ],
    },
  }),
  [dnbActions.deselectCompanies]: (state, action) => ({
    ...state,
    content: {
      ...state.content,
      selectedEntityIds:
        state.content.selectedEntityIds
          .filter(selectedId => !action.payload.includes(selectedId)),
    },
  }),
  [dnbActions.deselectAllCompanies]: (state, action) => ({
    ...state,
    content: {
      ...state.content,
      selectedEntityIds: getInitialState().content.selectedEntityIds,
    },
  }),
  [dnbActions.changeNameFilter]: (state, action) => ({
    ...state,
    content: {
      ...state.content,
      nameFilter: action.payload,
    },
  }),
  [dnbActions.showAddingIndicator]: (state, action) => ({
    ...state,
    content: {
      ...state.content,
      isUpdating: true,
    },
  }),
  [dnbActions.hideAddingIndicator]: (state, action) => ({
    ...state,
    content: {
      ...state.content,
      isUpdating: false,
    },
  }),
  [dnbActions.toggleCompaniesList]: (state, action) => ({
    ...state,
    content: {
      ...state.content,
      companiesToggle: !state.content.companiesToggle,
    },
  }),
}, getInitialState())
