import React, { Component } from 'react'
import { connect } from 'react-redux'
import './InsightsFrequencySettings.less'
import * as actions from 'app/email-notifications/email-notifications-actions'
import * as constants from 'app/email-notifications/email-notifications-constants'
import InputBlock from 'app/common/InputBlock'
import Button from 'app/common/Button'
import PropTypes from 'prop-types'
import SendZeroResults from "app/email-notifications/SendZeroResults"
import DeliveryDay from "app/email-notifications/DeliveryDay"
import TimeOfDay from "app/email-notifications/TimeOfDay"
import AdditionalRecipients from "app/email-notifications/AdditionalRecipients"
import InsightsCategoryContent from 'app/email-notifications/CategoryContent'
import classNames from "classnames"
import HideDuplicateArticles from "app/email-notifications/HideDuplicateArticles"
import * as globalSelectors from "app/global/global-selectors"


@connect(
  state => ({
    ccEmailsInsights: state.emailNotifications.ccEmailsInsights,
    insightsNoticeConfigs: state.emailNotifications.insightsNoticeConfigs,
    insightsSettings: state.emailNotifications.insightsSettings,
    editUserId: state.emailNotifications.emailDeliveryData.editUserId,
    insightsLabel: globalSelectors.getInsightsLabel(state),
  }),
  {
    changeSendSampleModal: actions.changeSendSampleModal,
    saveEmailAlertSettings: actions.api.saveEmailAlertSettings.request,
    getCCEmailsInsights: actions.api.getCCEmailsInsights.request,
    getInsightsNoticeConfigs: actions.api.getInsightsNoticeConfigs.request,
  },
)

export default class InsightsFrequencySettings extends Component {

  static propTypes = {
    noticeFrequency: PropTypes.string.isRequired,
  }

  componentDidMount() {
    this.props.getCCEmailsInsights({
      type: this.props.insightsSettings.notificationType,
      noticeFrequency: this.props.noticeFrequency,
      editUserId: this.props.editUserId,
    })
    this.props.getInsightsNoticeConfigs({
      editUserId: this.props.editUserId,
      type: this.props.insightsSettings.notificationType,
      noticeFrequency: this.props.noticeFrequency
    })
  }

  render() {
    const notificationType = this.props.insightsSettings.notificationType
    const frequencySettings = this.props.insightsSettings.settings.filter(
          setting => setting.noticeFrequency === this.props.noticeFrequency)

    const ccEmailInputWidth =
      this.props.noticeFrequency === constants.DAILY_FREQ ||
      this.props.noticeFrequency === constants.WEEKDAY_FREQ
      ? constants.CC_EMAIL_INPUT_WIDTH_WIDER
      : constants.CC_EMAIL_INPUT_WIDTH

    const frequencyHeader =
      this.props.noticeFrequency === constants.NONE_FREQ
      ? constants.INSIGHTS_EMAIL_FREQUENCIES[this.props.noticeFrequency]
      : `${constants.INSIGHTS_EMAIL_FREQUENCIES[this.props.noticeFrequency]} Email`

    const activeDeliveryDay = constants.DELIVERY_DAYS[frequencySettings.map(
      setting => setting.emailDeliveryWeekday)]

    const insightsLabel = this.props.insightsLabel

    const frequencyHelp = this.props.noticeFrequency === constants.DAILY_FREQ
      ? `Receive one email alert with relevant ${insightsLabel} articles each day.`
      : this.props.noticeFrequency === constants.WEEKDAY_FREQ
        ? `Receive one email alert with relevant ${insightsLabel} articles every weekday.`
        : this.props.noticeFrequency === constants.WEEKLY_FREQ
          ? `Receive one email alert with relevant ${insightsLabel} articles and ${insightsLabel} 
            Summary each week on ${activeDeliveryDay}.`
          : this.props.noticeFrequency === constants.NONE_FREQ
            ? `Do not receive email alerts with ${insightsLabel} articles.`
            : null

    const weekendOption =
      this.props.noticeFrequency === constants.DAILY_FREQ  ||
      this.props.noticeFrequency === constants.WEEKDAY_FREQ
      ? <InputBlock
          className={'input-label weekend-delivery'}
          label='Deliver on Weekends'
          isInline={true}
        >
          <input
            className={'input-checkbox'}
            type="checkbox"
            checked={this.props.insightsSettings.deliverOnWeekends}
            onChange={(e) => this.onDeliverOnWeekendsChange(e)}
          />
        </InputBlock>
      : null

    let hasFrequencyConfigs = false
    Object.values(this.props.insightsNoticeConfigs.configs).forEach(fc => {
      if (fc[this.props.noticeFrequency]){hasFrequencyConfigs = true}
    })

    const settingOptions = hasFrequencyConfigs && this.props.noticeFrequency !== constants.NONE_FREQ
      ? <div className={'settings-container'}>
          <div>
            <div className={"upper-settings"}>
              <TimeOfDay noticeFrequency={this.props.noticeFrequency}
                         notificationType={notificationType}/>
              <DeliveryDay noticeFrequency={this.props.noticeFrequency}
                           notificationType={notificationType}/>
              <AdditionalRecipients noticeFrequency={this.props.noticeFrequency}
                                    notificationType={notificationType}
                                    ccEmails={this.props.ccEmailsInsights}
                                    ccEmailInputWidth={ccEmailInputWidth}/>
            </div>
          </div>
          <div className={'lower-settings'}>
            <SendZeroResults noticeFrequency={this.props.noticeFrequency}
                             notificationType={notificationType}/>
            {weekendOption}
            <HideDuplicateArticles noticeFrequency={this.props.noticeFrequency}
                                   notificationType={notificationType}/>
            <Button
              className="send-sample-button"
              label={`Send Sample ${frequencyHeader}`}
              onClick={() => {this.onSendSampleButtonClick(frequencyHeader)}}
            />
          </div>
        </div>
      : null

    const jumpLinkId = this.props.noticeFrequency === constants.WEEKDAY_FREQ
      ? `insights-${constants.DAILY_FREQ}`
      : `insights-${this.props.noticeFrequency}`

    return (
      <div className={'signals-email-frequency-container'}>
        <div className={'frequency-header-container'}>
          <div
            className={
              classNames('frequency-header',
                        {'no-bottom-border': this.props.noticeFrequency === constants.NONE_FREQ})
            }
          >
            <a id={jumpLinkId}
               className={'freq-header-link'}
            >
              {frequencyHeader}
            </a>
            <span className={'frequency-header-help'}>{frequencyHelp}</span>
          </div>
        </div>
        {settingOptions}
        {this.getCategoryContent(frequencyHeader)}
      </div>
    )
  }
  getCategoryContent(frequencyHeader) {
    return(
      this.props.insightsSettings.searchCategories.map((cat, idx) =>
        <InsightsCategoryContent
          key={idx}
          category={cat}
          noticeFrequency={this.props.noticeFrequency}
          frequencyHeader={frequencyHeader}
        />
      )
    )
  }
  onDeliverOnWeekendsChange(event) {
    this.props.saveEmailAlertSettings({
      editUserId: this.props.editUserId,
      noticeFrequency: constants.WEEKDAY_FREQ,
      type: this.props.insightsSettings.notificationType,
      field: constants.DELIVER_ON_WEEKENDS,
      value: event.target.checked
    })
  }
  onSendSampleButtonClick(frequencyHeader) {
    this.props.changeSendSampleModal({
      show: true,
      notificationType: this.props.insightsSettings.notificationType,
      frequencyHeader: frequencyHeader,
      noticeFrequency: this.props.noticeFrequency,
      recipients: [this.props.insightsSettings.userEmail],
      editUserId: this.props.editUserId,
    })
  }
}
