import {graphqlQuery} from 'app/api'
import {args, gql} from 'app/graphql'

const flaggingPartial = `
  id
  directorId
  creditFeed {
    id
    sourceType
  }
  article {
    headline
    changeUrl
    publishedAt
    groupCount
    snippet
    feed {
      id
      name
      sourceType
    }
  }
`

const categoriesQuery = `
  categories: flagCategories() {
    id
    name
    isShared
    rssKey
    parent {
      id
      name
    }
    flaggings {
      ${flaggingPartial}
    }
  }
`

export function fetchFlaggingData({directorIds, userId,} = {}) {
  const query = gql`
    query {
      flagCategories(${args({userId, includeChildren: false})}) {
        ...flagCategory
        children {
          ...flagCategory
        }
      }
      
      flaggings(${args({directorIds, userId})}) {
        directorId
        category {
          id
        }
      }

      uncategorizedFlaggingsCount

      categoriesFlaggingsCount: flagCategories() {
          flaggingsCount {
          }
        }
    }
    
    fragment flagCategory on FlagCategory {
      id
      name
      parent {
        id
      }
    }
  `
  return graphqlQuery(query).then(({body: {data}}) => data)
}


// TODO: Merge the above query into this, so that we can return the new flagging
// data without needing a separate request.
export function setDocumentFlaggingState({
  documentIds,
  feedId,
  flagCategoryId = null,
  isFlagged,
  flagToUserId,
}) {
  const fieldName = isFlagged ? 'flagDocuments' : 'unflagDocuments'
  const flaggingArgs = {documentIds, categoryId: flagCategoryId, userId: flagToUserId}
  if (isFlagged && feedId) {
    flaggingArgs.feedId = feedId
  }
  const query = gql`
    mutation {
      result: ${() => fieldName}(${args(flaggingArgs)})
    }
    query {
      # Fetch the documents after flagging so we can refresh the entity store.
      documents(ids: ${documentIds}) {
        id
        isFlagged
      }
    }
  `
  return graphqlQuery(query).then(({body: {data}}) => data)
}


export function createFlagCategory({
  name,
  parentId = null,
  feedId,
  flagDocumentIds,
  flagToUserId,
}) {
  const fieldArgs = {name, parentId, feedId, flagDocumentIds, userId: flagToUserId}
  const query = gql`
    mutation {
      flagCategory: createFlagCategory(${args(fieldArgs)}) {
        id
        name
        parent {
          id
        }
        children {
          id
        }
      }
    }
  `
  return graphqlQuery(query).then(({body: {data}}) => data.flagCategory)
}
