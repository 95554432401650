import { handleActions } from 'redux-actions'
import * as profileBuilderActions from './profile-builder-actions'

const getInitialState = () => ({
  isLoading: false,
  messageData: {},
  currentStep: 1,
  browseModalCategory: null,
  searches: [],
  browseModalSelectedSearches: [],
  selectedSearches: [],
})

export default handleActions(
  {
    [profileBuilderActions.init]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [profileBuilderActions.showMessage]: (state, action) => ({
      ...state,
      isLoading: false,
      messageData: action.payload,
    }),
    [profileBuilderActions.hideMessage]: (state, action) => ({
      ...state,
      messageData: {},
    }),
    [profileBuilderActions.setCurrentStep]: (state, action) => ({
      ...state,
      currentStep: action.payload,
    }),
    [profileBuilderActions.fetchSearches]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [profileBuilderActions.showBrowseModal]: (state, action) => ({
      ...state,
      isLoading: false,
      browseModalCategory: action.payload.category,
      searches: action.payload.searches,
      browseModalSelectedSearches: [],
    }),
    [profileBuilderActions.hideBrowseModal]: (state, action) => ({
      ...state,
      browseModalCategory: null,
      searches: [],
    }),
    [profileBuilderActions.saveUser]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [profileBuilderActions.skip]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [profileBuilderActions.saveUserSuccess]: (state, action) => ({
      ...state,
      isLoading: false,
      currentStep: ++state.currentStep,
    }),
  },
  getInitialState(),
)
