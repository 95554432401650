import {all, takeLatest, put, select} from 'redux-saga/effects'
import {setQueryComponentState, resetSaveData} from 'app/firm-admin/searches/searches-admin-actions'
import {addProfileSearchIds, removeProfileSearches} from 'app/actions'
import {
  BROWSE_TYPE_GLOBAL,
  BROWSE_TYPE_FIRM_LIBRARY,
  BROWSE_TYPE_FEATURED,
} from './profile-searches-constants'
import {getProfileSearches} from './profile-searches-selectors'
import * as actions from 'app/actions'
import * as profileSearchesActions from './profile-searches-actions'
import * as profileSearchesApi from './profile-searches-api'
import * as entitiesActions from 'app/entities/entities-actions'
import * as notifications from 'app/global/notifications'
import {getEntityDataFromSearch, filtersByCategory} from 'app/utils/searches'
import {handleSagaError} from 'app/utils/errors'
import * as advancedSearchActions from 'app/advanced-search/advanced-search-actions'
import * as advancedSearchSelectors from 'app/advanced-search/advanced-search-selectors'
import * as entitiesSelectors from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import {SavedSearch} from 'app/models'
import * as reusableApi from 'app/reusable/api'
import {getAvailableFilters} from 'app/global/global-selectors'


function* handleInit(action) {
  yield* initializeTermFrequencyFilters()
  const category = action.payload.category
  const payload = {type: BROWSE_TYPE_FEATURED, category: category}
  yield* handleFetchBrowseModalSearches({payload})
}


function* handleHideEditSearchModal(action) {
  yield* initializeTermFrequencyFilters()
  yield put(setQueryComponentState({}))
  yield put(resetSaveData())
}


function* handleCreateSearches(action) {
  const userId = yield select(state => state.currentUser)
  let response = null
  try {
    response = yield profileSearchesApi.createSearches({...action.payload, userId})
  } catch(error) {
    yield* handleSagaError(error)
    yield put(profileSearchesActions.hideLoader())
    return
  }
  const searches = {}
  const newSearchIds = []
  Object.values(response).forEach(ss => {
    searches[ss.id] = {...ss}
    newSearchIds.push(ss.id)
  })
  const entities = {
    searches,
  }
  yield put(entitiesActions.update(entities))
  yield put(addProfileSearchIds(newSearchIds))
  const addedCount = action.payload.searchIds.length + action.payload.searchPhrases.length
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: `${addedCount === 1 ? 'Search' : 'Searches'} added successfully.`,
    })
  )
  yield put(profileSearchesActions.hideLoader())
}


function* handleFetchSearch(action) {
  let response = null
  try {
    response = yield profileSearchesApi.fetchSearch(action.payload)
  } catch(error) {
    yield* handleSagaError(error)
    return
  }
  const search = {...response.search}
  const entities = getEntityDataFromSearch(search)
  yield put(advancedSearchActions.setPendingTermFrequencyFilters(search.termFrequencyFilters))
  yield put(entitiesActions.update(entities))
  yield put(profileSearchesActions.fetchSearchComplete(action.payload))
  const availableFilters = yield select(getAvailableFilters)
  if (!availableFilters) {
    yield fetchFilters()
  }
  yield put(profileSearchesActions.hideLoader())
}


function* fetchFilters() {
  let response = null
  try {
    response = yield reusableApi.fetchAvailableFilters()
  } catch(error) {
    yield* handleSagaError(error)
    return
  }
  const searches = {}
  response.firmLibrarySearches.forEach(search => {
    search.isFirmLibrary = true
    search.ownerId = search.owner.id
    searches[search.id] = {...search}
  })
  response.availableFilterCategories.forEach(fc => {
    fc.searches.forEach(search => {
      search.ownerId = search.owner.id
      searches[search.id] = {...search}
      search.children.forEach(search => {
        search.ownerId = search.owner.id
        searches[search.id] = {...search}
      })
    })
  })
  yield put(entitiesActions.update({searches}))
  const availableFiltersByCategory = filtersByCategory(response.availableFilterCategories)
  yield put(actions.setAvailableFilters(availableFiltersByCategory))
  yield put(actions.setFirmSourceLabels(response.firmSourceLabels))
}


function* handleFetchSearchResults(action) {
  try {
    const { searchId, query, queryType, filters, selectedLanguageIds, } = action.payload
    let termFrequencyFilters = []
    if (queryType === 'raw') {
      termFrequencyFilters = yield select(advancedSearchSelectors.pendingTermFrequencyFilters)
    }
    const response = yield profileSearchesApi.fetchSearchResults({id: searchId, query, queryType,
      filters, termFrequencyFilters, selectedLanguageIds})
    yield put(profileSearchesActions.fetchSearchResultsComplete(response.savedSearch.results))
  } catch (error) {
    yield* handleSagaError(error)
    yield put(profileSearchesActions.hideLoader())
  }
}


function* handleUpdateSearch(action) {
  const {
    searchId,
    isMakeCopy,
    name,
    scope,
    category,
    query,
    filters,
    noticeConfig,
    solrSearchField,
    selectedLanguageIds,
    groupingLevel
  } = action.payload
  const params = {
    id: searchId,
    isMakeCopy,
    name,
    scope,
    category,
    searchPhrase: query,
    filters,
    noticeConfig,
    solrSearchField,
    groupingLevel
  }
  params.termFrequencyFilters =
    yield select(advancedSearchSelectors.pendingTermFrequencyFilters)

  if (selectedLanguageIds) {
    params.selectedLanguageIds = selectedLanguageIds.split(',').map(id => parseInt(id))
  }

  try {
    const response = yield profileSearchesApi.updateSearch(params)
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Search updated successfully.',
      })
    )
    const search = {...response.search}
    const entities = getEntityDataFromSearch(search)
    yield put(entitiesActions.update(entities))
    if (isMakeCopy) {
      yield put(addProfileSearchIds([search.id]))
    }
    yield put(profileSearchesActions.updateSearchComplete())
  } catch(error) {
    yield* handleSagaError(error)
    yield put(profileSearchesActions.hideLoader())
  }
}


function* initializeTermFrequencyFilters() {
  yield put(advancedSearchActions.initializePendingTermFrequencyFilters())
  yield put(advancedSearchActions.initializeTermFrequencyFilter())
}


function* handleShowEditSearchModal(action) {
  const searchId = action.payload
  const search = yield select(state => {
    const entities = entitiesSelectors.getEntities(state)
    const orm = Orm.withEntities(entities)
    return orm.getById(SavedSearch, searchId)
  })
  if (search.queryType === 'raw'){
    yield put(profileSearchesActions.fetchSearch(searchId))
  }
}

function* handleDeleteSearches(action) {
  try {
    yield profileSearchesApi.deleteSearches(action.payload)
  } catch(error) {
    yield* handleSagaError(error)
    yield put(profileSearchesActions.hideLoader())
    return
  }
  const searches = action.payload.map(id => {
    return ({
      id,
    })
  })
  // `removeProfileSearches` also removes from entities
  yield put(removeProfileSearches(searches))
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: `${action.payload.length === 1 ? 'Search' : 'Searches'} deleted successfully.`,
    })
  )
  yield put(profileSearchesActions.hideLoader())
}

function* handleFetchBrowseModalSearches(action) {
  let response = null
  const searches = {}
  const searchIds = []
  const selectedIds = []
  const {type} = action.payload
  let apiMethod = profileSearchesApi.fetchSearches
  let params = {
    category: action.payload.category,
  }
  if (type === BROWSE_TYPE_GLOBAL) {
    params.scope = SavedSearch.SCOPES.GLOBAL
  } else if (type === BROWSE_TYPE_FIRM_LIBRARY) {
    params.scope = SavedSearch.SCOPES.SHARED
    params.isFirmLibrary = true
  } else if (type === BROWSE_TYPE_FEATURED) {
    apiMethod = profileSearchesApi.fetchFeaturedSearches
  }
  try {
    response = yield apiMethod(params)
  } catch(error) {
    yield* handleSagaError(error)
    yield put(profileSearchesActions.hideLoader())
    return
  }
  response.savedSearches.forEach(ss => {
    searches[ss.id] = {...ss}
    searchIds.push(ss.id)
  })
  yield put(entitiesActions.update({searches}))
  params = {
    category: action.payload.category,
    ids: searchIds,
  }
  if (type === BROWSE_TYPE_GLOBAL) {
    yield put(profileSearchesActions.setGlobalSearchIdsByCategory(params))
  } else if (type === BROWSE_TYPE_FIRM_LIBRARY) {
    yield put(profileSearchesActions.setFirmLibrarySearchIdsByCategory(params))
  } else if (type === BROWSE_TYPE_FEATURED) {
    yield put(profileSearchesActions.setFeaturedSearchIdsByCategory(params))
  }
  /**
   * we already have user searches on load, but we still need `copyOfId`. we don't want to add that to data
   * fetched on load because it's expensive and only needed for the browse modal.
   * we also don't want to combine this fetch with `fetchSearches` above because we don't need `copyOfId` there either.
   */
  const userSearches = yield fetchUserSearches()
  userSearches.forEach(ss => {
    if (ss.copyOfId && searchIds.includes(ss.copyOfId)) {
      selectedIds.push(ss.copyOfId)
    }
  })
  yield put(profileSearchesActions.setBrowseModalSelectedIds(selectedIds))
  yield put(profileSearchesActions.hideLoader())
}

function* fetchUserSearches() {
  const userId = yield select(state => state.currentUser)
  const profileSearches = yield select(getProfileSearches)
  const categories = [profileSearches.category]
  if (profileSearches.category === 'client') {
    categories.push('prospect')
  }
  let response = null
  const searches = {}
  while (categories.length > 0) {
    const category = categories.shift()
    try {
      response = yield profileSearchesApi.fetchUserSearches({
        category,
        ownerId: userId,
      })
    } catch(error) {
      yield* handleSagaError(error)
      yield put(profileSearchesActions.hideLoader())
      return
    }
    response.savedSearches.forEach(ss => {
      searches[ss.id] = {...ss}
    })
  }
  yield put(entitiesActions.update({searches}))
  return Object.values(searches)
}

function* handleApplyBrowseModalSearches(action) {
  /**
   * send selected ids (global or FL) for adding to profile (any not included are removed)
   */
  const {type, category, ids, removedIds} = action.payload
  let apiMethod = null
  if (type === BROWSE_TYPE_GLOBAL) {
    apiMethod = profileSearchesApi.setUserGlobalSearches
  } else if (type === BROWSE_TYPE_FIRM_LIBRARY) {
    apiMethod = profileSearchesApi.setUserFirmLibrarySearches
  } else if (type === BROWSE_TYPE_FEATURED) {
    apiMethod = profileSearchesApi.setUserFeaturedSearches
  }
  let response = null
  try {
    response = yield apiMethod(ids, category)
  } catch(error) {
    yield* handleSagaError(error)
  }
  if (response) {
    const searches = {}
    const newSearchIds = []
    response.searches.forEach(ss => {
      searches[ss.id] = {...ss}
      newSearchIds.push(ss.id)
    })
    yield put(entitiesActions.update({searches}))
    yield put(removeProfileSearches([...removedIds.map(id => ({id}))]))
    yield put(addProfileSearchIds(newSearchIds))
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: `${ids.length === 1 ? 'Search' : 'Searches'} added successfully.`,
      })
    )
  }
  yield put(profileSearchesActions.resetBrowseModalData())
  yield put(profileSearchesActions.hideLoader())
}

function* handleRemoveExcludedFeedsFromSearch(action) {
  const {feedIds, searchId} = action.payload
  let response = null
  try {
    response = yield reusableApi.removeExcludedFeedsFromSearch({feedIds, searchId})
  } catch (error) {
    yield* handleSagaError(error)
    return
  }
  yield put(profileSearchesActions.fetchSearch(searchId))
}

export default function*() {
  yield all([
    takeLatest(
      profileSearchesActions.init,
      handleInit,
    ),
    takeLatest(
      profileSearchesActions.hideEditSearchModal,
      handleHideEditSearchModal,
    ),
    takeLatest(
      profileSearchesActions.createSearches,
      handleCreateSearches,
    ),
    takeLatest(
      profileSearchesActions.fetchSearch,
      handleFetchSearch,
    ),
    takeLatest(
      profileSearchesActions.fetchSearchResults,
      handleFetchSearchResults,
    ),
    takeLatest(
      profileSearchesActions.updateSearch,
      handleUpdateSearch,
    ),
    takeLatest(
      profileSearchesActions.showEditSearchModal,
      handleShowEditSearchModal,
    ),
    takeLatest(
      profileSearchesActions.deleteSearches,
      handleDeleteSearches,
    ),
    takeLatest(
      profileSearchesActions.fetchBrowseModalSearches,
      handleFetchBrowseModalSearches,
    ),
    takeLatest(
      profileSearchesActions.applyBrowseModalSearches,
      handleApplyBrowseModalSearches
    ),
    takeLatest(
      profileSearchesActions.removeExcludedFeedsFromSearch,
      handleRemoveExcludedFeedsFromSearch
    ),
  ])
}
