import * as R from 'ramda'
import { handleActions } from 'redux-actions'
import Cookie from 'js-cookie'

import * as searchesAdminActions from './searches-admin-actions'
import { DEFAULT_COLUMNS_VIEW } from './searches-admin-constants'

const getDefaultSearchColumns = () => {
  try {
    const encodedValue = Cookie.get('ss_columns')
    const columns = encodedValue ? window.atob(encodedValue): undefined
    return columns ? JSON.parse(columns) : [...DEFAULT_COLUMNS_VIEW]
  } catch (error) {
    return [...DEFAULT_COLUMNS_VIEW]
  }
}

const setSearchColumns = (value) => {
  const columns = window.btoa(JSON.stringify(value))
  Cookie.set('ss_columns', columns)
}

const getInitialState = () => ({
  // init data
  noticeFrequencies: null,
  isLargeFirm: false,
  activeUserCount: 0,
  activeGroupCount: 0,

  isLoading: false,
  isAssigneesFetched: false,
  isCreating: false,
  isSharing: false,

  // start as null to differentiate between not fetched yet, and fetched with no results.
  searchIds: null,

  // needed for share modal
  userIds: [],
  departments: [],
  teams: [],
  firmLocations: [],
  areAllUsersFetched: false,

  currentPage: 1,
  itemsPerPage: 50,
  totalCount: 0,
  filters: {
    name: '',
    ownerId: null,
    scope: '',
    category: '',
    includeTrustedSources: true,
  },
  sortField: 'name',
  sortDirection: 'asc',
  selectedSearchIds: [],
  deleteSearchIds: [],
  searchResults: {},

  // Modals
  addNewModalData: {
    isOpen: false,
    step: 1,
  },
  shareModalData: {
    step: 1,

    // key = user type (user, group, team, etc..), value = array of user ids.
    assigneeIdsBySection: {},

    noticeConfig: {
      frequency: '',
      maxItems: {},
    },
  },
  shouldShowEditModal: false,
  editModalData: {
    searchId: undefined,
    tab: undefined,
    query: {
      terms: [],
      filters: [],
    },
  },
  editSourceFolderModal: undefined,
  shouldShowAssignModal: false,
  assignType: 'add', // or 'copy'
  assignModalData: {},

  // this stores the search data to be saved; used by create, and tier3 save.
  saveData: {
    searchId: undefined,
    name: undefined,

    // only used when creating. if false, search is added to current user.
    isFirmLibrary: true,

    scope: undefined,
    category: undefined,
    queryType: 'any', // 'raw', 'all'
    coreSearchValues: null, // starts as null to identify that no changes have been made; set to an array when changes made
    coreSearchRawValue: null,
    filters: undefined, // starts as undefined to identify that no changes have been made; set to an array when changes made
    solrSearchField: undefined,

    // key = user type (user, group, member, department, team), value = array of user ids.
    assigneeIdsBySection: {},

    shareOnlyId: null,
    noticeConfig: {
      frequency: '',
      maxItems: {},
    },
  },

  // this stores the state of the SavedSearchFilters component; used by multi step/tab modals like add new and tier3 save
  queryComponentState: {},

  /**
   * this stores the ids of users whose searches were already fetched (to prevent unnecessary re-fetching).
   * we need to fetch user searches to populate the filters component for searches not owned by the current user.
   */
  searchesFetchedForUserIds: [],

  fullyFetchedSearchIds: [],
  canShowRelevanceFilter: false,

  shouldShowBulkAddFiltersModal: false,

  featureModalData: {
    isOpen: false,
    assigneeIdsBySection: {},
  },
  shouldShowManageLabelsModal:false,
  newLabelData: {
    name: '',
    parentId: undefined,
  },
  //adding for manage labels
  allParentLabels: null,
  labels: null,
  manageLabelsFilter: {
    currentPage: 1,
    itemsPerPage: 10,
    searchLabel: '',
    sortField: 'name',
    sortDirection: 'asc',
  },
  manageLabelsTotalCount:0,
  manageLabelsModalData: {
    id: null,
    operation: '',  // edit, delete
    name: undefined,
    parentId: undefined,
  },
  shouldShowBulkEditLabelModal: false,
  selectedColumns: getDefaultSearchColumns(),
})

export default handleActions(
  {
    [searchesAdminActions.init]: (state, action) => ({
      ...state,
      firmLibrarySearchFilters: action.payload.firmLibrarySearchFilters,
      noticeFrequencies: action.payload.noticeFrequencies,
      isLargeFirm: action.payload.isLargeFirm,
      activeUserCount: action.payload.activeUserCount,
      activeGroupCount: action.payload.activeGroupCount,
    }),
    [searchesAdminActions.hideLoader]: (state, action) => ({
      ...state,
      isLoading: false,
    }),
    [searchesAdminActions.fetchSearches]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [searchesAdminActions.fetchSearchesComplete]: (state, action) => ({
      ...state,
      isLoading: false,
      searchIds: action.payload.searchIds,
      totalCount: action.payload.totalCount,
    }),
    [searchesAdminActions.fetchAllSearchIds]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [searchesAdminActions.fetchSearch]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [searchesAdminActions.fetchSearchComplete]: (state, action) => ({
      ...state,
      isLoading: false,
      fullyFetchedSearchIds: [
        ...state.fullyFetchedSearchIds,
        action.payload.searchId,
      ],
      searchesFetchedForUserIds: [
        ...state.searchesFetchedForUserIds,
        action.payload.ownerId,
      ],
    }),
    [searchesAdminActions.fetchFilters]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [searchesAdminActions.updateSearch]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [searchesAdminActions.createSearches]: (state, action) => ({
      ...state,
      isLoading: true,
      isCreating: true,
    }),
    [searchesAdminActions.createSearchesComplete]: (state, action) => ({
      ...state,
      isCreating: false,
      shouldShowAssignModal: false,
      shareModalData: getInitialState().shareModalData,
      selectedSearchIds: [],
      assignType: getInitialState().assignType,
    }),
    [searchesAdminActions.shareSearches]: (state, action) => ({
      ...state,
      isLoading: true,
      isSharing: true,
    }),
    [searchesAdminActions.shareSearchesComplete]: (state, action) => ({
      ...state,
      isLoading: false,
      isSharing: false,
      shouldShowAssignModal: false,
      shareModalData: getInitialState().shareModalData,
      selectedSearchIds: [],
      assignType: getInitialState().assignType,
    }),
    [searchesAdminActions.deleteSearches]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [searchesAdminActions.deleteSearchesComplete]: (state, action) => ({
      ...state,
      isLoading: false,
      selectedSearchIds: state.selectedSearchIds.filter(id => !action.payload.includes(id)),
      searchIds: state.searchIds.filter(id => !action.payload.includes(id)),
      deleteSearchIds: [],
    }),
    [searchesAdminActions.sendSearchesCsvEmail]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [searchesAdminActions.getSearchResults]: (state, action) => ({
      ...state,
      isLoading: true,
      searchResults: {},
    }),
    [searchesAdminActions.getSearchResultsComplete]: (state, action) => ({
      ...state,
      isLoading: false,
      searchResults: action.payload,
    }),
    [searchesAdminActions.createAndShareSearch]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [searchesAdminActions.createAndShareSearchComplete]: (state, action) => ({
      ...state,
      isLoading: false,
      addNewModalData: getInitialState().addNewModalData,
      saveData: getInitialState().saveData,
    }),
    [searchesAdminActions.selectSearch]: (state, action) => ({
      ...state,
      selectedSearchIds: [...state.selectedSearchIds, action.payload],
    }),
    [searchesAdminActions.setDeleteSearchIds]: (state, action) => ({
      ...state,
      deleteSearchIds: action.payload,
    }),
    [searchesAdminActions.setSelectedSearchIds]: (state, action) => ({
      ...state,
      isLoading: false,
      selectedSearchIds: R.union(state.selectedSearchIds, action.payload),
    }),
    [searchesAdminActions.deselectSearch]: (state, action) => ({
      ...state,
      selectedSearchIds: state.selectedSearchIds.filter(id => id !== action.payload),
    }),
    [searchesAdminActions.deselectAllSearches]: (state, action) => ({
      ...state,
      selectedSearchIds: getInitialState().selectedSearchIds,
    }),
    [searchesAdminActions.changeCurrentPage]: (state, action) => ({
      ...state,
      isLoading: true,
      currentPage: action.payload,
    }),
    [searchesAdminActions.changeFilters]: (state, action) => ({
      ...state,
      filters: action.payload,
      selectedSearchIds: [],
      currentPage: 1
    }),
    [searchesAdminActions.changeSort]: (state, action) => ({
      ...state,
      isLoading: true,
      sortField: action.payload.field,
      sortDirection: action.payload.direction,
    }),
    [searchesAdminActions.showAddNewModal]: (state, action) => ({
      ...state,
      queryComponentState: {},
      addNewModalData: {
        ...state.addNewModalData,
        isOpen: true,
        step: 1,
      },
    }),
    [searchesAdminActions.hideAddNewModal]: (state, action) => ({
      ...state,
      addNewModalData: getInitialState().addNewModalData,
    }),
    [searchesAdminActions.addNewModalSetStep]: (state, action) => ({
      ...state,
      addNewModalData: {
        ...state.addNewModalData,
        step: action.payload,
      },
    }),
    [searchesAdminActions.setSaveData]: (state, action) => ({
      ...state,
      saveData: {
        ...state.saveData,
        ...action.payload,
      },
    }),
    [searchesAdminActions.resetSaveData]: (state, action) => ({
      ...state,
      saveData: getInitialState().saveData,
    }),
    [searchesAdminActions.setQueryComponentState]: (state, action) => ({
      ...state,
      queryComponentState: action.payload,
    }),
    [searchesAdminActions.showEditSearchModal]: (state, action) => ({
      ...state,
      isLoading: true,
      queryComponentState: {},
      shouldShowEditModal: true,
      editModalData: {
        ...getInitialState().editModalData,
        searchId: action.payload.searchId,
        tab: action.payload.tab,
      },
    }),
    [searchesAdminActions.hideEditSearchModal]: (state, action) => ({
      ...state,
      shouldShowEditModal: false,
      editModalData: getInitialState().editModalData,
      queryComponentState: {},
      saveData: getInitialState().saveData,
      manageLabelsFilter: getInitialState().manageLabelsFilter,
      labels: getInitialState().labels,
      manageLabelsTotalCount: getInitialState().manageLabelsTotalCount,
    }),
    [searchesAdminActions.showEditSourceFolderModal]: (state, action) => ({
      ...state,
      isLoading: false,
      editSourceFolderModal: {
        searchId: action.payload.searchId,
      },
    }),
    [searchesAdminActions.hideEditSourceFolderModal]: (state, action) => ({
      ...state,
      editSourceFolderModal: getInitialState().editSourceFolderModal,
      editModalData: getInitialState().editModalData,
    }),
    [searchesAdminActions.showAssignModal]: (state, action) => ({
      ...state,
      shouldShowAssignModal: true,
      selectedSearchIds: action.payload,
    }),
    [searchesAdminActions.fetchAssignees]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [searchesAdminActions.fetchAssigneesComplete]: (state, action) => ({
      ...state,
      isLoading: false,
      isAssigneesFetched: true,
      userIds: action.payload.userIds,
      departments: action.payload.departments || [],
      teams: action.payload.teams || [],
      firmLocations: action.payload.firmLocations || [],
    }),
    [searchesAdminActions.fetchUsers]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [searchesAdminActions.fetchUsersComplete]: (state, action) => ({
      ...state,
      isLoading: false,
      userIds: action.payload.userIds,
      areAllUsersFetched: action.payload.areAllUsersFetched,
    }),
    [searchesAdminActions.setUserIds]: (state, action) => ({
      ...state,
      userIds: action.payload,
    }),
    [searchesAdminActions.setAssignType]: (state, action) => ({
      ...state,
      assignType: action.payload,
    }),
    [searchesAdminActions.hideAssignModal]: (state, action) => ({
      ...state,
      assignType: getInitialState().assignType,
      shouldShowAssignModal: false,
      shareModalData: getInitialState().shareModalData,
      selectedSearchIds: [],
    }),
    [searchesAdminActions.setShareModalData]: (state, action) => ({
      ...state,
      shareModalData: {
        ...state.shareModalData,
        ...action.payload,
      },
    }),
    [searchesAdminActions.setCanShowRelevanceFilter]: (state, action) => ({
      ...state,
      canShowRelevanceFilter: action.payload,
    }),
    [searchesAdminActions.removeExcludedFeedsFromSearch]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [searchesAdminActions.setShouldShowBulkAddFiltersModal]: (state, action) => ({
      ...state,
      shouldShowBulkAddFiltersModal: action.payload,
      saveData: getInitialState().saveData,
      queryComponentState: {},
    }),
    [searchesAdminActions.applyFiltersToSearches]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [searchesAdminActions.setFeatureModalData]: (state, action) => ({
      ...state,
      featureModalData: {
        ...state.featureModalData,
        ...action.payload,
      }
    }),
    [searchesAdminActions.addFeaturedSearches]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [searchesAdminActions.removeFeaturedSearches]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [searchesAdminActions.showSearchesManageLabelsModal]: (state, action) => ({
      ...state,
      isLoading: action.payload,
      shouldShowManageLabelsModal: action.payload,
      manageLabelsModalData: getInitialState().manageLabelsModalData,
      newLabelData: getInitialState().newLabelData,
      manageLabelsFilter: getInitialState().manageLabelsFilter,
      labels: getInitialState().labels,
      manageLabelsTotalCount: getInitialState().manageLabelsTotalCount,
    }),
    [searchesAdminActions.setSearchesNewLabelData]: (state, action) => ({
      ...state,
      newLabelData: {
        ...state.newLabelData,
        ...action.payload,
      },
    }),
    [searchesAdminActions.setSearchesManageLabelsFilters]: (state, action) => ({
      ...state,
      isLoading: true,
      labels: null, 
      manageLabelsFilter: { ...state.manageLabelsFilter, ...action.payload }
    }),
    [searchesAdminActions.fetchSearchesParentLabelsComplete]: (state, action) => ({
      ...state,
      allParentLabels: action.payload,
    }),
    [searchesAdminActions.setSearchesManageLabelsTotalCount]: (state, action) => ({
      ...state,
      isLoading: false,
      manageLabelsTotalCount: action.payload

    }),
    [searchesAdminActions.fetchSearchesLabelsComplete]: (state, action) => ({
      ...state,
      isLoading: false,
      labels: action.payload,
    }),
    [searchesAdminActions.setSearchesManageLabelsModalData]: (state, action) => ({
      ...state,
      manageLabelsModalData: {
        ...state.manageLabelsModalData,
        ...action.payload,
      },
    }),
    [searchesAdminActions.resetSearchesManageLabelsModalData]: (state, action) => ({
      ...state,
      manageLabelsModalData: getInitialState().manageLabelsModalData,
    }),
    [searchesAdminActions.createSearchesLabel]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [searchesAdminActions.resetSearchesNewLabelData]: (state, action) => ({
      ...state,
      newLabelData: getInitialState().newLabelData,
    }),
    [searchesAdminActions.saveSearchesLabel]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [searchesAdminActions.deleteSearchesLabel]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [searchesAdminActions.updateSearchesLabels]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [searchesAdminActions.setBulkEditLabelModalData]: (state, action) => ({
      ...state,
      isLoading: action.payload,
      shouldShowBulkEditLabelModal: action.payload,
      manageLabelsFilter: getInitialState().manageLabelsFilter,
      labels: getInitialState().labels,
      manageLabelsTotalCount: getInitialState().manageLabelsTotalCount,
    }),
    [searchesAdminActions.updateBulkEditSearchesLabels]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [searchesAdminActions.resetBulkEditLabelModal]: (state, action) => ({
      ...state,
      shouldShowBulkEditLabelModal: false,
    }),
    [searchesAdminActions.showSelectedColumns]: (state, action) => {
      setSearchColumns(action.payload)
      return {
        ...state,
        selectedColumns: action.payload,
      }
    },
  },

  getInitialState(),
)
