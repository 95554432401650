import { all, takeLatest, call, put, delay, select } from 'redux-saga/effects'

import {getMIS} from './mis-selectors'
import * as misActions from './mis-actions'
import * as misApi from './mis-api'


function* hideNotification() {
  yield delay(5000)
  yield put(misActions.hideNotification())
}

function* handleGetReports(action) {
  try {
    const response = yield call(misApi.getReports)
    const reports = {}
    const recipients = {}
    const archives = {}
    const reportsWithFetchedRecipients = []
    response.body.reports.forEach(report => {
      // recipients and archives for external reports are fetched separately.
      if (report.hasOwnProperty('recipients')) {
        recipients[report.id] = [...report.recipients]
        delete report.recipients
        reportsWithFetchedRecipients.push(report.id)
      }
      if (report.hasOwnProperty('archives')) {
        archives[report.id] = [...report.archives]
        delete report.archives
      }
      reports[report.id] = report
    })
    yield put(misActions.setReports(reports))
    yield put(misActions.addReportsWithFetchedRecipients(reportsWithFetchedRecipients))
    yield put(misActions.setRecipients(recipients))
    yield put(misActions.setArchives(archives))
    yield put(misActions.setCurrentUserReportIds(response.body.currentUserReportIds))
  } catch(error) {
    yield put(misActions.showNotification({
      type: 'error',
      message: 'There was an error processing your request. Please try again.',
    }))
  }
}

function* handleGetArchives(action) {
  try {
    const response = yield call(misApi.getArchives, action.payload)
    // response.body = {[reportId]: array of archives, ...}
    yield put(misActions.setArchives(response.body))
  } catch(error) {
    yield put(misActions.showNotification({
      type: 'error',
      message: 'There was an error processing your request. Please try again.',
    }))
  }
}

function* handleGetRecipients(action) {
  try {
    const { reportType, reportId } = action.payload
    const response = yield call(misApi.getRecipients, reportType, reportId)
    yield put(misActions.setRecipients({[reportId]: response.body}))
    yield put(misActions.addReportsWithFetchedRecipients([reportId]))
    yield put(misActions.setCurrentReportId(reportId))
  } catch(error) {
    yield put(misActions.showNotification({
      type: 'error',
      message: 'There was an error processing your request. Please try again.',
    }))
  }
}

function* handleGetRenderedReport(action) {
  try {
    const response = yield call(misApi.getRenderedReport, action.payload)
    yield put(misActions.setRenderedReportHtml({id: action.payload.id, html: response.text}))
    yield put(misActions.showRenderedReport(action.payload))
  } catch(error) {
    yield put(misActions.showNotification({
      type: 'error',
      message: 'There was an error processing your request. Please try again.',
    }))
  }
}

function* handleDownloadRenderedReportPDF(action) {
  try {
    let url = `/api/v2/publish3r/view-rendered-publication/?id=${action.payload.id}&pdf=true`
    if (action.payload.url) {
      url += `&url=${action.payload.url}`
    }
    window.location.href = url
    yield put(misActions.hideLoader())
  } catch(error) {
    yield put(misActions.showNotification({
      type: 'error',
      message: 'There was an error processing your request. Please try again.',
    }))
  }
}

function* handleAddRecipient(action) {
  const mis = yield select(getMIS)
  const currentUser = mis.currentUserData
  const currentUserReportIds = [...mis.currentUserReportIds]
  const reportId = action.payload.report.id
  try {
    const response = yield call(misApi.saveSubscription, {
        operation: 'create',
        reportId,
        reportSource: action.payload.report.source,
        reportType: action.payload.report.type,
        firstName: action.payload.hasOwnProperty('recipient') ? action.payload.recipient.firstName : null,
        lastName: action.payload.hasOwnProperty('recipient') ? action.payload.recipient.lastName : null,
        emailAddress: action.payload.hasOwnProperty('recipient') ? action.payload.recipient.emailAddress : null,
      }
    )
    if ('error' in response.body) {
      yield put(misActions.showNotification({
        type: 'error',
        message: response.body.error,
      }))
    } else {
      yield put(misActions.addRecipientSuccess({
        reportId,
        recipient: response.body.recipient,
      }))
      if (!action.payload.hasOwnProperty('recipient') || (currentUser.emailAddress === action.payload.recipient.emailAddress)) {
        currentUserReportIds.push(reportId)
        yield put(misActions.setCurrentUserReportIds(currentUserReportIds))
      }
      yield put(misActions.showNotification({
        type: 'success',
        message: 'The recipient was added successfully.',
      }))
    }
  } catch(error) {
    yield put(misActions.showNotification({
      type: 'error',
      message: 'Invalid email address. Please try again.',
    }))
  }
}

function* handleSaveRecipient(action) {
  try {
    const response = yield call(misApi.saveSubscription, {
        operation: 'update',
        reportId: action.payload.report.id,
        reportSource: action.payload.report.source,
        reportType: action.payload.report.type,
        firstName: action.payload.editedRecipient.firstName,
        lastName: action.payload.editedRecipient.lastName,
        emailAddress: action.payload.editedRecipient.emailAddress,
        oldEmailAddress: action.payload.recipient.emailAddress,
      }
    )
    if ('error' in response.body) {
      yield put(misActions.showNotification({
        type: 'error',
        message: response.body.error,
      }))
    } else {
      yield put(misActions.saveRecipientSuccess({
        reportId: action.payload.report.id,
        oldEmailAddress: action.payload.recipient.emailAddress,
        recipient: response.body.recipient,
      }))
      yield put(misActions.showNotification({
        type: 'success',
        message: 'The recipient was updated successfully.',
      }))
    }
  } catch(error) {
    yield put(misActions.showNotification({
      type: 'error',
      message: 'Invalid email address. Please try again.',
    }))
  }
}

function* handleDeleteRecipient(action) {
  const mis = yield select(getMIS)
  const currentUser = mis.currentUserData
  let currentUserReportIds = [...mis.currentUserReportIds]
  const reportId = action.payload.report.id
  try {
    const response = yield call(misApi.saveSubscription, {
        operation: 'delete',
        reportId,
        reportSource: action.payload.report.source,
        reportType: action.payload.report.type,
        emailAddress: action.payload.hasOwnProperty('recipient') ? action.payload.recipient.emailAddress : null,
      }
    )
    if ('error' in response.body) {
      yield put(misActions.showNotification({
        type: 'error',
        message: response.body.error,
      }))
    } else {
      yield put(misActions.deleteRecipientSuccess({
        reportId,
        recipient: action.payload.hasOwnProperty('recipient') ? action.payload.recipient : currentUser,
      }))
      if (!action.payload.hasOwnProperty('recipient') || (currentUser.emailAddress === action.payload.recipient.emailAddress)) {
        currentUserReportIds = currentUserReportIds.filter(id => id !== reportId)
        yield put(misActions.setCurrentUserReportIds(currentUserReportIds))
      }
      yield put(misActions.showNotification({
        type: 'success',
        message: 'The recipient was removed successfully.',
      }))
    }
  } catch(error) {
    yield put(misActions.showNotification({
      type: 'error',
      message: 'There was an error processing your request. Please try again.',
    }))
  }
}

function* handleSaveAutoSubscribe(action) {
  try {
    yield call(misApi.updateFirmReport, action.payload)
    yield put(misActions.setAutoSubscribe(action.payload))
    yield put(misActions.showNotification({
      type: 'success',
      message: 'Auto subscribe was updated successfully.',
    }))
  } catch(error) {
    yield put(misActions.showNotification({
      type: 'error',
      message: 'There was an error processing your request. Please try again.',
    }))
  }
}

function* handleSubscribeAllUsers(action) {
  try {
    yield call(misApi.subscribeAllUsers, action.payload)
    yield put(misActions.showNotification({
      type: 'success',
      message: `We have begun the process of ${action.payload ? '' : 'un'}subscribing all users.`,
    }))
  } catch(error) {
    yield put(misActions.showNotification({
      type: 'error',
      message: 'There was an error processing your request. Please try again.',
    }))
  }
}

function* handleShowNotification(action) {
  yield call(hideNotification)
}

export default function*() {
  yield all([
    takeLatest(
      misActions.getReports,
      handleGetReports,
    ),
    takeLatest(
      misActions.getArchives,
      handleGetArchives,
    ),
    takeLatest(
      misActions.getRecipients,
      handleGetRecipients,
    ),
    takeLatest(
      misActions.getRenderedReport,
      handleGetRenderedReport,
    ),
    takeLatest(
      misActions.downloadRenderedReportPDF,
      handleDownloadRenderedReportPDF,
    ),
    takeLatest(
      misActions.addRecipient,
      handleAddRecipient,
    ),
    takeLatest(
      misActions.saveRecipient,
      handleSaveRecipient,
    ),
    takeLatest(
      misActions.deleteRecipient,
      handleDeleteRecipient,
    ),
    takeLatest(
      misActions.showNotification,
      handleShowNotification,
    ),
    takeLatest(
      misActions.saveAutoSubscribe,
      handleSaveAutoSubscribe,
    ),
    takeLatest(
      misActions.subscribeAllUsers,
      handleSubscribeAllUsers,
    ),
  ])
}
