import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {getSettingsAdmin} from './firm-settings-selectors'
import {
  fetchLanguages,
  addLanguage,
  removeLanguage,
} from './firm-settings-actions'

@connect(
  createSelector(
    [getSettingsAdmin], (settingsAdmin) => {
      return {
        firmLanguages: settingsAdmin.firmLanguages,
        allLanguages: settingsAdmin.allLanguages,
      }
    }
  ),
  {
    fetchLanguages,
    addLanguage,
    removeLanguage,
  }
)
export default class FirmSettingsLanguages extends Component {
  componentDidMount() {
    if (this.props.firmLanguages === null) {
      this.props.fetchLanguages()
    }
  }

  render() {
    const rows = this.props.allLanguages.map(l => {
      let name = l.name
      if (l.nativeName) {
        name = `${name} (${l.nativeName})`
      }
      return (
        <tr key={l.id}>
          <td className="language-list-item">
           <input
             className="language-input"
             type="checkbox"
             onChange={event => this.handleLanguageToggle(l.id, event.target.checked)}
             checked={this.props.firmLanguages ? this.props.firmLanguages.some(fl => fl.id === l.id) : false}
           />
           <span className="language-name">{name}</span>
          </td>
        </tr>
      )
    })

    return (
      <div>
        <div className="language-header">
          <span>Specify the languages to be displayed by default for all profiles. Users can set additional
            language preferences from their profiles.</span>
        </div>
        <div className="languages-top-div">
          <table className="languages-datatable">
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  handleLanguageToggle(id, isSelected) {
    if (isSelected){
      this.props.addLanguage(id)
    } else{
      this.props.removeLanguage(id)
    }
  }
}

