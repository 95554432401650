import {createAction} from 'redux-actions'

const action = name => createAction(`DASHBOARD_${name}`)

export const init = action('INIT')
export const setIsLoading = action('SET_IS_LOADING')
export const setAreAllSectionsLoaded = action('SET_ARE_ALL_SECTIONS_LOADED')
export const removeDocumentsForExcludedFeedId =
  action('REMOVE_DOCUMENTS_FOR_EXCLUDED_FEED_ID')
export const activeSidebarNavChange = action('ACTIVE_SIDEBAR_NAV_CHANGE')
export const setLastSidebarNavChange = action('SET_LAST_SIDEBAR_NAV_CHANGE')
export const setActiveSidebarNav = action('SET_ACTIVE_SIDEBAR_NAV')
export const setDocumentIds = action('SET_DOCUMENT_IDS')

// Searches
export const fetchDashboardSectionComplete = action('FETCH_DASHBOARD_SECTION_COMPLETE')
export const fetchDashboardResultsComplete = action('FETCH_DASHBOARD_RESULTS_COMPLETE')

// Flagging
export const showFlagModal = action('SHOW_FLAG_MODAL')
export const hideFlagModal = action('HIDE_FLAG_MODAL')

// Charts & Trends
export const getChartData = action('GET_CHART_DATA')
export const setChartData = action('SET_CHART_DATA')
export const chartPointClick = action('CHART_POINT_CLICK')
