import React from 'react'
import { navConfig } from './NavConfig'
import {useDispatch, useSelector} from 'react-redux'
import * as esgSelectors from 'app/esg/esg-selectors'
import {HashLink} from 'react-router-hash-link'
import * as esgActions from 'app/esg/esg-actions'

/**
 * The navigation bar present on top of esg pages
 * @param {string} [activeIdentifier] - The identifier of the nav item that should be displayed as active
 *     Optional. If not passed, the activeNavItem will be read from the redux store. (good for nav item change on scroll)
 *     If passed, the active nav item will be static regardless of the value in the redux store (good for classic navigation)
 */

function EsgNavbar({activeIdentifier}) {
  const activeNavItem =
    activeIdentifier || useSelector(esgSelectors.getActiveNavItem)
  const dispatch = useDispatch()
  return (
    <nav id="nav-primary-bar">
      <div id="nav-primary">
        <div id="nav-primary-content">
          <div>
            <ul>
              {navConfig.map(({displayName, path, identifier}) => {
                return (
                  <li key={identifier} 
                    className={
                      identifier === activeNavItem
                        ? 'primary active'
                        : 'primary'
                    }
                  >
                    <HashLink
                      to={path}
                      onClick={
                        !activeIdentifier
                          ? () => {
                              dispatch(esgActions.activateNavItem(identifier))
                            }
                          : null
                      }
                    >
                      <span className={'search-label'}>{displayName}</span>
                    </HashLink>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default EsgNavbar
