import React from 'react'
import {render} from 'react-dom'

import * as entitiesActions from 'app/entities/entities-actions'
import AppWrapper from 'app/global/app-wrapper'
import {SavedSearch} from 'app/models'
import store from 'app/store'
import {changeCaseObject} from 'app/utils'

import * as actions from './search-results-page-actions'
import * as constants from './search-results-page-constants'
import reducer from './search-results-page-reducer'
import saga from './search-results-page-saga'
import * as utils from './search-results-page-utils'
import SearchResultsPage from './SearchResultsPage'


export function init(data) {
  data = changeCaseObject.camelCase(data)
  // First save the search entity so that the page can retrieve it normally.
  // Otherwise, we have to make lots of special cases for when the page is
  // loading.
  store.dispatch(
    entitiesActions.updateByModel(
      SavedSearch,
      {
        id: utils.isFeedPage() ? -1 : utils.getTier3SearchOrFeedId(),
        name: data.searchName,
        duration: data.duration,
        relevancyLevel: data.relevancyLevel,
        shouldShowRelevanceFilter: data.shouldShowRelevanceFilter,
        groupingLevel: data.groupingLevel,
        resultsOrder: data.sortOption,
        filterGroups: [],
        savedSearchArticlesCount: data.savedSearchArticlesCount,
      },
    )
  )
  store.dispatch(actions.init({
    url: window.location.toString(),
  }))
  // not including this in init because init is also called when the search is updated.
  store.dispatch(actions.fetchUserEmailSettings())
  store.dispatch(actions.fetchCachedLargerTimeFrames(data.cachedSearchId))
  const component = (
    <AppWrapper>
      <SearchResultsPage />
    </AppWrapper>
  )
  const container = document.getElementById('results-frame')
  render(component, container)
}

export {actions, constants, reducer, saga}
