import { handleActions } from 'redux-actions'
import * as esgActions from './esg-actions'
import { assocPath } from 'ramda'
import { stringify } from 'uuid'

const getInitialState = () => ({
  isLoading: false,
  notificationMessage: null,
  notificationType: 'info', // success, error, info
  company: '',
  industry: null,
  savedSearches: [],
  esgCompanyTable: {},
  companyEsgData: {
    isLoading: true,
    terms: [],
    isError: false
  },
  companyStories: {
    isLoading: true,
    stories: [],
    count: 0,
    error: false,
  },
  companyStoryFilters: {
    selectedTerm: null,
    pillarId: null,
    topicId: null,
    order: 'relevance',
    page: 1,
  },
  industryEsgData: {
    isLoading: true,
    terms: [],
  },
  industryStories: {
    isLoading: true,
    stories: [],
    count: 0,
    error: false,
  },
  industryStoryFilters: {
    selectedTerm: null,
    pillarId: null,
    topicId: null,
    order: 'relevance',
    page: 1,
  },
  showSpinnerOnFeedbackSent: false,
  sectors: [],
  sidebarContent: [],
  defaultIndustry: null,
  visibleNavItems: ['industry'],
  esgSearchValue : '',
  esgCompaniesOnSearch:[],
  esgAllCompaniesData: [],
  totalEsgCompanyCount: 0,
})

function updateArticles(state, updateDeepDoc) {
  const companyTopicStories = state?.companyStories?.stories
    ? updateDeepDoc(state?.companyStories?.stories) : null

  const industryTopicStories = state?.industryStories?.stories
    ? updateDeepDoc(state?.industryStories?.stories) : null
  
  let newState
  if (companyTopicStories) {
    newState = assocPath(
      ['companyEsgData', 'companyTopicStories'],
      companyTopicStories,
      state,
    )
  }

  if (industryTopicStories) {
    newState = assocPath(
      ['industryEsgData', 'industryTopicStories'],
      industryTopicStories,
      newState ? newState : state,
    )
  }
  return newState
}

export default handleActions(
  {
    [esgActions.init]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [esgActions.setCompanyEsgData]: (state, action) => ({
      ...state,
      companyEsgData: {
        ...state.companyEsgData,
        ...action.payload,
      },
    }),
    [esgActions.setIndustryEsgData]: (state, action) => ({
      ...state,
      industryEsgData: {
        ...state.industryEsgData,
        ...action.payload,
      },
    }),
    [esgActions.setSavedSearches]: (state, action) => ({
      ...state,
      savedSearches: action.payload,
    }),
    [esgActions.setCompany]: (state, action) => ({
      ...state,
      company: action.payload,
    }),
    [esgActions.setIndustry]: (state, action) => ({
      ...state,
      industry: action.payload,
    }),
    [esgActions.setEsgCompanyTableData]: (state, action) => ({
      ...state,
      esgCompanyTable: action.payload,
    }),
    [esgActions.setShowSpinnerOnFeedbackSent]: (state, action) => ({
      ...state,
      showSpinnerOnFeedbackSent: action.payload,
    }),
    [esgActions.setSectors]: (state, action) => ({
      ...state,
      sectors: action.payload,
    }),
    [esgActions.activateNavItem]: (state, action) => {
      const newVisibleItems = state.visibleNavItems.filter((value) => (value !== action.payload))
      newVisibleItems.push(action.payload)
      return {
        ...state,
        visibleNavItems: newVisibleItems,
      }
    },
    [esgActions.deactivateNavItem]: (state, action) => ({
      ...state,
      visibleNavItems: state.visibleNavItems.filter((value) => (value !== action.payload))
    }),
    [esgActions.updateDocumentFlaggedState]: (state, action) => {
      const documents = action.payload.documents
      const documentLookup = Object.fromEntries(documents.map(d => [d.id, d]))
      const updateDeepDoc = stories => stories.map(s => {
        const documentUpdate = documentLookup[s.document.id]
        if (documentUpdate) {
          s.document = {
            ...s.document,
            ...documentUpdate,
          }
        }
        return s
      })

      return updateArticles(state, updateDeepDoc)
    },
    [esgActions.updateDocumentPromotedState]: (state, action) => {
      const feedId = action.payload.feedId
      const isPromoted = action.payload.isPromoted

      const updateDeepDoc = stories => {
        let updatedStories = stories && stories.map(s => {
          let updated_Story;
          if (s && s.document && s.document.feed && s.document.feed.id === feedId) {
            updated_Story = { ...s.document.feed, isPromoted }
          }
          else { updated_Story = s }
          return updated_Story;
        })
        return updatedStories;
      }

      return updateArticles(state, updateDeepDoc)
    },
    [esgActions.updateDocumentExcludedState]: (state, action) => {
      const feedId = action.payload.feedId
      const isExcluded = action.payload.isExcluded
      const updateDeepDoc = stories => {
        let updatedStories = stories && stories.map(s => {
          let updated_Story;
          if (s && s.document && s.document.feed && s.document.feed.id === feedId) {
            updated_Story = { ...s.document.feed, isExcluded }
          }
          else { updated_Story = s }
          return updated_Story;
        })
        return updatedStories;
      }
      return updateArticles(state, updateDeepDoc)
    },
    [esgActions.setSidebarContent]: (state, action) => ({
      ...state,
      sidebarContent: action.payload,
    }),
    [esgActions.setIsLoading]: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),

    [esgActions.setCompanyTerms]: (state, action) => ({
      ...state,
      companyEsgData: {
        ...state.companyEsgData,
        terms: action.payload,
      }
    }),
    [esgActions.setCompanySelectedTerm]: (state, action) => ({
      ...state,
      companyStoryFilters: {
        ...state.companyStoryFilters,
        selectedTerm: action.payload,
        page: 1,
      }
    }),
    [esgActions.setCompanyStorySortFilter]: (state, action) => ({
      ...state,
      companyStoryFilters: {
        ...state.companyStoryFilters,
        order: action.payload,
        page: 1,
      }
    }),
    [esgActions.setCompanySelectedPillar]: (state, action) => ({
      ...state,
      companyStoryFilters: {
        ...state.companyStoryFilters,
        pillarId: action.payload,
        page: 1,
      }
    }),
    [esgActions.setCompanySelectedTopic]: (state, action) => ({
      ...state,
      companyStoryFilters: {
        ...state.companyStoryFilters,
        topicId: action.payload,
        page: 1,
      }
    }),
    [esgActions.setCompanyStoryPage]: (state, action) => ({
      ...state,
      companyStoryFilters: {
        ...state.companyStoryFilters,
        page: action.payload,
      }
    }),
    [esgActions.setDefaultIndustry]: (state, action) => ({
      ...state,
      defaultIndustry: action.payload,
    }),
    [esgActions.fetchCompanyStories]: (state, action) => ({
      ...state,
      companyStories: {
        ...state.companyStories,
        isLoading: true,
        error: false,
      }
    }),
    [esgActions.fetchCompanyStoriesSuccess]: (state, action) => ({
      ...state,
      companyStories: {
        isLoading: false,
        stories: action.payload.stories,
        count: action.payload.count,
        error: false,
      }
    }),
    [esgActions.fetchCompanyStoriesError]: (state, action) => ({
      ...state,
      companyStories: {
        ...state.companyStories,
        isLoading: false,
        error: true,
      }
    }),
    [esgActions.setCompanyStoriesLoading]: (state, action) => ({
      ...state,
      companyStories: {
        ...state.companyStories,
        isLoading: true,
        stories: [],
        error: false,
      }
    }),
    [esgActions.getCompany]: (state, action) => ({
      ...state,
      companyStoryFilters: {
        ...state.companyStoryFilters,
        page: 1,
        selectedTerm: null,
        pillarId: null,
        topicId: null,
        order: 'relevance',
      }
    }),
    [esgActions.setIndustryTerms]: (state, action) => ({
      ...state,
      industryEsgData: {
        ...state.industryEsgData,
        terms: action.payload,
      }
    }),
    [esgActions.setIndustrySelectedTerm]: (state, action) => ({
      ...state,
      industryStoryFilters: {
        ...state.industryStoryFilters,
        selectedTerm: action.payload,
        page: 1,
      }
    }),
    [esgActions.setIndustryStorySortFilter]: (state, action) => ({
      ...state,
      industryStoryFilters: {
        ...state.industryStoryFilters,
        order: action.payload,
        page: 1,
      }
    }),
    [esgActions.setIndustrySelectedPillar]: (state, action) => ({
      ...state,
      industryStoryFilters: {
        ...state.industryStoryFilters,
        pillarId: action.payload,
        page: 1,
      }
    }),
    [esgActions.setIndustrySelectedTopic]: (state, action) => ({
      ...state,
      industryStoryFilters: {
        ...state.industryStoryFilters,
        topicId: action.payload,
        page: 1,
      }
    }),
    [esgActions.setIndustryStoryPage]: (state, action) => ({
      ...state,
      industryStoryFilters: {
        ...state.industryStoryFilters,
        page: action.payload,
      }
    }),
    [esgActions.setDefaultIndustry]: (state, action) => ({
      ...state,
      defaultIndustry: action.payload,
    }),
    [esgActions.fetchIndustryStories]: (state, action) => ({
      ...state,
      industryStories: {
        ...state.industryStories,
        isLoading: true,
        error: false,
      }
    }),
    [esgActions.fetchIndustryStoriesSuccess]: (state, action) => ({
      ...state,
      industryStories: {
        isLoading: false,
        stories: action.payload.stories,
        count: action.payload.count,
        error: false,
      }
    }),
    [esgActions.fetchIndustryStoriesError]: (state, action) => ({
      ...state,
      industryStories: {
        ...state.industryStories,
        isLoading: false,
        error: true,
      }
    }),
    [esgActions.setIndustryStoriesLoading]: (state, action) => ({
      ...state,
      industryStories: {
        ...state.industryStories,
        isLoading: true,
        stories: [],
        error: false,
      }
    }),
    [esgActions.getIndustry]: (state, action) => ({
      ...state,
      industryStoryFilters: {
        ...state.industryStoryFilters,
        page: 1,
        selectedTerm: null,
        pillarId: null,
        topicId: null,
        order: 'relevance',
      }
    }),
    [esgActions.setAllEsgCompaniesDataOnSearch]: (state, action) => ({
      ...state,
      esgCompaniesOnSearch: action.payload      
    }),
    [esgActions.setEsgCompaniesSearchString]: (state, action) => ({
      ...state,
      esgSearchValue: action.payload      
    }),
    [esgActions.clearAllEsgCompaniesDataOnSearch]: (state, action) => ({
      ...state,
      esgCompaniesOnSearch: []     
    }),
    [esgActions.deleteSearches]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [esgActions.getAllEsgCompaniesData]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [esgActions.setAllEsgCompaniesData]: (state, action) => ({
      ...state,
      isLoading: false,
      esgAllCompaniesData: action.payload,
    }),
    [esgActions.createSearches]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [esgActions.totalEsgCompaniesData]: (state, action) => ({
      ...state,
      totalEsgCompanyCount: action.payload,
    }),
    [esgActions.getSavedSearches]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
  },
  getInitialState()
)
