import React, { useEffect, useRef, useState } from 'react'
import { Link } from "react-router-dom";
import classNames from 'classnames'

import PaginatedTable from 'app/common/PaginatedTable'
import Table from 'app/common/Table'
import * as styles from './EsgCompanyTable.less'
import HealthBar from '../../common/HealthBar'
import { editCompanies } from 'app/urls'
import Button from '../../common/Button'
import { useDispatch, useSelector } from 'react-redux'
import * as routing from 'app/global/routing'
import LoadingSpinner from '../../common/LoadingSpinner'
import { trueRound } from '../../utils/numbers'
import * as esgSelectors from '../esg-selectors'
import * as utils from '../utils'
import * as esgActions from '../esg-actions'
import { Waypoint } from 'react-waypoint'
import { scrollTargetIds } from 'app/esg/Navigation/NavConfig'
import InputBlock from 'app/common/InputBlock'
import DeleteSearchesConfirmationModal from 'app/reusable/DeleteSearchesConfirmationModal'
import LoadingOverlay from 'app/common/LoadingOverlay'
import BulkAction from './BulkAction'
import { MY_COMPANIES, ALL_COMPANIES, COLUMNS, MAX_COMPANY_PER_PAGE, SHOW_MYCOMPANIES, SHOW_ALLCOMPANIES, MAX_ALLCOMPANIES_PER_PAGE } from '../esg-constants'
import ConfirmationModal from 'app/common/modals/ConfirmationModal'

const getPillarScoreFormat = score => {
  if (isNaN(score)) {
    return (<span>--</span>)
  }
  score = trueRound(score)
  let textScore = ''
  let numberClassName = styles.number

  if (score > 0) {
    textScore = `+${score}`
    numberClassName = classNames(numberClassName, styles.positiveNumber)
  } else if (score < 0) {
    textScore = `${score}`
    numberClassName = styles.negativeNumber
  } else {
    textScore = `${score}`
  }

  return (
    <span className={numberClassName}>{textScore}</span>
  )
}

export default function EsgCompanyTable() {
  const dispatch = useDispatch()
  const searchRef = useRef(null)
  let companies = utils.filterEsgEnabledCompaniesFromSavedSearches(useSelector(esgSelectors.getSavedSearches))
  const savedCompanyData = useSelector(esgSelectors.getEsgCompanyTable)
  const [currentCompanyPage, setCurrentCompanyPage] = useState(1)
  const [companySearchInputValue, setCompanySearchInputValue] = useState('')
  const [allcompaniesSearchInputValue, setallcompaniesSearchInputValue] = useState('')
  const [selectedCompanyOption, setselectedCompanyOption] = useState(MY_COMPANIES)
  const filteredCompanies = companies.filter(company => company.name.toLowerCase().includes(companySearchInputValue.toLowerCase()))
  const [selectedCompanies, setSelectedCompanies] = useState([])
  const myCompanies = filteredCompanies.map(company => { return company.id })
  const myCompaniesIds = filteredCompanies.map(company => { return company.value })
  const [isDeleteConfirmationModalShown, setisDeleteConfirmationModalShown] = useState(false)
  const [isSaveConfirmationModalShown, setisSaveConfirmationModalShown] = useState(false)
  const [addCompanies, setaddCompanies] = useState([])
  const isLoading = useSelector(esgSelectors.getIsLoading)
  const allEsgCompanies = useSelector(esgSelectors.getAllEsgCompaniesData)
  const esgCompaniesTotalCount = useSelector(esgSelectors.getEsgCompaniesTotalCount)
  let companiesToShow;
  if (selectedCompanyOption === MY_COMPANIES) {
    companiesToShow = filteredCompanies.map(company => {
      return {
        id: company.id,
        displayName: company.name,
        data: savedCompanyData[company.id],
        value: company.value,
        name: company.name,
      }
    }).slice(MAX_COMPANY_PER_PAGE * (currentCompanyPage - 1), MAX_COMPANY_PER_PAGE * currentCompanyPage)
    companiesToShow.forEach(company => company.data === undefined && dispatch(esgActions.getEsgCompanyTableData(company)))
  } else {
    companiesToShow = allEsgCompanies.map(company => {
      return {
        id: company.companyId,
        displayName: company.companyName,
        data: company,
        value: String(company.savedsearchId),
        name: company.companyName,
      }
    })
  }
  const handleCompanySearch = e => {
    setCompanySearchInputValue(e.target.value)
    if (currentCompanyPage !== 1) {
      setCurrentCompanyPage(1)
    }
  }

  const onCompanySelect = (company) => {
    let id = company.id
    if (selectedCompanies.includes(id)) {
      const selected = selectedCompanies.filter((company) => company !== id)
      setSelectedCompanies(selected)
      if (selectedCompanyOption === ALL_COMPANIES) {
        const addselectedcompanies = addCompanies.filter((company) => company.id !== id)
        setaddCompanies([...addselectedcompanies])
      }
    } else {
      setSelectedCompanies([...selectedCompanies, id])
      if (selectedCompanyOption === ALL_COMPANIES) {
        setaddCompanies([...addCompanies, company])
      }
    }
  }

  const showMyCompanies = () => {
    setselectedCompanyOption(MY_COMPANIES)
    setCurrentCompanyPage(1)
    setSelectedCompanies([])
    setallcompaniesSearchInputValue('')
  }

  const showAllCompanies = () => {
    const offset = (currentCompanyPage - 1) * MAX_ALLCOMPANIES_PER_PAGE
    setselectedCompanyOption(ALL_COMPANIES)
    setCurrentCompanyPage(1)
    setSelectedCompanies([])
    setCompanySearchInputValue('')
    dispatch(esgActions.getAllEsgCompaniesData({ offset }))
  }

  const deleteConfirmationModal =
    isDeleteConfirmationModalShown &&
    <DeleteSearchesConfirmationModal
      searches={filteredCompanies.filter(company => selectedCompanies.includes(company.id))}
      onClose={() => hideDeleteConfirmation()}
      onConfirm={() => deleteSearches()}
      deleteMessage = {"from My Companies"}
    />

  const saveConfirmationModal = isSaveConfirmationModalShown && (
    <ConfirmationModal
      message={`Are you sure you want to add ${addCompanies.length === 1 ? addCompanies.map(company => company.displayName).toString() : `${addCompanies.length} Companies`}?`}
      confirmButtonText="Yes"
      cancelButtonText="No"
      onConfirm={() => handleSaveConfirm()}
      onClose={() => handleSaveCancel()}
      isDestructive={true}
    />
  )

  const loadingOverlay = isLoading
    ? <div className={classNames('loading-container')}>
      <LoadingOverlay />
    </div>
    : null

  const deleteSearches = () => {
    dispatch(esgActions.deleteSearches(selectedCompanies))
    setisDeleteConfirmationModalShown(false)
    setSelectedCompanies([])
  }

  const showCompanyAddConfirmation = (company) => {
    if (myCompaniesIds.includes(company.value)) return
    setaddCompanies([company])
    setisSaveConfirmationModalShown(true)

  }

  const handleSaveConfirm = () => {
    const companyIds = addCompanies.map(company => company.data.savedsearchId)
    const postParams = {
      searchIds: [...companyIds]
    }
    setaddCompanies([])
    setSelectedCompanies([])
    setisSaveConfirmationModalShown(false)
    dispatch(esgActions.createSearches(postParams))
  }

  const handleSaveCancel = () => {
    setisSaveConfirmationModalShown(false)
    setaddCompanies([])
    setSelectedCompanies([])
  }

  const showBulkCompanyAddConfirmation = () => {
    if (selectedCompanies.length === 0) return
    setisSaveConfirmationModalShown(true)
  }

  const showDeleteConfirmation = () => {
    if (selectedCompanies.length === 0) return
    setisDeleteConfirmationModalShown(true)
  }

  const showCompanyDeleteConfirmation = (company) => {
    let id;
    if (selectedCompanyOption === ALL_COMPANIES) {
      const deleteCompany = filteredCompanies.find(mycompany => mycompany.value === company.value);
      id = Number(deleteCompany.id)
    } else {
      id = company.id
    }
    setSelectedCompanies([id])
    setisDeleteConfirmationModalShown(true)
  }

  const hideDeleteConfirmation = () => {
    setSelectedCompanies([])
    setisDeleteConfirmationModalShown(false)

  }

  const pageCount = selectedCompanyOption === MY_COMPANIES ? Math.max(Math.ceil(filteredCompanies.length / MAX_COMPANY_PER_PAGE), 1) : Math.max(Math.ceil(esgCompaniesTotalCount / 10), 1)
  const onChangeSelectAll = (value) => {
    setSelectedCompanies([])
    setaddCompanies([])
    if (value === 'all') {
      setSelectedCompanies(myCompanies)
    } else if (value === 'visible') {
      let visibleCompanies;
      visibleCompanies = companiesToShow.map(company => {
        return company.id
      })
      if (selectedCompanyOption === ALL_COMPANIES) {
        const addCompanies = companiesToShow.filter(company => !myCompaniesIds.includes(company.value));
        visibleCompanies = addCompanies.map(company => {
          return company.id
        })
        setaddCompanies([...addCompanies])
      }
      setSelectedCompanies(visibleCompanies)
    }
  }

  const handleAllCompaniesSearch = (e) => {
    const companyName = e.target.value
    setallcompaniesSearchInputValue(e.target.value)
    setCurrentCompanyPage(1)
    dispatch(esgActions.getAllEsgCompaniesData({ companyName, offset: 0 }))
  }

  const onPageChange = (page) => {
    if (selectedCompanyOption === MY_COMPANIES) {
      setCurrentCompanyPage(page)
    } else {
      const offset = (page - 1) * MAX_ALLCOMPANIES_PER_PAGE
      const companyName = allcompaniesSearchInputValue
      setCurrentCompanyPage(page)
      dispatch(esgActions.getAllEsgCompaniesData({companyName, offset }))
    }
  }

  useEffect(() => {
    function handleEscape(e) {
      if (e.key === 'Escape') {
        setCompanySearchInputValue('')
        setallcompaniesSearchInputValue('')
      }
    }

    searchRef.current.addEventListener('keydown', handleEscape)
    return () => searchRef.current.removeEventListener('keydown', handleEscape)
  }, [])

  return (
    <Waypoint
      onEnter={waypoint => {
        if (waypoint.event) dispatch(esgActions.activateNavItem('company'));
      }}
      onLeave={waypoint => {
        if (waypoint.event) dispatch(esgActions.deactivateNavItem('company'));
      }}
    >
      <div className='company-table' id={scrollTargetIds.company}>
        <h1 className={styles.companyHeader}>ESG Companies</h1>
        <div className={styles.companyHeaderContainer} style={{ justifyContent: "flex-start" }}>
          <InputBlock label={SHOW_MYCOMPANIES} isInline>
            <input
              type="radio"
              name="company-type"
              checked={selectedCompanyOption === MY_COMPANIES}
              onChange={() => showMyCompanies()}
              value={MY_COMPANIES}
            />
          </InputBlock>
          <InputBlock label={SHOW_ALLCOMPANIES} isInline>
            <input
              type="radio"
              name="company-type"
              checked={selectedCompanyOption === ALL_COMPANIES}
              onChange={() => showAllCompanies()}
              value={ALL_COMPANIES}
            />
          </InputBlock>
        </div>
        <div className={styles.companyHeaderContainer}>
          {
            selectedCompanyOption === MY_COMPANIES ? <input
              id='company-search'
              type='text'
              placeholder={`Search in My Companies`}
              className={styles.companySearchInput}
              value={companySearchInputValue}
              onChange={handleCompanySearch}
              ref={searchRef} /> :
              <input
                id='company-search'
                type='text'
                placeholder={`Search in All Companies`}
                className={styles.companySearchInput}
                value={allcompaniesSearchInputValue}
                onChange={handleAllCompaniesSearch}
                ref={searchRef} />
          }

          <a href={editCompanies()}>
            <Button id={'addCompanyButton'} label={'Add Company'} />
          </a>
        </div>
        <BulkAction
          totalCount={filteredCompanies.length}
          selectedCompanyIds={selectedCompanies}
          visibleCompanyIds={companiesToShow}
          onChangeSelectAll={(value) => onChangeSelectAll(value)}
          showDeleteConfirmation={showDeleteConfirmation}
          selectedCompanyOption={selectedCompanyOption}
          showBulkCompanyAddConfirmation={showBulkCompanyAddConfirmation}
        />
        <div id="esg-company-table-container">
          <PaginatedTable
            id={'esg-company-table'}
            defaultSort={{ column: COLUMNS.COMPANY_NAME, direction: 'asc' }}
            pageCount={pageCount}
            currentPage={currentCompanyPage}
            onPageChange={(page) => onPageChange(page)}
            data={companiesToShow}
            ignoreSort={true}
          >
            <Table.Column
              name={COLUMNS.CHECKBOX}
              baseWidth={45}
              growRatio={0}
              shrinkRatio={0}
              cellContents={company =>
                <input
                  className="checkbox"
                  type="checkbox"
                  onChange={() => onCompanySelect(company)}
                  disabled={myCompaniesIds.includes(company.value) && selectedCompanyOption === ALL_COMPANIES}
                  checked={selectedCompanies.includes(company.id)}
                />
              }
            />
            <Table.Column
              name={COLUMNS.COMPANY_NAME}
              label={'Company Name'}
              baseWidth={150}
              growRatio={1.3}
              cellContents={company =>
                <Link className={classNames({ [styles.disableLink]: selectedCompanyOption === ALL_COMPANIES })}
                  to={`/company/${company.value}`}>{company.displayName}</Link>
              }
            />

            <Table.Column
              name={COLUMNS.HEALTH_SCORE}
              label={'ESG Health Score'}
              baseWidth={100}
              growRatio={1}
              cellContents={company => company.data ? (
                <>
                  <div className={styles.healthContainer}>
                    <span className={styles.healthScore}>{
                      getPillarScoreFormat(company.data.articleVolume > 0 ? company.data.healthScore : undefined)
                    }</span>
                    <HealthBar
                      healthData={company.data}
                      className={styles.healthBar}
                    />
                  </div>
                </>) : (<LoadingSpinner className={styles.loadingSpinner} />)}
              className={styles.textCenter}
              colSpan={company => company.data ? 1 : 6}
            />

            <Table.Column
              name={COLUMNS.INDUSTRIES}
              label={'Industries'}
              baseWidth={100}
              growRatio={2}
              cellContents={company => company.data?.industryName ? company.data.industryName : 'No data'}
            />

            <Table.Column
              name={COLUMNS.GOVERNANCE}
              label={'Governance'}
              baseWidth={100}
              growRatio={1}
              shrinkRatio={0}
              className={styles.textCenter}
              cellContents={company =>
                getPillarScoreFormat(company.data.factors.governance?.articleVolume > 0
                  ? company.data.factors.governance?.healthScore : undefined)}
            />

            <Table.Column
              name={COLUMNS.PROSPERITY}
              label={'Prosperity'}
              baseWidth={90}
              growRatio={1}
              shrinkRatio={0}
              className={styles.textCenter}
              cellContents={company =>
                getPillarScoreFormat(company.data.factors.prosperity?.articleVolume > 0
                  ? company.data.factors.prosperity?.healthScore : undefined)}
            />

            <Table.Column
              name={COLUMNS.PEOPLE}
              label={'People'}
              baseWidth={90}
              growRatio={1}
              shrinkRatio={0}
              className={styles.textCenter}
              cellContents={company =>
                getPillarScoreFormat(company.data.factors.people?.articleVolume > 0
                  ? company.data.factors.people?.healthScore : undefined)}
            />

            <Table.Column
              name={COLUMNS.PLANET}
              label={'Planet'}
              baseWidth={90}
              growRatio={1}
              shrinkRatio={0}
              className={styles.textCenter}
              cellContents={company =>
                getPillarScoreFormat(company.data.factors.planet?.articleVolume > 0 ?
                  company.data.factors.planet?.healthScore : undefined)}
            />
            {selectedCompanyOption === MY_COMPANIES ?
              <Table.Column
                name={COLUMNS.ACTIONS}
                label={'Actions'}
                baseWidth={80}
                growRatio={0}
                shrinkRatio={0}
                className={styles.actions}
                cellContents={company =>
                  <div onClick={() => showCompanyDeleteConfirmation(company)}><img src="/media/img/Trash.png" width={20} /></div>
                }
              /> : <Table.Column
                name={COLUMNS.ACTIONS}
                label={'Actions'}
                baseWidth={120}
                growRatio={0}
                shrinkRatio={0}
                className={styles.actions}
                cellContents={company =>
                  <div className={styles.actionsColumn}>
                    <div onClick={() => showCompanyAddConfirmation(company)}><img src={myCompaniesIds.includes(company.value) ? "/media/img/Add_Disabled.png" : "/media/img/Add_Icon.png"} width={20} /></div>
                    {
                      myCompaniesIds.includes(company.value) ? <div onClick={() => showCompanyDeleteConfirmation(company)}><img src="/media/img/Trash.png" width={20} /></div> : ''
                    }
                  </div>
                }
              />
            }
          </PaginatedTable>
          {loadingOverlay}
        </div>
        {deleteConfirmationModal}
        {saveConfirmationModal}
      </div>
    </Waypoint>
  )
}
