import { all, fork } from 'redux-saga/effects'

import { saga as dnbSaga } from 'app/search-results/dun-and-bradstreet'
import { saga as searchesAdminSaga } from 'app/firm-admin/searches'
import { saga as sourcesAdminSaga } from 'app/firm-admin/sources'
import { saga as usersAdminSaga } from 'app/firm-admin/users'
import { saga as usageAdminSaga } from 'app/firm-admin/usage'
import { saga as settingsAdminSaga } from 'app/firm-admin/settings'
import { saga as dnbSearchSaga } from 'app/one-off/dandb-search'
import { saga as routingSaga } from 'app/global/routing'
import { saga as emailNotificationsSaga } from 'app/email-notifications'
import { saga as profileSearchesSaga } from 'app/profile/searches'
import { saga as profileSourcesSaga } from 'app/profile/sources'
import { saga as profileAccountSaga } from 'app/profile/account'
import { saga as profilePreferencesSaga } from 'app/profile/preferences'
import { saga as profileGroupMembersSaga } from 'app/profile/group-members'
import { saga as advancedSearchSaga } from 'app/advanced-search'
import { saga as searchLookupSaga } from 'app/advanced-search/search-lookup'
import { saga as misSaga } from 'app/mis'
import { saga as searchResultsSaga } from 'app/search-results-page'
import { saga as profileBuilderSaga } from 'app/profile-builder'
import { saga as profileBuilderRefreshSaga } from 'app/profile-refresh'
import { saga as notificationsSaga } from 'app/global/notifications'
import { saga as pendoStatsSaga } from 'app/firm-stats/pendo-stats'
import { saga as djangoInteractionBarSaga } from 'app/django-interaction-bar'
import { saga as dashboardSaga} from 'app/dashboard'
import { saga as comparisonPageSaga} from 'app/comparison-page'
import { saga as flaggedItemsSaga } from 'app/flagged-items'
import { saga as helpSaga } from 'app/help'
import { saga as chartSaga } from 'app/common/charts'
import { saga as esgSaga } from 'app/esg'


export default function*() {
  yield all([
    fork(searchesAdminSaga),
    fork(sourcesAdminSaga),
    fork(usersAdminSaga),
    fork(usageAdminSaga),
    fork(settingsAdminSaga),
    fork(dnbSaga),
    fork(dnbSearchSaga),
    fork(routingSaga),
    fork(emailNotificationsSaga),
    fork(profileSearchesSaga),
    fork(profileSourcesSaga),
    fork(profileAccountSaga),
    fork(profilePreferencesSaga),
    fork(profileGroupMembersSaga),
    fork(advancedSearchSaga),
    fork(searchLookupSaga),
    fork(misSaga),
    fork(searchResultsSaga),
    fork(profileBuilderSaga),
    fork(profileBuilderRefreshSaga),
    fork(notificationsSaga),
    fork(pendoStatsSaga),
    fork(djangoInteractionBarSaga),
    fork(dashboardSaga),
    fork(comparisonPageSaga),
    fork(flaggedItemsSaga),
    fork(helpSaga),
    fork(chartSaga),
    fork(esgSaga),
  ])
}
