import React from 'react'


export default ({feed, onConfirm, onModalClose}) => (
  <div>
    <p>This feed is empty and does not contain any content. Are you sure you would like to add it?</p>

    <div className="buttons left">
      <a
        className="confirm button"
        onClick={() => onConfirm(feed)}
      >
        Add as Source
      </a>
      <a className="cancel button" onClick={onModalClose}>
        Cancel
      </a>
    </div>
  </div>
)
