import { all, delay, takeLatest, put, call, select } from 'redux-saga/effects'
import * as dateFns from 'date-fns'

import { ISO_DATE_FORMAT } from 'app/constants'
import { handleSagaError } from 'app/utils/errors'

import * as api from './pendo-stats-api'
import * as actions from './pendo-stats-actions'


function* handleFetchPageVisits(action) {
  const firmId = action.payload.firmId || undefined
  const start = dateFns.format(action.payload.start, ISO_DATE_FORMAT)
  const end = dateFns.format(action.payload.end, ISO_DATE_FORMAT)
  let response = null
  try {
    response = yield api.fetchPageVisits({firmId, start, end})
  } catch(error) {
    yield* handleSagaError(error)
    yield put(actions.hideLoader())
    return
  }
  yield put(actions.setPageVisits(response.pageVisits))
}

function* handleFetchVisitors(action) {
  const firmId = action.payload.firmId || undefined
  const start = dateFns.format(action.payload.start, ISO_DATE_FORMAT)
  const end = dateFns.format(action.payload.end, ISO_DATE_FORMAT)
  let response = null
  try {
    response = yield api.fetchVisitors({firmId, start, end})
  } catch(error) {
    yield* handleSagaError(error)
    yield put(actions.hideLoader())
    return
  }
  yield put(actions.setVisitors(response.visitors))
}

function* handleFetchBrowsers(action) {
  const firmId = action.payload.firmId || undefined
  const start = dateFns.format(action.payload.start, ISO_DATE_FORMAT)
  const end = dateFns.format(action.payload.end, ISO_DATE_FORMAT)
  let response = null
  try {
    response = yield api.fetchBrowsers({firmId, start, end})
  } catch(error) {
    yield* handleSagaError(error)
    yield put(actions.hideLoader())
    return
  }
  yield put(actions.setBrowsers(response.browsers))
}

export default function*() {
  yield all([
    takeLatest(
      actions.fetchPageVisits,
      handleFetchPageVisits
    ),
    takeLatest(
      actions.fetchVisitors,
      handleFetchVisitors
    ),
    takeLatest(
      actions.fetchBrowsers,
      handleFetchBrowsers
    ),
  ])
}
