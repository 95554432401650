import { changeCaseObject } from 'app/utils'
import React from 'react'
import { render } from 'react-dom'
import AppWrapper from 'app/global/app-wrapper'

import store from 'app/store'

import ProfileBuilder from './ProfileBuilder'
import * as constants from './profile-builder-constants'
import * as actions from './profile-builder-actions'
import reducer from './profile-builder-reducer'
import saga from './profile-builder-saga'

import './ProfileBuilder.less'


export function init(data) {
  // Add the data from this page to the Redux store
  store.dispatch(actions.init(changeCaseObject.camelCase(data.result)))
  const component = (
    <AppWrapper store={store}>
      <ProfileBuilder/>
    </AppWrapper>
  )
  const container = document.getElementById('profile-builder-wrapper')
  render(component, container)
}

export {constants, reducer, saga}
