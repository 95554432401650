import {path} from 'ramda'
import {put} from 'redux-saga/effects'

import {logErrorEvent} from 'app/global/error-tracking'
import * as notifications from 'app/global/notifications'


/**
 * Tries to extract a meaningful error message to display to the user from the
 * Error object. If `error` does not contain a response body, or that body does
 * not contain error data, returns either `defaultMessage`, if it is set, or a
 * generic error message.
 */
export function extractErrorMessage(error, {defaultMessage} = {}) {
  const {name, message} = path(['response', 'body', 'error'], error) || {}
  if (message && name !== 'GenericError') {
    return message
  }
  return (
    defaultMessage
    || 'There was an error processing your request. Please try again.'
  )
}


/**
 * Gracefully handles an error while still making sure it gets logged to the
 * browser developer console as well as our backend error logging endpoint.
 * Shows an error message to the user.
 *
 * Options:
 *   showNotification: Function
 *     - A function that shows the error message to the user. Generally, this
 *       will be a Redux action creator.
 *   message: String - Overrides the notification message shown to the user.
 *   duration: Number - Overrides the notification duration.
 *   defaultMessage: String
 *     - The default generic message to show to the user if a more specific one
 *       cannot be extracted from the error object.
 */
export function handleError(
  error,
  {
    showNotification,
    message,
    defaultMessage,
    duration,
  } = {},
) {
  console.error(error)
  if (showNotification) {
    showNotification({
      type: 'error',
      message: message || extractErrorMessage(error, {defaultMessage}),
      duration,
    })
  }
  return logErrorEvent(error)
}


/**
 * The saga equivalent to `handleError`. Behaves the same and takes all the same
 * arguments except for the `showNotification` option.
 */
export function* handleSagaError(
  error,
  {
    noNotification = false,
    message,
    defaultMessage,
    duration,
  } = {},
) {
  console.error(error)
  if (!noNotification) {
    yield put(
      notifications.actions.showNotification({
        type: 'error',
        message: message || extractErrorMessage(error, {defaultMessage}),
        duration,
      }),
    )
  }
  logErrorEvent(error)
}
