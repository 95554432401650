import React, { Component } from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {sortBy, prop} from 'ramda'
import deepCopy from 'deepcopy'

import {FILTER_CATEGORY_OTHER, FILTER_CATEGORY_SEARCHES, FILTER_CATEGORY_SOURCE_LABELS, FILTER_CATEGORY_LIB_SEARCHES} from 'app/constants'
import Orm from 'app/framework/Orm'
import {SavedSearch, User} from 'app/models'
import SavedSearchInput from 'app/common/SavedSearchInput'
import {Tab, TabbedContainer} from 'app/common/TabbedContainer'
import Button from 'app/common/Button'
import * as entitiesSelectors from 'app/entities/entities-selectors'
import * as globalSelectors from 'app/global/global-selectors'
import {
  coreTermsForSearch,
  flattenFilterData,
  queryFromTerms
} from 'app/utils/searches'

import SearchRelevanceFilter from 'app/reusable/RelevanceFilter/SearchRelevanceFilter'
import SearchGroupingFilter from 'app/reusable/GroupingFilter/SearchGroupingFilter'
import {FOCUS_LEVEL_STANDARD} from 'app/reusable/GroupingFilter/search-grouping-filter-constants'
import {
  RELEVANCE_DEFAULT,
  RELEVANCE_LEVEL_LOOKUP,
  RELEVANCE_LOOKUP,
} from 'app/reusable/RelevanceFilter/SearchRelevanceFilterConstants'
import * as advancedSearchActions from 'app/advanced-search/advanced-search-actions'
import * as advancedSearchSelectors from 'app/advanced-search/advanced-search-selectors'
import * as searchesAdminSelectors from 'app/firm-admin/searches/searches-admin-selectors'
import TermFrequencyFilter from 'app/advanced-search/term-frequency-filter/TermFrequencyFIlter'
import * as searchesAdminActions from 'app/firm-admin/searches/searches-admin-actions'
import * as searchResultsPageActions from 'app/search-results-page/search-results-page-actions'
import * as searchResultsPageSelectors from 'app/search-results-page/search-results-page-selectors'

import SummaryContent from './SummaryContent'
import AdvancedFiltersContent from './AdvancedFiltersContent'
import BrowseSectionContent from './BrowseSectionContent'
import SampleSearchResults from './SampleSearchResults'
import {
  BROWSE_FILTERS_TEMPLATE,
  FILTER_GROUP_SPECIAL_KEY,
  FILTER_GROUP_SEARCH_ID_KEY,
  FILTER_GROUP_FIRM_SOURCE_LABEL_ID_KEY,
  BROWSE_SECTIONS,
  ADVANCED_FILTER_KEYS,
  FILTER_OPTION_TEMPLATE,
  ADVANCED_FILTER_CATEGORY_ID,
  MY_SEARCHES_FILTER_CATEGORY_ID,
} from './constants'
import {
  getSearchLabels,
  getBrowseSections,
} from './utils'

import './style.less'
import SearchLanguageFilter from "app/reusable/LanguageFilter"

// TODO: option to hide search category filters when creating in Admin -> Searches


class SavedSearchFilters extends Component {
  static propTypes = {
    // required props
    currentFirmLibraryName: PropTypes.string.isRequired,
    saveData: PropTypes.object.isRequired,
    queryComponentState: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    currentUser: PropTypes.object.isRequired,

    // optional props
    searchResults: PropTypes.object,
    search: PropTypes.object,
    solrSearchField: PropTypes.string,
    isTier3: PropTypes.bool,
    isMulti: PropTypes.bool,

    /**
     * whether to show the save/next-step button.
     * the EditSearchModal (tier3, profile pages) uses it's own save button.
     */
    includeSaveButton: PropTypes.bool,

    // required function props
    setQueryComponentState: PropTypes.func.isRequired,
    setSaveData: PropTypes.func.isRequired,

    // TODO: move all save/next-step buttons/logic out of here; should be in the parent.
    // optional function props
    onUpdateSearchResults: PropTypes.func,
    onSave: PropTypes.func,
    setStep: PropTypes.func,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    searchResults: {},
    queryType: 'any',
    search: null,
    solrSearchField: null,
    includeSaveButton: true,
    isTier3: false,
    isMulti: false,

    onUpdateSearchResults: () => {},
    onSave: () => {},
    setStep: () => {},
    onChange: () => {},
  }

  state = {
    currentView: 'build', // results
    searches: {},
    currentBrowseSection: BROWSE_SECTIONS.SEARCHES,
    currentBrowseSubsectionData: null, // ex. Companies, Topics, etc..
    isCurrentBrowseSubsectionShown: true,

    /**
     * each array contains objects w/ searchId, filterId, filterGroupId, and firmSourceLabelId
     * used for displaying the summary, running the query, and saving.
     * these are populated on load from the existing search filters, and when filters are selected.
     * `other` is for filter categories the user does not have access to (ie. `news` category from the wrong vertical).
     */
    selectedBrowseFilters: deepCopy(BROWSE_FILTERS_TEMPLATE),

    /**
     * 'Options' arrays contain Option objects (value, label) to be used in SavedSearchInput.
     * the Option objects have additional searchId, filterID, and filterGroupId to be used
     * for displaying the summary, running the query, and saving.
     * 'Values' arrays contain ss ids or free text values
     */
    coreSearchSelectedOptions: [],
    coreSearchValues: [],
    articleAllSelectedOptions: [],
    articleAnySelectedOptions: [],
    articleExcludeSelectedOptions: [],
    articleExactSelectedOptions: [],
    headlineAllSelectedOptions: [],
    headlineAnySelectedOptions: [],
    headlineExcludeSelectedOptions: [],
    headlineExactSelectedOptions: [],
    introAllSelectedOptions: [],
    introAnySelectedOptions: [],
    introExcludeSelectedOptions: [],
    introExactSelectedOptions: [],
    browseSectionListFilterValue: '',

    /**
     * if there are no core terms, but there are headline/intro - any/all filters, we hide the core terms area and
     * default the filters area to the advanced tab.
     */
    isHeadlineOnlyTerms: false,

    ...this.props.queryComponentState,
  }

  componentDidMount() {
    /**
     * filter out duplicate `My Courts` and `My States` searches caused by old bug (keep the newest ones).
     */
    let searchArray = [...this.props.searches]
    const courtId = Math.max(...searchArray.filter(ss => ss.category === 'court').map(ss => ss.id))
    const stateId = Math.max(...searchArray.filter(ss => ss.category === 'state').map(ss => ss.id))
    searchArray = searchArray.filter(ss => {
      return (
        !['court', 'state'].includes(ss.category) ||
        [courtId, stateId].includes(ss.id) ||
        // this is to make sure we include the parents of the `My Courts` and `My States` searches.
        ss.ownerId !== this.props.currentUser.id
      )
    })
    const searches = {}
    searchArray.forEach(ss => {
      searches[ss.id] = ss
    })
    // set searches in state before anything else.
    this.setState({searches}, () => {
      this.setBrowseSection(BROWSE_SECTIONS.SEARCHES)
      const selectedFilters = this.getSelectedFilters()
      const coreTerms = this.getCoreTermsFromQuery()

      if (Object.keys(this.props.queryComponentState).length === 0) {
        this.props.setQueryComponentState(this.state)
        this.setSelectedFilters()
      }

      if (!this.state.coreSearchValues || this.state.coreSearchValues.length === 0) {
        this.setCoreTermsFromQuery()
      }

      // on load, determine if isHeadlineOnlyTerms to maintain the applicable UI even if the filters are removed.
      const isHeadlineOnlyTerms =
        this.props.search &&
        this.props.search.queryType !== 'raw' &&
        (!coreTerms.coreSearchValues || coreTerms.coreSearchValues.length === 0) &&
        (
          selectedFilters.headlineAnySelectedOptions.length > 0 ||
          selectedFilters.headlineAllSelectedOptions.length > 0 ||
          selectedFilters.introAnySelectedOptions.length > 0 ||
          selectedFilters.introAllSelectedOptions.length > 0
        )

      this.setState({isHeadlineOnlyTerms})
    })
  }

  render() {
    const filterSummary = this.haveSelectedFilters() &&
      <div className={classNames('filter-summary')}>
        <SummaryContent
          currentUser={this.props.currentUser}
          currentFirmLibraryName={this.props.currentFirmLibraryName}
          articleAnySelectedOptions={this.state.articleAnySelectedOptions}
          articleAllSelectedOptions={this.state.articleAllSelectedOptions}
          articleExcludeSelectedOptions={this.state.articleExcludeSelectedOptions}
          articleExactSelectedOptions={this.state.articleExactSelectedOptions}
          headlineAnySelectedOptions={this.state.headlineAnySelectedOptions}
          headlineAllSelectedOptions={this.state.headlineAllSelectedOptions}
          headlineExcludeSelectedOptions={this.state.headlineExcludeSelectedOptions}
          headlineExactSelectedOptions={this.state.headlineExactSelectedOptions}
          introAnySelectedOptions={this.state.introAnySelectedOptions}
          introAllSelectedOptions={this.state.introAllSelectedOptions}
          introExcludeSelectedOptions={this.state.introExcludeSelectedOptions}
          introExactSelectedOptions={this.state.introExactSelectedOptions}
          selectedBrowseFilters={this.state.selectedBrowseFilters}
          getSearchById={(id) => this.getSearchById(id)}
          getFirmSourceLabelById={(id) => this.getFirmSourceLabelById(id)}
          removeSummaryFilter={(filter, categoryKey) => this.removeSummaryFilter(filter, categoryKey)}
        />
      </div>

    const browseSectionContent =
      <BrowseSectionContent
        search={this.props.search}
        currentFirmLibraryName={this.props.currentFirmLibraryName}
        currentBrowseSection={this.state.currentBrowseSection}
        currentBrowseSubsectionData={this.state.currentBrowseSubsectionData}
        isCurrentBrowseSubsectionShown={this.state.isCurrentBrowseSubsectionShown}
        browseSectionListFilterValue={this.state.browseSectionListFilterValue}
        getBrowseSectionOptions={() => this.getBrowseSectionOptions()}
        browseSubsectionChange={(option) => this.browseSubsectionChange(option)}
        setBrowseSectionListFilterValue={(value) => this.setBrowseSectionListFilterValue(value)}
        showBrowseSectionCategoryList={() => this.showBrowseSectionCategoryList()}
        isFirmSourceLabelSelected={(id) => this.isFirmSourceLabelSelected(id)}
        isFilterSelected={(ssId) => this.isFilterSelected(ssId)}
        handleBrowseFilterSelection={(value, checked, isAll) => this.handleBrowseFilterSelection(value, checked, isAll)}
        getSearchesFromQuery={(query) => this.getSearchesFromQuery(query)}
      />

    const browseSectionOptionsContent = this.getBrowseSectionOptionsContent()

    const advancedFiltersContent =
      <AdvancedFiltersContent
        search={this.props.search}
        articleAnySelectedOptions={this.state.articleAnySelectedOptions}
        articleAllSelectedOptions={this.state.articleAllSelectedOptions}
        articleExcludeSelectedOptions={this.state.articleExcludeSelectedOptions}
        articleExactSelectedOptions={this.state.articleExactSelectedOptions}
        headlineAnySelectedOptions={this.state.headlineAnySelectedOptions}
        headlineAllSelectedOptions={this.state.headlineAllSelectedOptions}
        headlineExcludeSelectedOptions={this.state.headlineExcludeSelectedOptions}
        headlineExactSelectedOptions={this.state.headlineExactSelectedOptions}
        introAnySelectedOptions={this.state.introAnySelectedOptions}
        introAllSelectedOptions={this.state.introAllSelectedOptions}
        introExcludeSelectedOptions={this.state.introExcludeSelectedOptions}
        introExactSelectedOptions={this.state.introExactSelectedOptions}
        handleArticleAnySearchChange={(values) => this.handleArticleAnySearchChange(values)}
        handleArticleAllSearchChange={(values) => this.handleArticleAllSearchChange(values)}
        handleArticleExcludeSearchChange={(values) => this.handleArticleExcludeSearchChange(values)}
        handleArticleExactSearchChange={(values) => this.handleArticleExactSearchChange(values)}
        handleHeadlineAnySearchChange={(values) => this.handleHeadlineAnySearchChange(values)}
        handleHeadlineAllSearchChange={(values) => this.handleHeadlineAllSearchChange(values)}
        handleHeadlineExcludeSearchChange={(values) => this.handleHeadlineExcludeSearchChange(values)}
        handleHeadlineExactSearchChange={(values) => this.handleHeadlineExactSearchChange(values)}
        handleIntroAnySearchChange={(values) => this.handleIntroAnySearchChange(values)}
        handleIntroAllSearchChange={(values) => this.handleIntroAllSearchChange(values)}
        handleIntroExcludeSearchChange={(values) => this.handleIntroExcludeSearchChange(values)}
        handleIntroExactSearchChange={(values) => this.handleIntroExactSearchChange(values)}
      />

    const filterTabs =
      <div>
        <div className="filter-tabs">
          <TabbedContainer
            defaultTabName={this.state.isHeadlineOnlyTerms ? 'advanced-filters' : 'browse'}
            useUpdatedCss
          >
            <Tab label="Browse" name="browse">
              <div className="filter-browse-column filter-browse-column-sections">
                {browseSectionOptionsContent}
              </div>
              <div className="filter-browse-column filter-browse-column-content">
                {browseSectionContent}
              </div>
              <br className="clear-both"/>
            </Tab>
            <Tab label="Advanced Filters" name="advanced-filters">
              {advancedFiltersContent}
            </Tab>
          </TabbedContainer>
        </div>
      </div>

    const coreTermsDescription =
      (this.props.search && this.props.search.queryType === 'raw') || this.props.saveData.queryType === 'raw'
        ? <div className="section-description">
            Boolean searches rely on user provided words, phrases and operators to help create searches that are
            highly targeted and precise. For assistance, please use
            our <a href="/media/1521318570/pdf/manzama-boolean.pdf" target="_blank">Boolean
              Quick Reference Guide</a>.
          </div>
        : (this.props.search && this.props.search.queryType === 'all') || this.props.saveData.queryType === 'all'
            ? <div className="section-description">
                You've started an Advanced Search. Adding terms in this string will equate to adding "AND" components,
                making the search more specific. This input does not accept boolean operations. To enter a boolean query, start an <a href="/advanced-search">
                advanced search</a>.
              </div>
            : <div className="section-description">
                Start your search by adding core terms to your query. This is equal to creating an "OR" search. This
                input does not accept boolean operations. To enter a boolean query, start an <a href="/advanced-search">
                advanced search</a>.
              </div>

    const coreTermsInput =
      (this.props.search && this.props.search.queryType === 'raw') || this.props.saveData.queryType === 'raw'
        ? <div className={classNames('input-container')}>
            <textarea
              className="text-area"
              value={this.state.coreSearchValues[0]}
              onChange={(evt) => this.handleRawCoreTermsChange(evt.target.value)}
            />
          </div>
        : <SavedSearchInput
            selectedValues={this.state.coreSearchSelectedOptions}
            omitIds={this.props.search ? [this.props.search.id] : []}
            onChange={values => this.handleCoreTermsChange(values)}
          />

    const searchTermsHeaderText =
      (this.props.search && this.props.search.queryType === 'raw') || this.props.saveData.queryType === 'raw'
        ? <span>Boolean Search Query</span>
        : <span>Your Core Search Terms</span>

    const filteredByDescription =
      <React.Fragment>
        {!this.props.isMulti && <h3 className="row-header-label">Filtered by</h3>}

        <div className="section-description">
          <p>Narrow {this.props.isMulti ? 'the selected searches' : 'your search'} by adding filters.
            Adding a filter is the same as creating an "AND" search. Adding multiple filters from the same category
            creates an "OR" search within that category.</p>

          {
            this.props.isMulti &&
            <p>Filters applied here will be added to all selected searches. Any existing filters on those searches will
              be left unchanged, unless you check the "Remove Existing Filters" box below.</p>
          }
        </div>
      </React.Fragment>

    const runQueryButtonLabel =
      this.props.isTier3
        ? 'Update Results'
        : 'Run Search'

    const coreTermsContainer =
      !this.state.isHeadlineOnlyTerms && !this.props.isMulti &&
      <div className="row search-terms-row">
        <div className="row-header">
          <h3 className="row-header-label">{searchTermsHeaderText}</h3>
        </div>
        {coreTermsInput}
        {coreTermsDescription}
      </div>

    const isTermFrequencyFilterExpanded = this.props.isTermFrequencyFilterExpanded
    const pendingTermFrequencyFilters = this.props.pendingTermFrequencyFilters
    const termFrequencyFilterLabel = pendingTermFrequencyFilters.length === 1
      ? `Term Frequency Filter (${pendingTermFrequencyFilters.length})`
      : pendingTermFrequencyFilters.length > 1
        ? `Term Frequency Filters (${pendingTermFrequencyFilters.length})`
        : 'Term Frequency Filter'
    const termFrequencyFilterContainer =
      ((this.props.search && this.props.search.queryType === 'raw') || this.props.saveData.queryType === 'raw') && !this.props.isMulti &&
      <React.Fragment>
        <div className={classNames('term-frequency-edit')}
          onClick={() =>
            this.props.shouldShowTermFrequencyFilter(!isTermFrequencyFilterExpanded)}
        >
          <span
            className={classNames('expand-arrow', {'collapsed': !isTermFrequencyFilterExpanded})}
          />
            <a className="term-frequency">{termFrequencyFilterLabel}</a>
        </div>
        {isTermFrequencyFilterExpanded && (
          <TermFrequencyFilter/>
        )}
      </React.Fragment>

    const buildFiltersContent =
      this.state.currentView === 'build' &&
      <div>
        {coreTermsContainer}
        {termFrequencyFilterContainer}
        <div className="row filter-row">
          <div className="row-header">
            {filteredByDescription}
          </div>
          {filterSummary}
          {filterTabs}
        </div>
      </div>

    const relevancyLevel = RELEVANCE_LEVEL_LOOKUP[
      this.props.saveData.solrSearchField ||
      (this.props.search && this.props.search.solrSearchField) ||
      RELEVANCE_DEFAULT
    ]

    const enableRelevanceFilter = this.props.hasFetchedRelevanceIds
      ? this.props.canShowRelevanceFilter
      : this.props.search && this.props.search.shouldShowRelevanceFilter

    const relevanceFilterSlider =
      !this.props.isMulti &&
      <SearchRelevanceFilter
        value={ relevancyLevel }
        onChange={value => this.handleRelevanceFilterChange(value)}
        isDisabled={!enableRelevanceFilter}
        tipDirection={'top'}
      />

    const groupingLevel =
      this.props.saveData.groupingLevel ||
      (this.props.search && this.props.search.groupingLevel) ||
      FOCUS_LEVEL_STANDARD

    const groupingFilterSlider =
      !this.props.isMulti &&
      <SearchGroupingFilter
        value={ groupingLevel }
        onChange={val => this.handleGroupingLevelChange(val)}
      />

    const languages =
      this.props.saveData.languageFilters ||
      (this.props.search && this.props.search.languageFilters) ||
      null

    const languageFilters = languages
      ? <SearchLanguageFilter
          languages={languages}
          onChange={val => this.handleLanguageFilterChange(val)}
          isSingleColumn={false}
        />
      : null

    const sliders = this.state.currentView === 'build' &&
      <div className={'sliders-row'}>
        {relevanceFilterSlider}
        {groupingFilterSlider}
        {languageFilters}
      </div>

    const leftButtons =
      this.state.currentView === 'build'
        ? <div>
            {!this.props.isMulti && <Button isDestructive={true} label="Reset Filters" className="button-left" onClick={() => this.reset()}/>}
            <Button isDestructive={true} label="Clear Filters" className="button-left" onClick={() => this.clear()}/>
          </div>
        : <div/>

    const runSearchButton =
      <Button
        label={runQueryButtonLabel}
        className="button-right"
        onClick={() => this.runQuery()}
        disabled={!this.canProceed()}
      />

    /**
     * Admin -> Searches uses this.
     * the save button is shown for existing searches.
     * the next button is shown when creating new searches.
     */
    const saveButton = this.props.includeSaveButton &&
      (
        this.props.search
          ? <Button
            label="Save Search"
            className="button-right"
            onClick={() => this.save()}
            isPrimary={true}
          />
          : <Button
            label="Next Step"
            className="button-right"
            onClick={() => this.nextStep()}
            disabled={!this.canProceed()}
          />
      )

    const buttonsRow =
      this.state.currentView === 'build' && !this.props.isMulti &&
      <div className={classNames('buttons-container', {'background-adjust': this.state.currentView === 'results'})}>
        <div className="buttons-row">
          {leftButtons}

          <div>
            {runSearchButton}
            {saveButton}
          </div>
        </div>
      </div>

    const resultsContainer =
      this.state.currentView === 'results' &&
      <SampleSearchResults
        isLoading={this.props.isLoading}
        search={this.props.search}
        searchResults={this.props.searchResults}
        setCurrentView={(view) => this.setCurrentView(view)}
      />

    return (
      <div id="search-filters-container" className={classNames({'background-adjust': this.state.currentView === 'results'})}>
        {buildFiltersContent}
        {resultsContainer}
        {sliders}
        {buttonsRow}
      </div>
    )
  }

  /**
   * some filter categories (popular, news, etc..) are based on the firm's vertical.
   * here we make a lookup of the ids and names from the user's filters.
   */
  get categoryLookup() {
    const categories = {
      [ADVANCED_FILTER_CATEGORY_ID]: 'advanced',
      [MY_SEARCHES_FILTER_CATEGORY_ID]: 'searches',
    }
    Object.entries(this.props.availableFiltersByCategory).forEach(([key, value]) => {
      if (value.length > 0) {
        categories[value[0].categoryId] = key
      }
    })
    return categories
  }

  get selectedFilterKeys() {
    // array of ss ids for standard filters and ss categories for special filters
    return this.state.selectedBrowseFilters[this.state.currentBrowseSection].map(filter => {
      if (filter.filterGroupSpecial) {
        return filter.filterGroupSpecial
      } else if (filter.searchId) {
        return filter.searchId
      }
    })
  }

  get selectedFirmSourceLabelIds() {
    return this.state.selectedBrowseFilters.firmSourceLabels.map(filter => filter.firmSourceLabelId)
  }

  getSearchesFromQuery(query) {
    const parts = query.split('~|')
    const searches = []
    parts.forEach(part => {
      if (part.indexOf('ss:') === 0) {
        const ssId = parseInt(part.substring(3))
        const search = this.getSearchById(ssId)
        searches.push(search)
      }
    })
    return searches
  }

  getCoreTermsFromQuery() {
    let newState = {}

    if (this.props.search) {
      if (this.props.search.queryType === 'raw') {
        this.setState({coreSearchValues: [this.props.search.query]})
      }
      else {
        const options = coreTermsForSearch(this.props.search)
          .map(option => {
            option = {...option}
            if (option.value && !option.label && !option.isFreeText) {
              const search = this.getSearchById(option.value)
              if (search) {
                option.label = search.name
                option.isFirmLibrary = search.isFirmLibrary
                option.isFirmLibraryChild = search.isFirmLibraryChild
                option.scope = search.scope
                option.notes = search.notes
                option.ownerName = search.ownerName
              } else {
                option.label = `Parent Search (ID: ${option.value} has been DELETED`
              }
            }
            return option
          })
        const coreSearchValues = options
          .filter(option => option.value !== null)
          .map(option => option.value)
        if (options.length) {
          const currentOptions = this.state.coreSearchSelectedOptions
          newState = {
            coreSearchSelectedOptions: [...currentOptions, ...options],
            coreSearchValues: coreSearchValues
          }
        }
      }
    }

    return newState
  }

  setCoreTermsFromQuery() {
    if (!this.props.search) {
      return
    }
    const newState = this.getCoreTermsFromQuery()
    this.setState(newState)
  }

  getSelectedFilters() {
    const search = this.props.search
    if (!search) {
      return {}
    }
    const browseFilters = deepCopy(BROWSE_FILTERS_TEMPLATE)
    const advancedFilterOptions = {
      text_any: [],
      text_all: [],
      text_exclude: [],
      content_exact: [],
      headline_any: [],
      headline_all: [],
      headline_exclude: [],
      headline_exact: [],
      intro_any: [],
      intro_all: [],
      intro_exclude: [],
      intro_exact: [],
    }
    search.filterGroups.forEach(fg => {
      /**
       * `other` indicates the filter category that the user no longer has access to
       * (ie. the firm's vertical was changed and the `news` category is from the previous one).
       */
      let category = this.categoryLookup[fg.category] || FILTER_CATEGORY_OTHER
      if (category === 'advanced') {
        fg.filters.forEach(f => {
          if (!f.active) {
            return
          }
          let option = {
            ...FILTER_OPTION_TEMPLATE,
            filterGroupCategory: fg.category,
            filterGroupId: fg.id,
            filterId: f.id,
            filterGroupSpecial: fg.special,
            filterField: fg.filterField,
            firmSourceLabelId: f.firmSourceLabel ? f.firmSourceLabel.id : null,
          }
          if (f.search) {
            Object.assign(option, {
              value: f.search.id,
              label: f.search.name,
              searchId: f.search.id,
              scope: f.search.scope,
              notes: f.search.notes,
              isFirmLibrary: f.search.isFirmLibrary || false,
              isFirmLibraryChild: f.search.isFirmLibraryChild || false,
            })
            if (f.search.owner) {
              /**
               * This is a bit of a hack, but the owner data may or may not
               * exist in the entity store, so we just create a model instance
               * on the fly so that we can access its display name.
               */
              const owner = new User(f.search.owner)
              option.ownerName = owner.displayNameLastFirst
            }
          } else if (f.firmSourceLabel) {
            option.value = f.firmSourceLabel.id
            option.label = f.firmSourceLabel.name
          } else {
            Object.assign(option, {
              value: f.queryTerm,
              label: f.queryTerm,
              isFreeText: !fg.special,
            })
          }
          advancedFilterOptions[fg.filterField].push(option)
        })
      } else if (fg.special) {
        // special fg will have only one filter
        const f = fg.filters[0]
        if (!f.active) {
          return
        }
        const option = {
          ...FILTER_OPTION_TEMPLATE,
          filterGroupCategory: fg.category,
          filterGroupId: fg.id,
          filterId: f.id,
          filterGroupSpecial: fg.special,
          filterField: fg.filterField,
        }
        browseFilters[category].push(option)
      } else if (fg.isFirmSourceLabelCategory) {
        fg.filters.forEach(f => {
          if (!f.active) {
            return
          }
          const option = {
            ...FILTER_OPTION_TEMPLATE,
            filterGroupId: fg.id,
            filterId: f.id,
            filterField: null,
            firmSourceLabelId: f.firmSourceLabel.id,
          }
          browseFilters[BROWSE_SECTIONS.FIRM_SOURCE_LABELS].push(option)
        })
      } else {
        fg.filters.forEach(f => {
          if (!f.active) {
            return
          }
          let filterCategory = category
          let option = {
            ...FILTER_OPTION_TEMPLATE,
            filterGroupCategory: fg.category,
            filterGroupId: fg.id,
            filterId: f.id,
            filterGroupSpecial: fg.special,
            filterField: fg.filterField,
          }
          if (f.search) {
            /**
             * for `other` we try to find a corresponding category among the available categories
             * so we can display it in the correct area, instead of in the `other` area.
             */
            if (filterCategory === FILTER_CATEGORY_OTHER) {
              const filterCheck = this.getFilterBySearchId(f.search.id)
              if (filterCheck) {
                filterCategory = this.categoryLookup[filterCheck.categoryId] || FILTER_CATEGORY_OTHER
              }
            }
            Object.assign(option, {
              searchId: f.search.id,
              searchName: f.search.name,
              isFirmLibrary: f.search.isFirmLibrary || false,
              isFirmLibraryChild: f.search.isFirmLibraryChild || false,
              scope: f.search.scope,
              notes: f.search.notes,
            })
            if (f.search.owner) {
              // This is a bit of a hack, but the owner data may or may not
              // exist in the entity store, so we just create a model instance
              // on the fly so that we can access its display name.
              const owner = new User(f.search.owner)
              option.ownerName = owner.displayNameLastFirst
            }
            /**
             * the backend does not differentiate between user search filters and FL search filters.
             * here we need to separate the FL ones in their own browse filter category.
             */
            const searchCheck = this.getSearchById(f.search.id)
            if (searchCheck && searchCheck.isFirmLibrary) {
              filterCategory = BROWSE_SECTIONS.FIRM_LIBRARY_SEARCHES
            }
          }
          browseFilters[filterCategory].push(option)
        })
      }
    })
    let newState = {
      selectedBrowseFilters: {...browseFilters},
    }
    Object.entries(ADVANCED_FILTER_KEYS).forEach(([key, filterField]) => {
      if (!newState[key]) {
        newState[key] = advancedFilterOptions[filterField]
      }
    })
    return newState
  }

  setSelectedFilters() {
    if (!this.props.search) {
      return
    }
    const newState = this.getSelectedFilters()
    this.setState(newState, () => {
      this.setSaveData()
    })
  }

  getCanShowRelevanceFilter(searchIds) {
    this.props.fetchCanShowRelevanceFilterIds(searchIds)
    this.props.setHasFetchedRelevanceIds(true)
  }

  getSelectedOptionData(selectedOptions, category, optionsKey, valuesKey) {
    const filterField = ADVANCED_FILTER_KEYS[optionsKey] || null
    let options = []
    let values = []
    let searchIds = []
    let data = {}
    selectedOptions.forEach(option => {
      const ssId = option.isFreeText || option.firmSourceLabelId? null : parseInt(option.value)
      values.push(option.value)
      if (ssId && optionsKey === 'coreSearchSelectedOptions') {
        searchIds.push(ssId)
      }
      options.push({
        ...option,
        filterGroupCategory: category,
        filterField: filterField,
        searchId: ssId,
      })
    })
    data[optionsKey] = options
    if (valuesKey) {
      data[valuesKey] = values
    }
    if (optionsKey === 'coreSearchSelectedOptions') {
      this.getCanShowRelevanceFilter(searchIds)
    }

    return data
  }

  handleRawCoreTermsChange(query) {
    this.setState({coreSearchValues: [query]}, () => {
      this.setSaveData()
    })
    this.props.onChange()
  }

  handleCoreTermsChange(selectedOptions) {
    const newState = this.getSelectedOptionData(
      selectedOptions, MY_SEARCHES_FILTER_CATEGORY_ID, 'coreSearchSelectedOptions', 'coreSearchValues'
    )
    this.setState(newState, () => {
      this.setSaveData()
    })
    this.props.onChange()
  }

  handleArticleAnySearchChange(selectedOptions) {
    const newState = this.getSelectedOptionData(
      selectedOptions, ADVANCED_FILTER_CATEGORY_ID, 'articleAnySelectedOptions', null
    )
    this.setState(newState, () => {
      this.setSaveData()
    })
    this.props.onChange()
  }

  handleArticleAllSearchChange(selectedOptions) {
    const newState = this.getSelectedOptionData(
      selectedOptions, ADVANCED_FILTER_CATEGORY_ID, 'articleAllSelectedOptions', null
    )
    this.setState(newState, () => {
      this.setSaveData()
    })
    this.props.onChange()
  }

  handleArticleExcludeSearchChange(selectedOptions) {
    const newState = this.getSelectedOptionData(
      selectedOptions, ADVANCED_FILTER_CATEGORY_ID, 'articleExcludeSelectedOptions', null
    )
    this.setState(newState, () => {
      this.setSaveData()
    })
    this.props.onChange()
  }

  handleArticleExactSearchChange(token) {
    this.advancedFilterWordChange(token, 'articleExactSelectedOptions')
  }

  handleHeadlineAnySearchChange(token) {
    this.advancedFilterWordChange(token, 'headlineAnySelectedOptions')
  }

  handleHeadlineAllSearchChange(token) {
    this.advancedFilterWordChange(token, 'headlineAllSelectedOptions')
  }

  handleHeadlineExcludeSearchChange(token) {
    this.advancedFilterWordChange(token, 'headlineExcludeSelectedOptions')
  }

  handleHeadlineExactSearchChange(token) {
    this.advancedFilterWordChange(token, 'headlineExactSelectedOptions')
  }

  handleIntroAnySearchChange(token) {
    this.advancedFilterWordChange(token, 'introAnySelectedOptions')
  }

  handleIntroAllSearchChange(token) {
    this.advancedFilterWordChange(token, 'introAllSelectedOptions')
  }

  handleIntroExcludeSearchChange(token) {
    this.advancedFilterWordChange(token, 'introExcludeSelectedOptions')
  }

  handleIntroExactSearchChange(token) {
    this.advancedFilterWordChange(token, 'introExactSelectedOptions')
  }

  advancedFilterWordChange(token, optionsStateKey) {
    const filterField = ADVANCED_FILTER_KEYS[optionsStateKey] || null
    let selectedOptions = [...this.state[optionsStateKey]]
    if (token.action && (token.action === 'delete')) {
      selectedOptions = this.state[optionsStateKey].filter(option => {
        return (option.value !== token.label)
      })
    } else {
      selectedOptions.push({
        filterGroupCategory: ADVANCED_FILTER_CATEGORY_ID,
        value: token.label,
        label: token.label,
        filterField: filterField,
        isFreeText: true,
      })
    }
    let newState = {}
    newState[optionsStateKey] = selectedOptions
    this.setState(newState, () => {
      this.setSaveData()
    })
    this.props.onChange()
  }

  setSaveData() {
    // keys correspond to component state keys, values correspond to the `filter_field` for advanced filters
    const advancedFilterStateKeys = {
      articleAnySelectedOptions: 'textAny',
      articleAllSelectedOptions: 'textAll',
      articleExcludeSelectedOptions: 'textExclude',
      articleExactSelectedOptions: 'contentExact',
      headlineAnySelectedOptions: 'headlineAny',
      headlineAllSelectedOptions: 'headlineAll',
      headlineExcludeSelectedOptions: 'headlineExclude',
      headlineExactSelectedOptions: 'headlineExact',
      introAnySelectedOptions: 'introAny',
      introAllSelectedOptions: 'introAll',
      introExcludeSelectedOptions: 'introExclude',
      introExactSelectedOptions: 'introExact',
    }
    /**
     * create an object to store selected filters.
     * keys come from the values of `advancedFilterStateKeys`.
     * values are empty arrays.
     */
    const filters = Object.values(advancedFilterStateKeys).reduce((a, b) => (a[b] = [], a), {})
    Object.entries(this.state.selectedBrowseFilters).forEach(([key, sectionFilters]) => {
      if (sectionFilters.length > 0) {
        const categoryId = this.getCategoryId(key) || 'your'
        if (!filters[categoryId])
          filters[categoryId] = []
        filters[categoryId].push(...sectionFilters)
      }
    })
    Object.keys(advancedFilterStateKeys).forEach(key => {
      const filterKey = advancedFilterStateKeys[key]
      this.state[key].forEach(option => {
        filters[filterKey].push({
          filterId: option.filterId,
          searchId: option.searchId,
          value: option.isFreeText ? option.label : null,
          filterGroupId: option.filterGroupId || null,
          firmSourceLabelId: option.firmSourceLabelId,
        })
      })
    })
    const data = {
      queryType: (this.props.search ? this.props.search.queryType : this.props.saveData.queryType),
      filters: flattenFilterData(filters),
    }

    if (this.props.search) {
      data.searchId = this.props.search.id
    }

    if (data.queryType === 'raw') {
      data.query = this.getCoreSearchRawValue()
      data.pendingTermFrequencyFilters = this.props.pendingTermFrequencyFilters
    } else {
      // Make sure to filter out null values (which aren't real terms).
      data.query = queryFromTerms(this.state.coreSearchValues.filter(v => v))
    }

    // set the save data in the redux store so it can be used in multi-step processes (e.g. add new)
    this.props.setSaveData(data)
    this.props.setQueryComponentState(this.state)

    if ('solrSearchField' in this.props.saveData) {
      data.solrSearchField = this.props.saveData.solrSearchField
    }
    if ('groupingLevel' in this.props.saveData) {
      data.groupingLevel = this.props.saveData.groupingLevel
    }

    return data
  }

  getCoreSearchRawValue() {
    let coreSearchRawValue = null
    if ((this.props.search && (this.props.search.queryType === 'raw')) || (this.props.saveData.queryType === 'raw')) {
      coreSearchRawValue = this.state.coreSearchValues[0]
    }
    return coreSearchRawValue
  }

  canProceed() {
    return this.state.coreSearchSelectedOptions.length
      || this.getCoreSearchRawValue()
      || this.haveSelectedFilters()
      || this.props.saveData.solrSearchField
      || this.props.saveData.groupingLevel
  }

  getCategoryId(category) {
    if (this.props.availableFiltersByCategory[category] && (this.props.availableFiltersByCategory[category].length > 0)) {
      return this.props.availableFiltersByCategory[category][0]['categoryId']
    }
    return null
  }

  setCurrentView(view) {
    this.setState({currentView: view})
  }

  setBrowseSection(section) {
    this.setState({currentBrowseSection: section}, () => {
      const option = this.getBrowseSectionOptions()[0]
      this.setState({
        currentBrowseSubsectionData: option,
        browseSectionListFilterValue: '',
        isCurrentBrowseSubsectionShown: true,
      })
    })
  }

  browseSubsectionChange(option) {
    this.setState({currentBrowseSubsectionData: option, isCurrentBrowseSubsectionShown: false})
  }

  showBrowseSectionCategoryList() {
    this.setState({isCurrentBrowseSubsectionShown: true})
  }

  getBrowseSectionOptionsContent() {
    const browseSections = getBrowseSections(this.props.currentFirmLibraryName)
    return Object.keys(browseSections).map(key => {
      const ignoreKeys = [FILTER_CATEGORY_SEARCHES, FILTER_CATEGORY_SOURCE_LABELS, FILTER_CATEGORY_LIB_SEARCHES]
      if (!ignoreKeys.includes(key) && this.props.availableFiltersByCategory[key] === undefined ) {
          return null;
        }
      else if (key === FILTER_CATEGORY_OTHER && this.props.availableFiltersByCategory.other.length === 0){
          return null;
        }
      const section = browseSections[key]
      return (
        <div
          key={'filter-browse-section-' + key}
          className={'filter-browse-section' + (this.state.currentBrowseSection === key? ' selected' : '')}
          onClick={() => this.setBrowseSection(key)}
        >
          <h4 className="section-header">{section.label}</h4>
          <div>{section.description}</div>
        </div>
      )
    })
  }

  setBrowseSectionListFilterValue(value) {
    this.setState({browseSectionListFilterValue: value})
  }

  getSearchById(id) {
    if (this.state.searches[id]) {
      const search = this.state.searches[id]
      const searchData = {...search}
      if (search.owner) {
        searchData.ownerName = search.owner.displayNameLastFirst
      }
      return searchData
    }
    return this.props.firmLibrarySearchFilters.find(ss => ss.id === id)
  }

  getFirmSourceLabelById(id) {
    return this.props.firmSourceLabels.find(fsl => fsl.id === id)
  }

  /**
   * note: this does not include My Categories; those are in `searches`.
   */
  getFilterBySearchId(id) {
    const categoryKeys = Object.keys(this.props.availableFiltersByCategory)
    for (let c = 0; c < categoryKeys.length; c++) {
      const category = categoryKeys[c]
      const filters = this.props.availableFiltersByCategory[category]
      if (!filters) {
        return null
      }
      id = parseInt(id)
      for (let f1 = 0; f1 < filters.length; f1++) {
        const filter = filters[f1]
        if (filter.search.id === id) {
          return {...filter}
        } else {
          const subFilters = filter.filters
          for (let f2 = 0; f2 < subFilters.length; f2++) {
            const subFilter = subFilters[f2]
            if (subFilter.search.id === id) {
              return {...subFilter}
            }
          }
        }
      }
    }
  }

  getUserSearchesByCategory(category) {
    let searches = []
    Object.values(this.state.searches).forEach(search => {
      const ownerId = this.props.search && this.props.search.ownerId ? this.props.search.ownerId : this.props.currentUser.id
      if ((search.ownerId === ownerId) && (search.category === category)) {
        searches.push(search)
      }
    })
    searches = sortBy(prop('name'), searches)
    return searches
  }

  getFirmLibrarySearchFiltersByCategory(category) {
    return this.props.firmLibrarySearchFilters.filter(ss => {
      return ss.category === category
    })
  }

  getBrowseSectionOptions() {
    const searchLabels = getSearchLabels(this.props.currentUser.firm)
    let options = []
    if ([BROWSE_SECTIONS.SEARCHES, BROWSE_SECTIONS.FIRM_LIBRARY_SEARCHES].includes(this.state.currentBrowseSection)) {
      const isFirmLibrary = this.state.currentBrowseSection === BROWSE_SECTIONS.FIRM_LIBRARY_SEARCHES
      Object.keys(searchLabels).forEach(category => {
        let items = isFirmLibrary ? this.getFirmLibrarySearchFiltersByCategory(category) : this.getUserSearchesByCategory(category)
        if (category === 'trusted-uncategorized') {
          const extraItems = isFirmLibrary ? this.getFirmLibrarySearchFiltersByCategory('trusted') : this.getUserSearchesByCategory('trusted')
          items.push(...extraItems)
        }
        if (['court', 'state'].includes(category) && items.length === 0) {
          return
        }
        if (!this.props.currentFirmIsPracticesEnabled && category === SavedSearch.CATEGORIES.PRACTICE) {
          return
        }
        options.push({
          label: searchLabels[category].label,
          value: category,
          items: items,
        })
      })
    } else if (this.state.currentBrowseSection === BROWSE_SECTIONS.FIRM_SOURCE_LABELS) {
      this.props.firmSourceLabels.forEach(fsl => {
        options.push({
          label: fsl.name,
          value: fsl.id,
          items: fsl.feeds,
        })
      })
    } else {
      const filters = this.props.availableFiltersByCategory[this.state.currentBrowseSection]
      const cleanName = name => name.replace('Geographic ', '').replace('Filters', '').replace('Filter', '').replace('-', '')
      const optionsTemp = Object.values(filters).map(filter => {
        return ({
          value: filter.search.id,
          label: cleanName(filter.search.name),
          items: filter.filters.map(search => {
            return ({
              id: search.search.id,
              name: cleanName(search.search.name),
            })
          }),
        })
      })
      let optionsOrdered = []
      if (['geography', 'news'].includes(this.state.currentBrowseSection)) {
        const forcedOrder = ['US Sources', 'United States', 'Canada', 'UK', 'Australia']
        forcedOrder.forEach(value => {
          const foundOptions = optionsTemp.filter(option =>
            option.label.includes(value)
          )
          optionsOrdered = [...optionsOrdered, ...foundOptions]
        })
        const optionsRemaining = optionsTemp.filter(option =>
          !optionsOrdered.some(o => o.value === option.value)
        )
        optionsOrdered = [...optionsOrdered, ...optionsRemaining]
      } else {
        optionsOrdered = [...optionsTemp]
      }
      options = [...optionsOrdered]
    }
    return options
  }

  removeSummaryFilter(filter, categoryKey) {
    let newState = {}
    let allFilters = {...this.state.selectedBrowseFilters}
    if (allFilters[categoryKey]) {
      let sectionFilters = allFilters[categoryKey]
      allFilters[categoryKey] = sectionFilters.filter(f => {
        // TODO: check firmSourceLabelId here
        return (
          (filter.searchId && (f.searchId !== filter.searchId)) ||
          (filter.filterGroupSpecial && (f.filterGroupSpecial !== filter.filterGroupSpecial)) ||
          (filter.firmSourceLabelId && (f.firmSourceLabelId !== filter.firmSourceLabelId))
        )
      })
      newState.selectedBrowseFilters = allFilters
    } else {
      // advanced filter
      const optionsKey = categoryKey + 'SelectedOptions'
      newState[optionsKey] = this.state[optionsKey].filter(option => {
        return option.value !== filter.value
      })
    }
    this.setState(newState, () => {
      this.setSaveData()
    })
    this.props.onChange()
  }

  handleRelevanceFilterChange(value) {
    this.props.setSaveData({solrSearchField: RELEVANCE_LOOKUP[value]})
  }

  handleGroupingLevelChange(value) {
    this.props.setSaveData({groupingLevel: value})
  }

  handleLanguageFilterChange(value) {
    const selectedLanguageIds = value && value.length > 0
      ? value.filter(lang => lang.exclude === false).map(lang => lang.id).join(',')
      : null
    this.props.setSaveData({
      selectedLanguageIds: selectedLanguageIds,
      languageFilters: value
    })
  }

  handleBrowseFilterSelection(value, checked, isAll) {
    let sectionFilters = [...this.state.selectedBrowseFilters[this.state.currentBrowseSection]]
    let allFilters = {...this.state.selectedBrowseFilters}
    if (isAll) {
      if (checked) {
        /**
         * when selecting the 'all' option, remove all others for this section option.
         * cannot simply empty the array because it contains values for all options of the browse selection
         * (ie. all search categories).
         */
        sectionFilters = sectionFilters.filter(f => {
          return !this.state.currentBrowseSubsectionData.items.some(option =>
            f.searchId && (option.id.toString() === f.searchId.toString())
          )
        })
        /**
         * add the `all` option.
         * search categories (My Categories) use special filter groups for `all`.
         * others (news, etc) just use a saved search.
         */
        let sectionFilterKey = FILTER_GROUP_SEARCH_ID_KEY
        if (this.state.currentBrowseSection === BROWSE_SECTIONS.SEARCHES) {
          sectionFilterKey = FILTER_GROUP_SPECIAL_KEY
        } else if (this.state.currentBrowseSection === BROWSE_SECTIONS.FIRM_SOURCE_LABELS) {
          value = parseInt(value)
          sectionFilterKey = FILTER_GROUP_FIRM_SOURCE_LABEL_ID_KEY
        } else {
          value = parseInt(value)
        }
        sectionFilters.push({
          [sectionFilterKey]: value,
        })
      } else {
        // keep everything except the de-selected option (which is the 'all' option in this case).
        sectionFilters = sectionFilters.filter(f => {
          return (
            f.searchId && (f.searchId.toString() !== value.toString()) ||
            f.filterGroupSpecial && (f.filterGroupSpecial.toString() !== value.toString())
          )
        })
      }
    } else {
      value = parseInt(value)
      if (checked) {
        /**
         * check if this selection makes all selected; remove all options for this section and add just the 'all' option.
         * does not apply to searches or FIRM_LIBRARY_SEARCHES; searches uses special filter groups for 'all' and
         * FIRM_LIBRARY_SEARCHES doesn't support a dynamic 'all'.
         * firmSourceLabels only has the 'all' option so will never get here.
         */
        if (![BROWSE_SECTIONS.SEARCHES, BROWSE_SECTIONS.FIRM_LIBRARY_SEARCHES].includes(this.state.currentBrowseSection) && this.willAllBeSelected(value)) {
          sectionFilters = sectionFilters.filter(f => {
            return !this.state.currentBrowseSubsectionData.items.some(option =>
              option.id.toString() === f.searchId.toString()
            )
          })
          sectionFilters.push({
            // this.state.currentBrowseSubsectionData.value is the 'all' option (search id) for this area.
            [FILTER_GROUP_SEARCH_ID_KEY]: this.state.currentBrowseSubsectionData.value,
          })
        } else {
          sectionFilters.push({
            [FILTER_GROUP_SEARCH_ID_KEY]: value,
          })
        }
      } else {
        /**
         * when all are selected, we only store the 'all' option.
         * the 'all' option is the ss category for searches (becomes special FG), or ss id for other sections.
         * so when removing anything, if 'all' is selected, we need to add them individually,
         * then remove the deselected one.
         */
        if (this.isFilterSelected(this.state.currentBrowseSubsectionData.value)) {
          this.state.currentBrowseSubsectionData.items.forEach(option => {
            sectionFilters.push({
              [FILTER_GROUP_SEARCH_ID_KEY]: option.id,
            })
          })
        }
        // remove deselected filter; when removing anything also remove the 'all' option
        const allIdKey = this.state.currentBrowseSection === BROWSE_SECTIONS.SEARCHES
          ? FILTER_GROUP_SPECIAL_KEY
          : FILTER_GROUP_SEARCH_ID_KEY
        sectionFilters = sectionFilters.filter(f => {
          return (
            !f.searchId ||
            f.searchId.toString() !== value.toString()
          ) && (
            !f[allIdKey] ||
            f[allIdKey].toString() !== this.state.currentBrowseSubsectionData.value.toString()
          )
        })
      }
    }
    const category = this.state.currentBrowseSection === BROWSE_SECTIONS.FIRM_SOURCE_LABELS
      ? null
      : this.getCategoryId(this.state.currentBrowseSection) || MY_SEARCHES_FILTER_CATEGORY_ID
    sectionFilters = sectionFilters.map(option => ({
      ...option,
      filterGroupCategory: category,
    }))
    allFilters[this.state.currentBrowseSection] = sectionFilters
    this.setState({selectedBrowseFilters: allFilters}, () => {
      this.setSaveData()
    })
    this.props.onChange()
  }

  isFilterSelected(ssId) {
    return this.selectedFilterKeys.includes(ssId)
  }

  isFirmSourceLabelSelected(id) {
    return this.selectedFirmSourceLabelIds.includes(id)
  }

  willAllBeSelected(value) {
    let allAreSelected = true
    let items = this.state.currentBrowseSubsectionData.items
    Object.keys(items).forEach(key => {
      const item = items[key]
      if ((item.id !== value) && (this.selectedFilterKeys.indexOf(item.id) === -1)) {
        allAreSelected = false
      }
    })
    return allAreSelected
  }

  haveSelectedFilters() {
    for (let key in this.state.selectedBrowseFilters) {
      if (this.state.selectedBrowseFilters[key].length > 0) {
        return true
      }
    }
    return !!(
      this.state.articleAnySelectedOptions.length ||
      this.state.articleAllSelectedOptions.length ||
      this.state.articleExcludeSelectedOptions.length ||
      this.state.articleExactSelectedOptions.length ||
      this.state.headlineAnySelectedOptions.length ||
      this.state.headlineAllSelectedOptions.length ||
      this.state.headlineExcludeSelectedOptions.length ||
      this.state.headlineExactSelectedOptions.length ||
      this.state.introAnySelectedOptions.length ||
      this.state.introAllSelectedOptions.length ||
      this.state.introExcludeSelectedOptions.length ||
      this.state.introExactSelectedOptions.length
    )
  }

  runQuery() {
    this.setCurrentView('results')
    const data = this.setSaveData()
    this.props.onUpdateSearchResults(data)
  }

  save() {
    const data = this.setSaveData()
    this.props.onSave(data)
  }

  nextStep() {
    this.setSaveData()
    let shareOnlyId = null

    // if we only have one ss, and it's global or shared, and no filters, we only share; do not create a new ss
    if (!this.haveSelectedFilters()) {
      if (this.state.coreSearchSelectedOptions.length === 1) {
        const option = this.state.coreSearchSelectedOptions[0]
        if (option.searchId && option.scope && ['global', 'shared'].indexOf(option.scope) !== -1) {
          shareOnlyId = option.searchId
        }
      }
    }

    this.props.setSaveData({shareOnlyId})
    const step = shareOnlyId ? 3 : 2
    this.props.setStep(step)
  }

  reset() {
    this.setSelectedFilters()
  }

  clear() {
    let selectedBrowseFilters = {...this.state.selectedBrowseFilters}
    Object.keys(selectedBrowseFilters).forEach((key) => {
      selectedBrowseFilters[key] = []
    })
    this.setState({
      selectedBrowseFilters: selectedBrowseFilters,
      articleAnySelectedOptions: [],
      articleAllSelectedOptions: [],
      articleExcludeSelectedOptions: [],
      articleExactSelectedOptions: [],
      headlineAnySelectedOptions: [],
      headlineAllSelectedOptions: [],
      headlineExcludeSelectedOptions: [],
      headlineExactSelectedOptions: [],
      introAnySelectedOptions: [],
      introAllSelectedOptions: [],
      introExcludeSelectedOptions: [],
      introExactSelectedOptions: [],
    })
    this.props.onChange()
    this.setSaveData()
  }
}

export default connect(
  createSelector(
    [
      globalSelectors.getCurrentUser,
      globalSelectors.getAvailableFilters,
      globalSelectors.getFirmSourceLabels,
      globalSelectors.getCurrentFirmIsPracticesEnabled,
      advancedSearchSelectors.getAdvancedSearch,
      advancedSearchSelectors.pendingTermFrequencyFilters,
      searchesAdminSelectors.canShowRelevanceFilter,
      searchResultsPageSelectors.getHasFetchedRelevanceIds,
      entitiesSelectors.getEntities,
    ],
    (
      currentUser,
      availableFiltersByCategory,
      firmSourceLabels,
      currentFirmIsPracticesEnabled,
      advancedSearch,
      pendingTermFrequencyFilters,
      canShowRelevanceFilter,
      getHasFetchedRelevanceIds,
      entities,
    ) => {
      const orm = Orm.withEntities(entities)
      return {
        currentUser,
        currentFirmIsPracticesEnabled,
        searches: orm.getByIds(SavedSearch, Object.keys(entities.searches)),
        firmLibrarySearchFilters: Object.values(entities.searches).filter(search => search.isFirmLibrary),

        /**
         * keys = values from `FILTER_CATEGORY_LABELS`
         * each item contains the parent search (i.e. all of Canada) and an array containing the sub-searches.
         */
        availableFiltersByCategory,

        /**
         * array of...
         * {id, name, feeds: {id, name}}
         */
        firmSourceLabels,

        isTermFrequencyFilterExpanded: advancedSearch.termFrequency.isTermFrequencyFilterExpanded,
        pendingTermFrequencyFilters: pendingTermFrequencyFilters,
        canShowRelevanceFilter: canShowRelevanceFilter,
        hasFetchedRelevanceIds: getHasFetchedRelevanceIds,
      }
    }
  ),
  {
    shouldShowTermFrequencyFilter: advancedSearchActions.shouldShowTermFrequencyFilter,
    fetchCanShowRelevanceFilterIds: searchesAdminActions.fetchCanShowRelevanceFilterIds,
    setHasFetchedRelevanceIds: searchResultsPageActions.setHasFetchedRelevanceIds,
  },
)(SavedSearchFilters)
