import Promise from 'bluebird'
import Cookie from 'js-cookie'
import superagent from 'superagent'
import {v4 as uuidv4} from 'uuid'

import {GRAPHQL_API_URL, HTTP_REQUEST_HEADER_ID_NAME} from 'app/constants'
import {changeCaseObject} from 'app/utils'


/**
 * Legacy API call helper. Should be used for any non-GraphQL API endpoints.
 */
export function apiCall({url, method, data, callback, options = {}}) {
  let req =
    superagent(method, url)
      .set({'X-Requested-With': 'XMLHttpRequest'})
      .set(HTTP_REQUEST_HEADER_ID_NAME, uuidv4())

  if (options.useCsrfCookie || method === 'PUT' || method === 'POST' || method === 'DELETE') {
    req = req.set('X-CSRFToken', Cookie.get('csrftoken'))
  }

  if (data) {
    // Automatically turn keys into snake_case for the Python side
    const snakeCaseData = changeCaseObject.snakeCase(data)
    if (method === 'GET') {
      req = req.query(snakeCaseData)
    } else if (options.dataFormat == 'json') {
      req = req.send(snakeCaseData)
    } else {
      req = req
        .set({'Content-Type': 'application/x-www-form-urlencoded'})
        .send(snakeCaseData)
    }
  }

  // This function supports both the callback style as well as promises
  if (callback) {
    req = req.end((err, res) => {
      // Automatically make the response body camelCase for JavaScript's
      // benefit
      let camelCaseRes = res
      if (camelCaseRes.body) {
        camelCaseRes = {
          ...camelCaseRes,
          body: changeCaseObject.camelCase(camelCaseRes.body),
        }
      }
      callback(err, camelCaseRes)
    })
  } else {
    req = req.then(resp => {
      return new Promise((resolve, reject) => {
        // Automatically make the response body camelCase for JavaScript's
        // benefit
        resolve({...resp, body: changeCaseObject.camelCase(resp.body)})
      })
    })
  }

  return req
}


export function request({
  url,
  method,
  headers,
  query,
  data,
  uuid,
  withCredentials = false,
  withCsrfToken = false,
  withUuid = true,
  shouldCamelCaseResponse = true,
}) {
  let req = superagent(method, url)
  if (headers) {
    Object.entries(headers).forEach(([header, value]) => {
      req.set(header, value)
    })
  }
  if (query) {
    req = req.query(query)
  }
  if (data) {
    req = req.send(data)
  }
  if (withCredentials) {
    req = req.withCredentials()
  }
  if (withCsrfToken) {
    req = req.set('X-CSRFToken', Cookie.get('csrftoken'))
  }
  if (withUuid) {
    uuid = uuid || uuidv4()
    req = req.set(HTTP_REQUEST_HEADER_ID_NAME, uuid)
  }
  // Wrap the request in another Promise so that we can camelCase the response
  // and add cancellation.
  return new Promise((resolve, reject, onCancel) => {
    req.end((error, response) => {
      if (error) {
        reject(error)
      } else {
        if (shouldCamelCaseResponse) {
          const body = changeCaseObject.camelCase(response.body)
          resolve({
            ...response,
            body,
          })
        } else {
          resolve(response)
        }
      }
    })
    onCancel(() => {
      req.abort()
    })
  })
}


export function restApiCall(endpoint, method, {query, data}) {
  const url = `/frontend-api/${endpoint}/`
  const headers = {'X-CSRFToken': Cookie.get('csrftoken')}
  // Automatically turn keys into snake_case for the Python side
  query = query && changeCaseObject.snakeCase(query)
  data = data && changeCaseObject.snakeCase(data)
  return request({
    url,
    method,
    headers,
    query,
    data,
  })
}


/*
 * Convenience object that contains a method for each supported HTTP verb.
 * Example:
 *
 *   restApi.put('saved-searches', {data: mySavedSearch})
 */
export const restApi = ['get', 'post', 'put', 'delete'].reduce((obj, verb) => {
  obj[verb] = (endpoint, options = {}) => {
    return restApiCall(endpoint, verb.toUpperCase(), {...options})
  }
  return obj
}, {})


export function graphqlQuery(
  query,
  {url = GRAPHQL_API_URL, ...options} = {},
) {
  let queryString
  if (typeof query === 'string') {
    queryString = query
  }
  else {
    queryString = query.toString()
  }
  return request({
    url,
    method: 'POST',
    data: {query: queryString},
    withCsrfToken: true,
    // GraphQL responses are already in camelCase, so we don't need to convert
    // it after the fact.
    shouldCamelCaseResponse: false,
    ...options,
  })
}
