import is from 'is'
import {all, call, select, takeLatest, put} from 'redux-saga/effects'

import * as notifications from 'app/global/notifications'
import {sortOptionLabel} from 'app/search-results-page/search-results-page-strings'
import {timeFrameDisplay} from 'app/strings'
import {handleSagaError} from 'app/utils/errors'
import {dashboardOrderLabel} from "./profile-preferences-strings"

import * as api from './profile-preferences-api'
import * as actions from './profile-preferences-actions'
import * as selectors from './profile-preferences-selectors'
import {
  groupingLabel,
  subscriptionContentSettingLabel,
  relevanceLabel,
  numberOfSearchResults
} from './profile-preferences-strings'


function* handleInit() {
  const response = yield api.fetchPreferences()
  const preferences = {
    languages: response.languages,
    boosters: response.boosters,
    dashboardOrder: response.currentUser.dashboardOrder,
    subscriptionContentFirmDefault:
      response.firmDefaultIncludeSubscriptionContent,
    includeSubscriptionContent:
      response.currentUser.includeSubscriptionContent,
    timeFrame: response.currentUser.defaultTimeFrame,
    timeFrameMobile: response.currentUser.defaultTimeFrameMobile,
    searchResultsOrderBy: response.currentUser.defaultSearchResultsOrderBy,
    relevanceFirmDefault: response.firmDefaultSearchRelevanceLevel,
    relevance: response.currentUser.defaultSearchRelevanceLevel,
    groupingFirmDefault: response.firmDefaultSearchGroupingLevel,
    grouping: response.currentUser.defaultSearchGroupingLevel,
    resultsPerPage: response.currentUser.defaultSearchResultsCount,
    quickFilters: [
      ...response.courtFiltersForCurrentVertical,
      ...response.stateFiltersForCurrentVertical,
    ],
    selectedQuickFilterIds:
      response.currentUser.enabledQuickFilters.map(ss => ss.id),
  }
  yield put(actions.setPreferences(preferences))
  yield put(actions.setIsLoading(false))
}


function* handleChangeLanguagePreferenceRequest(action) {
  const {id, isPreferred} = action.payload
  const language = yield select(selectors.getLanguageById, id)
  yield put(actions.setLanguageLoading({id, isLoading: true}))
  try {
    if (isPreferred) {
      yield api.addPreferredLanguage(id)
    }
    else {
      yield api.removePreferredLanguage(id)
    }
    yield put(actions.setLanguagePreference({id, isPreferred}))
    yield put(notifications.actions.showNotification({
      type: 'success',
      message: `${language.name} ${isPreferred ? 'added to' : 'removed from'} your preferred languages`,
    }))
  }
  catch (error) {
    yield* handleSagaError(error)
  }
  yield put(actions.setLanguageLoading({id, isLoading: false}))
}


function* handleChangeBoosterStateRequest(action) {
  const {id, isApplied} = action.payload
  const booster = yield select(selectors.getBoosterById, id)
  yield put(actions.setBoosterLoading({id, isLoading: true}))
  try {
    if (isApplied) {
      yield api.applyBooster(id)
    }
    else {
      yield api.unapplyBooster(id)
    }
    yield put(actions.setBoosterState({id, isApplied}))
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: `${booster.name} Booster ${isApplied ? 'applied to' : 'removed from'} your profile`,
      })
    )
  }
  catch (error) {
    yield* handleSagaError(error)
  }
  yield put(actions.setBoosterLoading({id, isLoading: false}))
}


function* handleChangeQuickFilterSelectedRequest(action) {
  const {id, isSelected} = action.payload
  const filter = yield select(selectors.getQuickFilterById, id)
  yield put(actions.setQuickFilterLoading({id, isLoading: true}))
  try {
    if (isSelected) {
      yield api.addQuickFilter(id)
    }
    else {
      yield api.removeQuickFilter(id)
    }
    yield put(actions.setQuickFilterSelected({id, isSelected}))
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: `${filter.name} ${isSelected ? 'added to' : 'removed from'} quick filters`,
      })
    )
  }
  catch (error) {
    yield* handleSagaError(error)
  }
  yield put(actions.setQuickFilterLoading({id, isLoading: false}))
}


/**
 * Generic handler for all dropdown values.
 */
function* handleDropdownValueChangeRequest(
  {
    /**
     * The selector for the value of this dropdown.
     */
    selectValue,
    /**
     * The action creator that sets the value of this dropdown.
     */
    setValue,
    /**
     * The action creator that sets the loading state of this dropdown.
     */
    setLoading,
    /**
     * The API call that sets the value on the backend.
     */
    apiCall,
    /**
     * The message to show in the success notification. Can be a function that
     * takes the value as an argument and returns the notification text.
     */
    successNotificationMessage,
  },
  action,
) {
  const value = action.payload
  yield put(setLoading(true))
  const oldValue = yield select(selectValue)
  // NOTE: We immediately apply the state change optimistically. The loading
  // state will disable the dropdown, so the user won't be able to trigger it
  // multiple times, which means we never run into any race conditions because
  // of this.
  yield put(setValue(value))
  try {
    yield call(apiCall, value)
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: is.function(successNotificationMessage)
          ? successNotificationMessage(value)
          : successNotificationMessage,
      })
    )
  }
  catch (error) {
    // Restore the old value since the request failed.
    yield put(setValue(oldValue))
    yield* handleSagaError(error)
  }
  yield put(setLoading(false))
}


/**
 * Generic handler for all "Apply to All" buttons.
 */
function* handleApplyToAllRequest(
  {
    /**
     * The action creator that sets the "currently applying" state.
     */
    setIsApplying,
    /**
     * The API call that does the application on the backend.
     */
    apiCall,
    /**
     * The message to show in the success notification. Can be a function that
     * takes the value as an argument and returns the notification text.
     */
    successNotificationMessage,
    /**
     * The duration for which to show the success notification. Optional.
     */
    successNotificationDuration = undefined,
  },
  action,
) {
  const value = action.payload
  yield put(setIsApplying(true))
  try {
    yield call(apiCall, value)
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: is.function(successNotificationMessage)
          ? successNotificationMessage(value)
          : successNotificationMessage,
        duration: successNotificationDuration,
      })
    )
  }
  catch (error) {
    yield* handleSagaError(error)
  }
  yield put(setIsApplying(false))
}


export default function* profilePreferencesSaga() {
  yield all([
    takeLatest(
      actions.init,
      handleInit,
    ),
    takeLatest(
      actions.changeLanguagePreferenceRequest,
      handleChangeLanguagePreferenceRequest,
    ),
    takeLatest(
      actions.changeBoosterStateRequest,
      handleChangeBoosterStateRequest,
    ),

    takeLatest(
      actions.changeDashboardOrderRequest,
      handleDropdownValueChangeRequest,
      {
        selectValue: selectors.getDashboardOrder,
        setValue: actions.setDashboardOrder,
        setLoading: actions.setDashboardOrderLoading,
        apiCall: api.setDashboardOrder,
        successNotificationMessage: value =>
          `Dashboard saved search order changed to ${dashboardOrderLabel(value)}`,
      },
    ),

    takeLatest(
      actions.changeQuickFilterSelectedRequest,
      handleChangeQuickFilterSelectedRequest,
    ),
    takeLatest(
      actions.changeSubscriptionContentSettingRequest,
      handleDropdownValueChangeRequest,
      {
        selectValue: selectors.getIncludeSubscriptionContent,
        setValue: actions.setSubscriptionContentSetting,
        setLoading: actions.setSubscriptionContentLoading,
        apiCall: api.setSubscriptionContentSetting,
        successNotificationMessage: value =>
          `Subscription content setting changed to ${subscriptionContentSettingLabel(value)}`,
      },
    ),
    takeLatest(
      actions.changeTimeFrameRequest,
      handleDropdownValueChangeRequest,
      {
        selectValue: selectors.getTimeFrame,
        setValue: actions.setTimeFrame,
        setLoading: actions.setTimeFrameLoading,
        apiCall: api.setDefaultTimeFrame,
        successNotificationMessage: value =>
          `Default search time frame changed to ${timeFrameDisplay(value)}`,
      },
    ),
    takeLatest(
      actions.changeTimeFrameMobileRequest,
      handleDropdownValueChangeRequest,
      {
        selectValue: selectors.getTimeFrameMobile,
        setValue: actions.setTimeFrameMobile,
        setLoading: actions.setTimeFrameMobileLoading,
        apiCall: api.setDefaultTimeFrameMobile,
        successNotificationMessage: value =>
          `Default mobile search time frame changed to ${timeFrameDisplay(value)}`,
      },
    ),
    takeLatest(
      actions.changeSearchResultsOrderByRequest,
      handleDropdownValueChangeRequest,
      {
        selectValue: selectors.getSearchResultsOrderBy,
        setValue: actions.setSearchResultsOrderBy,
        setLoading: actions.setSearchResultsOrderByLoading,
        apiCall: api.setSearchResultsOrderBy,
        successNotificationMessage: value =>
          `Default search results order changed to ${sortOptionLabel(value)}`,
      },
    ),

    takeLatest(
      actions.changeRelevanceRequest,
      handleDropdownValueChangeRequest,
      {
        selectValue: selectors.getRelevance,
        setValue: actions.setRelevance,
        setLoading: actions.setRelevanceLoading,
        apiCall: api.setDefaultRelevance,
        successNotificationMessage: value =>
          `Default search relevance filter changed to ${relevanceLabel(value)}`,
      },
    ),
    takeLatest(
      actions.applyRelevanceToAll,
      handleApplyToAllRequest,
      {
        setIsApplying: actions.setRelevanceApplyingToAll,
        apiCall: api.applyRelevanceToAllSearches,
        successNotificationMessage: value =>
          `A relevance level of ${relevanceLabel(value)} is being applied to all of your applicable searches. This process may take several minutes depending on the number of searches involved.`,
        successNotificationDuration: 10,
      },
    ),
    takeLatest(
      actions.changeGroupingRequest,
      handleDropdownValueChangeRequest,
      {
        selectValue: selectors.getGrouping,
        setValue: actions.setGrouping,
        setLoading: actions.setGroupingLoading,
        apiCall: api.setDefaultGrouping,
        successNotificationMessage: value =>
          `Default search results grouping changed to ${groupingLabel(value)}`,
      },
    ),
    takeLatest(
      actions.applyGroupingToAll,
      handleApplyToAllRequest,
      {
        setIsApplying: actions.setGroupingApplyingToAll,
        apiCall: api.applyGroupingToAllSearches,
        successNotificationMessage: value =>
          `A grouping level of ${groupingLabel(value)} will be applied to all of your searches shortly.`,
        successNotificationDuration: 6,
      },
    ),
    takeLatest(
      actions.applyRelevanceToAll,
      handleApplyToAllRequest,
      {
        setIsApplying: actions.setRelevanceApplyingToAll,
        apiCall: api.applyRelevanceToAllSearches,
        successNotificationMessage: value =>
          `A relevance level of ${relevanceLabel(value)} is being applied to all of your applicable searches. This process may take several minutes depending on the number of searches involved.`,
        successNotificationDuration: 10,
      },
    ),
    takeLatest(
      actions.changeGroupingRequest,
      handleDropdownValueChangeRequest,
      {
        selectValue: selectors.getGrouping,
        setValue: actions.setGrouping,
        setLoading: actions.setGroupingLoading,
        apiCall: api.setDefaultGrouping,
        successNotificationMessage: value =>
          `Default search results grouping changed to ${groupingLabel(value)}`,
      },
    ),
    takeLatest(
      actions.changeNumberOfSearchResults,
      handleDropdownValueChangeRequest,
      {
        selectValue: selectors.getresultsPerPage,
        setValue: actions.setresultsPerPage,
        setLoading: actions.setresultsPerPageLoading,
        apiCall: api.setDefaultResultsPerPage,
        successNotificationMessage: value =>
          `Default number of search results changed to ${numberOfSearchResults(value)}`,
      },
    ),
  ])
}
