import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './SummaryCard.less'
import Tooltip from "app/common/Tooltip";
import {
  SUMMARY_HEADERS,
  CHIP_COLOR_OPTIONS,
  CHIP_IMAGE_OPTIONS,
  CHIP_VALUE,
  ACTIVE_USERS_FROM,
  USERS, PERCENT
} from '../usage-constants'
import { getTooltipStyles } from '../utils';

export default class Card extends Component {
  static propTypes = {
    cardHeader: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cardBody: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cardInfo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    toolTip: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    totalEmailsDelivered: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }

  cardBodySection = () => {
    const chipColor = this.props.cardInfo == 0 ? CHIP_COLOR_OPTIONS.GREY : (this.props.cardInfo > 0 ? CHIP_COLOR_OPTIONS.GREEN : CHIP_COLOR_OPTIONS.RED)
    const chipImage = this.props.cardInfo == 0 ? null : (this.props.cardInfo > 0 ? CHIP_IMAGE_OPTIONS.INCREASE : CHIP_IMAGE_OPTIONS.DECREASE)
    const chipText = this.props.selectedOption
    if (this.props.cardHeader === SUMMARY_HEADERS.ACTIVE_USERS) {
      return (
        <div className='card-info-active-users'>
          <span>{ACTIVE_USERS_FROM}</span>
          <span className='active-users'>{this.props.cardInfo} {USERS}</span>
        </div>
      )
    }
    else {
      return (
        <div className='card-info'>
          <div className='card-chip' style={{ backgroundColor: `${chipColor}` }}>
            <img src={chipImage} />{this.props.cardInfo}{PERCENT}</div>
          {CHIP_VALUE[chipText]}
        </div>
      )
    }
  }

  render() {

    return (
      <div className="summary-card-container">
        <div>
          <div>
            <span className='card-header'>{this.props.cardHeader}</span>
            <Tooltip
              render={() => <div style={getTooltipStyles()}>
                {this.props.toolTip}
              </div>}
              direction='bottom'
              noBorder="true"
            >
              <img src="/media/img/Icon.svg" className='tool-tip' />
            </Tooltip>
          </div>
          <div className='card-body'>
            <div>{this.props.cardBody}
            </div>
            {this.cardBodySection()}
          </div>
        </div>
        {this.props.cardHeader===SUMMARY_HEADERS.EMAILS_DELIVERED?<p className='total-emails'>TOTAL EMAILS DELIVERED :{this.props.totalEmailsDelivered}</p>:null}
      </div>
    )
  }
}
