import React from 'react'
import Chip from 'app/common/Chip/Chip'
import * as styles from 'app/esg/TermsContainer/TermsContainer.less'

export default function TermsContainer({ data, labelSelector, onClick, selectedTerm }) {
  return (
    <div className={styles.termsContainer}>
      {data?.map((item) => {
        return (
          <Chip key={item[labelSelector]} label={item[labelSelector].toLowerCase()}
                onClick={(selected) => onClick(item, selected)}
                selected={selectedTerm === item}/>)
      })}
    </div>
  )
}
