import React from 'react'
import {render} from 'react-dom'
import store from 'app/store'

import ProfileSources from './ProfileSources'
import * as constants from './profile-sources-constants'
import {init as profileSourcesInit} from './profile-sources-actions'
import reducer from './profile-sources-reducer'
import saga from './profile-sources-saga'

import AppWrapper from 'app/global/app-wrapper'


export function init() {
  store.dispatch(profileSourcesInit())
  const component = (
    <AppWrapper>
      <ProfileSources />
    </AppWrapper>
  )
  const container = document.getElementById('profile-sources-wrapper')
  render(component, container)
}

export {ProfileSources, constants, reducer, saga}
