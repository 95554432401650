import { createAction } from 'redux-actions'

import { createRequestActions } from 'app/actions/utils'

// Prefix action names with MIS
const createMisAction = name => createAction(`MIS_${name}`)

export const init = createMisAction('INIT')
export const getReports = createMisAction('GET_REPORTS')
export const setReports = createMisAction('SET_REPORTS')
export const addReportsWithFetchedRecipients = createMisAction('ADD_REPORTS_WITH_FETCHED_RECIPIENTS')
export const getArchives = createMisAction('GET_ARCHIVES')
export const setArchives = createMisAction('SET_ARCHIVES')
export const getRecipients = createMisAction('GET_RECIPIENTS')
export const setRecipients = createMisAction('SET_RECIPIENTS')
export const getRenderedReport = createMisAction('GET_RENDERED_REPORT')
export const downloadRenderedReportPDF = createMisAction('DOWNLOAD_RENDERED_REPORT_PDF')
export const setRenderedReportHtml = createMisAction('SET_RENDERED_REPORT_HTML')
export const showRenderedReport = createMisAction('SHOW_RENDERED_REPORT')
export const hideRenderedReport = createMisAction('HIDE_RENDERED_REPORT')
export const showNotification = createMisAction('SHOW_NOTIFICATION')
export const hideNotification = createMisAction('HIDE_NOTIFICATION')
export const hideLoader = createMisAction('HIDE_LOADER')
export const setCurrentReportId = createMisAction('SET_CURRENT_REPORT_ID')
export const setNewFirstName = createMisAction('SET_NEW_FIRST_NAME')
export const setNewLastName = createMisAction('SET_NEW_LAST_NAME')
export const setNewEmailAddress = createMisAction('SET_NEW_EMAIL_ADDRESS')
export const addRecipient = createMisAction('ADD_RECIPIENT')
export const addRecipientSuccess = createMisAction('ADD_RECIPIENT_SUCCESS')
export const setCurrentRecipient = createMisAction('SET_CURRENT_RECIPIENT')
export const setEditedRecipient = createMisAction('SET_EDITED_RECIPIENT')
export const saveRecipient = createMisAction('SAVE_RECIPIENT')
export const saveRecipientSuccess = createMisAction('SAVE_RECIPIENT_SUCCESS')
export const deleteRecipient = createMisAction('DELETE_RECIPIENT')
export const deleteRecipientSuccess = createMisAction('DELETE_RECIPIENT_SUCCESS')
export const saveAutoSubscribe = createMisAction('SAVE_AUTO_SUBSCRIBE')
export const setAutoSubscribe = createMisAction('SET_AUTO_SUBSCRIBE')
export const showSubscribeAllConfirmationModal = createMisAction('SHOW_SUBSCRIBE_ALL_CONFIRMATION_MODAL')
export const hideSubscribeAllConfirmationModal = createMisAction('HIDE_SUBSCRIBE_ALL_CONFIRMATION_MODAL')
export const subscribeAllUsers = createMisAction('SUBSCRIBE_ALL_USERS')
export const setCurrentUserReportIds = createMisAction('SET_CURRENT_USER_REPORT_IDS')
