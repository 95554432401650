import React, {useState, useEffect} from 'react'

import Button from 'app/common/Button'
import Modal from 'app/common/Modal'

export default function InsightsModal({
  isOpen,
  users,
  selectedUserIds,
  insightsLabel,
  action,

  hide,
  setAction,
  handleUserCheckbox,
  apply,
}) {
  const [nameFilter, setNameFilter] = useState('')

  const filteredUsers = users.filter(user => {
    const name = user.displayName.toLowerCase()
    return !nameFilter || name.includes(nameFilter)
  })

  const filteredSelectedUserIds = filteredUsers.filter(user => selectedUserIds.includes(user.id)).map(user => user.id)

  useEffect(() => {

  }, [nameFilter])

  return (
    <Modal
      isOpen={isOpen}
      onClose={hide}
      id="insights-modal"
    >
      <h2>Manage {insightsLabel}</h2>

      <p>
        Turn {insightsLabel}
        <select
          className="inline-select"
          value={action}
          onChange={(evt) => setAction(evt.target.value)}
        >
          <option value="enable">On</option>
          <option value="disable">Off</option>
        </select>
        for the selected users/groups.
      </p>

      <div className="users-area">
        <label>
          <div>Search for Users/Groups by Name</div>
          <input
            className="search-input"
            value={nameFilter}
            onChange={evt => setNameFilter(evt.target.value)}
          />
        </label>

        <div className="user-list">
          <div className="user-list-col">
            {
              filteredUsers.map((user, index) => {
                if (index % 2 === 0) {
                  return (
                    <div
                      key={`user-${user.id}`}
                    >
                      <input
                        type="checkbox"
                        value={user.id}
                        checked={selectedUserIds.includes(user.id)}
                        onChange={(evt) => {handleUserCheckbox(evt)}}
                      />
                      <span className="user-name">{user.displayNameLastFirst}</span>
                    </div>
                  )
                }
              })
            }
          </div>
          <div className="user-list-col">
            {
              filteredUsers.map((user, index) => {
                if (index % 2 === 1) {
                  return (
                    <div
                      key={`user-${user.id}`}
                    >
                      <input
                        type="checkbox"
                        value={user.id}
                        checked={selectedUserIds.includes(user.id)}
                        onChange={(evt) => {handleUserCheckbox(evt)}}
                      />
                      <span className="user-name">{user.displayNameLastFirst}</span>
                    </div>
                  )
                }
              })
            }
          </div>
        </div>
      </div>

      <div className="buttons">
        <Button
          label="Apply"
          disabled={filteredSelectedUserIds.length === 0}
          onClick={() => apply(filteredSelectedUserIds)}
        />

        <Button
          label="Cancel"
          isPlainText
          onClick={hide}
        />
      </div>
    </Modal>
  )
}
