import invariant from 'invariant'
import PropTypes from 'prop-types'
import React, { Component } from 'react'


export default class Option extends Component {
  static propTypes = {
    label: PropTypes.node.isRequired,
    isGroup: PropTypes.bool,
    isInvisible: PropTypes.bool,
    value: PropTypes.any,
  }

  render() {
    // This method doesn't actually render anything, it just does
    // validation.

    invariant(
      this.props.value || this.props.children.length,
      "An Option must either have a value, or be a group that contains other options.",
    )

    return null
  }
}