import { changeCaseObject } from 'app/utils'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'

import store from 'app/store'

import FlaggedItems from './FlaggedItems'
import * as constants from './flagged-items-constants'
import reducer from './flagged-items-reducer'
import saga from './flagged-items-saga'
import * as flaggedItemsActions from './flagged-items-actions'

import './FlaggedItems.less'

export function init(data) {
  // Add the data from this page to the Redux store
  data = changeCaseObject.camelCase(data)
  store.dispatch(flaggedItemsActions.init(data))
  const component = (
    <Provider store={store}>
      <FlaggedItems/>
    </Provider>
  )
  const container = document.getElementById('flagged-items-wrapper')
  render(component, container)
}

export {constants, reducer, saga}
