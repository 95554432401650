export const STATE_PROP_NAME = 'sources'

export const ADD_PROPRIETARY_FEED_STEPS = {
  URL_FORM: 'url-form',
  EXISTING_SOURCES: 'existing-sources',
  FEEDS_FOUND: 'feeds-found',
  EMPTY_FEED_CONFIRMATION: 'empty-feed-confirmation',
  SELF_ADD: 'self-add',
  SELF_ADD_SUCCESS: 'self-add-success',
}

export const LABEL_MODAL_TABS = {
  ADD: 'add',
  REMOVE: 'remove',
}

export const MANAGE_LABELS_SECTION = {
  TITLE: 'Manage Labels',
  DESCRIPTION: 'Create and assign one or more labels to help organize and categorize your sources. You can also assign child labels to your labels to further categorize your sources.',
  EMPTY_LABEL_MESSAGE: 'Label name cannot be empty',
  NO_DATA: 'No data to display',
  EXCEEDS_CHARACTER_LIMIT: 'Label name should not exceed 100 characters'
}

export const PAGINATION_OPTIONS = {
  10: '10',
  20: '20',
  50: '50',
  100: '100',
}

export const LABEL_NAMES = {
  ASSIGNED_LABELS: 'assigned labels',
  ALL_LABELS: 'all labels',
}

export const LABEL_DESCRIPTION = {
  ASSIGNED_LABELS: 'Click X to remove a label. This also clears the associated check box in the All Labels table.',
  ALL_LABELS: 'Select check boxes to add labels and clear check boxes to remove labels.',
}

export const CHECKBOX_ACTIONS = {
  CHECKBOX_DESCRIPTION:'Select the check boxes to add or remove labels from multiple sources.',
  CHECKBOX_TICK:'-A check box with a tick indicates that the label is assigned to all the sources.',
  CHECKBOX_CLEAR:'-A clear check box indicates that the label is not assigned to any sources.',
  CHECKBOX_LINE:'-A check box with a line indicates that the label is assigned to at least one of the sources.'
}

export const MANAGE_LABELS_ACTIONS = {
  SAVE:'Save',
  SHOW:'Show',
  EDIT:'Edit',
  DELETE:'Delete',
  CANCEL:'Cancel',
  CREATE:'Create',
}

export const COLUMNS = {
  CHECKBOX: 'checkbox',
  NAME: 'name',
  TYPE: 'type',
  PUBLICATION_TYPE: 'publicationType',
  LABELS: 'labels',
  CREATED_AT: 'createdAt',
  LAST_CONTENT_AT: 'lastContentAt',
  IS_LIMITED_SEAT: 'isLimitedSeat',
  IS_PROMOTED: 'isPromoted',
  IS_DEMOTED: 'isDemoted',
  ASSIGNMENT_COUNT: 'assignmentCount',
  ACTIONS: 'actions',
  STATUS: 'status'
}

export const COLUMN_OPTIONS = [
  { value: COLUMNS.STATUS, label: 'Status'},
  { value: COLUMNS.TYPE, label: 'Type'},
  { value: COLUMNS.PUBLICATION_TYPE, label: 'Content Type'},
  { value: COLUMNS.LABELS, label: 'Labels'},
  { value: COLUMNS.CREATED_AT, label: 'Date Added'},
  { value: COLUMNS.LAST_CONTENT_AT, label: 'Last Content Date'},
  { value: COLUMNS.IS_LIMITED_SEAT, label: 'Limited Seat'},
  { value: COLUMNS.IS_PROMOTED, label: 'Promoted Wide'},
  { value: COLUMNS.IS_DEMOTED, label: 'Demoted Wide'},
  { value: COLUMNS.ASSIGNMENT_COUNT, label: 'Assigned to Individuals'},
 ]

export const DEFAULT_COLUMNS_VIEW = [
  COLUMNS.STATUS, 
  COLUMNS.NAME, 
  COLUMNS.TYPE, 
  COLUMNS.LABELS, 
  COLUMNS.LAST_CONTENT_AT,
  COLUMNS.IS_PROMOTED,
  COLUMNS.IS_DEMOTED,
  COLUMNS.ASSIGNMENT_COUNT,
  COLUMNS.ACTIONS
]

export const MAX_ROW_COUNT_FOR_LABELS = 50

