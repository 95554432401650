import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import TextBox from 'app/common/TextBox'
import {getEntities} from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import {Feed} from 'app/models'
import AssignmentsTable from './AssignmentsTable'
import AssignSourcesContent from '../../AssignSourcesContent'
import {getSourcesAdmin} from '../../sources-admin-selectors'
import {
  showAssignSourcesContent,
  hideAssignSourcesContent,
  updateAssignments,
  removeAssignments,
} from '../../sources-admin-actions'


@connect(
  createSelector(
    [getEntities, getSourcesAdmin],
    (entities, sourcesAdmin) => {
      const orm = Orm.withEntities(entities)
      const modalData = sourcesAdmin.editModalData
      return {
        feed: orm.getById(Feed, modalData.feedId),
        isAssignSourcesContentShown: sourcesAdmin.isAssignSourcesContentShown,
      }
    }
  ),
  // Map dispatch to props
  {
    updateAssignments,
    removeAssignments,
    showAssignSourcesContent,
    hideAssignSourcesContent,
  },
)
export default class AssignmentsContent extends Component {
  state = {
    filters: {
      name: '',
      user: true,
      group: true,
    },
    selectedAssignmentIDs: [],
  }

  get selectedAssignments() {
    const {assignments} = this.props.feed
    const ids = this.state.selectedAssignmentIDs
    return ids.map(id => assignments.find(a => a.id === id))
  }

  render() {
    const {feed} = this.props
    const {filters, selectedAssignmentIDs} = this.state
    const filteredAssignments = feed.assignments
      .filter(assignment => {
        if (
          filters.name
          && !assignment.assignedTo.displayName
            .toLowerCase()
            .includes(filters.name.toLowerCase())
        ) {
          return false
        }
        if (
          !filters.user
          && assignment.assignmentType === 'user'
        ) {
          return false
        }
        if (
          !filters.group
          && assignment.assignmentType === 'group'
        ) {
          return false
        }
        return true
      })

    const allSelected = selectedAssignmentIDs.length === feed.assignments.length
    const anySelected = !!selectedAssignmentIDs.length

    const content = this.props.isAssignSourcesContentShown
      ? <AssignSourcesContent
          feedIds={[this.props.feed.id]}
          onBack={() => this.hideAssignSourcesContent()}
        />
      : <div>
          <h2 className="content-header">Source Assignments</h2>

          <p>Promote, demote, exclude, or trust this source for individual users and groups by
            clicking the "assign" button.</p>

          <div className="filters">
            <div>
              <label className="search inline">
                <span className="label">Filter by User or Group Name</span>
                <TextBox
                  onChange={this.createFilterChangeHandler('name', 'value')}
                />
              </label>

              <div className="inline">
                <label className="inline">
                  <input
                    type="checkbox"
                    onChange={this.createFilterChangeHandler('user', 'checked')}
                    checked={this.state.filters.user}
                  />
                  <span className="label inline">User</span>
                </label>

                <label className="inline">
                  <input
                    type="checkbox"
                    onChange={this.createFilterChangeHandler('group', 'checked')}
                    checked={this.state.filters.group}
                  />
                  <span className="label inline">Group</span>
                </label>
              </div>
            </div>

            <div className="inline assign-button">
              <button
                className="save button primary"
                onClick={() => this.showAssignSourcesContent()}
              >Assign</button>
            </div>
          </div>

          <div className="bulk-actions">
            <input
              className="select-all"
              type="checkbox"
              onChange={this.onSelectAllChange}
              checked={allSelected}
            />

            <button
              className="button"
              disabled={!anySelected}
              onClick={() => this.onPromote(this.selectedAssignments)}
            >
              Promote
            </button>

            <button
              className="button"
              disabled={!anySelected}
              onClick={() => this.onDemote(this.selectedAssignments)}
            >
              Demote
            </button>

            <button
              className="button"
              disabled={!anySelected}
              onClick={() => this.onTrust(this.selectedAssignments)}
            >
              Trust
            </button>

            <button
              className="button"
              disabled={!anySelected}
              onClick={() => this.onPromoteAndTrust(this.selectedAssignments)}
            >
              Promote & Trust
            </button>

            <button
              className="button"
              disabled={!anySelected}
              onClick={() => this.onExclude(this.selectedAssignments)}
            >
              Exclude
            </button>

            <button
              className="button remove"
              disabled={!anySelected}
              onClick={() => this.onRemove(this.selectedAssignments)}
            >
              Remove
            </button>
          </div>

          <div className="table">
            <AssignmentsTable
              assignments={filteredAssignments}
              onSelectStateChange={this.onSelectStateChange}
              onPromote={this.onPromote}
              onDemote={this.onDemote}
              onTrust={this.onTrust}
              onPromoteAndTrust={this.onPromoteAndTrust}
              onExclude={this.onExclude}
              onRemove={this.onRemove}
            />
          </div>
        </div>

    return (
      <div className="assignments-content">
        {content}
      </div>
    )
  }

  // Event handlers

  /*
   * Creates a function that sets the filter property at `filterName` to
   * the value on the `event.target` attribute at `eventAttrName`.
   *
   * For example:
   *
   *   <input onChange={this.createFilterChangeHandler('name', 'value')} />
   *
   * gives the 'name' filter the value `event.target.value`.
   */
  createFilterChangeHandler(filterName, eventAttrName) {
    return event => {
      this.setState({
        filters: {
          ...this.state.filters,
          [filterName]: event.target[eventAttrName],
        },
      })
    }
  }

  onSelectAllChange = (event) => {
    if (event.target.checked) {
      const {assignments} = this.props.feed
      this.setState({selectedAssignmentIDs: assignments.map(a => a.id)})
    } else {
      this.setState({selectedAssignmentIDs: []})
    }
  }

  onSelectStateChange = (assignment, selected) => {
    const ids = this.state.selectedAssignmentIDs
    if (selected) {
      this.setState({selectedAssignmentIDs: [...ids, assignment.id]})
    } else {
      this.setState({
        selectedAssignmentIDs: ids.filter(id => id !== assignment.id),
      })
    }
  }

  onPromote = (assignments) => {
    this.props.updateAssignments({
      ids: assignments.map(a => a.id),
      isPromoted: true,
    })
  }

  onDemote = (assignments) => {
    this.props.updateAssignments({
      ids: assignments.map(a => a.id),
      isDemoted: true,
    })
  }

  onTrust = (assignments) => {
    this.props.updateAssignments({
      ids: assignments.map(a => a.id),
      isTrusted: true,
    })
  }

  onPromoteAndTrust = (assignments) => {
    this.props.updateAssignments({
      ids: assignments.map(a => a.id),
      isPromoted: true,
      isTrusted: true,
    })
  }

  onExclude = (assignments) => {
    this.props.updateAssignments({
      ids: assignments.map(a => a.id),
      isExcluded: true,
    })
  }

  onRemove = (assignments) => {
    const ids = assignments.map(a => a.id)
    this.props.removeAssignments(ids)

    // Deselect removed feeds so we don't have invalid IDs
    const selectedAssignmentIDs = [...this.state.selectedAssignmentIDs].filter(id => !ids.includes(id))
    this.setState({selectedAssignmentIDs})
  }

  showAssignSourcesContent(feedId) {
    this.props.showAssignSourcesContent()
  }

  hideAssignSourcesContent(feedId) {
    this.props.hideAssignSourcesContent()
  }
}
