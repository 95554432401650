import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import EmptyFeedConfirmation from './EmptyFeedConfirmation'
import ExistingSourcesSelect from './ExistingSourcesSelect'
import NewFeedSelect from './NewFeedSelect'
import SelfAddForm from './SelfAddForm'
import SelfAddSuccess from './SelfAddSuccess'
import SourceURLForm from './SourceUrlForm'
import {ADD_PROPRIETARY_FEED_STEPS as STEPS} from '../sources-admin-constants'
import {getSourcesAdmin} from '../sources-admin-selectors'
import {
  updateCreateProprietaryFeedData,
  resetCreateProprietaryFeedData,
  setShouldShowSelfAddModal,
  createProprietaryFeed,
} from '../sources-admin-actions'

@connect(
  createSelector(
    [getSourcesAdmin],
    (sourcesAdmin) => {
      return {
        createProprietaryFeedData: sourcesAdmin.createProprietaryFeedData,
      }
    }
  ),
  {
    updateCreateProprietaryFeedData,
    resetCreateProprietaryFeedData,
    setShouldShowSelfAddModal,
    createProprietaryFeed,
  },
)
export default class AddPrivateSource extends Component {
  state = {
    selectedFeed: null,
    addedFeed: null,
  }

  render() {
    const {currentStep, feeds} = this.props.createProprietaryFeedData

    let content
    if (currentStep === STEPS.URL_FORM) {
      content =
        <SourceURLForm/>
    }

    else if (currentStep === STEPS.EXISTING_SOURCES) {
      content =
        <ExistingSourcesSelect/>
    }

    else if (currentStep === STEPS.FEEDS_FOUND) {
      content =
        <NewFeedSelect/>
    }

    else if (currentStep === STEPS.EMPTY_FEED_CONFIRMATION) {
      content =
        <EmptyFeedConfirmation
          feed={feeds[0]}
          onConfirm={this.addFeed}
          onModalClose={() => this.props.setShouldShowSelfAddModal(false)}
        />
    }

    else if (currentStep === STEPS.SELF_ADD) {
      content =
        <SelfAddForm/>
    }

    else if (currentStep === STEPS.SELF_ADD_SUCCESS) {
      content =
        <SelfAddSuccess
          newFeedName={this.props.createProprietaryFeedData.newFeedName}
          onStartOver={this.startOver}
          onModalClose={() => this.props.setShouldShowSelfAddModal(false)}
        />
    }

    return content
  }

  addFeed = (feed) => {
    this.props.updateCreateProprietaryFeedData({
      currentStep: STEPS.SELF_ADD,
      selectedFeed: feed,
    })
  }

  startOver = () => {
    this.props.resetCreateProprietaryFeedData()
  }
}
