import {graphqlQuery} from 'app/api'
import {args, gql} from 'app/graphql'


const searchFields = () => {
  return (`
    id
    copyOfId
    owner {
      id
    }
    parentSearches {
      id
      name
      isFirmLibrary
      isFirmLibraryChild
      scope
      owner {
        id
        firstName
        lastName
        username
      }
    }
    name
    category
    scope
    query
    queryType
    solrSearchField
    groupingLevel
    filterGroups {
      id
      category: categoryId
      special: specialType
      filterField
      isFirmSourceLabelCategory
      filters {
        id
        active
        queryTerm
        search {
          id
          name
          scope
          category
          isFirmLibrary
          isFirmLibraryChild
          owner {
            id
            firstName
            lastName
            username
          }
        }
        firmSourceLabel {
          id
          name
        }
      }
    }
    noticeConfig {
      maxItems {
        defaultPubTypes
        allFilings
        twitter
        event
      }
      frequency
    }
    shouldShowRelevanceFilter
    termFrequencyFilters { 
      termArray
      frequency
      targetField
    }
    languageFilters {
      id
      name
      exclude
    }
    excludedFeeds {
      id
      name
    }
  `)
}


export function createSearches({searchIds, searchPhrases, userId, category, noticeConfig}) {
  const query1 = searchIds.map((id, index) => {
    return (
      gql`
        mutation {
          search${index}: saveSearch(${args({
            userId, 
            id, 
            isMakeChild: true, 
            category, 
            noticeConfig
          })}) {
            id
            name
            category
          }
        }
      `
    )
  }).join('')
  const query2 = searchPhrases.map((searchPhrase, index) => {
    return (
      gql`
        mutation {
          search${index}: saveSearch(${args({
            userId, 
            searchPhrase, 
            category, 
            noticeConfig
          })}) {
            id
            name
            category
          }
        }
      `
    )
  }).join('')
  return graphqlQuery(
    query1 + query2
  ).then(({body: {data}}) => data)
}


export function fetchSearch(id) {
  return graphqlQuery(
    gql`
      query {
        search: savedSearch(${args({id})}) {
          ${searchFields}
        }
      }
    `
  ).then(({body: {data}}) => data)
}

const resultsQueryPart = ({id,  ...resultsArgs}) => gql`
  results(${args(resultsArgs)}) {
    documents {
      id
      link
      headline
      publishedAt
      snippet
      feed {
        id
        name
      }
      link
      groupCount
      groupDocs {
        id
        headline
        feed {
          id
          name
        }
      }
    }
  }
`

export function fetchSearchResults({
  id,
  query,
  queryType,
  filters,
  termFrequencyFilters,
  selectedLanguageIds
}) {
  const resultsArgs = {}
  if (id) {
    resultsArgs.id = id
  }
  if (selectedLanguageIds){
    resultsArgs.selectedLanguageIds = selectedLanguageIds.split(',').map(id => parseInt(id))
  }

  return graphqlQuery(
    gql`
      query {
        savedSearch(${args({
          id, 
          query, 
          queryType, 
          filters, 
          termFrequencyFilters,
        })}) {
          ${() => resultsQueryPart(resultsArgs)} 
        }
      }
    `
  ).then(({body: {data}}) => data)
}


export function updateSearch({
  id,
  isMakeCopy,
  name,
  scope,
  category,
  searchPhrase,
  filters,
  noticeConfig,
  solrSearchField,
  termFrequencyFilters,
  selectedLanguageIds,
  groupingLevel
}) {
  return graphqlQuery(
    gql`
      mutation {
        search: saveSearch(${args({
          id,
          isMakeCopy,
          name,
          scope,
          category,
          searchPhrase,
          filters,
          noticeConfig,
          solrSearchField,
          termFrequencyFilters,
          selectedLanguageIds,
          groupingLevel
        })}) {
          ${searchFields}
        }
      }
    `
  ).then(({body: {data}}) => data)
}


export function deleteSearches(ids) {
  const query = ids.map(id => {
    return (
      `mutation {
        deleteSearch(id: ${id}) {
          success
        }
      }`
    )
  }).join('')
  return graphqlQuery(
    query
  ).then(({body: {data}}) => data)
}


export function fetchSearches({
  category,
  scope,
  isFirmLibrary,
}) {
  return graphqlQuery(
    gql`
      query {
        savedSearches(${args({
          category,
          scope,
          isFirmLibrary,
        })}) {
          id
          name
          category
          notes
          isFirmLibrary
        }
      }
    `
  ).then(({body: {data}}) => data)
}


export function fetchFeaturedSearches({category}) {
  return graphqlQuery(
    gql`
      query {
        savedSearches: featuredSearches(${args({category})}) {
          id
          name
          category
          notes
          isFirmLibrary
        }
      }
    `
  ).then(({body: {data}}) => data)
}


/**
 * this is separate from `fetchSearches` because `copyOfId` is expensive and only needed for the browse modal.
 */
export function fetchUserSearches({
  category,
  ownerId,
}) {
  return graphqlQuery(
    gql`
      query {
        savedSearches(${args({
          category,
          ownerId,
        })}) {
          id
          name
          category
          copyOfId
          labels {
            id
            name
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}


export function setUserGlobalSearches(ids, category) {
  return graphqlQuery(
    gql`
      mutation {
        searches: setUserGlobalSearches(${args({ids, category})}) {
          id
          name
          category
        }
      }
    `
  ).then(({body: {data}}) => data)
}


export function setUserFirmLibrarySearches(ids, category) {
  return graphqlQuery(
    gql`
      mutation {
        searches: setUserFirmLibrarySearches(${args({ids, category})}) {
          id
          name
          category
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function setUserFeaturedSearches(ids, category) {
  return graphqlQuery(
    gql`
      mutation {
        searches: setUserFeaturedSearches(${args({ids, category})}) {
          id
          name
          category
        }
      }
    `
  ).then(({body: {data}}) => data)
}
