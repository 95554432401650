import {prop} from 'ramda'

import * as entitySelectors from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import {User} from 'app/models'

/**
 * A convenience utility that returns a selector that gets the prop with
 * the given name.
 * Example:
 *   createSelector(getProp('title'), title => ...)
 */
export const getProp = propName => (state, props) => props[propName]

export const getAppName = prop('appName')
export const getAppNameFull = prop('appNameFull')
export const getAvailableFilters = prop('filters')
export const getFirmSourceLabels = prop('firmSourceLabels')
export const getQuickFilterOptions = prop('quickFilterOptions')
export const getFlaggedIds = prop('flaggedIds')
export const getInsightsLabel = prop('insightsLabel')
export const getInsightsEnabled = prop('currentUserHasInsights')
export const getHasDnbAccess = prop('currentUserHasDnbAccess')
export const getProfileSearchIds = prop('profileSearches')

export const getCurrentFirmIsPracticesEnabled = prop('currentFirmIsPracticesEnabled')
export const getCurrentFirmLibraryName = prop('currentFirmLibraryName')
export const getCurrentFirmShowAlternateDateFormat = prop('currentFirmShowAlternateDateFormat')
export const getCurrentUserId = prop('currentUser')
export const getCurrentUserRole = prop('currentUserRole')
export const getCurrentUserFullName = prop('currentUserFullName')
export const getCurrentUserIsClientAdmin = prop('currentUserIsClientAdmin')
export const getCurrentUserIsStaff = prop('currentUserIsStaff')
export const getOriginalUserIsStaff = prop('originalUserIsStaff')
export const getCurrentUserTimezoneOffset = prop('currentUserTimezoneOffset')
export const getCurrentUserHasRawQueryAccess = prop('currentUserHasRawQueryAccess')
export const getCurrentUserGroupIds = prop('userGroups')
export const getCurrentUserReceiveMonthlyUsageReport = prop('currentUserReceiveMonthlyUsageReport')
export const getCurrentUserReceiveMonthlyNewUsageReport = prop('currentUserReceiveMonthlyNewUsageReport')
export const getCurrentUserIsFirmLibraryGroup = prop('currentUserIsFirmLibraryGroup')
export const getIsLargeFirm = prop('isLargeFirm')
export const getActiveUserCount = prop('activeUserCount')
export const getActiveGroupCount = prop('activeGroupCount')
export const getCurrentFirmOrgType = prop('currentFirmOrgType')
export const getCurrentFirmEnableSourceLastContentDisplay = prop('currentFirmEnableSourceLastContentDisplay')

export const getSiteUrl = prop('siteUrl')
export const getAppUrl = prop('appUrl')
export const getAdminConsoleRoot = prop('adminConsoleRoot')

export const getHighlightBlog = prop('highlightBlog')

export const getCurrentUser = state => {
  const orm = Orm.withEntities(entitySelectors.getEntities(state))
  return orm.getById(User, getCurrentUserId(state))
}
export const getCurrentFirm = state => getCurrentUser(state).firm
export const getOriginalUserId = prop('originalUser')
