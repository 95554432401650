import React from 'react'


export default function DomesticNewsModal(props) {
  return (
    <ul>
      <li className="comparison-header">Energy News Without Booster</li>

      <li className="comparison-article">
        <div className="boosted"/>
        <div>Debate highlights West Virginia's inaction on energy diversity, efficiency.</div>
        <div className="source">Environmental Health News - Climate and Energy</div>
      </li>

      <li className="comparison-article">
        <div className="boosted"/>
        <div>U.S. nuclear industry achieved record capacity factor in 2014</div>
        <div className="source">SmartBrief - Nuclear Energy Institute</div>
      </li>

      <li className="comparison-article">
        <div className="boosted"/>
        <div>MICHIGAN: Governor says energy plan coming in March</div>
        <div className="source">E&amp;E Energy Wire News</div>
      </li>

      <li className="comparison-article">
        <div className="boosted"/>
        <div>Stocks up at midday as investors feel encouraged by oil price gains</div>
        <div className="source">Fox Business - Energy</div>
      </li>

      <li className="comparison-article">
        <div className="boosted"/>
        <div>OIL COLUMN - Brent above US\$50 as markets wait for Draghi</div>
        <div className="source">Proactive Investors Markets</div>
      </li>
    </ul>
  )
}
