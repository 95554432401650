import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, {useState, useRef, useEffect} from 'react'
import {titleCase} from 'title-case'
import {CHART_COLORS, EXCLUDED_COLOR, ON_CLICK_TYPES, CHART_TYPES, LINE_CHART_KEY_LENGTH}
  from "./constants"
import styles from './MzChart.less'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Legend,
  Tooltip
} from "recharts"
import is from "is"
import ExportChart from "./ExportChart"
import {usePrevious} from "app/utils/hooks"
import LoadingSpinner from "app/common/LoadingSpinner"
import CustomXAxisTick from "./CustomXAxisTick"


export default function MzLineChart({
  chartData,
  className,
  onChartClickFunc,
  onClickType,
  legendMargin,
  chartMargin,
  isStaggeredLoading,
}) {

  const seriesKeys = Object.keys(chartData.seriesKeys)
  const strokeWidthInit = (seriesKeys) => (
    seriesKeys.reduce((acc, key) => (acc[key] = 1, acc), {})
  )
  const legendColorInit = (seriesKeys) => (
    seriesKeys.reduce((acc, key, i) => (acc[key] = CHART_COLORS[i], acc), {})
  )
  const [strokeWidth, setStrokeWidth] = useState(strokeWidthInit(seriesKeys))
  const [legendColor, setLegendColor] = useState(legendColorInit(seriesKeys))
  const [showLegend, setShowLegend] = useState(false)
  const [chartRange, setChartRange] = useState([0, chartData.initMaxRange])
  const [activeData, setActiveData] = useState(chartData.seriesData)
  const activeChart = useRef(null)
  const previousKeysLength = usePrevious(seriesKeys.length)

  const seriesCount = chartData.seriesData && chartData.seriesData.length > 0
    ? chartData.seriesData.length
    : 0

  useEffect(() => {
    if (previousKeysLength !== seriesKeys.length) {
      setActiveData(null)
      setTimeout(() => {setActiveData(chartData.seriesData)}, 100)
      setStrokeWidth(strokeWidthInit(seriesKeys))
      setLegendColor(legendColorInit(seriesKeys))
    }
  })

  function RenderPlot({
    cx,
    cy,
    stroke,
    fill,
    dataKey,
    payload,
    isActive = false,
  }) {
    const strokeColor = isActive ? fill : stroke
    const opacity = cy
      ? strokeWidth[dataKey]
      : 0
    const radius = isActive && opacity > 0
      ? 4
      : opacity > 0
        ? 3
        : 0
    let plotPk = null
    let plotDateString = null
    if (onClickType === ON_CLICK_TYPES.PLOT && opacity > 0) {
      plotPk = chartData.seriesKeys[dataKey].pk
      plotDateString = payload.date
    }
    const clickablePlot = plotPk && plotDateString
      ? <circle
          className={classNames('plot-circle', {'has-hover': onClickType === ON_CLICK_TYPES.PLOT})}
          cx={cx}
          cy={cy}
          r={radius > 0 ? 15 : 0}
          opacity={0}
          onClick={() => onChartClickFunc({plotPk, plotDateString, dataKey})}
        />
      : null

    return (
      <React.Fragment>
        <circle
          className={classNames('plot-circle', {'has-hover': onClickType === ON_CLICK_TYPES.PLOT})}
          cx={cx}
          cy={cy}
          r={radius}
          fill={fill}
          stroke={strokeColor}
          opacity={opacity}
        />
        {clickablePlot}
      </React.Fragment>
    )
  }

  const chartLines = (seriesKeys) => (
    seriesKeys.map((key, i) =>
      <Line
        isAnimationActive={isStaggeredLoading === false}
        key={key+i}
        type="monotone"
        dataKey={key}
        stroke={CHART_COLORS[i]}
        strokeWidth={strokeWidth[key]}
        dot={<RenderPlot/>}
        activeDot={<RenderPlot isActive={true}/>}
      />
    )
  )

  const handleLegendMouseEvent = (dataKey, widthValue) => {
    const width = {...strokeWidth}
    if (width[dataKey] !== 0) {
      width[dataKey] = widthValue
      setStrokeWidth(width)
    }
  }

  const handleLegendOnClick = (dataKey, idx) => {
    // disallow setting all legends to inactive
    if (strokeWidth[dataKey] !== 0 && Object.keys(activeData[0]).length === LINE_CHART_KEY_LENGTH) {
      return
    }

    const width = {...strokeWidth}
    const color = {...legendColor}
    width[dataKey] = width[dataKey] === 0
      ? 1
      : 0
    color[dataKey] = width[dataKey] === 0
      ? EXCLUDED_COLOR
      : CHART_COLORS[idx]
    setStrokeWidth(width)
    setLegendColor(color)

    const maxRanges = Object.entries(chartData.seriesKeys)
      .filter(([k, v]) => width[k] > 0).map(([k, v]) =>
        v.maxRange
    )
    const newMaxRange = maxRanges && maxRanges.length > 0
      ? Math.max.apply(Math, maxRanges)
      : 0
    setChartRange([0, newMaxRange])

    const excludedKeys = seriesKeys.filter(k => width[k] === 0)
    const newActiveData = Object.values(chartData.seriesData).map(
      obj => Object.keys(obj).reduce((acc, key) => {
        if (!excludedKeys.includes(key)) {
          acc[key] = obj[key]
        }
        return acc
      }, {})
    )
    setActiveData(newActiveData)
  }

  const renderLegend = (props) => {
    const { payload } = props

    // Timeout needed to render legend without any shifting, otherwise it renders the
    // legend up in the chart area and shifts down moments after the chart fully renders.
    setTimeout(() => {setShowLegend(true)}, 100)

    const legendContent = isStaggeredLoading
      ? <div className={styles.spinnerWrapper}>
          <LoadingSpinner className={styles.spinner}/>
        </div>
      : <ul
          className={classNames(styles.legendWrapper, {[styles.show]: showLegend})}
          style={legendMargin}
        >
          {
            payload.map((entry, i) => (
              <li
                className={styles.legendItem} key={`item-${i}`}
                onClick={() => handleLegendOnClick(entry.dataKey, i)}
                onMouseEnter={() => handleLegendMouseEvent(entry.dataKey, 2)}
                onMouseLeave={() => handleLegendMouseEvent(entry.dataKey, 1)}
              >
                <span
                  style={{backgroundColor: legendColor[entry.dataKey]}}
                  className={classNames(styles.dot,
                    {[styles.exclude]: legendColor[entry.dataKey] === EXCLUDED_COLOR})}
                />
                <span
                  className={classNames(styles.label,
                    {[styles.exclude]: legendColor[entry.dataKey] === EXCLUDED_COLOR})}
                >
                  {titleCase(chartData.seriesKeys[entry.value].name)}
                </span>
              </li>
            ))
          }
          {activeChart && activeChart.current &&
            <ExportChart currentChart={activeChart.current} chartType={CHART_TYPES.LINE} />
          }
        </ul>

    return (legendContent)
  }

  const renderTooltip = (props) => {
    const {active, label, payload} = props
    if (!active || !payload || !label) {
      return null
    } else {
      const header = payload.reduce((acc, key) => (acc, key.payload.toolTipHeader), label)
      return (
        <div className={styles.toolTipWrapper}>
          <span className={styles.toolTipHeader}>{header}</span>
          {
            payload.map((item, idx) => (
              <span key={item.dataKey+idx} className={styles.toolTip}>
                <span
                  className={styles.toolTipDot}
                  style={{backgroundColor: legendColor[item.dataKey]}}
                />
                <span className={styles.toolTipText}>
                  {`${chartData.seriesKeys[item.name].name} : ${item.value}`}
                </span>
              </span>
            ))
          }
        </div>
      )
    }
  }

  const lineChart = !is.empty(chartData)
    ? <div className={classNames(styles.mzChart, className)}>
        <ResponsiveContainer width="100%" height='100%'>
          <LineChart
            key={seriesKeys.length}
            ref={(chart) => activeChart.current = chart}
            data={activeData}
            margin={chartMargin}
            cursor={onClickType === ON_CLICK_TYPES.CHART ? 'pointer' : 'default'}
            onClick={info => {
              if (info && onClickType === ON_CLICK_TYPES.CHART) {
                onChartClickFunc(info.activePayload.reduce(
                  (acc, key) => (acc, key.payload.date), null)
                )
              }
            }}
          >
            <XAxis
              dataKey="xAxis"
              axisLine={true}
              interval={'preserveEnd'}
              tick={
                <CustomXAxisTick
                  dataCount={seriesCount}
                  isStaggeredLoading={isStaggeredLoading}
                />
              }

            />
            <YAxis
              axisLine={true}
              tickLine={false}
              domain={chartRange}
            />
            <Legend
              content={renderLegend}
              verticalAlign="bottom"
              wrapperStyle={{bottom: 0}}
            />
            <CartesianGrid strokeDasharray="1 5" vertical={false} />
            <Tooltip content={renderTooltip}/>
            { chartLines(seriesKeys) }
          </LineChart>
        </ResponsiveContainer>
      </div>
    : null

  return (
    <React.Fragment>
      { lineChart }
    </React.Fragment>
  )
}
MzLineChart.propTypes = {
  chartData: PropTypes.object.isRequired,
  onChartClickFunc: PropTypes.func,
  className: PropTypes.string,
  onClickType: PropTypes.string,
  legendMargin: PropTypes.object,
  chartMargin: PropTypes.object,
  isStaggeredLoading: PropTypes.bool,

}
MzLineChart.defaultProps = {
  className: 'mz-line-chart',
  onClickType: ON_CLICK_TYPES.CHART,
  onChartClickFunc: () => null,
  legendMargin: {margin: '10px 10px 0 60px'},
  chartMargin: {top: 40, right: 40, bottom: 20, left: 20},
  isStaggeredLoading: false
}
