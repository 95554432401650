import React, {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'
import {createSelector} from 'reselect'

import Orm from 'app/framework/Orm'
import {getEntities} from 'app/entities/entities-selectors'
import {User} from 'app/models'
import LoadingOverlay from 'app/common/LoadingOverlay'
import InputBlock from 'app/common/InputBlock'
import TextBox from 'app/common/TextBox'
import Button from 'app/common/Button'

import { getProfileGroupMembers } from './profile-group-members-selectors'
import {
  fetchMembers,
  fetchUsers,
  createMembership,
  deleteMembership,
  setNewEmailAddress,
} from './profile-group-members-actions'

import * as styles from './ProfileGroupMembers.less'


@connect(
  createSelector(
    [getEntities, getProfileGroupMembers],
    (entities, groupMembers) => {
      const orm = Orm.withEntities(entities)
      return {
        isLoading: groupMembers.isLoading,
        isFetchingMembers: groupMembers.isFetchingMembers,
        isFetchingUsers: groupMembers.isFetchingUsers,
        isLargeFirm: groupMembers.isLargeFirm,
        activeUserCount: groupMembers.activeUserCount,
        areMembersFetched: groupMembers.areMembersFetched,
        areAllUsersFetched: groupMembers.areAllUsersFetched,
        users: orm.getByIds(User, groupMembers.userIds),
        memberIds: groupMembers.memberIds,
        selectedUsers: orm.getByIds(User, groupMembers.memberIds),
        newEmailAddress: groupMembers.newEmailAddress,
      }
    }
  ),
  {
    fetchMembers,
    fetchUsers,
    createMembership,
    deleteMembership,
    setNewEmailAddress,
  },
)
export default class ProfileGroupMembers extends Component {
  state = {
    nameFilter: '',
  }

  componentDidMount() {
    if(!this.props.areMembersFetched) {
      this.props.fetchMembers()
    }
    if (!this.props.isLargeFirm && !this.props.areAllUsersFetched) {
      this.props.fetchUsers()
    }
  }

  render() {
    const isLoading = this.props.isLoading || this.props.isFetchingMembers || this.props.isFetchingUsers
    const loader = isLoading && <LoadingOverlay/>

    const quickAdd =
      <div className={styles.quickAdd}>
        <TextBox
          placeholder="Email Address"
          value={this.props.newEmailAddress}
          onChange={event => this.props.setNewEmailAddress(event.target.value)}
        />

        <Button
          label="Quick Add"
          onClick={() => this.props.createMembership({emailAddress: this.props.newEmailAddress})}
          disabled={!this.props.newEmailAddress}
        />
      </div>

    const filterMessage =
      <span>
        Please filter for results or <a onClick={() => this.props.fetchUsers()}>click here to load all users ({this.props.activeUserCount})</a>.
      </span>

    const currentMemberMessage = this.props.isLargeFirm && !this.props.areAllUsersFetched &&
      <h4 className={classNames(styles.headerMessage)}>
        {(!this.state.nameFilter && this.props.memberIds.length > 0) && <span>Current group members are listed below. </span>}

        {filterMessage}
      </h4>

    const selectedUsersMessage = this.props.memberIds.length > 0 &&
      <div className={classNames(styles.headerMessage)}>
        <span>{this.props.memberIds.length} Selected: (</span>
        <span>{this.props.selectedUsers.slice(0, 5).map(u => u.displayName).join(', ')}</span>
        {this.props.memberIds.length > 5 && <span>, ..</span>}
        <span>)</span>
      </div>

    const nameFilter =
      <div className={styles.quickAdd}>
        <TextBox
          placeholder="Search"
          value={this.state.nameFilter}
          onChange={event => this.handleNameFilterChange(event.target.value)}
        />
      </div>

    const userList =
      <div className={styles.userListContainer}>
        <div className={styles.userList}>
          {this.props.users.filter(u => {
            const nameFilter = this.state.nameFilter.toLowerCase()
            if (!this.props.areAllUsersFetched && !nameFilter) {
              return this.props.memberIds.includes(u.id)
            }
            return (
              !nameFilter ||
              u.firstName.toLowerCase().includes(nameFilter) ||
              u.lastName.toLowerCase().includes(nameFilter) ||
              u.username.toLowerCase().includes(nameFilter)
            )
          }).map(u => {
            return (
              <div
                key={u.id}
                className={styles.userEntry}
              >
                <InputBlock
                  label={u.displayNameLastFirst}
                  isInline
                >
                  <input
                    type="checkbox"
                    checked={this.props.memberIds.includes(u.id)}
                    onChange={event => this.handleUserSelectionChange(u.id, event.target.checked)}
                  />
                </InputBlock>
              </div>
            )
          })}
        </div>
      </div>

    return (
      <div className={styles.container}>
        {loader}

        {quickAdd}

        {currentMemberMessage}

        {selectedUsersMessage}

        {nameFilter}

        {userList}
      </div>
    )
  }

  handleUserSelectionChange(userId, isSelected) {
    if (isSelected) {
      this.props.createMembership({userId})
    } else {
      this.props.deleteMembership(userId)
    }
  }

  handleNameFilterChange(nameFilter) {
    this.setState({nameFilter})
    this.props.fetchUsers(nameFilter)
  }
}
