import React  from 'react'
import { DATE_FORMAT } from 'app/constants'
import * as dateFns from 'date-fns'
import Modal from 'app/common/Modal'
import Table from 'app/common/Table'
import PaginatedTable from 'app/common/PaginatedTable'
import { PUBLICATIONS_ITEMS_PER_PAGE } from './users-admin-constants'

export default function PublicationsModal({
  isOpen,
  hide,
  publicationModalType,
  publicationData,
  onPageChange,
  publicationsPageNo
}) {
  
  const { type, user } = publicationModalType
  const count = type == 'Recurring' ? user.recurringPubCount : user.templatePubCount
  const pageCount = Math.ceil(count / PUBLICATIONS_ITEMS_PER_PAGE)
  return (
    <Modal
      isOpen={isOpen}
      onClose={hide}
      id="publications-modal"
    >
      <h2>{type} Publications</h2>

      <PaginatedTable
        id="publications-users-table"
        defaultSort={{ column: 'publicationName', direction: 'asc' }}
        data={publicationData}
        pageCount={pageCount}
        ignoreSort
        currentPage={publicationsPageNo}
        onPageChange={onPageChange}
        className="users-table"
      >
        <Table.Column
          name="publicationName"
          label="Publication Name"
          baseWidth={200}
          growRatio={0}
          cellContents={publicationData => publicationData.name}
        />
        <Table.Column
          name="lastPublishedDate"
          label="Last Published Date"
          baseWidth={200}
          growRatio={0}
          cellContents={publicationData => {
            if (publicationData.lastPublishedDate) {
              return dateFns.format(new Date(publicationData.lastPublishedDate), DATE_FORMAT)
            } else {
              return "Not yet sent"
            }
          }}
        />
      </PaginatedTable>

    </Modal>
  )

}
