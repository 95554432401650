import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { anchor, ToggleLayer } from 'react-laag'
import DebouncedSearchInput from '../DebouncedSearchInput'
import { useResizeObserver } from 'app/utils/hooks'
import classNames from 'classnames'
import invariant from 'invariant'
import './styles.less'
import Tooltip from '../Tooltip'
import HighlightedText from '../HighlightedText'

const PopoverDropdown = ({
    children,
    label = '',
    multiSelection = false,
    enableFilter = false,
    options,
    selectedOptions = [],
    onSelection,
    direction = 'top-left',
    containerClassName,
    filterVisibilityChange,
    showSubtitle = false,
    enableTooltip = false,
    sortOptions = true,
    placeholder = 'Search'
}) => {

    // invariant(
    //     (options && options.length),
    //     "A Dropdown must have at least one option.",
    // )

    const NO_MESSAGE_TEXT = 'There are no results for that Search'
    const placeholderString = "&#xf002; Search"
    const maxLength = 30
    // const placeholderString = String("&#xf002; " + placeholder)

    const [isOpen, setIsOpen] = useState(false)
    const [filter, setFilter] = useState(null)
    const [items, setItems] = useState([])
    const [selectedItems, setSelectedItems] = useState(selectedOptions)

    const dropdownRef = useRef()

    const ResizeObserver = useResizeObserver()

    const handleFreeSearchTextChange = (value) => {
        setFilter(value)
        let _options = getOptions()
        if (value && value.trim()) {
            _options = getOptions().filter(item =>
                item.label.toLowerCase().includes(value.trim().toLowerCase()) ||
                (showSubtitle && item.subTitle.toLowerCase().includes(value.trim().toLowerCase()))
            )
        }
        setItems(_options)
    }
    const handleOnSelect = (item) => {
        let _selectedItems = []
        if (multiSelection) {
            _selectedItems = [...selectedItems]
            if (isItemSelected(item)) {
                _selectedItems = _selectedItems.filter(option => option !== item.value)
            }
            else {
                _selectedItems.push(item.value)
            }
            setSelectedItems(_selectedItems)
            onSelection?.(_selectedItems)
        }
        else {
            _selectedItems = [item.value]
            setSelectedItems(_selectedItems)
            onSelection?.(item.value)
            handleClose()
        }
    }
    const handleOpen = () => {
        if (isOpen) {
            return
        }
        setIsOpen(true)
        document.addEventListener('click', handleOutsideClick)
        filterVisibilityChange?.(true)
    }
    const handleClose = () => {
        setIsOpen(false)
        document.removeEventListener('click', handleOutsideClick)
        filterVisibilityChange?.(false)
    }
    const handleOutsideClick = (event) => {
        // Hide the renderMenu if the user clicks outside of it
        const path = event.path || (event.composedPath && event.composedPath());

        if (!path.includes(dropdownRef.current)) {
            handleClose()
        }
    }
    const isItemSelected = (item) => {
        return selectedItems.includes(item.value)
    }
    const getFormattedText = (text) => {
        if (text.length > maxLength - 1) {
            const croppedText = `${text.substring(0, maxLength)}...`
            return croppedText
        }
        else {
            return text
        }
    }
    const getOptions = () => {
        return sortOptions ? [...options].sort((a, b) => a.label > b.label ? 1 : -1) : options
    }


    useEffect(() => {
        if (selectedOptions && selectedOptions.length > 0) {
            setSelectedItems(selectedOptions)
        }
    }, [selectedOptions])

    useEffect(() => {
        setItems(getOptions())
    }, [options])

    const dropdownAnchor = {
        top: anchor.BOTTOM_CENTER,
        'top-left': anchor.BOTTOM_LEFT,
        'top-right': anchor.BOTTOM_RIGHT,

        bottom: anchor.TOP_CENTER,
        right: anchor.LEFT_CENTER,
        left: anchor.RIGHT_CENTER,
    }[direction]

    return (
        <ToggleLayer
            placement={{ anchor: dropdownAnchor, autoAdjust: true, triggerOffset: 5 }}
            fixed
            isOpen={isOpen}
            renderLayer={({ layerProps, isOpen, arrowStyle, layerSide }) => {
                if (!isOpen) {
                    return null
                }
                else {
                    return (
                        <div ref={dropdownRef}>
                            <div
                                ref={layerProps.ref}
                                style={{ ...layerProps.style }}
                                className='popover-dropdown-component'>
                                {enableFilter ? <DebouncedSearchInput
                                    value={filter ?? ''}
                                    inputClassName="filter-input"
                                    containerClassName="filter-input-container"
                                    onChange={handleFreeSearchTextChange}
                                    // placeholder="&#xf002; Search"
                                    placeholder={placeholder}
                                /> : null}
                                {label ? <div className='label'>{label}</div> : null}
                                <div className='dropdown'>
                                    {items && items.length > 0 ?
                                        <>
                                            {items.map(item => {
                                                const isChecked = isItemSelected(item)
                                                return (<div
                                                    key={`item-${item.value}-${Math.random().toString()}`}
                                                    className={classNames('option', { active: isChecked })}
                                                    onClick={() => handleOnSelect(item)}>
                                                    {multiSelection ? <input
                                                        type={'checkbox'}
                                                        checked={isChecked}
                                                        className='filter-checkbox'
                                                        onChange={() => handleOnSelect(item)} /> : null}
                                                    <Tooltip
                                                        render={() => <div className='tooltip'>{item.tooltip ?? item.label}</div>}
                                                        direction='bottom'
                                                        disabled={!enableTooltip}
                                                        noBorder
                                                        containerClassName='filter-cell'>
                                                        <div className="option-title">
                                                            <HighlightedText className='highlight' text={item.label} highlight={filter} />
                                                        </div>
                                                        {showSubtitle ? <div className='option-sub-title'>
                                                            {<HighlightedText className='highlight' text={getFormattedText(item.subTitle)} highlight={filter} />}
                                                        </div> : null}
                                                    </Tooltip>
                                                </div>)
                                            })}
                                        </>
                                        : <div className='no-result-message'>{NO_MESSAGE_TEXT}</div>}
                                </div>
                            </div>
                        </div>
                    )
                }
            }
            }
            ResizeObserver={ResizeObserver}
        >
            {({ triggerRef }) => (
                <span ref={triggerRef} className={containerClassName} onClick={handleOpen}>{children}</span>
            )}
        </ToggleLayer>

    );
}

PopoverDropdown.propTypes = {
    children: PropTypes.any,
    label: PropTypes.string,
    direction: PropTypes.string,
    containerClassName: PropTypes.string,
    placeholder: PropTypes.string,
    multiSelection: PropTypes.bool,
    enableFilter: PropTypes.bool,
    enableTooltip: PropTypes.bool,
    showSubtitle: PropTypes.bool,
    sortOptions: PropTypes.bool,
    options: PropTypes.array.isRequired, // array of options which is an object {label:string, value:string, subTitle:string, tooltip:string}
    selectedOptions: PropTypes.array,
    onSelection: PropTypes.func,
    filterVisibilityChange: PropTypes.func
}


export default PopoverDropdown
