import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { MANAGE_LABELS_SECTION } from './searches-admin-constants'
import Button from 'app/common/Button'

export default class ManageLabelsSection extends Component {
    static propTypes = {
        onManageLabels: PropTypes.func.isRequired,
    }

    render() {
        return (
            <div id="manage-labels-section">
                <div className="header">
                    <div className="top">
                        <h1>
                            <span
                                className="header-with-tooltip"
                                data-mz-tooltip-text="These are global and private sources that you are managing for users in your firm."
                            >
                                {MANAGE_LABELS_SECTION.TITLE}
                            </span>
                        </h1>
                        <div className="top-right">
                            <div>
                                <Button
                                    label="Manage Labels"
                                    onClick={this.props.onManageLabels}
                                    isPrimary
                                />
                            </div>
                        </div>
                    </div>
                    <p>{MANAGE_LABELS_SECTION.DESCRIPTION}</p>
                </div>
            </div>
        )
    }
}