import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import Button from 'app/common/Button'
import Message from 'app/common/Message'

import {getSourcesAdmin} from '../sources-admin-selectors'
import {
  setShouldShowSelfAddModal,
  resetCreateProprietaryFeedData,
  updateCreateProprietaryFeedData,
  createProprietaryFeed,
} from '../sources-admin-actions'


@connect(
  createSelector(
    [getSourcesAdmin],
    (sourcesAdmin) => {
      return {
        isLoading: sourcesAdmin.isLoading,
        languages: sourcesAdmin.languages,
        feed: sourcesAdmin.createProprietaryFeedData.selectedFeed,
      }
    }
  ), {
    setShouldShowSelfAddModal,
    resetCreateProprietaryFeedData,
    updateCreateProprietaryFeedData,
    createProprietaryFeed,
  }
)
export default class SelfAddForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: props.feed.name,
      isSubscriptionRequired: false,
      isLimitedSeat: false,
      notes: '',
      isAffirmed: false,
      errorMessage: null,
      languageId: 1,
    }
  }

  render() {
    const {isLoading, feed, languages} = this.props
    const {
      name,
      isSubscriptionRequired,
      isLimitedSeat,
      notes,
      isAffirmed,
      errorMessage,
      languageId,
    } = this.state

    const allowSubmitForm =
      (isAffirmed || !isSubscriptionRequired) && name && !isLoading

    const error = errorMessage && <div className="message error">{errorMessage}</div>

    const loadingMessage = isLoading &&
      <Message
        type={Message.TYPES.LOADING}
        text="We are creating a new source based on this RSS feed. This might take a few seconds..."
      />

    const limitedSeat = isSubscriptionRequired &&
      <label className="inline quarter-width">
        <input
          type="checkbox"
          checked={isLimitedSeat}
          onChange={(event) => this.handleLimitedSeatChange(event.target.checked)}
        />
        <span className="label inline">Limited Seat Source</span>
      </label>

    const affirm = isSubscriptionRequired &&
      <label className="full-width affirm">
        <input
          type="checkbox"
          checked={isAffirmed}
          onChange={(event) => this.handleAffirmChange(event.target.checked)}
        />
        <p>I affirm, represent, and/or warrant that I own or have the necessary licenses, rights, consents, and permissions related to the above content for its use by myself, Manzama, and any other third party.</p>
      </label>

    return (
      <div className="self-add-form">
        {error}

        <p>Add sources that your organization has subscribed to or that you would like added to the system.</p>

        <label className="margin-r-m">
          <span className="label">RSS Feed URL</span>
          <div>{feed.url}</div>
        </label>

        <label className="margin-r-m half-width">
          <span className="label">Source Name</span>
          <input
            type="text"
            value={name}
            onChange={(event) => this.handleNameChange(event.target.value)}
          />
        </label>

        <div className="language-header">
          <span className="label">Specify the language for this feed</span>
        </div>
        <div className="languages-top-div">
          <table className="languages-datatable">
            <tbody>
              {
                languages.map(language => {
                  return (
                    <tr key={language.id}>
                      <td className="language-list-item">
                        <input
                          className="language-input"
                          type="checkbox"
                          onChange={event => this.handleLanguageChange(language.id, event.target.checked)}
                          checked={language.id === languageId}
                        />
                        <span className="language-name">{language.name}</span>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>< br/>

        <label className="inline quarter-width">
          <input
            type="checkbox"
            checked={isSubscriptionRequired}
            onChange={(event) => this.handleIsSubscriptionRequiredChange(event.target.checked)}
          />
          <span className="label inline">Subscribed Content</span>
        </label>

        {limitedSeat}

        <label className="full-width">
          <span className="label">Additional Information</span>
          <textarea
            value={notes}
            onChange={(event) => this.handleNotesChange(event.target.value)}
          />
        </label>

        {affirm}

        {loadingMessage}

        <div className="buttons">
          <Button
            label="Add Source"
            className="submit"
            disabled={!allowSubmitForm}
            onClick={() => this.createFeed()}
          />

          <a
            className="button cancel"
            onClick={() => this.props.setShouldShowSelfAddModal(false)}
          >
            Cancel
          </a>
        </div>
      </div>
    )
  }

  handleNameChange(name) {
    this.setState({name})
  }

  handleIsSubscriptionRequiredChange(isSubscriptionRequired) {
    this.setState({isSubscriptionRequired})
  }

  handleLimitedSeatChange(isLimitedSeat) {
    this.setState({isLimitedSeat})
  }

  handleNotesChange(notes) {
    this.setState({notes})
  }

  handleAffirmChange(isAffirmed) {
    this.setState({isAffirmed})
  }

  handleLanguageChange(languageId, isChecked) {
    if (!isChecked) {
      languageId = 1
    }
    this.setState({languageId})
  }

  createFeed = () => {
    const {
      name,
      isSubscriptionRequired,
      isLimitedSeat,
      notes,
      languageId
    } = this.state

    this.props.createProprietaryFeed({
      name,
      url: this.props.feed.url,
      languageId,
      notes,
      isSubscriptionRequired,
      isLimitedSeat: (isSubscriptionRequired && isLimitedSeat),
    })
  }
}
