import { apiCall } from '../api'
import { withQueryParams } from 'app/urls'
import {graphqlQuery} from 'app/api'
import {args, gql} from 'app/graphql'

export async function getCompanyData(id) {
  return await apiCall({
    url: '/esg/api/company',
    method: 'GET',
    data: { savedsearch_id: id },
  })
}

export async function getEsgIndustryHealthScores(industryId) {
  return await apiCall({
    url: `/esg/api/sector/${industryId}/`,
    method: 'GET',
  })
}

export async function postFeedback(feedback) {
  return await apiCall({
    url: '/esg/api/post_esg_feedback',
    method: 'POST',
    data: {
      feedback
    },
  })
}

export async function getSectors() {
  return await apiCall({
    url: '/esg/api/sectors',
    method: 'GET',
  })
}

export async function getEsgSectorStories(sectorId, pillarId = null, topicId = null, termId = null, order = null, offset = 0) {
  let data = {
    offset: offset,
  }
  if (pillarId) {
    data.topicId = pillarId
  }
  if (topicId) {
    data.topicId = topicId
  }
  if (termId) {
    data.termId = termId
  }
  if (order) {
    data.order = `${order}.desc`
  }
  return await apiCall({
    url: `/esg/api/sector/${sectorId}/stories`,
    method: 'GET',
    data: data,
  })
}

export async function getEsgCompanyStories(companyId, pillarId = null, topicId = null, termId = null, order = null, offset = 0) {
  let data = {
    offset: offset,
  }
  if (pillarId) {
    data.topicId = pillarId
  }
  if (topicId) {
    data.topicId = topicId
  }
  if (termId) {
    data.termId = termId
  }
  if (order) {
    data.order = `${order}.desc`
  }
  return await apiCall({
    url: `/esg/api/company/${companyId}/stories`,
    method: 'GET',
    data: data,
  })
}

export async function getEsgSidebarContent() {
  return await apiCall({
    url: '/esg/api/sidebar_content/',
    method: 'GET',
  })
}

export async function getEsgSectorTerms(sector_id, pillar_id, topic_id) {
  let params = {}
  if (pillar_id) params['pillar_id'] = pillar_id
  if (topic_id) params['topic_id'] = topic_id
  return await apiCall({
    url: withQueryParams(`/esg/api/sector/${sector_id}/terms?`, params),
    method: 'GET',
  })
}

export async function getEsgCompanyTerms(company_id, pillar_id, topic_id) {
  let params = {}
  if (pillar_id) params['pillar_id'] = pillar_id
  if (topic_id) params['topic_id'] = topic_id
  return await apiCall({
    url: withQueryParams(`/esg/api/company/${company_id}/terms?`, params),
    method: 'GET',
  })
}

export async function getDefaultIndustry() {
  return await apiCall({
    url: `/esg/api/default_sector/`,
    method: 'GET',
  })
}

export async function setDefaultIndustry(industryId) {
  return await apiCall({
    url: `/esg/api/default_sector/`,
    method: 'POST',
    data: {
      default_industry: industryId,
    },
    options: {
      dataFormat: 'json',
    }
  })
}

export async function getAllEsgCompaniesDataOnSearch(searchValue) {
  return await apiCall({
    url: '/esg/api/search_company_name',
    method: 'GET',
    data: { company_name: searchValue },
  })
}

export function deleteSearches(ids) {
  const query = ids.map(id => {
    return (
      `mutation {
        deleteSearch(id: ${id}) {
          success
        }
      }`
    )
  }).join('')
  return graphqlQuery(
    query
  ).then(({ body: { data } }) => data)
}

export async function getAllCompaniesData(offset, company_name) {
  let data = {
    offset: offset,
    company_name: company_name
  }
  return await apiCall({
    url: '/esg/api/all_esg_companies',
    method: 'GET',
    data: data
  })
}


export function createSearches({ searchIds, userId }) {
  const query1 = searchIds.map((id, index) => {
    return (
      gql`
        mutation {
          search${index}: saveSearch(${args({
        userId,
        id,
        isMakeChild: true,
        category: "client",
      })}) {
            id
            name
            category
            query
            isInsightsEnabled
          }
        }
      `
    )
  }).join('')
  return graphqlQuery(query1).then(({ body: { data } }) => data)
}
