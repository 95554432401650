import React, { Component } from 'react'
import { connect } from 'react-redux'
import './DeliveryDay.less'
import * as actions from './email-notifications-actions'
import * as constants from './email-notifications-constants'
import InputBlock from 'app/common/InputBlock'
import Dropdown from 'app/common/Dropdown'
import PropTypes from 'prop-types'


@connect(
  state => ({
    baseSettings: state.emailNotifications.baseSettings,
    insightsSettings: state.emailNotifications.insightsSettings,
    esgSettings: state.emailNotifications.esgSettings,
    editUserId: state.emailNotifications.emailDeliveryData.editUserId,
  }),
  {
    saveEmailAlertSettings: actions.api.saveEmailAlertSettings.request,
  },
)
export default class DeliveryDay extends Component {
  static propTypes = {
    noticeFrequency: PropTypes.string.isRequired,
    notificationType: PropTypes.string.isRequired,
  }
  render() {
    const activeSettings = this.props.notificationType === constants.BASE_NOTIFICATION_TYPE
      ? this.props.baseSettings
      : this.props.notificationType === constants.INSIGHTS_NOTIFICATION_TYPE
        ? this.props.insightsSettings
        : this.props.notificationType === constants.ESG_NOTIFICATION_TYPE
          ? this.props.esgSettings
          : null
    let deliveryDay = null
    if (activeSettings) {
      const frequencySettings = activeSettings.settings.find(
        setting => setting.noticeFrequency === this.props.noticeFrequency)
      const deliveryDayOptions = []
      Object.keys(constants.DELIVERY_DAYS).forEach(key => {
        deliveryDayOptions.push({
          key: key,
          notificationType: this.props.notificationType.toLowerCase(),
          fieldName: constants.EMAIL_DELIVERY_WEEKDAY,
          label: <span>{constants.DELIVERY_DAYS[key]}</span>,
          value: constants.DELIVERY_DAYS[key],
        })
      })
      const activeDeliveryDay = frequencySettings
        ? constants.DELIVERY_DAYS[frequencySettings.emailDeliveryWeekday]
        : null
      deliveryDay = this.props.notificationType === constants.BASE_NOTIFICATION_TYPE
        || this.props.noticeFrequency === constants.WEEKLY_FREQ
        ? <InputBlock
          className={'delivery-day-dropdown'}
          label="Delivery Day (for weekly frequency)"
        >
          <Dropdown
            type={'text'}
            options={deliveryDayOptions}
            value={activeDeliveryDay}
            onSelect={this.onDeliveryDaySelect.bind(this, activeSettings)}
          />
        </InputBlock>
        : null
    }
    return (
      <React.Fragment>
        {deliveryDay}
      </React.Fragment>
    )
  }
  onDeliveryDaySelect(activeSettings, data) {
    this.props.saveEmailAlertSettings({
      editUserId: this.props.editUserId,
      noticeFrequency: this.props.noticeFrequency,
      type: activeSettings.notificationType,
      field: data.fieldName,
      value: data.key,
      deliveryDaySettings:this.props.groupNotification? true:null,
    })
  }
}
