import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import Header from './Header'
import Companies from "./Companies"
import { getDunAndBradstreet } from './dandb-search-selectors'

import {
  createDnbAction,
  createDnbRequestActions,
} from './dandb-search-actions'

import * as actions from "./dandb-search-actions"
import LoadingOverlay from 'app/common/LoadingOverlay'
@connect(
    state  => ({ isLoading: getDunAndBradstreet(state).isLoading}),
    {
      setLoading: actions.setIsLoading,
      fetchCompanies: actions.searchDnbData.request,
    }
  )
export default class DandBSearch extends Component {
  state = {
    message: null,

  }

  render() {

    const loading = this.props.isLoading
    return (
      <div>
        <div>
          <Header onsubmit={value => this.onsubmit(value)}/>
        </div>
        {loading
          ? <LoadingOverlay/>
          : null
        }
        <div className="companies">
          <Companies/>
        </div>
      </div>
    )
  }
  onsubmit(headerVals){
    this.props.setLoading()
    console.log(headerVals)
    this.props.fetchCompanies(headerVals)
  }
}
