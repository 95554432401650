import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { startOfDay, subMonths, subWeeks, subYears } from 'date-fns'
import { createSelector } from 'reselect'
import * as dateFns from 'date-fns'

import { DATE_FORMAT } from 'app/constants'
import { getEntities } from 'app/entities/entities-selectors'
import { getCurrentUserReceiveMonthlyUsageReport, getCurrentUserReceiveMonthlyNewUsageReport } from 'app/global/global-selectors'
import Orm from 'app/framework/Orm'
import { User, SavedSearch } from 'app/models'
import DateRangePicker from 'app/common/DateRangePicker'
import InputBlock from 'app/common/InputBlock'
import Button from 'app/common/Button'
import LoadingOverlay from 'app/common/LoadingOverlay'
import Modal from 'app/common/Modal'
import Dropdown from 'app/common/Dropdown'

import { getUsage } from './usage-selectors'
import * as actions from './usage-actions'

import './Usage.less'
import ReportingGrid from './reporting-grid'
import ReportStats from './report-stats'
import {
  EXPORT_USAGE_REPORT, USAGE_TITLE, EMAIL_USAGE_REPORT, EXPORT_NOW,
  EXPORT_SCHEDULED, DATE_RANGE_OPTIONS, FILTERS_KEYS, PUBLICATION_USAGE_REPORT, EXPORT_PUBLISH,
  SAVED_SEARCHES_USAGE_REPORT, EXPORT_SAVED_SEARCH, EMAIL_ALERTS_USAGE_REPORT, EXPORT_ALERT,
  NEW_EMAIL_USAGE_REPORT, ALL_SAVED_SEARCHES_USAGE_REPORT, ALL_EXPORT_SAVED_SEARCH
} from './usage-constants'
import UsageSummary from './usage-summary/index'
import { getChunkMessage, getValidandInvalidEmailList } from 'app/utils'
import * as notifications from 'app/global/notifications'
import TokenInput from 'app/common/TokenInput'
import { TOKEN_INPUT_DELIMITERS } from 'app/email-notifications/email-notifications-constants'

@connect(
  // Map state to props
  createSelector(
    [getEntities, getUsage, getCurrentUserReceiveMonthlyUsageReport, getCurrentUserReceiveMonthlyNewUsageReport],
    (entities, usage, currentUserReceiveMonthlyUsageReport, currentUserReceiveMonthlyNewUsageReport) => {
      const orm = Orm.withEntities(entities)
      return {
        isLoading: usage.isNewLoading,
        isEmailModalOpen: usage.isEmailModalOpen,
        defaultEmailAddress: usage.defaultEmailAddress,
        currentUserReceiveMonthlyUsageReport: usage.currentUserReceiveMonthlyUsageReport === null ?
          currentUserReceiveMonthlyUsageReport : usage.currentUserReceiveMonthlyUsageReport,
        start: usage.usageGridFilters.start,
        end: usage.usageGridFilters.end,
        reportType: usage.reportType,
        currentUserReceiveMonthlyNewUsageReport: usage.currentUserReceiveMonthlyNewUsageReport === null ?
        currentUserReceiveMonthlyNewUsageReport : usage.currentUserReceiveMonthlyNewUsageReport,
      }
    }
  ),

  // Map dispatch to props
  {
    fetchUsageGridData: actions.fetchUsageGridData,
    fetchUsageGridFilterData: actions.fetchUsageGridFiltersData,
    showEmailModal: actions.showEmailModal,
    hideEmailModal: actions.hideEmailModal,
    sendEmail: actions.sendEmail,
    signupForMonthlyReport: actions.signupForMonthlyReport,
    setUsageGridFilters: actions.setUsageGridFilters,
    setReportType: actions.setReportType,
    showNotification: notifications.actions.showNotification,
    signupForNewMonthlyReport: actions.signupForNewMonthlyReport,
  },
)
export default class Usage extends Component {
  state = {
    dateRange: {
      start: subWeeks(new Date(), 1),
      end: startOfDay(new Date()),
    },
    recipientAddresses: this.props.defaultEmailAddress,
    publishRecipientAddresses: this.props.defaultEmailAddress,
    savedSearchRecipientAddresses: this.props.defaultEmailAddress,
    emailAlertRecipientAddresses: this.props.defaultEmailAddress,
    showDateRangeSection: false,
    selectedOption: DATE_RANGE_OPTIONS[7],
    showStats: true,
  }

  dateRangeOptions = Object.keys(DATE_RANGE_OPTIONS).map(
    numberOfDays => {
      return {
        label: DATE_RANGE_OPTIONS[numberOfDays],
        value: numberOfDays
      }
    }
  )

  render() {
    const loader = this.props.isLoading
      ? <LoadingOverlay className={'loader-style'} />
      : null

    const dateRangeSection = this.state.showDateRangeSection ?
      <>
        <InputBlock label="">
          <DateRangePicker
            value={{
              start: this.props.start,
              end: this.props.end,
            }}
            monthsBack={12}
            onChange={(dateRange) => this.handleDateRangeChange(dateRange, DATE_RANGE_OPTIONS.CUSTOM_RANGE, true)}
            className="date-range-picker"
          />
        </InputBlock>

        <Button label="Apply" onClick={() => this.fetchUsageData()} />
      </> : null

    const dateRangeDropdown =
      <div className='date-range-bar'>
        <InputBlock className={'date-range'} label="Select Period">
          <Dropdown
            className='drop-down'
            type={'text'}
            options={this.dateRangeOptions}
            onChange={value => {
              this.handleDateRangeDropdownSelect(value)
            }}
          />
        </InputBlock>
        {dateRangeSection}
      </div>

    const emailModal =
      <Modal
        isOpen={this.props.isEmailModalOpen}
        onClose={() => this.props.hideEmailModal()}
        className="usage-email-modal"
      >

        <h2>
          {this.props.reportType === 'users' ? "Email Individual Usage Data" : ""}
          {this.props.reportType === 'publish' ? "Email Publication Usage Data" : ""}
          {this.props.reportType === 'saved search' ? "Email Saved Search Usage Data" : "" }
          {this.props.reportType === 'alert' ? "Email Alert Usage Data" : "" }
          {this.props.reportType === 'all saved search' ? "Email All Saved Search Usage Data" : "" }
        </h2>

        {this.props.reportType === 'users' &&
          <InputBlock label="Recipients:">
            <TokenInput
                tokenInputStyle = "tokenInputStyle"
                style={{outline: "none"}}
                tokenItems={this.formatEmailTokens()}
                delimiters={TOKEN_INPUT_DELIMITERS}
                inputWidth="100%"
                updateTokensFunc={(token) => this.handleEmailItemChange(token)}
                ignoreSplit={true}
              />
          </InputBlock>
        }

        {this.props.reportType === 'publish' &&
          <InputBlock label="Recipients:">
              <TokenInput
                tokenInputStyle = "tokenInputStyle"
                style={{outline: "none"}}
                tokenItems={this.formatPublishEmailTokens()}
                delimiters={TOKEN_INPUT_DELIMITERS}
                inputWidth="100%"
                updateTokensFunc={(token) => this.handlePublishEmailItemChange(token)}
                ignoreSplit={true}
              />
          </InputBlock>
        }

        {this.props.reportType === 'saved search' &&
          <InputBlock label="Recipients:">
            <TokenInput
              tokenInputStyle = "tokenInputStyle"
              style={{outline: "none"}}
              tokenItems={this.formatSavedSearchEmailTokens()}
              delimiters={TOKEN_INPUT_DELIMITERS}
              inputWidth="100%"
              updateTokensFunc={(token) => this.handleSavedSearchEmailItemChange(token)}
              ignoreSplit={true}
            />
          </InputBlock>
        }
        {this.props.reportType === 'alert' &&
          <InputBlock label="Recipients:">
            <TokenInput
                tokenInputStyle = "tokenInputStyle"
                style={{outline: "none"}}
                tokenItems={this.formatEmailAlertTokens()}
                delimiters={TOKEN_INPUT_DELIMITERS}
                inputWidth="100%"
                updateTokensFunc={(token) => this.handleEmailAlertItemChange(token)}
                ignoreSplit={true}
              />
          </InputBlock>
        }

        {this.props.reportType === 'all saved search' &&
          <InputBlock label="Recipients:">
            <TokenInput
              tokenInputStyle="tokenInputStyle"
              style={{ outline: "none" }}
              tokenItems={this.formatSavedSearchEmailTokens()}
              delimiters={TOKEN_INPUT_DELIMITERS}
              inputWidth="100%"
              updateTokensFunc={(token) => this.handleSavedSearchEmailItemChange(token)}
              ignoreSplit={true}
            />
          </InputBlock>
        }

        <p className="input-help">Separate multiple addresses with a comma.</p>

        <div className="modal-buttons">
          <Button label="Send" onClick={() => this.sendEmail()} />
          <Button label="Cancel" isPlainText onClick={() => this.props.hideEmailModal()} />
        </div>
      </Modal>

    const exportDataLabel =
      <span className="export-options-label">
        {/* <i className="fa icon-off fa-download fa-lg" /> */}
        {EXPORT_USAGE_REPORT}
      </span>

    const signupLabel = this.props.currentUserReceiveMonthlyNewUsageReport
      ? 'Cancel Monthly New Usage Reports'
      : 'Signup for Monthly New Usage Reports'

    return (

      <div className="firm-usage">

        {loader}

        {emailModal}

        <UsageSummary />

        <div className="usage-header">
          <h4>{USAGE_TITLE}</h4>
        </div>

        <div>

          <div className='usage-header-new'>
            {dateRangeDropdown}
            <Dropdown
              options={[
                { label: exportDataLabel, value: null },
                // { label: 'Export Usage Report', value: null },
                { label: NEW_EMAIL_USAGE_REPORT, value: EXPORT_NOW },
                { label: signupLabel, value: EXPORT_SCHEDULED },
                { label: PUBLICATION_USAGE_REPORT, value: EXPORT_PUBLISH },
                { label: SAVED_SEARCHES_USAGE_REPORT, value: EXPORT_SAVED_SEARCH },
                { label:EMAIL_ALERTS_USAGE_REPORT , value:EXPORT_ALERT},
                { label: ALL_SAVED_SEARCHES_USAGE_REPORT, value: ALL_EXPORT_SAVED_SEARCH },

              ]}
              value={null}
              onChange={value => this.handleExportOptionChange(value)}
              className="export-options drop-down"
            />
          </div>

          <ReportStats />

          <div className="metrics">

            <ReportingGrid />
          </div>

        </div>
      </div>
    )
  }

  handleDateRangeDropdownSelect(option) {
    const selectedOption = DATE_RANGE_OPTIONS[option]
    const changeValue = (start) => {
      const dateRange = { start, end: startOfDay(new Date()) }
      this.handleDateRangeChange(dateRange, selectedOption)
    }
    switch (selectedOption) {
      case DATE_RANGE_OPTIONS[7]:
        changeValue(subWeeks(new Date(), 1))
        break;
      case DATE_RANGE_OPTIONS[30]:
        changeValue(subMonths(new Date(), 1))
        break;
      case DATE_RANGE_OPTIONS[90]:
        changeValue(subMonths(new Date(), 3))
        break;
      case DATE_RANGE_OPTIONS[182]:
        changeValue(subMonths(new Date(), 6))
        break;
      case DATE_RANGE_OPTIONS.CUSTOM_RANGE:
        this.setState({ showDateRangeSection: true })
        break;

      default:
        break;
    }
  }

  handleDateRangeChange(dateRange, selectedOption = '', showDateRangeSection = false) {
    this.setState({ dateRange, selectedOption, showDateRangeSection })
    this.props.setUsageGridFilters({ ...dateRange, [FILTERS_KEYS.currentPage]: 1 })
    if (selectedOption !== DATE_RANGE_OPTIONS.CUSTOM_RANGE) {
      this.fetchUsageData()
    }
  }

  fetchUsageData() {
    this.props.fetchUsageGridData()
    this.props.fetchUsageGridFilterData()
  }

  handleExportOptionChange(value) {
    if (value === EXPORT_NOW) {
      this.props.setReportType('users')
      this.props.showEmailModal()
    } else if (value === EXPORT_SCHEDULED) {
      this.props.signupForNewMonthlyReport(!this.props.currentUserReceiveMonthlyNewUsageReport)
    }
    else if (value === EXPORT_PUBLISH) {
      this.props.setReportType('publish')
      this.props.showEmailModal()
    }
    else if (value === EXPORT_SAVED_SEARCH) {
      this.props.setReportType('saved search')
      this.props.showEmailModal()
    }

    else if (value === EXPORT_ALERT) {
      this.props.setReportType('alert')
      this.props.showEmailModal()
    }
    else if (value === ALL_EXPORT_SAVED_SEARCH) {
      this.props.setReportType('all saved search')
      this.props.showEmailModal()
    }
  }

  sendEmail() {
    if (this.props.reportType == 'users'){
      this.props.sendEmail({ ...this.state.dateRange, emailAddresses: this.state.recipientAddresses, newReport: true , reportType: 'users'})
    }
    else if (this.props.reportType == 'publish'){
    this.props.sendEmail({ ...this.state.dateRange, emailAddresses: this.state.publishRecipientAddresses, newReport: true ,  reportType: 'publish'})
    }
    else if (this.props.reportType == 'saved search'){
      this.props.sendEmail({ ...this.state.dateRange, emailAddresses: this.state.savedSearchRecipientAddresses, newReport: true ,
        reportType: 'saved search'})
    }

    else if (this.props.reportType == 'alert'){
      this.props.sendEmail({ ...this.state.dateRange, emailAddresses: this.state.emailAlertRecipientAddresses,
         newReport: true, reportType:'alert'})
    }
    else if (this.props.reportType == 'all saved search'){
      const start = subYears(new Date(), 50)
      const end = startOfDay(new Date())
      this.props.sendEmail({ start, end, emailAddresses: this.state.savedSearchRecipientAddresses, newReport: true ,
        reportType: 'saved search'})
    }
  
  }

  formatEmailTokens() {
    if (this.state.recipientAddresses) {
      return this.state.recipientAddresses.split(',').map((item) => { return { label: item } });
    }
    else {
      return [];
    }
  }

  formatEmailAlertTokens() {
    if (this.state.emailAlertRecipientAddresses) {
      return this.state.emailAlertRecipientAddresses.split(',').map((item) => { return { label: item } });
    }
    else {
      return [];
    }
  }

  formatPublishEmailTokens() {
    if (this.state.publishRecipientAddresses) {
      return this.state.publishRecipientAddresses.split(',').map((item) => { return { label: item } });
    }
    else {
      return [];
    }
  }

  handleEmailItemChange = (token) => {
    let items = []
    let recipientAddresses = ""
    if (this.state.recipientAddresses) {
      items = this.state.recipientAddresses.split(',')
    }
    if ("action" in token && token['action'] === 'delete') {
      items = items.filter(e => e !== token.label)
      recipientAddresses = items.join(',')
    } else {
      items.push(...token.label.split(',').map(email => email.trim()))
      let [validEmails,invalidEmails,repeatingIds] = getValidandInvalidEmailList(items)
      recipientAddresses = validEmails.join(',')
      if (repeatingIds && repeatingIds.length > 0) {
        let message = getChunkMessage(repeatingIds)
        message = "[" + message + "] " + "has been already added to your recipients list"
        this.props.showNotification({
          type: 'error',
          message: message
        })
      }

      if (invalidEmails && invalidEmails.length > 0) {
        let message = getChunkMessage(invalidEmails)
        message = "[" + message + "] " + "is not valid email address"
        this.props.showNotification({
          type: 'error',
          message: message
        })
      }
    }

    this.setState({ recipientAddresses });
  }

  handleEmailAlertItemChange = (token) => {
    let items = []
    let emailAlertRecipientAddresses = ""
    if (this.state.emailAlertRecipientAddresses) {
      items = this.state.emailAlertRecipientAddresses.split(',')
    }
    if ("action" in token && token['action'] === 'delete') {
      items = items.filter(e => e !== token.label)
      emailAlertRecipientAddresses = items.join(',')
    } else {
      items.push(...token.label.split(',').map(email => email.trim()))
      let [validEmails,invalidEmails,repeatingIds] = getValidandInvalidEmailList(items)
      emailAlertRecipientAddresses = validEmails.join(',')
      if (repeatingIds && repeatingIds.length > 0) {
        let message = getChunkMessage(repeatingIds)
        message = "[" + message + "] " + "has been already added to your recipients list"
        this.props.showNotification({
          type: 'error',
          message: message
        })
      }

      if (invalidEmails && invalidEmails.length > 0) {
        let message = getChunkMessage(invalidEmails)
        message = "[" + message + "] " + "is not valid email address"
        this.props.showNotification({
          type: 'error',
          message: message
        })
      }
    }

    this.setState({ emailAlertRecipientAddresses });
  }
  

  handlePublishEmailItemChange = (token) => {
    let items = []
    let publishRecipientAddresses = ""
    if (this.state.publishRecipientAddresses) {
      items = this.state.publishRecipientAddresses.split(',')
    }
    if ("action" in token && token['action'] === 'delete') {
      items = items.filter(e => e !== token.label)
      publishRecipientAddresses = items.join(',')
    } else {
      items.push(...token.label.split(',').map(email => email.trim()))
      let [validEmails,invalidEmails,repeatingIds] = getValidandInvalidEmailList(items)
      publishRecipientAddresses = validEmails.join(',')
      if (repeatingIds && repeatingIds.length > 0) {
        let message = getChunkMessage(repeatingIds)
        message = "[" + message + "] " + "has been already added to your recipients list"
        this.props.showNotification({
          type: 'error',
          message: message
        })
      }

      if (invalidEmails && invalidEmails.length > 0) {
        let message = getChunkMessage(invalidEmails)
        message = "[" + message + "] " + "is not valid email address"
        this.props.showNotification({
          type: 'error',
          message: message
        })
      }
    }

    this.setState({ publishRecipientAddresses });
  }

  formatSavedSearchEmailTokens() {
    if (this.state.savedSearchRecipientAddresses) {
      return this.state.savedSearchRecipientAddresses.split(',').map((item) => { return { label: item } });
    }
    else {
      return [];
    }
  }

  handleSavedSearchEmailItemChange = (token) => {
    let items = []
    let savedSearchRecipientAddresses = ""
    if (this.state.savedSearchRecipientAddresses) {
      items = this.state.savedSearchRecipientAddresses.split(',')
    }
    if ("action" in token && token['action'] === 'delete') {
      items = items.filter(e => e !== token.label)
      savedSearchRecipientAddresses = items.join(',')
    } else {
      items.push(...token.label.split(',').map(email => email.trim()))
      let [validEmails,invalidEmails,repeatingIds] = getValidandInvalidEmailList(items)
      savedSearchRecipientAddresses = validEmails.join(',')
      if (repeatingIds && repeatingIds.length > 0) {
        let message = getChunkMessage(repeatingIds)
        message = "[" + message + "] " + "has been already added to your recipients list"
        this.props.showNotification({
          type: 'error',
          message: message
        })
      }

      if (invalidEmails && invalidEmails.length > 0) {
        let message = getChunkMessage(invalidEmails)
        message = "[" + message + "] " + "is not valid email address"
        this.props.showNotification({
          type: 'error',
          message: message
        })
      }
    }

    this.setState({ savedSearchRecipientAddresses });
  }


}