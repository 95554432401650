import is from 'is'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Table from 'app/common/Table'

import PageSelector from './PageSelector'

import './style.less'


export default class PaginatedTable extends Component {
  static propTypes = {
    pageCount: PropTypes.number.isRequired,
    currentPage: PropTypes.number,
    onPageChange: PropTypes.func,
    // Also takes any props that Table takes
  }

  static defaultProps = {
    onPageChange() {},
  }

  get currentPage() {
    return is.defined(this.props.currentPage)
      ? this.props.currentPage
      : this.state.currentPage
  }

  render() {
    const {children, pageCount, ...restProps} = this.props

    return (
      <div className="paginated-table-container" style={{marginTop: '1em'}}>
        <Table {...restProps}>{children}</Table>
        <PageSelector
          currentPage={this.currentPage}
          pageCount={pageCount}
          onPageChange={this.props.onPageChange}
        />
      </div>
    )
  }
}
