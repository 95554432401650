import React, { Component } from 'react'
import { connect } from 'react-redux'

import Modal from 'app/common/Modal'

import AddSearchContainer from './Container'
import { hideAddNewModal, fetchAssignees } from '../searches-admin-actions'
import { getSearchesAdmin } from '../searches-admin-selectors'


@connect(
  state => {
    const searchesAdmin = getSearchesAdmin(state)
    return {
      isOpen: searchesAdmin.addNewModalData.isOpen,
      isLoading: searchesAdmin.isLoading,
      isAssigneesFetched: searchesAdmin.isAssigneesFetched,
      step: searchesAdmin.addNewModalData.step,
      userIds: searchesAdmin.userIds,
    }
  },
  {
    hideAddNewModal,
    fetchAssignees,
  },
)
export default class AddNewModal extends Component {
  render() {
    if (this.props.isOpen && !this.props.isLoading && !this.props.isAssigneesFetched) {
      this.props.fetchAssignees()
    }

    const content = this.props.isOpen
      ? <AddSearchContainer
          step={this.props.step}
        />
      : null

    return (
      <div>
        <Modal
          id="add-new-firm-search-modal"
          isOpen={this.props.isOpen}
          onClose={this.props.hideAddNewModal}
        >
          {content}
        </Modal>
      </div>
    )
  }
}
