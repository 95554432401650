import PropTypes from 'prop-types'
import React, { Component } from 'react'
import * as R from 'ramda'
import Dropdown from 'app/common/Dropdown'
import Option from 'app/common/Dropdown/Option'
import Button from 'app/common/Button'
import Tooltip from 'app/common/Tooltip'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { getEntities } from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import PopoverDropdown from 'app/common/PopoverDropdown'
import { showSelectedColumns } from './sources-admin-actions'
import { COLUMNS, COLUMN_OPTIONS } from './sources-admin-constants'
import { getSourcesAdmin } from './sources-admin-selectors'
import { Feed } from 'app/models'
import { MAX_ROW_COUNT_FOR_LABELS } from './sources-admin-constants'

@connect(
  createSelector(
    [getEntities, getSourcesAdmin],
    (entities, sourcesAdmin) => {
      const orm = Orm.withEntities(entities)
      return {
        selectedColumns: sourcesAdmin.selectedColumns,
        selectedFeeds: orm.getByIds(Feed, sourcesAdmin.selectedFeedIds),
        selectedAllFeedsHealth: sourcesAdmin.selectedAllFeedsHealth
      }
    }
  ),
  {
    showSelectedColumns
  }
)

export default class BulkActions extends Component {
  static propTypes = {
    firm: PropTypes.object.isRequired,
    totalCount: PropTypes.number.isRequired,
    selectedFeedIds: PropTypes.array.isRequired,
    visibleFeedIds: PropTypes.array.isRequired,

    onChangeSelectAll: PropTypes.func.isRequired,
    onAssign: PropTypes.func.isRequired,
    onFeature: PropTypes.func.isRequired,
    onPromote: PropTypes.func.isRequired,
    onDemote: PropTypes.func.isRequired,
    onExclude: PropTypes.func.isRequired,
    onLabel: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired,
    onReport: PropTypes.func.isRequired,
    enableSourceLastContentDisplay: PropTypes.bool.isRequired
  }

  render() {
    const {firm, totalCount, selectedFeedIds, visibleFeedIds, selectedColumns, enableSourceLastContentDisplay, selectedFeeds, selectedAllFeedsHealth} = this.props

    const selectAllShouldBeChecked = (
      selectedFeedIds.length > 0 &&
      R.intersection(selectedFeedIds, visibleFeedIds).length === visibleFeedIds.length
    )

    let shouldReportEnabled = false
    if ((selectAllShouldBeChecked) && (selectedFeeds && selectedFeeds.length > 0 && selectedAllFeedsHealth && selectedAllFeedsHealth.length > 0)) {
      shouldReportEnabled = selectedAllFeedsHealth.every(val => val && ((val.status && val.status === 'Dormant') && (!val.reported)))
    }
    else if (selectedFeeds && selectedFeeds.length > 0) {
      shouldReportEnabled = selectedFeeds.every(val => val && ((val.status && val.status === 'Dormant') && (!val.reported)))
    }

    const filteredColumnOptions = enableSourceLastContentDisplay ? COLUMN_OPTIONS : COLUMN_OPTIONS.filter(obj => obj.value !== COLUMNS.CREATED_AT)
    
    return (
      <div className="bulk-actions">
        <div className="checkbox-dropdown">
          <Dropdown onChange={(value) => this.props.onChangeSelectAll(value)} value={''}>
            <Option
              value=''
              isInvisible={true}
              label={
                <input
                  type="checkbox"
                  onClick={e => e.preventDefault()}
                  onChange={e => e.preventDefault()}
                  checked={selectAllShouldBeChecked}
                />
              }
            />
            <Option label={`Select All (${totalCount})`} value="all" />
            <Option label={`Select Visible (${visibleFeedIds.length})`} value="visible" />
            <Option label="Deselect All" value="none" />
          </Dropdown>
        </div>

        <Button
          label="Assign..."
          disabled={selectedFeedIds.length === 0}
          onClick={this.props.onAssign}
        />

        <Button
          label="Feature..."
          disabled={selectedFeedIds.length === 0}
          onClick={this.props.onFeature}
        />

        <Button
          label={`Promote ${firm.orgType} Wide`}
          disabled={selectedFeedIds.length === 0}
          onClick={this.props.onPromote}
        />

        <Button
          label={`Demote ${firm.orgType} Wide`}
          disabled={selectedFeedIds.length === 0}
          onClick={this.props.onDemote}
        />

        <Button
          label={`Exclude ${firm.orgType} Wide`}
          disabled={selectedFeedIds.length === 0}
          onClick={this.props.onExclude}
        />


        <Tooltip
          label="Labeling multiple sources at a time is limited to 50 sources"
          disabled={selectedFeedIds.length > 0 && selectedFeedIds.length <= MAX_ROW_COUNT_FOR_LABELS ? true : false}
        >
          <Button
            label="Label..."
            disabled={selectedFeedIds.length > 0 && selectedFeedIds.length <= MAX_ROW_COUNT_FOR_LABELS ? false : true}
            onClick={this.props.onLabel}
          />
        </Tooltip>

        <Button
          label="Export"
          disabled={selectedFeedIds.length === 0}
          onClick={this.props.onExport}
        />

        <Button
          label="Report"
          disabled={selectedFeedIds.length === 0 || !shouldReportEnabled}
          onClick={this.props.onReport}
        />

        <Button
          label="Delete"
          isDestructive
          disabled={selectedFeedIds.length === 0}
          onClick={this.props.onRemove}
        />

        <PopoverDropdown
            label="Add Columns"
            options={filteredColumnOptions}
            multiSelection={true}
            enableFilter={true}
            enableTooltip={true}
            showSubtitle={false}
            placeholder="Search"
            containerClassName='manage-columns'
            selectedOptions={selectedColumns}
            onSelection={(items) => this.props.showSelectedColumns(items)}>
            <div>Manage Columns <img src="/media/img/ManageColumnsIcon.svg" /></div>
        </PopoverDropdown>
      </div>
    )
  }
}
