import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Dropdown, { Option } from 'app/common/Dropdown'
import { NUMBER_OF_SEARCH_RESULTS } from 'app/search-results-page/search-results-page-constants'

import * as actions from '../../profile-preferences-actions'
import * as selectors from '../../profile-preferences-selectors'

import styles from '../common/dropdown.less'

const DEFAULT_VALUE = NUMBER_OF_SEARCH_RESULTS.TWENTY


export default function SearchResultsNumber() {
  const value = useSelector(selectors.getresultsPerPage)
  const isLoading = useSelector(selectors.getresultsPerPageLoading)
  const dispatch = useDispatch()
  const onChange = value => {
    dispatch(actions.changeNumberOfSearchResults(value))
  }
  return (
    <Dropdown
      value={value || DEFAULT_VALUE}
      disabled={isLoading}
      onChange={onChange}
      className={styles.dropdown}
    >
      <Option value={NUMBER_OF_SEARCH_RESULTS.TWENTY} label="20" />
      <Option value={NUMBER_OF_SEARCH_RESULTS.SIXTY} label="60" />
      <Option value={NUMBER_OF_SEARCH_RESULTS.HUNDRED} label="100" />
    </Dropdown>
  )
}
