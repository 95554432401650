import classNames from 'classnames'
import PropTypes from 'prop-types'
import {zip} from 'ramda'
import React from 'react'

import SimpleModal from 'app/common/modals/SimpleModal'

import styles from './ShowMeModal.less'

const UNBOOSTED_ARTICLES = [
  {
    headline: 'Debate highlights West Virginia\'s inaction on energy diversity, efficiency.',
    source: 'Environmental Health News - Climate and Energy',
  },
  {
    headline: 'U.S. nuclear industry achieved record capacity factor in 2014',
    source: 'SmartBrief - Nuclear Energy Institute',
  },
  {
    headline: 'MICHIGAN: Governor says energy plan coming in March',
    source: 'E&E Energy Wire News',
  },
  {
    headline: 'Stocks up at midday as investors feel encouraged by oil price gains',
    source: 'Fox Business - Energy',
  },
  {
    headline: 'OIL COLUMN - Brent above US$50 as markets wait for Draghi',
    source: 'Proactive Investors Markets',
  },
]


function Article({headline, source, isActive = false, style}) {
  return (
    <div
      className={classNames(styles.article, {[styles.active]: isActive})}
      style={style}
    >
      <div className={styles.headline}>{headline}</div>
      <div className={styles.source}>{source}</div>
    </div>
  )
}
Article.propTypes = {
  headline: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  style: PropTypes.object,
}


export default function ShowMeModal({
  articles,
  header,
  description,
  onClose,
}) {
  const allArticles = zip(UNBOOSTED_ARTICLES, articles)
  return (
    <SimpleModal title={header} onClose={onClose}>
      <p className={styles.description}>{description}</p>

      <div className={styles.grid}>
        <h3 style={{msGridColumn: 1, msGridRow: 1}}>
          Energy News Without Booster
        </h3>

        <h3 style={{msGridColumn: 2, msGridRow: 1}}>
          Energy News With Booster
        </h3>

        {allArticles.map(([unboostedArticle, boostedArticle], index) =>
          <React.Fragment key={index}>
            <Article
              headline={unboostedArticle.headline}
              source={unboostedArticle.source}
              style={{msGridColumn: 1, msGridRow: index + 2}}
            />
            <Article
              headline={boostedArticle.headline}
              source={boostedArticle.source}
              isActive={boostedArticle.isActive}
              style={{msGridColumn: 2, msGridRow: index + 2}}
            />
          </React.Fragment>
        )}
      </div>
    </SimpleModal>
  )
}
ShowMeModal.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
    })
  ).isRequired,
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}
