import PropTypes from 'prop-types'
import React, {useCallback} from 'react'

import LoadingSpinner from 'app/common/LoadingSpinner'
import {
  insertEveryN,
} from 'app/utils'

import * as constants from '../../search-results-page-constants'

import styles from './DocumentList.less'
import ResultDocument from "app/reusable/ResultDocument"


function PageBreak({count}) {
  return <div className={styles.pageBreak}>{count}</div>
}
PageBreak.propTypes = {
  count: PropTypes.number.isRequired,
}


function DocumentList({
  search,
  topLevelDocuments,
  groupedDocuments,
  compareTopLevelDocuments,
  compareGroupedDocuments,
  compareCountIsGreater,
  isLoadingNextPage,
  areAllResultsDisplayed,
  selectedIds,
  onSelectChange,
  onFlaggingStateChange,
  onPromoteStateChange,
  onExclude,
  className,
}) {
  const documentComponents = (docs, groupedDocs, compare) => {
    const documents = docs.map(doc => {
      return(
        <ResultDocument
          search={search}
          document={doc}
          groupDocuments={groupedDocs[doc.id] || []}
          selectedIds={selectedIds}
          onSelectChange={onSelectChange}
          onFlaggingStateChange={onFlaggingStateChange}
          onPromoteStateChange={onPromoteStateChange}
          onExclude={onExclude}
          key={`document-${doc.id}`}
          compareDocuments={compare}
        />
      )
    })
    return(documents)
  }
  const docsWithPageBreaks = (docs) => {
    const pageBreaks = insertEveryN(
    index => (
      <PageBreak
        count={(index + 1) * constants.RESULTS_PER_PAGE}
        key={`page-break-${index}`}
      />
    ),
    constants.RESULTS_PER_PAGE,
    docs
    )
    return(pageBreaks)
  }
  const documents = documentComponents(topLevelDocuments, groupedDocuments,
    compareTopLevelDocuments.length > 0)
  const documentsWithPageBreaks = docsWithPageBreaks(documents)
  const compareDocuments = documentComponents(compareTopLevelDocuments,
    compareGroupedDocuments, compareTopLevelDocuments.length > 0)
  const compareDocumentsWithPageBreaks = docsWithPageBreaks(compareDocuments)

  const documentsContainer = compareTopLevelDocuments.length > 0
    ? <div className={styles.comparison}>
        <div className={styles.column1}>
          { !compareCountIsGreater ? documentsWithPageBreaks : compareDocumentsWithPageBreaks }
        </div>
        <div className={styles.column2}>
          { !compareCountIsGreater ? compareDocumentsWithPageBreaks : documentsWithPageBreaks}
        </div>
      </div>
    : <React.Fragment>
        {documentsWithPageBreaks}
      </React.Fragment>

  return (
    <div className={className}>
      {documentsContainer}
      {isLoadingNextPage && (
        <div className={styles.loading}>
          <LoadingSpinner className={styles.spinner} />
          Loading more results...
        </div>
      )}
      {areAllResultsDisplayed && !isLoadingNextPage && (
        <div className={styles.allResultsDisplayed}>
          All Results Displayed
        </div>
      )}
    </div>
  )
}
DocumentList.propTypes = {
  topLevelDocuments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  groupedDocuments: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
  ).isRequired,
  compareTopLevelDocuments: PropTypes.arrayOf(PropTypes.object.isRequired),
  compareGroupedDocuments: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.object.isRequired).isRequired),
  compareCountIsGreater: PropTypes.bool,
  isLoadingNextPage: PropTypes.bool.isRequired,
  areAllResultsDisplayed: PropTypes.bool.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onFlaggingStateChange: PropTypes.func.isRequired,
  onPromoteStateChange: PropTypes.func.isRequired,
  onExclude: PropTypes.func.isRequired,
  className: PropTypes.string,
}
export default DocumentList
