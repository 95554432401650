/*
 * Redux actions
 */

import { createAction } from 'redux-actions'

import { createRequestActions } from './utils'

// Generic action for adding initial state data returned by Django
export const addData = createAction('ADD_DATA')

// Same as above, but replaced IDs instead of merging them
export const replaceData = createAction('REPLACE_DATA')

//
// Init actions
//
// These actions are used to initialize pages from the data in the Django
// template context.
//

// Users

export const addAvailableUser = createAction('ADD_AVAILABLE_USER')

// Saved searches

export const setAvailableFilters = createAction('SET_AVAILABLE_FILTERS')
export const setQuickFilterOptions = createAction('SET_QUICK_FILTER_OPTIONS')
export const setFirmSourceLabels = createAction('SET_FIRM_SOURCE_LABELS')

// Profile searches

export const setProfileSearchIds = createAction('SET_PROFILE_SEARCH_IDS')
export const addProfileSearchIds = createAction('ADD_PROFILE_SEARCH_IDS')
export const removeProfileSearches = createAction('REMOVE_PROFILE_SEARCHES')

// Feeds

export const updateFeed = createAction('UPDATE_FEED')
