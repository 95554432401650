import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createSelector } from 'reselect'
import { connect } from 'react-redux'

import * as globalSelectors from 'app/global/global-selectors'
import InlineSvg from 'app/common/InlineSvg'
import Tooltip from 'app/common/Tooltip'
import {SavedSearch} from 'app/models'

import styles from './SearchTerm.less'


@connect(
  createSelector(
    [globalSelectors.getCurrentFirmLibraryName],
    (currentFirmLibraryName) => {
      return {
        currentFirmLibraryName,
      }
    },
  ),
  {},
)
export default class SearchTerm extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isFreeText: PropTypes.bool.isRequired,
    isFirmLibrary: PropTypes.bool,
    isFirmLibraryChild: PropTypes.bool,
    isSpecial: PropTypes.bool,
    isFeed: PropTypes.bool,
    isFirmSourceLabel: PropTypes.bool,
    scope: PropTypes.string,
    notes: PropTypes.string,
    ownerName: PropTypes.string,
    isRemovable: PropTypes.bool,
    onRemove: PropTypes.func,
    className: PropTypes.string,
  }

  static defaultProps = {
    isRemovable: true,
  }

  render() {
    const {SCOPES} = SavedSearch

    const {
      currentFirmLibraryName,
      label,
      isFreeText,
      isFirmLibrary,
      isFirmLibraryChild,
      isSpecial,
      isFeed,
      isFirmSourceLabel,
      scope,
      notes,
      ownerName,
      isRemovable,
      className,
    } = this.props

    const feedIcon = isFeed &&
      <div className={classNames(styles.icon)}>
        <Tooltip label="Source/Feed">
          <i className={classNames('fa', 'fa-newspaper-o')}/>
        </Tooltip>
      </div>

    const firmSourceLabelIcon = isFirmSourceLabel &&
      <div className={classNames(styles.icon)}>
        <Tooltip label="Firm Source Label">
          <i className={classNames('fa', 'fa-newspaper-o')}/>
        </Tooltip>
      </div>

    const notesPartial = notes ? `: ${notes}` : ''

    const globalIcon = scope === SCOPES.GLOBAL &&
      <div className={classNames(styles.icon)}>
        <Tooltip label={`Global Search${notesPartial}`}>
          <i className={classNames('fa', 'fa-globe')}/>
        </Tooltip>
      </div>

    const firmLibraryTooltipLabel = <React.Fragment>
      <strong>{isFirmLibraryChild && 'Linked to '}
      {currentFirmLibraryName} Search</strong>{notesPartial}
    </React.Fragment>

    const firmLibraryIcon = (isFirmLibrary || isFirmLibraryChild) &&
      <div className={styles.icon}>
        <Tooltip label={firmLibraryTooltipLabel}>
          <InlineSvg src="/media/img/firm-library-icon.svg"/>
        </Tooltip>
      </div>

    // if none of the above (except FL child), show profile icon.
    const profileIcon =
      !isFeed &&
      !isFirmSourceLabel &&
      !isFirmLibrary &&
      !isFreeText &&
      !isSpecial &&
      scope !== SCOPES.GLOBAL &&
      <div className={classNames(styles.icon, styles.profileIcon)}>
        <Tooltip label={`${ownerName ? `Owned by ${ownerName}` : 'Unknown owner'}${notesPartial}`}>
          <i className={classNames('fa', 'fa-user')}/>
        </Tooltip>
      </div>

    const icons =
      (feedIcon || firmSourceLabelIcon || globalIcon || firmLibraryIcon || profileIcon) &&
      <span className={styles.icons}>
        {feedIcon}
        {firmSourceLabelIcon}
        {globalIcon}
        {firmLibraryIcon}
        {profileIcon}
      </span>

    const removeButton = isRemovable && <span className={styles.remove} onClick={this.remove}>x</span>

    return (
      <span
        className={classNames(
          styles.term,
          className,
          {
            [styles.freeText]: isFreeText,
            [styles.removable]: isRemovable,
          },
        )}
      >
        {icons}

        <span>{label}</span>

        {removeButton}
      </span>
    )
  }

  remove = () => {
    if (this.props.onRemove) {
      this.props.onRemove(this.props.value)
    }
  }
}
