import React from 'react'
import { render } from 'react-dom'
import store from 'app/store'
import FirmSettings from "./FirmSettings"
import * as constants from './firm-settings-constants'
import saga from './firm-settings-saga'
import * as actions from './firm-settings-actions'
import reducer from './firm-settings-reducer'
import './style.less'
import {changeCaseObject} from 'app/utils'
import AppWrapper from "app/global/app-wrapper"


export function init(data) {
  // Add the data from this page to the Redux store
  store.dispatch(actions.init(changeCaseObject.camelCase(data)))
  const component =
    <AppWrapper>
      <FirmSettings />
    </AppWrapper>
  const container = document.getElementById('firm-settings-container')
  render(component, container)
}

export {constants, reducer, saga, actions}
