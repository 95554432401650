import React from 'react'

import { defaultPrevented } from '../../../utils'


export default ({newFeedName, onStartOver, onModalClose}) => (
  <div>
    <p>
      <strong>{newFeedName}</strong> is now available as a source
      in Base, but please allow up to 5 hours for content
      to successfully import into the system. You can
      assign <strong>{newFeedName}</strong> as a Promoted, Demoted, or Trusted
      source for users and groups by managing
      your <a href="" onClick={defaultPrevented(onModalClose)}>Source List</a>.
    </p>

    <div className="buttons left">
      <a className="leave button" onClick={onModalClose}>
        Back to Admin Dashboard
      </a>
      <a className="leave button" onClick={onStartOver}>
        Add Another Source
      </a>
    </div>
  </div>
)
