import { apiCall } from 'app/api'
import * as urls from 'app/urls'


export function fetchData({data}) {
  return apiCall({
    url: urls.dunAndBradstreet.data({data}),
    method: 'POST',
    data,
  })
}
