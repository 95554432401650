import {graphqlQuery} from 'app/api'
import {args, gql} from 'app/graphql'


export function getSearch({queryType, query}) {
  return graphqlQuery(
    gql`
      query {
        savedSearch(${args({queryType, query})}) {
          validationErrorMessage
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function getAppliedFilterKey(filters) {
  return graphqlQuery(
    gql`
      mutation {
        key: createAppliedFilter(${args({searchId: null, filters})})
      }
    `
  ).then(({body: {data}}) => data)
}

export function getTermFrequencyFilterKey(termFrequencyFilters) {
  return graphqlQuery(
    gql`
      mutation {
        key: createTermFrequencyFilter(${args(
          {searchId: null, termFrequencyFilters: termFrequencyFilters,})
        })
      }
    `
  ).then(({body: {data}}) => data)
}
