import * as searchLookupApi from './search-lookup-api'
import * as searchLookupActions from './search-lookup-actions'
import { all, takeLatest, put, delay } from 'redux-saga/effects'
import * as urls from 'app/urls'
import { handleSagaError } from 'app/utils/errors'


function* handleFetchSearches(action) {
  yield delay(200)
  const params = {}
  Object.keys(action.payload).forEach(k => {
    const value = action.payload[k]
    if (value) {
      params[k] = value
    }
  })
  try {
    const response = yield(searchLookupApi.fetchSearches(params))
    const payload = {
      ...response.data,
      items: response.data.items.map(item => ({...item.search, matchingQueryTerms: item.matchingQueryTerms})),
    }
    yield put(searchLookupActions.fetchSearchesComplete(payload))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleFetchSearch(action) {
  try {
    const response = yield searchLookupApi.fetchSearch(action.payload)
    const search = {...response.savedSearch}
    search.resultsLink = urls.tier3Shared(search)
    yield put(searchLookupActions.setQueryModalData({isOpen: true, search: search}))
  } catch(e) {
    yield* handleSagaError(error)
  }
}

export default function*() {
  yield all([
    takeLatest(
      searchLookupActions.fetchSearches,
      handleFetchSearches,
    ),
    takeLatest(
      searchLookupActions.fetchSearch,
      handleFetchSearch,
    ),
  ])
}
