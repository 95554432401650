import classNames from 'classnames'
import {fromPairs, map, pipe} from 'ramda'
import React, {useEffect, useState} from 'react'
import {Portal} from 'react-portal'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import Orm from 'app/framework/Orm'
import {Document, Feed, SavedSearch, User} from 'app/models'
import * as selectors from './dashboard-selectors'

import * as styles from './Dashboard.less'
import * as loadingStyles from './DashboardLoading.less'

import * as entitiesActions from 'app/entities/entities-actions'
import { getEntities } from 'app/entities/entities-selectors'
import {HomeSearchBar} from "app/home/search-bar"
import DashboardSidebarLoading from "./dashboard-sidebar/DashboardSidebarLoading"
import DashboardSidebar from "./dashboard-sidebar/DashboardSidebar"
import EmptySidebar from "./dashboard-sidebar/EmptySidebar"
import DashboardSection from "./dashboard-section/DashboardSection"
import EmptySection from './dashboard-section/EmptySection'
import DashboardSectionLoading from "./dashboard-section/DashboardSectionLoading"
import { getCurrentUserId, getCurrentFirmShowAlternateDateFormat, getCurrentFirm } from
    'app/global/global-selectors'
import { getGroupIdIfGroupDashboard } from "./dashboard-selectors";
import { removeDocumentsForExcludedFeedId, activeSidebarNavChange, chartPointClick } from
    "app/dashboard/dashboard-actions"
import MzLineChart from "app/common/charts/MzLineChart"
import LoadingSpinner from "app/common/LoadingSpinner"
import {getFirmCategoryLabel} from 'app/global/firm-category-label'


// The distance you need to scroll before the "Back to Top" button appears.
const BACK_TO_TOP_SCROLL_THRESHOLD = 700


function BackToTopLink() {
  const [shouldShowBackToTop, setShouldShowBackToTop] = useState(false)
  const onScroll = event => {
    const el = event.target

    if (!shouldShowBackToTop && el.scrollTop > BACK_TO_TOP_SCROLL_THRESHOLD) {
      setShouldShowBackToTop(true)
    }
    else if (
      shouldShowBackToTop
      && el.scrollTop <= BACK_TO_TOP_SCROLL_THRESHOLD
    ) {
      setShouldShowBackToTop(false)
    }
  }
  useEffect(
    () => {
      document
        .getElementById('body')
        .addEventListener('scroll', onScroll)
      return () => {
        document
          .getElementById('body')
          .removeEventListener('scroll', onScroll)
      }
    },
    [shouldShowBackToTop],
  )
  const goToTop = () => {
    document.getElementById('body').scrollTo(0, 0)
  }
  return (
    <Portal>
      <div
        className={classNames(
          styles.backToTop,
          {[styles.visible]: shouldShowBackToTop},
        )}
        onClick={goToTop}
      >
        Back to Top
      </div>
    </Portal>
  )
}


class Dashboard extends React.Component {

  componentDidMount() {
    document
      .getElementById('body')
      .addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    document
      .getElementById('body')
      .removeEventListener('scroll', this.handleScroll)
  }

  render() {
    const {
      firstCategoryHasChart,
      activeSectionName,
      activeSearchId,
      areAllSectionsLoaded,
      isLoading,
      fetchedSections,
      fetchedSearches,
      fetchedDocuments,
      chartData,
      showAlternateDateFormat,
      currentFirm,
      groupId,

      // Actions
      updateDocuments,
      updateFeeds,
      removeDocumentsForExcludedFeed,
      activeNavChange,
    } = this.props

    const sectionData = fetchedSections.map(sec => {
      if(!sec.searchData){return}
      const sectionChartData = sec.showChart && chartData
        ? chartData[sec.section]
        : null

      const chartSeriesData = (sectionChartData && sectionChartData.seriesData)
      const hasChartSeriesData = chartSeriesData !== null && chartSeriesData.length > 0
      const aggregateTimelineChart = sec.showChart && chartSeriesData
        ? <MzLineChart
            chartData={sectionChartData}
            className={styles.hpLineChartWrapper}
            onChartClickFunc={(clickData) => this.handleChartPointOnClick(clickData, sec.section)}
            onClickType={'plot'}
          />
        : sec.showChart && !(groupId && (sec.section === "client" || sec.section === "tracker"))
          ? <div
              className={classNames(styles.loading,)}
            >
              <LoadingSpinner className={styles.spinner} />
              { sec.chartSectionName
                ? `Loading ${sec.chartSectionName} Timeline...`
                : 'Loading Timeline Chart...'
              }
            </div>
          : null

      return (
        <div key={`section-${sec.section}`}
          className={classNames(
          styles.dashboardSearchSection,
          'dashboard-search-section', // for Pendo
        )}>
          {/*section header*/}
            <div className={styles.sectionContainer}>
              <div
                key={`section-header-${sec.sectionName}`}
                className={styles.sectionHeader}
                id={sec.sectionName}
              >
                {getFirmCategoryLabel({
                  category: sec.sectionName, currentFirm
                })}
              </div>
              { sec.showChart && (
                <div
                  className={classNames(styles.chart,
                    {[styles.chartLoading]: !hasChartSeriesData || !sectionChartData})}
                >
                  { aggregateTimelineChart }
                </div>
              )}
              <DashboardSection
                section={sec}
                searches={fetchedSearches}
                showAlternateDateFormat={showAlternateDateFormat}
                documents={fetchedDocuments}
                updateDocuments={updateDocuments}
                updateFeeds={updateFeeds}
                removeDocumentsForExcludedFeed={removeDocumentsForExcludedFeed}
                activeNavChange={activeNavChange}
              />
            </div>
          <BackToTopLink
            isLoading={isLoading}
          />
        </div>
      )
    })

    return (
      <div
        className={classNames(
          styles.dashboardPage,
          loadingStyles.skeleton,
          'dashboard-page', // for Pendo
        )}
      >
        <div className={styles.dashboard}>

          {/*sidebar nav*/}
          <div
            className={classNames(
              styles.sidebarContainer,
              'dashboard-sidebar', // for Pendo
            )}
            id={'sidebarNav'}
          >
            {isLoading && !areAllSectionsLoaded
              ? <DashboardSidebarLoading />
              : fetchedSearches && fetchedSearches.length > 0
                ? <DashboardSidebar
                    sections={this.props.fetchedSections}
                    searches={this.props.fetchedSearches}
                    activeSectionName={activeSectionName}
                    activeSearchId={activeSearchId}
                    activeNavChange={activeNavChange}
                  />
                : <EmptySidebar/>
            }
          </div>

          <div
            className={classNames(
              styles.content,
              'dashboard-content', // for Pendo
            )}
          >

            {/*search bar*/}
            <div className={styles.searchBar} id={'searchBar'}>
              <HomeSearchBar />
            </div>

            {/*Main results container*/}
            { isLoading && !areAllSectionsLoaded
              ? <DashboardSectionLoading
                  hasChart={firstCategoryHasChart}
                />
              : fetchedSearches && fetchedSearches.length > 0
                ? sectionData
                : <EmptySection/>
            }
          </div>

        </div>
      </div>
    )
  }
  handleChartPointOnClick(clickData, section) {
    const {plotPk, plotDateString} = clickData
    this.props.chartPointClick({category: section, searchId: plotPk, dateString: plotDateString})
  }
}
export default connect(
  createSelector(
    [
      selectors.getDashboardState,
      getCurrentFirmShowAlternateDateFormat,
      getEntities,
      getCurrentUserId,
      getCurrentFirm,
      getGroupIdIfGroupDashboard,
    ],
    (
      dashboardState,
      showAlternateDateFormat,
      entities,
      currentUserId,
      currentFirm,
      groupId,
    ) => {
      const orm = Orm.withEntities(entities)
      const searches = (ssIds) => {return(orm.getByIds(SavedSearch, ssIds))}
      const documents = (docIds) => {return(orm.getByIds(Document, docIds))}
      const fetchedSearches = searches(dashboardState.fetchedSearchIds)
      const fetchedDocuments = documents(dashboardState.fetchedDocumentIds)
      return {
        firstCategoryHasChart: dashboardState.firstCategoryHasChart,
        isLoading: dashboardState.isLoading,
        activeSectionName: dashboardState.activeSectionName,
        activeSearchId: dashboardState.activeSearchId,
        areAllSectionsLoaded: dashboardState.areAllSectionsLoaded,
        currentUser: orm.getById(User, currentUserId),
        showAlternateDateFormat: showAlternateDateFormat,
        fetchedSections: dashboardState.fetchedSections,
        chartData: dashboardState.chartData,
        currentFirm: currentFirm,
        fetchedSearches,
        fetchedDocuments,
        groupId,
      }
    },
  ),
  {
    updateDocuments: documents => entitiesActions.update({
      [Document.entityKey]: pipe(
        map(document => [document.id, document]),
        fromPairs,
      )(documents),
    }),
    updateFeeds: feeds => entitiesActions.update({
      [Feed.entityKey]: pipe(
        map(feed => [feed.id, feed]),
        fromPairs,
      )(feeds),
    }),
    removeDocumentsForExcludedFeed: feedId => removeDocumentsForExcludedFeedId({feedId}),
    activeNavChange: (waypoint, searchId, sectionName) =>
      activeSidebarNavChange({waypoint, searchId, sectionName}),
    chartPointClick,
  },
)(Dashboard)
