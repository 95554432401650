import React, { Component } from 'react';
import './style';

export class ConfirmModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="confirm-modal">
        <div className="smoke-base confirm-modal-blocker"/>
        <div className="smoke-base smoke-visible smoke-confirm confirm-modal-content">
          <div className="smokebg"/>
          <div className="dialog smoke">
            <div className="dialog-inner">
              <div>{this.props.message}</div>
              <div className="dialog-buttons">
                <button onClick={() => this.props.okHandler()}>OK</button>
                <button className="cancel" onClick={() => this.props.cancelHandler()}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
