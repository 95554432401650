import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import deepCopy from 'deepcopy'

import { SavedSearch } from 'app/models'
import Button from 'app/common/Button'
import Modal from 'app/common/Modal'
import InputBlock from 'app/common/InputBlock'
import TextBox from 'app/common/TextBox'
import CategoryDropdown from 'app/common/CategoryDropdown'
import Table from 'app/common/Table'
import Dropdown from 'app/common/Dropdown'
import LoadingOverlay from 'app/common/LoadingOverlay'
import AlertFrequencyDropdown from 'app/reusable/AlertFrequencyDropdown'
import AlertMaxItemsDropdown from 'app/reusable/AlertMaxItemsDropdown'
import * as constants from 'app/search-results-page/search-results-page-constants'
import './EditSearchSettingsModal.less'


@connect(
  // Map state to props
  state => {
    return {
      currentUserIsFirmLibraryGroup: state.currentUserIsFirmLibraryGroup,
      isPracticesEnabled: state.currentFirmIsPracticesEnabled,
      currentFirmLibraryName: state.currentFirmLibraryName,
    }
  },

  // Map dispatch to props
  {

  },
)
export default class EditSearchSettingsModal extends Component {
  static propTypes = {
    savedSearch: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    isSaving: PropTypes.bool,
  }

  state = {
    name: null,
    category: null,
    isPrivate: null,
    noticeConfig: {
      frequency: null,
      maxItems: {},
      sendAlone: null,
    },
    // This is true if the user tried to submit but a required field was empty.
    hasFailedValidation: false,
  }

  render() {
    const {CATEGORIES} = SavedSearch

    const omittedCategories = [CATEGORIES.TRUSTED, CATEGORIES.TRUSTED_UNCATEGORIZED]

    if (!this.props.isPracticesEnabled) {
      omittedCategories.push(CATEGORIES.PRACTICE)
    }

    let name = this.state.name !== null
      ? this.state.name
      : this.props.savedSearch.name
    if (name === null) {
      const params = new URLSearchParams(window.location.search)
      name = params.get('query')
    }

    let category = this.state.category || this.props.savedSearch.category
    // trusted categories are not supported here; default to topic.
    if ([CATEGORIES.TRUSTED_UNCATEGORIZED, CATEGORIES.TRUSTED].includes(category)) {
      category = 'tracker'
    }

    let alertFrequency = this.state.noticeConfig.frequency || this.props.savedSearch.noticeConfig.frequency
    if (alertFrequency === 'priority_trigger') {
      // real-time alerts are no longer supported
      alertFrequency = 'none'
    }

    const isPrivate =
      this.state.isPrivate === null
        ? this.props.savedSearch.scope === 'personal'
        : this.state.isPrivate

    const sendAlone =
      this.state.noticeConfig.sendAlone === null
        ? this.props.savedSearch.noticeConfig.sendAlone
        : this.state.noticeConfig.sendAlone

    /**
     * when searching on tier3 for a shared (incl. FL) search, you get a, personal, unsaved, child search to work with.
     * so, a parent FL search can be identified here by isFirmLibraryChild and being unsaved.
     * unless you're logged in as a FL user; then simply isFirmLibrary will work.
     */
    const firmLibraryIndicator =
      this.props.savedSearch.isFirmLibrary || (this.props.savedSearch.isFirmLibraryChild && !this.props.savedSearch.isSaved)
        ? <div className="tier3-indicator firm-library-search results-tooltip" data-tooltip-type="firm-library">
            <span className="icon" />
            <span className="label">{this.props.currentFirmLibraryName} Search</span>
          </div>
        : null

    const saveButtons =
      <div className="buttons-row">
        <Button
          label={
            this.props.savedSearch.isSaved
              ? "Update Search Settings"
              : "Save as New Search"
          }
          isPrimary={!this.props.savedSearch.isSaved}
        />

        <Button
          label="Cancel"
          isPlainText={true}
          onClick={() => this.props.onHide()}
        />
      </div>

    const contentTypeData = [
      {
        name: 'Articles',
        id: 'defaultPubTypes',
      },
      {
        name: 'Filings',
        id: 'allFilings',
      },
      {
        name: 'Tweets',
        id: 'twitter',
      },
      {
        name: 'Events',
        id: 'event',
      },
    ]

    const contentTypes =
      <div className="setting-block">
        <h4>Alert Content Types</h4>

        <Table
          data={contentTypeData}
        >
          <Table.Column
            name="checkbox"
            baseWidth={'10%'}
            growRatio={0}
            shrinkRatio={0}
            cellContents={contentType =>
              <input
                className="checkbox"
                type="checkbox"
                onChange={evt => this.contentTypeCheckboxHandler(contentType.id, evt.target.checked)}
                checked={this.getContentTypeMaxItems(contentType.id)}
              />
            }
          />

          <Table.Column
            name="name"
            label="Content Type"
            baseWidth={'45%'}
            growRatio={0}
            shrinkRatio={0}
            cellContents={contentType => contentType.name}
          />

          <Table.Column
            name="max-items"
            label="Quantity"
            baseWidth={'45%'}
            growRatio={0}
            shrinkRatio={0}
            cellContents={contentType => {
              return (
                this.getContentTypeMaxItems(contentType.id)
                ? <AlertMaxItemsDropdown
                    value={this.getContentTypeMaxItems(contentType.id)}
                    onChange={(value) => this.alertMaxItemsChangeHandler(contentType.id, value)}
                    hasZeroOption={false}
                  />
                : <Dropdown
                    options={[{value: null, label: '--'}]}
                    disabled={true}
                  />
              )
            }

            }
          />
        </Table>

        <p className="field-description">Maximum number of items to appear in your Base Email Alert for this search.</p>
      </div>

    const emailOptions =
      this.props.currentUserIsFirmLibraryGroup ?
        null
        : <div>
            <div className="setting-block">
              <InputBlock label="Alert Frequency">
                <AlertFrequencyDropdown
                  value={alertFrequency}
                  onChange={(value) => this.alertFrequencyChangeHandler(value)}
                />
              </InputBlock>
              <p className="field-description">The timing of which you would like this search to appear in your Base Email Alert.</p>
            </div>

            {contentTypes}

            <div className="setting-block">
              <InputBlock label="Deliver this search in a separate email alert" isInline>
                <input
                  type="checkbox"
                  checked={sendAlone}
                  onChange={evt => this.sendAloneCheckboxHandler(evt.target.checked)}
                />
              </InputBlock>
            </div>
          </div>

    const privateOption =
      this.props.currentUserIsFirmLibraryGroup &&
        <div className="checkbox-input-block">
          <InputBlock isInline label="Private Search">
            <input
              type="checkbox"
              checked={isPrivate}
              onChange={(evt) => this.privateCheckboxHandler(evt)}
            />
          </InputBlock>
        </div>

    const content =
      <form onSubmit={this.handleSubmit.bind(this)} className="form-container">
        <h2 className="header">Search Settings</h2>

        <div className="setting-block">
          <div>
            <InputBlock label="Name">
              <TextBox
                value={name}
                onChange={(evt) => this.nameChangeHandler(evt)}
                isInvalid={
                  this.state.hasFailedValidation && this.state.name === ''
                }
                ref={textBox => this.nameInput = textBox}
              />
            </InputBlock>
            {firmLibraryIndicator}
          </div>
          <p className="field-description">Enter a name for the Search you have built.</p>
        </div>

        <div className="setting-block">
          <InputBlock label="Category">
            <CategoryDropdown
              value={category}
              onChange={(value) => this.categoryChangeHandler(value)}
              omittedCategories={omittedCategories}
            />
          </InputBlock>
          <p className="field-description">The category in which you would like your search to appear. This will be reflected only in your user profile.</p>
        </div>

        {emailOptions}
        {privateOption}
        {saveButtons}
      </form>

    const loader =
      this.props.isSaving
      ? <LoadingOverlay/>
      : null

    return (
      <div id="edit-search-settings-modal">
        {loader}

        <Modal
          isOpen={true}
          onClose={() => this.props.onHide()}
        >
          {content}
        </Modal>
      </div>
    )
  }

  getContentTypeMaxItems(key) {
    if (this.state.noticeConfig.maxItems.hasOwnProperty(key)) {
      return this.state.noticeConfig.maxItems[key]
    } else if (this.props.savedSearch.noticeConfig.maxItems[key]) {
      return this.props.savedSearch.noticeConfig.maxItems[key]
    }
    return 0
  }

  nameChangeHandler(evt) {
    this.setState({name: evt.target.value})
  }

  categoryChangeHandler(value) {
    this.setState({category: value})
  }

  alertFrequencyChangeHandler(value) {
    const noticeConfig = deepCopy(this.state.noticeConfig)
    noticeConfig.frequency = value
    this.setState({noticeConfig})
  }

  alertMaxItemsChangeHandler(type, value) {
    const noticeConfig = deepCopy(this.state.noticeConfig)
    noticeConfig.maxItems[type] = value
    this.setState({noticeConfig})
  }

  privateCheckboxHandler(evt) {
    this.setState({isPrivate: evt.target.checked})
  }

  contentTypeCheckboxHandler(contentType, checked) {
    const noticeConfig = deepCopy(this.state.noticeConfig)
    if (checked) {
      noticeConfig.maxItems[contentType] = 5
    } else {
      noticeConfig.maxItems[contentType] = 0
    }
    this.setState({noticeConfig})
  }

  sendAloneCheckboxHandler(checked) {
    const noticeConfig = deepCopy(this.state.noticeConfig)
    noticeConfig.sendAlone = checked
    this.setState({noticeConfig})
  }

  handleSubmit(event) {
    event.preventDefault()
    if (this.state.name === '') {
      this.setState(
        {hasFailedValidation: true},
        () => {
          this.nameInput.focus()
        },
      )
      return
    }
    this.save()
  }

  save() {
    const data = {}
    if (this.props.savedSearch.isSaved) {
      data.id = this.props.savedSearch.id
    }
    const noticeConfig = {}
    if (this.state.name) {
      data.name = this.state.name
    }
    if (this.state.category) {
      data.category = this.state.category
    }
    if (this.state.isPrivate !== (this.props.savedSearch.scope === 'personal')) {
      data.scope = this.state.isPrivate ? 'personal' : 'shared'
    }
    if (this.state.noticeConfig.frequency) {
      noticeConfig.frequency = this.state.noticeConfig.frequency
    }
    if (Object.keys(this.state.noticeConfig.maxItems).length > 0) {
      noticeConfig.maxItems = this.state.noticeConfig.maxItems
    }
    if (this.state.noticeConfig.sendAlone !== null) {
      noticeConfig.sendAlone = this.state.noticeConfig.sendAlone
    }
    if (Object.keys(noticeConfig).length > 0) {
      data.noticeConfig = noticeConfig
    }
    const params = new URLSearchParams(window.location.search)
    const duration = params.get('time-frame')
    if(duration){
      data.duration = duration
    }
    const resultsOrder = params.get('sort')
    if(resultsOrder){
      data.resultsOrder = constants.SERVER_SORT_OPTIONS[resultsOrder]
    }

    this.props.onSave(data)
  }
}
