import classNames from 'classnames'
import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import {
  flatten,
  groupBy,
  intersection,
  length,
  map,
  pipe,
  prepend,
  prop,
  sortBy,
} from 'ramda'
import React from 'react'
import {connect} from 'react-redux'

import Button from 'app/common/Button'
import InlineSvg from 'app/common/InlineSvg'
import LoadingSpinner from 'app/common/LoadingSpinner'
import BareModal from 'app/common/modals/BareModal'
import TextBox from 'app/common/TextBox'
import * as api from 'app/flagging/flagging-api'
import * as notifications from 'app/global/notifications'

import styles from './FlagModal.less'
import {extractErrorMessage} from 'app/utils/errors'
import BulkUserAssignment from "app/reusable/BulkUserAssignment"
import { fetchUsers, fetchAssignees } from 'app/firm-admin/searches/searches-admin-actions'
import Orm from "app/framework/Orm"
import {getSearchesAdmin} from "app/firm-admin/searches/searches-admin-selectors"
import {User} from "app/models"
import store from 'app/store'
import * as flaggingActions from '../../flagging/flagging-actions'

const sortByLowercaseName = sortBy(obj => obj.name.toLowerCase())
const flattenFlagCategories = pipe(
  map(flagCategory => [flagCategory, ...(flagCategory.children || [])]),
  flatten,
)
const countTotalFlagCategories = pipe(
  flattenFlagCategories,
  length,
)

/**
 * The number of categories that must be present for the search bar to show.
 */
const SEARCH_BAR_COUNT_THRESHOLD = 20


// TODO: Replace this with a skeleton state.
function Loading() {
  return (
    <div className={styles.loadingData}>
      <LoadingSpinner className={styles.spinner} />
      Loading categories...
    </div>
  )
}


class AddFlagCategory extends React.PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }

  validInputPattern = /^[\w!'"&$+\-() ]*$/;

  state = {
    nameValue: '',
    folderInputInvalid: false,
  }

  render() {
    const {isLoading} = this.props
    const {nameValue} = this.state
    return (
      <form onSubmit={this.onSubmit} className={styles.addNew}>
        <label>
          <div className={styles.label}>Enter folder name:</div>
          <TextBox
            autoFocus={true}
            onChange={this.onTextChange}
            isInvalid={this.state.folderInputInvalid}
            className={styles.input}
          />
          {this.state.folderInputInvalid &&
            <div className={styles.invalidInputText}>
              Invalid character(s) in folder name. Allowed characters include letters, numbers and
              <pre style={{display: 'inline'}}>!'"&$+-()</pre>.
            </div>}
        </label>
        <div className={styles.buttons}>
          <Button
            label={isLoading ? 'Adding...' : 'Add'}
            disabled={!nameValue || this.state.folderInputInvalid || isLoading}
            isPrimary={true}
            isNarrow={true}
            isSmall={true}
          />
          <Button
            label="Cancel"
            isPlainText={true}
            isNarrow={true}
            isSmall={true}
            onClick={this.onCancel}
          />
        </div>
      </form>
    )
  }

  onTextChange = event => {
    this.setState({
      nameValue: event.target.value,
      folderInputInvalid: !event.target.value.match(this.validInputPattern)
    })
  }

  onSubmit = async event => {
    event.preventDefault()
    const {nameValue} = this.state
    if (!nameValue || this.state.folderInputInvalid) return
    this.props.onSubmit(nameValue)
  }

  onCancel = event => {
    event.preventDefault()
    this.props.onCancel()
  }
}


class SwitchFlaggingUser extends React.PureComponent {
  static propTypes = {
    users: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    onlyUserManagedGroups: PropTypes.bool.isRequired,
  }
  state = {
    flagToUserId: null,
  }

  render() {
    const {users, fetchUsers, onlyUserManagedGroups} = this.props
    const {flagToUserId} = this.state
    return (
      <form onSubmit={this.onSubmit} className={'switch-flagging-user-form'}>
        <div className={'user-selection-container'}>
          Select {onlyUserManagedGroups ? 'Group' : 'Group or User'} to Flag For:
        </div>
          <BulkUserAssignment
            users={users}
            onSelectedIdsChange={(ids) => this.onSelectedUserIdsChange(ids)}
            isLargeFirm={!onlyUserManagedGroups}
            onFetchUsers={fetchUsers}
            isSingleColumn={true}
            limitOne={true}
            onlyUserManagedGroups={onlyUserManagedGroups}
            defaultToGroups={true}
          />
        <div className={'buttons'}>
          <Button
            label={'Flag For'}
            disabled={!flagToUserId}
            isPrimary={true}
            isNarrow={true}
            isSmall={true}
          />
          <Button
            label="Cancel"
            isPlainText={true}
            isNarrow={true}
            isSmall={true}
            onClick={this.onCancel}
          />
        </div>
      </form>
    )
  }

  onSelectedUserIdsChange = ids => {
    let id = null
    if (ids.length > 0){id = ids[0]}
    this.setState({flagToUserId: id})
  }

  onSubmit = event => {
    event.preventDefault()
    const {flagToUserId} = this.state
    if (!flagToUserId) return
    this.props.onSubmit(flagToUserId)
  }

  onCancel = event => {
    event.preventDefault()
    this.props.onCancel()
  }
}


class FlagCategory extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    isUncategorized: PropTypes.bool.isRequired,
    isRoot: PropTypes.bool.isRequired,
    isChecked: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onAddChild: PropTypes.func.isRequired,
  }

  render() {
    const {
      name,
      isUncategorized,
      isRoot,
      isChecked,
      isLoading,
    } = this.props

    const actionIcons =
      <div className={styles.actionIcons}>
        {!isUncategorized && isRoot && (
          <img
            src="/media/img/ico_new_folder.png"
            alt="New Subfolder"
            onClick={this.handleAddSubfolderClick}
            className={styles.addSubfolder}
          />
        )}
      </div>

    return (
      <label className={classNames(styles.category, {[styles.child]: !isRoot})}>
        <div className={styles.checkboxLabelSection}>
          {isLoading ? (
            <LoadingSpinner className={styles.loading} />
          ) : (
            <input
              type="checkbox"
              checked={isChecked}
              onChange={this.handleChange}
              className={styles.checkbox}
            />
          )}
          <span>{name}</span>
        </div>
          { actionIcons }
      </label>
    )
  }

  handleChange = event => {
    this.props.onChange(event.target.checked)
  }

  handleAddSubfolderClick = event => {
    event.preventDefault()
    this.props.onAddChild()
  }
}


class FlagCategories extends React.Component {
  static propTypes = {
    flagCategories: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    flaggings: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    selectedDocumentIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    loadingFlagCategoryIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    addingChildFlagCategoryForId: PropTypes.number,
    isAddFlagCategoryLoading: PropTypes.bool.isRequired,
    shouldShowUncategorized: PropTypes.bool.isRequired,
    onFlaggingChange: PropTypes.func.isRequired,
    onAddFlagCategory: PropTypes.func.isRequired,
    showAddFlagCategoryForm: PropTypes.func.isRequired,
    hideAddFlagCategoryForm: PropTypes.func.isRequired,
  }

  render() {
    const {
      flagCategories,
      flaggings,
      selectedDocumentIds,
      loadingFlagCategoryIds,
      addingChildFlagCategoryForId,
      isAddFlagCategoryLoading,
      shouldShowUncategorized,
      onFlaggingChange,
    } = this.props

    let flattenedFlagCategories = pipe(
      sortByLowercaseName,
      map(flagCategory => [
        flagCategory,
        ...sortBy(prop('name'))(flagCategory.children),
      ]),
      flatten,
    )(flagCategories)
    if (shouldShowUncategorized) {
      // Prepend a fake category to represent uncategorized flaggings.
      flattenedFlagCategories = prepend(
        {id: null, name: 'Uncategorized'},
        flattenedFlagCategories,
      )
    }
    const flaggedDocumentIdsByCategoryId = pipe(
      groupBy(flagging => flagging.category ? flagging.category.id : null),
      map(flaggings => flaggings.map(flagging => flagging.directorId)),
    )(flaggings)

    const isFlagCategoryChecked = flagCategoryId => intersection(
      selectedDocumentIds,
      flaggedDocumentIdsByCategoryId[flagCategoryId] || [],
    ).length === selectedDocumentIds.length
    const isFlagCategoryLoading = flagCategoryId =>
      loadingFlagCategoryIds.includes(flagCategoryId)

    const flagCategoriesUi = pipe(
      map(flagCategory => {
        // We may also need to render the "add" component immediately after one
        // of these flag categories, so that's why we render an array here.
        const elements = [
          <FlagCategory
            name={flagCategory.name}
            isUncategorized={!flagCategory.id}
            isRoot={!flagCategory.parent}
            isChecked={isFlagCategoryChecked(flagCategory.id)}
            isLoading={isFlagCategoryLoading(flagCategory.id)}
            onChange={bind(onFlaggingChange, null, flagCategory.id)}
            onAddChild={
              bind(this.showAddFlagCategoryForm, this, flagCategory.id)
            }
            key={`flag-category-${flagCategory.id}`}
          />,
        ]
        if (
          addingChildFlagCategoryForId !== null
          && addingChildFlagCategoryForId === flagCategory.id
        ) {
          elements.push(
            <AddFlagCategory
              isLoading={isAddFlagCategoryLoading}
              onSubmit={bind(this.addFlagCategory, this)}
              onCancel={bind(this.hideAddFlagCategoryForm, this)}
              key={`add-${flagCategory.id}`}
            />
          )
        }
        return elements
      }),
    )(flattenedFlagCategories)

    return (
      <React.Fragment>
        {addingChildFlagCategoryForId === 0 ? (
          <AddFlagCategory
            isLoading={isAddFlagCategoryLoading}
            onSubmit={bind(this.addFlagCategory, this)}
            onCancel={bind(this.hideAddFlagCategoryForm, this)}
          />
        ) : (
          <a
            onClick={bind(this.showAddFlagCategoryForm, this, 0)}
            className={styles.addNewButton}
          >
            + Create New Folder
          </a>
        )}
        {flagCategoriesUi}
      </React.Fragment>
    )
  }

  showAddFlagCategoryForm(parentId) {
    this.props.showAddFlagCategoryForm(parentId)
  }
  hideAddFlagCategoryForm() {
    this.props.hideAddFlagCategoryForm()
  }
  addFlagCategory(name) {
    const parentId = this.props.addingChildFlagCategoryForId
    this.props.onAddFlagCategory({name, parentId})
  }
}


class FlagModal extends React.Component {
  static propTypes = {
    moveFromCategoryId: PropTypes.number,  // 0 represents "Uncategorized" folder
    documentIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    /**
     * Callback that fires when the flagging state is toggled for any flag
     * category. `FlagModal` calls this function with an object of shape
     * {documents, isFlagged, flagCategoryId}, where `flagCategoryId` is the ID
     * of the flag category that was toggled, `isFlagged` is the final flagging
     * state for this flag category, and `documents` is an array of objects of
     * shape {id, isFlagged}, where `isFlagged` represents whether the document
     * has *any* flaggings by this user (useful for determining e.g. whether to
     * show the flag icon as active in the interaction bar).
     */
    onFlaggingStateChange: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    feedId: PropTypes.number,

    // From Redux
    showNotification: PropTypes.func.isRequired,
  }

  state = {
    // `null` value means we're still fetching the data.
    flagCategories: null,
    flaggings: [],

    // Objects in the shape of {id, value} that represent queued flag/unflag
    // actions (i.e. the request hasn't been fired yet).
    flagCategoryUpdateQueue: [],

    // IDs for flag categories that are currently updating (the request has been
    // sent and we're waiting on the response).
    updatingFlagCategoryIds: [],

    // The ID for which we are currently creating a new sub-category, if any.
    addingChildFlagCategoryForId: null,
    isAddFlagCategoryLoading: false,

    // flagging for another user/group (Admin/Staff only option).
    showSwitchFlaggingUserForm: false,
    isOwnerFolders: true,
    flagToUserId: null,
    flagToUserName: null,

    // Any text entered into the search box. This filters the categories.
    searchText: '',

    currentFlagCategoryForId: null
  }

  componentDidMount() {
    this.fetchData().then()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {flagCategoryUpdateQueue, flagToUserId} = this.state

    if (flagToUserId !== prevState.flagToUserId) {
      this.setState({
        isOwnerFolders: flagToUserId === null,
      })
      this.fetchData().then()
    }

    if (!flagCategoryUpdateQueue.length) return
    for (const update of flagCategoryUpdateQueue) {
      this.updateFlaggingRequest(update.id, update.value).then()
    }
    this.setState(state => ({
      flagCategoryUpdateQueue: [],
      updatingFlagCategoryIds: [
        ...state.updatingFlagCategoryIds,
        ...flagCategoryUpdateQueue.map(update => update.id),
      ],
    }))
  }

  render() {
    const {moveFromCategoryId, documentIds, onClose, currentUserIsAdmin, currentUserIsGroupManager,
      users, fetchUsers, currentUserFullName, isMasquerading} = this.props
    const {
      flagCategories,
      flaggings,
      updatingFlagCategoryIds,
      addingChildFlagCategoryForId,
      showSwitchFlaggingUserForm,
      isOwnerFolders,
      isAddFlagCategoryLoading,
      searchText,
      flagToUserName,
    } = this.state

    const shouldShowSearchBar =
      flagCategories
       && countTotalFlagCategories(flagCategories) >= SEARCH_BAR_COUNT_THRESHOLD
    let filteredFlagCategories = flagCategories
    if (searchText && shouldShowSearchBar) {
      const searchTextLower = searchText.trim().toLowerCase()
      const containsSearchText = string =>
        string.toLowerCase().includes(searchTextLower)
      filteredFlagCategories
        = filteredFlagCategories
        .filter(flagCat =>
          containsSearchText(flagCat.name)
          || (
            flagCat.children
            && flagCat.children.some(child => containsSearchText(child.name))
          )
        )
        // Filter out children that don't contain the search text.
        .map(flagCat => ({
          ...flagCat,
          children:
            flagCat.children
            && flagCat.children.filter(child => containsSearchText(child.name)),
        }))
    }

    const onlyUserManagedGroups =
      !currentUserIsAdmin && currentUserIsGroupManager && !isMasquerading

    const isMove = typeof moveFromCategoryId === 'number'  // 0 represents "Uncategorized" folder

    return (
      <BareModal onClose={onClose} className={styles.flagModal}>
        <h1>
          <span className={styles.text}>
            {isMove? 'Move' : 'Flag'} {documentIds.length} Article{documentIds.length > 1 && 's'}
          </span>
          <InlineSvg
            src="/media/img/close-icon.svg"
            onClick={onClose}
            className={styles.closeIcon}
          />
        </h1>

        {(currentUserIsAdmin || currentUserIsGroupManager || isMasquerading) && (
          <div className={styles.switchFlaggingUserContainer}>
            <img
              src="/media/img/group_orange.png"
              alt="Flag to Profiles"
              className={styles.switchFlaggingUserIcon}
            />
            <a
              className={classNames(styles.switchFlaggingUserOwner,
                {[styles.active]: isOwnerFolders})}
              onClick={() => {this.onFoldersOwnerClick({
                isOwnerFolders: true, onlyUserManagedGroups: onlyUserManagedGroups
              })}}
            >
              <span className={styles.foldersOwner}>
                {isMasquerading ? `Folders For ${currentUserFullName}` : 'Your Folders'}
              </span>
            </a>

            <a
              className={classNames(styles.switchFlaggingUserOther,
                {[styles.active]: !isOwnerFolders})}
              onClick={() => {this.onFoldersOwnerClick({
                isOwnerFolders: false, onlyUserManagedGroups: onlyUserManagedGroups
              })}}
            >
              <span className={styles.foldersOther}>
                {onlyUserManagedGroups ? 'Group Folders' : 'Other Folders'}
              </span>
            </a>

          </div>
        )}
        {(currentUserIsAdmin || currentUserIsGroupManager || isMasquerading) &&
          showSwitchFlaggingUserForm && (
          <SwitchFlaggingUser
            users={users}
            onSubmit={(flagToUserId) => this.onSwitchFlagToUser(flagToUserId)}
            onCancel={() => this.hideSwitchFlaggingUserForm()}
            fetchUsers={fetchUsers}
            onlyUserManagedGroups={onlyUserManagedGroups}
          />
        )}

        {(currentUserIsAdmin || currentUserIsGroupManager || isMasquerading) &&
          !isOwnerFolders && flagToUserName
          && !showSwitchFlaggingUserForm && (
          <div className={styles.flagToUserName}>
            {`Folders For ${flagToUserName}`}
          </div>
        )}

        {shouldShowSearchBar && !showSwitchFlaggingUserForm && (
          <div className={styles.search}>
            <TextBox
              value={this.state.searchText}
              placeholder="Search..."
              onChange={this.onSearchTextChange}
            />
          </div>
        )}
        {!showSwitchFlaggingUserForm && (
          <div className={styles.content}>
            {flagCategories === null
              ? <Loading />
              : (
                <FlagCategories
                  flagCategories={filteredFlagCategories}
                  flaggings={flaggings}
                  selectedDocumentIds={documentIds}
                  loadingFlagCategoryIds={updatingFlagCategoryIds}
                  addingChildFlagCategoryForId={addingChildFlagCategoryForId}
                  isAddFlagCategoryLoading={isAddFlagCategoryLoading}
                  shouldShowUncategorized={!searchText}
                  onFlaggingChange={this.onFlaggingChange}
                  onAddFlagCategory={this.onAddFlagCategory}
                  showAddFlagCategoryForm={this.showAddFlagCategoryForm}
                  hideAddFlagCategoryForm={this.hideAddFlagCategoryForm}
                />
              )
            }
          </div>
        )}
      </BareModal>
    )
  }

  async fetchData() {
    const {flagCategories, flaggings, uncategorizedFlaggingsCount, categoriesFlaggingsCount} = await api.fetchFlaggingData({
      directorIds: this.props.documentIds,
      userId: this.state.flagToUserId,
    })
    
    let categoryCount = 0
    categoriesFlaggingsCount.forEach(category => {
    categoryCount += category.flaggingsCount;
    });
    const uncategorizedCount = uncategorizedFlaggingsCount;
    store.dispatch(flaggingActions.setFlaggedArticleCount(categoryCount + uncategorizedCount))

    this.setState({
      flagCategories,
      flaggings,
    })
  }

  async updateFlaggingRequest(flagCategoryId, isFlagged) {

    this.state.currentFlagCategoryForId = flagCategoryId;
    const {moveFromCategoryId, documentIds, feedId} = this.props
    const flagCategoryName = this.flagCategoryNameById(flagCategoryId)
    const {flagToUserId} = this.state
    const isMove = typeof moveFromCategoryId === 'number'  // 0 represents "Uncategorized" folder
    // TODO: Combine the two requests into one?
    try {
      const {documents} = await api.setDocumentFlaggingState({
        documentIds,
        feedId,
        flagCategoryId,
        isFlagged,
        flagToUserId,
      })
      if (isMove) {  // 0 represents "Uncategorized" folder
        await api.setDocumentFlaggingState({
          documentIds,
          feedId,
          flagCategoryId: moveFromCategoryId,
          isFlagged: false,
          flagToUserId,
        })
      }
      if (this.props.onFlaggingStateChange) {
        this.props.onFlaggingStateChange({
          flagCategoryId,
          isFlagged,
          documents,
        })
      }

      await this.fetchData()
      this.setState(state => ({
        ...state,
        updatingFlagCategoryIds:
          state.updatingFlagCategoryIds.filter(id => id !== flagCategoryId),
      }))
      this.showSuccessNotification({
        isFlagged,
        isMove,
        flagCategoryName,
      })
    } catch (error) {
      this.setState(state => ({
        ...state,
        updatingFlagCategoryIds: [],
      }))
      this.showErrorNotification({
        isFlagged,
        docCount: documentIds.length,
        flagCategoryName,
        error,
      })
    }
  }

  onFlaggingChange = (flagCategoryId, isFlagged) => {
    flagCategoryId = flagCategoryId || null
    const updateQueue = this.state.flagCategoryUpdateQueue
    if (updateQueue.find(update => update.id === flagCategoryId)) {
      // This request is already in progress, so don't do anything.
      return
    }
    this.setState(state => {
      const updateQueue = state.flagCategoryUpdateQueue
      return {
        ...state,
        flagCategoryUpdateQueue: [
          ...updateQueue,
          {id: flagCategoryId, value: isFlagged},
        ],
      }
    })
  }

  onSwitchFlagToUser(flagToUserId) {
    if (!flagToUserId) {return}
    const flagToUserName = this.props.orm.getById(User, flagToUserId).displayName

    this.setState({
      flagToUserId: flagToUserId,
      flagToUserName: flagToUserName,
    })

    this.hideSwitchFlaggingUserForm()
  }

  onAddFlagCategory = async ({name, parentId}) => {
    const {documentIds, feedId} = this.props
    this.setState({isAddFlagCategoryLoading: true})
    const {flagToUserId} = this.state
    try {
      const {id: flagCategoryId} = await api.createFlagCategory({
        name,
        parentId: parentId || undefined,
        feedId: feedId || undefined,
        flagDocumentIds: documentIds,
        flagToUserId
      })
      await this.fetchData()
      if (this.props.onFlaggingStateChange) {
        this.props.onFlaggingStateChange({
          flagCategoryId,
          isFlagged: true,
          documents: documentIds.map(id => ({id, isFlagged: true})),
        })
      }
      this.showSuccessNotification({
        isFlagged: true,
        flagCategoryName: name,
      })
    }
    catch (error) {
      this.showErrorNotification({
        isFlagged: true,
        docCount: documentIds.length,
        flagCategoryName: name,
        error,
      })
    }
    this.setState({isAddFlagCategoryLoading: false})
    this.hideAddFlagCategoryForm()
  }

  onFoldersOwnerClick({isOwnerFolders, onlyUserManagedGroups}) {
    if(onlyUserManagedGroups && !isOwnerFolders && !this.props.isAssigneesFetched) {
       this.props.fetchAssignees({onlyUserManagedGroups: onlyUserManagedGroups})
    }
    this.setState({
      showSwitchFlaggingUserForm: !isOwnerFolders,
      isOwnerFolders: isOwnerFolders
    })
    if (isOwnerFolders) {
      this.setState({
        flagToUserId: null,
        flagToUserName: null,
      })
    }
  }

  onSearchTextChange = event => {
    this.setState({searchText: event.target.value})
    this.hideAddFlagCategoryForm()
  }

  showAddFlagCategoryForm = parentId => {
    this.setState({addingChildFlagCategoryForId: parentId})
  }

  hideAddFlagCategoryForm = () => {
    this.setState({addingChildFlagCategoryForId: null})
  }

  hideSwitchFlaggingUserForm() {
    this.setState({
      showSwitchFlaggingUserForm: null,
      isOwnerFolders: this.state.flagToUserId === null,
    })
  }

  // Helpers

  flagCategoryNameById(id) {
    const flattenedCategories = flattenFlagCategories(this.state.flagCategories)
    const flagCategory = flattenedCategories.find(cat => cat.id === id)
    if (flagCategory) {
      return flagCategory.name
    }
    return 'Uncategorized'
  }

  showSuccessNotification({isFlagged, isMove, flagCategoryName, userIdCount}) {
    const {documentIds} = this.props
    this.props.showNotification({
      type: 'success',
      message: `${isFlagged ? (isMove ? 'Moved': 'Flagged') : 'Un-flagged'} ${documentIds.length > 1 
        ? `${documentIds.length} articles` : 'article'} ${isFlagged 
        ? 'to' : 'from'} "${flagCategoryName}" ${userIdCount 
        ? `for ${userIdCount} ${userIdCount > 1 ? 'profiles.' : 'profile.'}` : '.' }`,
    })
  }

  showErrorNotification({isFlagged, docCount, flagCategoryName, userIdCount, error}) {
    const errorMessage = extractErrorMessage(error)
    this.props.showNotification({
      type: 'error',
      message: `Unable to ${isFlagged ? 'flag' : 'unflag'} ${docCount > 1 
        ? `${docCount} articles` : 'article'} ${isFlagged ? 'to' : 'from'} "${flagCategoryName}" ${userIdCount 
        ? `for ${userIdCount} ${userIdCount > 1 ? 'profiles:' : 'profile:'}` : ':' }: ${errorMessage}`,
    })
  }
}
export default connect(
    state => {
    const orm = Orm.withEntities(state.entities)
    const searchesAdmin = getSearchesAdmin(state)
    return {
      currentUser: state.currentUser,
      currentUserIsAdmin: state.currentUserIsStaff || state.currentUserIsClientAdmin,
      currentUserIsGroupManager: state.isGroupManager,
      isMasquerading: state.isMasquerading,
      currentUserFullName: state.currentUserFullName,
      users: orm.getByIds(User, searchesAdmin.userIds),
      orm,
      isAssigneesFetched: searchesAdmin.isAssigneesFetched,
    }
  },
  {
    showNotification: notifications.actions.showNotification,
    fetchUsers,
    fetchAssignees,
  },
)(FlagModal)

