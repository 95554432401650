import {all, call, delay, put, select, takeEvery, takeLatest} from 'redux-saga/effects'
import {xprod} from 'ramda'

import * as actions from 'app/actions'
import * as notifications from 'app/global/notifications'
import {getEntityDataFromSearch, filtersByCategory} from 'app/utils/searches'
import * as entitiesActions from 'app/entities/entities-actions'
import {handleSagaError} from 'app/utils/errors'
import * as reusableApi from 'app/reusable/api'

import {fetchEmailAlertCategoryDefaults} from 'app/email-notifications/email-notifications-actions'
import {fetchCategoryDefaults} from 'app/email-notifications/email-notifications-api'
import * as advancedSearchSelectors from 'app/advanced-search/advanced-search-selectors'
import * as advancedSearchActions from 'app/advanced-search/advanced-search-actions'
import {ASSIGNEE_TYPES} from 'app/reusable/BulkUserAssignment'

import {getSearchesAdmin} from './searches-admin-selectors'
import * as searchesAdminApi from './searches-admin-api'
import * as searchesAdminActions from './searches-admin-actions'


function* handleInit(action) {
  yield put(searchesAdminActions.fetchSearches())
}

function* handleFetchSearches(action) {
  const searchesAdmin = yield select(getSearchesAdmin)
  const { currentPage, filters, sortField, sortDirection, itemsPerPage } = searchesAdmin
  try {
    const params = {
      page: currentPage,
      sortField,
      sortDirection,
      itemsPerPage
    }
    Object.keys(filters).forEach (key => {
      const value = filters[key]
      if (value || typeof value === 'boolean') {
        params[key] = value
      }
    })
    const response = yield searchesAdminApi.fetchSearchesPaged(params)
    const totalCount = response.searches.totalCount
    const searches = {}
    const users = {}
    const feeds = {}
    const searchIds = []
    response.searches.items.forEach(ss => {
      const feedIds = []
      ss.ownerId = ss.owner.id
      ss.aboutSources.forEach(f => {
        feeds[f.id] = f
        feedIds.push(f.id)
      })
      ss.childSearches.forEach(c => {
        if (!searches.hasOwnProperty(c.id)) {
          c.ownerId = c.owner.id
          searches[c.id] = {...c}
          users[c.owner.id] = {...c.owner}
        }
      })
      ss.aboutFeedsIds = feedIds
      searches[ss.id] = {...ss}
      users[ss.owner.id] = {...ss.owner}
      searchIds.push(ss.id)
    })
    const entities = {
      users,
      searches,
      feeds,
    }
    yield put(entitiesActions.update(entities))
    yield put(searchesAdminActions.fetchSearchesComplete({searchIds, totalCount}))
  } catch(error) {
    yield* handleSagaError(error)
    yield put(searchesAdminActions.hideLoader())
  }
}


function* handleFetchAllSearchIds(action) {
  const searchesAdmin = yield select(getSearchesAdmin)
  const { filters } = searchesAdmin
  const params = {}
  Object.keys(filters).forEach (key => {
    const value = filters[key]
    if (value || typeof value === 'boolean') {
      params[key] = value
    }
  })
  try {
    /**
     * `fetchAllSearchIds` also gets ss category and name.
     * need to add to entities to allow disabling `Add to` if any sources are selected.
     */
    const response = yield searchesAdminApi.fetchAllSearchIds(params)
    const searches = {}
    response.searches.forEach(ss => {
      searches[ss.id] = {...ss}
    })
    const entities = {
      searches,
    }
    yield put(entitiesActions.update(entities))
    yield put(searchesAdminActions.setSelectedSearchIds(response.searches.map(ss => ss.id)))
  } catch(error) {
    yield* handleSagaError(error)
    yield put(searchesAdminActions.hideLoader())
  }
}

function* handleFetchAssignees(action) {
  const searchesAdmin = yield select(getSearchesAdmin)
  const {isLargeFirm} = searchesAdmin
  try {
    const userIds = []
    const apiCalls = [
      call(reusableApi.fetchDepartments),
      call(reusableApi.fetchTeams),
      call(reusableApi.fetchFirmLocations),
    ]
    if (!isLargeFirm) {
      apiCalls.push(call(searchesAdminApi.fetchUsers, action.payload))
    }
    const [departmentsResponse, teamsResponse, firmLocationsResponse, usersResponse] = yield all(apiCalls)
    if (usersResponse) {
      const users = {}
      usersResponse.users.forEach(u => {
        users[u.id] = {...u}
        userIds.push(u.id)
      })
      const entities = {
        users,
      }
      yield put(entitiesActions.update(entities))
    }
    yield put(searchesAdminActions.fetchAssigneesComplete({
      userIds: userIds,
      departments: departmentsResponse.departments,
      teams: teamsResponse.teams,
      firmLocations: firmLocationsResponse.firmLocations,
    }))
  } catch(error) {
    yield* handleSagaError(error)
    yield put(searchesAdminActions.hideLoader())
  }
}

function* handleFetchUsers(action) {
  /**
   * this is only called when `isLargeFirm` is true, and `areAllUsersFetched` is false.
   * for other firms, users are fetched in `handleFetchAssignees`.
   */
  const nameFilter = action.payload ? action.payload.nameFilter : undefined
  // only fetch all if requested (ie. nameFilter is undefined).
  if (nameFilter === '') {
    // if name filter was removed, reset user ids to none.
    yield put(searchesAdminActions.setUserIds([]))
    yield put(searchesAdminActions.hideLoader())
    return
  }
  if (nameFilter) {
    yield delay(300)
  }
  try {
    const userIds = []
    const response = yield searchesAdminApi.fetchUsers(action.payload)
    const users = {}
    response.users.forEach(u => {
      users[u.id] = {...u}
      userIds.push(u.id)
    })
    const entities = {
      users,
    }
    yield put(entitiesActions.update(entities))
    yield put(searchesAdminActions.fetchUsersComplete({userIds, areAllUsersFetched: !nameFilter}))
  } catch(error) {
    yield* handleSagaError(error)
    yield put(searchesAdminActions.hideLoader())
  }
}

function* handleFetchSearch(action) {
  const currentUserId = yield select(state => state.currentUser)
  const { searchesFetchedForUserIds, filters } = yield select(getSearchesAdmin)
  try {
    const searches = {}
    const { searchId, ownerId } = action.payload
    const firmLocations = filters.firmLocations
    let response = null
    /**
     * we need all searches belonging to the search owner to populate the filters modal.
     * if the owner is the current user we already have them.
     */
    if (ownerId && (ownerId !== currentUserId) && !searchesFetchedForUserIds.includes(ownerId)) {
      response = yield searchesAdminApi.fetchUserSearches(ownerId, firmLocations)
      response.searches.forEach(ss => {
        ss.ownerId = ss.owner.id
        searches[ss.id] = {...ss}
      })
    }
    response = yield searchesAdminApi.fetchSearch(searchId)
    const search = {...response.search}
    const entities = getEntityDataFromSearch(search)
    yield put(advancedSearchActions.setPendingTermFrequencyFilters(search.termFrequencyFilters))
    yield put(entitiesActions.update(entities))
    yield put(searchesAdminActions.fetchSearchComplete({searchId, ownerId}))
    yield fetchLabelsIfNeeded()
  } catch(error) {
    yield* handleSagaError(error)
    yield put(searchesAdminActions.hideLoader())
  }
}


function* handleFetchFilters(action) {
  let response = null
  try {
    response = yield reusableApi.fetchAvailableFilters()
  } catch(error) {
    yield* handleSagaError(error)
    yield put(searchesAdminActions.hideLoader())
    return
  }
  const searches = {}
  response.firmLibrarySearches.forEach(search => {
    search.isFirmLibrary = true
    search.ownerId = search.owner.id
    searches[search.id] = {...search}
  })
  response.availableFilterCategories.forEach(fc => {
    fc.searches.forEach(search => {
      search.ownerId = search.owner.id
      searches[search.id] = {...search}
      search.children.forEach(search => {
        search.ownerId = search.owner.id
        searches[search.id] = {...search}
      })
    })
  })
  yield put(entitiesActions.update({searches}))
  const availableFiltersByCategory = filtersByCategory(response.availableFilterCategories)
  yield put(actions.setAvailableFilters(availableFiltersByCategory))
  yield put(actions.setFirmSourceLabels(response.firmSourceLabels))
  yield put(searchesAdminActions.hideLoader())
}

function* handleUpdateSearch(action) {
  const {
    searchId,
    userId,
    isMakeChild,
    isMakeCopy,
    name,
    isCascadeName,
    isFirmLibrary,
    scope,
    notes,
    category,
    queryType,
    query,
    filters,
    shareUserIds,
    noticeConfig,
    solrSearchField,
    groupingLevel,
  } = action.payload
  const params = {
    id: searchId,
    userId,
    isMakeChild,
    isMakeCopy,
    name,
    isCascadeName,
    isFirmLibrary,
    scope,
    notes,
    category,
    queryType,
    searchPhrase: query,
    filters,
    shareUserIds,
    noticeConfig,
    solrSearchField,
    groupingLevel,
  }

  if (params.queryType === 'raw' || params.queryType === undefined) {
    params.termFrequencyFilters =
      yield select(advancedSearchSelectors.pendingTermFrequencyFilters)
  }

  try {
    const response = yield(searchesAdminApi.updateSearch(params))
    const search = {...response.search}
    const entities = getEntityDataFromSearch(search)
    yield put(entitiesActions.update(entities))
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Search updated successfully.',
      })
    )
  } catch(error) {
    yield* handleSagaError(error)
  }
  yield put(searchesAdminActions.hideLoader())
}


function* handleGetSearchResults(action) {
  try {
    const { searchId, query, queryType, filters } = action.payload
    let termFrequencyFilters = []
    if (queryType === 'raw') {
      termFrequencyFilters = yield select(advancedSearchSelectors.pendingTermFrequencyFilters)
    }
    const response = yield searchesAdminApi.getSearchResults({id: searchId, query, queryType,
      filters, termFrequencyFilters})
    yield put(searchesAdminActions.getSearchResultsComplete(response.savedSearch.results))
  } catch (error) {
    yield* handleSagaError(error)
  }
  yield put(searchesAdminActions.hideLoader())
}


function* handleDeleteSearches(action) {
  const searchesAdmin = yield select(getSearchesAdmin)
  const { deleteSearchIds } = searchesAdmin
  try {
    const response = yield searchesAdminApi.deleteSearches(deleteSearchIds)
    yield put(searchesAdminActions.deleteSearchesComplete(deleteSearchIds))
    // removeProfileSearches also removes these searches from entities.
    yield put(actions.removeProfileSearches(deleteSearchIds.map(id => ({id}))))
    // need to re-fetch all searches so that usedBy and childSearches get updated
    yield put(searchesAdminActions.fetchSearches())
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: `${deleteSearchIds.length === 1 ? 'Search' : 'Searches'} deleted successfully.`,
      })
    )
  } catch(error) {
    yield* handleSagaError(error)
    yield put(searchesAdminActions.hideLoader())
  }
}


function* handleCreateSearches(action) {
  const { copySearchIds, childSearchIds, userIds, noticeConfig } = action.payload
  if(userIds.length > 0) {
  // This is kind of confusing, but basically we're creating one
  // big list of all the params that we will send to graphql.
  const userCopy = xprod(userIds, copySearchIds)
  const copySearchesToCreate = userCopy.map(([userId, searchId]) => ({
    userId,
    searchId,
    isMakeCopy: true,
    isMakeChild: false,
    noticeConfig,
  }))
  const userChild = xprod(userIds, childSearchIds)
  const childSearchesToCreate = userChild.map(([userId, searchId]) => ({
    userId,
    searchId,
    isMakeCopy: false,
    isMakeChild: true,
    noticeConfig,
  }))
  const searchesToCreate = copySearchesToCreate.concat(childSearchesToCreate)
  const copiedSearches = searchesToCreate.map(s => {
    let params = {
      id: s.searchId,
      userId: s.userId,
      isMakeChild: s.isMakeChild,
      isMakeCopy: s.isMakeCopy,
      name: undefined,
      isFirmLibrary: undefined,
      scope: undefined,
      category: undefined,
      queryType: undefined,
      searchPhrase: undefined,
      filters: undefined,
      noticeConfig: s.noticeConfig,
      termFrequencyFilters: undefined,
      solrSearchField: undefined,
      groupingLevel: undefined,
    }
    if (s.isMakeChild || s.isMakeCopy) {
      params.allowDuplicates = false
    }
    if (s.isMakeChild) {
      params.termFrequencyFilters = []
    }
    return params
  })
  // using separate calls so we can capture limit errors without affecting the other actions.
  //yield all(searchesToCreate.map(s => call(createCopySearch, s)))
  yield call(createCopySearch, copiedSearches)
  yield put(searchesAdminActions.createSearchesComplete())
  const searchesAdmin = yield select(getSearchesAdmin)
  if (!searchesAdmin.isSharing) {
    yield put(searchesAdminActions.fetchSearches())
  }
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: `${searchesToCreate.length === 1 ? 'Search' : 'Searches'} added successfully.`,
    })
  )
  }
}


function* handleShareSearches(action) {
  const { searchIds } = action.payload
  try {
    const response = yield reusableApi.shareSearches(action.payload)
  } catch(error) {
    yield* handleSagaError(error, {duration: 30})
    yield put(searchesAdminActions.hideLoader())
    // some may have succeeded so don't exit here.
  }
  // not showing a success message here because this can be called multiple times for the same user action
  yield put(searchesAdminActions.shareSearchesComplete())
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: `${searchIds.length === 1 ? 'Search' : 'Searches'} added successfully.`,
    })
  )
  const searchesAdmin = yield select(getSearchesAdmin)
  if (!searchesAdmin.isCreating) {
    yield put(searchesAdminActions.fetchSearches())
  }
}


function* handleCreateAndShareSearch(action) {
  const {
    isMakeChild,
    isMakeCopy,
    name,
    isFirmLibrary,
    scope,
    category,
    queryType,
    searchPhrase,
    filters,
    shareUserIds,
    shareDepartmentIds,
    shareTeamIds,
    shareFirmLocationIds,
    noticeConfig,
    solrSearchField,
    groupingLevel,
    shareGroupIds,
  } = action.payload
  let searchId = action.payload.searchId
  if (!searchId) {
    const params = {
      isMakeChild,
      isMakeCopy,
      name,
      isFirmLibrary,
      scope,
      category,
      queryType,
      searchPhrase,
      filters,
      shareUserIds,
      noticeConfig,
      solrSearchField,
      groupingLevel,
    }

    if (params.queryType === 'raw' || params.queryType === undefined) {
      params.termFrequencyFilters =
        yield select(advancedSearchSelectors.pendingTermFrequencyFilters)
    } else {
      params.termFrequencyFilters = []
    }

    let response = null
    try {
      response = yield searchesAdminApi.createSearch(params)
    } catch(error) {
      yield* handleSagaError(error)
      yield put(searchesAdminActions.hideLoader())
      return
    }
    searchId = response.search.id
    const search = {...response.search}
    const entities = getEntityDataFromSearch(search)
    yield put(entitiesActions.update(entities))
  }
  for (let userId of shareUserIds) {
    const params = {
      searchId,
      userId,
      isMakeCopy: scope === 'personal',
      noticeConfig,
    }
    params.isMakeChild = !params.isMakeCopy
    if (params.isMakeChild) {
      params.termFrequencyFilters = []
    }

    if (solrSearchField) {
      params.solrSearchField = solrSearchField
    }
    if (groupingLevel) {
      params.groupingLevel = groupingLevel
    }

    // using separate calls so we can capture individual errors without affecting the other actions.
    yield call(createSearch, params)
  }
  if (shareDepartmentIds.length > 0 || shareTeamIds.length > 0 || shareFirmLocationIds.length > 0 || shareGroupIds.length > 0 ) {
    const params = {
      searchIds: [searchId],
      departmentIds: shareDepartmentIds,
      teamIds: shareTeamIds,
      firmLocationIds: shareFirmLocationIds,
      isMakeChild: scope !== 'personal',
      isMakeCopy: scope === 'personal',
      noticeConfig,
      groupIds: shareGroupIds,
    }
    yield call(shareSearches, params)
  }
  yield put(searchesAdminActions.createAndShareSearchComplete())
  yield put(searchesAdminActions.fetchSearches())
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'Search created successfully.',
    })
  )
}


function* shareSearches(params) {
  try {
    const response = yield reusableApi.shareSearches(params)
  } catch(error) {
    yield* handleSagaError(error)
  }
}


function* createSearch(params) {
  let response = null
  try {
    response = yield searchesAdminApi.createSearch(params)
  } catch(error) {
    yield* handleSagaError(error)
    return
  }
  const search = {...response.search}
  const entities = getEntityDataFromSearch(search)
  yield put(entitiesActions.update(entities))
}


function* handleSendSearchesCsvEmail(action) {
  try {
    const response = yield searchesAdminApi.sendSearchesCsvEmail(action.payload)
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Your CSV export file is being generated and will be emailed to you shortly.',
      })
    )
  } catch(error) {
    yield* handleSagaError(error)
  }
  yield put(searchesAdminActions.hideLoader())
}


function* filtersChanged() {
  // Delay so that typing quickly doesn't fire off a ton of requests
  yield delay(200)
  yield put(searchesAdminActions.fetchSearches())
}

function* handleFetchAlertCategoryDefaults(action) {
  try {
    const response = yield fetchCategoryDefaults(action.payload)
    if (response.emailSettingsCategoryDefaults.length > 0) {
      const defaults = response.emailSettingsCategoryDefaults[0]
      const noticeConfig = {
        frequency: defaults.noticeFreq,
        maxItems: {
          defaultPubTypes: defaults.noticeMaxStories,
          allFilings: defaults.filingMaxItems,
          twitter: defaults.twitterMaxItems,
          event: defaults.eventMaxItems,
        }
      }
      yield put(searchesAdminActions.setSaveData({noticeConfig}))
    }
  } catch(error) {
    yield* handleSagaError(error)
  }
}


function* initializeDynamicFilters() {
  yield put(advancedSearchActions.initializePendingTermFrequencyFilters())
  yield put(advancedSearchActions.initializeTermFrequencyFilter())
  yield put(searchesAdminActions.setCanShowRelevanceFilter(false))
}


function* handleShowAddNewModal(action) {
  yield* initializeDynamicFilters()
}


function* handleHideEditSearchModal(action) {
  yield* initializeDynamicFilters()
}


function* handleFetchCanShowRelevanceFilterIds(action) {
  let canShowRelevanceFilter = false
  try {
    const searchIds = action.payload
    const response = yield searchesAdminApi.fetchCanShowRelevanceFilterIds(searchIds)
    canShowRelevanceFilter = response.canShowRelevanceFilterIds.length > 0
  } catch (error) {
    yield* handleSagaError(error)
  }
  yield put(searchesAdminActions.setCanShowRelevanceFilter(canShowRelevanceFilter))
}


function* handleRemoveExcludedFeedsFromSearch(action) {
  const feedIds = action.payload
  const searchesAdmin = yield select(getSearchesAdmin)
  const {searchId} = searchesAdmin.editModalData
  let response = null
  try {
    response = yield reusableApi.removeExcludedFeedsFromSearch({feedIds, searchId})
  } catch (error) {
    yield* handleSagaError(error)
    return
  }
  yield put(searchesAdminActions.fetchSearch({searchId}))
}


function* handleApplyFiltersToSearches(action) {
  const searchesAdmin = yield select(getSearchesAdmin)
  const searchIds = searchesAdmin.selectedSearchIds
  const filters = searchesAdmin.saveData.filters
  const isAddOnly = !action.payload.shouldRemoveExistingFilters
  let response
  try {
    response = yield searchesAdminApi.updateSearchFilters(searchIds, filters, isAddOnly)
  } catch (error) {
    yield* handleSagaError(error)
    yield put(searchesAdminActions.hideLoader())
    return
  }
  yield put(searchesAdminActions.hideLoader())
  yield put(searchesAdminActions.setShouldShowBulkAddFiltersModal(false))
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'Filters have been applied successfully.',
    })
  )
}


function* handleAddFeaturedSearches(action) {
  const searchesAdmin = yield select(getSearchesAdmin)
  const searchIds = searchesAdmin.selectedSearchIds
  const {assigneeIdsBySection} = searchesAdmin.featureModalData
  const userIds = [...assigneeIdsBySection[ASSIGNEE_TYPES.USER] || []]
  const groupIds = [...assigneeIdsBySection[ASSIGNEE_TYPES.GROUP] || []]
  const groupIdsForMembers = [...assigneeIdsBySection[ASSIGNEE_TYPES.GROUP_MEMBER] || []]
  const departmentIds = [...assigneeIdsBySection[ASSIGNEE_TYPES.DEPARTMENT] || []]
  const departmentIdsForMembers = [...assigneeIdsBySection[ASSIGNEE_TYPES.DEPARTMENT_MEMBER] || []]
  const teamIds = [...assigneeIdsBySection[ASSIGNEE_TYPES.TEAM] || []]
  const teamIdsForMembers = [...assigneeIdsBySection[ASSIGNEE_TYPES.TEAM_MEMBER] || []]
  const firmLocationIdsForMembers = [...assigneeIdsBySection[ASSIGNEE_TYPES.FIRM_LOCATION_MEMBER] || []]
  try {
    yield searchesAdminApi.addFeaturedSearches({
      searchIds, userIds, groupIds, groupIdsForMembers, departmentIds, departmentIdsForMembers, teamIds,
      teamIdsForMembers, firmLocationIdsForMembers
    })
  } catch (error) {
    yield* handleSagaError(error)
    yield put(searchesAdminActions.hideLoader())
    return
  }
  yield put(searchesAdminActions.setFeatureModalData({isOpen: false}))
  yield put(searchesAdminActions.hideLoader())
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'Features have been applied successfully.',
    })
  )
}


function* handleRemoveFeaturedSearches(action) {
  const searchesAdmin = yield select(getSearchesAdmin)
  const {searchId} = searchesAdmin.editModalData
  try {
    yield searchesAdminApi.removeFeaturedSearches({searchId: searchId, fromItems: action.payload})
  } catch (error) {
    yield* handleSagaError(error)
    yield put(searchesAdminActions.hideLoader())
    return
  }
  yield put(searchesAdminActions.fetchSearch({searchId}))
  yield put(searchesAdminActions.hideLoader())
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'Features have been removed successfully.',
    })
  )
}

function* fetchLabelsIfNeeded(action) {
  const props = yield select(getSearchesAdmin)
  const {labels, allParentLabels} = props
  if (labels === null) {
    yield put(searchesAdminActions.fetchSearchesManageLabels())
  }
  if (allParentLabels === null) {
    yield put(searchesAdminActions.fetchSearchesParentLabels())
  }
}

function* handleFetchLabels(action) {
  const props = yield select(getSearchesAdmin)
  let response = null
  try {
      response = yield searchesAdminApi.fetchSearchesManageLabels({...props.manageLabelsFilter})
  } catch (error) {
    yield* handleSagaError(error)
    yield put(searchesAdminActions.hideLoader())
    return
  }
    yield put(searchesAdminActions.fetchSearchesLabelsComplete(response.body.labels))
    yield put(searchesAdminActions.setSearchesManageLabelsTotalCount(response.body.totalCount))
}

function* handleFetchAllParentLabels() {
  let response = null
  try {
    response = yield searchesAdminApi.fetchSearchesParentLabels()
  } catch (error) {
    yield* handleSagaError(error)
    yield put(searchesAdminActions.hideLoader())
    return
  }
  yield put(searchesAdminActions.fetchSearchesParentLabelsComplete(response.parentSavedSearchLabels))
}


function* handleCreateLabel(action) {
  try {
    const response = yield searchesAdminApi.createSearchesLabel(action.payload)
  } catch(error) {
    yield* handleSagaError(error)
    yield put(searchesAdminActions.hideLoader())
    return
  }
  yield put(searchesAdminActions.resetSearchesNewLabelData())
  yield put(searchesAdminActions.fetchSearchesManageLabels())
  yield put(searchesAdminActions.fetchSearchesParentLabels())
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'Label created successfully',
    }),
  )
}

function* handleSaveLabel(action) {
  try {
    const response = yield searchesAdminApi.saveSearchesLabel(action.payload)
  } catch(error) {
    yield* handleSagaError(error)
    yield put(searchesAdminActions.hideLoader())
    return
  }
  yield put(searchesAdminActions.resetSearchesManageLabelsModalData())
  yield put(searchesAdminActions.fetchSearchesManageLabels())
  yield put(searchesAdminActions.fetchSearchesParentLabels())
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'Label saved successfully',
    }),
  )
}


function* handleDeleteLabel(action) {
  try {
    const response = yield searchesAdminApi.deleteSearchesLabel(action.payload)
  } catch(error) {
    yield* handleSagaError(error)
    yield put(searchesAdminActions.hideLoader())
    return
  }
  yield put(searchesAdminActions.resetSearchesManageLabelsModalData())
  yield put(searchesAdminActions.fetchSearches())
  yield put(searchesAdminActions.fetchSearchesManageLabels())
  yield put(searchesAdminActions.fetchSearchesParentLabels())
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'Label deleted successfully',
    }),
  )
}

function* handleUpdateLabels(action) {
  const {addIds, deleteIds, searchIds} = action.payload
  try {
    if (addIds && addIds.length > 0) {
      yield searchesAdminApi.addSearchesLabels({searchIds, labelIds: addIds})
    }
    if (deleteIds && deleteIds.length > 0) {
      yield searchesAdminApi.removeSearchesLabels({
        searchIds,
        labelIds: deleteIds,
      })
    }
  } catch (error) {
    yield* handleSagaError(error)
    yield put(searchesAdminActions.hideLoader())
    return
  }
  yield put(searchesAdminActions.fetchSearches())
  yield put(searchesAdminActions.hideEditSearchModal())
  if (addIds && addIds.length > 0 && deleteIds && deleteIds.length > 0) {
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: `Label added/removed successfully.`,
    }),
  )
  }else if(addIds && addIds.length > 0){
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: `${addIds && addIds.length > 1 ? 'Labels' : 'Label'} added successfully.`,
      }),
    )
  }
  else if(deleteIds && deleteIds.length > 0){
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: `${deleteIds && deleteIds.length > 1 ? 'Labels' : 'Label'} removed successfully.`,
      }),
    )
  }
}

function* handleBulkEditLabels(action) {
  const {selectedIds, deletedIds, selectedSearchIds} = action.payload
  try {
    if (selectedIds && selectedIds.length > 0) {
      yield searchesAdminApi.addSearchesLabels({
        searchIds: selectedSearchIds,
        labelIds: selectedIds,
      })
    }
    if (deletedIds && deletedIds.length > 0) {
      yield searchesAdminApi.removeSearchesLabels({
        searchIds:selectedSearchIds,
        labelIds: deletedIds,
      })
    }
  } catch (error) {
    yield* handleSagaError(error)
    yield put(searchesAdminActions.hideLoader())
    return
  }
  yield put(searchesAdminActions.fetchSearches())
  yield put(searchesAdminActions.resetBulkEditLabelModal())
  if (selectedIds && selectedIds.length > 0 && deletedIds && deletedIds.length > 0) {
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: `Labels added/removed successfully.`,
      }),
    )
    }else if(selectedIds && selectedIds.length > 0){
      yield put(
        notifications.actions.showNotification({
          type: 'success',
          message: `${selectedIds && selectedIds.length > 1 ? 'Labels' : 'Label'} added successfully.`,
        }),
      )
    }
    else if(deletedIds && deletedIds.length > 0){
      yield put(
        notifications.actions.showNotification({
          type: 'success',
          message: `${deletedIds && deletedIds.length > 1 ? 'Labels' : 'Label'} removed successfully.`,
        }),
      )
    }
}

function* createCopySearch(params) {
  let response = null
  try {
    response = yield searchesAdminApi.createCopiedSearch(params)
  } catch(error) {
    yield* handleSagaError(error)
    return
  }
}

export default function*() {
  yield all([
    takeLatest(
      searchesAdminActions.init,
      handleInit,
    ),
    takeLatest(
      searchesAdminActions.fetchSearches,
      handleFetchSearches,
    ),
    takeLatest(
      searchesAdminActions.fetchSearch,
      handleFetchSearch,
    ),
    takeLatest(
      searchesAdminActions.fetchFilters,
      handleFetchFilters,
    ),
    takeLatest(
      searchesAdminActions.showEditSearchModal,
      handleFetchSearch,
    ),
    takeLatest(
      searchesAdminActions.showEditSourceFolderModal,
      handleFetchSearch,
    ),
    takeLatest(
      searchesAdminActions.fetchAllSearchIds,
      handleFetchAllSearchIds,
    ),
    takeLatest(
      searchesAdminActions.fetchAssignees,
      handleFetchAssignees,
    ),
    takeLatest(
      searchesAdminActions.fetchUsers,
      handleFetchUsers,
    ),
    takeLatest(
      searchesAdminActions.updateSearch,
      handleUpdateSearch,
    ),
    takeLatest(
      searchesAdminActions.deleteSearches,
      handleDeleteSearches,
    ),
    takeEvery(
      searchesAdminActions.createSearches,
      handleCreateSearches,
    ),
    takeLatest(
      searchesAdminActions.createAndShareSearch,
      handleCreateAndShareSearch,
    ),
    takeLatest(
      searchesAdminActions.shareSearches,
      handleShareSearches,
    ),
    takeLatest(
      [
        // These are actions that will trigger a fetch
        searchesAdminActions.changeCurrentPage,
        searchesAdminActions.changeSort,
      ],
      handleFetchSearches,
    ),
    takeLatest(
      searchesAdminActions.changeFilters,
      filtersChanged,
    ),
    takeLatest(
      searchesAdminActions.sendSearchesCsvEmail,
      handleSendSearchesCsvEmail,
    ),
    takeLatest(
      searchesAdminActions.getSearchResults,
      handleGetSearchResults,
    ),
    takeLatest(
      fetchEmailAlertCategoryDefaults,
      handleFetchAlertCategoryDefaults,
    ),
    takeLatest(
      searchesAdminActions.showAddNewModal,
      handleShowAddNewModal,
    ),
    takeLatest(
      searchesAdminActions.hideEditSearchModal,
      handleHideEditSearchModal,
    ),
    takeLatest(
      searchesAdminActions.fetchCanShowRelevanceFilterIds,
      handleFetchCanShowRelevanceFilterIds,
    ),
    takeLatest(
      searchesAdminActions.removeExcludedFeedsFromSearch,
      handleRemoveExcludedFeedsFromSearch,
    ),
    takeLatest(
      searchesAdminActions.applyFiltersToSearches,
      handleApplyFiltersToSearches,
    ),
    takeLatest(
      searchesAdminActions.addFeaturedSearches,
      handleAddFeaturedSearches,
    ),
    takeLatest(
      searchesAdminActions.removeFeaturedSearches,
      handleRemoveFeaturedSearches,
    ),
    takeLatest(
      searchesAdminActions.fetchSearchesManageLabels,
      handleFetchLabels
    ),
    takeLatest(
      searchesAdminActions.fetchSearchesParentLabels,
      handleFetchAllParentLabels
    ),
    takeLatest(
      searchesAdminActions.showSearchesManageLabelsModal,
      fetchLabelsIfNeeded
    ),
    takeLatest(
      searchesAdminActions.createSearchesLabel,
      handleCreateLabel
    ),
    takeLatest(
      searchesAdminActions.setSearchesManageLabelsFilters,
      handleFetchLabels
    ),
    takeLatest(
      searchesAdminActions.saveSearchesLabel,
      handleSaveLabel
    ),
    takeLatest(
      searchesAdminActions.deleteSearchesLabel,
      handleDeleteLabel
    ),
    takeLatest(
      searchesAdminActions.updateSearchesLabels,
      handleUpdateLabels
    ),
    takeLatest(
      searchesAdminActions.setBulkEditLabelModalData,
      fetchLabelsIfNeeded
    ),
    takeLatest(
      searchesAdminActions.updateBulkEditSearchesLabels,
      handleBulkEditLabels
    ),
  ])
}
