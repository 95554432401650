import React from 'react'
import { useDrag } from 'react-dnd'
import classNames from 'classnames'

export default function DraggableItem(props) {
  const [{isDragging}, dragRef] = useDrag({
    item: {
      type: 'feed-list-entry',
      id: props.id,
      origin: props.origin,
      originId: props.originId,
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  return (
    <div
      ref={dragRef}
      className={classNames('draggable', props.className, {
        'is-dragging': isDragging,
      })}
    >
      {props.children}
    </div>
  )
}
