import PropTypes from 'prop-types'
import React from 'react'

const Column = () => {}
Column.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  isSortable: PropTypes.bool,
  sortBy: PropTypes.func,
  sortCompareFunction: PropTypes.func,
  baseWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  growRatio: PropTypes.number,
  shrinkRatio: PropTypes.number,
  cellContents: PropTypes.func,
  colSpan: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
}
Column.defaultProps = {
  label: '',
  isSortable: false,
  sortBy: (item, column) => item[column.name],
  sortCompareFunction: (val1, val2) => {
    if (typeof val1 == 'string' && typeof val2 == 'string') {
      // If both values are strings, we need to keep locales in mind
      return val1.localeCompare(val2)
    }
    if (val1 < val2) return -1
    if (val1 > val2) return 1
    return 0
  },
  growRatio: 1,
  shrinkRatio: 1,
  cellContents: (item, column) => item[column.name],
  colSpan: 1,
}

export default Column
