import {createAction} from 'redux-actions'

const action = name => createAction(`search-results/${name}`)

export const init = action('INIT')
export const fetchUserEmailSettings = action('FETCH_USER_EMAIL_SETTINGS')
export const setUserDefaultEmailSettings = action('SET_USER_DEFAULT_EMAIL_SETTINGS')
export const setIsLoading = action('SET_IS_LOADING')
export const setIsRefreshingResults = action('SET_IS_REFRESHING_RESULTS')
export const setSearchData = action('SET_SEARCH_DATA')
export const setCompareSearchData = action('SET_COMPARE_SEARCH_DATA')
export const setSearchId = action('SET_SEARCH_ID')
export const refreshResults = action('REFRESH_RESULTS')
export const showEditSearchSettingsModal = action('SHOW_EDIT_SEARCH_SETTINGS_MODAL')
export const hideEditSearchSettingsModal = action('HIDE_EDIT_SEARCH_SETTINGS_MODAL')
export const showEditSearchFiltersModal = action('SHOW_EDIT_SEARCH_FILTERS_MODAL')
export const hideEditSearchFiltersModal = action('HIDE_EDIT_SEARCH_FILTERS_MODAL')
export const setQuery = action('SET_QUERY')
export const hideFeed = action('HIDE_FEED')
export const unhideFeed = action('UNHIDE_FEED')
export const setQueryComponentState = action('SET_QUERY_COMPONENT_STATE')
export const setSaveData = action('SET_SAVE_DATA')
export const resetSaveData = action('RESET_SAVE_DATA')
export const updateSearch = action('UPDATE_SEARCH')
export const fetchSearchExcludedFeeds = action('FETCH_SEARCH_EXCLUDED_FEEDS')
export const setLastPage = action('SET_LAST_PAGE')
export const fetchCachedLargerTimeFrames = action('FETCH_CACHED_LARGER_TIME_FRAMES')
export const setCachedLargerTimeFrames = action('SET_CACHED_LARGER_TIME_FRAMES')

// Filters
export const addFilters = action('ADD_FILTERS')
export const addTermFrequencyFilters = action('ADD_TERM_FREQUENCY_FILTERS')
export const removeFilters = action('REMOVE_FILTERS')
export const consolidateFilters = action('CONSOLIDATE_FILTERS')
export const setAppliedFilterKey = action('SET_APPLIED_FILTER_KEY')
export const setTermFrequencyKey = action('SET_TERM_FREQUENCY_KEY')
export const resetUnsavedFilters = action('RESET_UNSAVED_FILTERS')

// Saving searches
export const saveSearch = action('SAVE_SEARCH')
export const setIsSaving = action('SET_IS_SAVING')
export const showNewSearchModal = action('SHOW_NEW_SEARCH_MODAL')
export const hideNewSearchModal = action('HIDE_NEW_SEARCH_MODAL')
export const saveNewSearch = action('SAVE_NEW_SEARCH')
export const showSaveIntoFirmLibraryModal = action('SHOW_SAVE_INTO_FIRM_LIBRARY_MODAL')
export const hideSaveIntoFirmLibraryModal = action('HIDE_SAVE_INTO_FIRM_LIBRARY_MODAL')
export const removeExcludedFeedsFromSearch = action('REMOVE_EXCLUDED_FEEDS_FROM_SEARCH')
export const fetchAssignees = action('FETCH_ASSIGNEES')
export const fetchAssigneesComplete = action('FETCH_ASSIGNEES_COMPLETE')
export const fetchUsers = action('FETCH_USERS')
export const fetchUsersComplete = action('FETCH_USERS_COMPLETE')
export const setUserIds = action('SET_USER_IDS')
export const setSelectedAssigneeIdsBySection = action('SET_SELECTED_ASSIGNEE_IDS_BY_SECTION')

// Feeds and trusted sources
export const setIsChangingTrustedState = action('SET_IS_CHANGING_TRUSTED_STATE')
export const setFeedId = action('SET_FEED_ID')
export const addToTrustedSources = action('ADD_TO_TRUSTED_SOURCES')
export const removeFromTrustedSources = action('REMOVE_FROM_TRUSTED_SOURCES')

// Search options
export const setRelevancyLevel = action('SET_RELEVANCY_LEVEL')
export const setHasFetchedRelevanceIds = action('SET_HAS_FETCHED_RELEVANCE_IDS')
export const setGroupingLevel = action('SET_GROUPING_LEVEL')
export const setLanguageFilters = action('SET_LANGUAGE_FILTERS')
export const setSelectedLanguageIds = action('SET_SELECTED_LANGUAGE_IDS')
export const setSortOption = action('SET_SORT_OPTION')
export const setCompareId = action('SET_COMPARE_ID')
export const setCompareCountIsGreater = action('SET_COMPARE_COUNT_IS_GREATER')
export const setInsightsArticlesFirst = action('SET_INSIGHTS_ARTICLES_FIRST')
export const setTimeFrame = action('SET_TIME_FRAME')
export const setActivePublicationTab = action('SET_ACTIVE_PUBLICATION_TAB')
export const setActivePublicationType = action('SET_ACTIVE_PUBLICATION_TYPE')
export const setUpcomingEvents = action('SET_UPCOMING_EVENTS')
export const setResultsPerPage = action('SET_RESULTS_PER_PAGE')

// Selection
export const selectDocumentId = action('SELECT_DOCUMENT_ID')
export const deselectDocumentId = action('DESELECT_DOCUMENT_ID')
export const selectAllTopLevelDocuments = action('SELECT_ALL_TOP_LEVEL_DOCUMENTS')
export const selectAllDocuments = action('SELECT_ALL_DOCUMENTS')
export const deselectAllDocuments = action('DESELECT_ALL_DOCUMENTS')

// Export
export const exportPdf = action('EXPORT_PDF')
export const exportDocx = action('EXPORT_DOCX')
export const exportExcel = action('EXPORT_EXCEL')
export const exportEmail = action('EXPORT_EMAIL')

// Filters bar
export const setIsFiltersBarOpen = action('SET_IS_FILTERS_BAR_OPEN')
export const setCollapsedFilterSections = action('SET_COLLAPSED_FILTER_SECTIONS')
export const toggleFilterSectionDisplay = action('TOGGLE_FILTER_SECTION_DISPLAY')

// Trending
export const setIsTrendingOpen = action('SET_IS_TRENDING_OPEN')
export const setTrendingData = action('SET_TRENDING_DATA')

// Flagging
export const showFlagModal = action('SHOW_FLAG_MODAL')
export const hideFlagModal = action('HIDE_FLAG_MODAL')

// Deleting
export const showDeleteModal = action('SHOW_DELETE_MODAL')
export const hideDeleteModal = action('HIDE_DELETE_MODAL')
export const deleteSelectedDocuments = action('DELETE_SELECTED_DOCUMENTS')

// Infinite scroll
export const loadMoreResults = action('LOAD_MORE_RESULTS')
export const setIsLoadingNextPage = action('SET_IS_LOADING_NEXT_PAGE')

// Meta
export const toggleShowQueryComponents = action('TOGGLE_SHOW_QUERY_COMPONENTS')
export const toggleShowFullQuery = action('TOGGLE_SHOW_FULL_QUERY')

// Search Settings
export const saveSearchSettings = action("SAVE_SEARCH_SETTINGS")
export const saveSearchSettingsComplete = action("SAVE_SEARCH_SETTINGS_COMPLETE")

// Charts & Trends
export const setChartsAndTrendsData = action('SET_CHARTS_AND_TRENDS_DATA')

// Dun & Bradstreet
export const showDnbModal = action('SHOW_DNB_MODAL')
export const hideDnbModal = action('HIDE_DNB_MODAL')

// ESG
export const setEsgData = action('SET_ESG_DATA')
export const setEsgActiveFilterApplied = action('SET_ESG_ACTIVE_FILTER_APPLIED')
export const updateEsgDocuments = action('UPDATE_ESG_DOCUMENTS')

