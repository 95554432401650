import { handleActions } from 'redux-actions'
import * as dnbActions from './dandb-search-actions'

const getInitialState = () => ({
  isLoading: false,
  data: [],
  parms: [],
})

export default handleActions({
  [dnbActions.setIsLoading]: (state, action) => ({
    ...state,
    isLoading: true,
  }),

  [dnbActions.saveParms]: (state, action) => {
    return {
      ...state,
      parms: action.payload,
    }
  },

  [dnbActions.searchDnbData.success]: (state, action) => ({
    ...state,
    isLoading: false,
    data: action.payload.response.body,
  }),

  [dnbActions.searchDnbData.failure]: (state, action) => ({
    ...state,
    isLoading: false,
  }),

  [dnbActions.countryChange]: (state, action) => {
    return {
      ...state,
      editCountryID: action.payload.country_id,
    }
  },

  [dnbActions.addInitialData]: (state, action) => ({
    ...state,
    locations: action.payload.locations,
  }),}, getInitialState())
