import React from 'react'

import {changeCaseObject} from 'app/utils'
import store from 'app/store'
import {Provider, useDispatch, useSelector} from 'react-redux'
import {render} from 'react-dom'
import * as actions from 'app/email-notifications/email-notifications-actions'
import * as constants from 'app/email-notifications/email-notifications-constants'
import SettingsBulkUpdate from '../settings-bulk-update'
import SendSampleEmail from '../send-sample-email'
import {EsgFrequencySettings} from '../esg-frequency-settings/'
import PillarsModal from "app/email-notifications/PillarsModal"

/**
 * Wrapper for the ESG email settings tab on the notifications settings page.
 */
export default function EsgEmailSettings() {
  const dispatch = useDispatch()

  const notificationSettings = useSelector(
    state => state.emailNotifications.esgSettings,
  )
  const noticeConfigs = useSelector(
    state => state.emailNotifications.esgNoticeConfigs,
  )

  const factorsConfig = useSelector(state => state.emailNotifications.factorsModalConfig)
  const editUserId = useSelector(state => state.emailNotifications.emailDeliveryData.editUserId)

  const saveEsgNoticeConfigs = (value) => dispatch(actions.api.saveEsgNoticeConfigs.request(value))
  const changeFactorsModalConfig = (value) => dispatch(actions.changeFactorsModalConfig(value))
  const changeShowFactorsModal = (value) => dispatch(actions.changeEsgShowFactorsModal(value))
  const changeShowBulkUpdateModal = (value) => dispatch(actions.changeShowBulkUpdateModal(value))
  const changeFrequencyBulkUpdateConfigs = (value) => dispatch(actions.changeFrequencyBulkUpdateConfigs(value))
  const factorsModal = noticeConfigs && factorsConfig
    ? <PillarsModal editUserId={editUserId}
                  factorsConfig={factorsConfig}
                  modal="ESG"
                  notificationType={notificationSettings.notificationType}
                  availableFactors={noticeConfigs.availableFactors}
                  showFactorsModal={noticeConfigs.showFactorsModal}
                  changeFactorsFunc={changeFactorsModalConfig}
                  changeShowModalFunc={changeShowFactorsModal}
                  saveConfigsFunc={saveEsgNoticeConfigs}
                  changeShowBulkUpdateFunc={changeShowBulkUpdateModal}
                  changeBulkUpdateConfigsFunc={changeFrequencyBulkUpdateConfigs}
  /> : null

  return (
    <div className={'signals-email-settings-container'}>
      <SettingsBulkUpdate
        notificationType={notificationSettings.notificationType}
      />
      <SendSampleEmail
        notificationType={notificationSettings.notificationType}
      />
      {factorsModal}

      <div>
        <div className={'help-text'}>
          The ESG Email Alert contains only articles that have been flagged by
          Insights. Your ESG emails are grouped by how often you wish to
          receive an email - Daily or Weekly. You can
          change the frequency per-company or per-industry by interacting with
          the frequency dropdown selector in the frequency column.
        </div>

        <div className={'jump-links-container'}>
          <div className={'jump-links'}>
            Jump To:
            <a className={'frequency-jump-link'} href={`#esg-${notificationSettings.frequencies[0]}`}>
              Daily
            </a>
            <span className={'link-divider'}>|</span>
            <a className={'frequency-jump-link'} href={'#esg-weekly'}>
              Weekly
            </a>
          </div>

          <div className={'grouped-by-freq'}>Grouped By Frequency</div>
        </div>
        {getFrequencySection()}
      </div>
    </div>
  )

  function getFrequencySection() {
    if (!notificationSettings) return null
    return notificationSettings.frequencies
    .filter(frequency => frequency !== constants.NONE_FREQ)
    .map((freq, freqIdx) => (
      <EsgFrequencySettings
        key={freqIdx}
        noticeFrequency={freq}
        notificationSettings={notificationSettings}
        noticeConfigs={noticeConfigs}
      />
    ))
  }
}

export function init(data) {
  const emailAlertSettings = changeCaseObject.camelCase(data)
  const element = document.getElementById('django-esg-email-settings-container')
  if (!element) return

  store.dispatch(actions.esgEmailSettingsInit(emailAlertSettings))

  const component = (
    <Provider store={store}>
      <EsgEmailSettings />
    </Provider>
  )

  render(component, element)
}
