import * as R from 'ramda'

import { STATE_PROP_NAME } from './django-interaction-bar-constants'

export const getDjangoInteractionBar = R.path([STATE_PROP_NAME])
export const getArePromotedAndExcludedFeedsFetched = state =>
  getDjangoInteractionBar(state).arePromotedAndExcludedFeedsFetched
export const getInitializedDocumentIds = state =>
  getDjangoInteractionBar(state).initializedDocumentIds
export const getInitializedFeedIds = state => getDjangoInteractionBar(state).initializedFeedIds
