import React, { Component } from 'react'
import PropTypes from 'prop-types'

import SampleModal from './SampleModal'


export default class Searches extends Component {
  static propTypes = {
    boosters: PropTypes.array.isRequired,
    selectedBoosterIds: PropTypes.array.isRequired,
    onBoosterSelectionChange: PropTypes.func.isRequired,
    relevancySetting: PropTypes.string.isRequired,
    onRelevancyChange: PropTypes.func.isRequired,
  }

  state = {
    currentModalBoosterId: null,
  }

  render() {
    const boosterOptions =
      this.props.boosters.map((b, idx) => {
        return (
          <div key={idx} className="builder-content-row">
            <div className="checkbox-container">
              <input
                type="checkbox"
                checked={this.props.selectedBoosterIds.includes(b.id)}
                onChange={(evt) => this.props.onBoosterSelectionChange(b.id, evt.target.checked)}
              />
            </div>

            <div className="description-container">
              <label>{b.name}</label>
              <p>
                <span>{b.description}</span>
                <a
                  onClick={(evt) => this.showModal(evt, b.id)}
                >View Sample</a>
              </p>
            </div>
          </div>
        )
      })

    const sampleModal =
      this.state.currentModalBoosterId === null ?
        null
        : <SampleModal
            isOpen={true}
            onClose={() => this.hideModal()}
            content={this.props.boosters.find(b => b.id === this.state.currentModalBoosterId).content}
          />

    return (
      <div className="builder-content-body-container">
        {sampleModal}

        <div className="builder-content-body">
          <p>Content boosters reorder the results to promote articles that match higher. They do not remove any
            articles. Below are some of Manzama's most popular boosters.</p>

          <p><i>If any of the topic areas below are not of interest to you please deselect those boosters.</i></p>

          {boosterOptions}

          <hr/>

          <h3>Relevance Filter</h3>

          <p>Use the relevance filter to set the desired level of relevance when viewing search results,
            varying from broad coverage to more varied coverage.</p>

          <div className="builder-content-row">
            <div className="checkbox-container">
              <input
                type="radio"
                checked={this.props.relevancySetting === 'default'}
                onChange={() => this.handleRelevancyChange('default')}
              />
            </div>

            <div className="description-container">
              <label>Low</label>
              <p>Includes all results matching your search criteria.</p>
            </div>
          </div>

          <div className="builder-content-row">
            <div className="checkbox-container">
              <input
                type="radio"
                checked={this.props.relevancySetting === 'intro'}
                onChange={() => this.handleRelevancyChange('intro')}
              />
            </div>

            <div className="description-container">
              <label>Medium</label>
              <p>Narrows the broad parameters of the search, increasing relevance and reducing the displayed
                results</p>
            </div>
          </div>

          <div className="builder-content-row">
            <div className="checkbox-container">
              <input
                type="radio"
                checked={this.props.relevancySetting === 'headline'}
                onChange={() => this.handleRelevancyChange('headline')}
              />
            </div>

            <div className="description-container">
              <label>High</label>
              <p>Further narrows the results, showing only the most relevant articles matching your query.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleRelevancyChange(setting) {
    this.props.onRelevancyChange(setting)
  }

  showModal(evt, id) {
    evt.preventDefault()
    this.setState({currentModalBoosterId: id})
  }

  hideModal() {
    this.setState({currentModalBoosterId: null})
  }
}
