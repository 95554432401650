import React from 'react'

import * as styles from './HealthScoreContainer.less'

function HealthScoreContainer({ children }) {
  return (
    <div className={styles.healthScoreContainer}>
      {React.Children.map(children, React.cloneElement)}
    </div>
  )
}

function LeftColumn({ children }) {
  return (
    <div className={styles.left}>
      {React.Children.map(children, React.cloneElement)}
    </div>
  )
}

function RightColumn({ children }) {
  return (
    <div className={styles.right}>
      {React.Children.map(children, React.cloneElement)}
    </div>
  )
}

export default HealthScoreContainer
export {
  LeftColumn,
  RightColumn,
}
