import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import * as esgActions from './esg-actions'
import * as esgApi from './esg-api'
import * as esgSelectors from './esg-selectors'
import { filterEsgEnabledCompaniesFromSavedSearches, transformEsgDataToHealthScoreData } from './utils'
import * as globalSelectors from '../global/global-selectors'
import * as entitiesSelectors from '../entities/entities-selectors'
import { actions as notificationActions } from 'app/global/notifications'
import Orm from '../framework/Orm'
import { SavedSearch } from '../models'
import { NOTIFICATION_TYPES } from '../global/notifications/notifications-constants'
import * as routing from 'app/global/routing'
import {addProfileSearchIds, removeProfileSearches} from 'app/actions'
import * as notifications from 'app/global/notifications'
import * as entitiesActions from 'app/entities/entities-actions'

function* hideNotification() {
  yield delay(5000)
  yield put(notificationActions.hideNotification())
}

function* handleInit() {
  yield call(handleGetSavedSearches)
  const savedSearches = yield select(esgSelectors.getSavedSearches)
  const company = yield select(esgSelectors.getCompany)

  if (company) {
    if (filterEsgEnabledCompaniesFromSavedSearches(savedSearches).find(search => search.id == company)) {
      yield put(esgActions.getCompany(company))
    }
  }
  yield put(esgActions.getSidebarContent())
  yield call(handleGetDefaultIndustry)
  yield put(esgActions.getSectors())
}

function* handleGetSidebarContent(action) {
  try {
    const response = yield call(esgApi.getEsgSidebarContent)
    if (response.body.length > 0) {
      yield put(esgActions.setSidebarContent(response.body))
    }
  } catch (e) {
    yield put(esgActions.setSidebarContent([]))
  }
}

function* handleGetSectors(action) {
  yield put(esgActions.setSectors({ isLoading: true }))

  try {
    const response = yield call(esgApi.getSectors)
    yield put(esgActions.setSectors(response.body))
    const defaultIndustry = yield select(esgSelectors.getDefaultIndustry)
    const route = yield select(routing.selectors.getRoutingState)

    if (response.body.length > 0) {
      const queryParameterFilter = (sector) => {
        return sector.sectorId == route.query?.industry
      }
      const technology = (sector) => sector.sectorName == 'Technology' && sector.sectorId == 1863
      const defaultIndustryFilter = (sector) => {
        const TECHNOLOGY_ID = 1863
        return sector.sectorId == (defaultIndustry ? defaultIndustry : TECHNOLOGY_ID)
      }
      let initiallySelectedSector = response.body.find(queryParameterFilter)
      if (!initiallySelectedSector) {
        initiallySelectedSector = response.body.find(defaultIndustryFilter)
      }
      if (!initiallySelectedSector) {
        // In case the default industry doesn't exist
        initiallySelectedSector = response.body.find(technology)
      }
      yield put(esgActions.setIndustry(initiallySelectedSector))
      yield put(esgActions.getIndustryEsgData({ id: initiallySelectedSector.sectorId }))
    }
  } catch (e) {
    yield put(notificationActions.showNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: 'There was an error getting sectors.',
    }))
  }
}

function* handleGetCompanyEsgData(action) {
  try {
    yield put(esgActions.setIsLoading(true))
    yield put(esgActions.setCompanyEsgData({ isLoading: true }))
    const esgCompany = yield call(esgApi.getCompanyData, action.payload.id)

    const transformedData = transformEsgDataToHealthScoreData(
      esgCompany.body.healthScore,
      esgCompany.body.companyTopicScores
    )

    yield call(handleGetCompanyTerms, { payload: { id: esgCompany.body.companyId } })
    const esgData = { ...esgCompany.body, ...transformedData }
    yield put(esgActions.setCompanyEsgData(esgData))
    yield put(esgActions.setIsLoading(false))
    yield put(esgActions.setCompanyEsgData({ isLoading: false, isError: false }))
    yield put(esgActions.fetchCompanyStories())
  } catch (error) {
    const esgDataError = {
      isLoading: false,
      companyTopicStories: [],
      healthScore: 0,
      companyScores: {
        companyName: null,
      },
      factors: {},
      subfactors: {},
    }
    // Show empty data
    yield put(esgActions.setCompanyEsgData(esgDataError))
    yield put(esgActions.setIsLoading(false))
    yield put(esgActions.setCompanyEsgData({ isLoading: false, isError: true}))
    yield put(esgActions.fetchCompanyStoriesError())
    yield put(notificationActions.showNotification({
      type: 'error',
      message: 'There was an error processing your request. Please try again.',
    }))
  }
}

function* handleGetIndustryEsgData(action) {
  try {
    yield put(esgActions.setIsLoading(true))
    yield put(esgActions.setIndustryEsgData({ isLoading: true }))

    const esgIndustry = yield call(esgApi.getEsgIndustryHealthScores, action.payload.id)
    const transformedData = transformEsgDataToHealthScoreData(
      esgIndustry ? esgIndustry.body.industryOverallHealthScore : 0,
      esgIndustry ? esgIndustry.body.sectorTopicScores : []
    )
    yield call(handleGetSectorTerms, action)

    const esgData = { ...esgIndustry.body, ...transformedData }
    yield put(esgActions.setIndustryEsgData(esgData))
    yield put(esgActions.setIsLoading(false))
    yield put(esgActions.setIndustryEsgData({ isLoading: false }))
    yield put(esgActions.fetchIndustryStories())
  } catch (error) {
    const esgDataError = {
      isLoading: false,
      industryPillarStories: [],
      industryScores: {},
      healthScore: 0,
      factors: {},
      subfactors: {},
    }
    // Show empty data
    yield put(esgActions.setIndustryEsgData(esgDataError))
    yield put(esgActions.setIsLoading(false))
    yield put(esgActions.setIndustryEsgData({ isLoading: false }))
    yield put(notificationActions.showNotification({
      type: 'error',
      message: 'There was an error processing your request. Please try again.',
    }))
  }
}

function* handleGetSavedSearches() {
  const profileSearchIds = yield select(globalSelectors.getProfileSearchIds)
  const entities = yield select(entitiesSelectors.getEntities)
  const orm = Orm.withEntities(entities)
  const searches = yield call(() => orm.getByIds(SavedSearch, profileSearchIds)
    .sort((s1, s2) => {
      const name1 = s1.name.trim()
      const name2 = s2.name.trim()
      return name1.localeCompare(name2)
    })
    // Let's add values and labels to them, so they can be used in the dropdowns
    .map(search => ({ ...search, label: search.name, value: String(search.query.split("ss:")[1]) })))
  yield put(esgActions.setSavedSearches(searches))
  yield put(esgActions.setIsLoading(false))
}

function* handleGetCompany(action) {
  const companyInStore = yield select(esgSelectors.getCompany)
  if (companyInStore === action.payload) {
    return
  }
  yield put(esgActions.setCompany(action.payload))
  if (action.payload) {
    const companies = yield select(esgSelectors.getSavedSearches)
    const allcompanies = yield select(esgSelectors.getAllEsgCompaniesOnSearch)
    let currentCompany = ''
    currentCompany = yield call(() => allcompanies.find(element => String(element.value) === action.payload))
    if (currentCompany === undefined) {
      currentCompany = yield call(() => companies.find(element => String(element.value) === action.payload))
    }
    yield put(esgActions.getCompanyEsgData({ id: currentCompany.id }))
  }
}

function* handleGetIndustry(action) {
  const industryInStore = yield select(esgSelectors.getIndustry)
  const sectors = yield select(esgSelectors.getSectors)

  if (industryInStore.sectorId == action.payload) {
    return
  }

  const industry = sectors.find(sector => sector.sectorId == action.payload)
  if (industry) {
    yield put(esgActions.setIndustry(industry))
    if (action.payload) {
      yield put(esgActions.getIndustryEsgData({ id: action.payload }))
    }
  }
}

function* handleGetSavedCompanyData(action) {
  const savedSearch = action.payload
  let currentSavedData = yield select(esgSelectors.getEsgCompanyTable)
  if (Object.keys(currentSavedData).includes(savedSearch.id)) {
    // We have already fetched this company, ignore the request.
    return
  }

  // Create the key to signal that we're already working on this request.
  yield put(esgActions.setEsgCompanyTableData({ ...currentSavedData, [savedSearch.id]: null }))

  try {
    const response = yield call(esgApi.getCompanyData, savedSearch.id)
    let esgData = response.body
    esgData.companyScores = {
      companyTopicScores: [],
      healthScore: esgData.healthScore,
      companyPillarScores: [],
    }

    const transformedData = transformEsgDataToHealthScoreData(esgData.healthScore, esgData.companyTopicScores)
    esgData = { ...esgData, ...transformedData }

    currentSavedData = yield select(esgSelectors.getEsgCompanyTable)
    yield put(esgActions.setEsgCompanyTableData({ ...currentSavedData, [savedSearch.id]: esgData }))
  } catch (error) {
    const esgDataError = {
      isLoading: false,
      industryPillarStories: [],
      industryScores: {},
      healthScore: 0,
      factors: {},
      subfactors: {},
    }
    currentSavedData = yield select(esgSelectors.getEsgCompanyTable)
    yield put(esgActions.setEsgCompanyTableData({ ...currentSavedData, [savedSearch.id]: esgDataError }))
    if (error.status !== 404) {
      yield put(notificationActions.showNotification({
        type: 'error',
        message: `There was an error while requesting ESG data of ${savedSearch.displayName}.`,
      }))
    }
  }
}

function* handleGetSectorTerms(action) {
  try {
    const response = yield call(esgApi.getEsgSectorTerms, action.payload.id)
    yield put(esgActions.setIndustryTerms(response.body.terms))
  } catch (e) {
  }
}

function* getIndustrySectorTermsData(){
  try {
    const industryId = yield select(esgSelectors.getIndustryEsgData)
    const pillarId = yield select(esgSelectors.getIndustrySelectedPillar)
    const topicId = yield select(esgSelectors.getIndustrySelectedTopic)
    const response = yield call(esgApi.getEsgSectorTerms, industryId.sectorId, pillarId, topicId)
    yield put(esgActions.setIndustrySelectedTerm(null))
    yield put(esgActions.setIndustryTerms(response.body.terms))
  } catch (e) {
  }
}

function* handleGetCompanyTerms(action) {
  try {
    const response = yield call(esgApi.getEsgCompanyTerms, action.payload.id)
    yield put(esgActions.setCompanyTerms(response.body.terms))
  } catch (e) {
  }
}

function* getCompanyTermsData(){
  try {
    const companyId = yield select(esgSelectors.getCompanyEsgData)
    const pillarId = yield select(esgSelectors.getCompanySelectedPillar)
    const topicId = yield select(esgSelectors.getCompanySelectedTopic)
    const response = yield call(esgApi.getEsgCompanyTerms, companyId.companyId, pillarId, topicId)
    yield put(esgActions.setCompanySelectedTerm(null))
    yield put(esgActions.setCompanyTerms(response.body.terms))
  } catch (e) {
  }
}

function* handleGetDefaultIndustry() {
  try {
    const response = yield call(esgApi.getDefaultIndustry)
    yield put(esgActions.setDefaultIndustry(response.body.defaultIndustry))
  } catch (e) {
    yield put(notificationActions.showNotification({
      type: 'error',
      message: 'Failed to fetch default industry.',
    }))
  }
}

function* handleUpdateDefaultIndustry(action) {
  try {
    const response = yield call(esgApi.setDefaultIndustry, action.payload)
    yield put(esgActions.setDefaultIndustry(response.body.defaultIndustry))
  } catch (e) {
    yield put(notificationActions.showNotification({
      type: 'error',
      message: 'Failed to update default industry.',
    }))
  }
}

function* handleGetCompanyStories(action) {
  try {
    const companyId = yield select(esgSelectors.getCompanyEsgData)
    const termId = (yield select(esgSelectors.getCompanySelectedTerm))?.termId
    const pillarId = yield select(esgSelectors.getCompanySelectedPillar)
    const topicId = yield select(esgSelectors.getCompanySelectedTopic)
    const order = yield select(esgSelectors.getCompanyStoryOrder)
    const offset = ((yield select(esgSelectors.getCompanyStoryPage)) - 1) * 10
    const response = yield call(esgApi.getEsgCompanyStories, companyId.companyId, pillarId, topicId, termId, order, offset)
    yield put(esgActions.fetchCompanyStoriesSuccess(response.body))
  } catch (e) {

  }
}

function* handleGetIndustryStories(action) {
  try {
    const industryId = yield select(esgSelectors.getIndustryEsgData)
    const termId = (yield select(esgSelectors.getIndustrySelectedTerm))?.termId
    const pillarId = yield select(esgSelectors.getIndustrySelectedPillar)
    const topicId = yield select(esgSelectors.getIndustrySelectedTopic)
    const order = yield select(esgSelectors.getIndustryStoryOrder)
    const offset = ((yield select(esgSelectors.getIndustryStoryPage)) - 1) * 10
    const response = yield call(esgApi.getEsgSectorStories, industryId.sectorId, pillarId, topicId, termId, order, offset)
    yield put(esgActions.fetchIndustryStoriesSuccess(response.body))
  } catch (e) {

  }
}

function* fireFetchCompanyStories(action) {
  yield put(esgActions.fetchCompanyStories())
}

function* fireFetchIndustryStories(action) {
  yield put(esgActions.fetchIndustryStories())
}

function* handleGetAllEsgCompaniesOnSearch() {
  let searchValue = ''
  searchValue = yield select(esgSelectors.getEsgCompaniesSearchString)
  try {
    if (searchValue && searchValue.length > 0) {
      searchValue =  `*${searchValue}*`
      const response = yield call(esgApi.getAllEsgCompaniesDataOnSearch, searchValue)
      yield put(esgActions.setAllEsgCompaniesDataOnSearch(response.body))
    } else {
      yield put(esgActions.setAllEsgCompaniesDataOnSearch([]))
    }

  } catch {
    yield put(esgActions.setAllEsgCompaniesDataOnSearch([{label:'No matching records found',value:"noData"}]))
  }
}

function* handleDeleteSearches(action) {
  try {
    yield esgApi.deleteSearches(action.payload)
  } catch (error) {
    yield* handleSagaError(error)
    yield put(esgActions.setIsLoading(false))
    return
  }
  const searches = action.payload.map(id => {
    return ({
      id,
    })
  })
  // `removeProfileSearches` also removes from entities
  yield put(removeProfileSearches(searches))
  yield call(handleGetSavedSearches)
  yield put(esgActions.setIsLoading(false))
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: `${action.payload.length === 1 ? 'Search' : 'Searches'} deleted successfully.`,
    })
  )

}

function* handleGetAllCompanies(action) {
  const { offset, companyName } = action.payload
  let company_name = '*'
  if (companyName && companyName.length > 0) {
    company_name = `*${companyName}*`
  }
  try {
    const response = yield call(esgApi.getAllCompaniesData, offset, company_name)
    if (response.body.companies.length > 0) {
      const transformedData = response.body.companies.map((company) => {
        const addHealthFactors = transformEsgDataToHealthScoreData(company.healthScore, company.companyTopicScores)
        return { ...company, ...addHealthFactors }
      })

      yield put(esgActions.setAllEsgCompaniesData(transformedData))
      yield put(esgActions.totalEsgCompaniesData(response.body.count))
    }
  } catch (e) {
    yield put(esgActions.setAllEsgCompaniesData([]))
  }
}

function* handleCreateSearches(action) {
  const userId = yield select(state => state.currentUser)
  let response = null
  try {
    response = yield esgApi.createSearches({ ...action.payload, userId })
  } catch (error) {
    yield* handleSagaError(error)
    yield put(esgActions.setIsLoading(false))
    return
  }
  const searches = {}
  const newSearchIds = []
  Object.values(response).forEach(ss => {
    ss.isInsightsEnabled = true
  })
  Object.values(response).forEach(ss => {
    searches[ss.id] = {...ss}
    newSearchIds.push(ss.id)
  })
  const entities = {
    searches,
  }
  yield put(entitiesActions.update(entities))
  yield put(addProfileSearchIds(newSearchIds))
  yield call(handleGetSavedSearches)
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: `${action.payload.searchIds.length === 1 ? 'Company' : 'Companies'} added successfully.`,
    })
  )
  yield put(esgActions.setIsLoading(false))
}

export default function* () {
  yield all([
    takeLatest(
      esgActions.init,
      handleInit,
    ),
    takeLatest(
      esgActions.getCompanyEsgData,
      handleGetCompanyEsgData,
    ),
    takeLatest(
      esgActions.getIndustryEsgData,
      handleGetIndustryEsgData,
    ),
    takeLatest(
      esgActions.getSavedSearches,
      handleGetSavedSearches,
    ),
    takeLatest(
      esgActions.getCompany,
      handleGetCompany,
    ),
    takeLatest(
      esgActions.getIndustry,
      handleGetIndustry,
    ),
    takeEvery(
      esgActions.getEsgCompanyTableData,
      handleGetSavedCompanyData,
    ),
    takeLatest(
      esgActions.getSectors,
      handleGetSectors,
    ),
    takeLatest(
      esgActions.getSidebarContent,
      handleGetSidebarContent,
    ),
    takeLatest(
      esgActions.updateDefaultIndustry,
      handleUpdateDefaultIndustry,
    ),
    takeLatest(
      esgActions.getDefaultIndustry,
      handleGetDefaultIndustry,
    ),
    takeLatest(
      esgActions.fetchCompanyStories,
      handleGetCompanyStories,
    ),
    takeLatest(
      [esgActions.setCompanySelectedTerm, esgActions.setCompanyStorySortFilter,
      esgActions.setCompanySelectedPillar, esgActions.setCompanySelectedTopic, esgActions.setCompanyStoryPage],
      fireFetchCompanyStories,
    ),
    takeLatest(
      esgActions.fetchIndustryStories,
      handleGetIndustryStories,
    ),
    takeLatest(
      [esgActions.setIndustrySelectedTerm, esgActions.setIndustryStorySortFilter,
      esgActions.setIndustrySelectedPillar, esgActions.setIndustrySelectedTopic, esgActions.setIndustryStoryPage],
      fireFetchIndustryStories,
    ),
    takeLatest(
      [esgActions.fetchIndustryFilterTerms, esgActions.setIndustrySelectedTopic, esgActions.setIndustrySelectedPillar],
      getIndustrySectorTermsData,
    ),
    takeLatest(
      [esgActions.fetchCompanyFilterTerms, esgActions.setCompanySelectedTopic, esgActions.setCompanySelectedPillar],
      getCompanyTermsData,
    ),
    takeLatest(
      esgActions.getAllEsgCompaniesDataOnSearch,
      handleGetAllEsgCompaniesOnSearch,
    ),
    takeLatest(
      esgActions.deleteSearches,
      handleDeleteSearches,
    ),
    takeLatest(
      esgActions.getAllEsgCompaniesData,
      handleGetAllCompanies,
    ),
    takeLatest(
      esgActions.createSearches,
      handleCreateSearches,
    ),
  ])
}
