import React from 'react';

import * as styles from './styles.less';

function LoadingHeadline() {
  return (
    <>
      <div className={styles.headline} />
    </>
  )
}

function LoadingSingleLine() {
  return (
    <>
      <div className={styles.line} />
    </>
  )
}

export {LoadingHeadline, LoadingSingleLine};
