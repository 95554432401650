import {args, gql} from "app/graphql"
import {apiCall, graphqlQuery} from "app/api"


export function fetchComparisonSearchGroups({category}) {
  return graphqlQuery(
    gql`
      query {
        comparisonGroups: comparisonSearchGroups(${args({category})}) {
          id
          appliedFilterKey
          filterItems {
            label
            isFreeText
            value
            searchId
            query
          }
          title
          searchIds
          duration
          displayChartTypes
          shareOfVoiceChartType
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchCachedLargerTimeFrames({category}) {
  return graphqlQuery(
    gql`
      query {
        data: largerTimeFramesCachedSearches(${args({category})}) {
          search_id
          time_frames
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function createComparisonSearchGroup({
  category,
  title,
  searchIds,
  appliedFilterKey,
  duration,
  displayChartTypes,
  shareOfVoiceChartType,
}) {
  return graphqlQuery(
    gql`
      mutation {
        createComparisonSearchGroup(${args({
          category,
          title,
          searchIds,
          appliedFilterKey,
          duration,
          displayChartTypes,
          shareOfVoiceChartType,
        })}
        ) {
          id
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function saveComparisonSearchGroup({
  comparisonGroupId,
  category,
  title,
  searchIds,
  appliedFilterKey,
  duration,
  displayChartTypes,
  shareOfVoiceChartType,
}) {
  return graphqlQuery(
    gql`
      mutation {
        saveComparisonSearchGroup(${args({
          comparisonGroupId,
          category,
          title,
          searchIds,
          appliedFilterKey,
          duration,
          displayChartTypes,
          shareOfVoiceChartType,
        })}
        ) {
          id
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchChartData(data) {
  const {type, category} = data
  return apiCall({
    url: `/chart/${type}/${category}/`,
    method: 'GET',
    data,
  })
}

export function deleteComparisonSearchGroup({comparisonGroupId}) {
  return graphqlQuery(
    gql`
      mutation {
        deleteComparisonSearchGroup(${args({comparisonGroupId})}
        ) {
          success
        }
      }
    `
  ).then(({body: {data}}) => data)
}
