import PropTypes from 'prop-types'
import React from 'react'

import styles from './DashboardDocumentList.less'
import ResultDocument from "app/reusable/ResultDocument"


function DashboardDocumentList({
  documents,
  onFlaggingStateChange,
  onPromoteStateChange,
  onExclude,
}) {

  const documentComponents = (docs) => {
    const documents = docs.map((doc, idx) => {
        if (doc) {
          return <ResultDocument
            document={doc}
            onFlaggingStateChange={onFlaggingStateChange}
            onPromoteStateChange={onPromoteStateChange}
            onExclude={onExclude}
            isDashboard={true}
            key={`document-${idx}-${doc.id}`}
          />
        }
      }
    )
    return(documents)
  }

  return (
    <div className={styles.documentList}>
      <div className={styles.documentListContainer}>
        { documents.length > 0
          ? documentComponents(documents)
          : null
        }
      </div>
    </div>
  )
}
DashboardDocumentList.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  onFlaggingStateChange: PropTypes.func.isRequired,
  onPromoteStateChange: PropTypes.func.isRequired,
  onExclude: PropTypes.func.isRequired,
}
export default DashboardDocumentList
