import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from './email-notifications-actions'
import * as constants from './email-notifications-constants'
import InputBlock from 'app/common/InputBlock'
import './SendZeroResults.less'
import PropTypes from 'prop-types'


@connect(
  state => ({
    baseSettings: state.emailNotifications.baseSettings,
    insightsSettings: state.emailNotifications.insightsSettings,
    esgSettings: state.emailNotifications.esgSettings,
    editUserId: state.emailNotifications.emailDeliveryData.editUserId,
  }),
  {
    saveEmailAlertSettings: actions.api.saveEmailAlertSettings.request,
  },
)
export default class SendZeroResults extends Component {
  static propTypes = {
    noticeFrequency: PropTypes.string.isRequired,
    notificationType: PropTypes.string.isRequired,
  }
  render() {
    const activeSettings = this.props.notificationType === constants.BASE_NOTIFICATION_TYPE
      ? this.props.baseSettings
      : this.props.notificationType === constants.INSIGHTS_NOTIFICATION_TYPE
        ? this.props.insightsSettings
        : this.props.notificationType === constants.ESG_NOTIFICATION_TYPE
          ? this.props.esgSettings
          : null
    let zeroResultsOption = null
    if (activeSettings) {
      const frequencySettings = activeSettings.settings.find(
        setting => setting.noticeFrequency === this.props.noticeFrequency)
      const sendZeroResults = frequencySettings
        ? frequencySettings.emailSendZeroResults
        : null
      zeroResultsOption = this.props.noticeFrequency !== constants.INTRA_DAY_FREQ
        ? <InputBlock
            className={'input-label email-send-zero-container'}
            label={'Send my Alert even if no new results are found'}
            isInline={true}
          >
            <input
              className={'input-checkbox'}
              type="checkbox"
              checked={sendZeroResults}
              onChange={(e) => this.onEmailSendZeroResultsChange(e, activeSettings)}
            />
          </InputBlock>
        : null
    }
    return (
      <React.Fragment>
        {zeroResultsOption}
      </React.Fragment>
    )
  }
  onEmailSendZeroResultsChange(event, activeSettings) {
    this.props.saveEmailAlertSettings({
      editUserId: this.props.editUserId,
      noticeFrequency: this.props.noticeFrequency,
      type: activeSettings.notificationType,
      field: constants.EMAIL_SEND_ZERO_RESULTS,
      value: event.target.checked
    })
  }
}
