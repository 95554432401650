import React from 'react'
import { render } from 'react-dom'

import AppWrapper from 'app/global/app-wrapper'
import store from 'app/store'

import ProfilePreferences from './ProfilePreferences'
import * as constants from './profile-preferences-constants'
import { init as profilePreferencesInit } from './profile-preferences-actions'
import reducer from './profile-preferences-reducer'
import saga from './profile-preferences-saga'


export function init() {
  store.dispatch(profilePreferencesInit())
  const component =
    <AppWrapper>
      <ProfilePreferences/>
    </AppWrapper>
  const container = document.getElementById('profile-preferences-wrapper')
  render(component, container)
}

export {ProfilePreferences, constants, reducer, saga}
