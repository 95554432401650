import {createAction} from 'redux-actions'

const PREFIX = 'HELP'
const action = name => createAction(`${PREFIX}_${name}`)

export const setIsLoading = action('SET_IS_LOADING')
export const fetchTopics = action('FETCH_TOPICS')
export const setTopics = action('SET_TOPICS')
export const fetchSearchResults = action('FETCH_SEARCH_RESULTS')
export const setSearchResults = action('SET_SEARCH_RESULTS')
