import React from 'react'
import {render} from 'react-dom'
import store from 'app/store'

import {changeCaseObject} from 'app/utils'
import AppWrapper from 'app/global/app-wrapper'

import ProfileSearches from './ProfileSearches'
import {init as profileSearchesInit} from './profile-searches-actions'
import * as constants from './profile-searches-constants'
import reducer from './profile-searches-reducer'
import saga from './profile-searches-saga'


export function init(props) {
  store.dispatch(profileSearchesInit(changeCaseObject.camelCase(props)))

  const component =
    <AppWrapper store={store}>
      <ProfileSearches/>
    </AppWrapper>
  const container = document.getElementById('profile-searches-wrapper')
  render(component, container)
}

export {constants, reducer, saga}
