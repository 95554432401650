import React from 'react'
import {useSelector} from 'react-redux'

import Dropdown, { Option } from 'app/common/Dropdown'
import * as globalSelectors from 'app/global/global-selectors'
import { SavedSearch } from 'app/models'
import * as strings from 'app/strings'


export default ({showAllCategories = false, omittedCategories = [], ...restProps}) => {
  const {USER_ACCESSIBLE_CATEGORIES} = SavedSearch
  const currentFirm = useSelector(globalSelectors.getCurrentFirm)
  const isPracticesEnabled = useSelector(globalSelectors.getCurrentFirmIsPracticesEnabled)

  if (!isPracticesEnabled) {
    omittedCategories.push(SavedSearch.CATEGORIES.PRACTICE)
  }

  /**
   * filter out omitted categories unless the dropdown value IS one of the omitted categories.
   * this can happen if a search was created before it's category was disabled.
   */
  let categories = Object.values(USER_ACCESSIBLE_CATEGORIES).filter(c =>
    ('value' in restProps && c === restProps.value) ||
    (!omittedCategories.includes(c))
  )

  const allCategoriesOption =
    showAllCategories && <Option key="all" label="All Categories" />

  const options = categories.map(category =>
    <Option
      key={category}
      label={strings.searchCategory(category, {currentFirm})}
      value={category}
    />
  )

  return (
    <Dropdown {...restProps}>
      {allCategoriesOption}
      {options}
    </Dropdown>
  )
}
