import classNames from 'classnames'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { Component } from 'react'

import Table from 'app/common/Table'

import * as styles from './style.less'


const COLUMNS = {
  CHECKBOX: 'checkbox',
  NAME: 'name',
  USER_TYPE: 'userType',
  ACTIONS: 'actions',
}


export default class EditSearchModalSharingTable extends Component {
  static propTypes = {
    parentSearch: PropTypes.object.isRequired,
    searches: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedSearchIds: PropTypes.arrayOf(PropTypes.number),
    onSelectStateChange: PropTypes.func,

    // [Search] -> ()
    onRemove: PropTypes.func,
  }

  static defaultProps = {
    selectedSearchIds: [],
    onSelectStateChange() {},
    onRemove() {},
  }

  state = {
    // The ID of the search that is currently expanded, or null
    expandedSearchId: null,
  }

  // React methods

  render() {
    return (
      <Table
        defaultSort={{column: COLUMNS.NAME, direction: 'asc'}}
        data={[this.props.parentSearch, ...this.props.searches]}
        className={styles.sharingContentTable}
      >
        <Table.Column
          name={COLUMNS.CHECKBOX}
          baseWidth={45}
          growRatio={0}
          shrinkRatio={0}
          cellContents={search =>
            search.id === this.props.parentSearch.id
              ? null
              : <input
                  type="checkbox"
                  className="select-assignment-checkbox"
                  checked={this.props.selectedSearchIds.includes(search.id)}
                  onChange={this.onSelectStateChange.bind(this, search)}
                />
          }
        />

        <Table.Column
          name={COLUMNS.NAME}
          label="Name"
          isSortable
          sortBy={R.pipe(R.path(['owner', 'displayName']), R.trim, R.toLower)}
          baseWidth={150}
          growRatio={2}
          cellContents={search => {
            const user = search.owner
            const membershipCount = user.isGroup
              ? user.userMemberships.length : null
            const memberCount = user.isGroup
              ? `(${membershipCount} user${membershipCount === 1 ? '' : 's'})`
              : null
            const expandButton = user.isGroup
              ? <span
                  className={styles.expandArrow}
                  onClick={this.toggleExpandAssignment.bind(this, search)}
                />
              : null
            const expanded = search.id === this.state.expandedSearchId
            const expandedContent = expanded
              ? <div className={styles.expandedContent}>
                  {this.renderExpandedContent(search)}
                </div>
              : null

            return (
              <div className={classNames('cell-content', {[styles.expanded]: expanded})}>
                {expandButton}
                {search.owner.displayName} {memberCount}
                {expandedContent}
              </div>
            )
          }}
        />

        <Table.Column
          name={COLUMNS.USER_TYPE}
          label="Type"
          isSortable
          sortBy={search => _userTypeLabel(search.owner)}
          baseWidth={80}
          maxWidth={100}
          growRatio={1}
          cellContents={search => _userTypeLabel(search.owner)}
        />

        <Table.Column
          name={COLUMNS.ACTIONS}
          baseWidth={80}
          growRatio={0}
          cellContents={search =>
            search.id === this.props.parentSearch.id
              ? 'Owner'
              : <span className="actions">
                  <a
                    className="action remove"
                    onClick={() => this.props.onRemove(search)}
                  >
                    Remove
                  </a>
                </span>
          }
        />
      </Table>
    )
  }

  // Render helpers

  renderExpandedContent(search) {
    let names = search.owner.members.map(u => u.displayNameLastFirst)
    if (!names.length) {
      names = ['This group has no members.']
    }
    const sortedNames = R.sortBy(R.toLower, names)
    return sortedNames.map(name => <div className="name" key={name}>{name}</div>)
  }

  // Event handlers

  onSelectStateChange(search, event) {
    this.props.onSelectStateChange(search, event.target.checked)
  }

  // State management

  toggleExpandAssignment(search) {
    if (search.id === this.state.expandedSearchId) {
      this.setState({expandedSearchId: null})
    } else {
      this.setState({expandedSearchId: search.id})
    }
  }
}


function _userTypeLabel(user) {
  if (user.isGroup) return 'Group'
  return 'User'
}
