import store from 'app/store'

import * as actions from './flagging-actions'
import * as constants from './flagging-constants'
import reducer from './flagging-reducer'
import * as selectors from './flagging-selectors'

export function adjustFlaggedArticleCount(change) {
  store.dispatch(actions.adjustFlaggedArticleCount(change))
}

export {actions, constants, reducer, selectors}
