import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './MenuDropdown.less'


export default class MenuDropdown extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    onMenuIconClick: PropTypes.func.isRequired,
    onOptionClick: PropTypes.func.isRequired,
    isMenuOpen: PropTypes.bool.isRequired,
  }

  state = {
    style: {
      top: 0,
      left: 0,
    },
  }

  selfRef = React.createRef()
  menuOptionsRef = React.createRef()

  get top() {
    const selfRect = this.selfRef.current.getBoundingClientRect()
    return `${parseInt(selfRect.top + 15)}px`
  }

  get left() {
    const selfRect = this.selfRef.current.getBoundingClientRect()
    const menuOptionsRect = this.menuOptionsRef.current ? this.menuOptionsRef.current.getBoundingClientRect() : 0
    return `${parseInt(selfRect.right - menuOptionsRect.width + 2)}px`
  }

  componentDidMount() {
    this.setState({
      style: {
        top: this.top,
        left: this.left,
      }
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const top = this.top
    const left = this.left

    if (top !== prevState.style.top || left !== prevState.style.left) {
      this.setState({
        style: {
          top,
          left,
        }
      })
    }
  }

  render() {
    return (
      <div
        ref={this.selfRef}
        className="menu-dropdown"
      >
        <div
          className="menu-icon"
          onClick={() => this.props.onMenuIconClick()}
        >
          <i className="fa fa-bars"/>
        </div>

        {
          this.props.isMenuOpen
          ? <div
              ref={this.menuOptionsRef}
              className="menu-options"
              style={this.state.style}
            >
              {
                this.props.options.map(option => {
                  return (
                    <div
                      key={option.value}
                      className="menu-option"
                      onClick={() => this.props.onOptionClick(option)}
                    >{option.label}</div>
                  )
                })
              }
            </div>
          : null
        }
      </div>
    )
  }
}
