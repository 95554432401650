import PropTypes from 'prop-types'
import React from 'react'
import { Waypoint } from 'react-waypoint'
import styles from './DashboardSection.less'
import classNames from "classnames"
import * as dateFns from 'date-fns'
import DashboardDocumentList from "app/dashboard/dashboard-document-list/DashboardDocumentList"


function DashboardSection({
  section,
  searches,
  showAlternateDateFormat,
  documents,
  updateDocuments,
  updateFeeds,
  removeDocumentsForExcludedFeed,
  activeNavChange,
}) {

  const findSearchData = (searchId) => {
    return(
      section.searchData.find(sd => sd.savedSearchId === searchId)
    )
  }

  const trendLink = (trend, searchData, category) => {
    const trendHref = `${searchData.searchUrl}?usrc_s=${category}&usrc=trends&narrow=${trend.displayName.replace(' ','+')}`
    return (
      <a
        title={`Increased by ${trend.displayScore}`}
        href={trendHref}
        className="trending-term"
      >
        {trend.displayName}
      </a>
    )
  }

  const trendTerms = (searchData, category) => {
    return(
      searchData.trendData.map((td, idx) =>
        <div className={styles.term} key={`trend-${td.displayName}-${idx}`}>
          {trendLink(td, searchData, category)}
        </div>
      )
    )
  }

  const trending = (searchId, category) => {
    const searchData = findSearchData(searchId)
    const trends = searchData && searchData.trendData.length > 0
      ? <div key={`trending-search-${searchId}`} className={styles.trendingTermsContainer}>
          <div className={styles.trendingLabel}>
            Trending Terms:
          </div>
          <div className={styles.trendingTerms}>
            {trendTerms(searchData, category)}
          </div>
        </div>
      : null
    return (
      trends
    )
  }

  const formatDate = (lastUpdate) => {
    const dateFormat = showAlternateDateFormat
      ? 'do MMM yyyy'
      : 'MMM do, yyyy'
    const parsedDate = dateFns.parseISO(lastUpdate)
    return(
      lastUpdate
        ? dateFns.format(parsedDate, dateFormat)
        : null
    )
  }

  const stock = (searchId) => {
    const searchData = findSearchData(searchId)
    const stockData = searchData && searchData.stockData
      ? <div className={styles.stockDataContainer}>
          <div className={styles.stockSymbol}>
            {searchData.stockData.symbol.toUpperCase()}
          </div>
          <div className={styles.stockLastTradePrice}>
            {
              searchData.stockData.lastTradePrice
                ? searchData.stockData.lastTradePrice.toFixed(2)
                : null
            }
          </div>
          { searchData.stockData.change || searchData.stockData.percentChange
            ? <div
                className={classNames(styles.stockPriceChange,
                {[styles.stockPositive]: searchData.stockData.change &&
                  searchData.stockData.change > 0},
                {[styles.stockNegative]: searchData.stockData.change &&
                  searchData.stockData.change < 0},
                {[styles.stockNeutral]: (searchData.stockData.change &&
                    searchData.stockData.change === 0)},
                )}
              >
                {searchData.stockData.change
                  ? searchData.stockData.change.toFixed(2)
                  : null
                }
                {searchData.stockData.percentChange
                  ? ` / ${searchData.stockData.percentChange.toFixed(2)}%`
                  : null
                }
              </div>
            : null
          }
          <div className={styles.stockLastUpdate}>
            { searchData.stockData.lastUpdate
              ? formatDate(searchData.stockData.lastUpdate)
              : null
            }
          </div>
        </div>
      : null
    return (
      stockData
    )
  }

  const searchDocuments = (searchId) => {
    const searchData = findSearchData(searchId)
    // get complete document data from entities, while maintaining order.
    const fullDocuments = searchData.documents.map(doc => documents.find(({id}) => doc.id === id))
    return (
      <DashboardDocumentList
        key={`ss-document-list-${searchId}`}
        documents={fullDocuments}
        insightsInterimUrls={searchData.insightsInterimUrls}
        onFlaggingStateChange={({documents}) => updateDocuments(documents)}
        onPromoteStateChange={({feedId, isPromoted}) => updateFeeds([{id: feedId, isPromoted}])}
        onExclude={(feedId) => removeDocumentsForExcludedFeed(feedId)}
      />
    )
  }

  const searchFooter = (searchName, searchId) => {
    const searchData = findSearchData(searchId)
    const searchUrl = searchData.searchUrl
    const searchHref = `${searchUrl}?usrc_s=more&usrc=module`
    return (
      <div className={styles.sectionFooter}>
        <div className={styles.searchNameFooter}>
          <a href={searchHref}>{`All ${searchName} articles >`}</a>
        </div>
      </div>
    )
  }

  const searchHeaderLink = (savedSearch) => {
    const searchData = findSearchData(savedSearch.id)
    const searchUrl = searchData.searchUrl
    const searchHref = `${searchUrl}?usrc_s=header:${savedSearch.category}&usrc=module`
    return (
      <a href={searchHref}>{savedSearch.name}</a>
    )
  }
  const categorySearches = searches.filter(ss => section.categories.includes(ss.category))
  const searchDataIds = section.searchData.map(sd => sd.savedSearchId)
  const showSearches = categorySearches.filter(ss => searchDataIds.includes(ss.id))
  const sectionSearches = showSearches.map(ss =>
    <React.Fragment key={`dashboard-section-search-${ss.id}`}>
      <div className={styles.searchHeaderContainer}>
        <div className={styles.searchHeader}>
          <Waypoint
            fireOnRapidScroll={false}
            onEnter={(waypoint) =>
              activeNavChange(waypoint, ss.id, section.sectionName)}
          >
            <div className={styles.searchName} id={ss.name}>
              {searchHeaderLink(ss)}
            </div>
          </Waypoint>
          {stock(ss.id)}
        </div>
        {trending(ss.id, ss.category)}
      </div>
      {searchDocuments(ss.id)}
      {searchFooter(ss.name, ss.id)}
    </React.Fragment>
  )

  return (
    <React.Fragment>
      { sectionSearches }
    </React.Fragment>
  )

}
DashboardSection.propTypes = {
  section: PropTypes.object.isRequired,
  searches: PropTypes.array.isRequired,
  showAlternateDateFormat: PropTypes.bool.isRequired,
  documents: PropTypes.array.isRequired,
  updateDocuments: PropTypes.func.isRequired,
  updateFeeds: PropTypes.func.isRequired,
  removeDocumentsForExcludedFeed: PropTypes.func.isRequired,
  activeNavChange: PropTypes.func,
}
export default DashboardSection
