import Cookie from 'js-cookie'
import { handleActions } from 'redux-actions'
import * as usersAdminActions from './users-admin-actions'

const getDefaultGroupColumns = () => {
  try {
    const encodedValue = Cookie.get('ag_columns')
    const columns = encodedValue ? window.atob(encodedValue): undefined
    return columns ? JSON.parse(columns) : []
  } catch (error) {
    return []
  }
}

const setGroupColumns = (value) => {
  const columns = window.btoa(JSON.stringify(value))
  Cookie.set('ag_columns', columns)
}

const getInitialState = () => ({
  // init data
  managedGroupIds: [],
  firmLocations: [],
  departments: [],
  teams: [],
  countries: [],
  timezones: [],
  roles: [],
  noticeFrequencies: [],
  shouldAutoFetchProfiles: false,
  shouldAutoFetchAvailableMembers: false,
  areAllProfilesFetched: false,
  areAllAvailableMembersFetched: false,

  // count of users or groups depending on the page.
  activeProfileCount: 0,
  activeAdminProfileCount: 0,
  inactiveProfileCount: 0,

  /**
   * count of active users or groups available for assigning group membership.
   * on the users page this is the count of groups; on the groups page this is the count of users.
   */
  availableMemberCount: 0,

  /**
   * `isLoading` is for when the fetch is taking place.
   * `isProcessing` is for when the action (which can be cancelled) was dispatched.
   * this is used to prevent the `No users` text when filtering.
   */
  isLoading: false,
  isProcessing: false,

  isGroupPage: false,
  users: [],
  filters: {},
  userIds: [],
  groupMemberships: [],
  userMemberships: [],
  availableMemberNameFilter: '',

  // for filtering on the users page
  availableGroups: [],

  // user ids on the groups page, group ids on the users page.
  availableMemberIds: [],

  // stored when all users are fetched (no filters) so we can revert to all users when filters are removed.
  allUserIds: [],

  newUser: {
    firstName: null,
    lastName: null,
    emailAddress: null,
    shouldSendWelcomeEmail: true,
  },

  /**
   * selectedUserIds/selectedModalUserIds hold ids regardless of the filters applied.
   * when applying bulk actions, only visible users are included.
   */
  selectedUserIds: [],
  selectedModalUserIds: [],

  isInsightsModalDisplayed: false,
  insightsModalAction: 'enable',
  userModalData: {
    isOpen: false,
    userId: null,
    userUpdates: {},
    searches: [],
  },
  deleteUserConfirmationModalData: {
    isOpen: false,
    ids: [],
    isBulk: false,
  },
  deleteSearchesConfirmationModalData: {
    isOpen: false,
    ids: [],
  },
  editSearchData: {},
  isInAddSearchMode: false,
  newSearchData: {
    type: '',
  },
  selectedSearchIds: [],

  // for fetching complete user data for modal.
  isUserFetched: false,
  currentPage: 1,
  totalCount: 0,
  sortField: 'name',
  sortDirection: 'asc',
  pfilters: {},
  selectType: '',
  allSelectedUsers: [],
  selectedColumns: getDefaultGroupColumns(),
  publicationsModalDisplayed: false,
  publicationModalType: {
    type:'',
    user: ''
  },
  publicationData: [],
  isNlaFirm: false,
  isRefreshIntervalModalDisplayed: false,
  refreshIntervalModalAction: 0,
})

export default handleActions(
  {
    [usersAdminActions.init]: (state, action) => ({
      ...state,
      managedGroupIds: action.payload.managedGroupIds,
      firmLocations: action.payload.firmLocations,
      departments: action.payload.departments,
      teams: action.payload.teams,
      countries: action.payload.countries,
      timezones: action.payload.timezones,
      roles: action.payload.roles,
      noticeFrequencies: action.payload.noticeFrequencies,
      shouldAutoFetchProfiles: action.payload.shouldAutoFetchProfiles,
      shouldAutoFetchAvailableMembers: action.payload.shouldAutoFetchAvailableMembers,
      activeProfileCount: action.payload.activeProfileCount,
      activeAdminProfileCount: action.payload.activeAdminProfileCount,
      inactiveProfileCount: action.payload.inactiveProfileCount,
      availableMemberCount: action.payload.availableMemberCount,
      isGroupPage: action.payload.isGroupPage,
      availableGroups: action.payload.availableGroups,
    }),
    [usersAdminActions.fetchUser]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.fetchUsers]: (state, action) => ({
      ...state,
      filters: action.payload.filters,
      currentPage: 1,
      isProcessing: true,
      selectedUserIds: []
    }),
    [usersAdminActions.fetchAllUsers]: (state, action) => ({
      ...state,
      isProcessing: true,
      selectedUserIds: []
    }),
    [usersAdminActions.changeCurrentPage]: (state, action) => ({
      ...state,
      isLoading: true,
      currentPage: action.payload.page,
    }),
    [usersAdminActions.changeSort]: (state, action) => ({
      ...state,
      isLoading: true,
      sortField: action.payload.field,
      sortDirection: action.payload.direction,
    }),
    [usersAdminActions.changeIsFilter]: (state, action) => ({
      ...state,
      isLoading: true,
      pfilters: action.payload.filters,
      selectedUserIds: [],
    }),
    [usersAdminActions.fetchAvailableMembers]: (state, action) => ({
      ...state,
      availableMemberNameFilter: action.payload,
      isProcessing: true,
    }),
    [usersAdminActions.fetchAvailableMembersComplete]: (state, action) => ({
      ...state,
      availableMemberIds: action.payload.availableMemberIds,
      areAllAvailableMembersFetched: action.payload.areAllAvailableMembersFetched || state.areAllAvailableMembersFetched,
    }),
    [usersAdminActions.showLoader]: (state, action) => ({
      ...state,
      isLoading: true,
      isProcessing: false,
    }),
    [usersAdminActions.hideLoader]: (state, action) => ({
      ...state,
      isLoading: false,
    }),
    [usersAdminActions.fetchUsersSuccess]: (state, action) => ({
      ...state,
      isLoading: false,
      areAllProfilesFetched: state.areAllProfilesFetched || action.payload.areAllProfilesFetched,
      totalCount: action.payload.totalCount,
      visibleIds: action.payload.visibleIds,
      isNlaFirm: action.payload.isNlaFirm
    }),
    [usersAdminActions.setUserIds]: (state, action) => ({
      ...state,
      userIds: action.payload,
      isLoading: false,
      isProcessing: false,
    }),
    [usersAdminActions.setAllUserIds]: (state, action) => ({
      ...state,
      allUserIds: action.payload,
    }),
    [usersAdminActions.setMemberships]: (state, action) => ({
      ...state,
      groupMemberships: action.payload.groupMemberships,
      userMemberships: action.payload.userMemberships,
    }),
    [usersAdminActions.setNewUser]: (state, action) => ({
      ...state,
      newUser: action.payload,
    }),
    [usersAdminActions.setUserUpdates]: (state, action) => ({
      ...state,
      userModalData: {
        ...state.userModalData,
        userUpdates: action.payload,
      },
    }),
    [usersAdminActions.saveNewUser]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.saveNewGroup]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.createUserSuccess]: (state, action) => ({
      ...state,
      isLoading: false,
      userIds: [action.payload, ...state.userIds],
      allUserIds: [action.payload, ...state.allUserIds],
      newUser: getInitialState().newUser,
    }),
    [usersAdminActions.saveUserSuccess]: (state, action) => {
      return ({
        ...state,
        isLoading: false,
        newUser: getInitialState().newUser,
        userModalData: {
          ...state.userModalData,
          userUpdates: {},
        }
      })
    },
    [usersAdminActions.setSelectedUserIds]: (state, action) => ({
      ...state,
      isLoading: false,
      selectedUserIds: action.payload,
    }),
    [usersAdminActions.setSelectedModalUserIds]: (state, action) => ({
      ...state,
      isLoading: false,
      selectedModalUserIds: action.payload,
    }),
    [usersAdminActions.bulkPasswordReset]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.bulkSendWelcomeEmail]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.bulkSendRefreshEmail]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.bulkActivateAccounts]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.showInsightsModal]: (state, action) => ({
      ...state,
      isInsightsModalDisplayed: action.payload,
    }),
    [usersAdminActions.setInsightsModalAction]: (state, action) => ({
      ...state,
      insightsModalAction: action.payload,
    }),
    [usersAdminActions.bulkEnableInsights]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.bulkEnableInsightsSuccess]: (state, action) => ({
      ...state,
      isLoading: false,
      isInsightsModalDisplayed: false,
      insightsModalAction: getInitialState().insightsModalAction,
      selectedUserIds: [],
      selectedModalUserIds: [],
    }),
    [usersAdminActions.showUserModal]: (state, action) => ({
      ...state,
      userModalData: {
        ...state.userModalData,
        isOpen: true,
        userId: action.payload,
      },
    }),
    [usersAdminActions.hideUserModal]: (state, action) => ({
      ...state,
      userModalData: getInitialState().userModalData,
      editSearchData: getInitialState().editSearchData,
      newSearchData: getInitialState().newSearchData,
      isUserFetched: false,
    }),
    [usersAdminActions.saveUser]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.showDeleteUserConfirmationModal]: (state, action) => ({
      ...state,
      deleteUserConfirmationModalData: {
        isOpen: true,
        ids: action.payload.ids,
        isBulk: action.payload.isBulk,
      },
    }),
    [usersAdminActions.hideDeleteUserConfirmationModal]: (state, action) => ({
      ...state,
      deleteUserConfirmationModalData: getInitialState().deleteUserConfirmationModalData,
    }),
    [usersAdminActions.deleteUserSuccess]: (state, action) => ({
      ...state,
      deleteUserConfirmationModalData: getInitialState().deleteUserConfirmationModalData,
      userIds: [...state.userIds].filter(id => !action.payload.includes(id)),
      allUserIds: [...state.allUserIds].filter(id => !action.payload.includes(id)),
      selectedUserIds: [],
      isLoading: false,
    }),
    [usersAdminActions.deleteUsers]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.fetchSearches]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.setSearches]: (state, action) => ({
      ...state,
      isLoading: false,
      userModalData: {
        ...state.userModalData,
        searches: action.payload,
      }
    }),
    [usersAdminActions.showDeleteSearchesConfirmationModal]: (state, action) => ({
      ...state,
      deleteSearchesConfirmationModalData: {
        isOpen: true,
        ids: action.payload,
      },
    }),
    [usersAdminActions.hideDeleteSearchesConfirmationModal]: (state, action) => ({
      ...state,
      deleteSearchesConfirmationModalData: getInitialState().deleteSearchesConfirmationModalData,
      selectedSearchIds: [],
    }),
    [usersAdminActions.deleteSearches]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.setEditSearchData]: (state, action) => ({
      ...state,
      editSearchData: action.payload,
    }),
    [usersAdminActions.resetEditSearchData]: (state, action) => ({
      ...state,
      editSearchData: getInitialState().editSearchData,
      newSearchData: getInitialState().newSearchData,
    }),
    [usersAdminActions.saveSearch]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.setNewSearchData]: (state, action) => ({
      ...state,
      isInAddSearchMode: true,
      newSearchData: action.payload,
    }),
    [usersAdminActions.resetNewSearchData]: (state, action) => ({
      ...state,
      isInAddSearchMode: false,
      newSearchData: getInitialState().newSearchData,
    }),
    [usersAdminActions.createSearch]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.createSource]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.setSelectedSearchIds]: (state, action) => ({
      ...state,
      selectedSearchIds: action.payload,
    }),
    [usersAdminActions.applyBulkSearchUpdates]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.resetBulkSearchUpdateData]: (state, action) => ({
      ...state,
      isLoading: false,
      selectedSearchIds: [],
    }),
    [usersAdminActions.fetchUserComplete]: (state, action) => ({
      ...state,
      isUserFetched: true,
      isLoading: false,
    }),
    [usersAdminActions.addGroupToUsers]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.removeGroupFromUsers]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.addUserToGroups]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.removeUserFromGroups]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.toggleMembershipIsManager]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.makeSelectedGroupsPrivate]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.selectAllUsersCheckbox]: (state, action) => ({
      ...state,
      selectType: action.payload,
    }),
    [usersAdminActions.setAllSelectedUser]: (state, action) => ({
      ...state,
      allSelectedUsers: action.payload,
    }),
    [usersAdminActions.showSelectedGroupsColumns]: (state, action) => ({
      ...state,
      selectedColumns: action.payload,
    }),
    [usersAdminActions.showSelectedGroupsColumns]: (state, action) => {
      setGroupColumns(action.payload)
      return {
        ...state,
        selectedColumns: action.payload,
      }
    },
    [usersAdminActions.showPublicationsModal]: (state, action) => ({
      ...state,
      publicationsModalDisplayed: action.payload,
    }),
    [usersAdminActions.setPublicationsModalType]: (state, action) => ({
      ...state,
      publicationModalType: action.payload,
    }),
    [usersAdminActions.getRecurringPublicationsData]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.getTemplatePublicationsData]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.setPublicationsData]: (state, action) => ({
      ...state,
      publicationData: action.payload,
    }),
    [usersAdminActions.saveBulkUsersEmailDeliverySettings]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.saveBulkNlaArticleViewingSettings]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.showRefreshIntervalModal]: (state, action) => ({
      ...state,
      isRefreshIntervalModalDisplayed: action.payload,
    }),
    [usersAdminActions.setRefreshIntervalModalAction]: (state, action) => ({
      ...state,
      refreshIntervalModalAction: action.payload,
    }),
    [usersAdminActions.bulkManageRefreshInterval]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [usersAdminActions.bulkManageRefreshIntervalSuccess]: (state, action) => ({
      ...state,
      isLoading: false,
      isRefreshIntervalModalDisplayed: false,
      refreshIntervalModalAction: getInitialState().refreshIntervalModalAction,
      selectedUserIds: [],
      selectedModalUserIds: [],
    }),
  },
  getInitialState(),
)
