import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { render } from 'react-dom'

import { defaultPrevented } from 'app/utils'

import './style.less'


class AdditionalServicesPage extends Component {
  constructor(props) {
    super(props);
    this.state = props
  }
  render() {
    const mailLink = `mailto:support@manzama.com?Subject=${this.props.app_name}%20Additional%20Services%20Inquiry`
    return (
      <div>
        <p>
          {this.props.app_name_full} offers the following additional services for purchase.
          Contact <a href={mailLink}>{this.props.app_name_full} support</a> for more information.
        </p>
        <div
          id="additional-services"
          className={`grid cols-2 services`}
        >
          <AdditionalServices
            services={this.state.services}
          />
        </div>
      </div>
    )
  }
}


class AdditionalServices extends Component {
  static propTypes = {
    services: PropTypes.arrayOf(PropTypes.object).isRequired,
  };
  render() {
    const servicesList = this.props.services.map(service => {
      return(
        <div
          className="additional-service-wrapper"
          key={service.id}
        >
          <Service
            service={service}
          />
        </div>
      )
    });
    return (
      <div className="services-list">
        {servicesList}
      </div>
    )
  }
}


class Service extends Component {
  static propTypes = {
    service: PropTypes.object.isRequired,
  };
  state = {
    learnMoreExpanded: false,
  };
  render() {
    let examplesContent = null;
    if (this.state.learnMoreExpanded) {
      examplesContent=
        <div
          className="service-examples"
          >
            <ServiceExamples
              examples={this.props.service.examples}
            />
        </div>
    }
    return(
      <div>
        <div
          className={classNames('additional-service', 'grid', {'no-bottom-border': this.state.learnMoreExpanded})}
        >
          <div className='col-1-8'>
            {displayImage(this.props.service.image)}
          </div>
          <div className="col-7-8 pad-l-m">
            <h2>{this.props.service.name}</h2>
            <p className="description">
              {this.props.service.description}&nbsp;
              <a href={this.props.service.more_info_url}>{this.props.service.display_url_as}</a>
            </p>
          </div>
        </div>
        {examplesContent}
        {this.displayLearnMore()}
      </div>
    )
  }
  toggleExamples() {
    this.setState({learnMoreExpanded: !this.state.learnMoreExpanded})
  }
  displayLearnMore() {
    if(this.props.service.examples.length) {
      return (
        <div className="learn-more">
          <a
            href='#'
            className="text"
            onClick={defaultPrevented(() => this.toggleExamples())}
          >
            <i
              className={classNames('fa', {'fa-angle-up': this.state.learnMoreExpanded}, {'fa-angle-down': !this.state.learnMoreExpanded}, 'fa-lg')}
              aria-hidden="true"
            >
            </i>
            &nbsp; Learn more
          </a>
        </div>
      )
    }
  }
}


class ServiceExamples extends Component {
  static propTypes = {
    examples: PropTypes.arrayOf(PropTypes.object).isRequired,
  };
  render() {
    const examplesList = this.props.examples.map(example => {
      return(
        <div
          className="service-example-wrapper"
          key={example.id}
        >
          <div className='service-example grid'>
            <div className='col-1-5'>
              {displayImage(example.image)}
            </div>
            <div className="col-4-5 pad-l-m">
              <h2>{example.name}</h2>
              <p className="description">
                {example.description}
                <br/><br/>
                <a href={example.more_info_url}>{example.display_url_as }</a>
              </p>
            </div>
          </div>
        </div>
      )
    });
    return (
      <div>
        {examplesList}
      </div>
    )
  }
}


function displayImage(image) {
  if(image) {
    return (
      <img
        className="image"
        src={image}
        alt="display image"
      />
    )
  }
}


export function init(props) {
  const container = document.getElementById('additional-services-wrapper');
  render(<AdditionalServicesPage {...props} />, container)
}
