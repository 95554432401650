import {all, put, takeLatest, select, delay, fork} from 'redux-saga/effects'

import * as entitiesActions from 'app/entities/entities-actions'
import * as notifications from 'app/global/notifications'
import {handleSagaError, extractErrorMessage} from 'app/utils/errors'
import SavedSearch from 'app/models/SavedSearch'
import * as actions from 'app/actions'
import {Feed} from 'app/models'
import * as reusableApi from 'app/reusable/api'

import * as sourcesActions from './sources-admin-actions'
import * as sourcesApi from './sources-admin-api'
import {getSourcesAdmin} from './sources-admin-selectors'
import {addProfileSearchIds} from 'app/actions'
import {ADD_PROPRIETARY_FEED_STEPS} from './sources-admin-constants'


function* handleFetchFeeds(action) {
  const props = yield select(getSourcesAdmin)
  const {currentPage, sortField, sortDirection,itemsPerPage} = props
  const filters = {...props.filters}
  Object.entries(filters).forEach(([k, v]) => {
    if (v === null) {
      filters[k] = undefined
    }
  })
  if (filters.hasOwnProperty('publicationType')) {
    filters.publicationTypes = Feed.PUBLICATION_TYPES_BY_CATEGORY[filters.publicationType]
    delete filters.publicationType
  }
  if (filters.name || filters.label) {
    yield delay(300)
  }
  try {
    const response = yield sourcesApi.fetchFeeds({...filters, page: currentPage, sortField, sortDirection, itemsPerPage})
    const totalCount = response.feeds.totalCount
    const excludedFeedIds = response.feeds.excludedFeedIds
    const feedIds = []
    const feeds = {}
    const users = {}
    response.feeds.items.forEach(feed => {
      feed._assignedToIndividualsCount = feed.assignedToIndividualsCount
      delete feed.assignedToIndividualsCount
      feed.isAdded = true
      feed.isExcluded = excludedFeedIds.includes(feed.id)
      feed.createdById = feed.createdBy.id
      feedIds.push(feed.id)
      feeds[feed.id] = feed
      users[feed.createdBy.id] = feed.createdBy
      users[feed.updatedBy.id] = feed.updatedBy
    })
    const entities = {
      users,
      feeds,
    }

    yield put(entitiesActions.update(entities))
    yield put(sourcesActions.fetchFeedsSuccess({feedIds, totalCount}))
  } catch(error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
  }
}

function* handleSelectAllFeeds(action) {
  const props = yield select(getSourcesAdmin)
  const filters = {...props.filters}
  Object.entries(filters).forEach(([k, v]) => {
    if (v === null) {
      filters[k] = undefined
    }
  })
  if (filters.hasOwnProperty('publicationType')) {
    filters.publicationTypes = Feed.PUBLICATION_TYPES_BY_CATEGORY[filters.publicationType]
    delete filters.publicationType
  }
  const response = yield sourcesApi.fetchFeedIds(filters)
  yield put(sourcesActions.setSelectedFeedIds(response.feeds.items.map(f => f.id)))
  yield put(sourcesActions.setSelectedAllFeedsHealth(response.feeds.items))
}

function* handleShowAssignModal(action) {
  yield fetchAssignees()
}

function* handleShowAssignSourcesContent(action) {
  yield fetchAssignees()
}

function* fetchAssignees() {
  const props = yield select(getSourcesAdmin)
  if (!props.isAssigneesFetched) {
    let usersResponse = null
    let departmentsResponse = null
    let teamsResponse = null
    let firmLocationsResponse = null
    yield put(sourcesActions.showLoader())
    try {
      usersResponse = yield sourcesApi.fetchAllUsersAndGroups(true)
    } catch(error) {
      yield* handleSagaError(error)
      yield put(sourcesActions.hideLoader())
      return
    }
    const users = {}
    usersResponse.users.forEach(u => {
      users[u.id] = {...u}
    })
    const entities = {
      users,
    }
    yield put(entitiesActions.update(entities))
    try {
      departmentsResponse = yield reusableApi.fetchDepartments()
    } catch(error) {
      yield* handleSagaError(error)
      yield put(sourcesActions.hideLoader())
      return
    }
    try {
      teamsResponse = yield reusableApi.fetchTeams()
    } catch(error) {
      yield* handleSagaError(error)
      yield put(sourcesActions.hideLoader())
      return
    }
    try {
      firmLocationsResponse = yield reusableApi.fetchFirmLocations()
    } catch(error) {
      yield* handleSagaError(error)
      yield put(sourcesActions.hideLoader())
      return
    }
    yield put(sourcesActions.fetchAssigneesComplete({
        userIds: usersResponse.users.map(u => u.id),
        departments: departmentsResponse.departments,
        teams: teamsResponse.teams,
        firmLocations: firmLocationsResponse.firmLocations,
    }))
    yield put(sourcesActions.hideLoader())
  }
}

function* handleShowEditModal(action) {
  const editFeed = action.payload.feed
  const props = yield select(getSourcesAdmin)
  const {labels} = props
  yield fetchFeed(editFeed.id)
  yield fetchLabelsIfNeeded()
  if (labels && labels?.length > 0) {
    yield put(sourcesActions.hideLoader())
  }
}

function* handleAddAssignments(action) {
  const {feedIds} = action.payload
  try {
    yield sourcesApi.addSourceAssignments(action.payload)
    if (feedIds.length === 1) {
      yield fetchFeed(feedIds[0])
    }
  } catch(error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: `${feedIds.length} ${feedIds.length === 1 ? 'source has' : 'sources have'} been assigned.`,
    }),
  )
  yield put(sourcesActions.setSelectedFeedIds([]))
  yield put(sourcesActions.hideAssignModal())
  yield put(sourcesActions.hideAssignSourcesContent())
  if (action.payload.isTrusted) {
    // Update profile searches in the background in case any trusted sources are
    // added for the current user.
    yield fork(updateProfileSearches)
  }
}

function* handleUpdateAssignments(action) {
  try {
    yield sourcesApi.updateSourceAssignments(action.payload)
  } catch(error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  const {feedId} = yield select(state => getSourcesAdmin(state).editModalData)
  yield fetchFeed(feedId)
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'Assignments updated successfully.',
    }),
  )
  yield put(sourcesActions.hideLoader())
}

function* handleRemoveAssignments(action) {
  try {
    yield sourcesApi.removeSourceAssignments(action.payload)
  } catch(error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  yield put(entitiesActions.remove({
      sourceAssignments: action.payload,
  }))
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'Assignments updated successfully.',
    }),
  )
  yield put(sourcesActions.hideLoader())
}

function* handleAddFeaturedSources(action) {
  const {feedIds} = action.payload
  try {
    yield sourcesApi.addFeaturedSources(action.payload)
  } catch(error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: `${feedIds.length} ${feedIds.length === 1 ? 'source is' : 'sources are'} now featured.`,
    }),
  )
  yield put(sourcesActions.setSelectedFeedIds([]))
  yield put(sourcesActions.hideAssignModal())
}

function* handleRemoveFeaturedSources(action) {
  const props = yield select(getSourcesAdmin)
  const feedId = props.editModalData.feedId
  try {
    const response = yield sourcesApi.removeFeaturedSources({feedId, fromItems: action.payload})
  } catch(error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  // re-fetch firm source to refresh featured-for data
  yield fetchFeed(feedId)
  yield put(sourcesActions.hideLoader())
}

function* fetchFeed(id) {
  let response = null
  try {
    response = yield sourcesApi.fetchFeed(id)
  } catch (error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  const feed = response.feed
  const users = {}
  const departments = {}
  const teams = {}
  const sourceAssignments = {}
  const memberships = {}
  feed.assignments.forEach(a => {
    users[a.assignedTo.id] = a.assignedTo
    a.assignedToId = a.assignedTo.id
    sourceAssignments[a.id] = a
    a.assignedTo.groupMemberships.forEach(m => {
      m.userId = m.user.id
      m.groupId = m.group.id
      memberships[m.id] = m
      users[m.user.id] = m.user
      users[m.group.id] = m.group
    })
    a.assignedTo.userMemberships.forEach(m => {
      m.userId = m.user.id
      m.groupId = m.group.id
      memberships[m.id] = m
      users[m.user.id] = m.user
      users[m.group.id] = m.group
    })
  })
  feed.featuredForUsersIds = []
  feed.featuredForDepartmentsIds = []
  feed.featuredForTeamsIds = []
  feed.featuredForUsers.forEach(u => {
    users[u.id] = {...u}
    feed.featuredForUsersIds.push(u.id)
  })
  feed.featuredForDepartments.forEach(d => {
    departments[d.id] = {...d}
    feed.featuredForDepartmentsIds.push(d.id)
  })
  feed.featuredForTeams.forEach(t => {
    teams[t.id] = {...t}
    feed.featuredForTeamsIds.push(t.id)
  })
  const feeds = {[id]: {...feed}}
  yield put(entitiesActions.update({
      users,
      departments,
      teams,
      sourceAssignments,
      memberships,
      feeds,
  }))
}

function* handleUpdateFeeds(action) {
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'Your changes were saved successfully.',
    }),
  )
}

function* handlePromoteFeeds(action) {
  try {
    yield sourcesApi.promoteFeeds(action.payload)
  } catch (error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  yield put(sourcesActions.fetchFeeds())
}

function* handleUnpromoteFeeds(action) {
  try {
    yield sourcesApi.unpromoteFeeds(action.payload)
  } catch (error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  yield put(sourcesActions.fetchFeeds())
}

function* handleDemoteFeeds(action) {
  try {
    yield sourcesApi.demoteFeeds(action.payload)
  } catch (error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  yield put(sourcesActions.fetchFeeds())
}

function* handleUndemoteFeeds(action) {
  try {
    yield sourcesApi.undemoteFeeds(action.payload)
  } catch (error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  yield put(sourcesActions.fetchFeeds())
}

function* handleExcludeFeeds(action) {
  try {
    yield sourcesApi.excludeFeeds(action.payload)
  } catch (error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  yield put(sourcesActions.fetchFeeds())
}

function* handleRemoveFeeds(action) {
  try {
    yield sourcesApi.removeFeeds(action.payload)
  } catch (error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'Sources removed successfully.',
    }),
  )
  yield put(sourcesActions.setSelectedFeedIds([]))
  yield put(sourcesActions.fetchFeeds())
}

function* handleSaveFeed(action) {
  const editModalData = yield select(state => getSourcesAdmin(state).editModalData)
  try {
    yield sourcesApi.saveFeed({
      feedId: editModalData.feedId,
      name: editModalData.name,
      url: editModalData.url,
      isPromoted: editModalData.isPromoted,
      isDemoted: editModalData.isDemoted,
      isExcluded: editModalData.isExcluded,
      isLimitedSeat: editModalData.isLimitedSeat,
      sourceNotes: editModalData.sourceNotes,
    })
  } catch (error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'Source saved successfully.',
    }),
  )
  yield put(sourcesActions.fetchFeeds())
}

function* handleCreateSources(action) {
  try {
    yield sourcesApi.createSources({
      feedIds: action.payload,
    })
  } catch (error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'Source(s) added successfully.',
    }),
  )
  yield put(sourcesActions.createSourcesComplete(action.payload))
  yield put(sourcesActions.fetchFeeds())
}

function* handleValidateFeed(action) {
  let response = null
  try {
    response = yield sourcesApi.validateFeed({url: action.payload})
  } catch (error) {
    yield put(sourcesActions.updateCreateProprietaryFeedData({
      errorMessage: 'That doesn\'t appear to be a valid RSS feed URL. ' +
          'Please verify that you are entering a valid RSS URL.',
        isValidating: false,
    }))
    yield put(sourcesActions.hideLoader())
    return
  }
  const validateFeed = response.validateFeed || {}
  const {isExisting, feeds} = validateFeed
  const step = isExisting
    ? ADD_PROPRIETARY_FEED_STEPS.EXISTING_SOURCES
    : feeds.length > 0 && feeds[0].documents.length > 0
    ? ADD_PROPRIETARY_FEED_STEPS.FEEDS_FOUND
    : ADD_PROPRIETARY_FEED_STEPS.EMPTY_FEED_CONFIRMATION
  yield put(sourcesActions.updateCreateProprietaryFeedData({
      isExisting,
      feeds,
      isValidating: false,
      currentStep: step,
  }))
  yield put(sourcesActions.hideLoader())
}

function* handleCreateProprietaryFeed(action) {
  try {
    yield sourcesApi.createProprietaryFeed(action.payload)
  } catch (error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  yield put(sourcesActions.updateCreateProprietaryFeedData({
      currentStep: ADD_PROPRIETARY_FEED_STEPS.SELF_ADD_SUCCESS,
      newFeedName: action.payload.name,
  }))
  yield put(sourcesActions.fetchFeeds())
}

function* handleCreateTwitterFeed(action) {
  yield put(sourcesActions.updateCreateTwitterFeedData({
      isProcessing: true,
      errorMessage: null,
  }))
  try {
    yield sourcesApi.createTwitterFeed(action.payload)
  } catch (error) {
    yield put(sourcesActions.hideLoader())
    yield put(sourcesActions.updateCreateTwitterFeedData({
        errorMessage: extractErrorMessage(error),
        isProcessing: false,
    }))
    return
  }
  yield put(sourcesActions.updateCreateTwitterFeedData({
      isComplete: true,
      isProcessing: false,
      handle: '',
  }))
  yield put(sourcesActions.fetchFeeds())
}

/**
 * Fetches and updates the current user's profile searches. This is used to make
 * sure we reflect any search updates without needing to keep track of them
 * manually.
 */
function* updateProfileSearches() {
  try {
    const savedSearches = yield sourcesApi.fetchProfileSearches()
    const savedSearchIds = savedSearches.map(ss => ss.id)
    yield put(entitiesActions.updateByModel(SavedSearch, savedSearches))
    yield put(addProfileSearchIds(savedSearchIds))
  } catch (error) {
    // We can safely ignore any errors since this isn't critical functionality.
    yield* handleSagaError(error, {noNotification: true})
  }
}

function* fetchLabelsIfNeeded(action) {
  const props = yield select(getSourcesAdmin)
  const {labels, allParentLabels} = props
  if (labels === null) {
    yield put(sourcesActions.fetchManageLabels())
  }
  if (allParentLabels === null) {
    yield put(sourcesActions.fetchAllParentLabels())
  }
}

function* handleFetchLabels(action) {
  const props = yield select(getSourcesAdmin)
  let response = null
  try {
      response = yield sourcesApi.fetchManageLabels({...props.manageLabelsFilter})
  } catch (error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
    yield put(sourcesActions.fetchLabelsComplete(response.body.labels))
    yield put(sourcesActions.setManageLabelsTotalCount(response.body.totalCount))
}

function* handleFetchAllParentLabels() {
  let response = null
  try {
    response = yield sourcesApi.fetchAllParentLabels()
  } catch (error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  yield put(sourcesActions.fetchAllParentLabelsComplete(response.parentFirmSourceLabels))
}

function* handleCreateLabel(action) {
  try {
    const response = yield sourcesApi.createLabel(action.payload)
  } catch(error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  yield put(sourcesActions.resetNewLabelData())
  yield put(sourcesActions.fetchManageLabels())
  yield put(sourcesActions.fetchAllParentLabels())
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'Label created successfully',
    }),
  )
}

function* handleSaveLabel(action) {
  try {
    const response = yield sourcesApi.saveLabel(action.payload)
  } catch(error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  yield put(sourcesActions.resetManageLabelsModalData())
  yield put(sourcesActions.fetchFeeds())
  yield put(sourcesActions.fetchManageLabels())
  yield put(sourcesActions.fetchAllParentLabels())
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'Label saved successfully',
    }),
  )
}

function* handleDeleteLabel(action) {
  try {
    const response = yield sourcesApi.deleteLabel(action.payload)
  } catch(error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  yield put(sourcesActions.resetManageLabelsModalData())
  yield put(sourcesActions.fetchFeeds())
  yield put(sourcesActions.fetchManageLabels())
  yield put(sourcesActions.fetchAllParentLabels())
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'Label deleted successfully',
    }),
  )
}

function* handleAddLabels(action) {
  const props = yield select(getSourcesAdmin)
  const feedIds = props.selectedFeedIds
  const labelIds = props.labelModalData.selectedIds
  try {
    const response = yield sourcesApi.addLabels({feedIds, labelIds})
  } catch(error) {
    yield* handleSagaError(error)
    return
  } finally {
    yield put(sourcesActions.hideLoader())
  }
  yield put(sourcesActions.setIsLabelModalShown(false))
  yield put(sourcesActions.setSelectedFeedIds([]))
  yield put(sourcesActions.fetchFeeds())
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'Labels added successfully',
    }),
  )
}

function* handleRemoveLabels(action) {
  const {editModalData} = yield select(getSourcesAdmin)
  try {
    const response = yield sourcesApi.removeLabels(action.payload)
  } catch(error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  yield put(sourcesActions.fetchFeeds())
  yield put(sourcesActions.setSelectedFeedIds([]))
  yield put(sourcesActions.setIsLabelModalShown(false))
  yield put(sourcesActions.hideLoader())
  if (!editModalData.isVisible) {
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: `${action.payload.labelIds.length > 1 ? 'Labels' : 'Label'} removed successfully`,
      }),
    )
  }
}

function* handleUpdateLabels(action) {
  const {addIds, deleteIds, feedIds} = action.payload
  try {
    if (addIds && addIds.length > 0) {
      yield sourcesApi.addLabels({feedIds, labelIds: addIds})
    }
    if (deleteIds && deleteIds.length > 0) {
      yield sourcesApi.removeLabels({
        feedIds,
        labelIds: deleteIds,
      })
    }
  } catch (error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  yield put(sourcesActions.fetchFeeds())
  yield put(sourcesActions.hideEditModal())
  if (addIds.length > 0 && deleteIds.length > 0) {
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: `Label added/removed successfully.`,
    }),
  )
  }else if(addIds && addIds.length > 0){
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: `${addIds && addIds.length > 1 ? 'Labels' : 'Label'} added successfully.`,
      }),
    )
  }
  else if(deleteIds && deleteIds.length > 0){
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: `${deleteIds && deleteIds.length > 1 ? 'Labels' : 'Label'} removed successfully.`,
      }),
    )
  }
}

function* handleSendCsvEmail(action) {
  const {selectedFeedIds} = yield select(getSourcesAdmin)
  try {
    yield sourcesApi.sendSourcesCsvEmail(selectedFeedIds)
  } catch (error) {
    yield* handleSagaError(error)
    return
  }
  yield put(sourcesActions.hideLoader())
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'Your CSV export file is being generated and will be emailed to you shortly.',
    })
  )
}

function* handleBulkEditLabels(action) {
  const {selectedIds, deletedIds, selectedFeedIds} = action.payload
  try {
    if (selectedIds && selectedIds.length > 0) {
      yield sourcesApi.addLabels({
        feedIds: selectedFeedIds,
        labelIds: selectedIds,
      })
    }
    if (deletedIds && deletedIds.length > 0) {
      yield sourcesApi.removeLabels({
        feedIds:selectedFeedIds,
        labelIds: deletedIds,
      })
    }
  } catch (error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  yield put(sourcesActions.fetchFeeds())
  yield put(sourcesActions.resetLabelModalData())
  if (selectedIds.length > 0 && deletedIds.length > 0) {
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: `Labels added/removed successfully.`,
      }),
    )
    }else if(selectedIds && selectedIds.length > 0){
      yield put(
        notifications.actions.showNotification({
          type: 'success',
          message: `${selectedIds && selectedIds.length > 1 ? 'Labels' : 'Label'} added successfully.`,
        }),
      )
    }
    else if(deletedIds && deletedIds.length > 0){
      yield put(
        notifications.actions.showNotification({
          type: 'success',
          message: `${deletedIds && deletedIds.length > 1 ? 'Labels' : 'Label'} removed successfully.`,
        }),
      )
    }
}

function* handleReportFeeds(action) {
  try {
    yield sourcesApi.reportFeeds(action.payload)
  } catch (error) {
    yield* handleSagaError(error)
    yield put(sourcesActions.hideLoader())
    return
  }
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'Source(s) reported successfully.',
    }),
  )
  yield put(sourcesActions.setSelectedFeedIds([]))
  yield put(sourcesActions.fetchFeeds())
}

export default function*() {
  yield all([
    takeLatest(
      sourcesActions.fetchFeeds,
      handleFetchFeeds
    ),
    takeLatest(
      sourcesActions.setFilters,
      handleFetchFeeds
    ),
    takeLatest(
      sourcesActions.setSort,
      handleFetchFeeds
    ),
    takeLatest(
      sourcesActions.setPageNumber,
      handleFetchFeeds
    ),
    takeLatest(
      sourcesActions.selectAllFeeds,
      handleSelectAllFeeds
    ),
    takeLatest(
      sourcesActions.showAssignModal,
      handleShowAssignModal
    ),
    takeLatest(
      sourcesActions.showAssignSourcesContent,
      handleShowAssignSourcesContent
    ),
    takeLatest(
      sourcesActions.showEditModal,
      handleShowEditModal
    ),
    takeLatest(
      sourcesActions.addAssignments,
      handleAddAssignments
    ),
    takeLatest(
      sourcesActions.updateAssignments,
      handleUpdateAssignments
    ),
    takeLatest(
      sourcesActions.removeAssignments,
      handleRemoveAssignments
    ),
    takeLatest(
      sourcesActions.addFeaturedSources,
      handleAddFeaturedSources
    ),
    takeLatest(
      sourcesActions.removeFeaturedSources,
      handleRemoveFeaturedSources
    ),
    takeLatest(
      actions.updateFeed,
      handleUpdateFeeds
    ),
    takeLatest(
      sourcesActions.promoteFeeds,
      handlePromoteFeeds
    ),
    takeLatest(
      sourcesActions.unpromoteFeeds,
      handleUnpromoteFeeds
    ),
    takeLatest(
      sourcesActions.demoteFeeds,
      handleDemoteFeeds
    ),
    takeLatest(
      sourcesActions.undemoteFeeds,
      handleUndemoteFeeds
    ),
    takeLatest(
      sourcesActions.excludeFeeds,
      handleExcludeFeeds
    ),
    takeLatest(
      sourcesActions.removeFeeds,
      handleRemoveFeeds
    ),
    takeLatest(
      sourcesActions.saveFeed,
      handleSaveFeed
    ),
    takeLatest(
      sourcesActions.createSources,
      handleCreateSources
    ),
    takeLatest(
      sourcesActions.validateFeed,
      handleValidateFeed
    ),
    takeLatest(
      sourcesActions.createProprietaryFeed,
      handleCreateProprietaryFeed
    ),
    takeLatest(
      sourcesActions.createTwitterFeed,
      handleCreateTwitterFeed
    ),
    takeLatest(
      sourcesActions.setIsManageLabelsModalShown,
      fetchLabelsIfNeeded
    ),
    takeLatest(
      sourcesActions.setIsLabelModalShown,
      fetchLabelsIfNeeded
    ),
    takeLatest(
      sourcesActions.fetchManageLabels,
      handleFetchLabels
    ),
    takeLatest(
      sourcesActions.createLabel,
      handleCreateLabel
    ),
    takeLatest(
      sourcesActions.saveLabel,
      handleSaveLabel
    ),
    takeLatest(
      sourcesActions.deleteLabel,
      handleDeleteLabel
    ),
    takeLatest(
      sourcesActions.addLabels,
      handleAddLabels
    ),
    takeLatest(
      sourcesActions.removeLabels,
      handleRemoveLabels
    ),
    takeLatest(
      sourcesActions.sendCsvEmail,
      handleSendCsvEmail
    ),
    takeLatest(
      sourcesActions.setManageLabelsFilters,
      handleFetchLabels
    ),
    takeLatest(
      sourcesActions.fetchAllParentLabels,
      handleFetchAllParentLabels
    ),
    takeLatest(
      sourcesActions.updateLabels,
      handleUpdateLabels
    ),
    takeLatest(
      sourcesActions.updateBulkEditLabels,
      handleBulkEditLabels
    ),
    takeLatest(
      sourcesActions.reportFeeds,
      handleReportFeeds
    ),
  ])
}
