import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Orm from 'app/framework/Orm'
import { User } from 'app/models'

import Table from 'app/common/Table'

import { getSearchesAdmin } from '../searches-admin-selectors'

@connect(
  // Map state to props
  state => {
    const searchesAdmin = getSearchesAdmin(state)
    const orm = Orm.withEntities(state.entities)
    return {
      isLoading: searchesAdmin.isLoading,
      orm,
    }
  },
  // Map dispatch to props
  {

  },
)
export default class UsedByContent extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
  }

  render() {
    /**
     * childSearches (and therefor their owners) are not entities.
     * get them from entities here so we can use `User.displayName`.
     */
    const usedBy = this.props.search.childSearches.map(ss => {
      return {
        ...ss,
        owner: this.props.orm.getById(User, ss.owner.id),
        usedAs: 'term',
        isActiveUser: ss.owner?.isActive
      }
    }).concat(this.props.search.usedAsFilterBy.map(ss => {
      return {
        ...ss,
        owner: this.props.orm.getById(User, ss.owner.id),
        usedAs: 'filter',
        isActiveUser: ss.owner?.isActive
      }
    }))
    const table =
      !this.props.isLoading && usedBy.length ?
        <Table
          data={usedBy}
        >
          <Table.Column
            name="name"
            label="Search Name"
            baseWidth={290}
            growRatio={0}
            shrinkRatio={0}
            cellContents={search => search.name}
          />
          <Table.Column
            name="owner"
            label="Owner"
            baseWidth={290}
            growRatio={0}
            shrinkRatio={0}
            cellContents={search => search.owner.displayName}
          />
          <Table.Column
            name="used-as"
            label="Used As"
            baseWidth={90}
            growRatio={0}
            shrinkRatio={0}
            cellContents={search => search.usedAs === 'term' ? 'Core Term' : 'Filter'}
          />
          <Table.Column
            name="active"
            label="Active"
            baseWidth={70}
            growRatio={0}
            shrinkRatio={0}
            cellContents={search => search.isActiveUser ? 'Yes' : 'No'}
          />
        </Table>
        : null

    return (
      <div className="table">
        {table}
      </div>
    )
  }
}
