import {createAction} from 'redux-actions'

import * as utils from './routing-utils'

const action = name => createAction(`routing/${name}`)

/**
 * Dispatch this action to navigate to another URL. The payload can be any
 * string that `history.pushState` accepts, or an object with one of these
 * shapes:
 *   {url}
 *   {
 *     pathname?: String,
 *     query?: Object<String | Array>,
 *     persistQuery?: Boolean = false,
 *   }
 * If `persistQuery` is `true`, any key/value pairs in `query` are added to the
 * existing query instead of overwriting them.
 */
export const pushLocation = action(
  'PUSH_LOCATION',
  utils.normalizeLocationInput,
)

/**
 * Same as `pushLocation`, but replaces the current location without adding to
 * the location stack.
 */
export const replaceLocation = action(
  'REPLACE_LOCATION',
  utils.normalizeLocationInput,
)

/**
 * Dispatched internally when the location has changed. This action should never
 * be dispatched by hand.
 * Payload shape:
 *   {pathname: String, query?: Object<String | Array>, replaced: Boolean}
 */
export const locationChanged = action('LOCATION_CHANGED')
