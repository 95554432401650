import React from 'react'
import styles from './DashboardSection.less'
import { getAppNameFull } from 'app/global/global-selectors'
import {useSelector} from "react-redux"

function EmptySection() {
  const appFullName = useSelector(getAppNameFull)
  const emptyText =
    <div className={styles.emptySectionBody}>
      The {appFullName} dashboard is built to display recent search results from the past
      week. You can navigate to other areas of&nbsp;
      <a className={styles.profileLink} href="/profile/">your profile</a>&nbsp;
      to view more historical results for individual searches. As new results come in for your
      searches, the dashboard will display with content whenever possible. If you would like to
      add or edit your searches, that can be done in&nbsp;
      <a className={styles.profileLink} href="/profile/">your profile</a>. To
      begin a new search, please use the Search bar above.
    </div>

  return (
    <div className={styles.emptySectionContainer}>
      <div className={styles.emptySectionHeader}>
        No Recent Search Results Found
      </div>
      <div className={styles.emptySection}>
        <div className={styles.emptySectionTitle}>
          There are no recent results found for the searches in your profile.
        </div>
        { emptyText }
      </div>
    </div>
  )

}
export default EmptySection
