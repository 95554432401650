import React from 'react'

import Dropdown, { Option } from 'app/common/Dropdown'


export default function AlertFrequencyDropdown(props) {
  return (
    <Dropdown {...props}>
      <Option value="none" label="Do Not Send"/>
      <Option value="trigger" label="Intra-Day"/>
      <Option value="daily" label="Daily"/>
      <Option value="weekday" label="Weekdays"/>
      <Option value="weekly" label="Weekly"/>
      <Option value="monthly" label="Monthly"/>
    </Dropdown>
  )
}
AlertFrequencyDropdown.propTypes = {...Dropdown.propTypes}
