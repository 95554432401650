import Model from 'app/framework/Model'
import { field, foreignKey } from 'app/framework/Model/fields'

import User from './User'


export default class Firm extends Model {
  static entityKey = 'firms'

  static fields = {
    name: field(),
    orgType: field(),
    showPracticeAreas: field(),
    firmUser: foreignKey(User, {required: false}),
    clientDisplayName: field(),
    clientDisplayNamePlural: field(),
    firmDisplayName: field(),
    firmDisplayNamePlural: field(),
    practiceDisplayName: field(),
    practiceDisplayNamePlural: field(),
    industryDisplayName: field(),
    industryDisplayNamePlural: field(),
    competitorDisplayName: field(),
    competitorDisplayNamePlural: field(),
    trackerDisplayName: field(),
    trackerDisplayNamePlural: field(),
    hideInsightsLink: field({required: false}),
  }
}
User.fields.firm = foreignKey(Firm, {required: false})
