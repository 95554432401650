import { createAction } from 'redux-actions'

// Prefix action names with USERS_ADMIN
const createUsersAdminAction = name => createAction(`USERS_ADMIN_${name}`)

export const init = createUsersAdminAction('INIT')
export const fetchUsers = createUsersAdminAction('FETCH_USERS')
export const fetchAllUsers = createUsersAdminAction('FETCH_ALL_USERS')
export const changeCurrentPage = createUsersAdminAction('CHANGE_CURRENT_PAGE')
export const changeSort = createUsersAdminAction('CHANGE_SORT')
export const changeIsFilter = createUsersAdminAction('CHANGE_IS_FILTERS')
export const fetchUsersSuccess = createUsersAdminAction('FETCH_USERS_SUCCESS')
export const setUserIds = createUsersAdminAction('SET_USER_IDS')
export const setAllUserIds = createUsersAdminAction('SET_ALL_USER_IDS')
export const fetchAvailableMembers = createUsersAdminAction('FETCH_AVAILABLE_MEMBERS')
export const fetchAvailableMembersComplete = createUsersAdminAction('FETCH_AVAILABLE_MEMBERS_COMPLETE')
export const setMemberships = createUsersAdminAction('SET_MEMBERSHIPS')
export const showLoader = createUsersAdminAction('SHOW_LOADER')
export const hideLoader = createUsersAdminAction('HIDE_LOADER')
export const saveNewUser = createUsersAdminAction('SAVE_NEW_USER')
export const saveNewGroup = createUsersAdminAction('SAVE_NEW_GROUP')
export const setNewUser = createUsersAdminAction('SET_NEW_USER')
export const createUserSuccess = createUsersAdminAction('CREATE_USER_SUCCESS')
export const setSelectedUserIds = createUsersAdminAction('SET_SELECTED_USER_IDS')
export const setSelectedModalUserIds = createUsersAdminAction('SET_SELECTED_MODAL_USER_IDS')
export const bulkPasswordReset = createUsersAdminAction('BULK_PASSWORD_RESET')
export const bulkSendWelcomeEmail = createUsersAdminAction('BULK_SEND_WELCOME_EMAIL')
export const bulkSendRefreshEmail = createUsersAdminAction('BULK_SEND_REFRESH_EMAIL')
export const bulkActivateAccounts = createUsersAdminAction('BULK_ACTIVATE_ACCOUNTS')
export const setInsightsModalAction = createUsersAdminAction('SET_INSIGHTS_MODAL_ACTION')
export const bulkEnableInsights = createUsersAdminAction('BULK_ENABLE_INSIGHTS')
export const bulkEnableInsightsSuccess = createUsersAdminAction('BULK_ENABLE_INSIGHTS_SUCCESS')
export const showInsightsModal = createUsersAdminAction('SHOW_INSIGHTS_MODAL')
export const showUserModal = createUsersAdminAction('SHOW_USER_MODAL')
export const hideUserModal = createUsersAdminAction('HIDE_USER_MODAL')
export const setUserUpdates = createUsersAdminAction('SET_USER_UPDATES')
export const saveUser = createUsersAdminAction('SAVE_USER')
export const saveUserSuccess = createUsersAdminAction('SAVE_USER_SUCCESS')
export const showDeleteUserConfirmationModal = createUsersAdminAction('SHOW_DELETE_USER_CONFIRMATION_MODAL')
export const hideDeleteUserConfirmationModal = createUsersAdminAction('HIDE_DELETE_USER_CONFIRMATION_MODAL')
export const deleteUsers = createUsersAdminAction('DELETE_USERS')
export const deleteUserSuccess = createUsersAdminAction('DELETE_USER_SUCCESS')
export const fetchSearches = createUsersAdminAction('FETCH_SEARCHES')
export const setSearches = createUsersAdminAction('SET_SEARCHES')
export const showDeleteSearchesConfirmationModal = createUsersAdminAction('SHOW_DELETE_SEARCHES_CONFIRMATION_MODAL')
export const hideDeleteSearchesConfirmationModal = createUsersAdminAction('HIDE_DELETE_SEARCHES_CONFIRMATION_MODAL')
export const deleteSearches = createUsersAdminAction('DELETE_SEARCHES')
export const setEditSearchData = createUsersAdminAction('SET_EDIT_SEARCH_DATA')
export const resetEditSearchData = createUsersAdminAction('RESET_EDIT_SEARCH_DATA')
export const saveSearch = createUsersAdminAction('SAVE_SEARCH')
export const setNewSearchData = createUsersAdminAction('SET_NEW_SEARCH_DATA')
export const resetNewSearchData = createUsersAdminAction('RESET_NEW_SEARCH_DATA')
export const createSearch = createUsersAdminAction('CREATE_SEARCH')
export const createSource = createUsersAdminAction('CREATE_SOURCE')
export const setSelectedSearchIds = createUsersAdminAction('SET_SELECTED_SEARCH_IDS')
export const applyBulkSearchUpdates = createUsersAdminAction('APPLY_BULK_SEARCH_UPDATES')
export const resetBulkSearchUpdateData = createUsersAdminAction('RESET_BULK_SEARCH_UPDATE_DATA')
export const fetchUser = createUsersAdminAction('FETCH_USER')
export const fetchUserComplete = createUsersAdminAction('FETCH_USER_COMPLETE')
export const addUserToGroups = createUsersAdminAction('ADD_USER_TO_GROUPS')
export const removeUserFromGroups = createUsersAdminAction('REMOVE_USER_FROM_GROUPS')
export const addGroupToUsers = createUsersAdminAction('ADD_GROUP_TO_USERS')
export const removeGroupFromUsers = createUsersAdminAction('REMOVE_GROUP_FROM_USERS')
export const toggleMembershipIsManager = createUsersAdminAction('TOGGLE_MEMBERSHIP_IS_MANAGER')
export const refreshSummarySignup = createUsersAdminAction('REFRESH_SUMMARY_SIGNUP')
export const makeSelectedGroupsPrivate = createUsersAdminAction('MAKE_SELECTED_GROUPS_PRIVATE')
export const selectAllUsersCheckbox = createUsersAdminAction('SELECT_ALL_USERS_CHECKBOX')
export const setAllSelectedUser = createUsersAdminAction('SELECT_ALL_SELECTED_USERS')
export const showSelectedGroupsColumns = createUsersAdminAction('SHOW_SELECTED_GROUPS_COLUMNS')
export const showPublicationsModal = createUsersAdminAction('SHOW_PUBLICATIONS_MODAL')
export const setPublicationsModalType = createUsersAdminAction('SET_PUBLICATIONS_MODAL_TYPE')
export const getRecurringPublicationsData = createUsersAdminAction('GET_RECURRING_PUBLICATIONS_DATA')
export const getTemplatePublicationsData = createUsersAdminAction('GET_TEMPLATE_PUBLICATIONS_DATA')
export const fetchRecurringPublicationsData = createUsersAdminAction('FETCH_RECURRING_PUBLICATIONS_DATA')
export const fetchTemplatePublicationsData = createUsersAdminAction('FETCH_TEMPLATE_PUBLICATIONS_DATA')
export const setPublicationsData = createUsersAdminAction('SET_PUBLICATIONS_DATA')
export const saveBulkUsersEmailDeliverySettings = createUsersAdminAction('SAVE_BULK_USERS_EMAIL_DELIVERY_SETTINGS')
export const saveBulkNlaArticleViewingSettings = createUsersAdminAction('SAVE_BULK_NLA_ARTICLE_VIEWING_SETTINGS')
export const showRefreshIntervalModal = createUsersAdminAction('SHOW_REFRESH_INTERVAL_MODAL')
export const setRefreshIntervalModalAction = createUsersAdminAction('SET_REFRESH_INTERVAL_MODAL_ACTION')
export const bulkManageRefreshInterval = createUsersAdminAction('BULK_MANAGE_REFRESH_INTERVAL')
export const bulkManageRefreshIntervalSuccess = createUsersAdminAction('BULK_MANAGE_REFRESH_INTERVAL_SUCCESS')

