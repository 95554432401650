import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import {Portal} from 'react-portal'
import {connect} from 'react-redux'
import {CSSTransition, TransitionGroup} from 'react-transition-group'
import {createSelector} from 'reselect'

import InlineSvg from 'app/common/InlineSvg'

import {hideNotification} from './notifications-actions'
import {getNotifications} from './notifications-selectors'

import styles from './Notifications.less'

// This should be equal to the `@fade-time` var in the LESS file.
const TRANSITION_TIME = 0.5


function Notification({notification, hideNotification}) {
  return (
    <div
      className={classNames(
        styles.notification,
        styles[notification.type],
      )}
      onClick={() => hideNotification()}
    >
      <span>{notification.message}</span>
      <InlineSvg src="/media/img/close-icon.svg" className={styles.close} />
    </div>
  )
}
Notification.propTypes = {
  notification: PropTypes.object.isRequired,
  hideNotification: PropTypes.func.isRequired,
}


function Notifications({notifications, hideNotification}) {
  return (
    <Portal>
      <TransitionGroup className={styles.notifications}>
        {notifications.map(notification =>
          <CSSTransition
            classNames={{
              enter: styles.transitionIn,
              exit: styles.transitionOut,
            }}
            timeout={TRANSITION_TIME * 1000}
            unmountOnExit
            key={notification.id}
          >
            <Notification
              notification={notification}
              hideNotification={() => hideNotification(notification.id)}
            />
          </CSSTransition>
        )}
      </TransitionGroup>
    </Portal>
  )
}
Notifications.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  hideNotification: PropTypes.func.isRequired,
}

export default connect(
  createSelector(
    [getNotifications],
    notifications => ({notifications}),
  ),
  {hideNotification},
)(Notifications)
