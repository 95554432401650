import React, { Component } from 'react'
import './FactorsModal.less'
import InputBlock from 'app/common/InputBlock/index'
import PropTypes from 'prop-types'
import Modal from "../common/Modal"
import Button from "../common/Button"
import * as constants from "./email-notifications-constants"
import {titleCase} from 'title-case'


export default class FactorsModal extends Component {
  state = {
    bulkApply: false,
  }
  static propTypes = {
    editUserId: PropTypes.number.isRequired,
    factorsConfig: PropTypes.object.isRequired,
    notificationType: PropTypes.string.isRequired,
    availableFactors: PropTypes.array,
    showFactorsModal: PropTypes.bool,
    changeFactorsFunc: PropTypes.func.isRequired,
    changeShowModalFunc: PropTypes.func.isRequired,
    saveConfigsFunc: PropTypes.func.isRequired,
    changeShowBulkUpdateFunc: PropTypes.func.isRequired,
    changeBulkUpdateConfigsFunc: PropTypes.func.isRequired,
  }
  static defaultProps = {
    availableFactors: null,
    showFactorsModal: false,
  }

  render() {
    const {factorsConfig, availableFactors} = this.props
    const searchName = factorsConfig.searchName
    const selectedSubFactorIds = factorsConfig.subFactors.map(subFactor => subFactor['pk'])
    const category = this.props.factorsConfig.category
    const frequency = this.props.factorsConfig.frequency
    const bulkUpdateText = `Apply These Settings to All ${constants.CATEGORIES[category]} in ${titleCase(frequency)} Emails`
    const lableText = this.props.modal === 'ESG' ? 'Esg': 'Insights'
    const modalhelpText = this.props.modal === 'ESG' ? 'Topics': 'Subfactors'
    const bulkType =   this.props.modal === 'ESG' ? 'topics' : 'subfactors'

    const factorColumns = availableFactors
      ? availableFactors.map((factor, factorIndex)=>{
        const columnNumber = factorIndex % 3
        const factorName = factor.factorDisplayName
        const factorId = factor.factorId
        const subFactorCount = factor.subFactors.length - 1
        const selectedSubFactorCount = factorsConfig.subFactors.filter(
          sub => sub.factorId === factorId).length

        const subFactors = factor.subFactors.map((subFactor, subIndex)=>{
          const inputId = subFactor.pk
            ? subFactor.pk
            : factorId + '-select-all'

          const allSelected = subFactorCount === selectedSubFactorCount
          const subSelected = allSelected && !subFactor.pk
            ? true
            : allSelected
              ? false
              : selectedSubFactorIds.includes(subFactor.pk)

          const selectAllInput = !subFactor.pk
          const addSubFactor =
            {
              pk: inputId, factorId: factorId,
              displayName: subFactor.displayName,
              factorDisplayName: factorName
            }
          return (
            <div key={subIndex} >
              <InputBlock
                className={'sub-factor-input'}
                label={subFactor.displayName}
                isInline={true}
              >
                <input
                  id={inputId}
                  type="checkbox"
                  checked={subSelected}
                  onChange={(event) => this.onSubFactorClick({selectAllInput, allSelected, factorId,
                    event, factorsConfig, addSubFactor}, inputId)
                  }
                />
              </InputBlock>
            </div>
          )
        })
        return(
          <div key={factorIndex}
               className={'factor-column '+'column-'+columnNumber}
          >
            <div id={factorId} className={'factor-name'}>
              {factorName}
            </div>
            {subFactors}
          </div>
        )
      })
      : null
    const modalData = factorsConfig
      ? <div className={'factors-modal-container'}>
          <div className={'modal-title'}>
            <span>Choose {modalhelpText} for {searchName}</span>
          </div>
          <div className={'modal-help-text'}>
            {lableText} will only email you {lableText} articles that
            are in the {modalhelpText.toLowerCase()} that you select.
          </div>
          <div className={'factor-columns'}>
            {factorColumns}
          </div>
          <InputBlock
            className={'bulk-update-input'}
            label={bulkUpdateText}
            isInline={true}
          >
            <input
              type="checkbox"
              checked={this.state.bulkApply}
              onChange={(event) => this.onBulkApplyChange(event)
              }
            />
          </InputBlock>
          <div className={'action-row'}>
            <Button className="save-factors-button"
              isPrimary={true}
              label={this.props.modal === 'ESG' ? 'Save Topic Settings':'Save Subfactor Settings'}
              onClick={() => {this.onSaveFactorSettingsClick(factorsConfig)}}
            />
            <Button className="bulk-apply-button"
              isPrimary={true}
              label={'Bulk Apply Settings'}
              onClick={() => {this.onBulkApplySettingsClick(factorsConfig)}}
            />
            <div>
              <a className="cancel"
                 onClick={() => {this.onFactorsModalClose()}}>
                Cancel
              </a>
            </div>
          </div>

        </div>
      : null
    return (
      <Modal
        className={'subfactors-modal'}
        id="notification-factors-modal"
        isOpen={this.props.showFactorsModal}
        onClose={this.onFactorsModalClose.bind(this)}
      >
        {modalData}
      </Modal>
    )
  }
  onFactorsModalClose() {
    this.props.changeFactorsFunc(null)
    this.props.changeShowModalFunc(false)
  }
  onSubFactorClick({selectAllInput, allSelected, factorId, event, factorsConfig, addSubFactor},
                     subFactorId) {
    let updateFactors = {}
    if (selectAllInput) {
      updateFactors = factorsConfig.subFactors.filter(
        sub => sub.factorId !== factorId)
      if (event.target.checked) {
        const addFactor = this.props.availableFactors.filter(
          factor => factor.factorId === factorId)
        addFactor.map(factor => {
          factor.subFactors.map(sub => {
            if (sub.pk) {updateFactors.push(sub)}
          })
        })
      }
    } else {
      updateFactors = allSelected
        ? factorsConfig.subFactors.filter(
          sub => sub.factorId !== factorId)
        : factorsConfig.subFactors.filter(
          sub => sub.pk !== subFactorId)

      if (event.target.checked) {
        updateFactors.push(addSubFactor)
      }
    }

    this.props.changeFactorsFunc({...factorsConfig, subFactors:updateFactors})
    this.props.changeShowModalFunc(true)
  }

  onBulkApplyChange(event) {
    this.setState({bulkApply: event.target.checked})
  }

  onSaveFactorSettingsClick(factorsConfig) {
    this.props.saveConfigsFunc({
      editUserId: this.props.editUserId,
      type: this.props.notificationType,
      field: constants.SUB_FACTORS,
      value: factorsConfig.subFactors,
      configIds: [factorsConfig.id],
      bulkApplySubfactors: this.state.bulkApply
    })
    this.setState({bulkApply: false})
  }

  onBulkApplySettingsClick(factorsConfig) {
    this.props.changeBulkUpdateConfigsFunc({
      configIds: [factorsConfig.id],
      frequency: factorsConfig.frequency,
      notificationType: this.props.notificationType
    })
    this.props.changeShowBulkUpdateFunc({
      show: true,
      category: factorsConfig.category,
      frequency: factorsConfig.frequency,
      bulkType: 'subfactors',
      subfactors: factorsConfig.subFactors,
    })
    this.setState({bulkApply: false})
    this.onFactorsModalClose()
  }

}
