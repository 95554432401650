import React from 'react'
import Dropdown, { Option } from 'app/common/Dropdown'


export default ({showAllDepartments = true, departments, ...restProps}) => {

  const allDepartments =
    showAllDepartments && <Option key="all" label="All Departments" />

  const options = departments.map(department =>
    <Option
      key={department.id}
      label={department.name}
      value={department.id}
    />
  )

  return (
    <Dropdown {...restProps}>
      {allDepartments}
      {options}
    </Dropdown>
  )
}
