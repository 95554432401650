import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Tab, TabbedContainer } from 'app/common/TabbedContainer'

import DetailsContent from './DetailsContent'
import SharingContent from '../EditModal/SharingContent'
import UsedByContent from '../EditModal/UsedByContent'

const TABS = {
  DETAILS: 'details',
  SHARING: 'sharing',
  USED_BY: 'used-by',
}


export default class EditSourceFolderModalContent extends Component {
  static TABS = TABS

  static propTypes = {
    search: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  render() {
    const {search, closeModal, defaultTab} = this.props
    return (
      <TabbedContainer defaultTabName={defaultTab} useUpdatedCss>
        <Tab name={TABS.DETAILS} label="Details">
          <DetailsContent search={search} closeModal={closeModal} />
        </Tab>
        <Tab name={TABS.SHARING} label="Profiles">
          <SharingContent search={search} closeModal={closeModal} />
        </Tab>
        <Tab name={TABS.USED_BY} label="Used By">
          <UsedByContent search={search} closeModal={closeModal} />
        </Tab>
      </TabbedContainer>
    )
  }
}
