import React, {Component} from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import classNames from 'classnames'

import Table from 'app/common/Table'
import ConfirmationModal from 'app/common/modals/ConfirmationModal'
import Button from 'app/common/Button'

import * as styles from './ExcludedFeedsList.less'


export default class ExcludedFeedsList extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
  }

  state = {
    deleteIds: [],
    selectedIds: [],
  }

  render() {
    const {excludedFeeds} = this.props.search

    const selectedText = this.state.deleteIds.length === 1 ? 'this' : 'the selected'

    const sourceText = this.state.deleteIds.length === 1 ? 'Source' : 'Sources'

    const deleteConfirmationModal = this.state.deleteIds.length > 0 &&
      <ConfirmationModal
        message={`Are you sure you want to remove ${selectedText} Excluded ${sourceText}?`}
        secondaryMessage={`If you proceed, articles from ${selectedText} ${sourceText} will be included in the results for this search when applicable.`}
        confirmButtonText="Remove"
        onConfirm={() => this.handleConfirmDeleteSelectedButtonClick()}
        onClose={() => this.setState({deleteIds: []})}
        isDestructive={true}
      />

    return (
      <div>
        {
          excludedFeeds.length === 0
          ? <p>There are no excluded sources assigned to this search.</p>
          : <React.Fragment>
              <div className={styles.bulkActions}>
                <input
                  type="checkbox"
                  className={styles.selectAllCheckbox}
                  checked={this.state.selectedIds.length === excludedFeeds.length}
                  onChange={(event) => this.handleSelectAllCheckbox(event.target.checked)}
                />

                <Button
                  label="Delete Selected"
                  disabled={this.state.selectedIds.length === 0}
                  onClick={() => this.handleDeleteSelectedButtonClick()}
                />
              </div>

              <Table
                data={excludedFeeds}
                includeHeader={false}
              >
                <Table.Column
                  name="checkbox"
                  baseWidth="25px"
                  growRatio={0}
                  cellContents={feed => {
                    return (
                      <input
                        type="checkbox"
                        checked={!!this.state.selectedIds.find(id => id === feed.id)}
                        onChange={(event) => this.handleItemCheckbox(feed, event.target.checked)}
                      />
                    )
                  }}
                />

                <Table.Column
                  name="name"
                  baseWidth="50%"
                  cellContents={R.prop('name')}
                />

                <Table.Column
                  name="actions"
                  baseWidth="40%"
                  className={styles.actions}
                  cellContents={feed =>
                    <i
                      className={classNames('fa', 'fa-trash-o', styles.actionButton)}
                      onClick={() => this.handleDeleteButtonClick(feed)}
                    />
                  }
                />
              </Table>

              {deleteConfirmationModal}
            </React.Fragment>
        }
      </div>
    )
  }

  handleDeleteButtonClick(feed) {
    this.setState({deleteIds: [feed.id]})
  }

  handleDeleteSelectedButtonClick() {
    this.setState({deleteIds: [...this.state.selectedIds]})
  }

  handleItemCheckbox(feed, checked) {
    let selectedIds = [...this.state.selectedIds]
    if (checked) {
      selectedIds.push(feed.id)
    } else {
      selectedIds = selectedIds.filter(id => id !== feed.id)
    }
    this.setState({selectedIds})
  }

  handleSelectAllCheckbox(checked) {
    let selectedIds = []
    if (checked) {
      selectedIds = this.props.search.excludedFeeds.map(feed => feed.id)
    }
    this.setState({selectedIds})
  }

  handleConfirmDeleteSelectedButtonClick() {
    this.props.onDelete([...this.state.deleteIds])
    this.setState({selectedIds: [], deleteIds: []})
  }
}
