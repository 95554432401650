import React, { useState } from "react";
import PropTypes from 'prop-types'
import cn from 'classnames'
import './styles.less'

const Accordion = (props) => {

  const [open, setOpen] = useState(false);
  const toggleHandler = (e) => setOpen(!open)

  return (
    <div className="accordion" >
      <div className="accordion-header" onClick={toggleHandler}>
        <div className={cn("accordion-title", props.textClassName)}>{props.title}</div>
        <div className={open ? "accordion-arrowDown" : "accordion-arrowUp"} />
      </div>
      <div className="accordion-content">
        {open ? props.children : null}
      </div>
    </div>
  )
}

Accordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  textClassName: PropTypes.any,
}

export default Accordion;
