import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Dropdown from 'app/common/Dropdown'

import './DateSort.less'

const VALUES = {
  RELEVANCE: 'relevance',
  DATE_DESC: 'date-desc',
  DATE_ASC: 'date-asc',
}


export default class DateSort extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    value: 'relevance',
  }

  static VALUES = VALUES

  render() {
    const {value, onChange, className, ...props} = this.props
    const options = [
      {
        label: "Relevance",
        value: VALUES.RELEVANCE,
        description: "Articles are ordered based upon relevance score and similar articles are grouped together.",
      },
      {
        label: "Newest First",
        value: VALUES.DATE_DESC,
        description: "Articles with the most recent date and time are sorted to the top. Similar articles are not grouped together.",
      },
      {
        label: "Oldest First",
        value: VALUES.DATE_ASC,
        description: "Articles with the oldest date and time are sorted to the top. Similar articles are not grouped together.",
      },
    ]
    const sortClass = this.props.value === VALUES.DATE_DESC
      ? 'sort-desc'
      : this.props.value === VALUES.DATE_ASC
        ? 'sort-asc'
        : 'sort-relevancy'
    return (
      <Dropdown
        options={options}
        value={this.props.value}
        onChange={this.props.onChange}
        optionRenderer={this.renderOption}
        className={`${sortClass} date-sort`}
        {...props}
      />
    )
  }

  renderOption(option) {
    return (
      <>
        <div className="title">{option.label}</div>
        <p className="description">{option.description}</p>
      </>
    )
  }
}
