import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import classNames from 'classnames'

import { getEntities } from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import { Feed } from 'app/models'
import Modal from 'app/common/Modal'
import Button from 'app/common/Button'
import InputBlock from 'app/common/InputBlock'
import TextBox from 'app/common/TextBox'
import Table from 'app/common/Table'
import Dropdown from 'app/common/Dropdown'
import {
  setIsLabelModalShown,
  updateLabelModalData,
  addLabels,
  removeLabels,
  setManageLabelsFilters,
  updateBulkEditLabels
} from '../sources-admin-actions'
import { getSourcesAdmin } from '../sources-admin-selectors'
import {
  MANAGE_LABELS_SECTION, MANAGE_LABELS_ACTIONS,
  CHECKBOX_ACTIONS, LABEL_NAMES, LABEL_MODAL_TABS as TABS, PAGINATION_OPTIONS
} from '../sources-admin-constants'
import * as styles from './LabelModal.less'
import PaginatedTable from 'app/common/PaginatedTable'
import snakeCase from 'snake-case'
import ConfirmationModal from 'app/common/modals/ConfirmationModal'


@connect(
  createSelector(
    [getEntities, getSourcesAdmin],
    (entities, sourcesAdmin) => {
      const orm = Orm.withEntities(entities)
      return {
        isShown: sourcesAdmin.isLabelModalShown,
        labels: sourcesAdmin.labels,
        labelModalData: sourcesAdmin.labelModalData,
        selectedFeedIds: sourcesAdmin.selectedFeedIds,
        selectedFeeds: orm.getByIds(Feed, sourcesAdmin.selectedFeedIds),
        isLoading: sourcesAdmin.isLoading,
        manageLabelsFilter: sourcesAdmin.manageLabelsFilter,
        manageLabelsTotalCount: sourcesAdmin.manageLabelsTotalCount,
      }
    }
  ),
  {
    setIsLabelModalShown,
    updateLabelModalData,
    addLabels,
    removeLabels,
    setManageLabelsFilters,
    updateBulkEditLabels
  },
)

export default class LabelModal extends Component {
  state = {
    selectedIds: [],
    deletedIds: [],
    confirmModal: { status: false, label: null },
    saveconfirmModal: { status: false, labelId: null, labelName: null },
    manageLabelsFilters: {
      currentPage: 1,
      itemsPerPage: 10,
      searchLabel: '',
    },
    expandItems: [],
    collapseState: [],
  }

  render() {
    const feedCount = this.props.selectedFeeds.length
    const saveConfirmationModal = this.state.saveconfirmModal.status && (
      <ConfirmationModal
        message={`Are you sure you want to save selected labels for this Source?`}
        confirmButtonText="Yes"
        cancelButtonText="No"
        onConfirm={this.handleSaveConfirm}
        onClose={this.handleSaveCancel}
        isDestructive={true}
      />
    )
    return (
      <React.Fragment>
        <Modal
          id="label-modal"
          isOpen={this.props.isShown}
          onClose={() => this.setState({ selectedIds: [], deletedIds: [] }, () => { this.props.setIsLabelModalShown(false) })}
        >
          <h1>Edit labels for {feedCount} selected Sources</h1>
          {this.tabContent}

          <div className="buttons">
            <Button
              label={MANAGE_LABELS_ACTIONS.CANCEL}
              isGrey
              className={styles.cancelBtn}
              onClick={this.hideBulkEditModal}
            />
            <Button
              label={MANAGE_LABELS_ACTIONS.SAVE}
              className={styles.saveBtn}
              onClick={this.handleSaveModal}
            />
          </div>
        </Modal>
        {saveConfirmationModal}
      </React.Fragment>
    )
  }

  sortedLabels(id = null) {
    const updatedLabels = []
    if (this.props.labels === null) {
      return updatedLabels
    }
    const bulkEditLabels = []
    this.props.selectedFeeds.forEach(feed => {
      feed.labels.forEach(label => {
        bulkEditLabels.push(label.id)
      })
    })
    this.props.labels.forEach(label => {

      let haschildLabelIds = false
      label.childLabels.map(childLabel => {
        if (bulkEditLabels.includes(childLabel.id)) {
          haschildLabelIds = true
          return
        }
      })
      
      if ((haschildLabelIds && !this.state.collapseState.includes(label.id)) || this.state.expandItems.includes(label.id)) {
        updatedLabels.push({ ...label, isExpand: true })
        var lastChild = label.childLabels.length - 1
        label.childLabels.forEach((childLabel, index) => {
          if (lastChild === index) {
            updatedLabels.push({ ...childLabel, isLastChild: true })
          } else {
            updatedLabels.push({ ...childLabel, isLastChild: false })
          }
        })
      } else {
        updatedLabels.push({ ...label, isExpand: false })
      }
    })

    const labelsWithCheckBoxState = updatedLabels.map(label => {
      const checkBoxState = this.labelIsEnabled(label)
      return ({
        ...label, ...checkBoxState

      })
    })
    return labelsWithCheckBoxState

  }

  get tabContent() {
    const pageCount = Math.ceil(this.props.manageLabelsTotalCount / this.props.manageLabelsFilter.itemsPerPage)
    const paginationOptions = Object.entries(PAGINATION_OPTIONS).map(item => {
      const [value, label] = item
      return {
        label,
        value,
      }
    })
    return (
      <React.Fragment>
        <div className="label-detail">{CHECKBOX_ACTIONS.CHECKBOX_DESCRIPTION}</div>
        <div className="label-desc">
          <div className="label-desc-content">{CHECKBOX_ACTIONS.CHECKBOX_TICK}</div>
          <div className="label-desc-content">{CHECKBOX_ACTIONS.CHECKBOX_CLEAR}.</div>
          <div className="label-desc-content">{CHECKBOX_ACTIONS.CHECKBOX_LINE}</div>
        </div>

        <div className="filter-container">
          <InputBlock label="Filter by Label">
            <TextBox
              onChange={evt => this.handleFilterChange('searchLabel', evt.target.value)} />
          </InputBlock>
          <div className="page-size">
            <span>{MANAGE_LABELS_ACTIONS.SHOW}</span>
            <Dropdown
              className="page-size-drop-down"
              type={'text'}
              value={this.state.pageSize}
              options={paginationOptions}
              onChange={(size) => this.handleOnPageSizeChange(size)}
            />
          </div>
        </div>

        <div className="label-container">
          <div className="label-header">{LABEL_NAMES.ALL_LABELS}</div>
        </div>

        <PaginatedTable
          id="bulk-edit-labels-table"
          defaultSort={{ column: 'name', direction: 'asc' }}
          data={this.sortedLabels() || []}
          pageCount={pageCount}
          tab="Sources"
          currentPage={this.props.manageLabelsFilter.currentPage}
          onPageChange={(page) => this.onPageChange(page)}
          onSortChange={(sortOptions) => this.onSortChange(sortOptions)}
          getRowStyle={(label) => this.borderStyle(label)} >

          <Table.Column
            name="cell"
            baseWidth="5%"
            cellContents={label => {
              return (
                <div
                  className={classNames({
                    [styles.arrowRight]:
                      !label.parent &&
                      !label.isExpand &&
                      label.childLabels &&
                      label.childLabels.length > 0,
                    [styles.arrowDown]: !label.parent && label.isExpand,
                  })}
                  onClick={() => this.handleColumnExpand(label)}
                ></div>
              )
            }} />
          <Table.Column
            label="Name"
            name="name"
            baseWidth="95%"
            isSortable
            cellContents={label => {
              return (
                <div className={classNames(styles.cellContent, { [styles.childLabelName]: label.parent })}>
                  <input
                    type="checkbox"
                    checked={label.isChecked}
                    ref={input => {
                      if (input) {
                        input.indeterminate = label.isIndeterminate;
                      }
                    }}
                    onChange={(event) => this.handleCheckbox(label.id, event.target.checked)}
                  />
                  <div>{label.name}</div>
                </div>
              )
            }}
          />
        </PaginatedTable>
        {!this.props.isLoading && this.sortedLabels().length === 0 ? <div className={styles.noData}>{MANAGE_LABELS_SECTION.NO_DATA}</div> : ''}
      </React.Fragment>
    )
  }

  borderStyle(label) {
    if (!label.parent && label.isExpand) {
      return 'borderBottomStyle'
    } else if (label.parent && !label.isLastChild) {
      return 'borderBottomStyle'
    } else {
      return ''
    }
  }

  handleOnPageSizeChange = (size) => {
    const count = Number(size)
    const manageLabelsFilters = { ...this.state.manageLabelsFilters, currentPage: 1, itemsPerPage: count }
    this.setState({ expandItems: [] }, () => { this.props.setManageLabelsFilters(manageLabelsFilters) })
  }

  onPageChange(page) {
    const manageLabelsFilters = { ...this.state.manageLabelsFilters, currentPage: page }
    this.setState({ expandItems: [] }, () => { this.props.setManageLabelsFilters(manageLabelsFilters) })
  }

  onSortChange(sortOptions) {
    const field = snakeCase(sortOptions.column)
    this.props.setManageLabelsFilters({
      sortField: field,
      sortDirection: sortOptions.direction,
    })
  }

  handleFilterChange = (key, value) => {
    const manageLabelsFilters = { ...this.state.manageLabelsFilters, currentPage: 1, [key]: value }
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(function () {
      if (value.length >= 1) {
        this.setState({ manageLabelsFilters, expandItems: [] })
      } else if (value.length === 0) {
        this.setState({ manageLabelsFilters, expandItems: [] })
      }
      this.props.setManageLabelsFilters(manageLabelsFilters)
    }.bind(this), 400);
  }

  handleColumnExpand(label) {
    const expandState = label.isExpand
    if (expandState) {
      const expandItem = this.state.expandItems.filter(item => item !== label.id)
      this.setState({ collapseState: [...this.state.collapseState, label.id], expandItems: [...expandItem] })
    } else if (!expandState && label.childLabels.length !== 0) {
      const collapseItems = this.state.collapseState.filter(item => item !== label.id)
      this.setState({ collapseState: [...collapseItems], expandItems: [...this.state.expandItems, label.id] })
    }
  }

  handleFilterByLabel(value) {
    let tempAllLabels = [...this.props.labels]
    let allLabels = tempAllLabels ? tempAllLabels.filter(label =>
      label.name.toLowerCase().includes(value.toLowerCase()),
    ) : ''

    if (allLabels) {
      this.setState({ allLabels })
    } else {
      this.setState(this.props.labels)
    }
  }

  labelIsEnabled(updatedlabel) {
    const checkBoxState = { isChecked: false, isIndeterminate: false }
    // only labels shared by all selected sources should show a tick checkbox
    const checkbox_tick = this.props.selectedFeeds.every(feed => {
      const hasLabel = feed.labels.some(label => label.id === updatedlabel.id)
      return hasLabel
    })
    //only labels present in atleast one selected source should a indeterminate checkbox
    let checkbox_line = false
    this.props.selectedFeeds.map(feed => {
      const hasLabel = feed.labels.find(label => label.id === updatedlabel.id)
      if (hasLabel) {
        checkbox_line = true
      }
    })
    if (!this.state.selectedIds.includes(updatedlabel.id) && !this.state.deletedIds.includes(updatedlabel.id)) {
      if (checkbox_tick) {
        checkBoxState['isChecked'] = true
      } else if (checkbox_line) {
        checkBoxState['isIndeterminate'] = true
      }
    } else if (this.state.selectedIds.includes(updatedlabel.id)) {
      checkBoxState['isChecked'] = true
    }

    return checkBoxState
  }

  handleCheckbox(id, checked) {
    let selectedIds = [...this.state.selectedIds]
    let deletedIds = [...this.state.deletedIds]
    if (checked) {
      selectedIds.push(id)
    } else {
      selectedIds = selectedIds.filter(_id => _id !== id)
      deletedIds.push(id)
    }
    this.setState({ selectedIds, deletedIds })
  }

  handleGoButtonClick() {
    if (this.props.labelModalData.currentTab === TABS.ADD) {
      this.props.addLabels()
    } else {
      this.props.removeLabels({
        feedIds: this.props.selectedFeedIds,
        labelIds: this.props.labelModalData.selectedIds,
      })
    }
  }

  hideBulkEditModal = () => {
    this.setState({ selectedIds: [], deletedIds: [] }, () => { this.props.setIsLabelModalShown(false) })
  }

  handleSaveModal = () => {
    this.setState({
      saveconfirmModal: { status: true, labelId: null, labelName: null },
    })
  }

  handleSaveConfirm = () => {
    const selectedIds = this.state.selectedIds
    const deletedIds = this.state.deletedIds
    const selectedFeedIds = this.props.selectedFeedIds
    this.props.updateBulkEditLabels({ selectedIds, deletedIds, selectedFeedIds })
    this.setState({
      saveconfirmModal: { status: false, labelId: null, labelName: null },
    })
  }

  handleSaveCancel = () => {
    this.setState({
      saveconfirmModal: { status: false, labelId: null, labelName: null },
    })
  }
}





