import {createAction} from 'redux-actions'

const PREFIX = 'PROFILE_SOURCES'
const action = name => createAction(`${PREFIX}_${name}`)

export const init = action('INIT')
export const initComplete = action('INIT_COMPLETE')
export const setIsLoading = action('SET_IS_LOADING')
export const setFilter = action('SET_FILTER')
export const setFilterField = action('SET_FILTER_FIELD')
export const setGlobalFeedIds = action('SET_GLOBAL_FEED_IDS')
export const setSelectedFirmFeedIds = action('SET_SELECTED_FIRM_FEED_IDS')
export const setSelectedGlobalFeedIds = action('SET_SELECTED_GLOBAL FEED_IDS')
export const fetchFeaturedSources = action('FETCH_FEATURED_SOURCES')
export const fetchFeaturedSourcesComplete = action(
  'FETCH_FEATURED_SOURCES_COMPLETE',
)
export const fetchFeeds = action('FETCH_FEEDS')
export const fetchFeedsComplete = action('FETCH_FEEDS_COMPLETE')
export const fetchFeedAssignments = action('FETCH_FEED_ASSIGNMENTS')
export const fetchFeedAssignmentsComplete = action(
  'FETCH_FEED_ASSIGNMENTS_COMPLETE',
)
export const fetchPromotedFeeds = action('FETCH_PROMOTED_FEEDS')
export const fetchPromotedFeedsComplete = action(
  'FETCH_PROMOTED_FEEDS_COMPLETE',
)
export const fetchDemotedFeeds = action('FETCH_DEMOTED_FEEDS')
export const fetchDemotedFeedsComplete = action('FETCH_DEMOTED_FEEDS_COMPLETE')
export const fetchTrustedFeeds = action('FETCH_TRUSTED_FEEDS')
export const fetchTrustedFeedsComplete = action('FETCH_TRUSTED_FEEDS_COMPLETE')
export const fetchTrustedFolders = action('FETCH_TRUSTED_FOLDERS')
export const fetchTrustedFoldersComplete = action(
  'FETCH_TRUSTED_FOLDERS_COMPLETE',
)
export const fetchExcludedFeeds = action('FETCH_EXCLUDED_FEEDS')
export const fetchExcludedFeedsComplete = action(
  'FETCH_EXCLUDED_FEEDS_COMPLETE',
)
export const fetchNlaLicense = action('FECTH_NLA_LICENSE')
export const fetchNlaFeedIds = action('FECTH_NLA_FEED_IDS')
export const promoteSelectedFeeds = action('PROMOTE_SELECTED_FEEDS')
export const promoteSelectedFeedsComplete = action(
  'PROMOTE_SELECTED_FEEDS_COMPLETE',
)
export const demoteSelectedFeeds = action('DEMOTE_SELECTED_FEEDS')
export const demoteSelectedFeedsComplete = action(
  'DEMOTE_SELECTED_FEEDS_COMPLETE',
)
export const trustSelectedFeeds = action('TRUST_SELECTED_FEEDS')
export const trustSelectedFeedsComplete = action(
  'TRUST_SELECTED_FEEDS_COMPLETE',
)
export const excludeSelectedFeeds = action('EXCLUDE_SELECTED_FEEDS')
export const excludeSelectedFeedsComplete = action(
  'EXCLUDE_SELECTED_FEEDS_COMPLETE',
)
export const showAddToFolderModal = action('SHOW_ADD_TO_FOLDER_MODAL')
export const hideAddToFolderModal = action('HIDE_ADD_TO_FOLDER_MODAL')
export const showModalCreateFolder = action('SHOW_MODAL_CREATE_FOLDER')
export const hideModalCreateFolder = action('HIDE_MODAL_CREATE_FOLDER')
export const setSelectedFolderIds = action('SET_SELECTED_FOLDER_IDS')
export const setNewFolderName = action('SET_NEW_FOLDER_NAME')
export const createFolder = action('CREATE_FOLDER')
export const createFolderComplete = action('CREATE_FOLDER_COMPLETE')
export const addFeedsToFolders = action('ADD_FEEDS_TO_FOLDERS')
export const addFeedsToFoldersComplete = action('ADD_FEEDS_TO_FOLDERS_COMPLETE')
export const deletePromotedFeed = action('DELETE_PROMOTED_FEED')
export const deletePromotedFeedComplete = action(
  'DELETE_PROMOTED_FEED_COMPLETE',
)
export const deleteDemotedFeed = action('DELETE_DEMOTED_FEED')
export const deleteDemotedFeedComplete = action('DELETE_DEMOTED_FEED_COMPLETE')
export const deleteTrustedFolder = action('DELETE_TRUSTED_FOLDER')
export const deleteTrustedFolderComplete = action(
  'DELETE_TRUSTED_FOLDER_COMPLETE',
)
export const deleteTrustedFeed = action('DELETE_TRUSTED_FEED')
export const deleteTrustedFeedComplete = action('DELETE_TRUSTED_FEED_COMPLETE')
export const removeFeedFromTrustedFolder = action(
  'REMOVE_FEED_FROM_TRUSTED_FOLDER',
)
export const removeFeedFromTrustedFolderComplete = action(
  'REMOVE_FEED_FROM_TRUSTED_FOLDER_COMPLETE',
)
export const deleteExcludedFeed = action('DELETE_EXCLUDED_FEED')
export const deleteExcludedFeedComplete = action(
  'DELETE_EXCLUDED_FEED_COMPLETE',
)
export const setLimitedSeatModalFeedId = action(
  'SET_LIMITED_SEAT_MODAL_FEED_ID',
)
export const setLimitedSeatModalNotes = action('SET_LIMITED_SEAT_MODAL_NOTES')
export const submitLimitedSeatRequest = action('SUBMIT_LIMITED_SEAT_REQUEST')
export const submitLimitedSeatRequestComplete = action(
  'SUBMIT_LIMITED_SEAT_REQUEST_COMPLETE',
)
export const showCreateFolder = action('SHOW_CREATE_FOLDER')
export const hideCreateFolder = action('HIDE_CREATE_FOLDER')
export const promoteFeed = action('PROMOTE_FEED')
export const demoteFeeds = action('DEMOTE_FEEDS')
export const trustFeed = action('TRUST_FEED')
export const excludeFeed = action('EXCLUDE_FEED')
export const addFeedToFolder = action('ADD_FEED_TO_FOLDER')
export const setDeleteFolderConfirmationModalSearchId = action(
  'SET_DELETE_FOLDER_CONFIRMATION_MODAL_SEARCH_ID',
)
export const setEditFolderSearchId = action('SET_EDIT_FOLDER_SEARCH_ID')
export const saveFolderName = action('SAVE_FOLDER_NAME')
export const saveFolderNameComplete = action('SAVE_FOLDER_NAME_COMPLETE')
export const moveFeed = action('MOVE_FEED')
export const setIsTrustedLoader = action('SET_IS_TRUSTED_LOADER')

export const setSelectedPromotedFeedIds = action('SET_SELECTED_PROMOTED_FEED_IDS')
export const setSelectedExcludedFeedIds = action('SET_SELECTED_EXCLUDED_SOURCE_IDS')
export const setSelectedDemotedFeedIds = action('SET_SELECTED_DEMOTED_SOURCE_IDS')
export const setSelectedTrustedFeedIds = action('SET_SELECTED_TRUSTED_SOURCE_IDS')
export const setSelectedTrustedFolderIds = action('SET_SELECTED_TRUSTED_FOLDER_IDS')

export const deleteTrustedFeedsAndFolders = action('DELETE_TRUSTED_FEEDS_AND_FOLDERS')
export const deleteTrustedFeedsAndFoldersComplete = action('DELETE_TRUSTED_FEEDS_AND_FOLDERS_COMPLETE')
