import {graphqlQuery} from 'app/api'
import {args, gql} from 'app/graphql'

export function fetchFeaturedSources() {
  return graphqlQuery(
    gql`
      query {
        currentUser {
          featuredSources {
            id
            feedId
          }
        }
      }
    `,
  ).then(({body: {data}}) => data.currentUser)
}

export function fetchFeeds() {
  return graphqlQuery(
    gql`
      query {
        feeds(isPaged: false) {
          items {
            id
            name
            isLimitedSeat
            url
            absoluteUrl
            labels {
              id
              name
            }
          }
          excludedFeedIds
          nlaLicenseExpired
          nlaFeedIds
          
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function fetchGlobalFeeds({filter, filterField}) {
  return graphqlQuery(
    gql`
      query {
        globalFeeds(${args({filter, filterField, maxResults: 100})}) {
          id
          name
          absoluteUrl
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function fetchFeedAssignments() {
  return graphqlQuery(
    gql`
      query {
        sourceAssignments(isLimitedSeat: true) {
          feedId
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function fetchPromotedFeeds() {
  return graphqlQuery(
    gql`
      query {
        feeds: promotedSources() {
          id
          name
          absoluteUrl
          labels {
            id
            name
          }
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function fetchDemotedFeeds() {
  return graphqlQuery(
    gql`
      query {
        feeds: demotedSources() {
          id
          name
          absoluteUrl
          labels {
            id
            name
          }
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function fetchTrustedFeeds() {
  // note, trusted feeds are stored as saved searches.
  return graphqlQuery(
    gql`
      query {
        searches: trustedSources() {
          id
          name
          aboutSources {
            id
            name
            absoluteUrl
            labels {
              id
              name
            }
          }
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function fetchTrustedFolders() {
  // note, trusted folders are stored as saved searches.
  return graphqlQuery(
    gql`
      query {
        searches: trustedSourceFolders() {
          id
          name
          aboutSources {
            id
            name
            absoluteUrl
            labels {
              id
              name
            }
          }
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function fetchExcludedFeeds() {
  return graphqlQuery(
    gql`
      query {
        feeds: excludedSources() {
          id
          name
          absoluteUrl
          labels {
            id
            name
          }
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function promoteFeeds(feedIds) {
  const query = feedIds
    .map(feedId => {
      return `
      mutation {
        createPromotedSource(
          feedId: ${feedId},
        ) {
          success
        }
      }
    `
    })
    .join('')
  return graphqlQuery(query).then(({body: {data}}) => data)
}

export function trustFeeds(feedIds) {
   return graphqlQuery(
    gql`
      mutation {
        addBulkTrustedSource(
          feedIds: ${feedIds},
        ) {
          id
          name
        }
      }
    `,
  ).then(({body: {data}}) => data)
}


export function excludeFeeds(feedIds) {
  const query = feedIds
    .map(feedId => {
      return `
      mutation {
        createExcludedSource(
          feedId: ${feedId},
        ) {
          success
        }
      }
    `
    })
    .join('')
  return graphqlQuery(query).then(({body: {data}}) => data)
}

export function createFolder(name) {
  return graphqlQuery(
    gql`
      mutation {
        trustedSourceFolder: createTrustedSourceFolder(name: ${name}) {
          id
          name
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function addFeedsToFolders({feedIds, searchIds}) {
  const queries = []
  feedIds.forEach(feedId => {
    searchIds.forEach(searchId => {
      queries.push(`
        mutation {
          addSourceToTrustedFolder(
            feedId: ${feedId},
            searchId: ${searchId},
          ) {
            success
          }
        }
      `)
    })
  })
  const query = queries.join('')
  return graphqlQuery(
    query
  ).then(({body: {data}}) => data)
}

export function deletePromotedFeed({id}) {
  let promotedSourceId = id
  if (!Array.isArray(promotedSourceId)) {
    promotedSourceId = [promotedSourceId]
  }
  promotedSourceId = promotedSourceId.flat()
  return graphqlQuery(
    gql`
      mutation {
        deletePromotedSource(feedId: ${promotedSourceId}) {
          success
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function deleteDemotedFeed({id}) {
  let demotedSourceId = id
  if (!Array.isArray(demotedSourceId)) {
    demotedSourceId = [demotedSourceId]
  }
  demotedSourceId = demotedSourceId.flat()
  return graphqlQuery(
    gql`
      mutation {
        deleteDemotedSource(feedId: ${demotedSourceId}) {
          success
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function deleteTrustedFeed({id}) {
  let trustedSourceId = id
  if (!Array.isArray(trustedSourceId)) {
    trustedSourceId = [trustedSourceId]
  }
  trustedSourceId = trustedSourceId.flat()
    return graphqlQuery(
    gql`
      mutation {
        deleteTrustedSource(searchId: ${trustedSourceId}) {
          id
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function deleteTrustedFolder({id}) {
  let trustedFolderId = id
  if (!Array.isArray(trustedFolderId)) {
    trustedFolderId = [trustedFolderId]
  }
  trustedFolderId = trustedFolderId.flat()
    return graphqlQuery(
    gql`
      mutation {
        deleteTrustedSourceFolder(searchId: ${trustedFolderId}) {
          success
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function deleteExcludedFeed({id}) {
  let excludedSourceId = id
  if (!Array.isArray(excludedSourceId)) {
    excludedSourceId = [excludedSourceId]
  }
  excludedSourceId = excludedSourceId.flat()
  return graphqlQuery(
    gql`
      mutation {
        deleteExcludedSource(feedId: ${excludedSourceId}) {
          success
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function removeFeedFromFolder({id, searchId}) {
  return graphqlQuery(
    gql`
      mutation {
        removeSourceFromTrustedFolder(
          feedId: ${id},
          searchId: ${searchId}
        ) {
          success
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function submitLimitedSeatRequest({feedId, notes}) {
  return graphqlQuery(
    gql`
      mutation {
        submitLimitedSeatRequest(
          feedId: ${feedId},
          notes: ${notes}
        ) {
          success
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function promoteFeed({id}) {
  return graphqlQuery(
    gql`
      mutation {
        createPromotedSource(feedId: ${id}) {
          success
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function demoteFeeds(feedIds) {
  if (!Array.isArray(feedIds)) {
    feedIds = [feedIds.id]
  }
  return graphqlQuery(
    gql`
      mutation {
        createDemotedSource(feedIds: ${feedIds}) {
          success
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function trustFeed({id}) {
  return graphqlQuery(
    gql`
      mutation {
        createTrustedSource(feedId: ${id}) {
          id
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function excludeFeed({id}) {
  return graphqlQuery(
    gql`
      mutation {
        createExcludedSource(feedId: ${id}) {
          success
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function addFeedToFolder({id, searchId}) {
  return graphqlQuery(
    gql`
      mutation {
        addSourceToTrustedFolder(
          feedId: ${id},
          searchId: ${searchId},
        ) {
          success
        }
      }
    `,
  ).then(({body: {data}}) => data)
}

export function saveFolderName({userId, searchId, name}) {
  return graphqlQuery(
    gql`
      mutation {
        saveSearch(
          userId: ${userId},
          id: ${searchId},
          name: ${name},
        ) {
          id
        }
      }
    `,
  ).then(({body: {data}}) => data)
}
