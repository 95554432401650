import React from 'react'

import Button from 'app/common/Button'
import * as urls from 'app/urls'

import styles from './NewSearchModal.less'


export default function NewSearchModalSuccess({onDone}) {
  return (
    <div className={styles.container}>
      <p>
        You can update your alert settings by clicking on the "Edit Search
        Settings" link located in the upper right hand corner of the results
        page, or by visiting the{' '}
        <a href={urls.emailSettings()}>Email Notifications page</a>.
      </p>

      <div className={styles.button}>
        <Button label="Done" isPrimary={true} onClick={() => onDone()} />
      </div>
    </div>
  )
}
