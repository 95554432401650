import { createAction } from 'redux-actions'

// Prefix action names with FLAGGED_ITEMS
const createFlaggedItemsAction = name => createAction(`FLAGGED_ITEMS_${name}`)

export const init = createFlaggedItemsAction('INIT')
export const setIsLoading = createFlaggedItemsAction('SET_IS_LOADING')
export const setHelpQuestions = createFlaggedItemsAction('SET_HELP_QUESTIONS')
export const fetchPromotedSearches = createFlaggedItemsAction('FETCH_PROMOTED_SEARCHES')
export const setPromotedFeedIds = createFlaggedItemsAction('SET_PROMOTED_FEED_IDS')
export const fetchCategories = createFlaggedItemsAction('FETCH_CATEGORIES')
export const setCategories = createFlaggedItemsAction('SET_CATEGORIES')
export const showCreateCategoryModal = createFlaggedItemsAction('SHOW_CREATE_CATEGORY_MODAL')
export const hideCreateCategoryModal = createFlaggedItemsAction('HIDE_CREATE_CATEGORY_MODAL')
export const createCategory = createFlaggedItemsAction('CREATE_CATEGORY')
export const setDeleteCategoryId = createFlaggedItemsAction('SET_DELETE_CATEGORY_ID')
export const deleteCategories = createFlaggedItemsAction('DELETE_CATEGORIES')
export const clearCategories = createFlaggedItemsAction('CLEAR_CATEGORIES')
export const deleteFlaggings = createFlaggedItemsAction('DELETE_FLAGGINGS')
export const updateEditCategoryData = createFlaggedItemsAction('UPDATE_EDIT_CATEGORY_DATA')
export const resetEditCategoryData = createFlaggedItemsAction('RESET_EDIT_CATEGORY_DATA')
export const saveCategory = createFlaggedItemsAction('SAVE_CATEGORY')
export const setSelectedCategoryIds = createFlaggedItemsAction('SET_SELECTED_CATEGORY_IDS')
export const setBulkAction = createFlaggedItemsAction('SET_BULK_ACTION')
export const setDeleteModalCategoryId = createFlaggedItemsAction('SET_DELETE_MODAL_CATEGORY_ID')
export const deleteArticles = createFlaggedItemsAction('DELETE_ARTICLES')
export const exportPDF = createFlaggedItemsAction('EXPORT_PDF')
export const exportDoc = createFlaggedItemsAction('EXPORT_DOC')
export const exportExcel = createFlaggedItemsAction('EXPORT_EXCEL')
export const exportEmail = createFlaggedItemsAction('EXPORT_EMAIL')
export const setFlagPageVersion = createFlaggedItemsAction('SET_FLAG_PAGE_VERSION')
export const setSideBarCategories = createFlaggedItemsAction('SET_SIDE_BAR_CATEGORIES')
export const getSelectedCategoryIds = createFlaggedItemsAction('GET_SELECTED_CATEGORY_IDS')
export const setFoldersOpened = createFlaggedItemsAction('SET_FOLDERS_OPENED')
export const setNewSidebarId = createFlaggedItemsAction('SET_NEW_SIDEBAR_ID')

