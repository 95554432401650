import React, { Component } from 'react'
import { connect } from 'react-redux'
import './HideDuplicateArticles.less'
import * as actions from './email-notifications-actions'
import * as constants from './email-notifications-constants'
import InputBlock from 'app/common/InputBlock'
import PropTypes from 'prop-types'


@connect(
  state => ({
    baseSettings: state.emailNotifications.baseSettings,
    insightsSettings: state.emailNotifications.insightsSettings,
    esgSettings: state.emailNotifications.esgSettings,
    editUserId: state.emailNotifications.emailDeliveryData.editUserId,
  }),
  {
    saveEmailAlertSettings: actions.api.saveEmailAlertSettings.request,
  },
)
export default class HideDuplicateArticles extends Component {
  static propTypes = {
    noticeFrequency: PropTypes.string.isRequired,
    notificationType: PropTypes.string.isRequired,
  }
  render() {
    const activeSettings = this.props.notificationType === constants.BASE_NOTIFICATION_TYPE
      ? this.props.baseSettings
      : this.props.notificationType === constants.INSIGHTS_NOTIFICATION_TYPE
        ? this.props.insightsSettings
        : this.props.notificationType === constants.ESG_NOTIFICATION_TYPE
          ? this.props.esgSettings
          : null
    let hideDuplicateArticlesOption = null
    if (activeSettings) {
      const frequencySettings = activeSettings.settings.find(
        setting => setting.noticeFrequency === this.props.noticeFrequency)
      const hideDuplicateArticles = frequencySettings
        ? frequencySettings.hideDuplicateArticles
        : null
      hideDuplicateArticlesOption = this.props.noticeFrequency !== constants.INTRA_DAY_FREQ
        ? <InputBlock
            className={'input-label hide-duplicates-container'}
            label='Hide Duplicate Articles'
            isInline={true}
          >
            <input
              className={'input-checkbox'}
              type="checkbox"
              checked={hideDuplicateArticles}
              onChange={(e) => this.onHideDuplicateArticlesChange(e, activeSettings)}
            />
          </InputBlock>
        : null
    }
    return (
      <React.Fragment>
        {hideDuplicateArticlesOption}
      </React.Fragment>
    )
  }
  onHideDuplicateArticlesChange(event, activeSettings) {
    this.props.saveEmailAlertSettings({
      editUserId: this.props.editUserId,
      noticeFrequency: this.props.noticeFrequency,
      type: activeSettings.notificationType,
      field: constants.HIDE_DUPLICATE_ARTICLES,
      value: event.target.checked,
      deliveryHideDuplicateSettings:this.props.groupNotification? true:null,
    })
  }
}
