import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import * as R from 'ramda'

import * as searchLookupActions from './search-lookup-actions'
import { getSearchLookup } from './search-lookup-selectors'
import * as urls from 'app/urls'

import LoadingOverlay from 'app/common/LoadingOverlay'
import Table from 'app/common/Table'
import PaginatedTable from 'app/common/PaginatedTable'
import Modal from 'app/common/Modal'
import Button from 'app/common/Button'
import TextBox from 'app/common/TextBox'
import Dropdown from 'app/common/Dropdown'
import Tooltip from 'app/common/Tooltip'

import './SearchLookup.less'

const PAGE_SIZE = 30


@connect(
  // Map state to props
  state => {
    const searchLookup = getSearchLookup(state)
    return {
      ...searchLookup,
      currentUserIsStaff: state.currentUserIsStaff,
      currentUserHasRawQueryAccess: state.currentUserHasRawQueryAccess,
      appName: state.appName,
      currentFirmLibraryName: state.currentFirmLibraryName,
    }
  },

  // Map dispatch to props
  {
    setSearchKeywords: searchLookupActions.setSearchKeywords,
    setSelectedSearchCategories: searchLookupActions.setSelectedSearchCategories,
    setSelectedBrowseCategory: searchLookupActions.setSelectedBrowseCategory,
    setSelectedSearchIds: searchLookupActions.setSelectedSearchIds,
    setNameMatchOnly: searchLookupActions.setNameMatchOnly,
    setExactMatch: searchLookupActions.setExactMatch,
    resetSearch: searchLookupActions.resetSearch,
    fetchSearches: searchLookupActions.fetchSearches,
    fetchSearch: searchLookupActions.fetchSearch,
    setQueryModalData: searchLookupActions.setQueryModalData,
  },
)
export default class SearchLookup extends Component {
  categoryLookup = {
    'agency': 'Agency',
    'client': 'Company',
    'court': 'Court',
    'filter': 'Filter',
    'firm': 'Firm',
    'industry': 'Industry',
    'practice': 'Practice',
    'state': 'State',
    'tracker': 'Topic',
  }

  render() {
    const loader =
      this.props.isLoading ?
        <LoadingOverlay/>
        : null

    const isSearchButtonEnabled =
      this.props.selectedSearchCategories.length && this.props.searchKeywords

    const startView =
      <div>
        <div className="col col1">
          <h4>Lookup by Keyword</h4>

          <p>Enter one or more keywords to lookup searches by words contained in the search title or in the tags
            associated with that search.</p>

          <h4>Enter Keywords</h4>

          <TextBox
            className="keywords-input"
            onChange={(evt) => this.handleKeywordsChange(evt)}
          />

          <div className="search-lookup-checkbox-container">
            <input
              type="checkbox"
              checked={this.props.nameMatchOnly}
              onChange={(evt) => this.handleMustIncludeKeywordInNameCheckbox(evt)}
            />
            <label>Lookup by Search Title Only</label>
            <div className="spacer"/>
            <input
              type="checkbox"
              checked={this.props.exactMatch}
              onChange={(evt) => this.handleExactMatchCheckbox(evt)}
            />
            <label>Exact Keyword Match</label>
          </div>

          <h4 className="margin-top">Search Categories</h4>

          <div className="search-lookup-checkbox-container select-all">
            <input
              type="checkbox"
              checked={this.props.selectedSearchCategories.length === Object.keys(this.categoryLookup).length}
              onChange={(evt) => this.handleCategorySelectAllCheckbox(evt)}
            />
            <label>Select All</label>
          </div>

          <div className="sub-col">
            <div className="search-lookup-checkbox-container">
              <input
                type="checkbox"
                checked={this.props.selectedSearchCategories.includes('agency')}
                onChange={(evt) => this.handleCategoryCheckbox(evt, 'agency')}
              />
              <label>Agencies</label>
            </div>

            <div className="search-lookup-checkbox-container">
              <input
                type="checkbox"
                checked={this.props.selectedSearchCategories.includes('client')}
                onChange={(evt) => this.handleCategoryCheckbox(evt, 'client')}
              />
              <label>Companies</label>
            </div>

            <div className="search-lookup-checkbox-container">
              <input
                type="checkbox"
                checked={this.props.selectedSearchCategories.includes('court')}
                onChange={(evt) => this.handleCategoryCheckbox(evt,'court')}
              />
              <label>Courts</label>
            </div>
          </div>

          <div className="sub-col">
            <div className="search-lookup-checkbox-container">
              <input
                type="checkbox"
                checked={this.props.selectedSearchCategories.includes('filter')}
                onChange={(evt) => this.handleCategoryCheckbox(evt, 'filter')}
              />
              <label>Filters</label>
            </div>

            <div className="search-lookup-checkbox-container">
              <input
                type="checkbox"
                checked={this.props.selectedSearchCategories.includes('firm')}
                onChange={(evt) => this.handleCategoryCheckbox(evt, 'firm')}
              />
              <label>Firms</label>
            </div>

            <div className="search-lookup-checkbox-container">
              <input
                type="checkbox"
                checked={this.props.selectedSearchCategories.includes('industry')}
                onChange={(evt) => this.handleCategoryCheckbox(evt, 'industry')}
              />
              <label>Industries</label>
            </div>
          </div>

          <div className="sub-col">
            <div className="search-lookup-checkbox-container">
              <input
                type="checkbox"
                checked={this.props.selectedSearchCategories.includes('practice')}
                onChange={(evt) => this.handleCategoryCheckbox(evt, 'practice')}
              />
              <label>Practices</label>
            </div>

            <div className="search-lookup-checkbox-container">
              <input
                type="checkbox"
                checked={this.props.selectedSearchCategories.includes('state')}
                onChange={(evt) => this.handleCategoryCheckbox(evt, 'state')}
              />
              <label>States</label>
            </div>

            <div className="search-lookup-checkbox-container">
              <input
                type="checkbox"
                checked={this.props.selectedSearchCategories.includes('tracker')}
                onChange={(evt) => this.handleCategoryCheckbox(evt, 'tracker')}
              />
              <label>Topics</label>
            </div>
          </div>

          <div className="margin-top">
            <button
              className="submit button primary"
              onClick={() => this.handleSearchButton()}
              disabled={!isSearchButtonEnabled}
            >Search</button>
          </div>
        </div>

        <div className="col col2">
          <span>OR</span>
        </div>
        <div className="col col3">
          <h4>Browse by Category</h4>

          <p>Choose a category to browse all of the searches that are in that category.</p>

          <h4>Search Category</h4>

          <div className="sub-col narrow">
            <Dropdown
              onChange={(value) => this.handleCategoryDropdown(value)}
              options={[
                {label: 'Agencies', value: 'agency'},
                {label: 'Companies', value: 'client'},
                {label: 'Courts', value: 'court'},
                {label: 'Filters', value: 'filter'},
                {label: 'Firms', value: 'firm'},
                {label: 'Industries', value: 'industry'},
                {label: 'Practices', value: 'practice'},
                {label: 'States', value: 'state'},
                {label: 'Topics', value: 'tracker'},
              ]}
            />
          </div>

          <div className="sub-col narrow">
            <button className="submit button primary" onClick={() => this.handleBrowseButton()}>Browse by Category</button>
          </div>
        </div>
      </div>

    const searchResultsList = this.getSearchResultsList()

    const searchResultsListPageCount = this.getSearchResultsListPageCount()

    const sort =
      this.props.sortField ?
        {
          column: this.props.sortField,
          direction: this.props.sortDirection,
        }
        : null

    const selectedSearchesText =
      this.props.selectedSearchIds.length ?
        <span>{this.props.selectedSearchIds.length} Search{this.props.selectedSearchIds.length > 1 ? 'es' : ''} Selected</span>
        :null

    const resultsViewCategories =
      this.props.view === 'search-results' ?
        <div>
          <h4>Show Categories:</h4>
          <div className="search-lookup-checkbox-container">
            <input
              type="checkbox"
              checked={this.props.selectedSearchCategories.includes('agency')}
              onChange={(evt) => this.handleCategoryCheckbox(evt, 'agency')}
            />
            <label>Agencies</label>

            <input
              type="checkbox"
              checked={this.props.selectedSearchCategories.includes('client')}
              onChange={(evt) => this.handleCategoryCheckbox(evt, 'client')}
            />
            <label>Companies</label>

            <input
              type="checkbox"
              checked={this.props.selectedSearchCategories.includes('court')}
              onChange={(evt) => this.handleCategoryCheckbox(evt,'court')}
            />
            <label>Courts</label>

            <input
              type="checkbox"
              checked={this.props.selectedSearchCategories.includes('filter')}
              onChange={(evt) => this.handleCategoryCheckbox(evt, 'filter')}
            />
            <label>Filters</label>

            <input
              type="checkbox"
              checked={this.props.selectedSearchCategories.includes('firm')}
              onChange={(evt) => this.handleCategoryCheckbox(evt, 'firm')}
            />
            <label>Firms</label>

            <input
              type="checkbox"
              checked={this.props.selectedSearchCategories.includes('industry')}
              onChange={(evt) => this.handleCategoryCheckbox(evt, 'industry')}
            />
            <label>Industries</label>

            <input
              type="checkbox"
              checked={this.props.selectedSearchCategories.includes('practice')}
              onChange={(evt) => this.handleCategoryCheckbox(evt, 'practice')}
            />
            <label>Practices</label>

            <input
              type="checkbox"
              checked={this.props.selectedSearchCategories.includes('state')}
              onChange={(evt) => this.handleCategoryCheckbox(evt, 'state')}
            />
            <label>States</label>

            <input
              type="checkbox"
              checked={this.props.selectedSearchCategories.includes('tracker')}
              onChange={(evt) => this.handleCategoryCheckbox(evt, 'tracker')}
            />
            <label>Topics</label>
          </div>
        </div>
        : null

    const resultsViewAuthor =
      this.props.view === 'search-results' || this.props.view === 'browse-results'?
        <div>
          <h4>Show By Author:</h4>
          <div className="search-lookup-checkbox-container">
            <input
              type="checkbox"
              checked={this.props.selectedSearchAuthors.includes('manzama')}
              onChange={(evt) => this.handleAuthorCheckbox(evt, 'manzama')}
            />
            <label>{this.props.appName}</label>

            <input
              type="checkbox"
              checked={this.props.selectedSearchAuthors.includes('firm-library')}
              onChange={(evt) => this.handleAuthorCheckbox(evt, 'firm-library')}
            />
            <label>{this.props.currentFirmLibraryName}</label>
          </div>
        </div>
        : null

    const resultsViewNameFilter =
      this.props.view === 'browse-results' ?
        <div>
          <h4>Filter by Search Name</h4>
          <div>
            <input
              type="text"
              className="search-name-filter"
              onChange={(evt) => this.handleNameFilterTextBox(evt)}
              value={this.props.searchResultsNameFilter || ''}
            />
          </div>
        </div>
      : null

    const resultsViewDescription =
      this.props.view === 'search-results' ?
        <h3 className="results-meta-row">
          {this.props.total} Search{this.props.total === 1 ? '' : 'es'} contain "{this.props.searchKeywords}"
        </h3>
        : <h3 className="results-meta-row">
            {this.props.total} Search{this.props.total === 1 ? '' : 'es'} within the
            &nbsp;{this.categoryLookup[this.props.selectedBrowseCategory]} Category
          </h3>

    const startsWith = this.props.searchResultsStartsWithFilter || 'ALL'

    const resultsViewLetters =
      this.props.view === 'browse-results' ?
        <div className="letters">
          <span className="letters-label">View:</span>
          {
            'ALL,A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z'.split(',').map(letter =>
              <a
                key={`letter-${letter}`}
                className={classNames('letter', {selected: startsWith === letter})}
                href="#"
                onClick={evt => this.handleLetterClick(evt, letter)}
              >{letter}</a>
            )
          }
        </div>
        : null

    const queryModal =
      this.props.queryModalData.isOpen ?
        <Modal
          className="query-modal"
          isOpen={this.props.queryModalData.isOpen}
          onClose={() => {this.props.setQueryModalData({isOpen: false})}}
        >
          <h1>Search Query For {this.props.queryModalData.search.name}</h1>
          <div className="query-modal-query">
            <textarea
              value={this.props.queryModalData.search.baseQuery}
              readOnly={true}
            />
          </div>
          <a
            className="button primary"
            href={this.props.queryModalData.search.resultsLink}
            target="_blank"
          >
            View Search Results
          </a>
          <Button
            label="Close"
            isPlainText={true}
            onClick={() => {this.props.setQueryModalData({isOpen: false}) }}
          />
        </Modal>
        : null

    const resultsView =
      this.props.isLoading ?
        null
        : <div>
            <div>
              {resultsViewDescription}
              <div className="results-meta-row search-again-link">
                <a href="#" onClick={(evt) => this.resetSearch(evt)}>Search Again</a>
              </div>
            </div>
            {resultsViewCategories}
            {resultsViewAuthor}
            {resultsViewNameFilter}
            <div className="option-row">
              <div>
                <div>{selectedSearchesText}</div>
                <div>
                  <button
                    className="button primary"
                    onClick={() => this.fetchSearchResults()}
                    disabled={this.props.selectedSearchIds.length === 0}
                  >View Results</button>
                </div>
              </div>
              {resultsViewLetters}
            </div>
            <PaginatedTable
              data={searchResultsList}
              pageCount={searchResultsListPageCount}
              currentPage={this.props.searchResultsListCurrentPage}
              onPageChange={pageNum => this.handleSearchResultsListPageChange(pageNum)}
              onSortChange={newSort => this.handleSearchResultsListSortChange(newSort)}
              defaultSort={sort}
              ignoreSort={true}
              className="table"
            >
              <Table.Column
                name="checkbox"
                baseWidth={45}
                growRatio={0}
                shrinkRatio={0}
                cellContents={search =>
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(evt) => this.handleSearchCheckbox(evt, search.id)}
                    checked={this.props.selectedSearchIds.includes(search.id)}
                  />
                }
              />
              <Table.Column
                name="name"
                isSortable
                label="Search Name"
                cellContents={search => {
                  return (
                    <span>
                      <span>{search.name}</span>
                      {
                        search.notes &&
                        <span className="notes-tooltip">
                          <Tooltip
                            label={search.notes}
                          >
                            <i className="fa fa-sticky-note-o" aria-hidden="true"/>
                          </Tooltip>
                        </span>
                      }
                    </span>
                  )
                }}
                baseWidth={180}
                growRatio={2}
              />
              {
                this.props.view === 'search-results' ?
                  <Table.Column
                    name="terms"
                    isSortable
                    label="Matching Terms"
                    cellContents={R.prop('terms')}
                    baseWidth={170}
                    growRatio={2}
                  />
                  :null
              }
              <Table.Column
                name="category"
                isSortable
                label="Search Category"
                cellContents={R.prop('categoryDisplay')}
                baseWidth={60}
                growRatio={2}
              />
              <Table.Column
                name="author"
                isSortable
                label="Author"
                cellContents={search => search.isFirmLibrary ? this.props.currentFirmLibraryName : this.props.appName}
                baseWidth={60}
                growRatio={2}
              />
              <Table.Column
                name="actions"
                baseWidth={200}
                growRatio={0}
                cellContents={search => {
                  return (
                    <span className="actions">
                      {
                        this.props.currentUserIsStaff || this.props.currentUserHasRawQueryAccess ?
                        <a
                          className="action"
                          href="#"
                          onClick={(evt) => this.handleViewQueryLink(evt, search)}
                        >
                          View Query
                        </a> :
                        null
                      }
                      <a
                        className="action"
                        href={search.resultsLink}
                        target="_blank"
                      >
                        View Results
                      </a>
                    </span>
                  )
                }}
              />
            </PaginatedTable>
          </div>

    const content = this.props.view === 'start' ? startView : resultsView

    const additionalDescription =
      this.props.view !== 'start' ?
        ' Choose one ore more searches and click "view results" to create an "OR" query.' :
        null

    return (
      <div id="advanced-search-lookup">
        <p className="search-method-description">{this.props.appName} has thousands of conceptual searches to start from. These
          searches are hand-crafted by {this.props.appName}’s content specialists specifically to help you get the best results.
          {additionalDescription}</p>

        {content}
        {loader}
        {queryModal}
      </div>
    )
  }

  /**
   * this only handles the initial fetch from the start page, paging, and sorting;
   * not when filtering on the results page.
   */
  fetchSearches(view, page, sort) {
    let data = {
      page: page,
      authors: this.props.selectedSearchAuthors,
    }
    if (sort) {
      data.sortField = sort.column
      data.sortDirection = sort.direction
    }
    if (view === 'search-results') {
      data.view = 'search-results'
      data.keywords = this.props.searchKeywords.split(' ')
      data.categories = this.props.selectedSearchCategories
      data.nameMatchOnly = this.props.nameMatchOnly
      data.exactMatch = this.props.exactMatch
    } else {
      data.view = 'browse-results'
      data.categories = [this.props.selectedBrowseCategory]
      data.nameStartsWith = this.props.searchResultsStartsWithFilter
      data.nameFilter = this.props.searchResultsNameFilter
    }
    this.props.setSelectedSearchIds([])
    this.props.fetchSearches(data)
  }

  handleSearchButton() {
    this.fetchSearches('search-results', 1, null)
  }

  handleBrowseButton() {
    this.fetchSearches('browse-results', 1, {column: 'name', direction: 'asc'})
  }

  handleKeywordsChange(evt) {
    const value = evt.target.value
    this.props.setSearchKeywords(value)
  }

  handleCategorySelectAllCheckbox(evt) {
    const checked = evt.target.checked
    let categories = Object.keys(this.categoryLookup)
    if (!checked) {
      categories = []
    }
    this.props.setSelectedSearchCategories(categories)
  }

  handleCategoryCheckbox(evt, category) {
    const checked = evt.target.checked
    let categories = [...this.props.selectedSearchCategories]
    if (checked) {
      categories.push(category)
    } else {
      categories = categories.filter(c => c !== category)
    }
    if (this.props.view === 'start') {
      this.props.setSelectedSearchCategories(categories)
    } else {
      const data = {
        view: 'search-results',
        keywords: this.props.searchKeywords.split(' '),
        categories,
        authors: this.props.selectedSearchAuthors,
        nameMatchOnly: this.props.nameMatchOnly,
        exactMatch: this.props.exactMatch,
        sortField: this.props.sortField,
        sortDirection: this.props.sortDirection,
      }
      this.props.fetchSearches(data)
    }
  }

  handleAuthorCheckbox(evt, author) {
    const checked = evt.target.checked
    let authors = [...this.props.selectedSearchAuthors]
    if (checked) {
      authors.push(author)
    } else {
      authors = authors.filter(a => a !== author)
    }
    const data = {
      view: this.props.view,
      keywords: this.props.searchKeywords.split(' '),
      categories: this.props.selectedSearchCategories,
      authors: authors,
      nameMatchOnly: this.props.nameMatchOnly,
      exactMatch: this.props.exactMatch,
      sortField: this.props.sortField,
      sortDirection: this.props.sortDirection,
      nameStartsWith: this.props.searchResultsStartsWithFilter,
      nameFilter: this.props.searchResultsNameFilter,
    }
    this.props.fetchSearches(data)
  }

  handleCategoryDropdown(value) {
    this.props.setSelectedBrowseCategory(value)
  }

  handleMustIncludeKeywordInNameCheckbox(evt) {
    this.props.setNameMatchOnly(evt.target.checked)
  }

  handleExactMatchCheckbox(evt) {
    this.props.setExactMatch(evt.target.checked)
  }

  handleSearchCheckbox(evt, ssId) {
    const checked = evt.target.checked
    let ids = [...this.props.selectedSearchIds]
    if (checked) {
      ids.push(ssId)
    } else {
      ids.splice(ids.indexOf(ssId), 1)
    }
    this.props.setSelectedSearchIds(ids)
  }

  handleNameFilterTextBox(evt) {
    const data = {
      view: 'browse-results',
      authors: this.props.selectedSearchAuthors,
      categories: this.props.selectedSearchCategories,
      sortField: this.props.sortField,
      sortDirection: this.props.sortDirection,
      nameStartsWith: this.props.searchResultsStartsWithFilter,
      nameFilter: evt.target.value,
      useIsLoading: false,
    }
    this.props.fetchSearches(data)
  }

  handleLetterClick(evt, letter) {
    evt.preventDefault()
    if (letter === 'ALL') {
      letter = null
    }
    const data = {
      view: 'browse-results',
      keywords: this.props.searchKeywords.split(' '),
      categories: this.props.selectedSearchCategories,
      authors: this.props.selectedSearchAuthors,
      nameMatchOnly: this.props.nameMatchOnly,
      exactMatch: this.props.exactMatch,
      sortField: this.props.sortField,
      sortDirection: this.props.sortDirection,
      nameStartsWith: letter,
      nameFilter: this.props.searchResultsNameFilter,
    }
    this.props.fetchSearches(data)
  }

  handleViewQueryLink(evt, search) {
    evt.preventDefault()
    this.props.fetchSearch(search.id)
  }

  handleSearchResultsListPageChange(pageNum) {
    const sort = {column: this.props.sortField, direction: this.props.sortDirection}
    this.fetchSearches(this.props.view, pageNum, sort)
  }

  handleSearchResultsListSortChange(newSort) {
    this.fetchSearches(this.props.view, 1, newSort)
  }

  getSearchResultsList() {
    return [...this.props.searches].map(search => {
      const terms = this.getMatchingTerms(search)
      return {
        ...search,
        terms: terms,
        categoryDisplay: this.categoryLookup[search.category],
        resultsLink: urls.tier3Shared(search),
        author: search.isFirmLibrary ? 'firm-library' : this.props.appName,
      }
    })
  }

  getSearchResultsListPageCount() {
    return Math.ceil(this.props.total / PAGE_SIZE)
  }

  resetSearch(evt) {
    evt.preventDefault()
    this.props.resetSearch()
  }

  getMatchingTerms(search) {
    /**
     * returns an array of term elements with matching one's highlighted
     */
    if (this.props.view !== 'search-results') {
      return []
    }
    const queryTerms =
      search.matchingQueryTerms
      ? this.props.exactMatch ? [search.matchingQueryTerms] : search.matchingQueryTerms.split(',')
      : []
    const searchKeywords = this.props.exactMatch ? [this.props.searchKeywords] : this.props.searchKeywords.split(' ')
    let results = []
    let counter = 0
    // create array of term elements with matching part highlighted
    queryTerms.forEach(term => {
      searchKeywords.forEach(keyword => {
        const words = term.split(',')
        words.forEach((word, index) => {
          const start = word.toLowerCase().indexOf(keyword.toLowerCase())
          if (start === -1) {
            counter++
            results.push(<span key={`matching-term-parts-${counter}`}>{word}</span>)
          } else {
            let before = ''
            if (start !== 0) {
              before = word.substring(0, start)
              counter++
              results.push(<span key={`matching-term-parts-${counter}`}>{before}</span>)
            }
            const during = word.substring(start, start + keyword.length)
            counter++
            results.push(<span key={`matching-term-parts-${counter}`}><b>{during}</b></span>)
            const after = word.substring(start + keyword.length)
            if (after) {
              counter++
              results.push(<span key={`matching-term-parts-${counter}`}>{after}</span>)
            }
          }
          if (index < words.length - 1) {
            counter++
            results.push(<span key={`matching-term-parts-${counter}`}>&nbsp;</span>)
          }
        })
        counter++
        results.push(<span key={`matching-term-parts-${counter}`}>, </span>)
      })
    })
    results.pop()  // remove last comma
    return results
  }

  fetchSearchResults() {
    const query = this.props.selectedSearchIds.map(id => `ss:${id}`).join('~|')
    window.location.href = `${urls.tier3Unsaved()}?query=${query}&query-type=any`
  }
}
