import React, { Component } from 'react'
import { connect } from 'react-redux'
import './SettingsBulkUpdate.less'
import * as actions from '../email-notifications-actions'
import Modal from '../../common/Modal/index'
import * as constants from "../email-notifications-constants"
import {titleCase} from 'title-case'
import Table from 'app/common/Table'
import Button from 'app/common/Button'
import InputBlock from 'app/common/InputBlock'
import PropTypes from "prop-types"
const _ = require('lodash');

@connect(
  state => ({
    insightsSettings: state.emailNotifications.insightsSettings,
    insightsNoticeConfigs: state.emailNotifications.insightsNoticeConfigs,
    esgSettings: state.emailNotifications.esgSettings,
    esgNoticeConfigs: state.emailNotifications.esgNoticeConfigs,
    baseNoticeConfigs: state.emailNotifications.baseNoticeConfigs,
    selectedFrequencyBulkAssignConfigs: state.emailNotifications.selectedFrequencyBulkAssignConfigs,
    bulkUpdateFilterBy: state.emailNotifications.bulkUpdateFilterBy,
    settingsBulkUpdate: state.emailNotifications.settingsBulkUpdate,
    editUserId: state.emailNotifications.emailDeliveryData.editUserId,
  }),
  {
    changeBulkUpdateFilterBy: actions.changeBulkUpdateFilterBy,
    changeShowBulkUpdateModal: actions.changeShowBulkUpdateModal,
    changeFrequencyBulkUpdateConfigs: actions.changeFrequencyBulkUpdateConfigs,
    saveBaseNoticeConfigs: actions.api.saveBaseNoticeConfigs.request,
    saveInsightsNoticeConfigs: actions.api.saveInsightsNoticeConfigs.request,
    saveEsgNoticeConfigs: actions.api.saveEsgNoticeConfigs.request,
  },
)

export default class SettingsBulkUpdate extends Component {

  static propTypes = {
    notificationType: PropTypes.string.isRequired,
    editUserFullName: PropTypes.string,
  }
  static defaultProps = {
    editUserFullName: null,
  }

  render() {
    const settingsBulkUpdate = this.props.settingsBulkUpdate
      ? this.props.settingsBulkUpdate
      : null

    const bulkFrequency = settingsBulkUpdate
      ? settingsBulkUpdate.frequency
      : null

    const bulkType = settingsBulkUpdate
      ? settingsBulkUpdate.bulkType
      : null

    const bulkAssignFreq = {
      [constants.INSIGHTS_NOTIFICATION_TYPE]: constants.INSIGHTS_BULK_ASSIGN_FREQUENCY[bulkFrequency],
      [constants.BASE_NOTIFICATION_TYPE]: `${constants.BASE_EMAIL_FREQUENCIES[bulkFrequency]}${bulkFrequency !== constants.NONE_FREQ ? ' Email' : ''}`,
      [constants.ESG_NOTIFICATION_TYPE]: constants.ESG_BULK_ASSIGN_FREQUENCY[bulkFrequency],
    }[this.props.notificationType]

    let configsArray = []
    let bulkAssignConfigs = []
    if (this.props.notificationType === constants.BASE_NOTIFICATION_TYPE){
      const freqCatKey = bulkFrequency && this.props.baseNoticeConfigs
        ? bulkFrequency + settingsBulkUpdate.category
        : null
      const assignedConfigs = this.props.baseNoticeConfigs.baseConfigs[freqCatKey]
      if (assignedConfigs) {
        assignedConfigs.forEach(config => {
          const item = {
            configId: config.id,
            frequency: config.frequency,
            searchName: config.searchName,
          }
          configsArray.push(item)
        })
      }
      if (settingsBulkUpdate) {
        constants.BASE_FREQUENCY_KEYS.forEach(freq => {
          if (freq !== bulkFrequency) {
            const freqConfig =
              this.props.baseNoticeConfigs.baseConfigs[freq + settingsBulkUpdate.category]
            if (freqConfig) {
              freqConfig.forEach(config => {
                const item = {
                  configId: config.id,
                  frequency: config.frequency,
                  searchName: config.searchName,
                }
                bulkAssignConfigs.push(item)
              })
            }
          }
        })
      }
    } else if (this.props.notificationType === constants.INSIGHTS_NOTIFICATION_TYPE){
      configsArray = this.props.insightsNoticeConfigs && settingsBulkUpdate
        ? this.props.insightsNoticeConfigs.configsByCategory[settingsBulkUpdate.category]
        : null
      bulkAssignConfigs = configsArray && settingsBulkUpdate && bulkType === 'frequency'
        ? configsArray.filter(
          config => config.frequency !== bulkFrequency)
        : configsArray && settingsBulkUpdate && bulkType === 'subfactors'
          ? configsArray.filter(
          config => config.frequency === bulkFrequency)
          : null
    } else if (this.props.notificationType === constants.ESG_NOTIFICATION_TYPE) {
      configsArray = this.props.esgNoticeConfigs && settingsBulkUpdate
        ? this.props.esgNoticeConfigs.configsByCategory[settingsBulkUpdate.category]
        : null
      bulkAssignConfigs = configsArray && settingsBulkUpdate && bulkType === 'frequency'
        ? configsArray.filter(
          config => config.frequency !== bulkFrequency)
        : configsArray && settingsBulkUpdate && bulkType === 'subfactors'
        ? configsArray.filter(config => config.frequency === bulkFrequency)
        : null
    }

    const filteredBulkAssignConfigs = bulkAssignConfigs
      ?_.cloneDeep(bulkAssignConfigs).filter(config => {
          return!(this.props.bulkUpdateFilterBy &&
            !config.searchName.toLowerCase().includes(
              this.props.bulkUpdateFilterBy.toLowerCase()))
        })
      : null

    if (filteredBulkAssignConfigs) {
      filteredBulkAssignConfigs.forEach(obj => {
        obj.id = obj.configId
      })
    }
    const allConfigsSelected = this.props.selectedFrequencyBulkAssignConfigs && filteredBulkAssignConfigs
      ? (this.props.selectedFrequencyBulkAssignConfigs.configIds.length === filteredBulkAssignConfigs.length)
        && filteredBulkAssignConfigs.length > 0
      : false

    const bulkAssignTable = filteredBulkAssignConfigs && settingsBulkUpdate
      ? <Table
          className={'bulk-assign-table'}
          defaultSort={{column: 'name', direction: 'asc'}}
          data={filteredBulkAssignConfigs}
        >
          <Table.Column
            name='checkbox'
            baseWidth={45}
            growRatio={0}
            shrinkRatio={0}
            cellContents={assignConfig => {
              const {selectedFrequencyBulkAssignConfigs} = this.props
              const checked = selectedFrequencyBulkAssignConfigs
                ? selectedFrequencyBulkAssignConfigs.configIds.includes(
                  assignConfig.configId)
                : false
              return (
                <input
                  key={assignConfig.configId}
                  type="checkbox"
                  checked={checked}
                  onChange={event => this.onSelectConfigChange(
                    assignConfig,
                    bulkFrequency,
                    event.target.checked)}
                />
              )
            }}
          />
          <Table.Column
            name="name"
            label={constants.CATEGORIES[settingsBulkUpdate.category]}
            baseWidth={200}
            isSortable
            sortBy={config => {
              return config.searchName
            }}
            cellContents={config => config.searchName}
          />
        </Table>
      : null

    const notificationTypeDisplay = {
      [constants.INSIGHTS_NOTIFICATION_TYPE]: 'Insights ',
      [constants.BASE_NOTIFICATION_TYPE]: 'Base ',
      [constants.ESG_NOTIFICATION_TYPE]: 'ESG ',
    }[this.props.notificationType] || titleCase(`${this.props.notificationType}`)

    let modalTitle = null
    if (bulkType === 'frequency') {
      modalTitle = settingsBulkUpdate && !this.props.editUserFullName
        ? titleCase(`assign ${constants.CATEGORIES[settingsBulkUpdate.category]} to your 
        ${notificationTypeDisplay} ${bulkAssignFreq}`)
        : settingsBulkUpdate && this.props.editUserFullName
          ? titleCase(`assign ${constants.CATEGORIES[settingsBulkUpdate.category]} to 
        ${notificationTypeDisplay} ${bulkAssignFreq} for ${this.props.editUserFullName}`)
          : null
    }
    const modalType = this.props.notificationType === 'esg' ? 'Topic':'Subfactor'
    if (bulkType === 'subfactors') {
      modalTitle = settingsBulkUpdate && !this.props.editUserFullName
        ? titleCase(`apply ${modalType} Changes to these ${constants.CATEGORIES[settingsBulkUpdate.category]} in your  
        ${notificationTypeDisplay} ${bulkAssignFreq}`)
        : settingsBulkUpdate && this.props.editUserFullName
          ? titleCase(`apply ${modalType} Changes to these ${constants.CATEGORIES[settingsBulkUpdate.category]} in 
        ${notificationTypeDisplay} ${bulkAssignFreq} for ${this.props.editUserFullName}`)
          : null
    }

    const buttonLabel = bulkType === 'frequency'
      ? `Assign to ${bulkAssignFreq}`
      : bulkType === 'subfactors'
        ? `Apply ${modalType} Changes`
        : null

    const BulkAssignModal = this.props.insightsSettings && settingsBulkUpdate
      ? <Modal
          className="notification-bulk-assign-modal"
          isOpen={settingsBulkUpdate.show}
          onClose={() => {this.onBulkAssignModalClose()}}
        >
          <div className={'bulk-assign-title'}>
            { modalTitle }
          </div>
          <div className={'bulk-assign-filter'}>
            <InputBlock
              label={
                'Filter by Name'
              }
              className="filter-label"
            >
              <input className="filter-input"
                type="text"
                onChange={this.onChangeBulkAssignFilterBy()}
              />
            </InputBlock>
          </div>

          <div className={'bulk-select-checkbox'}>
            <input
              type="checkbox"
              onChange={event => this.onSelectAllChange(
                filteredBulkAssignConfigs,
                bulkFrequency,
                event.target.checked)}
              checked={allConfigsSelected}
            />
          </div>
          <Button className="bulk-assign-button"
            label={buttonLabel}
            onClick={() => {this.onBulkActionButtonClick()}}
            disabled={
              this.props.selectedFrequencyBulkAssignConfigs
                ? !this.props.selectedFrequencyBulkAssignConfigs.configIds.length
                : true
            }
          />

          <div className={'bulk-assign-table-container'}>
            {bulkAssignTable}
          </div>
        </Modal>
      : null

    return (
      <React.Fragment>
        {BulkAssignModal}
      </React.Fragment>
    )
  }

  onChangeBulkAssignFilterBy() {
    return event => {
      this.props.changeBulkUpdateFilterBy(event.target['value'])
    }
  }
  onSelectAllChange(filteredBulkAssignConfigs, toFrequency, checked) {
    if (checked) {
      this.props.changeFrequencyBulkUpdateConfigs(
        {configIds: filteredBulkAssignConfigs.map(
          changeConfig => changeConfig.configId),
          frequency: toFrequency,
          notificationType: this.props.insightsSettings.notificationType})
    } else {
      this.props.changeFrequencyBulkUpdateConfigs(
        {configIds: [], frequency: null, notificationType: null})
    }
  }
  onSelectConfigChange(assignConfig, toFrequency, checked) {
    let configIds = this.props.selectedFrequencyBulkAssignConfigs
      ? this.props.selectedFrequencyBulkAssignConfigs.configIds
      : []
    let newConfigIds;

    if (checked) {
      newConfigIds = [assignConfig.configId, ...configIds]
    } else {
      newConfigIds = configIds.filter(id => id !== assignConfig.configId)
    }
    this.props.changeFrequencyBulkUpdateConfigs({
      configIds: newConfigIds,
      frequency: toFrequency,
      notificationType: this.props.insightsSettings.notificationType
    })
  }
  onBulkAssignModalClose() {
    this.props.changeFrequencyBulkUpdateConfigs({
      configIds: [], frequency: null, notificationType: null
    })
    this.props.changeBulkUpdateFilterBy(null)
    this.props.changeShowBulkUpdateModal({
      show: false, category: null, frequency: null, bulkType: null, subfactors: null,
    })
  }
  onBulkActionButtonClick() {

    let updateField = null
    let updateValue = null

    if (this.props.settingsBulkUpdate.bulkType === 'frequency') {
      updateField = constants.FREQUENCY
      updateValue = this.props.selectedFrequencyBulkAssignConfigs.frequency
    }
    if (this.props.settingsBulkUpdate.bulkType === 'subfactors') {
      updateField = constants.SUB_FACTORS
      updateValue = this.props.settingsBulkUpdate.subfactors
    }

    const data = {
      editUserId: this.props.editUserId,
      type: this.props.notificationType,
      field: updateField,
      value: updateValue,
      configIds: this.props.selectedFrequencyBulkAssignConfigs.configIds,
    }
    if (this.props.notificationType === constants.BASE_NOTIFICATION_TYPE) {
      this.props.saveBaseNoticeConfigs(data)
    } else if (this.props.notificationType === constants.INSIGHTS_NOTIFICATION_TYPE) {
      this.props.saveInsightsNoticeConfigs(data)
    } else if (this.props.notificationType === constants.ESG_NOTIFICATION_TYPE) {
      this.props.saveEsgNoticeConfigs(data)
    }
    this.onBulkAssignModalClose()
  }
}
