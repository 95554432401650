import PropTypes from 'prop-types'
import React from 'react'
import { Waypoint } from 'react-waypoint'
import styles from './ComparisonSearchList.less'
import {useSelector} from 'react-redux'
import ComparisonDocumentList from "../comparison-document-list"
import * as selectors from 'app/comparison-page/comparison-selectors'
import Orm from "app/framework/Orm"
import {Document} from "app/models"
import * as entitiesSelectors from 'app/entities/entities-selectors'
import {map} from "ramda"
import ComparisonDocumentListLoading
  from "../comparison-document-list/ComparisonDocumentListLoading"


function ComparisonSearchList({
  activeNavChange,
  updateDocuments,
  updateFeeds,
  removeDocumentsForExcludedFeed,
  viewMoreOnClickFunc,
}) {

  const loadingSearchIds = useSelector(selectors.getLoadingSearchIds)
  const activeSidebarGroupTitle = useSelector(selectors.getActiveSidebarGroupTitle)
  const selectedSearches = useSelector(selectors.getSelectedSearches)
  const comparisonDocumentIds = useSelector(selectors.getComparisonDocumentIds)
  const entities = useSelector(entitiesSelectors.getEntities)
  const orm = Orm.withEntities(entities)

  const searchHeaderLink = (savedSearch) => {
    const searchUrl = savedSearch.absoluteUrl
    const searchHref = `${searchUrl}?usrc_s=header:${savedSearch.category}&usrc=module`
    const displayHeader = searchUrl
      ? <a href={''}
          onClick={(evt) => viewMoreOnClickFunc(evt, searchHref)}
        >
          {savedSearch.name}
        </a>
      : savedSearch.name
    return (displayHeader)
  }

  const searchDocuments = (savedSearch) => {
    const hiddenFeedIds = []
    const searchDocumentIds = comparisonDocumentIds[savedSearch.id]
    const topLevelDocs = (docIds) => {
      return(
        orm.getByIds(Document, docIds)
        .filter(doc => !doc.feed || !hiddenFeedIds.includes(doc.feed.id))
      )
    }
    const topLevelDocuments = searchDocumentIds
      ? topLevelDocs(searchDocumentIds.topLevelDocumentIds)
      : null

    const groupedDocs = (docIds) => {
      const grouped = map(
        ids =>
          orm.getByIds(Document, ids)
            .filter(doc => !doc.feed || !hiddenFeedIds.includes(doc.feed.id)),
        docIds,
      )
      return(grouped)
    }
    const groupedDocuments = searchDocumentIds
      ? groupedDocs(searchDocumentIds.groupedDocumentIds)
      : null

    return({topLevelDocuments, groupedDocuments})
  }

  const searchDocumentsElement = (savedSearch) => {
    const {topLevelDocuments, groupedDocuments} = searchDocuments(savedSearch)
    const documents = topLevelDocuments && !loadingSearchIds.includes(savedSearch.id)
      ? <ComparisonDocumentList
          search={savedSearch}
          topLevelDocuments={topLevelDocuments}
          groupedDocuments={groupedDocuments}
          onFlaggingStateChange={({documents}) => updateDocuments(documents)}
          onPromoteStateChange={({feedId, isPromoted}) => updateFeeds([{id: feedId, isPromoted}])}
          onExclude={(feedId) => removeDocumentsForExcludedFeed(feedId)}
        />
      : null
    return (documents)
  }

  const searchFooter = (savedSearch) => {
    const searchUrl = savedSearch.absoluteUrl
    const searchHref = `${searchUrl}?usrc_s=more&usrc=module`
    const {topLevelDocuments} = searchDocuments(savedSearch)

    const displayFooter = searchUrl && topLevelDocuments &&
        topLevelDocuments.length > 0 && !loadingSearchIds.includes(savedSearch.id)
      ? <div className={styles.sectionFooter}>
          <div className={styles.searchNameFooter}>
            <a href={''}
               onClick={(evt) => viewMoreOnClickFunc(evt, searchHref)}
            >
              {`View more ${savedSearch.name} articles >`}
            </a>
          </div>
        </div>
      : searchUrl && !loadingSearchIds.includes(savedSearch.id)
        ? <div className={styles.sectionFooter}>
            <div className={styles.noResults} >
              No results were found for this search. Increase the time range or expand your search
              to get some results.
            </div>
            <div className={styles.searchNameFooter}>
              <a href={''}
                 onClick={(evt) => viewMoreOnClickFunc(evt, searchHref)}
              >
                {`View more ${savedSearch.name} articles >`}
              </a>
            </div>
          </div>
        : <ComparisonDocumentListLoading/>
    return (displayFooter)
  }

  const comparisonSearches = selectedSearches.map(ss =>
    <React.Fragment key={`comparison-search-${ss.id}`}>
      <div className={styles.searchHeaderContainer}>
        <div className={styles.searchHeader}>
          <Waypoint
            fireOnRapidScroll={false}
            onEnter={(waypoint) =>
              activeNavChange(waypoint, ss.id, activeSidebarGroupTitle)}
            viewportTop={100}
            viewportBottom={50}
          >
            <div className={styles.searchName} id={ss.name}>
              {searchHeaderLink(ss)}
            </div>
          </Waypoint>
        </div>
      </div>
      {searchDocumentsElement(ss)}
      {searchFooter(ss)}
    </React.Fragment>
  )

  return (
    <React.Fragment>
      { comparisonSearches }
    </React.Fragment>
  )

}
ComparisonSearchList.propTypes = {
  activeNavChange: PropTypes.func.isRequired,
  showAlternateDateFormat: PropTypes.bool.isRequired,
  updateDocuments: PropTypes.func.isRequired,
  updateFeeds: PropTypes.func.isRequired,
  removeDocumentsForExcludedFeed: PropTypes.func.isRequired,
  viewMoreOnClickFunc: PropTypes.func.isRequired,
}
export default ComparisonSearchList
