import React from 'react'

import { IndustryDashboard } from './Dashboard'
import {default as CompanyPageContent} from './Company'
import EsgNavbar from 'app/esg/Navigation/EsgNavbar'
import { ScrollToTop } from 'app/esg/utils'

function EsgIndustryDashboardPage() {
  return (
    <div className="esg-page" style={{display: 'grid'}}>
      <EsgNavbar />
      <IndustryDashboard />
    </div>
  )
}

function EsgCompanyPage() {
  return (
    <div className="esg-page">
      <EsgNavbar />
      <ScrollToTop />
      <div style={{maxWidth: '1300px', margin: '35px auto'}}>
        <CompanyPageContent />
      </div>
    </div>
  )
}

export {EsgIndustryDashboardPage, EsgCompanyPage}
