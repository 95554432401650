import PropTypes from 'prop-types'
import React from 'react'

import styles from './SearchQueryComponents.less'

export default function SearchQueryComponents({
  baseQuery,
  andComponents,
  orComponents,
}) {
  const getUniqueValues = (components) => {
    let uniqueItems = []
    let andUniqueItems = []
    components.forEach(item => {
      uniqueItems = []
      const extractedItems = item.substring(1, item.length - 1).split("OR")
      extractedItems.forEach(term => {
        if (!uniqueItems.includes(term.trim())) {
          uniqueItems.push(term.trim())
        }
      })
      andUniqueItems.push("(" + uniqueItems.join(" OR ") + ")")
    })
    return andUniqueItems
  }
  const uniqueAndComponents = getUniqueValues(andComponents)
  const uniqueOrComponents = getUniqueValues(orComponents)

  return (
    <div className={styles.container}>
      {baseQuery && (
        <>
          <h4>Base Query:</h4>
          <p className={styles.component}>{baseQuery}</p>
        </>
      )}
      {(uniqueAndComponents.length || uniqueOrComponents.length) && (
        <>
          <h4>Filter Query:</h4>
          <ul className={styles.componentItems}>
            {uniqueAndComponents.map((component, index) =>
              <li className={styles.componentItem} key={index}>
                <span className={styles.label}>AND </span>
                {component}
              </li>
            )}
          </ul>
          <ul className={styles.componentItems}>
            {uniqueOrComponents.map((component, index) =>
              <li className={styles.componentItem} key={index}>
                <span className={styles.label}>OR </span>
                {component}
              </li>
            )}
          </ul>
        </>
      )}
      <h5>
        Note: Conceptual Tokens are based upon both taxonomies and sources dedicated to a given topic. What is displayed above represents only the taxonomy portion of the conceptual tokens.
      </h5>
    </div>
  )
}
SearchQueryComponents.propTypes = {
  baseQuery: PropTypes.string,
  andComponents: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  orComponents: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}
