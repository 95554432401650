import classNames from 'classnames'
import invariant from 'invariant'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { Component } from 'react'

import Cell from './Cell'
import Column from './Column'
import Row from './Row'

import './style.less'


/*
 * Generic data table component
 */
export default class Table extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    defaultSort: PropTypes.object,
    sort: PropTypes.object,
    onSortChange: PropTypes.func,
    getKeyForItem: PropTypes.func,

    // Disables the table's own sorting
    ignoreSort: PropTypes.bool,

    includeHeader: PropTypes.bool,
    getRowStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    tab: PropTypes.string,
  }

  static defaultProps = {
    ignoreSort: false,
    getKeyForItem: item => item.id,
    includeHeader: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      currentSort: props.defaultSort,
    }
    React.Children.toArray(props.children).forEach(child => {
      invariant(
        child.type === Column,
        "Children passed to <Table /> must be of type <Column />.",
      )
    })
  }

  get columns() {
    return React.Children.toArray(this.props.children).map(child =>
      // This grabs only the props in `Column.propTypes` from the child
      // component
      R.mapObjIndexed((_, key) => child.props[key], Column.propTypes)
    )
  }

  get sortedData() {
    let {data} = this.props
    if (this.props.ignoreSort) return data
    if (this.props.tab==="Searches" || this.props.tab==="Sources") return data
    
    const {currentSort} = this.state

    if (currentSort) {
      const column = this.columns.find(col => col.name === currentSort.column)
      invariant(
        column,
        `The current sort state of this Table references a column '${currentSort.column}' that does not exist.`,
      )

      const sortFunction = (a, b) => {
        // The default sort function sorts by the column value
        const val1 = column.sortBy(a, column)
        const val2 = column.sortBy(b, column)
        return column.sortCompareFunction(val1, val2)
      }
      const sortFunctionWithDirection = currentSort.direction == 'desc'
        // Reverse it if the direction is descending
        ? (a, b) => sortFunction(b, a)
        : sortFunction
      data = [...data].sort(sortFunctionWithDirection)
    }

    return data
  }

  // React methods

  render() {
    return (
      <div
        id={this.props.id}
        className={classNames('data-table', this.props.className)}
      >
        {
          this.props.includeHeader &&
          <div className="head">
            {this.renderHead(this.columns)}
          </div>
        }

        <div className="table-body">
          {this.renderRows(this.sortedData)}
        </div>
      </div>
    )
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.sort && nextProps.sort !== this.state.currentSort) {
      this.setState({currentSort: nextProps.sort})
    }
  }

  // Render helpers

  renderHead(columns) {
    const cells = columns.map(this.renderHeadColumn.bind(this))
    return <div className="row">{cells}</div>
  }

  renderHeadColumn(column) {
    const {currentSort} = this.state
    const sortClassNames = currentSort && currentSort.column === column.name && !this.props.ignoreSort
      ? {
        sorted: true,
        asc: currentSort.direction == 'asc',
        desc: currentSort.direction == 'desc',
      }
      : {}
    return (
      <Cell
        columnName={column.name}
        className={classNames(
          sortClassNames,
          {sortable: column.isSortable},
        )}
        baseWidth={column.baseWidth}
        minWidth={column.minWidth}
        maxWidth={column.maxWidth}
        growRatio={column.growRatio}
        shrinkRatio={column.shrinkRatio}
        onClick={() => this.onClickHeaderCell(column)}
        key={column.name}
      >
        {column.label}
      </Cell>
    )
  }

  renderRows(data) {
    return data.map(item =>
      <Row
        columns={this.columns}
        item={item}
        key={this.props.getKeyForItem(item)}
        getRowStyle={this.customRowStyle(this.props.getRowStyle, item)}
      />
    )
  }

  // Event handlers
  customRowStyle(value, item){
    if(!value){
      return ''
    }

    if (typeof value == 'function') {
      return value(item)
    }
    return value
  }

  onClickHeaderCell(column) {
    if (!column.isSortable) return

    let direction
    const {currentSort} = this.state
    if (
      column.isSortable &&
      currentSort &&
      currentSort.column == column.name &&
      currentSort.direction == 'asc'
    ) {
      direction = 'desc'
    } else {
      direction = 'asc'
    }

    const newSort = {column: column.name, direction}
    if (this.props.onSortChange) {
      this.props.onSortChange(newSort)
    }
    if (!this.props.sort) {
      // If this table's sorting is managed elsewhere, we don't want to override
      // that.
      this.setState({currentSort: newSort})
    }
  }
}


Table.Column = Column
Table.Cell = Cell
export {Column, Cell}
