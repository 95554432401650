import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Dropdown from 'app/common/Dropdown'
import Option from 'app/common/Dropdown/Option'
import Button from 'app/common/Button'
import classNames from 'classnames'
import * as styles from './EsgCompanyTable.less'
import { MY_COMPANIES, ALL_COMPANIES, REMOVE_COMPANIES, ADD_COMPANIES } from '../esg-constants'
import Tooltip from 'app/common/Tooltip'

export default class BulkAction extends Component {
  static propTypes = {
    totalCount: PropTypes.number.isRequired,
    selectedCompanyIds: PropTypes.array.isRequired,
    visibleCompanyIds: PropTypes.array.isRequired,
  }

  render() {
    const { totalCount, selectedCompanyIds, visibleCompanyIds, selectedCompanyOption } = this.props
    const visibleCompaniesLength = visibleCompanyIds.map(company => { return company.id })
    const selectAllShouldBeChecked = (selectedCompanyIds.length > 0 && R.intersection(selectedCompanyIds, visibleCompaniesLength).length === visibleCompaniesLength.length)
    const selectedCountText =
      selectedCompanyIds.length ?
        <span className="selected-count-text">
          {
            selectedCompanyIds.length === totalCount ?
              `Select all (${totalCount})`
              : `Select all (${selectedCompanyIds.length})`
          }
        </span>
        : <span className="selected-count-text">
          Select all (0)
        </span>
    return (
      <div className="bulk-actions">
        <div className="checkbox-dropdown">
          <Dropdown value={''} onChange={(value) => this.props.onChangeSelectAll(value)}>
            <Option
              value=''
              isInvisible={true}
              label={
                <input
                  type="checkbox"
                  onClick={e => e.preventDefault()}
                  onChange={e => e.preventDefault()}
                  checked={selectAllShouldBeChecked}
                />
              }
            />
            {
              selectedCompanyOption === MY_COMPANIES ? <Option label={`Select All (${totalCount})`} value="all" /> : null
            }
            <Option label={`Select Visible (${visibleCompanyIds.length})`} value="visible" />
            <Option label="Deselect All" value="none" />
          </Dropdown>
        </div>
        {selectedCountText}
        {
          selectedCompanyOption === MY_COMPANIES ?
            <Tooltip
              label="Select Companies to remove"
              disabled={selectedCompanyIds.length > 0}
            >
              <div className="remove-companies" onClick={() => this.props.showDeleteConfirmation()}>
                <img src='/media/img/Red_Trash.png' className='tool-tip' />
                <span>{REMOVE_COMPANIES}</span>
              </div></Tooltip> :
            <Tooltip
              label="Select Companies to add"
              disabled={selectedCompanyIds.length > 0}
            >
              <div className="remove-companies" style={{ color: "#035c8b" }} onClick={() => this.props.showBulkCompanyAddConfirmation()}>
                <img src='/media/img/Add_Icon.png' className='tool-tip' />
                <span>{ADD_COMPANIES}</span>
              </div>
            </Tooltip>
        }
      </div>
    )

  }
}