import {parseISO} from 'date-fns'

import Model from 'app/framework/Model'
import {field, foreignKey} from 'app/framework/Model/fields'

import User from './User'


export default class ActionLog extends Model {
  static entityKey = 'actionLog'

  static fields = {
    user: foreignKey(User),
    originalUser: foreignKey(User),
    createdAt: field(),
    objectType: field(),
    objectId: field(),
    action: field(),
    arguments: field(),
  }

  constructor(data) {
    super(data)
    const {createdAt} = data
    if (createdAt && createdAt.constructor !== Date) {
      this.createdAt = parseISO(createdAt)
    }
    this.arguments = JSON.parse(data.arguments)
  }
}
