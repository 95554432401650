import {handleActions} from 'redux-actions'
import * as actions from './dashboard-actions'
import {assocPath} from "ramda"


const getInitialState = () => ({
  firstCategoryHasChart: false,
  sqoopHelpText: null,
  areAllSectionsLoaded: false,
  isLoading: true,
  activeSearchId: null,
  activeSectionName: null,
  fetchedSections: [],
  fetchedSearchIds: [],
  fetchedDocumentIds: [],
  chartData: {
    client: null,
    tracker: null,
  }
})

export default handleActions(
  {
    [actions.init]: (state, action) => {
      return {
        ...state,
        firstCategoryHasChart: action.payload.firstCategoryHasChart,
        sqoopHelpText: action.payload.sqoopHelpText,
        groupId: action.payload.groupId
      }
    },
    [actions.fetchDashboardSectionComplete]: (state, action) => {
      const {section} = action.payload
      const fetchedSections = [...state.fetchedSections]
      if (!fetchedSections.includes(section)){fetchedSections.push(section)}
      return {
        ...state,
        fetchedSections: fetchedSections,
      }
    },

    [actions.fetchDashboardResultsComplete]: (state, action) => {
      const {sectionResults} = action.payload
      const fetchedSections = [...state.fetchedSections]
      const section = fetchedSections.find(sec => sec.section === sectionResults.section)
      if (section) {
        section.showChart = sectionResults.showChart
        section.chartIds = sectionResults.chartIds
        section.searchData = sectionResults.searchData
      }
      const fetchedSearchIds = [...new Set([...state.fetchedSearchIds, ...action.payload.searchIds])]
      const fetchedDocumentIds = [...new Set([...state.fetchedDocumentIds, ...action.payload.documentIds])]
      return {
        ...state,
        fetchedSections: fetchedSections,
        fetchedSearchIds: fetchedSearchIds,
        fetchedDocumentIds: fetchedDocumentIds,
      }
    },

    [actions.setIsLoading]: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
    [actions.setDocumentIds]: (state, action) => ({
      ...state,
      fetchedDocumentIds: action.payload,
    }),
    [actions.setChartData]: (state, action) => {
      const {section, data} = action.payload
      return assocPath(
        ['chartData', section],
        data,
        state,
      )
    },
    [actions.setActiveSidebarNav]: (state, action) => {
      return {
        ...state,
        activeSearchId: action.payload.searchId,
        activeSectionName: action.payload.sectionName,
      }
    },
    [actions.setAreAllSectionsLoaded]: (state, action) => ({
      ...state,
      areAllSectionsLoaded: action.payload,
    }),
  },

  getInitialState(),
)
