import React, { Component } from 'react'
import { connect, Provider } from 'react-redux'
import classNames from 'classnames'

import { SavedSearch } from 'app/models'
import Orm from 'app/framework/Orm'
import InputBlock from 'app/common/InputBlock'
import Modal from 'app/common/Modal'
import TextBox from 'app/common/TextBox'
import Button from 'app/common/Button'

import { getProfileSources } from './profile-sources-selectors'
import {
  setSelectedFolderIds,
  showAddToFolderModal,
  hideAddToFolderModal,
  showModalCreateFolder,
  hideModalCreateFolder,
  setNewFolderName,
  createFolder,
  addFeedsToFolders,
} from './profile-sources-actions'

import './AddToFolderModal.less'


@connect(
  state => {
    const profileSources = getProfileSources(state)
    const orm = Orm.withEntities(state.entities)

    return {
      isLoading: profileSources.isLoading,
      trustedFolders: orm.getByIds(SavedSearch, profileSources.trustedFolderIds),
      addToFolderModalData: profileSources.addToFolderModalData,
      selectedFirmFeedIds: profileSources.selectedFirmFeedIds,
      selectedGlobalFeedIds: profileSources.selectedGlobalFeedIds,
      newFolderName: profileSources.newFolderName,
    }
  },
  {
    setSelectedFolderIds,
    showAddToFolderModal,
    hideAddToFolderModal,
    showModalCreateFolder,
    hideModalCreateFolder,
    setNewFolderName,
    createFolder,
    addFeedsToFolders,
  },
)
export default class AddToFolderModal extends Component {
  state = {
    filterValue: '',
  }

  render() {
    const trustedFolders =
      this.props.trustedFolders
      ? Object.values(this.props.trustedFolders)
      : []

    const trustedFoldersFiltered =
      this.props.trustedFolders
      ? trustedFolders.sort((tf1, tf2) => {
          const name1 = tf1.name.trim()
          const name2 = tf2.name.trim()
          return name1.localeCompare(name2)
        }).filter(tf =>
          !this.state.filterValue || tf.name.toLowerCase().includes(this.state.filterValue.toLowerCase())
        )
      : []

    const folderList =
      trustedFoldersFiltered.map(tf =>
        <InputBlock key={tf.id} label={tf.name} isInline>
          <input
            type="checkbox"
            checked={this.props.addToFolderModalData.selectedFolderIds.includes(tf.id)}
            onChange={evt => this.handleFolderCheckbox(evt.target.checked, tf.id)}
          />
        </InputBlock>
      )

    const prompt =
      trustedFolders.length > 0
      ? this.props.feedIdsToAdd && this.props.feedIdsToAdd.length === 1
        ? <div className={'modal-body-row'}>Which folder(s) do you want to add your trusted source to?</div>
        : <div className={'modal-body-row'}>Which folder(s) do you want to add your trusted sources to?</div>
      : null

    const header =
      trustedFolders.length > 0
      ? <div className={'modal-header'}>
          Select Folder of Trusted Sources
        </div>
      : <div className={'modal-header'}>
          Please create at least one trusted source folder.
        </div>

    const filterContainer =
      trustedFolders.length > 0
      ? <div className="modal-body-row">
          <TextBox
            placeholder="Filter by Folder Name"
            value={this.state.filterValue}
            onChange={evt => this.handleFilterChange(evt.target.value)}
          />
        </div>
      : null

    const createFolderContainer =
      this.props.addToFolderModalData.isCreateFolderVisible
      ? <div className="modal-body-row">
          <TextBox
            placeholder="Enter New Folder Name"
            value={this.props.newFolderName}
            onChange={evt => this.handleNewFolderNameChange(evt.target.value)}
          />
          <Button
            label="Create Folder"
            disabled={!this.props.newFolderName}
            onClick={() => this.createFolder()}
          />
          <Button
            label="Cancel"
            isPlainText={true}
            onClick={() => this.hideCreateFolder()}
          />
        </div>
      : <div className="modal-body-row">
          <a
            onClick={() => this.showCreateFolder()}
          >Create New Folder</a>
        </div>

    const modalContent =
      <React.Fragment>
        {header}

        <div className={'modal-body'}>
          {prompt}

          {createFolderContainer}

          {filterContainer}

          <div className="folder-list">
            {folderList}
          </div>
        </div>
      </React.Fragment>

    const confirmButton = trustedFolders.length > 0
      ? <div>
          <Button
            label="Add to Folder"
            className={'button'}
            disabled={this.props.addToFolderModalData.selectedFolderIds.length === 0}
            onClick={() => {this.onAddToFoldersClick()}}
          />
        </div>
      : null

    const modalClasses = trustedFolders.length > 0
      ? classNames('add-to-trusted-modal', this.props.modalClassGrow)
      : classNames('add-to-trusted-modal', 'shrink')

    return (
      <div
        className={'add-to-trusted-folder-container'}
      >
        <Modal
          isOpen={this.props.addToFolderModalData.isShown}
          className={modalClasses}
          onClose={() => {this.props.hideAddToFolderModal()}}
        >
          {modalContent}
          <div className={'button-container'}>
            {confirmButton}
            <div>
              <Button
                label="Cancel"
                isPlainText={true}
                onClick={() => {this.props.hideAddToFolderModal()}}
              />
            </div>
          </div>
        </Modal>
      </div>
    )
  }

  showCreateFolder() {
    this.props.showModalCreateFolder()
  }

  hideCreateFolder() {
    this.props.hideModalCreateFolder()
  }

  handleNewFolderNameChange(newFolderName) {
    this.props.setNewFolderName(newFolderName)
  }

  createFolder() {
    this.props.createFolder(this.props.newFolderName)
  }

  handleFilterChange(filterValue) {
    this.setState({filterValue})
  }

  handleFolderCheckbox(checked, folderId) {
    let selectedFolderIds = [...this.props.addToFolderModalData.selectedFolderIds]
    if (checked) {
      selectedFolderIds.push(folderId)
    } else {
      selectedFolderIds = selectedFolderIds.filter(id => id !== folderId)
    }
    this.props.setSelectedFolderIds(selectedFolderIds)
  }

  onAddToFoldersClick() {
    this.props.addFeedsToFolders({
      feedIds: this.props.selectedFirmFeedIds.concat(this.props.selectedGlobalFeedIds),
      searchIds: this.props.addToFolderModalData.selectedFolderIds,
    })
  }
}
