import React from 'react'

import Industry from '../../Industry'
import EsgCompanyTable from '../../EsgCompanyTable'
import Tutorials from '../../Tutorials'

import './style.less'

export function IndustryDashboard() {
  const [pillarFilter, setPillarFilter] = React.useState(null)
  const [topicFilter, setTopicFilter] = React.useState(null)
  const filterOptions = {
    pillarFilter,
    setPillarFilter,
    topicFilter,
    setTopicFilter,
  }

  return (
    <div className="dashboard">
      <h1 className="header">ESG Dashboard</h1>
      <main>
        <div style={{gridArea: 'main'}}>
          <Industry {...filterOptions} />
          <EsgCompanyTable />
        </div>
        <div style={{gridArea: 'sidebar'}}>
          <Tutorials />
        </div>
      </main>
    </div>
  )
}
