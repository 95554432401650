import React from 'react'
import PropType from 'prop-types'

ESGSection.propTypes = {
    posts: PropType.arrayOf(PropType.shape({
        title: PropType.string.isRequired,
        snippet: PropType.string.isRequired,
        link: PropType.string.isRequired,
    })).isRequired
}

function ESGSection({
    posts
}) {
    return (
        <section className='section esg-section'>
            <header>
                <h1>About ESG</h1>
                <a className='link-with-arrow' href='https://www.diligent.com/solutions/modern-esg' target='_blank'>See more</a>
            </header>

            {posts.map((post, idx) =>
                <article key={idx}>
                    <header>
                        <h1>{post.title}</h1>
                    </header>
                    <p>{post.snippet}</p>
                    <a className='link-with-arrow' href={post.link} target='_blank'>Read more</a>
                </article>)}
        </section>
    )
}

export default ESGSection