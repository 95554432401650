import { changeCaseObject } from 'app/utils'
import React, { Component } from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import store from 'app/store'
import './EmailDelivery.less'
import * as actions from '../email-notifications-actions'
import * as constants from '../email-notifications-constants'
import InputBlock from 'app/common/InputBlock'
import AppWrapper from "app/global/app-wrapper"
import { titleCase } from 'title-case'
import TextBox from "app/common/TextBox"
import Button from "app/common/Button";
import Tooltip from 'app/common/Tooltip'


@connect(
  state => ({
    selectedEmailDelivery: state.emailNotifications.emailDeliveryData.selected,
    selectedPrefix: state.emailNotifications.selectedPrefix,
    selectedSuffix: state.emailNotifications.selectedSuffix,
    isSignalsEnabled: state.emailNotifications.emailDeliveryData.isSignalsEnabled,
    isGroupUser: state.emailNotifications.emailDeliveryData.isGroupUser,
    editUserId: state.emailNotifications.emailDeliveryData.editUserId,
    insightsLabel: state.insightsLabel,
    currentUserIsStaff: state.currentUserIsStaff,
    applyGroupMembers : state.emailNotifications.emailDeliveryData.emailDeliveryToGroupMembers,
    emailDeliveryToGroupMembers: state.emailNotifications.emailDeliveryToGroupMembers,
  }),
  {
    saveEmailDelivery: actions.api.saveEmailDelivery.request,
    addEmailPrefixSuffix: actions.api.addEmailPrefixSuffix.request,
    applyEmailDeliverySettings: actions.api.applyEmailDeliverySettings.request,
  },
)

export default class EmailDelivery extends Component {
  state = {
    prefixText: this.props.selectedPrefix,
    suffixText: this.props.selectedSuffix,
    applyMember: this.props.emailDeliveryToGroupMembers,
  }
  render() {

    const selectedEmailDeliveryArray = this.props.selectedEmailDelivery.split(',');
    const tooltipTextApplyToGroupMembers = "When enabled, enabling Disable Alerts will disable the ESG and Insights email alerts for all group members. Enabling Insights and/or ESG Alerts will enable for all group members. Disabling Disable Alerts will uncheck the checkbox and not turn on the ESG or Insights checkboxes. The Admin can press those checkboxes if they want those alerts enabled. Each group member’s Personal Alerts and Group Alerts will not be affected."
    return (
      <div id={'email-delivery-container'}>
        <div className={'email-delivery-label'}>Email Delivery</div>
        <div className={'options'}>
          {constants.DELIVERY_OPTIONS.map(
            (opt, index) => this.emailDeliveryOption(
              opt, selectedEmailDeliveryArray, index, this.props.insightsLabel)
          )}
        </div>
        {
        this.props.isGroupUser && <>
        <div className={'email-delivery-label'}>Apply Email Delivery to Group members
          {this.getTooltip(tooltipTextApplyToGroupMembers)}
        </div>
          <div>
            {
              this.applyEmailDeliveryProfile()
            }
          </div>
          </>
        }
        <div className={'customize-email-alert'}>Customize Email Alert</div>
        <div className={'customize-email-alert'}>
          {
            this.emailPrefixSuffixOption()
          }
        </div>
      </div>
    )
  }

  getTooltip(label) {
    if(!label) {
      return null
    }
    return <Tooltip
      render={() => <div style={{
          fontSize: '14px',
          wordWrap: 'break-word'
        }}>
            {label}
          </div>}
          direction='bottom'
          noBorder="true"
        >
          <a className="tooltip help"/>
      </Tooltip>
  }

  applyEmailDeliveryProfile() {
    const emailDeliveryBlock = <InputBlock
      className={'input-label'}
      label={"Apply Email Delivery to Group Members"} isInline
    >
      <input
        className={'email-delivery-input'}
        type="checkbox"
        checked={this.state.applyMember}
        onChange={(e) => this.applyDelivery(e)}
      />
    </InputBlock>

    return (
      emailDeliveryBlock
    )
  }

  applyDelivery(event) {
    const isChecked = event.target.checked
    this.setState({ applyMember: isChecked }, () => {
      this.props.applyEmailDeliverySettings({
        editUserId: this.props.editUserId,
        isSelected: isChecked
      })
    })
  }

  emailPrefixSuffixOption() {
    const prefix = <InputBlock className={'customize-prefix-suffix'} label={'Prefix'}>
      <TextBox
        className={'frequency-input'}
        value={this.state.prefixText}
        onChange={(e) => this.setState({ prefixText: e.target.value })}
      />
      <div className={'html-notifier'}>
        The Prefix Text accepts both plain text and HTML.</div>
    </InputBlock>

    const suffix = <InputBlock className={'customize-prefix-suffix'} label={"Suffix"}>
      <TextBox
        className={'frequency-input'}
        value={this.state.suffixText}
        onChange={(e) => this.setState({ suffixText: e.target.value })}
      />
      <div className={'html-notifier'}>
        The Suffix Text accepts both plain text and HTML.</div>
    </InputBlock>

    const button = <Button
      label={'Add'}
      isPrimary={false}
      onClick={(e) => this.onEmailPrefixChange(
        this.state.prefixText, this.state.suffixText)}
    />

    return (<div>
      {prefix}
      {suffix}
      {button}
    </div>
    )
  }

  onEmailPrefixChange(prefix, suffix) {
    this.props.addEmailPrefixSuffix({
      prefix:prefix,
      suffix: suffix,
      editUserId: this.props.editUserId,
    })
  }

  emailDeliveryOption(deliveryOption, selectedEmailDeliveryArray, index, insightsLabel) {

    const optionLabel = deliveryOption.label === 'Insights Alerts'
      ? `${titleCase(insightsLabel)} Alerts`
      : deliveryOption.label

    const block = (deliveryOption.option === constants.GROUP
      || deliveryOption.option === constants.PERSONAL)
      && this.props.isGroupUser
      ? null
      : !this.props.isSignalsEnabled
        && deliveryOption.option === constants.SIGNALS
        ? null
        : <InputBlock
          className={'input-label'}
          label={optionLabel} isInline
          key={index}
        >
          <input
            className={'email-delivery-input'}
            type="checkbox"
            checked={selectedEmailDeliveryArray.includes(
              deliveryOption.option
            )}
            onChange={(e) => this.onEmailDeliveryChange(
              deliveryOption.option, selectedEmailDeliveryArray, e)}
          />
        </InputBlock>
    return (
      block
    )
  }
  onEmailDeliveryChange(option, selectedEmailDeliveryArray, event) {
    const preSelected =
      option === constants.DISABLE && event.target.checked
        ? [constants.DISABLE]
        : !event.target.checked &&
          selectedEmailDeliveryArray &&
          selectedEmailDeliveryArray.length === 1
        ? [constants.DISABLE]
        : selectedEmailDeliveryArray
    const applyGroupMember = this.state.applyMember ? true : false
    this.props.saveEmailDelivery({
      option: option,
      isSelected: event.target.checked,
      preSelected: preSelected,
      editUserId: this.props.editUserId,
      applyGroupMember: applyGroupMember,
    })
    
  }
}

export function init(data) {
  const {emailDeliveryData} = changeCaseObject.camelCase(data)
  const element = document.getElementById('django-email-delivery-container')
  if (!element) return
  store.dispatch(actions.emailDeliveryInit(emailDeliveryData))
  const component =
    <AppWrapper>
      <EmailDelivery />
    </AppWrapper>
  render(component, element)
}
