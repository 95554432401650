import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {titleCase} from 'title-case'
import classNames from 'classnames'

import InputBlock from 'app/common/InputBlock'
import TextBox from 'app/common/TextBox'
import Button from 'app/common/Button'
import MenuDropdown from 'app/common/MenuDropdown'


class QuickAddMemberForm extends Component {
  state = {
    newEmailAddress: '',
  }

  render() {
    return (
      <div
        className="inside-labels group-member-quick-add"
      >
        <span className="label">Email:</span>
        <input
          type="text"
          name="email"
          className="text-box"
          value={this.state.newEmailAddress}
          placeholder="Enter an email address here to quickly add a member to this Group"
          onChange={evt => this.handleNewEmailAddressChange(evt)}
        />
        <button
          type="submit"
          className="button"
          onClick={() => this.handleSubmit()}
        >
          Quick Add
        </button>
      </div>
    )
  }

  handleNewEmailAddressChange(evt) {
    this.setState({newEmailAddress: evt.target.value})
  }

  handleSubmit() {
    this.props.onSubmit(this.state.newEmailAddress)
    this.setState({newEmailAddress: ''})
  }
}


/*
 * Generic membership component. Shows available entities on one side, and
 * memberships on the other. Used on the group members and user group
 * memberships pages. Takes these props:
 *
 *   availableMembers: Array<User>
 *     Represents all available users/groups, including those that are already members.
 *
 *   memberships: Array<Membership>
 *
 *   type: String
 *     The type of the `availableMembers` list. Either "user" or "group".
 *
 *   add: (array of userIds) => void
 *   remove: (array of userIds) => void
 *   onMemberChange: (userId) => void
 *   onMemberQuickAdd: (email) => void
 */
export default class MemberList extends Component {
  static propTypes = {
    availableMembers: PropTypes.arrayOf(PropTypes.object).isRequired,
    memberships: PropTypes.arrayOf(PropTypes.object).isRequired,
    type: PropTypes.string.isRequired,
    add: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    toggleManager: PropTypes.func.isRequired,
    availableMemberCount: PropTypes.number.isRequired,
    onFetchMembers: PropTypes.func.isRequired,
    areAllAvailableMembersFetched: PropTypes.bool.isRequired,

    onMemberQuickAdd: PropTypes.func,
  }

  static defaultProps = {
    onMemberQuickAdd: () => {},
  }

  state = {
    nameFilter: '',
    membershipsFilter: '',
    selectedAvailableUserIds: [],
    selectedAddedUserIds: [],
    listItemMenuKey: '',
  }

  render() {
    const userType = this.props.type
    const filteredMembers = this.getFilteredMembers()
    const memberships = this.getMemberships()
    const addLabel = userType === 'user'
      ? 'Add User'
      : 'Add Group'

    const availableMembersList = filteredMembers.map(user => {
      return (
        <div
          key={user.id}
          className="list-item"
        >
          <div className="trunc list-item-name">
            <input
              type="checkbox"
              checked={this.state.selectedAvailableUserIds.includes(user.id)}
              onChange={evt => this.handleAvailableUserCheckbox(user.id, evt.target.checked)}
            />
            <span>{user.displayName}</span>
          </div>

          <div className="hover-show">
            <MenuDropdown
              options={[
                {
                  label: addLabel,
                  value: 'addMember',
                }
              ]}
              onMenuIconClick={() => this.handleListItemMenuClick(`available-${user.id}`)}
              onOptionClick={option => this.handleListItemMenuOptionClick(user.id, option)}
              isMenuOpen={this.state.listItemMenuKey === `available-${user.id}`}
            />
          </div>
        </div>
      )
    })

    const membersList = memberships.map(membership => {
      const userId = membership[userType].id

      const firmWideGroupLabel = membership.group.isFirmGroup
        ? <div className="list-item-column">
            <span>Firm Wide Group</span>
          </div>
        : null

      const firmAdminLabel = membership.user.isFirmAdmin
        ? <div className="list-item-column">
            <span>Admin</span>
          </div>
        : null

      const managerLabel = membership.isManager
        ? <div className="list-item-column">
            <span>Manager</span>
          </div>
        : null

      const actionMenuOptions = []

      if (!membership.user.isFirmAdmin) {
        const managerOption = {
          label: 'Make Manager',
          value: 'toggleManager',
        }
        if (membership.isManager) {
          managerOption.label = 'Remove as Manager'
        }
        actionMenuOptions.push(managerOption)
      }

      if (!membership.group.isFirmGroup) {
        actionMenuOptions.push({
          label: 'Remove',
          value: 'removeMember',
        })
      }

      return (
        <div
          key={userId}
          className="list-item"
        >
          <div className="trunc list-item-name">
            {
              membership.group.isFirmGroup
              ? null
              : <input
                  type="checkbox"
                  checked={this.state.selectedAddedUserIds.includes(userId)}
                  onChange={evt => this.handleAddedUserCheckbox(userId, evt.target.checked)}
                />
            }
            <span>{membership[userType].displayName}</span>
          </div>

          <div className="list-item-right">
            {firmWideGroupLabel}

            {firmAdminLabel}

            {managerLabel}

            {
              !membership.group.isFirmGroup && actionMenuOptions.length > 0
              ? <div className="hover-show list-item-column">
                  <MenuDropdown
                    options={actionMenuOptions}
                    onMenuIconClick={() => this.handleListItemMenuClick(`member-${userId}`)}
                    onOptionClick={option => this.handleListItemMenuOptionClick(userId, option)}
                    isMenuOpen={this.state.listItemMenuKey === `member-${userId}`}
                  />
                </div>
              : null
            }
          </div>
        </div>
      )
    })

    const quickAddForm = userType === 'user'
      ? <QuickAddMemberForm onSubmit={this.onQuickAddFormSubmit} />
      : null

    const availableLabel = userType === 'user'
      ? 'Available Users'
      : 'Available Groups'

    const membersLabel = userType === 'user'
      ? 'Members'
      : 'Member of Groups'

    const filterLabel = userType === 'user'
      ? 'Filter by User Name'
      : 'Filter by Group Name'

    const filterMessage =
      !this.props.areAllAvailableMembersFetched && !this.state.nameFilter &&
      <div className="filter-message">
        <span>Please filter for results or </span>
        <a onClick={() => this.props.onFetchMembers({forceFetch: true})}>click here to load all {userType}s ({this.props.availableMemberCount})</a>.
      </div>

    return (
      <div>
        {quickAddForm}

        <div className="grid ua-base" id="ua-members-container">
          <div className="col-1-2 pad-l-l pad-b-l column no-right">
            <h2>{availableLabel}</h2>

            <InputBlock label={filterLabel}>
              <TextBox
                value={this.state.nameFilter}
                onChange={this.onEntitiesFilterInputChange}
              />
            </InputBlock>

            <div className="pad-10">
              <input
                type="checkbox"
                checked={filteredMembers.length > 0 && filteredMembers.length === this.state.selectedAvailableUserIds.length}
                onChange={evt => this.handleSelectAllAvailableMembersCheckbox(evt.target.checked)}
                disabled={this.state.selectedAvailableUserIds.length === 0}
              />
              <Button
                label={`${addLabel}(s)`}
                isNarrow
                isSkinny
                onClick={() => this.addSelected()}
                disabled={this.state.selectedAvailableUserIds.length === 0}
              />
            </div>

            <div
              className={classNames('ua-list-box ua-lists', {'is-groups-list': userType === 'group'})}
              onScroll={() => this.handleScroll()}
            >
              {filterMessage}

              <div id="user-available" className="list plain">
                {availableMembersList}
              </div>
            </div>
          </div>

          <div className="col-1-2 no-right column pad-l-l pad-r-l">
            <h2>{membersLabel}</h2>

            <InputBlock label={filterLabel}>
              <TextBox
                value={this.state.membershipsFilter}
                onChange={this.onMembershipsFilterInputChange}
              />
            </InputBlock>

            <div className="pad-10">
              <input
                type="checkbox"
                checked={memberships.length > 0 && memberships.length === this.state.selectedAddedUserIds.length}
                onChange={evt => this.handleSelectAllAddedMembersCheckbox(evt.target.checked)}
                disabled={this.state.selectedAddedUserIds.length === 0}
              />
              <Button
                label={`Remove ${titleCase(userType)}(s)`}
                isNarrow
                isSkinny
                onClick={() => this.removeSelected()}
                disabled={this.state.selectedAddedUserIds.length === 0}
              />
            </div>

            <div className={classNames('ua-list-box ua-lists', {'is-groups-list': userType === 'group'})}>
              <div id="members-list" className="list plain">
                {membersList}
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }

  handleScroll() {
    this.setState({listItemMenuKey: ''})
  }

  handleListItemMenuClick(key) {
    const listItemMenuKey = key === this.state.listItemMenuKey ? '' : key
    this.setState({listItemMenuKey})
  }

  handleListItemMenuOptionClick(userId, option) {
    const action = option.value
    switch (action) {
      case 'addMember':
        this.props.add([userId])
        break;
      case 'removeMember':
        this.props.remove([userId])
        break;
      case 'toggleManager':
        this.props.toggleManager(userId)
        break;
    }
  }

  addSelected() {
    this.props.add(this.state.selectedAvailableUserIds)
    this.setState({selectedAvailableUserIds: []})
  }
  removeSelected() {
    this.props.remove(this.state.selectedAddedUserIds)
    this.setState({selectedAddedUserIds: []})
  }

  onEntitiesFilterInputChange = (e) => {
    this.setState({nameFilter: e.target.value, selectedAvailableUserIds: []})
    this.props.onFetchMembers({name: e.target.value})
  }

  onMembershipsFilterInputChange = (e) => {
    this.setState({membershipsFilter: e.target.value, selectedAddedUserIds: []})
  }

  onQuickAddFormSubmit = (newEmailAddress) => {
    this.props.onMemberQuickAdd(newEmailAddress)
  }

  getFilteredMembers() {
    const userType = this.props.type
    return this.props.availableMembers.filter(user => {
      const nameFilter = this.state.nameFilter.toLowerCase()
      return (
        !this.props.memberships.find(m => m[userType].id === user.id) &&
        (
          user.username.toLowerCase().includes(nameFilter) ||
          user.firstName.toLowerCase().includes(nameFilter) ||
          user.lastName.toLowerCase().includes(nameFilter)
        )
      )
    }).sort((user1, user2) => {
      const displayName1 = user1.displayName.toLowerCase()
      const displayName2 = user2.displayName.toLowerCase()
      return displayName1.localeCompare(displayName2)
    })
  }

  getMemberships() {
    const userType = this.props.type
    return this.props.memberships.filter(membership => {
      const entity = membership[userType]
      const filter = this.state.membershipsFilter.toLowerCase()
      return entity.displayName.toLowerCase().includes(filter)
    }).sort((membership1, membership2) => {
      if (membership1.group.isFirmGroup) return -1
      if (membership2.group.isFirmGroup) return 1

      const displayName1 = membership1[userType].displayName.toLowerCase()
      const displayName2 = membership2[userType].displayName.toLowerCase()
      return displayName1.localeCompare(displayName2)
    })
  }

  handleAvailableUserCheckbox(userId, checked) {
    let selectedAvailableUserIds = [...this.state.selectedAvailableUserIds]
    if (checked) {
      selectedAvailableUserIds.push(userId)
    } else {
      selectedAvailableUserIds = selectedAvailableUserIds.filter(id => id !== userId)
    }
    this.setState({selectedAvailableUserIds})
  }

  handleSelectAllAvailableMembersCheckbox(checked) {
    const selectedAvailableUserIds = checked ? this.getAvailableMembers().map(u => u.id) : []
    this.setState({selectedAvailableUserIds})
  }

  handleAddedUserCheckbox(userId, checked) {
    let selectedAddedUserIds = [...this.state.selectedAddedUserIds]
    if (checked) {
      selectedAddedUserIds.push(userId)
    } else {
      selectedAddedUserIds = selectedAddedUserIds.filter(id => id !== userId)
    }
    this.setState({selectedAddedUserIds})
  }

  handleSelectAllAddedMembersCheckbox(checked) {
    const userType = this.props.type
    const selectedAddedUserIds = checked ? this.getMemberships().filter(
      // the Firm Group cannot be selected
      m => !m[userType].isFirmGroup
    ).map(
      m => m[userType].id
    ) : []
    this.setState({selectedAddedUserIds})
  }
}
