import React, { useState } from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { changeCaseObject } from 'app/utils'

import store from 'app/store'

import NewUsage from './NewUsage'
import Usage from './Usage'
import * as constants from './usage-constants'
import {
  init as initAdminUSage, fetchUsageData, fetchUsageGridData,
  fetchUsageGridFiltersData, setUsageGridFilters,fetchUsageGridSummaryData
} from './usage-actions'
import reducer from './usage-reducer'
import saga from './usage-saga'
import { COLUMN_KEYS, FILTERS_KEYS, SORTING_TYPE,
  OFF, ON, OPT_NEW_USAGE } from './usage-constants'
import { columnsToFetch, getNecessaryColumn, getSnakeCase, getTooltipStyles } from './utils'
import Switch from 'react-switch'
import './Usage.less'
import Tooltip from 'app/common/Tooltip'

const UsageContainer = () => {

  const [optedNewUsage, setOptedNewUsage] = useState(true)

  const handleToggle = (checked) => {
    setOptedNewUsage(checked)
  }

  return (
    <div className='usage-container'>
      <div className='switch-bar'>
        <div className='switch-container'>
          <Switch
            id={'usage-view-select'}
            onChange={(checked, evt, id) => handleToggle(checked)}
            checked={optedNewUsage}
            height={22}
            width={48}
            handleDiameter={16}
            offColor="#ccc"
            onColor="#0074b4"
            uncheckedIcon={
              <div className="switch-icon">{OFF}</div>
            }
            checkedIcon={
              <div className="switch-icon">{ON}</div>
            }
          />
          <span className="switch-label">{OPT_NEW_USAGE}</span>
          <Tooltip
              // label={constants.OPT_NEW_USAGE_TOOLTIP}
              render={() => <div style={getTooltipStyles()}>
                {constants.OPT_NEW_USAGE_TOOLTIP}
              </div>}
              direction='bottom'
              noBorder="true"
              >
              <img src="/media/img/Icon.svg" className='tool-tip'/>
            </Tooltip>
        </div>
      </div>
      {optedNewUsage ? <NewUsage /> :
        <Usage />}
    </div>
  )
}

export function init(data) {
  data = changeCaseObject.camelCase(data)
  store.dispatch(initAdminUSage(data))
  store.dispatch(fetchUsageData(data))

  const firstDimension = COLUMN_KEYS.profileName
  const distinctColumn = getNecessaryColumn(firstDimension)
  const columnsToSelect = columnsToFetch(firstDimension)
  store.dispatch(setUsageGridFilters({
    [FILTERS_KEYS.firstDimension]: firstDimension,
    [FILTERS_KEYS.distinctColumn]: distinctColumn,
    [FILTERS_KEYS.columnsToSelect]: columnsToSelect,
    [FILTERS_KEYS.sortField]: getSnakeCase(COLUMN_KEYS.uniqueClicks),
    [FILTERS_KEYS.sortDirection]: SORTING_TYPE.DESC,
  }))
  store.dispatch(fetchUsageGridData(data))
  store.dispatch(fetchUsageGridFiltersData(data))
  store.dispatch(fetchUsageGridSummaryData())

  const component = (
    <Provider store={store}>
      <UsageContainer />
    </Provider>
  )
  const container = document.getElementById('admin-usage-wrapper')
  render(component, container)
}

export { constants, reducer, saga }
