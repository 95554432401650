import formatDate from 'date-fns/format'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import Button from 'app/common/Button'
import InputBlock from 'app/common/InputBlock'
import TextBox from 'app/common/TextBox'
import CategoryDropdown from 'app/common/CategoryDropdown'
import {DATE_FORMAT} from 'app/constants'
import {SavedSearch} from 'app/models'
import {defaultPrevented} from 'app/utils'
import {
  getCurrentFirmIsPracticesEnabled,
  getCurrentFirmLibraryName,
} from 'app/global/global-selectors'

import {updateSearch} from '../searches-admin-actions'


@connect(
  createSelector(
    [
      getCurrentFirmIsPracticesEnabled,
      getCurrentFirmLibraryName,
    ],
    (
      currentFirmIsPracticesEnabled,
      currentFirmLibraryName,
    ) => {
      return {
        isPracticesEnabled: currentFirmIsPracticesEnabled,
        currentFirmLibraryName,
      }
    }
  ),
  {
    updateSearch,
  },
)
export default class EditSearchModalDetailsContent extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    const {search} = props

    this.state = {
      name: search.name,
      isNameChanged: false,
      isCascadeName: false,
      category: search.category,
      scope: search.scope,
      notes: search.notes,
    }
  }

  render() {
    const {CATEGORIES} = SavedSearch

    const omittedCategories = this.props.isPracticesEnabled ? [] : [CATEGORIES.PRACTICE]

    const {search, currentFirmLibraryName} = this.props

    const cascadeNameOption = search.isFirmLibrary && this.state.isNameChanged &&
      <InputBlock
        isInline
        label="Also apply Updated Name to All Searches Linked to this one"
         className="bump-up"
      >
        <input
          type="checkbox"
          onChange={this.onCascadeNameCheckboxChange}
        />
      </InputBlock>

    const scopeDescription =
      this.state.scope === 'personal' ?
        <p>"Private” searches are not searchable or browsable as part of the {currentFirmLibraryName} by non admin users
          in your account. Set your search to “Shared” when you are ready to allow your users access to this search and
          to add it to their profiles.</p>
        : <p>"Shared" searches are searchable and browsable as part of the {currentFirmLibraryName} by all users in your
          account. If you are not ready for this search to be visible to all users, set it to "Private".</p>

    const scopeSelection =
      search.isFirmLibrary ?
        <div>
          <div className="label">Search Status</div>

          <InputBlock isInline label="Private" className="radio">
            <input
              type="radio"
              name="scope"
              value="personal"
              defaultChecked={
                this.state.scope === SavedSearch.SCOPES.PERSONAL
              }
              onClick={this.onScopeInputClick}
            />
          </InputBlock>

          <InputBlock isInline label="Shared" className="radio">
            <input
              type="radio"
              name="scope"
              value="shared"
              defaultChecked={
                this.state.scope === SavedSearch.SCOPES.SHARED
              }
              onClick={this.onScopeInputClick}
            />
          </InputBlock>

          <div>{scopeDescription}</div>
        </div>
        : <InputBlock isInline label="Private Search">
            <input
              type="checkbox"
              defaultChecked={
                this.state.scope === SavedSearch.SCOPES.PERSONAL
              }
              onChange={this.onScopeInputChange}
            />
          </InputBlock>

    const notesArea =
      <div>
          <InputBlock label="Notes">
            <TextBox
              multiline
              value={this.state.notes || ''}
              onChange={this.onNotesInputChange}
            />
          </InputBlock>
      </div>

    return (
      <form
        className="details-content"
        onSubmit={defaultPrevented(this.saveChanges.bind(this))}
      >
        <div className="columns">
          <div className="column">
            <InputBlock label="Search Name">
              <TextBox
                defaultValue={search.name}
                onChange={this.onNameInputChange}
              />
            </InputBlock>

            {cascadeNameOption}

            <InputBlock label="Category">
              <CategoryDropdown
                value={this.state.category}
                onChange={this.changeCategory}
                omittedCategories={omittedCategories}
              />
            </InputBlock>

            <div className="columns">
              <div className="column">
                <InputBlock label="Owner">
                  <span className="value">{search.owner.displayName}</span>
                </InputBlock>

                <InputBlock label="Last Updated">
                  <span className="value">
                    {formatDate(search.updatedAt, DATE_FORMAT)}
                  </span>
                </InputBlock>
              </div>

              <div className="separator" />

              <div className="column">
                <InputBlock label="Created">
                  <span className="value">
                    {formatDate(search.createdAt, DATE_FORMAT)}
                  </span>
                </InputBlock>

                <InputBlock label="ID">
                  <span className="value">
                    {search.id}
                  </span>
                </InputBlock>
              </div>
            </div>
          </div>

          <div className="divider" />

          <div className="column">
            {scopeSelection}

            {notesArea}
          </div>
        </div>

        <Button className="save" isPrimary label="Save Changes" />
      </form>
    )
  }

  // Event handlers

  onNameInputChange = (event) => {
    this.setState({name: event.target.value, isNameChanged: true})
  }

  onCascadeNameCheckboxChange = (event) => {
    this.setState({isCascadeName: event.target.checked})
  }

  onScopeInputChange = (event) => {
    const {SCOPES} = SavedSearch
    this.setState({
      scope: event.target.checked ? SCOPES.PERSONAL : SCOPES.SHARED,
    })
  }

  onScopeInputClick = (event) => {
    this.setState({
      scope: event.target.value,
    })
  }

  changeCategory = (category) => {
    this.setState({category})
  }

  onNotesInputChange = (event) => {
    this.setState({
      notes: event.target.value,
    })
  }

  saveChanges() {
    const {search} = this.props
    const data = {
      searchId: search.id,
      name: this.state.name === search.name ? undefined : this.state.name,
      isCascadeName: search.isFirmLibrary ? this.state.isCascadeName : undefined,
      category: this.state.category === search.category ? undefined : this.state.category,
      scope: this.state.scope === search.scope ? undefined : this.state.scope,
      notes: this.state.notes === search.notes ? undefined : this.state.notes,
    }
    this.props.updateSearch(data)
    this.props.closeModal()
  }
}
