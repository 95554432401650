import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import {FACTORS, VALENCES} from 'app/constants'

import * as styles from './styles.less'

export default function FactorIcon({factor, valence, className, ...restProps}) {
  const Icon = {
    [FACTORS.PLANET]: Planet,
    [FACTORS.PEOPLE]: People,
    [FACTORS.GOVERNANCE]: Governance,
    [FACTORS.PROSPERITY]: Prosperity,
  }[factor]
  return (
    <span
      className={classNames(
        styles.icon,
        styles[factor],
        valence && styles[valence.toLowerCase()],
        className,
      )}
      {...restProps}
    >
      {Icon ? <Icon/> : null}
    </span>
  )
}
FactorIcon.propTypes = {
  factor: PropTypes.oneOf(Object.values(FACTORS)).isRequired,
  valence: PropTypes.oneOf(Object.values(VALENCES)),
}

function Planet() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="transparent" fillOpacity={0}
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z"
        stroke="black" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M2.50586 7.40981C2.50881 7.40779 4.78037 5.89306 5.45492 6.66211C6.06918 7.36104 4.32167 7.90075 4.71755 8.62829C5.11343 9.35583 5.87441 8.77379 6.331 9.26876C6.78758 9.76333 5.68933 10.7238 6.11556 11.2478C6.54179 11.7718 6.4423 12.6159 5.38494 12.9069C4.85752 13.052 4.96713 13.8287 5.41571 14.2745C6.03251 14.888 6.48909 16.0178 5.44016 16.2221"
        stroke="black" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M10.3199 15.1027C10.3199 15.1027 9.283 16.8741 7.87879 15.9606C6.53227 15.0851 8.12913 14.6488 7.92463 13.6465C7.71034 12.5957 8.6494 11.4133 9.89805 11.8843C11.1467 12.3553 11.1312 13.7881 10.3199 15.1027Z"
        stroke="black" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M17.5 11.5C17.1643 9.32611 15.8867 8.89329 14.8355 9.10538C13.7387 9.32669 13.9525 10.7635 14.2513 11.5001C14.5502 12.2367 14.027 12.5691 12.9437 14.1166C11.8605 15.6641 12.7096 17.0375 12.7096 17.0375"
        stroke="black" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M17.1112 6.58289C16.4792 7.08459 15.3152 6.57267 14.2372 6.35671C12.9169 6.09186 12.3076 7.98533 10.955 8.20307C9.60234 8.42082 8.8036 6.73043 8.90535 6.02121C9.00711 5.31199 8.60091 4.00287 7.07746 4.05753C6.46568 4.0793 5.95111 3.80113 5.55566 3.46118"
        stroke="black" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

function People() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="transparent" fillOpacity={0} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0625 4.21875C14.9945 4.21875 15.75 3.46323 15.75 2.53125C15.75 1.59927 14.9945 0.84375 14.0625 0.84375C13.1305 0.84375 12.375 1.59927 12.375 2.53125C12.375 3.46323 13.1305 4.21875 14.0625 4.21875Z"
        stroke="#202020" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M12.375 11.5312V15.1875C12.375 15.4113 12.4639 15.6259 12.6221 15.7841C12.7804 15.9424 12.995 16.0312 13.2188 16.0312H14.9062C15.13 16.0312 15.3446 15.9424 15.5029 15.7841C15.6611 15.6259 15.75 15.4113 15.75 15.1875V11.5312H16.5938C16.8175 11.5312 17.0321 11.4424 17.1904 11.2841C17.3486 11.1259 17.4375 10.9113 17.4375 10.6875V6.75C17.4375 6.52622 17.3486 6.31161 17.1904 6.15338C17.0321 5.99514 16.8175 5.90625 16.5938 5.90625H11.5312C11.3075 5.90625 11.0929 5.99514 10.9346 6.15338C10.7764 6.31161 10.6875 6.52622 10.6875 6.75V7.03125"
        stroke="#202020" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M3.9375 4.21875C4.86948 4.21875 5.625 3.46323 5.625 2.53125C5.625 1.59927 4.86948 0.84375 3.9375 0.84375C3.00552 0.84375 2.25 1.59927 2.25 2.53125C2.25 3.46323 3.00552 4.21875 3.9375 4.21875Z"
        stroke="#202020" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M7.3125 6.75C7.3125 6.52622 7.22361 6.31161 7.06537 6.15338C6.90714 5.99514 6.69253 5.90625 6.46875 5.90625H1.40625C1.18247 5.90625 0.967862 5.99514 0.809629 6.15338C0.651395 6.31161 0.5625 6.52622 0.5625 6.75V10.6875C0.5625 10.9113 0.651395 11.1259 0.809629 11.2841C0.967862 11.4424 1.18247 11.5312 1.40625 11.5312H2.25V15.1875C2.25 15.4113 2.33889 15.6259 2.49713 15.7841C2.65536 15.9424 2.86997 16.0312 3.09375 16.0312H4.78125C5.00503 16.0312 5.21964 15.9424 5.37787 15.7841C5.53611 15.6259 5.625 15.4113 5.625 15.1875V11.5312"
        stroke="#202020" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M9 5.34375C9.93198 5.34375 10.6875 4.58823 10.6875 3.65625C10.6875 2.72427 9.93198 1.96875 9 1.96875C8.06802 1.96875 7.3125 2.72427 7.3125 3.65625C7.3125 4.58823 8.06802 5.34375 9 5.34375Z"
        stroke="#202020" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M11.5312 7.03125H6.46875C6.24497 7.03125 6.03036 7.12014 5.87213 7.27838C5.71389 7.43661 5.625 7.65122 5.625 7.875V11.8125C5.625 12.0363 5.71389 12.2509 5.87213 12.4091C6.03036 12.5674 6.24497 12.6562 6.46875 12.6562H7.3125V16.3125C7.3125 16.5363 7.40139 16.7509 7.55963 16.9091C7.71786 17.0674 7.93247 17.1562 8.15625 17.1562H9.84375C10.0675 17.1562 10.2821 17.0674 10.4404 16.9091C10.5986 16.7509 10.6875 16.5363 10.6875 16.3125V12.6562H11.5312C11.755 12.6562 11.9696 12.5674 12.1279 12.4091C12.2861 12.2509 12.375 12.0363 12.375 11.8125V7.875C12.375 7.65122 12.2861 7.43661 12.1279 7.27838C11.9696 7.12014 11.755 7.03125 11.5312 7.03125Z"
        stroke="#202020" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

function Governance() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="transparent" fillOpacity={0} xmlns="http://www.w3.org/2000/svg">
      <path d="M7.875 3.375H3.375L0.5625 9M9 4.5V15.75V4.5ZM6.1875 15.75H11.8125H6.1875Z" stroke="#202020"
            strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M9 4.5C9.62132 4.5 10.125 3.99632 10.125 3.375C10.125 2.75368 9.62132 2.25 9 2.25C8.37868 2.25 7.875 2.75368 7.875 3.375C7.875 3.99632 8.37868 4.5 9 4.5Z"
        stroke="#202020" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.375 3.375L6.1875 9M10.125 3.375H14.625M14.625 3.375L17.4375 9M14.625 3.375L11.8125 9" stroke="#202020"
            strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M3.375 11.8125C4.12092 11.8125 4.83629 11.5162 5.36374 10.9887C5.89118 10.4613 6.1875 9.74592 6.1875 9H0.5625C0.5625 9.74592 0.858816 10.4613 1.38626 10.9887C1.64743 11.2499 1.95747 11.4571 2.2987 11.5984C2.63993 11.7398 3.00566 11.8125 3.375 11.8125V11.8125ZM14.625 11.8125C13.8791 11.8125 13.1637 11.5162 12.6363 10.9887C12.1088 10.4613 11.8125 9.74592 11.8125 9H17.4375C17.4375 9.74592 17.1412 10.4613 16.6137 10.9887C16.0863 11.5162 15.3709 11.8125 14.625 11.8125Z"
        stroke="#202020" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

function Prosperity() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="transparent" fillOpacity={0}
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.75 14.358L4.18347 11.058C4.55601 10.7 5.05267 10.5 5.56937 10.5H12.375L14.6064 8.43055C15.1508 7.92565 16.0078 7.98302 16.4801 8.55597V8.55597C16.9131 9.08126 16.8637 9.85249 16.3672 10.3183L11.0365 15.3198H5.62841L3.64219 17.25M1.76552 13.4867L4.58441 16.3343"
        stroke="black" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.25 12.75H10.875C11.4963 12.75 12 12.2463 12 11.625V11.625C12 11.0037 11.4963 10.5 10.875 10.5H8.25"
            stroke="black" strokeWidth="0.75" strokeLinecap="round"/>
      <path
        d="M13.1383 3.47796C10.1696 3.17235 7.30423 5.50237 6.99831 8.47098C9.96724 8.7769 12.8327 6.44693 13.1383 3.47796Z"
        stroke="black" strokeWidth="0.75" strokeLinejoin="round"/>
      <path d="M6.75001 0.75C4.75018 2.75 4.75 6.25 6.74977 8.25C8.74997 6.25 8.75021 2.75 6.75001 0.75Z" stroke="black"
            strokeWidth="0.75" strokeLinejoin="round"/>
    </svg>
  )
}
