import PropTypes from 'prop-types'
import React from 'react'

import Button from 'app/common/Button'
import BareModal from 'app/common/modals/BareModal'

import styles from './ConfirmationModal.less'


export default class ConfirmationModal extends React.PureComponent {
  static propTypes = {
    message: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
    secondaryMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    confirmButtonText: PropTypes.string.isRequired,
    cancelButtonText: PropTypes.string,
    isDestructive: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,

    /**
     * What to do if the user cancels the request. This is only triggered by
     * clicking on the cancel button, not by closing the modal in any other way.
     * If no value is passed, it will delegate to the `onClose` prop.
     */
    onCancel: PropTypes.func,

    onClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    cancelButtonText: 'Cancel',
    isDestructive: false,
  }

  render() {
    return (
      <BareModal onClose={this.props.onClose} className={styles.modal}>
        <div className={styles.message}>{this.props.message}</div>

        {this.props.secondaryMessage &&
          <div className={styles.secondaryMessage}>
            {this.props.secondaryMessage}
          </div>
        }

        <div className={styles.buttons}>
          <Button
            isPrimary={!this.props.isDestructive}
            isDestructive={this.props.isDestructive}
            label={this.props.confirmButtonText}
            onClick={this.confirm}
          />
          <Button
            isPlainText={true}
            label={this.props.cancelButtonText}
            onClick={this.cancel}
          />
        </div>
      </BareModal>
    )
  }

  confirm = () => {
    this.props.onConfirm()
  }

  cancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
    else if (this.props.onClose) {
      this.props.onClose()
    }
  }
}
