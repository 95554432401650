import {path} from 'ramda'

import {apiCall, graphqlQuery} from 'app/api'
import {args, gql} from 'app/graphql'
import {SERVER_SORT_OPTIONS} from 'app/search-results-page/search-results-page-constants'
import {handleError} from 'app/utils/errors'
import {Feed} from 'app/models'

import * as chartsAndTrends from './charts-and-trends/charts-and-trends-api'

export {chartsAndTrends}

// TODO: Clean all of this up. The composition here is super messy.


const feedFragment = `
  fragment feed on Feed {
    id
    name
    sourceType
    isProprietary
    isPromoted
    credentials
    isSubscriptionRequired
    editUrl
    publicationType
  }
`
const searchEssentialsFragment = `
  fragment searchEssentials on SavedSearch {
    id
    name
    category
    scope
    slug
    duration
    resultsOrder
    relevancyLevel
    adminEditUrl
    savedSearchArticlesCount
    shouldShowRelevanceFilter
    absoluteUrl
    noticeConfig {
      maxItems {
        defaultPubTypes
        allFilings
        twitter
        event
      }
      frequency
      frequencyDisplay
      sendAlone
    }
    languageFilters {
      id
      name
      exclude
    }
    validationErrorMessage
  }
`
const searchFilterGroupsFragment = `
  fragment searchFilterGroups on SavedSearch {
    filterGroups {
      id
      category: categoryId
      special: specialType
      filterField
      isFirmSourceLabelCategory
      filters {
        id
        active
        queryTerm
        displayName
        search {
          id
          name
          scope
          notes
          isFirmLibrary
          isFirmLibraryChild
          owner {
            id
            firstName
            lastName
          }
        }
        firmSourceLabel {
          id
          name
        }
      }
    }
    aboutSources {
      id
      name
      isTrusted
      publicationType
    }
    excludedFeeds {
      id
      name
    }
  }
`


const resultsQueryPart = ({id,  ...resultsArgs}) => gql`
  results(${args(resultsArgs)}) {
    publicationCounts {
      name
      count
    }
    documents {
      id
      headline
      snippet
      feed {
        ...feed
      }
      link
      isFlagged
      isNlaArticle
      publicationType
      publishedAt
      headlineHighlightLocations { start, end }
      snippetHighlightLocations { start, end }
      groupCount
      groupDocs {
        id
        headline
        feed {
          ...feed
        }
        link
      }
      insights {
        valence
        factor
        factorDisplayName
        isLitigation
        isOpinion
        isRumor
        ${() => id ? `interimUrl(savedSearchId: ${id})` : ''}
      }
      staffInfo {
        editUrl
        selectUrl
      }
    }
    fqParams
    numPages
  }
`

const resultsQuery = params => {
  const {
    id,
    feedId,
    query,
    queryType,
    name,
    appliedFilterKey,
    termFrequencyKey,
    includeFilters,
    topLevelDocumentIds,
    selectedLanguageIds,
    ...resultsArgs
  } = params
  const searchArgs = {}
  if (id) {
    searchArgs.id = id
    resultsArgs.id = id
  }
  else if (feedId) {
    searchArgs.feedId = feedId
  }
  else if (name) {
    searchArgs.name = name
  }
  searchArgs.query = query
  if (queryType) {
    searchArgs.queryType = queryType
  }
  if (appliedFilterKey) {
    searchArgs.appliedFilterKey = appliedFilterKey
  }
  if (termFrequencyKey) {
    searchArgs.termFrequencyKey = termFrequencyKey
  }
  if (topLevelDocumentIds) {
    resultsArgs.topLevelDocumentIds = topLevelDocumentIds
  }
  if (selectedLanguageIds) {
    resultsArgs.selectedLanguageIds = selectedLanguageIds.split(',').map(id => parseInt(id))
  }
  return gql`
    query {
      savedSearch(${args(searchArgs)}) {
        ...searchEssentials
        ${() => includeFilters ? '...searchFilterGroups' : ''}
        ${() => resultsQueryPart(resultsArgs)}
      }
    }
    ${() => feedFragment}
    ${() => searchEssentialsFragment}
    ${() => includeFilters ? searchFilterGroupsFragment : ''}
  `
}

const searchDataQuery = (
  {
    includeStockData = false,
    includeAvailableFilterCategories = false,
    includeQuickFilterOptions = false,
    includeFirmSourceLabels = false,
    includeHelpText = false,
    ...params
  }
) => {
  const {
    id,
    feedId,
    query,
    queryType,
    name,
    appliedFilterKey,
    termFrequencyKey,
    ...resultsArgs
  } = params
  const searchArgs = {}
  if (id) {
    searchArgs.id = id
    resultsArgs.id = id
  }
  else if (feedId) {
    searchArgs.feedId = feedId
  }
  else if (name) {
    searchArgs.name = name
  }
  searchArgs.query = query
  if (queryType) {
    searchArgs.queryType = queryType
  }
  if (appliedFilterKey) {
    searchArgs.appliedFilterKey = appliedFilterKey
  }
  if (termFrequencyKey) {
    searchArgs.termFrequencyKey = termFrequencyKey
  }
  return gql`
    query {
      savedSearch(${args(searchArgs)}) {
        ...searchEssentials
        ...searchFilterGroups
        slug
        isInsightsEnabled
        scope
        category
        query
        userFriendlyQuery
        queryType
        relevancyLevel
        groupingLevel
        solrSearchField
        isFirmLibrary
        isFirmLibraryChild
        duration
        resultsOrder
        savedSearchArticlesCount
        
        mainParent {
          id
        }
        
        parentSearches {
          id
          name
          scope
          notes
          isFirmLibrary
          isFirmLibraryChild
          owner {
            id
            firstName
            lastName
            username
          }
        }
        
        dnbData {
          familyCount
          association
          associationPlural
          moreInfoContact {
            name
            email
          }
        }
        
        noticeConfig {
          maxItems {
            defaultPubTypes
            allFilings
            twitter
            event
          }
          frequency
          frequencyDisplay
          sendAlone
        }
        
        termFrequencyFilters {
          termArray
          frequency
          targetField
        }
        
        ${() => includeStockData 
          ? `
            stockData {
              id
              symbol
              lastUpdate
              prevClose
              open
              bid
              ask
              percentChange
              oneYearTargetPrice
              averageDayVolume
              change
              yearRange
              divYield
              eps
              price_earnings
              dayRange
              lastTradePrice
              marketCap
              volume
            }
          `
          : ''
        }
      }
      
      ${() => includeAvailableFilterCategories
        ? `
          availableFilterCategories {
            id
            label
            description
            searches {
              id
              name
              scope
              isFirmLibrary
              isFirmLibraryChild
              owner {
                id
                firstName
                lastName
                username
              }
              children {
                id
                name
                scope
                owner {
                  id
                  firstName
                  lastName
                  username
                }
              }
            }
          }
        `
        : ''
      }
      
      ${() => includeQuickFilterOptions
        ? `
          quickFilterOptions {
            categories {
              id
              label
            }
            popular {
              id
              label
            }
          }
        `
        : ''
      }
      
      ${() => includeFirmSourceLabels
        ? `
          firmSourceLabels {
            id
            parentId
            name
            feeds {
              id
              name
            }
          }
        `
        : ''
      }
      
      ${() => includeHelpText
        ? `
          helpQuestions(slugs: ["sqoop-alert"]) {
            slug
            statement
          }
        `
        : ''
      }
    }
    
    ${() => feedFragment}
    ${() => searchEssentialsFragment}
    ${() => searchFilterGroupsFragment}
  `
}


export function fetchUserEmailSettings(id) {
  return graphqlQuery(
    gql`
      query {
        user(id: ${id}) {
          baseEmailSettings {
            categoryDefaults {
              category
              noticeFreq
              noticeMaxStories
              twitterMaxItems
              eventMaxItems
              filingMaxItems
            }
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}


export function fetchCachedLargerTimeFrames({searchId}) {
  return graphqlQuery(
    gql`
      query {
        data: largerTimeFramesCachedSearch(${args({searchId})}) {
          search_id
          time_frames
        }
      }
    `
  ).then(({body: {data}}) => data)
}


export function fetchSearchResults(
  {
    searchId,
    feedId,
    query,
    queryType,
    name,
    startDate,
    endDate,
    publicationTypes,
    relevancyLevel,
    groupingLevel,
    selectedLanguageIds,
    orderBy,
    insightsArticlesFirst,
    upcomingEvents,
    page,
    resultsPerPage,
    appliedFilterKey,
    termFrequencyKey,
    includeFilters,
    topLevelDocumentIds,
    excludeFirmFilter,
  } = {},
) {
  const args = {
    id: searchId,
    feedId,
    query,
    queryType,
    name,
    startDate,
    endDate,
    publicationTypes,
    relevancyLevel,
    groupingLevel,
    selectedLanguageIds,
    orderBy: SERVER_SORT_OPTIONS[orderBy],
    insightsArticlesFirst,
    upcomingEvents,
    page,
    resultsPerPage,
    appliedFilterKey,
    termFrequencyKey,
    includeFilters,
    topLevelDocumentIds,
    excludeFirmFilter,
  }
  return graphqlQuery(resultsQuery(args))
    .then(({body: {data}}) => ({search: data.savedSearch}))
}


export function fetchSearchData(
  {
    searchId,
    feedId,
    query,
    queryType,
    name,
    startDate,
    endDate,
    publicationTypes,
    relevancyLevel,
    groupingLevel,
    orderBy,
    insightsArticlesFirst,
    upcomingEvents,
    page,
    resultsPerPage,
    appliedFilterKey,
    termFrequencyKey,
    includeAvailableFilterCategories,
    includeQuickFilterOptions,
    includeFirmSourceLabels,
    includeHelpText,
  } = {},
) {
  const args = {
    id: searchId,
    feedId,
    query,
    queryType,
    name,
    startDate,
    endDate,
    publicationTypes,
    relevancyLevel,
    groupingLevel,
    orderBy: SERVER_SORT_OPTIONS[orderBy],
    insightsArticlesFirst,
    upcomingEvents,
    page,
    resultsPerPage,
    appliedFilterKey,
    termFrequencyKey,
    includeAvailableFilterCategories,
    includeQuickFilterOptions,
    includeFirmSourceLabels,
    includeHelpText,
  }
  return graphqlQuery(searchDataQuery(args))
    .then(({body: {data}}) => ({
      search: data.savedSearch,
      availableFilterCategories: data.availableFilterCategories,
      quickFilterOptions: data.quickFilterOptions,
      firmSourceLabels: data.firmSourceLabels,
      helpQuestions: data.helpQuestions,
    }))
}


export function fetchSearchQueryComponents(
  {
    id,
    feedId,
    query,
    queryType,
    appliedFilterKey,
    termFrequencyKey,
  },
) {
  const searchArgs = {
    id,
    feedId,
    query,
    queryType,
    appliedFilterKey,
    termFrequencyKey,
  }
  return graphqlQuery(
    gql`
      query {
        savedSearch(${args(searchArgs)}) {
          queryComponents {
            baseQuery
            orComponents
            andComponents
          }
        }
      }
    `
  )
    .then(response => response.body.data.savedSearch.queryComponents)
}


export function fetchSearchFullQuery(
  {
    id,
    feedId,
    query,
    queryType,
    appliedFilterKey,
    termFrequencyKey,
  },
) {
  const searchArgs = {
    id,
    feedId,
    query,
    queryType,
    appliedFilterKey,
    termFrequencyKey,
  }
  return graphqlQuery(
    gql`
      query {
        savedSearch(${args(searchArgs)}) {
          results {
            query
          }
        }
      }
    `
  )
    .then(response => response.body.data.savedSearch.results.query)
}


export async function fetchTrending({
  id,
  slug,
  query,
  queryType,
  filterKey,
  relevancyLevel,
  groupingLevel,
  publicationType,
  publicationTab,
  useCache = true,
}) {
  const data = {}
  data.query = query
  if (queryType) {
    data.queryType = queryType
  }
  if (filterKey) {
    data.fkey = filterKey
  }
  if (relevancyLevel) {
    data.relevancyLevel = relevancyLevel
  }
  if (groupingLevel) {
    data.groupingLevel = groupingLevel
  }
  if (publicationType) {
    data.facet = publicationType
  }
  else if (publicationTab) {
    data.facet = {
      [Feed.PUBLICATION_TYPE_CATEGORIES.NEWS]: 'default',
      [Feed.PUBLICATION_TYPE_CATEGORIES.FILINGS]: 'filing',
      [Feed.PUBLICATION_TYPE_CATEGORIES.EVENTS]: 'event',
      [Feed.PUBLICATION_TYPE_CATEGORIES.TWITTER]: 'twitter',
      [Feed.PUBLICATION_TYPE_CATEGORIES.MY_SOURCES]: 'sources',
    }[publicationTab]
  }
  if (!useCache) {
    data.useCache = 'False'
  }
  const url = `/trending/${slug}/${id}/`
  if(!id) {
    return {
      general: [],
      trackers: [],
      clients: [],
      companies: [],
      legal: [],
      firms: [],
    }
  }

  try {
    const response = await apiCall({
      url,
      method: 'GET',
      data,
    })
    return response.body.trends
  } catch (error) {
    if (path(['response', 'statusCode'], error) !== 404) {
      handleError(error)
    }
    return {
      general: [],
      trackers: [],
      clients: [],
      companies: [],
      legal: [],
      firms: [],
    }
  }
}


export function applyFilters({searchId, filters}) {
  const filterArgs = {searchId, filters}
  return graphqlQuery(
    gql`
      mutation {
        key: createAppliedFilter(${args(filterArgs)})
      }
    `
  )
    .then(response => response.body.data.key)
}


export function deleteDocuments(ids) {
  return graphqlQuery(
    gql`
      mutation {
        ids: deleteDocuments(${args({ids})})
      }
    `
  )
    .then(response => response.body.data.ids)
}


export function saveSearch(
  {
    id,
    userId,
    feedId,
    name,
    scope,
    category,
    query,
    queryType,
    noticeConfig,
    duration,
    resultsOrder,
    relevancyLevel,
    groupingLevel,
    appliedFilterKey,
    filters,
    termFrequencyKey,
    isFirmLibrary,
    isChangeOwner,
    isMakeCopy,
    isMakeChild,
    termFrequencyFilters,
    selectedLanguageIds,
  },
) {
  const params = {
    id,
    userId,
    feedId,
    name,
    scope,
    category,
    searchPhrase: query,
    queryType,
    noticeConfig,
    duration,
    resultsOrder,
    solrSearchField: relevancyLevel,
    groupingLevel,
    filters,
    appliedFilterKey,
    termFrequencyKey,
    isFirmLibrary,
    isChangeOwner,
    isMakeCopy,
    isMakeChild,
    termFrequencyFilters,
  }

  if (selectedLanguageIds){
    params.selectedLanguageIds = selectedLanguageIds.split(',').map(id => parseInt(id))
  }

  return graphqlQuery(
    gql`
      mutation {
        search: saveSearch(${args(params)}) {
          ...searchEssentials
        }
      }
      ${() => searchEssentialsFragment}
    `
  )
    .then(response => response.body.data)
}


export function addFeedToTrustedSources(feedId) {
  return graphqlQuery(
    gql`
      mutation {
        search: createTrustedSource(${args({feedId})}) {
          ...searchEssentials
        }
      }
      ${() => searchEssentialsFragment}
    `
  )
    .then(response => response.body.data.search)
}


export function removeFeedFromTrustedSources(feedId) {
  let trustedSourceId = feedId
  if (!Array.isArray(trustedSourceId)) {
    trustedSourceId = [trustedSourceId]
  }
  trustedSourceId = trustedSourceId.flat()
    return graphqlQuery(
    gql`
      mutation {
        feed: deleteTrustedSource(${args({trustedSourceId})}) {
          feedId: id
        }
      }
    `
  )
    .then(response => response.body.data.feed)
}


export function deleteSearch(id) {
  return graphqlQuery(
    gql`
      mutation {
        deleteSearch(${args({id})})
      }
    `
  )
    .then(response => response.body.data.feed)
}


// TODO: Remove this in favor of `saveSearch` above?
export function updateSearch({
  id,
  feedId,
  userId,
  isMakeChild,
  isMakeCopy,
  name,
  isFirmLibrary,
  scope,
  category,
  queryType,
  searchPhrase,
  filters,
  noticeConfig,
  solrSearchField,
  duration,
  resultsOrder,
  appliedFilterKey,
  termFrequencyKey,
  termFrequencyFilters,
  selectedLanguageIds,
  groupingLevel,
}) {
  const allowDuplicates = isMakeChild || isMakeCopy
  return graphqlQuery(
    gql`
      mutation {
        search: saveSearch(${args({
          id,
          feedId,
          userId,
          isMakeChild,
          isMakeCopy,
          allowDuplicates,
          name,
          isFirmLibrary,
          scope,
          category,
          queryType,
          searchPhrase,
          filters,
          noticeConfig,
          solrSearchField,
          groupingLevel,
          duration,
          resultsOrder,
          appliedFilterKey,
          termFrequencyKey,
          termFrequencyFilters,
          selectedLanguageIds,
        })}) {
          ...searchEssentials
          ...searchFilterGroups
          slug
          isInsightsEnabled
          scope
          category
          query
          userFriendlyQuery
          queryType
          relevancyLevel
          groupingLevel
          solrSearchField
          isFirmLibrary
          isFirmLibraryChild
          duration
          resultsOrder
          termFrequencyFilters {
            termArray
            frequency
            targetField
          }
        }
      }
      ${() => searchEssentialsFragment}
      ${() => searchFilterGroupsFragment}
    `
  ).then(({body: {data}}) => data)
}


export function fetchSearchExcludedFeeds(id) {
  return graphqlQuery(
    gql`
      query {
        savedSearch(${args({id})}) {
          id
          excludedFeeds {
            id
            name
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchUsers({nameFilter} = {}) {
  return graphqlQuery(
    gql`
      query {
        users: usersAndGroups(${args({isActive: true, name: nameFilter})}) {
          id
          firstName
          lastName
          username
          role
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchFirmLibrarySearches() {
  return graphqlQuery(
    gql`
      query {
        savedSearches(isFirmLibrary: true) {
          id
          name
          category
          notes
          owner {
            id
            firstName
            lastName
            username
            role
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchEsgData(searchId) {
  return graphqlQuery(
    gql`
      query {
        esgData(${args({searchId})}) {
          pillars
          companyScores {
            companyName
            industryName
            healthScore
            industryHealthScore
            companyPillarScores {
              topicId
              pillar
              score
            }
            industryPillarScores {
              topicId
              pillar
              score
            }
            percentileRank
            percentileText
          }
          industryChartData {
            initMaxRange
            segmentData {
              count
              maxRange
              segmentIndex
              segmentKey
              xAxis
            }
            segmentKeys
          }
          companyPillarStories {
            topicId
            headline
            storyId
            description
            url
            storyDate
            document {
              id
              isFlagged
              publishedAt
              insights {
                valence
                valenceDisplayName
                factor
                factorDisplayName
                subfactor
                subfactorDisplayName
                isLitigation
                isOpinion
                isRumor
                ${() => searchId ? `interimUrl(savedSearchId: ${searchId})` : ''}
              }
              headline
              link
              snippet
              feed {
                id
                name
                sourceType
                url
                isPromoted
                isProprietary
                credentials
                isSubscriptionRequired
                editUrl
              }
            }
          }
          industryPillarStories {
            topicId
            headline
            storyId
            description
            url
            storyDate
            document {
              id
              isFlagged
              publishedAt
              insights {
                valence
                valenceDisplayName
                factor
                factorDisplayName
                subfactor
                subfactorDisplayName
                isLitigation
                isOpinion
                isRumor
                ${() => searchId ? `interimUrl(savedSearchId: ${searchId})` : ''}
              }
              headline
              link
              snippet
              feed {
                id
                name
                sourceType
                url
                isPromoted
                isProprietary
                credentials
                isSubscriptionRequired
                editUrl
              }
            }
          }
          sidebarContent {
            title
            content
            link
            linkText
          }
          staticSidebarContent {
            title
            content
            link
            linkText
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}
