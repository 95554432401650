import deepCopy from 'deepcopy'
import invariant from 'invariant'
import * as R from 'ramda'
import {combineActions, handleActions} from 'redux-actions'

import * as actions from 'app/actions'
import Orm from 'app/framework/Orm'
import * as models from 'app/models'

import * as entitiesActions from './entities-actions'

// Auto-generate empty state for each model
const getInitialState = () => Object.values(models).reduce((state, Model) => {
  state[Model.entityKey] = {}
  if (Model.indexes) {
    state.indexes[Model.entityKey] = {}
    Model.indexes.forEach(indexedField => {
      if (Model.fields[indexedField].__foreignKey) {
        indexedField += 'Id'
      }
      state.indexes[Model.entityKey][indexedField] = {}
    })
  }
  return state
}, {indexes: {}})

const getModelByEntityKey = entityKey => {
  const Model =
    Object.values(models).find(Model => Model.entityKey === entityKey)
  invariant(
    Model,
    `The Redux reducer is trying to find a model with entityKey '${entityKey}' but such a model class does not exist. Check the keys of the 'entities' object in Redux.`
  )
  return Model
}

// Returns a function that just calls `updateEntities` on the state using the
// `entitiesGetter` to get the entities from the action payload.
const entitiesUpdater = entitiesGetter => (state, action) => {
  const entities = entitiesGetter(action.payload)
  let entityKeys = []
  if (entities) {
    entityKeys = Object.keys(entities)
  }
  const newState = copyEntities(entityKeys, state)
  const orm = Orm.withEntities(newState)
  entityKeys.forEach(key => {
    orm.updateByIds(getModelByEntityKey(key), entities[key])
  })
  return newState
}

const copyEntities = (entityKeys, state) => {
  const newState = {...state, indexes: deepCopy(state.indexes)}
  entityKeys.forEach(entityKey => {
    newState[entityKey] = deepCopy(newState[entityKey])
  })
  return newState
}

const copyEntitiesForModels = (models, state) => {
  return copyEntities(models.map(R.prop('entityKey')), state)
}

// ORM helper functions
// These are just here to reduce boilerplate.

const getOrmUpdater = (Model, methodName, ...argFuncs) => (state, action) => {
  const newState = copyEntitiesForModels([Model], state)
  const orm = Orm.withEntities(newState)
  orm[methodName](Model, ...argFuncs.map(func => func(action.payload)))
  return newState
}
const updateById = (Model, idFunc, updatesFunc) =>
  getOrmUpdater(Model, 'updateById', idFunc, updatesFunc)
const deleteById = (Model, idFunc) => getOrmUpdater(Model, 'deleteById', idFunc)
const deleteByIds = (Model, idsFunc) =>
  getOrmUpdater(Model, 'deleteByIds', idsFunc)
const updateMany = (Model, updatesFunc) =>
  getOrmUpdater(Model, 'updateMany', updatesFunc)
const deleteMany = (Model, idsFunc) =>
  getOrmUpdater(Model, 'deleteMany', idsFunc)


const updaterFunction = ormFunc => (entities, action) => {
  entities = {...entities, indexes: deepCopy(entities.indexes)}
  const orm = Orm.withEntities(entities)
  Object.entries(action.payload).forEach(([entityKey, arg]) => {
    entities[entityKey] = deepCopy(entities[entityKey])
    ormFunc(orm, getModelByEntityKey(entityKey), arg)
  })
  return entities
}


export default handleActions({
  // Entity actions

  [entitiesActions.update]: updaterFunction((orm, Model, changesById) => {
    orm.updateByIds(Model, changesById)
  }),
  [entitiesActions.replace]: updaterFunction((orm, Model, objectsById) => {
    orm.replaceByIds(Model, objectsById)
  }),
  [entitiesActions.remove]: updaterFunction((orm, Model, ids) => {
    orm.deleteByIds(Model, ids)
  }),

  // Init

  [combineActions(
    actions.addData,
    actions.replaceData,
  )]: entitiesUpdater(R.prop('entities')),

  // Users

  [actions.addAvailableUser]: updateById(models.User, R.prop('id'), R.identity),

  // Profile searches

  [actions.removeProfileSearches]: deleteMany(models.SavedSearch, R.identity),

  // Feeds

  [actions.updateFeed]: updateById(models.Feed, R.prop('id'), R.identity),
}, getInitialState())
