
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './styles.less'

const DebouncedSearchInput = ({
    value: initialValue,
    onChange,
    debounce = 500,
    inputClassName,
    containerClassName,
    ...props
}) => {
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <div className={classNames('debounce-input-wrapper', containerClassName)}>
            <div className='debounce-input-icon' />
            <input
                className={classNames(inputClassName, 'debounce-input')}
                style={{ border: 'none', padding: '0px' }}
                {...props} value={value}
                onChange={e => setValue(e.target.value)} />
        </div>
    )
}

DebouncedSearchInput.propTypes = {
    value: PropTypes.string.isRequired,
    inputClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    onChange: PropTypes.func,
    debounce: PropTypes.number,
}

export default DebouncedSearchInput