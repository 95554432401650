import classNames from 'classnames'
import PropTypes from 'prop-types'
import {filter, fromPairs, pipe, toPairs} from 'ramda'
import React, {useCallback, useState} from 'react'
import {anchor, ToggleLayer} from 'react-laag'
import {titleCase} from 'title-case'

import Button from 'app/common/Button'
import InlineSvg from 'app/common/InlineSvg'
import {useResizeObserver} from 'app/utils/hooks'

import styles from './SplitButtonDropdown.less'


function borderStyleFromLayerSide(layerSide) {
  const oppositeSide = {
    top: 'bottom',
    bottom: 'top',
    left: 'right',
    right: 'left',
  }[layerSide]
  if (oppositeSide) {
    return {[`border${titleCase(oppositeSide)}`]: 'none'}
  }
  return {}
}


export default function SplitButtonDropdown({
  label,
  options,
  buttonDisabled = false,
  dropdownDisabled = false,
  onChange,
  mainButtonClassName,
  menuButtonClassName,
  className,
  ...restProps
}) {
  const [isOpen, setIsOpen] = useState(false)
  const onMenuButtonClick = useCallback(
    event => {
      // Prevent default in case this is inside a <form>.
      event.preventDefault()
      setIsOpen(!isOpen)
    },
    [isOpen],
  )
  const ResizeObserver = useResizeObserver()

  const mainButton = (
    <Button
      label={label}
      disabled={buttonDisabled}
      className={classNames(styles.mainButton, mainButtonClassName)}
      {...restProps}
    />
  )
  if (!ResizeObserver) {
    // While we're loading the polyfill, only show the main button.
    return mainButton
  }

  // The rest of the props that are applicable to the menu button.
  const menuButtonRestProps = pipe(
    toPairs,
    filter(([key, value]) => Object.keys(Button.propTypes).includes(key)),
    fromPairs,
  )(restProps)
  return (
    <ToggleLayer
      placement={{
        anchor: anchor.BOTTOM_CENTER,
        possibleAnchors: [
          anchor.BOTTOM_CENTER,
          anchor.BOTTOM_LEFT,
          anchor.BOTTOM_RIGHT,
          anchor.TOP_CENTER,
          anchor.TOP_LEFT,
          anchor.TOP_RIGHT,
        ],
        autoAdjust: true,
      }}
      fixed
      isOpen={isOpen}
      onOutsideClick={() => setIsOpen(false)}
      renderLayer={({layerProps, isOpen, triggerRect, layerSide}) =>
        isOpen && (
          <div
            ref={layerProps.ref}
            style={{
              ...layerProps.style,
              width: triggerRect.width,
              ...borderStyleFromLayerSide(layerSide),
            }}
            className={classNames(styles.dropdownMenu)}
          >
            {options.map(option => (
              <div
                onClick={() => {
                  onChange(option.value)
                  setIsOpen(false)
                }}
                className={styles.option}
                key={option.value}
              >
                {option.label}
              </div>
            ))}
          </div>
        )
      }
      ResizeObserver={ResizeObserver}
    >
      {({triggerRef}) => (
        <div
          ref={triggerRef}
          className={classNames(
            styles.splitButtonDropdown,
            className,
            {[styles.open]: isOpen},
          )}
        >
          {mainButton}
          <Button
            onClick={dropdownDisabled ? undefined : onMenuButtonClick}
            label={
              <div className={styles.arrow}>
                <InlineSvg src="/media/img/dropdown-arrow.svg" />
              </div>
            }
            isNarrow={true}
            disabled={dropdownDisabled}
            className={classNames(styles.menuButton, menuButtonClassName)}
            {...menuButtonRestProps}
          />
        </div>
      )}
    </ToggleLayer>
  )
}
SplitButtonDropdown.propTypes = {
  label: Button.propTypes.label,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
}
