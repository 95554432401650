import React from 'react'
import {useSelector} from 'react-redux'
import LoadingOverlay from 'app/common/LoadingOverlay'

import {ContentBoosters} from './blocks/boosters'
import {DashboardOrder} from './blocks/dashboard-order'
import {DefaultGrouping} from './blocks/grouping'
import {LanguagePreferences} from './blocks/languages'
import {QuickFilters} from './blocks/quick-filters'
import {DefaultRelevance} from './blocks/relevance'
import {SearchResultsOrder} from './blocks/search-results-order'
import {SearchResultsNumber} from './blocks/search-results-number'
import {SubscriptionContent} from './blocks/subscriptions'
import {DefaultTimeFrame} from './blocks/time-frame'
import {DefaultTimeFrameMobile} from './blocks/time-frame-mobile'
import PreferencesBlock from './PreferencesBlock'
import * as selectors from './profile-preferences-selectors'

import styles from './ProfilePreferences.less'


export default function ProfilePreferences() {
  const isLoading = useSelector(selectors.getIsLoading)
  const shouldShowQuickFilters = useSelector(selectors.shouldShowQuickFilters)
  return (
    <div className={styles.profilePreferences}>
      {isLoading && <LoadingOverlay/>}

      <div className={styles.column}>
        <PreferencesBlock
          title="Language Preferences"
          description="Specify the languages in which you would like to receive content."
        >
          <LanguagePreferences />
        </PreferencesBlock>

        <PreferencesBlock
          title="Content Boosters"
          description="Articles from selected boosters are elevated within your results."
        >
          <ContentBoosters />
        </PreferencesBlock>

        <PreferencesBlock
          title="Dashboard Display Order"
          description="Specify the order in which you would like to see your Saved Searches on the Dashboard."
        >
          <DashboardOrder />
        </PreferencesBlock>

      </div>

      <div className={styles.column}>
        <PreferencesBlock
          title="Subscription Content"
          description="Controls display of content sources requiring a separate subscription."
        >
          <SubscriptionContent />
        </PreferencesBlock>

        <PreferencesBlock
          title="Default Search Time Frame"
          description="Controls the timeframe of search."
        >
          <DefaultTimeFrame />
        </PreferencesBlock>

        <PreferencesBlock
          title="Default Mobile Search Time Frame"
          description="Controls the timeframe of search on the mobile app."
        >
          <DefaultTimeFrameMobile />
        </PreferencesBlock>

        <PreferencesBlock
          title="Default Search Results Order"
          description="Controls the default order of search results."
        >
          <SearchResultsOrder />
        </PreferencesBlock>

        <PreferencesBlock
          title="Default Number of Search Results"
          description="Controls the default number of search results."
        >
          <SearchResultsNumber />
        </PreferencesBlock>

        <PreferencesBlock
          title="Default Search Relevance Filter"
          description={'Controls the default relevance filter for searches when applicable. Click the "Apply to All" button to apply the selected relevance to all of your previously saved searches.'}
        >
          <DefaultRelevance />
        </PreferencesBlock>

        <PreferencesBlock
          title="Default Search Results Grouping"
          description={'Controls the default search results grouping level. Click the "Apply to All" button to apply the selected grouping level to all of your previously saved searches.'}
        >
          <DefaultGrouping />
        </PreferencesBlock>

        {shouldShowQuickFilters && (
          <PreferencesBlock
            title="Quick Filters"
            description={'Selections will be added to the "Quick Filters" menu on your results pages.'}
          >
            <QuickFilters />
          </PreferencesBlock>
        )}
      </div>
    </div>
  )
}
