import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import classNames from 'classnames'
import Modal from 'app/common/Modal'
import Button from 'app/common/Button'
import TextBox from 'app/common/TextBox'
import Table from 'app/common/Table'
import Dropdown from 'app/common/Dropdown'
import InputBlock from 'app/common/InputBlock'
import {
  showSearchesManageLabelsModal,
  setSearchesManageLabelsFilters,
  setSearchesNewLabelData,
  createSearchesLabel,
  setSearchesManageLabelsModalData,
  saveSearchesLabel,
  deleteSearchesLabel
} from '../searches-admin-actions'
import { getSearchesAdmin } from '../searches-admin-selectors'
import * as styles from './ManageLabelsModal.less'
import { DATE_FORMAT } from 'app/constants'
import * as dateFns from 'date-fns'
import PaginatedTable from 'app/common/PaginatedTable'
import { PAGINATION_OPTIONS } from '../searches-admin-constants'
import snakeCase from 'snake-case'
import { MANAGE_LABELS_SECTION, MANAGE_LABELS_ACTIONS } from '../searches-admin-constants'

@connect(
  createSelector(
    [getSearchesAdmin],
    (searchesAdmin) => {
      return {
        shouldShowManageLabelsModal: searchesAdmin.shouldShowManageLabelsModal,
        newLabelData: searchesAdmin.newLabelData,
        manageLabelsFilter: searchesAdmin.manageLabelsFilter,
        allParentLabels: searchesAdmin.allParentLabels,
        labels: searchesAdmin.labels,
        manageLabelsModalData: searchesAdmin.manageLabelsModalData,
        isLoading: searchesAdmin.isLoading,
        manageLabelsTotalCount: searchesAdmin.manageLabelsTotalCount,
      }
    }
  ),
  {
    showManageLabelsModal: showSearchesManageLabelsModal,
    setSearchesManageLabelsFilters,
    setSearchesNewLabelData,
    createSearchesLabel,
    setSearchesManageLabelsModalData,
    saveSearchesLabel,
    deleteSearchesLabel
  },
)
export default class ManageLabelsModal extends Component {
  static propTypes = {
    onFilterStateChange: PropTypes.func,
  }
  state = {
    manageLabelsFilters: {
      currentPage: 1,
      itemsPerPage: 10,
      searchLabel: '',
    },
    expandItems: [],
  }

  render() {
    const pageCount = Math.ceil(this.props.manageLabelsTotalCount / this.props.manageLabelsFilter.itemsPerPage)
    const paginationOptions = Object.entries(PAGINATION_OPTIONS).map(
      item => {
        const [value, label] = item
        return {
          label,
          value
        }
      }
    )
    const edit_delete_actions = (label) => {
      return (
        <div className={classNames(styles.actionButtons)}>
          <span className="actions">
            <a className="action edit" onClick={() => this.handleEditButtonClick(label)}>Edit</a>
            <a className="action remove" onClick={() => this.handleDeleteButtonClick(label)}>Delete</a>
          </span>
        </div>)
    }
    const save_cancel_buttons = <React.Fragment>
      <Button label={MANAGE_LABELS_ACTIONS.SAVE} disabled={this.props.manageLabelsModalData.name && this.props.manageLabelsModalData.name.length >= 100 ? true : false} onClick={() => this.handleSaveButtonClick()} />
      <Button label={MANAGE_LABELS_ACTIONS.CANCEL} isPlainText={true} onClick={() => this.handleCancelOperationButtonClick()} />
    </React.Fragment>
    const delete_cancel_buttons = <React.Fragment>
      <Button label={MANAGE_LABELS_ACTIONS.DELETE} isDestructive={true} onClick={() => this.handleConfirmDeleteButtonClick()} />
      <Button label={MANAGE_LABELS_ACTIONS.CANCEL} isPlainText={true} onClick={() => this.handleCancelOperationButtonClick()} />
    </React.Fragment>

    return (
      <Modal
        id="manage-labels-modal"
        isOpen={this.props.shouldShowManageLabelsModal}
        onClose={() => this.props.showManageLabelsModal(false)}>
        <h1 className="feed-name">{MANAGE_LABELS_SECTION.TITLE}</h1>
        <div className={styles.newLabel}>
          <InputBlock label="New Label Name">
            <TextBox
              value={this.props.newLabelData.name}
              onChange={event => this.handleNewLabelNameChange(event.target.value)}
              placeholder="Enter Label Name..."
            />
          </InputBlock>
          <InputBlock label="Nest New Label Under">
            <Dropdown
              options={this.getLabelDropdownOptions()}
              value={this.props.newLabelData.parentId || null}
              onChange={parentId => { this.handleNewLabelParentChange(parentId) }}
              showFilter={true}
              appTypelabel="ManageLabels"
            />
          </InputBlock>
          <Button
            label={MANAGE_LABELS_ACTIONS.CREATE}
            disabled={this.props.newLabelData.name && this.props.newLabelData.name.trim().length > 0 && this.props.newLabelData.name.trim().length < 100 ? false : true}
            onClick={() => this.handleCreateNewLabelButtonClick()}
          />
        </div>
        {
          this.props.newLabelData.name && this.props.newLabelData.name.trim().length === 0 ? <div className={styles.emptyLabelMessage}>{MANAGE_LABELS_SECTION.EMPTY_LABEL_MESSAGE}</div> : ''
        }
        {
          this.props.newLabelData.name && this.props.newLabelData.name.trim().length > 99 ? <div className={styles.emptyLabelMessage}>{MANAGE_LABELS_SECTION.EXCEEDS_CHARACTER_LIMIT}</div> : ''
        }
        <div className={classNames(styles.newLabel, styles.filterLabel)}>
          <InputBlock label="Filter by Label">
            <TextBox
              onChange={evt => this.handleFilterChange('searchLabel', evt.target.value)}
            />
          </InputBlock>
          <div className="page-size" >
            <span>{MANAGE_LABELS_ACTIONS.SHOW}</span>
            <Dropdown
              className="page-size-drop-down"
              type={'text'}
              value={PAGINATION_OPTIONS[this.props.manageLabelsFilter.itemsPerPage]}
              options={paginationOptions}
              onChange={(size) => this.handleOnPageSizeChange(size)}

            />
          </div>
        </div>

        <PaginatedTable
          id="manage-labels-table"
          defaultSort={{ column: 'name', direction: 'asc' }}
          data={this.sortedLabels() || []}
          pageCount={pageCount}
          tab="Searches"
          currentPage={this.props.manageLabelsFilter.currentPage}
          onPageChange={(page) => this.onPageChange(page)}
          onSortChange={(sortOptions) => this.onSortChange(sortOptions)}
          getRowStyle={(label) => this.borderStyle(label)} >

          <Table.Column
            name='cell'
            baseWidth="5%"
            cellContents={label => {
              return (
                <div className={classNames({ [styles.arrowRight]: !label.parent && !label.isExpand && label.childLabels && label.childLabels.length > 0, [styles.arrowDown]: !label.parent && label.isExpand })} onClick={() => this.handleColumnExpand(label)}></div>
              )
            }} />

          <Table.Column
            name="name"
            label="Name"
            baseWidth="41%"
            isSortable
            cellContents={label => {
              if (this.props.manageLabelsModalData.id === label.id && this.props.manageLabelsModalData.operation === 'edit') {
                return (
                  <React.Fragment>
                    <InputBlock label="Name">
                      <TextBox
                        value={this.props.manageLabelsModalData.name || label.name}
                        onChange={event => { this.props.setSearchesManageLabelsModalData({ name: event.target.value }) }}
                      />
                    </InputBlock>
                    {label.parent ? <InputBlock label="Nest Label Under">
                      <Dropdown
                        options={this.getLabelDropdownOptions(this.props.manageLabelsModalData.id)}
                        value={this.props.manageLabelsModalData.parentId || null}
                        onChange={parentId => { this.props.setSearchesManageLabelsModalData({ parentId }) }}
                        showFilter={true}
                        appTypelabel="ManageLabels"
                      />
                    </InputBlock> : ''}
                    {
                        this.props.manageLabelsModalData.name && this.props.manageLabelsModalData.name.length >= 100 ? <div className={styles.emptyLabelMessage}>{MANAGE_LABELS_SECTION.EXCEEDS_CHARACTER_LIMIT}</div> : ''
                    }
                  </React.Fragment>
                )
              } else {
                return <div className={classNames({ [styles.childLabelName]: label.parent })}>{label.name}</div>
              }
            }} />

          <Table.Column
            name="createdOn"
            label="Date Created"
            baseWidth="24%"
            isSortable
            cellContents={label => {
              return (
                <div>{dateFns.format(new Date(label.createdOn), DATE_FORMAT)}</div>
              )
            }} />

          <Table.Column
            name="actions"
            label="Actions"
            baseWidth="30%"
            cellContents={label => {
              return (
                <div>
                  {
                    this.props.manageLabelsModalData.id === label.id ?
                      this.props.manageLabelsModalData.operation === 'edit' ?
                        <div className={classNames(styles.actionButtons, styles.actionButtonsSpaceStyle, { [styles.alwaysShow]: this.props.manageLabelsModalData.id === label.id })}>
                          {save_cancel_buttons} </div> : <div className={classNames(styles.actionButtons, styles.actionButtonsSpaceStyle, { [styles.alwaysShow]: this.props.manageLabelsModalData.id === label.id })}>
                          {delete_cancel_buttons} </div> : <div>{edit_delete_actions(label)}</div>
                  }
                </div>
              )
            }}
          />
        </PaginatedTable>
        {!this.props.isLoading && this.sortedLabels().length === 0 ? <div className={styles.noData}>{MANAGE_LABELS_SECTION.NO_DATA}</div> : ''}
      </Modal>
    )
  }

  onSortChange(sortOptions) {
    const field = snakeCase(sortOptions.column)
    this.props.setSearchesManageLabelsFilters({ sortField: field, sortDirection: sortOptions.direction })
  }

  borderStyle(label) {
    if (!label.parent && label.isExpand) {
      return 'borderBottomStyle'
    } else if (label.parent && !label.isLastChild) {
      return 'borderBottomStyle'
    } else {
      return ''
    }
  }

  onPageChange(page) {
    const manageLabelsFilters = { ...this.state.manageLabelsFilters, currentPage: page }
    this.setState({ expandItems: [] }, () => { this.props.setSearchesManageLabelsFilters(manageLabelsFilters) })
  }

  handleFilterChange = (key,value) => {
    const manageLabelsFilters = { ...this.state.manageLabelsFilters, currentPage: 1, [key]: value }
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(function () {
      if (value.length >= 1) {
        this.setState({ manageLabelsFilters,expandItems: []})
      } else if (value.length === 0) {
        this.setState({ manageLabelsFilters,expandItems: [] })
      }
      this.props.setSearchesManageLabelsFilters(manageLabelsFilters)
    }.bind(this), 400);
  }

  handleOnPageSizeChange = (size) => {
    const count = Number(size)
    const manageLabelsFilters = { ...this.state.manageLabelsFilters, currentPage: 1, itemsPerPage: count }
    this.setState({ expandItems: [] }, () => { this.props.setSearchesManageLabelsFilters(manageLabelsFilters) })
  }

  handleColumnExpand(label) {
    const expandState = this.state.expandItems.includes(label.id)
    if (expandState) {
      const collapseState = this.state.expandItems.filter(item => item !== label.id)
      this.setState({ expandItems: [...collapseState] })
    } else if (!expandState && label.childLabels.length !== 0) {
      this.setState({ expandItems: [...this.state.expandItems, label.id] })
    }
  }


  sortedLabels(id = null) {
    const updatedLabels = []
    if (this.props.labels === null) {
      return updatedLabels
    }
    this.props.labels.forEach(label => {
      if (this.state.expandItems.includes(label.id)) {
        updatedLabels.push({ ...label, isExpand: true })
        var lastChild = label.childLabels.length - 1
        label.childLabels.forEach((childLabel, index) => {
          if (lastChild === index) {
            updatedLabels.push({ ...childLabel, isLastChild: true })
          } else {
            updatedLabels.push({ ...childLabel, isLastChild: false })
          }
        })
      } else {
        updatedLabels.push({ ...label, isExpand: false })
      }
    })
    return updatedLabels
  }

  getLabelDropdownOptions(id = null) {
    // exclude the label for the passed id and all child labels.
    const labelDropdownOptions = [{ label: 'Select a parent label', value: null }]
    if (this.props.allParentLabels) {
      labelDropdownOptions.push(
        ...this.props.allParentLabels.map(l => ({ label: l.name, value: l.id }))
      )
    }
    return labelDropdownOptions
  }

  hasChildChildren(id) {
    return this.props.labels.some(label => label.parentId === id)
  }

  handleNewLabelNameChange(name) {
    this.props.setSearchesNewLabelData({ name })
  }

  handleNewLabelParentChange(parentId) {
    this.props.setSearchesNewLabelData({ parentId })
  }

  handleCreateNewLabelButtonClick() {
    const { name, parentId } = this.props.newLabelData
    this.props.createSearchesLabel({ name, parentId })
  }

  handleEditButtonClick(label) {
    this.props.setSearchesManageLabelsModalData({ id: label.id, name: label.name, parentId: label.parent, operation: 'edit' })
  }

  handleDeleteButtonClick(label) {
    this.props.setSearchesManageLabelsModalData({ id: label.id, name: label.name, parentId: label.parentId, operation: 'delete' })
  }

  handleCancelOperationButtonClick() {
    this.props.setSearchesManageLabelsModalData({ id: '', name: '', parentId: '', operation: '' })
  }

  handleSaveButtonClick() {
    const { id, name, parentId } = this.props.manageLabelsModalData
    this.setState({ expandItems: [] }, () => { this.props.saveSearchesLabel({ id, name, parentId }) })
  }

  handleConfirmDeleteButtonClick() {
    this.setState({ expandItems: [] }, () => { this.props.deleteSearchesLabel(this.props.manageLabelsModalData.id) })
  }
}