import React from 'react';
import classNames from 'classnames'
import LoadingSpinner from "../../common/LoadingSpinner";

import * as styles from './LoadingSegment.less';

export default function LoadingSegment({className}) {

  return (
    <>
      <div className={classNames(styles.loadingSegmentContainer, className)}>
        <LoadingSpinner/>
      </div>
    </>
  )
}
