import React, { Component } from 'react'
import { connect } from 'react-redux'
import Orm from 'app/framework/Orm'
import Button from 'app/common/Button'
import Dropdown from 'app/common/Dropdown'
import InputBlock from 'app/common/InputBlock'
import AlertMaxItemsDropdown from 'app/reusable/AlertMaxItemsDropdown'
import {getSourcesAdmin} from './sources-admin-selectors'
import {setSourceModalData} from "app/firm-admin/sources/sources-admin-actions";

@connect(
  // Map state to props
  state => {
    const orm = Orm.withEntities(state.entities)
    const searchesAdmin = getSourcesAdmin(state)

    return {
      assignModalData: searchesAdmin.assignModalData,
      noticeFrequencies: searchesAdmin.noticeFrequencies,
      name:searchesAdmin
    }
  },{
    setSourceModalData
  }
)
export default class SourceSearch extends Component {
  state = {
    emailSettingType: '',
  }

  render() {
    const { assignModalData, noticeFrequencies} = this.props
    const description = <p>This will create a search that always refers back to the original and any updates to the
      original search
      will automatically update the linked search. *If you've selected any Sources, they will be
      added to the selected profiles.</p>
    const header = <h1>
          Add User Group or Members Profiles for Email Settings
          </h1>

    const frequencyOptions = noticeFrequencies.map(
        f => {return {value: f[0], label: f[1]}}
      ).filter(f => f.value !== 'priority_trigger')

     return(

      <div id="share-searches-content">
        <div>{header}</div>
        <div>{description}</div>
        <h2>Step 2: Email Settings</h2>

        <div className="new-search-notification-content">
          <InputBlock isInline label="Set Email Settings based upon the User Defaults" className="radio">
            <input
              type="radio"
              name="email_setting_type"
              value="default"
              defaultChecked={this.state.emailSettingType === 'default'}
              onClick={evt => this.setEmailSettingType(evt)}
            />
          </InputBlock>

          <InputBlock isInline label="Choose Email Notification Settings" className="radio">
            <input
              type="radio"
              name="email_setting_type"
              value="custom"
              defaultChecked={this.state.emailSettingType === 'custom'}
              onClick={evt => this.setEmailSettingType(evt)}
            />
          </InputBlock>
          {
            this.state.emailSettingType === 'custom' ?
              <div className="new-search-notification-options">
                <InputBlock label="Alert Frequency">
                  <Dropdown
                    options={frequencyOptions}
                    value={assignModalData.noticeConfig?.frequency || frequencyOptions[0].value}
                    onChange={value => this.handleFrequencyChange(value)}
                  />
                </InputBlock>

                <InputBlock label="Articles">
                  <AlertMaxItemsDropdown
                    value={this.defaultPubTypeMaxItemsHandler()}
                    onChange={(value) => this.alertMaxItemsChangeHandler('defaultPubTypes', value)}
                  />
                </InputBlock>

                <InputBlock label="Filings">
                  <AlertMaxItemsDropdown
                    value={assignModalData.noticeConfig.maxItems.allFilings || 0}
                    onChange={(value) => this.alertMaxItemsChangeHandler('allFilings', value)}
                  />
                </InputBlock>

                <InputBlock label="Tweets">
                  <AlertMaxItemsDropdown
                    value={assignModalData.noticeConfig.maxItems.twitter || 0}
                    onChange={(value) => this.alertMaxItemsChangeHandler('twitter', value)}
                  />
                </InputBlock>

                <InputBlock label="Events">
                  <AlertMaxItemsDropdown
                    value={assignModalData.noticeConfig.maxItems.event || 0}
                    onChange={(value) => this.alertMaxItemsChangeHandler('event', value)}
                  />
                </InputBlock>
                </div>
              : null
          }
        </div>
        <div className="share-search-buttons-container-sources">
          <div className="share-search-buttons-sources">
            <div className="buttons left">
              <Button
                label="Back to Previous Step"
                onClick={() => {this.props.back_screen1()}}
              />
            </div>

            <div className="buttons right save-btn">
              <Button
                label={'Save'}
                onClick={() =>{ this.props.submit_setting()}}
                disabled={this.state.emailSettingType === ''}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  defaultPubTypeMaxItemsHandler() {
    if (this.props.assignModalData.noticeConfig.maxItems?.defaultPubTypes) {
      return this.props.assignModalData.noticeConfig.maxItems.defaultPubTypes
    } else {
      this.alertMaxItemsChangeHandler('defaultPubTypes', 5)
      return (5)
    }
  }
  alertMaxItemsChangeHandler(type, value) {

    const noticeConfig = {
      ...this.props.assignModalData.noticeConfig,
      maxItems: {
        ...this.props.assignModalData.noticeConfig.maxItems,
        [type]: value,
      }
    }
    this.props.setSourceModalData({noticeConfig})
  }
  setEmailSettingType(evt) {
    const noticeConfig = {
      frequency: '',
      maxItems: {},
    }
    if (evt.target.checked) {
      this.setState({emailSettingType: evt.target.value})
    }
    this.props.setSourceModalData({noticeConfig})
  }

  handleFrequencyChange(value) {
    const noticeConfig = {...this.props.assignModalData.noticeConfig}
    noticeConfig.frequency = value
    this.props.setSourceModalData({noticeConfig})
  }
}

