import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from 'app/common/Button'
import './TermFrequencyFilter.less'
import TokenInput from "app/common/TokenInput"
import {createSelector} from "reselect"
import {getAdvancedSearch} from "app/advanced-search/advanced-search-selectors"
import { changeTermFrequencyArray, setTermFrequencyFrequency,
  submitTermFrequencyFilter, initializeTermFrequencyFilter,
  setTermFrequencyTargetField, removePendingTermFrequencyFilter} from
    'app/advanced-search/advanced-search-actions'
import Dropdown from "app/common/Dropdown"
import InputBlock from 'app/common/InputBlock'
import TextBox from "app/common/TextBox"
import {TERM_FREQUENCY_TARGET_FIELDS, TERM_FREQUENCY_TARGET_FIELD_LABELS} from
    "app/advanced-search/advanced-search-constants"


@connect(
  createSelector(
    [getAdvancedSearch],
    (advancedSearch) => {
      return {
        termArray: advancedSearch.termFrequency.termArray,
        frequency: advancedSearch.termFrequency.frequency,
        targetField: advancedSearch.termFrequency.targetField,
        pendingFilters: advancedSearch.pendingTermFrequency.filters,
      }
    }
  ),
  {
    changeTermFrequencyArray,
    setTermFrequencyFrequency,
    submitTermFrequencyFilter,
    initializeTermFrequencyFilter,
    setTermFrequencyTargetField,
    removePendingTermFrequencyFilter,
  }
)

export default class TermFrequencyFilter extends Component {

  render() {
    const {termArray, frequency, targetField, pendingFilters} = this.props
    const targetOptions = [
      {
        value: TERM_FREQUENCY_TARGET_FIELDS.TEXT,
        label: TERM_FREQUENCY_TARGET_FIELD_LABELS.TEXT,
      },
      {
        value: TERM_FREQUENCY_TARGET_FIELDS.INTRO,
        label: TERM_FREQUENCY_TARGET_FIELD_LABELS.INTRO,
      },
      {
        value: TERM_FREQUENCY_TARGET_FIELDS.HEADLINE,
        label: TERM_FREQUENCY_TARGET_FIELD_LABELS.HEADLINE,
      },
    ]

    const pendingTerms = pendingFilters.map((filter) => {
      return (
        {
          label: filter.termArray.map((term) => term).join(', ')+`: ${filter.frequency}`,
          frequency: filter.frequency,
          targetField: filter.targetField,
          termArray: filter.termArray,
        }
      )
    })
    const pendingHeadlineLeadBody = pendingTerms.filter(
      pt => pt.targetField === TERM_FREQUENCY_TARGET_FIELDS.TEXT
    )
    const pendingHeadlineLead = pendingTerms.filter(
      pt => pt.targetField === TERM_FREQUENCY_TARGET_FIELDS.INTRO
    )
    const pendingHeadline = pendingTerms.filter(
      pt => pt.targetField === TERM_FREQUENCY_TARGET_FIELDS.HEADLINE
    )

    const pendingHeadlineLeadBodyContainer = pendingHeadlineLeadBody.length > 0
      ? <div className={'pending-headline-lead-body'}>
          <div
            className={'container-label'}
          >
            {TERM_FREQUENCY_TARGET_FIELD_LABELS.TEXT + ':'}
          </div>
          <TokenInput
            disableInput={true}
            tokenItems={pendingHeadlineLeadBody}
            updateTokensFunc={this.props.removePendingTermFrequencyFilter}
          />
        </div>
      : null

    const pendingHeadlineLeadContainer = pendingHeadlineLead.length > 0
      ? <div className={'pending-headline-lead'}>
          <div
            className={'container-label'}
          >
            {TERM_FREQUENCY_TARGET_FIELD_LABELS.INTRO + ':'}
          </div>
          <TokenInput
            disableInput={true}
            tokenItems={pendingHeadlineLead}
            updateTokensFunc={this.props.removePendingTermFrequencyFilter}
          />
        </div>
      : null

    const pendingHeadlineContainer = pendingHeadline.length > 0
      ? <div className={'pending-headline'}>
          <div
            className={'container-label'}
          >
            {TERM_FREQUENCY_TARGET_FIELD_LABELS.HEADLINE + ':'}
          </div>
          <TokenInput
            disableInput={true}
            tokenItems={pendingHeadline}
            updateTokensFunc={this.props.removePendingTermFrequencyFilter}
          />
        </div>
      : null

    const pendingFiltersContainer = pendingHeadlineLeadBodyContainer ||
      pendingHeadlineLeadContainer ||
      pendingHeadlineContainer
      ? <div className={'pending-filters-container'}>
        {pendingHeadlineLeadBodyContainer}
        {pendingHeadlineLeadContainer}
        {pendingHeadlineContainer}
        </div>
      : null

    return (
      <div id={'term-frequency-container'}>
        <div className={'term-frequency-inputs'}>
          <TokenInput
            inputLabel="Terms (comma separate multiple terms)"
            tokenItems={termArray}
            updateTokensFunc={this.props.changeTermFrequencyArray}
            inputWidth={'430px'}
            delimiters={['Enter', 'Tab', ';', ',']}
          />
          <div className={'font-size-s quiet term-input-help'}>
            (Phrases are not supported, please limit to single word terms.)
          </div>
          <div className={'second-row-input'}>
            <InputBlock label="Apply to"
              className={'target-dropdown'}
            >
              <Dropdown
                options={targetOptions}
                onChange={(value) => this.handleTargetFieldChange(value)}
                value={targetField || 'text'}
              />
            </InputBlock>
            <InputBlock className={'frequency'} label={'Frequency (1-99)'}>
              <TextBox
                value={frequency || ''}
                className={'frequency-input'}
                onChange={(e) => this.handleFrequencyChange(e)}
              />
            </InputBlock>
          </div>
          {pendingFiltersContainer}
          <div className={'button-row'}>
            <Button
              className={'cancel-term-freq'}
              label="Cancel"
              isPlainText={true}
              onClick={() => this.handleCancelOnClick()}
            />
            <Button
              disabled={termArray.length < 1 || !frequency}
              label={'Add'}
              isPrimary={true}
              className="add-term-freq-button"
              onClick={() => this.handleAddButtonOnClick()}
            />
          </div>
        </div>
      </div>
    )
  }

  handleFrequencyChange(event) {
    this.props.setTermFrequencyFrequency(event.currentTarget.value)
  }
  handleAddButtonOnClick() {
    const targetField = this.props.targetField || 'text'
    this.props.setTermFrequencyTargetField(targetField)
    this.props.submitTermFrequencyFilter()
  }
  handleCancelOnClick() {
    this.props.initializeTermFrequencyFilter()
  }
  handleTargetFieldChange(target) {
    this.props.setTermFrequencyTargetField(target)
  }
}
