import {changeCaseObject} from 'app/utils'
import React from 'react'
import { render } from 'react-dom'
import store from 'app/store'
import SearchesAdmin from './SearchesAdmin'
import * as constants from './searches-admin-constants'
import * as actions from './searches-admin-actions'
import reducer from './searches-admin-reducer'
import saga from './searches-admin-saga'
import * as selectors from './searches-admin-selectors'
import './style.less'
import AppWrapper from "app/global/app-wrapper"


export function init(data) {
  // Add the data from this page to the Redux store
  store.dispatch(actions.init(changeCaseObject.camelCase(data)))
  const component =
    <AppWrapper>
      <SearchesAdmin />
    </AppWrapper>
  const container = document.getElementById('searches-admin-wrapper')
  render(component, container)
}

export {constants, reducer, saga, selectors}
