import {parseISO} from 'date-fns'
import deepCopy from 'deepcopy'
import immerProduce from 'immer'
import is from 'is'
import {equals, flatten, pipe, uniq, values, without, assocPath} from 'ramda'
import {handleActions} from 'redux-actions'

import * as routing from 'app/global/routing'
import {urlStringToLocation} from 'app/global/routing/routing-utils'
import {doFiltersMatch} from 'app/utils/searches'
import {Feed} from 'app/models'
import {TABS as EDIT_SEARCH_MODAL_TABS} from 'app/reusable/EditSearchModal'

import * as actions from './search-results-page-actions'
import * as constants from './search-results-page-constants'
import * as utils from './search-results-page-utils'

let produce = immerProduce
if (!window.Proxy) {
  // Hack alert! See app/root-reducer.js for an explanation of this. The only
  // difference here is we do a deep copy since we have nested objects.
  // TODO: Properly figure out the IE issue.
  function ieProduce(base, producer) {
    const newBase = deepCopy(base)
    producer(newBase)
    return newBase
  }
  produce = ieProduce
}


const getInitialState = () => ({
  isLoading: true,
  isSaving: false,
  isRefreshingResults: true,
  isChangingTrustedState: false,
  searchId: null,
  // Only valid if we're looking at search results for a feed.
  feedId: null,
  query: null,
  queryType: null,

  // This only contains IDs for documents at the top level of groups; grouped
  // documents are included in `groupedDocumentIds` below.
  topLevelDocumentIds: [],
  compareTopLevelDocumentIds: [],

  // Contains arrays of IDs of grouped documents, keyed by the IDs of their
  // parents.
  groupedDocumentIds: {},

  // Feeds to hide because they have been excluded. This doesn't need to be
  // persisted in any way because it's only used to immediately hide the
  // excluded article(s) instead of refreshing the results.
  hiddenFeedIds: [],

  publicationCounts: [],
  comparePublicationCounts: [],
  compareCountIsGreater: false,

  isEditSearchSettingsModalOpen: false,
  isEditSearchFiltersModalOpen: false,
  activePublicationTab: null,
  activePublicationType: null,
  relevancyLevel: null, // null means to use the search's level
  groupingLevel: null, // null means to use the search's level
  languageFilters: null,
  selectedLanguageIds: null,
  sortOption: null,
  insightsArticlesFirst: false,
  compareId: null,
  upcomingEvents: true,
  resultsPerPage: null,

  // This can either be a string (like '2w') or an object representing a Date
  // range (like {start, end}).
  timeFrame: null,

  appliedFilterKey: null,
  termFrequencyKey: null,
  // Filters that have not yet been applied via the above key.
  unappliedFilters: [],
  // Filters that have been queued for removal.
  removedFilters: [],

  // Pagination
  page: 1,
  isLoadingNextPage: false,

  // Selection
  selectedDocumentIds: [],
  selectAllState: constants.SELECT_ALL_STATES.NONE,

  isFiltersBarOpen: true,
  collapsedFilterSections: [],

  trending: {
    isOpen: false,
    // Null value means it hasn't been fetched yet, which means that either it
    // is currently loading, or the trending bar is closed.
    data: null,
  },

  flagModal: {
    isOpen: false,
    documentIds: [],
  },

  isDeleteModalOpen: false,
  editModalDefaultTab: null,

  // Meta
  shouldShowQueryComponents: false,
  shouldShowFullQuery: false,

  isSavingSearchSettings: false,

  chartsAndTrends: {
    timeline: null,
    shareOfVoice: null,
    stockTimeline: null,
  },

  esgData: {
    pillars: null,
    companyScores: null,
    industryChartData: null,
    companyPillarStories: null,
    industryPillarStories: null,
    sidebarContent: [],
    staticSidebarContent: [],
  },
  esgActiveFilterApplied: false,

  shouldShowDnbModal: false,

  saveData: {
    searchId: null,
    name: undefined,
    scope: undefined,
    category: undefined,
    coreSearchValues: null, // starts as null to identify that no changes have been made; set to an array when changes made
    coreSearchRawValue: null,
    filters: null, // starts as null to identify that no changes have been made; set to an array when changes made
    solrSearchField: undefined,
    groupingLevel: undefined,
    languageFilters: null,
    selectedLanguageIds: null,
    resultsOrder: undefined,
    duration: undefined,
    resultsPerPage: null,

    noticeConfig: {
      frequency: '',
      maxItems: {},
    },
  },

  // this stores the state of the SavedSearchFilters component so that it is maintained when tabbing back and forth.
  queryComponentState: {},

  // New search modal
  newSearchModalData: {
    shouldShow: false,
    isFirmLibrary: false,
  },

  // Modal explaining saving a search into the firm library
  shouldShowSaveIntoFirmLibraryModal: false,

  hasFetchedRelevanceIds: false,

  // sharing
  areAssigneesFetched: false,
  areAllUsersFetched: false,
  userIds: [],
  departments: [],
  teams: [],
  firmLocations: [],
  assigneeIdsBySection: {},

  cachedLargerTimeFrames: [],

  // default frequency/maxItems by category; used when saving a source as a search (changing category).
  userDefaultEmailSettings: {},
})

/**
 * Uses the query parameters from the passed in location object to determine
 * what search options to set, such as sorting and relevance.
 */
const getStateFromLocation = location => {
  const initialState = getInitialState()
  const id = utils.getTier3SearchOrFeedId(location.pathname)
  const isFeed = utils.isFeedPage(location.pathname)
  const query = location.query[constants.QUERY_PARAM_NAMES.QUERY]
  const partialState = {
    searchId: isFeed ? -1 : id,
    feedId: isFeed ? id : initialState.feedId,
    query: is.defined(query) ? query : null,
    insightsArticlesFirst: location.query[constants.QUERY_PARAM_NAMES.INSIGHTS_FIRST] === 'true',
    upcomingEvents:
      location.query[constants.QUERY_PARAM_NAMES.UPCOMING_EVENTS] !== 'false',
  }

  if (partialState.searchId === -1) {
    partialState.searchId = null
  }

  const queryType = location.query[constants.QUERY_PARAM_NAMES.QUERY_TYPE]
  if (queryType) {
    partialState.queryType = queryType
  }
  const name = location.query[constants.QUERY_PARAM_NAMES.NAME]
  if (name) {
    partialState.name = name
  }

  const sortByParam = location.query[constants.QUERY_PARAM_NAMES.SORT]
  if (Object.values(constants.SORT_OPTIONS).includes(sortByParam)) {
    partialState.sortOption = sortByParam
  } else {
    partialState.sortOption = initialState.sortOption
  }

  const relevancyLevel =
    location.query[constants.QUERY_PARAM_NAMES.RELEVANCE]
  if (Object.values(constants.RELEVANCY_LEVELS).includes(relevancyLevel)) {
    partialState.relevancyLevel = relevancyLevel
  } else {
    partialState.relevancyLevel = initialState.relevancyLevel
  }

  const resultsPerPage =
    parseInt(location.query[constants.QUERY_PARAM_NAMES.RESULTS_PER_PAGE])
  if (Object.values(constants.NUMBER_OF_SEARCH_RESULTS).includes(resultsPerPage)) {
    partialState.resultsPerPage = resultsPerPage
  } else {
    partialState.resultsPerPage = initialState.resultsPerPage
  }

  const groupingLevel =
    location.query[constants.QUERY_PARAM_NAMES.GROUPING]
  if (Object.values(constants.GROUPING_LEVELS).includes(groupingLevel)) {
    partialState.groupingLevel = groupingLevel
  } else {
    partialState.groupingLevel = initialState.groupingLevel
  }

  const selectedLanguageIds = location.query[constants.QUERY_PARAM_NAMES.LANGUAGE]
  if (selectedLanguageIds) {
    partialState.selectedLanguageIds = selectedLanguageIds
  } else {
    partialState.selectedLanguageIds = initialState.selectedLanguageIds
  }

  const compareId = location.query[constants.QUERY_PARAM_NAMES.COMPARE_ID]
  if (compareId) {
    partialState.compareId = compareId
  }

  const timeFrame = location.query[constants.QUERY_PARAM_NAMES.TIME_FRAME]
  if (timeFrame) {
    if (timeFrame.includes(constants.TIME_FRAME_SEPARATOR)) {
      const [start, end] = timeFrame.split(constants.TIME_FRAME_SEPARATOR)
      partialState.timeFrame = {start: parseISO(start), end: parseISO(end)}
    } else {
      partialState.timeFrame = timeFrame
    }
  } else {
    partialState.timeFrame = initialState.timeFrame
  }

  const activeTab = location.query[constants.QUERY_PARAM_NAMES.TAB]
  if (
    Object.values(Feed.PUBLICATION_TYPE_CATEGORIES).includes(activeTab)
    || activeTab === 'charts' || activeTab === 'esg'
  ) {
    partialState.activePublicationTab = activeTab
  } else {
    partialState.activePublicationTab = initialState.activePublicationTab
  }

  const activePubType = location.query[constants.QUERY_PARAM_NAMES.PUB_TYPE]
  if (Object.values(Feed.PUBLICATION_TYPES).includes(activePubType)) {
    partialState.activePublicationType = activePubType
  } else {
    partialState.activePublicationType = initialState.activePublicationType
  }

  partialState.appliedFilterKey =
    location.query[constants.QUERY_PARAM_NAMES.APPLIED_FILTER_KEY]

  partialState.termFrequencyKey =
    location.query[constants.QUERY_PARAM_NAMES.TERM_FREQUENCY_KEY]

  return partialState
}


const handleInit = (state, {url, isLoading = true}) => ({
  ...getInitialState(),
  ...getStateFromLocation(urlStringToLocation(url)),
  // Preserve save modal state, so that we can continue to show the success
  // modal.
  shouldShowNewSearchModal: state.shouldShowNewSearchModal,
  isLoading,
})


export default handleActions(
  {
    [actions.init]: (state, action) => handleInit(
      state,
      {
        url: action.payload.url,
        isLoading: action.payload.isLoading,
      },
    ),
    [routing.actions.locationChanged]: (state, action) => {
      const stateFromLocation = getStateFromLocation(action.payload)
      if (
        stateFromLocation.searchId === state.searchId
        && stateFromLocation.feedId === state.feedId
      ) {
        return {
          ...state,
          ...getStateFromLocation(action.payload),
        }
      }
      return handleInit(
        state,
        {
          url: routing.utils.locationToString(action.payload),
        },
      )
    },

    [actions.setIsLoading]: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
    [actions.setIsSaving]: (state, action) => ({
      ...state,
      isSaving: action.payload,
    }),
    [actions.setIsRefreshingResults]: (state, action) => produce(
      state,
      newState => {
        newState.isRefreshingResults = action.payload
        newState.page = getInitialState().page
      }
    ),
    [actions.setIsChangingTrustedState]: (state, action) => ({
      ...state,
      isChangingTrustedState: action.payload,
    }),

    [actions.setSearchData]: (state, action) => produce(
      state,
      newState => {
        newState.topLevelDocumentIds =
          action.payload.topLevelDocumentIds || state.topLevelDocumentIds
        newState.groupedDocumentIds =
          action.payload.groupedDocumentIds || state.groupedDocumentIds
        newState.publicationCounts =
          action.payload.publicationCounts || state.publicationCounts

        if (state.isLoadingNextPage) {
          newState.isLoadingNextPage = false
          if (
            [
              constants.SELECT_ALL_STATES.TOP_LEVEL,
              constants.SELECT_ALL_STATES.INCLUDING_GROUPED,
            ].includes(state.selectAllState)
          ) {
            // Make sure to select any new documents that appear.
            let newSelectedIds = [
              ...state.selectedDocumentIds,
              ...newState.topLevelDocumentIds,
            ]
            if (state.selectAllState === constants.SELECT_ALL_STATES.INCLUDING_GROUPED) {
              newSelectedIds = [
                ...newSelectedIds,
                ...pipe(
                  values,
                  flatten,
                )(newState.groupedDocumentIds),
              ]
            }
            newState.selectedDocumentIds = uniq(newSelectedIds)
          }
        }
        newState.isRefreshingResults = false
      }
    ),

    [actions.setCompareSearchData]: (state, action) => ({
      ...state,
      compareTopLevelDocumentIds:
        action.payload.compareTopLevelDocumentIds || state.compareTopLevelDocumentIds,
      compareGroupedDocumentIds:
        action.payload.compareGroupedDocumentIds || state.compareGroupedDocumentIds,
      comparePublicationCounts:
        action.payload.comparePublicationCounts || state.comparePublicationCounts,
    }),
    [actions.setCompareCountIsGreater]: (state, action) => ({
      ...state,
      compareCountIsGreater: action.payload,
    }),

    [actions.showEditSearchSettingsModal]: (state, action) => ({
      ...state,
      isEditSearchSettingsModalOpen: true,
    }),
    [actions.hideEditSearchSettingsModal]: (state, action) => ({
      ...state,
      isEditSearchSettingsModalOpen: false,
    }),
    [actions.showEditSearchFiltersModal]: (state, action) => ({
      ...state,
      isEditSearchFiltersModalOpen: true,
      editModalDefaultTab: EDIT_SEARCH_MODAL_TABS.FILTERS,
    }),
    [actions.hideEditSearchFiltersModal]: (state, action) => ({
      ...state,
      isEditSearchFiltersModalOpen: false,
    }),

    // Filters

    [actions.addFilters]: (state, action) => ({
      ...state,
      unappliedFilters: [...state.unappliedFilters, ...action.payload],
    }),
    [actions.removeFilters](state, action) {
      const newState = {...state}
      for (const filter of action.payload) {
        // If the filter exists in our unappliedFilters list, remove it directly
        // from there; otherwise, it's a filter on the search, so queue it up
        // for removal.
        // Note: If the filter has an ID, that means it's already been applied,
        // so we can skip looking for it in `unappliedFilters`.
        const newUnappliedFilters = filter.id
          ? newState.unappliedFilters
          : newState.unappliedFilters.filter(unappliedFilter =>
            !doFiltersMatch(unappliedFilter, filter)
          )
        if (newUnappliedFilters.length !== newState.unappliedFilters.length) {
          newState.unappliedFilters = newUnappliedFilters
        }
        else {
          newState.removedFilters = [
            ...newState.removedFilters,
            filter,
          ]
        }
      }
      return newState
    },
    [actions.consolidateFilters]: (state, action) => produce(
      state,
      newState => {
        const searchFilters = action.payload

        // Remove any unapplied filters that have a matching search filter
        // (which means they have been applied).
        const unappliedFiltersToKeep =
          state.unappliedFilters.filter(filter =>
            !searchFilters.some(searchFilter =>
              doFiltersMatch(searchFilter, filter)
            )
          )

        // Discard any filters queued for removal that have since been removed
        // from the search.
        const filterIds = new Set(searchFilters.map(filter => filter.id))
        const removedFiltersToKeep =
          state.removedFilters.filter(filter =>
            filter.id && filterIds.has(filter.id)
          )

        // Don't update if nothing has changed.
        if (!equals(state.unappliedFilters, unappliedFiltersToKeep)) {
          newState.unappliedFilters = unappliedFiltersToKeep
        }
        if (!equals(state.removedFilters, removedFiltersToKeep)) {
          newState.removedFilters = removedFiltersToKeep
        }
      }
    ),

    [actions.selectDocumentId]: (state, action) => ({
      ...state,
      selectedDocumentIds: [...state.selectedDocumentIds, action.payload],
    }),
    [actions.deselectDocumentId]: (state, action) => ({
      ...state,
      selectedDocumentIds: without([action.payload], state.selectedDocumentIds),
      selectAllState: constants.SELECT_ALL_STATES.NONE,
    }),
    [actions.selectAllTopLevelDocuments]: (state, action) => ({
      ...state,
      selectAllState: constants.SELECT_ALL_STATES.TOP_LEVEL,
      selectedDocumentIds: [...state.topLevelDocumentIds],
    }),
    [actions.selectAllDocuments]: (state, action) => ({
      ...state,
      selectAllState: constants.SELECT_ALL_STATES.INCLUDING_GROUPED,
      selectedDocumentIds: [
        ...state.topLevelDocumentIds,
        ...pipe(
          values,
          flatten,
        )(state.groupedDocumentIds),
      ],
    }),
    [actions.deselectAllDocuments]: (state, action) => produce(
      state,
      newState => {
        newState.selectAllState = constants.SELECT_ALL_STATES.NONE
        if (state.selectedDocumentIds.length) {
          newState.selectedDocumentIds = getInitialState().selectedDocumentIds
        }
      }
    ),
    [actions.hideFeed]: (state, action) => ({
      ...state,
      hiddenFeedIds: [...state.hiddenFeedIds, action.payload.feedId],
    }),
    [actions.unhideFeed]: (state, action) => ({
      ...state,
      hiddenFeedIds: state.hiddenFeedIds.filter(f => f.id !== action.payload.feedId),
    }),

    // Filters bar

    [actions.setIsFiltersBarOpen]: (state, action) => ({
      ...state,
      isFiltersBarOpen: action.payload,
    }),
    [actions.setCollapsedFilterSections]: (state, action) => ({
      ...state,
      collapsedFilterSections: action.payload,
    }),
    [actions.toggleFilterSectionDisplay]: (state, action) => ({
      ...state,
      collapsedFilterSections:
        state.collapsedFilterSections.includes(action.payload)
          ? state.collapsedFilterSections.filter(
            section => section !== action.payload
          )
          : [
            ...state.collapsedFilterSections,
            action.payload,
          ],
    }),

    // Trending

    [actions.setIsTrendingOpen]: (state, action) => produce(
      state,
      newState => {
        newState.trending.isOpen = action.payload
      },
    ),
    [actions.setTrendingData]: (state, action) => produce(
      state,
      newState => {
        if (!equals(action.payload, state.trending.data)) {
          newState.trending.data = action.payload
        }
      },
    ),

    [actions.showFlagModal]: (state, action) => ({
      ...state,
      flagModal: {
        isOpen: true,
        documentIds: action.payload,
      },
    }),
    [actions.hideFlagModal]: (state, action) => ({
      ...state,
      flagModal: {
        isOpen: false,
        documentIds: getInitialState().documentIds,
      },
    }),

    [actions.showDeleteModal]: (state, action) => ({
      ...state,
      isDeleteModalOpen: true,
    }),
    [actions.hideDeleteModal]: (state, action) => ({
      ...state,
      isDeleteModalOpen: false,
    }),

    [actions.setIsLoadingNextPage]: (state, action) => ({
      ...state,
      page: state.page + (action.payload ? 1 : 0),
      isLoadingNextPage: action.payload,
    }),

    [actions.toggleShowQueryComponents]: (state, action) => ({
      ...state,
      shouldShowQueryComponents: !state.shouldShowQueryComponents,
    }),
    [actions.toggleShowFullQuery]: (state, action) => ({
      ...state,
      shouldShowFullQuery: !state.shouldShowFullQuery,
    }),

    [actions.saveSearchSettings]: (state, action) => ({
      ...state,
      isSavingSearchSettings: true,
    }),
    [actions.saveSearchSettingsComplete]: (state, action) => ({
      ...state,
      isSavingSearchSettings: false,
      isEditSearchSettingsModalOpen: false,
    }),

    [actions.setChartsAndTrendsData]: (state, action) => ({
      ...state,
      chartsAndTrends: action.payload
        ? {
          timeline: action.payload.timeline,
          shareOfVoice: action.payload.shareOfVoice,
          stockTimeline: action.payload.stockTimeline,
        }
        : getInitialState().chartsAndTrends,
    }),

    [actions.setEsgData]: (state, action) => ({
      ...state,
      esgData: action.payload
        ? {
          pillars: action.payload.pillars,
          companyScores: action.payload.companyScores,
          industryChartData: action.payload.industryChartData,
          companyPillarStories: action.payload.companyPillarStories,
          industryPillarStories: action.payload.industryPillarStories,
          sidebarContent: action.payload.sidebarContent,
          staticSidebarContent: action.payload.staticSidebarContent,
        }
        : getInitialState().esgData,
    }),
    [actions.setEsgActiveFilterApplied]: (state, action) => ({
      ...state,
      esgActiveFilterApplied: action.payload,
    }),
    [actions.updateEsgDocuments]: (state, action) => {
      // This should probably be done by `updateDocuments` but currently
      // the ESG documents aren't in the ORM (has extra fields like topic_id)
      const documents = action.payload.documents
      const documentLookup = Object.fromEntries(documents.map(d => [d.id, d]))

      const updateDeepDoc = stories => stories.map(s => {
        const documentUpdate = documentLookup[s.document.id]
        if (documentUpdate) {
          s.document = {
            ...s.document,
            ...documentUpdate,
          }
        }
        return s
      })

      const companyPillarStories = updateDeepDoc(state.esgData.companyPillarStories)
      const industryPillarStories = updateDeepDoc(state.esgData.industryPillarStories)
      let newState = assocPath(
        ['esgData', 'companyPillarStories'],
        companyPillarStories,
        state,
      )
      newState = assocPath(
        ['esgData', 'industryPillarStories'],
        industryPillarStories,
        newState,
      )
      return newState
    },

    [actions.showDnbModal]: (state, action) => ({
      ...state,
      shouldShowDnbModal: true,
    }),
    [actions.hideDnbModal]: (state, action) => ({
      ...state,
      shouldShowDnbModal: false,
    }),

    [actions.setQueryComponentState]: (state, action) => ({
      ...state,
      queryComponentState: action.payload,
    }),
    [actions.setSaveData]: (state, action) => ({
      ...state,
      saveData: {
        ...state.saveData,
        ...action.payload,
      },
    }),
    [actions.resetSaveData]: (state, action) => ({
      ...state,
      saveData: getInitialState().saveData,
    }),

    [actions.showNewSearchModal]: (state, action) => ({
      ...state,
      newSearchModalData: {
        shouldShow: true,
        isFirmLibrary: action.payload,
      },
      shouldShowSaveIntoFirmLibraryModal: false,
    }),
    [actions.hideNewSearchModal]: (state, action) => ({
      ...state,
      newSearchModalData: getInitialState().newSearchModalData,
      assigneeIdsBySection: getInitialState().assigneeIdsBySection,
    }),

    [actions.showSaveIntoFirmLibraryModal]: (state, action) => ({
      ...state,
      shouldShowSaveIntoFirmLibraryModal: true,
    }),
    [actions.hideSaveIntoFirmLibraryModal]: (state, action) => ({
      ...state,
      shouldShowSaveIntoFirmLibraryModal: false,
    }),
    [actions.setHasFetchedRelevanceIds]: (state, action) => ({
      ...state,
      hasFetchedRelevanceIds: action.payload,
    }),
    [actions.setLanguageFilters]: (state, action) => ({
      ...state,
      languageFilters: action.payload,
    }),
    [actions.setSelectedLanguageIds]: (state, action) => ({
      ...state,
      selectedLanguageIds: action.payload,
    }),
    [actions.removeExcludedFeedsFromSearch]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.fetchSearchExcludedFeeds]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.setSelectedAssigneeIdsBySection]: (state, action) => ({
      ...state,
      assigneeIdsBySection: action.payload,
    }),
    [actions.fetchAssignees]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.fetchAssigneesComplete]: (state, action) => ({
      ...state,
      userIds: action.payload.userIds,
      departments: action.payload.departments,
      teams: action.payload.teams,
      firmLocations: action.payload.firmLocations,
      areAssigneesFetched: true,
      isLoading: false,
    }),
    [actions.fetchUsers]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.fetchUsersComplete]: (state, action) => ({
      ...state,
      userIds: action.payload.userIds,
      areAllUsersFetched: action.payload.areAllUsersFetched,
      isLoading: false,
    }),
    [actions.setUserIds]: (state, action) => ({
      ...state,
      userIds: action.payload,
    }),
    [actions.setLastPage]: (state, action) => ({
      ...state,
      lastPage: action.payload,
    }),
    [actions.setUserDefaultEmailSettings]: (state, action) => ({
      ...state,
      userDefaultEmailSettings: action.payload,
    }),
    [actions.setCachedLargerTimeFrames]: (state, action) => ({
      ...state,
      cachedLargerTimeFrames: action.payload,
    }),
  },
  getInitialState(),
)
