import formatDate from 'date-fns/format'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import InputBlock from 'app/common/InputBlock/index'
import { DATE_FORMAT } from 'app/constants'

import FeedsTable from './Table'

import * as styles from './style.less'


export default class EditSourceFolderModalDetailsContent extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    appName:PropTypes.string,
    appNameFull:PropTypes.string,
  }

  render() {
    const {search} = this.props
    const count = search.aboutFeeds.length || 'No'
    const feedsTable = search.aboutFeeds.length
      ? <FeedsTable feeds={search.aboutFeeds} appName={this.props.appName} appNameFull={this.props.appNameFull}/>
      : null

    return (
      <div className={styles.detailsContent}>
        <div className={styles.properties}>
          <InputBlock label="Folder Name">
            <span className="value">{search.name}</span>
          </InputBlock>

          <InputBlock label="Owner">
            <span className="value">{search.owner.displayName}</span>
          </InputBlock>

          <InputBlock label="Created">
            <span className="value">
              {formatDate(search.createdAt, DATE_FORMAT)}
            </span>
          </InputBlock>

          <InputBlock label="Last Updated">
            <span className="value">
              {formatDate(search.updatedAt, DATE_FORMAT)}
            </span>
          </InputBlock>
        </div>

        <div className={styles.description}>
          <h2>{count} Sources Included in {search.name}</h2>
          <h3>
            Sources contained in this folder can be managed by <a href="/profile/firm/accounts/">logging into {search.owner.displayName}'s profile</a> directly and managing the sources from their profile.
          </h3>
        </div>

        {feedsTable}
      </div>
    )
  }
}
