import React, { Component, forwardRef } from 'react'

import SavedSearchInput from '../../common/SavedSearchInput'
import Button from 'app/common/Button'
import Modal from 'app/common/Modal'

import './style.less'
import PropTypes from "prop-types"
import classNames from "classnames"
import {runSearchForQuery} from 'app/utils'


class SearchBar extends Component {
  static propTypes = {
    appName: PropTypes.string,
    showButton: PropTypes.bool,
    handleShowModalClick: PropTypes.func,
  }
  static defaultProps = {
    appName: 'Manzama',
    showButton: true,
  }
  state = {
    // Tokenization in the SavedSearchInput happens asynchronously, so
    // this keeps track of whether we're waiting on that to happen
    isTokenizing: false,
    searchValue: '',
    isModalOpen: false,
  }

  render() {
    const searchButton =
      <Button
        label="New Search"
        isPrimary
        className="new-search-button"
        onClick={() => this.showModal()}
      />
    const browseButtonLabel = this.props.appName === 'Diligent'
      ? 'Browse Governance Intel Searches'
      : `Browse ${this.props.appName} Searches`
    
    const handleShowModalClick = () => {
      this.showModal()
    }

    const modal =
      <Modal
        className={classNames('search-bar-modal', {'diligent-brand': this.props.appName === 'Diligent'})}
        isOpen={this.state.isModalOpen}
        onClose={() => this.hideModal()}
      >
        <div className='search-bar'>
          <h1>How do you want to search?</h1>
          <form ref={ref => this.form = ref} onSubmit={this.onSubmit}>
            <label className="search-input-container">
              <span>Enter your search terms</span>
              <SavedSearchInput
                onChange={this.onSearchInputChange}
                ref={ref => this.input = ref}
              />
            </label>
            <div className="buttons">
                <button
                  type="submit"
                  className="button primary"
                >Search</button>
              <span>OR</span>
              <a href="/advanced-search/?tab=lookup" className="button">{browseButtonLabel}</a>
              <span>OR</span>
              <a href="/advanced-search/?tab=advanced" className="button">Advanced Search</a>
            </div>
          </form>
        </div>
      </Modal>
    return (
      <div id="site-search">
        {this.props.showButton && searchButton}
        {modal}
      </div>
    )
  }

  showModal = () => {
    this.setState({isModalOpen: true})
  }

  hideModal = () => {
    this.setState({isModalOpen: false})
  }

  onSearchInputChange = (tokens) => {
    if (this.input.hasUntokenizedText) return

    const searchValue = tokens.map(val => val.value).join('~|')
    const callback = this.state.isTokenizing
      ? () => this.form.submit()
      : () => {}
    this.setState({searchValue}, callback)
  }

  onSubmit = (event) => {
    event.preventDefault()

    // If the SavedSearchInput is currently tokenizing the free text, we
    // want to wait for that and not submit the form yet
    if (this.state.isTokenizing) {
      return
    }
    // If there is still free text in the input field, we want to turn
    // it into a "free text" token first before submitting
    if (this.input.hasUntokenizedText) {
      this.input.tokenizeFreeText()
      return
    }
    // If there is nothing to search for, don't submit
    else if (!this.state.searchValue) {
      return
    }

    runSearchForQuery(this.state.searchValue)
  }
}

const UpdatedSearchBar = forwardRef((props, ref) => {
  return <SearchBar {...props} ref={ref} />;
});

export default UpdatedSearchBar;