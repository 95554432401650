import React from 'react'


export default ({onModalClose}) => (
  <div>
    <p>If the email alerts or newsletters are structured as a list of article headlines with links, then they may be importable into your firm's Manzama account. Contact Support with the sources sending you the email that you want to import to get started.</p>

    <p><a href="/media/img/screenshot-add-source-email.png" target="_blank">Click here to see an example.</a></p>

    <div className="buttons left">
      <a
        className="contact-content-team button"
        href="mailto:content@manzama.com"
      >
        Contact Support
      </a>
      <a className="cancel button" onClick={onModalClose}>
        Cancel
      </a>
    </div>
  </div>
)
