import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Modal from 'app/common/Modal'

import UnboostedNews from './UnboostedNews'


export default class SampleModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    content: PropTypes.object.isRequired,
  }

  render() {
    const { isOpen, onClose, content } = this.props
    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <div>
          <h1>{content.header}</h1>

          <p>{content.description}</p>

          <div className="comparison">
            <div className="side">
              <UnboostedNews/>
            </div>

            <div className="side">
              <ul>
                <li className="comparison-header">Energy News With Booster</li>

                {
                  content.content.map((c, idx) => {
                    return (
                      <li key={idx} className={classNames('comparison-article',  {'is-boosted': !!c.classes})}>
                        {
                          c.classes ?
                            <div className="boosted">Result from Booster</div>
                            : <div className="boosted"/>
                        }
                        <div>{c.headline}</div>
                        <div className="source">{c.source}</div>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
