import { handleActions } from 'redux-actions'
import * as actions from './pendo-stats-actions'


const getInitialState = () => ({
  isLoading: false,
  firms: [],
  firmId: null,
  pageVisits: [],
  visitors: [],
  browsers: [],
})

export default handleActions(
  {
    [actions.init]: (state, action) => ({
      ...state,
      firms: action.payload.firms,
    }),
    [actions.hideLoader]: (state, action) => ({
      ...state,
      isLoading: false,
    }),
    [actions.setFirmId]: (state, action) => ({
      ...state,
      firmId: action.payload,
      pageVisits: [],
      visitors: [],
      browsers: [],
    }),
    [actions.fetchPageVisits]: (state, action) => ({
      ...state,
      isLoading: true,
      pageVisits: [],
    }),
    [actions.setPageVisits]: (state, action) => ({
      ...state,
      isLoading: false,
      pageVisits: action.payload,
    }),
    [actions.fetchVisitors]: (state, action) => ({
      ...state,
      isLoading: true,
      visitors: [],
    }),
    [actions.setVisitors]: (state, action) => ({
      ...state,
      isLoading: false,
      visitors: action.payload,
    }),
    [actions.fetchBrowsers]: (state, action) => ({
      ...state,
      isLoading: true,
      browsers: [],
    }),
    [actions.setBrowsers]: (state, action) => ({
      ...state,
      isLoading: false,
      browsers: action.payload,
    }),
  },
  getInitialState(),
)
