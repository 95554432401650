import React from 'react'

import Cell from './Cell'

const Row = ({item, columns, getRowStyle}) => {
  // For cells with colSpan > 1, ignore this many cells after
  let cellsToIgnore = 0

  const cells = columns.map((column, index) => {
    if (cellsToIgnore > 0) {
      cellsToIgnore--
      return undefined
    }

    let sizeInfo = {
      baseWidth: column.baseWidth,
      minWidth: column.minWidth,
      maxWidth: column.maxWidth,
      growRatio: column.growRatio,
      shrinkRatio: column.shrinkRatio,
    }

    const colSpan = valueFromMaybeFunction(column.colSpan, item, column)
    if (colSpan > 1) {
      cellsToIgnore = colSpan - 1

      // Calculate the new size information for the cell
      const columnsSpanned = columns.slice(index, index + colSpan)

      const attrs = [
        'baseWidth',
        'minWidth',
        'maxWidth',
        'growRatio',
        'shrinkRatio',
      ]
      attrs.forEach(attr => {
        // Sum
        sizeInfo[attr] = columnsSpanned.reduce((sum, column) => {
          // Ignore undefined sizes
          if (typeof column[attr] == 'undefined') return sum
          return (sum || 0) + column[attr]
        }, undefined)
      })
    }

    return (
      <Cell
        columnName={column.name}
        className={valueFromMaybeFunction(column.className, item, column)}
        baseWidth={sizeInfo.baseWidth}
        minWidth={sizeInfo.minWidth}
        maxWidth={sizeInfo.maxWidth}
        growRatio={sizeInfo.growRatio}
        shrinkRatio={sizeInfo.shrinkRatio}
        key={column.name}
      >
        {valueFromMaybeFunction(column.cellContents, item, column)}
      </Cell>
    )
  })

  return <div className={`row ${getRowStyle}`}>{cells}</div>
}


/*
 * If `value` is a function, calls that function with `args` and returns
 * its return value. Otherwise, just returns `value` directly. Useful
 * for values that may or may not be represented as functions.
 */
function valueFromMaybeFunction(value, ...args) {
  if (typeof value == 'function') {
    return value(...args)
  }
  return value
}


export default Row
