import React from 'react'
import {useSelector, useDispatch} from 'react-redux'

import Button from 'app/common/Button'
import Dropdown from 'app/common/Dropdown'
import InputBlock from 'app/common/InputBlock'
import TextBox from 'app/common/TextBox'

import {
  setNewLocationDescription,
  setNewLocationCountryId,
  setNewLocationTimezone,
  saveLocation,
  showDeleteLocationModal,
} from '../firm-settings-actions'
import {
  getNewLocationDescription,
  getNewLocationCountryId,
  getNewLocationTimezone,
  getCountries,
  getTimezones,
  getSelectedLocationIds,
} from '../firm-settings-selectors'
import {
  getCountryOptions,
  getTimezoneOptions,
} from '../firm-settings-utils'


export default function Actions() {
  const dispatch = useDispatch()
  const newDescription = useSelector(getNewLocationDescription)
  const newCountryId = useSelector(getNewLocationCountryId)
  const newTimezone = useSelector(getNewLocationTimezone)
  const countries = useSelector(getCountries)
  const timezones = useSelector(getTimezones)
  const selectedLocationIds = useSelector(getSelectedLocationIds)

  const countryOptions = getCountryOptions(countries)
  const timezoneOptions = getTimezoneOptions(timezones)

  const save = () => {
    dispatch(saveLocation({
      description: newDescription,
      countryId: newCountryId,
      timezone: newTimezone,
    }))
  }

  return (
    <div>
      <div className="header-action-container">
        <InputBlock label="New Location Name">
          <TextBox
            maxLength="100"
            autoFocus={true}
            value={newDescription}
            onChange={event => dispatch(setNewLocationDescription(event.target.value))}
          />
        </InputBlock>

        <InputBlock label="Country">
          <Dropdown
            options={countryOptions}
            className="country-dropdown"
            value={newCountryId}
            onChange={(value) => dispatch(setNewLocationCountryId(value))}
          />
        </InputBlock>

        <InputBlock label="Timezone">
          <Dropdown
            options={timezoneOptions}
            className="timezone-dropdown"
            value={newTimezone}
            onChange={(value) => dispatch(setNewLocationTimezone(value))}
          />
        </InputBlock>

        <Button
          label="Add Location"
          isPrimary
          disabled={!newDescription || !newCountryId}
          onClick={() => save()}
        />
      </div>

      <div className="header-action-container">
        <Button
          label="Delete Selected"
          isDestructive
          disabled={selectedLocationIds.length === 0}
          onClick={() => dispatch(showDeleteLocationModal())}
        />
      </div>
    </div>
  )
}
