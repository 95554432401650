import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from "classnames"
import {titleCase} from "title-case"
import * as constants from 'app/reusable/RelevanceFilter/SearchRelevanceFilterConstants'

import './SearchRelevanceFilter.less'
import Tooltip from "app/common/Tooltip"

export default class SearchRelevanceFilter extends Component {

  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    shouldShowTitle: PropTypes.bool,
    isDisabled: PropTypes.bool,
    tipDirection: PropTypes.string,
  }

  static defaultProps = {
    shouldShowTitle: true,
    isDisabled: false,
    tipDirection: 'left'
  }

  render() {
    return (
      <Tooltip
        label={'The Relevance Filter is not applicable to the Core Search Terms in your query.'}
        disabled={!this.props.isDisabled}
        direction={this.props.tipDirection}
      >
      <div className="search-relevance-container">
        {this.props.shouldShowTitle &&
          <div className={classNames('slider-label', {'disabled': this.props.isDisabled},)}>
            Relevance
          </div>
        }
        <div className="slider-container">
          {this.getRelevanceOption(constants.FOCUS_LEVEL_LOW)}
          {this.getRelevanceOption(constants.FOCUS_LEVEL_MEDIUM)}
          {this.getRelevanceOption(constants.FOCUS_LEVEL_HIGH)}
        </div>
      </div>
      </Tooltip>
    )
  }

  getRelevanceOption(focusLevel) {
    const bar = focusLevel === constants.FOCUS_LEVEL_HIGH
      ? null
      : <div className="bar">
          <div className="inner-bar" />
        </div>

    return(
      <div className="relevance-level-container">
        <div className="relevance-level">
          <div className='relevance'>
            <div
              onClick={() => this.props.isDisabled ? null : this.props.onChange(focusLevel)}
              className={classNames('circle-container',
                {'disabled': this.props.isDisabled},
                {'active': this.props.value === focusLevel},
              )}
            >
              <div className="circle">
                <span className={classNames('inner-circle', {'disabled': this.props.isDisabled},)}/>
              </div>
              {bar}
              <div className={classNames('relevance-text', {'disabled': this.props.isDisabled},)}>
                {titleCase(focusLevel)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
