import { createAction } from 'redux-actions'
import { createRequestActions } from '../actions/utils'

const PREFIX = 'EMAIL_NOTIFICATIONS'
const action = name => createAction(`${PREFIX}_${name}`)
export const emailDeliveryInit = action('EMAIL_DELIVERY_INIT')
export const insightsEmailSettingsInit = action('INSIGHTS_EMAIL_SETTINGS_INIT')
export const esgEmailSettingsInit = action('ESG_EMAIL_SETTINGS_INIT')
export const baseNotificationsInit = action('BASE_NOTIFICATIONS_INIT')
export const changeInsightsShowFactorsModal = action('CHANGE_INSIGHTS_SHOW_FACTORS_MODAL')
export const changeEsgShowFactorsModal = action('CHANGE_ESG_SHOW_FACTORS_MODAL')
export const changeCategoryOrderModalCategories = action('CHANGE_CATEGORY_ORDER_MODAL_CATEGORIES')
export const changeCategoryOrderModalShow = action('CHANGE_CATEGORY_ORDER_MODAL_SHOW')
export const changeCategoryOrderModalFrequency = action('CHANGE_CATEGORY_ORDER_MODAL_FREQUENCY')
export const changeCategoryDefaultsModalShow = action('CHANGE_CATEGORY_DEFAULTS_MODAL_SHOW')
export const changeCategoryDefaultsModalCategories =
  action('CHANGE_CATEGORY_DEFAULTS_MODAL_CATEGORIES')
export const changeContentSettingsModalSettings = action('CHANGE_CONTENT_SETTINGS_MODAL_SETTINGS')
export const changeContentSettingsModalState = action('CHANGE_CONTENT_SETTINGS_MODAL_STATE')
export const changeShowBulkUpdateModal = action(
  'CHANGE_SHOW_BULK_UPDATE_MODAL')
export const changeSendSampleModal = action('CHANGE_SEND_SAMPLE_MODAL')
export const changeSendSampleTokens = action(
  'CHANGE_SEND_SAMPLE_TOKENS')
export const changeSendSampleRecipients = action(
  'CHANGE_SEND_SAMPLE_RECIPIENTS')
export const changeFrequencyBulkUpdateConfigs = action('CHANGE_FREQUENCY_BULK_UPDATE_CONFIGS')
export const changeBulkUpdateFilterBy = action(
  'CHANGE_BULK_UPDATE_FILTER_BY')
export const changeFactorsModalConfig = action('CHANGE_FACTORS_MODAL_CONFIG')
export const changeFrequencyHeader = action('CHANGE_FREQUENCY_HEADER')
export const changeFrequencyToggle = action('CHANGE_FREQUENCY_TOGGLE')
export const changeCategoryToggle = action('CHANGE_CATEGORY_TOGGLE')
export const changeBaseFrequency = action('CHANGE_BASE_FREQUENCY')
export const changeSelectedConfigs = action('CHANGE_SELECTED_CONFIGS')
export const removeSelectedConfigs = action('REMOVE_SELECTED_CONFIGS')
export const changeBulkActionState = action('CHANGE_BULK_ACTION_STATE')
export const resetBulkActionState = action('RESET_BULK_ACTION_STATE')

export const fetchEmailAlertCategoryDefaults = action('FETCH_EMAIL_ALERT_CATEGORY_DEFAULTS')

const requestActions = name => createRequestActions(`${PREFIX}_${name}`)
// API Actions
export const api = {
  saveEmailDelivery: requestActions('SAVE_EMAIL_DELIVERY'),
  addEmailPrefixSuffix: requestActions('ADD_EMAIL_PREFIX_SUFFIX'),
  applyEmailDeliverySettings: requestActions('ADD_EMAIL_SETTINGS_PROFILE'),
  applyCategoryOrdersSettings: requestActions('ADD_CATEGORY_ORDERS_PROFILE'),
  saveEmailAlertSettings: requestActions('SAVE_EMAIL_ALERT_SETTINGS'),
  getCCEmailsInsights: requestActions('GET_CC_EMAILS_INSIGHTS'),
  getCCEmailsEsg: requestActions('GET_CC_EMAILS_ESG'),
  getCCEmailsBase: requestActions('GET_CC_EMAILS_BASE'),
  sendSampleEmail: requestActions('SEND_SAMPLE_EMAIL'),
  updateCCEmails: requestActions('UPDATE_CC_EMAILS'),
  getInsightsNoticeConfigs: requestActions('GET_INSIGHTS_NOTICE_CONFIGS'),
  getEsgNoticeConfigs: requestActions('GET_ESG_NOTICE_CONFIGS'),
  getBaseNoticeConfigs: requestActions('GET_BASE_NOTICE_CONFIGS'),
  saveInsightsNoticeConfigs: requestActions('SAVE_INSIGHTS_NOTICE_CONFIGS'),
  saveEsgNoticeConfigs: requestActions('SAVE_ESG_NOTICE_CONFIGS'),
  saveBaseNoticeConfigs: requestActions('SAVE_BASE_NOTICE_CONFIGS'),
  updateCategoryOrder: requestActions('UPDATE_CATEGORY_ORDER'),
  updateCategoryDefaults: requestActions('UPDATE_CATEGORY_DEFAULTS'),
  updateContentSettings: requestActions('UPDATE_CONTENT_SETTINGS'),
  getEmailAlertSettings: requestActions('GET_EMAIL_ALERT_SETTINGS'),
  getNotificationSettings: requestActions('GET_NOTIFICATION_SETTINGS'),
  changeNotificationSettings: requestActions('CHANGE_NOTIFICATION_SETTINGS'),
}
