import React, { Component } from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {joinInSentence} from 'app/utils'

import {getDunAndBradstreet} from './dun-and-bradstreet-selectors'

const getSearchNameProp = (state, props) => props.searchName


@connect(
  () => createSelector(
    [getDunAndBradstreet, getSearchNameProp],
    (dnbState, searchName) => {
      const allCompanies = dnbState.data.mzFamily
      const selectedCompanies = allCompanies.filter(company =>
        dnbState.content.selectedEntityIds.includes(company.entityId)
      )
      const selectedCompanyNames = selectedCompanies.map(c => c.companyName)

      let association, names, preposition
      if (selectedCompanies.length === 1) {
        names = selectedCompanies[0].companyName
        association = dnbState.data.association[0]
        preposition = 'has'
      }
      else {
        names = joinInSentence(selectedCompanyNames)
        association = dnbState.data.association[1]
        preposition = 'have'
      }
      const action = selectedCompanies.some(entity => entity.includedInSearch)
        ? 'removed from'
        : 'added to'

      return {
        confirmationMessage:
          `The ${association} ${names} ${preposition} been ${action} this ${searchName} search.`,
      }
    }
  ),
)
export default class CompaniesChangedConfirmation extends Component {
  render() {
    const {confirmationMessage} = this.props
    return (
      <div id="dnb-chg-confirmation">
        <div>
          <span className='dnb-trophy'></span>
        </div>
        <p id="changed-details">
          {confirmationMessage}
        </p>
        <p id="info-text" className="text">
          To save these changes and/or to further modify this search,
          click on either "Save Search / Alert" or "Refine Search" located in
          the upper right hand corner of the search results page.
        </p>
      </div>
    )
  }
}
