import classNames from 'classnames'
import React, {useEffect, useState} from 'react'

import Button from 'app/common/Button'
import CategoryDropdown from 'app/common/CategoryDropdown'
import InputBlock from 'app/common/InputBlock'
import TextBox from 'app/common/TextBox'
import {SavedSearch} from 'app/models'

import styles from './NewSearchModal.less'

const {CATEGORIES} = SavedSearch


export default function NewSearchModalBasicInfo({
  isSaving,
  isFirmLibrary,  // admin user is saving into the firm library
  currentUserIsFirmLibraryGroup,
  defaultName,
  defaultCategory,
  onSubmit,
}) {
  const [name, setName] = useState(defaultName)
  const [category, setCategory] = useState(defaultCategory)
  const [isPrivate, setIsPrivate] = useState(true)
  const [willSetEmailAlerts, setWillSetEmailAlerts] = useState(true)
  const [hasValidationError, setHasValidationError] = useState(false)
  useEffect(
    () => {
      if (hasValidationError && name) {
        // Reset the validation error when the name has been populated.
        setHasValidationError(false)
      }
    },
    [name, hasValidationError],
  )
  const handleSubmit = event => {
    event.preventDefault()
    if (!name) {
      setHasValidationError(true)
      return
    }
    onSubmit({
      name,
      category,
      isPrivate,
      willSetEmailAlerts,
    })
  }
  return (
    <form onSubmit={handleSubmit} className={styles.container}>
      <InputBlock
        label="Search Name"
        description="Enter a name for the search you have built."
        className={styles.block}
      >
        <TextBox
          defaultValue={defaultName}
          onChange={event => setName(event.target.value)}
          isInvalid={hasValidationError}
        />
      </InputBlock>

      <InputBlock
        label="Search Category"
        description="The category in which your search will appear."
        className={styles.block}
      >
        <CategoryDropdown
          defaultValue={defaultCategory}
          onChange={setCategory}
          omittedCategories={[
            CATEGORIES.TRUSTED,
            CATEGORIES.TRUSTED_UNCATEGORIZED,
          ]}
        />
      </InputBlock>

      {!isFirmLibrary && !currentUserIsFirmLibraryGroup &&
        <div className={classNames(styles.block, styles.emailAlertQuestion)}>
          <div className={styles.label}>
            Do you want to receive the results of this search in an email alert?
          </div>
          <div className={styles.inputs}>
            <InputBlock label="Yes" isInline className={styles.input}>
              <input
                type="radio"
                name="email-alerts"
                defaultChecked={true}
                onChange={event => setWillSetEmailAlerts(event.target.checked)}
              />
            </InputBlock>
            <InputBlock label="No" isInline className={styles.input}>
              <input
                type="radio"
                name="email-alerts"
                onChange={event => setWillSetEmailAlerts(!event.target.checked)}
              />
            </InputBlock>
          </div>
        </div>
      }

      {(isFirmLibrary || currentUserIsFirmLibraryGroup) &&
        <InputBlock isInline label="Private Search">
          <input
            type="checkbox"
            checked={isPrivate}
            onChange={(evt) => setIsPrivate(evt.target.checked)}
          />
        </InputBlock>
      }

      <div className={styles.button}>
        <Button
          label={isSaving ? 'Saving...' : (willSetEmailAlerts && !isFirmLibrary) || currentUserIsFirmLibraryGroup ? 'Next' : 'Save'}
          disabled={isSaving}
          isPrimary={true}
        />
      </div>
    </form>
  )
}
