import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as styles from './Company.less'
import BackLink from './BackLink'
import OverviewEsg from '../../esg-graphs'
import { CompanyNews as News } from 'app/esg/News'
import { FACTORS_BY_ID, FACTORS_BY_NAME, SUBFACTORS_BY_ID, SUBFACTORS_BY_NAME } from '../../../constants'
import LoadingSegment from '../../LoadingSegment/LoadingSegment'

import * as esgSelectors from '../../esg-selectors'
import * as esgActions from '../../esg-actions'
import { filterEsgEnabledCompaniesFromSavedSearches } from '../../utils'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import HealthScoreContainer, { LeftColumn, RightColumn } from 'app/esg/HealthScoreContainer'
import { LoadingHeadline, LoadingSingleLine } from 'app/esg/loading'
import Dropdown from 'app/common/Dropdown'
import EsgScoreInfo from 'app/esg/EsgScoreInfo'
import PillarInfo from 'app/esg/PillarInfo'
import InputBlock from 'app/common/InputBlock'
import ESGSearch from './ESGSearch'
/*
  This is a container for the ESG company page, which consists of multiple components.
 */

export default function Company() {
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const MY_COMPANIES = "MyCompanies"
  const ALL_COMPANIES = "AllCompanies"

  // filter the saved searches and find the selected company
  let selectedCompany = params.companyId
  const [selectedOption, setselectedOption] = useState(MY_COMPANIES)
  const [searchValue, setsearchValue] = useState('')
  const companies = filterEsgEnabledCompaniesFromSavedSearches(useSelector(esgSelectors.getSavedSearches))
  const esgAllCompaniesOnSearch = useSelector(esgSelectors.getAllEsgCompaniesOnSearch)

  const updatesearchValue = search => {
    const searchString = search
    setsearchValue(searchString)
    dispatch(esgActions.setEsgCompaniesSearchString(searchString))
    dispatch(esgActions.getAllEsgCompaniesDataOnSearch())
  }
  const myCompaniesClick = () => {
    setselectedOption(MY_COMPANIES)
    dispatch(esgActions.clearAllEsgCompaniesDataOnSearch())
  }

  const getSelectedCompanyData = (savedSearchId) => {
    return companies.find(element => String(element.value) === savedSearchId)
  }
  const getSelectedCompanyFromSearch = (savedSearchId) => {
    return esgAllCompaniesOnSearch.find(element => String(element.value) === savedSearchId)
  }
  let selectedCompanyData = ''
  if (selectedOption === ALL_COMPANIES) {
    selectedCompanyData = getSelectedCompanyFromSearch(selectedCompany)
  if (selectedCompanyData === undefined) {
    selectedCompanyData = getSelectedCompanyData(selectedCompany)
  }
}
  //dropdown option on select radio button
  if (selectedOption === MY_COMPANIES) {
    selectedCompanyData = getSelectedCompanyData(selectedCompany)
    if (selectedCompanyData === undefined) {
      selectedCompany = companies[0].value
      history.push(`/company/${selectedCompany}`)
    }
  }

  if (!selectedCompany) {
    return <Redirect to='/' />
  }
  
  React.useEffect(() => {
    dispatch(esgActions.getCompany(selectedCompany))
    dispatch(esgActions.setCompanyStoriesLoading())
  }, [selectedCompany])

  // get esg docs
  const esgData = useSelector(esgSelectors.getCompanyEsgData)
  const isLoading = esgData.isLoading
  const isError = esgData.isError
  const pillarFilter = useSelector(esgSelectors.getCompanySelectedPillar)
  const setPillarFilter = pillar => dispatch(esgActions.setCompanySelectedPillar(pillar))
  const topicFilter = useSelector(esgSelectors.getCompanySelectedTopic)
  const setTopicFilter = topic => dispatch(esgActions.setCompanySelectedTopic(topic))


  const healthBadge = !selectedCompany || isLoading ? <LoadingSegment className={styles.loadingHealthScoreWrapper} /> :
    <OverviewEsg healthData={esgData} selectedFactor={FACTORS_BY_ID[pillarFilter]} hideHealthComparison={isError}
      selectedSubfactor={SUBFACTORS_BY_ID[topicFilter]}
      onFactorClick={(factor) => setPillarFilter(FACTORS_BY_NAME[factor])}
      onSubfactorClick={(subfactor) => setTopicFilter(SUBFACTORS_BY_NAME[subfactor])} />

  
  return (<div className={styles.container}>
    <BackLink />
    <h1 className={styles.headerText}>ESG Company Details</h1>
    <h3 className={styles.subHeader}>ESG Health Score</h3>
    <HealthScoreContainer>
      <LeftColumn>
        {!selectedCompany ?
          <div>
            <LoadingHeadline />
            <LoadingSingleLine />
            <LoadingSingleLine />
          </div>
          :
          (
            <div>
              <h5 className={styles.selectcompanyText}>Select another Company</h5>
              <div className="row">
                <InputBlock label="From All Companies" isInline>
                  <input
                    type="radio"
                    name="company-type"
                    checked={selectedOption === ALL_COMPANIES}
                    onChange={() => setselectedOption(ALL_COMPANIES)}
                    value={ALL_COMPANIES}
                  />
                </InputBlock>
                <InputBlock label="From My Companies" isInline>
                  <input
                    type="radio"
                    name="company-type"
                    checked={selectedOption === MY_COMPANIES}
                    onChange={() => myCompaniesClick()}
                    value={MY_COMPANIES}
                  />
                </InputBlock>
              </div>
              {selectedOption === MY_COMPANIES ? <Dropdown
                onChange={(c) => history.push(`/company/${c}`)}
                options={companies}
                value={selectedCompany}
                className={styles.dropdown}
                showFilter={true}
                appTypelabel="ESG"
              /> :
                <ESGSearch
                  onChange={(c) => history.push(`/company/${c}`)}
                  updatesearchValue={updatesearchValue}
                  options={esgAllCompaniesOnSearch}
                  className={styles.dropdown}
                />
              }
              {
                !isError?              
                <div className={styles.companyContent}>
                  <div className={styles.companyName}>
                    {!isLoading && esgData?.companyName}
                  </div>
                  <div className={styles.percentileText}>
                    {!isLoading && esgData?.percentileText}
                  </div>
                </div> : 
                <div className={styles.companyContent}>
                  <div className={styles.companyName}>
                    {selectedCompanyData?.label}
                  </div>
                </div>
              }
            </div>
          )
        }
        <EsgScoreInfo />
      </LeftColumn>
      <RightColumn>
        {healthBadge}
        {!isError ? <PillarInfo /> : <>
            <div className={styles.noCompanyData}>
              <div className={styles.noTitle}>
                No ESG data to display
              </div>
              <div className={styles.noDesc}>
                Select another company to view their ESG data
              </div>
            </div>
          </>
        }
      </RightColumn>
    </HealthScoreContainer>
    <div className={styles.newsBox}>
      <News
        selectedCompany={selectedCompanyData}
        pillarFilter={pillarFilter}
        setPillarFilter={setPillarFilter}
        topicFilter={topicFilter}
        setTopicFilter={setTopicFilter}
        isError={isError}
      />
    </div>
  </div>
  )
}
