import React, { Component } from 'react'
import { connect } from 'react-redux'

import Button from 'app/common/Button'
import Dropdown from 'app/common/Dropdown'
import InputBlock from 'app/common/InputBlock'
import AlertMaxItemsDropdown from 'app/reusable/AlertMaxItemsDropdown'
import { ASSIGNEE_TYPES } from 'app/reusable/BulkUserAssignment'

import {
  addNewModalSetStep,
  createAndShareSearch,
  setSaveData,
} from '../searches-admin-actions'
import { getSearchesAdmin } from '../searches-admin-selectors'


@connect(
  // Map state to props
  state => {
    const searchesAdmin = getSearchesAdmin(state)

    return {
      saveData: searchesAdmin.saveData,
      searchCategories: state.searchCategories,
      noticeFrequencies: searchesAdmin.noticeFrequencies,
    }
  },
  {
    setStep: addNewModalSetStep,
    createAndShareSearch,
    setSaveData,
  },
)
export default class ShareSearch extends Component {
  state = {
    emailSettingType: '',
  }

  render() {
    const { saveData, noticeFrequencies } = this.props
    const step = this.props.saveData.shareOnlyId ? 3 : 4
    const saveButtonLabel = this.props.saveData.shareOnlyId ? 'Share Search' : 'Add Search'
    const frequencyOptions = noticeFrequencies.map(
        f => {return {value: f[0], label: f[1]}}
      ).filter(f => f.value !== 'priority_trigger')

    return (
      <div>
        <h2>Step {step}: Email Settings</h2>

        <div className="new-search-notification-content">
          <InputBlock isInline label="Set Email Settings based upon the User Defaults" className="radio">
            <input
              type="radio"
              name="email_setting_type"
              value="default"
              defaultChecked={this.state.emailSettingType === 'default'}
              onClick={evt => this.setEmailSettingType(evt)}
            />
          </InputBlock>

          <InputBlock isInline label="Choose Email Notification Settings" className="radio">
            <input
              type="radio"
              name="email_setting_type"
              value="custom"
              defaultChecked={this.state.emailSettingType === 'custom'}
              onClick={evt => this.setEmailSettingType(evt)}
            />
          </InputBlock>

          {
            this.state.emailSettingType === 'custom' ?
              <div className="new-search-notification-options">
                <InputBlock label="Alert Frequency">
                  <Dropdown
                    options={frequencyOptions}
                    value={saveData.noticeConfig.frequency || frequencyOptions[0].value}
                    onChange={value => this.handleFrequencyChange(value)}
                  />
                </InputBlock>

                <InputBlock label="Articles">
                  <AlertMaxItemsDropdown
                    value={saveData.noticeConfig.maxItems.defaultPubTypes || 5}
                    onChange={(value) => this.alertMaxItemsChangeHandler('defaultPubTypes', value)}
                  />
                </InputBlock>

                <InputBlock label="Filings">
                  <AlertMaxItemsDropdown
                    value={saveData.noticeConfig.maxItems.allFilings || 0}
                    onChange={(value) => this.alertMaxItemsChangeHandler('allFilings', value)}
                  />
                </InputBlock>

                <InputBlock label="Tweets">
                  <AlertMaxItemsDropdown
                    value={saveData.noticeConfig.maxItems.twitter || 0}
                    onChange={(value) => this.alertMaxItemsChangeHandler('twitter', value)}
                  />
                </InputBlock>

                <InputBlock label="Events">
                  <AlertMaxItemsDropdown
                    value={saveData.noticeConfig.maxItems.event || 0}
                    onChange={(value) => this.alertMaxItemsChangeHandler('event', value)}
                  />
                </InputBlock>
              </div>
              : null
          }
        </div>

        <div className="new-search-buttons-container">
          <div className="new-search-buttons">
            <div className="buttons left">
              <Button
                label="Back to Previous Step"
                onClick={() => this.previousStep()}
              />
            </div>

            <div className="buttons right">
              <Button
                label={saveButtonLabel}
                isPrimary
                onClick={() => this.save()}
                disabled={this.state.emailSettingType === ''}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  setEmailSettingType(evt) {
    const { saveData, noticeFrequencies } = this.props
    const noticeConfig = {
      frequency: '',
      maxItems: {},
    }
    if (evt.target.value === 'custom') {
      noticeConfig.frequency = saveData.noticeConfig.frequency || noticeFrequencies[0][0]
      noticeConfig.maxItems = {
        defaultPubTypes: 5,
        allFilings: 0,
        twitter: 0,
        event: 0,
        ...saveData.noticeConfig.maxItems,
      }
    }
    if (evt.target.checked) {
      this.setState({emailSettingType: evt.target.value})
    }
    this.props.setSaveData({noticeConfig})
  }

  handleFrequencyChange(value) {
    const noticeConfig = {
      ...this.props.saveData.noticeConfig,
      frequency: value
    }
    this.props.setSaveData({noticeConfig})
  }

  alertMaxItemsChangeHandler(type, value) {
    const noticeConfig = {
      ...this.props.saveData.noticeConfig,
      maxItems: {
        ...this.props.saveData.noticeConfig.maxItems,
        [type]: value,
      }
    }

    this.props.setSaveData({noticeConfig})
  }

  previousStep() {
    this.props.setStep(3)
  }

  save() {
    const { name, isFirmLibrary, scope, category, query, queryType, assigneeIdsBySection, shareOnlyId,
      filters, noticeConfig, solrSearchField, groupingLevel } = this.props.saveData
    const shareUserIds = [
      ...assigneeIdsBySection[ASSIGNEE_TYPES.USER] || [],
      ...assigneeIdsBySection[ASSIGNEE_TYPES.GROUP] || []
    ]
    const shareDepartmentIds = [...assigneeIdsBySection[ASSIGNEE_TYPES.DEPARTMENT_MEMBER] || []]
    const shareTeamIds = [...assigneeIdsBySection[ASSIGNEE_TYPES.TEAM_MEMBER] || []]
    const shareFirmLocationIds = [...assigneeIdsBySection[ASSIGNEE_TYPES.FIRM_LOCATION_MEMBER] || []]
    const shareGroupIds = [...assigneeIdsBySection[ASSIGNEE_TYPES.GROUP_MEMBER] || []]
    const data = {
      name,
      isFirmLibrary,
      scope,
      category,
      queryType,
      searchPhrase: query,
      filters,
      shareUserIds,
      shareDepartmentIds,
      shareTeamIds,
      shareFirmLocationIds,
      noticeConfig,
      solrSearchField,
      groupingLevel,
      shareGroupIds,
    }
    // when sharing only, the ss to be shared is the only core value
    if (shareOnlyId) {
      data.searchId = shareOnlyId
      data.isMakeChild = true
    }
    this.props.createAndShareSearch(data)
  }
}
