import PropTypes from 'prop-types'
import React, { Component } from 'react'
import * as R from 'ramda'

import Table from 'app/common/Table'
import PaginatedTable from 'app/common/PaginatedTable'

const COLUMNS = {
  CHECKBOX: 'checkbox',
  NAME: 'name',
  REQUIRES_SUBSCRIPTION: 'is_subscription_required',
}


export default class BrowseManzamaSourcesTable extends Component {
  static propTypes = {
    feeds: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedFeedIds: PropTypes.arrayOf(PropTypes.number),
    onFeedSelectStateChange: PropTypes.func,
    onFeedsSelectAll: PropTypes.func,
    onFeedsUnselectAll: PropTypes.func,
    onPageChange: PropTypes.func,
    onSortChange: PropTypes.func,
  }

  static defaultProps = {
    selectedFeedIds: [],
    onFeedSelectStateChange() {},
    onFeedsSelectAll() {},
    onFeedsUnselectAll() {},
    onPageChange() {},
    onSortChange() {},
  }

  render() {
    const selectAll =
      <input
        type="checkbox"
        className="checkbox"
        onChange={this.onSelectAllFeeds.bind(this)}
        checked={this.props.selectedFeedIds.length && (this.props.selectedFeedIds.length === this.props.feeds.length)}
      />
      const pageCount = Math.ceil(this.props.totalCount/30)
    return (
      <PaginatedTable
        id="browse-sources-table"
        data={this.props.feeds}
        sort={{ column: this.props.sortField, direction: this.props.sortDirection }}
        pageCount={pageCount}
        currentPage={this.props.currentPage}
        tab="Sources"
        ignoreSort={this.props.ignoreSort}
        onPageChange={(page) => this.props.onPageChange(page)}
        onSortChange={(sortOptions) => this.props.onSortChange(sortOptions)}
      >
        <Table.Column
          name={COLUMNS.CHECKBOX}
          baseWidth={45}
          growRatio={0}
          shrinkRatio={0}
          label=""
          cellContents={feed =>
            <input
              type="checkbox"
              className="checkbox"
              onChange={this.onFeedSelectStateChange.bind(this, feed.id)}
              checked={this.props.selectedFeedIds.includes(feed.id)}
            />
          }
        />

        <Table.Column
          name={COLUMNS.NAME}
          label="Source Name"
          isSortable
          growRatio={2}
        />

        <Table.Column
          name={COLUMNS.REQUIRES_SUBSCRIPTION}
          label="May Require Subscription"
          isSortable
          baseWidth={110}
          maxWidth={180}
          growRatio={1}
          shrinkRatio={0}
          cellContents={feed =>
            feed.isSubscriptionRequired ? <i className="checkmark" /> : null
          }
        />
      
      </PaginatedTable>
    )
  }

  // Event handlers

  onFeedSelectStateChange = (dataID, event) => {
    this.props.onFeedSelectStateChange(dataID, event.target.checked)
  }

  onSelectAllFeeds = (event) =>{
    if (event.target.checked) {
      this.props.onFeedsSelectAll()
    }
    else {
      this.props.onFeedsUnselectAll()
    }
  }

}
