import Model from 'app/framework/Model'
import { field, foreignKey } from 'app/framework/Model/fields'

import Firm from './Firm'


export default class Team extends Model {
  static entityKey = 'teams'

  static fields = {
    name: field({required: false}),
    firm: foreignKey(Firm, {required: false}),
  }
}
