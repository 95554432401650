import React from 'react'

import InputBlock from 'app/common/InputBlock'

import BrowseSectionChecklist from './BrowseSectionChecklist'
import {
  BROWSE_SECTIONS,
} from './constants'
import {
  getBrowseSections,
} from './utils'


/**
 * `My Categories` (currentBrowseSection = BROWSE_SECTIONS.SEARCHES)
 * - categories list contains ss categories (company, topic, etc.)
 * - checklist contains ss's
 * - when all are selected individually, we do not switch to the `all` option, since that includes all
 *   future options (becomes a special filter group)
 *
 * `Firm Source Labels`
 * - categories list contains the Firm Source Label name
 * - checklist contains feeds (disabled - only the FSL itself can be selected)
 *
 * All Others (news, geo, etc.)
 * - category list contains actual ss's (All Canada Sources, etc.)
 * - when all are selected individually, we switch to the 'all' option.
 */
export default function BrowseSectionContent({
  search,
  currentFirmLibraryName,
  currentBrowseSection,
  currentBrowseSubsectionData,
  isCurrentBrowseSubsectionShown,
  browseSectionListFilterValue,
  getBrowseSectionOptions,
  browseSubsectionChange,
  setBrowseSectionListFilterValue,
  showBrowseSectionCategoryList,
  isFirmSourceLabelSelected,
  isFilterSelected,
  handleBrowseFilterSelection,
  getSearchesFromQuery,
}) {
  const categoryList = getBrowseSectionOptions().map(option =>
    <div
      key={`browse-section-content-category-${option.value}`}
      className="browse-section-content-category-list-item"
      onClick={() => browseSubsectionChange(option)}
    >
      <div className="left">{option.label}</div>
      <div className="right">&nbsp;</div>
      <br className="clear-both"/>
    </div>
  )

  const browseSections = getBrowseSections(currentFirmLibraryName)
  const header = browseSections[currentBrowseSection].label
  const longDescription = browseSections[currentBrowseSection].longDescription &&
    <p>{browseSections[currentBrowseSection].longDescription}</p>
  const categoryLabel = currentBrowseSubsectionData ? currentBrowseSubsectionData.label : null

  const categories = isCurrentBrowseSubsectionShown &&
    <div>
      <div>Select a subcategory to browse filters for that subcategory.</div>
      <div className="browse-section-content-category-list">{categoryList}</div>
    </div>

  const filterContent =
    currentBrowseSection !== BROWSE_SECTIONS.FIRM_SOURCE_LABELS &&
    currentBrowseSubsectionData &&
    !['court', 'state'].includes(currentBrowseSubsectionData.value) &&
    <React.Fragment>
      <p>Use the checkboxes to select one or more filters.</p>
      <InputBlock label="Filter by Name">
        <div className="input-container">
          <input
            className="input"
            value={browseSectionListFilterValue}
            onChange={(event) => setBrowseSectionListFilterValue(event.target.value)}
          />
        </div>
      </InputBlock>
    </React.Fragment>

  const checklist = !isCurrentBrowseSubsectionShown &&
    <div>
      <p>
        <a onClick={() => showBrowseSectionCategoryList()}>&lt;&lt;Back to Category List</a>
      </p>

      <h4>{categoryLabel}</h4>

      {filterContent}

      <BrowseSectionChecklist
        search={search}
        currentBrowseSection={currentBrowseSection}
        currentBrowseSubsectionData={currentBrowseSubsectionData}
        browseSectionListFilterValue={browseSectionListFilterValue}
        isFirmSourceLabelSelected={(id) => isFirmSourceLabelSelected(id)}
        isFilterSelected={(ssId) => isFilterSelected(ssId)}
        handleBrowseFilterSelection={
          (value, checked, isAll) => handleBrowseFilterSelection(value, checked, isAll)
        }
        getSearchesFromQuery={(query) => getSearchesFromQuery(query)}
      />
    </div>

  return (
    <div>
      {longDescription}
      <h4 className="section-header">{header}</h4>
      {categories}
      {checklist}
    </div>
  )
}
