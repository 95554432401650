import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

const HighlightedText = ({ text = '', highlight = '', className='' }) => {
    if (!highlight?.trim()) {
        return <span>{text}</span>
    }
    const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi')
    const parts = text.split(regex)
    return (
        <span>
            {parts.filter(part => part).map((part, i) => (
                regex.test(part) ? <mark className={className} key={i}>{part}</mark> : <span key={i}>{part}</span>
            ))}
        </span>
    )
}

HighlightedText.propTypes = {
    text: PropTypes.string,
    highlight: PropTypes.string,
    className: PropTypes.string
}

export default HighlightedText
