import {invertObj} from "ramda"

import {Feed} from 'app/models'

export const STATE_PROP_NAME = 'searchResultsPage'

export const DEFAULT_TIME_FRAME = '2w'
export const RESULTS_PER_PAGE = 20

export const PUBLICATION_TABS_ORDERED = [
  Feed.PUBLICATION_TYPE_CATEGORIES.NEWS,
  Feed.PUBLICATION_TYPE_CATEGORIES.FILINGS,
  Feed.PUBLICATION_TYPE_CATEGORIES.EVENTS,
  Feed.PUBLICATION_TYPE_CATEGORIES.TWITTER,
  Feed.PUBLICATION_TYPE_CATEGORIES.MY_SOURCES,
]

export const SORT_OPTIONS = {
  DATE_DESC: 'date-desc',
  DATE_ASC: 'date-asc',
  RELEVANCE: 'relevance',
}

export const SERVER_SORT_OPTIONS = {
  'date-desc': 'date_desc',
  'date-asc': 'date_asc',
  'relevance': 'relevance',
}

export const REVERSE_SORT_OPTIONS = invertObj(SERVER_SORT_OPTIONS)

export const RELEVANCY_LEVELS = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
}

export const GROUPING_LEVELS = {
  LIMITED: 'limited',
  LESS: 'less',
  STANDARD: 'standard',
  MORE: 'more',
}

export const QUERY_PARAM_NAMES = {
  RELEVANCE: 'relevance',
  GROUPING: 'grouping',
  SORT: 'sort',
  INSIGHTS_FIRST: 'insights-first',
  TIME_FRAME: 'time-frame',
  TAB: 'tab',
  PUB_TYPE: 'pub-type',
  APPLIED_FILTER_KEY: 'filter-key',
  TERM_FREQUENCY_KEY: 'term-freq',
  QUERY: 'query',
  QUERY_TYPE: 'query-type',
  NAME: 'name',
  UPCOMING_EVENTS: 'upcoming-events',
  COMPARE_ID: 'compare-id',
  LANGUAGE: 'language',
  RESULTS_PER_PAGE: 'results-per-page',
}

export const TIME_FRAMES = [
  '24h',
  '1w',
  '2w',
  '1m',
  '3m',
  '6m',
]
export const TIME_FRAME_SEPARATOR = ';'

export const TRUSTED_SOURCE_CATEGORY_TYPES = ['trusted-uncategorized', 'trusted']

// The sections of the filters bar.
export const FILTER_SECTIONS = {
  TIME_RANGE: 'time-range',
  SORT_BY: 'sort-by',
  RELEVANCE: 'relevance',
  GROUPING: 'grouping',
  LANGUAGE: 'language',
  QUICK_FILTERS: 'quick-filters',
  RESULTS_PER_PAGE: 'results-per-page',
}

export const RSS_QUERY_PARAMS = {
  'filter-key': 'fkey',
  'relevance': 'relevancy_level',
  'relevancy-level': 'relevancy_level',
  'tab': 'facet',
  'time-frame': 'duration',
  'usrc': 'exclude_param',
  'usrc_s': 'exclude_param',
}

export const RSS_FACETS = {
  'filings': 'filing',
  'charts': 'news',
  'events': 'event'
}

// The possible states of the "select all" dropdown.
export const SELECT_ALL_STATES = {
  NONE: null,
  TOP_LEVEL: 'top-level',
  INCLUDING_GROUPED: 'including-grouped',
}

// export const ESG_GLOBAL_FILTER_ID = document

export const NUMBER_OF_SEARCH_RESULTS = {
  TWENTY: 20,
  SIXTY: 60,
  HUNDRED: 100,
}
