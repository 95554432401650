import React, { Component } from 'react'
import PropTypes from "prop-types"
import {Provider} from "react-redux"
import store from "../../store"
import ErrorBoundary from "../ErrorBoundary"


export default class AppWrapper extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    store: PropTypes.object,
  }
  render() {
    const theStore = this.props.store
      ? this.props.store
      : store
    return (
      <Provider store={theStore}>
        <ErrorBoundary>{this.props.children}</ErrorBoundary>
      </Provider>
    )
  }
}
