import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import classNames from 'classnames'
import * as R from 'ramda'
import {getEntities} from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import {Feed} from 'app/models'
import Table from 'app/common/Table'
import Button from 'app/common/Button'
import InputBlock from 'app/common/InputBlock'
import TextBox from 'app/common/TextBox'
import Dropdown from 'app/common/Dropdown'
import PaginatedTable from 'app/common/PaginatedTable'
import ConfirmationModal from 'app/common/modals/ConfirmationModal'
import * as styles from './LabelsContent.less'
import {
  hideEditModal,
  updateLabels,
  updateLabelModalData,
  setManageLabelsFilters,
} from '../sources-admin-actions'
import {getSourcesAdmin} from '../sources-admin-selectors'
import {
  LABEL_NAMES,
  LABEL_DESCRIPTION,
  MANAGE_LABELS_SECTION,
  PAGINATION_OPTIONS,
  MANAGE_LABELS_ACTIONS
} from '../sources-admin-constants'
import snakeCase from 'snake-case'

@connect(
  createSelector([getEntities, getSourcesAdmin], (entities, sourcesAdmin) => {
    const orm = Orm.withEntities(entities)
    return {
      editModalData: sourcesAdmin.editModalData,
      feed: orm.getById(Feed, sourcesAdmin.editModalData.feedId),
      labels: sourcesAdmin.labels,
      manageLabelsFilter: sourcesAdmin.manageLabelsFilter,
      manageLabelsTotalCount: sourcesAdmin.manageLabelsTotalCount,
      isLoading: sourcesAdmin.isLoading,
    }
  }),
  {
    updateLabels,
    updateLabelModalData,
    hideEditModal,
    setManageLabelsFilters,
  },
)
export default class LabelsContent extends Component {
  state = {
    selectedIds: this.props.feed.labels.map(i => i.id),
    deletedIds: [],
    confirmModal: {status: false, label: null},
    saveconfirmModal: {status: false, labelId: null, labelName: null},
    allLabels: [],
    selectedLabels: this.props.feed.labels,
    manageLabelsFilters: {
      currentPage: 1,
      itemsPerPage: 10,
      searchLabel: '',
    },
    expandItems: [],
  }

  render() {
    const pageCount = Math.ceil(
      this.props.manageLabelsTotalCount /
        this.props.manageLabelsFilter.itemsPerPage,
    )
    const paginationOptions = Object.entries(PAGINATION_OPTIONS).map(item => {
      const [value, label] = item
      return {
        label,
        value,
      }
    })
    const saveConfirmationModal = this.state.saveconfirmModal.status && (
      <ConfirmationModal
        message={`Are you sure you want to save selected labels for this Source?`}
        confirmButtonText="Yes"
        cancelButtonText="No"
        onConfirm={this.handleSaveConfirm}
        onClose={this.handleSaveCancel}
        isDestructive={true}
      />
    )

    const removeCheckConfirmationModal = this.state.confirmModal.status && (
      <ConfirmationModal
        message={`Are you sure you want to remove ${this.state.confirmModal.label.name} label from this Source?`}
        confirmButtonText="Remove"
        onConfirm={() =>
          this.handleConfirmDeleteSelectedButtonClick(
            this.state.confirmModal.label,
          )
        }
        onClose={() =>
          this.setState({
            confirmModal: {status: false, label: null},
          })
        }
        isDestructive={true}
      />
    )

    const Chip = ({text, onCancel}) => {
      return (
        <div className={styles.chip}>
          <div>{text}</div>
          <div className={styles.crossIcon} onClick={onCancel} />
        </div>
      )
    }

    const ChipContainer = (
      <div className={styles.chipContainer}>
        {this.state.selectedLabels && this.state.selectedLabels.length > 0 ? (
          <>
            {this.state.selectedLabels?.map(label => {
              return (
                <Chip
                  key={`chip-${label.id}`}
                  text={`${label.name}`}
                  onCancel={evt => this.handleCrossIcon(label)}
                />
              )
            })}
          </>
        ) : null}
      </div>
    )

    return (
      <div>
        <React.Fragment>
          <div className={styles.bulkActions}>
            <div className={styles.labelContainer}>
              <div className={styles.labelHeader}>
                {LABEL_NAMES.ASSIGNED_LABELS}
              </div>
              <div className={styles.labelDesc}>
                {LABEL_DESCRIPTION.ASSIGNED_LABELS}
              </div>
            </div>
            {ChipContainer}
            <div className={styles.labelContainer}>
              <div className={styles.labelHeader}>{LABEL_NAMES.ALL_LABELS}</div>
              <div className={styles.labelDesc}>
                {LABEL_DESCRIPTION.ALL_LABELS}
              </div>
            </div>
          </div>
          <div className={styles.filterContainer}>
            <InputBlock label="Filter by Label">
              <TextBox
                onChange={evt =>
                  this.handleFilterChange('searchLabel', evt.target.value)
                }
              />
            </InputBlock>
            <div className={styles.pageSize}>
            <span>{MANAGE_LABELS_ACTIONS.SHOW}</span>
              <Dropdown
                className={styles.pageSizeDropdown}
                type={'text'}
                value={
                  PAGINATION_OPTIONS[this.props.manageLabelsFilter.itemsPerPage]
                }
                options={paginationOptions}
                onChange={size => this.handleOnPageSizeChange(size)}
              />
            </div>
          </div>
          <PaginatedTable
            id="edit-labels-table"
            defaultSort={{column: 'name', direction: 'asc'}}
            data={this.sortedLabels() || []}
            pageCount={pageCount}
            tab="Sources"
            currentPage={this.props.manageLabelsFilter.currentPage}
            onPageChange={page => this.onPageChange(page)}
            onSortChange={(sortOptions) => this.onSortChange(sortOptions)}
            getRowStyle={label => this.borderStyle(label)}
          >
            <Table.Column
              name="cell"
              baseWidth="5%"
              cellContents={label => {
                return (
                  <div
                    className={classNames({
                      [styles.arrowRight]:
                        !label.parent &&
                        !label.isExpand &&
                        label.childLabels &&
                        label.childLabels.length > 0,
                      [styles.arrowDown]: !label.parent && label.isExpand,
                    })}
                    onClick={() => this.handleColumnExpand(label)}
                  ></div>
                )
              }}
            />
            <Table.Column
              label="Name"
              name="name"
              baseWidth="95%"
              isSortable
              cellContents={label => {
                return (
                  <div
                    className={classNames(styles.cellContent, {
                      [styles.childLabelName]: label.parent,
                    })}
                  >
                    <input
                      type="checkbox"
                      checked={this.state.selectedIds.includes(label.id)}
                      onChange={event =>
                        this.handleItemCheckbox(label, event.target.checked)
                      }
                    />
                    <div>{label.name}</div>
                  </div>
                )
              }}
            />
          </PaginatedTable>
          {!this.props.isLoading && this.sortedLabels().length === 0 ? (
          <div className={styles.noData}>{MANAGE_LABELS_SECTION.NO_DATA}</div>
        ) : (
          ''
        )}
          <div className={styles.btnContainer}>
            <Button
              label={MANAGE_LABELS_ACTIONS.CANCEL}
              isGrey
              className={styles.cancelBtn}
              onClick={this.props.hideEditModal}
            />
            <Button
              label={MANAGE_LABELS_ACTIONS.SAVE}
              className={styles.saveBtn}
              onClick={this.handleSaveModal}
            />
          </div>
        </React.Fragment>
        {saveConfirmationModal}
        {removeCheckConfirmationModal}
        
      </div>
    )
  }

  sortedLabels() {
    const updatedLabels = []
    if (this.props.labels === null) {
      return updatedLabels
    }
    this.props.labels.forEach(label => {
      if (this.state.expandItems.includes(label.id)) {
        updatedLabels.push({...label, isExpand: true})
        var lastChild = label.childLabels.length - 1
        label.childLabels.forEach((childLabel, index) => {
          if (lastChild === index) {
            updatedLabels.push({...childLabel, isLastChild: true})
          } else {
            updatedLabels.push({...childLabel, isLastChild: false})
          }
        })
      } else {
        updatedLabels.push({...label, isExpand: false})
      }
    })
    return updatedLabels
  }

  borderStyle(label) {
    if (!label.parent && label.isExpand) {
      return 'borderBottomStyle'
    } else if (label.parent && !label.isLastChild) {
      return 'borderBottomStyle'
    } else {
      return ''
    }
  }

  onPageChange(page) {
    const manageLabelsFilters = {
      ...this.state.manageLabelsFilters,
      currentPage: page,
    }
    this.setState({expandItems: []}, () => {
      this.props.setManageLabelsFilters(manageLabelsFilters)
    })
  }

  onSortChange(sortOptions) {
    const field = snakeCase(sortOptions.column)
    this.props.setManageLabelsFilters({ sortField: field, sortDirection: sortOptions.direction })
  }

  handleCrossIcon(label) {
    this.setState({
      confirmModal: {status: true, label: label},
    })
  }

  handleOnPageSizeChange = size => {
    const count = Number(size)
    const manageLabelsFilters = {
      ...this.state.manageLabelsFilters,
      currentPage: 1,
      itemsPerPage: count,
    }
    this.setState({expandItems: []}, () => {
      this.props.setManageLabelsFilters(manageLabelsFilters)
    })
  }

  handleColumnExpand(label) {
    const expandState = this.state.expandItems.includes(label.id)
    if (expandState) {
      const collapseState = this.state.expandItems.filter(
        item => item !== label.id,
      )
      this.setState({expandItems: [...collapseState]})
    } else if (!expandState && label.childLabels.length !== 0) {
      this.setState({expandItems: [...this.state.expandItems, label.id]})
    }
  }

  handleFilterChange = (key,value) => {
    const manageLabelsFilters = { ...this.state.manageLabelsFilters, currentPage: 1, [key]: value }
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(function () {
      if (value.length >= 1) {
        this.setState({ manageLabelsFilters,expandItems: []})
      } else if (value.length === 0) {
        this.setState({ manageLabelsFilters,expandItems: [] })
      }
      this.props.setManageLabelsFilters(manageLabelsFilters)
    }.bind(this), 400);
  }

  handleConfirmDeleteSelectedButtonClick(label) {
    let selectedIds = this.state.selectedIds.filter(id => id !== label.id)
    let selectedLabels = this.state.selectedLabels.filter(
      item => item.id !== label.id,
    )
    let deletedIds = [...this.state.deletedIds]
    deletedIds.push(label.id)
    this.setState({selectedIds, selectedLabels, deletedIds, confirmModal: {status: false, label: null}})
  }

  handleItemCheckbox(label, checked) {
    let selectedIds = [...this.state.selectedIds]
    let selectedLabels = [...this.state.selectedLabels]
    if (checked) {
      selectedIds.push(label.id)
      selectedLabels.push(label)
      this.setState({selectedLabels})
    } else {
      let deletedIds = [...this.state.deletedIds]
      deletedIds.push(label.id)
      this.setState({deletedIds})
      selectedIds = selectedIds.filter(id => id !== label.id)
      selectedLabels = selectedLabels.filter(item => item.id !== label.id)
    }
    this.setState({selectedIds, selectedLabels})
  }

  handleSaveModal = () => {
    this.setState({
      saveconfirmModal: {status: true, labelId: null, labelName: null},
    })
  }

  handleSaveConfirm = () => {
    let addIds = [...this.state.selectedIds]
    const preselectedIds = this.props.feed.labels.map(i => i.id)
    addIds = addIds.filter(id => !preselectedIds.includes(id));
    let deleteIds = [...this.state.deletedIds]
    deleteIds = deleteIds.filter(id => preselectedIds.includes(id));
    let feedIds = []
    feedIds.push(this.props.feed.id)
    this.props.updateLabels({addIds, deleteIds, feedIds})
    this.setState({
      saveconfirmModal: {status: false, labelId: null, labelName: null},
    })
  }

  handleSaveCancel = () => {
    this.setState({
      saveconfirmModal: {status: false, labelId: null, labelName: null},
    })
  }
}
