import React, {useState} from 'react'

import Button from 'app/common/Button'
import Dropdown from 'app/common/Dropdown'
import InputBlock from 'app/common/InputBlock'
import Table from 'app/common/Table'
import AlertFrequencyDropdown from 'app/reusable/AlertFrequencyDropdown'
import AlertMaxItemsDropdown from 'app/reusable/AlertMaxItemsDropdown'

import styles from './NewSearchModal.less'

const contentTypeTableData = [
  {
    name: 'Articles',
    id: 'defaultPubTypes',
  },
  {
    name: 'Filings',
    id: 'allFilings',
  },
  {
    name: 'Tweets',
    id: 'twitter',
  },
  {
    name: 'Events',
    id: 'event',
  },
]

const EMAIL_SETTINGS_TYPE = {
  DEFAULT: 'default',
  CUSTOM: 'custom',
}


export default function NewSearchModalEmailAlerts({
  isSaving,
  defaultFrequency,
  defaultMaxItemsByContentType,
  onSubmit,
  onBack,
}) {
  const [alertFrequency, setAlertFrequency] = useState(defaultFrequency)
  const [
    maxItemsByContentType,
    setMaxItemsByContentType,
  ] = useState(defaultMaxItemsByContentType)
  const setMaxItemsForContentType = (contentType, value) => {
    setMaxItemsByContentType(maxItems => ({...maxItems, [contentType]: value}))
  }
  const [sendAlone, setSendAlone] = useState(false)
  const [emailSettingsType, setEmailSettingsType] = useState(EMAIL_SETTINGS_TYPE.DEFAULT)

  const handleSubmit = event => {
    event.preventDefault()
    if (emailSettingsType === EMAIL_SETTINGS_TYPE.CUSTOM) {
      onSubmit({
        alertFrequency,
        maxItemsByContentType,
        sendAlone,
      })
    } else {
      onSubmit({useDefault: true})
    }
  }
  return (
    <form onSubmit={handleSubmit} className={styles.container}>
      <div>
        <InputBlock isInline label="Set Email Settings based upon the User Defaults">
          <input
            type="radio"
            value={EMAIL_SETTINGS_TYPE.DEFAULT}
            checked={emailSettingsType === EMAIL_SETTINGS_TYPE.DEFAULT}
            onChange={() => setEmailSettingsType(EMAIL_SETTINGS_TYPE.DEFAULT)}
          />
        </InputBlock>

        <InputBlock isInline label="Choose Email Notification Settings">
          <input
            type="radio"
            value={EMAIL_SETTINGS_TYPE.CUSTOM}
            checked={emailSettingsType === EMAIL_SETTINGS_TYPE.CUSTOM}
            onChange={() => setEmailSettingsType(EMAIL_SETTINGS_TYPE.CUSTOM)}
          />
        </InputBlock>
      </div>

      <div className={styles.emailAlerts}>
      {
        emailSettingsType === EMAIL_SETTINGS_TYPE.CUSTOM &&
        <React.Fragment>
          <InputBlock
            label="Alert Frequency"
            description="The timing for which you would like this search to appear in your Base Email Alert."
            className={styles.block}
          >
            <AlertFrequencyDropdown
              defaultValue={defaultFrequency}
              onChange={setAlertFrequency}
            />
          </InputBlock>

          <InputBlock
            label="Alert Content Types"
            description="Maximum number of items to appear in your Base Email Alert for this search."
            wrapperElement="div"
            className={styles.block}
          >
            <Table data={contentTypeTableData} className={styles.maxItemsTable}>
              <Table.Column
                name="checkbox"
                baseWidth={'10%'}
                growRatio={0}
                shrinkRatio={0}
                cellContents={contentType =>
                  <input
                    type="checkbox"
                    onChange={evt =>
                      setMaxItemsForContentType(
                        contentType.id,
                        evt.target.checked ? 5 : 0,
                      )
                    }
                    checked={!!maxItemsByContentType[contentType.id]}
                  />
                }
              />

              <Table.Column
                name="name"
                label="Content Type"
                baseWidth={'45%'}
                growRatio={0}
                shrinkRatio={0}
                cellContents={contentType => contentType.name}
              />

              <Table.Column
                name="max-items"
                label="Quantity"
                baseWidth={'45%'}
                growRatio={1}
                shrinkRatio={1}
                cellContents={contentType => {
                  const maxItems = maxItemsByContentType[contentType.id]
                  return (
                    maxItems
                      ? (
                        <AlertMaxItemsDropdown
                          value={maxItems}
                          onChange={value =>
                            setMaxItemsForContentType(contentType.id, value)
                          }
                          hasZeroOption={false}
                          className={styles.dropdown}
                        />
                      )
                      : (
                        <Dropdown
                          options={[{value: null, label: '--'}]}
                          disabled={true}
                          className={styles.dropdown}
                        />
                      )
                  )
                }}
                className={styles.dropdownCell}
              />
            </Table>
          </InputBlock>

          <InputBlock
            label="Deliver this search in a separate email alert"
            isInline
            className={styles.input}
          >
            <input
              type="checkbox"
              defaultChecked={false}
              onChange={event => setSendAlone(event.target.checked)}
            />
          </InputBlock>
        </React.Fragment>
      }
      </div>

      <div className={styles.buttons}>
        <Button
          label="Back"
          onClick={() => onBack()}
          disabled={isSaving}
          isPlainText={true}
        />
        <Button
          label={isSaving ? 'Saving...' : 'Save'}
          disabled={isSaving}
          isPrimary={true}
        />
      </div>
    </form>
  )
}
