import {graphqlQuery} from 'app/api'
import {args, gql} from 'app/graphql'
import { apiCall } from 'app/api'
import * as urls from 'app/urls'

function makeApiCall(url, method, data, callback) {
  return apiCall({
    url: url,
    method: method,
    data,
    callback,
    options: {dataFormat: 'json', useCsrfCookie: true},
  })
}

export const userFields = () => {
  return (`
    id
    firmId
    username
    email
    firstName
    lastName
    role
    phone
    timezone
    groupNotes
    locationId
    location {
      name
    }
    firmLocationId
    firmLocationName
    firmDepartmentName
    firmTeamName
    isActive
    isStaff
    isFirmAdmin
    isPortalGroup
    isPrivateGroup
    hasPublishAccess
    hasPublishV3Access
    publishAccessForNonadmins
    hasInsights
    includeWithGroupSignup
    sendEmailToGroupAddress
    authToken
    groupingLevelDefault
    isFirmLibraryGroup
    nextRefreshEmailAt
    refreshCompletedAt
    lastRefreshAt
    refreshInterval
    showSignupWizard
    receiveMonthlyRefreshSummary
    templatePubCount
    recurringPubCount
    nlaProfile
    restrictNlaFeeds
  `)
}

export const groupFields = () => {
  return (`
    ${userFields()}
    emailNotificationCount
  `)
}

export function fetchUser(id) {
  return graphqlQuery(
    gql`
      query {
        user(id: ${id}) {
          ${userFields}
          fullName
          selectedDeliveryOptions
          selectedPrefix
          selectedSuffix
          nlaProfile
          restrictNlaFeeds
          emailDeliveryToGroupMembers
          categoryOrderToGroupMembers
          categoryDefaultToGroupMembers
          deliveryTimeToGroupMembers
          deliveryDayToGroupMembers
          duplicateArticleToGroupMembers
          searchesAlphabeticallyToGroupMembers
          quickFilterOptions {
            id
            label
          }
          baseEmailSettings {
            notificationType
            frequencies
            useAlternateEmailTemplate
            categoryDefaults {
              id
              noticeOrder
              filterBy
              category
              noticeFreq
              noticeMaxStories
              twitterMaxItems
              eventMaxItems
              filingMaxItems
              spikeType
              spikeSendWhen
              updatedAt
              user
            }
            settings {
              noticeFrequency
              emailSchedule
              emailDeliveryWeekday
              emailSendZeroResults
              showCcEmails
              hideDuplicateArticles
              summaryTextLength
              alphabeticalOrder
              mergeInsightsIntoBase
              deliveryTimeToGroupMembers
              deliveryDayToGroupMembers
              duplicateArticleToGroupMembers
              searchesAlphabeticallyToGroupMembers
            }
          }
          groupMemberships {
            id
            user {
              ${userFields}
            }
            group {
              ${userFields}
            }
            isManager
          }
          userMemberships {
            id
            user {
              ${userFields}
            }
            group {
              ${userFields}
            }
            isManager
          }
          departments {
            id
            name
          }
          teams {
            id
            name
          }
          isFirmGroup
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchUsers({page, sortDirection, itemsPerPage, name, groupId, firmLocationId, departmentId, teamId, isActive, isFirmAdmin} = {}) {
  return graphqlQuery(
    gql`
      query {
        users: users(${args({page, sortDirection, itemsPerPage, name, groupId, firmLocationId, departmentId, teamId, isActive, isFirmAdmin})}) {
          items{
            ${userFields}
          }
          isNlaFirm
          totalCount
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchAllUsers({isPaged=false, name, groupId, firmLocationId, departmentId, teamId, isActive, isFirmAdmin} = {}) {
  return graphqlQuery(
    gql`
      query {
        users: users(${args({isPaged, name, groupId, firmLocationId, departmentId, teamId, isActive, isFirmAdmin})}) {
          items {
            ${userFields}
          }
          totalCount
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchGroups({page, sortDirection, sortField, itemsPerPage, firmLocationId, locationId, name, includeFirmwideGroup, isActive, isFirmAdmin, isPrivateGroup} = {}) {
  return graphqlQuery(
    gql`
      query {
        groups: groups(${args({page, sortDirection, sortField, itemsPerPage, firmLocationId, locationId, name, includeFirmwideGroup, isActive, isFirmAdmin, isPrivateGroup})}) {
          items{
            ${groupFields}
          }
          totalCount
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchAllGroups({isPaged=false, name, includeFirmwideGroup, isActive, isFirmAdmin} = {}) {
  return graphqlQuery(
    gql`
      query {
        groups: groups(${args({isPaged, name, includeFirmwideGroup, isActive, isFirmAdmin})}) {
          items{
            ${userFields}
          }
          totalCount
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function createUser(
  firstName,
  lastName,
  emailAddress,
  shouldSendWelcomeEmail,
) {
  return graphqlQuery(
    gql`
      mutation {
        user: createUser(
          firstName: ${firstName},
          lastName: ${lastName},
          emailAddress: ${emailAddress},
          shouldSendWelcomeEmail: ${shouldSendWelcomeEmail}
        ) {
          ${userFields}
          newUserPassword
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function createGroup(
  name
) {
  return graphqlQuery(
    gql`
      mutation {
        group: createGroup(
          name: ${name},
        ) {
          ${userFields}
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function saveUser(data) {
  return graphqlQuery(
    gql`
      mutation {
        user: saveUser(${args(data)}) {
          ${userFields}
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function deleteUsers(ids) {
  return graphqlQuery(
    ids.map(id =>
      gql`
        mutation {
          deleteUser(id: ${id})
        }
      `
    )
  ).then(({body: {data}}) => data)
}

export function fetchSearches(ownerId) {
  return graphqlQuery(
    gql`
      query {
        searches: savedSearches(ownerId: ${ownerId}, topLevelOnly: true) {
          id
          name
          scope
          category
          categoryDisplay
          
          noticeConfig {
            maxItems {
              defaultPubTypes
              allFilings
              twitter
              event
            }
            frequency
            frequencyDisplay
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function saveSearch(data) {
  return graphqlQuery(
    gql`
      mutation {
        search: saveSearch(${args(data)}) {
          # We don't need a return value, but an empty selection set is invalid.
          id
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function createSearch({userId, searchId = undefined, searchPhrase = undefined} = {}) {
  return graphqlQuery(
    gql`
      mutation {
        search: saveSearch(${args({userId, id: searchId, isMakeChild: !!searchId, searchPhrase})})
      }
    `
  ).then(({body: {data}}) => data)
}

export function createSource(userId, feedId) {
  return graphqlQuery(
    gql`
      mutation {
        search: createSource(userId: ${userId}, feedId: ${feedId}) {
          # We don't need a return value, but an empty selection set is invalid.
          id
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function addUserToGroups(userId, groupIds) {
  const query = groupIds.map(groupId => {
    return (`
      mutation {
        createMembership(
          userId: ${userId},
          groupId: ${groupId},
        ) {
          id
        }
      }
    `)
  }).join('')
  return graphqlQuery(
    query
  ).then(({body: {data}}) => data)
}

export function removeUserFromGroups(userId, groupIds) {
  const query = groupIds.map(groupId => {
    return (`
      mutation {
        deleteMembership(
          userId: ${userId},
          groupId: ${groupId},
        ) {
          success
        }
      }
    `)
  }).join('')
  return graphqlQuery(
    query
  ).then(({body: {data}}) => data)
}

export function addGroupToUsers(groupId, userIds) {
  const query = userIds.map(userId => {
    return (`
      mutation {
        createMembership(
          userId: ${userId},
          groupId: ${groupId},
        ) {
          id
        }
      }
    `)
  }).join('')
  return graphqlQuery(
    query
  ).then(({body: {data}}) => data)
}

export function removeGroupFromUsers(groupId, userIds) {
  const query = userIds.map(userId => {
    return (`
      mutation {
        deleteMembership(
          userId: ${userId},
          groupId: ${groupId},
        ) {
          success
        }
      }
    `)
  }).join('')
  return graphqlQuery(
    query
  ).then(({body: {data}}) => data)
}

export function toggleMembershipIsManager(userId, groupId) {
  return graphqlQuery(
    gql`
      mutation {
        toggleMembershipIsManager(userId: ${userId}, groupId: ${groupId}) {
          success
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function refreshSummarySignup(signup) {
  return graphqlQuery(
    gql`
      mutation {
        user: refreshSummarySignup(signup: ${signup}) {
          ${userFields}
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function bulkPasswordReset(userIds) {
  const url = '/profile/firm/accounts/pwd-reset/'
  const data = {user: userIds}
  return apiCall({url, method: 'POST', data: data,})
}

export function bulkSendWelcomeEmail(userIds) {
  const url = '/profile/firm/accounts/send-welcome/'
  const data = {user: userIds}
  return apiCall({url, method: 'POST', data: data,})
}

export function bulkSendRefreshEmail(userIds) {
  const url = '/profile/firm/accounts/send-refresh/'
  const data = {user: userIds}
  return apiCall({url, method: 'POST', data: data,})
}

export function bulkActivateAccounts(userIds, action) {
  /**
   * the `deactivate` endpoint handles both `activate` and `deactivate` actions
   */
  const url = '/profile/firm/accounts/deactivate/'
  const data = {user: userIds, action}
  return apiCall({url, method: 'POST', data: data,})
}

export function bulkEnableInsights(userIds, action) {
  /**
   * the `enable-signals` endpoint handles both `enable` and `disable` actions
   */
  const url = '/profile/firm/accounts/enable-signals/'
  const data = {user: userIds, action}
  return apiCall({url, method: 'POST', data: data,})
}

export function updateSearches({userId, searchIds, updates}) {
  const query = searchIds.map(searchId => {
    return gql`
      mutation {
        saveSearch(
          id: ${searchId},
          userId: ${userId},
          scope: ${updates.scope},
          noticeConfig: ${updates.noticeConfig},
        ) {
          id
        }
      }
    `
  }).join('')
  return graphqlQuery(
    query
  ).then(({body: {data}}) => data)
}

export function deleteSearches({searchIds}) {
  const query = searchIds.map(searchId => {
    return gql`
      mutation {
        deleteSearch(
          id: ${searchId},
        ) {
          success
        }
      }
    `
  }).join('')
  return graphqlQuery(
    query
  ).then(({body: {data}}) => data)
}

export function memberQuickAdd(userId, email, callback) {
  const url = urls.groupMemberQuickChange()
  const data = {userId, user: email}
  return apiCall({url, method: 'POST', data, callback})
}

export function quickCreateFirmAccount(email, callback) {
  const url = urls.groupMemberQuickCreate()
  const data = {email}
  return apiCall({url, method: 'POST', data, callback})
}

export function makeGroupsPrivate(userIds) {
  const query = userIds.map(userId => {
    return gql`
      mutation {
        saveUser(
          id: ${userId},
          isPrivateGroup: true,
        )
      }
    `
  }).join('')
  return graphqlQuery(
    query
  ).then(({body: {data}}) => data)
}

export function fetchRecurringPublicationsData({ page, itemsPerPage, groupId }) {
  return graphqlQuery(
    gql`
    query {
        recurringPublication(${args({ page, itemsPerPage, groupId })})
        {
          name: recurringPubName
          lastPublishedDate: recurringPubDate
        }
      }
    `,
  ).then(({ body: { data } }) => data)
}


export function fetchTemplatePublicationsData({ page, itemsPerPage, groupId }) {
  return graphqlQuery(
    gql`
    query {
      templatePublication(${args({ page, itemsPerPage, groupId })})
        {
          name: templatePubName
          lastPublishedDate: templatePubDate
        }
      }
    `,
  ).then(({ body: { data } }) => data)
}

export function callSaveEmailDelivery(data, callback) {
  return makeApiCall(urls.emailNotifications.saveMultipleDelivery(),
    'POST', data, callback)
}

export function saveBulkNlaArticleViewingSettings(userIds, action) {
  const url = '/profile/firm/accounts/setting-nla-articles/'
  const data = {user: userIds, action}
  return apiCall({url, method: 'POST', data: data,})
}

export function bulkManageRefreshInterval(userIds, action) {
  const url = '/profile/refresh-interval/'
  const data = {users: userIds, interval: action}
  return apiCall({url, method: 'POST', data: data,})
}

