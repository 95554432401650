import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import SavedSearchFilters from 'app/reusable/SavedSearchFilters'
import InputBlock from 'app/common/InputBlock'
import {
  getCurrentFirmLibraryName,
  getAvailableFilters,
} from 'app/global/global-selectors'
import {getEntities} from 'app/entities/entities-selectors'

import {
  getSearchResults,
  setSaveData,
  setQueryComponentState,
  addNewModalSetStep,
  fetchFilters,
} from '../searches-admin-actions'
import {getSearchesAdmin} from '../searches-admin-selectors'


@connect(
  createSelector(
    [
      getEntities,
      getCurrentFirmLibraryName,
      getAvailableFilters,
      getSearchesAdmin,
    ],
    (
      entities,
      currentFirmLibraryName,
      availableFilters,
      searchesAdmin,
    ) => {
      return {
        currentFirmLibraryName,
        searchResults: searchesAdmin.searchResults,
        isLoading: searchesAdmin.isLoading,
        queryComponentState: searchesAdmin.queryComponentState,
        saveData: searchesAdmin.saveData,
        availableFiltersByCategory: availableFilters,
        firmLibrarySearches: Object.values(entities.searches).filter(search => search.isFirmLibrary),
      }
    }
  ),
  {
    getSearchResults,
    setSaveData,
    setQueryComponentState,
    setStep: addNewModalSetStep,
    fetchFilters,
  },
)
export default class CreateSearchQuery extends Component {
  state = {
    searchId: null,
  }

  componentDidMount() {
    if (!this.props.availableFiltersByCategory) {
      this.props.fetchFilters();
    }
  }

  render() {
    if (!this.props.availableFiltersByCategory) {
      return null
    }

    return (
      <div className="enter-search-details">
        <h2>Step 1: Create Search Query</h2>

        <div className="row">
          <InputBlock label="Normal Search" isInline>
            <input
              type="radio"
              name="query-type"
              checked={this.props.saveData.queryType === 'any'}
              onChange={() => this.setQueryType('any')}
            />
          </InputBlock>

          <InputBlock label="Boolean Search" isInline>
            <input
              type="radio"
              name="query-type"
              checked={this.props.saveData.queryType === 'raw'}
              onChange={() => this.setQueryType('raw')}
            />
          </InputBlock>
        </div>

        <div className="row">
          <SavedSearchFilters
            // setting the key forces SavedSearchFilters to re-mount when the queryType is changed
            key={this.props.saveData.queryType}
            currentFirmLibraryName={this.props.currentFirmLibraryName}
            onUpdateSearchResults={this.props.getSearchResults}
            searchResults={this.props.searchResults}
            isLoading={this.props.isLoading}
            saveData={this.props.saveData}
            setSaveData={this.props.setSaveData}
            queryComponentState={this.props.queryComponentState}
            setQueryComponentState={this.props.setQueryComponentState}
            setStep={this.props.setStep}
          />
        </div>
      </div>
    )
  }

  setQueryType(queryType) {
    this.props.setSaveData({queryType: queryType})
    this.props.setQueryComponentState({
      ...this.props.queryComponentState,
      coreSearchSelectedOptions: [],
      coreSearchValues: [],
      coreSearchRawValue: null
    })
  }
}
