import React, { Component } from 'react'
import { connect } from 'react-redux'
import './CategoryContent.less'
import * as actions from './email-notifications-actions'
import * as constants from './email-notifications-constants'
import InputBlock from 'app/common/InputBlock'
import Dropdown from 'app/common/Dropdown'
import PropTypes from 'prop-types'
import Table from "app/common/Table"
import { titleCase } from "title-case"
import InlineSvg from "app/common/InlineSvg/InlineSvg"
import classNames from "classnames"
import * as globalSelectors from "app/global/global-selectors"


@connect(
  state => ({
    editUserId: state.emailNotifications.emailDeliveryData.editUserId,
    noticeConfigs: state.emailNotifications.insightsNoticeConfigs,
    noticeSettings: state.emailNotifications.insightsSettings,
    appTypeLabel: globalSelectors.getInsightsLabel(state),
  }),
  {
    changeFactorsModalConfig: actions.changeFactorsModalConfig,
    changeShowFactorsModal: actions.changeInsightsShowFactorsModal,
    saveNoticeConfigs: actions.api.saveInsightsNoticeConfigs.request,
    changeShowBulkUpdateModal: actions.changeShowBulkUpdateModal,
  },
)
export default class InsightsCategoryContent extends Component {
  render() {
    return <CategoryContent {...this.props}/>
  }
}

@connect(
  state => ({
    editUserId: state.emailNotifications.emailDeliveryData.editUserId,
    noticeConfigs: state.emailNotifications.esgNoticeConfigs,
    noticeSettings: state.emailNotifications.esgSettings,
    appTypeLabel: 'ESG',
  }),
  {
    changeFactorsModalConfig: actions.changeFactorsModalConfig,
    changeShowFactorsModal: actions.changeEsgShowFactorsModal,
    saveNoticeConfigs: actions.api.saveEsgNoticeConfigs.request,
    changeShowBulkUpdateModal: actions.changeShowBulkUpdateModal,
  },
)
export class EsgCategoryContent extends Component {
  render() {
    return <CategoryContent {...this.props} />
  }
}

export class CategoryContent extends Component {
  static propTypes = {
    noticeFrequency: PropTypes.string.isRequired,
    category: PropTypes.object.isRequired,
    frequencyHeader: PropTypes.string.isRequired,
    noticeSettings: PropTypes.object.isRequired,
    noticeConfigs: PropTypes.object.isRequired,
    saveNoticeConfigs: PropTypes.func.isRequired,
    appTypeLabel: PropTypes.string.isRequired,
  }
  render() {
    const categoryName = this.props.category.categoryName.toLowerCase()
    const availableFactorsCount =
      this.props.noticeConfigs.availableFactors.length

    let defaultExcludeSubFactorNames = []
    if (this.props.appTypeLabel === 'ESG') {
    for (const factor of this.props.noticeConfigs.availableFactors) {
      for (const sub of factor.subFactors) {
        if (!sub.alertsEnabledByDefault
          && sub.displayName !== 'All Topics') {
          defaultExcludeSubFactorNames.push(sub.displayName)
        }
      }
    }
        }
    else
      {for (const factor of this.props.noticeConfigs.availableFactors) {
        for (const sub of factor.subFactors) {
          if (!sub.alertsEnabledByDefault
            && (sub.displayName !== 'All Subfactors')) {
            defaultExcludeSubFactorNames.push(sub.displayName)
          }
        }
      }
    }
    const frequencyOptions = []
    Object.keys(constants.INSIGHTS_EMAIL_FREQUENCIES)
      .filter(key => key !== this.props.noticeSettings.excludeFrequency)
      .forEach(key => {
        frequencyOptions.push({
          key: key,
          label: <span>{constants.INSIGHTS_EMAIL_FREQUENCIES[key]}</span>,
          value: constants.INSIGHTS_EMAIL_FREQUENCIES[key],
        })
      })

    const contentCategory = this.props.noticeConfigs
      ? Object.keys(this.props.noticeConfigs.configs)
        .filter(category => category === categoryName)
        .map((category, catIndex) => {

          const articlesPerFactorColumn = category === constants.CATEGORY_INDUSTRY
            ? <Table.Column
              name="articlesPerFactor"
              label="Number of Articles per Factor"
              baseWidth={130}
              cellContents={config => this.getArticlesPerFactorDropdown(config)}
            />
            : null

          const frequency = Object.keys(
            this.props.noticeConfigs.configs[category])
            .filter(freq => freq === this.props.noticeFrequency)
            .map((freq, freqIndex) => {

              const configs = this.props.noticeConfigs.configs[category][freq]
                ? <Table
                  className={'config-table'}
                  data={this.props.noticeConfigs.configs[category][freq]}
                >
                  <Table.Column
                    name="name"
                    label={`${titleCase(category)} Name`}
                    baseWidth={200}
                    isSortable
                    sortBy={config => {
                      return config.searchName
                    }}
                    cellContents={config => config.searchName}
                  />
                  <Table.Column
                    name="factors"
                    label = { this.props.appTypeLabel === 'ESG'? 'Pillars':"Factors" }
                    baseWidth={475}
                    cellContents={config => this.getFactorsDisplay(
                      config, availableFactorsCount, defaultExcludeSubFactorNames
                    )}
                  />
                  {/*TODO: Change Alerts will be some sort of as it happens alerts, */}
                  {/*  but the details haven't quite been flushed out yet*/}
                  {/*<Table.Column*/}
                  {/*name="changeAlerts"*/}
                  {/*label="Change Alerts"*/}
                  {/*baseWidth={125}*/}
                  {/*isSortable*/}
                  {/*/>*/}
                  <Table.Column
                    name="editIcon"
                    label=""
                    baseWidth={50}
                    cellContents={config =>
                      <div
                        className='insights-action-icon'
                        onClick={() => { this.onEditFactorsClick(config, category) }}
                      >
                        <InlineSvg
                          className='insights-edit-icon'
                          src="/media/img/edit.svg"
                        />
                      </div>
                    }
                  />

                  {/*EN - hide articles per factor dropdown for now, may change
                  to articles per company/industry at some point*/}
                  {/*{ articlesPerFactorColumn }*/}
                  <Table.Column
                    name="frequency"
                    label="Frequency"
                    baseWidth={180}
                    cellContents={config => this.getFrequencyDropdown(
                      config,
                      frequencyOptions
                    )}
                  />
                  <Table.Column
                    name="changeOrder"
                    label="Change Order"
                    baseWidth={150}
                    cellContents={config =>
                      <div className={'order-icons-container'}>
                        <div
                          className='insights-action-icon order-icon margin-left'
                          onClick={() => {
                            this.onOrderChangeClick(config, 'order_down')
                          }}
                        >
                          <InlineSvg
                            className='insights-order-down-icon'
                            src="/media/img/DownArrow.svg"
                          />
                        </div>

                        <div
                          className='insights-action-icon order-icon'
                          onClick={() => {
                            this.onOrderChangeClick(config, 'order_up')
                          }}
                        >
                          <InlineSvg
                            className='insights-order-up-icon'
                            src="/media/img/UpArrow.svg"
                          />
                        </div>
                        <div
                          className={
                            classNames('insights-action-icon margin-left larger',
                              {'disable': this.props.noticeFrequency ===
                                  constants.NONE_FREQ})
                          }
                          onClick={() => { this.onRemoveClick(config) }}
                        >
                          <InlineSvg
                            className='insights-remove-icon'
                            src="/media/img/close-icon.svg"
                          />
                        </div>
                      </div>
                    }
                  />
                </Table>
                : <div className={'freq-no-configs'}>
                  {`You have no ${this.props.appTypeLabel} ${constants.CATEGORIES[category]}
                  set to ${this.props.frequencyHeader}.`}
                </div>

              return (
                <React.Fragment key={freqIndex}>
                  <div className={'configs-container'}>
                    <div className={'category-header'}>
                      {constants.CATEGORIES[category]}
                    </div>
                    {configs}
                  </div>
                </React.Fragment>
              )
            })

          return (
            <div key={catIndex} className={'category-container'}>
              <div className={'frequency-container'}>
                {frequency}
                <a className="bulk-assign-link"
                  onClick={() => { this.onBulkAssignLinkClick(category) }}
                >
                  {`+ Assign ${constants.CATEGORIES[category]} to
                    ${this.props.frequencyHeader}`}
                </a>
              </div>
            </div>
          )

        })
      : null
    return (
      contentCategory
    )
  }
  onBulkAssignLinkClick(category) {
    this.props.changeShowBulkUpdateModal({
      show: true,
      category: category,
      frequency: this.props.noticeFrequency,
      bulkType: 'frequency',
      subfactors: null,
    })
  }
  getFactorsDisplay(config, availableFactorsCount, defaultExcludeSubFactorNames) {
    let excludeSubFactorsClone = Object.assign(
      [], defaultExcludeSubFactorNames)

    let factorNames = new Set()
    let subFactorNames = []

    for (const sub of config.subFactors) {
      const DisplayName= this.props.appTypeLabel === 'ESG' ? sub.pillarDisplayName
      : sub.factorDisplayName
      factorNames.add(DisplayName)
      subFactorNames.push(sub.displayName)
      if (!sub.alertsEnabledByDefault) {
        excludeSubFactorsClone = excludeSubFactorsClone.filter(name =>
          name.trim() !== sub.displayName.trim()
        )
      }
    }

    let displayFactorsList = [...factorNames]
    const configFactorsCount = displayFactorsList.length

    if (configFactorsCount > constants.MAX_FACTORS_TO_DISPLAY) {
      const additionalFactorsCount =
        `and ${String(configFactorsCount - constants.MAX_FACTORS_TO_DISPLAY)} more ...`
      displayFactorsList.splice(constants.MAX_FACTORS_TO_DISPLAY)
      displayFactorsList.push(additionalFactorsCount)
    }

    displayFactorsList = displayFactorsList.join(', ').trim()
    const checkLabel = this.props.appTypeLabel === 'ESG' ? 'Pillars': 'Factors'
    const factors = configFactorsCount === availableFactorsCount
      ? `All ${checkLabel}`
      : configFactorsCount === 0
        ? `No ${checkLabel}`
        : `${checkLabel}: ${displayFactorsList}`

    const configSubFactorsCount = subFactorNames.length

    if (configSubFactorsCount > constants.MAX_SUBFACTORS_TO_DISPLAY) {
      const additionalSubFactorsCount =
        `and ${String(configSubFactorsCount - constants.MAX_SUBFACTORS_TO_DISPLAY)} more ...`
      subFactorNames.splice(constants.MAX_SUBFACTORS_TO_DISPLAY)
      subFactorNames.push(additionalSubFactorsCount)
    }

    let displaySubFactorsList = subFactorNames.join(', ').trim()
    const displayExcludeSubFactorsList = excludeSubFactorsClone
      .join(', ')
    const checkSubLabel = this.props.appTypeLabel === 'ESG' ? 'Topics': 'Subfactors'
    const subFactors = configSubFactorsCount ===
      this.props.noticeConfigs.totalSubFactorsCount
      ? `All ${checkSubLabel}`
      : configSubFactorsCount === 0
        ? `No ${checkSubLabel}`
        : excludeSubFactorsClone.length > 0 && excludeSubFactorsClone.length + configSubFactorsCount
          === this.props.noticeConfigs.totalSubFactorsCount
          ? `All ${checkSubLabel} except: ${displayExcludeSubFactorsList}`
          : `${checkSubLabel}: ${displaySubFactorsList}`

    const displayFactors = `${factors}; ${subFactors}`

    return (
      <div>{displayFactors}</div>
    )
  }
  onEditFactorsClick(config, category) {
    const modalConfig = Object.assign({}, config)
    modalConfig.category = category
    this.props.changeFactorsModalConfig(modalConfig)
    this.props.changeShowFactorsModal(true)
  }
  getFrequencyDropdown(config, frequencyOptions) {
    const activeFrequency =
      constants.INSIGHTS_EMAIL_FREQUENCIES[config.frequency]

    return (
      <InputBlock
        className={'frequency-dropdown'}
        label={''}
      >
        <Dropdown
          type={'text'}
          options={frequencyOptions}
          value={activeFrequency}
          onSelect={this.onSearchSettingSelect.bind(this, config, constants.FREQUENCY)}
        />
      </InputBlock>
    )
  }
  onSearchSettingSelect(config, fieldName, data) {
    this.props.saveNoticeConfigs({
      editUserId: this.props.editUserId,
      type: this.props.noticeSettings.notificationType.toLowerCase(),
      field: fieldName,
      value: data.key,
      configIds: [config.id],
    })
  }
  onOrderChangeClick(config, direction) {
    this.props.saveNoticeConfigs({
      editUserId: this.props.editUserId,
      type: this.props.noticeSettings.notificationType,
      field: direction,
      value: config.order,
      configIds: [config.id],
    })
  }
  onRemoveClick(config) {
    // move to frequency 'none' (Do Not Send)
    if (this.props.noticeFrequency !== constants.NONE_FREQ) {
      this.props.saveNoticeConfigs({
        editUserId: this.props.editUserId,
        type: this.props.noticeSettings.notificationType,
        field: constants.FREQUENCY,
        value: constants.NONE_FREQ,
        configIds: [config.id],
      })
    }
  }
  getArticlesPerFactorDropdown(config) {
    return (
      <InputBlock
        className={'articles-per-factor-dropdown'}
        label={''}
      >
        <Dropdown
          type={'text'}
          options={constants.INSIGHTS_ARTICLES_PER_FACTOR}
          value={config.articlesPerFactor}
          onSelect={this.onSearchSettingSelect.bind(this, config, constants.ARTICLES_PER_FACTOR)}
        />
      </InputBlock>
    )
  }
}
