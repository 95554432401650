/*
 * Functions that build URLs based (potentially) on some arguments.
 * Basically the frontend equivalent of Django's reverse() function.
 */

import is from 'is'

export const withQueryParams = (url, params) => {
  const [urlWithoutQuery, searchString] = url.split('?')
  const urlSearchParams = new URLSearchParams(searchString)
  Object.entries(params).forEach(([key, value]) => {
    if (is.defined(value)) {
      urlSearchParams.set(key, value)
    }
  })
  const queryString = Array.from(urlSearchParams).length
    ? '?' + urlSearchParams.toString()
    : ''
  return urlWithoutQuery + queryString
}

// General pages
export const home = () => '/'
export const profile = () => '/profile/'
export const flagged = () => '/flagged/'
export const publish = () => '/reports/builder/'
export const publish3 = () => '/publish/'
export const emailSettings = () => '/profile/notifications/'

export const adminUsers = () => '/profile/firm/accounts/'
export const adminGroups = () => '/profile/firm/groups/'
export const adminSources = () => '/profile/firm/content/'
export const adminSearches = () => '/profile/firm/searches/'
export const adminSettings = () => '/firmsettings/settings/'
export const adminUsage = () => '/profile/firm/usage/'
export const adminServices = () => '/additionalservices/'


export const editCompanies = () => '/profile/client/'
export const manageGroups = () => '/profile/firm/groups/'
export const help = () => '/help/'
export const logout = () => '/logout/'

export const groupMemberQuickChange = () =>
  '/groups/firm/group/member-quick-change/'
export const groupMemberQuickCreate = () =>
  '/profile/firm/account/create-quick/'

export const firmAdmin = {
  sourcesAdmin: ({initialFeedId: feedId}) =>
    '/profile/firm/content/' + (feedId ? `?initial-feed-id=${feedId}` : ''),
  searchesAdmin: () => '/profile/firm/searches/',
}

export const groupSignup = {
  checkEmail: () => '/group-signup/check-email/',
  signUp: () => '/group-signup/sign-up/',
}

export const reportSignup = {
  checkEmail: () => '/report-signup/check-email/',
  signUp: () => '/report-signup/sign-up/',
}

export const portalResults = {
  refreshResults: () => '/portal-results/refresh-results/',
}

export const dunAndBradstreet = {
  data: () => `/dun-bradstreet/data/`,
  search: () => `/dun-bradstreet/search/`,
  getcompanies: () => "/api/getdandbcompanies",
  exportExcel: (data) =>
    withQueryParams(
      "/api/exportdandbcompanies",
      {excelparms: JSON.stringify(data)},
    ),
}

export const emailNotifications = {
  saveDelivery: () => `/profile/notifications/save-email-delivery/`,
  addPrefixSuffix: () => `/profile/notifications/add-email-prefixsuffix/`,
  applyEmailDeliverySettings: () => `/profile/notifications/add-email-delivery-profiles/`,
  applyCategoryOrdersSettings: () => `/profile/notifications/add-category-orders-profiles/`,
  saveSettings: () => `/profile/notifications/save-email-settings/`,
  getEmailAlertSettings: () => `/profile/notifications/get-email-settings/`,
  getCCEmailsInsights: () => `/profile/notifications/get-cc-emails/`,
  getCCEmailsBase: () => `/profile/notifications/get-cc-emails/`,
  getCCEmailsEsg: () => `/profile/notifications/get-cc-emails/`,
  updateCCEmails: () => `/profile/notifications/update-cc-emails/`,
  getInsightsNoticeConfigs: () => `/profile/notifications/get-notice-configs/`,
  saveInsightsNoticeConfigs: () =>
    `/profile/notifications/save-notice-configs/`,
  getEsgNoticeConfigs: () => `/profile/notifications/get-notice-configs/`,
  saveEsgNoticeConfigs: () => `/profile/notifications/save-notice-configs/`,
  sendSampleEmail: () => `/profile/notifications/send-sample-email/`,
  getBaseNoticeConfigs: () => `/profile/notifications/get-notice-configs/`,
  saveBaseNoticeConfigs: () => `/profile/notifications/save-notice-configs/`,
  updateCategoryOrder: () => `/profile/notifications/update-category-order/`,
  updateCategoryDefaults: () =>
    `/profile/notifications/update-category-defaults/`,
  updateContentSettings: () =>
    `/profile/notifications/update-content-settings/`,
  getNotificationSettings: () =>
    `/profile/notifications/notification-settings-get/`,
  saveNotificationSettings: ({editUserId}) =>
    `/profile/notifications/${editUserId}/notification-settings-post/`,
  saveMultipleDelivery: () => `/profile/notifications/save-multiple-email-delivery/`,
}

export const mis = {
  home: () => '/mis/',
}

export const esg = {
  home: () => '/esg/',
  company: companyId => `/esg/${companyId}`,
}

export const feed = feedId => `/feed_display/${feedId}/`

export const searchRssFeed = ({id, slug, queryParams}) => queryParams
    ? withQueryParams(`/rss/${slug}/${id}/`, queryParams)
    : `/rss/${slug}/${id}/`

export const tier3Saved = ({id, slug, category}) =>
  `/${category}/${slug}/${id}/`
export const tier3Unsaved = ({query, name} = {}) =>
  withQueryParams(`/search/`, {query, name})
export const tier3Shared = ({id} = {}) =>
  withQueryParams(`/search/`, {query: `ss:${id}`})

/**
 * Search category strings that are valid in a tier 3 URL.
 */
export const SAVED_SEARCH_TYPES = [
  'practice',
  'industry',
  'client',
  'prospect',
  'competitor',
  'tracker',
  'trusted-sources',
  // You can only get to these ones from the Django admin:
  'filter',
  'booster',
]
export const tier3Regex = new RegExp(
  `\\/(${SAVED_SEARCH_TYPES.join('|')})\\/(.*?)\\/(\\d+)\\/`,
)
export const feedDisplayRegex = /^\/feed_display\/(\d+)\/$/
export const tier3CategoryFromPathname = pathname => {
  const match = pathname.match(tier3Regex)
  if (!match) {
    return null
  }
  return match[1]
}

export const firmStats = {
  usage: () => '/firmstats/usage/',
  usageTotals: () => '/firmstats/usage_totals/',
  sourceInteractions: () => '/firmstats/content/source-interactions/',
  articleViews: () => '/firmstats/content/views/',
  interactions: () => '/firmstats/interactions/',
  pendoStats: () => '/firmstats/pendo-stats/',
  dailySnapshot: () => '/firmstats/latest_snapshot/',
  sixMonthSnapshot: () => '/firmstats/six_month_snapshot/',
  tierComparison: () => '/firmstats/tier_comparison/',
  tierInteractionComparison: () => '/firmstats/tier_interact_comparison/',
  insightsCompanyEmails: () => '/firmstats/signals_company_emails_firm/',
  insightsSummaryEmails: () => '/firmstats/signals_summary_emails_firm/',
  insightsUserEmails: () => '/firmstats/signals_user_emails/',
}

export const exportArticles = {
  pdf: () => '/article/multiple/export/?usrc=tier3&usrc_s=multiple:export:pdf',
  doc: () => '/article/multiple/export/?usrc=tier3&usrc_s=multiple:export:docx',
  excel: () => '/article/multiple/excel/?usrc=tier3&usrc_s=multiple:export:excel',
  email: () => '/article/multiple/email/?usrc=tier3&usrc_s=multiple:export:email',
}

export const responsiveSubMenuItems = {
  baseGroup: {
    title: "Groups",
    subMenus: [],
    maxSubMenuToShow: 5,
  },
  basePractices: {
    title: "Practices",
    url: "",
    subMenus: [],
    maxSubMenuToShow: 5,
    children: null
  },
  baseIndustries: {
    title: "Industries",
    url: "",
    subMenus: [],
    maxSubMenuToShow: 5,
    children: null
  },
  baseCompanies: {
    title: "Companies",
    url: "",
    subMenus: [],
    maxSubMenuToShow: 5,
    children: null
  },
  baseCompetitors: {
    title: "Competitors",
    url: "",
    subMenus: [],
    maxSubMenuToShow: 5,
    children: null
  },
  baseTopics: {
    title: "Topics",
    url: "",
    subMenus: [],
    maxSubMenuToShow: 5,
    children: null
  },
  baseSources: {
    title: "Sources",
    url: "",
    subMenus: [],
    maxSubMenuToShow: 5,
    children: null
  },
  insights: (url) => ([
    {
      title: "Dashboard",
      url: `${url}dashboard`
    },
    {
      title: "Companies",
      url: `${url}companies`
    },
    {
      title: "Industries",
      url: `${url}industries`
    },
    {
      title: "Litigation Trends",
      url: `${url}litigation`
    }
  ]),
  mis: {
    subMenus: [
      {
        title: "Illuminations",
        url: `${mis.home()}illuminations/`
      },
      {
        title: "Premium Reports",
        url: `${mis.home()}premium-reports/`
      }
    ]
  },
  esg: {
    subMenus: [
      {
        title: "ESG Industry",
        url: `${esg.home()}#esg-industry/`
      },
      {
        title: "ESG Company",
        url: `${esg.home()}#esg-company/`
      }
    ]
  },
  oldPublish: {
    title: "Publish",
    url: publish(),
  },
  newPublish: {
    title: "Publish",
    url: publish3(),
    tagText: "NEW",
  }
}