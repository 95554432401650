import React from 'react'
import {render} from 'react-dom'
import store from 'app/store'

import Help from './Help'
import * as constants from './help-constants'
import reducer from './help-reducer'
import saga from './help-saga'

import AppWrapper from 'app/global/app-wrapper'


export function init() {
  const component =
    <AppWrapper>
      <Help/>
    </AppWrapper>
  const container = document.getElementById('help-wrapper')
  render(component, container)
}

export {constants, reducer, saga}
