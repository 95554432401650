import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import ConfirmationModal from 'app/common/modals/ConfirmationModal'
import {ASSIGNEE_TYPES} from 'app/reusable/BulkUserAssignment'
import LoadingOverlay from 'app/common/LoadingOverlay'

import Actions from './Actions'
import AssignModal from './AssignModal'
import EditModal from './EditModal'
import LocationsTable from './LocationsTable'
import {getSettingsAdmin} from '../firm-settings-selectors'
import {
  fetchLocations,
  deleteLocations,
  hideDeleteLocationModal,
  updateAssignModalData,
} from '../firm-settings-actions'


@connect(
  createSelector(
    [getSettingsAdmin], (settingsAdmin) => {
      return {
        isLoading: settingsAdmin.isLoading,
        firmLocations: settingsAdmin.firmLocations,
        isDeleteLocationModalOpen: settingsAdmin.isDeleteLocationModalOpen,
        selectedLocationIds: settingsAdmin.selectedLocationIds,
        assignModalData: settingsAdmin.assignModalData,
      }
    }
  ),
  {
    fetchLocations,
    deleteLocations,
    hideDeleteLocationModal,
    updateAssignModalData,
  }
)
export default class FirmSettingsLocations extends Component {
  state = {
    assignModalSection: ASSIGNEE_TYPES.USER,
  }

  componentDidMount() {
    if (this.props.firmLocations === null) {
      this.props.fetchLocations()
    }
  }

  render() {
    const loadingOverlay = this.props.isLoading && <LoadingOverlay />

    const assignModal = this.props.assignModalData.id && <AssignModal/>

    const editModal = <EditModal/>

    const deleteModal = this.props.isDeleteLocationModalOpen &&
      <ConfirmationModal
        showExit={false}
        message="Are you sure you want to delete the selected locations? This action cannot be undone."
        confirmButtonText="Delete"
        onConfirm={() => this.onDeleteOk()}
        onClose={event => this.onDeleteCancel()}
      />

    return (
      <div>
        {assignModal}

        {editModal}

        {deleteModal}

        <Actions/>

        <LocationsTable/>

        {loadingOverlay}
      </div>
    )
  }

  showAssignModal(location) {
    this.props.updateAssignModalData({id: location.id, description: location.description})
  }

  onDeleteCancel() {
    this.props.hideDeleteLocationModal()
  }

  onDeleteOk() {
    this.props.deleteLocations([...this.props.selectedLocationIds])
  }
}
