export const DATE_FORMAT = 'MMM-dd-yyyy'
export const ISO_DATE_FORMAT = 'yyyy-MM-dd'
export const SUPPORT_EMAIL = 'support@manzama.com'
export const BASE_APP = 'Manzama Base'
export const PUBLISH_APP = 'Manzama Publish'
export const ERROR_PAGE_IMAGE = '/media/img/manzama-error.png'
export const GRAPHQL_API_URL = '/frontend-api/graphql'
export const HTTP_REQUEST_HEADER_ID_NAME = 'X-Request-ID'
export const MAX_HEALTH_SCORE = 10

export const FILTER_CATEGORY_REGULATORY = 'regulatory'
export const FILTER_CATEGORY_POPULAR = 'popular'
export const FILTER_CATEGORY_EXCLUSIONS = 'exclusions'
export const FILTER_CATEGORY_GEOGRAPHY = 'geography'
export const FILTER_CATEGORY_TOPICS = 'topics'
export const FILTER_CATEGORY_NEWS = 'news'
export const FILTER_CATEGORY_OTHER = 'other'

export const FILTER_CATEGORY_SOURCE_LABELS = 'firmSourceLabels'
export const FILTER_CATEGORY_SEARCHES = 'searches'
export const FILTER_CATEGORY_LIB_SEARCHES = 'firmLibrarySearches'

export const FILTER_CATEGORY_LABELS = {
  'Regulatory': FILTER_CATEGORY_REGULATORY,
  'Popular Filters': FILTER_CATEGORY_POPULAR,
  'Recommended Exclusions': FILTER_CATEGORY_EXCLUSIONS,
  'Geography': FILTER_CATEGORY_GEOGRAPHY,
  'Practice & Industry Topics': FILTER_CATEGORY_TOPICS,
  'News Sources': FILTER_CATEGORY_NEWS,
}

export const VALENCES = {
  POSITIVE: 'POS',
  NEGATIVE: 'NEG',
  NEUTRAL: 'NEU',
}
export const ORDERED_VALENCES = [
  VALENCES.POSITIVE,
  VALENCES.NEUTRAL,
  VALENCES.NEGATIVE,
]

export const ASPECTS = {
  LITIGATION: 'litigation',
  OPINION: 'opinion',
  RUMOR: 'rumor',
}
export const ORDERED_ASPECTS = [
  ASPECTS.LITIGATION,
  ASPECTS.OPINION,
  ASPECTS.RUMOR,
]

// To be used in place of string literals for referencing factors by name
export const FACTORS = {
  PLANET: 'planet',
  PEOPLE: 'people',
  GOVERNANCE: 'governance',
  PROSPERITY: 'prosperity',
}

export const FACTORS_BY_ID = {
  8: FACTORS.PLANET,
  19: FACTORS.PEOPLE,
  22: FACTORS.GOVERNANCE,
  42: FACTORS.PROSPERITY,
}

export const FACTORS_BY_NAME = Object.fromEntries(Object.entries(FACTORS_BY_ID).map(a => [a[1], Number(a[0])]))

export const ORDERED_FACTORS = [
  FACTORS.PLANET,
  FACTORS.PEOPLE,
  FACTORS.GOVERNANCE,
  FACTORS.PROSPERITY,
]

export const SUBFACTORS = {
  GREENHOUSE_GAS_EMISSIONS: 'greenhouse-gas-emissions',
  CLIMATE_CHANGE: 'climate-change',
  NATURE_LOSS: 'nature-loss',
  RESOURCE_AVAILABILITY: 'resource-availability',
  SOLID_WASTE: 'solid-waste',
  DIVERSITY_AND_INCLUSION: 'diversity-and-inclusion',
  WATER_POLLUTION: 'water-pollution',
  DISCRIMINATION_AND_HARASSMENT: 'discrimination-and-harassment',
  FORCED_OR_CHILD_LABOR: 'forced-or-child-labor',
  AIR_POLLUTION: 'air-pollution',
  FRESHWATER_AVAILABILITY: 'freshwater-availability',
  WAGE_LEVELS: 'wage-levels',
  PAY_EQUALITY: 'pay-equality',
  DIGNITY_AND_EQUALITY: 'dignity-and-equality',
  COLLECTIVE_BARGAINING: 'collective-bargaining',
  HAZARDOUS_WASTE: 'hazardous-waste',
  QUALITY_OF_GOVERNING_BODY: 'quality-of-governing-body',
  GOVERNANCE_BODY_COMPOSITION: 'governance-body-composition',
  REMUNERATION: 'remuneration',
  PROGRESS_AGAINST_STRATEGIC_MILESTONES: 'progress-against-strategic-milestones',
  GOVERNING_PURPOSE: 'governing-purpose',
  HEALTH_AND_SAFETY: 'health-and-safety',
  STAKEHOLDER_ENGAGEMENT: 'stakeholder-engagement',
  ETHICAL_BEHAVIOR: 'ethical-behavior',
  MONETARY_LOSSES_FROM_UNETHICAL_BEHAVIOR: 'monetary-losses-from-unethical-behavior',
  EMPLOYEE_WELL_BEING: 'employee-well-being',
  LEAVE_POLICIES: 'leave-policies',
  TRAINING_PROVIDED: 'training-provided',
  SKILLS_SHORTAGE: 'skills-shortage',
  DATA_SECURITY: 'data-security',
  RISK_AND_OPPORTUNITY_OVERSIGHT: 'risk-and-opportunity-oversight',
  ANTI_CORRUPTION: 'anti-corruption',
  RATE_OF_EMPLOYMENT: 'rate-of-employment',
  PROTECTED_ETHICS_ADVICE_AND_REPORTING_MECHANISMS: 'protected-ethics-advice-and-reporting-mechanisms',
  ALIGNMENT_OF_STRATEGY_AND_POLICIES_TO_LOBBYING: 'alignment–of–strategy-and-policies-to-lobbying',
  COMMUNITY_AND_SOCIAL_VITALITY: 'community-and-social-vitality',
  COMMUNITY_AND_SOCIAL_INVESTMENT: 'community-and-social-investment',
  INFRASTRUCTURE_INVESTMENTS_AND_SERVICES_SUPPORTED: 'infrastructure-investments-and-services-supported',
  TAX_REPORTING: 'tax-reporting',
  INNOVATION_OF_BETTER_PRODUCTS_AND_SERVICES: 'innovation-of-better-products-and-services',
  R_D_ACTIVITY: 'r-d-activity',
  CUSTOMER_EXPERIENCE: 'customer-experience',
  EMPLOYMENT_AND_WEALTH_GENERATION: 'employment-and-wealth-generation',
  ECONOMIC_CONTRIBUTION: 'economic-contribution',
  FINANCIAL_INVESTMENT_CONTRIBUTION: 'financial-investment-contribution',
  GOVERNMENT_ASSISTANCE: 'government-assistance',
  HEALTH_AND_WELL_BEING: 'health-and-well-being',
  SKILLS_FOR_THE_FUTURE: 'skills-for-the-future',
  INVESTOR_ACTIVISM: 'investor-activism',
  GREEN_INVESTING: 'green-investing',
  SUPPLY_CHAIN_RISK: 'supply-chain-risk',
  PRODUCT_RISK: 'product-risk',
  DATA_STEWARDSHIP: 'data-stewardship',
  DATA_PRIVACY: 'data-privacy',
  EMERGING_TECHNOLOGY_RISK: 'emerging-technology-risk',
  IMPACT_OF_COVID_ON_CUSTOMER_EXPERIENCE: 'impact-of-covid-on-customer-experience',
  BIORISK: 'biorisk',
  HUMAN_RIGHTS: 'human-rights'
}

export const SUBFACTORS_BY_ID = {
  1: SUBFACTORS.GREENHOUSE_GAS_EMISSIONS,
  4: SUBFACTORS.CLIMATE_CHANGE,
  6: SUBFACTORS.NATURE_LOSS,
  7: SUBFACTORS.RESOURCE_AVAILABILITY,
  9: SUBFACTORS.SOLID_WASTE,
  10: SUBFACTORS.DIVERSITY_AND_INCLUSION,
  11: SUBFACTORS.WATER_POLLUTION,
  12: SUBFACTORS.DISCRIMINATION_AND_HARASSMENT,
  13: SUBFACTORS.FORCED_OR_CHILD_LABOR,
  14: SUBFACTORS.AIR_POLLUTION,
  15: SUBFACTORS.FRESHWATER_AVAILABILITY,
  16: SUBFACTORS.WAGE_LEVELS,
  17: SUBFACTORS.PAY_EQUALITY,
  18: SUBFACTORS.DIGNITY_AND_EQUALITY,
  20: SUBFACTORS.COLLECTIVE_BARGAINING,
  21: SUBFACTORS.HAZARDOUS_WASTE,
  23: SUBFACTORS.QUALITY_OF_GOVERNING_BODY,
  24: SUBFACTORS.GOVERNANCE_BODY_COMPOSITION,
  25: SUBFACTORS.REMUNERATION,
  26: SUBFACTORS.PROGRESS_AGAINST_STRATEGIC_MILESTONES,
  27: SUBFACTORS.GOVERNING_PURPOSE,
  28: SUBFACTORS.HEALTH_AND_SAFETY,
  29: SUBFACTORS.STAKEHOLDER_ENGAGEMENT,
  30: SUBFACTORS.ETHICAL_BEHAVIOR,
  31: SUBFACTORS.MONETARY_LOSSES_FROM_UNETHICAL_BEHAVIOR,
  32: SUBFACTORS.EMPLOYEE_WELL_BEING,
  33: SUBFACTORS.LEAVE_POLICIES,
  34: SUBFACTORS.TRAINING_PROVIDED,
  35: SUBFACTORS.SKILLS_SHORTAGE,
  36: SUBFACTORS.DATA_SECURITY,
  37: SUBFACTORS.RISK_AND_OPPORTUNITY_OVERSIGHT,
  38: SUBFACTORS.ANTI_CORRUPTION,
  39: SUBFACTORS.RATE_OF_EMPLOYMENT,
  40: SUBFACTORS.PROTECTED_ETHICS_ADVICE_AND_REPORTING_MECHANISMS,
  41: SUBFACTORS.ALIGNMENT_OF_STRATEGY_AND_POLICIES_TO_LOBBYING,
  43: SUBFACTORS.COMMUNITY_AND_SOCIAL_VITALITY,
  44: SUBFACTORS.COMMUNITY_AND_SOCIAL_INVESTMENT,
  45: SUBFACTORS.INFRASTRUCTURE_INVESTMENTS_AND_SERVICES_SUPPORTED,
  46: SUBFACTORS.TAX_REPORTING,
  47: SUBFACTORS.INNOVATION_OF_BETTER_PRODUCTS_AND_SERVICES,
  48: SUBFACTORS.R_D_ACTIVITY,
  49: SUBFACTORS.CUSTOMER_EXPERIENCE,
  50: SUBFACTORS.EMPLOYMENT_AND_WEALTH_GENERATION,
  51: SUBFACTORS.ECONOMIC_CONTRIBUTION,
  52: SUBFACTORS.FINANCIAL_INVESTMENT_CONTRIBUTION,
  53: SUBFACTORS.GOVERNMENT_ASSISTANCE,
  54: SUBFACTORS.HEALTH_AND_WELL_BEING,
  55: SUBFACTORS.SKILLS_FOR_THE_FUTURE,
  56: SUBFACTORS.SUPPLY_CHAIN_RISK,
  57: SUBFACTORS.PRODUCT_RISK,
  58: SUBFACTORS.DATA_STEWARDSHIP,
  59: SUBFACTORS.DATA_PRIVACY,
  60: SUBFACTORS.EMERGING_TECHNOLOGY_RISK,
  61: SUBFACTORS.IMPACT_OF_COVID_ON_CUSTOMER_EXPERIENCE,
  64: SUBFACTORS.BIORISK,
  75: SUBFACTORS.INVESTOR_ACTIVISM,
  76: SUBFACTORS.HUMAN_RIGHTS,
  77: SUBFACTORS.GREEN_INVESTING,
}

export const SUBFACTORS_BY_NAME = Object.fromEntries(Object.entries(SUBFACTORS_BY_ID).map(a => [a[1], Number(a[0])]))

export const FACTORS_BY_SUBFACTOR = {
  [SUBFACTORS.CLIMATE_CHANGE]: FACTORS.PLANET,
  [SUBFACTORS.GREENHOUSE_GAS_EMISSIONS]: FACTORS.PLANET,
  [SUBFACTORS.NATURE_LOSS]: FACTORS.PLANET,
  [SUBFACTORS.RESOURCE_AVAILABILITY]: FACTORS.PLANET,
  [SUBFACTORS.SOLID_WASTE]: FACTORS.PLANET,
  [SUBFACTORS.HAZARDOUS_WASTE]: FACTORS.PLANET,
  [SUBFACTORS.WATER_POLLUTION]: FACTORS.PLANET,
  [SUBFACTORS.AIR_POLLUTION]: FACTORS.PLANET,
  [SUBFACTORS.FRESHWATER_AVAILABILITY]: FACTORS.PLANET,
  [SUBFACTORS.GREEN_INVESTING]: FACTORS.PLANET,
  [SUBFACTORS.DIGNITY_AND_EQUALITY]: FACTORS.PEOPLE,
  [SUBFACTORS.DIVERSITY_AND_INCLUSION]: FACTORS.PEOPLE,
  [SUBFACTORS.DISCRIMINATION_AND_HARASSMENT]: FACTORS.PEOPLE,
  [SUBFACTORS.FORCED_OR_CHILD_LABOR]: FACTORS.PEOPLE,
  [SUBFACTORS.WAGE_LEVELS]: FACTORS.PEOPLE,
  [SUBFACTORS.PAY_EQUALITY]: FACTORS.PEOPLE,
  [SUBFACTORS.COLLECTIVE_BARGAINING]: FACTORS.PEOPLE,
  [SUBFACTORS.HEALTH_AND_WELL_BEING]: FACTORS.PEOPLE,
  [SUBFACTORS.HEALTH_AND_SAFETY]: FACTORS.PEOPLE,
  [SUBFACTORS.EMPLOYEE_WELL_BEING]: FACTORS.PEOPLE,
  [SUBFACTORS.LEAVE_POLICIES]: FACTORS.PEOPLE,
  [SUBFACTORS.SKILLS_FOR_THE_FUTURE]: FACTORS.PEOPLE,
  [SUBFACTORS.TRAINING_PROVIDED]: FACTORS.PEOPLE,
  [SUBFACTORS.SKILLS_SHORTAGE]: FACTORS.PEOPLE,
  [SUBFACTORS.QUALITY_OF_GOVERNING_BODY]: FACTORS.GOVERNANCE,
  [SUBFACTORS.GOVERNANCE_BODY_COMPOSITION]: FACTORS.GOVERNANCE,
  [SUBFACTORS.REMUNERATION]: FACTORS.GOVERNANCE,
  [SUBFACTORS.PROGRESS_AGAINST_STRATEGIC_MILESTONES]: FACTORS.GOVERNANCE,
  [SUBFACTORS.GOVERNING_PURPOSE]: FACTORS.GOVERNANCE,
  [SUBFACTORS.STAKEHOLDER_ENGAGEMENT]: FACTORS.GOVERNANCE,
  [SUBFACTORS.INVESTOR_ACTIVISM]: FACTORS.GOVERNANCE,
  [SUBFACTORS.ETHICAL_BEHAVIOR]: FACTORS.GOVERNANCE,
  [SUBFACTORS.MONETARY_LOSSES_FROM_UNETHICAL_BEHAVIOR]: FACTORS.GOVERNANCE,
  [SUBFACTORS.ANTI_CORRUPTION]: FACTORS.GOVERNANCE,
  [SUBFACTORS.PROTECTED_ETHICS_ADVICE_AND_REPORTING_MECHANISMS]: FACTORS.GOVERNANCE,
  [SUBFACTORS.ALIGNMENT_OF_STRATEGY_AND_POLICIES_TO_LOBBYING]: FACTORS.GOVERNANCE,
  [SUBFACTORS.RISK_AND_OPPORTUNITY_OVERSIGHT]: FACTORS.GOVERNANCE,
  [SUBFACTORS.SUPPLY_CHAIN_RISK]: FACTORS.GOVERNANCE,
  [SUBFACTORS.PRODUCT_RISK]: FACTORS.GOVERNANCE,
  [SUBFACTORS.DATA_STEWARDSHIP]: FACTORS.GOVERNANCE,
  [SUBFACTORS.DATA_SECURITY]: FACTORS.GOVERNANCE,
  [SUBFACTORS.DATA_PRIVACY]: FACTORS.GOVERNANCE,
  [SUBFACTORS.EMERGING_TECHNOLOGY_RISK]: FACTORS.GOVERNANCE,
  [SUBFACTORS.BIORISK]: FACTORS.GOVERNANCE,
  [SUBFACTORS.COMMUNITY_AND_SOCIAL_VITALITY]: FACTORS.PROSPERITY,
  [SUBFACTORS.COMMUNITY_AND_SOCIAL_INVESTMENT]: FACTORS.PROSPERITY,
  [SUBFACTORS.TAX_REPORTING]: FACTORS.PROSPERITY,
  [SUBFACTORS.HUMAN_RIGHTS]: FACTORS.PROSPERITY,
  [SUBFACTORS.INNOVATION_OF_BETTER_PRODUCTS_AND_SERVICES]: FACTORS.PROSPERITY,
  [SUBFACTORS.R_D_ACTIVITY]: FACTORS.PROSPERITY,
  [SUBFACTORS.CUSTOMER_EXPERIENCE]: FACTORS.PROSPERITY,
  [SUBFACTORS.IMPACT_OF_COVID_ON_CUSTOMER_EXPERIENCE]: FACTORS.PROSPERITY,
  [SUBFACTORS.EMPLOYMENT_AND_WEALTH_GENERATION]: FACTORS.PROSPERITY,
  [SUBFACTORS.RATE_OF_EMPLOYMENT]: FACTORS.PROSPERITY,
  [SUBFACTORS.INFRASTRUCTURE_INVESTMENTS_AND_SERVICES_SUPPORTED]: FACTORS.PROSPERITY,
  [SUBFACTORS.ECONOMIC_CONTRIBUTION]: FACTORS.PROSPERITY,
  [SUBFACTORS.GOVERNMENT_ASSISTANCE]: FACTORS.PROSPERITY,
  [SUBFACTORS.FINANCIAL_INVESTMENT_CONTRIBUTION]: FACTORS.PROSPERITY,
}

export const ORDERED_SUBFACTORS_BY_FACTOR = {
  // Leaving this here until SubFactorHealthBadge is refactored to use the dependency version.
  [FACTORS.PLANET]: [
    SUBFACTORS.CLIMATE_CHANGE,
    SUBFACTORS.GREENHOUSE_GAS_EMISSIONS,
    SUBFACTORS.NATURE_LOSS,
    SUBFACTORS.RESOURCE_AVAILABILITY,
    SUBFACTORS.SOLID_WASTE,
    SUBFACTORS.HAZARDOUS_WASTE,
    SUBFACTORS.WATER_POLLUTION,
    SUBFACTORS.AIR_POLLUTION,
    SUBFACTORS.FRESHWATER_AVAILABILITY,
    SUBFACTORS.GREEN_INVESTING,
  ],
  [FACTORS.PEOPLE]: [
    SUBFACTORS.DIGNITY_AND_EQUALITY,
    SUBFACTORS.DIVERSITY_AND_INCLUSION,
    SUBFACTORS.DISCRIMINATION_AND_HARASSMENT,
    SUBFACTORS.FORCED_OR_CHILD_LABOR,
    SUBFACTORS.WAGE_LEVELS,
    SUBFACTORS.PAY_EQUALITY,
    SUBFACTORS.COLLECTIVE_BARGAINING,
    SUBFACTORS.HEALTH_AND_WELL_BEING,
    SUBFACTORS.HEALTH_AND_SAFETY,
    SUBFACTORS.EMPLOYEE_WELL_BEING,
    SUBFACTORS.LEAVE_POLICIES,
    SUBFACTORS.SKILLS_FOR_THE_FUTURE,
    SUBFACTORS.TRAINING_PROVIDED,
    SUBFACTORS.SKILLS_SHORTAGE,
  ],
  [FACTORS.GOVERNANCE]: [
    SUBFACTORS.QUALITY_OF_GOVERNING_BODY,
    SUBFACTORS.GOVERNANCE_BODY_COMPOSITION,
    SUBFACTORS.REMUNERATION,
    SUBFACTORS.PROGRESS_AGAINST_STRATEGIC_MILESTONES,
    SUBFACTORS.GOVERNING_PURPOSE,
    SUBFACTORS.STAKEHOLDER_ENGAGEMENT,
    SUBFACTORS.INVESTOR_ACTIVISM,
    SUBFACTORS.ETHICAL_BEHAVIOR,
    SUBFACTORS.MONETARY_LOSSES_FROM_UNETHICAL_BEHAVIOR,
    SUBFACTORS.ANTI_CORRUPTION,
    SUBFACTORS.PROTECTED_ETHICS_ADVICE_AND_REPORTING_MECHANISMS,
    SUBFACTORS.ALIGNMENT_OF_STRATEGY_AND_POLICIES_TO_LOBBYING,
    SUBFACTORS.RISK_AND_OPPORTUNITY_OVERSIGHT,
    SUBFACTORS.SUPPLY_CHAIN_RISK,
    SUBFACTORS.PRODUCT_RISK,
    SUBFACTORS.DATA_STEWARDSHIP,
    SUBFACTORS.DATA_SECURITY,
    SUBFACTORS.DATA_PRIVACY,
    SUBFACTORS.EMERGING_TECHNOLOGY_RISK,
    SUBFACTORS.BIORISK,
  ],
  [FACTORS.PROSPERITY]: [
    SUBFACTORS.COMMUNITY_AND_SOCIAL_VITALITY,
    SUBFACTORS.COMMUNITY_AND_SOCIAL_INVESTMENT,
    SUBFACTORS.TAX_REPORTING,
    SUBFACTORS.HUMAN_RIGHTS,
    SUBFACTORS.INNOVATION_OF_BETTER_PRODUCTS_AND_SERVICES,
    SUBFACTORS.R_D_ACTIVITY,
    SUBFACTORS.CUSTOMER_EXPERIENCE,
    SUBFACTORS.IMPACT_OF_COVID_ON_CUSTOMER_EXPERIENCE,
    SUBFACTORS.EMPLOYMENT_AND_WEALTH_GENERATION,
    SUBFACTORS.RATE_OF_EMPLOYMENT,
    SUBFACTORS.INFRASTRUCTURE_INVESTMENTS_AND_SERVICES_SUPPORTED,
    SUBFACTORS.ECONOMIC_CONTRIBUTION,
    SUBFACTORS.GOVERNMENT_ASSISTANCE,
    SUBFACTORS.FINANCIAL_INVESTMENT_CONTRIBUTION,
  ]
}

export const ORDERED_FACTOR_DEPENDENCY_TREE = {
  [FACTORS.PLANET]: [
    SUBFACTORS.CLIMATE_CHANGE,
    SUBFACTORS.NATURE_LOSS,
    SUBFACTORS.RESOURCE_AVAILABILITY,
    SUBFACTORS.SOLID_WASTE,
    SUBFACTORS.WATER_POLLUTION,
    SUBFACTORS.AIR_POLLUTION,
    SUBFACTORS.FRESHWATER_AVAILABILITY,
    SUBFACTORS.GREEN_INVESTING,
  ],
  [SUBFACTORS.CLIMATE_CHANGE]: [
    SUBFACTORS.GREENHOUSE_GAS_EMISSIONS,
  ],
  [SUBFACTORS.SOLID_WASTE]: [
    SUBFACTORS.HAZARDOUS_WASTE,
  ],
  [FACTORS.PEOPLE]: [
    SUBFACTORS.DIGNITY_AND_EQUALITY,
    SUBFACTORS.HEALTH_AND_WELL_BEING,
    SUBFACTORS.SKILLS_FOR_THE_FUTURE,
  ],
  [SUBFACTORS.DIGNITY_AND_EQUALITY]: [
    SUBFACTORS.DIVERSITY_AND_INCLUSION,
    SUBFACTORS.DISCRIMINATION_AND_HARASSMENT,
    SUBFACTORS.FORCED_OR_CHILD_LABOR,
    SUBFACTORS.WAGE_LEVELS,
    SUBFACTORS.PAY_EQUALITY,
    SUBFACTORS.COLLECTIVE_BARGAINING,
  ],
  [SUBFACTORS.HEALTH_AND_WELL_BEING]: [
    SUBFACTORS.HEALTH_AND_SAFETY,
    SUBFACTORS.EMPLOYEE_WELL_BEING,
    SUBFACTORS.LEAVE_POLICIES,
  ],
  [SUBFACTORS.SKILLS_FOR_THE_FUTURE]: [
    SUBFACTORS.TRAINING_PROVIDED,
    SUBFACTORS.SKILLS_SHORTAGE,
  ],
  [FACTORS.GOVERNANCE]: [
    SUBFACTORS.QUALITY_OF_GOVERNING_BODY,
    SUBFACTORS.GOVERNING_PURPOSE,
    SUBFACTORS.STAKEHOLDER_ENGAGEMENT,
    SUBFACTORS.ETHICAL_BEHAVIOR,
    SUBFACTORS.RISK_AND_OPPORTUNITY_OVERSIGHT,
  ],
  [SUBFACTORS.QUALITY_OF_GOVERNING_BODY]: [
    SUBFACTORS.GOVERNANCE_BODY_COMPOSITION,
    SUBFACTORS.REMUNERATION,
    SUBFACTORS.PROGRESS_AGAINST_STRATEGIC_MILESTONES,
  ],
  [SUBFACTORS.STAKEHOLDER_ENGAGEMENT]: [
    SUBFACTORS.INVESTOR_ACTIVISM,
  ],
  [SUBFACTORS.ETHICAL_BEHAVIOR]: [
    SUBFACTORS.MONETARY_LOSSES_FROM_UNETHICAL_BEHAVIOR,
    SUBFACTORS.ANTI_CORRUPTION,
    SUBFACTORS.PROTECTED_ETHICS_ADVICE_AND_REPORTING_MECHANISMS,
    SUBFACTORS.ALIGNMENT_OF_STRATEGY_AND_POLICIES_TO_LOBBYING,
  ],
  [SUBFACTORS.RISK_AND_OPPORTUNITY_OVERSIGHT]: [
    SUBFACTORS.SUPPLY_CHAIN_RISK,
    SUBFACTORS.PRODUCT_RISK,
    SUBFACTORS.DATA_STEWARDSHIP,
    SUBFACTORS.EMERGING_TECHNOLOGY_RISK,
    SUBFACTORS.BIORISK,
  ],
  [SUBFACTORS.DATA_STEWARDSHIP]: [
    SUBFACTORS.DATA_SECURITY,
    SUBFACTORS.DATA_PRIVACY,
  ],
  [FACTORS.PROSPERITY]: [
    SUBFACTORS.COMMUNITY_AND_SOCIAL_VITALITY,
    SUBFACTORS.INNOVATION_OF_BETTER_PRODUCTS_AND_SERVICES,
    SUBFACTORS.EMPLOYMENT_AND_WEALTH_GENERATION,

  ],
  [SUBFACTORS.COMMUNITY_AND_SOCIAL_VITALITY]: [
    SUBFACTORS.COMMUNITY_AND_SOCIAL_INVESTMENT,
    SUBFACTORS.TAX_REPORTING,
    SUBFACTORS.HUMAN_RIGHTS,
  ],
  [SUBFACTORS.INNOVATION_OF_BETTER_PRODUCTS_AND_SERVICES]: [
    SUBFACTORS.R_D_ACTIVITY,
    SUBFACTORS.CUSTOMER_EXPERIENCE,
  ],
  [SUBFACTORS.CUSTOMER_EXPERIENCE]: [
    SUBFACTORS.IMPACT_OF_COVID_ON_CUSTOMER_EXPERIENCE,
  ],
  [SUBFACTORS.EMPLOYMENT_AND_WEALTH_GENERATION]: [
    SUBFACTORS.RATE_OF_EMPLOYMENT,
    SUBFACTORS.INFRASTRUCTURE_INVESTMENTS_AND_SERVICES_SUPPORTED,
    SUBFACTORS.ECONOMIC_CONTRIBUTION,
    SUBFACTORS.FINANCIAL_INVESTMENT_CONTRIBUTION,
  ],
  [SUBFACTORS.ECONOMIC_CONTRIBUTION]: [
    SUBFACTORS.GOVERNMENT_ASSISTANCE,
  ]
}

export const MULTISELECT_DROPDOWN = {
  SELECT_ALL_TEXT : 'Select All',
  SELECT_ALL_ID : -1,
  ALL_TEXT : 'All',
  SELECTED_TEXT : 'Selected',
  NO_SELECTION_TEXT : 'Please Select...'
};

export const APP_LOGO = {
  DILIGENT: '/media/img/diligent-logotype.svg',
  DILIGENT_DARK: '/media/img/diligent-logo-notext-white.svg',
  DILIGENT_FAV_ICON:'/media/img/diligent-favicon-d-32x32@2x.ico',
  MANZAMA: '/media/img/manzama-logo.svg',
  MANZAMA_DARK: '/media/img/manzama-logotype.svg',
  MANZAMA_FAV_ICON: '/media/img/manzama-favicon-dark-32x32@2x.ico',
}

export const APP_NAME = {
  MANZAMA: 'manzama',
  DILIGENT: 'diligent',
}

export const getAppLogo = (isDark = false) => process.env.MANZAMA_BRAND === APP_NAME.DILIGENT
    ? (isDark ? APP_LOGO.DILIGENT_DARK : APP_LOGO.DILIGENT)
    : (isDark ? APP_LOGO.MANZAMA_DARK : APP_LOGO.MANZAMA)

export const TWITTER_TOOLTIP_MESSAGE = "Twitter posts after April 4th, 2023 are not available due to Twitter’s recent policy change"

export const MENU_ICONS = {
  help: '/media/img/menu-bar/help.svg',
  flag: '/media/img/menu-bar/flag.svg',
  publish: '/media/img/menu-bar/publish.svg',
  separator: '/media/img/menu-bar/separator.svg',
  admin: '/media/img/menu-bar/settings.svg',
  emailNotifications: '/media/img/menu-bar/email_notifications.svg',
  logout: '/media/img/menu-bar/logout.svg',
  hamburger: '/media/img/menu-bar/hamburger.svg',
}
export const screenWidthForHamburgerMenu = 1270
export const screenWidthForFullHamburgerMenu = 820

const MAX_BADGE_COUNT = 99
export const EMAIL_REGEX = /^[a-zA-Z0-9._%+'-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
