import PropTypes from 'prop-types'
import React, {useState} from 'react'
import styles from './ComparisonSearchSelect.less'
import {useSelector} from 'react-redux'
import TextBox from 'app/common/TextBox'
import * as selectors from '../comparison-selectors'

function ComparisonSearchSelect({handleOnSearchClick, selectedSearchIds}) {

  const [filterSearchText, setFilterSearchText] = useState(null)
  const categorySearches = useSelector(selectors.getCategorySearches)
  const displayCategory = useSelector(selectors.getDisplayCategory)

  const containsFilterText = string =>
    string.toLowerCase().includes(filterSearchText.toLowerCase())
  const filteredSearches = filterSearchText
    ? categorySearches.filter(ss => containsFilterText(ss.name))
    : categorySearches
  const categorySearchesList = filteredSearches.map(ss => {
    return (
      <div
        className={styles.searchRow}
        key={ss.id}
        onClick={() => handleOnSearchClick(ss.id)}
      >
        <input
          className="select-search-checkbox"
          type="checkbox"
          checked={selectedSearchIds.includes(ss.id)}
          readOnly={true}
        />
        <span className={styles.searchName}>{ss.name}</span>
      </div>
    )
  })

  return (
    <React.Fragment>
      <div className={styles.selectSearchWrapper}>
        <TextBox
          className={styles.selectSearchFilter}
          placeholder={`Find ${displayCategory}...`}
          onChange={e => setFilterSearchText(e.target.value)}
        />
        <div className={styles.searchRowWrapper}>
          <div className={styles.searchColumns}>{categorySearchesList}</div>
        </div>
      </div>
    </React.Fragment>
  )
}
ComparisonSearchSelect.propTypes = {
  handleOnSearchClick: PropTypes.func.isRequired,
  selectedSearchIds: PropTypes.arrayOf(PropTypes.number),
}
export default ComparisonSearchSelect
