import React, { Component } from 'react'
import PropTypes from 'prop-types'

import TextBox from 'app/common/TextBox'
import Dropdown from 'app/common/Dropdown'
import Tooltip from '../common/Tooltip';
import InlineSvg from "../common/InlineSvg";


export default class AccountSettings extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    firmLocations: PropTypes.array.isRequired,
    timezones: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    locations: PropTypes.array.isRequired,

    onPasswordChange: PropTypes.func.isRequired,
    onConfirmPasswordChange: PropTypes.func.isRequired,
    onCurrentPasswordChange: PropTypes.func.isRequired,
    onFirmLocationChange: PropTypes.func.isRequired,
    onTimezoneChange: PropTypes.func.isRequired,
    onRoleChange: PropTypes.func.isRequired,

    password: PropTypes.string,
    confirmPassword: PropTypes.string,
    currentPassword: PropTypes.string,
    firmLocationId: PropTypes.number,
    timezone: PropTypes.string,
    role: PropTypes.string,
    locationId: PropTypes.number,
  }

  render() {
    const { user, firmLocations, timezones, roles, locations } = this.props

    const firmLocationOptions = [{value: 0, label: ''}].concat(
      firmLocations.map(l => ({value: l.id, label: l.description}))
    )

    const topLocations = [
      locations.find(c => c.name === 'Australia'),
      locations.find(c => c.name === 'Canada'),
      locations.find(c => c.name === 'United Kingdom'),
      locations.find(c => c.name === 'United States'),
      {id: 0, name: '----------'}
    ]

    const locationOptions = topLocations.concat(locations).map(c => ({value: c.id, label: c.name}))

    const timezoneOptions = timezones.map(tz => ({value: tz[0], label: tz[1]}))

    const roleOptions = roles.map(r => ({value: r[0], label: r[1]})).filter(r => r.value !== 'group')

    return (
      <div className="builder-content-body-container">
        <div className="builder-content-body">
          <p>Almost done! We just need a little more information and then you'll be all set.</p>

          <div className="account-settings-row">
            <div className="label">USERNAME</div>
            <div>{user.username}</div>
          </div>

          <div className="account-settings-row">
            <div className="label">PASSWORD *
              <Tooltip render={() =>
                <div>
                  <ul>
                    <li>Password needs to be between 8-128 characters.</li>
                    <li>It can not be too similar to your username, email address, first or last name.</li>
                    <li>The new password can not match the last 5 passwords.</li>
                  </ul>
                </div>}>
                <InlineSvg
                  className="tooltip-icon"
                  src="/media/img/help-tooltip-icon.svg"
                />
              </Tooltip>
            </div>
            <div>
              <TextBox
                type="password"
                value={this.props.password || ''}
                onChange={evt => this.handlePasswordChange(evt)}
              />
            </div>
          </div>

          <div className="account-settings-row">
            <div className="label">CONFIRM PASSWORD *</div>
            <div>
              <TextBox
                type="password"
                value={this.props.confirmPassword || ''}
                onChange={evt => this.handleConfirmPasswordChange(evt)}
              />
            </div>
          </div>

          <div className="account-settings-row">
            <div className="label">CURRENT PASSWORD *</div>
            <div>
              <TextBox
                type="password"
                value={this.props.currentPassword || ''}
                onChange={evt => this.handleCurrentPasswordChange(evt)}
              />
            </div>
          </div>

          <div className="account-settings-row">
            <div className="label">ROLE *</div>
            <div>
              <Dropdown
                options={roleOptions}
                value={this.props.role || ''}
                onChange={value => this.props.onRoleChange(value)}
              />
            </div>
          </div>

          <div className="account-settings-row">
            <div className="label">COUNTRY *</div>
            <div>
              <Dropdown
                options={locationOptions}
                value={this.props.locationId}
                onChange={value => this.props.onLocationChange(value)}
              />
            </div>
          </div>

          <div className="account-settings-row">
            <div className="label">TIMEZONE *</div>
            <div>
              <Dropdown
                options={timezoneOptions}
                value={this.props.timezone}
                onChange={value => this.props.onTimezoneChange(value)}
              />
            </div>
          </div>

          <div className="account-settings-row">
            <div className="label">OFFICE LOCATION</div>
            <div>
              <Dropdown
                options={firmLocationOptions}
                value={this.props.firmLocationId || 0}
                onChange={value => this.props.onFirmLocationChange(value)}
              />
            </div>
          </div>

          <div className="account-settings-row">
            <div>* Required Field</div>
          </div>
        </div>
      </div>
    )
  }

  handlePasswordChange(evt) {
    this.props.onPasswordChange(evt.target.value)
  }

  handleConfirmPasswordChange(evt) {
    this.props.onConfirmPasswordChange(evt.target.value)
  }

  handleCurrentPasswordChange(evt) {
    this.props.onCurrentPasswordChange(evt.target.value)
  }
}
