import {handleActions} from 'redux-actions'

import * as actions from './django-interaction-bar-actions'

const getInitialState = () => ({
  flaggedIds: [],
  arePromotedAndExcludedFeedsFetched: false,
  initializedDocumentIds: [],
  initializedFeedIds: [],
})

export default handleActions(
  {
    [actions.fetchPromotedAndExcludedFeedsComplete]: (state, action) => ({
      ...state,
      arePromotedAndExcludedFeedsFetched: action.payload,
    }),
    [actions.initBarComplete]: (state, action) => {
      const documentIds = action.payload.documentIds.length > 0
        ? [...state.initializedDocumentIds,
          ...action.payload.documentIds.filter(id => !state.initializedDocumentIds.includes(id))]
        : [...state.initializedDocumentIds]
      const feedIds = action.payload.feedIds.length > 0
        ? [...state.initializedFeedIds,
          ...action.payload.feedIds.filter(id => !state.initializedFeedIds.includes(id))]
        : [...state.initializedFeedIds]
      const flaggedIds = action.payload.flaggedIds.length > 0
        ? [...state.flaggedIds,
          ...action.payload.flaggedIds.filter(id => !state.flaggedIds.includes(id))]
        : [...state.flaggedIds]
      return {
        ...state,
        flaggedIds: flaggedIds,
        initializedDocumentIds: documentIds,
        initializedFeedIds: feedIds,
      }
    },
  },
  getInitialState(),
)

