import { handleActions } from 'redux-actions'
import * as actions from './profile-group-members-actions'


const getInitialState = () => ({
  isLoading: false,
  isFetchingMembers: false,
  isFetchingUsers: false,
  groupProfileId: null,
  groupUserId: null,
  isLargeFirm: false,
  activeUserCount: 0,
  areMembersFetched: false,
  areAllUsersFetched: false,
  newEmailAddress: '',

  // user ids for group members
  memberIds: [],

  // fetched user ids
  userIds: [],

  // if all users were fetched
  allUserIds: [],
})

export default handleActions({
  [actions.init]: (state, action) => ({
    ...state,
    groupProfileId: action.payload.groupProfileId,
    groupUserId: action.payload.groupUserId,
    isLargeFirm: action.payload.isLargeFirm,
    activeUserCount: action.payload.activeUserCount,
  }),
  [actions.setIsLoading]: (state, action) => ({
    ...state,
    isLoading: action.payload,
  }),
  [actions.setIsFetchingMembers]: (state, action) => ({
    ...state,
    isFetchingMembers: action.payload,
  }),
  [actions.setIsFetchingUsers]: (state, action) => ({
    ...state,
    isFetchingUsers: action.payload,
  }),
  [actions.fetchMembers]: (state, action) => ({
    ...state,
    isFetchingMembers: true,
  }),
  [actions.fetchUsers]: (state, action) => ({
    ...state,
    isFetchingUsers: true,
  }),
  [actions.setMemberIds]: (state, action) => ({
    ...state,
    memberIds: action.payload,
    areMembersFetched: true,
  }),
  [actions.addUserIds]: (state, action) => ({
    ...state,
    userIds: [...new Set([...state.userIds, ...action.payload])],
  }),
  [actions.setAllUsersAreFetched]: (state, action) => ({
    ...state,
    areAllUsersFetched: true,
  }),
  [actions.createMembership]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.deleteMembership]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.setNewEmailAddress]: (state, action) => ({
    ...state,
    newEmailAddress: action.payload,
  }),
}, getInitialState())
