import React from "react";
import classNames from 'classnames'
import * as styles from '../style.less'
import Orm from 'app/framework/Orm'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { Feed } from 'app/models'
import { getSourcesAdmin } from '../sources-admin-selectors'
import { getEntities } from 'app/entities/entities-selectors'

@connect(
  createSelector(
    [getEntities, getSourcesAdmin],
    (entities, sourcesAdmin) => {
      const orm = Orm.withEntities(entities)
      const modalData = sourcesAdmin.editModalData
      return {
        feed: orm.getById(Feed, modalData.feedId),
      }
    }
  ),
)
export default class ReportHistory extends React.Component {
  state = {
    sortDate: 'asc',
    dateArray: this.props.feed.reportedOn ? this.props.feed.reportedOn : []
  }

  sortDate = () => {
    const sortDate = this.state.sortDate === 'asc' ? 'desc' : 'asc'
    let dateArray = this.props.feed.reportedOn
    const sortArray = dateArray.map((date) => {
      return date
    })

    if (this.state.sortDate === 'asc') {
      sortArray.sort(function (a, b) {
        return new Date(a) - new Date(b);
      });
    } else {
      sortArray.sort(function (a, b) {
        return new Date(b) - new Date(a);
      });
    }
    dateArray = sortArray
    this.setState({ sortDate, dateArray })
  }
  
  render() {
    const dates = this.props.feed.reportedOn ? this.state.dateArray.map(report => {
      return <div className="report-dates" >{report}</div>
    }) : ''
    const dateLabel = 'Date (UTC Timezone)'

    return (
      <div>
        {
          this.props.feed.reportedOn?.length === 0 ?
            <p>This Source does not have a report history.</p> :
            <div id="report-history-tab" >
              <div className="report-header">
                <h4>{dateLabel}</h4><div className="report-history-sort-icon" onClick={() => this.sortDate()} /></div>
              {dates}
            </div>
        }
      </div>
    );
  }
}
