import React from 'react'
import Dropdown, { Option } from 'app/common/Dropdown'
import { SavedSearch } from 'app/models'


export default ({showAllTeams = true, teams, ...restProps}) => {
  const allTeamsOption =
    showAllTeams && <Option key="all" label="All Teams" />

  const options = teams.map(team =>
    <Option
      key={team.id}
      label={team.name}
      value={team.id}
    />
  )

  return (
    <Dropdown {...restProps}>
      {allTeamsOption}
      {options}
    </Dropdown>
  )
}
