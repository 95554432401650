import React  from 'react'


export default function DunAndBradstreetMoreInformation({moreInfoContact}) {

  const contactInfo = moreInfoContact
    ?<a className="link" href={"mailto:" + moreInfoContact[1]}>{moreInfoContact[0]}</a>
    :<a className="link" href="mailto:sales@manzama.com">Sales</a>

  return (
    <div id="dnb-more-information">
      <div id='logos-section'>
        <span id='mz-logo'></span>
        <span id='plus'>+</span>
        <span className='dnb-logo'></span>
        <span id='equals'>=</span>
        <span className='dnb-trophy'></span>
      </div>
      <p id="info-text" className="text">
        We are now partnering with Dun &amp;
        Bradstreet to offer you more information about the clients
        you want to monitor. Our new add-on, powered by D&amp;B, will
        give you access to subsidiary and relationship information
        like revenue, number of employees, and the CEO of a company.
        You will also be able to easily expand your search query to
        include subsidiary and relationship terms at the click of a
        button, allowing you to see more news about companies and
        related entities across the family tree leveraging D&amp;B’s
        corporate hierarchy information.
      </p>
      <div id="dnb-example"></div>
      <p id="contact-text" className="text">
        To get access to these new D&amp;B powered features, please contact {contactInfo}.
      </p>
    </div>
  )
}
