import React, { Component } from 'react'
import { connect } from 'react-redux'
import './SummaryTextLength.less'
import * as actions from './email-notifications-actions'
import * as constants from './email-notifications-constants'
import InputBlock from 'app/common/InputBlock'
import PropTypes from 'prop-types'
import classNames from "classnames"


@connect(
  state => ({
    baseSettings: state.emailNotifications.baseSettings,
    insightsSettings: state.emailNotifications.insightsSettings,
    editUserId: state.emailNotifications.emailDeliveryData.editUserId,
  }),
  {
    saveEmailAlertSettings: actions.api.saveEmailAlertSettings.request,
  },
)
export default class SummaryTextLength extends Component {
  static propTypes = {
    noticeFrequency: PropTypes.string.isRequired,
    notificationType: PropTypes.string.isRequired,
  }
  render() {
    const activeSettings = this.props.notificationType === constants.BASE_NOTIFICATION_TYPE
      ? this.props.baseSettings
      : this.props.notificationType === constants.INSIGHTS_NOTIFICATION_TYPE
        ? this.props.insightsSettings
        : null
    let summaryTextLengthOptions = null
    if (activeSettings) {
      const frequencySettings = activeSettings.settings.find(
        setting => setting.noticeFrequency === this.props.noticeFrequency)
    const summaryTextLength = frequencySettings
      ? frequencySettings.summaryTextLength
      :null
      summaryTextLengthOptions =
        constants.SUMMARY_TEXT_LENGTH_OPTIONS.map(opt => {
          const selectedTextLength = opt.key === summaryTextLength
          return (
            <div className={'summary-text-length-option'} key={opt.value}>
              <InputBlock
                className={classNames(null, {'fade': selectedTextLength})}
                isInline={true}
                label={opt.value}
              >
                <input
                  value={opt.key}
                  checked={selectedTextLength}
                  type="radio"
                  onChange={() => {this.onSummaryTextLengthChange(opt.key, activeSettings)}}
                />
              </InputBlock>
            </div>
          )
      },)
    }
    return (
      <React.Fragment>
        <div className={'label summary-text-length-label'}>
          Article Summary Text Length
        </div>
        <div className={'summary-text-length-container'}>
          {summaryTextLengthOptions}
        </div>
      </React.Fragment>
    )
  }
  onSummaryTextLengthChange(length, activeSettings) {
    this.props.saveEmailAlertSettings({
      editUserId: this.props.editUserId,
      noticeFrequency: this.props.noticeFrequency,
      type: activeSettings.notificationType,
      field: constants.SUMMARY_TEXT_LENGTH,
      value: length
    })
  }
}
