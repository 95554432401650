import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { Component } from 'react'

import Table from 'app/common/Table'

const COLUMNS = {
  NAME: 'name',
  TYPE: 'type',
}


export default class EditSourceFolderModalFeedsTable extends Component {
  static propTypes = {
    feeds: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  render() {
    return (
      <Table
        defaultSort={{column: COLUMNS.NAME, direction: 'asc'}}
        data={this.props.feeds}
      >
        <Table.Column
          name={COLUMNS.NAME}
          label="Name"
          isSortable
          sortBy={R.prop('name')}
          baseWidth={150}
          cellContents={R.prop('name')}
        />

        <Table.Column
          name={COLUMNS.TYPE}
          label="Type"
          isSortable
          sortBy={search => search.scope}
          baseWidth={100}
          cellContents={feed =>
            feed.isProprietary ? 'Firm Only' : 'Global Source'
          }
        />
      </Table>
    )
  }
}
