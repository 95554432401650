import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {connect} from 'react-redux'

import {Tab, TabbedContainer} from 'app/common/TabbedContainer'
import ExcludedFeedsList from 'app/reusable/ExcludedFeedsList'

import DetailsContent from './DetailsContent'
import FiltersContent from './FiltersContent'
import UsedByContent from './UsedByContent'
import SharingContent from './SharingContent'
import FeaturedForContent from './FeaturedForContent/FeaturedForContent'
import ActionLogContent from './ActionLogContent'
import EditLabelsModal from '../ManageLabels/EditLabelsModal'

import {removeExcludedFeedsFromSearch, resetSaveData} from '../searches-admin-actions'

const TABS = {
  DETAILS: 'details',
  SHARING: 'sharing',
  USED_BY: 'used-by',
  FEATURED_FOR: 'featured-for',
  FILTERS: 'filters',
  EXCLUDED_FEEDS: 'excluded-feeds',
  ACTION_LOG: 'action-log',
  LABELS: 'labels'
}


@connect(
  undefined,
  {
    removeExcludedFeedsFromSearch,
    resetSaveData,
  }
)
export default class EditSearchModalContent extends Component {
  static TABS = TABS

  static propTypes = {
    search: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    defaultTab: PropTypes.string,
  }

  render() {
    const {search, closeModal, defaultTab} = this.props
    return (
      <div>
        <TabbedContainer
          defaultTabName={defaultTab}
          useUpdatedCss
          onTabChange={this.props.resetSaveData}
        >
          <Tab name={TABS.DETAILS} label="Details">
            <DetailsContent search={search} closeModal={closeModal} />
          </Tab>

          <Tab name={TABS.SHARING} label="Profiles">
            <SharingContent search={search} closeModal={closeModal} />
          </Tab>

          <Tab name={TABS.USED_BY} label="Used By">
            <UsedByContent search={search} closeModal={closeModal} />
          </Tab>

          <Tab name={TABS.FEATURED_FOR} label="Featured For">
            <FeaturedForContent search={search} closeModal={closeModal} />
          </Tab>

          <Tab name={TABS.FILTERS} label="Filters">
            <FiltersContent search={search} />
          </Tab>

          <Tab name={TABS.EXCLUDED_FEEDS} label="Excluded Sources">
            <ExcludedFeedsList
              search={search}
              onDelete={this.props.removeExcludedFeedsFromSearch}
            />
          </Tab>
          <Tab name={TABS.ACTION_LOG} label="Change History">
            <ActionLogContent search={search} />
          </Tab>
          <Tab name={TABS.LABELS} label="Labels">
            <EditLabelsModal search={search} closeModal={closeModal}/>
          </Tab>
          </TabbedContainer>
      </div>
    )
  }
}
