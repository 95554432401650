import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import Dropdown from 'app/common/Dropdown'
import * as globalSelectors from 'app/global/global-selectors'


export const ACTIONS = {
  EXPORT_PDF: 'export_pdf',
  EXPORT_DOCX: 'export_docx',
  EXPORT_EXCEL: 'excel',
  EMAIL: 'email',
  FLAG: 'flag',
  MOVE_FLAG: 'move-flag',
  UNFLAG: 'unflag',
  DELETE: 'delete',
}

export class SearchResultsActions extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    isFlagPage: PropTypes.bool,
    isUserStaff: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
  }

  static defaultProps = {
    isFlagPage: false,
    isDisabled: false,
  }

  static ACTIONS = ACTIONS

  render() {
    const options = [
      {label: "Actions", value: '', isInvisible: true},
      {
        isGroup: true,
        label: "Quick Publishing",
        options: [
          {label: "Create PDF Report", value: ACTIONS.EXPORT_PDF},
          {label: "Create Word Report", value: ACTIONS.EXPORT_DOCX},
          {label: "Export to Excel", value: ACTIONS.EXPORT_EXCEL},
          {label: "Send via Email", value: ACTIONS.EMAIL},
        ],
      },
      {
        isGroup: true,
        label: "Curation",
        options: [
          {label: "Flag as", value: ACTIONS.FLAG},
        ],
      },
    ]

    if (this.props.isFlagPage) {
      options[2].options.push({label: "Move to", value: ACTIONS.MOVE_FLAG})
      options[2].options.push({label: "Remove from Flagged", value: ACTIONS.UNFLAG})
    }
    if (this.props.isUserStaff) {
      options.push({
        isGroup: true,
        label: "Staff Actions",
        options: [
          {label: "Delete Articles", value: ACTIONS.DELETE},
        ],
      })
    }

    return (
      <span className="search-results-actions">
        <Dropdown
          options={options}
          onChange={this.props.onChange}
          value=""
          disabled={this.props.isDisabled}
        />
      </span>
    )
  }
}


// TODO: Remove this once we've switched to the React-ified tier 3 page.
export function init({onChange, isFlagPage, isUserStaff}) {
  const container = document.getElementById('search-results-actions')
  render(
    <SearchResultsActions
      onChange={onChange}
      isFlagPage={isFlagPage}
      isUserStaff={isUserStaff}
    />,
    container,
  )
}


export default connect(
  createSelector(
    [globalSelectors.getOriginalUserIsStaff],
    isUserStaff => ({isUserStaff}),
  ),
)(SearchResultsActions)
