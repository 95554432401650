import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Modal from 'app/common/Modal'
import {withQueryParams} from 'app/urls'

import './style';

const SHARE_TYPES = [
  {type: 'facebook', label: 'Facebook'},
  {type: 'linkedin', label: 'LinkedIn'},
  {type: 'twitter', label: 'X'},
  {type: 'yammer', label: 'Yammer'},
  // Microsoft Teams is added separately because it's button and url are generated dynamically by the MS library.
]

function shareUrl({type, articleId, feedId, url}) {
  return withQueryParams(
    `/article/share/send/${type}/${articleId}/`,
    {
      feed_id: feedId || undefined,
      url: url || undefined,
    },
  )
}

export class ShareModal extends Component {
  static propTypes = {
    article: PropTypes.object,
    onClose: PropTypes.func.isRequired,
  }

  state = {
    microsoftTeamsUrl: null,
    microsoftTeamsButtonRef: React.createRef(),
  }

  componentDidMount() {
    /**
     * the Microsoft Teams share button needs to be setup after the page is rendered.
     * if the library failed to load for any reason, just skip it.
     */
    if (window.shareToMicrosoftTeams) {
      window.shareToMicrosoftTeams.renderButtons()
      // grab the MS Teams url after the button is rendered so we can pass it to our share endpoint for redirecting
      // after the interaction is recorded.
      const microsoftTeamsButton = this.state.microsoftTeamsButtonRef.current
      const microsoftTeamsLink = microsoftTeamsButton.querySelector('a')
      this.setState({microsoftTeamsUrl: microsoftTeamsLink.href})
      // remove the MS Teams click handler (by cloning the element) so only ours is used now.
      const microsoftTeamsButtonCopy = microsoftTeamsButton.cloneNode(true)
      microsoftTeamsButton.parentNode.replaceChild(microsoftTeamsButtonCopy, microsoftTeamsButton)
    }
  }

  render() {
    return (
      <Modal
        onClose={this.props.onClose}
        className="share-modal"
      >
        <h3 className="share-modal-header">Share Article</h3>

        <div>{this.props.article.headline}</div>

        <div className="share-icons">
          {SHARE_TYPES.map(({type, label}) =>
            <div key={type} className="share-icon-container">
              <a
                href={
                  shareUrl({
                    type,
                    articleId: this.props.article.id,
                    feedId: this.props.article.feedId,
                  })
                }
                target="_blank"
              >
                <span className={`share-icon share-icon-${type}`}>&nbsp;{label}</span>
              </a>
            </div>
          )}
          {
            // render the MS Teams share button, unless the library failed to load for any reason.
            window.shareToMicrosoftTeams &&
            <div className="share-icon-container">
              <a
                className="teams-share-button-link"
                href={
                  shareUrl({
                    type: 'teams',
                    articleId: this.props.article.id,
                    feedId: this.props.article.feedId,
                    // url: this.state.microsoftTeamsUrl,
                  })
                }
                target="_blank"
              >
                <div
                  ref={this.state.microsoftTeamsButtonRef}
                  className="teams-share-button"
                  // data-href={`${window.location.href}`}
                  data-preview="true"
                  data-msg-text={`${this.props.article.headline.substring(0, 80)} Found via ${window.location.hostname}`}
                />
                <span>Microsoft Teams</span>
              </a>
            </div>
          }
        </div>
      </Modal>
    )
  }
}
