import Model from 'app/framework/Model'
import { field, foreignKey, hasMany } from 'app/framework/Model/fields'

import User from './User'


export default class Membership extends Model {
  static entityKey = 'memberships'

  static fields = {
    user: foreignKey(User),
    group: foreignKey(User),
    isManager: field({required: false}),
  }

  static indexes = ['user', 'group']
}
// `groupMemberships` represents groups that this user is a member of,
// and `userMemberships` represent's this group's user memberships.
// Essentially, the former should be used with normal users and the
// latter with groups.
User.fields.groupMemberships = hasMany(
  Membership,
  {required: false, foreignKeyField: 'user'},
)
User.fields.userMemberships = hasMany(
  Membership,
  {required: false, foreignKeyField: 'group'},
)
