import lodash from 'lodash'
const { COLUMN_KEYS } = require("./usage-constants");

export const columnsToFetch = (dimension) => {
    let columns = []
    switch (dimension) {
        case COLUMN_KEYS.profileName:
            columns = [
                getSnakeCase(COLUMN_KEYS.userId),
                getSnakeCase(COLUMN_KEYS.profileName),
                getSnakeCase(COLUMN_KEYS.profileEmail),
                getSnakeCase(COLUMN_KEYS.totalUsers),
                getSnakeCase(COLUMN_KEYS.isGroup),
                getSnakeCase(COLUMN_KEYS.articleClicks),
                getSnakeCase(COLUMN_KEYS.uniqueClicks),
            ]
            break;
        case COLUMN_KEYS.source:
            columns = [
                getSnakeCase(COLUMN_KEYS.sourceId),
                getSnakeCase(COLUMN_KEYS.source),
                getSnakeCase(COLUMN_KEYS.articleClicks),
                getSnakeCase(COLUMN_KEYS.uniqueClicks),
            ]
            break;
        case COLUMN_KEYS.articleType:
            columns = [
                getSnakeCase(COLUMN_KEYS.articleType),
                getSnakeCase(COLUMN_KEYS.articleClicks),
                getSnakeCase(COLUMN_KEYS.uniqueClicks),
            ]
            break;
        case COLUMN_KEYS.headline:
            columns = [
                getSnakeCase(COLUMN_KEYS.headline),
                getSnakeCase(COLUMN_KEYS.articleLink),
                getSnakeCase(COLUMN_KEYS.articleId),
                getSnakeCase(COLUMN_KEYS.interactionLink),
                getSnakeCase(COLUMN_KEYS.articleClicks),
                getSnakeCase(COLUMN_KEYS.uniqueClicks),
            ]
            break;

        default:
            columns = [
                getSnakeCase(COLUMN_KEYS.timeStamp),
            ]
            break;
    }
    return columns
}
export const getNecessaryColumn = (dimension) => {
    let column
    switch (dimension) {
        case COLUMN_KEYS.profileName:
            column = getSnakeCase(COLUMN_KEYS.userId)
            break;
        case COLUMN_KEYS.source:
            column = getSnakeCase(COLUMN_KEYS.sourceId)
            break;
        case COLUMN_KEYS.headline:
            column = getSnakeCase(COLUMN_KEYS.articleId)
            break;
        case COLUMN_KEYS.articleType:
            column = getSnakeCase(COLUMN_KEYS.articleType)
            break;
        // case COLUMN_KEYS.savedSearch:
        //     column = getSnakeCase(COLUMN_KEYS.savedSearchId)
        //     break;
        default:
            column = ''
            break;
    }
    return column
}
export const getAlphabetsArray = (isCaps = true) => {

    //set the default value of i & j to print A to Z
    let i = 65;
    let j = 91;

    //if the option is small set the value of i,j to print a to z
    if (!isCaps) {
        i = 97;
        j = 123;
    }

    const array = []
    for (let k = i; k < j; k++) {
        let value = String.fromCharCode(k);
        array.push(value);
    }
    return array;
}
export const getCleanObject = (obj) => {
    var cloned = lodash.cloneDeep(obj)
    for (var prop in cloned) {
        const value = cloned[prop]
        if (value === null ||
            value === '' ||
            (Array.isArray(value) && value.length == 0))
            delete cloned[prop];
    }
    return cloned
}
export const getSnakeCase = (camelCaseKey) => {
    return lodash.snakeCase(camelCaseKey)
}
export const getCamelCase = (kebabCaseKey) => {
    return lodash.camelCase(kebabCaseKey)
    return kebabCaseKey.toLowerCase().replace(/([-_][a-z])/g, group =>
        group
            .toUpperCase()
            .replace('-', '')
            .replace('_', '')
    )
}
export const getCapitalize = (text) => {
    return lodash.capitalize(text)
}
export const getTooltipStyles = () => {
    return {
        maxWidth: '226px',
        fontSize: '14px',
        color: '#444444',
        wordWrap: 'break-word'
    }
}
