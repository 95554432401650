import React, { useState, useRef } from "react";
import useDynamicHeightField from "./useDynamicHeightField";
import PropTypes from 'prop-types'
import cn from 'classnames'
import "./styles.less";

const ExpandableInput = (props) => {
  const [value, setValue] = useState(props.value)
  const maxLimit = props.maxLimit
  const textRef = useRef(null)
  useDynamicHeightField(textRef, value)

  const onChange = (e) => {
    const text = e.target.value
    if(maxLimit && text.length > maxLimit) {
      return
    }
    setValue(text);
    if(props.onTextChange){
      props.onTextChange( text)
    }
  };

  const borderColor = props.error ? '#AF292E' : '#B1B1B1';
  console.log('--borderColor--', props.error, borderColor);
  return (
    <>
      <textarea
          ref={textRef}
          onChange={onChange}
          value={value}
          className={cn('text-area', props.inputClassName)}
          style={{'borderColor': borderColor }}
      />
      {maxLimit ? <div className={cn('counter-text', props.counterClassName)}>{value.length}/{maxLimit}</div> : null}
    </>
  );
}

ExpandableInput.propTypes = {
  value: PropTypes.string.isRequired,
  onTextChange: PropTypes.func.isRequired,
  inputClassName: PropTypes.any,
  counterClassName: PropTypes.any,
  maxLimit: PropTypes.number,
  error: PropTypes.bool,
}

export default ExpandableInput
