import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import LoadingSpinner from 'app/common/LoadingSpinner'
import InlineSvg from 'app/common/InlineSvg'
import * as strings from 'app/strings'
import {defaultPrevented, isLeftClickEvent} from 'app/utils'

import * as constants from '../search-results-page-constants'

import styles from './TrendingBar.less'

const TRENDING_SECTIONS = [
  {
    key: 'general',
    title: 'Top Trending',
  },
  {
    key: 'legal',
    title: 'Practice Terms',
  },
]
const MENTIONS_SECTIONS = [
  {
    key: 'firms',
    title: 'Firms',
  },
  {
    key: 'clients',
    title: 'Companies',
  },
  {
    key: 'companies',
    title: 'Organizations',
  },
  {
    key: 'trackers',
    title: 'Topics',
  },
]


function TrendingItem({
  title,
  score,
  isPercentage,
  barValue,
  timeFrame = constants.DEFAULT_TIME_FRAME,
  onApply,
}) {
  const mentionsCount = isPercentage
    ? `${parseInt(score, 10)}% more mentions`
    : `${score} mention${score > 1 ? 's' : ''}`
  const onClick = defaultPrevented(event => {
    if (!isLeftClickEvent(event)) return
    onApply()
  })
  return (
    <a onClick={onClick} className={classNames(styles.item, 'trending-item')}>
      <div className={styles.itemTitle}>{title}</div>
      <div className={styles.mentionsText}>
        {mentionsCount} in the last {strings.timeFrameDisplay(timeFrame)}
      </div>
      <div style={{width: `${barValue * 100}%`}} className={styles.valueBar} />
    </a>
  )
}
TrendingItem.propTypes = {
  title: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  isPercentage: PropTypes.bool.isRequired,
  barValue: PropTypes.number.isRequired,
  timeFrame: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      start: PropTypes.object.isRequired,
      end: PropTypes.object.isRequired,
    }),
  ]),
  onApply: PropTypes.func.isRequired,
}


class TrendingSection extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    isMentions: PropTypes.bool.isRequired,
    timeFrame: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        start: PropTypes.object.isRequired,
        end: PropTypes.object.isRequired,
      }),
    ]),
    onApplyTerm: PropTypes.func.isRequired,
  }

  state = {
    isOpen: !!this.props.items.length,
  }

  render() {
    const {id, title, items} = this.props
    const {isOpen} = this.state
    const countDisplay = items.length ? ` (${items.length})` : ''
    return (
      <div className={classNames(styles.section, `trending-group-${id}`)}>
        <div
          onClick={this.toggleSectionOpen}
          className={classNames(
            styles.title,
            {[styles.open]: isOpen},
          )}
        >
          {title}{countDisplay}
          <InlineSvg
            src="/media/img/dropdown-arrow.svg"
            className={styles.arrow}
          />
        </div>
        <div>
          {this.renderContent()}
        </div>
      </div>
    )
  }

  renderContent() {
    if (!this.state.isOpen) {
      return null
    }
    if (!this.props.items.length) {
      return (
        <div className={styles.noData}>
          No {this.props.title} were found.
        </div>
      )
    }
    const maxScore =
      this.props.items.reduce((max, item) => Math.max(max, item.score), 0)
    return this.props.items.map(item =>
      <TrendingItem
        title={item.displayName}
        score={item.score}
        isPercentage={!this.props.isMentions}
        barValue={item.score / maxScore}
        onApply={() =>
          this.props.onApplyTerm({
            label: item.displayName,
            value: this.props.isMentions
              // Make sure to turn it into a number if it's a search ID.
              ? parseInt(item.narrowValue, 10)
              : item.narrowValue,
          })
        }
        key={item.narrowValue}
      />
    )
  }

  toggleSectionOpen = () => {
    this.setState(state => ({
      ...state,
      isOpen: !state.isOpen,
    }))
  }
}


function TrendingBar({data, timeFrame, onApplyTerm}) {
  const isLoading = !data
  const hasData = !!data && Object.values(data).some(data => data.length)
  return (
    <div className={classNames(styles.trending, 'trending')}>
      {isLoading ? (
        <div className={styles.loading}>
          <LoadingSpinner className={styles.spinner} />
          Loading trends...
        </div>
      ) : hasData ? (
        <>
          <div className={styles.header}>
            Trending
          </div>
          {TRENDING_SECTIONS.map(({key, title}) =>
            <TrendingSection
              id={key}
              title={title}
              items={data[key]}
              isMentions={false}
              timeFrame={timeFrame}
              onApplyTerm={onApplyTerm}
              key={key}
            />
          )}

          <div className={styles.header}>
            Mentions
          </div>
          {MENTIONS_SECTIONS.map(({key, title}) =>
            <TrendingSection
              id={key}
              title={title}
              items={data[key]}
              isMentions={true}
              timeFrame={timeFrame}
              onApplyTerm={onApplyTerm}
              key={key}
            />
          )}
        </>
      ) : (
        <>
          <div className={styles.header}>
            Trending
          </div>
          <div className={styles.noData}>
            No trending data available for this search.
          </div>
        </>
      )}
    </div>
  )
}
TrendingBar.propTypes = {
  data: PropTypes.object,
  timeFrame: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      start: PropTypes.object.isRequired,
      end: PropTypes.object.isRequired,
    }),
  ]),
  onApplyTerm: PropTypes.func.isRequired,
}
export default TrendingBar
