export const STATE_PROP_NAME = 'esg'

export function getSortOptions() {
  return [
    {
      label: 'Relevance',
      value: 'relevance',
    },
    {
      label: 'Date',
      value: 'story_date',
    }
  ]
}

export const MY_COMPANIES = "MyCompanies"
export const ALL_COMPANIES = "AllCompanies"
export const SHOW_MYCOMPANIES = "Show My Companies"
export const SHOW_ALLCOMPANIES = "Show All Companies"
export const MAX_COMPANY_PER_PAGE = 10
export const MAX_ALLCOMPANIES_PER_PAGE = 10
export const REMOVE_COMPANIES = "Remove from My Companies"
export const ADD_COMPANIES = "Add Company"


export const COLUMNS = {
  CHECKBOX: 'checkbox',
  COMPANY_NAME: 'companyName',
  HEALTH_SCORE: 'healthScore',
  INDUSTRIES: 'industries',
  GOVERNANCE: 'governance',
  PROSPERITY: 'prosperity',
  PEOPLE: 'people',
  PLANET: 'planet',
  MORE_DETAILS: 'moreDetails',
  ACTIONS: 'actions'
}
