import React from 'react'
import * as R from 'ramda'
import * as dateFns from 'date-fns'

import Table from 'app/common/Table'
import InlineSvg from 'app/common/InlineSvg'
import PaginatedTable from 'app/common/PaginatedTable'
import {COLUMNS} from './users-admin-constants'

export default function UsersTable({
  isGroupPage,
  currentFirmHasInsights,
  insightsLabel,
  users,
  currentFirmShowAlternateDateFormat,
  shouldShowRefreshDetails,
  selectedUserIds,
  handleUserCheckbox,
  showUserModal,
  showDeleteUserConfirmationModal,
  currentPage,
  pageCount,
  onPageChange,
  onSortChange,
  selectedColumns,
  showPublicationsModal
}) {
  const formatRefreshDate = (refreshDate) => {
    const DATE_FORMAT = currentFirmShowAlternateDateFormat
      ? 'do MMM yyyy'
      : 'MMM do yyyy'
    if (refreshDate !== null) {
      return (dateFns.format(refreshDate, DATE_FORMAT))
    } else {
      return null
    }
  }

  const formatRefreshInterval = (refreshInterval) => {
    if (refreshInterval == 0){
      return refreshInterval
    } else {
      return refreshInterval+"M"
    }
  }

  return (
      <PaginatedTable
        id="firm-users-table"
        defaultSort={{column: 'name', direction: 'asc'}}
        data={users}
        pageCount={pageCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
        onSortChange={onSortChange}
        className="users-table"
        >
      <Table.Column
        name={COLUMNS.CHECKBOX}
        baseWidth={40}
        growRatio={0}
        cellContents={user =>
          <input
            type="checkbox"
            className="checkbox"
            value={user.id}
            checked={selectedUserIds.includes(user.id)}
            onChange={(evt) => handleUserCheckbox(evt)}
          />
        }
      />

      <Table.Column
        name={COLUMNS.NAME}
        label="Name"
        isSortable
        sortBy={user => {
          // prefix firm library group name with a space to force it to be sorted first
          return (
            isGroupPage
              ? user.isFirmLibraryGroup
                  ? ` ${user.firstName}`
                  : user.firstName
              : user.displayNameLastFirst
          )
        }}
        baseWidth={150}
        cellContents={user => {
          return (
            <span>
              {user.isFirmLibraryGroup &&
                <span className="firm-library-icon">
                  <InlineSvg src="/media/img/firm-library-icon.svg"/>
                </span>
              }

              <a
                onClick={evt => showUserModal(evt, user.id)}
              >{user.displayNameLastFirst}</a>

              {
                user.isFirmAdmin
                  ? <span className="firm-admin">
                      {
                        user.isActive
                          ? <span>(Admin)</span>
                          : <span>(Admin - inactive)</span>
                      }
                    </span>
                  : null
              }

              {
                user.newUserPassword
                  ? <span> - Password: {user.newUserPassword}</span>
                  : null
              }
            </span>
          )
        }}
      />

      {
        currentFirmHasInsights &&
        <Table.Column
          name={COLUMNS.INSIGHTS}
          label={insightsLabel}
          sortBy={R.prop('hasInsights')}
          baseWidth={100}
          growRatio={0}
          cellContents={user => user.hasInsights && <div className="insights"/>}
        />
      }

      {
        isGroupPage &&
        <Table.Column
          name={COLUMNS.PRIVATE}
          label='Private'
          sortBy={user => user.isPrivateGroup}
          baseWidth={100}
          growRatio={0}
          cellContents={user => user.isPrivateGroup && <i className="checkmark"/>}
        />
      }

      {
        !isGroupPage && shouldShowRefreshDetails &&
        <Table.Column
          name={COLUMNS.LAST_REFRESH_EMAIL}
          label="Last Refresh Email"
          isSortable
          sortBy={user => user.lastRefreshAt}
          baseWidth={100}
          growRatio={0}
          cellContents={user => (
            <div className="refresh-detail">
              {formatRefreshDate(user.lastRefreshAt)}
            </div>
          )}
        />
      }

      {
        !isGroupPage && shouldShowRefreshDetails &&
        <Table.Column
          name={COLUMNS.REFRESH_COMPLETED}
          label="Last Refresh Completed"
          isSortable
          sortBy={user => user.refreshCompletedAt}
          baseWidth={100}
          growRatio={0}
          cellContents={user => (
            <div className="refresh-detail">
              {formatRefreshDate(user.refreshCompletedAt)}
            </div>
          )}
        />
      }

      {
        !isGroupPage && shouldShowRefreshDetails &&
        <Table.Column
          name={COLUMNS.NEXT_REFRESH_EMAIL}
          label='Next Scheduled Refresh'
          isSortable
          sortBy={user => user.nextRefreshEmailAt}
          baseWidth={100}
          growRatio={0}
          cellContents={user => (
            <div className="refresh-detail">
              {formatRefreshDate(user.nextRefreshEmailAt)}
            </div>
          )}
        />
      }

      {
        !isGroupPage && shouldShowRefreshDetails &&
        <Table.Column
          name={COLUMNS.REFRESH_INTERVAL}
          label='Refresh Interval'
          isSortable
          sortBy={user => user.refreshInterval}
          baseWidth={90}
          growRatio={0}
          cellContents={user => (
            <div className="refresh-detail">
              {formatRefreshInterval(user.refreshInterval)}
            </div>
          )}
        />
      }

      {
        isGroupPage && selectedColumns && selectedColumns.includes(COLUMNS.OFFICE_LOCATION) ?
          <Table.Column
            name={COLUMNS.OFFICE_LOCATION}
            label='Office Location'
            isSortable
            sortBy={user => user.firmLocationName}
            baseWidth={173}
            growRatio={0}
            cellContents={user => user.firmLocationName}
          /> : null
      }

      {
        isGroupPage && selectedColumns && selectedColumns.includes(COLUMNS.COUNTRY) ?
          <Table.Column
            name={COLUMNS.COUNTRY}
            label='Country'
            isSortable
            sortBy={user => user.location.name}
            baseWidth={173}
            growRatio={0}
            cellContents={user => user.location.name}
          /> : null
      }

      {
        isGroupPage && selectedColumns && selectedColumns.includes(COLUMNS.RECURRING_PUBLICATIONS) ?
          <Table.Column
            name={COLUMNS.RECURRING_PUBLICATIONS}
            label='Recurring Publications'
            isSortable
            sortBy={user => user.recurringPubCount}
            baseWidth={173}
            growRatio={0}
            cellContents={user => {
              let publicationCount = user.recurringPubCount
              if (publicationCount === 0) {
                publicationCount = 0
              }
              return (
                <a
                  className="assignment-count"
                  onClick={
                    () => showPublicationsModal("Recurring", user)
                  }
                >
                  {publicationCount}
                </a>
              )
            }}
          /> : null
      }

      {
        isGroupPage && selectedColumns && selectedColumns.includes(COLUMNS.TEMPLATE_PUBLICATIONS) ?
          <Table.Column
            name={COLUMNS.TEMPLATE_PUBLICATIONS}
            label='Template Publications'
            isSortable
            sortBy={user => user.templatePubCount}
            baseWidth={173}
            growRatio={0}
            cellContents={user => {
              let publicationCount = user.templatePubCount
              if (publicationCount === 0) {
                publicationCount = 0
              }
              return (
                <a
                  className="assignment-count"
                  onClick={
                    () => showPublicationsModal("Template", user)
                  }
                >
                  {publicationCount}
                </a>
              )
            }}
          /> : null
      }

{
        isGroupPage && selectedColumns && selectedColumns.includes(COLUMNS.EMAIL_NOTIFICATIONS) ?
          <Table.Column
            name={COLUMNS.EMAIL_NOTIFICATIONS}
            label='Email Notifications'
            // isSortable
            // sortBy={user => user.emailNotificationCount}
            baseWidth={173}
            growRatio={0}
            cellContents={user => user.emailNotificationCount}
          /> : null
      }

      <Table.Column
        name={COLUMNS.ACTIONS}
        label=""
        baseWidth={180}
        growRatio={0}
        cellContents={user => (
          <div className="actions">
            <a className="action" onClick={evt => showUserModal(evt, user.id)}>Edit</a>

            {
              user.isActive &&
              <a className="action" href={`/masquerade/as/?user_id=${user.id}`} >Login As</a>
            }

            {
              !user.isActive &&
              <a className="action"
                onClick={evt => showDeleteUserConfirmationModal(evt, [user.id])}
              >
                Delete
              </a>
            }
          </div>
        )}
      />
    </PaginatedTable>
  )
}
