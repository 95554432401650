import React, { useState, useEffect, useMemo } from "react";
import {
    useReactTable,
    getCoreRowModel,
    getExpandedRowModel,
    flexRender,
} from "@tanstack/react-table";
import {
    COLUMN_KEYS,
    COLUMN_HEADERS,
    DIMENSIONS_OPTION,
    DIMENSIONS,
    ADMIN_ROLE_OPTIONS,
    PAGINATION_OPTIONS,
    PROFILE_TYPE_OPTIONS,
    PROFILE_GROUPING_KEYS,
    DEFAULT_VISIBLE_COLUMNS,
    NO_DIMENSION_COLUMNS,
    NO_DIMENSION_MESSAGE,
    SCOPE_ICONS,
    TEXT_MAX_LENGTH,
    FILTERS_KEYS,
    TIME_STAMP_FORMAT,
    DATE_RANGE as GRID_DATE_RANGE_LABEL,
    NO_DATA_MESSAGE,
    SORTING_TYPE,
    ALL_COLUMNS,
    LAST_INVISIBLE_DIMENSION,
    DEFAULT_ROWS_TO_FETCH_ON_EXPAND,
    SHOW_ALL_ARTICLES as SHOW_ALL_TEXT,
    SHOW_MORE_ARTICLES as SHOW_MORE_TEXT,
    SHOW_MORE_ABLE_DIMENSIONS,
    GENERAL_SEARCH_PLACEHOLDER,
} from '../usage-constants'
import InputBlock from 'app/common/InputBlock'
import Dropdown from 'app/common/Dropdown'
import MultiSelectDropdown from 'app/common/MultiSelectDropdown'
import './styles.less'
import Tooltip from "app/common/Tooltip";
import PageSelector from "app/common/PaginatedTable/PageSelector";
import { useDispatch, useSelector } from "react-redux";
import * as usageActions from '../usage-actions'
import * as selectors from '../usage-selectors'
import * as dateFns from 'date-fns'
import { stringFormat } from "app/common/utils";
import classNames from 'classnames'
import DebouncedSearchInput from "app/common/DebouncedSearchInput";
import PopoverDropdown from "app/common/PopoverDropdown";
import lodash from 'lodash'
import { fetchUsageGridData } from "../usage-api";
import { ISO_DATE_FORMAT, DATE_FORMAT } from "app/constants";
import {
    columnsToFetch,
    getNecessaryColumn,
    getAlphabetsArray,
    getCamelCase,
    getSnakeCase,
    getCapitalize,
    getTooltipStyles,
} from "../utils";

const Filter = ({
    column,
    table,
    onSelection,
    filterVisibilityChange,
    containerClassName,
    enableMultiSelection,
    enableSearch,
    enableTooltip,
    showSubtitle,
    getOptions,
    placeholder
}) => {
    const columnHeader = column.columnDef.header

    const handleFilterVisibilityChange = (isVisible) => {
        if (filterVisibilityChange) {
            filterVisibilityChange(column, isVisible)
        }
    }
    const handleOnFilterSelection = (selectedItems) => {
        if (onSelection) {
            onSelection(column, selectedItems)
        }
    }
    const options = getOptions ? getOptions() : []

    return (
        <PopoverDropdown
            label={columnHeader}
            options={options}
            multiSelection={enableMultiSelection}
            enableFilter={enableSearch}
            enableTooltip={enableTooltip}
            showSubtitle={showSubtitle}
            placeholder={placeholder}
            containerClassName={containerClassName}
            filterVisibilityChange={handleFilterVisibilityChange}
            onSelection={handleOnFilterSelection}>
            <div />
        </PopoverDropdown>
    )
}
const Chip = ({ text, onCancel }) => {
    return (
        <div className="chip">
            <div>{text}</div>
            <div className="cross-icon" onClick={onCancel} />
        </div>
    )
}
const ReportingGrid = () => {
    const dispatch = useDispatch()

    const isLoading = useSelector(selectors.isLoading)
    const usageGridData = useSelector(selectors.usageGrid)
    const usageGridFilters = useSelector(selectors.usageGridFilters)
    const { pageSize, currentPage, start, end, firstDimension,
        freeTextSearch, sortField, sortDirection } = usageGridFilters
    const gridColumnFilters = useSelector(selectors.gridColumnFilters)
    const filtersData = useSelector(selectors.filtersData)

    const [data, setData] = useState([]);
    const [grouping, setGrouping] = useState([])
    const [globalFilter, setGlobalFilter] = useState('')
    const [columnVisibility, setColumnVisibility] = useState({})
    const [columnOrder, setColumnOrder] = useState(DEFAULT_VISIBLE_COLUMNS)
    const [expanded, setExpanded] = useState({})
    const [sorting, setSorting] = useState([])
    const [profileType, setProfileType] = useState(null)
    const [adminRole, setAdminRole] = useState(null)
    const [filterOn, setFilterOn] = useState({})
    const [isNoDimensionCase, setIsNoDimensionCase] = useState(false)

    const columns = useMemo(() => [
        {
            accessorKey: COLUMN_KEYS.profileName,
            header: COLUMN_HEADERS[COLUMN_KEYS.profileName],
            minSize: 110,
            size: 120,
            maxSize: 130,
            enableColumnFilter: true,
            filterConfig: {
                enableSearch: true,
                enableMultiSelection: false,
                selectedOptions: [gridColumnFilters[FILTERS_KEYS.profileStartsWith]],
                getOptions: () => {
                    const options = [{ value: '', label: '-' }]
                    options.push(...getAlphabetsArray().map(type => {
                        return {
                            value: type,
                            label: type,
                        }
                    }))
                    return options
                },
            },
            enableSorting: true,
            cell: info => {
                const cellValue = info.getValue();
                if (!cellValue) {
                    return ''
                }
                else
                    return <div className="group-column">
                        <div className={info.row.original[COLUMN_KEYS.isGroup] ? 'group-icon' : 'user-icon'} />
                        {getFormattedText(cellValue, TEXT_MAX_LENGTH.profileName)}
                    </div>
            },
        },
        {
            accessorKey: COLUMN_KEYS.profileEmail,
            header: COLUMN_HEADERS[COLUMN_KEYS.profileEmail],
            minSize: 110,
            size: 120,
            maxSize: 140,
            enableColumnFilter: true,
            filterConfig: {
                enableSearch: true,
                enableMultiSelection: false,
                selectedOptions: [gridColumnFilters[FILTERS_KEYS.emailStartsWith]],
                getOptions: () => {
                    const options = [{ value: '', label: '-' }]
                    options.push(...getAlphabetsArray().map(type => {
                        return {
                            value: type,
                            label: type,
                        }
                    }))
                    return options
                },
            },
            enableSorting: true,
            cell: info => {
                const cellValue = info.getValue();
                if (!cellValue) {
                    return ''
                }
                else {
                    return getFormattedText(cellValue, TEXT_MAX_LENGTH.email)
                }
            },
        },
        {
            accessorKey: COLUMN_KEYS.totalUsers,
            header: COLUMN_HEADERS[COLUMN_KEYS.totalUsers],
            minSize: 50,
            size: 60,
            maxSize: 70,
            enableColumnFilter: false,
            enableSorting: false,
            cell: info => {
                const cellValue = info.getValue();
                if (!cellValue) {
                    return ''
                }
                else {
                    return info.getValue()
                }
            },
        },
        {
            accessorKey: COLUMN_KEYS.source,
            header: COLUMN_HEADERS[COLUMN_KEYS.source],
            minSize: 90,
            size: 100,
            maxSize: 110,
            enableColumnFilter: true,
            filterConfig: {
                enableMultiSelection: true,
                enableSearch: true,
                selectedOptions: gridColumnFilters[FILTERS_KEYS.sourceIds],
                getOptions: () => {
                    const { sources } = filtersData
                    return sources.map(source => {
                        const formattedLabel = getFormattedText(source.name, TEXT_MAX_LENGTH.email, false)
                        return {
                            value: String(source.id),
                            label: formattedLabel,
                            subTitle: source.url,
                            tooltip: source.url
                        }
                    })
                },
                showSubtitle: true,
                enableTooltip: true,
                placeholder: 'Search by URL or Name'
            },
            enableSorting: true,
            cell: info => {
                const cellValue = info.getValue();
                if (!cellValue) {
                    return ''
                }
                else {
                    return getFormattedText(cellValue, TEXT_MAX_LENGTH.source)
                }
            }
        },
        // // TODO: Search related columns code, will be used in future release
        // {
        //     accessorKey: COLUMN_KEYS.savedSearch,
        //     header: COLUMN_HEADERS[COLUMN_KEYS.savedSearch],
        //     minSize: 90,
        //     size: 100,
        //     maxSize: Number.MAX_SAFE_INTEGER,
        //     enableColumnFilter: true,
        //     filterConfig: {
        //         enableMultiSelection: true,
        //         enableSearch: true,
        //         selectedOptions: gridColumnFilters[FILTERS_KEYS.savedSearchIds],
        //         getOptions: () => {
        //             const { savedSearches } = jsonData.filters
        //             // const { savedSearches } = filterData
        //             return savedSearches.map(search => {
        //                 return {
        //                     value: String(search.id),
        //                     label: search.name,
        //                 }
        //             })
        //         },
        //     },
        //     enableSorting: true,
        //     cell: info => {
        //         const iconClassName = SCOPE_ICONS[info.row.original[COLUMN_KEYS.savedSearchScope]]
        //         const cellValue = info.getValue();
        //         if (!cellValue) {
        //             return ''
        //         }
        //         else {
        //             return (<div className="group-column">
        //                 <div className={iconClassName} />
        //                 {getFormattedText(info.row.original[COLUMN_KEYS.savedSearch], TEXT_MAX_LENGTH.savedSearch)}
        //             </div>)
        //         }
        //     }
        // },
        // {
        //     accessorKey: COLUMN_KEYS.savedSearchType,
        //     header: COLUMN_HEADERS[COLUMN_KEYS.savedSearchType],
        //     minSize: 90,
        //     size: 100,
        //     maxSize: 110,
        //     enableColumnFilter: true,
        //     filterConfig: {
        //         enableMultiSelection: true,
        //         enableSearch: false,
        //         selectedOptions: gridColumnFilters[FILTERS_KEYS.savedSearchTypes],
        //         getOptions: () => {
        //             const { savedSearchTypes } = jsonData.filters
        //             // const { savedSearchTypes } = filterData
        //             return savedSearchTypes.map(type => {
        //                 return {
        //                     value: type,
        //                     label: type,
        //                 }
        //             })
        //         },
        //     },
        //     enableSorting: true,
        //     cell: info => {
        //         const cellValue = info.getValue();
        //         if (!cellValue) {
        //             return ''
        //         }
        //         else {
        //             return getFormattedText(info.getValue(), TEXT_MAX_LENGTH.savedSearchType)
        //         }
        //     }
        // },
        {
            accessorKey: COLUMN_KEYS.articleType,
            header: COLUMN_HEADERS[COLUMN_KEYS.articleType],
            minSize: 90,
            size: 100,
            maxSize: 110,
            enableColumnFilter: true,
            filterConfig: {
                enableMultiSelection: true,
                enableSearch: false,
                selectedOptions: gridColumnFilters[FILTERS_KEYS.articleTypes],
                getOptions: () => {
                    const { articleTypes } = filtersData
                    const options = []
                    options.push(...articleTypes.map(type => {
                        return {
                            value: type,
                            label: getCapitalize(type),
                        }
                    }))
                    return options
                },
            },
            enableSorting: true,
            cell: info => {
                const cellValue = getCapitalize(info.getValue());
                if (!cellValue) {
                    return ''
                }
                else {
                    return getFormattedText(cellValue, TEXT_MAX_LENGTH.articleType)
                }
            },
        },
        {
            accessorKey: COLUMN_KEYS.headline,
            header: COLUMN_HEADERS[COLUMN_KEYS.headline],
            minSize: 120,
            maxSize: Number.MAX_SAFE_INTEGER,
            enableColumnFilter: true,
            filterConfig: {
                enableMultiSelection: true,
                enableSearch: true,
                enableTooltip: true,
                selectedOptions: gridColumnFilters[FILTERS_KEYS.articleIds],
                getOptions: () => {
                    const { articles } = filtersData
                    return articles.map(article => {
                        const formattedLabel = getFormattedText(article.headline, TEXT_MAX_LENGTH.shortHeadline, false);
                        return {
                            value: String(article.id),
                            label: formattedLabel,
                            tooltip: article.headline
                        }
                    })
                },
            },
            // enableSorting: true,
            enableSorting: false,
            cell: info => {
                const cellValue = info.getValue()
                const maxLength = isNoDimensionCase ? TEXT_MAX_LENGTH.longHeadline : TEXT_MAX_LENGTH.headline
                if (!cellValue) {
                    return ''
                }
                else {
                    return (<a target="_blank" href={info.row.original[COLUMN_KEYS.interactionLink]}>
                        {getTooltip(getFormattedText(cellValue, maxLength, false), info.row.original[COLUMN_KEYS.articleLink])}
                    </a>)
                }
            }
        },
        {
            accessorKey: COLUMN_KEYS.uniqueClicks,
            header: COLUMN_HEADERS[COLUMN_KEYS.uniqueClicks],
            minSize: 60,
            size: 70,
            maxSize: 80,
            enableColumnFilter: false,
            enableSorting: true,
            cell: info => {
                const cellValue = info.getValue();
                if (!cellValue) {
                    return ''
                }
                else {
                    return cellValue
                }
            }
        },
        {
            accessorKey: COLUMN_KEYS.articleClicks,
            header: COLUMN_HEADERS[COLUMN_KEYS.articleClicks],
            minSize: 50,
            size: 60,
            maxSize: 70,
            enableColumnFilter: false,
            enableSorting: true,
            cell: info => {
                const cellValue = info.getValue();
                if (!cellValue) {
                    return ''
                }
                else {
                    return cellValue
                }
            }
        },
        {
            accessorKey: COLUMN_KEYS.timeStamp,
            header: COLUMN_HEADERS[COLUMN_KEYS.timeStamp],
            minSize: 60,
            size: 70,
            maxSize: 90,
            enableColumnFilter: false,
            enableSorting: true,
            cell: info => {
                const cellValue = info.getValue();
                if (!cellValue) {
                    return ''
                }
                else {
                    return dateFns.format(new Date(cellValue), TIME_STAMP_FORMAT)
                }
            }
        },
    ], [filtersData, isNoDimensionCase])

    const table = useReactTable({
        data,
        columns,
        state: {
            columnVisibility,
            columnOrder,
            expanded,
            sorting
        },
        onColumnVisibilityChange: setColumnVisibility,
        onColumnOrderChange: setColumnOrder,
        onExpandedChange: setExpanded,
        getSubRows: originalRow => originalRow.rows,
        getExpandedRowModel: getExpandedRowModel(),
        getCoreRowModel: getCoreRowModel(),
    });

    const dimensionsOptions = DIMENSIONS_OPTION
    const selectedDimensions = DIMENSIONS.filter(dim => grouping.includes(dim.value))
    const profileTypeOptions = Object.entries(PROFILE_TYPE_OPTIONS).map(
        item => {
            const [value, label] = item
            return {
                label,
                value
            }
        }
    )
    const adminRoleOptions = Object.entries(ADMIN_ROLE_OPTIONS).map(
        item => {
            const [value, label] = item
            return {
                label,
                value
            }
        }
    )
    const paginationOptions = Object.entries(PAGINATION_OPTIONS).map(
        item => {
            const [value, label] = item
            return {
                label,
                value
            }
        }
    )

    const prepareData = () => {
        const rows = []
        const additionalProps = {
            depth: 0,
            dimension: firstDimension,
            totalChildCount: null,
            currentChildCount: null,
            parent: null
        }
        for (let index = 0; index < usageGridData.data.length; index++) {
            const row = { ...usageGridData.data[index] };
            row.additionalProps = { ...additionalProps }
            rows.push(row)
        }
        resetExpanding()
        setData(rows)
        setLoading(false)
    }
    const applyDefaultGrouping = () => {
        const _grouping = DIMENSIONS.map(dim => dim.value)
        _grouping.push(LAST_INVISIBLE_DIMENSION)
        setGrouping(_grouping)
    }
    const applyDefaultSorting = () => {
        setSorting([{ id: COLUMN_KEYS.uniqueClicks, desc: true }])
    }
    const getIsShowMoreAbleRow = (row) => {
        const { parent, dimension, depth } = row.original.additionalProps
        if (!depth)
            return false
        else {
            const parentProps = parent.additionalProps
            const showMoreRow = (!dimension || (dimension != grouping[0] && SHOW_MORE_ABLE_DIMENSIONS.includes(dimension))) &&
                parentProps.totalChildCount > parentProps.currentChildCount &&
                parentProps.currentChildCount == row.index + 1
            return showMoreRow
        }
    }
    const setLoading = (isVisible) => {
        if (isVisible)
            dispatch(usageActions.showNewLoader())
        else
            dispatch(usageActions.hideNewLoader())
    }
    const addMoreRows = async (originalRow, noOfRows, callback) => {
        const askedRowsToFetch = noOfRows
        if (originalRow) {
            const { dimension, parentDimensions, depth, totalChildCount, currentChildCount } = originalRow.additionalProps
            // const newParentDimensions = [...(parentDimensions ?? []), { [getKebabCase(dimension)]: originalRow[dimension] }]
            const newParentDimensions = [...(parentDimensions ?? [])]
            const necessaryColumn = getNecessaryColumn(dimension);
            if (necessaryColumn) {
                const value = originalRow[getCamelCase(necessaryColumn)]
                if (value) {
                    newParentDimensions.push({ [necessaryColumn]: String(value) })
                }
            }
            const nextDimensionExists = grouping.length != depth + 1
            const newDepth = depth + 1

            let nextDimension, columnsToSelect, distinctColumn
            const additionalProps = {
                depth: newDepth,
                dimension: null,
                parentDimensions: newParentDimensions,
                totalChildCount: null,
                currentChildCount: null,
                parent: originalRow
            }

            if (nextDimensionExists) {
                nextDimension = grouping[depth + 1];
                columnsToSelect = columnsToFetch(nextDimension)
                distinctColumn = getNecessaryColumn(nextDimension)
                additionalProps.dimension = nextDimension
            }
            else {
                const columnsReceived = [];
                grouping.map(group => {
                    columnsReceived.push(...columnsToFetch(group))
                });
                if (isNoDimensionCase) {
                    columnsToSelect = columnsToFetch()
                }
                else {
                    columnsToSelect = ALL_COLUMNS.filter(column => !columnsReceived.includes(getSnakeCase(column))).map(column => getSnakeCase(column))
                }
                distinctColumn = ''
                additionalProps.dimension = null
            }

            let oldTotalChildCount = totalChildCount ?? 0
            let oldCurrentChildCount = currentChildCount ?? 0
            let rowsToFetch = null

            if (!nextDimensionExists || (nextDimension != grouping[0] && SHOW_MORE_ABLE_DIMENSIONS.includes(nextDimension))) {
                rowsToFetch = askedRowsToFetch ?? DEFAULT_ROWS_TO_FETCH_ON_EXPAND
                if (oldTotalChildCount && oldCurrentChildCount) {
                    // fetching more rows
                    const remainingRows = oldTotalChildCount - oldCurrentChildCount
                    rowsToFetch = remainingRows < rowsToFetch ? remainingRows : rowsToFetch
                }
            }
            else {
                // to fetch all child
                rowsToFetch = null
            }

            let response, fetchedRows = []
            try {
                setLoading(true)
                response = await fetchGridDimensionData(distinctColumn, columnsToSelect, newParentDimensions, rowsToFetch, oldCurrentChildCount)
            } catch (error) {
                setLoading(false)
                callback?.(null)
            }

            if (response && response.body) {
                fetchedRows = response.body.data
                const fetchedTotalCount = response.body.totalCount
                let newCurrentChildCount, newTotalChildCount

                if (rowsToFetch) {
                    newCurrentChildCount = oldCurrentChildCount + fetchedRows.length
                    newTotalChildCount = fetchedTotalCount
                }
                else {
                    newCurrentChildCount = fetchedRows.length
                    newTotalChildCount = fetchedRows.length
                }

                originalRow.additionalProps.totalChildCount = newTotalChildCount
                originalRow.additionalProps.currentChildCount = newCurrentChildCount

                for (let index = 0; index < fetchedRows.length; index++) {
                    const row = fetchedRows[index];
                    row.additionalProps = {...additionalProps}
                }
            }

            callback?.(fetchedRows)
        }
        else {
            callback?.([])
        }
    }
    const fetchGridData = (resetCurrentPage = true) => {
        if (resetCurrentPage) {
            // reset to page 1
            updateFilters({
                [FILTERS_KEYS.currentPage]: 1
            })
        }
        dispatch(usageActions.fetchUsageGridData())
    }
    const fetchGridDimensionData = (distinctColumn, columnsToSelect, whereConditions, rowsCount, rowsOffSet, callback) => {
        const params = {}

        Object.keys(usageGridFilters).forEach(key => {
            if (key === 'columnFilters') { return }
            const value = usageGridFilters[key]
            if (value || typeof value === 'boolean') {
                params[key] = value
            }
        })
        Object.keys(usageGridFilters.columnFilters).forEach(key => {
            const value = usageGridFilters.columnFilters[key]
            if (value || typeof value === 'boolean') {
                params[key] = value
            }
        })
        params.start = dateFns.format(start, ISO_DATE_FORMAT)
        params.end = dateFns.format(end, ISO_DATE_FORMAT)

        delete params[FILTERS_KEYS.firstDimension]
        delete params[FILTERS_KEYS.pageSize]
        delete params[FILTERS_KEYS.currentPage]

        if (columnsToSelect) {
            params[FILTERS_KEYS.columnsToSelect] = columnsToSelect
        }
        else {
            params[FILTERS_KEYS.columnsToSelect] = columnsToFetch()
        }

        if (distinctColumn) {
            params[FILTERS_KEYS.distinctColumn] = distinctColumn
        }
        else {
            delete params[FILTERS_KEYS.distinctColumn]
        }

        if (whereConditions) {
            params[FILTERS_KEYS.whereConditions] = whereConditions
        }
        else {
            delete params[FILTERS_KEYS.whereConditions]
        }

        if (rowsCount) {
            params[FILTERS_KEYS.rowsCount] = rowsCount
            params[FILTERS_KEYS.rowsOffset] = rowsOffSet
        }
        else {
            delete params[FILTERS_KEYS.rowsCount]
            delete params[FILTERS_KEYS.rowsOffset]
        }

        return fetchUsageGridData(params, callback)
    }
    const fetchMoreRows = (parent, moreRowsCount) => {
        addMoreRows(parent, moreRowsCount, (moreRows) => {
            if (!moreRows) {
                setLoading(false)
                return
            }
            parent.rows.push(...moreRows)
            const updatedData = lodash.cloneDeep(data)
            setData(updatedData)
            setLoading(false)
        })
    }
    const getTooltip = (text, label) => {
        return <Tooltip
            render={() => <div style={getTooltipStyles()}>
                {label}
            </div>}
            containerClassName='single-line-text'
            direction='bottom'
            noBorder>
            {text}
        </Tooltip>
    }
    const getFormattedText = (text, maxLength, addTooltip = true, tooltipText) => {
        if (text && text.trim().length > maxLength) {
            // const croppedText = text
            const croppedText = `${text.substring(0, maxLength)}...`
            if (addTooltip) {
                return getTooltip(croppedText, tooltipText ?? text)
            }
            else {
                return croppedText
            }
        }
        else {
            return text
        }
    }
    const updateFilters = (filters) => {
        dispatch(usageActions.setUsageGridFilters(filters))
    }
    const fixColumnsOrder = (updatedAccessorKeys) => {
        const defaultVisibleColumns = [...DEFAULT_VISIBLE_COLUMNS]
        if (isNoDimensionCase) {
            setColumnOrder(defaultVisibleColumns)
            return
        }
        let newOrder = [...updatedAccessorKeys, ...defaultVisibleColumns.filter(columnId => !updatedAccessorKeys.includes(columnId))]
        if (updatedAccessorKeys.includes(PROFILE_GROUPING_KEYS[0])) {
            newOrder = newOrder.filter(columnId => columnId !== COLUMN_KEYS.totalUsers)
            const index = newOrder.findIndex(key => key === PROFILE_GROUPING_KEYS[PROFILE_GROUPING_KEYS.length - 1]) + 1
            // newOrder.splice(index, 0, COLUMN_KEYS.totalUsers)
            newOrder.splice(index, 0, COLUMN_KEYS.profileEmail, COLUMN_KEYS.totalUsers) // putting email also because email grouping is not applied
        }
        setColumnOrder(newOrder)
    }
    const resetExpanding = () => {
        //collapse all expanding
        setExpanded({})
    }
    const handleRemoveDimension = (columnKey) => {
        let updatedColumnKeys
        if (columnKey === PROFILE_GROUPING_KEYS[0] && grouping.includes(columnKey)) {
            updatedColumnKeys = [...grouping.filter(op => !PROFILE_GROUPING_KEYS.includes(op))]
        }
        else {
            updatedColumnKeys = [...grouping.filter(op => op !== columnKey)]
        }
        setGrouping(updatedColumnKeys)

        if (updatedColumnKeys && updatedColumnKeys.length == 1 && updatedColumnKeys == LAST_INVISIBLE_DIMENSION) {
            handleNoDimensionSelectionCase()
        }
        else {
            handleNormalCase()
        }

        fixColumnsOrder(updatedColumnKeys)
        handleGroupByChange(updatedColumnKeys)
    }
    const handleShowDimensions = (columnKeys) => {
        const updatedColumnKeys = [...columnKeys, LAST_INVISIBLE_DIMENSION]
        if (updatedColumnKeys.includes(PROFILE_GROUPING_KEYS[0])) {
            const index = updatedColumnKeys.findIndex(key => key === PROFILE_GROUPING_KEYS[0]) + 1
            updatedColumnKeys.splice(index, 0, ...PROFILE_GROUPING_KEYS.slice(1))
        }
        setGrouping(updatedColumnKeys)

        if (updatedColumnKeys && updatedColumnKeys.length == 1 && updatedColumnKeys == LAST_INVISIBLE_DIMENSION) {
            handleNoDimensionSelectionCase()
        }
        else {
            handleNormalCase()
        }
        fixColumnsOrder(updatedColumnKeys)
        handleGroupByChange(updatedColumnKeys)
    }
    const handleNoDimensionSelectionCase = () => {
        if (isNoDimensionCase) {
            return
        }
        setIsNoDimensionCase(true)
        const invisibleColumns = {}
        table.getAllColumns().map(column => {
            if (!NO_DIMENSION_COLUMNS.includes(column.id))
                invisibleColumns[column.id] = false
        })
        setColumnVisibility(invisibleColumns)
    }
    const handleNormalCase = () => {
        if (!isNoDimensionCase) {
            return
        }
        setIsNoDimensionCase(false)
        setColumnVisibility({})
    }
    const handleProfileTypeVisibilityChange = (option) => {
        let _profileType;
        if (option.label === PROFILE_TYPE_OPTIONS.group) {
            _profileType = true
        }
        else if (option.label === PROFILE_TYPE_OPTIONS.individual) {
            _profileType = false
        }
        else {
            _profileType = null
        }

        setProfileType(_profileType)
        updateFilters({ [FILTERS_KEYS.isGroup]: _profileType })
        fetchGridData()
    }
    const handleAdminRoleVisibilityChange = (option) => {
        let _adminRole;
        if (option.label === ADMIN_ROLE_OPTIONS.admin) {
            _adminRole = true
        }
        else if (option.label === ADMIN_ROLE_OPTIONS.nonAdmin) {
            _adminRole = false
        }
        else {
            _adminRole = null
        }

        setAdminRole(_adminRole)
        updateFilters({ [FILTERS_KEYS.isAdmin]: String(_adminRole ?? '') })
        fetchGridData()
    }
    const handleColumnExpand = (cell, row) => {
        const originalRow = row.original
        const { rows, additionalProps } = originalRow;

        if (row.getIsExpanded()) {
            delete expanded[row.id]
            setExpanded({ ...expanded })
            //reduce extra rows to default
            if (rows.length > DEFAULT_ROWS_TO_FETCH_ON_EXPAND) {
                rows.splice(DEFAULT_ROWS_TO_FETCH_ON_EXPAND)
                additionalProps.currentChildCount = rows.length
                const updatedData = lodash.cloneDeep(data)
                setData(updatedData)
            }
        }
        else if (rows && rows.length > 0) {
            setExpanded({ ...expanded, [row.id]: true })
        }
        else {
            // const rowsToFetch = DEFAULT_ROWS_TO_FETCH_ON_EXPAND // On All columns Expand
            const rowsToFetch = null
            addMoreRows(originalRow, rowsToFetch, (moreRows) => {
                if (!moreRows) {
                    setLoading(false)
                    return
                }
                originalRow.rows = moreRows
                const updatedData = lodash.cloneDeep(data)
                setData(updatedData)
                setExpanded({ ...expanded, [row.id]: true })
                setLoading(false)
            })
        }
    }
    const handleOnPageSizeChange = (size) => {
        updateFilters({
            [FILTERS_KEYS.pageSize]: size,
        })
        fetchGridData()
    }
    const handleOnPageChange = (page) => {
        updateFilters({ [FILTERS_KEYS.currentPage]: page })
        fetchGridData(false)
    }
    const handleFreeSearchTextChange = (value) => {
        const freeText = value.trim();
        if (freeText !== globalFilter) {
            setGlobalFilter(freeText)
            updateFilters({ [FILTERS_KEYS.freeTextSearch]: freeText })
            fetchGridData()
        }
    }
    const handleGroupByChange = (updatedColumnKeys) => {
        if (updatedColumnKeys[0] !== firstDimension) {
            const firstGrouping = updatedColumnKeys[0]
            updateFilters({
                [FILTERS_KEYS.firstDimension]: firstGrouping,
                [FILTERS_KEYS.distinctColumn]: getNecessaryColumn(firstGrouping),
                [FILTERS_KEYS.columnsToSelect]: columnsToFetch(firstGrouping),
                [FILTERS_KEYS.whereConditions]: []
            })
        }
        resetExpanding()
        fetchGridData()
    }
    const handleOnSortingChange = (header) => {
        const column = header.column
        const colId = column.columnDef.accessorKey
        const isAlreadyThere = sorting && sorting.length > 0 && colId === sorting[0].id
        const filter = {}
        if (isAlreadyThere) {
            if (!sorting[0].desc) {
                //descending order
                setSorting([{ id: colId, desc: true }])
                filter[FILTERS_KEYS.sortField] = getSnakeCase(colId)
                filter[FILTERS_KEYS.sortDirection] = SORTING_TYPE.DESC
            }
            else {
                // remove sorting
                setSorting([])
                filter[FILTERS_KEYS.sortField] = ''
                filter[FILTERS_KEYS.sortDirection] = ''
            }
        }
        else {
            // ascending order
            setSorting([{ id: colId, desc: false }])
            filter[FILTERS_KEYS.sortField] = getSnakeCase(colId)
            filter[FILTERS_KEYS.sortDirection] = SORTING_TYPE.ASC
        }
        updateFilters(filter)
        fetchGridData()
    }
    const handleColumnFilterVisibilityChange = (column, isVisible) => {
        const columnId = column.columnDef.accessorKey;
        setFilterOn(prevState => { return { ...prevState, [columnId]: isVisible } })
    }
    const handleOnFilterSelection = (column, selectedItems) => {
        const columnId = column.columnDef.accessorKey;
        let filterColumn = ''
        switch (columnId) {
            case COLUMN_KEYS.profileName:
                filterColumn = FILTERS_KEYS.profileStartsWith
                break;
            case COLUMN_KEYS.profileEmail:
                filterColumn = FILTERS_KEYS.emailStartsWith
                break;
            case COLUMN_KEYS.source:
                filterColumn = FILTERS_KEYS.sourceIds
                break;
            // case COLUMN_KEYS.savedSearch:
            //     filterColumn = FILTERS_KEYS.savedSearchIds
            //     break;
            // case COLUMN_KEYS.savedSearchType:
            //     filterColumn = FILTERS_KEYS.savedSearchTypes
            //     break;
            case COLUMN_KEYS.headline:
                filterColumn = FILTERS_KEYS.articleIds
                break;
            case COLUMN_KEYS.articleType:
                filterColumn = FILTERS_KEYS.articleTypes
                break;
            default:
                break;
        }
        dispatch(usageActions.setUsageGridColumnFilters({ [filterColumn]: selectedItems }))
        fetchGridData()
    }
    const handleShowMoreRows = (row) => {
        const moreRowsCount = DEFAULT_ROWS_TO_FETCH_ON_EXPAND
        const parent = row.original.additionalProps.parent

        fetchMoreRows(parent, moreRowsCount)
    }
    const handleShowAllRows = (row) => {
        const parent = row.original.additionalProps.parent
        const { totalChildCount, currentChildCount } = parent.additionalProps

        const remainingCount = totalChildCount - currentChildCount
        fetchMoreRows(parent, remainingCount)
    }

    useEffect(() => {
        prepareData()
    }, [usageGridData.data])

    useEffect(() => {
        applyDefaultSorting()
        applyDefaultGrouping()
    }, [])

    const filtersSection = <div className="filters-section">
        <InputBlock label="Filter By Profile Type">
            <Dropdown
                className="drop-down"
                type={'text'}
                options={profileTypeOptions}
                onSelect={handleProfileTypeVisibilityChange}
            />
        </InputBlock>
        <InputBlock label="Filter By Admin Role">
            <Dropdown
                className="drop-down"
                type={'text'}
                options={adminRoleOptions}
                onSelect={handleAdminRoleVisibilityChange}
            />
        </InputBlock>
    </div>

    const header = <div className="dimensions-section">
        <InputBlock label="Select Dimension" className="input-block">
            <MultiSelectDropdown
                className="drop-down"
                type={'text'}
                options={dimensionsOptions}
                showSelectAllOption={false}
                initiallyAllSelected={false}
                isGrouped
                label='Dimensions'
                noSelectionText='-'
                values={selectedDimensions}
                onChange={values => {
                    values && handleShowDimensions(values)
                }}
            />
        </InputBlock>
        <div className="chip-container">
            {selectedDimensions.length > 0 ? <>
                {grouping.map(key => {
                    const dim = DIMENSIONS.find(d => d.value === key)
                    return dim ? <Chip
                        key={`chip-${dim.value}-${Math.random().toString()}`}
                        text={dim.label}
                        onCancel={() => handleRemoveDimension(dim.value)} /> : null
                })}
            </>
                : NO_DIMENSION_MESSAGE}
        </div>
        <DebouncedSearchInput
            value={globalFilter ?? ''}
            inputClassName="search-text-input"
            containerClassName="search-text-input-container"
            onChange={handleFreeSearchTextChange}
            placeholder={GENERAL_SEARCH_PLACEHOLDER}
        />
        <div className="page-size">
            {'Show'}
            <Dropdown
                className="page-size-drop-down"
                type={'text'}
                value={pageSize}
                options={paginationOptions}
                onChange={handleOnPageSizeChange}
            />
        </div>
    </div>
    const totalPageCount = Math.ceil(usageGridData.totalCount / pageSize)

    return (
        <div className="tableContainer">

            {filtersSection}
            {header}

            <table className="table">
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                                const columnId = header.column.columnDef.accessorKey;
                                const isArticleColumn = columnId == COLUMN_KEYS.headline
                                const isColumnSorted = sorting.length > 0 && sorting[0].id == columnId
                                const isDesc = isColumnSorted ? sorting[0].desc : null
                                return (
                                    <th className={classNames("th", { "filter-open": filterOn[header.column.columnDef.accessorKey] })}
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        {...{
                                            style: {
                                                width: isArticleColumn ? null : header.column.columnDef.size,
                                                minWidth: header.column.columnDef.minSize,
                                                maxWidth: header.column.columnDef.maxSize,
                                                // width: isArticleColumn ? null :header.getSize()
                                                // width: header.getSize()
                                            },
                                        }}>
                                        <div className="header-container">
                                            <div className={classNames("header", { pointer: header.column.getCanSort() })}
                                                onClick={() => header.column.getCanSort() ? handleOnSortingChange(header) : null}
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                {isColumnSorted ? (isDesc ? <div className="desc-icon" /> : <div className="asc-icon" />) : null}
                                            </div>
                                            {header.column.getCanFilter() ? (
                                                <Filter
                                                    column={header.column}
                                                    table={table}
                                                    containerClassName={"filter-icon"}
                                                    {...header.column.columnDef.filterConfig}
                                                    filterVisibilityChange={handleColumnFilterVisibilityChange}
                                                    onSelection={handleOnFilterSelection} />
                                            ) : null}
                                        </div>
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => {
                        const showMoreArticleRow = getIsShowMoreAbleRow(row)
                        const columnHeader = (COLUMN_HEADERS[row.original.additionalProps.dimension] ?? 'row') + 's'
                        const rowClassName = row.depth == 0 ?
                            (row.getIsExpanded() ? 'expanded-group' : 'collapsed-group') :
                            (row.depth % 2 != 0 ? 'expanded-tr' : null)
                        return (
                            <React.Fragment key={`fragment-${row.id}`}>
                                <tr
                                    key={row.id}
                                    className={rowClassName}>
                                    {row.getVisibleCells().map(cell => {
                                        const cellData = cell.getValue()
                                        const isGrouped = grouping.includes(cell.column.columnDef.accessorKey)
                                        const isLastGroupingColumn = grouping.length > 0 && grouping[grouping.length - 1] == cell.column.id;
                                        return (
                                            <td key={cell.id} className={isLastGroupingColumn ? 'dimension-end' : null}>
                                                <div className="td">
                                                    {cellData && isGrouped ? (
                                                        <div
                                                            {...{
                                                                onClick: () => handleColumnExpand(cell, row),
                                                                style: {
                                                                    cursor: 'pointer',
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                    flex: 1
                                                                },
                                                                className: cell.column.id === LAST_INVISIBLE_DIMENSION ? 'article-column' : ''
                                                            }}
                                                        >
                                                            <div className={row.getIsExpanded() ? 'arrow-down' : 'arrow-right'} />
                                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                        </div>
                                                    ) : flexRender(cell.column.columnDef.cell, cell.getContext())
                                                    }
                                                </div>
                                            </td>
                                        )
                                    })}
                                </tr>
                                {showMoreArticleRow && <tr
                                    key={`extra-row-${Math.random().toString()}`}
                                    className={rowClassName}>
                                    <React.Fragment key={`inner-fragment-${row.id}`}>
                                        {row.getVisibleCells().map(cell => {
                                            const isLastGroupingColumn = grouping.length > 0 && grouping[grouping.length - 1] == cell.column.id;
                                            return (
                                                <td key={`extra-cell-${Math.random().toString()}`} className={isLastGroupingColumn ? 'dimension-end' : null}>
                                                    <div className="td">
                                                        {/* Empty Columns */}
                                                    </div>
                                                </td>
                                            )
                                        })}
                                        <div key={`show-more-row-${Math.random().toString()}`}
                                            className={classNames('absolute-center', 'show-more-articles', 'pointer')}>
                                            <div onClick={() => handleShowAllRows(row)}>{stringFormat(SHOW_ALL_TEXT, columnHeader)}</div>
                                            <div onClick={() => handleShowMoreRows(row)}>{stringFormat(SHOW_MORE_TEXT, columnHeader)}</div>
                                        </div>
                                    </React.Fragment>
                                </tr>}
                            </React.Fragment>
                        )
                    })}
                </tbody >
                {!isLoading && data.length === 0
                    ? <tfoot>
                        <tr>
                            <td colSpan={table.getVisibleFlatColumns().length}>
                                <div className="no-results-message">{NO_DATA_MESSAGE}</div>
                            </td>
                        </tr>
                    </tfoot>
                    : null}
            </table >

            {usageGridData.start && usageGridData.end ? <div className="date-range-text">
                {stringFormat(GRID_DATE_RANGE_LABEL,
                    usageGridData.start ? dateFns.format(new Date(usageGridData.start), DATE_FORMAT) : '',
                    usageGridData.end ? dateFns.format(new Date(usageGridData.end), DATE_FORMAT) : '')
                }
            </div> : null}

            <PageSelector
                currentPage={currentPage}
                pageCount={totalPageCount}
                onPageChange={handleOnPageChange}
            />

        </div >
    )
}

export default ReportingGrid
