import { apiCall } from 'app/api'


export function sendFrontendStats(data, callback) {
  return apiCall({
    url: '/api/frontend-stats/',
    method: 'PUT',
    data,
    callback,
    options: {dataFormat: 'json', useCsrfCookie: true},
  })
}
