import classNames from 'classnames'
import * as dateFns from 'date-fns'
import is from 'is'
import PropTypes from 'prop-types'
import React from 'react'
import {Portal} from 'react-portal'

import BareModal from 'app/common/modals/BareModal'
import DateRangePicker from 'app/common/DateRangePicker'
import Dropdown from 'app/common/Dropdown'
import HelpIconTooltip from 'app/common/HelpIconTooltip'
import {timeFrameDisplay} from 'app/strings'
import {dateRangeFromTimeFrameString} from 'app/search-results-page/search-results-page-utils'
import Button from 'app/common/Button'

import * as constants from '../search-results-page-constants'

import styles from './TimeFrameSelector.less'

const DATE_RANGE_FORMAT = 'M/d'

export const timeFrameType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
  }),
])

const dateRangeToString = ({start, end}) => {
  if (dateFns.isSameDay(start, end)) {
    return dateFns.format(start, DATE_RANGE_FORMAT)
  }
  return `${dateFns.format(start, DATE_RANGE_FORMAT)} - ${dateFns.format(end, DATE_RANGE_FORMAT)}`
}


export default class TimeFrameSelector extends React.Component {
  static propTypes = {
    value: timeFrameType,
    onChange: PropTypes.func,
    className: PropTypes.string,
  }

  state = {
    isDropdownOpen: false,
    isDateRangePickerOpen: false,
    dateRange: null,
  }

  render() {
    const {value, className} = this.props
    const isValueDateRange = is.object(value)
    const {isDropdownOpen, isDateRangePickerOpen} = this.state
    let dropdownValue = value
    if (isValueDateRange) {
      dropdownValue = 'custom'
    }
    return (
      <div
        className={classNames(
          styles.timeFrameContainer,
          className,
          'time-frame-selector', // for Pendo
          {[styles.open]: isDropdownOpen},
        )}
      >
        <Dropdown
          value={dropdownValue}
          onChange={this.handleDropdownChange}
          onMenuOpenChange={this.handleMenuOpenChange}
          selectedOptionLabel={
            <>
              <span>
                {isValueDateRange
                  ? dateRangeToString(value)
                  : timeFrameDisplay(value)
                }
              </span>
              <HelpIconTooltip label="Article data goes back 6 months." />
            </>
          }
          className={classNames(
            styles.timeFrame,
            {[styles.open]: isDropdownOpen},
          )}
          selectedOptionClassName={classNames(
            styles.selectedOption,
            'time-frame-selector-label', // for Pendo
          )}
          arrowClassName={styles.arrow}
          menuClassName={styles.menu}
        >
          {constants.TIME_FRAMES.map(timeFrame => (
            <Dropdown.Option
              label={timeFrameDisplay(timeFrame)}
              value={timeFrame}
              key={timeFrame}
            />
          ))}
          <Dropdown.Option label="Custom" value="custom" />
        </Dropdown>
        {isDateRangePickerOpen && (
          <Portal>
            <BareModal
              onClose={this.closeDatePicker}
              className={styles.dateRangePickerModalContent}
            >
              <form
                onSubmit={this.handleDatePickerSubmit}
                className={styles.dateRangePickerContainer}
              >
                <DateRangePicker
                  value={
                    isValueDateRange
                      ? value
                      : dateRangeFromTimeFrameString(value)
                  }
                  onChange={this.handleDatePickerChange}
                  autoFocusStart={true}
                />
                <Button
                  label="Apply"
                  isPrimary
                  className={styles.applyButton}
                />
              </form>
            </BareModal>
          </Portal>
        )}
      </div>
    )
  }

  handleDropdownChange = timeFrame => {
    if (timeFrame === 'custom') {
      this.openDatePicker()
      return
    }
    if (this.props.onChange) {
      this.props.onChange(timeFrame)
    }
  }

  handleMenuOpenChange = isOpen => {
    this.setState({isDropdownOpen: isOpen})
  }

  handleDatePickerChange = dateRange => {
    this.setState({dateRange})
  }

  handleDatePickerSubmit = event => {
    event.preventDefault()
    if (this.props.onChange && this.state.dateRange) {
      this.closeDatePicker()
      this.props.onChange(this.state.dateRange)
    }
  }

  openDatePicker() {
    this.setState({isDateRangePickerOpen: true})
  }

  closeDatePicker = () => {
    this.setState({isDateRangePickerOpen: false})
  }
}
