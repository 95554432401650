import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startOfDay, subMonths, subWeeks, subDays } from 'date-fns'
import InputBlock from 'app/common/InputBlock'
import './UsageSummary.less'
import Card from './SummaryCard'
import * as selectors from '../usage-selectors'
import * as actions from '../usage-actions'
import {
  SUMMARY_TITLE,
  SUMMARY_VALUE,
  SUMMARY_OPTIONS,
  SUMMARY_TOOLTIP,
  SUMMARY_HEADERS,
  SUMMARY_LAST_UPDATED_DATE,
  LAST_UPDATED_ON
} from '../usage-constants'
import { stringFormat } from 'app/common/utils'
import * as dateFns from 'date-fns'
import { DATE_FORMAT } from "app/constants";
import { getTooltipStyles } from '../utils';
import Tooltip from "app/common/Tooltip";

export default function UsageSummary() {
  const dispatch = useDispatch()
  const [selectedOption, setSelectedOption] = useState(SUMMARY_VALUE.YESTERDAY)
  const [date, setDate] = useState()
  const { data, lastUpdatedDate } = useSelector(selectors.summaryData)
  const { activeUsers, totalUsers, emailsDelivered, emailsDeliveredPercentage,
    openMail, openEmailPercentage, openRate, openRatePercentage, totalEmailsDelivered } = data
  
  const setSummaryoption = (e) => {
    const updatedDate = {}
    if (e.target.value === SUMMARY_VALUE.YESTERDAY) {
      setSelectedOption(SUMMARY_VALUE.YESTERDAY)
      updatedDate.start = subDays(new Date(), 1)
      updatedDate.end = startOfDay(new Date())
      setDate(updatedDate)
    }

    else if (e.target.value === SUMMARY_VALUE.LAST_WEEK) {
      setSelectedOption(SUMMARY_VALUE.LAST_WEEK)
      updatedDate.start = subWeeks(new Date(), 1)
      updatedDate.end = startOfDay(new Date())
      setDate(updatedDate)
    }

    else {
      setSelectedOption(SUMMARY_VALUE.LAST_MONTH)
      updatedDate.start = subMonths(new Date(), 1)
      updatedDate.end = startOfDay(new Date())
      setDate(updatedDate)
    }
    dispatch(actions.setSummaryDateOption(updatedDate))
    dispatch(actions.fetchUsageGridSummaryData())

  }
  const sanitizeValue = (value, defaultValue = 0) => value || defaultValue

  return (
    <div id="summarySection">
      <div className="usage-header" >
        <div >
          <h4>{SUMMARY_TITLE}</h4>
          {/* <div className={'switch-container'} style={{'marginLeft': '40px', 'marginTop': '22px'}}>
                <label>Include Admin Users</label>
              <Switch
              onChange={(checked) => onIncludeAdminsToggleChange(checked)}
              checked={canIncludeAdminUsers}
              height={22}
              width={48}
              handleDiameter={16}
              offColor="#ccc"
              onColor="#0074b4"
              uncheckedIcon={
                <div className="switch-icon">NO</div>
              }
              checkedIcon={
                <div className="switch-icon">YES</div>
              }
            />
          </div> */}
        </div>
      </div>
      <div className='summary-options' >
        {SUMMARY_OPTIONS.map((option) => {
          return (
            <div>
            <InputBlock label={option.label} isInline key={option.label}>
              <input
                type="radio"
                name="summary-type"
                checked={selectedOption === option.value}
                onChange={(e) => setSummaryoption(e)}
                value={option.value}
              />
            </InputBlock>
              <Tooltip
                render={() => <div style={getTooltipStyles()}>
                  {option.tooltip}
                </div>}
                direction='bottom'
                noBorder="true"
              >
                <img src="/media/img/Icon.svg" style={{top:"-5.5px"}}className='tool-tip' />
              </Tooltip>
          </div>
            )
        })}
      </div>
      <div className="container-one">

        <Card
          cardHeader={SUMMARY_HEADERS.ACTIVE_USERS}
          cardBody={sanitizeValue(activeUsers)}
          cardInfo={sanitizeValue(totalUsers)}
          toolTip={SUMMARY_TOOLTIP.ACTIVE_USERS}
          selectedOption={selectedOption}
        />

        <Card
          cardHeader={SUMMARY_HEADERS.EMAILS_DELIVERED}
          cardBody={sanitizeValue(emailsDelivered)}
          cardInfo={sanitizeValue(emailsDeliveredPercentage)}
          toolTip={SUMMARY_TOOLTIP.EMAILS_DELIVERED}
          selectedOption={selectedOption}
          totalEmailsDelivered={sanitizeValue(totalEmailsDelivered)}
        />

        <Card
          cardHeader={SUMMARY_HEADERS.OPEN_EMAIL}
          cardBody={sanitizeValue(openMail)}
          cardInfo={sanitizeValue(openEmailPercentage)}
          toolTip={SUMMARY_TOOLTIP.OPEN_EMAIL}
          selectedOption={selectedOption}
        />

        <Card
          cardHeader={SUMMARY_HEADERS.EMAIL_OPEN_RATE}
          cardBody={`${sanitizeValue(openRate, 0)}%`}
          cardInfo={sanitizeValue(openRatePercentage)}
          toolTip={SUMMARY_TOOLTIP.EMAIL_OPEN_RATE}
          selectedOption={selectedOption}
        />

      </div>
      {lastUpdatedDate ?
        <div className="last-updated-date">
          {SUMMARY_LAST_UPDATED_DATE} <b> {LAST_UPDATED_ON} {dateFns.format(new Date(lastUpdatedDate), DATE_FORMAT)}</b>
        </div>
        : null}
    </div>
  )
}

