import React from 'react'

import Tooltip from 'app/common/Tooltip'


export default function HelpIconTooltip(props) {
  return (
    <Tooltip {...props}>
      <span className="tooltip help" />
    </Tooltip>
  )
}
