import React, { Component } from 'react'
import { connect } from 'react-redux'
import {startOfDay, subDays} from 'date-fns'
import formatDate from 'date-fns/format'
import {parseISO} from 'date-fns'
import * as R from 'ramda'

import LoadingOverlay from 'app/common/LoadingOverlay'
import Table from 'app/common/Table'
import {TabbedContainer, Tab} from 'app/common/TabbedContainer'
import {firmStats as firmStatsUrls} from 'app/urls'

import {getPendoStats} from './pendo-stats-selectors'
import * as actions from './pendo-stats-actions'
import Header from '../Header'

import '../FirmStats.less'
import './PendoStats.less'

const TAB_PAGE_VISITS = 'page-visits'
const TAB_VISITORS = 'visitors'
const TAB_BROWSERS = 'browsers'

@connect(
  // Map state to props
  state => {
    const pendoStats = getPendoStats(state)
    return {
      isStaff: state.currentUserIsStaff || state.originalUserIsStaff,
      isLoading: pendoStats.isLoading,
      firms: pendoStats.firms,
      firmId: pendoStats.firmId,
      pageVisits: pendoStats.pageVisits,
      visitors: pendoStats.visitors,
      browsers: pendoStats.browsers,
    }
  },

  // Map dispatch to props
  {
    setFirmId: actions.setFirmId,
    fetchPageVisits: actions.fetchPageVisits,
    fetchVisitors: actions.fetchVisitors,
    fetchBrowsers: actions.fetchBrowsers,
  },
)
export default class PendoStats extends Component {
  state = {
    currentTab: TAB_PAGE_VISITS,
    pageVisitsDateRange: {
      start: subDays(new Date(), 2),
      end: startOfDay(new Date()),
    },
    pageVisitsSort: {
      column: 'name',
      direction: 'asc',
    },
    visitorsDateRange: {
      start: subDays(new Date(), 10),
      end: startOfDay(new Date()),
    },
    visitorsSort: {
      column: 'name',
      direction: 'asc',
    },
    browsersDateRange: {
      start: subDays(new Date(), 15),
      end: startOfDay(new Date()),
    },
    browsersSort: {
      column: 'browser',
      direction: 'asc',
    },
    expandedPages: [],
    expandedVisitors: [],
    expandedBrowsers: [],
  }

  render() {
    const loader = this.props.isLoading
      ? <LoadingOverlay/>
      : null

    const pageVisitsTable =
      <Table
        key="pendo-stats-page-visits-table"
        data={this.props.pageVisits}
        defaultSort={{...this.state.pageVisitsSort}}
        onSortChange={(sort) => this.setState({pageVisitsSort: sort})}
      >
        <Table.Column
          name="name"
          label="Page"
          isSortable
          sortBy={R.prop('name')}
          baseWidth="30%"
          growRatio={0}
          shrinkRatio={0}
          className="allow-overflow"
          cellContents={(pv) => {
            return (
              <React.Fragment>
                <div
                  onClick={() => this.handlePageNameClick(pv.id)}
                  className="clickable"
                >{pv.name}</div>

                {
                  this.state.expandedPages.includes(pv.id)
                    ? <Table
                        data={pv.visitors}
                        defaultSort={{column: 'name', direction: 'asc'}}
                        className="sub-table extra-wide"
                        getKeyForItem={(v) => `${v.id}-${v.date}`}
                      >
                        <Table.Column
                          name="name"
                          label="User"
                          isSortable
                          sortBy={R.prop('name')}
                          cellContents={R.prop('name')}
                          baseWidth="30%"
                          growRatio={0}
                          shrinkRatio={0}
                        />

                        <Table.Column
                          name="device"
                          label="Device"
                          isSortable
                          sortBy={(v) => `${v.userAgent.os} ${v.userAgent.deviceType}`}
                          cellContents={(v) => `${v.userAgent.os} ${v.userAgent.deviceType}`}
                          baseWidth="30%"
                          growRatio={0}
                          shrinkRatio={0}
                        />

                        <Table.Column
                          name="browser"
                          label="Browser"
                          isSortable
                          sortBy={(v) => `${v.userAgent.browser} ${v.userAgent.browserVersion}`}
                          cellContents={(v) => `${v.userAgent.browser} ${v.userAgent.browserVersion}`}
                          baseWidth="20%"
                          growRatio={0}
                          shrinkRatio={0}
                        />

                        <Table.Column
                          name="date"
                          label="Date"
                          isSortable
                          sortBy={R.prop('date')}
                          cellContents={(v) => formatDate(parseISO(v.date), 'M/d/yyyy')}
                          baseWidth="10%"
                          growRatio={0}
                          shrinkRatio={0}
                        />

                        <Table.Column
                          name="minutes"
                          label="Minutes"
                          isSortable
                          sortBy={R.prop('minutes')}
                          cellContents={R.prop('minutes')}
                          baseWidth="10%"
                          growRatio={0}
                          shrinkRatio={0}
                        />
                      </Table>
                    : null
                }
              </React.Fragment>
            )
          }}
        />

        <Table.Column
          name="url"
          label="URL"
          isSortable
          sortBy={R.prop('url')}
          cellContents={R.prop('url')}
          baseWidth="60%"
          growRatio={0}
          shrinkRatio={0}
        />

        <Table.Column
          name="minutes"
          label="Minutes"
          isSortable
          sortBy={R.prop('minutes')}
          cellContents={R.prop('minutes')}
          baseWidth="10%"
          growRatio={0}
          shrinkRatio={0}
        />
      </Table>

    const visitorsTable =
      <Table
        key="pendo-stats-visitors-table"
        data={this.props.visitors}
        defaultSort={{...this.state.visitorsSort}}
        onSortChange={(sort) => this.setState({visitorsSort: sort})}
      >
        <Table.Column
          name="name"
          label="Name"
          isSortable
          sortBy={R.prop('name')}
          baseWidth="90%"
          growRatio={0}
          shrinkRatio={0}
          cellContents={(v) => {
            return (
              <React.Fragment>
                <div
                  onClick={() => this.handleUserNameClick(v.id)}
                  className="clickable"
                >{v.name}</div>

                {
                  this.state.expandedVisitors.includes(v.id)
                    ? <Table
                        data={v.pages}
                        defaultSort={{column: 'name', direction: 'asc'}}
                        className="sub-table"
                        getKeyForItem={(p) => `${p.id}-${p.date}`}
                      >
                        <Table.Column
                          name="name"
                          label="Page"
                          isSortable
                          sortBy={R.prop('name')}
                          cellContents={R.prop('name')}
                          baseWidth="25%"
                          growRatio={0}
                          shrinkRatio={0}
                        />

                        <Table.Column
                          name="url"
                          label="URL"
                          isSortable
                          sortBy={R.prop('url')}
                          cellContents={R.prop('url')}
                          baseWidth="25%"
                          growRatio={0}
                          shrinkRatio={0}
                        />

                        <Table.Column
                          name="device"
                          label="Device"
                          isSortable
                          sortBy={(p) => `${p.userAgent.os} ${p.userAgent.deviceType}`}
                          cellContents={(p) => `${p.userAgent.os} ${p.userAgent.deviceType}`}
                          baseWidth="15%"
                          growRatio={0}
                          shrinkRatio={0}
                        />

                        <Table.Column
                          name="browser"
                          label="Browser"
                          isSortable
                          sortBy={(p) => `${p.userAgent.browser} ${p.userAgent.browserVersion}`}
                          cellContents={(p) => `${p.userAgent.browser} ${p.userAgent.browserVersion}`}
                          baseWidth="15%"
                          growRatio={0}
                          shrinkRatio={0}
                        />

                        <Table.Column
                          name="date"
                          label="Date"
                          isSortable
                          sortBy={R.prop('date')}
                          cellContents={(p) => formatDate(parseISO(p.date), 'M/d/yyyy')}
                          baseWidth="10%"
                          growRatio={0}
                          shrinkRatio={0}
                        />

                        <Table.Column
                          name="minutes"
                          label="Minutes"
                          isSortable
                          sortBy={R.prop('minutes')}
                          cellContents={R.prop('minutes')}
                          baseWidth="10%"
                          growRatio={0}
                          shrinkRatio={0}
                        />
                      </Table>
                    : null
                }
              </React.Fragment>
            )
          }}
        />

        <Table.Column
          name="minutes"
          label="Minutes"
          isSortable
          sortBy={R.prop('minutes')}
          cellContents={R.prop('minutes')}
          baseWidth="10%"
          growRatio={0}
          shrinkRatio={0}
        />
      </Table>

    const browsersTable =
      <Table
        key="pendo-stats-browsers-table"
        data={this.props.browsers}
        defaultSort={{...this.state.browsersSort}}
        onSortChange={(sort) => this.setState({browsersSort: sort})}
        getKeyForItem={R.prop('browser')}
      >
        <Table.Column
          name="browser"
          label="Browser"
          isSortable
          sortBy={R.prop('browser')}
          baseWidth="90%"
          growRatio={0}
          shrinkRatio={0}
          cellContents={(b) => {
            return (
              <React.Fragment>
                <div
                  onClick={() => this.handleBrowserNameClick(b.browser)}
                  className="clickable"
                >{b.browser}</div>

                {
                  this.state.expandedBrowsers.includes(b.browser)
                    ? <Table
                        data={b.visitors}
                        defaultSort={{column: 'name', direction: 'asc'}}
                        className="sub-table"
                        getKeyForItem={R.prop('name')}
                      >
                        <Table.Column
                          name="name"
                          label="User"
                          isSortable
                          sortBy={R.prop('name')}
                          cellContents={R.prop('name')}
                          baseWidth="40%"
                          growRatio={0}
                          shrinkRatio={0}
                        />

                        <Table.Column
                          name="device"
                          label="Device"
                          isSortable
                          sortBy={(v) => `${v.userAgent.os} ${v.userAgent.deviceType}`}
                          cellContents={(v) => `${v.userAgent.os} ${v.userAgent.deviceType}`}
                          baseWidth="20%"
                          growRatio={0}
                          shrinkRatio={0}
                        />

                        <Table.Column
                          name="browser"
                          label="Browser"
                          isSortable
                          sortBy={(v) => `${v.userAgent.browser} ${v.userAgent.browserVersion}`}
                          cellContents={(v) => `${v.userAgent.browser} ${v.userAgent.browserVersion}`}
                          baseWidth="20%"
                          growRatio={0}
                          shrinkRatio={0}
                        />
                      </Table>
                    : null
                }
              </React.Fragment>
            )
          }}
        />

        <Table.Column
          name="visitor_count"
          label="Users"
          isSortable
          sortBy={(v) => v.visitors.length}
          cellContents={(v) => v.visitors.length}
          baseWidth="10%"
          growRatio={0}
          shrinkRatio={0}
        />
      </Table>

    const pageVisitsTab =
      <React.Fragment>
        {pageVisitsTable}
      </React.Fragment>

    const visitorsTab =
      <React.Fragment>
        {visitorsTable}
      </React.Fragment>

    const browsersTab =
      <React.Fragment>
        {browsersTable}
      </React.Fragment>

    return (
      <div>
        {loader}

        <Header
          isStaff={this.props.isStaff}
          firms={this.props.firms}
          firmId={this.props.firmId}
          engagementValue={firmStatsUrls.pendoStats()}
          dateRange={this.getDateRange()}
          onFirmChange={this.props.setFirmId}
          onDateRangeChange={(dateRange) => this.setDateRange({...dateRange})}
          onFetchStats={() => this.fetchStats()}
        />

        <TabbedContainer
          tabName={this.state.currentTab}
          onTabChange={(tabName) => this.setState({currentTab: tabName})}
          useUpdatedCss
        >
          <Tab name={TAB_PAGE_VISITS} label="Page Visits">{pageVisitsTab}</Tab>
          <Tab name={TAB_VISITORS} label="Visitors">{visitorsTab}</Tab>
          <Tab name={TAB_BROWSERS} label="Browsers">{browsersTab}</Tab>
        </TabbedContainer>
      </div>
    )
  }

  handlePageNameClick(pageId) {
    let expandedPages = [...this.state.expandedPages]
    if (expandedPages.includes(pageId)) {
      expandedPages = expandedPages.filter(p => p !== pageId)
    } else {
      expandedPages.push(pageId)
    }
    this.setState({expandedPages})
  }

  handleUserNameClick(userId) {
    let expandedVisitors = [...this.state.expandedVisitors]
    if (expandedVisitors.includes(userId)) {
      expandedVisitors = expandedVisitors.filter(p => p !== userId)
    } else {
      expandedVisitors.push(userId)
    }
    this.setState({expandedVisitors})
  }

  handleBrowserNameClick(browserName) {
    let expandedBrowsers = [...this.state.expandedBrowsers]
    if (expandedBrowsers.includes(browserName)) {
      expandedBrowsers = expandedBrowsers.filter(b => b !== browserName)
    } else {
      expandedBrowsers.push(browserName)
    }
    this.setState({expandedBrowsers})
  }

  getDateRange() {
    const {currentTab} = this.state
    if (currentTab === TAB_PAGE_VISITS) {
      return this.state.pageVisitsDateRange
    } else if (currentTab === TAB_VISITORS) {
      return this.state.visitorsDateRange
    } else if (currentTab === TAB_BROWSERS) {
      return this.state.browsersDateRange
    }
  }

  setDateRange(dateRange) {
    const {currentTab} = this.state
    if (currentTab === TAB_PAGE_VISITS) {
      this.setState({pageVisitsDateRange: dateRange})
    } else if (currentTab === TAB_VISITORS) {
      this.setState({visitorsDateRange: dateRange})
    } else if (currentTab === TAB_BROWSERS) {
      this.setState({browsersDateRange: dateRange})
    }
  }

  fetchStats() {
    const {currentTab} = this.state
    if (currentTab === TAB_PAGE_VISITS) {
      this.fetchPageVisits()
    } else if (currentTab === TAB_VISITORS) {
      this.fetchVisitors()
    } else if (currentTab === TAB_BROWSERS) {
      this.fetchBrowsers()
    }
  }

  fetchPageVisits() {
    this.props.fetchPageVisits({firmId: this.props.firmId, ...this.state.pageVisitsDateRange})
    this.setState({expandedPages: []})
  }

  fetchVisitors() {
    this.props.fetchVisitors({firmId: this.props.firmId, ...this.state.visitorsDateRange})
    this.setState({expandedVisitors: []})
  }

  fetchBrowsers() {
    this.props.fetchBrowsers({firmId: this.props.firmId, ...this.state.browsersDateRange})
    this.setState({expandedBrowsers: []})
  }
}
