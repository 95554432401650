import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import Modal from 'app/common/Modal'

import * as actions from './cglytics-teaser-actions'
import * as selectors from './cglytics-teaser-selectors'

import styles from './CglyticsTeaserModal.less'


function CglyticsTeaserModal({isOpen, close}) {
  if (!isOpen) return null
  return (
    <Modal isOpen={true} onClose={close} className={styles.modal}>
      <a href="mailto:support@manzama.com">
        <img
          src="/media/img/cglytics-teaser.png"
          alt="CGLytics &ndash; Board Analytics"
          className={styles.image}
        />

        <button className={styles.button}>
          Click Here to Learn More
        </button>
      </a>
    </Modal>
  )
}
CglyticsTeaserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
}


export default connect(
  createSelector([selectors.getIsOpen], isOpen => ({isOpen})),
  {close: actions.hideModal},
)(CglyticsTeaserModal)
