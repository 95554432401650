import { createAction } from 'redux-actions'

const createPendoStatsAction = name => createAction(`PENDO_STATS_${name}`)

export const init = createPendoStatsAction('INIT')
export const hideLoader = createPendoStatsAction('HIDE_LOADER')
export const setFirmId = createPendoStatsAction('SET_FIRM_ID')
export const fetchPageVisits = createPendoStatsAction('FETCH_PAGE_VISITS')
export const setPageVisits = createPendoStatsAction('SET_PAGE_VISITS')
export const fetchVisitors = createPendoStatsAction('FETCH_VISITORS')
export const setVisitors = createPendoStatsAction('SET_VISITORS')
export const fetchBrowsers = createPendoStatsAction('FETCH_BROWSERS')
export const setBrowsers = createPendoStatsAction('SET_BROWSERS')
