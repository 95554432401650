import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Message from 'app/common/Message'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {getSourcesAdmin} from '../sources-admin-selectors'
import {
  setShouldShowSelfAddModal,
  createTwitterFeed,
  updateCreateTwitterFeedData,
} from '../sources-admin-actions'

@connect(
  createSelector(
    [getSourcesAdmin], (sourcesAdmin) => {
      return {
        createTwitterFeedData: sourcesAdmin.createTwitterFeedData,
        languages: sourcesAdmin.languages,
      }
    }
  ), {
    setShouldShowSelfAddModal,
    createTwitterFeed,
    updateCreateTwitterFeedData,
  })
export default class AddTwitterSource extends Component {
  componentDidMount() {
    // Immediately focus the handle field
    this.input.focus()
  }

  render() {
    const {handle, languageId, errorMessage, isProcessing, isComplete} = this.props.createTwitterFeedData

    const allowSubmitForm = handle && !isProcessing

    const loadingMessage = isProcessing &&
      <Message
        type={Message.TYPES.LOADING}
        text="We are checking the Handle you submitted for content. This might take a few seconds..."
      />

    const error = errorMessage &&
      <div className="message error">{errorMessage}</div>

    const successMessage = isComplete &&
      <Message
        type={Message.TYPES.SUCCESS}
        text="The Twitter feed was successfully added!"
      />

    return (
      <div className="source-url-form">
        {error}

        <div className="no-spacing">
          <p>Add any Twitter feed by pasting in either the <strong>handle</strong> or the <strong>Twitter URL</strong> of the feed you wish to add.</p>
          <p>An example of a Twitter handle is <strong>@ManzamaInc</strong>. You can see the handle by looking at the second part of the Twitter URL.</p>
          <p>i.e. https://twitter.com/<strong>ManzamaInc</strong>. You may also just copy and paste the full URL: <strong>https://twitter.com/ManzamaInc</strong>.</p>
        </div>

        <div className="no-spacing">
          <p>This includes Twitter Lists, which can be added by entering in the URL, such as this example: <strong>https://twitter.com/i/lists/197004447</strong></p>
        </div>

        <div>
          <div className="language-header">
            <span className="label">Specify the language for this Twitter feed</span>
          </div>

          <div className="languages-top-div">
            <table className="languages-datatable">
              <tbody>
                {
                  this.props.languages.map(language => {
                    return (
                      <tr key={language.id}>
                        <td className="language-list-item">
                          <input
                            className="language-input"
                            type="checkbox"
                            onChange={event => this.handleLanguageChange(language.id, event.target.checked)}
                            checked={language.id === languageId}
                          />
                          <span className="language-name">{language.name}</span>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>

        <br/>

        <label className="inline margin-r-l twitter-label">
          <span className="label">Twitter Handle or URL</span>
          <table className="twitter-table">
            <tbody>
              <tr>
                <td className="twitter-td-left">
                  <input
                    ref={input => this.input = input}
                    type="text"
                    value={handle}
                    onChange={(event) => this.handleHandleChange(event.target.value)}/>
                </td>
              </tr>
            </tbody>
          </table>
        </label>

        {loadingMessage}
        {successMessage}

        <div className="buttons">
          <button
            className="submit"
            disabled={!allowSubmitForm}
            onClick={() => this.addFeed()}
          >
            Add Twitter Feed
          </button>

          <a className="cancel" onClick={() => this.props.setShouldShowSelfAddModal(false)}>
            Close
          </a>
        </div>
      </div>
    )
  }

  handleHandleChange(handle) {
    this.props.updateCreateTwitterFeedData({errorMessage: null, handle})
  }

  handleLanguageChange(languageId, isChecked) {
    if (!isChecked) {
      languageId = null
    }
    this.props.updateCreateTwitterFeedData({languageId})
  }

  addFeed() {
    const {handle, languageId} = this.props.createTwitterFeedData
    this.props.createTwitterFeed({handle, languageId})
  }
}
