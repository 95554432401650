import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import classNames from 'classnames'

import { getAppName, getAppNameFull, getCurrentUserId } from 'app/global/global-selectors'
import LoadingOverlay from 'app/common/LoadingOverlay'
import DeleteSearchesConfirmationModal from 'app/reusable/DeleteSearchesConfirmationModal'
import { getEntities } from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import { SavedSearch, User } from 'app/models'
import * as urls from 'app/urls'
import { formatNumber } from 'app/utils'

import AddNewSearch from './AddNewSearch'
import BulkActions from './BulkActions'
import EditModal from './EditModal'
import BulkAddFiltersModal from './BulkAddFiltersModal/BulkAddFiltersModal'
import FeatureModal from './FeatureModal/FeatureModal'
import Header from './Header'
import SearchesTable from './Table'
import {
  fetchAllSearchIds,
  deleteSearches,
  selectSearch,
  setSelectedSearchIds,
  setDeleteSearchIds,
  deselectSearch,
  deselectAllSearches,
  changeCurrentPage,
  changeFilters,
  changeSort,
  showEditSearchModal,
  showEditSourceFolderModal,
  showAssignModal,
  hideAssignModal,
  sendSearchesCsvEmail,
  showSearchesManageLabelsModal
} from './searches-admin-actions'
import { getSearchesAdmin } from './searches-admin-selectors'
import ManageLabelsSection from './ManageLabelsSection';
import DjangoCSRFToken from 'django-react-csrftoken'
import ManageLabelsModal from './ManageLabels/ManageLabelsModal'
import BulkEditLabelModal from './ManageLabels/BulkEditLabelModal'


@connect(
  // Map state to props
  createSelector(
    [getEntities, getSearchesAdmin, getAppName, getAppNameFull, getCurrentUserId],
    (entities, searchesAdmin, appName, appNameFull, currentUserId) => {
      const orm = Orm.withEntities(entities)
      return {
        searches: orm.getByIds(SavedSearch, searchesAdmin.searchIds || []),
        searchIds: searchesAdmin.searchIds,
        totalCount: searchesAdmin.totalCount,
        selectedSearchIds: searchesAdmin.selectedSearchIds,
        searchesToDelete: orm.getByIds(SavedSearch, searchesAdmin.deleteSearchIds),
        currentPage: searchesAdmin.currentPage,
        pageCount: searchesAdmin.pageCount,
        filters: searchesAdmin.filters,
        sort: {
          field: searchesAdmin.sortField,
          direction: searchesAdmin.sortDirection,
        },
        isLoading: searchesAdmin.isLoading,
        appName: appName,
        appNameFull: appNameFull,
        shouldShowEditModal: searchesAdmin.shouldShowEditModal,
        shouldShowAssignModal: searchesAdmin.shouldShowAssignModal,
        shouldShowAddModal: searchesAdmin.addNewModalData.isOpen,
        shouldShowSourceFolderModal: !!searchesAdmin.editSourceFolderModal,
        shouldShowBulkAddFiltersModal: searchesAdmin.shouldShowBulkAddFiltersModal,
        currentUser: orm.getById(User, currentUserId),
        shouldShowManageLabelsModal: searchesAdmin.shouldShowManageLabelsModal,
        shouldShowBulkEditLabelModal: searchesAdmin.shouldShowBulkEditLabelModal,
        selectedColumns: searchesAdmin.selectedColumns,
        itemsPerPage: searchesAdmin.itemsPerPage,
      }
    },
  ),

  // Map dispatch to props
  {
    fetchAllSearchIds,
    deleteSearches,
    selectSearch,
    setSelectedSearchIds,
    setDeleteSearchIds,
    deselectSearch,
    deselectAllSearches,
    changeCurrentPage,
    changeFilters,
    changeSort,
    showEditSearchModal,
    showEditSourceFolderModal,
    showAssignModal,
    hideAssignModal,
    sendSearchesCsvEmail,
    showManageLabelsModal: showSearchesManageLabelsModal
  },
)
export default class SearchesAdmin extends Component {
  state = {
    message: null,
  }

  render() {
    const {
      selectedSearchIds,
      searchesToDelete,
      sort,
      currentUser,
      shouldShowManageLabelsModal
    } = this.props

    let modal
    if (this.props.shouldShowManageLabelsModal) {
      modal = <ManageLabelsModal />
    } else if (this.props.shouldShowBulkEditLabelModal) {
      modal = <BulkEditLabelModal />
    }

    const isModalOpen =
      this.props.shouldShowEditModal ||
      this.props.shouldShowAssignModal ||
      this.props.shouldShowAddModal ||
      this.props.shouldShowSourceFolderModal ||
      this.props.searchesToDelete.length > 0 ||
      this.props.shouldShowBulkAddFiltersModal ||
      this.props.shouldShowManageLabelsModal ||
      this.props.shouldShowBulkEditLabelModal

    const loadingOverlay = this.props.isLoading
      ? <div className={classNames('loading-container', {'is-modal': isModalOpen})}>
          <LoadingOverlay/>
        </div>
      : null

    const selectedCount = this.props.selectedSearchIds.length
    const selectedCountDisplay = formatNumber(selectedCount)
    const totalCount = this.props.totalCount
    const totalCountDisplay = formatNumber(totalCount)
    const selectedCountText =
      selectedCount ?
        <span className="selected-count-text">
          {
            selectedCount === totalCount ?
              `All ${selectedCountDisplay} searches selected`
              : `${selectedCountDisplay} of ${totalCountDisplay} searches selected`
          }
        </span>
        : null

    const selectAllLink =
      selectedCount === totalCount ?
        null
        : <a href="#" onClick={() => this.setSelectAllType('all')}>Select All {totalCountDisplay}</a>

      const deleteConfirmationModal =
        searchesToDelete.length > 0 &&
        <DeleteSearchesConfirmationModal
          searches={searchesToDelete}
          onConfirm={() => this.props.deleteSearches()}
          onClose={() => this.props.setDeleteSearchIds([])}
        />
      
    const pageCount = Math.ceil(this.props.totalCount / this.props.itemsPerPage)

    return (
      <div id="searches-admin">
       <div id="firm-sources-main-wrapper">
        <ManageLabelsSection
            onManageLabels={() => this.props.showManageLabelsModal(true)}
          />
          <AddNewSearch />

          <h1>Manage Searches for {currentUser.firm.name}</h1>

          <Header
            filterState={this.props.filters}
            onFilterStateChange={this.setFilters.bind(this)}
          />

          <div id="firm-searches-header-wrapper">
            <div className="selected-count">
              {selectedCountText}{selectAllLink}
            </div>

            <BulkActions
              onExport={this.exportSearches.bind(this, selectedSearchIds)}
              onDelete={this.deleteSearches.bind(this, selectedSearchIds)}
              onChangeSelectAll={(type) => this.setSelectAllType(type)}
            />
          </div>

          <div id="firm-searches-container">
            <SearchesTable
              isLoading={this.props.isLoading}
              searches={this.props.searches}
              selectedSearchIds={this.props.selectedSearchIds}
              onSearchSelectStateChange={this.setSearchSelectState.bind(this)}
              currentPage={this.props.currentPage}
              pageCount={pageCount}
              onPageChange={this.changePage.bind(this)}
              onEdit={this.showEditSearchModal.bind(this)}
              onDelete={this.deleteSearch.bind(this)}
              sort={{
                column: this.sortColumnFromField(sort.field),
                direction: sort.direction,
              }}
              onSortChange={this.changeSort.bind(this)}
              selectedColumns={this.props.selectedColumns}
            />
          </div>
        </div>
        {modal}
        {deleteConfirmationModal}
        <FeatureModal/>
        <BulkAddFiltersModal/>
        {loadingOverlay}
        <form id="download-csv-form" method="post" action="/profile/firm/saved-search-csv-export/">
          <DjangoCSRFToken/>
          <input id="csv_ss_ids" name="ss_ids"/>
        </form>
      </div>
    )
  }

  // State management

  getSearchById(id) {
    return this.props.searches.find(ss => ss.id === id)
  }

  setSelectAllType(type) {
    const allAreSelected = this.props.selectedSearchIds.length === this.props.totalCount
    if (type === 'all') {
      this.props.fetchAllSearchIds()
    } else if (type === 'visible') {
      if (allAreSelected) {
        this.props.deselectAllSearches()
      }
      this.props.setSelectedSearchIds(this.props.searchIds)
    } else {
      this.props.deselectAllSearches()
    }
  }

  setSearchSelectState(id, selected) {
    if (selected) {
      this.props.selectSearch(id)
    } else {
      this.props.deselectSearch(id)
    }
  }

  changePage(page) {
    this.props.changeCurrentPage(page)
  }

  changeSort(sort) {
    // Convert the column name to a field name the backend will recognize
    const field = this.sortFieldFromColumn(sort.column)
    this.props.changeSort({field, direction: sort.direction})
  }

  sortFieldFromColumn(column) {
    return {
      [SearchesTable.COLUMNS.TYPE]: 'scope',
    }[column] || column
  }

  sortColumnFromField(field) {
    return {
      'scope': SearchesTable.COLUMNS.TYPE,
    }[field] || field
  }

  setFilters(filters) {
    this.props.changeFilters(filters)
  }

  deleteSearch(searchId) {
    this.props.setDeleteSearchIds([searchId])
  }

  deleteSearches(searchIds) {
    this.props.setDeleteSearchIds(searchIds)
  }

  /**
   * exports of 20 or less are processed immediately.
   * others are processed by a background task which emails the csv to the user.
   */
  exportSearches(searchIds) {
    if (searchIds.length > 20) {
      this.props.sendSearchesCsvEmail({searchIds})
    } else {
      document.getElementById('csv_ss_ids').value = searchIds.join(',')
      const csvForm = document.getElementById('download-csv-form')
      csvForm.submit()
    }
  }

  manageSource(search) {
    window.location.href = urls.firmAdmin.sourcesAdmin({
      initialFeedId: search.aboutFeedsIds[0],
    })
  }

  // Modals

  showEditSearchModal(searchId, tab = EditModal.TABS.DETAILS) {
    const search = this.getSearchById(searchId)
    if (search.isSource) {
      if (search.category === SavedSearch.CATEGORIES.TRUSTED_UNCATEGORIZED) {
        this.manageSource(search)
      } else {
        this.props.showEditSourceFolderModal({searchId})
      }
      return
    }

    this.props.showEditSearchModal({
      searchId: search.id,
      ownerId: search.ownerId,
      tab,
    })
  }
}
