import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import Switch from 'react-switch'
import RRule from 'rrule'
import moment from 'moment/moment'

import Modal from 'app/common/Modal'
import LoadingOverlay from 'app/common/LoadingOverlay'
import Message from 'app/common/Message'
import Button from 'app/common/Button'

import CustomReports from './CustomReports'
import AdminModal from './AdminModal'
import { getMIS } from './mis-selectors'
import * as misActions from './mis-actions'


/**
 * note: free reports are now called "Illuminations". still using `free` as report type.
 * TODO: rename the slug back to "free-reports" for consistency (names are pulled from the db anyway).
 */
@connect(
  // Map state to props
  state => {
    const mis = getMIS(state)
    return {
      currentUserIsAdmin: state.currentUserIsStaff || state.currentUserIsClientAdmin,
      isDiligent: state.isDiligent,
      appName: state.appName,
      currentUserData: mis.currentUserData,
      headerDescription: mis.headerDescription,
      sections: mis.sections,
      imageUrl: mis.imageUrl,
      reports: Object.values(mis.reports),
      currentUserReportIds: mis.currentUserReportIds,
      currentReportId: mis.currentReportId,
      archives: mis.archives,
      recipients: mis.recipients,
      isLoading: mis.isLoading,
      notificationMessage: mis.notificationMessage,
      notificationType: mis.notificationType,
      renderedReports: mis.renderedReports,
      reportsWithFetchedRecipients: mis.reportsWithFetchedRecipients,
      currentRenderedReport: mis.currentRenderedReport,
      renderedReportHtml: (
        mis.currentRenderedReport && (mis.currentRenderedReport.id in mis.renderedReports) ?
          mis.renderedReports[mis.currentRenderedReport.id] : null
      ),
    }
  },

  // Map dispatch to props
  {
    getReports: misActions.getReports,
    getArchives: misActions.getArchives,
    getRecipients: misActions.getRecipients,
    setReports: misActions.setReports,
    getRenderedReport: misActions.getRenderedReport,
    downloadRenderedReportPDF: misActions.downloadRenderedReportPDF,
    showRenderedReport: misActions.showRenderedReport,
    hideRenderedReport: misActions.hideRenderedReport,
    showNotification: misActions.showNotification,
    hideNotification: misActions.hideNotification,
    setCurrentReportId: misActions.setCurrentReportId,
    setNewEmailAddress: misActions.setNewEmailAddress,
    setNewFirstName: misActions.setNewFirstName,
    setNewLastName: misActions.setNewLastName,
    addRecipient: misActions.addRecipient,
    setCurrentRecipient: misActions.setCurrentRecipient,
    saveRecipient: misActions.saveRecipient,
    deleteRecipient: misActions.deleteRecipient,
  },
)
export default class MIS extends Component {
  state = {
    currentSection: null,
    editedRecipient: null,
    recipientFilterValue: '',
    reportIdsShowingArchives: [],
  }

  componentDidMount() {
    const parts = window.location.href.split('/')
    const slug = parts[parts.length - 2]
    const section = this.getSectionBySlug(slug)
    if (section) {
      this.setCurrentSection(section)
    } else if (this.props.sections.length === 1) {
      // skip the landing page if there's only one section
      this.setCurrentSection(this.props.sections[0])
    }
  }

  render() {
    const tabs = this.props.sections.map(section => {
      return (
        <li
          key={`mis-tab-${section.id}`}
          className={classNames(
              'primary',
              {'active': this.state.currentSection && section.id === this.state.currentSection.id}
            )}
        >
          <a
            href="#"
            onClick={evt => this.handleTabClick(evt, section)}
          >
            <span className="search-label">{section.name}</span>
          </a>
        </li>
      )
    })

    const title = this.props.isDiligent
      ? 'Intel Now'
      : 'Manzama Intelligence Services'

    const subHeader = this.props.isDiligent
      ? 'Receive automated Intelligence on a regular basis. No setup required, simply turn the Ready Reports to On and ' +
        'start receiving information via email.'
      : 'Uncover Business Opportunities. Power Up Your Competitive Intelligence. Strengthen Client Relationships.'

    const header =
      this.state.currentSection ?
        <div>
          <h3 className="header">{this.state.currentSection.name}</h3>
          <p className="section-description">{this.state.currentSection.description}</p>
        </div>
        : <div>
            <h3 className="header">{title}</h3>
            <p className="sub-header">{subHeader}</p>
            <p>{this.props.headerDescription}</p>
          </div>

    const landingPage = this.props.sections.map(section => {
      return (
        <div
          key={`mis-section-${section.id}`}
          className="section-row"
        >
          <div className="section section-image">
            <img src={this.props.imageUrl + section.image}/>
          </div>

          <div className="section section-content">
            <div className="content-header">{section.name}</div>
            <div>{section.description}</div>

            <div>
              <button
                className="button primary"
                onClick={() => this.setCurrentSection(section)}
              >{section.displayUrlAs}</button>
            </div>
          </div>
        </div>
      )
    })

    const reportData =
      this.state.currentSection ?
        this.state.currentSection.slug === 'illuminations' ?
          this.props.reports.filter(report => {
            return report.type === 'free'
          })
          : this.props.reports.filter(report => {
              return report.type === 'premium'
            })
        : []

    const reportsContent = reportData.map(report => {
      return (
        <div
          key={`mis-report-${report.id}`}
          className="report"
        >
          <div className="report-column align-center">
            {
              report.enabled
                ? <Switch
                    id={report.id.toString()}
                    onChange={(checked, evt, id) => this.handleReportToggle(checked, report)}
                    checked={this.props.currentUserReportIds.includes(report.id)}
                    height={22}
                    width={48}
                    handleDiameter={16}
                    offColor="#ccc"
                    onColor="#0074b4"
                    uncheckedIcon={
                      <div className="switch-icon">OFF</div>
                    }
                    checkedIcon={
                      <div className="switch-icon">ON</div>
                    }
                  />
                : <div>
                    <Button
                      label={
                        report.trialId
                          ? 'Signup for Free Trial'
                          : 'Learn About Free Trial'
                      }
                      className="free-trial-button"
                      onClick={() => {
                        if (report.trialId) {
                          this.handleReportToggle(true, report)
                        } else {
                          window.open(report.signUpUrl)
                        }
                      }}
                    />
                  </div>
            }
          </div>

          <div className="report-column inner-flex-container">
            <div className="inner-flex">
              <div className="report-details">
                <div className="report-title">
                  <h3 className="report-title-part">{report.title}</h3>
                  <div className="report-title-part report-title-schedule">{this.getScheduleDisplay(report)}</div>
                </div>
                <div>{report.description}</div>
              </div>
              {
                report.enabled ?
                  <div className="report-options">
                    <div
                      className="archive-toggle-container"
                      onClick={() => this.handleArchiveViewToggle(report.id)}
                    >
                      <div>Archive</div>
                      <div className={classNames('arrow', {open: this.state.reportIdsShowingArchives.includes(report.id)})} />
                    </div>
                    {
                      this.props.currentUserIsAdmin ?
                        <div
                          className="admin-icon fa fa-cog"
                          onClick={() => this.handleAdminIconClick(report)}
                        />
                        : null

                    }
                  </div>
                  : null
              }
            </div>

            {
              this.state.reportIdsShowingArchives.includes(report.id) && this.props.archives.hasOwnProperty(report.id) ?
                <div className="archive-list">
                  {
                    this.props.archives[report.id].length ?
                      this.props.archives[report.id].map(rp => {
                        return (
                          <div
                            key={`archive-item-${rp.id}`}
                            className="archive-item"
                            onClick={() => this.handleRenderedReportClick(rp)}
                          >
                            <span>{rp.createdAt}</span>
                          </div>
                        )
                      })
                      : <div>There are no archives.</div>
                  }
                </div>
                : null
            }
          </div>
        </div>
      )
    })

    const privacyPolicyUrl = this.props.isDiligent
      ? 'http://www.diligent.com/privacy-policy/'
      : 'http://manzama.com/privacy-policy/'

    const body =
      this.state.currentSection ?
        ['illuminations', 'premium-reports'].includes(this.state.currentSection.slug) ?
          <div>
            <h3 className="header">Subscriptions</h3>
            {reportsContent}
            {
              this.state.currentSection.slug === 'illuminations' ?
                <p className="privacy-policy-link">By opting in to receive a newsletter sent by {this.props.appName}, you are
                  accepting the {this.props.appName} <a href={privacyPolicyUrl} target="_blank">privacy policy</a>.</p>
                : null
            }
          </div>
          : <div>
              <h3 className="header">Examples</h3>
              <div id="custom-reports">
                <CustomReports
                  imageUrl={this.props.imageUrl}
                />
              </div>
            </div>
        : <div>
            {landingPage}
          </div>

    const loader =
      this.props.isLoading && this.props.currentReportId === null?
        <LoadingOverlay/>
        : null

    const reportContentModal =
      <div className="modal-container">
        <Modal
          isOpen={!!this.props.currentRenderedReport}
          showDownload={true}
          onClose={() => this.closeReportContentModal()}
          onDownload={() => this.downloadReportPDF()}
        >
          <div
            className="rendered-report-content"
            dangerouslySetInnerHTML={{__html: this.props.renderedReportHtml}}
          />
        </Modal>
      </div>

    const message =
      this.props.notificationMessage && this.props.currentReportId === null?
        <Message
          type={this.props.notificationType}
          text={this.props.notificationMessage}
        />
        : null

    return (
      <div>
        <div id="nav-primary-bar">
          <div id="nav-primary">
            <div id="nav-primary-content">
              <div>
                <ul>
                  {tabs}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="mis-body">
          {message}
          {header}
          {body}
        </div>
        {loader}
        {reportContentModal}
        <AdminModal
          currentSection={this.state.currentSection}
        />
      </div>
    )
  }

  handleTabClick(evt, section) {
    evt.preventDefault()
    this.setCurrentSection(section)
  }

  handleReportToggle(checked, report) {
    if (checked) {
      this.props.addRecipient({report})
    } else {
      this.props.deleteRecipient({report})
    }
  }

  handleArchiveViewToggle(reportId) {
    let reportIdsShowingArchives = [...this.state.reportIdsShowingArchives]
    if (reportIdsShowingArchives.includes(reportId)) {
      reportIdsShowingArchives = reportIdsShowingArchives.filter(id => id !== reportId)
    } else {
      reportIdsShowingArchives.push(reportId)
    }
    this.setState({reportIdsShowingArchives})
    /**
     * archives for internal reports are included when the reports are fetched.
     * fetching archives for external reports can be slow, so we fetch them here (i.e only when requested).
      */
    if (!this.props.archives.hasOwnProperty(reportId)) {
      const reportType = this.state.currentSection.slug === 'illuminations' ? 'free' : 'premium'
      this.props.getArchives(reportType)
    }
  }

  handleRenderedReportClick(rp) {
    if (rp.id in this.props.renderedReports) {
      this.props.showRenderedReport(rp)
    } else {
      this.props.getRenderedReport(rp)
    }
  }

  handleAdminIconClick(report) {
    // only fetch recipients for a report if we don't already have them.
    if (this.props.reportsWithFetchedRecipients.includes(report.id)) {
      this.props.setCurrentReportId(report.id)
    } else {
      this.props.getRecipients({reportType: report.type, reportId: report.id})
    }
  }

  setCurrentSection(section, addHistory = true) {
    if (['illuminations', 'premium-reports'].includes(section.slug) && !this.props.reports.length) {
      this.props.getReports()
    }
    this.setState({currentSection: section})
    if (addHistory) {
      history.pushState(section, section.name, `/mis/${section.slug}`)
    }
  }

  closeReportContentModal() {
    this.props.hideRenderedReport()
  }

  downloadReportPDF() {
    this.props.downloadRenderedReportPDF(this.props.currentRenderedReport)
  }

  getSectionBySlug(slug) {
    return this.props.sections.find(section => section.slug === slug)
  }

  getScheduleDisplay(report) {
    if (report.schedule) {
      return report.schedule
    }
    if (!report.rrule || !report.time) {
      return ''
    }
    const rule = new RRule(RRule.parseString(report.rrule))
    const textRule = rule.toText()
    const d = new Date('Apr 13, 2018 ' + report.time)
    const m = moment(d)
    const timeString = ' at ' + m.format('h:mm A')
    return textRule.charAt(0).toUpperCase() + textRule.slice(1) + timeString
  }
}
