import { createAction } from 'redux-actions'

// Prefix action names with SOURCES_ADMIN_
const createSourcesAdminAction = name => createAction(`SOURCES_ADMIN_${name}`)

// TODO: most of these action names should be 'source' instead of 'feed'
export const init = createSourcesAdminAction('INIT')
export const setFilters = createSourcesAdminAction('SET_FILTERS')
export const setSort = createSourcesAdminAction('SET_SORT')
export const fetchFeeds = createSourcesAdminAction('FETCH_FEEDS')
export const fetchAssigneesComplete = createSourcesAdminAction('FETCH_ASSIGNEES_COMPLETE')
export const fetchFeedsSuccess = createSourcesAdminAction('FETCH_FEEDS_SUCCESS')
export const setPageNumber = createSourcesAdminAction('SET_PAGE_NUMBER')
export const selectAllFeeds = createSourcesAdminAction('SELECT_ALL_FEEDS')
export const showAssignModal = createSourcesAdminAction('SHOW_ASSIGN_MODAL')
export const setAssignModalAssigneeIdsBySection = createSourcesAdminAction('SET_ASSIGN_MODAL_ASSIGNEE_IDS_BY_SECTION')
export const hideAssignModal = createSourcesAdminAction('HIDE_ASSIGN_MODAL')
export const showAssignSourcesContent = createSourcesAdminAction('SHOW_ASSIGN_SOURCES_CONTENT')
export const hideAssignSourcesContent = createSourcesAdminAction('HIDE_ASSIGN_SOURCES_CONTENT')
export const selectFeed = createSourcesAdminAction('SELECT_FEED')
export const deselectFeed = createSourcesAdminAction('DESELECT_FEED')
export const showEditModal = createSourcesAdminAction('SHOW_EDIT_MODAL')
export const hideEditModal = createSourcesAdminAction('HIDE_EDIT_MODAL')
export const addAssignments = createSourcesAdminAction('ADD_ASSIGNMENTS')
export const updateAssignments = createSourcesAdminAction('UPDATE_ASSIGNMENTS')
export const removeAssignments = createSourcesAdminAction('REMOVE_ASSIGNMENTS')
export const setSelectedFeedIds = createSourcesAdminAction('SET_SELECTED_FEED_IDS')
export const showLoader = createSourcesAdminAction('SHOW_LOADER')
export const hideLoader = createSourcesAdminAction('HIDE_LOADER')
export const promoteFeeds = createSourcesAdminAction('PROMOTE_FEEDS')
export const unpromoteFeeds = createSourcesAdminAction('UNPROMOTE_FEEDS')
export const demoteFeeds = createSourcesAdminAction('DEMOTE_FEEDS')
export const undemoteFeeds = createSourcesAdminAction('UNDEMOTE_FEEDS')
export const excludeFeeds = createSourcesAdminAction('EXCLUDE_FEEDS')
export const removeFeeds = createSourcesAdminAction('REMOVE_FEEDS')
export const setEditModalDataDetails = createSourcesAdminAction('SET_EDIT_MODAL_DATA_DETAILS')
export const saveFeed = createSourcesAdminAction('SAVE_FEED')
export const setShouldShowBrowseModal = createSourcesAdminAction('SET_SHOULD_SHOW_BROWSE_MODAL')
export const setShouldShowSelfAddModal = createSourcesAdminAction('SET_SHOULD_SHOW_SELF_ADD_MODAL')
export const createSources = createSourcesAdminAction('CREATE_SOURCES')
export const createSourcesComplete = createSourcesAdminAction('CREATE_SOURCES_COMPLETE')
export const validateFeed = createSourcesAdminAction('VALIDATE_FEED')
export const updateCreateProprietaryFeedData = createSourcesAdminAction('UPDATE_CREATE_PROPRIETARY_FEED_DATA')
export const resetCreateProprietaryFeedData = createSourcesAdminAction('RESET_CREATE_PROPRIETARY_FEED_DATA')
export const createProprietaryFeed = createSourcesAdminAction('CREATE_PROPRIETARY_FEED')
export const updateCreateTwitterFeedData = createSourcesAdminAction('UPDATE_CREATE_TWITTER_FEED_DATA')
export const createTwitterFeed = createSourcesAdminAction('CREATE_TWITTER_FEED')
export const sendCsvEmail = createSourcesAdminAction('SEND_CSV_EMAIL')
export const setSourceModalData = createSourcesAdminAction('SET_SOURCE_MODAL_DATA')
// firm source labels
export const fetchLabels = createSourcesAdminAction('FETCH_LABELS')
export const fetchLabelsComplete = createSourcesAdminAction('FETCH_LABELS_COMPLETE')
export const setNewLabelData = createSourcesAdminAction('SET_NEW_LABEL_DATA')
export const resetNewLabelData = createSourcesAdminAction('RESET_NEW_LABEL_DATA')
export const createLabel = createSourcesAdminAction('CREATE_LABEL')
export const setIsManageLabelsModalShown = createSourcesAdminAction('SET_IS_MANAGE_LABELS_MODAL_SHOWN')
export const setManageLabelsModalData = createSourcesAdminAction('SET_MANAGE_LABELS_MODAL_DATA')
export const resetManageLabelsModalData = createSourcesAdminAction('RESET_MANAGE_LABELS_MODAL_DATA')
export const saveLabel = createSourcesAdminAction('SAVE_LABEL')
export const deleteLabel = createSourcesAdminAction('DELETE_LABEL')
export const setIsLabelModalShown = createSourcesAdminAction('SET_IS_LABEL_MODAL_SHOWN')
export const updateLabelModalData = createSourcesAdminAction('UPDATE_LABEL_MODAL_DATA')
export const resetLabelModalData = createSourcesAdminAction('RESET_LABEL_MODAL_DATA')
export const addLabels = createSourcesAdminAction('ADD_LABELS')
export const removeLabels = createSourcesAdminAction('REMOVE_LABELS')

// featured sources
export const addFeaturedSources = createSourcesAdminAction('ADD_FEATURED_SOURCES')
export const removeFeaturedSources = createSourcesAdminAction('REMOVE_FEATURED_SOURCES')

export const fetchAllParentLabels = createSourcesAdminAction('FETCH_ALL_PARENT_LABELS')
export const fetchAllParentLabelsComplete = createSourcesAdminAction('FETCH_ALL_PARENT_LABELS_COMPLETE')
export const fetchManageLabels = createSourcesAdminAction('FETCH_MANAGE_LABELS')
export const setManageLabelsTotalCount = createSourcesAdminAction('SET_MANAGE_LABELS_TOTAL_COUNT')
export const setManageLabelsFilters = createSourcesAdminAction('SET_MANAGE_LABELS_FILTERS')

export const updateLabels = createSourcesAdminAction('UPDATE_LABELS')
export const updateBulkEditLabels = createSourcesAdminAction('UPDATE_BULK_EDIT_LABELS')

export const showSelectedColumns = createAction('SHOW_SELECTED_COLUMNS')

export const setReportModalVisibility = createAction('SET_REPORT_MODAL_VISIBILITY')
export const setFeedIdsToReport = createAction('SET_FEED_IDS_TO_REPORT')
export const reportFeeds = createSourcesAdminAction('REPORT_FEEDS')
export const setSelectedAllFeedsHealth = createSourcesAdminAction('SET_SELECTED_ALL_FEEDS_HEALTH')
