import React from 'react'
import {titleCase} from 'title-case'


export function getFirmCategoryLabel({category, currentFirm, titleCased=true}) {

  const categoryLabel = (category) => {

    if (category === null || category === undefined) {
      return 'Company'
    }

    switch (category.toLowerCase()) {
      case 'client':
        return currentFirm.clientDisplayName
      case 'clients':
        return currentFirm.clientDisplayNamePlural
      case 'prospect':
        return currentFirm.clientDisplayName
      case 'prospects':
        return currentFirm.clientDisplayNamePlural
      case 'company':
        return currentFirm.clientDisplayName
      case 'companies':
        return currentFirm.clientDisplayNamePlural

      case 'competitor':
        return currentFirm.competitorDisplayName
      case 'competitors':
        return currentFirm.competitorDisplayNamePlural
      case 'firm':
        return currentFirm.competitorDisplayName
      case 'firms':
        return currentFirm.competitorDisplayNamePlural

      case 'industry':
        return currentFirm.industryDisplayName
      case 'industries':
        return currentFirm.industryDisplayNamePlural

      case 'practice':
        return currentFirm.practiceDisplayName
      case 'practices':
        return currentFirm.practiceDisplayNamePlural

      case 'tracker':
        return currentFirm.trackerDisplayName
      case 'trackers':
        return currentFirm.trackerDisplayNamePlural
      case 'topic':
        return currentFirm.trackerDisplayName
      case 'topics':
        return currentFirm.trackerDisplayNamePlural

      case 'trusted':
        return 'Sources'
      case 'trusted-uncategorized':
        return 'Sources'

      default:
        return category
    }
  }

  const label = titleCased ? titleCase(categoryLabel(category)) : categoryLabel(category)

  return (label)
}
