export function getCountryOptions(countries) {
  return [{
    value: null,
    label: '--',
  }].concat(countries.map(c => {
    return ({
      value: c.id,
      label: c.name,
    })
  }))
}

export function getTimezoneOptions(timezones) {
  return [{
    value: null,
    label: '--',
  }].concat(timezones.map(tz => {
    const value = tz[1] === '---------------'
      ? null
      : tz[0]
    return ({
      value: value,
      label: tz[1],
    })
  }))
}

export function getLanguageDisplayName(language) {
  return language.nativeName ? `${language.name} (${language.nativeName})` : language.name
}

export function getLanguageOptions(languages) {
  return [{
    value: null,
    label: '--',
  }].concat(languages.map(language => {
    return {
      value: language.id,
      label: getLanguageDisplayName(language),
    }
  }))
}
