import React, {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'
import {createSelector} from 'reselect'

import LoadingOverlay from 'app/common/LoadingOverlay'
import {getIsLoading, getBrowseModalData, getEditSearchId} from './profile-searches-selectors'
import AddSearches from './AddSearches'
import Searches from './Searches'
import ProfileEditSearchModal from './ProfileEditSearchModal'

import './ProfileSearches.less'


@connect(
  createSelector(
    [getIsLoading, getBrowseModalData, getEditSearchId],
    (isLoading, browseModalData, editSearchId) => {
      return {
        isLoading,
        browseModalData,
        editSearchId,
      }
    }
  ),
  {

  }
)
export default class ProfileSearches extends Component {
  render() {
    const loader =
      this.props.isLoading &&
      <LoadingOverlay
        className={classNames({'modal-is-open': !!this.props.browseModalData.type})}
      />

    const tips =
      <div className="tips">
        <h4 className="group-heading">Tips</h4>
        <div>
          <p>Our system has thousands of pre-built searches, covering industries, companies, organizations, as well as focused topics.</p>

          <p>To find and add a pre-built search to your profile, start typing in the input field. The system will suggest matching searches.
            If you see one you’d like to use simply click on it, then click "Add". If you don’t see a suggestion that suits your needs,
            simply type the complete word or phrase you want to search for and press Enter to create a free-text search. You can add multiple
            search items at one time, each separate capsule is a distinct search that will be added.</p>

          <p>Finally, if you would prefer, you are able to use the Browse button to view available searches that may also be added to your profile.</p>
        </div>
      </div>

    return (
      <div id="profile-searches">
        <div className="columns">
          <div className="searches">
            <AddSearches/>

            <Searches/>
          </div>

          {tips}
        </div>

        {loader}

        {this.props.editSearchId && <ProfileEditSearchModal/>}
      </div>
    )
  }
}
