import React from 'react'
import { useDrop } from 'react-dnd'
import classNames from 'classnames'


export default function DropArea(props) {
  const [{isOver}, drop] = useDrop({
    accept: 'feed-list-entry',
    drop: (
        item,
        monitor,
      ) => props.onDrop(item.id, props.destination, item.origin, props.destinationId, item.originId),
    collect: monitor => ({
			isOver: !!monitor.isOver(),
		}),
  })

  return (
    <div
      ref={drop}
      className={
        classNames(
          'drop-area',
          {
            'is-over': isOver,
            'trusted-folder': props.destination === 'trusted-folder',
            [props.className]: !!props.className,
          }
        )
      }
    >
      {props.children}
    </div>
  )
}
