import React from 'react'
import styles from './DashboardSidebar.less'
import classNames from "classnames"


function EmptySidebar() {
  return (
    <div className={styles.sidebarWrapper}>
      <div
        className={styles.sidebar}
      >
        <div className={classNames(styles.searchCategory, styles.isEmpty)}/>
        <div className={styles.searchNamesContainer}>
          <div className={classNames(styles.searchName)}/>
        </div>
      </div>
    </div>
  )
}

export default EmptySidebar
