import invariant from 'invariant'
import {createAction} from 'redux-actions'

import {NOTIFICATION_TYPES} from './notifications-constants'
import * as utils from './notifications-utils'

const action = (name, ...args) => createAction(`notifications/${name}`, ...args)

// The action that should be used externally to show notifications. Duration is
// in seconds. If duration is falsy, the notification will never hide on its own
// and will have to be dismissed manually.
export const showNotification = action(
  'SHOW_NOTIFICATION',
  ({type, message, duration = 5}) => {
    invariant(
      Object.values(NOTIFICATION_TYPES).includes(type),
      `Invalid message type '${type}'.`,
    )
    return {
      id: utils.generateId(),
      type,
      message,
      duration,
    }
  },
)

// Used internally to hide notifications. Payload is the message ID.
export const hideNotification = action('HIDE_NOTIFICATION')
