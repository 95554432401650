import React, { Component } from 'react'
import { connect, Provider } from 'react-redux'

import { Membership, User } from 'app/models'
import Orm from 'app/framework/Orm'

import {
  addGroupToUsers,
  removeGroupFromUsers,
  toggleMembershipIsManager,
  fetchUser,
  fetchAvailableMembers,
} from '../users-admin-actions'
import * as api from '../users-admin-api'
import { getUsersAdmin } from '../users-admin-selectors'
import MemberList from './MemberList'


/**
 * this displays the users that are a member of a group.
 */

@connect(
  // Map state to props
  state => {
    const orm = Orm.withEntities(state.entities)
    const usersAdmin = getUsersAdmin(state)
    return {
      user: orm.getById(User, usersAdmin.userModalData.userId),
      availableMembers: orm.getByIds(User, usersAdmin.availableMemberIds),
      memberships: orm.getByIds(Membership, usersAdmin.userMemberships),
      availableMemberCount: usersAdmin.availableMemberCount,
      areAllAvailableMembersFetched: usersAdmin.areAllAvailableMembersFetched,
    }
  },

  // Map dispatch to props
  {
    addGroupToUsers,
    removeGroupFromUsers,
    toggleMembershipIsManager,
    fetchUser,
    fetchAvailableMembers,
  },
)
export class UserMemberships extends Component {
  render() {
    return (
      <MemberList
        type="user"
        availableMembers={this.props.availableMembers}
        memberships={this.props.memberships}
        availableMemberCount={this.props.availableMemberCount}
        areAllAvailableMembersFetched={this.props.areAllAvailableMembersFetched}
        toggleManager={(userId) => this.toggleManager(userId)}
        onMemberQuickAdd={this.memberQuickAdd}
        add={(userIds) => this.addGroupToUsers(userIds)}
        remove={(userIds) => this.removeGroupFromUsers(userIds)}
        onFetchMembers={this.props.fetchAvailableMembers}
      />
    )
  }

  addGroupToUsers(userIds) {
    this.props.addGroupToUsers({groupId: this.props.user.id, userIds})
  }

  removeGroupFromUsers(userIds) {
    this.props.removeGroupFromUsers({groupId: this.props.user.id, userIds})
  }

  toggleManager(userId) {
    this.props.toggleMembershipIsManager({groupId: this.props.user.id, userId})
  }

  memberQuickAdd = (email) => {
    const {user} = this.props
    api.memberQuickAdd(user.id, email, (err, res) => {
      if (err) {
        notify.error("Something went wrong with the submission.")
        return
      }

      const {body} = res

      if (body.userAdded) {
        /**
         * `userAdded` actually indicates that the user already exists, and was added to the group.
         * here we just need to re-fetch the group to update the member lists.
         */
        this.props.fetchUser(user.id)
      } else {
        /**
         * `addUserOption` indicates that the user does not exist. prompt to have it created.
         */
        if (body.addUserOption) {
          smoke.confirm(body.msg, (e) => {
            if (e) {
              this.quickCreate(email)
            }
          }, {
            reverseButtons: true
          })
          smokeOutsideClickRemove()
        } else {
          notify.error(body.msg)
        }
      }
    })
  }

  quickCreate(email) {
    api.quickCreateFirmAccount(email, (err, res) => {
      if (err) {
        notify.error("Something went wrong with the submission.")
        return
      }
      // user was created; now add to group.
      this.props.addGroupToUsers({groupId: this.props.user.id, userIds: [res.body.result]})
    })
  }
}
