import classNames from 'classnames'
import {range} from 'ramda'
import React from 'react'

import * as documentContainerStyles from
    'app/comparison-page/comparison-document-list/ComparisonDocumentList.less'
import * as documentStyles from 'app/reusable/ResultDocument/style.less'
import * as styles from './ComparisonDocumentListLoading.less'


function Document() {
  return (
    <div className={documentContainerStyles.documentList}>
      <div className={documentContainerStyles.documentListContainer}>
        <div className={documentStyles.document}>
          <div className={classNames(documentStyles.mainContainer, documentStyles.isDashboard)}>
            <div className={classNames(documentStyles.headline, styles.headline)}/>
            <div className={classNames(documentStyles.publishedMeta, styles.publishedMeta)} />
            <div className={styles.snippet}>
              <div className={styles.line} />
              <div className={styles.line} />
              <div className={styles.line} />
            </div>
          </div>
        </div>
        <div className={documentStyles.interactionBar} />
      </div>
    </div>
  )
}


export default function ComparisonDocumentListLoading() {
  return range(0, 3).map(index => <Document key={index} />)
}
