import { all, put, select, takeLatest } from 'redux-saga/effects'

import * as globalSelectors from 'app/global/global-selectors'
import { actionApiRequest } from 'app/utils/sagas'

import * as dnbApi from './api'
import * as dnbActions from './dun-and-bradstreet-actions'


function* handleShowModal(action) {
  const hasDnbAccess = yield select(globalSelectors.getHasDnbAccess)
  if (!hasDnbAccess) return
  yield put(
    dnbActions.fetchDnbData.request({data: action.payload})
  )
}


function* fetchDnbData(action) {
  yield* actionApiRequest(action, dnbApi.fetchData)
}


export default function*() {
  yield all([
    takeLatest(dnbActions.showDnbModal, handleShowModal),
    takeLatest(dnbActions.fetchDnbData.request, fetchDnbData),
  ])
}
