import { all, delay, takeLatest, put, call, select } from 'redux-saga/effects'
import * as dateFns from 'date-fns'

import * as entitiesActions from 'app/entities/entities-actions'
import * as notifications from 'app/global/notifications'
import { ISO_DATE_FORMAT } from 'app/constants'
import { handleSagaError } from 'app/utils/errors'

import * as api from './usage-api'
import * as actions from './usage-actions'
import { getUsage } from './usage-selectors'
import { FILTERS_KEYS } from './usage-constants'
import * as selectors from './usage-selectors'

function* handleFetchUsageData(action) {
  let start = dateFns.format(dateFns.subMonths(new Date(), 1), ISO_DATE_FORMAT)
  let end = dateFns.format(dateFns.startOfDay(new Date()), ISO_DATE_FORMAT)
  let response = null
  if (action.payload.start && action.payload.end) {
    start = dateFns.format(action.payload.start, ISO_DATE_FORMAT)
    end = dateFns.format(action.payload.end, ISO_DATE_FORMAT)
  }
  try {
    response = yield api.fetchUsageData({ start, end })
  } catch (error) {
    yield* handleSagaError(error)
    yield put(actions.hideLoader())
    return
  }
  const topPublisherIds = []
  const recentSearchIds = []
  const recentRefreshedProfileUserIds = []
  const users = {}
  const searches = {}
  response.topPublishers.forEach(p => {
    const u = { ...p.user }
    topPublisherIds.push(u.id)
    u.publishedReportCount = p.count
    users[u.id] = u
  })
  response.recentSearches.forEach(ss => {
    recentSearchIds.push(ss.id)
    users[ss.owner.id] = { ...ss.owner }
    ss.ownerId = ss.owner.id
    searches[ss.id] = { ...ss }
  })
  response.recentRefreshedProfileUsers.forEach(u => {
    recentRefreshedProfileUserIds.push(u.id)
    users[u.id] = { ...u }
  })
  yield put(entitiesActions.update({ users, searches }))
  yield put(actions.fetchUsageDataComplete({
    metrics: response.metrics,
    topFeeds: response.topFeeds,
    topArticles: response.topArticles,
    topPublisherIds,
    recentSearchIds,
    recentRefreshedProfileUserIds,
  }))
}

function* handleSignupForMonthlyReport(action) {
  try {
    const response = yield api.usageReportSignup(action.payload)
    const successMessage = response.user.receiveMonthlyUsageReport
      ? 'You have been signed up for the monthly usage report'
      : 'Your monthly usage report has been cancelled'
    yield put(actions.signupForMonthlyReportComplete(response.user.receiveMonthlyUsageReport))
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: successMessage,
      })
    )
  } catch (error) {
    yield* handleSagaError(error)
  }
  yield put(actions.hideLoader())
}

function* handleSignupForNewMonthlyReport(action) {
  try {
    const response = yield api.newUsageReportSignup(action.payload)
    const successMessage = response.user.receiveMonthlyNewUsageReport
      ? 'You have been signed up for the monthly new usage report'
      : 'Your monthly new usage report has been cancelled'
    yield put(actions.signupForNewMonthlyReportComplete(response.user.receiveMonthlyNewUsageReport))
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: successMessage,
      })
    )
  } catch (error) {
    yield* handleSagaError(error)
  }
  yield put(actions.hideNewLoader())
}

function* handleSendEmail(action) {
  try {
    if (action.payload.newReport) {
      yield put(actions.showNewLoader())
    }
    else {
      yield put(actions.showLoader())
    }
    const start = dateFns.format(action.payload.start, ISO_DATE_FORMAT)
    const end = dateFns.format(action.payload.end, ISO_DATE_FORMAT)
    const response = yield api.sendEmail({ start, end, emailAddresses: action.payload.emailAddresses, newReport: action.payload.newReport , reportType: action.payload.reportType})
    if (action.payload.reportType === 'users') {
      yield put(
        notifications.actions.showNotification({
          type: 'success',
          message: 'Individual and Group Usage Data is being generated and will be emailed shortly.',
        })
      )}
    else if(action.payload.reportType === 'publish'){
      yield put(
        notifications.actions.showNotification({
          type: 'success',
          message: 'Individual and Group Publication Usage Data is being generated and will be emailed shortly.',
        })
      )
    }else if(action.payload.reportType === 'all saved search'){
      yield put(
        notifications.actions.showNotification({
          type: 'success',
          message: 'Individual and Group All Saved Search Usage Data is being generated and will be emailed shortly.',
        })
      )
    }else if(action.payload.reportType === 'alert'){
      yield put(
        notifications.actions.showNotification({
          type: 'success',
          message: 'Individual and Group Email Alert Usage Data is being generated and will be emailed shortly.',
        })
      )
    }else{
      yield put(
        notifications.actions.showNotification({
          type: 'success',
          message: 'Individual and Group Saved Search Usage Data is being generated and will be emailed shortly.',
        })
      )
    }
  } catch (error) {
    yield* handleSagaError(error)
  }
  yield put(actions.hideEmailModal())
  if (action.payload.newReport) {
    yield put(actions.hideNewLoader())
  }
  else {
    yield put(actions.hideLoader())
  }
}

function* handleFetchUsageGridData(action) {
  const usage = yield select(getUsage)
  const { usageGridFilters } = usage

  let response = null
  const params = {}
  try {
    Object.keys(usageGridFilters).forEach(key => {
      if (key === FILTERS_KEYS.columnFilters) { return }
      const value = usageGridFilters[key]
      if (value || typeof value === 'boolean') {
        params[key] = value
      }
    })
    Object.keys(usageGridFilters.columnFilters).forEach(key => {
      const value = usageGridFilters.columnFilters[key]
      if (value || typeof value === 'boolean') {
        params[key] = value
      }
    })
    params[FILTERS_KEYS.start] = dateFns.format(params[FILTERS_KEYS.start] ?? new Date(), ISO_DATE_FORMAT)
    params[FILTERS_KEYS.end] = dateFns.format(params[FILTERS_KEYS.end] ?? new Date(), ISO_DATE_FORMAT)

    params[FILTERS_KEYS.rowsCount] = params[FILTERS_KEYS.pageSize]
    const currentPage = params[FILTERS_KEYS.currentPage]
    params[FILTERS_KEYS.rowsOffset] = currentPage <= 1 ? 0 : (currentPage - 1) * params[FILTERS_KEYS.pageSize]

    delete params[FILTERS_KEYS.firstDimension]
    delete params[FILTERS_KEYS.pageSize]
    delete params[FILTERS_KEYS.currentPage]

    response = yield api.fetchUsageGridData(params)

  } catch (error) {
    yield* handleSagaError(error)
    yield put(actions.hideNewLoader())
    return
  }

  yield put(actions.setUsageGridData({
    data: response.body.data,
    totalCount: response.body.totalCount,
    start: params.start,
    end: params.end
  }))
}

function* handleFetchUsageGridFiltersData(action) {
  let response = null
  const usage = yield select(getUsage)
  const { usageGridFilters } = usage
  
  try {
    const params = {}
    params[FILTERS_KEYS.start] = dateFns.format(usageGridFilters.start ?? new Date(), ISO_DATE_FORMAT)
    params[FILTERS_KEYS.end] = dateFns.format(usageGridFilters.end ?? new Date(), ISO_DATE_FORMAT)

    response = yield api.fetchUsageGridFiltersData(params)

  } catch (error) {
    yield* handleSagaError(error)
    yield put(actions.hideNewLoader())
    return
  }

  yield put(actions.setFiltersData({
    articles: response.body.data.articles ?? [],
    sources: response.body.data.sources ?? [],
    articleTypes: response.body.data.articleTypes ?? [],
  }))
}

function* handleFetchUsageGridSummaryData(action) {
  let response = null
  const params = {}
  try {
    const usageSummaryDate = yield select(selectors.usageSummaryDate)
    params[FILTERS_KEYS.start] = dateFns.format(params[FILTERS_KEYS.start] ?? usageSummaryDate.start, ISO_DATE_FORMAT)
    params[FILTERS_KEYS.end] = dateFns.format(params[FILTERS_KEYS.end] ?? usageSummaryDate.end, ISO_DATE_FORMAT)
    response = yield api.fetchUsageGridSummaryData(params)
  } catch (error) {
    yield* handleSagaError(error)
    yield put(actions.hideNewLoader())
    return
  }
  yield put(actions.setSummaryData({
    data: response.body.data,
    lastUpdatedDate: response.body.lastUpdatedDate
  }))
}

function* handleUsersDropDownList(action) {
  try {
    const noDataOption = [{ label: "No matching records found", value: -1 }]
    const name = action.payload
    if (name && name.length > 0) {
      const response = yield api.fetchUsers({ name })
      const response1 = yield api.fetchGroups({ name })
      console.log(response1, response)
      const users = response.users.items.map((item) => ({ label: `${item.lastName}, ${item.firstName}`, value: item.id }))
      const groups = response1.groups.items.map((item) => ({ label: `${item.firstName}`, value: item.id }))
      const options = [...users, ...groups]
      if (options.length > 0) {
        yield put(actions.setUsersDropDownList(options))
      }
      else {
        yield put(actions.setUsersDropDownList(noDataOption))
      }
    } else {
      yield put(actions.setUsersDropDownList([]))
    }
  } catch (error) {
    yield* handleSagaError(error)
  }
  yield put(actions.hideLoader())
}

function* handleIndividualUserOrGroupStatsData(action) {
  const params = {}
  try {
    console.log('fetchIndividualStatsData',action.payload)
    params.id = action.payload.id
    params[FILTERS_KEYS.start] = dateFns.format(action.payload.start, ISO_DATE_FORMAT)
    params[FILTERS_KEYS.end] = dateFns.format(action.payload.end, ISO_DATE_FORMAT)
    const response = yield api.fetchIndividualStatsData(params)
    console.log(response)
    const username = response.body.user 
    yield put(actions.setIndividualStatsData(response.body.data))
    yield put(actions.setIndividualStatsUser(username))
  } catch (error) {
    yield* handleSagaError(error)
  }
  yield put(actions.hideLoader())
}

export default function* () {
  yield all([
    takeLatest(
      actions.fetchUsageData,
      handleFetchUsageData
    ),
    takeLatest(
      actions.signupForMonthlyReport,
      handleSignupForMonthlyReport
    ),
    takeLatest(
      actions.sendEmail,
      handleSendEmail
    ),
    takeLatest(
      actions.fetchUsageGridData,
      handleFetchUsageGridData
    ),
    takeLatest(
      actions.fetchUsageGridFiltersData,
      handleFetchUsageGridFiltersData
    ),
    takeLatest(
      actions.fetchUsageGridSummaryData,
      handleFetchUsageGridSummaryData
    ),
    takeLatest(
      actions.signupForNewMonthlyReport,
      handleSignupForNewMonthlyReport
    ),
    takeLatest(
      actions.fetchUsersDropDownList,
      handleUsersDropDownList
    ),
    takeLatest(
      actions.getIndividualStatsData,
      handleIndividualUserOrGroupStatsData
    ),
  ])
}



