export function isVowel(char) {
  // 'y' is not included because this function is used for determining whether
  // to put 'a' or 'an' before a word, and words starting with 'y' rarely use it
  // as a vowel.
  return ['a', 'e', 'i', 'o', 'u'].includes(char.toLowerCase())
}


/**
 * Shortens the given string to only contain a maximum of `wordCount` words. If
 * `replacement` is truthy, adds that to the end of any truncated strings.
 */
export function truncateWords(string, wordCount, {replacement = '...'} = {}) {
  let words = string.split(/\s+/)
  if (words.length > wordCount) {
    words = words.slice(0, wordCount)
    if (replacement) {
      words.push(replacement)
    }
  }
  return words.join(' ')
}


/**
 * Escapes the given string for use in a regular expression.
 */
export function escapeRegex(string) {
  // https://stackoverflow.com/a/3561711/104184
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
}
