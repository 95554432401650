import PropTypes from 'prop-types'
import React, {Component} from 'react'

import {Tab, TabbedContainer} from 'app/common/TabbedContainer'

import AssignmentsContent from './AssignmentsContent'
import DetailsContent from './DetailsContent'
import LabelsContent from './LabelsContent'
import FeaturedForContent from './FeaturedForContent'
import ReportHistory from './ReportHistory'


export const TABS = {
  DETAILS: 'details',
  ASSIGNMENTS: 'assignments',
  LABELS: 'labels',
  FEATURED_FOR: 'featured-for',
  HISTORY: 'history'
}


export default class Content extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    defaultTab: PropTypes.string,
  }

  render() {
    const {closeModal, defaultTab} = this.props
    return (
      <TabbedContainer defaultTabName={defaultTab} useUpdatedCss={true}>
        <Tab name={TABS.DETAILS} label="Details">
          <DetailsContent/>
        </Tab>

        <Tab name={TABS.ASSIGNMENTS} label="Assignments">
          <AssignmentsContent closeModal={closeModal} />
        </Tab>

        <Tab name={TABS.LABELS} label="Labels">
          <LabelsContent/>
        </Tab>

        <Tab name={TABS.FEATURED_FOR} label="Featured For">
          <FeaturedForContent/>
        </Tab>

        <Tab name={TABS.HISTORY} label="Report History">
          <ReportHistory/>
        </Tab>
      </TabbedContainer>
    )
  }
}

Content.TABS = TABS
