import React from 'react'
import {render} from 'react-dom'
import {Provider} from 'react-redux'
import {changeCaseObject} from 'app/utils'
import store from 'app/store'
import * as constants from './esg-constants'
import * as actions from './esg-actions'
import reducer from './esg-reducer'
import saga from './esg-saga'
import './index.less'
import ESG from './ESG.jsx'
import {BrowserRouter as Router} from 'react-router-dom'

export function init(data) {
  // Add the data from this page to the Redux store
  store.dispatch(actions.init(changeCaseObject.camelCase(data)))
  let container = document.getElementById('esg-wrapper')
  if (container) {
    const component = (
      <Provider store={store}>
        <Router basename="/esg">
          <ESG />
        </Router>
      </Provider>
    )
    render(component, container)
  }
}

export {constants, reducer, saga}
