import React, { Component } from 'react'
import { connect } from 'react-redux'
import './TimeOfDay.less'
import * as actions from './email-notifications-actions'
import * as constants from './email-notifications-constants'
import InputBlock from 'app/common/InputBlock'
import Dropdown from 'app/common/Dropdown'
import PropTypes from 'prop-types'


@connect(
  state => ({
    baseSettings: state.emailNotifications.baseSettings,
    insightsSettings: state.emailNotifications.insightsSettings,
    esgSettings: state.emailNotifications.esgSettings,
    editUserId: state.emailNotifications.emailDeliveryData.editUserId,
  }),
  {
    saveEmailAlertSettings: actions.api.saveEmailAlertSettings.request,
  },
)
export default class TimeOfDay extends Component {
  static propTypes = {
    noticeFrequency: PropTypes.string.isRequired,
    notificationType: PropTypes.string.isRequired,
  }
  render() {
    const activeSettings = this.props.notificationType === constants.BASE_NOTIFICATION_TYPE
      ? this.props.baseSettings
      : this.props.notificationType === constants.INSIGHTS_NOTIFICATION_TYPE
        ? this.props.insightsSettings
          : this.props.notificationType === constants.ESG_NOTIFICATION_TYPE 
            ? this.props.esgSettings
            :null
    let timeOfDay = null
    if (activeSettings) {
      const frequencySettings = activeSettings.settings.find(
        setting => setting.noticeFrequency === this.props.noticeFrequency)
      const timeOfDayOptions = []
      Object.keys(constants.SCHEDULE_BLOCKS).forEach(key => {
        timeOfDayOptions.push({
          key: key,
          notificationType: this.props.notificationType
            .toLowerCase(),
          fieldName: constants.EMAIL_SCHEDULES_ARRAY,
          label: <span>{constants.SCHEDULE_BLOCKS[key]}</span>,
          value: constants.SCHEDULE_BLOCKS[key],
        })
      })
      const activeTimeOfDay = frequencySettings
        ? constants.SCHEDULE_BLOCKS[frequencySettings.emailSchedule]
        : null
      timeOfDay = this.props.noticeFrequency !== constants.NONE_FREQ
        ? <InputBlock
          className={'time-of-day-dropdown'}
          label="Time of Day"
        >
          <Dropdown
            type={'text'}
            options={timeOfDayOptions}
            value={activeTimeOfDay}
            onSelect={this.onTimeOfDaySelect.bind(this, activeSettings)}
          />
        </InputBlock>
        : null
    }
    return (
      <React.Fragment>
        {timeOfDay}
      </React.Fragment>
    )
  }
  onTimeOfDaySelect(activeSettings, data) {
    this.props.saveEmailAlertSettings({
      editUserId: this.props.editUserId,
      noticeFrequency: this.props.noticeFrequency,
      type: activeSettings.notificationType,
      field: data.fieldName,
      value: true,
      emailSchedule: data.key,
    })
  }
}
