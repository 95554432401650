import React, { Component } from 'react'
import ManzamaLogo from 'app/logos/manzama-logo/index'
import {SUPPORT_EMAIL} from 'app/constants'
import * as styles from './style.less'
import * as selectors from '../../error-handling/error-handling-selectors'
import connect from "react-redux/es/connect/connect"
import * as constants from 'app/constants'


@connect(
  state => ({
    errorForApp: selectors.getErrorForApp(state),
    mainImage: selectors.getMainImage(state),
  }),
)
export default class ErrorPage extends Component {
  render() {
    const forApp = this.props.errorForApp
    const appLogo = forApp === constants.BASE_APP || constants.PUBLISH_APP
      ? <ManzamaLogo className={styles.logo} />
      : null
    return (
      <div id="error-page" className={styles.page}>
        <div className={styles.columns}>
          <div className={styles.imageColumn}>
            <img className={styles.image} src={this.props.mainImage} />
          </div>
          <div className={styles.textColumn}>
            {appLogo}
            <div className={styles.text}>
              <p className={styles.sorry}>Something went wrong.</p>
              <p>
                {forApp} experienced an unexpected error, and our team has
                been notified. In the meantime, please{' '}
                <a href="">refresh the page</a> and try again. If the problem
                persists, please contact{' '}
                <a href={`mailto:${SUPPORT_EMAIL}`}>Support</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
