import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {getEntities} from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import {SavedSearch, User} from 'app/models'
import Modal from 'app/common/Modal'
import Button from 'app/common/Button'
import BulkUserAssignment from 'app/reusable/BulkUserAssignment'
import ShareSearchBase from 'app/reusable/ShareSearchBase'
import {joinInSentence} from 'app/utils'

import {
  setFeatureModalData,
  fetchAssignees,
  fetchUsers,
  addFeaturedSearches,
} from '../searches-admin-actions'
import {getSearchesAdmin} from '../searches-admin-selectors'

import * as styles from './FeatureModal.less'


@connect(
  // Map state to props
  createSelector(
    [getEntities, getSearchesAdmin],
    (entities, searchesAdmin) => {
      const orm = Orm.withEntities(entities)
      return {
        users: orm.getByIds(User, searchesAdmin.userIds),
        searches: orm.getByIds(SavedSearch, searchesAdmin.selectedSearchIds),
        isAssigneesFetched: searchesAdmin.isAssigneesFetched,
        departments: searchesAdmin.departments,
        teams: searchesAdmin.teams,
        firmLocations: searchesAdmin.firmLocations,
        isOpen: searchesAdmin.featureModalData.isOpen,
        assigneeIdsBySection: searchesAdmin.featureModalData.assigneeIdsBySection,
        areAllUsersFetched: searchesAdmin.areAllUsersFetched,
        isLargeFirm: searchesAdmin.isLargeFirm,
        activeUserCount: searchesAdmin.activeUserCount,
        activeGroupCount: searchesAdmin.activeGroupCount,
      }
    }
  ),
  {
    setFeatureModalData,
    fetchAssignees,
    fetchUsers,
    addFeaturedSearches,
  }
)
export default class ShareSearchContainer extends ShareSearchBase {
  componentDidMount() {
    if (!this.props.isAssigneesFetched) {
      this.props.fetchAssignees()
    }
  }

  render() {
    if (!this.props.isAssigneesFetched) {
      return null
    }

    const searchNames = joinInSentence(this.props.searches.map(ss => ss.name))
    const shareCount = this.shareWithAssigneeCount()
    const shareSummary = shareCount > 0 && <p>{shareCount} Selected: {this.shareWithAssigneeNames()}</p>

    return (
      <Modal
        isOpen={this.props.isOpen}
        onClose={() => this.props.setFeatureModalData({isOpen: false})}
      >
        <h1>Make {searchNames} Featured for:</h1>

        {shareSummary}

        <BulkUserAssignment
          users={this.props.users}
          departments={this.props.departments}
          teams={this.props.teams}
          firmLocations={this.props.firmLocations}
          onFetchUsers={this.props.fetchUsers}
          onSectionChange={section => this.setSelectedSection(section)}
          onSelectedIdsChange={ids => this.setSelectedAssigneeIds(ids)}
          selectedIdsBySection={this.props.assigneeIdsBySection}
          showIndividualMembers={true}
          showDepartments={true}
          showDepartmentMembers={true}
          showTeams={true}
          showTeamMembers={true}
          showFirmLocations={true}
          isLargeFirm={this.props.isLargeFirm}
          activeUserCount={this.props.activeUserCount}
          activeGroupCount={this.props.activeGroupCount}
          areAllUsersFetched={this.props.areAllUsersFetched}
        />

        <div className={styles.buttons}>
          <Button
            label="Feature"
            onClick={() => this.submit()}
            disabled={shareCount === 0}
          />
          <Button
            label="Cancel"
            isPlainText
            onClick={() => this.props.setFeatureModalData({isOpen: false})}
          />
        </div>
      </Modal>
    )
  }

  setSelectedAssigneeIds(ids) {
    let assigneeIdsBySection = {...this.props.assigneeIdsBySection}
    assigneeIdsBySection[this.state.selectedSection] = ids
    this.props.setFeatureModalData({assigneeIdsBySection: assigneeIdsBySection})
  }

  submit() {
    this.props.addFeaturedSearches()
  }
}
