export const STATE_PROP_NAME = 'usage'

export const COLUMN_KEYS = {
    userId: 'userId',
    isGroup: 'isGroup',
    isAdmin: 'isAdmin',
    // groupName: 'groupName',
    // groupEmail: 'groupEmail',
    profileName: 'profileName',
    profileEmail: 'profileEmail',
    // fullName: 'fullName',
    // email: 'email',
    totalUsers: 'totalUsers',
    sourceId: 'sourceId',
    source: 'source',
    savedSearch: 'savedSearch',
    savedSearchId: 'savedSearchId',
    savedSearchType: 'savedSearchType',
    savedSearchScope: 'savedSearchScope',
    uniqueClicks: 'uniqueClicks',
    articleType: 'articleType',
    articleId: 'articleId',
    articleLink: 'articleLink',
    interactionLink: 'interactionLink',
    headline: 'headline',
    articleClicks: 'articleClicks',
    timeStamp: 'timeStamp',
}

export const COLUMN_HEADERS = {
    [COLUMN_KEYS.userId]: 'Profile Id',
    [COLUMN_KEYS.isGroup]: 'Is Group',
    // [COLUMN_KEYS.isAdmin]: 'Is Admin',
    // [COLUMN_KEYS.groupName]: 'Group Name',
    // [COLUMN_KEYS.groupEmail]: 'Group Email',
    [COLUMN_KEYS.profileName]: 'Profile Name',
    [COLUMN_KEYS.profileEmail]: 'Username',
    // [COLUMN_KEYS.fullName]: 'Name',
    // [COLUMN_KEYS.email]: 'Username',
    [COLUMN_KEYS.totalUsers]: 'Total Users',
    [COLUMN_KEYS.sourceId]: 'Source',
    [COLUMN_KEYS.source]: 'Source',
    [COLUMN_KEYS.savedSearchId]: 'Saved Search',
    [COLUMN_KEYS.savedSearch]: 'Saved Search',
    [COLUMN_KEYS.savedSearchType]: 'Search Type',
    [COLUMN_KEYS.savedSearchScope]: 'Search Scope',
    [COLUMN_KEYS.articleType]: 'Event Type',
    [COLUMN_KEYS.articleId]: 'Article',
    [COLUMN_KEYS.headline]: 'Article',
    [COLUMN_KEYS.articleLink]: 'Article Link',
    [COLUMN_KEYS.interactionLink]: 'Interaction Link',
    [COLUMN_KEYS.uniqueClicks]: 'Unique Clicks',
    [COLUMN_KEYS.articleClicks]: 'Total Clicks',
    [COLUMN_KEYS.timeStamp]: 'Date and Time',
}

export const ALL_COLUMNS = [
    COLUMN_KEYS.userId,
    COLUMN_KEYS.profileName,
    COLUMN_KEYS.profileEmail,
    COLUMN_KEYS.totalUsers,
    COLUMN_KEYS.sourceId,
    COLUMN_KEYS.source,
    // COLUMN_KEYS.savedSearchId,
    // COLUMN_KEYS.savedSearchType,
    COLUMN_KEYS.articleType,
    COLUMN_KEYS.articleId,
    COLUMN_KEYS.uniqueClicks,
    COLUMN_KEYS.articleClicks,
    COLUMN_KEYS.timeStamp,
]

export const DEFAULT_VISIBLE_COLUMNS = [
    COLUMN_KEYS.profileName,
    COLUMN_KEYS.profileEmail,
    COLUMN_KEYS.totalUsers,
    COLUMN_KEYS.source,
    // COLUMN_KEYS.savedSearchId,
    // COLUMN_KEYS.savedSearchType,
    COLUMN_KEYS.articleType,
    COLUMN_KEYS.headline,
    COLUMN_KEYS.uniqueClicks,
    COLUMN_KEYS.articleClicks,
    COLUMN_KEYS.timeStamp,
]

export const NO_DIMENSION_COLUMNS = [
    COLUMN_KEYS.headline,
    COLUMN_KEYS.uniqueClicks,
    COLUMN_KEYS.articleClicks,
    COLUMN_KEYS.timeStamp,
]

export const SHOW_MORE_ABLE_DIMENSIONS = [
    COLUMN_KEYS.headline,
    COLUMN_KEYS.source,
]

export const SCOPE_ICONS = {
    personal: "library-icon",
    shared: "globe-icon",
    hidden: "",
}

export const PROFILE_GROUPING_KEYS = [
    COLUMN_KEYS.profileName,
    // COLUMN_KEYS.email
]

export const DIMENSIONS = [
    { label: COLUMN_HEADERS[COLUMN_KEYS.profileName], value: COLUMN_KEYS.profileName },
    { label: COLUMN_HEADERS[COLUMN_KEYS.source], value: COLUMN_KEYS.source },
    // { label: COLUMN_HEADERS[COLUMN_KEYS.savedSearch], value: COLUMN_KEYS.savedSearch },
    // { label: COLUMN_HEADERS[COLUMN_KEYS.savedSearchType], value: COLUMN_KEYS.savedSearchType }
    { label: COLUMN_HEADERS[COLUMN_KEYS.articleType], value: COLUMN_KEYS.articleType },
]

export const LAST_INVISIBLE_DIMENSION = COLUMN_KEYS.headline

export const DIMENSIONS_OPTION = [
    {
        label: 'USERS',
        isGroup: true,
        options: [
            DIMENSIONS[0],
        ]
    },
    {
        label: 'ARTICLES',
        isGroup: true,
        options: [
        ...DIMENSIONS.slice(1).sort((a,b)=>a.label > b.label?1:-1),
        ]
    },
]

export const TEXT_MAX_LENGTH = {
    profileName: 15,
    email: 20,
    source: 15,
    savedSearch: 10,
    savedSearchType: 10,
    shortHeadline: 20,
    headline: 30,
    longHeadline: 140,
    articleType: 15,
}

export const PROFILE_TYPE_OPTIONS = {
    'all': 'All',
    'group': 'Group Profiles',
    'individual': 'Individual Profiles',
}

export const ADMIN_ROLE_OPTIONS = {
    'all': 'All Users',
    'admin': 'Admin Users',
    'nonAdmin': 'Non Admin Users',
}

export const PAGINATION_OPTIONS = {
    10: '10',
    20: '20',
    50: '50',
    100: '100',
}

export const FILTERS_KEYS = {
    isAdmin: 'isAdmin',
    isGroup: 'isGroup',
    sortField: 'sortField',
    sortDirection: 'sortDirection',
    freeTextSearch: 'freeTextSearch',
    currentPage: 'currentPage',
    pageSize: 'pageSize',
    start: 'start',
    end: 'end',
    columnFilters: 'columnFilters',
    articleIds: 'articleIds',
    articleTypes: 'articleTypes',
    sourceIds: 'sourceIds',
    savedSearchIds: 'savedSearchIds',
    savedSearchTypes: 'savedSearchTypes',
    emailStartsWith: 'emailStartsWith',
    profileStartsWith: 'profileStartsWith',
    distinctColumn: 'distinctColumn',
    columnsToSelect: 'columnsToSelect',
    whereConditions: 'whereConditions',
    rowsCount: 'rowsCount',
    rowsOffset: 'rowsOffset',
    firstDimension: 'firstDimension'
}

export const SORTING_TYPE = {
    ASC: 'ASC',
    DESC: 'DESC'
}

export const SUMMARY_LABEL = {
    YESTERDAY: 'Yesterday',
    LAST_WEEK: 'Last Week',
    LAST_MONTH: 'Last Month'
  }

export const SUMMARY_VALUE = {
    YESTERDAY: 'YESTERDAY',
    LAST_WEEK: 'LASTWEEK',
    LAST_MONTH: 'LASTMONTH'
  }

export  const SUMMARY_OPTIONS = [
    { label: SUMMARY_LABEL.YESTERDAY, value: SUMMARY_VALUE.YESTERDAY, tooltip: 'Values shown below represent the data between the "Last refreshed date" to 1 day before it.' },
    { label: SUMMARY_LABEL.LAST_WEEK, value: SUMMARY_VALUE.LAST_WEEK, tooltip: 'Values shown below represent the data between the "Last refreshed date" to 7 days before it.' },
    { label: SUMMARY_LABEL.LAST_MONTH, value: SUMMARY_VALUE.LAST_MONTH, tooltip: 'Values shown below represent the data between the "Last refreshed date" to 30 days before it.' }
  ]

export const CHIP_VALUE = {
    [SUMMARY_VALUE.YESTERDAY]: 'on previous day',
    [SUMMARY_VALUE.LAST_WEEK]: 'on previous week',
    [SUMMARY_VALUE.LAST_MONTH]: 'on previous month'
  }

export  const SUMMARY_TOOLTIP = {
    ACTIVE_USERS: "Active Users are users who have at least one item in their profile or are a member of at least one group in addition to the firm wide group.",
    EMAILS_DELIVERED: "Emails delivered is the average number of emails delivered to the active users.",
    OPEN_EMAIL: "Open email is the average number of delivered emails that active users have opened.",
    EMAIL_OPEN_RATE: "Email Open Rate is the percentage of users that opened their delivered emails."
  }

export  const SUMMARY_HEADERS = {
    ACTIVE_USERS: "AVG ACTIVE USERS",
    EMAILS_DELIVERED: "AVG EMAILS DELIVERED",
    OPEN_EMAIL: "AVG EMAILS OPENED",
    EMAIL_OPEN_RATE: "AVG EMAIL OPEN RATE"
  }

export const CHIP_COLOR_OPTIONS={
    GREEN : "rgba(62, 137, 20, 0.15)",
    RED : "rgba(255, 64, 102, 0.15)",
    GREY : '#F2F2F2'
}
    
export const CHIP_IMAGE_OPTIONS={
    INCREASE : "/media/img/GreenArrow.svg",
    DECREASE : "/media/img/RedArrow.svg"
}

export const TIME_STAMP_FORMAT = 'MMM/dd/yyyy HH:mm:ss'
export const DATE_FORMAT = 'MM-dd-yyyy'

export const NO_DATA_MESSAGE = 'No data to display'
export const NO_DIMENSION_MESSAGE = 'No Dimension Selected'
export const DATE_RANGE = 'Date range {0} to {1}'
export const DEFAULT_ROWS_TO_FETCH_ON_EXPAND = 10
// export const DEFAULT_ROWS_TO_FETCH_ON_EXPAND = 4
export const SHOW_ALL_ARTICLES = 'Show All {0}...'
export const SHOW_MORE_ARTICLES = 'Show More {0}...'
// export const GENERAL_SEARCH_PLACEHOLDER = "&#xf002; Search"
export const GENERAL_SEARCH_PLACEHOLDER = "Search By Profile Name, Username or Source"
export const EXPORT_USAGE_REPORT = "Export Usage Report"
export const USAGE_TITLE = "Firm Wide Usage Report"
export const EMAIL_USAGE_REPORT = "Email Usage Report"
export const NEW_EMAIL_USAGE_REPORT = "User Activity Report"
export const SUMMARY_TITLE = "Summary Report"
export const OPT_NEW_USAGE = "New Firm Usage Summary"
export const OPT_NEW_USAGE_TOOLTIP = "Use this switch to compare usage statistics between the new and old tool before the redesign of the Usage tab becomes permanent. The redesigned tool provides you with more precise usage data and insights."
export const ON = "ON"
export const OFF = "OFF"
export const STATS = "STATS"
export const ACTIVE_USERS_FROM = "from total of"
export const USERS = "users"
export const PERCENT = "%"
export const SUMMARY_LAST_UPDATED_DATE = 'Data updated daily.'
export const LAST_UPDATED_ON = 'Last refreshed on'
export const EXPORT_NOW = 'now'
export const EXPORT_SCHEDULED = 'scheduled'
export const DATE_RANGE_OPTIONS = {
  7: 'Last Week',
  30: 'Last Month',
  90: 'Last 3 Months',
  182: 'Last 6 Months',
  CUSTOM_RANGE: 'Custom Range',
}
export const SAVED_SEARCHES_USAGE_REPORT = "Saved Searches Usage Report"
export const EXPORT_SAVED_SEARCH = 'Saved Search'
export const PUBLICATION_USAGE_REPORT = "Publication Usage Report"
export const EXPORT_PUBLISH = 'publish'

export const EMAIL_ALERTS_USAGE_REPORT = 'Email Alert Usage Report'
export const EXPORT_ALERT = 'alert'

export const INDIVIDUAL_STATISTICS_LIST = [
    {
        'field': 'No of email alerts sent',
        'value': 0
    },
    {
        'field': 'No of email alerts opened',
        'value': 0
    },
    {
        'field': 'No of articles in email alerts',
        'value': 0
    },
    {
        'field': 'No of articles in email alerts clicked',
        'value': 0
    },
    {
        'field': 'No of newsletters received',
        'value': 0
    },
    {
        'field': 'No of newsletters opened',
        'value': 0
    },
    {
        'field': 'No of articles in newsletters clicked',
        'value': 0
    },
    {
        'field': 'No of Topics in Saved Searches',
        'value': 0
    },
    {
        'field': 'No of Practices in Saved Searches',
        'value': 0
    },
    {
        'field': 'No of Industries in Saved Searches',
        'value': 0
    },
    {
        'field': 'No of Companies in Saved Searches',
        'value': 0
    },
    {
        'field': 'No of Prospects in Saved Searches',
        'value': 0
    },
    {
        'field': 'No of Competitors in Saved Searches',
        'value': 0
    },
    {
        'field': 'Most recently created Search List',
        'value': 0
    },
    {
        'field': 'No of Flagged Articles',
        'value': 0
    },
    {
        'field': 'No of logins in time period',
        'value': 0
    },
    {
        'field': 'Last login date',
        'value': 0
    }
]

export const ALL_SAVED_SEARCHES_USAGE_REPORT = "All Saved Searches Usage Report"
export const ALL_EXPORT_SAVED_SEARCH = 'All Saved Search'


