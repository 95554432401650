import { createAction } from 'redux-actions'

const createUsageAction = name => createAction(`USAGE_${name}`)

export const init = createUsageAction('INIT')
export const showLoader = createUsageAction('SHOW_LOADER')
export const hideLoader = createUsageAction('HIDE_LOADER')
export const hideNewLoader = createUsageAction('HIDE_NEW_LOADER')
export const showNewLoader = createUsageAction('SHOW_NEW_LOADER')
export const fetchUsageData = createUsageAction('FETCH_USAGE_DATA')
export const fetchUsageDataComplete = createUsageAction('FETCH_USAGE_DATA_COMPLETE')
export const showEmailModal = createUsageAction('SHOW_EMAIL_MODAL')
export const hideEmailModal = createUsageAction('HIDE_EMAIL_MODAL')
export const signupForMonthlyReport = createUsageAction('SIGNUP_FOR_MONTHLY_REPORT')
export const signupForMonthlyReportComplete = createUsageAction('SIGNUP_FOR_MONTHLY_REPORT_COMPLETE')
export const sendEmail = createUsageAction('SEND_EMAIL')
export const fetchUsageGridData = createUsageAction('FETCH_USAGE_GRID_DATA')
export const setUsageGridData = createUsageAction('SET_USAGE_GRID_DATA')
export const fetchUsageGridFiltersData = createUsageAction('FETCH_USAGE_GRID_FILTERS_DATA')
export const setUsageGridFilters = createUsageAction('SET_USAGE_GRID_FILTERS')
export const setUsageGridColumnFilters = createUsageAction('SET_USAGE_GRID_COLUMN_FILTERS')
export const setFiltersData = createUsageAction('SET_GRID_COLUMN_FILTERS')


export const setUsageGridSummaryData = createUsageAction('SET_USAGE_SUMMARY_DATA')
export const fetchUsageGridSummaryData = createUsageAction('FETCH_USAGE_SUMMARY_DATA')
export const setSummaryData = createUsageAction('SET_SUMMARY_DATA')
export const setSummaryDateOption = createUsageAction('SET_SUMMARY_DATE_OPTION')
export const setReportType = createUsageAction('SET_REPORT_TYPE')
export const showIndividualStatisticsModal = createUsageAction('SHOW_INDIVIDUAL_STATISTICS_MODAL')
export const hideIndividualStatisticsModal = createUsageAction('HIDE_INDIVIDUAL_STATISTICS_MODAL')


export const signupForNewMonthlyReport = createUsageAction('SIGNUP_FOR_NEW_MONTHLY_REPORT')
export const signupForNewMonthlyReportComplete = createUsageAction('SIGNUP_FOR_NEW_MONTHLY_REPORT_COMPLETE')

export const fetchUsersDropDownList = createUsageAction('FETCH_USERS_DROPDOWN_LIST')
export const setUsersDropDownList = createUsageAction('SET_USERS_DROPDOWN_LIST')

export const getIndividualStatsData = createUsageAction('GET_INDIVIDUAL_STATS_DATA')
export const setIndividualStatsData = createUsageAction('SET_INDIVIDUAL_STATS_DATA')
export const setIndividualStatsUser = createUsageAction('SET_INDIVIDUAL_STATS_USER')

