import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {titleCase} from 'title-case'
import {findDOMNode} from "react-dom"
import * as emailNotificationsConstants from "app/email-notifications/email-notifications-constants"
import classNames from "classnames"


export default class RemoveSearches extends Component {
  static propTypes = {
    existingUserSearches: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
    userSearchesToRemove: PropTypes.array.isRequired,
    setUserSearchesToRemove: PropTypes.func.isRequired,
  }

  render() {
    const reviewSearches = (category) => {
      const categorySearches = category === 'source'
        ? this.props.existingUserSearches.filter(ss => ss.category === 'trusted'
            || ss.category === 'trusted-uncategorized')
        : this.props.existingUserSearches.filter(ss => ss.category === category)

      return(
        categorySearches.map(ss =>
          <div
            key={ss.id} className="refresh-existing-searches-row"
            onClick={(evt) => this.onSearchSelectionChange(
              evt, ss.name, ss.category)}
          >
            <div className="row-search">
              <div className="checkbox-container">
                <input
                  id={ss.id}
                  type="checkbox"
                  checked={!this.props.userSearchesToRemove.map(search => search.id)
                    .includes(ss.id)}
                />
              </div>
              <div className={'search-details-container'}>
                <div className="description-container">
                  <label
                    className={classNames('alerts', 'search-name',
                      {'no-alerts': ss.noticeConfig.frequency === 'none'},
                      {'has-alerts': ss.noticeConfig.frequency !== 'none'},
                    )}
                  >
                    {ss.name}
                  </label>
                </div>
                <div className={'notice-frequency-container'}>
                  <label
                    className={classNames(
                      {'no-alerts': ss.noticeConfig.frequency === 'none'},
                      {'has-alerts': ss.noticeConfig.frequency !== 'none'},
                    )}
                  >
                    {ss.noticeConfig.frequency !== 'none'
                      ? `${emailNotificationsConstants.
                        BASE_EMAIL_FREQUENCIES[ss.noticeConfig.frequency]} Alert`
                      : 'No Alert'
                    }
                  </label>

                </div>
              </div>
            </div>
            <div className={'divider'}/>
          </div>
        )
      )
    }

    const categorySection =
      this.props.categories.map((category, idx) => {
        const searches = reviewSearches(category.category)
        return (
          <div key={idx}>
            {searches.length > 0 && (
              <div className="search-category-section">
                <h3 className={'search-category'}>{titleCase(category.plural)}</h3>
                <div className={'refresh-existing-searches-row-container'}>
                  {searches}
                </div>
              </div>
            )}
          </div>
        )
      })

    return (
      <div className="profile-refresh-body-container">
        <div className="profile-refresh-body">
          <p>Please review your current list of searches and uncheck any that you no longer wish
            to track. You will be able to add more in the next step.</p>
          <div className={'existing-searches-container'}>
            {categorySection}
          </div>
        </div>
      </div>
    )
  }

  onSearchSelectionChange(evt, searchName, category) {
    const checkbox = findDOMNode(evt.currentTarget).getElementsByTagName('input')[0]
    const searchId = parseInt(checkbox.id)
    const isChecked = !this.props.userSearchesToRemove.map(search => search.id).includes(searchId)
    if (isChecked) {
      let userSearchesToRemove = [...this.props.userSearchesToRemove];
      userSearchesToRemove.push({id: searchId, name: searchName, category: category})
      this.props.setUserSearchesToRemove(userSearchesToRemove)
    } else {
      this.props.setUserSearchesToRemove(this.props.userSearchesToRemove
        .filter(search => search.id !== searchId))
    }
  }

}
