import * as entitiesSelectors from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import {Document, SavedSearch} from 'app/models'
import {STATE_PROP_NAME} from './comparison-constants'
import * as globalSelectors from 'app/global/global-selectors'
import * as emailConstants from 'app/email-notifications/email-notifications-constants'
import {getFirmCategoryLabel} from 'app/global/firm-category-label'

export const getComparisonState = state => state[STATE_PROP_NAME]

export const getTimeFrame = state => getComparisonState(state).timeFrame
export const getSelectedSearchIds = state => getComparisonState(state).selectedSearchIds
export const getActiveSidebarGroupTitle = state => getComparisonState(state).activeSidebarGroupTitle
export const getComparisonGroups = state => getComparisonState(state).comparisonGroups
export const getLoadingSearchIds = state => getComparisonState(state).loadingSearchIds
export const getCategorySearchIds = state => getComparisonState(state).categorySearchIds
export const getActiveSidebarSearchId = state => getComparisonState(state).activeSidebarSearchId
export const getComparisonDocumentIds = state => getComparisonState(state).comparisonDocumentIds
export const getPendingComparison = state => getComparisonState(state).pendingComparison
export const getActiveComparisonId = state => getComparisonState(state).activeComparisonId
export const getComparisonCategory = state => getComparisonState(state).comparisonCategory
export const getNewComparisonId = state => getComparisonState(state).newComparisonId
export const getChartData = state => getComparisonState(state).chartData
export const getDisplayChartTypes = state => getComparisonState(state).displayChartTypes
export const getActiveFilterKey = state => getComparisonState(state).activeFilterKey
export const getFetchingFilterKey = state => getComparisonState(state).fetchingFilterKey
export const getActiveFilterItems = state => getComparisonState(state).activeFilterItems
export const getCachedLargerTimeFrames = state => getComparisonState(state).cachedLargerTimeFrames
export const getEditComparisonGroup = state =>
  getComparisonState(state).showEditComparisonModal.group
export const getShareOfVoiceChartType = state => getComparisonState(state).shareOfVoiceChartType

// Entity selectors
export const getSelectedSearches = state => {
  const searchIds = getSelectedSearchIds(state)
  const entities = entitiesSelectors.getEntities(state)
  const orm = Orm.withEntities(entities)
  return orm.getByIds(SavedSearch, searchIds)
}

export const getCategorySearches = state => {
  const searchIds = getCategorySearchIds(state)
  const entities = entitiesSelectors.getEntities(state)
  const orm = Orm.withEntities(entities)
  return orm.getByIds(SavedSearch, searchIds)
}

export const getAllTopLevelDocuments = state => {
  const allDocumentIds = getComparisonDocumentIds(state)
  const topLevelDocumentIdsBySearch = Object.values(allDocumentIds).map(
    ss => ss.topLevelDocumentIds,
  )
  let topLevelIds = []
  topLevelDocumentIdsBySearch.forEach(ids => {topLevelIds = [...topLevelIds, ...ids]})
  const entities = entitiesSelectors.getEntities(state)
  const orm = Orm.withEntities(entities)
  return orm.getByIds(Document, topLevelIds)
}

export const getDisplayCategory = state => {
  const currentFirm = globalSelectors.getCurrentFirm(state)
  return getFirmCategoryLabel({
    category: emailConstants.CATEGORIES[getComparisonCategory(state)],
    currentFirm,
  })
}

export const getSingularDisplayCategory = state => {
  const currentFirm = globalSelectors.getCurrentFirm(state)
  return getFirmCategoryLabel({
    category: getComparisonCategory(state),
    currentFirm,
  })
}
