import { createAction } from 'redux-actions'

// Prefix action names with PROFILE_
const createProfileAction = name => createAction(`PROFILE_${name}`)

export const init = createProfileAction('INIT')
export const hideLoader = createProfileAction('HIDE_LOADER')
export const showEditSearchModal = createProfileAction('SHOW_EDIT_SEARCH_MODAL')
export const hideEditSearchModal = createProfileAction('HIDE_EDIT_SEARCH_MODAL')
export const setIsProspectCategory = createProfileAction('SET_IS_PROSPECT_CATEGORY')

export const fetchSearch = createProfileAction('FETCH_SEARCH')
export const setFilters = createProfileAction('SET_FILTERS')
export const fetchSearchComplete = createProfileAction('FETCH_SEARCH_COMPLETE')
export const createSearches = createProfileAction('CREATE_SEARCHES')

export const fetchSearchResults = createProfileAction('FETCH_SEARCH_RESULTS')
export const fetchSearchResultsComplete = createProfileAction('FETCH_SEARCH_RESULTS_COMPLETE')
export const setQueryComponentState = createProfileAction('SET_QUERY_COMPONENT_STATE')
export const setSaveData = createProfileAction('SET_SAVE_DATA')
export const updateSearch = createProfileAction('UPDATE_SEARCH')
export const updateSearchComplete = createProfileAction('UPDATE_SEARCH_COMPLETE')
export const deleteSearches = createProfileAction('DELETE_SEARCHES')
export const removeExcludedFeedsFromSearch = createProfileAction('REMOVE_EXCLUDED_FEEDS_FROM_SEARCH')

// browse modal
export const setBrowseModalType = createProfileAction('SET_BROWSE_MODAL_TYPE')
export const setBrowseModalSelectedIds = createProfileAction('SET_BROWSE_MODAL_SELECTED_IDS')
export const setBrowseModalRemovedIds = createProfileAction('SET_BROWSE_MODAL_REMOVED_IDS')
export const resetBrowseModalData = createProfileAction('RESET_BROWSE_MODAL_DATA')
export const fetchBrowseModalSearches = createProfileAction('FETCH_BROWSE_MODAL_SEARCHES')
export const setFirmLibrarySearchIdsByCategory = createProfileAction('SET_FIRM_LIBRARY_SEARCH_IDS_BY_CATEGORY')
export const setGlobalSearchIdsByCategory = createProfileAction('SET_GLOBAL_SEARCH_IDS_BY_CATEGORY')
export const setFeaturedSearchIdsByCategory = createProfileAction('SET_FEATURED_SAVED_SEARCHES_BY_CATEGORY')
export const applyBrowseModalSearches = createProfileAction('APPLY_BROWSE_MODAL_SEARCHES')
