import {createAction} from 'redux-actions'

const action = name => createAction(`COMPARISON_PAGE_${name}`)

export const init = action('INIT')
export const setLoadingSearchIds = action('SET_LOADING_SEARCH_IDS')
export const setBuildNewComparison = action('SET_BUILD_NEW_COMPARISON')
export const setShowFirstTimePage = action('SET_SHOW_FIRST_TIME_PAGE')
export const setSelectedSearchIds = action('SET_SELECTED_SEARCH_IDS')
export const setDeleteComparison = action('SET_DELETE_COMPARISON')
export const setNewComparisonTitle = action('SET_NEW_COMPARISON_TITLE')
export const setDisplayChartTypes = action('SET_DISPLAY_CHART_TYPES')
export const timeFrameChange = action('TIME_FRAME_CHANGE')
export const setComparisonGroups = action('SET_COMPARISON_GROUPS')
export const fetchComparisonSearchResults = action('FETCH_COMPARISON_SEARCH_RESULTS')
export const setSearchResults = action('SET_SEARCH_RESULTS')
export const setActiveComparison = action('SET_ACTIVE_COMPARISON')
export const removeDocumentsForExcludedFeedId =
  action('REMOVE_DOCUMENTS_FOR_EXCLUDED_FEED_ID')
export const activeSidebarNavChange = action('ACTIVE_SIDEBAR_NAV_CHANGE')
export const setLastSidebarNavChange = action('SET_LAST_SIDEBAR_NAV_CHANGE')
export const setActiveSidebarNav = action('SET_ACTIVE_SIDEBAR_NAV')
export const saveComparisonSearchGroup = action('SAVE_COMPARISON_SEARCH_GROUP')
export const deleteComparisonSearchGroup = action('DELETE_COMPARISON_SEARCH_GROUP')
export const fetchComparisonSearchGroups = action('FETCH_COMPARISON_SEARCH_GROUPS')
export const setNewComparisonId = action('SET_NEW_COMPARISON_ID')
export const narrowFiltersChange = action('NARROW_FILTERS_CHANGE')
export const refreshComparisonInit = action('REFRESH_COMPARISON_INIT')
export const setFetchingFilterKey = action('SET_FETCHING_FILTER_KEY')
export const setActiveFilterItems = action('SET_ACTIVE_FILTER_ITEMS')
export const restoreStateFromHistory = action('RESTORE_STATE_FROM_HISTORY')
export const setIsStaggeredLoading = action('SET_IS_STAGGERED_LOADING')
export const setShowEditComparisonModal = action('SET_SHOW_EDIT_COMPARISON_MODAL')
export const setActiveSidebarGroupTitle = action('SET_ACTIVE_SIDEBAR_GROUP_TITLE')
export const setShareOfVoiceChartType = action('SET_SHARE_OF_VOICE_CHART_TYPE')
export const setCachedLargerTimeFrames = action('SET_CACHED_LARGER_TIME_FRAMES')

// Flagging
export const showFlagModal = action('SHOW_FLAG_MODAL')

// Charts & Trends
export const fetchChartData = action('FETCH_CHART_DATA')
export const setChartData = action('SET_CHART_DATA')
export const initChartData = action('INIT_CHART_DATA')
export const chartPointClick = action('CHART_POINT_CLICK')
