import PropTypes from 'prop-types'
import React from 'react'

import StockInformation from './StockInformation'
import MzLineChart from "app/common/charts/MzLineChart"

import styles from './ChartsAndTrends.less'
import chartStyles from './Chart.less'
import LoadingSpinner from "app/common/LoadingSpinner"
import {shouldDisableLineChart} from "app/comparison-page/comparison-utils"
import MzPieChart from "app/common/charts/MzPieChart"
import classNames from "classnames"

export default class ChartsAndTrends extends React.PureComponent {
  static propTypes = {
    savedSearch: PropTypes.object.isRequired,
    timelineData: PropTypes.object,
    shareOfVoiceData: PropTypes.object,
    stockTimelineData: PropTypes.object,
    cachedLargerTimeFrames: PropTypes.array,
    timeFrame: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        start: PropTypes.object.isRequired,
        end: PropTypes.object.isRequired,
      }),
    ]),
  }

  render() {
    const {savedSearch, cachedLargerTimeFrames, timeFrame} = this.props
    const {id, slug} = savedSearch
    const shouldLineChartBeDisabled =
      shouldDisableLineChart(timeFrame, cachedLargerTimeFrames, [savedSearch.id])

    const timeLineChart = this.props.timelineData && !shouldLineChartBeDisabled
      ? <MzLineChart
          chartData={this.props.timelineData.chartData}
          className={styles.tier3LineChartWrapper}
          onClickType={'none'}
          chartMargin={{top: 20, right: 20, bottom: 0, left: 0}}
        />
      : !shouldLineChartBeDisabled
        ? <div className={chartStyles.loading}>
            <LoadingSpinner className={chartStyles.spinner} />
            Loading Timeline Chart...
          </div>
        : null

    const pieChart = this.props.shareOfVoiceData && this.props.shareOfVoiceData.chartData
      ? <MzPieChart
          className={styles.tier3PieChartWrapper}
          chartData={this.props.shareOfVoiceData.chartData}
        />
      : <div className={chartStyles.loading}>
          <LoadingSpinner className={chartStyles.spinner} />
          Loading Share of Voice Chart...
        </div>

    return (
      <div>
        <div className={styles.headerBar}>
          Mentions by Publication Type
        </div>

        <div className={styles.content}>
          { timeLineChart }
        </div>

        <div className={classNames(styles.content, styles.pieChart)}>
          {pieChart}
        </div>

        {/*{savedSearch.stockData && (*/}
        {/*  <>*/}
        {/*    <div className={styles.headerBar}>*/}
        {/*      Stock Information*/}
        {/*    </div>*/}
        {/*    <div className={styles.content}>*/}
        {/*      <StockInformation*/}
        {/*        savedSearch={savedSearch}*/}
        {/*        data={this.props.stockTimelineData}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </>*/}
        {/*)}*/}
      </div>
    )
  }
}
