import {parseISO} from 'date-fns'
import is from 'is'

import Model from 'app/framework/Model'
import { field, foreignKey, hasMany, arrayOf } from 'app/framework/Model/fields'

import ActionLog from './ActionLog'
import Feed from './Feed'
import User from './User'
import Department from './Department'
import Team from './Team'


export default class SavedSearch extends Model {
  static entityKey = 'searches'

  static fields = {
    owner: foreignKey(User, {required: false}),
    name: field(),
    slug: field({required: false}),
    category: field({required: false}),
    scope: field({required: false}),
    notes: field({required: false}),
    query: field({required: false}),
    userFriendlyQuery: field({required: false}),
    queryType: field({required: false}),
    createdAt: field({required: false}),
    updatedAt: field({required: false}),
    isInsightsEnabled: field({required: false}),
    filterGroups: field({required: false}),
    hasFilters: field({required: false}),
    aboutFeeds: arrayOf(foreignKey(Feed, {required: false})),
    excludedFeeds: arrayOf(foreignKey(Feed, {required: false})),
    duration: field({required: false}),
    resultsOrder: field({required: false}),
    savedSearchArticlesCount: field({required: false}),

    /**
     * noticeConfig format example = {
     *    frequency: 'weekday',
     *    maxItems: {
     *      defaultPubTypes: 5,
     *      twitter: 5,
     *      event: 5,
     *      allFilings: 5,
     *    },
     *    sendAlone: false,
     *  }
     */
    noticeConfig: field({required: false}),

    absoluteUrl: field({required: false}),
    adminEditUrl: field({required: false}),
    isFirmLibrary: field({required: false}),
    isFirmLibraryChild: field({required: false}),
    mainParent: foreignKey(SavedSearch, {required: false}),
    childSearches: field({required: false}),
    usedAsFilterBy: field({required: false}),
    usedCount: field({required: false}),
    numberOfLinkedProfiles: field({required: false}),
    solrSearchField: field({required: false}),
    groupingThreshold: field({required: false}),
    dnbData: field({required: false}),
    relevancyLevel: field({required: false}),
    groupingLevel: field({required: false}),
    queryComponents: field({required: false}),
    fullQuery: field({required: false}),
    fqParams: field({required: false}),
    shouldShowRelevanceFilter: field({required: false}),
    validationErrorMessage: field({required: false}),
    termFrequencyFilters: field({required: false}),
    languageFilters: field({required: false}),
    featuredForUsers: arrayOf(foreignKey(User, {required: false})),
    featuredForDepartments: arrayOf(foreignKey(Department, {required: false})),
    featuredForTeams: arrayOf(foreignKey(Team, {required: false})),
    actionLog: arrayOf(foreignKey(ActionLog, {required: false})),
    labels: field({required: false}),
  }

  static indexes = ['copyOf']

  static USER_SELECTABLE_CATEGORIES = {
    COMPANY: 'client',
    COMPETITOR: 'firm',
    INDUSTRY: 'industry',
    PRACTICE: 'practice',
    PROSPECT: 'prospect',
    TOPIC: 'tracker',
  }
  static TRUSTED_SOURCE_CATEGORIES = {
    TRUSTED: 'trusted',
    TRUSTED_UNCATEGORIZED: 'trusted-uncategorized',
  }
  static USER_ACCESSIBLE_CATEGORIES = {
    ...SavedSearch.USER_SELECTABLE_CATEGORIES,
    ...SavedSearch.TRUSTED_SOURCE_CATEGORIES,
  }
  static CATEGORIES = {
    ...SavedSearch.USER_ACCESSIBLE_CATEGORIES,
    COURT: 'court',
    STATE: 'state',
  }

  static SCOPES = {
    GLOBAL: 'global',
    SHARED: 'shared',
    PERSONAL: 'personal',
  }

  constructor(data) {
    super(data)
    const {createdAt, updatedAt, signalsEnabled} = data
    if (createdAt && createdAt.constructor !== Date) {
      this.createdAt = parseISO(createdAt)
    }
    if (updatedAt && createdAt.constructor !== Date) {
      this.updatedAt = parseISO(updatedAt)
    }

    // Legacy field name. TODO: Remove at some point.
    if (is.defined(signalsEnabled)) {
      this.isInsightsEnabled = signalsEnabled
    }
    this.signalsEnabled = this.isInsightsEnabled
  }

  /**
   * Whether this search has been saved, i.e. whether it has a valid ID.
   */
  get isSaved() {
    return this.id > 0
  }

  get isSource() {
    const {TRUSTED_SOURCE_CATEGORIES} = this.constructor
    return Object.values(TRUSTED_SOURCE_CATEGORIES).includes(this.category)
  }

  get defaultFilterField() {
    return this.queryType === 'all' ? 'text_all' : 'text_any'
  }
}
SavedSearch.fields.copyOf = foreignKey(SavedSearch, {required: false})
SavedSearch.fields.copiedBy = hasMany(
  SavedSearch,
  {required: false, foreignKeyField: 'copyOf'},
)
