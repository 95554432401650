import classNames from 'classnames'
import PropTypes from 'prop-types'
import {last} from 'ramda'
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import Highlighter from 'react-highlight-words'
import request from 'superagent'

import SearchTerm from 'app/reusable/SearchTerm'

import '../AutocompleteInput/style.less'


export default class SavedSearchInput extends Component {
  static propTypes = {
    url: PropTypes.string,
    options: PropTypes.object,
    selectedValues: PropTypes.array,
    maxValues: PropTypes.number,
    allowFreeText: PropTypes.bool,
    isEnabled: PropTypes.bool,

    // search ids to omit from results
    omitIds: PropTypes.array,

    onChange: PropTypes.func,
  }

  static defaultProps = {
    url: '/frontend-api/saved-search/autocomplete/',
    allowFreeText: true,
    isEnabled: true,
    selectedValues: null, // need to default to null so can differentiate from empty array
    omitIds: [],

    onChange: () => {},
  }

  state = {
    // Note: The value of the actual input element isn't synced with
    // this value. In order to change the input, you must currently
    // manually change this.input.value. This is due to a React bug
    // that affects IE11: https://github.com/facebook/react/issues/7027
    inputValue: '',
    values: this.props.selectedValues || [],

    // The currently highlighted option (either with the mouse or the
    // arrow keys)
    highlightedOption: null,

    // The options to show in the autocomplete menu
    options: [],

    // Whether the menu can be shown. Note that this won't do
    // anything if the `options` array is empty.
    canShowMenu: false,

    fetchTimer: null,
  }

  // Public methods

  get values() {
    return this.state.values
  }

  get hasUntokenizedText() {
    return !!this.state.inputValue
  }

  /*
   * If there is any remaining free text in the input, turn it into a
   * "free text" token.
   */
  tokenizeFreeText() {
    if (this.props.allowFreeText && this.hasUntokenizedText) {
      this.state.inputValue.split(',').forEach((value) => {
        this.selectOption(this.freeTextOption(value))
      })
    }
  }

  clear() {
    this.setState(prevState => ({...prevState, inputValue: '', values: [], options: []}))
    this.input.value = ''
  }

  // React methods

  render() {
    const {highlightedOption} = this.state

    const values = this.state.values.map((value, index) => {
      const valueRemoved = () => {
        this.removeValue(index)
      }
      return (
        <SearchTerm
          label={value.label}
          value={value.value}
          isFreeText={value.isFreeText || false}
          isRemovable={!value.isFeed}
          isFirmLibrary={value.isFirmLibrary || false}
          isFirmLibraryChild={value.isFirmLibraryChild || false}
          isFeed={value.isFeed}
          scope={value.scope}
          notes={value.notes}
          ownerName={value.ownerName}
          isFirmSourceLabel={!!value.firmSourceLabelId}
          onRemove={valueRemoved}
          key={'saved-searches-input-value-' + index}
        />
      )
    })

    const options = this.state.options.map((option, index) => {
      const highlighted =
        !!(highlightedOption && option.value === highlightedOption.value)

      return (
        <SavedSearchesInputOption
          option={option}
          input={this.state.inputValue}
          highlighted={highlighted}
          onClick={this.selectOption}
          onHover={this.highlightOption}
          key={'saved-searches-input-option-' + index}
        />
      )
    })
    const optionsDisplay =
      this.state.options.length && this.state.canShowMenu ? 'block' : 'none'

    const inputWidth = Math.max(5, this.state.inputValue.length) + 'em'

    return (
      <div className={classNames('autocomplete-container', {disabled: !this.props.isEnabled})} onKeyDown={this.keyPressed}>
        <div className={classNames('autocomplete-input', {disabled: !this.props.isEnabled})} onClick={this.containerClicked}>
          <span className="values">
            {values}
          </span>

          <input
            className="input"
            onChange={this.inputChanged}
            onFocus={this.showMenu}
            style={{width: inputWidth}}
            ref={ref => this.input = ref}
            disabled={!this.props.isEnabled}
          />

          <div className="options" style={{display: optionsDisplay}}>
            {options}
          </div>
        </div>
      </div>
    )
  }

  componentWillReceiveProps(props) {
    if (props.selectedValues) {
      this.setState({values: props.selectedValues})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const curState = this.state

    // If the input value changes, we need to decide whether to show
    // new options
    if (curState.inputValue !== prevState.inputValue) {
      if (curState.inputValue) {
        // If there is a value in the input, fetch the options
        this.fetchOptions()
      } else {
        // If the input is empty, remove the options
        this.setState({options: []})
      }
    }
  }

  fetchOptions() {
    window.clearTimeout(this.state.fetchTimer)
    this.setState({fetchTimer: null}, () => {
      const timer = window.setTimeout(() => this.fetchOptionsRequest(), 300)
      this.setState({fetchTimer: timer})
    })
  }

  fetchOptionsRequest() {
    const input = this.state.inputValue
    const {highlightedOption} = this.state
    if (input.length < 2) {
      this.setState({
        options: [],
        highlightedOption: null,
      })
      return
    }
    request
      .get(this.props.url)
      .query({q: input})
      .query(this.props.options)
      .end((err, res) => {
        // ignore these results if the input has changed
        if (input !== this.state.inputValue) {
          return
        }
        if (err) {
          // TODO: Show an actual error message to the user
          console.error(err)
        } else {
          const options = res.body.filter(obj => !this.props.omitIds.includes(obj.id)).map(obj => {
            return {
              label: obj.name,
              value: obj.id,
              searchPhrases: obj.search_phrases,
              category: obj.category,
              categoryDisplay: obj.category_display,

              // ownerName is needed to keep consistent with existing input values.
              owner: obj.owner,
              ownerName: obj.owner ? obj.owner.full_name : '',

              scope: obj.scope || null,
              notes: obj.notes,
              isFirmLibrary: obj.is_firm_library,
              firmSourceLabelId: obj.firm_source_label_id,
            }
          })
          const freeTextOption = this.freeTextOption(input)
          if (this.props.allowFreeText) {
            options.push(freeTextOption)
          }

          // Reset the currently highlighted option if it's not one of
          // the options displayed. This avoids weird behavior like
          // pressing Enter selecting an invisible option.
          const newHighlightedOption =
            highlightedOption
              && options.find(option =>
                option.value === highlightedOption.value
              )
              ? highlightedOption
              : null

          this.setState({
            options: options,
            highlightedOption: newHighlightedOption,
          })
          this.showMenu()
        }
      })
  }

  showMenu = () => {
    // Note: This does not guarantee that the menu will actually
    // appear! If there are no options, the menu will still not show.
    this.setState({canShowMenu: true})
    document.addEventListener('click', this.handleGlobalClick)
  }

  hideMenu() {
    this.setState({canShowMenu: false})
    document.removeEventListener('click', this.handleGlobalClick)
  }

  addValue(value) {
    this.setState(prevState => {
      const newValues = [...prevState.values, value]
      this.props.onChange(newValues)
      return ({...prevState, values: newValues})
    })
  }

  removeValue(index) {
    this.setState(prevState => {
      const newValues = [...prevState.values]
      newValues.splice(index, 1)
      this.props.onChange(newValues)
      return ({...prevState, values: newValues})
    })
  }

  selectOption = (option) => {
    // wrap numeric free text search values in quotes to search
    // for the literal number rather than searching by
    // savedsearch id. Changing here as the app relies on
    // search id logic for filters.
    if (isNaN(option.value) !== true && option.isFreeText){
      option.value = "'" + option.value + "'"
    }
    this.setState({
      inputValue: '',
      highlightedOption: null,
    }, () => this.addValue(option))
    this.input.value = ''
    this.input.focus()
  }

  highlightOption = (option) => {
    this.setState({highlightedOption: option})
  }

  hasHitMaxValuesLimit() {
    const {maxValues} = this.props
    return maxValues && this.state.values.length === maxValues
  }

  // Event handlers

  containerClicked = (event) => {
    this.input.focus()
  }

  inputChanged = () => {
    this.setState({inputValue: this.input.value.trim()})
  }

  keyPressed = (event) => {
    const {key} = event
    const {highlightedOption, inputValue, options, values} = this.state

    if (key == 'ArrowUp' || key == 'ArrowDown') {
      const offset = key == 'ArrowUp' ? -1 : 1
      const highlightedIndex =
        highlightedOption
          ? options.findIndex(option => {
            return option.value === highlightedOption.value
          })
          : -1
      let newIndex = highlightedIndex + offset
      if (newIndex < 0) {
        newIndex = 0
      } else if (newIndex >= options.length) {
        newIndex = options.length - 1
      }
      const newHighlightedOption = options[newIndex]
      this.highlightOption(newHighlightedOption)
    }

    else if (['Enter', 'Tab', ',', '(', ')'].includes(key)) {
      if (highlightedOption && key != ',') {
        this.selectOption(highlightedOption)
        // We don't want the default behavior of either the Enter
        // (submit) or Tab (lose focus) keys
        event.preventDefault()
      } else if (inputValue && ['Enter', 'Tab', ','].includes(key)) {
        this.tokenizeFreeText()
        event.preventDefault()
      } else if (key == ',' || key == '(' || key == ')') {
        // These chars aren't allowed in searches, so we always prevent them
        event.preventDefault()
      }
    }

    else if (key == 'Backspace') {
      const {selectionStart, selectionEnd} = this.input
      if (selectionStart === 0 && selectionEnd === 0 && values.length) {
        const lastValue = last(values)
        if (!lastValue.hasOwnProperty('isRemovable') || lastValue.isRemovable) {
          this.removeValue(values.length - 1)
        }
      }
    }

    else if (this.hasHitMaxValuesLimit()) {
      // If we've hit our max values limit, don't allow any more typing
      event.preventDefault()
    }
  }

  handleGlobalClick = (e) => {
    // Hide the menu if the user clicks outside of it
    try {
      const rootNode = findDOMNode(this)
      if (!rootNode.contains(e.target)) {
        this.tokenizeFreeText()
        this.hideMenu()
      }
    } catch(e) {}
  }

  // Helpers

  freeTextOption(text) {
    return {
      label: text,
      value: text,
      category: null,
      isFreeText: true,
    }
  }
}


class SavedSearchesInputOption extends Component {
  static propTypes = {
    input: PropTypes.string.isRequired,
    option: PropTypes.object.isRequired,
    highlighted: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    onHover: PropTypes.func,
  }

  render() {
    const {input, option, highlighted} = this.props

    let owner
    if (!option.owner) {
      owner = ''
    } else if (
      option.scope === 'global'
      && (option.category === 'practice' || option.category === 'industry')
    ) {
      owner = <div className={classNames('ss-mz-owner', {'diligent-branding': window.MZ['app_name'] === 'Diligent'})}/>
    } else if (window.MZ['app_name'] === 'Diligent' && option.owner.first_name === 'Manzama') {
      owner = 'Author: Diligent'
    } else {
      owner = `Author: ${option.owner.full_name}`
    }

    const searchTerms =
      option.isFreeText
        ? `Search for the free text "${option.label}"`
        : (
          <Highlighter
            searchWords={[input]}
            textToHighlight={option.searchPhrases}
            autoEscape={true}
          />
        )

    return (
      <div
        className={classNames('option', 'ss-auto-instance', {highlighted}, {'firm-library': option.isFirmLibrary})}
        onClick={e => this.clicked(option, e)}
        onMouseMove={e => this.hovered(option)}
      >
        <div className="hd font-size-s">
          <strong>{option.label}</strong>
          <span className="ss-type">{option.categoryDisplay}</span>
        </div>
        <div className="hd font-size-xs">
          {searchTerms}
          <span className="ss-created-by font-size-s">{owner}</span>
        </div>
      </div>
    )
  }

  clicked(option, event) {
    event.stopPropagation()
    if (this.props.onClick) {
      this.props.onClick(option)
    }
  }

  hovered(option) {
    if (this.props.onHover) {
      this.props.onHover(option)
    }
  }
}
