import React, { Component } from 'react'
import { render } from 'react-dom'

import SavedSearchInput from 'app/common/SavedSearchInput'

import './style.less'
import {connect, Provider} from "react-redux"
import store from "app/store"
import {runSearchForQuery} from 'app/utils'


@connect(state => {return {isDiligent: state.isDiligent, appName: state.appName}},)
export class HomeSearchBar extends Component {
  state = {
    isTokenizing: false,
    searchValue: '',
  }

  render() {

    const browseButtonLabel = this.props.isDiligent
      ? 'Browse Governance Intel Searches'
      : `Browse ${this.props.appName} Searches`

    return (
      <div id="homepage-search">
        <div className="search-container">
          <form
            id="site-search"
            onSubmit={this.onFormSubmit}
            ref={form => this.form = form}
          >
            <SavedSearchInput
              onChange={this.onSearchChange}
              ref={ref => this.input = ref}
            />
            <input
              type="submit"
              id="site-search-submit"
              value="Search"
              title="Search"
            />
          </form>
        </div>
        <div className={'button-container'}>
          <div className={'browse-button'}>
            <a href="/advanced-search/?tab=lookup" className="advancedSearch standardButton blueButton">{browseButtonLabel}</a>
          </div>
          <div className="button-spacer">OR</div>
          <div className={'advanced-button'}>
            <a href="/advanced-search/?tab=advanced" className="advancedSearch standardButton blueButton">Start an Advanced Search</a>
          </div>
        </div>
        {/*
          mz.js expects an element of search-site-icon-holder and
          search-advanced-arrow on every page. All other pages have
          it in the nav header.
        */}
        <span id="search-site-icon-holder" className="hide" />
        <span id="search-advanced-arrow" className="hide" />
      </div>
    )
  }

  onFormSubmit = (event) => {
    event.preventDefault()
    if (this.input.hasUntokenizedText) {
      this.setState({isTokenizing: true})
      this.input.tokenizeFreeText()
      return
    }
    // If the search bar is empty or we're waiting for the
    // SavedSearchInput component to tokenize free text, don't submit
    if (!this.state.searchValue || this.state.isTokenizing) {
      return
    }

    runSearchForQuery(this.state.searchValue)
  }

  onSearchChange = (values) => {
    const searchValue = values.map(val => val.value).join('~|')
    const callback = this.state.isTokenizing
      ? () => this.form.submit()
      : () => {}
    this.setState({searchValue}, callback)
  }
}


export function init() {
  const container = document.getElementById('homepage-search-container')
  const component =
    <Provider store={store}>
      <HomeSearchBar />
    </Provider>
  render(component, container)
}
