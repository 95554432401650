import React, { Component } from 'react'
import Table from 'app/common/Table'
import { connect } from 'react-redux'
import * as R from "ramda"
import { getDunAndBradstreet } from './dandb-search-selectors'

@connect(
  state => ({
    companies: getDunAndBradstreet(state).data
  })
)
export default class DandBSearchCompanies extends Component {

  static defaultProps = {}

  render() {

    return (
      <div className="dnb-table-container">
        <br />
        <Table
          data={this.props.companies}
          defaultSort={{column: 'companyEmployees', direction: 'desc'}}
        >

          <Table.Column
            name={'companyName'}
            label={"Company Name"}
            isSortable
            sortBy={R.prop('companyName')}
            baseWidth={120}
            growRatio={1}
            cellContents={R.prop('companyName')}
          />

          <Table.Column
            name={'companyDuns'}
            label="DUNS"
            isSortable
            sortBy={R.prop('companyDuns')}
            baseWidth={120}
            growRatio={1}
            cellContents={R.prop('companyDuns')}
          />

          <Table.Column
            name={'parentDuns'}
            label="Parent DUNS"
            isSortable
            sortBy={R.prop('parentDuns')}
            baseWidth={120}
            growRatio={1}
            cellContents={R.prop('parentDuns')}
          />

          <Table.Column
            name={'inEntities'}
            label="In Entities"
            isSortable
            sortBy={R.prop('inEntities')}
            baseWidth={120}
            growRatio={1}
            cellContents={R.prop('inEntities')}
          />

          <Table.Column
            name={'companyRole'}
            label="Role"
            isSortable
            sortBy={R.prop('companyRole')}
            baseWidth={120}
            growRatio={1}
            cellContents={R.prop('companyRole')}
          />

          <Table.Column
            name={'companyEmployees'}
            label="Employees"
            isSortable
            sortBy={R.prop('companyEmployees')}
            baseWidth={120}
            growRatio={1}
            cellContents={company => formatNumber(company.companyEmployees)}
          />

          <Table.Column
            name={'companyCity'}
            label="City"
            isSortable
            sortBy={R.prop('companyCity')}
            baseWidth={120}
            growRatio={1}
            cellContents={R.prop('companyCity')}
          />

          <Table.Column
            name={'companyState'}
            label="State"
            isSortable
            sortBy={R.prop('companyState')}
            baseWidth={120}
            growRatio={1}
            cellContents={R.prop('companyState')}
          />

          <Table.Column
            name={'companyCountry'}
            label="Country"
            isSortable
            sortBy={R.prop('companyCountry')}
            baseWidth={120}
            growRatio={1}
            cellContents={R.prop('companyCountry')}
          />

        </Table>
      </div>
    )
  }
}

  function formatNumber(number) {
    if (window.Intl && window.Intl.NumberFormat) {
      return (new Intl.NumberFormat).format(number)
    }
    // If the browser doesn't support the international number format API (IE11),
    // just show the number as-is.
    return number
  }

