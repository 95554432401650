import { changeCaseObject } from 'app/utils'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'

import store from 'app/store'

import UsersAdmin from './UsersAdmin'
import { init as initPage } from './users-admin-actions'
import * as constants from './users-admin-constants'
import reducer from './users-admin-reducer'
import saga from './users-admin-saga'

import './UsersAdmin.less'


export function init(data) {
  // Add the data from this page to the Redux store
  store.dispatch(initPage(changeCaseObject.camelCase(data)))
  const component = (
    <Provider store={store}>
      <UsersAdmin/>
    </Provider>
  )
  const container = document.getElementById('users-admin-wrapper')
  render(component, container)
}

export {constants, reducer, saga}
