import { all, delay, takeLatest, put, call, select } from 'redux-saga/effects'
import * as usersAdminApi from './users-admin-api'
import * as usersAdminActions from './users-admin-actions'
import { getUsersAdmin } from './users-admin-selectors'
import { baseNotificationsInit } from 'app/email-notifications/email-notifications-actions'
import * as entitiesActions from 'app/entities/entities-actions'
import * as entitiesSelectors from 'app/entities/entities-selectors'
import * as notifications from 'app/global/notifications'
import Orm from 'app/framework/Orm'
import { User } from 'app/models'
import { handleSagaError } from 'app/utils/errors'
import {actionApiRequest} from 'app/utils/sagas'


function* updateUserEntities(users) {
  const userEntities = {}
  users.forEach(u => {
    userEntities[u.id] = u
  })
  const entityData = {
    users: userEntities
  }
  yield put(entitiesActions.update(entityData))
}

function* deleteUserEntities(ids) {
  const entities = yield select(entitiesSelectors.getEntities)
  const orm = Orm.withEntities(entities)
  const users = orm.getByIds(User, ids)
  let memberships = []
  users.forEach(user => {
    memberships = [...memberships, ...user.groupMemberships.map(m => m.id), ...user.userMemberships.map(m => m.id)]
  })
  const entityData = {
    users: ids,
    memberships,
  }
  yield put(entitiesActions.remove(entityData))
}

function* handleFetchUser(action) {
  const usersAdmin = yield select(state => getUsersAdmin(state))
  const {shouldAutoFetchAvailableMembers, areAllAvailableMembersFetched} = usersAdmin
  const currentUserId = yield select(state => state.currentUser)
  const entities = yield select(state => state.entities)
  const orm = Orm.withEntities(entities)
  const currentUser = orm.getById(User, currentUserId)
  const users = {}
  const memberships = {}
  const shouldFetchAvailableMembers = shouldAutoFetchAvailableMembers && !areAllAvailableMembersFetched
  if (shouldFetchAvailableMembers) {
    yield call(handleFetchAvailableMembers)
  }
  const userResponse = yield usersAdminApi.fetchUser(action.payload)
  const notificationsInitData = {
    result: {
      isProfilePage: false,
      baseEmailSettings: userResponse.user.baseEmailSettings,
      emailDeliveryData: {
        selected: userResponse.user.selectedDeliveryOptions,
        isSignalsEnabled: userResponse.user.hasInsights,
        isGroupUser: userResponse.user.role === 'group',
        editUserId: userResponse.user.id,
        editUserFullName: userResponse.user.fullName,
        defaultUserEmail: currentUser.email,
        quickFilterOptions: userResponse.user.quickFilterOptions,
        selectedPrefix: userResponse.user.selectedPrefix,
        selectedSuffix: userResponse.user.selectedSuffix,
        emailDeliveryToGroupMembers: userResponse.user.emailDeliveryToGroupMembers,
        categoryOrderToGroupMembers: userResponse.user.categoryOrderToGroupMembers,
        categoryDefaultToGroupMembers: userResponse.user.categoryDefaultToGroupMembers,
        deliveryTimeToGroupMembers: userResponse.user.deliveryTimeToGroupMembers,
        deliveryDayToGroupMembers: userResponse.user.deliveryDayToGroupMembers,
        duplicateArticleToGroupMembers: userResponse.user.duplicateArticleToGroupMembers,
        searchesAlphabeticallyToGroupMembers:userResponse.user.searchesAlphabeticallyToGroupMembers,
      }
    }
  }
  yield put(baseNotificationsInit(notificationsInitData))
  userResponse.user.groupMemberships.forEach(m => {
    m.userId = m.user.id
    m.groupId = m.group.id
    memberships[m.id] = {...m}
    users[m.user.id] = {...m.user}
    users[m.group.id] = {...m.group}
  })
  userResponse.user.userMemberships.forEach(m => {
    m.userId = m.user.id
    m.groupId = m.group.id
    memberships[m.id] = {...m}
    users[m.user.id] = {...m.user}
    users[m.group.id] = {...m.group}
  })
  users[userResponse.user.id] = {...userResponse.user}
  yield put(entitiesActions.update({
    users,
    memberships,
  }))
  yield put(usersAdminActions.setMemberships({
    /**
     * for users, groupMemberships contains the groups they belong to.
     * for groups, userMemberships contains the users who belong to the group.
     */
    groupMemberships: userResponse.user.groupMemberships.map(m => m.id),
    userMemberships: userResponse.user.userMemberships.map(m => m.id),
  }))
  yield put(usersAdminActions.fetchUserComplete())
}

function* handleFetchAvailableMembers(action) {
  let name = undefined
  let forceFetch = false
  if (action) {
    name = action.payload.name
    forceFetch = action.payload.forceFetch
  }
  const usersAdmin = yield select(state => getUsersAdmin(state))
  const {isGroupPage, shouldAutoFetchAvailableMembers, areAllAvailableMembersFetched} = usersAdmin
  const users = {}
  const availableMemberIds = []
  if (areAllAvailableMembersFetched) {
    return
  }
  /**
   * if we're not fetching all, not auto-fetching, and we haven't fetched all yet, and there are no filters, just reset.
   */
  if (!forceFetch && !shouldAutoFetchAvailableMembers && !areAllAvailableMembersFetched && !name) {
    yield put(usersAdminActions.fetchAvailableMembersComplete({availableMemberIds: []}))
    return
  }
  if (name) {
    yield delay(300)
  }
  if (action) {
    yield put(usersAdminActions.showLoader())
  }
  /**
   * if we're on the users page, we need all groups for the memberships page.
   * if we're on the groups page, we need all users for the memberships page.
   */
  let apiMethod = usersAdminApi.fetchAllGroups
  let responseKey = 'groups'
  if (isGroupPage) {
    apiMethod = usersAdminApi.fetchAllUsers
    responseKey = 'users'
  }
  let entityResponse = null
  try {
    entityResponse = yield apiMethod({isActive: true, name})
  } catch (error) {
    yield* handleSagaError(error)
    return
  }
  entityResponse[responseKey]['items'].forEach(u => {
    users[u.id] = {...u}
    availableMemberIds.push(u.id)
  })
  yield put(entitiesActions.update({
    users,
  }))
  if (availableMemberIds.length > 0) {
    yield put(usersAdminActions.fetchAvailableMembersComplete({availableMemberIds, areAllAvailableMembersFetched: !name}))
  }
  if (action) {
    yield put(usersAdminActions.hideLoader())
  }
}

function* handleFetchUsers(action) {
  const usersAdmin = yield select(state => getUsersAdmin(state))
  const {isGroupPage, shouldAutoFetchProfiles, allUserIds} = usersAdmin
  const { currentPage, pfilters, sortField, sortDirection } = usersAdmin
  let { filters } = usersAdmin
  let {forceFetch} = action.payload
  const params = {
    page: currentPage,
    sortField,
    sortDirection,
  }

  Object.keys(filters).forEach (key => {
    const value = filters[key]
    if (value || typeof value === 'boolean') {
      params[key] = value
    }
  })
  Object.keys(pfilters).forEach (key => {
    const value = pfilters[key]
      params[key] = value
  })

  const filterCount = Object.keys(filters).length 
  if (!forceFetch) {
    /**
     * if we're not auto-fetching, and we haven't fetched all yet, and there are no filters, just reset.
     */
    if (!shouldAutoFetchProfiles) {
      yield put(usersAdminActions.setUserIds([]))
      return
    }
    /**
     * if all profiles have been fetched, and there are no filters requiring a re-fetch, we can just filter in place.
     * the name/isPrivateGroup filters only require a fetch if we haven't fetched all already.
     * note, we need to call `setUserIds` here (rather than in the component) so that we get the benefit of takeLatest
     * cancelling previous calls to `fetchUsers`.
     */
    const haveAnyBackendFilters = Object.keys(filters).some(k => !['name', 'isPrivateGroup'].includes(k))
    if (!haveAnyBackendFilters) {
      yield put(usersAdminActions.setUserIds(allUserIds))
      return
    }
  }
  if (filters.name) {
    yield delay(300)
  }
  /**
   * show loader here instead of in reducer so that it only shows when fetching, not after typing.
   */
  yield put(usersAdminActions.showLoader())
  try {
    let apiMethod = usersAdminApi.fetchUsers
    let userType = 'user'
    if (isGroupPage) {
      apiMethod = usersAdminApi.fetchGroups
      userType = 'group'
      params.includeFirmwideGroup = false
    }
    const responseKey = `${userType}s`
    const response = yield apiMethod(params)
    const totalCount = response[responseKey].totalCount
    const isNlaFirm = response[responseKey].isNlaFirm
    const visibleIds = response[responseKey].items.map(u => u.id)
    const userEntities = {}
    const userIds = []
    response[responseKey]['items'].forEach(u => {
      userEntities[u.id] = u
      userIds.push(u.id)
    })
    const entityData = {
      users: userEntities,
    }
    yield put(entitiesActions.update(entityData))
    yield put(usersAdminActions.setUserIds(response[responseKey]['items'].map(u => u.id)))
    yield put(usersAdminActions.fetchUsersSuccess({areAllProfilesFetched: filterCount === 0, 
      totalCount: totalCount, 
      visibleIds: visibleIds,
      isNlaFirm: isNlaFirm
    }))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleFetchAllUsers(action) {
  const usersAdmin = yield select(state => getUsersAdmin(state))
  const {isGroupPage, currentPage,filters, pfilters, sortField, sortDirection } = usersAdmin
  const params = {
    page: currentPage,
    sortField,
    sortDirection,
  }
  Object.keys(filters).forEach (key => {
    const value = filters[key]
    if (value || typeof value === 'boolean') {
      params[key] = value
    }
  })
  Object.keys(pfilters).forEach (key => {
    const value = pfilters[key]
      params[key] = value
  })

  try {
    yield put(usersAdminActions.showLoader())
    let apiMethod2 = usersAdminApi.fetchAllUsers
    let userType = 'user'
    if (isGroupPage) {
      apiMethod2 = usersAdminApi.fetchAllGroups
      userType = 'group'
      params.includeFirmwideGroup = false
    }
    const response2 = yield apiMethod2({...params, isPaged: false})
    const responseKey = `${userType}s`
    const allUsers = response2[responseKey].items
    yield call(updateUserEntities, allUsers)
    const entities = yield select(entitiesSelectors.getEntities)
    const orm = Orm.withEntities(entities)
    const ids = allUsers.map(u => u.id)
    const users = orm.getByIds(User, ids)
    yield put(usersAdminActions.setAllSelectedUser(users))
    yield put(usersAdminActions.setSelectedUserIds(allUsers.map(u => u.id)))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleSaveNewUser(action) {
  try {
    const response = yield usersAdminApi.createUser(
      action.payload.firstName, action.payload.lastName, action.payload.emailAddress,
      action.payload.shouldSendWelcomeEmail
    )
    yield put(notifications.actions.showNotification({
      type: 'success',
      message: 'User added successfully',
    }))
    yield call(updateUserEntities, [response.user])
    yield put(usersAdminActions.createUserSuccess(response.user.id))
  } catch(error) {
    yield* handleSagaError(error)
  }
  yield put(usersAdminActions.hideLoader())
}

function* handleSaveNewGroup(action) {
  try {
    const response = yield usersAdminApi.createGroup(action.payload)
    yield put(notifications.actions.showNotification({
      type: 'success',
      message: 'Group added successfully',
    }))
    yield call(updateUserEntities, [response.group])
    yield put(usersAdminActions.createUserSuccess(response.group.id))
  } catch(error) {
    yield* handleSagaError(error)
    yield put(usersAdminActions.hideLoader())
  }
}

function* handleSaveUser(action) {
  /**
   * this handles saving groups also.
   */
  let response = null
  try {
    response = yield usersAdminApi.saveUser(action.payload)
  } catch(error) {
    yield* handleSagaError(error)
    yield put(usersAdminActions.hideLoader())
    return
  }
  yield put(notifications.actions.showNotification({
    type: 'success',
    message: `${response.user.role === 'group' ? 'Group' : 'User'} saved successfully`,
  }))

  if ('hasInsights' in action.payload) {
    yield put(usersAdminActions.fetchUser(response.user.id))
  }

  yield call(updateUserEntities, [response.user])
  yield put(usersAdminActions.saveUserSuccess(response.user))
}

function* handleDeleteUsers(action) {
  /**
   * this handles deleting groups also.
   */
  const userType = action.payload.isGroupPage ? 'Group' : 'User'
  const deleteMessagePart = action.payload.ids.length === 1
    ? userType
    : `${userType}s`
  try {
    yield usersAdminApi.deleteUsers(action.payload.ids)
    yield put(notifications.actions.showNotification({
      type: 'success',
      message: `${deleteMessagePart} deleted successfully`,
    }))
    yield put(usersAdminActions.deleteUserSuccess(action.payload.ids))
    yield put(usersAdminActions.hideUserModal())
    yield call(deleteUserEntities, action.payload.ids)
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleBulkPasswordReset(action) {
  try {
    const response = yield usersAdminApi.bulkPasswordReset(action.payload)
    yield put(notifications.actions.showNotification({
      type: response.body.success ? 'success' : 'error',
      message: response.body.msg,
    }))
    if (response.body.success) {
      yield put(usersAdminActions.setSelectedUserIds([]))
    }
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleBulkSendWelcomeEmail(action) {
  try {
    const response = yield usersAdminApi.bulkSendWelcomeEmail(action.payload)
    yield put(notifications.actions.showNotification({
      type: response.body.success ? 'success' : 'error',
      message: response.body.msg,
    }))
    if (response.body.success) {
      yield put(usersAdminActions.setSelectedUserIds([]))
    }
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleBulkSendRefreshEmail(action) {
  try {
    const response = yield usersAdminApi.bulkSendRefreshEmail(action.payload)
    yield put(notifications.actions.showNotification({
      type: response.body.success ? 'success' : 'error',
      message: response.body.msg,
    }))
    if (response.body.success) {
      yield put(usersAdminActions.setSelectedUserIds([]))
    }
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleBulkActivateAccounts(action) {
  try {
    const filters = yield select(
      state => getUsersAdmin(state).filters
    )
    const response = yield usersAdminApi.bulkActivateAccounts(action.payload.userIds, action.payload.action)
    yield put(notifications.actions.showNotification({
      type: response.body.success ? 'success' : 'error',
      message: response.body.msg,
    }))
    if (response.body.success) {
      yield put(usersAdminActions.hideUserModal())
      yield put(usersAdminActions.setSelectedUserIds([]))
      yield put(usersAdminActions.fetchUsers({filters, forceFetch: true}))
    }
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleBulkEnableInsights(action) {
  try {
    const filters = yield select(
      state => getUsersAdmin(state).filters
    )
    const response = yield usersAdminApi.bulkEnableInsights(action.payload.userIds, action.payload.action)
    yield put(notifications.actions.showNotification({
      type: response.body.success ? 'success' : 'error',
      message: response.body.msg,
    }))
    if (response.body.success) {
      yield put(usersAdminActions.bulkEnableInsightsSuccess())
      yield put(usersAdminActions.fetchUsers({filters, forceFetch: true}))
    }
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleFetchSearches(action) {
  try {
    const response = yield usersAdminApi.fetchSearches(action.payload)
    yield put(usersAdminActions.setSearches(response.searches))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleDeleteSearches(action) {
  const userModalData = yield select(state => getUsersAdmin(state).userModalData)
  try {
    const response = yield usersAdminApi.deleteSearches({...action.payload})
    yield put(notifications.actions.showNotification({
      type: 'success',
      message: 'Search(es) deleted successfully.',
    }))
    yield put(usersAdminActions.hideDeleteSearchesConfirmationModal())
    yield put(usersAdminActions.fetchSearches(userModalData.userId))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleSaveSearch(action) {
  try {
    yield usersAdminApi.saveSearch(action.payload)
    yield put(notifications.actions.showNotification({
      type: 'success',
      message: 'Search saved successfully',
    }))
    yield put(usersAdminActions.resetEditSearchData())
    yield put(usersAdminActions.fetchSearches(action.payload.userId))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleCreateSearch(action) {
  try {
    yield usersAdminApi.createSearch(action.payload)
    yield put(notifications.actions.showNotification({
      type: 'success',
      message: 'Search created successfully',
    }))
    yield put(usersAdminActions.resetNewSearchData())
    yield put(usersAdminActions.fetchSearches(action.payload.userId))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleCreateSource(action) {
  try {
    yield usersAdminApi.createSource(action.payload.userId, action.payload.feedId)
    yield put(notifications.actions.showNotification({
      type: 'success',
      message: 'Source created successfully',
    }))
    yield put(usersAdminActions.resetNewSearchData())
    yield put(usersAdminActions.fetchSearches(action.payload.userId))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleApplyBulkSearchUpdate(action) {
  try {
    const response = yield usersAdminApi.updateSearches({...action.payload})
    yield put(notifications.actions.showNotification({
      type: 'success',
      message: 'Search(es) updated successfully.',
    }))
    yield put(usersAdminActions.resetBulkSearchUpdateData())
    yield put(usersAdminActions.fetchSearches(action.payload.userId))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleAddUserToGroups(action) {
  try {
    const response = yield usersAdminApi.addUserToGroups(action.payload.userId, action.payload.groupIds)
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Group memberships were updated successfully.',
      })
    )
    yield put(usersAdminActions.fetchUser(action.payload.userId))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleRemoveUserFromGroups(action) {
  try {
    const response = yield usersAdminApi.removeUserFromGroups(action.payload.userId, action.payload.groupIds)
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Group memberships were updated successfully.',
      })
    )
    yield put(usersAdminActions.fetchUser(action.payload.userId))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleAddGroupToUsers(action) {
  try {
    const response = yield usersAdminApi.addGroupToUsers(action.payload.groupId, action.payload.userIds)
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Group memberships were updated successfully.',
      })
    )
    yield put(usersAdminActions.fetchUser(action.payload.groupId))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleRemoveGroupFromUsers(action) {
  try {
    const response = yield usersAdminApi.removeGroupFromUsers(action.payload.groupId, action.payload.userIds)
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Group memberships were updated successfully.',
      })
    )
    yield put(usersAdminActions.fetchUser(action.payload.groupId))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleToggleMembershipIsManager(action) {
  const isGroupPage = yield select(state => getUsersAdmin(state).isGroupPage)
  try {
    const response = yield usersAdminApi.toggleMembershipIsManager(action.payload.userId, action.payload.groupId)
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Group manager status was updated successfully.',
      })
    )
    if (isGroupPage) {
      yield put(usersAdminActions.fetchUser(action.payload.groupId))
    } else {
      yield put(usersAdminActions.fetchUser(action.payload.userId))
    }
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleRefreshSummarySignup(action) {
  const signup = action.payload
  const successMessage = signup
    ? 'You are now signed up to receive monthly Refresh Summary reports.'
    : 'You will no longer receive monthly Refresh Summary reports.'

  try {
    const response = yield usersAdminApi.refreshSummarySignup(signup)
    yield call(updateUserEntities, [response.user])
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: successMessage,
      })
    )
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleMakeSelectedGroupsPrivate(action) {
  const usersAdmin = yield select(getUsersAdmin)
  const {filters, selectedUserIds} = usersAdmin
  try {
    yield usersAdminApi.makeGroupsPrivate(selectedUserIds)
  } catch (error) {
    yield* handleSagaError(error)
    yield put(usersAdminActions.hideLoader())
    return
  }
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: `Group${selectedUserIds.length === 1 ? '' : 's'} successfully made private`,
    })
  )
  yield put(usersAdminActions.fetchUsers({filters, forceFetch: true}))
}

function* handleRecurringPublication(action) {
  let response = null
  const { groupId, page, itemsPerPage } = action.payload
  try {
    response = yield usersAdminApi.fetchRecurringPublicationsData({ groupId, page, itemsPerPage })
  } catch (error) {
    yield* handleSagaError(error)
    return
  } finally {
    yield put(usersAdminActions.hideLoader())
  }
  yield put(usersAdminActions.setPublicationsData(response.recurringPublication))
}

function* handleTemplatePublication(action) {
  let response = null
  const { groupId, page, itemsPerPage } = action.payload
  try {
    response = yield usersAdminApi.fetchTemplatePublicationsData({ groupId, page, itemsPerPage })
  } catch (error) {
    yield* handleSagaError(error)
    return
  } finally {
    yield put(usersAdminActions.hideLoader())
  }
  yield put(usersAdminActions.setPublicationsData(response.templatePublication))
}

function* handleSaveEmailDelivery(action) {
  yield* actionApiRequest(action, usersAdminApi.callSaveEmailDelivery)
  yield put(usersAdminActions.hideLoader())
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: `${action.payload.message} email delivery settings saved successfully.`,
    })
  )
}

function* handleSaveBulkNlaArticleViewingSettings(action) {
  try {
    const response = yield usersAdminApi.saveBulkNlaArticleViewingSettings(action.payload.users, action.payload.setting)
    yield put(notifications.actions.showNotification({
      type: response.body.success ? 'success' : 'error',
      message: response.body.msg,
    }))
    if (response.body.success) {
      yield put(usersAdminActions.setSelectedUserIds([]))
    }
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleBulkManageRefreshInterval(action) {
  try {
    const filters = yield select(
      state => getUsersAdmin(state).filters
    )
    const response = yield usersAdminApi.bulkManageRefreshInterval(action.payload.userIds, action.payload.action)
    yield put(notifications.actions.showNotification({
      type: response.body.success ? 'success' : 'error',
      message: response.body.msg,
    }))
    if (response.body.success) {
      yield put(usersAdminActions.bulkManageRefreshIntervalSuccess())
      yield put(usersAdminActions.fetchUsers({filters, forceFetch: true}))
    }
  } catch(error) {
    yield* handleSagaError(error)
  }
}

export default function*() {
  yield all([
    takeLatest(
      usersAdminActions.fetchUser,
      handleFetchUser
    ),
    takeLatest(
      usersAdminActions.fetchUsers,
      handleFetchUsers
    ),
    takeLatest(
      usersAdminActions.fetchAllUsers,
      handleFetchAllUsers
    ),
    takeLatest(
      [
        usersAdminActions.changeCurrentPage,
        usersAdminActions.changeSort,
        usersAdminActions.changeIsFilter
      ],
      handleFetchUsers,
    ),
    takeLatest(
      usersAdminActions.fetchAvailableMembers,
      handleFetchAvailableMembers
    ),
    takeLatest(
      usersAdminActions.saveNewUser,
      handleSaveNewUser
    ),
    takeLatest(
      usersAdminActions.saveNewGroup,
      handleSaveNewGroup
    ),
    takeLatest(
      usersAdminActions.bulkPasswordReset,
      handleBulkPasswordReset
    ),
    takeLatest(
      usersAdminActions.bulkSendWelcomeEmail,
      handleBulkSendWelcomeEmail
    ),
    takeLatest(
      usersAdminActions.bulkSendRefreshEmail,
      handleBulkSendRefreshEmail
    ),
    takeLatest(
      usersAdminActions.bulkActivateAccounts,
      handleBulkActivateAccounts
    ),
    takeLatest(
      usersAdminActions.bulkEnableInsights,
      handleBulkEnableInsights
    ),
    takeLatest(
      usersAdminActions.saveUser,
      handleSaveUser
    ),
    takeLatest(
      usersAdminActions.deleteUsers,
      handleDeleteUsers
    ),
    takeLatest(
      usersAdminActions.fetchSearches,
      handleFetchSearches
    ),
    takeLatest(
      usersAdminActions.deleteSearches,
      handleDeleteSearches
    ),
    takeLatest(
      usersAdminActions.saveSearch,
      handleSaveSearch
    ),
    takeLatest(
      usersAdminActions.createSearch,
      handleCreateSearch
    ),
    takeLatest(
      usersAdminActions.createSource,
      handleCreateSource
    ),
    takeLatest(
      usersAdminActions.applyBulkSearchUpdates,
      handleApplyBulkSearchUpdate
    ),
    takeLatest(
      usersAdminActions.addUserToGroups,
      handleAddUserToGroups
    ),
    takeLatest(
      usersAdminActions.removeUserFromGroups,
      handleRemoveUserFromGroups
    ),
    takeLatest(
      usersAdminActions.addGroupToUsers,
      handleAddGroupToUsers
    ),
    takeLatest(
      usersAdminActions.removeGroupFromUsers,
      handleRemoveGroupFromUsers
    ),
    takeLatest(
      usersAdminActions.toggleMembershipIsManager,
      handleToggleMembershipIsManager
    ),
    takeLatest(
      usersAdminActions.refreshSummarySignup,
      handleRefreshSummarySignup
    ),
    takeLatest(
      usersAdminActions.makeSelectedGroupsPrivate,
      handleMakeSelectedGroupsPrivate
    ),
    takeLatest(
      usersAdminActions.getRecurringPublicationsData,
      handleRecurringPublication
    ),
    takeLatest(
      usersAdminActions.getTemplatePublicationsData,
      handleTemplatePublication
    ),
    takeLatest(
      usersAdminActions.saveBulkUsersEmailDeliverySettings,
      handleSaveEmailDelivery
    ),
    takeLatest(
      usersAdminActions.saveBulkNlaArticleViewingSettings,
      handleSaveBulkNlaArticleViewingSettings
    ),
    takeLatest(
      usersAdminActions.bulkManageRefreshInterval,
      handleBulkManageRefreshInterval
    ),
  ])
}
