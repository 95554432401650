import {changeCaseObject} from 'app/utils'
import React, { Component } from 'react'
import { render } from 'react-dom'
import { connect, Provider } from 'react-redux'
import store from 'app/store'
import './InsightsEmailSettings.less'
import * as actions from 'app/email-notifications/email-notifications-actions'
import InsightsFrequencySettings from
    "app/email-notifications/insights-frequency-settings/InsightsFrequencySettings"
import SettingsBulkUpdate from "app/email-notifications/settings-bulk-update/SettingsBulkUpdate"
import SendSampleEmail from "app/email-notifications/send-sample-email/SendSampleEmail"
import FactorsModal from "app/email-notifications/FactorsModal"
import * as globalSelectors from "app/global/global-selectors"
import {changeInsightsShowFactorsModal} from "app/email-notifications/email-notifications-actions";


@connect(
  state => ({
    insightsSettings: state.emailNotifications.insightsSettings,
    insightsNoticeConfigs: state.emailNotifications.insightsNoticeConfigs,
    factorsModalConfig: state.emailNotifications.factorsModalConfig,
    editUserId: state.emailNotifications.emailDeliveryData.editUserId,
    insightsLabel: globalSelectors.getInsightsLabel(state),
  }),
  {
    saveInsightsNoticeConfigs: actions.api.saveInsightsNoticeConfigs.request,
    changeFactorsModalConfig: actions.changeFactorsModalConfig,
    changeShowFactorsModal: actions.changeInsightsShowFactorsModal,
    changeShowBulkUpdateModal: actions.changeShowBulkUpdateModal,
    changeFrequencyBulkUpdateConfigs: actions.changeFrequencyBulkUpdateConfigs,
  },
)

export default class InsightsEmailSettings extends Component {

  render() {
    const factorsModal = this.props.insightsNoticeConfigs && this.props.factorsModalConfig
      ? <FactorsModal editUserId={this.props.editUserId}
                      factorsConfig={this.props.factorsModalConfig}
                      notificationType={this.props.insightsSettings.notificationType}
                      availableFactors={this.props.insightsNoticeConfigs.availableFactors}
                      showFactorsModal={this.props.insightsNoticeConfigs.showFactorsModal}
                      changeFactorsFunc={this.props.changeFactorsModalConfig}
                      changeShowModalFunc={this.props.changeShowFactorsModal}
                      saveConfigsFunc={this.props.saveInsightsNoticeConfigs}
                      changeShowBulkUpdateFunc={this.props.changeShowBulkUpdateModal}
                      changeBulkUpdateConfigsFunc={this.props.changeFrequencyBulkUpdateConfigs}
      />
      : null

    const insightsLabel = this.props.insightsLabel

    return (
      <div className={'signals-email-settings-container'}>
        <SettingsBulkUpdate notificationType={this.props.insightsSettings.notificationType}/>
        <SendSampleEmail notificationType={this.props.insightsSettings.notificationType}/>
        {factorsModal}
        <div>
          <div className={'help-text'}>
            {`The ${insightsLabel} Email Alert contains only articles that 
            have been flagged by ${insightsLabel}.
            Your ${insightsLabel} emails are grouped by how often you wish to receive an email -
            Daily, Weekly, or not at all (Do Not Send). You can change the frequency per-company
            or per-industry by interacting with the frequency dropdown selector in the frequency
            column.`}
          </div>

          <div className={'jump-links-container'}>
            <div className={'jump-links'}>
              Jump To:
              <a className={'frequency-jump-link'}
                 href={'#insights-daily'}>Daily</a>
              <span className={'link-divider'}>|</span>
              <a className={'frequency-jump-link'}
                 href={'#insights-weekly'}>Weekly</a>
              <span className={'link-divider'}>|</span>
              <a className={'frequency-jump-link'}
                 href={'#insights-none'}>Do Not Send</a>
            </div>

            <div className={'grouped-by-freq'}>
              Grouped By Frequency
            </div>
          </div>
          {this.getFrequencySection()}
        </div>
      </div>
    )
  }
  getFrequencySection() {
    if (!this.props.insightsSettings) return null
    return (
      this.props.insightsSettings.frequencies.map((freq, freqIdx)=>
        <InsightsFrequencySettings
          key={freqIdx}
          noticeFrequency={freq}
        />
      )
    )
  }
}

export function init(data) {
  const emailAlertSettings = changeCaseObject.camelCase(data)
  const element = document.getElementById(
    'django-insights-email-settings-container')
  if (!element) return

  store.dispatch(actions.insightsEmailSettingsInit(emailAlertSettings))

  const component =
    <Provider store={store}>
      <InsightsEmailSettings />
    </Provider>

  render(component, element)
}
