import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import {changeCaseObject} from 'app/utils'

import store from 'app/store'

import PendoStats from './PendoStats'
import { init as initPendoStats } from './pendo-stats-actions'
import * as constants from './pendo-stats-constants'
import reducer from './pendo-stats-reducer'
import saga from './pendo-stats-saga'


export function init(initialState) {
  store.dispatch(initPendoStats(changeCaseObject.camelCase(initialState)))
  const component = (
    <Provider store={store}>
      <PendoStats/>
    </Provider>
  )
  const container = document.getElementById('pendo-stats-wrapper')
  render(component, container)
}

export {constants, reducer, saga}
