import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import Highlighter from 'react-highlight-words'
import request from 'superagent'

import './style.less'


/**
 * generic autocomplete component.
 * for saved search autocomplete, use app/common/SavedSearchInput.
 */
export default class AutocompleteInput extends Component {
  static propTypes = {
    url: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.object,
    maxValues: PropTypes.number,
    allowFreeText: PropTypes.bool,
  }

  static defaultProps = {
    allowFreeText: true,
  }

  state = {
    // Note: The value of the actual input element isn't synced with
    // this value. In order to change the input, you must currently
    // manually change this.input.value. This is due to a React bug
    // that affects IE11: https://github.com/facebook/react/issues/7027
    inputValue: '',
    values: [],

    // The currently highlighted option (either with the mouse or the
    // arrow keys)
    highlightedOption: null,

    // The options to show in the autocomplete menu
    options: [],

    // Whether the menu can be shown. Note that this won't do
    // anything if the `options` array is empty.
    canShowMenu: false,
  }

  // Public methods

  get values() {
    return this.state.values
  }

  get hasUntokenizedText() {
    return !!this.state.inputValue
  }

  /*
   * If there is any remaining free text in the input, turn it into a
   * "free text" token.
   */
  tokenizeFreeText() {
    if (this.props.allowFreeText && this.hasUntokenizedText) {
      this.selectOption(this.freeTextOption(this.state.inputValue))
    }
  }

  clear() {
    this.setState(prevState => ({...prevState, inputValue: '', values: [], options: []}))
    this.input.value = ''
  }

  // React methods

  render() {
    const {highlightedOption} = this.state

    const values = this.state.values.map((value, index) => {
      const valueRemoved = () => {
        this.removeValue(index)
      }
      return (
        <AutocompleteInputValue
          value={value}
          onRemove={valueRemoved}
          key={index}
        />
      )
    })

    const options = this.state.options.map(option => {
      const highlighted =
        !!(highlightedOption && option.value === highlightedOption.value)

      return (
        <AutocompleteInputOption
          option={option}
          input={this.state.inputValue}
          highlighted={highlighted}
          onClick={this.selectOption}
          onHover={this.highlightOption}
          key={option.value}
        />
      )
    })
    const optionsDisplay =
      this.state.options.length && this.state.canShowMenu ? 'block' : 'none'

    const inputDisplay = (this.state.values.length? 'none' : 'inline')

    return (
      <div className="autocomplete-container" onKeyDown={this.keyPressed}>
        <div className="autocomplete-input" onClick={this.containerClicked}>
          <span className="values">
            {values}
          </span>

          <input
            id="autocomplete-input"
            className="input"
            onChange={this.inputChanged}
            onFocus={this.showMenu}
            style={{display: inputDisplay}}
            ref={ref => this.input = ref}
          />

          <div className="options" style={{display: optionsDisplay}}>
            {options}
          </div>
        </div>
      </div>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const curState = this.state

    // If the input value changes, we need to decide whether to show
    // new options
    if (curState.inputValue !== prevState.inputValue) {
      if (curState.inputValue) {
        // If there is a value in the input, fetch the options
        this.fetchOptions()
      } else {
        // If the input is empty, remove the options
        this.setState({options: []})
      }
    }

    // If the selected values have changed, trigger an onChange
    if (curState.values !== prevState.values && this.props.onChange) {
      this.props.onChange(curState.values)
    }
  }

  // State management

  fetchOptions() {
    const input = this.state.inputValue
    const {highlightedOption} = this.state

    request
      .get(this.props.url)
      .query({q: input})
      .query(this.props.options)
      .end((err, res) => {
        if (err) {
          // TODO: Show an actual error message to the user
          console.error('*** fetchOptions error', err)
        } else {
          if (input !== this.state.inputValue) {
            return
          }
          const options = res.body.map(obj => {
            return {
              label: obj.label,
              value: obj.value,
              searchPhrases: (obj.search_phrases || ''),
              type: 'user',
            }
          })
          const freeTextOption = this.freeTextOption(input)
          if (this.props.allowFreeText) {
            options.push(freeTextOption)
          }

          // Reset the currently highlighted option if it's not one of
          // the options displayed. This avoids weird behavior like
          // pressing Enter selecting an invisible option.
          const newHighlightedOption =
            highlightedOption
              && options.find(option =>
                option.value === highlightedOption.value
              )
              ? highlightedOption
              : null

          this.setState({
            options: options,
            highlightedOption: newHighlightedOption,
          })
          this.showMenu()
        }
      })
  }

  showMenu = () => {
    // Note: This does not guarantee that the menu will actually
    // appear! If there are no options, the menu will still not show.
    this.setState({canShowMenu: true})
    document.addEventListener('click', this.handleGlobalClick)
  }

  hideMenu() {
    this.setState({canShowMenu: false})
    document.removeEventListener('click', this.handleGlobalClick)
  }

  addValue(value) {
    this.setState(prevState => ({...prevState, values: [...prevState.values, value]}))
  }

  removeValue(index) {
    const newValues = [...this.state.values]
    newValues.splice(index, 1)
    this.setState({values: newValues})
  }

  selectOption = (option) => {
    // wrap numeric free text search values in quotes to search
    // for the literal number rather than searching by id.
    // Changing here as the app relies on id logic for filters.
    if (isNaN(option.value) !== true && option.isFreeText){
      option.value = "'" + option.value + "'"
    }
    this.addValue(option)
    this.setState({
      inputValue: '',
      highlightedOption: null,
    })
    this.input.value = ''
    this.input.focus()
  }

  highlightOption = (option) => {
    this.setState({highlightedOption: option})
  }

  hasHitMaxValuesLimit() {
    const {maxValues} = this.props
    return maxValues && this.state.values.length === maxValues
  }

  // Event handlers

  containerClicked = (event) => {
    this.input.focus()
  }

  inputChanged = () => {
    this.setState({inputValue: this.input.value.trim()})
  }

  keyPressed = (event) => {
    const {key} = event
    const {highlightedOption, inputValue, options, values} = this.state

    if (key == 'ArrowUp' || key == 'ArrowDown') {
      const offset = key == 'ArrowUp' ? -1 : 1
      const highlightedIndex =
        highlightedOption
          ? options.findIndex(option => {
            return option.value === highlightedOption.value
          })
          : -1
      let newIndex = highlightedIndex + offset
      if (newIndex < 0) {
        newIndex = 0
      } else if (newIndex >= options.length) {
        newIndex = options.length - 1
      }
      const newHighlightedOption = options[newIndex]
      this.highlightOption(newHighlightedOption)
    }

    else if (['Enter', 'Tab', ','].includes(key)) {
      if (highlightedOption && key != ',') {
        this.selectOption(highlightedOption)
        // We don't want the default behavior of either the Enter
        // (submit) or Tab (lose focus) keys
        event.preventDefault()
      } else if (inputValue && ['Enter', ','].includes(key)) {
        this.tokenizeFreeText()
        event.preventDefault()
      } else if (key == ',') {
        // Commas aren't allowed in searches, so we always prevent them
        event.preventDefault()
      }
    }

    else if (key == 'Backspace') {
      const {selectionStart, selectionEnd} = this.input
      if (selectionStart === 0 && selectionEnd === 0 && values.length) {
        this.removeValue(values.length - 1)
      }
    }

    else if (this.hasHitMaxValuesLimit()) {
      // If we've hit our max values limit, don't allow any more typing
      event.preventDefault()
    }
  }

  handleGlobalClick = (e) => {
    // Hide the menu if the user clicks outside of it
    const rootNode = findDOMNode(this)
    if (!rootNode.contains(e.target)) {
      this.hideMenu()
    }
  }

  // Helpers

  freeTextOption(text) {
    return {
      label: text,
      value: text,
      isFreeText: true,
    }
  }
}


class AutocompleteInputValue extends Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
    onRemove: PropTypes.func,
  }

  render() {
    const {value} = this.props
    return (
      <span
        className={
          classNames('value', {'free-text': value.isFreeText})
        }
      >
        <span className="name">{value.label}</span>
        <span className="remove" onClick={this.remove}>x</span>
      </span>
    )
  }

  remove = () => {
    if (this.props.onRemove) {
      this.props.onRemove(this.props.value)
    }
  }
}


class AutocompleteInputOption extends Component {
  static propTypes = {
    input: PropTypes.string.isRequired,
    option: PropTypes.object.isRequired,
    highlighted: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    onHover: PropTypes.func,
  }

  render() {
    const {input, option, highlighted} = this.props

    const searchTerms =
      option.isFreeText
        ? `Search for the free text "${option.label}"`
        : (
          <Highlighter
            searchWords={[input]}
            textToHighlight={option.searchPhrases}
            autoEscape={true}
          />
        )

    return (
      <div
        className={classNames('option', 'ss-auto-instance', {highlighted})}
        onClick={e => this.clicked(option, e)}
        onMouseMove={e => this.hovered(option)}
      >
        <div className="hd font-size-s">
          <strong>{option.label}</strong>
        </div>
        <div className="hd font-size-xs">
          {searchTerms}
        </div>
      </div>
    )
  }

  clicked(option, event) {
    event.stopPropagation()
    if (this.props.onClick) {
      this.props.onClick(option)
    }
  }

  hovered(option) {
    if (this.props.onHover) {
      this.props.onHover(option)
    }
  }
}
