import React, { Component } from 'react'
import { connect } from 'react-redux'

import { TabbedContainer, Tab } from 'app/common/TabbedContainer'
import * as routingActions from 'app/global/routing/routing-actions'

/**
 * this whole page is named `Advanced Search` but also contains `Search Lookup` and `Boolean Search`.
 * so this component (`AdvancedSearch`) is the container for all of that.
 * `FilteredSearch` is the component for the advanced search functionality specifically.
 */
import FilteredSearch from './filtered-search/FilteredSearch'
import SearchLookup from './search-lookup/SearchLookup'
import BooleanSearch from './boolean-search/BooleanSearch'

import './AdvancedSearch.less'


@connect(
  undefined,
  {
    pushLocation: routingActions.pushLocation,
  }
)
export default class AdvancedSearch extends Component {
  state = {
    defaultTab: 'advanced',
  }

  constructor(props) {
    super(props)
    const params = new URLSearchParams(window.location.search)
    if (params.has('tab')) {
      this.state.defaultTab = params.get('tab')
    }
  }

  render() {
    return (
      <div id="advanced-search">
        <TabbedContainer
          defaultTabName={this.state.defaultTab}
          onTabChange={(tabName) => this.tabChanged(tabName)}
          useUpdatedCss
        >
          <Tab name="advanced" label="Advanced Search">
            {() =>
              <div className="search-method">
                <FilteredSearch/>
              </div>
            }
          </Tab>

          <Tab name="lookup" label="Search Lookup">
            {() =>
              <div className="search-method profile-base">
                <SearchLookup/>
              </div>
            }
          </Tab>

          <Tab name="boolean" label="Boolean Search">
            {() =>
              <div className="search-method">
                <BooleanSearch/>
              </div>
            }
          </Tab>
        </TabbedContainer>
      </div>
    )
  }

  tabChanged(tabName) {
    this.props.pushLocation({query: {tab: tabName}})
  }
}
