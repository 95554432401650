import React from 'react'

const scrollTargetIds = {
  industry: 'esg-industry',
  company: 'esg-company',
}

// pass the navConfig -> identifier value to the EsgNavbar component's activeIdentifier prop
// to make the specific button active on a specific page
const navConfig = [
  {
    displayName: 'ESG Industry',
    path: `/#${scrollTargetIds.industry}`,
    identifier: 'industry',
  },
  {
    displayName: 'ESG Company',
    path: `/#${scrollTargetIds.company}`,
    identifier: 'company',
  },
]

export { navConfig, scrollTargetIds }
