import is from 'is'

import * as entitiesSelectors from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import {Document, SavedSearch} from 'app/models'
import {consolidateSearchFilters} from 'app/utils/searches'

import {STATE_PROP_NAME} from './search-results-page-constants'

export const getSearchResultsState = state => state[STATE_PROP_NAME]
export const getUserDefaultEmailSettings = state => getSearchResultsState(state).userDefaultEmailSettings
export const getSearchId = state => getSearchResultsState(state).searchId
export const getFeedId = state => getSearchResultsState(state).feedId
export const getQuery = state => getSearchResultsState(state).query
export const getQueryType = state => getSearchResultsState(state).queryType
export const getSearchName = state => getSearchResultsState(state).searchName
export const getTopLevelDocumentIds = state =>
  getSearchResultsState(state).topLevelDocumentIds
export const getGroupedDocumentIds = state =>
  getSearchResultsState(state).groupedDocumentIds
export const getCompareTopLevelDocumentIds = state =>
  getSearchResultsState(state).compareTopLevelDocumentIds
export const getCompareGroupedDocumentIds = state =>
  getSearchResultsState(state).compareGroupedDocumentIds
export const getPublicationCounts = state =>
  getSearchResultsState(state).publicationCounts
export const getSortOption = state => getSearchResultsState(state).sortOption
export const getInsightsArticlesFirst = state =>
  getSearchResultsState(state).insightsArticlesFirst
export const getCompareId = state =>
  getSearchResultsState(state).compareId
export const getUpcomingEvents = state =>
  getSearchResultsState(state).upcomingEvents
export const getTimeFrame = state => getSearchResultsState(state).timeFrame
export const getCurrentPage = state => getSearchResultsState(state).page
export const getActivePublicationTab =
  state => getSearchResultsState(state).activePublicationTab
export const getActivePublicationType =
  state => getSearchResultsState(state).activePublicationType
export const getRelevancyLevel =
  state => getSearchResultsState(state).relevancyLevel
export const getGroupingLevel =
  state => getSearchResultsState(state).groupingLevel
export const getLanguageFilters =
  state => getSearchResultsState(state).languageFilters
export const getSelectedLanguageIds =
  state => getSearchResultsState(state).selectedLanguageIds
export const getSelectedDocumentIds =
  state => getSearchResultsState(state).selectedDocumentIds
export const getAppliedFilterKey =
  state => getSearchResultsState(state).appliedFilterKey
export const getTermFrequencyKey =
  state => getSearchResultsState(state).termFrequencyKey
export const getIsRefreshingResults =
  state => getSearchResultsState(state).isRefreshingResults
export const getNewSearchModalData =
  state => getSearchResultsState(state).newSearchModalData
export const getHasFetchedRelevanceIds =
  state => getSearchResultsState(state).hasFetchedRelevanceIds
export const getShouldShowQueryComponents =
  state => getSearchResultsState(state).shouldShowQueryComponents
export const getShouldShowFullQuery =
  state => getSearchResultsState(state).shouldShowFullQuery
export const getCachedLargerTimeFrames =
  state => getSearchResultsState(state).cachedLargerTimeFrames
export const getIsEditSearchFiltersModalOpen =
  state => getSearchResultsState(state).isEditSearchFiltersModalOpen
export const getSaveData =
  state => getSearchResultsState(state).saveData
export const getResultsPerPage =
  state => getSearchResultsState(state).resultsPerPage

// Trending

export const getIsTrendingOpen =
  state => getSearchResultsState(state).trending.isOpen
export const getTrendingData =
  state => getSearchResultsState(state).trending.data

// Filters

export const getUnappliedFilters =
  state => getSearchResultsState(state).unappliedFilters
export const getRemovedFilters =
  state => getSearchResultsState(state).removedFilters
export const getActiveFilters = state => {
  const search = getSearch(state)
  if (!search) {
    return []
  }
  const unappliedFilters = getUnappliedFilters(state)
  const removedFilters = getRemovedFilters(state)
  return consolidateSearchFilters(search, unappliedFilters, removedFilters)
}
export const getHasUnsavedChanges = state => !!(
  getAppliedFilterKey(state)
  || getTermFrequencyKey(state)
  || getQuery(state) !== null
  || getRelevancyLevel(state)
  || getSortOption(state)
  || getGroupingLevel(state)
  || getLanguageFilters(state)
  // Custom date ranges are not supported, hence the string check.
  || is.string(getTimeFrame(state))
)
export const getCollapsedFilterSections = state =>
  getSearchResultsState(state).collapsedFilterSections

// Entity selectors

export const getSearch = state => {
  const searchId = getSearchId(state) || -1
  const entities = entitiesSelectors.getEntities(state)
  const orm = Orm.withEntities(entities)
  return orm.getById(SavedSearch, searchId)
}
export const getTopLevelDocuments = state => {
  const documentIds = getTopLevelDocumentIds(state)
  const entities = entitiesSelectors.getEntities(state)
  const orm = Orm.withEntities(entities)
  return orm.getByIds(Document, documentIds)
}

// ESG
export const getEsgData = state => getSearchResultsState(state).esgData
export const getEsgActiveFilterApplied = state =>
  getSearchResultsState(state).esgActiveFilterApplied
