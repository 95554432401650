import React from 'react'
import DiligentSection from './DiligentSection'
import ESGSection from './ESGSection'

import './style.less'
import { useSelector } from 'react-redux'
import * as selectors from '../esg-selectors'

const diligentSectionPosts = [
    {
        title: "Boards and Climate: 3 Imperatives from Global Business Leaders",
        topic: "ESG",
        publishedAt: "December 9th, 2021",
        link: "https://www.diligent.com/insights/esg/climate-imperatives-from-global-business-leaders/",
        snippet: `Many organizations are starting to connect the dots between ESG strategies and access to capital. 
        This is driven by growing consumer and employee preferences for companies to make a positive impact. 
        There’s also the multifaceted connection between climate action and business risk. Moynihan cited research linking 
        poor ESG metrics to poor company performance.`,
    },
    {
        title: "Measuring Stakeholder Capitalism Webinar – Part 2",
        topic: "ESG",
        publishedAt: "December 8th, 2021",
        link: "https://www.diligent.com/insights/esg/measuring-stakeholder-capitalism-webinar-part-2/",
        snippet: `As a follow-up to the initial Measuring Stakeholder Capitalism virtual event, Diligent 
        hosted an event on October 7, 2020 on taking action on ESG and how companies can adopt new market-led 
        metrics. Several CEOs and leaders behind the World Economic Forum’s new ESG metrics discussed the steps 
        organizations of all sizes and industries can take to truly operationalize ESG.`,
    },
    {
        title: "Meeting Climate Reporting Expectations: A Roadmap to Robotic Process Automation",
        topic: "ESG",
        publishedAt: "December 8th, 2021",
        link: "https://www.diligent.com/insights/white-paper/esg/climate-reporting-expectations-robotic-process-automation/",
        snippet: `Recent announcements by the U.S. Securities and Exchange Commission (SEC) and the Group of Seven (G7) indicate that 
        climate-reporting measures are under active consideration. These upcoming climate disclosures will be mandatory, not voluntary, 
        and they will likely cover both qualitative and quantitative information.`,
    },
    {
        title: "Integrating Net-Zero Commitments To Integrated Risk Management",
        topic: "ESG",
        publishedAt: "December 6th, 2021",
        link: "https://www.diligent.com/insights/esg/integrating-net-zero-commitments-to-integrated-risk-management/",
        snippet: `Everyone is talking about net-zero, and net-zero commitments are proliferating in the business community, 
        especially since the COP26 summit. The summit threw a spotlight on corporate carbon footprints and the need to reduce them, 
        with a rising tide of ESG-related expectations, requirements and regulations driving action. What does this mean in practice, 
        though? Net-zero ambitions are great, but how do they translate into your organization’s strategy and operations?`,
    },
    {
        title: "How to Measure Greenhouse Gas Emissions: Everything You Need To Know",
        topic: "ESG",
        publishedAt: "December 2nd, 2021",
        link: "https://www.diligent.com/insights/esg/how-to-measure-greenhouse-gas-emissions/",
        snippet: `As pressure to tackle climate change intensifies, so does scrutiny of the actions we’re 
        taking to reduce our carbon footprint. On a corporate level, this plays out in many ways. For instance, 
        organizations are exploring how to measure greenhouse gas emissions, minimize their corporate impact, and 
        manage and report on their ESG performance overall.`,
    },
]

const esgSectionPosts = [
    {
        title: "Diligent ESG Solutions",
        link: "https://www.diligent.com/products/diligent-esg",
        snippet: `As organizations adopt environmental, social and governance (ESG) 
        principles, there is no clear way to measure and track their ESG goals. 
        Diligent solutions help organizations comply with ESG standards and 
        regulations, evaluate risk controls, benchmark governance practices, 
        and educate leadership on new frameworks and developments.`,
    },
    {
        title: "Manzama’s ESG News Analysis",
        link: "https://www.diligent.com/products/diligent-esg",
        snippet: `The Big Four accounting firms have backed the creation of the World Economic Forum’s 
        International Business Council set of metrics for ESG. These 50 or so metrics are 
        grouped into 4 major pillars: Planet, People, Governance and Prosperity. 
        Our patent-pending AI technology classifies news about this company into 
        these pillars (and their subtopics) as well as indicating whether it’s good 
        news or bad news in order to create an ESG Health Score ranging from -10 to 10. 
        Industry level stories are from the last 30-days and Corporation levels stories are 
        from the last 365 days.   Corporations are generally limited to public companies only.`,
    },
]

function Tutorials() {
    const diligentSectionPosts = useSelector(selectors.getSidebarContent);
    const isLoading = useSelector(selectors.getIsLoading);
    return (
        <div className='esg-tutorials'>
            <header>
                <h1>Diligent Insights</h1>
            </header>
            <DiligentSection posts={diligentSectionPosts} isLoading={isLoading}/>
            <ESGSection posts={esgSectionPosts}/>
        </div>
    )
}

export default Tutorials
