import { changeCaseObject } from 'app/utils'
import React from 'react'
import { render } from 'react-dom'
import AppWrapper from 'app/global/app-wrapper'
import store from 'app/store'
import ProfileRefresh from './ProfileRefresh'
import * as constants from './profile-refresh-constants'
import * as actions from './profile-refresh-actions'
import reducer from './profile-refresh-reducer'
import saga from './profile-refresh-saga'
import './ProfileRefresh.less'


export function init(data) {
  // Add the data from this page to the Redux store
  store.dispatch(actions.init(changeCaseObject.camelCase(data.result)))
  const component = (
    <AppWrapper store={store}>
      <ProfileRefresh/>
    </AppWrapper>
  )
  const container = document.getElementById('profile-refresh-wrapper')
  render(component, container)
}

export {constants, reducer, saga}
