import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import { getAdvancedSearch } from '../advanced-search-selectors'
import { getSearchResults, shouldShowTermFrequencyFilter } from
    'app/advanced-search/advanced-search-actions'

import LoadingOverlay from 'app/common/LoadingOverlay'
import TextBox from 'app/common/TextBox'
import classNames from 'classnames'
import Button from 'app/common/Button'

import './BooleanSearch.less'
import TermFrequencyFilter from
    "app/advanced-search/term-frequency-filter/TermFrequencyFIlter"


@connect(
  createSelector(
    [getAdvancedSearch],
    (advancedSearch) => {
      return {
        isLoading: advancedSearch.isLoading,
        isTermFrequencyFilterExpanded: advancedSearch.termFrequency.isTermFrequencyFilterExpanded,
        pendingTermFrequencyFilters: advancedSearch.pendingTermFrequency.filters,
      }
    }
  ),
  {
    getSearchResults,
    shouldShowTermFrequencyFilter
  }
)
export default class BooleanSearch extends Component {
  state = {
    query: '',
  }

  render() {
    const loader =
      this.props.isLoading
      ? <LoadingOverlay/>
      : null

    const {isTermFrequencyFilterExpanded, pendingTermFrequencyFilters} = this.props
    const termFrequencyFilterLabel = pendingTermFrequencyFilters.length === 1
      ? `Term Frequency Filter (${pendingTermFrequencyFilters.length})`
      : pendingTermFrequencyFilters.length > 1
        ? `Term Frequency Filters (${pendingTermFrequencyFilters.length})`
        : 'Term Frequency Filter'

    return (
      <div id="boolean-search">
        {loader}

        <div className="input-group">
          <TextBox
            multiline
            onChange={evt => this.handleQueryChange(evt)}
          />
        </div>

        <div className={'action-items'}>
          <div className={'first-row'}>
            <div>
              <a
                href="https://storage.googleapis.com/manzama-marketing/Intranet/Client-Success/Quick-Reference-Guids/MZ-QRG-Advanced-Search-Boolean.pdf"
                style={{paddingLeft: '15px'}} target="_blank" className="download-link">Quick Reference (PDF)</a>
            </div>

            <div className={classNames('add-term-frequency-container')}
              onClick={() => this.handleAddTermFrequencyOnClick()}
            >
              <span
                className={classNames('expand-arrow', {'collapsed': !isTermFrequencyFilterExpanded})}
              />
                <a className="add-term-freq">{termFrequencyFilterLabel}</a>
            </div>
          </div>
          {isTermFrequencyFilterExpanded && (
            <div className={'term-frequency-container'}>
              <TermFrequencyFilter/>
            </div>
          )}
          <div className={'second-row'}>
            <div className={'right-items'}>
              <Button
                disabled={!this.state.query}
                label={'Search'}
                isPrimary={true}
                className="run-boolean-search"
                onClick={() => this.runSearch()}
              />
            </div>
          </div>

        </div>

        <div className="how-to">
          <p className="intro">Boolean searches rely on user provided words, phrases and operators to help create
            searches
            that are highly targeted and precise.</p>

          <p>Boolean operators are case-sensitive. Also, the operators work in the following order of priority:</p>

          <ol>
            <li>OR</li>
            <li>AND</li>
            <li>AND NOT</li>
          </ol>

          <table>
            <tbody>
            <tr>
              <td>
                <span className="operator">EXACT WORD</span>
                <span
                  className="description">If you would like to search for an exact word. No quotation mark needed.</span>
              </td>

              <td className="example">
                <span>privatisation</span>
                <span>biodiversity OR farming</span>
              </td>
            </tr>

            <tr>
              <td>
                <span className="operator">PHRASE</span>
                <span className="description">If you would like to search for a phrase.</span>
              </td>

              <td className="example">
                <span>"intellectual property"</span>
                <span>"Brad Smith" AND Intuit</span>
                <span>"truth in lending act" OR "foreign corrupt practices act"</span>
              </td>
            </tr>

            <tr>
              <td>
                <span className="operator">STEMMING</span>
                <span className="description">If you would like to find various versions of a word.<br/><br/>NOTE: Stemming is US English sensitive. For UK English spelling, please search for that specific spelling</span>
              </td>

              <td className="example">
                <span>stem:fund (finds fund, funding, funder, funds, or funded)</span>
                <span>stem:privatization (finds private, privatize, privatized, privatizing, privatizer, privatization)</span>
              </td>
            </tr>

            <tr>
              <td>
                <span className="operator">AND</span>
                <span className="description">If you would like to narrow the search to two or more terms.</span>
              </td>

              <td className="example">
                <span>Bank AND "financial institution"</span>
                <span>Patent AND copyright AND trademark</span>
              </td>
            </tr>

            <tr>
              <td>
                <span className="operator">OR</span>
                <span
                  className="description">If you would like to broaden your search to include two or more terms.</span>
              </td>

              <td className="example">
                <span>FCPA OR "foreign corrupt practices act"</span>
                <span>Doctor OR physician OR "medical professional"</span>
                <span>Partner OR lawyer OR associate</span>
              </td>
            </tr>

            <tr>
              <td>
                <span className="operator">EXCLUDE</span>
                <span className="description">If you would like exclude results containing that term.</span>
              </td>

              <td className="example">
                <span>Bank AND NOT "snow bank"</span>
                <span>Apple AND NOT ("apple tree" OR "apple pie")</span>
              </td>
            </tr>

            <tr>
              <td>
                <span className="operator">PARENTHESES</span>
                <span className="description">If you would like to do a complex searching, you can mix and match terms and operators using parentheses. Operators inside the parentheses have sequential priority over operators outside parentheses.</span>
              </td>

              <td className="example">
                <span>(student OR college OR "education loan") AND (bank OR "financial institution")</span>
                <span>(("student loan" AND default) OR ("load default" AND student)) AND (university OR college OR "higher education") AND NOT ("for-profit" OR "foreign university")</span>
              </td>
            </tr>

            <tr>
              <td>
                <span className="operator">HEADLINE &amp; LEAD</span>
                <span
                  className="description">If you want your terms to appear in the headline or first 100 words. <strong>Stemming</strong> operator cannot be intermixed with this search.</span>
              </td>

              <td className="example">
                <span>hlead:(Tesla)</span>
                <span>hlead:(Tesla AND "Home Battery")</span>
                <span>hlead:Tesla AND hlead:SpaceX</span>
                <span>headline:privati?ation AND headline:pass*</span>
              </td>
            </tr>

            <tr>
              <td>
                <span className="operator">HEADLINE ONLY</span>
                <span
                  className="description">If you want your terms to appear in the headline only. <strong>Stemming</strong> operator cannot be intermixed with this search.</span>
              </td>

              <td className="example">
                <span>headline:(Tesla AND SpaceX)</span>
                <span>headline:Tesla</span>
                <span>headline:Tesla AND headline:"home battery"</span>
                <span>headline:privati?ation AND headline:pass*</span>
              </td>
            </tr>

            <tr>
              <td>
                <span className="operator">PROXIMITY</span>
                <span className="description">
                  If you would like to ensure that your words are within proximate words to each other.
                </span>
              </td>

              <td className="example">
                <span>"Pfizer merger"~10 (finds Pfizer and merger w/in 10 words)</span>
                <span>"iPhone launched quietly"~10 (finds the words iPhone, launched, and quietly w/in 10 words of each other)</span>
                <span>text:"Pfizer acquir"~100 (finds Pfizer and the stemmed or truncated word of acquir (ie. acquire/acquires/acquired/acquiring etc) within 100 words of each other)</span>
                <span>text:"private comp"~10 (finds private and the stemmed or truncated word of comp (ie. company/companies/compare/comparison/compensation/etc.) within 10 words of each other)</span>
                <span>text:"AI fund”~10  (finds AI and the stemmed or truncated word of fund (ie. funding/funder/funds/funded) within 10 words of each other)</span>                
              </td>
            </tr>

            <tr>
              <td>
                <span className="operator">PROXIMITY WITH PHRASES</span>
                <span className="description">
                  For searches involving a phrase, use a combination of <strong>Proximity</strong> and <strong>Phrase</strong> operators.
                </span>
              </td>

              <td className="example">
                <span>"general electric wind power"~10 AND "General Electric" AND "wind power" (finds General Electric and wind power w/in 10 words)</span>
              </td>
            </tr>

            <tr>
              <td>
                <span className="operator">WILDCARD</span>
                <span className="description">If you would like to replace a specific character for a variable character. Multiple wildcards operators within a single word is not supported.</span>
              </td>

              <td className="example">
                <span>wom?n (finds woman or women)</span>
                <span>privati?ation (finds privatization and privatisation)</span>
              </td>
            </tr>

            <tr>
              <td>
                <span className="operator">TRUNCATING</span>
                <span className="description">If you would like to truncate a word at that specific location in a word and find variations of that word.</span>
              </td>

              <td className="example">
                <span>privati* (finds privatize, privatise, privatized, privatized, privatizing, privatising, privatization, privatisation BUT NOT private)</span>
                <span>epst* (finds Epstein and Epstien)</span>
              </td>
            </tr>

            <tr>
              <td>
                <span className="operator">CASE SENSITIVE</span>
                <span className="description">If you would like to ensure your search term or phrase is only returned in a specific case-sensitive format. For Instance, the first letter must be Capitalized.</span>
              </td>

              <td className="example">
                <span>content_exact_case:(IoT)</span>
                <span>content_exact_case:("London Partners")</span>
                <span>content_exact_case:(Boots OR Next)</span>
              </td>
            </tr>
            </tbody>
          </table>

          <a href="#page" className="toplink">Return to top</a>
        </div>
      </div>
    )
  }

  handleQueryChange(evt) {
    this.setState({query: evt.target.value})
  }

  runSearch() {
    const data = {
      queryType: 'raw',
      query: this.state.query,
    }
    this.props.getSearchResults(data)
  }

  handleAddTermFrequencyOnClick() {
    this.props.shouldShowTermFrequencyFilter(!this.props.isTermFrequencyFilterExpanded)
  }
}
