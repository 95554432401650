import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import './style.less'


/**
 * Represents a form section consisting of a label and an input. Meant
 * to be used in place of <label> HTML elements.
 *
 * @param {String} label - The text label
 * @param {Boolean} isInline - Whether the input is an inline element (like a checkbox)
 */
export default class InputBlock extends Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    isInline: PropTypes.bool,

    /**
     * The element type to use for wrapping the input block. Can be a string
     * representing an HTML element or a component. Default is "label".
     */
    wrapperElement: PropTypes.elementType,
  }

  static defaultProps = {
    isInline: false,
    wrapperElement: 'label',
  }

  render() {
    const {
      label,
      description,
      isInline,
      wrapperElement: WrapperElement,
      children,
      className,
      ...restProps
    } = this.props
    return (
      <WrapperElement
        className={classNames(
          'input-block',
          className,
          {inline: isInline},
        )}
        {...restProps}
      >
        {isInline ? children : null}
        <span className="label">{label}</span>
        {isInline ? null : children}
        {description && (
          <div className="description">
            {description}
          </div>
        )}
      </WrapperElement>
    )
  }
}
