import { handleActions } from 'redux-actions'

import { STATE_PROP_NAME as SEARCH_LOOKUP_STATE_PROP_NAME } from './search-lookup/search-lookup-constants'
import * as actions from './advanced-search-actions'
import * as searchLookupActions from './search-lookup/search-lookup-actions'

const getInitialState = () => ({
  isLoading: false,

  [SEARCH_LOOKUP_STATE_PROP_NAME]: {
    ...getInitialSearchLookupState(),
  },
  'termFrequency': {
    ...getInitialTermFrequencyState(),
  },
  'pendingTermFrequency': {
    ...getInitialPendingTermFrequencyState(),
  }
})

const getInitialTermFrequencyState = () => ({
  isTermFrequencyFilterExpanded: false,
  termArray: [],
  frequency: null,
  targetField: null,
})

const getInitialPendingTermFrequencyState = () => ({
  filters: [],
})

const getInitialSearchLookupState = () => ({
  // view = 'start', 'search-results', or 'browse-results'
  view: 'start',
  isLoading: false,
  searchKeywords: '',
  // by default all search categories are selected
  selectedSearchCategories: [
    'agency',
    'client',
    'court',
    'filter',
    'firm',
    'industry',
    'practice',
    'state',
    'tracker',
  ],
  selectedSearchAuthors: [
    'manzama',
    'firm-library',
  ],
  selectedBrowseCategory: 'agency',
  nameMatchOnly: false,
  exactMatch: true,
  searches: [],
  sortField: 'name',
  sortDirection: 'asc',
  total: 0,
  searchResultsListCurrentPage: 1,
  selectedSearchIds: [],
  searchResultsStartsWithFilter: null,
  searchResultsNameFilter: '',
  queryModalData: {
    isOpen: false,
  }
})

export default handleActions(
  {
    [actions.getSearchResults]: (state, action) => ({
      ...state,
      isLoading: true,
    }),

    [actions.hideLoader]: (state, action) => ({
      ...state,
      isLoading: false,
    }),

    // search lookup actions
    [searchLookupActions.setSearchKeywords]: (state, action) => ({
      ...state,
      [SEARCH_LOOKUP_STATE_PROP_NAME]: {
        ...state[SEARCH_LOOKUP_STATE_PROP_NAME],
        searchKeywords: action.payload,
      }
    }),
    [searchLookupActions.setSelectedBrowseCategory]: (state, action) => ({
      ...state,
      [SEARCH_LOOKUP_STATE_PROP_NAME]: {
        ...state[SEARCH_LOOKUP_STATE_PROP_NAME],
        selectedBrowseCategory: action.payload,
      }
    }),
    [searchLookupActions.setSelectedSearchCategories]: (state, action) => ({
      ...state,
      [SEARCH_LOOKUP_STATE_PROP_NAME]: {
        ...state[SEARCH_LOOKUP_STATE_PROP_NAME],
        selectedSearchCategories: action.payload,
      }
    }),
    [searchLookupActions.setNameMatchOnly]: (state, action) => ({
      ...state,
      [SEARCH_LOOKUP_STATE_PROP_NAME]: {
        ...state[SEARCH_LOOKUP_STATE_PROP_NAME],
        nameMatchOnly: action.payload,
      }
    }),
    [searchLookupActions.setExactMatch]: (state, action) => ({
      ...state,
      [SEARCH_LOOKUP_STATE_PROP_NAME]: {
        ...state[SEARCH_LOOKUP_STATE_PROP_NAME],
        exactMatch: action.payload,
      }
    }),
    [searchLookupActions.setSelectedSearchIds]: (state, action) => ({
      ...state,
      [SEARCH_LOOKUP_STATE_PROP_NAME]: {
        ...state[SEARCH_LOOKUP_STATE_PROP_NAME],
        selectedSearchIds: action.payload,
      }
    }),
    [searchLookupActions.resetSearch]: (state, action) => ({
      ...state,
      [SEARCH_LOOKUP_STATE_PROP_NAME]: {
        ...getInitialSearchLookupState(),
      }
    }),
    [searchLookupActions.setQueryModalData]: (state, action) => ({
      ...state,
      [SEARCH_LOOKUP_STATE_PROP_NAME]: {
        ...state[SEARCH_LOOKUP_STATE_PROP_NAME],
        queryModalData: action.payload,
        isLoading: false,
      }
    }),

    [searchLookupActions.fetchSearches]: (state, action) => ({
      ...state,
      [SEARCH_LOOKUP_STATE_PROP_NAME]: {
        ...state[SEARCH_LOOKUP_STATE_PROP_NAME],
        isLoading: !action.payload.hasOwnProperty('useIsLoading') || action.payload.useIsLoading,
        view: action.payload.view,
        searchResultsListCurrentPage: action.payload.page || 1,
        sortField: action.payload.sortField || getInitialSearchLookupState().sortField,
        sortDirection: action.payload.sortDirection || getInitialSearchLookupState().sortDirection,
        selectedSearchCategories: action.payload.categories || getInitialState().selectedSearchCategories,
        selectedSearchAuthors: action.payload.authors,
        searchResultsStartsWithFilter: action.payload.nameStartsWith || null,
        searchResultsNameFilter: action.payload.nameFilter || null,
        searches: [],
        total: 0,
      }
    }),
    [searchLookupActions.fetchSearchesComplete]: (state, action) => ({
      ...state,
      [SEARCH_LOOKUP_STATE_PROP_NAME]: {
        ...state[SEARCH_LOOKUP_STATE_PROP_NAME],
        isLoading: false,
        searches: action.payload.items,
        total: action.payload.totalCount,
        // remove any selected searches that were filtered out
        selectedSearchIds: state[SEARCH_LOOKUP_STATE_PROP_NAME].selectedSearchIds.filter(id => {
          return action.payload.allIds.includes(id)
        })
      }
    }),
    [searchLookupActions.fetchSearch.request]: (state, action) => ({
      ...state,
      [SEARCH_LOOKUP_STATE_PROP_NAME]: {
        ...state[SEARCH_LOOKUP_STATE_PROP_NAME],
        isLoading: true,
      }
    }),
    [actions.shouldShowTermFrequencyFilter]: (state, action) => ({
      ...state,
      'termFrequency': {
        ...state['termFrequency'],
        isTermFrequencyFilterExpanded: action.payload,
      }
    }),
    [actions.setTermFrequencyArray]: (state, action) => ({
      ...state,
      'termFrequency': {
        ...state['termFrequency'],
        termArray: action.payload,
      }
    }),
    [actions.setTermFrequencyFrequency]: (state, action) => ({
      ...state,
      'termFrequency': {
        ...state['termFrequency'],
        frequency: action.payload,
      }
    }),
    [actions.initializeTermFrequencyFilter]: (state, action) => ({
      ...state,
      'termFrequency': {
        ...state['termFrequency'],
        ...getInitialTermFrequencyState(),
      }
    }),
    [actions.resetTermFrequencyFilter]: (state, action) => ({
      ...state,
      'termFrequency': {
        ...state['termFrequency'],
        ...getInitialTermFrequencyState(),
        isTermFrequencyFilterExpanded: true,
      }
    }),
    [actions.initializePendingTermFrequencyFilters]: (state, action) => ({
      ...state,
      'pendingTermFrequency': {
        ...state['pendingTermFrequency'],
        ...getInitialPendingTermFrequencyState(),
      }
    }),
    [actions.setTermFrequencyTargetField]: (state, action) => ({
      ...state,
      'termFrequency': {
        ...state['termFrequency'],
        targetField: action.payload,
      }
    }),
    [actions.setPendingTermFrequencyFilters]: (state, action) => ({
      ...state,
      'pendingTermFrequency': {
        ...state['pendingTermFrequency'],
        filters: action.payload,
      }
    }),
  },
  getInitialState(),
)
