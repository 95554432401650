import {graphqlQuery} from 'app/api'
import {args, gql} from 'app/graphql'


export function fetchUser() {
  return graphqlQuery(
    gql`
      query {
        user: currentUser() {
          id
          firstName
          lastName
          username
          firmLocationId
          phone
          role
          authToken
          locationId
          timezone
          favoredTimezones {
            id
            name
          }
          linkedinToken
          twitterToken
          groupMemberships {
            id
            group {
              id
              firstName
            }
            isManager
          }
          userMemberships {
            id
            user {
              id
              firstName
              lastName
              isFirmAdmin
              username
            }
            isManager
          }
          groupNotes
          isPrivateGroup
          departments {
            id
            name
          }
          teams {
            id
            name
          }
          nextRefreshEmailAt
          refreshCompletedAt
          lastRefreshAt
          nlaProfile
          restrictNlaFeeds
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchStaticData() {
  return graphqlQuery(
    gql`
      query {
        locations() {
          id
          name
          favored
        }
        firmLocations() {
          id
          description
        }
        roles() {
          id
          name
        }
        timezones() {
          id
          name
        }
        groups(isActive: true, isPaged: false, isEntire: true) {
          items{
            id
            firstName
            isPrivateGroup
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function createMembership({userId, groupId}) {
  return graphqlQuery(
    gql`
      mutation {
        createMembership(
          userId: ${userId},
          groupId: ${groupId},
        ) {
          id
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function deleteMembership({userId, groupId}) {
  return graphqlQuery(
    gql`
      mutation {
        deleteMembership(
          userId: ${userId},
          groupId: ${groupId},
        ) {
          success
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function saveUser({
  id, firstName, lastName, username, firmLocationId, locationId, phone, role, timezone, password, currentPassword,
  groupNotes, isPrivateGroup
}) {
  return graphqlQuery(
    gql`
      mutation {
        saveUser(
          id: ${id},
          firstName: ${firstName},
          lastName: ${lastName},
          username: ${username},
          firmLocationId: ${firmLocationId},
          locationId: ${locationId},
          phone: ${phone},
          role: ${role},
          timezone: ${timezone},
          password: ${password},
          currentPassword: ${currentPassword},
          groupNotes: ${groupNotes},
          isPrivateGroup: ${isPrivateGroup},
        ) {
          id
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function toggleMembershipIsManager({userId, groupId}) {
  return graphqlQuery(
    gql`
      mutation {
        toggleMembershipIsManager(userId: ${userId}, groupId: ${groupId}) {
          success
        }
      }
    `
  ).then(({body: {data}}) => data)
}
