import {
  all,
  put, select,
  takeLatest,
  delay,
  takeEvery, call,
} from 'redux-saga/effects'
import * as actions from 'app/comparison-page/comparison-actions'
import * as api from "app/comparison-page/comparison-api"
import * as searchResultsPageApi from 'app/search-results-page/search-results-page-api'
import {handleSagaError} from "app/utils/errors"
import {RESULTS_PER_PAGE, TYPE_LINE_CHART, LARGER_TIMEFRAMES} from "./comparison-constants"
import is from "is"
import * as utils from "app/search-results-page/search-results-page-utils"
import * as selectors from "./comparison-selectors"
import * as dateFns from 'date-fns'
import {ISO_DATE_FORMAT} from "app/constants"
import {prop} from "ramda"
import * as searchResultsPageSaga from 'app/search-results-page/search-results-page-saga'
import {actions as notificationActions} from 'app/global/notifications'
import * as entitiesActions from "app/entities/entities-actions"
import {shouldDisableLineChart} from "./comparison-utils"
import {changeCaseObject} from "app/utils"


function* handleInit(action) {
  const category = action.payload.comparisonCategory
  if (!category){return}
  try {
    const cachedLargerTimeFrames =
      yield api.fetchCachedLargerTimeFrames({category})
      yield put(actions.setCachedLargerTimeFrames(
        changeCaseObject.camelCase(cachedLargerTimeFrames.data)
      ))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleSearchResults(results) {
  if(!results){
    yield put(actions.setLoadingSearchIds([]))
    return
  }
  const searchId = results.id
  yield* searchResultsPageSaga.saveSearchDataEntities(results)
  yield put(actions.setSearchResults(
    {
      searchId: searchId,
      comparisonDocumentIds:
        {
          [searchId]: {
            topLevelDocumentIds: results.results.documents.map(prop('id')),
            groupedDocumentIds: searchResultsPageSaga.getGroupedDocumentIds(
              results.results.documents
            )
          }
        }
    }
  ))
  const loadingSearchIds = yield select(selectors.getLoadingSearchIds)
  yield put(actions.setLoadingSearchIds(loadingSearchIds.filter(id => id !== results.id)))
}

function* handleRemoveDocumentsForExcludedFeedId(action) {
  const {feedId} = action.payload.feedAndSearchIds
  const allTopLevelDocuments = yield select(selectors.getAllTopLevelDocuments)
  const removeDocumentIds = allTopLevelDocuments
    .filter(doc => doc.feed.id === feedId)
    .map(doc => doc.id)

  if (removeDocumentIds.length > 0) {
    const comparisonDocumentIds = yield select(selectors.getComparisonDocumentIds)
    const setSearchResultsArray = []
    Object.entries(comparisonDocumentIds).forEach(([ssId, docLevels]) => {
      const topDocIds = docLevels.topLevelDocumentIds.filter(id => !removeDocumentIds.includes(id))
      const removeGrouped = (id, { [id]: exclProp, ...rest }) => rest
      let newGroupedIds = { ...docLevels.groupedDocumentIds }
      removeDocumentIds.forEach(id => {
          newGroupedIds = removeGrouped(id, newGroupedIds)
        }
      )
      setSearchResultsArray.push(put(actions.setSearchResults({
        searchId: ssId,
        comparisonDocumentIds: {
          [ssId]: {
            topLevelDocumentIds: topDocIds,
            groupedDocumentIds: newGroupedIds,
          }
        }
      })))
    })
    yield all(setSearchResultsArray)
    yield put(entitiesActions.remove({documents: [removeDocumentIds]}))
    yield put(actions.fetchComparisonSearchResults())
  }
}

function* handleActiveSidebarNavChange(action) {
  if (action.payload.waypoint === null) {
    const {searchId, groupTitle} = action.payload
    yield delay(100)
    yield put(actions.setActiveSidebarNav({searchId, groupTitle}))
  }
  yield put(actions.setLastSidebarNavChange(action.payload))
}

function* handleSetLastSidebarNavChange(action) {
  yield delay(100)
  yield put(actions.setActiveSidebarNav(action.payload))
}

function* handleFetchComparisonSearchResults({page = 1} = {}) {
  const timeFrame = yield select(selectors.getTimeFrame)
  const selectedSearchIds = yield select(selectors.getSelectedSearchIds)
  const activeFilterKey = yield select(selectors.getActiveFilterKey)
  if (selectedSearchIds.length < 1) {return}

  let startDate, endDate
  if (is.object(timeFrame)) {
    // It's a date range.
    startDate = timeFrame.start
    endDate = timeFrame.end
  }
  else if (is.string(timeFrame)) {
    startDate = utils.dateRangeFromTimeFrameString(timeFrame).start
  }

  const params = {
    startDate: startDate && dateFns.format(startDate, ISO_DATE_FORMAT),
    endDate: endDate && dateFns.format(endDate, ISO_DATE_FORMAT),
    page,
    resultsPerPage: RESULTS_PER_PAGE,
    appliedFilterKey: activeFilterKey,
  }

  // fetch first search results by itself then fetch all remaining searches in parallel
  params.searchId = selectedSearchIds[0]
  try {
    const resultsResponse = yield call(searchResultsPageApi.fetchSearchResults, params)
    const processSearchResults = []
    processSearchResults.push(handleSearchResults(resultsResponse.search))
    yield all(processSearchResults)
  } catch(error) {
    yield* handleSagaError(error)
  }

  // fetch all remaining search results in parallel requests
  const remainingSearchIds = selectedSearchIds.filter(id => id !== params.searchId)
  const resultsApiCalls = []
  remainingSearchIds.map(id => {
    const newParams = {...params}
    newParams.searchId = id
    resultsApiCalls.push(call(searchResultsPageApi.fetchSearchResults, newParams))
  })
  try {
    const remainingResultsResponses = yield all(resultsApiCalls)
    const processRemainingResults = []
    remainingResultsResponses.map(response => {
      processRemainingResults.push(handleSearchResults(response.search))
    })
    yield all(processRemainingResults)
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleSaveComparisonSearchGroup(action) {
  const {searchIds, editComparisonGroup, searchIdsHaveChanged} = action.payload
  const activeComparisonId = yield select(selectors.getActiveComparisonId)
  const activeFilterKey = yield select(selectors.getActiveFilterKey)
  let timeFrame = yield select(selectors.getTimeFrame)
  const displayChartTypes = yield select(selectors.getDisplayChartTypes)
  const shareOfVoiceChartType = yield select(selectors.getShareOfVoiceChartType)
  const activeCategory = yield (select(selectors.getComparisonCategory))
  const activeTitle = yield (select(selectors.getActiveSidebarGroupTitle))
  const title = action.payload.title || activeTitle
  const category = action.payload.category || activeCategory
  const isUpdateOnly = editComparisonGroup && editComparisonGroup.id

  if (!is.string(timeFrame)) {
    timeFrame = null
  }

  let apiData = {
    category: category || activeCategory,
    title: title,
    searchIds: searchIds,
    appliedFilterKey: activeFilterKey,
    duration: timeFrame,
    displayChartTypes: displayChartTypes,
    shareOfVoiceChartType: shareOfVoiceChartType,
  }

  if (isUpdateOnly) {
    apiData.comparisonGroupId = editComparisonGroup.id
    apiData.category = editComparisonGroup.category || activeCategory
    apiData.appliedFilterKey = null
    apiData.duration = null
    apiData.displayChartTypes = null
    apiData.shareOfVoiceChartType = null
  }

  if (isUpdateOnly || activeComparisonId) {
    if (!isUpdateOnly) {
      apiData.comparisonGroupId = activeComparisonId
    }
    try {
      yield api.saveComparisonSearchGroup(apiData)
      yield put(
        notificationActions.showNotification({
          type: 'success',
          message: `${title} updated successfully.`,
        })
      )
    } catch(error) {
      yield* handleSagaError(error)
    }
  } else {
    try {
      const response = yield api.createComparisonSearchGroup(apiData)
      const newGroupId = response.createComparisonSearchGroup.id
      yield put(actions.setNewComparisonId(newGroupId))
      yield put(
        notificationActions.showNotification({
          type: 'success',
          message: `${title} created successfully.`,
        })
      )
    } catch(error) {
      yield* handleSagaError(error)
    }
  }

  yield put(actions.fetchComparisonSearchGroups({category}))

  if (isUpdateOnly && editComparisonGroup.id === activeComparisonId) {
    if (searchIdsHaveChanged) {
      yield put(actions.setActiveComparison({
        selectedSearchIds: searchIds,
        activeComparisonId: editComparisonGroup.id,
        isLoading: true,
        activeComparisonTitle: title,
        startNewComparison: false,
        activeFilterKey: editComparisonGroup.appliedFilterKey,
        activeFilterItems: editComparisonGroup.filterItems,
        timeFrame: editComparisonGroup.duration,
        displayChartTypes: editComparisonGroup.displayChartTypes,
        shareOfVoiceChartType: editComparisonGroup.shareOfVoiceChartType,
        pendingComparison: {
          category: null,
          title: null,
          searchIds: [],
        }
      }))
    } else {
      yield put(actions.setNewComparisonTitle(title))
    }
  }

  if (title) {
    yield put(actions.setActiveSidebarGroupTitle(title))
  }

}

function* handleFetchComparisonSearchGroups(action) {
  const {category} = action.payload
  let comparisonGroups = []
  try {
    comparisonGroups = yield api.fetchComparisonSearchGroups({category})
    yield put(actions.setComparisonGroups(comparisonGroups))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleSetDisplayChartTypes(action) {
  const {chartType, fetchData} = action.payload
  const chartData = yield select(selectors.getChartData)
  if (fetchData && !chartData[chartType]) {
    yield put(actions.fetchChartData(chartType))
  }
}

function* handleTimeFrameChange(action) {
  const timeFrame = yield select(selectors.getTimeFrame)
  const newTimeFrame = action.payload
  if (newTimeFrame !== timeFrame) {
    const displayChartTypes = yield select(selectors.getDisplayChartTypes)
    const activeFilterKey = yield select(selectors.getActiveFilterKey)
    const activeFilterItems = yield select(selectors.getActiveFilterItems)
    const searchIds = yield select(selectors.getSelectedSearchIds)
    const timeFrameChangeActions = []

    timeFrameChangeActions.push(
      put(actions.refreshComparisonInit({
        timeFrame: newTimeFrame || timeFrame,
        activeFilterKey: activeFilterKey || null,
        activeFilterItems: activeFilterItems || [],
        loadingSearchIds: searchIds,
      }))
    )
    if (displayChartTypes.length > 0){
      displayChartTypes.forEach(ct => {
        timeFrameChangeActions.push(put(actions.fetchChartData(ct)))
      })
    }
    timeFrameChangeActions.push(put(actions.fetchComparisonSearchResults()))
    yield all(timeFrameChangeActions)
  }
}

function* handleChartResponse(chartResponse, chartType, mergeData) {
  if ((chartResponse.body.chartData.seriesData && chartResponse.body.chartData.seriesData.length > 0) ||
      (chartResponse.body.chartData.segmentData && chartResponse.body.chartData.segmentData.length > 0)) {
    yield put(actions.setChartData({
      merge: mergeData,
      chartType: chartType,
      data: chartResponse.body.chartData,
    }))
  }
}

function* handleFetchChartData(action) {
  const chartType = action.payload
  const timeFrame = yield select(selectors.getTimeFrame)
  const category = yield select(selectors.getComparisonCategory)
  const searchIds = yield select(selectors.getSelectedSearchIds)
  const activeFilterKey = yield select(selectors.getActiveFilterKey)
  const cachedLargerTimeFrames = yield select(selectors.getCachedLargerTimeFrames)

  if (chartType === TYPE_LINE_CHART &&
    shouldDisableLineChart(timeFrame, cachedLargerTimeFrames, searchIds)) {
    return
  }

  const requestOptions = {
    category: category,
    ssIds: searchIds.join(),
    alphaSort: 1,
    type: chartType,
    appliedFilterKey: activeFilterKey,
  }

  let days = 0

  if (timeFrame) {
    if (is.string(timeFrame)) {
      requestOptions.duration = timeFrame
    }
    else {
      const {start, end} = timeFrame
      requestOptions.startDate = dateFns.format(start, ISO_DATE_FORMAT)
      if (end) {
        requestOptions.endDate = dateFns.format(end, ISO_DATE_FORMAT)
        days = Math.round((end-start)/(1000*60*60*24))
      }
    }
  }

  try {
    if (chartType === TYPE_LINE_CHART &&
        (requestOptions.duration && LARGER_TIMEFRAMES.includes(requestOptions.duration) ||
          days > 14)) {

      yield put(actions.setIsStaggeredLoading(true))
      const searchIds = requestOptions.ssIds.split(',')

      for (let idx = 0; idx < searchIds.length; idx++) {
        requestOptions.ssIds = searchIds[idx]
        let mergeData = idx > 0
        yield handleChartResponse(yield call(api.fetchChartData, requestOptions), chartType, mergeData)
      }
      yield put(actions.setIsStaggeredLoading(false))

    } else {
      yield handleChartResponse(yield call(api.fetchChartData, requestOptions), chartType, false)
    }
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleSetActiveComparison(action) {
  const {displayChartTypes} = action.payload
  yield put(actions.initChartData())
  const fetchChartDataActions = []
  if (displayChartTypes.length > 0){
    displayChartTypes.forEach(ct => {
      fetchChartDataActions.push(put(actions.fetchChartData(ct)))
    })
  }
  yield all(fetchChartDataActions)
  yield put(actions.fetchComparisonSearchResults())
}

function* handleNarrowFiltersChange(action) {
  yield put(actions.setFetchingFilterKey(true))
  const filters = []
  const narrowFilters = action.payload
  const activeFilterKey = yield select(selectors.getActiveFilterKey)
  narrowFilters.forEach(n => {
    const value = n.isFreeText
      ? n.value
      : null
    const searchId = n.isFreeText
      ? null
      : n.searchId
        ? n.searchId
        : parseInt(n.value)

    const filter = {
      'specialType': null,
      'value': value,
      'isFreeText': n.isFreeText || false,
      'groupId': null,
      'searchId': searchId,
      'firmSourceLabelId': n.firmSourceLabelId || null,
      'label': n.label,
      'id': null,
      'filterField': 'text_any'
    }
    filters.push(filter)
  })

  const params = {searchId: null, filters: filters}
  const newFilterKey = yield searchResultsPageApi.applyFilters(params)
  yield put(actions.setFetchingFilterKey(false))

  if (activeFilterKey !== newFilterKey) {
    const searchIds = yield select(selectors.getSelectedSearchIds)
    const timeFrame = yield select(selectors.getTimeFrame)
    const displayChartTypes = yield select(selectors.getDisplayChartTypes)
    const narrowFiltersChangeActions = []

    narrowFiltersChangeActions.push(
      put(actions.refreshComparisonInit({
        timeFrame: timeFrame,
        activeFilterKey: newFilterKey,
        activeFilterItems: filters,
        loadingSearchIds: searchIds,
      }))
    )
    narrowFiltersChangeActions.push(put(actions.fetchComparisonSearchResults()))

    if (displayChartTypes.length > 0){
      displayChartTypes.forEach(ct => {
        narrowFiltersChangeActions.push(put(actions.fetchChartData(ct)))
      })
    }
    yield all(narrowFiltersChangeActions)
  }
}

function* handleDeleteComparisonSearchGroup(action) {
  const {comparisonGroupId, title} = action.payload
  const activeCategory = yield (select(selectors.getComparisonCategory))
  const category = action.payload.category || activeCategory
  const activeComparisonId = yield (select(selectors.getActiveComparisonId))
  try {
    yield api.deleteComparisonSearchGroup({comparisonGroupId})
    yield put(actions.fetchComparisonSearchGroups({category}))
    yield put(actions.setDeleteComparison({}))

    if (comparisonGroupId === activeComparisonId) {
      yield put(actions.setBuildNewComparison())
    }

    yield put(
      notificationActions.showNotification({
        type: 'success',
        message: `Comparison ${title} deleted successfully.`,
      })
    )
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleRestoreStateFromHistory(action) {
  const {selectedSearchIds} = action.payload
  yield put(actions.setLoadingSearchIds(selectedSearchIds))
  yield put(actions.fetchComparisonSearchResults())
}

export default function* comparisonSaga() {
  yield all([
    takeLatest(actions.removeDocumentsForExcludedFeedId, handleRemoveDocumentsForExcludedFeedId),
    takeEvery(actions.fetchChartData, handleFetchChartData),
    takeEvery(actions.activeSidebarNavChange, handleActiveSidebarNavChange),
    takeLatest(actions.setLastSidebarNavChange, handleSetLastSidebarNavChange),
    takeLatest(actions.fetchComparisonSearchResults, handleFetchComparisonSearchResults),
    takeLatest(actions.saveComparisonSearchGroup, handleSaveComparisonSearchGroup),
    takeLatest(actions.fetchComparisonSearchGroups, handleFetchComparisonSearchGroups),
    takeLatest(actions.setDisplayChartTypes, handleSetDisplayChartTypes),
    takeLatest(actions.timeFrameChange, handleTimeFrameChange),
    takeLatest(actions.setActiveComparison, handleSetActiveComparison),
    takeLatest(actions.narrowFiltersChange, handleNarrowFiltersChange),
    takeLatest(actions.deleteComparisonSearchGroup, handleDeleteComparisonSearchGroup),
    takeLatest(actions.restoreStateFromHistory, handleRestoreStateFromHistory),
    takeLatest(actions.init, handleInit),
  ])
}
