import {call} from 'redux-saga/effects'

import {getCsrfToken} from 'app/utils/django'
import {submitPostRequest} from 'app/utils'
import {exportArticles} from 'app/urls'
import { searchCategory } from 'app/strings'


function* exportDocuments({url, documentIds, exportAction}) {
  let nextUrl = window.location.pathname
  if (window.location.search) {
    nextUrl += window.location.search
  }
  const data = {
    csrfmiddlewaretoken: getCsrfToken(),
    next: nextUrl,
    preview: 'true',
    unchecked: '[999]',
    catsDocs: '',
    multiple_action: exportAction,
    id: documentIds,
  }
  yield call(submitPostRequest, {url, data})
}

function* exportSearchResultExcelDocuments({ url, documentIds, name, category, exportAction }) {
  let nextUrl = window.location.pathname
  if (window.location.search) {
    nextUrl += window.location.search
  }
  const data = {
    csrfmiddlewaretoken: getCsrfToken(),
    next: nextUrl,
    preview: 'true',
    unchecked: '[999]',
    catsDocs: '',
    multiple_action: exportAction,
    id: documentIds,
    searchName: name,
    searchCategory: category,
  }
  yield call(submitPostRequest, { url, data })
}

export function* exportPDF(documentIds) {
  const url = exportArticles.pdf()
  yield* exportDocuments({
    url,
    documentIds,
    exportAction: 'export_pdf',
  })
}

export function* exportDoc(documentIds) {
  const url = exportArticles.doc()
  yield* exportDocuments({
    url,
    documentIds,
    exportAction: 'export_docx',
  })
}

export function* exportExcel(documentIds, name = '', category = '') {
  const url = exportArticles.excel()
  yield* exportSearchResultExcelDocuments({
    url,
    documentIds,
    name,
    category,
    exportAction: 'excel',
  })
}

export function* exportEmail(documentIds) {
  const url = exportArticles.email()
  yield* exportDocuments({
    url,
    documentIds,
    exportAction: 'email',
  })
}
