import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import * as actions from '../../profile-preferences-actions'
import * as selectors from '../../profile-preferences-selectors'
import {Checklist} from '../common/checklist'


export default function LanguagePreferences() {
  const languages = useSelector(selectors.getLanguages)
  const loadingIds = useSelector(selectors.getLanguageLoadingIds)
  const dispatch = useDispatch()
  const items = languages.map(language => ({
    key: language.id,
    isChecked: language.isPreferred,
    isLoading: loadingIds.includes(language.id),
    label:
      language.name + (language.nativeName ? ` (${language.nativeName})` : ''),
    onChange: isPreferred => {
      dispatch(
        actions.changeLanguagePreferenceRequest({id: language.id, isPreferred})
      )
    },
  }))
  return <Checklist items={items} />
}
