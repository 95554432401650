import React from 'react'
import MultiSelectDropdown, { Option } from 'app/common/MultiSelectDropdown'

export default ({ locations, ...restProps }) => {
  const NO_LOCATION_ID = 0
  const NO_LOCATION_TEXT = 'No Location'

  const sortedLocations = [...locations].sort((a,b)=>a.name > b.name?1:-1)
  const locationArray = [{ id: NO_LOCATION_ID, name: NO_LOCATION_TEXT }, ...sortedLocations]

  const options = locationArray.map(location =>
    <Option
      key={location.id}
      label={location.name}
      value={location.id}
    />
  )

  return (
    <MultiSelectDropdown label='Locations' {...restProps}>
      {options}
    </MultiSelectDropdown>
  )
}
