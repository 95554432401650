import is from 'is'

export const searchParamsToQuery = searchParams =>
  Array.from(searchParams.entries())
    .filter(([key, value]) => is.defined(value))
    .reduce((obj, [key, value]) => {
      if (obj.hasOwnProperty(key)) {
        // We have multiple values for the same key, so it should be an array.
        if (!Array.isArray(obj[key])) {
          obj[key] = [obj[key]]
        }
        obj[key].push(value)
      } else {
        obj[key] = value
      }
      return obj
    }, {})

export const mergeLocations = (dest, source) => ({
  pathname: source.pathname || dest.pathname,
  query: mergeQueries(dest.query, source.query),
})

export const mergeQueries = (dest, source) => {
  const newQuery = {...dest}
  for (const [key, value] of Object.entries(source)) {
    if (newQuery.hasOwnProperty(key) && Array.isArray(newQuery[key])) {
      if (Array.isArray(value)) {
        newQuery[key] = [...newQuery[key], ...value]
      } else {
        newQuery[key] = [...newQuery[key], value]
      }
    } else {
      newQuery[key] = value
    }
  }
  return newQuery
}

export const urlStringToLocation = urlString => {
  let url
  try {
    url = new URL(urlString)
  } catch (e) {
    // We're assuming that the problem is a lack of origin. Otherwise, this will
    // throw again.
    url = new URL(window.location.origin + urlString)
  }
  return {
    pathname: url.pathname,
    query: searchParamsToQuery(url.searchParams),
    persistQuery: false,
  }
}

/**
 * Takes a flexible location input and turns it into an internal location
 * object. Takes the following inputs:
 *   String - Either an absolute URL or pathname
 *   Object - {
 *     pathname?: String,
 *     query?: Object<Array | String>,
 *     persistQuery?: Boolean = false,
 *   }
 */
export const normalizeLocationInput = input => {
  if (is.string(input)) {
    return urlStringToLocation(input)
  }
  if (is.object(input)) {
    const locationObj = {
      persistQuery: input.persistQuery || false,
    }
    if (input.pathname) {
      locationObj.pathname = input.pathname
    }
    if (input.query) {
      locationObj.query = input.query
    }
    return locationObj
  }
  throw new Error(`Invalid location input: ${input}`)
}

export const locationToString = locationObj => {
  let url = ''
  if (locationObj.pathname) {
    url += locationObj.pathname
  }
  if (locationObj.query) {
    const searchParams = new URLSearchParams()
    for (const [key, value] of Object.entries(locationObj.query)) {
      if (Array.isArray(value)) {
        for (const item of value) {
          searchParams.append(key, item)
        }
      } else {
        searchParams.append(key, value)
      }
    }
    url += '?' + searchParams.toString()
  }
  return url
}
