import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, {useState, useRef, useEffect} from 'react'
import {titleCase} from 'title-case'
import {CHART_COLORS, EXCLUDED_COLOR, CHART_COLORS_HOVER,
  CHART_TYPES} from "./constants"
import styles from './MzChart.less'
import {
  Pie,
  PieChart,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
} from "recharts"
import is from "is"
import ExportChart from "./ExportChart"


export default function MzPieChart({
  chartData,
  className,
  responsiveClassMaxWidth,
}) {
  const showSliceInit = (segmentKeys) => (
    segmentKeys.reduce((acc, key) => (acc[key] = true, acc), {})
  )
  const legendColorInit = (chartData) => {
    const {segmentKeys, segmentData} = chartData
    const index = (pk) => {
      const item = segmentData.find(d => d.segmentKey === pk)
      return item.segmentIndex
    }
    return (
        segmentKeys.reduce((acc, key, _) => (acc[key] = CHART_COLORS[index(key)], acc), {})
      )
  }

  const [showSlice, setShowSlice] = useState(showSliceInit(chartData.segmentKeys))
  const [legendColor, setLegendColor] = useState(legendColorInit(chartData))
  const [showLegend, setShowLegend] = useState(false)
  const [activeData, setActiveData] = useState(chartData.segmentData)
  const [activeSlice, setActiveSlice] = useState(0)
  const [legendScrollTop, setLegendScrollTop] = useState(0)
  const activeChart = useRef(null)
  const scrollRef = useRef({scrollTop: 0})

  const handleLegendMouseEvent = (item, isHover) => {
    const color = {...legendColor}
    if (showSlice[item.segmentKey] !== false){
      color[item.segmentKey] = isHover
        ? CHART_COLORS_HOVER[item.segmentIndex]
        : CHART_COLORS[item.segmentIndex]
      setLegendColor(color)
      if (isHover) {
        const index = activeData.findIndex(ad => ad.segmentIndex === item.segmentIndex)
        setActiveSlice(index)
      }
    }
  }

  useEffect(() => {
    if (legendScrollTop > 0) {
      scrollRef.current.scrollTop = legendScrollTop
    }
  })

  const handleLegendOnClick = (segmentItem) => {
    const {segmentKey, segmentIndex} = segmentItem

    // disallow setting all slices to inactive
    if (showSlice[segmentKey] !== false && activeData.length === 1) {
      return
    }

    const slice = {...showSlice}
    const color = {...legendColor}

    slice[segmentKey] = slice[segmentKey] === false
    color[segmentKey] = slice[segmentKey] === false
      ? EXCLUDED_COLOR
      : CHART_COLORS[segmentIndex]

    setShowSlice(slice)
    setLegendColor(color)

    // set active pie data for re-draw
    const excludedKeys = chartData.segmentKeys.filter(pk => slice[pk] === false)
    const newActiveData = chartData.segmentData.filter(bd => !excludedKeys.includes(bd.segmentKey))

    setActiveData(newActiveData)

    if (slice[segmentKey] === false && newActiveData.length > 0) {
      setActiveSlice(0)
    }
  }

  const renderLegend = (props) => {
    const { payload } = props
    setShowLegend(true)

    let labels = (item) => {
      let labelWords = item.xAxis.split(' ')
      let labelData = getLabel(labelWords, 30)
      let labelLines = [labelData.label]
      while (labelData.remainingWords.length > 0) {
        labelData = getLabel(labelData.remainingWords, 30)
        labelLines.push(labelData.label)
      }
      const label = labelLines.map((l, idx) => {
        return(
          <div
            key={`item-${item.segmentKey}-${idx}`}
            className={styles.legendItem}
          >
            <span
              style={{backgroundColor: legendColor[item.segmentKey]}}
              className={classNames(styles.dot,
                {[styles.exclude]: legendColor[item.segmentKey] === EXCLUDED_COLOR},
                {[styles.hideDot]: idx > 0},
              )}
            />
            <span
              className={classNames(styles.label,
                {[styles.exclude]: legendColor[item.segmentKey] === EXCLUDED_COLOR},
              )}
            >
              {titleCase(l)}
            </span>
          </div>
        )
      })
      return(
        <div
          key={`item-wrapper-${item.segmentKey}`}
          className={classNames(styles.legendItemWrapper,
            {[styles.exclude]: legendColor[item.segmentKey] === EXCLUDED_COLOR},
          )}
          onClick={() => handleLegendOnClick(item)}
          onMouseEnter={() => handleLegendMouseEvent(item, true)}
          onMouseLeave={() => handleLegendMouseEvent(item, false)}
        >
          {label}
        </div>
      )
    }

    return (
      <React.Fragment>
        <div className={styles.pieLegendExport}>
          {activeChart && activeChart.current &&
            <ExportChart
              currentChart={activeChart.current}
              chartType={CHART_TYPES.PIE}
              textBoxWidth={'11em'}
            />
          }
        </div>
        <div
          ref={scrollRef}
          onScroll={handleLegendScroll}
          className={classNames(styles.legendWrapper, styles.pieLegend,
            {[styles.show]: showLegend})}
        >
          {
            payload.map(item => (labels(item)))
          }
        </div>
      </React.Fragment>
    )
  }

  const RADIAN = Math.PI / 180

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, _ }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    const percentNum = (percent * 100).toFixed(0)
    const percentLabel = parseInt(percentNum) > 4 && parseInt(percentNum) < 100
      ? `${percentNum}%`
      : null

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {percentLabel}
      </text>
    )
  }

  const onPieEnter = (_, index) => {
    setActiveSlice(index)
  }

  const getLabel = (words, breakLength) => {
    let label = ''
    let remainingWords = [...words]
    words.forEach(w => {
      if (label.length < breakLength){
        label = `${label} ${w}`
        remainingWords = remainingWords.filter((w, idx) => idx > 0 )
      }
    })
    return({label, remainingWords})
  }

  const handleLegendScroll = () => {
    const scrollTop = scrollRef.current.scrollTop
    setLegendScrollTop(scrollTop)
  }

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload,
      percent, value } = props
    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const sx = cx + (outerRadius + 10) * cos
    const sy = cy + (outerRadius + 10) * sin
    const mx = cx + (outerRadius + 30) * cos
    const my = cy + (outerRadius + 30) * sin
    const ex = mx + (cos >= 0 ? 1 : -1) * 22
    const ey = my
    const textAnchor = cos >= 0 ? 'start' : 'end'
    const labelWords = payload.xAxis.split(' ')
    let labelData = getLabel(labelWords, 10)
    const textLine1 = labelData.label
    labelData = getLabel(labelData.remainingWords, 10)
    let textLine2 = labelData.label

    textLine2 = labelData.remainingWords.length > 0
      ? `${textLine2}...`
      : textLine2

    const middleY = textLine2
      ? cy - 15
      : cy

    return (
      <g>
        <text x={cx} y={middleY} dy={8} textAnchor="middle" fill={fill}>
          { textLine1 &&
            <tspan x={cx} dy=".6em">{textLine1}</tspan>
          }
          { textLine2 &&
            <tspan x={cx} dy="1.2em">{textLine2}</tspan>
          }
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
          { textLine1 &&
            <tspan x={ex + (cos >= 0 ? 1 : -1) * 12} dy=".6em">{textLine1}</tspan>
          }
          { textLine2 &&
            <tspan x={ex + (cos >= 0 ? 1 : -1) * 12} dy="1.2em">{textLine2}</tspan>
          }
          { textLine1 &&
          <tspan x={ex + (cos >= 0 ? 1 : -1) * 12} dy="1.2em" fill="#999">
            {`(Share ${(percent * 100).toFixed(0)}%)`}
          </tspan>
          }
        </text>
      </g>
    )
  }

  const responsiveClassName = responsiveClassMaxWidth
    ? 'responsive-pie-max'
    : 'responsive-pie'

  const pieChart = !is.empty(chartData)
    ? <div className={classNames(styles.mzChart, className)}>
        <ResponsiveContainer
          height='100%'
          width='100%'
          className={responsiveClassName}
        >
          <PieChart
            key={activeSlice}
            width={400}
            height={400}
            ref={(chart) => activeChart.current = chart}
          >
            <Legend
              content={renderLegend}
              payload={chartData.segmentData}
              layout={'vertical'}
              verticalAlign={'middle'}
              align={'right'}
            />
            <Pie
              activeIndex={activeSlice}
              activeShape={renderActiveShape}
              nameKey={'xAxis'}
              data={activeData}
              cx={"50%"}
              cy={"50%"}
              labelLine={false}
              label={renderCustomizedLabel}
              isAnimationActive={false}
              innerRadius={75}
              outerRadius={150}
              dataKey={'count'}
              onMouseEnter={onPieEnter}
            >
              {activeData.map((slice, index) => (
                <Cell key={`cell-${index}`} fill={legendColor[slice.segmentKey]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    : null

  return (
    <React.Fragment>
      { pieChart }
    </React.Fragment>
  )
}
MzPieChart.propTypes = {
  chartData: PropTypes.object.isRequired,
  className: PropTypes.string,
  responsiveClassMaxWidth: PropTypes.bool,
}
MzPieChart.defaultProps = {
  className: 'mz-pie-chart',
  responsiveClassMaxWidth: true
}
