import { handleActions } from 'redux-actions'
import * as fsActions from './firm-settings-actions'
import {prop} from 'ramda'

const getInitialState = () => ({
  isLoading: false,
  allLanguages: [],
  allCountries: [],
  allTimezones: [],
  userIds: [],
  isDeleteLocationModalOpen: false,
  selectedLocationIds: [],
  error: null,
  selectedUserIds: [],

  newLocationDescription: '',
  newLocationCountryId: null,
  newLocationTimezone: null,

  // start with null, set to an array to differentiate between not fetched and fetched but empty.
  firmLanguages: null,
  firmLocations: null,
  departments: null,
  teams: null,

  selectedClassificationIds: [],
  newClassificationItemName: '',
  deleteClassificationIds: [],
  editLocationModalData: {
    id: null,
    description: '',
    countryId: null,
    timezone: null,
  },
  editClassificationModalData: {
    id: null,
    name: '',
  },
  assignModalData: {
    id: null,
    assigneeIdsBySection: {},
  }
})

export default handleActions({
  [fsActions.init]: (state, action) => ({
    ...state,
    allLanguages: action.payload.allLanguages,
    allCountries: action.payload.allCountries,
    allTimezones: action.payload.allTimezones,
    userIds: action.payload.users.map(prop('id')),
  }),

  [fsActions.setIsLoading]: (state, action) => {
    return {
      ...state,
      isLoading: action.payload,
    }
  },

  [fsActions.setNewLocationDescription]: (state, action) => {
    return {
      ...state,
      newLocationDescription: action.payload,
    }
  },

  [fsActions.setNewLocationCountryId]: (state, action) => {
    return {
      ...state,
      newLocationCountryId: action.payload,
    }
  },

  [fsActions.setNewLocationTimezone]: (state, action) => {
    return {
      ...state,
      newLocationTimezone: action.payload,
    }
  },

  [fsActions.addSelectedLocationId]: (state, action) => {
    return {
      ...state,
      selectedLocationIds: [...state.selectedLocationIds, action.payload],
    }
  },

  [fsActions.removeSelectedLocationId]: (state, action) => {
    return {
      ...state,
      selectedLocationIds: state.selectedLocationIds.filter(id => id !== action.payload),
    }
  },

  [fsActions.setSelectedLocationIds]: (state, action) => {
    return {
      ...state,
      selectedLocationIds: action.payload,
    }
  },

  [fsActions.addLanguage]: (state, action) => {
    return {
      ...state,
      isLoading: true,
    }
  },

  [fsActions.removeLanguage]: (state, action) => {
    return {
      ...state,
      isLoading: true,
    }
  },

  [fsActions.fetchLanguages]: (state, action) => ({
    ...state,
    isLoading: true,
  }),

  [fsActions.setLanguages]: (state, action) => ({
    ...state,
    firmLanguages: action.payload,
  }),

  [fsActions.fetchLocations]: (state, action) => ({
    ...state,
    isLoading: true,
  }),

  [fsActions.setLocations]: (state, action) => ({
    ...state,
    firmLocations: action.payload,
  }),

  [fsActions.fetchDepartments]: (state, action) => ({
    ...state,
    isLoading: true,
  }),

  [fsActions.setDepartments]: (state, action) => ({
    ...state,
    departments: action.payload,
  }),

  [fsActions.fetchTeams]: (state, action) => ({
    ...state,
    isLoading: true,
  }),

  [fsActions.setTeams]: (state, action) => ({
    ...state,
    teams: action.payload,
  }),

  [fsActions.setEditLocationModalData]: (state, action) => {
    return {
      ...state,
      editLocationModalData: {
        ...action.payload,
      },
    }
  },

  [fsActions.hideEditLocationModal]: (state, action) => {
    return {
      ...state,
      isLoading: false,
      editLocationModalData: {
        ...getInitialState().editLocationModalData,
      },
      newLocationDescription: getInitialState().newLocationDescription,
      newLocationCountryId: getInitialState().newLocationCountryId,
      newLocationTimezone: getInitialState().newLocationTimezone,
    }
  },

  [fsActions.showDeleteLocationModal]: (state, action) => {
    return {
      ...state,
      isDeleteLocationModalOpen: true,
    }
  },

  [fsActions.hideDeleteLocationModal]: (state, action) => {
    return {
      ...state,
      isDeleteLocationModalOpen: false,
      selectedLocationIds: [],
    }
  },

  [fsActions.setSelectedClassificationIds]: (state, action) => ({
    ...state,
    selectedClassificationIds: action.payload,
  }),

  [fsActions.setNewClassificationItemName]: (state, action) => ({
    ...state,
    newClassificationItemName: action.payload,
  }),

  [fsActions.setDeleteClassificationIds]: (state, action) => ({
    ...state,
    deleteClassificationIds: action.payload,
  }),

  [fsActions.deleteLocations]: (state, action) => ({
    ...state,
    isLoading: true,
  }),

  [fsActions.deleteDepartments]: (state, action) => ({
    ...state,
    isLoading: true,
  }),

  [fsActions.deleteTeams]: (state, action) => ({
    ...state,
    isLoading: true,
  }),

  [fsActions.setEditClassificationModalData]: (state, action) => ({
    ...state,
    editClassificationModalData: {
      ...state.editClassificationModalData,
      ...action.payload,
    },
  }),

  [fsActions.hideEditClassificationModal]: (state, action) => ({
    ...state,
    isLoading: false,
    editClassificationModalData: {
      ...getInitialState().editClassificationModalData,
    },
    newClassificationItemName: '',
  }),

  [fsActions.saveLocation]: (state, action) => ({
    ...state,
    isLoading: true,
  }),

  [fsActions.saveDepartment]: (state, action) => ({
    ...state,
    isLoading: true,
  }),

  [fsActions.saveTeam]: (state, action) => ({
    ...state,
    isLoading: true,
  }),

  [fsActions.updateAssignModalData]: (state, action) => ({
    ...state,
    assignModalData: {
      ...state.assignModalData,
      ...action.payload
    },
  }),

  [fsActions.resetAssignModalData]: (state, action) => ({
    ...state,
    assignModalData: getInitialState().assignModalData,
  }),

  [fsActions.saveDepartmentAssignments]: (state, action) => ({
    ...state,
    isLoading: true,
  }),

  [fsActions.saveTeamAssignments]: (state, action) => ({
    ...state,
    isLoading: true,
  }),

  [fsActions.addLocationLanguage]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [fsActions.removeLocationLanguage]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
}, getInitialState())
