import { all, take, takeLatest, put, select, join, fork } from 'redux-saga/effects'

import { User } from 'app/models'
import Orm from 'app/framework/Orm'
import * as entitiesActions from 'app/entities/entities-actions'
import * as notifications from 'app/global/notifications'
import { handleSagaError } from 'app/utils/errors'

import * as profileAccountApi from './profile-account-api'
import * as profileAccountActions from './profile-account-actions'


function* handleInit(action) {
  try {
    yield join([
      yield fork(fetchUser),
      yield fork(fetchStaticData),
    ])
    yield put(profileAccountActions.initComplete())
    yield put(profileAccountActions.setIsLoading(false))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleFetchUser(action) {
  yield fetchUser()
}

function* fetchUser() {
  try {
    const response = yield profileAccountApi.fetchUser()
    const user = response.user
    const users = {
      [user.id]: user,
    }
    const memberships = {}
    response.user.groupMemberships.forEach(m => {
      m.userId = user.id
      m.groupId = m.group.id
      memberships[m.id] = m
      if (m.group.id !== user.id) {
        users[m.group.id] = m.group
      }
    })
    response.user.userMemberships.forEach(m => {
      m.userId = m.user.id
      m.groupId = user.id
      memberships[m.id] = m
      users[m.user.id] = m.user
    })
    const entitiesData = {
      users,
      memberships,
    }
    yield put(entitiesActions.update(entitiesData))
    yield put(profileAccountActions.setSaveData({}))
    yield put(profileAccountActions.setIsLoading(false))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* fetchStaticData() {
  try {
    const response = yield profileAccountApi.fetchStaticData()
    const groupIds = []
    const users = {}
    if (response && response.groups && response.groups.items){
      response.groups.items.forEach(g => {
        groupIds.push(g.id)
        users[g.id] = g
      })
    }
    const entitiesData = {
      users,
    }
    yield put(entitiesActions.update(entitiesData))
    yield put(profileAccountActions.fetchStaticDataComplete({
      locations: response.locations,
      firmLocations: response.firmLocations,
      roles: response.roles,
      timezones: response.timezones,
      groupIds,
    }))
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleCreateMembership(action) {
  const userId = yield select(state => state.currentUser)
  try {
    yield profileAccountApi.createMembership({userId: userId, groupId: action.payload})
    yield put(profileAccountActions.fetchUser())
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Group membership added successfully.',
      })
    )
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleDeleteMembership(action) {
  const groupId = action.payload
  const userId = yield select(state => state.currentUser)
  const entities = yield select(state => state.entities)
  const orm = Orm.withEntities(entities)
  const user = orm.getById(User, userId)
  const membership = user.groupMemberships.filter(m => m.group.id === groupId)[0]
  try {
    yield profileAccountApi.deleteMembership({userId: userId, groupId: groupId})
    yield put(entitiesActions.remove({memberships: [membership.id]}))
    yield put(profileAccountActions.setIsLoading(false))
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Group membership removed successfully.',
      })
    )
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleSave(action) {
  const userId = yield select(state => state.currentUser)
  try {
    yield profileAccountApi.saveUser({id: userId, ...action.payload})
    yield put(profileAccountActions.fetchUser())
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Account saved successfully.',
      })
    )
    yield put(profileAccountActions.setPasswordConfirmModalOpen(false))
  } catch(error) {
    yield* handleSagaError(error)
    yield put(profileAccountActions.setIsLoading(false))
  }
}

function* handleToggleMembershipIsManager(action) {
  const groupId = yield select(state => state.currentUser)
  try {
    yield profileAccountApi.toggleMembershipIsManager({userId: action.payload, groupId})
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Group manager status was updated successfully.',
      })
    )
    yield put(profileAccountActions.fetchUser())
  } catch(error) {
    yield* handleSagaError(error)
  }
}

export default function*() {
  yield all([
    takeLatest(
      profileAccountActions.init,
      handleInit
    ),
    takeLatest(
      profileAccountActions.fetchUser,
      handleFetchUser
    ),
    takeLatest(
      profileAccountActions.createMembership,
      handleCreateMembership
    ),
    takeLatest(
      profileAccountActions.deleteMembership,
      handleDeleteMembership
    ),
    takeLatest(
      profileAccountActions.save,
      handleSave
    ),
    takeLatest(
      profileAccountActions.toggleMembershipIsManager,
      handleToggleMembershipIsManager
    ),
  ])
}
