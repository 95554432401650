import React from 'react'
import PropTypes from 'prop-types'
import {DATA_COUNT_ROTATE_THRESHOLD} from "./constants"
import * as branding from 'palette'


export default function CustomXAxisTick({
  x,
  y,
  payload,
  dataCount,
  isStaggeredLoading
}) {

  if (isStaggeredLoading) {return null}

  const rotate = dataCount > DATA_COUNT_ROTATE_THRESHOLD
    ? "rotate(-25)"
    : "rotate(0)"

  const xValue = dataCount > DATA_COUNT_ROTATE_THRESHOLD
    ? 0
    : 20

  const label = payload.value.length > 12
      ? `${payload.value.substring(0, 10)}...`
      : payload.value

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={xValue}
        y={0}
        dy={16}
        textAnchor="end"
        fill={branding['text-color-general']}
        transform={rotate}
        cursor="default"
      >
        {label}
      </text>
    </g>
  )
}

CustomXAxisTick.propTypes = {
  dataCount: PropTypes.number.isRequired,
  isStaggeredLoading: PropTypes.bool,
}
CustomXAxisTick.defaultProps = {
  isStaggeredLoading: false,
}
