import React from 'react'
import classNames from 'classnames'

import EsgDocumentList from '../../search-results-page/esg-insights/EsgDocumentList'
import { useDispatch, useSelector } from 'react-redux'
import * as esgSelectors from '../esg-selectors'
import Filters from '../Filters'

import './style.less'
import * as esgActions from '../esg-actions'
import TermsContainer from 'app/esg/TermsContainer'
import Divider from 'app/common/Divider'
import { getSortOptions } from 'app/esg/esg-constants'
import { getPillarOptions, getTopicOptions } from 'app/esg/utils'
import PageSelector from 'app/common/PaginatedTable/PageSelector'

function IndustryNews({}) {
  const dispatch = useDispatch()
  const pillarFilter = useSelector(esgSelectors.getIndustrySelectedPillar)
  const setPillarFilter = pillar => dispatch(esgActions.setIndustrySelectedPillar(pillar))
  const fetchPillarTopicFilterTerms = pillar => dispatch(esgActions.fetchIndustryFilterTerms(pillar))
  const topicFilter = useSelector(esgSelectors.getIndustrySelectedTopic)
  const setTopicFilter = topic => dispatch(esgActions.setIndustrySelectedTopic(topic))
  const selectedIndustry = useSelector(esgSelectors.getIndustry)
  const industryStories = useSelector(esgSelectors.getIndustryStories)
  const terms = useSelector(esgSelectors.getIndustryTerms)
  const sortFilter = useSelector(esgSelectors.getIndustryStoryOrder)
  const selectedTerm = useSelector(esgSelectors.getIndustrySelectedTerm)
  const setSelectedTerm = item => dispatch(esgActions.setIndustrySelectedTerm(item))
  const page = useSelector(esgSelectors.getIndustryStoryPage)
  const pageCount = Math.ceil(industryStories.count / 10)
  const setPage = page => dispatch(esgActions.setIndustryStoryPage(page))
  const filtersProps = {
    pillarFilter,
    pillarOptions: getPillarOptions(),
    setPillarFilter,
    fetchPillarTopicFilterTerms,
    topicFilter,
    topicOptions: getTopicOptions(pillarFilter),
    setTopicFilter,
    sortFilter,
    sortOptions: getSortOptions(),
    setSortFilter: (option) => dispatch(esgActions.setIndustryStorySortFilter(option)),
  }

  return <News
    selectedEntity={{ ...selectedIndustry, name: selectedIndustry?.sectorName }}
    isLoading={industryStories.isLoading}
    topLevelDocuments={fixNews(industryStories.stories)}
    terms={terms}
    selectedTerm={selectedTerm}
    setSelectedTerm={setSelectedTerm}
    filtersProps={filtersProps}
    currentPage={page}
    pageCount={pageCount}
    onPageChange={setPage}
  />
}

function CompanyNews({
  selectedCompany, isError
}) {
  const dispatch = useDispatch()
  const pillarFilter = useSelector(esgSelectors.getCompanySelectedPillar)
  const setPillarFilter = pillar => dispatch(esgActions.setCompanySelectedPillar(pillar))
  const fetchPillarTopicFilterTerms = pillar => dispatch(esgActions.fetchCompanyFilterTerms(pillar))
  const topicFilter = useSelector(esgSelectors.getCompanySelectedTopic)
  const setTopicFilter = topic => dispatch(esgActions.setCompanySelectedTopic(topic))
  const companyStories = useSelector(esgSelectors.getCompanyStories)
  const sortFilter = useSelector(esgSelectors.getCompanyStoryOrder)
  const page = useSelector(esgSelectors.getCompanyStoryPage)
  const pageCount = Math.ceil(companyStories.count / 10)
  const setPage = page => dispatch(esgActions.setCompanyStoryPage(page))
  const terms = useSelector(esgSelectors.getCompanyTerms)
  const selectedTerm = useSelector(esgSelectors.getCompanySelectedTerm)
  const setSelectedTerm = item => dispatch(esgActions.setCompanySelectedTerm(item))
  const filtersProps = {
    pillarFilter,
    pillarOptions: getPillarOptions(),
    setPillarFilter,
    fetchPillarTopicFilterTerms,
    topicFilter,
    topicOptions: getTopicOptions(pillarFilter),
    setTopicFilter,
    sortFilter,
    sortOptions: getSortOptions(),
    setSortFilter: (option) => dispatch(esgActions.setCompanyStorySortFilter(option)),
  }
  return <News
    selectedEntity={selectedCompany}
    isLoading={companyStories.isLoading}
    topLevelDocuments={fixNews(companyStories.stories)}
    terms={terms}
    selectedTerm={selectedTerm}
    setSelectedTerm={setSelectedTerm}
    filtersProps={filtersProps}
    currentPage={page}
    pageCount={pageCount}
    onPageChange={setPage}
    isError={isError}
  />
}

function News({
  selectedEntity,
  isLoading,
  topLevelDocuments,
  terms,
  selectedTerm,
  setSelectedTerm,
  filtersProps,
  currentPage,
  pageCount,
  onPageChange,
  isError
}) {
  const dispatch = useDispatch()
  return (
    <div className='esg-news'>
      <h1>Top ESG News on <span className='highlight'>{selectedEntity?.name}</span></h1>
      {
        !selectedEntity ?
          <p>No data yet</p>
          :
          <>
            <Filters {...filtersProps} isError={isError}/>
            { (!isError) &&
              <>            
              { terms && terms.length ? <div className='keyword-filter'>Select one keyword to filter</div>: null }
              <TermsContainer data={terms} labelSelector='displayTerm' selectedTerm={selectedTerm}
                              onClick={(item, selected) => setSelectedTerm(selected ? null : item)}/>            
              </>
            }
            <Divider className='divider'/>
            <div className={classNames({ 'loading-news-overlay': isLoading })}>
              {isLoading ? (
                <div className='no-news-found'>
                  <p>Loading news data.</p>
                </div>
              ) : (topLevelDocuments === null ||
                (topLevelDocuments != null && topLevelDocuments.length === 0)) ? (
                <div className='no-news-found'>
                  {<p>No ESG data to display</p>}
                </div>
              ) : (
                <div className='paginated-table-container'>
                  <EsgDocumentList
                    search={selectedEntity}
                    topLevelDocuments={topLevelDocuments}
                    onFlaggingStateChange={(state) => {
                      dispatch(esgActions.updateDocumentFlaggedState(state))
                      onPageChange(currentPage);
                    }}
                    onPromoteStateChange={(state) => {
                      dispatch(esgActions.updateDocumentPromotedState(state))
                      onPageChange(currentPage);
                    }}
                    onExclude={(state) => {
                      dispatch(esgActions.updateDocumentExcludedState(state))
                      onPageChange(currentPage);
                    }}
                    maxDocs={10}
                  />
                  <PageSelector currentPage={currentPage} pageCount={pageCount} onPageChange={onPageChange}/>
                </div>
              )}
            </div>
          </>
      }
    </div>
  )
}

function fixNews(stories) {
  return stories?.map(story => story.document)?.filter(doc => doc && doc.id)?.map(doc => ({
    ...doc,
    publishedAt: new Date(doc.publishedAt)
  }))
}

export { IndustryNews, CompanyNews }
