import React from 'react'
import { render } from 'react-dom'
import store from 'app/store'

import ProfileAccount from './ProfileAccount'
import * as constants from './profile-account-constants'
import { init as profileAccountInit } from './profile-account-actions'
import reducer from './profile-account-reducer'
import saga from './profile-account-saga'

import AppWrapper from 'app/global/app-wrapper'


export function init() {
  store.dispatch(profileAccountInit())
  const component =
    <AppWrapper>
      <ProfileAccount/>
    </AppWrapper>
  const container = document.getElementById('profile-account-wrapper')
  render(component, container)
}

export {ProfileAccount, constants, reducer, saga}
