import {createAction} from 'redux-actions'

const PREFIX = 'ENTITIES'
const action = name => createAction(`${PREFIX}_${name}`)

export const update = action('UPDATE')
export const replace = action('REPLACE')
export const remove = action('REMOVE')

/**
 * Helper action to update one or more entities by model. `updates` can be an
 * array of entity objects or a single object.
 */
export const updateByModel = (Model, updates) => {
  const entities = {}
  if (!Array.isArray(updates)) {
    updates = [updates]
  }
  for (const update of updates) {
    entities[update.id] = update
  }
  return update({[Model.entityKey]: entities})
}
