import {graphqlQuery} from 'app/api'
import {args, gql} from 'app/graphql'


export function fetchDocuments(documentIds) {
  const query = gql`
    query {
      documents(ids: ${documentIds}) {
        id
        is_flagged
        insights
        headline
        link
        snippet
        feed
      }
    }
  `
  return graphqlQuery(query).then(({body: {data}}) => data)
}

export function fetchFeedsByIds(feedIds) {
  return graphqlQuery(
    gql`
      query {
        feeds(feedIds: ${feedIds}, isPaged: false) {
          items {
            id
            name
            absoluteUrl
            isPromoted
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}
