import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {startOfDay, subDays} from 'date-fns'

import InputBlock from 'app/common/InputBlock'
import Dropdown from 'app/common/Dropdown'
import Button from 'app/common/Button'
import DateRangePicker from 'app/common/DateRangePicker'
import {firmStats as firmStatsUrls} from 'app/urls'


export default class Header extends Component {
  static propTypes = {
    isStaff: PropTypes.bool,
    firms: PropTypes.arrayOf(PropTypes.object),
    usageValue: PropTypes.string,
    engagementValue: PropTypes.string,
    firmId: PropTypes.number,
    dataRange: PropTypes.object,

    onFirmChange: PropTypes.func,
    onDateRangeChange: PropTypes.func,
    onFetchStats: PropTypes.func,
  }

  static defaultProps = {
    dateRange: {
      start: subDays(new Date(), 2),
      end: startOfDay(new Date()),
    },

    onFirmChange: () => {},
    onDateRangeChange: () => {},
    onFetchStats: () => {},
  }

  render() {
    const usageDropdownOptions = [
      {
        value: null,
        label: '---',
      },
      {
        value: firmStatsUrls.usage(),
        label: 'Usage Report',
      },
      {
        value: firmStatsUrls.usageTotals(),
        label: 'Usage Totals Report',
      },
      {
        value: firmStatsUrls.sourceInteractions(),
        label: 'Top Sources by Interactions',
      },
      {
        value: firmStatsUrls.articleViews(),
        label: 'Article Views',
      },
      {
        value: firmStatsUrls.interactions(),
        label: 'Content Interactions',
      },
    ]

    const engagementDropdownOptions = [
      {
        value: null,
        label: '---',
      },
      {
        value: firmStatsUrls.dailySnapshot(),
        label: 'Daily Snapshot',
      },
      {
        value: firmStatsUrls.sixMonthSnapshot(),
        label: 'Monthly Snapshots',
      },
      {
        value: firmStatsUrls.tierComparison(),
        label: 'Tier Comparison',
      },
      {
        value: firmStatsUrls.tierInteractionComparison(),
        label: 'Tier Avg Interactions Comparison',
      },
      {
        value: firmStatsUrls.pendoStats(),
        label: 'Pendo Stats Report',
      },
      {
        value: firmStatsUrls.insightsCompanyEmails(),
        label: 'Insights Company Emails (all firms)',
      },
      {
        value: firmStatsUrls.insightsSummaryEmails(),
        label: 'Insights Summary Emails (all firms)',
      },
      {
        value: firmStatsUrls.insightsUserEmails(),
        label: 'Insights User Emails',
      },
    ]

    const usageDropdown =
      <InputBlock label="Usage">
        <Dropdown
          options={usageDropdownOptions}
          onChange={(value) => this.handleDropdownSelect(value)}
          value={this.props.usageValue || null}
        />
      </InputBlock>

    const engagementDropdown = this.props.isStaff &&
      <InputBlock label="Engagement">
        <Dropdown
          options={engagementDropdownOptions}
          onChange={(value) => this.handleDropdownSelect(value)}
          value={this.props.engagementValue || null}
        />
      </InputBlock>

    const firmDropdown = this.props.firms &&
      <InputBlock label="Firm">
        <Dropdown
          options={[{value: null, label: 'All Firms'}].concat([...this.props.firms.map(f => ({value: f.id, label: f.name}))])}
          onChange={(firmId) => this.props.onFirmChange(firmId)}
          value={this.props.firmId || null}
        />
      </InputBlock>


    return (
      <div className="firm-stats-header">
        <h2 className="firm-stats-title">Firm Stats</h2>

        {usageDropdown}

        {engagementDropdown}

        {firmDropdown}

        <DateRangePicker
          value={this.props.dateRange}
          onChange={(dateRange) => this.props.onDateRangeChange(dateRange)}
          className="date-range-picker"
          monthsBack={12}
        />

        <Button
          label="Fetch Stats"
          isPrimary={true}
          onClick={this.props.onFetchStats}
        />
      </div>
    )
  }

  handleDropdownSelect(value) {
    window.location.href = value
  }
}
