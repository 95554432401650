import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import styles from './TextBox.less'


/**
 * Generic text box component. Should replace <input type="text"> elements.
 */
export default class TextBox extends Component {
  static propTypes = {
    multiline: PropTypes.bool,
    isInvalid: PropTypes.bool,
  }

  static defaultProps = {
    multiline: false,
    isInvalid: false,
  }

  get value() {
    return this.input.value
  }

  focus() {
    return this.input.focus()
  }

  render() {
    const {className, multiline, isInvalid, ...restProps} = this.props
    if (multiline) {
      return (
        <textarea
          className={classNames(
            'text-box',
            'multiline',
            className,
            {[styles.invalid]: isInvalid},
          )}
          {...restProps}
          ref={el => this.input = el}
        />
      )
    }
    return (
      <input
        type="text"
        className={classNames(
          'text-box',
          className,
          {[styles.invalid]: isInvalid},
        )}
        {...restProps}
        ref={el => this.input = el} 
      />
    )
  }
}
