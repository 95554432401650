import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import classNames from 'classnames'
import {createSelector} from 'reselect'

import {getEntities} from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import {User} from 'app/models'
import LoadingOverlay from 'app/common/LoadingOverlay'
import Button from 'app/common/Button'
import TextBox from 'app/common/TextBox'
import InputBlock from 'app/common/InputBlock'
import Table from 'app/common/Table'
import Modal from 'app/common/Modal'
import ConfirmationModal from 'app/common/modals/ConfirmationModal'
import BulkUserAssignment, {ASSIGNEE_TYPES} from 'app/reusable/BulkUserAssignment'

import {getSettingsAdmin} from './firm-settings-selectors'
import {
  fetchDepartments,
  fetchTeams,
  deleteDepartments,
  deleteTeams,
  saveDepartment,
  saveTeam,
  saveDepartmentAssignments,
  saveTeamAssignments,
  setSelectedClassificationIds,
  setNewClassificationItemName,
  setDeleteClassificationIds,
  setEditClassificationModalData,
  hideEditClassificationModal,
  updateAssignModalData,
  resetAssignModalData,
} from './firm-settings-actions'

import './Classification.less'

export const CLASSIFICATION_TYPES = {
  DEPARTMENT: 'department',
  TEAM: 'team',
}


@connect(
  createSelector(
    [getEntities, getSettingsAdmin], (entities, settingsAdmin) => {
      const orm = Orm.withEntities(entities)
      return {
        isLoading: settingsAdmin.isLoading,
        users: orm.getByIds(User, settingsAdmin.userIds),
        selectedUserIds: settingsAdmin.selectedUserIds,
        departments: settingsAdmin.departments,
        teams: settingsAdmin.teams,
        selectedClassificationIds: settingsAdmin.selectedClassificationIds,
        newClassificationItemName: settingsAdmin.newClassificationItemName,
        deleteClassificationIds: settingsAdmin.deleteClassificationIds,
        editClassificationModalData: settingsAdmin.editClassificationModalData,
        assignModalData: settingsAdmin.assignModalData,
      }
    }
  ),
  {
    fetchDepartments,
    fetchTeams,
    deleteDepartments,
    deleteTeams,
    saveDepartment,
    saveTeam,
    saveDepartmentAssignments,
    saveTeamAssignments,
    setSelectedClassificationIds,
    setNewClassificationItemName,
    setDeleteClassificationIds,
    setEditClassificationModalData,
    hideEditClassificationModal,
    updateAssignModalData,
    resetAssignModalData,
  }
)
export default class Classification extends Component {
  static propTypes = {
    // CLASSIFICATION_TYPES
    type: PropTypes.string.isRequired,
  }

  state = {
    assignModalSection: ASSIGNEE_TYPES.USER,
  }

  componentDidMount() {
    // selections are shared between components; reset.
    this.props.setSelectedClassificationIds([])

    if (this.props.type === CLASSIFICATION_TYPES.DEPARTMENT && this.props.departments === null) {
      this.props.fetchDepartments()
    } else if (this.props.type === CLASSIFICATION_TYPES.TEAM && this.props.teams === null) {
      this.props.fetchTeams()
    }
  }

  render() {
    const { deleteClassificationIds, editClassificationModalData, assignModalData } = this.props

    const items = this.getItems()

    const itemsToDelete = this.getItemsByIds(deleteClassificationIds)

    const itemsToDeleteUserCount = itemsToDelete.reduce((total, d) => total + d.users.length, 0)

    const deleteModalItemsText = deleteClassificationIds.length === 1
      ? itemsToDelete[0].name
      : `these ${deleteClassificationIds.length} ${this.getItemLabel(true)}`

    const modalIsOpen = editClassificationModalData.id || assignModalData.id || deleteClassificationIds.length > 0

    const loader =
      this.props.isLoading
        ? <LoadingOverlay
            className={classNames({'modal-is-open': modalIsOpen})}
          />
        : null

    const editModal =
      editClassificationModalData.id
        ? <Modal
            showExit={false}
            className="edit-modal"
          >
            <h3 className="modal-header">Edit {this.getItemLabel()}</h3>

            <InputBlock label={`${this.getItemLabel()} Name`}>
              <TextBox
                value={this.props.editClassificationModalData.name}
                onChange={(evt) => this.handleEditModalNameChange(evt.target.value)}
              />
            </InputBlock>

            <div className="buttons">
              <Button
                isPlainText
                label="Cancel"
                onClick={() => this.closeEditModal()}
              />

              <Button
                isPrimary
                label="Save"
                onClick={() => this.saveItem()}
              />
            </div>
          </Modal>
        : null

    const assignModal =
      assignModalData.id
        ? <Modal
            onClose={() => this.closeAssignModal()}
          >
            <h3 className="modal-header">Update Assignments for {this.getItemById(assignModalData.id).name}</h3>

            <BulkUserAssignment
              users={this.props.users}
              onSectionChange={section => this.setState({assignModalSection: section})}
              onSelectedIdsChange={(ids) => this.handleUserSelection(ids)}
              showGroups={false}
              showIndividualMembers={true}
              selectedIdsBySection={{
                user: this.getAssignedUserIdsByItemId(assignModalData.id)
                // group ids (for individual members) are not tracked so cannot be set here
              }}
            />

            <div className="buttons">
              <Button
                isPlainText
                label="Cancel"
                onClick={() => this.closeAssignModal()}
              />

              <Button
                isPrimary
                label="Update Assignments"
                onClick={() => this.saveAssignments()}
              />
            </div>
          </Modal>
        : null

    const deleteModalWarning = itemsToDeleteUserCount === 0
      ? null
      : `There ${itemsToDeleteUserCount === 1 ? 'is' : 'are'} ${itemsToDeleteUserCount}
        ${itemsToDeleteUserCount === 1 ? 'user' : 'users'} currently
        assigned to ${deleteModalItemsText}. If you proceed,
        ${itemsToDeleteUserCount === 1 ? 'this user' : 'these users'} will be unassigned.`

    const deleteModal =
      deleteClassificationIds.length > 0
        ? <ConfirmationModal
            showExit={false}
            message={`Are you sure you want to delete ${deleteModalItemsText}? This action cannot be undone.`}
            secondaryMessage={deleteModalWarning}
            confirmButtonText="Delete"
            onConfirm={() => this.deleteClassificationItems()}
            onClose={() => this.closeDeleteModal()}
          />
        : null

    return (
      <div className="departments-container">
        {loader}

        {editModal}

        {assignModal}

        {deleteModal}

        <h3 className="header">{this.getItemLabel(true)}</h3>

        <div className="header-action-container">
          <InputBlock label={`New ${this.getItemLabel()} Name`}>
            <TextBox
              value={this.props.newClassificationItemName}
              onChange={(evt) => this.props.setNewClassificationItemName(evt.target.value)}
            />
          </InputBlock>

          <Button
            label={`Add ${this.getItemLabel()}`}
            isPrimary
            disabled={!this.props.newClassificationItemName}
            onClick={() => this.createClassificationItem()}
          />
        </div>

        <div className="header-action-container">
          <Button
            label="Delete Selected"
            disabled={this.props.selectedClassificationIds.length === 0}
            isDestructive
            onClick={() => this.props.setDeleteClassificationIds(this.props.selectedClassificationIds)}
          />
        </div>

        <Table
          data={items}
          defaultSort={{column: 'name', direction: 'asc'}}
        >
          <Table.Column
            name="checkbox"
            baseWidth={40}
            growRatio={0}
            shrinkRatio={0}
            cellContents={d =>
              <input
                className="checkbox"
                type="checkbox"
                onChange={(evt) => this.handleClassificationItemCheckbox(d.id, evt.target.checked)}
                checked={this.props.selectedClassificationIds.includes(d.id)}
              />
            }
          />

          <Table.Column
            name="name"
            label={`${this.getItemLabel()} Name`}
            baseWidth={400}
            growRatio={1}
            shrinkRatio={0}
            cellContents={R.prop('name')}
          />

          <Table.Column
            name="user-count"
            label="Number of Users"
            baseWidth={200}
            growRatio={1}
            shrinkRatio={0}
            cellContents={d => d.users.length}
          />

          <Table.Column
            name="actions"
            baseWidth={300}
            growRatio={1}
            shrinkRatio={0}
            cellContents={d => {
              return (
                <div className="actions">
                  <a className="action" onClick={() => this.props.setEditClassificationModalData({...d})}>Edit</a>
                  <a className="action" onClick={() => this.props.updateAssignModalData({id: d.id, assigneeIdsBySection: []})}>Assignments</a>
                  <a className="action" onClick={() => this.props.setDeleteClassificationIds([d.id])}>Delete</a>
                </div>
              )
            }}
          />
        </Table>
      </div>
    )
  }

  getItemLabel(isPlural = false) {
    let label = ''
    if (this.props.type === CLASSIFICATION_TYPES.DEPARTMENT) {
      label = 'Department'
    } else if (this.props.type === CLASSIFICATION_TYPES.TEAM) {
      label = 'Team'
    }
    if (isPlural) {
      label += 's'
    }
    return label
  }

  getItems() {
    if (this.props.type === CLASSIFICATION_TYPES.DEPARTMENT) {
      return this.props.departments || []
    } else if (this.props.type === CLASSIFICATION_TYPES.TEAM) {
      return this.props.teams || []
    }
  }

  getItemById(id) {
    const items = this.getItems()
    return items.find(item => item.id === id)
  }

  getAssignedUserIdsByItemId(id) {
    const item = this.getItemById(id)
    return item.users.map(u => u.id)
  }

  getItemsByIds(ids) {
    const items = this.getItems()
    return items.filter(d => ids.includes(d.id))
  }

  closeEditModal() {
    this.props.hideEditClassificationModal()
  }

  closeAssignModal() {
    this.props.resetAssignModalData()
  }

  closeDeleteModal() {
    this.props.setDeleteClassificationIds([])
  }

  saveItem() {
    const {editClassificationModalData} = this.props
    if (this.props.type === CLASSIFICATION_TYPES.DEPARTMENT) {
      this.props.saveDepartment(editClassificationModalData)
    } else if (this.props.type === CLASSIFICATION_TYPES.TEAM) {
      this.props.saveTeam(editClassificationModalData)
    }
  }

  saveAssignments() {
    if (this.props.type === CLASSIFICATION_TYPES.DEPARTMENT) {
      this.props.saveDepartmentAssignments()
    } else if (this.props.type === CLASSIFICATION_TYPES.TEAM) {
      this.props.saveTeamAssignments()
    }
  }

  createClassificationItem() {
    const {newClassificationItemName} = this.props
    if (this.props.type === CLASSIFICATION_TYPES.DEPARTMENT) {
      this.props.saveDepartment({name: newClassificationItemName})
    } else if (this.props.type === CLASSIFICATION_TYPES.TEAM) {
      this.props.saveTeam({name: newClassificationItemName})
    }
  }

  deleteClassificationItems() {
    const ids = [...this.props.deleteClassificationIds]
    if (this.props.type === CLASSIFICATION_TYPES.DEPARTMENT) {
      this.props.deleteDepartments(ids)
    } else if (this.props.type === CLASSIFICATION_TYPES.TEAM) {
      this.props.deleteTeams(ids)
    }
  }

  handleClassificationItemCheckbox(itemId, isChecked) {
    let selectedIds = [...this.props.selectedClassificationIds]
    if (isChecked) {
      selectedIds.push(itemId)
    } else {
      selectedIds = selectedIds.filter(id => id !== itemId)
    }
    this.props.setSelectedClassificationIds(selectedIds)
  }

  handleEditModalNameChange(name) {
    const editClassificationModalData = {...this.props.editClassificationModalData}
    editClassificationModalData.name = name
    this.props.setEditClassificationModalData(editClassificationModalData)
  }

  handleUserSelection(ids) {
    const assigneeIdsBySection = {...this.props.assignModalData.assigneeIdsBySection}
    assigneeIdsBySection[this.state.assignModalSection] = ids
    this.props.updateAssignModalData({assigneeIdsBySection})
  }
}
