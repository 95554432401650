import React, { Component } from 'react'
import InputBlock from 'app/common/InputBlock'
import { connect } from "react-redux"
import * as actions from "./dandb-search-actions"
import * as urls from "../../urls"
import {getDunAndBradstreet} from "./dandb-search-selectors"

@connect(
  state  => ({
        data: getDunAndBradstreet(state).data,
        parms: getDunAndBradstreet(state).parms,
        locations: getDunAndBradstreet(state).locations,
        editCountryID: getDunAndBradstreet(state).editCountryID,
  }),
  {
    fetchCompanies: actions.searchDnbData.request,
    saveParms: actions.saveParms,
    countryChange: actions.countryChange,
  }
  )
export default class DandBSearchHeader extends Component {

  static defaultProps = {
  }

  render() {
    let options = []
    const countryDropdown =
      <div>
          {
            this.props.locations.map(country_dict => {
          Object.keys(country_dict).forEach(key => {
            options.push(<option value={key}>
              {key}</option>)
          })})}
        <select className='select-country' onChange={event => this.onCountryClick(event)}
          defaultValue={this.props.editCountryID}>
          <option value={0}></option>
          {options}
        </select>
      </div>

    return (
      <div className="header">
          <table align="center">
            <tbody>
              <tr>
                <td className="cname">
                  <label className="input-block">Company Name</label>
                </td>
                <td className="cnametd">
                  <InputBlock label="">
                    <input className="cnamein"
                      type="text"
                      ref={element => this.searchname = element}
                    />
                  </InputBlock>
                </td>
                <td className="duns">
                  <label className="input-block">DUNS</label>
                </td>
                <td className="dunstd">
                  <InputBlock label="">
                    <input className="dunsin"
                      type="text"
                      ref={element => this.duns = element}
                    />
                  </InputBlock>
                </td>
                <td className="cnametd">
                  {<InputBlock label="">
                    <input
                      type="button"
                      value="Search"
                      onClick={
                        () => this.handlesearch()
                      }
                    />
                  </InputBlock>}
                </td>
                <td className="cnametdlink">
                  <a href={urls.dunAndBradstreet.exportExcel(this.props.parms)}
                     className="export-excel-link">Export to Excel&nbsp;<i id="export-firm-accounts-icon"
                                                             className="fa icon-off fa-download fa-lg"></i></a>
                </td>
              </tr>
              <tr>
                <td className="cname">
                  <label className="input-block">City</label>
                </td>
                <td className="cnametd">
                  <InputBlock label="">
                    <input className="cnamein"
                      type="text"
                      ref={element => this.cityname = element}
                    />
                  </InputBlock>
                </td>
                <td className="sic">
                  <label className="input-block">SIC Low</label>
                </td>
                <td className="sictd">
                  <InputBlock label="">
                    <input className="sicsin"
                      type="text"
                      ref={element => this.siccodelow = element}
                    />
                  </InputBlock>
                </td>
              </tr>
              <tr>
                <td className="cname">
                  <label className="input-block">Website</label>
                </td>
                <td className="cnametd">
                  <InputBlock label="">
                    <input className="cnamein"
                      type="text"
                      ref={element => this.website = element}
                    />
                  </InputBlock>
                </td>
                <td className="sic">
                  <label className="input-block">SIC High</label>
                </td>
                <td className="sictd">
                  <InputBlock label="">
                    <input className="sicsin"
                      type="text"
                      ref={element => this.siccodehigh = element}
                    />
                  </InputBlock>
                </td>
              </tr>
              <tr>
                <td className="cname">
                  <label className="input-block">Country</label>
                </td>
                <td className="cnametd">
                  {countryDropdown}
                </td>
              </tr>
            </tbody>
          </table>

      </div>
    )
  }

  // Event handlers
  onCountryClick(evt) {
    console.log(evt.target.value)
    this.props.countryChange({'country_id': evt.target.value})
  }

  handlesearch(){
    let parms = {search: this.searchname.value, city: this.cityname.value,
      webSite: this.website.value, duns: this.duns.value,
      siccodelow: this.siccodelow.value, siccodehigh: this.siccodehigh.value,
      country: this.props.editCountryID};

    this.props.saveParms(parms)

    this.props.onsubmit({search: this.searchname.value, city: this.cityname.value,
      webSite: this.website.value, duns: this.duns.value,
      siccodelow: this.siccodelow.value, siccodehigh: this.siccodehigh.value,
      country: this.props.editCountryID});
  }
}
