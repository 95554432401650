import classNames from 'classnames'
import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import {pipe, prop, reverse, sortBy, whereEq} from 'ramda'
import React, {cloneElement} from 'react'
import {findDOMNode} from 'react-dom'

import HealthScoreSvg from 'app/common/HealthScoreSvg'
import SizedContainer from 'app/common/SizedContainer'

import * as styles from './HealthBadge.less'

/**
 * The minimum size of a segment, as a ratio of the total surface area for that
 * valence, for the corresponding label to be displayed. Labels for segments
 * smaller than this are hidden.
 */
const MIN_SEGMENT_RATIO_FOR_LABEL = 0.02

const getOverlapAmount = (rect1, rect2) => {
  const leftmost = rect1.left < rect2.left ? rect1 : rect2
  const rightmost = leftmost === rect1 ? rect2 : rect1
  const topmost = rect1.top < rect2.top ? rect1 : rect2
  const bottommost = topmost === rect1 ? rect2 : rect1
  return {
    x: Math.max(0, leftmost.left + leftmost.width - rightmost.left),
    y: Math.max(0, topmost.top + topmost.height - bottommost.top),
  }
}

const getDistance = (point1, point2) =>
  Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2))

export default function HealthBadge({
  segments,
  valueTotal,
  healthScore,
  healthLabel,
  displaySegmentLabels,
  onSectionMouseOver,
  onSectionMouseOut,
  onSectionClick,
  className,
  ...restProps
}) {
  return (
    <div
      className={classNames(styles.healthBadge, className, 'health-badge')}
      {...restProps}
    >
      <div className={styles.rainbowContainer}>
        <SizedContainer
          className={styles.sizedContainer}
          render={(width, height) => (
            <HealthBadgeRainbow
              segments={segments}
              valueTotal={valueTotal}
              healthScore={healthScore}
              healthLabel={healthLabel}
              displaySegmentLabels={displaySegmentLabels}
              onSectionMouseOver={onSectionMouseOver}
              onSectionMouseOut={onSectionMouseOut}
              onSectionClick={onSectionClick}
              width={width}
              height={height}
            />
          )}
        />
      </div>
    </div>
  )
}
HealthBadge.propTypes = {
  segments: PropTypes.arrayOf(PropTypes.object).isRequired,
  valueTotal: PropTypes.number.isRequired,
  healthScore: PropTypes.number,
  healthLabel: PropTypes.string,
  displaySegmentLabels: PropTypes.bool,
  onSectionMouseOver: PropTypes.func,
  onSectionMouseOut: PropTypes.func,
  onSectionClick: PropTypes.func,
}

class HealthBadgeRainbow extends React.PureComponent {
  static propTypes = {
    segments: PropTypes.arrayOf(PropTypes.object).isRequired,
    valueTotal: PropTypes.number.isRequired,
    healthScore: PropTypes.number,
    displaySegmentLabels: PropTypes.bool,
    onSectionMouseOver: PropTypes.func,
    onSectionMouseOut: PropTypes.func,
    onSectionClick: PropTypes.func,

    width: PropTypes.number,
    height: PropTypes.number,
  }

  static defaultProps = {
    displaySegmentLabels: true,
  }

  render() {
    const {
      segments,
      valueTotal,
      healthScore,
      onSectionMouseOver,
      displaySegmentLabels,

      width = 0,
      height = 0,
    } = this.props

    const sortedSegments = pipe(
      sortBy(prop('value')),
      reverse,
    )(segments)
    const hasHighlightedSegment = sortedSegments.some(prop('isHighlighted'))

    // Layout config
    const aspectRatio = 2 // 2 = perfect circle
    const borderWidth = 1
    const scoreSectionSizeRatio = 0.45

    // Layout calculations
    const realWidth = Math.round(Math.min(width, height * aspectRatio))
    const realHeight = Math.round(Math.min(height, width / aspectRatio))
    const midX = realWidth / 2
    const innerWidth = realWidth - borderWidth * 2
    const innerHeight = realHeight - borderWidth * 2
    const scoreSectionWidth = innerWidth * scoreSectionSizeRatio
    const scoreSectionHeight = innerHeight * scoreSectionSizeRatio

    const makePaths = isPositive => {
      const relevantSegments = sortedSegments.filter(whereEq({isPositive}))

      const calculateArcPoint = ratio => {
        const multiplier = 1 - ratio
        return {
          x:
            midX +
            ((Math.cos((Math.PI / 2) * multiplier) * innerWidth) / 2) *
              (isPositive ? 1 : -1),
          y:
            (1 - Math.sin((Math.PI / 2) * multiplier)) * innerHeight +
            borderWidth,
        }
      }

      let accumulatedRatio = 0
      return relevantSegments.map((segment, index) => {
        const ratio = segment.value / valueTotal
        const arcStart = calculateArcPoint(accumulatedRatio)
        accumulatedRatio += ratio
        const arcEnd = calculateArcPoint(accumulatedRatio)

        return (
          <path
            className={classNames(styles.segment, {
              [styles.positive]: isPositive,
              [styles.negative]: !isPositive,
              [styles.faded]: hasHighlightedSegment && !segment.isHighlighted,
            })}
            d={`
              M ${midX} ${innerHeight + borderWidth}
              L ${arcStart.x} ${arcStart.y}
              A ${innerWidth / 2} ${innerHeight}, 0, 0, ${
              isPositive ? 1 : 0
            }, ${arcEnd.x} ${arcEnd.y}
              Z
            `}
            onMouseEnter={bind(this.onSegmentMouseEnter, this, segment.id)}
            onMouseLeave={bind(this.onSegmentMouseOut, this, segment.id)}
            onClick={bind(this.onSegmentClick, this, segment.id)}
            style={{cursor: onSectionMouseOver ? 'pointer' : 'inherit'}}
            key={index}
          />
        )
      })
    }

    const positivePaths = makePaths(true)
    const negativePaths = makePaths(false)

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          flex: '1 1 auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{width: realWidth, height: realHeight, position: 'relative'}}
        >
          <svg className={styles.rainbow} width={realWidth} height={realHeight}>
            <path
              className={styles.outline}
              d={`
                M 0 ${realHeight}
                a ${realWidth / 2} ${realHeight}, 0, 1, 1, ${realWidth} 0
                Z
              `}
            />
            <path
              className={styles.background}
              d={`
                M ${borderWidth} ${innerHeight + borderWidth}
                a ${innerWidth / 2} ${innerHeight}, 0, 1, 1, ${innerWidth} 0
                Z
              `}
            />

            <g>{positivePaths}</g>
            <g>{negativePaths}</g>

            <path
              className={styles.scoreSection}
              d={`
                M ${midX} ${innerHeight + borderWidth * 1.5}
                m ${-scoreSectionWidth / 2} 0
                a ${scoreSectionWidth /
                  2} ${scoreSectionHeight}, 0, 1, 1, ${scoreSectionWidth} 0
                Z
              `}
            />

            <HealthBadgeRainbowScore
              healthScore={healthScore}
              midX={midX}
              midY={innerHeight + borderWidth - scoreSectionHeight / 4}
            />
          </svg>
          {displaySegmentLabels &&
            <Labels
              segments={sortedSegments}
              valueTotal={valueTotal}
              badgeRadius={realHeight}
              chartStartX={midX - realWidth / 2}
            />}
        </div>
      </div>
    )
  }

  onSegmentMouseEnter(segmentId, event) {
    if (this.props.onSectionMouseOver) {
      this.props.onSectionMouseOver(segmentId, event)
    }
  }

  onSegmentMouseOut(segmentId, event) {
    if (this.props.onSectionMouseOut) {
      this.props.onSectionMouseOut(segmentId, event)
    }
  }

  onSegmentClick(segmentId) {
    if (this.props.onSectionClick) {
      this.props.onSectionClick(segmentId)
    }
  }
}

function HealthBadgeRainbowScore({healthScore, midX, midY}) {
    return (
    <g className={styles.healthScoreContainer}>
      <HealthScoreSvg
        score={healthScore}
        className={styles.healthScore}
        x={midX}
        y={healthScore === null ? midY + 20 : midY}
      />
    </g>
  )
}
HealthBadgeRainbowScore.propTypes = {
  healthScore: PropTypes.number,
  label: PropTypes.string,
  midX: PropTypes.number.isRequired,
  midY: PropTypes.number.isRequired,
}

class Labels extends React.PureComponent {
  static propTypes = {
    segments: PropTypes.arrayOf(PropTypes.object).isRequired,
    valueTotal: PropTypes.number.isRequired,
    badgeRadius: PropTypes.number.isRequired,
    chartStartX: PropTypes.number.isRequired,
  }

  state = {
    labelOverlaps: {},
  }

  componentDidMount() {
    this.calculateOverlaps()
  }

  render() {
    const {segments, valueTotal, badgeRadius, chartStartX} = this.props
    const {labelOverlaps} = this.state

    const originX = chartStartX + badgeRadius
    const radiusSizeRatio = 1.1
    const labelRadius = badgeRadius * radiusSizeRatio

    const xFromAngle = angle => labelRadius * Math.cos(angle)
    const yFromAngle = angle => labelRadius * Math.sin(angle)

    const makeIcons = isPositive => {
      const sign = isPositive ? 1 : -1
      let accumulatedRatio = 0
      let angleOverlapOffset = 0

      const relevantSegments = segments.filter(whereEq({isPositive}))
      return relevantSegments.map(segment => {
        const segmentSizeRatio = segment.value / valueTotal

        if (segmentSizeRatio < MIN_SEGMENT_RATIO_FOR_LABEL) {
          return null
        }

        // The angle is relative to the right-hand side of the badge (the (0,0)
        // point of the unit circle).
        const originalAngle =
          Math.PI / 2 -
          (Math.PI / 2) * (accumulatedRatio + segmentSizeRatio / 2) * sign
        let angle = originalAngle + angleOverlapOffset

        const point = {
          x: xFromAngle(angle),
          y: yFromAngle(angle),
        }

        const labelId = `${isPositive ? '+' : '-'}${segment.id}`
        if (labelOverlaps[labelId]) {
          const overlap = labelOverlaps[labelId]
          const oldAngle = angle

          // Try moving by both X and Y, and use whichever needs the smallest
          // change in angle.
          const possibleAngles = [
            Math.asin((point.y - overlap.y) / labelRadius) * sign +
              (isPositive ? 0 : Math.PI),
            Math.acos((point.x + overlap.x * sign) / labelRadius),
          ]
          if (
            !possibleAngles[1] ||
            Math.abs(angle - possibleAngles[0]) <
              Math.abs(angle - possibleAngles[1])
          ) {
            angle = possibleAngles[0]
          } else {
            angle = possibleAngles[1]
          }

          angleOverlapOffset += angle - oldAngle
          point.x = xFromAngle(angle)
          point.y = yFromAngle(angle)
        }

        accumulatedRatio += segmentSizeRatio

        let label = (
          <span data-label-id={labelId} className={styles.label}>
            {segment.label}
          </span>
        )

        const position = {
          bottom: `calc(${point.y}px - 0.5em)`,
        }
        if (isPositive) {
          position.left = `calc(${originX + point.x}px - 0.5em)`
        } else {
          position.right = `calc(${originX - point.x}px - 0.5em)`
        }

        label = cloneElement(label, {
          style: {
            position: 'absolute',
            ...position,
          },
          key: labelId,
        })

        let line
        {
          const pointOnBadge = {
            x: badgeRadius * 0.95 * Math.cos(originalAngle),
            y: badgeRadius * 0.95 * Math.sin(originalAngle),
          }
          const distance = getDistance(pointOnBadge, point)

          const deltaX = point.x - pointOnBadge.x
          const deltaY = point.y - pointOnBadge.y
          let rotateAngle = Math.atan2(deltaX, deltaY) - Math.PI / 2
          if (!isPositive) {
            rotateAngle += Math.PI
          }

          const position = {bottom: pointOnBadge.y}
          if (isPositive) {
            position.left = originX + pointOnBadge.x
          } else {
            position.right = originX - pointOnBadge.x
          }
          line = (
            <span
              className={styles.line}
              style={{
                transform: `rotate(${rotateAngle}rad)`,
                transformOrigin: `${isPositive ? 'left' : 'right'} center`,
                width: `calc(${distance}px - 0.5em)`,
                ...position,
              }}
            />
          )
        }

        return (
          <span className={styles.segmentLabel} key={labelId}>
            {label}
            {line}
          </span>
        )
      })
    }

    // This has to be wrapped by a real element so that we have a parent node to
    // reference in `calculateOverlaps`.
    return (
      <span>
        {makeIcons(false)}
        {makeIcons(true)}
      </span>
    )
  }

  calculateOverlaps() {
    const container = findDOMNode(this)
    const overlaps = {}
    let lastRect = null
    const labels = container.querySelectorAll(`.${styles.label}`)
    for (const label of Array.from(labels)) {
      const id = label.getAttribute('data-label-id')
      const rect = label.getBoundingClientRect()

      if (lastRect) {
        const overlap = getOverlapAmount(rect, lastRect)
        if (overlap.x && overlap.y) {
          overlaps[id] = overlap
        }
      }

      lastRect = rect
    }
    this.setState({labelOverlaps: overlaps})
  }
}
