import { apiCall } from 'app/api'

const options = {
  useCsrfCookie: true,
  dataFormat: 'json',
}


export function getReports() {
  const url = '/mis/api/get-reports/'
  return apiCall({url, method: 'GET'})
}

export function getArchives(reportType) {
  const url = '/mis/api/get-archives/'
  const data = {
    reportType: reportType,
  }
  return apiCall({url, method: 'GET', data: data})
}

export function getRecipients(reportType, reportId) {
  const url = '/mis/api/get-recipients/'
  const data = {
    reportType: reportType,
    reportId: reportId,
  }
  return apiCall({url, method: 'GET', data: data})
}

export function getRenderedReport(rp) {
  const url = '/api/v2/publish3r/view-rendered-publication/'
  const data = {
    id: rp.id,
  }
  if (rp.url) {
    data['url'] = rp.url
  }
  return apiCall({url, method: 'GET', data: data})
}

export function saveSubscription(data) {
  const url = '/mis/api/update-report-subscription/'
  return apiCall({url, method: 'POST', data, options})
}

export function updateFirmReport(data) {
  const url = '/mis/api/update-firm-report/'
  return apiCall({url, method: 'POST', data, options})
}

export function subscribeAllUsers(data) {
  const url = '/mis/api/subscribe-all-users/'
  return apiCall({url, method: 'POST', data, options})
}
