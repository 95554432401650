export const stringFormat = (str, ...val) => {
    for (let index = 0; index < val.length; index++) {
      str = str.replace(`{${index}}`, val[index]);
    }
    return str;
  };

  export const topCountriesList = (countries) => {
    return [
      {id: 0, name: 'All Countries'},
      countries.find(c => c.name === 'Australia'),
      countries.find(c => c.name === 'Canada'),
      countries.find(c => c.name === 'United Kingdom'),
      countries.find(c => c.name === 'United States'),
    ]
  };
  