import React from 'react'

import ConfirmationModal from 'app/common/modals/ConfirmationModal'

import './DeleteSearchesConfirmationModal.less'
import PropType from 'prop-types'

DeleteSearchesConfirmationModal.propTypes = {
  deleteMessage: PropType.string,
}
DeleteSearchesConfirmationModal.defaultProps = {
  deleteMessage: '',
}
export default function DeleteSearchesConfirmationModal({
  searches,
  onClose,
  onConfirm,
  deleteMessage
}) {
  const usedByWarning =
    searches.length === 1
      ? searches[0].usedCount > 0
        ? <div className="used-by-warning">
            {`Warning: ${searches[0].usedCount === 1 ? 'another search is' : `${searches[0].usedCount} other searches are`}  
            dependent on the search being deleted. ${searches[0].usedCount === 1 ? 'It' : `They`} will be unaffected, but 
            you will not be able to use this deleted search as a term or filter in the future.`}
          </div>
        : null
      : searches.filter(ss => ss.usedCount > 0).length > 0
        ? <div className="used-by-warning">
            {`Warning: some of the searches you are deleting are relied upon by other searches. Those other searches 
            will be unaffected, but you will not be able to use the deleted searches as a term or filter in the future.`}
          </div>
        : null

  const deleteConfirmationMessage =
    <React.Fragment>
      <div style={{lineBreak: "normal"}}>
        {
          `Are you sure you would like to delete ${
            searches.length === 1
              ? searches[0].name
              : `these ${searches.length} selected searches `
          } ${deleteMessage}?`
        }
      </div>
      {usedByWarning}
    </React.Fragment>

  return (
    <ConfirmationModal
      message={deleteConfirmationMessage}
      secondaryMessage="This action cannot be undone."
      isDestructive={true}
      confirmButtonText="Yes"
      onClose={onClose}
      onConfirm={onConfirm}
    />
  )
}
