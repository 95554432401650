import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import Orm from 'app/framework/Orm'
import {SavedSearch} from 'app/models'
import Modal from 'app/common/Modal'
import InputBlock from 'app/common/InputBlock'
import TextBox from 'app/common/TextBox'
import Button from 'app/common/Button'
import Tooltip from 'app/common/Tooltip'
import {
  getAppName,
  getAppNameFull,
  getInsightsLabel,
  getCurrentFirmLibraryName,
  getInsightsEnabled,
  getCurrentUserId,
  getProfileSearchIds,
} from 'app/global/global-selectors'
import {getEntities} from 'app/entities/entities-selectors'

import {
  getCategory,
  getCategoryLabel,
  getCategoryLabelPlural,
  getIsFirmLibraryGroup,
  getIsProspectCategory,
  getBrowseModalData,
  getIsLoading,
  getGlobalSearchIdsByCategory,
  getFirmLibrarySearchIdsByCategory,
  getFeaturedSearchIdsByCategory,
} from './profile-searches-selectors'
import {
  fetchBrowseModalSearches,
  setBrowseModalSelectedIds,
  setBrowseModalRemovedIds,
  resetBrowseModalData,
  applyBrowseModalSearches,
} from './profile-searches-actions'
import {
  BROWSE_TYPE_GLOBAL,
  BROWSE_TYPE_FIRM_LIBRARY,
  BROWSE_TYPE_FEATURED,
} from './profile-searches-constants'


@connect(
  createSelector(
    [
      getEntities,
      getAppName,
      getAppNameFull,
      getInsightsLabel,
      getCurrentFirmLibraryName,
      getInsightsEnabled,
      getCurrentUserId,
      getProfileSearchIds,
      getCategory,
      getCategoryLabel,
      getCategoryLabelPlural,
      getIsFirmLibraryGroup,
      getIsProspectCategory,
      getBrowseModalData,
      getIsLoading,
      getGlobalSearchIdsByCategory,
      getFirmLibrarySearchIdsByCategory,
      getFeaturedSearchIdsByCategory,
    ],
    (
      entities,
      appName,
      appNameFull,
      insightsLabel,
      currentFirmLibraryName,
      insightsEnabled,
      currentUserId,
      profileSearchIds,
      category,
      categoryLabel,
      categoryLabelPlural,
      isFirmLibraryGroup,
      isProspectCategory,
      browseModalData,
      isLoading,
      globalSearchIdsByCategory,
      firmLibrarySearchIdsByCategory,
      featuredSearchIdsByCategory
    ) => {
      const orm = Orm.withEntities(entities)
      const type = browseModalData.type
      let searchIds = []
      if (type === BROWSE_TYPE_GLOBAL && globalSearchIdsByCategory.hasOwnProperty(category)) {
        searchIds = globalSearchIdsByCategory[category]
      } else if (type === BROWSE_TYPE_FIRM_LIBRARY && firmLibrarySearchIdsByCategory.hasOwnProperty(category)) {
        searchIds = firmLibrarySearchIdsByCategory[category]
      } else if (type === BROWSE_TYPE_FEATURED && featuredSearchIdsByCategory.hasOwnProperty(category)) {
        searchIds = featuredSearchIdsByCategory[category]
      }
      return {
        currentFirmLibraryName,
        currentUserId,
        isLoading,
        browseModalData,
        category,
        categoryLabel,
        categoryLabelPlural,
        firmLibrarySearchIdsByCategory,
        globalSearchIdsByCategory,
        featuredSearchIdsByCategory,
        userSearches: orm.getByIds(SavedSearch, profileSearchIds), // searches owned by the current user
        modalSearches: orm.getByIds(SavedSearch, searchIds || []), // global or FL searches
      }
    }
  ),
  {
    fetchBrowseModalSearches,
    setBrowseModalSelectedIds,
    setBrowseModalRemovedIds,
    resetBrowseModalData,
    applyBrowseModalSearches,
  }
)
export default class BrowseModal extends Component {
  state = {
    nameFilter: '',
  }

  componentDidMount() {
    const {
      browseModalData,
      firmLibrarySearchIdsByCategory,
      globalSearchIdsByCategory,
      featuredSearchIdsByCategory,
    } = this.props
    const fetchParams = {category: this.props.category, type: browseModalData.type}
    if (
      browseModalData.type === BROWSE_TYPE_GLOBAL &&
      !firmLibrarySearchIdsByCategory.hasOwnProperty(browseModalData.category)
    ) {
      this.props.fetchBrowseModalSearches({...fetchParams, type: BROWSE_TYPE_GLOBAL})
    } else if (
      browseModalData.type === BROWSE_TYPE_FIRM_LIBRARY &&
      !globalSearchIdsByCategory.hasOwnProperty(browseModalData.category)
    ) {
      this.props.fetchBrowseModalSearches({...fetchParams, type: BROWSE_TYPE_FIRM_LIBRARY})
    } else if (
      browseModalData.type === BROWSE_TYPE_FEATURED &&
      !featuredSearchIdsByCategory.hasOwnProperty(browseModalData.category)
    ) {
      this.props.fetchBrowseModalSearches({...fetchParams, type: BROWSE_TYPE_FEATURED})
    }
  }

  render() {
    const searchType =
      this.props.browseModalData.type === BROWSE_TYPE_FIRM_LIBRARY
        ? this.props.currentFirmLibraryName
        : 'Featured'

    return (
      <Modal
        isOpen={true} // need control this from the parent so that `componentDidMount` fires.
        onClose={() => this.props.resetBrowseModalData()}
        className="browse-modal"
      >
        <h1>Select {this.props.categoryLabelPlural}</h1>

        {
          [BROWSE_TYPE_FIRM_LIBRARY, BROWSE_TYPE_FEATURED].includes(this.props.browseModalData.type) &&
          <p>
            {searchType} {this.props.categoryLabelPlural} are {this.props.categoryLabel} Search
            Queries that have been created and promoted by administrators in your firm. They will often include specialty
            filters and promoted sources that are relevant to your firm.
          </p>
        }

        {
          this.props.modalSearches.length === 0 && this.props.browseModalData.type === BROWSE_TYPE_FEATURED
            ? <h3>No Featured {this.props.categoryLabelPlural} have been assigned to you yet.</h3>
            : <>
                <InputBlock
                  label={`Filter by ${this.props.categoryLabel} Name`}
                >
                  <TextBox
                    value={this.state.nameFilter}
                    onChange={(evt) => this.handleFilterChange(evt.target.value)}
                  />
                </InputBlock>

                <div className="browse-modal-list">
                  {
                    this.props.modalSearches.filter(ss => {
                      return !this.state.nameFilter || ss.name.toLowerCase().includes(this.state.nameFilter.toLowerCase())
                    }).map(ss => {
                      const label =
                        <span>
                          {ss.name}
                          {
                            ss.notes &&
                            <span className="notes-tooltip">
                              <Tooltip
                                label={ss.notes}
                              >
                                <i className="fa fa-sticky-note-o" aria-hidden="true"/>
                              </Tooltip>
                            </span>
                          }
                        </span>

                      return (
                        <InputBlock key={ss.id} label={label} isInline>
                          <input
                            type="checkbox"
                            checked={this.props.browseModalData.selectedIds.includes(ss.id)}
                            onChange={(evt) => this.handleCheckbox(ss.id, evt.target.checked)}
                          />
                        </InputBlock>
                      )
                    })
                  }
                </div>
              </>
        }

        <div className="buttons">
          <Button
            label="Update"
            disabled={
              this.props.browseModalData.selectedIds.length === 0 &&
              this.props.browseModalData.removedIds.length === 0
            }
            onClick={() => this.save()}
          />
        </div>
      </Modal>
    )
  }

  handleFilterChange(nameFilter) {
    this.setState({nameFilter})
    this.props.setBrowseModalSelectedIds([])
    this.props.setBrowseModalRemovedIds([])
  }

  handleCheckbox(id, checked) {
    let selectedIds = [...this.props.browseModalData.selectedIds]
    let removedIds = [...this.props.browseModalData.removedIds]
    /**
     * if current user already owns a copy of the selected/deselected search, grab it to we can add/remove
     * it's id from `removedIds`.
     */
    const userSearch = this.props.userSearches.find(ss => ss.copyOfId === id)
    if (checked) {
      selectedIds.push(id)
      if (userSearch) {
        removedIds = removedIds.filter(id_ => id_ !== userSearch.id)
      }
    } else {
      selectedIds = selectedIds.filter(id_ => id_ !== id)
      if (userSearch) {
        removedIds.push(userSearch.id)
      }
    }
    this.props.setBrowseModalSelectedIds(selectedIds)
    this.props.setBrowseModalRemovedIds(removedIds)
  }

  save() {
    this.props.applyBrowseModalSearches({
      type: this.props.browseModalData.type,
      category: this.props.category,
      ids: [...this.props.browseModalData.selectedIds],
      removedIds: [...this.props.browseModalData.removedIds],
    })
  }
}
