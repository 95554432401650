import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {defaultPrevented} from 'app/utils'

import * as actions from '../../profile-preferences-actions'
import * as selectors from '../../profile-preferences-selectors'
import {Checklist} from '../common/checklist'

import ShowMeModal from './ShowMeModal'

import styles from './ContentBoosters.less'


export default function ContentBoosters() {
  const boosters = useSelector(selectors.getBoosters)
  const loadingIds = useSelector(selectors.getBoosterLoadingIds)
  const boosterForShowMeModal = useSelector(selectors.getBoosterForShowMeModal)
  const dispatch = useDispatch()
  const openShowMeModal = booster => {
    dispatch(actions.setBoosterShowMeModalId(booster.id))
  }
  const closeShowMeModal = () => {
    dispatch(actions.setBoosterShowMeModalId(null))
  }
  const items = boosters.map(booster => ({
    key: booster.id,
    isChecked: booster.isApplied,
    isLoading: loadingIds.includes(booster.id),
    label: (
      <div className={styles.booster}>
        <div>{booster.name}</div>
        <div className={styles.description}>
          {booster.description}
          <div>
            <a onClick={defaultPrevented(() => openShowMeModal(booster))}>
              Show me
            </a>
          </div>
        </div>
      </div>
    ),
    onChange: isApplied => {
      dispatch(
        actions.changeBoosterStateRequest({id: booster.id, isApplied})
      )
    },
  }))
  return (
    <React.Fragment>
      {boosterForShowMeModal && (
        <ShowMeModal
          articles={boosterForShowMeModal.showMe.articles}
          header={boosterForShowMeModal.showMe.header}
          description={boosterForShowMeModal.showMe.description}
          onClose={closeShowMeModal}
        />
      )}
      <Checklist items={items} />
    </React.Fragment>
  )
}
