import React from 'react'
import classNames from 'classnames'
import {HashLink} from 'react-router-hash-link'
import {scrollTargetIds} from 'app/esg/Navigation/NavConfig'

import * as styles from './BackLink.less'

function BackLink({className}) {
  return (
    <>
      <HashLink
        to={`/#${scrollTargetIds.company}`}
        className={classNames(styles.link, className)}
      >
        ← Back to ESG Companies
      </HashLink>
    </>
  )
}

export default BackLink
