import classNames from 'classnames'
import is from 'is'
import PropTypes from 'prop-types'
import { identity } from 'ramda'
import React, { Component } from 'react'

import './style.less'
import Tooltip from "app/common/Tooltip"
import {titleCase} from 'title-case'
import InlineSvg from "app/common/InlineSvg"
import { TWITTER_TOOLTIP_MESSAGE } from 'app/constants'

/*
 * Generic tabbed container class. Example:
 *
 *   <TabbedContainer>
 *     <Tab name="tab1" label="First Tab">
 *       <SomeContent />
 *     </Tab>
 *     <Tab name="tab2" label="Second Tab">
 *       <SomeOtherContent />
 *     </Tab>
 *   </TabbedContainer>
 */
export class TabbedContainer extends Component {
  static propTypes = {
    tabName: PropTypes.string,
    defaultTabName: PropTypes.string,
    onTabChange: PropTypes.func,

    /**
     * renderAll is used to force content for all tabs to be rendered (but hidden), not just the current one.
     */
    renderAll: PropTypes.bool,

    className: PropTypes.string,
    tabsClassName: PropTypes.string,
    tabClassName: PropTypes.string,
    activeTabClassName: PropTypes.string,
    contentClassName: PropTypes.string,

    // temporary until all uses are updated
    useUpdatedCss: PropTypes.bool,
  }

  static getDerivedStateFromProps(props, state) {
    if (props.tabName && props.tabName !== state.currentTabName) {
      return {...state, currentTabName: props.tabName}
    }
    return state
  }

  constructor(props) {
    super(props)
    this.state = {
      currentTabName:
        props.tabName
        || props.forceTabName
        || props.defaultTabName
        || this.getTabs()[0].name,
    }
  }

  getTabs() {
    return React.Children.map(
      this.props.children,
      component => component && ({
        name: component.props.name,
        label: component.props.label,
        className: component.props.className,
        count: component.props.count,
        getContent:
          is.function(component.props.children)
            ? component.props.children
            : () => component.props.children,
      }),
    ).filter(identity) // Make sure to filter out non-values
  }

  render() {
    const tabs = this.getTabs()
    const renderedTabs = tabs.map(tab => this.renderTab(tab))
    const {getContent} = tabs.find(tab => tab.name === this.state.currentTabName)
    return (
      <div className={classNames('tabbed-container', this.props.className, {'use-updated-css': this.props.useUpdatedCss})}>
        <div className={classNames('tabs', this.props.tabsClassName)}>
          {renderedTabs}
        </div>
        <div className={classNames('content', this.props.contentClassName)}>
          {getContent()}
        </div>
        {this.props.renderAll &&
          <div className="hidden-content">
            {
              tabs.filter(t => t.name !== this.state.currentTabName).map(t => {
                return t.getContent()
              })
            }
          </div>
        }
      </div>
    )
  }

  renderTab(tab) {
    const isActive = this.state.currentTabName === tab.name
    let toolTipText;
    if (tab.name.toLowerCase() === "twitter") {
      toolTipText = TWITTER_TOOLTIP_MESSAGE
    } else {
      toolTipText = tab.count && tab.count === 1
        ? `${tab.count} ${titleCase(tab.name)} result was found.`
        : tab.count && tab.count > 1
          ? `${tab.count} ${titleCase(tab.name)} results were found. However, duplicates have 
          been excluded from the results below.`
          : null
    }
    const countTip = toolTipText
      ? <Tooltip
          onClick={(e) => this.countTipOnClick(e)}
          label={toolTipText}
        >
          <InlineSvg
            onClick={(e) => this.countTipOnClick(e)}
            className={classNames('count-tip', {'active': isActive})}
            src="/media/img/help-tooltip-icon.svg"
          />
        </Tooltip>
      : null

    return (
      <div
        className={classNames(
          'tab',
          this.props.tabClassName,
          tab.className,
          isActive && this.props.activeTabClassName,
          {active: isActive},
        )}
        onClick={() => this.setTab(tab)}
        key={tab.label}
      >
        {tab.label}
        {countTip}
      </div>
    )
  }

  setTab(tab) {
    if (!this.props.tabName) {
      this.setState({currentTabName: tab.name})
    }
    if (this.props.onTabChange) {
      this.props.onTabChange(tab.name)
    }
  }
  countTipOnClick(evt) {
    evt.stopPropagation()
  }
}


export class Tab extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    count: PropTypes.number,
  }
}


export default TabbedContainer
