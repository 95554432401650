import {connect} from 'react-redux'
import React, { Component } from 'react'
import {defaultPrevented} from 'app/utils'
import {createSelector} from 'reselect'

import Orm from 'app/framework/Orm'
import {SavedSearch} from 'app/models'
import TextBox from 'app/common/TextBox'
import DeleteSearchesConfirmationModal from 'app/reusable/DeleteSearchesConfirmationModal'
import {getProfileSearchIds} from 'app/global/global-selectors'
import {getEntities} from 'app/entities/entities-selectors'

import {
  getCategory,
  getCategoryLabel,
  getCategoryLabelPlural,
} from './profile-searches-selectors'
import {
  showEditSearchModal,
  deleteSearches,
} from './profile-searches-actions'
import { FILTER_FIELDS_WITHOUT_URL, FILTER_FIELD_ALL, FILTER_FIELD_LABELS, FILTER_FIELD_NAME } from '../sources/profile-sources-constants'
import InputBlock from 'app/common/InputBlock'

@connect(
  createSelector(
    [
      getProfileSearchIds,
      getEntities,
      getCategory,
      getCategoryLabel,
      getCategoryLabelPlural,
    ],
    (
      profileSearchIds,
      entities,
      category,
      categoryLabel,
      categoryLabelPlural,
    ) => {
      const orm = Orm.withEntities(entities)
      return {
        searches: orm.getByIds(SavedSearch, profileSearchIds),
        category,
        categoryLabel,
        categoryLabelPlural,
      }
    }
  ),
  {
    showEditSearchModal,
    deleteSearches,
  },
)
export default class Searches extends Component {
  state = {
    selectedSearchIds: [],
    filter: '',
    filterField: FILTER_FIELD_ALL,
    isDeleteConfirmationModalShown: false,
  }

  render() {
    if (!this.props.searches.length) return null

    const searches = this.filteredSearches

    const areAllSearchesSelected = searches.length === this.state.selectedSearchIds.length

    const bulkDelete =
      searches.length > 0 &&
      <div className="bulk-delete grid cols-inline-block">
        <input
          className="select-all"
          type="checkbox"
          checked={areAllSearchesSelected}
          onChange={e => this.selectAllSearches(e.target.checked)}
        />
          <button
          className="button destructive"
          onClick={() => this.showDeleteConfirmation()}
          disabled={!this.state.selectedSearchIds.length}
        >
          Delete
        </button>
      </div>

    const deleteConfirmationModal =
      this.state.isDeleteConfirmationModalShown &&
      <DeleteSearchesConfirmationModal
        searches={searches.filter(ss => this.state.selectedSearchIds.includes(ss.id))}
        onClose={() => this.hideDeleteConfirmation()}
        onConfirm={() => this.deleteSearches()}
      />

    const searchesList = searches.map(search => {
      const prospect =
        search.category === 'prospect' &&
        <i className="sub-label">Prospect</i>

      const signalsSearch =
        search.signalsEnabled &&
        this.props.signalsEnabledUser &&
        <span className="icon-transparent"/>

      return (
         <div
          key={search.id}
          className="grid item cols-inline-block searches-list-item"
        >
          <div className="col-6-10 name-container">
            <input
              type="checkbox"
              className='check-box'
              onChange={(event) => this.searchSelectionChanged(search, event.target.checked)}
              checked={this.state.selectedSearchIds.includes(search.id)}
            />
              <a
                className="name ss-name"
                onClick={defaultPrevented(() => this.props.showEditSearchModal(search.id))}
              >
                <span>{search.name}{prospect}</span>
              </a>
          </div>
          <div className="col-4-10 labels-buttons-container">
            {search.labels ? <div className='labels'>
              {
                search.labels.map(label => {
                  return <div key={label.id} className="label-token">{label.name}</div>
                })
              }
            </div> : null}
            <a
              className="button button-group"
              onClick={defaultPrevented(() => this.props.showEditSearchModal(search.id))}
            >
              Edit
            </a>
            {signalsSearch}
          </div>
        </div>
      )
    })

    const noDataView = <div className='no-data-view'>
      No {this.props.categoryLabel} Found
    </div>

    return (
      <div id="search-list" className="field-group">
        <h4 className="group-heading">
          Edit Existing {this.props.categoryLabelPlural}
        </h4>

        <TextBox
          className="search"
          placeholder={`Find ${this.props.categoryLabelPlural}...`}
          onChange={e => this.handleFilterChange(e.target.value)}
        />

        <div className="search-by">
          <div className="description">Search By:</div>
          {
            Object.entries(FILTER_FIELDS_WITHOUT_URL).map(([value, label]) => {
              return (
                <InputBlock key={value} label={label} isInline>
                  <input
                    type="radio"
                    value={value}
                    checked={this.state.filterField === value}
                    disabled={!this.state.filter}
                    onChange={() => this.setState({filterField: value})}
                  />
                </InputBlock>
              )
            })
          }
        </div>

        {bulkDelete}

        {deleteConfirmationModal}

        {searches.length > 0 ?
          <div className="search-list searches-list">
            {searchesList}
          </div>
          : noDataView}
        
      </div>
    )
  }

  get filteredSearches() {
    const categories = [this.props.category]
    const {filter, filterField} = this.state
    if (this.props.category === 'client') {
      categories.push('prospect')
    }
    return this.props.searches.filter(search => {
      if (!categories.includes(search.category)){
        return false
      }

      const filterName = search.name.toLowerCase().includes(filter.toLowerCase())
      const filterLabel = search.labels && search.labels.some(label => label.name.toLowerCase().includes(filter.toLowerCase()))

      if (!filter) {
        return true
      }
      else if (filterField == FILTER_FIELD_ALL) {
        return filterName || filterLabel
      }
      else if (filterField == FILTER_FIELD_NAME) {
        return filterName
      }
      else if (filterField == FILTER_FIELD_LABELS) {
        return filterLabel
      }
    }).sort((s1, s2) => {
      const name1 = s1.name
      const name2 = s2.name
      return name1.localeCompare(name2)
    })
  }

  handleFilterChange(filter) {
    this.setState({filter, selectedSearchIds: []})
  }

  selectAllSearches(select) {
    if (select) {
      this.setState({selectedSearchIds: this.filteredSearches.map(ss => ss.id)})
    } else {
      this.setState({selectedSearchIds: []})
    }
  }

  showDeleteConfirmation() {
    this.setState({isDeleteConfirmationModalShown: true})
  }

  hideDeleteConfirmation() {
    this.setState({isDeleteConfirmationModalShown: false})
  }

  searchSelectionChanged(search, checked) {
    if (checked) {
      this.setState({selectedSearchIds: [...this.state.selectedSearchIds, search.id]})
    } else {
      const ids = this.state.selectedSearchIds.filter(id => id !== search.id)
      this.setState({selectedSearchIds: ids})
    }
  }

  deleteSearches() {
    this.props.deleteSearches(this.state.selectedSearchIds)
    this.setState({selectedSearchIds: [], isDeleteConfirmationModalShown: false})
  }
}
