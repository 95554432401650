import React, {useState, useCallback} from "react"
import * as dateFns from 'date-fns'
import {formatRelativeDate, newUtcDate, truncateText} from "app/utils"
import classNames from "classnames"
import styles from "./style.less"
import InteractionBar from "app/reusable/InteractionBar"
import Tooltip from "app/common/Tooltip"
import InsightSummary from "app/reusable/insight-summary"
import Highlighter from "react-highlight-words"
import {emptyArray} from "app/utils/empty"
import DocumentFeedDisplay from "app/reusable/DocumentFeedDisplay"
import PropTypes from "prop-types"
import {useSelector} from "react-redux"
import * as globalSelectors from "app/global/global-selectors"
import * as helpQuestions from "app/global/help-questions"
import {truncateWords} from "app/utils/strings"
import * as dashboardSelectors from 'app/dashboard/dashboard-selectors'


// Since the shape of the document object that InteractionBar expects is
// slightly different, we need to transform it as such.
const articleForInteractionBar = document => ({
  ...document,
  directorId: document.id,
  feedId: document.feed && document.feed.id,
  signals: document.insights,
  signalsInterimUrl: document.insights && document.insights.interimUrl,
  displayHeadlineShort: truncateWords(document.headline, 10),
  source: document.feed && document.feed.name
})


function GroupedDocument({
  search,
  document,
  isSelected,
  onSelectChange,
  onFlaggingStateChange,
  onPromoteStateChange,
  onExclude,
  isComparisonDashboard,
}) {
  return (
    <div className={styles.groupedDocument}>
      <div
        className={classNames(styles.content,
          {[styles.isComparisonDashboard]: isComparisonDashboard})}
      >
        {!isComparisonDashboard &&
          <label className={styles.checkbox}>
            <input
              type="checkbox"
              checked={isSelected}
              onChange={event =>
                onSelectChange({id: document.id, selected: event.target.checked})
              }
            />
          </label>
        }
        <a href={document.link} target="_blank" className={styles.headline}>
          {truncateText(document.headline, 48)}
        </a>
        {document.feed && (
          <DocumentFeedDisplay document={document} isGrouped={true} />
        )}
      </div>

      <InteractionBar
        search={search}
        article={articleForInteractionBar(document)}
        isFlagged={!!document.isFlagged}
        isPromoted={document.feed ? document.feed.isPromoted : false}
        isSignalsEnabled={false}
        includeExcludeFromSearchOption={true}
        onFlaggingStateChange={onFlaggingStateChange}
        onPromoteStateChange={onPromoteStateChange}
        onExclude={onExclude}
        className={styles.groupInteractionBar}
      />
    </div>
  )
}
GroupedDocument.propTypes = {
  search: PropTypes.object,
  document: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onFlaggingStateChange: PropTypes.func.isRequired,
  onPromoteStateChange: PropTypes.func.isRequired,
  onExclude: PropTypes.func.isRequired,
  isComparisonDashboard: PropTypes.bool,
}


function GroupDocuments({
  search,
  documents,
  selectedIds,
  onSelectChange,
  onFlaggingStateChange,
  onPromoteStateChange,
  onExclude,
  isComparisonDashboard,
}) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className={styles.grouping}>
      <a onClick={() => setIsOpen(!isOpen)} className={styles.similarArticles}>
        <i
          className={`fa fa-fw toggle-icon fa-angle-${isOpen ? 'down' : 'right'}`}
          aria-hidden="true"
        />
        <img src="/media/img/grouping-icon-01.png" alt="Article Group" />
        {documents.length} similar article{documents.length > 1 && 's'}
      </a>

      {isOpen && (
        <div className={styles.documents}>
          {documents.map(document =>
            <GroupedDocument
              search={search}
              document={document}
              isSelected={selectedIds.includes(document.id)}
              onSelectChange={onSelectChange}
              onFlaggingStateChange={onFlaggingStateChange}
              onPromoteStateChange={onPromoteStateChange}
              onExclude={onExclude}
              isComparisonDashboard={isComparisonDashboard}
              key={document.id}
            />
          )}
        </div>
      )}
    </div>
  )
}
GroupDocuments.propTypes = {
  search: PropTypes.object,
  documents: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onFlaggingStateChange: PropTypes.func.isRequired,
  onPromoteStateChange: PropTypes.func.isRequired,
  onExclude: PropTypes.func.isRequired,
  isComparisonDashboard: PropTypes.bool,
}


export default function ResultDocument({
  document,
  onFlaggingStateChange,
  onPromoteStateChange,
  onExclude,
  isDashboard,
  search,
  groupDocuments,
  selectedIds,
  onSelectChange,
  compareDocuments,
  isComparisonDashboard
}) {

  // TODO: Do we even need react-highlight-words? All we're using it for is to
  // pass an array of "chunks" to highlight.
  const findHeadlineChunks = useCallback(
    // Need to clone the array because react-highlight-words mutates it.
    () => [...document.headlineHighlightLocations],
    [document.headlineHighlightLocations],
  )
  const findSnippetChunks = useCallback(
    // Need to clone the array because react-highlight-words mutates it.
    () => [...document.snippetHighlightLocations],
    [document.snippetHighlightLocations],
  )

  const timezoneOffset = useSelector(globalSelectors.getCurrentUserTimezoneOffset)
  const dashboardState = useSelector(dashboardSelectors.getDashboardState)
  const helpQuestionsState = useSelector(helpQuestions.selectors.getHelpQuestions)
  const sqoopAlertQuestion = helpQuestionsState['sqoop-alert']
  const sqoopAlertHelpText = sqoopAlertQuestion && sqoopAlertQuestion.statement ||
    dashboardState.sqoopHelpText

  const adjustedDate = dateFns.addSeconds(document.publishedAt, timezoneOffset)
  const adjustedNow = dateFns.addSeconds(newUtcDate(), timezoneOffset)

  return (
    <article
      className={classNames(
        styles.document,
        'article-item', // for Pendo
        {
          [styles.compare]: compareDocuments,
          [styles.isDashboard]: isDashboard || isComparisonDashboard,
        },
      )}
    >
      {!isDashboard && !isComparisonDashboard && (
        <label
          className={classNames(
            styles.checkboxContainer,
            'select-document', // for Pendo
          )}
        >
          <input
            type="checkbox"
            checked={selectedIds.includes(document.id)}
            onChange={event =>
              onSelectChange({id: document.id, selected: event.target.checked})
            }
          />
        </label>
      )}

      <div
        className={classNames(styles.mainContainer, 'document-main-container',
          {[styles.isDashboard]: isDashboard || isComparisonDashboard})}
      >
        <InteractionBar
          article={articleForInteractionBar(document)}
          search={search}
          isFlagged={!!document.isFlagged}
          isPromoted={document.feed ? document.feed.isPromoted : false}
          isSignalsEnabled={!!document.insights}
          includeExcludeFromSearchOption={!isDashboard || isComparisonDashboard}
          onFlaggingStateChange={onFlaggingStateChange}
          onPromoteStateChange={onPromoteStateChange}
          onExclude={onExclude}
          onExcludeFromSearch={() => {}}
          className={styles.interactionBar}
        />

        {document.feed && document.feed.sourceType === 'sqoop' && (
          <h3 className={styles.sqoopAlert}>
            Sqoop Filing Alert
            <span className={styles.iconContainer}>
              {sqoopAlertHelpText &&
                <Tooltip label={sqoopAlertHelpText}>
                  <a className="tooltip help"/>
                </Tooltip>
              }
            </span>
          </h3>
        )}

        {document.insights && (
          <InsightSummary
            valence={document.insights.valence}
            factor={document.insights.factor}
            factorDisplayName={document.insights.factorDisplayName}
            isLitigation={document.insights.isLitigation}
            isOpinion={document.insights.isOpinion}
            isRumor={document.insights.isRumor}
          />
        )}

        <h3 className={styles.headline}>
          <a href={document.link} target="_blank" className="article-headline">
            {!isDashboard
              ? <Highlighter
                  textToHighlight={document.headline}
                  searchWords={emptyArray}
                  findChunks={findHeadlineChunks}
                  highlightClassName={styles.highlight}
                />
              : document.headline
            }
          </a>

          {document.staffInfo && (
            <span className={styles.staff}>
              (
              <a href={document.staffInfo.editUrl}>edit</a>
              {' | '}
              <a href={document.staffInfo.selectUrl} target="_blank">select</a>
              )
            </span>
          )}
        </h3>

        <div className={styles.publishedMeta}>
          <time dateTime={adjustedDate} className={styles.date}>
            {formatRelativeDate(adjustedDate, adjustedNow)}
          </time>
          {document.feed && (
            <React.Fragment>
              {' via '}
              <DocumentFeedDisplay document={document} />
            </React.Fragment>
          )}
        </div>

        {document.snippet && (
          <p className={styles.snippet}>
            {!isDashboard
              ? <Highlighter
                  textToHighlight={document.snippet}
                  searchWords={emptyArray}
                  findChunks={findSnippetChunks}
                  highlightClassName={styles.highlight}
                />
              : <React.Fragment>
                  {document.snippet}
                </React.Fragment>
            }
          </p>
        )}

        {(!isDashboard || isComparisonDashboard) && groupDocuments.length > 0 &&
          <GroupDocuments
            search={search}
            documents={groupDocuments}
            selectedIds={selectedIds}
            onSelectChange={onSelectChange}
            onFlaggingStateChange={onFlaggingStateChange}
            onPromoteStateChange={onPromoteStateChange}
            onExclude={onExclude}
            isComparisonDashboard={isComparisonDashboard}
          />
        }
      </div>
    </article>
  )
}
ResultDocument.propTypes = {
  document: PropTypes.object.isRequired,
  onFlaggingStateChange: PropTypes.func.isRequired,
  onPromoteStateChange: PropTypes.func.isRequired,
  onExclude: PropTypes.func.isRequired,
  isDashboard: PropTypes.bool,
  // results page props
  search: PropTypes.object,
  groupDocuments: PropTypes.arrayOf(PropTypes.object),
  selectedIds: PropTypes.arrayOf(PropTypes.string.isRequired),
  onSelectChange: PropTypes.func,
  compareDocuments: PropTypes.bool,
  isComparisonDashboard: PropTypes.bool,
}
