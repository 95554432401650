import {args, gql} from 'app/graphql'
import {graphqlQuery} from 'app/api'


export function removeExcludedFeedsFromSearch({feedIds, searchId}) {
  return graphqlQuery(
    feedIds.map(feedId =>
      gql`
        mutation {
          removeExcludedFeedFromSearch(${args({feedId, searchId})})
        }
      `
    )
  ).then(({body: {data}}) => data)
}

export function fetchDepartments() {
  return graphqlQuery(
    gql`
      query {
        departments() {
          id
          name
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchTeams() {
  return graphqlQuery(
    gql`
      query {
        teams() {
          id
          name
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchFirmLocations() {
  return graphqlQuery(
    gql`
      query {
        firmLocations() {
          id
          name: description
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function shareSearches({
  searchIds,
  departmentIds,
  teamIds,
  firmLocationIds,
  isMakeChild,
  isMakeCopy,
  noticeConfig,
  groupIds
}) {
  return graphqlQuery(
    gql`
      mutation {
        shareSearches(${args({
          ids: searchIds,
          departmentIds,
          teamIds,
          firmLocationIds,
          isMakeChild,
          isMakeCopy,
          noticeConfig,
          groupIds
        })}) {
          success
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchAvailableFilters() {
  return graphqlQuery(
    gql`
      query {
        firmLibrarySearches: savedSearches(isFirmLibrary: true) {
          id
          name
          category
          notes
          owner {
            id
            firstName
            lastName
            username
          }
        }
        firmSourceLabels {
          id
          parentId
          name
          feeds {
            id
            name
          }
        }
        availableFilterCategories {
          id
          label
          description
          searches {
            id
            name
            scope
            isFirmLibrary
            isFirmLibraryChild
            owner {
              id
              firstName
              lastName
              username
            }
            children {
              id
              name
              scope
              owner {
                id
                firstName
                lastName
                username
              }
            }
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}
