import invariant from 'invariant'
import is from 'is'
import React, {useEffect, useState} from 'react'

import SimpleModal from 'app/common/modals/SimpleModal'
import {SavedSearch} from 'app/models'
import {usePrevious} from 'app/utils/hooks'

import NewSearchModalBasicInfo from './NewSearchModalBasicInfo'
import NewSearchShare from './NewSearchShare'
import NewSearchModalEmailAlerts from './NewSearchModalEmailAlerts'
import NewSearchModalSuccess from './NewSearchModalSuccess'

import styles from './NewSearchModal.less'

const STEPS = {
  BASIC_INFO: 'basic-info',
  SHARE: 'share',
  EMAIL_ALERT: 'email-alert',
  SUCCESS: 'success',
}

export default function NewSearchModal({
  search,
  userDefaultEmailSettings,
  isSaving,
  isFirmLibrary,  // saving into the FL
  currentUserIsFirmLibraryGroup,
  onSave,
  onClose,
}) {
  const [step, setStep] = useState(STEPS.BASIC_INFO)
  const [searchData, setSearchData] = useState({})

  // I don't like this, but we need to keep track of whether `isSaving` has
  // changed so that we know when to show the success modal.
  const wasPreviouslySaving = usePrevious(isSaving)
  useEffect(
    () => {
      if (wasPreviouslySaving && !isSaving && !is.empty(searchData)) {
        setStep(STEPS.SUCCESS)
      }
    },
    [isSaving],
  )

  const getContentForStep = {
    [STEPS.BASIC_INFO]: () => {
      const onSubmit = ({name, category, isPrivate, willSetEmailAlerts}) => {
        setSearchData({
          name,
          category,
          scope: isPrivate ? SavedSearch.SCOPES.PERSONAL : SavedSearch.SCOPES.SHARED,
        })
        if(currentUserIsFirmLibraryGroup) {
          setStep(STEPS.SHARE)
        }
        else if (willSetEmailAlerts && !isFirmLibrary) {
          setStep(STEPS.EMAIL_ALERT)
        }
        else {
          onSave({
            ...searchData,
            name,
            category,
            noticeConfig: {frequency: 'none'},
          })
        }
      }
      let category = searchData.category || search.category
      // Default to 'topic' if it's an invalid category.
      if (
        !Object.values(SavedSearch.USER_SELECTABLE_CATEGORIES)
          .includes(category)
      ) {
        category = SavedSearch.CATEGORIES.TOPIC
      }
      return (
        <NewSearchModalBasicInfo
          isSaving={isSaving}
          isFirmLibrary={isFirmLibrary}
          currentUserIsFirmLibraryGroup={currentUserIsFirmLibraryGroup}
          defaultName={searchData.name || search.name}
          defaultCategory={category}
          onSubmit={onSubmit}
        />
      )
    },
    [STEPS.SHARE]: () => {
      const onSubmit = ({shareUserIds, shareDepartmentIds, shareTeamIds, shareFirmLocationIds}) => {
        if (
          shareUserIds.length > 0 ||
          shareDepartmentIds.length > 0 ||
          shareTeamIds.length > 0 ||
          shareFirmLocationIds.length > 0
        ) {
          setSearchData({
            ...searchData,
            shareUserIds,
            shareDepartmentIds,
            shareTeamIds,
            shareFirmLocationIds,
          })
          setStep(STEPS.EMAIL_ALERT)
        } else {
          onSave({
            ...searchData,
            shareUserIds,
            shareDepartmentIds,
            shareTeamIds,
            shareFirmLocationIds,
          })
        }
      }
      return (
        <NewSearchShare
          isSaving={isSaving}
          onSubmit={onSubmit}
          onBack={() => setStep(STEPS.BASIC_INFO)}
        />
      )
    },
    [STEPS.EMAIL_ALERT]: () => {
      const onSubmit = ({useDefault, alertFrequency, maxItemsByContentType, sendAlone}) => {
        if (useDefault) {
          onSave({...searchData})
        } else {
          onSave({
            ...searchData,
            noticeConfig: {
              frequency: alertFrequency,
              maxItems: maxItemsByContentType,
              sendAlone,
            },
          })
        }
      }
      let frequency = search.noticeConfig.frequency
      let maxItems = search.noticeConfig.maxItems
      if ([SavedSearch.CATEGORIES.TRUSTED, SavedSearch.CATEGORIES.TRUSTED_UNCATEGORIZED].includes(search.category)) {
        // email settings for sources aren't valid so we use the defaults for the new category.
        const categoryDefaults = userDefaultEmailSettings[searchData.category]
        frequency = categoryDefaults.frequency
        maxItems = categoryDefaults.maxItems
      }
      return (
        <NewSearchModalEmailAlerts
          isSaving={isSaving}
          defaultFrequency={frequency}
          defaultMaxItemsByContentType={maxItems}
          onSubmit={onSubmit}
          onBack={() => {
              if (currentUserIsFirmLibraryGroup) {
                setStep(STEPS.SHARE)
              } else {
                setStep(STEPS.BASIC_INFO)
              }
            }
          }
        />
      )
    },
    [STEPS.SUCCESS]: () => <NewSearchModalSuccess onDone={onClose} />,
  }
  const getContent = getContentForStep[step]
  invariant(getContent, `Invalid step for NewSearchModal: '${step}'.`)
  return (
    <SimpleModal
      title={
        step === STEPS.SUCCESS
          ? 'Your search has been saved successfully!'
          : 'Save Search'
      }
      onClose={onClose}
      className={styles.modal}
    >
      {getContent()}
    </SimpleModal>
  )
}
