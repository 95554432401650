import React, {Component} from 'react'
import {connect} from 'react-redux'

import Orm from 'app/framework/Orm'
import {SavedSearch} from 'app/models'
import Button from 'app/common/Button'
import Dropdown from 'app/common/Dropdown'
import InputBlock from 'app/common/InputBlock'
import AlertMaxItemsDropdown from 'app/reusable/AlertMaxItemsDropdown'
import {ASSIGNEE_TYPES} from 'app/reusable/BulkUserAssignment'
import {setShareModalData, createSearches, shareSearches} from '../searches-admin-actions'
import {getSearchesAdmin} from '../searches-admin-selectors'

import './ShareModal.less'


@connect(
  // Map state to props
  state => {
    const orm = Orm.withEntities(state.entities)
    const searchesAdmin = getSearchesAdmin(state)

    return {
      shareModalData: searchesAdmin.shareModalData,
      assignType: searchesAdmin.assignType,
      searchIds: searchesAdmin.selectedSearchIds,
      searches: orm.getByIds(SavedSearch, searchesAdmin.selectedSearchIds),
      searchCategories: state.searchCategories,
      noticeFrequencies: searchesAdmin.noticeFrequencies,
    }
  },
  {
    setShareModalData,
    createSearches,
    shareSearches,
  },
)
export default class ShareSearch extends Component {
  state = {
    emailSettingType: '',
  }

  render() {
    const { shareModalData, noticeFrequencies } = this.props
    const saveButtonLabel = this.props.assignType === 'copy'
      ? 'Add Copies'
      : 'Add'
    const frequencyOptions = noticeFrequencies.map(
        f => {return {value: f[0], label: f[1]}}
      ).filter(f => f.value !== 'priority_trigger')

    return (
      <div>
        <h2>Step 2: Email Settings</h2>

        <div className="new-search-notification-content">
          <InputBlock isInline label="Set Email Settings based upon the User Defaults" className="radio">
            <input
              type="radio"
              name="email_setting_type"
              value="default"
              defaultChecked={this.state.emailSettingType === 'default'}
              onClick={evt => this.setEmailSettingType(evt)}
            />
          </InputBlock>

          <InputBlock isInline label="Choose Email Notification Settings" className="radio">
            <input
              type="radio"
              name="email_setting_type"
              value="custom"
              defaultChecked={this.state.emailSettingType === 'custom'}
              onClick={evt => this.setEmailSettingType(evt)}
            />
          </InputBlock>

          {
            this.state.emailSettingType === 'custom' ?
              <div className="new-search-notification-options">
                <InputBlock label="Alert Frequency">
                  <Dropdown
                    options={frequencyOptions}
                    value={shareModalData.noticeConfig.frequency || frequencyOptions[0].value}
                    onChange={value => this.handleFrequencyChange(value)}
                  />
                </InputBlock>

                <InputBlock label="Articles">
                  <AlertMaxItemsDropdown
                    value={this.defaultPubTypeMaxItemsHandler()}
                    onChange={(value) => this.alertMaxItemsChangeHandler('defaultPubTypes', value)}
                  />
                </InputBlock>

                <InputBlock label="Filings">
                  <AlertMaxItemsDropdown
                    value={shareModalData.noticeConfig.maxItems.allFilings || 0}
                    onChange={(value) => this.alertMaxItemsChangeHandler('allFilings', value)}
                  />
                </InputBlock>

                <InputBlock label="Tweets">
                  <AlertMaxItemsDropdown
                    value={shareModalData.noticeConfig.maxItems.twitter || 0}
                    onChange={(value) => this.alertMaxItemsChangeHandler('twitter', value)}
                  />
                </InputBlock>

                <InputBlock label="Events">
                  <AlertMaxItemsDropdown
                    value={shareModalData.noticeConfig.maxItems.event || 0}
                    onChange={(value) => this.alertMaxItemsChangeHandler('event', value)}
                  />
                </InputBlock>
              </div>
              : null
          }
        </div>

        <div className="share-search-buttons-container">
          <div className="share-search-buttons">
            <div className="buttons left">
              <Button
                label="Back to Previous Step"
                onClick={() => this.previousStep()}
              />
            </div>

            <div className="buttons right">
              <Button
                label={saveButtonLabel}
                isPrimary
                onClick={() => this.save()}
                disabled={this.state.emailSettingType === ''}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  setEmailSettingType(evt) {
    const noticeConfig = {
      frequency: '',
      maxItems: {},
    }
    if (evt.target.checked) {
      this.setState({emailSettingType: evt.target.value})
    }
    this.props.setShareModalData({noticeConfig})
  }

  handleFrequencyChange(value) {
    const noticeConfig = {...this.props.shareModalData.noticeConfig}
    noticeConfig.frequency = value
    this.props.setShareModalData({noticeConfig})
  }

  defaultPubTypeMaxItemsHandler() {
    if (this.props.shareModalData.noticeConfig.maxItems.defaultPubTypes) {
      return this.props.shareModalData.noticeConfig.maxItems.defaultPubTypes
    } else {
      this.alertMaxItemsChangeHandler('defaultPubTypes', 5)
      return (5)
    }
  }

  alertMaxItemsChangeHandler(type, value) {
    const noticeConfig = {
      ...this.props.shareModalData.noticeConfig,
      maxItems: {
        ...this.props.shareModalData.noticeConfig.maxItems,
        [type]: value,
      }
    }

    this.props.setShareModalData({noticeConfig})
  }

  previousStep() {
    this.props.setShareModalData({step: 1})
  }

  save() {
    const { searches, shareModalData, assignType } = this.props
    const isMakeCopy = assignType === 'copy'
    const isMakeChild = !isMakeCopy
    const { noticeConfig } = shareModalData
    const userIds = [
      ...shareModalData.assigneeIdsBySection[ASSIGNEE_TYPES.USER] || [],
      ...shareModalData.assigneeIdsBySection[ASSIGNEE_TYPES.GROUP] || []
    ]
    /**
     * Trusted Sources and Trusted Source Folders cannot be children, even if the "child" share option is
     * used (`assignType` = "child").
     * But the "child" share option is always available, even if TS/TSF are selected, so that we can still
     * allow easy bulk sharing.
     * So, here we need to force the TS/TSF to be shared as a copy, even if "child" share was selected.
     * `copySearchIds` = all if "copy" was selected, otherwise just TS/TSF if "child" was selected.
     * `childSearchIds` = only non-TS/TSF if "child" was selected, otherwise none if "copy" was selected.
     */
    const copySearchIds = searches.filter(ss => {
      return isMakeCopy || Object.values(SavedSearch.TRUSTED_SOURCE_CATEGORIES).includes(ss.category)
    }).map(ss => ss.id)
    const childSearchIds = searches.filter(ss => {
      return isMakeChild && !Object.values(SavedSearch.TRUSTED_SOURCE_CATEGORIES).includes(ss.category)
    }).map(ss => ss.id)
    if (copySearchIds.length > 0 || childSearchIds.length > 0) {
      this.props.createSearches({
        copySearchIds,
        childSearchIds,
        userIds,
        noticeConfig
      })
    } else {
      console.log('No searches selected to create copies/children')
    }
    const departmentIds = [...shareModalData.assigneeIdsBySection[ASSIGNEE_TYPES.DEPARTMENT_MEMBER] || []]
    const teamIds = [...shareModalData.assigneeIdsBySection[ASSIGNEE_TYPES.TEAM_MEMBER] || []]
    const firmLocationIds = [...shareModalData.assigneeIdsBySection[ASSIGNEE_TYPES.FIRM_LOCATION_MEMBER] || []]
    const groupIds = [...shareModalData.assigneeIdsBySection[ASSIGNEE_TYPES.GROUP_MEMBER] || []]
    if (departmentIds.length > 0 || teamIds.length > 0 || firmLocationIds.length > 0 || groupIds.length > 0) {
      if (copySearchIds.length > 0) {
        this.props.shareSearches({
          searchIds: copySearchIds,
          departmentIds,
          teamIds,
          firmLocationIds,
          isMakeCopy: true,
          isMakeChild: false,
          noticeConfig,
          groupIds
        })
      }
      if (childSearchIds.length > 0) {
        this.props.shareSearches({
          searchIds: childSearchIds,
          departmentIds,
          teamIds,
          firmLocationIds,
          isMakeCopy: false,
          isMakeChild: true,
          noticeConfig,
          groupIds
        })
      }
    }
  }
}
