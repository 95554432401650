import React, {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {createSelector} from 'reselect'

import Button from 'app/common/Button'
import Modal from 'app/common/Modal'
import BulkUserAssignment from 'app/reusable/BulkUserAssignment'
import {ASSIGNEE_TYPES} from 'app/reusable/BulkUserAssignment'

import {getEntities} from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import {User} from 'app/models'

import {
  resetAssignModalData,
  updateAssignModalData,
  saveLocationAssignments,
} from '../firm-settings-actions'
import {
  getAssignModalData,
  getUserIds,
  getFirmLocations,
} from '../firm-settings-selectors'


export default function AssignModal() {
  const dispatch = useDispatch()
  const entities = useSelector(getEntities)
  const assignModalData = useSelector(getAssignModalData)
  const firmLocations = useSelector(getFirmLocations)
  const orm = Orm.withEntities(entities)
  const firmUsers = useSelector(
    createSelector(
      getUserIds,
      (userIds) => orm.getByIds(User, userIds),
    )
  )

  const [section, setSection] = useState(ASSIGNEE_TYPES.USER)

  const getLocationFromId = (id) => {
    return firmLocations.find(fl => fl.id === id)
  }

  const setSelectedIds = (ids) => {
    const assigneeIdsBySection = {...assignModalData.assigneeIdsBySection}
    assigneeIdsBySection[section] = ids
    dispatch(updateAssignModalData({assigneeIdsBySection}))
  }

  const save = () => {
    setSection(ASSIGNEE_TYPES.USER)
    dispatch(saveLocationAssignments())
  }

  return (
    <Modal
      isOpen={!!assignModalData.id}
      onClose={() => dispatch(resetAssignModalData())}
    >
      <div>
        <h1>Assign Location</h1>

        <strong className="afs-selected-label">
          Location Selected:
        </strong>

        <div id="assign-source-titles">
          {getLocationFromId(assignModalData.id).description}
        </div>

        <br />

        <strong className="afl-selected-label">Assign to:</strong>

        <BulkUserAssignment
          users={firmUsers}
          onSectionChange={section => setSection(section)}
          onSelectedIdsChange={(ids) => setSelectedIds(ids)}
          showGroups={false}
          showIndividualMembers={true}
          selectedIdsBySection={{
            user: getLocationFromId(assignModalData.id).users.map(u => u.id),
          }}
        />

        <div className="buttons">
          <Button
            label="Cancel"
            isPlainText
            onClick={() => dispatch(resetAssignModalData())}
          />

          <Button
            label="Assign"
            isPrimary
            onClick={() => save()}
          />
        </div>
      </div>
    </Modal>
  )
}
