import is from 'is'
import classNames from 'classnames'
import invariant from 'invariant'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import TextBox from 'app/common/TextBox'
import Option from './Option'
import './Dropdown.less'


/*
 * Generic styled dropdown component
 */
export default class Dropdown extends Component {
  static Option = Option

  static propTypes = {
    // Array of Option objects. Each object has one of the following
    // shapes:
    //     {label: String, value: any, isInvisible: Boolean?, level: int?} or
    //     {isGroup: true, label: String, options: [Option], level: int?}
    //
    // Options may also hold any other arbitrary attributes, which will
    // be preserved.
    options: PropTypes.arrayOf(PropTypes.object),

    // An optional function that takes in an Option and returns a
    // rendering of it. Overrides the default renderer.
    optionRenderer: PropTypes.func,

    // What to show as the label for the selected option.
    selectedOptionLabel:
      PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    // Called when an option is selected with that Option as an argument
    onSelect: PropTypes.func,

    // Same as above, but is passed the option value
    onChange: PropTypes.func,

    // Called when the menu is opened or closed with a boolean argument
    // representing the open/close state.
    onMenuOpenChange: PropTypes.func,

    // The starting selected value
    defaultValue: PropTypes.any,

    // The currently selected option value
    value: PropTypes.any,

    shouldShowSelectedOption: PropTypes.bool,
    dropUp: PropTypes.bool,

    disabled: PropTypes.bool,
    className: PropTypes.string,
    selectedOptionClassName: PropTypes.string,
    arrowClassName: PropTypes.string,
    menuClassName: PropTypes.string,
    showFilter: PropTypes.bool,
    appTypelabel: PropTypes.string
  }

  static defaultProps = {
    shouldShowSelectedOption: true,
    dropUp: false,
    disabled: false,
    showFilter: false,
    appTypelabel: '',
  }

  constructor(props) {
    super(props)

    invariant(
      (props.options && props.options.length) || props.children,
      "A Dropdown must have at least one option.",
    )

    this.state = {
      showMenu: false,
      selectedOptionValue: undefined,
      filterValue: '',
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.onMenuOpenChange) return
    if (prevState.showMenu && !this.state.showMenu) {
      this.props.onMenuOpenChange(false)
    }
    if (!prevState.showMenu && this.state.showMenu) {
      this.props.onMenuOpenChange(true)
    }
  }

  // React methods

  render() {
    const selectedOption = this.optionFromValue(this.selectedOptionValue)
    const {
      className,
      selectedOptionClassName,
      arrowClassName,
      shouldShowSelectedOption,
      selectedOptionLabel,
    } = this.props
    return (
      <div
        className={classNames(
          'dropdown-component', className,
          {open: this.state.showMenu},
          {disabled: this.props.disabled}
        )}
        onClick={this.handleClick}
      >
        {shouldShowSelectedOption && (
          <div className={classNames('selected', selectedOptionClassName)}>
            {selectedOptionLabel || selectedOption.label}
          </div>
        )}
        <div className={classNames('arrow', arrowClassName)} />
        {this.renderMenu()}
      </div>
    )
  }

  // Render helpers

  renderMenu() {
    if (!this.state.showMenu) return null

    const options = this.options.map((option, idx) => {
      option = {...option, uniqueKey: idx}
      return this.renderOption(option)
    })

    if (this.props.showFilter) {
      if (this.props.appTypelabel !== "ESG" && this.props.appTypelabel !== "ManageLabels" ) {
        options.unshift(
          <div key="filter" className="option filter">
            <div className="label">Filter</div>
            <TextBox
              onChange={(event) => this.setState({ filterValue: event.target.value })}
            />
          </div>
        )
      }
    }

    return (
      <div
        className={classNames(
          'options',
          this.props.menuClassName,
          {'drop-up': this.props.dropUp},
        )}
      >
        {this.props.showFilter && this.props.appTypelabel === "ESG" || this.props.appTypelabel === "ManageLabels" ?
          <div className={classNames(
            'option')}
            style={{ paddingLeft: '0.75em' }}>
            <TextBox className={'esg-search'}
              onChange={(event) => this.setState({ filterValue: event.target.value })}
              placeholder="&#xf002; Search"
            />
          </div> : ''
        }
        <div className={classNames(
          'dropdown-height')}>
          {options}
        </div>
      </div>
    )
  }

  renderOption = (option, {isGrouped = false} = {}) => {
    if (option.isInvisible) return null
    const active = option.value === this.selectedOptionValue
    const {isGroup} = option
    const filterValue = this.state.filterValue.toLowerCase()
    const level = option.level || 0
    let label;
    try {
      label = (typeof option.label === 'object' ? option.label.props.children : option.label).toLowerCase()
    } catch(e) {
      label = ''
    }

    const renderedOption =
      <div
        className={classNames(
          'option',
          {
            active,
            group: isGroup,
            // We add the 'grouped' class to options that are part of a group
            grouped: isGrouped,
          },
        )}
        style={{paddingLeft: `calc(0.75em + 10px * ${level})`}}
        onClick={() => this.selectOption(option)}
        key={option.uniqueKey}
      >
        {this.renderOptionContents(option)}
      </div>

    if (isGroup) {
      return [
        renderedOption,
        ...option.options.map((option, idx) => {
          option.uniqueKey = `group-${idx}`
          return this.renderOption(option, {isGrouped: true})
        }),
      ]
    }

    if (isGroup || !filterValue || label.includes(filterValue)) {
      return renderedOption
    }

    return null
  }

  renderOptionContents(option) {
    if (this.props.optionRenderer) {
      return this.props.optionRenderer(option)
    }

    if (option.label) {
      return (
        <div className="title">{option.label}</div>
      )
    } else {
      return (
        <div className="title">&nbsp;</div>
      )
    }

  }

  // State management

  showMenu() {
    this.setState({showMenu: true})
    document.addEventListener('click', this.handleGlobalClick)
  }

  hideMenu() {
    this.setState({showMenu: false, filterValue: ''})
    document.removeEventListener('click', this.handleGlobalClick)
  }

  selectOption(option) {
    // You can't select a group
    if(option.isGroup) return

    this.setState({
      showMenu: false,
    })

    if (typeof this.props.value === 'undefined') {
      this.setState({
        selectedOptionValue: option.value,
      })
    }

    if (this.props.onSelect) {
      this.props.onSelect(option)
    }
    if (this.props.onChange) {
      this.props.onChange(option.value)
    }
  }

  optionFromValue(value) {
    const findOption = (options) => {
      for (const option of options) {
        if (option.value === value) {
          return option
        }
        if (option.isGroup) {
          const subOption = findOption(option.options)
          if (is.defined(subOption)) {
            return subOption
          }
        }
      }
    }
    const option = findOption(this.options)
    invariant(
      option,
      `A value '${value}' was given to a Dropdown, but it has no options with that value.`
      + `\nValid option values are: (${this.options.map(option => `'${option.value}'`).join(', ')}).`,
    )
    return option
  }

  optionFromComponent(component) {
    return {
      label: component.props.label,
      value: component.props.value,
      isInvisible: component.props.isInvisible,
    }
  }

  getOptionsFromComponents(components) {
    return React.Children.toArray(components).map(component => {
      if (component.children) {
        return this.getOptionsFromComponents(component.children)
      }
      return this.optionFromComponent(component)
    })
  }

  get options() {
    return this.props.options || this.getOptionsFromComponents(this.props.children)
  }

  get selectedOptionValue() {
    if(typeof this.props.value !== 'undefined') {
      return this.props.value
    } else if (typeof this.state.selectedOptionValue !== 'undefined') {
      return this.state.selectedOptionValue
    } else if(typeof this.props.defaultValue !== 'undefined') {
      return this.props.defaultValue
    }
    return this.options[0].value
  }

  // Event handlers

  handleClick = (event) => {
    if (this.props.disabled) {
      return;
    }
    const {target} = event
    const rootNode = findDOMNode(this)
    const optionsNode = rootNode.getElementsByClassName('options')[0]
    // Only close the menu if we clicked the select box, not an option
    // (selecting an option closes the menu by itself)
    if (this.state.showMenu && !optionsNode.contains(target)) {
      this.hideMenu()
    } else if (!this.state.showMenu) {
      this.showMenu()
    }
  }

  handleGlobalClick = (e) => {
    // Hide the renderMenu if the user clicks outside of it
    let rootNode
    try {
      rootNode = findDOMNode(this)
    } catch(e) {
      // findDOMNode will error if the component has been unmounted, so
      // we just return early if that's the case
      return
    }
    if (!rootNode.contains(e.target)) {
      this.hideMenu()
    }
  }
}

export {Option}
