import { createAction } from 'redux-actions'

// Prefix action names with PROFILE_BUILDER
const createProfileBuilderAction = name => createAction(`PROFILE_BUILDER_${name}`)

export const init = createProfileBuilderAction('INIT')
export const setCurrentStep = createProfileBuilderAction('SET_CURRENT_STEP')
export const fetchSearches = createProfileBuilderAction('FETCH_SEARCHES')
export const showBrowseModal = createProfileBuilderAction('SHOW_BROWSE_MODAL')
export const hideBrowseModal = createProfileBuilderAction('HIDE_BROWSE_MODAL')
export const showMessage = createProfileBuilderAction('SHOW_MESSAGE')
export const hideMessage = createProfileBuilderAction('HIDE_MESSAGE')
export const saveUser = createProfileBuilderAction('SAVE_USER')
export const skip = createProfileBuilderAction('SKIP')
export const saveUserSuccess = createProfileBuilderAction('SAVE_USER_SUCCESS')
