import {groupBy} from 'ramda'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import PropTypes from 'prop-types'

import {FILTER_CATEGORY_OTHER} from 'app/constants'
import SavedSearchInput from 'app/common/SavedSearchInput'
import * as globalSelectors from 'app/global/global-selectors'
import {searchCategory} from 'app/strings'
import {flattenFilterData, queryFromTerms} from 'app/utils/searches'

import './style.less'


class NarrowBy extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    quickFilterOptions: PropTypes.object,
    isQuickFiltersEnabled: PropTypes.bool,
    currentFirm: PropTypes.object,

    // queryComponentState will be populated if filters are changed here or in SavedSearchFilters
    queryComponentState: PropTypes.object,

    onApply: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    setSaveData: PropTypes.func,
  }

  static defaultProps = {
    quickFilterOptions: {},
    isQuickFiltersEnabled: true,
    queryComponentState: {},
    onChange: () => {},
    setSaveData: () => {},
  }

  advancedFilterKeys = {
    articleAnySelectedOptions: 'text_any',
    articleAllSelectedOptions: 'text_all',
    articleExcludeSelectedOptions: 'text_exclude',
    articleExactSelectedOptions: 'content_exact',
    headlineAnySelectedOptions: 'headline_any',
    headlineAllSelectedOptions: 'headline_all',
    headlineExcludeSelectedOptions: 'headline_exclude',
    headlineExactSelectedOptions: 'headline_exact',
    introAnySelectedOptions: 'intro_any',
    introAllSelectedOptions: 'intro_all',
    introExcludeSelectedOptions: 'intro_exclude',
    introExactSelectedOptions: 'intro_exact',
  }

  searchLabels = {
    client: {
      label: `My ${searchCategory('client', {plural: true, currentFirm: this.props.currentFirm})}`,
    },
    industry: {
      label: `My ${searchCategory('industry', {plural: true, currentFirm: this.props.currentFirm})}`,
    },
    tracker: {
      label: `My ${searchCategory('tracker', {plural: true, currentFirm: this.props.currentFirm})}`,
    },
    firm: {
      label: `My ${searchCategory('firm', {plural: true, currentFirm: this.props.currentFirm})}`,
    },
    practice: {
      label: `My ${searchCategory('practice', {plural: true, currentFirm: this.props.currentFirm})}`,
    },
    // trusted includes categorized (folders) and un-categorized.
    trusted: {
      label: `My ${searchCategory('trusted-uncategorized', {plural: true, currentFirm: this.props.currentFirm})}`,
    },

    // these are available in Narrow By but not in SavedSearchFilters
    prospect: {
      label: 'My Prospects'
    },
    court: {
      label: 'My Courts'
    },
    state: {
      label: 'My States'
    },
  }

  /**
   * some filter categories (popular, news, etc..) are based on the firm's vertical.
   * here we make a lookup of the ids and names from the user's filters.
   */
  categoryLookup = {
    1: 'advanced',
    2: 'searches',
    ...Object.assign(...Object.keys(this.props.filters).filter(
      key => this.props.filters[key].length > 0
    ).map(key => {
        let c = {}
        c[this.props.filters[key][0]['categoryId']] = key
        return c
      }
    ))
  }

  render() {
    const filterCategories = this.props.quickFilterOptions.categories || []
    const categoryFilterOptionGroup =
      <optgroup label="My..." id="user-quick-filters">
        {filterCategories.map(catArray => {
          return <option value={catArray[0]} key={`category-filter-${catArray[0]}`}>{catArray[1]}</option>
        })}
      </optgroup>

    const popularFilterOptionGroup =
      this.props.quickFilterOptions.popular
      ? <optgroup label="Popular Filters:" id="popular-quick-filters">
          {
            this.props.quickFilterOptions.popular.map(filter => {
              return <option value={filter[0]} key={`popular-filter-${filter[0]}`}>{filter[1]}</option>
            })
          }
        </optgroup>
      : null

    return (
      <div id="filters-quick" className="dark-muted pad-v-xs pad-l-m pad-r-s">
        <div className="filters-quick-column">Narrow by</div>
        <div className="filters-quick-column">
          {
            this.props.isQuickFiltersEnabled
            ? <select
                className="select"
                data-placeholder="Quick Filters..."
                onChange={(evt) => this.handleQuickFilterChange(evt.target.value)}
                disabled={!this.props.isQuickFiltersEnabled}
                value=""
              >
                <option value="">Quick Filters...</option>
                {categoryFilterOptionGroup}
                {popularFilterOptionGroup}
              </select>
            : null
          }
        </div>
        <div className="filters-quick-column">
          <span className="filters-autocomplete-holder">
            <SavedSearchInput
              selectedValues={this.getAutoCompleteFilterOptions()}
              onChange={selectedOptions => this.handleOtherFilterChange(selectedOptions)}
            />
          </span>
        </div>
        <div
          id="filters-quick-submit"
          className="small font-size-s filters-quick-submit filters-quick-column"
          onClick={() => this.applyOtherFilterChange()}
        >Apply</div>
      </div>
    )
  }

  getSelectedFilterOptions() {
    const {search, queryComponentState} = this.props
    const browseFilters = {
      searches: [],
      news: [],
      geography: [],
      topics: [],
      popular: [],
      regulatory: [],
      other: [],
    }
    const advancedFilterOptions = {
      text_any: [],
      text_all: [],
      text_exclude: [],
      content_exact: [],
      headline_any: [],
      headline_all: [],
      headline_exclude: [],
      headline_exact: [],
      intro_any: [],
      intro_all: [],
      intro_exclude: [],
      intro_exact: [],
    }
    let selectedFilterOptions = {}
    if (Object.keys(queryComponentState).length > 0) {
      selectedFilterOptions = queryComponentState
    }
    search.filterGroups.forEach(fg => {
      let category = this.categoryLookup[fg.category] || FILTER_CATEGORY_OTHER
      if (category === 'advanced') {
        fg.filters.forEach(f => {
          if (!f.active) {
            return
          }
          // value/option/type are used for dropdown component; id/filterGroupId used for saving/running-query
          let option = {
            filterGroupCategory: fg.category,
            filterGroupId: fg.id,
            filterId: f.id,
            filterGroupSpecial: fg.special,
            searchId: null,
            filterField: fg.filterField,
          }
          if (f.search) {
            Object.assign(option, {
              value: f.search.id,
              label: f.search.name,
              searchId: f.search.id,
            })
            advancedFilterOptions[fg.filterField].push(option)
          } else {
            Object.assign(option, {
              value: f.queryTerm,
              label: f.queryTerm,
              isFreeText: true,
            })
            advancedFilterOptions[fg.filterField].push(option)
          }
        })
      } else if (fg.special) {
        // special fg will have only one filter
        const f = fg.filters[0]
        if (!f.active) {
          return
        }
        const option = {
          filterGroupCategory: fg.category,
          filterGroupId: fg.id,
          filterId: f.id,
          filterGroupSpecial: fg.special,
          searchId: null,
          filterField: fg.filterField,
        }
        browseFilters[category].push(option)
      } else {
        fg.filters.forEach(f => {
          if (!f.active) {
            return
          }
          let option = {
            filterGroupCategory: fg.category,
            filterGroupId: fg.id,
            filterId: f.id,
            filterGroupSpecial: fg.special,
            searchId: null,
            filterField: fg.filterField,
          }
          if (f.search) {
            Object.assign(option, {
              searchId: f.search.id,
              value: f.search.id,
              label: f.search.name,
            })
            browseFilters[category].push(option)
          } else if (f.queryTerm) {
            Object.assign(option, {
              filterGroupSpecial: null,
              value: f.queryTerm,
              label: f.queryTerm,
              isFreeText: true,
            })
            browseFilters[category].push(option)
          }
        })
      }
    })
    if (!selectedFilterOptions['selectedBrowseFilters']) {
      selectedFilterOptions['selectedBrowseFilters'] = browseFilters
    }
    Object.keys(this.advancedFilterKeys).forEach(key => {
      const filterField = this.advancedFilterKeys[key]
      if (!selectedFilterOptions[key]) {
        selectedFilterOptions[key] = advancedFilterOptions[filterField]
      }
    })
    return selectedFilterOptions
  }

  isAutocompleteFilterOption(option) {
    return  option.filterGroupCategory !== 1 || option.filterField === 'text_any'
  }

  getAutoCompleteFilterOptions() {
    /**
     * these go in the auto-complete component.
     * excludes all advanced filter options except Content Any Of (text_any).
     * note: if there's no `filterGroupCategory` then the option was just added.
     */
    const selectedFilterOptions = this.getSelectedFilterOptions()
    let options = []

    if (selectedFilterOptions.selectedBrowseFilters) {
      Object.keys(selectedFilterOptions.selectedBrowseFilters).forEach(key => {
        const selectedOptions = [...selectedFilterOptions.selectedBrowseFilters[key]]
        selectedOptions.forEach(option => {
          if (this.isAutocompleteFilterOption(option)) {
            if (option.filterGroupSpecial) {
              option.label = 'All ' + this.searchLabels[option.filterGroupSpecial].label
            }
            options.push(option)
          }
        })
      })
    }

    Object.keys(this.advancedFilterKeys).forEach(key => {
      if (selectedFilterOptions[key]) {
        const selectedOptions = [...selectedFilterOptions[key]]
        selectedOptions.forEach(option => {
          if (this.isAutocompleteFilterOption(option)) {
            options.push(option)
          }
        })
      }
    })

    return options
  }

  getSaveData() {
    const selectedFilterOptions = this.getSelectedFilterOptions()
    let options = []
    Object.keys(selectedFilterOptions.selectedBrowseFilters).forEach(key => {
      options = [...options, ...selectedFilterOptions.selectedBrowseFilters[key]]
    })
    Object.keys(this.advancedFilterKeys).forEach(key => {
      if (selectedFilterOptions[key]) {
        options = [...options, ...selectedFilterOptions[key]]
      }
    })
    const filters = groupBy(
      option => {
        switch (option.filterGroupCategory) {
          case 1: // advanced
            return option.filterField
          case 2:
            return 'your'
          default:
            return option.filterGroupCategory
        }
      },
      options,
    )
    const search = this.props.search
    let coreSearchValues = []
    if (search.query) {
      const query = search.query
      const parts = query.split('~|')
      coreSearchValues = parts.map(part => {
        if (part.startsWith('ss:')) {
          return parseInt(part.substring(3))
        }
        return part
      })
    }
    return {
      searchId: search.id,
      query: search.queryType === 'raw' ? search.query : queryFromTerms(coreSearchValues),
      queryType: search.queryType,
      filters: flattenFilterData(filters),
    }
  }

  handleQuickFilterChange(categoryKey) {
    /**
     * categoryKey will be the search category (for `My...` filters) or a search id (for popular filters).
     * quickFilterOptions.categories holds the `My...` filters.
     */
    const filterCategories = this.props.quickFilterOptions.categories || []
    const categoryKeys = filterCategories.map(catArray => catArray[0])
    const data = this.getSaveData()
    const popularCategoryId = this.props.filters['popular'][0]['categoryId']
    let category = popularCategoryId
    if (categoryKeys.includes(categoryKey)) {
      category = 'your'
    }
    let searchId = category === popularCategoryId ? categoryKey : null
    const filter = {
      id: null,
      groupId: null,
      filterField: category,
      specialType: category === 'your' ? categoryKey : null,
      searchId,
      value: null,
      isFreeText: !searchId,
    }
    data.filters.push(filter)
    this.apply(data)
  }

  handleOtherFilterChange(selectedOptions) {
    /**
     * note: anything added here (autocomplete) is added to the advanced filter category, `text_any` filter field.
     */
    let articleAnySelectedOptions = []
    let selectedFilterOptions = this.getSelectedFilterOptions()
    let selectedBrowseFilters = {...selectedFilterOptions.selectedBrowseFilters}
    selectedOptions.forEach(option => {
      /**
       * `filterGroupCategory` will be missing if option was added from autocomplete.
       * if so, add it (along with all `text_any` advanced filters) to Redux to be available to SavedSearchFilters.
       */
      if (!option.filterGroupCategory || (option.filterGroupCategory === 1 && option.filterField === 'text_any')) {
        articleAnySelectedOptions.push({
          ...option,
          filterGroupCategory: 1,
          filterGroupId: option.filterGroupId,
          filterId: option.filterId,
          filterGroupSpecial: null,
          searchId: option.isFreeText ? null : option.value,
          filterField: 'text_any',
        })
      }
    })
    Object.keys(selectedBrowseFilters).forEach(key => {
      let previousOptions = selectedBrowseFilters[key]
      selectedBrowseFilters[key] = previousOptions.filter(previousOption => {
        if (previousOption.searchId) {
          return selectedOptions.find(o => o.searchId === previousOption.searchId)
        } else {
          return selectedOptions.find(o => o.filterGroupSpecial === previousOption.filterGroupSpecial)
        }
      })
    })

    const newQueryComponentState = {
      ...this.props.queryComponentState,
      articleAnySelectedOptions,
      selectedBrowseFilters: {
        ...this.props.queryComponentState.selectedBrowseFilters,
        ...selectedBrowseFilters,
      },
    }
    this.props.onChange(newQueryComponentState)
    // Hack alert: wait until new queryComponentState is set before setting save data.
    window.setTimeout(() => {
      this.props.setSaveData(this.getSaveData())
    }, 200)
  }

  applyOtherFilterChange() {
    const data = this.getSaveData()
    this.apply(data)
  }

  apply(data) {
    this.props.onApply(data)
  }
}
export default connect(
  createSelector(
    [globalSelectors.getCurrentFirm],
    currentFirm => ({currentFirm}),
  )
)(NarrowBy)
