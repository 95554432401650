import {changeCaseObject} from 'app/utils'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { addData } from './actions'
import rootReducer from './root-reducer'
import rootSaga from './root-saga'
import * as errorActions from "./error-handling/error-handling-actions"
import * as constants from "./constants"

const sagaMiddleware = createSagaMiddleware({
  onError: (error, {sagaStack}) => {
    console.error(error)
    if (sagaStack) {
      console.error(sagaStack)
    }
    store.dispatch(errorActions.setError({
      error: error,
      errorForApp: constants.BASE_APP,
      mainImage: constants.ERROR_PAGE_IMAGE,
    }))
  },
})
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
)
sagaMiddleware.run(rootSaga)

// Add the initial state data as a separate step, so that we go through
// the normal pipeline for adding data and don't have to duplicate logic
// here
const initialGlobalState = window.mzInitialGlobalState
if (initialGlobalState && Object.keys(initialGlobalState).length) {
  store.dispatch(addData(changeCaseObject.camelCase(initialGlobalState)))
  store.dispatch(errorActions.initError({
    errorForApp: constants.BASE_APP,
    mainImage: constants.ERROR_PAGE_IMAGE,
  }))
}

export default store
