import React from 'react'
import classNames from 'classnames'

import Loader from 'app/common/LoadingSpinner'

import './style.less'

export default ({className}) => (
  <div className={classNames('loading-overlay', className)}>
    <Loader />
  </div>
)
