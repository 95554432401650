import React from 'react'
import { render } from 'react-dom'
import AppWrapper from 'app/global/app-wrapper'

import store from 'app/store'

import AdvancedSearch from './AdvancedSearch'

import * as constants from './advanced-search-constants'
import reducer from './advanced-search-reducer'
import saga from './advanced-search-saga'


export function init() {
  const component = (
    <AppWrapper store={store}>
      <AdvancedSearch/>
    </AppWrapper>
  )

  const container = document.getElementById('advanced-search-wrapper')
  render(component, container)
}

export { constants, reducer, saga }
