import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { setQueryComponentState } from 'app/firm-admin/searches/searches-admin-actions'
import { getSearchesAdmin } from 'app/firm-admin/searches/searches-admin-selectors'

import NarrowBy from 'app/common/NarrowBy'


@connect(
  // Map state to props
  state => {
    const searchesAdmin = getSearchesAdmin(state)
    return {
      searches: state.entities.searches,
      queryComponentState: searchesAdmin.queryComponentState,
      quickFilterOptions: state.quickFilterOptions,
    }
  },

  // Map dispatch to props
  {
    setQueryComponentState,
  },
)
export default class Tier2NarrowBy extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <NarrowBy
          search={this.props.search}
          filters={this.props.filters}
          isQuickFiltersEnabled={false}
          onChange={data => this.onChange(data)}
          onApply={data => this.getResults(data)}
          queryComponentState={this.props.queryComponentState}
        />
      </div>
    )
  }

  onChange(data) {
    this.props.setQueryComponentState(data)
  }

  getResults(data) {
    const params = new URLSearchParams(window.location.search)
    params.delete('narrow')

    data.filters.filter(f => f.filterField === 'text_any').forEach(f => {
      params.append('narrow', f.value)
    })

    this.setState({isLoading: true}, () => {
      window.location.href = `?${params.toString()}`
    })
  }
}
