import { all, takeLatest, call, put, delay, select } from 'redux-saga/effects'

import {getCurrentUserId, getCurrentFirm} from 'app/global/global-selectors'
import * as notifications from 'app/global/notifications'
import {
  exportPDF,
  exportDoc,
  exportExcel,
  exportEmail,
} from 'app/reusable/saga'
import { handleSagaError } from 'app/utils/errors'

import * as actions from './flagged-items-actions'
import * as api from './flagged-items-api'
import {getFlaggedItems} from './flagged-items-selectors'
import store from 'app/store'
import * as flaggingActions from '../flagging/flagging-actions'
import {getFlaggedArticleCount} from '../flagging/flagging-selectors'

function* handleFetchInitData(action) {
  const flaggedCount = yield select(getFlaggedArticleCount)
  const flagresponse = yield api.fetchFlagPagethreshold()
    if(flaggedCount > flagresponse.flagPageThreshold){
      /* new page version */
      yield put(actions.setFlagPageVersion('new'))
      let response = null
      let response2 = null
      try {
        response = yield api.fetchInitDataNew()
        response2 = yield api.fetchflaggingsCountNew()
      } catch (error) {
        yield put(actions.setIsLoading(false))
        yield* handleSagaError(error)
        return
      }
      const categories = prepareCategories([
        {
          id: 0,
          name: 'Uncategorized',
          flaggings: response.uncategorizedFlaggings,
        },
      ])

      response2.categories.forEach(item => {
        if (item.parent !== null) {
          item.show = false;
        } else {
          item.show = true;
        }
        item.isArrowOpen = false
      });

      const sideBarCategories = [
        {
          id: 0,
          name: 'Uncategorized',
          flaggingsCount: response2.uncategorizedFlaggingsCount,
          show: true,
          isArrowOpen: false
        },
        ...response2.categories
      ]

      yield put(actions.setCategories(categories))
      yield put(actions.setSideBarCategories(sideBarCategories))
      yield put(actions.setHelpQuestions(response.helpQuestions))
      const promotedFeedIds = response.promotedSources.map(f => f.id)
      yield put(actions.setPromotedFeedIds(promotedFeedIds))
      yield put(actions.setIsLoading(false))

    }else{
      /* old page version */
      yield put(actions.setSelectedCategoryIds([]))
      let response = null
      try {
        response = yield api.fetchInitData()
      } catch (error) {
        yield put(actions.setIsLoading(false))
        yield* handleSagaError(error)
        return
      }
      const categories = prepareCategories([
        {
          id: 0,
          name: 'Uncategorized',
          flaggings: response.uncategorizedFlaggings,
        },
        ...response.categories
      ])
      yield put(actions.setCategories(categories))
      yield put(actions.setHelpQuestions(response.helpQuestions))
      const promotedFeedIds = response.promotedSources.map(f => f.id)
      yield put(actions.setPromotedFeedIds(promotedFeedIds))
      yield put(actions.setIsLoading(false))
    }
}

function* handleFetchCategories(action) {
  const flaggedItems = yield select(getFlaggedItems)
  const version = flaggedItems.pageVersion
  if (version == 'old') {
    let response = null
    try {
      response = yield api.fetchCategories()
    } catch (error) {
      yield put(actions.setIsLoading(false))
      yield* handleSagaError(error)
      return
    }
    const categories = prepareCategories([
      {
        id: 0,
        name: 'Uncategorized',
        flaggings: response.uncategorizedFlaggings,
      },
      ...response.categories
    ])
    let categoryCount = 0
    response.categories.forEach(category => {
      categoryCount += category.flaggings.length;
    });
    const uncategorizedCount = response.uncategorizedFlaggings.length;
    store.dispatch(flaggingActions.setFlaggedArticleCount(categoryCount + uncategorizedCount))
    yield put(actions.setCategories(categories))
    yield put(actions.setIsLoading(false))
  } else {
    const selectedIds = flaggedItems.selectedCategoryIds.join().split(',')
    let response, response2, response3 = null
    try {
      if (flaggedItems.selectedCategoryIds.length > 0) {
        response = yield api.fetchCategoryByIdNew(selectedIds)
      }
      response2 = yield api.fetchflaggingsCountNew()
      if (flaggedItems.selectedCategoryIds.includes(0)) {
        response3 = yield api.fetchUncategorizedNew()
      }
    } catch (error) {
      yield put(actions.setIsLoading(false))
      yield* handleSagaError(error)
      return
    }

    let categories = []
    let updatedCategories = []
    if (flaggedItems.selectedCategoryIds.length > 0) {
      categories = prepareCategories(
        flaggedItems.selectedCategoryIds.includes(0) ?
          [
            {
              id: 0,
              name: 'Uncategorized',
              flaggings: response3.uncategorizedFlaggings,
            },
            ...response.categories
          ] :
          [
            ...response.categories
          ]
      )
    }

    response2.categories.forEach(item => {
      if (item.parent !== null) {
        item.show = false;
      } else {
        item.show = true;
      }
      item.isArrowOpen = false

      if (flaggedItems.newSidebarId == item.id) {
        item.show = true
      }

      const folderMap = new Map(flaggedItems.foldersOpened?.map(item => [item.id, item]));
      updatedCategories = response2.categories.map(category => ({
        ...category,
        ...(folderMap.get(category.id) || {})
      }));
    });

    const sideBarCategories = [
      {
        id: 0,
        name: 'Uncategorized',
        flaggingsCount: response2.uncategorizedFlaggingsCount,
        show: true,
        isArrowOpen: false
      },
      ...updatedCategories
    ]

    let categoryCount = 0
    response2.categories.forEach(category => {
      categoryCount += category.flaggingsCount;
    });
    const uncategorizedCount = response2.uncategorizedFlaggingsCount
    store.dispatch(flaggingActions.setFlaggedArticleCount(categoryCount + uncategorizedCount))
    yield put(actions.setCategories(categories))
    yield put(actions.setSideBarCategories(sideBarCategories))
    yield put(actions.setIsLoading(false))
  }
}

function* handleFetchPromotedSearches(action) {
  let response = null
  try {
    response = yield api.fetchPromotedSearches()
  } catch (error) {
    yield put(actions.setIsLoading(false))
    yield* handleSagaError(error)
    return
  }
  const promotedFeedIds = response.promotedSources.map(f => f.id)
  yield put(actions.setPromotedFeedIds(promotedFeedIds))
  yield put(actions.setIsLoading(false))
}

function* handleExportPDF(action) {
  yield* exportPDF(action.payload)
  yield put(actions.setIsLoading(false))
}

function* handleExportDoc(action) {
  yield* exportDoc(action.payload)
  yield put(actions.setIsLoading(false))
}

function* handleExportExport(action) {
  yield* exportExcel(action.payload)
  yield put(actions.setIsLoading(false))
}

function* handleExportEmail(action) {
  yield* exportEmail(action.payload)
  yield put(actions.setIsLoading(false))
}

function* handleCreateCategory(action) {
  const flaggedItems = yield select(getFlaggedItems)
  const version = flaggedItems.pageVersion
  if (version == 'old') {
    try {
      yield api.createCategory(action.payload)
    } catch (error) {
      yield put(actions.setIsLoading(false))
      yield* handleSagaError(error)
      return
    }
    yield put(actions.hideCreateCategoryModal())
    yield put(actions.fetchCategories())
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Folder created successfully.',
      })
    )
  }else{
    let response = null
    try {
      response = yield api.createCategory(action.payload)
    } catch (error) {
      yield put(actions.setIsLoading(false))
      yield* handleSagaError(error)
      return
    }
    yield put(actions.hideCreateCategoryModal())
    yield put(actions.setNewSidebarId(response.createFlagCategory.id))
    yield put(actions.fetchCategories())
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Folder created successfully.',
      })
    )
  }
}

function* handleDeleteCategories(action) {
  const ids = action.payload
  try {
    yield api.deleteCategories(action.payload)
  } catch (error) {
    yield put(actions.setIsLoading(false))
    yield* handleSagaError(error)
    return
  }
  yield put(actions.setDeleteCategoryId(null))
  yield put(actions.setSelectedCategoryIds([]))
  yield put(actions.setBulkAction(null))
  yield put(actions.fetchCategories())
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: `Folder${ids.length === 1 ? '' : 's'} deleted successfully`,
    })
  )
}

function* handleClearCategories(action) {
  const ids = action.payload
  try {
    yield api.clearCategories(action.payload)
  } catch (error) {
    yield put(actions.setIsLoading(false))
    yield* handleSagaError(error)
    return
  }
  yield put(actions.setSelectedCategoryIds([]))
  yield put(actions.setBulkAction(null))
  yield put(actions.fetchCategories())
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: `Folder${ids.length === 1 ? '' : 's'} cleared successfully`,
    })
  )
}

function* handleSaveCategory(action) {
  const flaggedItems = yield select(getFlaggedItems)
  const {id, name, isShared} = flaggedItems.editCategoryData
  try {
    yield api.saveCategory({id, name, isShared})
  } catch (error) {
    yield put(actions.setIsLoading(false))
    yield* handleSagaError(error)
    return
  }
  yield put(actions.resetEditCategoryData())
  yield put(actions.fetchCategories())
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'Folder saved successfully.',
    })
  )
}

function* handleDeleteArticles(action) {
  const ids = action.payload
  try {
    yield api.deleteArticles(ids)
  } catch (error) {
    yield put(actions.setIsLoading(false))
    yield* handleSagaError(error)
    return
  }
  yield put(actions.setDeleteModalCategoryId(null))
  yield put(actions.fetchCategories())
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: `Article${ids.length === 1 ? '' : 's'} deleted successfully`,
    })
  )
}

function* handleDeleteFlaggings(action) {
  try {
    yield api.deleteFlaggings(action.payload)
  } catch (error) {
    yield put(actions.setIsLoading(false))
    yield* handleSagaError(error)
    return
  }
  yield put(actions.fetchCategories())
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: `Article${action.payload.articleIds.length === 1 ? '' : 's'} removed successfully`,
    })
  )
}

function prepareCategories(categories) {
  return categories.map(c => {
    return {
      ...c,
      flaggings: c.flaggings.map(f => {
        return {
          ...f,
          creditFeed: f.creditFeed ? {...f.creditFeed} : {...f.article.feed},
          article: {
            ...f.article,
            feedId: f.article.feed.id,
          }
        }
      }),
    }
  })
}

function* handleCategorySelection(action) {
  const flaggedItems = yield select(getFlaggedItems)
  const version = flaggedItems.pageVersion
  if (version == 'old') {
    /* do nothing */
  }else{
    yield put(actions.fetchCategories())
  }
}

export default function*() {
  yield all([
    takeLatest(
      actions.init,
      handleFetchInitData,
    ),
    takeLatest(
      actions.fetchCategories,
      handleFetchCategories,
    ),
    takeLatest(
      actions.fetchPromotedSearches,
      handleFetchPromotedSearches,
    ),
    takeLatest(
      actions.exportPDF,
      handleExportPDF,
    ),
    takeLatest(
      actions.exportDoc,
      handleExportDoc,
    ),
    takeLatest(
      actions.exportExcel,
      handleExportExport,
    ),
    takeLatest(
      actions.exportEmail,
      handleExportEmail,
    ),
    takeLatest(
      actions.createCategory,
      handleCreateCategory,
    ),
    takeLatest(
      actions.deleteCategories,
      handleDeleteCategories,
    ),
    takeLatest(
      actions.clearCategories,
      handleClearCategories,
    ),
    takeLatest(
      actions.saveCategory,
      handleSaveCategory,
    ),
    takeLatest(
      actions.deleteArticles,
      handleDeleteArticles,
    ),
    takeLatest(
      actions.deleteFlaggings,
      handleDeleteFlaggings,
    ),
    takeLatest(
      actions.setSelectedCategoryIds,
      handleCategorySelection
    )
  ])
}