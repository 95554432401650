import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {Portal} from 'react-portal'

import {isLeftClickEvent} from 'app/utils'

import styles from './BareModal.less'


export default class BareModal extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    shouldCloseOnOutsideClick: PropTypes.bool,
    className: PropTypes.string,
  }

  static defaultProps = {
    shouldCloseOnOutsideClick: true,
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  render() {
    const {children, shouldCloseOnOutsideClick, className} = this.props
    return (
      <Portal>
        <div className={styles.modal}>
          <div
            className={styles.mask}
            onClick={shouldCloseOnOutsideClick ? this.handleMaskClick : null}
          >
            <div
              className={classNames(styles.content, className)}
              ref={ref => this.contentContainer = ref}
            >
              {children}
            </div>
          </div>
        </div>
      </Portal>
    )
  }

  // Event handlers

  handleMaskClick = event => {
    if (!this.contentContainer || !isLeftClickEvent(event)) return
    // Only close the modal if we clicked outside of it
    if (!this.contentContainer.contains(event.target)) {
      this.closeModal()
    }
  }

  handleKeyDown = event => {
    if (event.key === 'Escape' && this.props.shouldCloseOnOutsideClick) {
      this.closeModal()
    }
  }

  // Helpers

  closeModal() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }
}
