import store from 'app/store'

import * as actions from './notifications-actions'
import * as constants from './notifications-constants'

const showNotification = ({type, message}) =>
  store.dispatch(actions.showNotification({type, message}))

// Used to mock the YUI `Y.Notify` object we use in legacy code.
export const notifyMock = {
  success: message =>
    showNotification({type: constants.NOTIFICATION_TYPES.SUCCESS, message}),
  error: message =>
    showNotification({type: constants.NOTIFICATION_TYPES.ERROR, message}),
}
