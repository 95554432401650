import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { getCsrfToken } from 'app/utils/django'
import * as notifications from 'app/global/notifications'
import { User } from 'app/models'
import Orm from 'app/framework/Orm'
import Tooltip from 'app/common/Tooltip'
import InputBlock from 'app/common/InputBlock'
import TextBox from 'app/common/TextBox'
import Dropdown from 'app/common/Dropdown'
import Button from 'app/common/Button'

import { getProfileAccount } from './profile-account-selectors'
import {
  createMembership,
  deleteMembership,
  setSaveData,
  setPasswordConfirmModalOpen,
  setCurrentPassword,
} from './profile-account-actions'

import './ProfileAccount.less'
import Modal from "../../common/Modal";


@connect(
  state => {
    const profileAccount = getProfileAccount(state)
    const orm = Orm.withEntities(state.entities)

    return {
      orgType: state.currentFirmOrgType,
      isDiligent: state.isDiligent,
      currentUserHasInsights: state.currentUserHasInsights,
      insightsLabel: state.insightsLabel,
      currentUserIsClientAdmin: state.currentUserIsClientAdmin,
      user: profileAccount.isInitializing ? null : orm.getById(User, state.currentUser),
      locations: profileAccount.locations,
      firmLocations: profileAccount.firmLocations,
      roles: profileAccount.roles,
      timezones: profileAccount.timezones,
      groups: orm.getByIds(User, profileAccount.groupIds),
      saveData: profileAccount.saveData,
      passwordConfirmModalOpen: profileAccount.passwordConfirmModalOpen,
      currentPassword: profileAccount.currentPassword,
    }
  },
  {
    createMembership,
    deleteMembership,
    setSaveData,
    setPasswordConfirmModalOpen,
    setCurrentPassword,
    showNotification: notifications.actions.showNotification,
  },
)
export default class UserProfile extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
  }

  render() {
    const {
      orgType,
      isDiligent,
      currentUserHasInsights,
      insightsLabel,
      currentUserIsClientAdmin,
      user,
      locations,
      firmLocations,
      roles,
      timezones,
      groups,
      saveData,
      currentPassword,
      passwordConfirmModalOpen,
      setPasswordConfirmModalOpen,
    } = this.props

    const csrfToken = getCsrfToken()

    const detailsHeader =
      currentUserHasInsights
        ? <span>Your Base and {insightsLabel} Details</span>
        : <span>Your Details</span>

    const groupsTooltipContent = "This allows you to opt in as a member of a Group Profile. Group Profiles send " +
      "separate email alerts (in addition to your personal profile alert) or Newsletters, often to Practice/Client " +
      "Teams, or focused on specific areas of work."

    const locationOptions =
      [
        {value: 0, label: 'Please Select...'},
        ...locations
          .filter(l => l.favored)
          .map(l => ({value: l.id, label: l.name})),
        {value: '', label: '----------'},
        ...locations.map(l => ({value: l.id, label: l.name})),
      ]

    const officeLocationOptions =
      [
        {value: 0, label: '--'},
        ...firmLocations.map(fl => ({value: fl.id, label: fl.description})),
      ]

    const roleOptions =
      roles.length === 0
        ? [{value: '', label: '--'}]
        : roles.filter(r => r.id !== 'group').map(r => ({value: r.id, label: r.name}))

    const favoredTimezoneIds = user ? user.favoredTimezones.map(tz => tz.id) : []

    const timezoneOptions =
      user
        ? [
            ...user.favoredTimezones.map(tz => ({value: tz.id, label: tz.name})),
            {value: '', label: '----------'},
            ...timezones
              .filter(tz => !favoredTimezoneIds.includes(tz.id))
              .map(tz => ({value: tz.id, label: tz.name})),
          ]
        : [{value: '', label: '--'}]

    const memberGroupIds =
      user
        ? user.groupMemberships.map(m => m.group.id)
        : []

    const managerGroupIds =
      user
        ? user.groupMemberships.filter(m => m.isManager).map(m => m.group.id)
        : []

    const groupList =
      [
        <InputBlock key={0} label={`${orgType} Wide Group`} isInline className="firm-wide-group">
          <input
            type="checkbox"
            checked={true}
            disabled
          />
        </InputBlock>,
        ...groups
          .filter(g => !g.isPrivateGroup || currentUserIsClientAdmin || memberGroupIds.includes(g.id))
          .map(g => {
            return (
              <InputBlock key={g.id} label={g.firstName} isInline>
                <input
                  type="checkbox"
                  checked={memberGroupIds.includes(g.id)}
                  onChange={evt => this.toggleMembership(g.id, evt.target.checked)}
                  disabled={managerGroupIds.includes(g.id)}
                />
              </InputBlock>
            )
          })
      ]

    const linkedinButton =
      user
        ? user.linkedinToken
            ? <form action="/disconnect/linkedin-oauth2/" method="POST">
                <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken}/>
                <Button
                  label="Disconnect LinkedIn"
                  className="social linkedin"
                  type="submit"
                />
              </form>
            : <form action="/login/linkedin-oauth2/">
                <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken}/>
                <Button
                  label="Connect LinkedIn"
                  className="social linkedin"
                  type="submit"
                />
              </form>
        : null

    const twitterButton =
      user
        ? user.twitterToken
            ? <form action="/disconnect/twitter/" method="POST">
                <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken}/>
                <Button
                  label="Disconnect Twitter"
                  className="social twitter"
                  type="submit"
                />
              </form>
            : <form action="/login/twitter/">
                <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken}/>
                <Button
                  label="Connect Twitter"
                  className="social twitter"
                  type="submit"
                />
              </form>
        : null

    const socialMediaBlock =
      isDiligent
        ? null
        : <div>
            <h2 className="header">Social Media</h2>

            <InputBlock label="">
              <div>
                {linkedinButton}
              </div>
            </InputBlock>

            <InputBlock label="">
              <div>
                {twitterButton}
              </div>
            </InputBlock>
          </div>

    const departmentsBlock =
      <div className="classifications">
        {user && (
          user.departments.length === 0
            ? <div className="classification">No assigned Departments</div>
            : user.departments.map(d => {
                return (
                  <div key={d.id} className="classification">{d.name}</div>
                )
              })
        )}
      </div>

    const teamsBlock =
      <div className="classifications">
        {user && (
          user.teams.length === 0
            ? <div className="classification">No assigned Teams</div>
            : user.teams.map(t => {
                return (
                  <div key={t.id} className="classification">{t.name}</div>
                )
              })
        )}
      </div>

    const firstName = saveData.hasOwnProperty('firstName') ? saveData.firstName : (user ? user.firstName : '')

    const lastName = saveData.hasOwnProperty('lastName') ? saveData.lastName : (user ? user.lastName : '')

    const username = saveData.hasOwnProperty('username') ? saveData.username : (user ? user.username : '')

    const firmLocationId =
      saveData.hasOwnProperty('firmLocationId')
        ? saveData.firmLocationId
        : (user && user.firmLocationId) || 0

    const password = saveData.hasOwnProperty('password') ? saveData.password : ''

    const confirmPassword = saveData.hasOwnProperty('confirmPassword') ? saveData.confirmPassword : ''

    const phone = saveData.hasOwnProperty('phone') ? saveData.phone : (user ? user.phone : '')

    const role = saveData.hasOwnProperty('role') ? saveData.role : (user ? user.role : '')

    const locationId = saveData.hasOwnProperty('locationId') ? saveData.locationId : (user ? user.locationId : 0)

    const timezone = saveData.hasOwnProperty('timezone') ? saveData.timezone : (user ? user.timezone : '')

    const onSave = (e) => {
      try { e.preventDefault() } catch {}
      if(!currentPassword && (saveData.password || saveData.username)) {
        setPasswordConfirmModalOpen(true);
      } else {
        this.props.onSave();
      }
    }

    const passwordConfirmModal = (
      <Modal
        className="modal"
        isOpen={passwordConfirmModalOpen}
        onClose={() => {
          setPasswordConfirmModalOpen(false);
          this.handleCurrentPasswordChange('');
        }}
      >
        <h1>Please confirm your current password</h1>
        <form onSubmit={onSave}>
          <InputBlock label="Current Password">
            <TextBox
              value={currentPassword}
              type="password"
              autoComplete="current-password"
              onChange={evt => this.handleCurrentPasswordChange(evt.target.value)}
            />
          </InputBlock>
          <div className="buttons">
            <div>
              <Button
                label="Save"
                onClick={onSave}
              />
            </div>
          </div>
          <input type={'submit'} hidden/>
        </form>
      </Modal>
    )

    return (
      <div className="profile-account">
        {this.props.passwordConfirmModalOpen &&
        passwordConfirmModal}
        <div className="column left large">
          <h2 className="header">{detailsHeader}</h2>

          <div className="column left">
            <InputBlock label="First Name">
              <TextBox
                value={firstName}
                onChange={evt => this.handleFirstNameChange(evt.target.value)}
              />
            </InputBlock>

            <InputBlock label="Last Name">
              <TextBox
                value={lastName}
                onChange={evt => this.handleLastNameChange(evt.target.value)}
              />
            </InputBlock>

            <InputBlock label="Email Address">
              <TextBox
                value={username}
                onChange={evt => this.handleUsernameChange(evt.target.value)}
              />
            </InputBlock>

            <InputBlock label="Office Location">
              <Dropdown
                options={officeLocationOptions}
                value={firmLocationId}
                onChange={value => this.handleFirmLocationChange(value)}
              />
            </InputBlock>

            <h2 className="header">Your Password</h2>

            <InputBlock label="Change Password">
              <Tooltip render={() =>
                <div>
                  <ul>
                    <li>Password needs to be between 8-128 characters.</li>
                    <li>It can not be too similar to your username, email address, first or last name.</li>
                    <li>The new password can not match the last 5 passwords.</li>
                  </ul>
                </div>}>
              <a className="password tooltip help"/>
              </Tooltip>
              <TextBox
                value={password}
                type="password"
                autoComplete="new-password"
                onChange={evt => this.handlePasswordChange(evt.target.value)}
              />
            </InputBlock>

            <InputBlock label="Confirm Password">
              <TextBox
                value={confirmPassword}
                type="password"
                autoComplete="new-password"
                onChange={evt => this.handleConfirmPasswordChange(evt.target.value)}
              />
            </InputBlock>

            <h2 className="header">Departments</h2>

            {departmentsBlock}
          </div>

          <div className="column right">
            <InputBlock label="Phone">
              <TextBox
                value={phone}
                onChange={evt => this.handlePhoneChange(evt.target.value)}
              />
            </InputBlock>

            <InputBlock label="Role">
              <Dropdown
                options={roleOptions}
                value={role}
                onChange={value => this.handleRoleChange(value)}
              />
            </InputBlock>

            <InputBlock label="Country">
              <Dropdown
                options={locationOptions}
                value={locationId}
                onChange={value => this.handleLocationChange(value)}
              />
            </InputBlock>

            <InputBlock label="Timezone">
              <Dropdown
                options={timezoneOptions}
                value={timezone}
                onChange={value => this.handleTimezoneChange(value)}
              />
            </InputBlock>

            {socialMediaBlock}

            <h2 className="header">Teams</h2>

            {teamsBlock}
          </div>
        </div>

        <div className="column right small">
          <h2 className="header">
            <span>Groups</span>
            <Tooltip label={groupsTooltipContent}>
              <a className="tooltip help"/>
            </Tooltip>
          </h2>

          <div className="group-list">{groupList}</div>
        </div>

        <div className="buttons">
          <div>
            <Button
              label="Save"
              onClick={() => onSave()}
            />
          </div>
        </div>
      </div>
    )
  }

  handleFirstNameChange(value) {
    const saveData = {...this.props.saveData}
    saveData.firstName = value
    this.props.setSaveData(saveData)
  }

  handleLastNameChange(value) {
    const saveData = {...this.props.saveData}
    saveData.lastName = value
    this.props.setSaveData(saveData)
  }

  handleUsernameChange(value) {
    const saveData = {...this.props.saveData}
    saveData.username = value
    this.props.setSaveData(saveData)
  }

  handleFirmLocationChange(value) {
    const saveData = {...this.props.saveData}
    saveData.firmLocationId = value
    this.props.setSaveData(saveData)
  }

  handlePasswordChange(value) {
    const saveData = {...this.props.saveData}
    saveData.password = value
    this.props.setSaveData(saveData)
  }

  handleCurrentPasswordChange(value) {
    this.props.setCurrentPassword(value)
  }

  handleConfirmPasswordChange(value) {
    const saveData = {...this.props.saveData}
    saveData.confirmPassword = value
    this.props.setSaveData(saveData)
  }

  handlePhoneChange(value) {
    const saveData = {...this.props.saveData}
    saveData.phone = value
    this.props.setSaveData(saveData)
  }

  handleRoleChange(value) {
    const saveData = {...this.props.saveData}
    saveData.role = value
    this.props.setSaveData(saveData)
  }

  handleLocationChange(value) {
    const saveData = {...this.props.saveData}
    saveData.locationId = value
    this.props.setSaveData(saveData)
  }

  handleTimezoneChange(value) {
    const saveData = {...this.props.saveData}
    saveData.timezone = value
    this.props.setSaveData(saveData)
  }

  toggleMembership(groupId, checked) {
    if (checked) {
      this.props.createMembership(groupId)
    } else {
      this.props.deleteMembership(groupId)
    }
  }
}
