import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import classNames from 'classnames'
import {titleCase} from 'title-case'
import {joinInSentence} from 'app/utils'
import * as R from 'ramda'

import {getEntities} from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import {Feed} from 'app/models'
import Table from 'app/common/Table'
import Button from 'app/common/Button'
import ConfirmationModal from 'app/common/modals/ConfirmationModal'

import {
  removeFeaturedSources,
} from '../sources-admin-actions'
import {getSourcesAdmin} from '../sources-admin-selectors'

import * as styles from './FeaturedForContent.less'


@connect(
  createSelector(
    [getEntities, getSourcesAdmin],
    (entities, sourcesAdmin) => {
      const orm = Orm.withEntities(entities)
      return {
        editModalData: sourcesAdmin.editModalData,
        feed: orm.getById(Feed, sourcesAdmin.editModalData.feedId),
      }
    }
  ),
  {
    removeFeaturedSources,
  },
)
export default class FeaturedForContent extends Component {
  state = {
    // user, department, or team
    removeFromType: null,
    selectedItems: [],
    deleteItems: [],
  }

  render() {
    const tableData = this.featuredForItems

    const deleteItemTypes = joinInSentence([...new Set(this.state.deleteItems.map(item => `${titleCase(item.type)}${this.state.deleteItems.length === 1 ? '' : 's'}`))])

    const selectedText = this.state.deleteItems.length === 1 ? 'this' : 'the selected'

    const deleteConfirmationModal = this.state.deleteItems.length > 0 &&
      <ConfirmationModal
        message={<span>Are you sure you want to remove <strong>{this.props.feed.name}</strong> as a Featured Source from {selectedText} {deleteItemTypes}?</span>}
        secondaryMessage={<span>If you proceed, <strong>{this.props.feed.name}</strong> will no longer be featured for {selectedText} {deleteItemTypes}.</span>}
        confirmButtonText="Remove"
        onConfirm={() => this.handleConfirmDeleteSelectedButtonClick()}
        onClose={() => this.setState({deleteItems: []})}
        isDestructive={true}
      />

    return (
      <div>
        {
          tableData.length === 0
            ? <p>This Source is not featured for any Users, Departments, or Teams.</p>
            : <React.Fragment>
              <div className={styles.bulkActions}>
                <input
                  type="checkbox"
                  className={styles.selectAllCheckbox}
                  checked={this.state.selectedItems.length === tableData.length}
                  onChange={(event) => this.handleSelectAllCheckbox(event.target.checked)}
                />

                <Button
                  label="Delete Selected"
                  disabled={this.state.selectedItems.length === 0}
                  onClick={() => this.handleDeleteSelectedButtonClick()}
                />
              </div>

              <Table
                data={tableData}
                includeHeader={false}
              >
                <Table.Column
                  name="checkbox"
                  baseWidth="25px"
                  growRatio={0}
                  cellContents={item => {
                    return (
                      <input
                        type="checkbox"
                        checked={!!this.state.selectedItems.find(i => i.id === item.id && i.type === item.type)}
                        onChange={(event) => this.handleItemCheckbox(item, event.target.checked)}
                      />
                    )
                  }}
                />

                <Table.Column
                  name="name"
                  baseWidth="40%"
                  cellContents={R.prop('name')}
                />

                <Table.Column
                  name="type"
                  baseWidth="15%"
                  cellContents={item => titleCase(item.type)}
                />

                <Table.Column
                  name="actions"
                  baseWidth="40%"
                  className={styles.actions}
                  cellContents={item =>
                    <i
                      className={classNames('fa fa-trash-o', styles.actionButton)}
                      onClick={() => this.handleDeleteButtonClick(item)}
                    />
                  }
                />
              </Table>
            </React.Fragment>
        }

        {deleteConfirmationModal}
      </div>
    )
  }

  get featuredForItems() {
    const {feed} = this.props
    return [
      ...feed.featuredForUsers.map(u => ({name: u.displayName, id: u.id, type: u.role === 'group' ? 'group' : 'user'})),
      ...feed.featuredForDepartments.map(d => ({name: d.name, id: d.id, type: 'department'})),
      ...feed.featuredForTeams.map(t => ({name: t.name, id: t.id, type: 'team'})),
    ]
  }

  handleItemCheckbox(item, checked) {
    let selectedItems = [...this.state.selectedItems]
    if (checked) {
      selectedItems.push({...item})
    } else {
      selectedItems = selectedItems.filter(i => i.id !== item.id || i.type !== item.type)
    }
    this.setState({selectedItems})
  }

  handleSelectAllCheckbox(checked) {
    let selectedItems = []
    if (checked) {
      selectedItems = [...this.featuredForItems]
    }
    this.setState({selectedItems})
  }

  handleDeleteSelectedButtonClick() {
    this.setState({deleteItems: [...this.state.selectedItems]})
  }

  handleConfirmDeleteSelectedButtonClick() {
    this.props.removeFeaturedSources([...this.state.deleteItems])
    this.setState({selectedItems: [], deleteItems: []})
  }

  handleDeleteButtonClick(item) {
    const removeFromType = item.type === 'group' ? 'user' : item.type
    this.setState({removeFromType, deleteItems: [item]})
  }
}
