import classNames from 'classnames'
import invariant from 'invariant'
import PropTypes from 'prop-types'
import React from 'react'

import Dropdown from 'app/common/Dropdown'
import SearchResultsActions from 'app/reusable/SearchResultsActions'

import styles from './ActionBar.less'

const SELECT_ALL_VALUES = {
  ALL: 'all',
  UNGROUPED: 'ungrouped',
  DESELECT: 'deselect',
}


const UpcomingEvents = ({enabled, onChange}) => (
  <label className={styles.insightsFirst}>
    <input
      type="checkbox"
      checked={enabled}
      onChange={event => onChange(event.target.checked)}
      className={styles.checkbox}
    />
    <span className={styles.label}>
      include upcoming events
    </span>
  </label>
)
UpcomingEvents.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}


export default function ActionBar({
  isActionDropdownDisabled,
  areAllDocumentsSelected,
  topLevelDocumentCount,
  totalDocumentCount,
  onSelectAllTopLevel,
  onSelectAll,
  onDeselectAll,
  onActionSelect,
  className,
}) {
  return (
    <div className={classNames(styles.actionBar, className)}>
      <div className={styles.mainActions}>
        <Dropdown
          selectedOptionLabel={
            <input
              type="checkbox"
              checked={areAllDocumentsSelected}
              onChange={() => {}} // to squelch the React warning
            />
          }
          onChange={value => {
            const action = {
              [SELECT_ALL_VALUES.UNGROUPED]: onSelectAllTopLevel,
              [SELECT_ALL_VALUES.ALL]: onSelectAll,
              [SELECT_ALL_VALUES.DESELECT]: onDeselectAll,
            }[value]
            invariant(action, `Invalid value '${value}'.`)
            action()
          }}
          value={null}
          className={styles.selectAll}
          selectedOptionClassName={styles.checkboxContainer}
          menuClassName={styles.menu}
        >
          <Dropdown.Option
            label={`Select All Visible (${topLevelDocumentCount})`}
            value={SELECT_ALL_VALUES.UNGROUPED}
          />
          <Dropdown.Option
            label={`Select All Visible Including Similar (${totalDocumentCount})`}
            value={SELECT_ALL_VALUES.ALL}
          />
          <Dropdown.Option
            label="Deselect All"
            value={SELECT_ALL_VALUES.DESELECT}
          />
          <Dropdown.Option
            label=""
            value={null}
            isInvisible={true}
          />
        </Dropdown>
        <div className={styles.actionsDropdownContainer}>
          <SearchResultsActions
            onChange={onActionSelect}
            isDisabled={isActionDropdownDisabled}
          />
        </div>
      </div>
    </div>
  )
}
ActionBar.propTypes = {
  isActionDropdownDisabled: PropTypes.bool.isRequired,
  areAllDocumentsSelected: PropTypes.bool.isRequired,
  topLevelDocumentCount: PropTypes.number.isRequired,
  totalDocumentCount: PropTypes.number.isRequired,
  onSelectAllTopLevel: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onDeselectAll: PropTypes.func.isRequired,
  onActionSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
}
