import PropTypes from 'prop-types'
import React, { Component } from 'react'
import InputBlock from "app/common/InputBlock"
import Dropdown from "app/common/Dropdown"
import './NotificationsBulkActions.less'
import connect from "react-redux/es/connect/connect"
import * as actions from "app/email-notifications/email-notifications-actions"
import * as constants from "app/email-notifications/email-notifications-constants"


@connect(
  state => ({
    editUserId: state.emailNotifications.emailDeliveryData.editUserId,
    baseFrequency: state.emailNotifications.baseFrequency,
    bulkActionState: state.emailNotifications.bulkActionState,
    baseContentConfigs: state.emailNotifications.baseNoticeConfigs.baseContentConfigs,
    baseSettings: state.emailNotifications.baseSettings,
  }),
  {
    changeSelectedConfigs: actions.changeSelectedConfigs,
    removeSelectedConfigs: actions.removeSelectedConfigs,
    changeBulkActionState: actions.changeBulkActionState,
    resetBulkActionState: actions.resetBulkActionState,
    updateContentSettings: actions.api.updateContentSettings.request,
    saveBaseNoticeConfigs: actions.api.saveBaseNoticeConfigs.request,
  },
)
export default class NotificationsBulkActions extends Component {
  static propTypes = {
    category: PropTypes.string.isRequired,
    notificationType: PropTypes.string.isRequired,
    frequencyOptions: PropTypes.array.isRequired,
    allCategoryConfigs: PropTypes.array,
    allSelected: PropTypes.bool,
    categorySelectedConfigs: PropTypes.array,
  }
  static defaultProps = {
    categorySelectedConfigs: [],
    allSelected: false,
    allCategoryConfigs: [],
  }

  render() {
    const {allSelected, categorySelectedConfigs, frequencyOptions} = this.props
    const anySelected = categorySelectedConfigs
      ? categorySelectedConfigs.length > 0
      : false
    const bulkSelections = this.props.bulkActionState[this.props.category]
    const anyBulkSelections = bulkSelections
      ? Object.values(bulkSelections).filter(item => item.value !== -1).length > 0
      : false

    return (
      <div className="bulk-actions">
        <div className={'select-all-container'}>
          <input
            className="select-all"
            type="checkbox"
            onChange={this.onSelectAllChange}
            checked={allSelected}
          />
        </div>
        {this.getFrequencyDropdown(frequencyOptions, bulkSelections)}
        {this.getBulkMaxItems(bulkSelections)}
        <div className={'buttons-container'}>
          <button
            className="apply button"
            disabled={!anySelected || !anyBulkSelections}
            onClick={() => {this.onApplyClick(bulkSelections)}}
          >
            Apply
          </button>
        </div>
      </div>
    )
  }
  onSelectAllChange = (e) => {
    this.props.allCategoryConfigs.forEach(config => {
      this.props.changeSelectedConfigs({
        configId: config.id,
        noticeFrequency: this.props.baseFrequency,
        category: this.props.category,
        selected: e.target.checked
      })
    })
  }
  getBulkMaxItems(bulkSelections) {
    const bulkMaxItems = Object.keys(constants.NOTICE_PUBLICATION_TYPE).map((type, idx) => {
      return(
        this.getMaxItemsDropdown(type, idx, bulkSelections)
      )
    })
    return (
      <div className={'bulk-max-items-container'}>
        {bulkMaxItems}
      </div>
    )
  }
  onMaxItemsSelect(publicationType, selectedOptionData) {
    this.props.changeBulkActionState({
      bulkItem: publicationType,
      category: this.props.category,
      value: selectedOptionData.key,
    })
  }
  onFrequencySelect(selectedOptionData) {
    this.props.changeBulkActionState({
      bulkItem: constants.FREQUENCY,
      category: this.props.category,
      value: selectedOptionData.key,
    })
  }
  getFrequencyDropdown(frequencyOptions, bulkSelections) {
    const category = this.props.category
    let activeFrequencySelection = bulkSelections && bulkSelections[constants.FREQUENCY]
      ? constants.BASE_EMAIL_FREQUENCIES[bulkSelections[constants.FREQUENCY].value]
      : constants.NONE_SELECTED
    if (activeFrequencySelection === undefined){
      activeFrequencySelection = constants.NONE_SELECTED
    }
    return (
      <InputBlock
        className={'bulk-frequency-dropdown'}
        label={'Frequency'}
        key={category}
      >
        <Dropdown
          type={'text'}
          options={frequencyOptions}
          value={activeFrequencySelection}
          onSelect={this.onFrequencySelect.bind(this)}
        />
      </InputBlock>
    )
  }
  getMaxItemsDropdown(publicationType, idx, bulkSelections) {
    const category = this.props.category
    let activeBulkSelection = bulkSelections && bulkSelections[publicationType]
      ? bulkSelections[publicationType].value
      : constants.NONE_SELECTED
    if (activeBulkSelection === -1){activeBulkSelection = constants.NONE_SELECTED}
    return (
      <InputBlock
        className={'max-items-dropdown'}
        label={constants.NOTICE_PUBLICATION_TYPE[publicationType]}
        key={idx}
      >
        <Dropdown
          type={'text'}
          options={category === constants.TRUSTED
            || category === constants.TRUSTED_UNCATEGORIZED
            ? constants.MAX_ITEMS_OPTIONS_BULK_ALL
            : constants.MAX_ITEMS_OPTIONS_BULK}
          value={activeBulkSelection}
          onSelect={this.onMaxItemsSelect.bind(this, publicationType)}
        />
      </InputBlock>
    )
  }
  onApplyClick(bulkSelections) {
    const maxItemsBulkSelections = bulkSelections
      ? Object.values(bulkSelections).filter(item =>
        item.value !== -1 && item.bulkItem !== constants.FREQUENCY)
      : null
    const frequencyBulkSelection = bulkSelections
      ? Object.values(bulkSelections).filter(item =>
        item.value !== -1 && item.bulkItem === constants.FREQUENCY)
      : null
    if (maxItemsBulkSelections.length > 0) {
      const contentSettingsData = []
      this.props.categorySelectedConfigs.forEach(config => {

        const contentSettings = JSON.parse(
          JSON.stringify(this.props.baseContentConfigs[config.configId]))

        maxItemsBulkSelections.forEach(selection => {
          const updateSetting = contentSettings.find(s =>
            s.noticePublicationType === selection.bulkItem)
          updateSetting.maxItems = selection.value
          const settings = {
            searchConfigId: config.configId,
            contentSettings: contentSettings
          }
          contentSettingsData.push(settings)
        })
      })
      if (contentSettingsData.length > 0){
        this.props.updateContentSettings({
          editUserId: this.props.editUserId,
          notificationType: this.props.notificationType,
          contentSettingsData: contentSettingsData,
        })
      }
    }
    if (frequencyBulkSelection.length > 0) {
      const updateConfigIds = this.props.categorySelectedConfigs.map(config => {
        return(config.configId)
      })
      const frequencySelection = frequencyBulkSelection.find(freq => {
        return freq.bulkItem === constants.FREQUENCY})

      this.props.saveBaseNoticeConfigs({
        editUserId: this.props.editUserId,
        type: this.props.notificationType,
        field: constants.FREQUENCY,
        value: frequencySelection.value,
        configIds: updateConfigIds,
      })
    }
    this.props.resetBulkActionState({
      category: this.props.category
    })
    const configIds = this.props.allCategoryConfigs.map(config => {return(config.id)})
    this.props.removeSelectedConfigs(configIds)
  }
}

