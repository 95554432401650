import { handleActions } from 'redux-actions'
import * as actions from './email-notifications-actions'
import { assocPath } from 'ramda'
import * as constants from './email-notifications-constants'

const getInitialState = () => ({
  emailDeliveryData: {
    selected: [],
    isSignalsEnabled: null,
    isGroupUser: null,
    editUserId: null,
    editUserFullName: null,
    defaultUserEmail: null,
    quickFilterOptions: {},
  },
  addPrefixSuffixData:{
    selected: [],
    editUserId: null,
    editUserFullName: null,
    defaultUserEmail: null,
  },
  applyEmailDeliverySettings:{
    editUserId: null,
  },
   applyCategoryOrdersSettings:{
    editUserId: null,
  },
  baseSettings: {
    settings: [],
    frequencies: [],
    searchCategories: [],
    notificationType: null,
    hideDuplicateArticles: null,
    summaryTextLength: null,
    categoryDefaults: [],
    useAlternateEmailTemplate: false,
  },
  insightsSettings: {
    settings: [],
    frequencies: [],
    excludeFrequency: null,
    searchCategories: [],
    notificationType: null,
    deliverOnWeekends: null,
  },
  esgSettings: {
    settings: [],
    frequencies: [],
    excludeFrequency: null,
    searchCategories: [],
    notificationType: null,
    deliverOnWeekends: null,
  },
  ccEmailsInsights: [],
  ccEmailsBase: [],
  ccEmailsEsg: [],
  insightsNoticeConfigs: {
    totalSubFactorsCount: null,
    configsByCategory: {},
    showFactorsModal: null,
    availableFactors: [],
    configs: {},
  },
  esgNoticeConfigs: {
    totalSubFactorsCount: null,
    configsByCategory: {},
    showFactorsModal: null,
    availableFactors: [],
    configs: {},
  },
  baseNoticeConfigs: {
    categoryOrderModalState: {
      show: false,
      frequency: null,
      categories: [],
    },
    categoryDefaultsModalState: {
      show: false,
      categories: [],
    },
    contentSettingsModalState: {
      show: false,
      searchConfig: {},
      contentSettings: [],
      sendAlone: null,
    },
    frequencyHeader: null,
    baseConfigs: {},
    baseContentConfigs: {},
    frequencyToggle: {},
  },
  sendSampleModal: {
    show: false,
    notificationType: null,
    frequencyHeader: null,
    noticeFrequency: null,
    recipients: [],
    editUserId: null,
  },
  FrequencyBulkAssign: {
    show: false,
    category: null,
    frequency: null,
    bulkType: null,
    subfactors: null,
  },
  categoryToggle: {},
  baseFrequency: constants.ALL_FREQ,
  notificationSettings: {},
  selectedConfigs: {},
  bulkActionState: {},
  selectedPrefix:'',
  selectedSuffix:'',
  emailDeliveryToGroupMembers: ''
})

export default handleActions({
  [actions.emailDeliveryInit](state, action) {
    return {
      ...state, emailDeliveryData: action.payload,
      selectedPrefix: action.payload.selectedPrefix,
      selectedSuffix: action.payload.selectedSuffix,
      emailDeliveryToGroupMembers: action.payload.emailDeliveryToGroupMembers,
    }
  },
  [actions.api.saveEmailDelivery.success](state, action) {
    return {...state, emailDeliveryData: action.payload.response.body.data}
  },
  [actions.api.addEmailPrefixSuffix.success](state, action) {
    return {
      ...state,
        addPrefixSuffixData: action.payload.response.body.data,
        selectedPrefix: action.payload.response.body.data.selectedPrefix,
        selectedSuffix: action.payload.response.body.data.selectedSuffix,
      }
  },
  [actions.api.applyEmailDeliverySettings.success](state, action) {
    return {
      ...state, applyEmailDeliverySettings: action.payload.response.body.data,
      emailDeliveryToGroupMembers: action.payload.response.body.data.isSelected
    }
  },
  [actions.api.applyCategoryOrdersSettings.success](state, action) {
    return {...state, applyCategoryOrdersSettings: action.payload.response.body.data}
  },
  [actions.insightsEmailSettingsInit](state, action) {
    return {...state, insightsSettings: action.payload}
  },
  [actions.esgEmailSettingsInit](state, action) {
    return {...state, esgSettings: action.payload}
  },
  [actions.baseNotificationsInit](state, action) {
    if (action.payload.result.isProfilePage){
      return {
        ...state,
        isProfilePage: action.payload.result.isProfilePage,
        baseSettings: action.payload.result.baseEmailSettings,
      }
    } else {
      return {
        ...state,
        isProfilePage: action.payload.result.isProfilePage,
        baseSettings: action.payload.result.baseEmailSettings,
        emailDeliveryData: action.payload.result.emailDeliveryData,
        selectedPrefix: action.payload.result.emailDeliveryData.selectedPrefix,
        selectedSuffix: action.payload.result.emailDeliveryData.selectedSuffix,
        emailDeliveryToGroupMembers: action.payload.result.emailDeliveryData.emailDeliveryToGroupMembers,
      }
    }
  },
  [actions.api.getEmailAlertSettings.success](state, action) {
    if(action.payload.response.body.notificationType ===
      constants.BASE_NOTIFICATION_TYPE){
      return {...state, baseSettings:
          action.payload.response.body
      }
    } else if (action.payload.response.body.notificationType
      === constants.INSIGHTS_NOTIFICATION_TYPE){
      return {...state, insightsSettings:
          action.payload.response.body}
    }
  },
  [actions.api.saveEmailAlertSettings.success](state, action) {
    if(action.payload.response.body.notificationType ===
      constants.BASE_NOTIFICATION_TYPE){
      return {...state, baseSettings:
          action.payload.response.body
      }
    } else if (action.payload.response.body.notificationType
      === constants.INSIGHTS_NOTIFICATION_TYPE){
      return {...state, insightsSettings:
          action.payload.response.body}
    } else if (action.payload.response.body.notificationType == constants.ESG_NOTIFICATION_TYPE) {
      return {...state, esgSettings: action.payload.response.body}
    }
  },
  [actions.api.getCCEmailsInsights.success](state, action) {
    return {...state, ccEmailsInsights:
        action.payload.response.body.ccEmailsData
    }
  },
  [actions.api.getCCEmailsBase.success](state, action) {
    return {...state, ccEmailsBase:
        action.payload.response.body.ccEmailsData
    }
  },
  [actions.api.getCCEmailsEsg.success](state, action) {
    return {...state, ccEmailsEsg:
        action.payload.response.body.ccEmailsData
    }
  },
  [actions.api.updateCCEmails.success](state, action) {
    if (action.payload.response.body.notificationType ===
      constants.INSIGHTS_NOTIFICATION_TYPE) {
      return {...state, ccEmailsInsights:
          action.payload.response.body.ccEmailsData
      }
    } else if (action.payload.response.body.notificationType ===
      constants.BASE_NOTIFICATION_TYPE) {
      return {...state, ccEmailsBase:
          action.payload.response.body.ccEmailsData
      }
    } else if (action.payload.response.body.notificationType === constants.ESG_NOTIFICATION_TYPE) {
      return {...state, ccEmailsEsg: action.payload.response.body.ccEmailsData}
    }
  },
  [actions.api.getInsightsNoticeConfigs.success](state, action) {
    return {...state, insightsNoticeConfigs:
        action.payload.response.body.noticeConfigsData}
  },
  [actions.api.getEsgNoticeConfigs.success](state, action) {
    return {...state, esgNoticeConfigs:
        action.payload.response.body.noticeConfigsData}
  },
  [actions.api.saveInsightsNoticeConfigs.success](state, action) {
    return {...state, insightsNoticeConfigs:
        action.payload.response.body.noticeConfigsData}
  },
  [actions.api.saveEsgNoticeConfigs.success](state, action) {
    return {...state, esgNoticeConfigs:
        action.payload.response.body.noticeConfigsData}
  },
  [actions.api.getBaseNoticeConfigs.success](state, action) {
    return {...state, baseNoticeConfigs:
        action.payload.response.body.noticeConfigsData}
  },
  [actions.api.saveBaseNoticeConfigs.success](state, action) {
    return {...state, baseNoticeConfigs:
        action.payload.response.body.noticeConfigsData}
  },
  [actions.changeFactorsModalConfig](state, action) {
    return {...state, factorsModalConfig: action.payload}
  },
  [actions.changeInsightsShowFactorsModal](state, action) {
    return assocPath(
      ['insightsNoticeConfigs', 'showFactorsModal'],
      action.payload,
      state,
    )
  },
  [actions.changeEsgShowFactorsModal](state, action) {
    return assocPath(
      ['esgNoticeConfigs', 'showFactorsModal'],
      action.payload,
      state,
    )
  },
  [actions.changeCategoryOrderModalCategories](state, action) {
    return assocPath(
      ['baseNoticeConfigs', 'categoryOrderModalState', 'categories'],
      action.payload,
      state,
    )
  },
  [actions.api.updateCategoryOrder.success](state, action) {
    return assocPath(
      ['baseSettings', 'categoryDefaults'],
      action.payload.response.body.categoryDefaults,
      state,
    )
  },

  [actions.api.updateCategoryDefaults.success](state, action) {
    return assocPath(
      ['baseSettings', 'categoryDefaults'],
      action.payload.response.body.categoryDefaults,
      state,
    )
  },

  [actions.changeCategoryOrderModalShow](state, action) {
    return assocPath(
      ['baseNoticeConfigs', 'categoryOrderModalState', 'show'],
      action.payload,
      state,
    )
  },
  [actions.changeCategoryOrderModalFrequency](state, action) {
    return assocPath(
      ['baseNoticeConfigs', 'categoryOrderModalState', 'frequency'],
      action.payload,
      state,
    )
  },

  [actions.changeCategoryDefaultsModalShow](state, action) {
    return assocPath(
      ['baseNoticeConfigs', 'categoryDefaultsModalState', 'show'],
      action.payload,
      state,
    )
  },
  [actions.changeCategoryDefaultsModalCategories](state, action) {
    return assocPath(
      ['baseNoticeConfigs', 'categoryDefaultsModalState', 'categories'],
      action.payload,
      state,
    )
  },
  [actions.changeContentSettingsModalSettings](state, action) {
    return assocPath(
      ['baseNoticeConfigs', 'contentSettingsModalState', 'contentSettings'],
      action.payload,
      state,
    )
  },
  [actions.changeContentSettingsModalState](state, action) {
    return assocPath(
      ['baseNoticeConfigs', 'contentSettingsModalState'],
      action.payload,
      state,
    )
  },
  [actions.api.updateContentSettings.success](state, action) {
    return {...state, baseNoticeConfigs:
        action.payload.response.body.noticeConfigsData}
  },
  [actions.changeFrequencyHeader](state, action) {
    return assocPath(
      ['baseNoticeConfigs', 'frequencyHeader'],
      action.payload,
      state,
    )
  },
  [actions.changeFrequencyToggle](state, action) {
    return assocPath(
      ['baseNoticeConfigs', 'frequencyToggle', action.payload.frequency],
      action.payload.show,
      state,
    )
  },
  [actions.changeCategoryToggle](state, action) {
    return assocPath(
      ['categoryToggle', action.payload.category],
      action.payload.show,
      state,
    )
  },
  [actions.changeShowBulkUpdateModal](state, action) {
    return {...state, settingsBulkUpdate:
        action.payload}
  },
  [actions.changeFrequencyBulkUpdateConfigs](state, action) {
    return {...state, selectedFrequencyBulkAssignConfigs:
        action.payload}
  },
  [actions.changeBulkUpdateFilterBy](state, action) {
    return {...state, bulkUpdateFilterBy:
        action.payload}
  },
  [actions.changeSendSampleModal](state, action) {
    return {...state, sendSampleModal:
        action.payload}
  },
  [actions.changeSendSampleRecipients](state, action) {
    return assocPath(
      ['sendSampleModal', 'recipients'],
      action.payload,
      state,
    )
  },
  [actions.changeBaseFrequency](state, action) {
    return {...state, baseFrequency: action.payload}
  },
  [actions.api.getNotificationSettings.success](state, action) {
    return {...state, notificationSettings:
        action.payload.response.body.notificationSettings}
  },
  [actions.api.changeNotificationSettings.success](state, action) {
    return {...state, notificationSettings:
        action.payload.response.body.notificationSettings}
  },
  [actions.changeSelectedConfigs](state, action) {
    return assocPath(
      ['selectedConfigs', [action.payload.configId]],
      action.payload,
      state,
    )
  },
  [actions.removeSelectedConfigs](state, action) {
    const selectedConfigs = { ...state.selectedConfigs}
    action.payload.forEach(id => {
      delete selectedConfigs[id]
    })
    return {...state, selectedConfigs: selectedConfigs}
  },
  [actions.changeBulkActionState](state, action) {
    return assocPath(
      ['bulkActionState', [action.payload.category], [action.payload.bulkItem]],
      action.payload,
      state,
    )
  },
  [actions.resetBulkActionState](state, action) {
    return assocPath(
      ['bulkActionState', [action.payload.category]],
      null,
      state,
    )
  },

}, getInitialState())
