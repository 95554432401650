import * as R from 'ramda'

import { STATE_PROP_NAME } from './usage-constants'
import {
  STATE_PROP_NAME as FIRM_ADMIN_PROP_NAME
} from '../firm-admin-constants'

export const getUsage = R.path([FIRM_ADMIN_PROP_NAME, STATE_PROP_NAME])
export const isLoading = state => getUsage(state).isNewLoading
export const usageGrid = state => getUsage(state).usageGrid
export const usageGridFilters = state => getUsage(state).usageGridFilters
export const gridColumnFilters = state => getUsage(state).usageGridFilters.columnFilters
export const filtersData = state => getUsage(state).filtersData

export const summaryData = state => getUsage(state).usageSummary
export const usageSummaryDate = state => getUsage(state).usageSummaryFilters

