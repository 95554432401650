import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Button from 'app/common/Button'
import * as actions from '../comparison-actions'
import * as styles from './ComparisonEditModal.less'
import * as selectors from '../comparison-selectors'
import Modal from 'app/common/Modal'
import ComparisonSearchSelect from '../comparison-search-select'
import TextBox from 'app/common/TextBox'
import PropTypes from 'prop-types'

function ComparisonEditModal({comparisonGroup}) {
  const dispatch = useDispatch()
  const displayCategory = useSelector(selectors.getSingularDisplayCategory)
  const [comparisonTitle, setComparisonTitle] = useState(comparisonGroup.title)
  const [selectedSearchIds, setSelectedSearchIds] = useState(comparisonGroup.searchIds)

  const onClose = () => {
    dispatch(actions.setShowEditComparisonModal({show: false, group: {}}))
  }

  const handleOnSearchClick = searchId => {
    let selectedIds = [...selectedSearchIds]
    if (selectedIds.includes(searchId)) {
      selectedIds = selectedIds.filter(id => id !== searchId)
    } else {
      selectedIds.push(searchId)
    }
    setSelectedSearchIds(selectedIds)
  }

  const handleTitleOnChange = text => {
    setComparisonTitle(text)
  }

  const handleOnSaveClick = () => {
    dispatch(
      actions.saveComparisonSearchGroup({
        title: comparisonTitle,
        editComparisonGroup: comparisonGroup,
        searchIds: selectedSearchIds,
        searchIdsHaveChanged:
          JSON.stringify(selectedSearchIds) !==
          JSON.stringify(comparisonGroup.searchIds),
      }),
    )
    onClose()
  }

  const content = (
    <React.Fragment>
      <h2>{`Edit ${displayCategory} Comparison`}</h2>
      <div className={styles.comparisonTitle}>
        <TextBox
          autoFocus={true}
          className={styles.titleInput}
          value={comparisonTitle}
          onChange={e => handleTitleOnChange(e.target.value)}
        />
      </div>
      <ComparisonSearchSelect
        handleOnSearchClick={id => handleOnSearchClick(id)}
        selectedSearchIds={selectedSearchIds}
      />
      <div className={styles.buttons}>
        <Button
          label={'Save'}
          isPrimary
          onClick={() => handleOnSaveClick()}
          disabled={
            selectedSearchIds.length < 2 ||
            comparisonTitle.length < 1 ||
            (JSON.stringify(selectedSearchIds) ===
              JSON.stringify(comparisonGroup.searchIds) &&
              comparisonTitle === comparisonGroup.title)
          }
        />

        <Button
          className={styles.cancelButton}
          label="Cancel"
          isPlainText
          onClick={() => onClose()}
        />
      </div>
    </React.Fragment>
  )

  return (
    <Modal className={styles.comparisonEditModal} onClose={onClose}>
      {content}
    </Modal>
  )
}
ComparisonEditModal.propTypes = {
  comparisonGroup: PropTypes.object.isRequired,
}
export default ComparisonEditModal
