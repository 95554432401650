import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Dropdown from 'app/common/Dropdown'

import './style.less'

const VALUES = {
  TWENTY: 20,
  SIXTY: 60,
  HUNDRED: 100,
}


export default class NumberOfSearchResults extends Component {
  static propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    value: 20,
  }

  static VALUES = VALUES

  render() {
    const {value, onChange, className, ...props} = this.props
    const options = [
      {
        label: "20",
        value: VALUES.TWENTY,
        description: "20 Search Results",
      },
      {
        label: "60",
        value: VALUES.SIXTY,
        description: "60 Search Results",
      },
      {
        label: "100",
        value: VALUES.HUNDRED,
        description: "100 Search Results",
      },
    ]
    
    return (
      <Dropdown
        options={options}
        value={this.props.value}
        onChange={this.props.onChange}
        optionRenderer={this.renderOption}
        className="search-results"
        {...props}
      />
    )
  }

  renderOption(option) {
    return (
      <>
        <div className="title">{option.label}</div>
      </>
    )
  }
}
