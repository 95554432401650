import { combineReducers } from 'redux'

/**
 * The reason why I imported the reducers and constants separately instead of using the index.js
 * file in every folder, which already exports these, is because they contain the init function
 * which uses the root store to pass the initial values into.
 * However, I want to use these reducers in the portal-results' store too.
 * If I import the index files while constructing the reducer for the portal-results store,
 * the original store will be referenced, which would like to add this reducer,
 * and it just blows up the page (circular dependency).
 */
import searchesAdminReducer from './searches/searches-admin-reducer'
import {STATE_PROP_NAME as searchesAdminPropName} from './searches/searches-admin-constants'
import sourcesAdminReducer from './sources/sources-admin-reducer'
import {STATE_PROP_NAME as sourcesAdminPropName} from './sources/sources-admin-constants'
import usersAdminReducer from './users/users-admin-reducer'
import {STATE_PROP_NAME as usersAdminPropName} from './users/users-admin-constants'
import usageAdminReducer from './usage/usage-reducer'
import {STATE_PROP_NAME as usageAdminPropName} from './usage/usage-constants'
import settingsAdminReducer from './settings/firm-settings-reducer'
import {STATE_PROP_NAME as settingsAdminPropName} from './settings/firm-settings-constants'


export default combineReducers({
  [searchesAdminPropName]: searchesAdminReducer,
  [sourcesAdminPropName]: sourcesAdminReducer,
  [usersAdminPropName]: usersAdminReducer,
  [usageAdminPropName]: usageAdminReducer,
  [settingsAdminPropName]: settingsAdminReducer,
})
