import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import Tooltip from 'app/common/Tooltip'
import * as globalSelectors from 'app/global/global-selectors'
import {isVowel} from 'app/utils/strings'

import * as styles from './InsightSummary.less'


function InsightSummary({
  valence,
  factor,
  factorDisplayName,
  isLitigation,
  isOpinion,
  isRumor,
  appNameFull,
  insightsLabel,
}) {
  return (
    <div
      className={classNames(
        styles.insightSummary,
        styles[factor.toLowerCase()],
        styles[valence.toLowerCase()],
      )}
    >
      <div className={styles.icons}>
        <Tooltip
          label={`A${isVowel(insightsLabel[0]) ? 'n' : ''} ${insightsLabel} icon on an article indicates that this article has information concerning a company's corporate health with regards to that factor. You are seeing these icons as part of ${appNameFull} ${insightsLabel}.`}
          direction="top-left"
        >
          <div className={styles.icon} />
        </Tooltip>

        {isLitigation && (
          <span className={classNames(styles.aspectIcon, styles.litigation)} />
        )}
        {isOpinion && (
          <span className={classNames(styles.aspectIcon, styles.opinion)} />
        )}
        {isRumor && (
          <span className={classNames(styles.aspectIcon, styles.rumor)} />
        )}
      </div>

      <div className={styles.label}>
        {factorDisplayName}
        {isLitigation && (
          <>
            <span className={styles.separator} />
            Litigation
          </>
        )}
        {isOpinion && (
          <>
            <span className={styles.separator} />
            Opinion
          </>
        )}
        {isRumor && (
          <>
            <span className={styles.separator} />
            Rumor
          </>
        )}
      </div>
    </div>
  )
}
InsightSummary.propTypes = {
  valence: PropTypes.string.isRequired,
  factor: PropTypes.string.isRequired,
  factorDisplayName: PropTypes.string.isRequired,
  isLitigation: PropTypes.bool.isRequired,
  isOpinion: PropTypes.bool.isRequired,
  isRumor: PropTypes.bool.isRequired,
  appNameFull: PropTypes.string.isRequired,
  insightsLabel: PropTypes.string.isRequired,
}
export default connect(
  createSelector(
    [globalSelectors.getAppNameFull, globalSelectors.getInsightsLabel],
    (appNameFull, insightsLabel) => ({appNameFull, insightsLabel}),
  ),
)(InsightSummary)
