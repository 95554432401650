import React, { Component } from 'react'
import { connect } from 'react-redux'

import Modal from 'app/common/Modal'
import Orm from 'app/framework/Orm'
import { SavedSearch } from 'app/models'

import Content from './Content'
import { hideEditSourceFolderModal } from '../searches-admin-actions'
import { getSearchesAdmin } from '../searches-admin-selectors'


@connect(
  state => {
    const searchesAdmin = getSearchesAdmin(state)
    const props = {
      isOpen: !!searchesAdmin.editSourceFolderModal,
    }
    if (props.isOpen) {
      const orm = Orm.withEntities(state.entities)
      const searchId = searchesAdmin.editSourceFolderModal.searchId
      props.search = orm.getById(SavedSearch, searchId)
      props.isSearchFullyFetched = searchesAdmin.fullyFetchedSearchIds.includes(searchId)
    }
    return props
  },
  {hideEditSourceFolderModal},
)
export default class AddNewModal extends Component {
  render() {
    const content = this.props.isOpen && this.props.isSearchFullyFetched
      ? (
        <div>
          <h1 className="search-name">{this.props.search.name}</h1>
          <Content
            search={this.props.search}
            closeModal={hideEditSourceFolderModal}
          />
        </div>
      )
      : null
    return (
      <Modal
        isOpen={this.props.isOpen}
        onClose={this.props.hideEditSourceFolderModal}
      >
        {content}
      </Modal>
    )
  }
}
