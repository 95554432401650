import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Overlay from 'app/common/Modal'

import Container from './Container'

import './style.less'


export default class AddSourceModal extends Component {
  static propTypes = {
    appName: PropTypes.string.isRequired,
    appNameFull: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  render() {
    return (
      <Overlay
        id="add-source-overlay"
        isOpen={true}
        onClose={this.props.onClose}
      >
        <Container
          onModalClose={this.props.onClose} />
      </Overlay>
    )
  }
}
