import {handleActions} from 'redux-actions'
import * as actions from './error-handling-actions'

const getInitialState = () => ({
  errorObj: {
    error: null,
    errorForApp: null,
    mainImage: null,
  },
})

export default handleActions(
  {
    [actions.initError](state, action) {
      return {...state, errorObj: action.payload}
    },
    [actions.setError](state, action) {
      return {...state, errorObj: action.payload}
    },
  },
  getInitialState(),
)
