import * as R from 'ramda'

import {
  STATE_PROP_NAME as SEARCH_RESULTS_PROP_NAME
} from '../search-results-constants'
import {
  STATE_PROP_NAME as DNB_PROP_NAME
} from './dun-and-bradstreet-constants'

export const getDunAndBradstreet = R.path([
  SEARCH_RESULTS_PROP_NAME,
  DNB_PROP_NAME,
])
