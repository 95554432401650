import { all, take, takeLatest, put, select, join, fork } from 'redux-saga/effects'

import {handleSagaError} from 'app/utils/errors'

import * as api from './help-api'
import * as actions from './help-actions'


function* handleFetchTopics(action) {
  let response = null
  try {
    response = yield api.fetchTopics()
  } catch(error) {
    yield* handleSagaError(error)
    return
  }
  yield put(actions.setTopics(response.topics))
  yield put(actions.setIsLoading(false))
}

function* handleFetchSearchResults(action) {
  let response = null
  try {
    response = yield api.fetchSearchResults(action.payload)
  } catch(error) {
    yield* handleSagaError(error)
    return
  }
  yield put(actions.setSearchResults(response.searchResults))
  yield put(actions.setIsLoading(false))
}

export default function*() {
  yield all([
    takeLatest(
      actions.fetchTopics,
      handleFetchTopics
    ),
    takeLatest(
      actions.fetchSearchResults,
      handleFetchSearchResults
    ),
  ])
}
