import PropTypes from 'prop-types'
import React, {Component} from 'react'

import Modal from 'app/common/Modal'
import {insertBetweenItems} from 'app/utils'

import Content from './Content'
import './EditModal.less'


export default class EditModal extends Component {
  static propTypes = {
    feed: PropTypes.object,
    firm: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    defaultTab: PropTypes.string,
  }

  static defaultProps = {
    onClose() {},
  }

  render() {
    // hack to handle case where editing the feed causes it to no longer be in the list while the modal is still open
    if (!this.props.feed) {
      return null
    }

    const {feed, defaultTab} = this.props
    const attributes = this.getSourceAttributes(feed)
    const attributesJoined = insertBetweenItems(attributes, index => <span key={index} className="separator" />)

    return (
      <Modal
        id="edit-source-overlay"
        isOpen={true}
        onClose={this.onClose}
        outsideExit={false}
      >
        <h1 className="feed-name">Edit {`<${feed.name}>`} Sources</h1>

        <Content
          closeModal={this.onClose}
          defaultTab={defaultTab}
        />
      </Modal>
    )
  }

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  getSourceAttributes(feed) {
    const {orgType} = this.props.firm
    return [
      {
        label: `${orgType} Only Source`,
        showIf: feed.isProprietary,
      },
      {
        label: 'Base Source',
        showIf: !feed.isProprietary,
      },
      {
        label: `Promoted ${orgType} Wide`,
        showIf: feed.isPromoted,
      },
      {
        label: `Demoted ${orgType} Wide`,
        showIf: feed.isDemoted,
      },
      {
        label: 'Limited Seats',
        showIf: feed.isLimitedSeat,
      },
    ]
      .filter(attribute => attribute.showIf)
      .map(attribute => attribute.label)
  }
}
