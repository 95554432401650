import { handleActions } from 'redux-actions'
import * as profileRefreshActions from './profile-refresh-actions'

const getInitialState = () => ({
  isLoading: false,
  messageData: {},
  currentStep: 1,
  browseModalCategory: null,
  searches: [],
  browseModalSelectedSearches: [],
  selectedSearches: [],
  existingUserSearches: [],
  userSearchesToRemove: [],
  userSearchesToAdd: [],
  profileRefreshInterval: 3,
  showOnlyFirmLibrarySearches: false,
})

export default handleActions(
  {
    [profileRefreshActions.init]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [profileRefreshActions.setCurrentStep]: (state, action) => ({
      ...state,
      currentStep: action.payload,
    }),
    [profileRefreshActions.fetchSearches]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [profileRefreshActions.setExistingUserSearches]: (state, action) => ({
      ...state,
      existingUserSearches: action.payload,
    }),
    [profileRefreshActions.showBrowseModal]: (state, action) => ({
      ...state,
      isLoading: false,
      browseModalCategory: action.payload.category,
      searches: action.payload.searches,
      browseModalSelectedSearches: [],
    }),
    [profileRefreshActions.hideBrowseModal]: (state, action) => ({
      ...state,
      browseModalCategory: null,
      searches: [],
    }),
    [profileRefreshActions.saveUserSearches]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [profileRefreshActions.skip]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [profileRefreshActions.saveUserSearchesSuccess]: (state, action) => ({
      ...state,
      isLoading: false,
    }),
    [profileRefreshActions.setUserSearchesToRemove]: (state, action) => ({
      ...state,
      userSearchesToRemove: action.payload,
    }),
    [profileRefreshActions.setUserSearchesToAdd]: (state, action) => ({
      ...state,
      userSearchesToAdd: action.payload,
    }),
    [profileRefreshActions.setProfileRefreshInterval]: (state, action) => ({
      ...state,
      profileRefreshInterval: action.payload,
    }),
  },
  getInitialState(),
)
