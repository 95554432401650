import PropTypes from 'prop-types'
import React, { Component } from 'react'

import InputBlock from 'app/common/InputBlock'
import HelpIconTooltip from 'app/common/HelpIconTooltip'

import Assignees from 'app/reusable/Assignees'

import './style.less'
import classNames from "classnames"

export const ASSIGNEE_TYPES = {
  USER: 'user',
  GROUP: 'group',
  GROUP_MEMBER: 'group-member',
  DEPARTMENT: 'department',
  DEPARTMENT_MEMBER: 'department-member',
  TEAM: 'team',
  TEAM_MEMBER: 'team-member',
  FIRM_LOCATION_MEMBER: 'firm-location-member',
}


export default class BulkUserAssignment extends Component {
  static propTypes = {
    users: PropTypes.arrayOf(PropTypes.object).isRequired,

    departments: PropTypes.arrayOf(PropTypes.object),
    teams: PropTypes.arrayOf(PropTypes.object),
    firmLocations: PropTypes.arrayOf(PropTypes.object),
    showGroups: PropTypes.bool,
    showIndividualMembers: PropTypes.bool,
    showDepartments: PropTypes.bool,
    showDepartmentMembers: PropTypes.bool,
    showTeams: PropTypes.bool,
    showTeamMembers: PropTypes.bool,
    showFirmLocations: PropTypes.bool,
    selectedIdsBySection: PropTypes.object,
    isLargeFirm: PropTypes.bool,
    activeUserCount: PropTypes.number,
    activeGroupCount: PropTypes.number,
    areAllUsersFetched: PropTypes.bool,
    isSingleColumn: PropTypes.bool,
    limitOne: PropTypes.bool,
    onlyUserManagedGroups: PropTypes.bool,
    defaultToGroups: PropTypes.bool,

    // (section: String) -> ()
    onSectionChange: PropTypes.func,

    // (ids: [Number]) -> ()
    onSelectedIdsChange: PropTypes.func,

    onFetchUsers: PropTypes.func,
  }

  static defaultProps = {
    departments: [],
    teams: [],
    firmLocations: [],
    showGroups: true,
    showIndividualMembers: false,
    showDepartments: false,
    showDepartmentMembers: false,
    showTeams: false,
    showTeamMembers: false,
    showFirmLocations: false,
    selectedIdsBySection: {},
    isLargeFirm: false,
    defaultToGroups: false,

    // defaulting to null to differentiate between "not passed" and zero.
    activeUserCount: null,
    activeGroupCount: null,

    areAllUsersFetched: false,
    isSingleColumn: false,
    limitOne: false,
    onlyUserManagedGroups: false,

    onSectionChange: () => {},
    onSelectedIdsChange: () => {},
    onFetchUsers: () => {},
  }

  state = {
    selectedSection: this.props.onlyUserManagedGroups || this.props.defaultToGroups
      ? ASSIGNEE_TYPES.GROUP
      : ASSIGNEE_TYPES.USER,
    selectedIdsBySection: {
      [ASSIGNEE_TYPES.USER]: [],
      [ASSIGNEE_TYPES.GROUP]: [],
      [ASSIGNEE_TYPES.GROUP_MEMBER]: [],
      [ASSIGNEE_TYPES.DEPARTMENT]: [],
      [ASSIGNEE_TYPES.DEPARTMENT_MEMBER]: [],
      [ASSIGNEE_TYPES.TEAM]: [],
      [ASSIGNEE_TYPES.TEAM_MEMBER]: [],
      [ASSIGNEE_TYPES.FIRM_LOCATION_MEMBER]: [],
      ...this.props.selectedIdsBySection,
    },
    areTypesDisabled: false,
  }

  render() {
    const {selectedSection} = this.state

    const activeCount = selectedSection === ASSIGNEE_TYPES.USER
      ? this.props.activeUserCount
      : this.props.activeGroupCount

    const filterMessage =
      this.props.isLargeFirm &&
      !this.props.areAllUsersFetched &&
      [ASSIGNEE_TYPES.USER, ASSIGNEE_TYPES.GROUP].includes(selectedSection) &&
      <div className="filter-message">
        <span>Please filter for results or </span>
        <a onClick={() => this.props.onFetchUsers()}>
          click here to load all {selectedSection}s {activeCount === null ? null : `(${activeCount})`}
        </a>.
      </div>

    const assignees = this.assigneesByType(selectedSection)

    const assigneesContainer =
      <div className="assignees-container">
        {filterMessage}

        <Assignees
          assignees={assignees}
          type={selectedSection}
          selectedIds={this.state.selectedIdsBySection[selectedSection]}
          onIdSelectChange={
            this.onIdSelectChange.bind(this, selectedSection)
          }
          onSelectAllStateChange={this.onSelectAll.bind(this, selectedSection)}
          onNameFilterChange={(nameFilter) => this.onNameFilterChange(nameFilter)}
          isLargeFirm={this.props.isLargeFirm}
          isSingleColumn={this.props.isSingleColumn}
          limitOne={this.props.limitOne}
        />
      </div>

    return (
      <div className="bulk-user-assignment">
        <div className="user-type-select">
          {
            !this.props.onlyUserManagedGroups &&
            <InputBlock
              label={
                <span className={classNames({'disabled': this.state.areTypesDisabled})}>
                  Individual Users
                </span>
              }
              isInline
            >
              <input
                type="radio"
                name="assign-to"
                checked={selectedSection === ASSIGNEE_TYPES.USER}
                onChange={() => this.onSelectedSectionChange(ASSIGNEE_TYPES.USER)}
                disabled={this.state.areTypesDisabled}
              />
            </InputBlock>
          }
          {
            this.props.showGroups && !this.props.onlyUserManagedGroups &&
            <InputBlock
              label={
                <span className={classNames({'disabled': this.state.areTypesDisabled})}>
                  Group Profiles
                  <HelpIconTooltip label="The selected items will be added into these Group Profiles."/>
                </span>
              }
              isInline
            >
              <input
                type="radio"
                name="assign-to"
                checked={selectedSection === ASSIGNEE_TYPES.GROUP}
                onChange={() => this.onSelectedSectionChange(ASSIGNEE_TYPES.GROUP)}
                disabled={this.state.areTypesDisabled}
              />
            </InputBlock>
          }

          {
            this.props.showIndividualMembers &&
            <InputBlock
              label={
                <span className={classNames({'disabled': this.state.areTypesDisabled})}>
                  Group Members
                  <HelpIconTooltip label="The selected items will be added into the profiles of these Group Members."/>
                </span>
              }
              isInline
            >
              <input
                type="radio"
                name="assign-to"
                checked={selectedSection === ASSIGNEE_TYPES.GROUP_MEMBER}
                onChange={() => this.onSelectedSectionChange(ASSIGNEE_TYPES.GROUP_MEMBER)}
                disabled={this.state.areTypesDisabled}
              />
            </InputBlock>
          }

          {
            this.props.showDepartments &&
            <InputBlock
              label={
                <span className={classNames({'disabled': this.state.areTypesDisabled})}>
                  Departments
                  <HelpIconTooltip label="The selected items will be added to the Department assignment, meaning all
                    current and future Users within the Department will receive them."/>
                </span>
              }
              isInline
            >
              <input
                type="radio"
                name="assign-to"
                checked={selectedSection === ASSIGNEE_TYPES.DEPARTMENT}
                onChange={() => this.onSelectedSectionChange(ASSIGNEE_TYPES.DEPARTMENT)}
                disabled={this.state.areTypesDisabled}
              />
            </InputBlock>
          }

          {
            this.props.showDepartmentMembers &&
            <InputBlock
              label={
                <span className={classNames({'disabled': this.state.areTypesDisabled})}>
                  Department Members
                  <HelpIconTooltip label="The selected items will be added to all current, but not future users that
                    are assigned to this Department."/>
                </span>
              }
              isInline
            >
              <input
                type="radio"
                name="assign-to"
                checked={selectedSection === ASSIGNEE_TYPES.DEPARTMENT_MEMBER}
                onChange={() => this.onSelectedSectionChange(ASSIGNEE_TYPES.DEPARTMENT_MEMBER)}
                disabled={this.state.areTypesDisabled}
              />
            </InputBlock>
          }

          {
            this.props.showTeams &&
            <InputBlock
              label={
                <span className={classNames({'disabled': this.state.areTypesDisabled})}>
                  Teams
                  <HelpIconTooltip label="The selected items will be added to the Team assignment, meaning all
                    current and future Users within the Team will receive them."/>
                </span>
              }
              isInline
            >
              <input
                type="radio"
                name="assign-to"
                checked={selectedSection === ASSIGNEE_TYPES.TEAM}
                onChange={() => this.onSelectedSectionChange(ASSIGNEE_TYPES.TEAM)}
                disabled={this.state.areTypesDisabled}
              />
            </InputBlock>
          }

          {
            this.props.showTeamMembers &&
            <InputBlock
              label={
                <span className={classNames({'disabled': this.state.areTypesDisabled})}>
                  Team Members
                  <HelpIconTooltip label="The selected items will be added to all current, but not future users that
                    are assigned to this Team."/>
                </span>
              }
              isInline
            >
              <input
                type="radio"
                name="assign-to"
                checked={selectedSection === ASSIGNEE_TYPES.TEAM_MEMBER}
                onChange={() => this.onSelectedSectionChange(ASSIGNEE_TYPES.TEAM_MEMBER)}
                disabled={this.state.areTypesDisabled}
              />
            </InputBlock>
          }

          {
            this.props.showFirmLocations &&
            <InputBlock
              label={
                <span className={classNames({'disabled': this.state.areTypesDisabled})}>
                  Office Location Members
                  <HelpIconTooltip label="The selected items will be added to all current, but not future users that
                    are assigned to this Office Location."/>
                </span>
              }
              isInline
            >
              <input
                type="radio"
                name="assign-to"
                checked={selectedSection === ASSIGNEE_TYPES.FIRM_LOCATION_MEMBER}
                onChange={() => this.onSelectedSectionChange(ASSIGNEE_TYPES.FIRM_LOCATION_MEMBER)}
                disabled={this.state.areTypesDisabled}
              />
            </InputBlock>
          }
        </div>

        {assigneesContainer}
      </div>
    )
  }

  onIdSelectChange(assigneeType, assigneeID, selected) {
    this.setState({
      selectedIdsBySection: {
        ...this.state.selectedIdsBySection,
        [assigneeType]: selected
          ? [
            ...this.state.selectedIdsBySection[assigneeType],
            assigneeID,
          ]
          : this.state.selectedIdsBySection[assigneeType].filter(id => id !== assigneeID),
      },
    }, () => this.setSelectedIds())

    this.setState({areTypesDisabled: this.props.limitOne && selected && assigneeID})
  }

  onSelectAll(assigneeType, filteredIds, selected) {
    this.setState({
      selectedIdsBySection: {
        ...this.state.selectedIdsBySection,
        [assigneeType]: selected
          ? [...new Set(this.state.selectedIdsBySection[assigneeType].concat(filteredIds))]
          : this.state.selectedIdsBySection[assigneeType].filter(id => !filteredIds.includes(id))
      },
    }, () => this.setSelectedIds())
  }

  onSelectedSectionChange(section) {
    this.setState({
      selectedSection: section
    }, this.props.onSectionChange(section))
  }

  onNameFilterChange(nameFilter) {
    if (
      !this.props.isLargeFirm ||
      this.props.areAllUsersFetched ||
      ![ASSIGNEE_TYPES.USER, ASSIGNEE_TYPES.GROUP, ASSIGNEE_TYPES.GROUP_MEMBER].includes(this.state.selectedSection)
    ) {
      return
    }
    this.props.onFetchUsers({nameFilter: nameFilter, onlyUserManagedGroups: this.props.onlyUserManagedGroups})
  }

  assigneesByType(assigneeType) {
    if ([ASSIGNEE_TYPES.GROUP, ASSIGNEE_TYPES.GROUP_MEMBER].includes(assigneeType)) {
      return this.props.users.filter(user => user.isGroup).map(u => ({...u, name: u.displayName}))
    } else if ([ASSIGNEE_TYPES.DEPARTMENT, ASSIGNEE_TYPES.DEPARTMENT_MEMBER].includes(assigneeType)) {
      return this.props.departments
    } else if ([ASSIGNEE_TYPES.TEAM, ASSIGNEE_TYPES.TEAM_MEMBER].includes(assigneeType)) {
      return this.props.teams
    } else if (assigneeType === ASSIGNEE_TYPES.FIRM_LOCATION_MEMBER) {
      return this.props.firmLocations
    } else {
      return this.props.users.filter(user => !user.isGroup).map(u => ({...u, name: u.displayNameLastFirst}))
    }
  }

  setSelectedIds() {
    const {selectedSection, selectedIdsBySection} = this.state
    this.props.onSelectedIdsChange(selectedIdsBySection[selectedSection])
  }
}
