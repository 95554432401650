import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {titleCase} from 'title-case'
import { joinInSentence } from 'app/utils'

import SavedSearchInput from 'app/common/SavedSearchInput'
import Button from "app/common/Button"

export default class Searches extends Component {
  static propTypes = {
    sections: PropTypes.array.isRequired,
    currentFirmLibraryName: PropTypes.string.isRequired,
    fetchSearches: PropTypes.func.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
  }

  render() {
    const {currentFirmLibraryName} = this.props
    const numberLabels = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth']

    const sectionContent =
      this.props.sections.map((section, idx) => {
        return (
          <div key={idx}>
            <div className="search-category-header">
              <h3>{titleCase(section.plural)}</h3>
              <Button
                className="select-list-button"
                isSmall={true}
                isSkinny={true}
                isPrimary={true}
                onClick={() => this.props.fetchSearches(section.category)}
                label={`Select from ${currentFirmLibraryName}`}
              />
            </div>
            {
              [...section.searches].map((s, idx) => {
                const numberLabel = numberLabels[idx] || ''
                const selectedValues =
                  Object.keys(s).length > 0 ?
                    [{
                      value: s.value,
                      label: s.name,
                      isFreeText: s.isFreeText || false,
                    }]
                    : []
                return (
                  <div key={idx} className="search-category-item">
                    <div className="search-category-item-header">{numberLabel.toUpperCase()} {section.label.toUpperCase()}</div>
                    <SavedSearchInput
                      options={{
                        category: section.categories || section.category,
                        shared_only: true,
                      }}
                      selectedValues={selectedValues}
                      maxValues={1}
                      onChange={values => this.props.onSelectionChange(idx, section.category, values)}
                    />
                  </div>
                )
              })
            }
          </div>
        )
      })

    const searchesDescription = this.getSearchesDescription()

    return (
      <div className="builder-content-body-container">
        <div className="builder-content-body">
          <p>Please enter {searchesDescription} that you’d like to track. You’ll be able to add
            more {searchesDescription} and refine your results once in the platform.</p>

          <div>
            {sectionContent}
          </div>
        </div>
      </div>
    )
  }

  getSearchesDescription() {
    const a = this.props.sections.map(s => {
      return (
        titleCase(s.plural)
      )
    })
    return joinInSentence(a)
  }
}
