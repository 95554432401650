import React, { Component } from 'react'
import { connect } from 'react-redux'
import './AdditionalRecipients.less'
import * as actions from './email-notifications-actions'
import * as constants from './email-notifications-constants'
import PropTypes from 'prop-types'
import TokenInput from "app/common/TokenInput"
import * as notifications from 'app/global/notifications'
import { getChunkMessage, getValidandInvalidEmailList } from 'app/utils'

@connect(
  state => ({
    baseSettings: state.emailNotifications.baseSettings,
    insightsSettings: state.emailNotifications.insightsSettings,
    esgSettings: state.emailNotifications.esgSettings,
    editUserId: state.emailNotifications.emailDeliveryData.editUserId,
  }),
  {
    updateCCEmails: actions.api.updateCCEmails.request,
    showNotification: notifications.actions.showNotification,
  },
)
export default class AdditionalRecipients extends Component {
  static propTypes = {
    noticeFrequency: PropTypes.string.isRequired,
    notificationType: PropTypes.string.isRequired,
    ccEmails: PropTypes.array,
    ccEmailInputWidth: PropTypes.string
  }
  static defaultProps = {
    ccEmails: [],
    ccEmailInputWidth: '300px',
  }
  render() {
    const activeSettings = this.props.notificationType === constants.BASE_NOTIFICATION_TYPE
      ? this.props.baseSettings
      : this.props.notificationType === constants.INSIGHTS_NOTIFICATION_TYPE
        ? this.props.insightsSettings
        : this.props.notificationType === constants.ESG_NOTIFICATION_TYPE
          ? this.props.esgSettings
          : null
    let ccEmailsTokens = null
    if (activeSettings) {
      const frequencySettings = activeSettings.settings.find(
        setting => setting.noticeFrequency === this.props.noticeFrequency)
      const ccEmailsByFreq = this.props.ccEmails
        && this.props.notificationType === constants.INSIGHTS_NOTIFICATION_TYPE
        ? this.props.ccEmails.filter(ccFreq => ccFreq.noticeFrequency === this.props.noticeFrequency)
        : this.props.ccEmails && this.props.notificationType === constants.BASE_NOTIFICATION_TYPE
          ? this.props.ccEmails
          : this.props.ccEmails && this.props.notificationType === constants.ESG_NOTIFICATION_TYPE
            ? this.props.ccEmails.filter(ccFreq => ccFreq.noticeFrequency === this.props.noticeFrequency)
            : null

      const addTokenParams = {
        editUserId: this.props.editUserId,
        noticeFrequency: this.props.noticeFrequency,
        type: this.props.notificationType,
        action: 'add',
        recipientList: ccEmailsByFreq?.map(i => i.label)
      }
      ccEmailsTokens = frequencySettings && frequencySettings.showCcEmails && ccEmailsByFreq
        ? <React.Fragment>
            <TokenInput
              updateTokensFunc={(token) => this.validateUpdateCCEmails(token)}
              tokenItems={ccEmailsByFreq}
              delimiters={constants.TOKEN_INPUT_DELIMITERS}
              addTokenParams={addTokenParams}
              inputLabel={'Additional Recipients'}
              inputWidth={this.props.ccEmailInputWidth}
              ignoreSplit={true}
            />
          </React.Fragment>
        : null
    }
    return (
      <div id="additional-recipients" className={'cc-email-input'}>
        {ccEmailsTokens}
      </div>
    )
  }
  validateUpdateCCEmails(token){
    if(token.action == "add"){
      let recipientsToAdd = []
      let recipientList = token.recipientList
      recipientsToAdd.push(...token.label.split(',').map(email => email.trim()))
      let [validEmails,invalidEmails,repeatingIds] = getValidandInvalidEmailList(recipientsToAdd)
      recipientsToAdd = validEmails
      const existingIds = validEmails.filter(label =>
        (recipientList.some(item => item.toLocaleLowerCase() === label.toLocaleLowerCase()))
      );
      if (repeatingIds && repeatingIds.length > 0 || existingIds && existingIds.length > 0) {
        let message = getChunkMessage([...repeatingIds, ...existingIds])
        message = "[" + message + "] " + "has been already added to your additional recipients for alerts"
        this.props.showNotification({
          type: 'error',
          message: message
        })
      }
      if (invalidEmails && invalidEmails.length > 0) {
        let message = getChunkMessage(invalidEmails)
        message = "[" + message + "] " + "is not valid email address";
        this.props.showNotification({
          type: 'error',
          message: message
        })
      }
      recipientsToAdd.forEach(label => {
        if(!existingIds.includes(label)){
          this.props.updateCCEmails({...token, "label": label})
        }
      })
    }
    else{
      this.props.updateCCEmails(token)
    }       
  }
}
