import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import {titleCase} from 'title-case'
import Orm from 'app/framework/Orm'
import { User } from 'app/models'
import { getProfileRefresh} from './profile-refresh-selectors'
import * as profileRefreshActions from './profile-refresh-actions'
import Modal from 'app/common/Modal'
import LoadingOverlay from 'app/common/LoadingOverlay'
import Button from 'app/common/Button'
import Message from 'app/common/Message'
import InlineSvg from 'app/common/InlineSvg'
import RemoveSearches from './RemoveSearches'
import AddSearches from './AddSearches'
import ReviewSearchChanges from './ReviewSearchChanges'
import deepCopy from "deepcopy"
import * as constants from 'app/profile-refresh/profile-refresh-constants'


@connect(
  // Map state to props
  state => {
    const orm = Orm.withEntities(state.entities)
    const profileRefresh = getProfileRefresh(state)
    return {
      currentFirmName: state.currentFirmName,
      currentFirmLibraryName: state.currentFirmLibraryName,
      currentUser: orm.getById(User, state.currentUser),
      supportEmail: state.supportEmail,
      isDiligent: state.isDiligent,
      appNameFull: state.appNameFull,

      ...profileRefresh,
    }
  },

  // Map dispatch to props
  {
    ...profileRefreshActions,
  },
)
export default class ProfileRefresh extends Component {
  render() {
    const { currentFirmName, currentFirmLibraryName, showOnlyFirmLibrarySearches,
      currentUser, currentStep, isDiligent, supportEmail } = this.props

    const steps = [
      'Review Your Searches',
      'Add New Searches',
      'Confirm Changes',
    ]

    const stepsContent =
      steps.map((step, idx) => {
        const stepNumber = idx + 1
        return (
          <li
            key={idx}
            className={classNames({
              active: stepNumber === currentStep,
              accessible: stepNumber < currentStep && currentStep < 3
            })}
            onClick={() => this.handleStepClick(stepNumber)}
          >
            <div className="step">
              <span className="stepnum">{stepNumber < currentStep
                ? <i className="fa fa-check"/>
                : stepNumber}</span>
              <span>{step}</span>
            </div>
          </li>
        )
      })

    const refreshContent = this.getContent()
    const currentStepName = steps[currentStep - 1]
    const companyName = isDiligent ? 'Diligent Corporation' : 'Manzama Inc.'
    const currentYear = new Date().getFullYear()
    const haveFirmLibrarySearches = this.props.searches.some(s => s.isFirmLibrary)
    const searchesToList = haveFirmLibrarySearches
      ? this.props.searches.filter(s => s.isFirmLibrary)
      : showOnlyFirmLibrarySearches
        ? []
        : this.props.searches

    const browseModalMessage = haveFirmLibrarySearches
      ? null
      : showOnlyFirmLibrarySearches
        ? <p>No {currentFirmLibraryName} searches found. Please contact your admin.</p>
        : this.props.searches.length === 0
          ? <p>No {currentFirmLibraryName} searches exist. Please use the Input Fields to select a search.</p>
          : <p>No {currentFirmLibraryName} searches exist, but please choose from existing Global Searches.</p>

    const browseModalCategory = this.props.browseModalCategory
      ? this.searchCategory(this.props.browseModalCategory).plural
      : null

    const browseModal =
      this.props.browseModalCategory &&
        <Modal
          isOpen={true}
          onClose={() => this.props.hideBrowseModal()}
        >
          <h1>{titleCase(browseModalCategory)}</h1>
          {browseModalMessage}
          <div className="search-list">
            {
              searchesToList.map(search => {
                return (
                  <div
                    key={search.id}
                  >
                    <input
                      type="checkbox"
                      checked={this.searchIsSelected(search.id)}
                      onChange={evt => this.handleSearchCheckbox(evt, search.id, search.name)}
                    />
                    <span>{search.name}</span>
                  </div>
                )
              })
            }
          </div>
          <div className="modal-footer">
            <Button
              label="Done"
              onClick={() => this.props.hideBrowseModal()}
            />
          </div>
        </Modal>

    const loader = this.props.isLoading  && <LoadingOverlay/>
    const message =
      this.props.messageData.type &&
        <Message
          type={this.props.messageData.type}
          text={this.props.messageData.text}
        />

    return (
      <div>
        {loader}
        {message}
        {browseModal}
        <div className="header">
          <InlineSvg
            src={`/media/img/${process.env.MANZAMA_BRAND}-logotype.svg`}
            className="logo"
          />
          <a onClick={() => this.handleSkipClick()}>Skip Profile Refresh</a>
        </div>

        <div className="wrapper">
          <div className="profile-refresh-menu">
            <div className="menu-header">
              <p className="group-name">{currentFirmName}</p>
              <p className="member-name">{currentUser.displayName}</p>
            </div>

            <ul className="menu-content">
              {stepsContent}
            </ul>
          </div>

          <div className="profile-refresh-content">
            <div className="profile-refresh-header">
              <h4>Step {currentStep}</h4> <h5>{currentStepName}</h5>
            </div>

            {refreshContent}

            <div className="profile-refresh-footer">
              <div>&copy; {currentYear} {companyName} |
                <a href={`mailto:${supportEmail}`} target="_blank">Help</a>
              </div>
              <div
                className="next-button"
                onClick={() => this.handleNextClick()}
              >
                <div>
                  <span>
                    {currentStep === 3
                      ? 'SAVE CHANGES'
                      : 'NEXT'}
                  </span>
                </div>

                <div className="next-button-button">
                  <div>
                    <i className="fa fa-angle-right"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getContent() {
    const { currentStep, currentFirmLibraryName, showOnlyFirmLibrarySearches, existingUserSearches,
      userSearchesToRemove, userSearchesToAdd, profileRefreshInterval, currentUser,
      setCurrentStep, setUserSearchesToRemove, setUserSearchesToAdd, setProfileRefreshInterval,
      categoryOrder } = this.props

    const orderedCategories = Object.values(categoryOrder).map(value => this.searchCategory(value))

    const addSearchCategories = this.props.showPracticeAreas
      ? this.filteredSearchCategories(['source', 'agency', 'prospect'],
        orderedCategories)
      : this.filteredSearchCategories(['practice', 'source', 'agency', 'prospect'],
        orderedCategories)

    switch (currentStep) {
      case 1:
        return (
          <RemoveSearches
            existingUserSearches={existingUserSearches}
            categories={orderedCategories}
            userSearchesToRemove={userSearchesToRemove}
            setUserSearchesToRemove={setUserSearchesToRemove}
          />
        )
      case 2:
        return (
          <AddSearches
            categories={addSearchCategories}
            currentFirmLibraryName={currentFirmLibraryName}
            showOnlyFirmLibrarySearches={showOnlyFirmLibrarySearches}
            fetchSearches={category => this.fetchSearches(category)}
            userSearchesToAdd={userSearchesToAdd}
            setUserSearchesToAdd={setUserSearchesToAdd}
          />
        )
      case 3:
        return (
          <ReviewSearchChanges
            categories={orderedCategories}
            user={currentUser}
            userSearchesToRemove={userSearchesToRemove}
            userSearchesToAdd={userSearchesToAdd}
            currentStep={currentStep}
            profileRefreshInterval={profileRefreshInterval}
            setCurrentStep={setCurrentStep}
            setUserSearchesToRemove={setUserSearchesToRemove}
            setUserSearchesToAdd={setUserSearchesToAdd}
            setProfileRefreshInterval={setProfileRefreshInterval}
          />
        )
      default:
        throw new Error(`currentStep has an invalid value: ${currentStep}.`)
    }
  }

  handleSearchCheckbox(evt, id, name) {
    const search = {
      label: name,
      value: id,
      isFirmLibrary: true,
    }

    const category = this.searchCategory(this.props.browseModalCategory).category
    search.category = category

    const excludeCategory = this.props.userSearchesToAdd.find(cat => cat[category])
    let searchesToAdd = this.props.userSearchesToAdd.filter(cat => cat !== excludeCategory)

    let categorySearches = deepCopy(this.props.userSearchesToAdd.find(cat => cat[category]))
    if (categorySearches === undefined) {
      categorySearches = {[category]: []}
    }

    if (evt.target.checked) {
      categorySearches[category].push(search)
      searchesToAdd.push(categorySearches)
      this.props.setUserSearchesToAdd(searchesToAdd)
    } else {
      categorySearches[category] = categorySearches[category].filter(search => search.value !== id)
      searchesToAdd.push(categorySearches)
      this.props.setUserSearchesToAdd(searchesToAdd)
    }
  }

  searchIsSelected(id) {
    const category = this.searchCategory(this.props.browseModalCategory).category
    const categorySearches =
      this.props.userSearchesToAdd.filter(cat => Object.keys(cat)[0] === category)[0]
        || {[category]: []}
    return categorySearches[category].some(s => s.value === id)
  }

  handleSkipClick() {
    this.props.skip(this.props.currentUser.id)
  }

  handleNextClick() {
    const { currentStep } = this.props
    if (currentStep === 3) {
      this.props.saveUserSearches(this.props.currentUser.id)
      return
    }
    this.props.setCurrentStep(currentStep + 1)
  }

  handleStepClick(step) {
    if (step < this.props.currentStep || this.props.currentStep === 3) {
      this.props.setCurrentStep(step)
    }
  }

  fetchSearches(category) {
    const authors = this.searchCategory(category).authors
    this.props.fetchSearches({category, authors})
  }

  searchCategory(category) {
    return constants.CATEGORIES.filter(cat => cat.category === category)[0]
  }

  filteredSearchCategories(categories, categoryArray) {
    return categoryArray.filter(cat => !categories.includes(cat.category))
  }

}
