import {path} from 'ramda'

import {STATE_PROP_NAME as PROFILE_PROP_NAME} from '../profile-constants'
import {STATE_PROP_NAME} from './profile-preferences-constants'

export const getState = path([PROFILE_PROP_NAME, STATE_PROP_NAME])
export const getIsLoading = state => getState(state).isLoading

export const getLanguages = state => getState(state).languages
export const getLanguageLoadingIds = state => getState(state).languageLoadingIds
export const getLanguageById = (state, id) =>
  getLanguages(state).find(lang => lang.id === id)

export const getBoosters = state => getState(state).boosters
export const getBoosterLoadingIds = state => getState(state).boosterLoadingIds
export const getBoosterForShowMeModal = state => getBoosters(state)
  .find(booster => booster.id === getState(state).boosterShowMeModalId)
export const getBoosterById = (state, id) =>
  getBoosters(state).find(booster => booster.id === id)

export const getDashboardOrder = state => getState(state).dashboardOrder
export const getDashboardOrderLoading = state => getState(state).dashboardOrderLoading

export const getSubscriptionContentFirmDefault = state => getState(state).subscriptionContentFirmDefault
export const getIncludeSubscriptionContent = state => getState(state).includeSubscriptionContent
export const getSubscriptionContentLoading = state => getState(state).subscriptionContentLoading

export const getTimeFrame = state => getState(state).timeFrame
export const getTimeFrameLoading = state => getState(state).timeFrameLoading

export const getTimeFrameMobile = state => getState(state).timeFrameMobile
export const getTimeFrameMobileLoading = state => getState(state).timeFrameMobileLoading

export const getSearchResultsOrderBy = state => getState(state).searchResultsOrderBy
export const getSearchResultsOrderByLoading = state => getState(state).searchResultsOrderByLoading

export const getRelevanceFirmDefault = state => getState(state).relevanceFirmDefault
export const getRelevance = state => getState(state).relevance
export const getRelevanceLoading = state => getState(state).relevanceLoading
export const getRelevanceApplyingToAll = state => getState(state).relevanceApplyingToAll

export const getGroupingFirmDefault = state => getState(state).groupingFirmDefault
export const getGrouping = state => getState(state).grouping
export const getGroupingLoading = state => getState(state).groupingLoading
export const getGroupingApplyingToAll = state => getState(state).groupingApplyingToAll

export const shouldShowQuickFilters = state => !!getState(state).quickFilters.length
export const getQuickFilters = state => getState(state).quickFilters
export const getSelectedQuickFilterIds = state => getState(state).selectedQuickFilterIds
export const getQuickFilterLoadingIds = state => getState(state).quickFilterLoadingIds
export const getQuickFilterById = (state, id) =>
  getQuickFilters(state).find(filter => filter.id === id)

export const getresultsPerPage = state => getState(state).resultsPerPage
export const getresultsPerPageLoading = state => getState(state).resultsPerPageLoading
