import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Button from "app/common/Button"
import * as actions from "../comparison-actions"
import * as styles from './ComparisonNarrow.less'
import SavedSearchInput from "app/common/SavedSearchInput"
import classNames from "classnames"
import * as selectors from "../comparison-selectors"


function ComparisonNarrow() {
  const dispatch = useDispatch()
  const fetchingFilterKey = useSelector(selectors.getFetchingFilterKey)
  const narrowItems = useSelector(selectors.getActiveFilterItems)

  const applyNarrow = () => {
    dispatch(actions.narrowFiltersChange(narrowItems))
  }

  return (
    <div className={styles.narrowByContainer}>
      <div className={styles.narrowByLabel}>
        Narrow by
      </div>
      <div className={styles.narrowByInputContainer}>
        <div>
          <span
            className={classNames(styles.filtersAutocompleteHolder,
              {[styles.hasItems]: narrowItems.length > 0})}
          >
            <SavedSearchInput
              selectedValues={narrowItems}
              onChange={selectedItem => dispatch(actions.setActiveFilterItems(selectedItem))}
            />
          </span>
        </div>
        <div>
          <Button
            className={styles.applyNarrowButton}
            label={'Apply'}
            isPrimary={false}
            disabled={fetchingFilterKey}
            onClick={() => applyNarrow()}
          />
        </div>
      </div>
    </div>
  )
}
export default ComparisonNarrow
