import React from 'react'
import {render} from 'react-dom'
import store from 'app/store'
import {changeCaseObject} from 'app/utils'

import ProfileGroupMembers from './ProfileGroupMembers'
import * as constants from './profile-group-members-constants'
import {init as profileGroupMembersInit} from './profile-group-members-actions'
import reducer from './profile-group-members-reducer'
import saga from './profile-group-members-saga'

import AppWrapper from 'app/global/app-wrapper'


export function init(data) {
  store.dispatch(profileGroupMembersInit(changeCaseObject.camelCase(data)))
  const component =
    <AppWrapper>
      <ProfileGroupMembers/>
    </AppWrapper>
  const container = document.getElementById('profile-group-members-wrapper')
  render(component, container)
}

export {constants, reducer, saga}
