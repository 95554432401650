import {parseISO} from 'date-fns'
import is from 'is'

import Model from 'app/framework/Model'
import {field, foreignKey} from 'app/framework/Model/fields'
import {adjustDateForUtc} from 'app/utils'

import Feed from './Feed'
import SavedSearch from './SavedSearch'


export default class Document extends Model {
  static entityKey = 'documents'

  static fields = {
    search: foreignKey(SavedSearch, {required: false}),
    headline: field(),
    link: field(),
    snippet: field({required: false}),
    feed: foreignKey(Feed, {required: false}),
    publicationType: field({required: false}),
    publishedAt: field({required: false}),
    groupCount: field({required: false}),
    insights: field({required: false}),
    staffInfo: field({required: false}),
    isFlagged: field({required: false}),
    headlineHighlightLocations: field({required: false}),
    snippetHighlightLocations: field({required: false}),
    isNlaArticle: field({required: false}),
  }

  constructor(data) {
    const {publishedAt} = data
    if (publishedAt && !is.date(publishedAt)) {
      data = {...data, publishedAt: adjustDateForUtc(parseISO(publishedAt))}
    }
    super(data)
  }
}
