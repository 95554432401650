import {handleActions} from 'redux-actions'

import * as actions from './sources-admin-actions'
import {ADD_PROPRIETARY_FEED_STEPS, LABEL_MODAL_TABS, DEFAULT_COLUMNS_VIEW} from './sources-admin-constants'
import {setSourceModalData} from "./sources-admin-actions";
import Cookie from 'js-cookie'

const getDefaultSourceColumns = () => {
  try {
    const encodedValue = Cookie.get('managesource_columns')
    const columns = encodedValue ? window.atob(encodedValue): undefined
    return columns ? JSON.parse(columns) : [...DEFAULT_COLUMNS_VIEW]
  } catch (error) {
    return [...DEFAULT_COLUMNS_VIEW]
  }
}

const setSourceColumns = (value) => {
  const columns = window.btoa(JSON.stringify(value))
  Cookie.set('managesource_columns', columns)
}

const getInitialState = () => ({
  isLoading: false,
  feedIds: [],
  languages: [],

  // only fetch users, departments, and teams once.
  isAssigneesFetched: false,
  userIds: [],
  departments: [],
  teams: [],
  firmLocations: [],

  itemsPerPage: 50,
  totalCount: 0,
  currentPage: 1,
  filters: {},
  sortField: 'name',
  sortDirection: 'asc',
  selectedFeedIds: [],
  isAssignSourcesContentShown: false,
  assignModalData: {
    isVisible: false,
    isFeaturing: false,
    feedIds: [],

    // key = user type (user, group, team, etc..), value = array of user ids.
    assigneeIdsBySection: {},
    noticeConfig: {
      frequency: '',
      maxItems: {},
    },
  },
  editModalData: {
    isVisible: false,
    feedId: null,
    name: undefined,
    url: undefined,
    isPromoted: undefined,
    isDemoted: undefined,
    isExcluded: undefined,
    isLimitedSeat: undefined,
    tab: 'details',
    sourceNotes: undefined,
  },

  // firm source labels
  labels: null,  // start with null to represent not yet fetched
  newLabelData: {
    name: '',
    parentId: undefined,
  },
  isManageLabelsModalShown: false,
  manageLabelsModalData: {
    id: null,
    operation: '',  // edit, delete
    name: undefined,
    parentId: undefined,
  },
  isLabelModalShown: false,
  labelModalData: {
    currentTab: LABEL_MODAL_TABS.ADD,
    selectedIds: [],
  },
  isReportModalVisible: false,
  feedIdsToReport: [],

  // adding new sources
  shouldShowBrowseModal: false,
  shouldShowSelfAddModal: false,
  feedIdsForSourcesBeingCreated: [],
  feedIdsForCreatedSources: [],
  createProprietaryFeedData: {
    currentStep: ADD_PROPRIETARY_FEED_STEPS.URL_FORM,
    isValidating: false,
    errorMessage: null,
    isExisting: false,  // whether returned feeds are already in the system
    feeds: [],
    selectedFeed: null,
    newFeedName: null,
  },
  createTwitterFeedData: {
    isProcessing: false,
    errorMessage: null,
    handle: '',
    languageId: 1,
    isComplete: false,
  },
  noticeFrequencies: {
      frequency: '',
      maxItems: {},
  },
  //adding for manage labels
  manageLabelsFilter: {
    currentPage: 1,
    itemsPerPage: 10,
    searchLabel: '',
    sortField: 'name',
    sortDirection: 'asc',
  },
  allParentLabels: null,
  manageLabelsTotalCount: 0,
  selectedColumns: getDefaultSourceColumns(),
  selectedAllFeedsHealth: []
})


export default handleActions(
  {
    [actions.init]: (state, action) => ({
      ...state,
      languages: action.payload.languages,
      noticeFrequencies: action.payload.noticeFrequencies,
    }),
    [actions.setFilters]: (state, action) => ({
      ...state,
      isLoading: true,
      filters: action.payload,
      currentPage: 1,
      selectedFeedIds: [],
    }),
    [actions.setSort]: (state, action) => ({
      ...state,
      isLoading: true,
      sortField: action.payload.field,
      sortDirection: action.payload.direction,
    }),
    [actions.fetchFeeds]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.fetchFeedsSuccess]: (state, action) => ({
      ...state,
      isLoading: false,
      feedIds: action.payload.feedIds,
      totalCount: action.payload.totalCount,
    }),
    [actions.setPageNumber]: (state, action) => ({
      ...state,
      isLoading: true,
      currentPage: action.payload,
    }),
    [actions.showAssignModal]: (state, action) => ({
      ...state,
      assignModalData: {
        isVisible: true,
        isFeaturing: action.payload.isFeaturing || false,
        feedIds: action.payload.feedIds,
        assigneeIdsBySection: {},
        noticeConfig : {
                  frequency: '',
                  maxItems: {},
    }
      },
    }),
    [actions.fetchAssigneesComplete]: (state, action) => ({
      ...state,
      isAssigneesFetched: true,
      userIds: action.payload.userIds,
      departments: action.payload.departments,
      teams: action.payload.teams,
      firmLocations: action.payload.firmLocations,
    }),
    [actions.setSourceModalData]: (state, action) => ({
      ...state,
      assignModalData: {
        ...state.assignModalData,
        ...action.payload,
      },
    }),
    [actions.setAssignModalAssigneeIdsBySection]: (state, action) => ({
      ...state,
      assignModalData: {
        ...state.assignModalData,
        assigneeIdsBySection: action.payload,
      },
    }),
    [actions.hideAssignModal]: (state, action) => ({
      ...state,
      assignModalData: getInitialState().assignModalData,
    }),
    [actions.showAssignSourcesContent]: (state, action) => ({
      ...state,
      isAssignSourcesContentShown: true,
    }),
    [actions.hideAssignSourcesContent]: (state, action) => ({
      ...state,
      isAssignSourcesContentShown: false,
    }),
    [actions.selectFeed]: (state, action) => ({
      ...state,
      selectedFeedIds: [...state.selectedFeedIds, action.payload],
    }),
    [actions.deselectFeed]: (state, action) => ({
      ...state,
      selectedFeedIds:
        state.selectedFeedIds.filter(id => id !== action.payload),
    }),
    [actions.selectAllFeeds]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.setSelectedFeedIds]: (state, action) => ({
      ...state,
      selectedFeedIds: action.payload,
      isLoading: false,
    }),
    [actions.showEditModal]: (state, action) => ({     
      ...state,
      editModalData: {
        isVisible: true,
        feedId: action.payload.feed.id,
        tab: action.payload.tab,
      },
    }),
    [actions.hideEditModal]: (state, action) => ({
      ...state,
      editModalData: getInitialState().editModalData,
      manageLabelsFilter: getInitialState().manageLabelsFilter,
      labels: getInitialState().labels
    }),
    [actions.addAssignments]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.updateAssignments]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.removeAssignments]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.showLoader]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.hideLoader]: (state, action) => ({
      ...state,
      isLoading: false,
    }),
    [actions.promoteFeeds]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.demoteFeeds]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.unpromoteFeeds]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.undemoteFeeds]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.excludeFeeds]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.removeFeeds]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.setEditModalDataDetails]: (state, action) => ({
      ...state,
      editModalData: {
        ...state.editModalData,
        ...action.payload,
      }
    }),
    [actions.saveFeed]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.setShouldShowBrowseModal]: (state, action) => ({
      ...state,
      shouldShowBrowseModal: action.payload,
    }),
    [actions.setShouldShowSelfAddModal]: (state, action) => ({
      ...state,
      shouldShowSelfAddModal: action.payload,
      createProprietaryFeedData: getInitialState().createProprietaryFeedData,
      createTwitterFeedData: getInitialState().createTwitterFeedData,
    }),
    [actions.createSources]: (state, action) => ({
      ...state,
      feedIdsForSourcesBeingCreated: [...action.payload],
    }),
    [actions.createSourcesComplete]: (state, action) => ({
      ...state,
      feedIdsForCreatedSources: [
        ...state.feedIdsForCreatedSources,
        ...action.payload,
      ],
      feedIdsForSourcesBeingCreated: [],
      shouldShowBrowseModal: false,
    }),
    [actions.validateFeed]: (state, action) => ({
      ...state,
      isLoading: true,
      createProprietaryFeedData: {
        ...state.createProprietaryFeedData,
        isValidating: true,
        errorMessage: null,
        isExisting: false,
        feeds: [],
        selectedFeed: null,
      },
    }),
    [actions.updateCreateProprietaryFeedData]: (state, action) => ({
      ...state,
      createProprietaryFeedData: {
        ...state.createProprietaryFeedData,
        ...action.payload,
      },
    }),
    [actions.resetCreateProprietaryFeedData]: (state, action) => ({
      ...state,
      createProprietaryFeedData: getInitialState().createProprietaryFeedData,
    }),
    [actions.createProprietaryFeed]: (state, action) => ({
      ...state,
      isLoading: true,
      createProprietaryFeedData: {
        ...state.createProprietaryFeedData,
        name: action.payload.name,
      }
    }),
    [actions.updateCreateTwitterFeedData]: (state, action) => ({
      ...state,
      createTwitterFeedData: {
        ...state.createTwitterFeedData,
        ...action.payload,
      },
    }),
    [actions.createTwitterFeed]: (state, action) => ({
      ...state,
      isLoading: true,
    }),

    // label actions
    [actions.fetchLabels]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.fetchLabelsComplete]: (state, action) => ({
      ...state,
      isLoading: false,
      labels: action.payload,
    }),
    [actions.setNewLabelData]: (state, action) => ({
      ...state,
      newLabelData: {
        ...state.newLabelData,
        ...action.payload,
      },
    }),
    [actions.resetNewLabelData]: (state, action) => ({
      ...state,
      newLabelData: getInitialState().newLabelData,
    }),
    [actions.saveLabel]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.setIsManageLabelsModalShown]: (state, action) => ({
      ...state,
      isLoading: action.payload,
      isManageLabelsModalShown: action.payload,
      manageLabelsModalData: getInitialState().manageLabelsModalData,
      newLabelData: getInitialState().newLabelData,
      manageLabelsFilter: getInitialState().manageLabelsFilter,
      labels: getInitialState().labels,
      manageLabelsTotalCount: getInitialState().manageLabelsTotalCount,
    }),
    [actions.setManageLabelsModalData]: (state, action) => ({
      ...state,
      manageLabelsModalData: {
        ...state.manageLabelsModalData,
        ...action.payload,
      },
    }),
    [actions.resetManageLabelsModalData]: (state, action) => ({
      ...state,
      manageLabelsModalData: getInitialState().manageLabelsModalData,
    }),
    [actions.createLabel]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.deleteLabel]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.removeLabels]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.setIsLabelModalShown]: (state, action) => ({
      ...state,
      isLoading: action.payload,
      isLabelModalShown: action.payload,
      labelModalData: getInitialState().labelModalData,
      manageLabelsFilter: getInitialState().manageLabelsFilter,
      labels: getInitialState().labels,
      manageLabelsTotalCount: getInitialState().manageLabelsTotalCount,
    }),
    [actions.updateLabelModalData]: (state, action) => ({
      ...state,
      labelModalData: {
        ...state.labelModalData,
        ...action.payload,
      },
    }),
    [actions.resetLabelModalData]: (state, action) => ({
      ...state,
      isLabelModalShown: false,
      labelModalData: getInitialState().labelModalData,
    }),
    [actions.addLabels]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.addFeaturedSources]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.removeFeaturedSources]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.sendCsvEmail]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.fetchAllParentLabelsComplete]: (state, action) => ({
      ...state,
      allParentLabels: action.payload,
    }),
    [actions.setManageLabelsFilters]: (state, action) => ({
      ...state,
      isLoading: true,
      labels: null, 
      manageLabelsFilter: { ...state.manageLabelsFilter, ...action.payload }
    }),
    [actions.setManageLabelsTotalCount]: (state, action) => ({
      ...state,
      isLoading: false,
      manageLabelsTotalCount: action.payload

    }),
    [actions.updateLabels]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.updateBulkEditLabels]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.showSelectedColumns]: (state, action) => {
      setSourceColumns(action.payload)
      return {
        ...state,
        selectedColumns: action.payload,
      }
    },
    [actions.setReportModalVisibility]: (state, action) => ({
      ...state, 
      isReportModalVisible: action.payload
    }),
    [actions.setFeedIdsToReport]: (state, action) => ({
      ...state, 
      feedIdsToReport: action.payload
    }),
    [actions.reportFeeds]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.setSelectedAllFeedsHealth]: (state, action) => ({
      ...state,
      selectedAllFeedsHealth: action.payload,
      isLoading: false,
    }),
  },
  getInitialState(),
)
