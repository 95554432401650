import PropTypes from 'prop-types'
import React from 'react'

import styles from './SearchQueryComponents.less'


export default function SearchFullQuery({query, fqParams}) {
  return (
    <div className={styles.container}>
      <h4>Solr Query:</h4>
      <p className={styles.component}>{query}</p>
      <h4>Filter query parameters:</h4>
      {fqParams.map((param, index) =>
        <p className={styles.component} key={index}>{param}</p>
      )}
    </div>
  )
}
SearchFullQuery.propTypes = {
  query: PropTypes.string.isRequired,
  fqParams: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}
