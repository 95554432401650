import {changeCaseObject} from 'app/utils'

import * as API from './api'

if (window.performance && window.performance.timing) {
  window.addEventListener('load', () => {
    // Don't log this data for certain URLs (it'll skew the results)
    const ignoreUrls = ['/article']
    if (ignoreUrls.some(url => window.location.pathname.startsWith(url))) {
      return
    }

    // We need to defer this for one more frame so that the 'load' event
    // has finished firing
    window.requestAnimationFrame(() => {
      const {performance} = window
      if (!performance) return

      const {timing} = performance

      const timingData = {}
      if (timing.redirectEnd) {
        timingData.redirect = timing.redirectEnd - timing.redirectStart
      }
      timingData.domainLookup =
        timing.domainLookupEnd - timing.domainLookupStart
      timingData.latency = timing.connectEnd - timing.fetchStart
      timingData.responseStart = timing.responseStart - timing.requestStart
      timingData.responseFinish = timing.responseEnd - timing.responseStart
      timingData.domParse = timing.domInteractive - timing.domLoading
      timingData.domFullLoad = timing.domComplete - timing.domLoading
      timingData.frontendFullLoad = timing.loadEventEnd - timing.domLoading
      timingData.fullLoad = timing.loadEventEnd - timing.fetchStart
      if (performance.getEntriesByType) {
        timingData.requestCount =
          performance.getEntriesByType('resource').length
      }

      // Only send these stats on production pages where a global state
      // is present
      if (
        window.mzInitialGlobalState
        && window.mzInitialGlobalState.result
        && window.mzInitialGlobalState.result.is_production
      ) {
        const data = changeCaseObject.snakeCase(timingData)
        API.sendFrontendStats(data)
      }
    })
  })
}
