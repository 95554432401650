import {graphqlQuery} from 'app/api'
import {args, gql} from 'app/graphql'


export function fetchPageVisits({firmId, start, end}) {
  return graphqlQuery(
    gql`
      query {
        pageVisits(${args({firmId, start, end})}) {
          id
          name
          url
          minutes
          visitors {
            id
            name
            date
            minutes
            userAgent {
              os
              deviceType
              browser
              browserVersion
            }
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchVisitors({firmId, start, end}) {
  return graphqlQuery(
    gql`
      query {
        visitors(${args({firmId, start, end})}) {
          id
          name
          minutes
          pages {
            id
            name
            url
            date
            minutes
            userAgent {
              os
              deviceType
              browser
              browserVersion
            }
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchBrowsers({firmId, start, end}) {
  return graphqlQuery(
    gql`
      query {
        browsers(${args({firmId, start, end})}) {
          browser
          visitors {
            id
            name
            userAgent {
              os
              deviceType
              browser
              browserVersion
            }
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}
