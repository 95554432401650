import classNames from 'classnames'
import React from 'react'

import * as dashboardStyles from 'app/dashboard/Dashboard.less'
import * as sectionStyles from './DashboardSection.less'
import * as styles from './DashboardSectionLoading.less'
import DashboardDocumentListLoading from "../dashboard-document-list/DashboardDocumentListLoading"
import PropTypes from "prop-types"
import LoadingSpinner from "app/common/LoadingSpinner"


function DashboardSectionLoading({
  hasChart,
}) {

  return (
    <div className={dashboardStyles.dashboardSection}>
      <div className={classNames(styles.sectionHeader)} />
      { hasChart && (
      <div
        className={classNames(dashboardStyles.chart, dashboardStyles.chartLoading)}
      >
        <div className={classNames(dashboardStyles.loading,)}>
          <LoadingSpinner className={dashboardStyles.spinner} />
          Loading Timeline Chart...
        </div>
      </div>
      )}
      <div className={classNames(sectionStyles.searchHeaderContainer)}>
        <div className={classNames(sectionStyles.searchHeader)} >
          <div className={classNames(styles.searchName, sectionStyles.searchName)} />
          <div className={classNames(styles.searchName, sectionStyles.stockDataContainer)} />
        </div>
        <div className={classNames(
          styles.trendingTermsContainer, sectionStyles.trendingTermsContainer
        )}>
          <div className={classNames(sectionStyles.trendingLabel, sectionStyles.trendingLabelDisplay)}>
            Trending Terms:
          </div>
          <div className={classNames(styles.trendingTerms, sectionStyles.trendingTerms)}>
            <div className={classNames(styles.term, sectionStyles.term)}/>
            <div className={classNames(styles.term, sectionStyles.term)}/>
            <div className={classNames(styles.term, sectionStyles.term)}/>
          </div>
        </div>
      </div>
      <DashboardDocumentListLoading />
      <div className={sectionStyles.sectionFooter}>
        <div className={classNames(styles.searchNameFooter, sectionStyles.searchNameFooter)} />
      </div>
    </div>
  )
}
DashboardSectionLoading.propTypes = {
  hasChart: PropTypes.bool.isRequired,
}
export default DashboardSectionLoading
