import {graphqlQuery} from 'app/api'
import {args, gql} from 'app/graphql'


export function fetchSearches({
  page,
  sortField,
  sortDirection,
  authors,
  categories,
  keywords,
  nameMatchOnly,
  exactMatch,
  nameStartsWith,
  nameFilter,
}) {
  return graphqlQuery(
    gql`
      query {
        data: savedSearchesLookup(${args({
          page,
          sortField,
          sortDirection,
          authors,
          categories,
          keywords,
          nameMatchOnly,
          exactMatch,
          nameStartsWith,
          nameFilter,
        })}) {
          items {
            search {
              id
              name
              slug
              category
              isFirmLibrary
              notes
            }
            matchingQueryTerms
          }
          totalCount
          allIds
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchSearch(id) {
  return graphqlQuery(
    gql`
      query {
        savedSearch(id: ${id}) {
          id
          name
          baseQuery
        }
      }
    `
  ).then(({body: {data}}) => data)
}
