import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getSearchesAdmin } from '../searches-admin-selectors'
import ShareSearch from './ShareSearch'
import AlertSettings from './AlertSettings'

import './ShareModal.less'


@connect(
  // Map state to props
  state => {
    const searchesAdmin = getSearchesAdmin(state)
    return {
      searchIds: searchesAdmin.selectedSearchIds,
      assignType: searchesAdmin.assignType,
      step: searchesAdmin.shareModalData.step,
      shareModalData: searchesAdmin.shareModalData,
    }
  },
  {

  }
)
export default class ShareSearchContainer extends Component {
  render() {
    const {searchIds} = this.props
    const plural = searchIds.length > 1
    const selectedUserCount = this.shareWithUserCount()
    const header = this.props.assignType === 'add'
      ? <h1>
          Add {searchIds.length} Linked Search{plural ? 'es' : ''} to User or Group Profiles
          ({selectedUserCount} Selected)
        </h1>
      : <h1>
          Add Unlinked Copies of {searchIds.length} Search{plural ? 'es' : ''} to User or Group Profiles
          ({selectedUserCount} Selected)
        </h1>

    const description = this.props.assignType === 'add'
      ? <p>This will create a search that always refers back to the original and any updates to the original search
          will automatically update the linked search. *If you've selected any Sources or Source Folders, they will be
          added to the selected profiles, but will be independent of and not linked to the original; updates to the
          original Sources/Folders will not update the copy.</p>
      : <p>This will create a direct copy of the search as it is at this point in time. This search will be
          independent of and not linked to the original; updates to the original search will not update the copy.</p>

    let content = null
    if (this.props.step === 2) {
      content = <AlertSettings/>
    } else {
      content = <ShareSearch/>
    }

    return (
     <div id="share-searches-content">
        {header}
        {description}
        {content}
      </div>
    )
  }

  shareWithUserCount() {
    const assigneeIdsBySection = this.props.shareModalData.assigneeIdsBySection
    let count = 0
    Object.keys(assigneeIdsBySection).forEach(key => {
      count += assigneeIdsBySection[key].length
    })
    return count
  }
}
