export const STATE_PROP_NAME = 'emailNotifications'

export const PERSONAL = 'personal_alerts'
export const GROUP = 'group_alerts'
export const SIGNALS = 'signals_alerts'
export const ESG = 'esg_alerts'
export const DISABLE = 'disable_alerts'

export const DELIVERY_OPTIONS = [
  {option: PERSONAL, label: 'Personal Alerts'},
  {option: GROUP, label: 'Group Alerts'},
  {option: SIGNALS, label: 'Insights Alerts'},
  {option: ESG, label: 'ESG Alerts'},
  {option: DISABLE, label: 'Disable Alerts'},
]

export const DELIVERY_DAYS = {
  0: 'Monday',
  1: 'Tuesday',
  2: 'Wednesday',
  3: 'Thursday',
  4: 'Friday',
  5: 'Saturday',
  6: 'Sunday',
}

export const SCHEDULE_BLOCKS = {
  0: 'Early Morning',
  3: 'Morning',
  6: 'Mid-day',
  9: 'Afternoon',
  12: 'Evening',
}

export const NONE_FREQ = 'none'
export const DAILY_FREQ = 'daily'
export const WEEKDAY_FREQ = 'weekday'
export const WEEKLY_FREQ = 'weekly'
export const MONTHLY_FREQ = 'monthly'
export const INTRA_DAY_FREQ = 'trigger'
export const REAL_TIME_FREQ = 'priority_trigger'
export const ALL_FREQ = 'all'

export const FREQUENCY_HELP = {
  'esg': {
    [NONE_FREQ]: () => 'Do not receive email alerts with ESG articles.',
    [DAILY_FREQ]: () => 'Receive one email alert with relevant ESG articles each day.',
    [WEEKDAY_FREQ]: () => 'Receive one email alert with relevant ESG articles every weekday.',
    [WEEKLY_FREQ]: day => `Receive one email alert with relevant ESG articles and ESG Summary each week on ${day}.`,
  }
}

export const BASE_FREQUENCY_KEYS = [
  NONE_FREQ, DAILY_FREQ, WEEKDAY_FREQ, WEEKLY_FREQ, MONTHLY_FREQ, INTRA_DAY_FREQ, REAL_TIME_FREQ
]

export const INSIGHTS_EMAIL_FREQUENCIES = {
  [NONE_FREQ]: 'Do Not Send',
  [DAILY_FREQ]: 'Daily',
  [WEEKDAY_FREQ]: 'Daily',
  [WEEKLY_FREQ]: 'Weekly',
}

export const ESG_EMAIL_FREQUENCIES = {
  [NONE_FREQ]: 'Do Not Send',
  [DAILY_FREQ]: 'Daily',
  [WEEKDAY_FREQ]: 'Daily',
  [WEEKLY_FREQ]: 'Weekly',
}

export const BASE_EMAIL_FREQUENCIES = {
  [NONE_FREQ]: 'Do Not Send',
  [DAILY_FREQ]: 'Daily',
  [WEEKDAY_FREQ]: 'Weekday',
  [WEEKLY_FREQ]: 'Weekly',
  [MONTHLY_FREQ]: 'Monthly',
  [INTRA_DAY_FREQ]: 'Intra-Day',
  [REAL_TIME_FREQ]: 'Real-Time',
}

export const EMAIL_FREQUENCY_MAPPING = {
  esg: ESG_EMAIL_FREQUENCIES,
  signals: INSIGHTS_EMAIL_FREQUENCIES,
  base: BASE_EMAIL_FREQUENCIES,
}

export const TRUSTED = 'trusted'
export const TRUSTED_UNCATEGORIZED = 'trusted-uncategorized'

export const CATEGORIES = {
  'company': 'Companies',
  'client': 'Companies',
  'industry': 'Industries',
  'practice': 'Practices',
  'competitor': 'Competitors',
  'firm': 'Competitors',
  'source': 'Sources',
  'trusted': 'Sources',
  'topic': 'Topics',
  'tracker': 'Topics',
}

export const CATEGORIES_SINGULAR = {
  'company': 'Company',
  'client': 'Company',
  'industry': 'Industry',
  'practice': 'Practice',
  'competitor': 'Competitor',
  'firm': 'Competitor',
  'source': 'Source',
  'trusted': 'Source',
  'topic': 'Topic',
  'tracker': 'Topic',
}

export const EMAIL_DELIVERY_WEEKDAY = 'email_delivery_weekday'
export const EMAIL_SCHEDULES_ARRAY = 'email_schedules_array'
export const FREQUENCY = 'frequency'
export const ARTICLES_PER_FACTOR = 'articles_per_factor'
export const EMAIL_SEND_ZERO_RESULTS = 'email_send_zero_results'
export const DELIVER_ON_WEEKENDS = 'deliver_on_weekends'
export const SUB_FACTORS = 'sub_factors'
export const HIDE_DUPLICATE_ARTICLES = 'hide_duplicate_articles'
export const ALPHABETICAL_ORDER = 'alphabetical_order'
export const USE_ALTERNATE_EMAIL_TEMPLATE = 'use_alternate_email_template'
export const SUMMARY_TEXT_LENGTH = 'summary_text_length'
export const MERGE_INSIGHTS_INTO_BASE = 'merge_insights_into_base'
export const SEND_ALONE = 'send_alone'
export const CC_EMAIL_INPUT_WIDTH = '490px'
export const CC_EMAIL_INPUT_WIDTH_WIDER = '715px'
export const DELIVERY_GROUP_MEMBER = 'delivery_group_member'

export const TOKEN_INPUT_DELIMITERS = ['Enter', 'Tab', ';', ',']

export const MAX_FACTORS_TO_DISPLAY = 5
export const MAX_SUBFACTORS_TO_DISPLAY = 5

export const BASE_FREQUENCY_HELP = {
  [DAILY_FREQ]: 'Receive one email alert with relevant articles each day.',
  [WEEKDAY_FREQ]: 'Receive one email alert with relevant articles each day.',
  [WEEKLY_FREQ]: 'Receive one email alert with relevant articles each week.',
  [MONTHLY_FREQ]: 'Receive one email alert with relevant articles each month.',
  [INTRA_DAY_FREQ]: 'Receive emails with relevant articles from individual ' +
  'searches more frequently throughout the day.',
  [REAL_TIME_FREQ]: 'Similar to Intra-Day, but delivered even more frequently.',
}

export const BASE_NOTIFICATION_TYPE = 'personal'
export const INSIGHTS_NOTIFICATION_TYPE = 'signals'
export const ESG_NOTIFICATION_TYPE = 'esg'

export const CATEGORY_INDUSTRY = 'industry'

export const SEARCH_CATEGORY_BACKEND_LOOKUP = {
  'Practice': 'Practice',
  'Competitor': 'Firm',
  'Source': 'Trusted',
  'Industry': 'Industry',
  'Topic': 'Tracker',
  'Company': 'Client',
}

export const PUBLICATION_TYPE_DEFAULT = 'default_pub_types'
export const PUBLICATION_TYPE_FILINGS = 'all_filings'
export const PUBLICATION_TYPE_TWITTER = 'twitter'
export const PUBLICATION_TYPE_EVENT = 'event'

export const NOTICE_PUBLICATION_TYPE = {
  [PUBLICATION_TYPE_DEFAULT]: 'Articles',
  [PUBLICATION_TYPE_FILINGS]: 'Filings',
  [PUBLICATION_TYPE_TWITTER]: 'Tweets',
  [PUBLICATION_TYPE_EVENT]: 'Events'
}

export const MAX_ITEMS_LOOKUP = {
  0: 'None',
  3: 'Three',
  5: 'Five',
  10: 'Ten',
  20: 'Twenty',
  40: 'Forty',
  500: 'All'
}

export const MAX_ITEMS_OPTIONS = [
  {key: 0, label: MAX_ITEMS_LOOKUP[0], value: MAX_ITEMS_LOOKUP[0]},
  {key: 3, label: MAX_ITEMS_LOOKUP[3], value: MAX_ITEMS_LOOKUP[3]},
  {key: 5, label: MAX_ITEMS_LOOKUP[5], value: MAX_ITEMS_LOOKUP[5]},
  {key: 10, label: MAX_ITEMS_LOOKUP[10], value: MAX_ITEMS_LOOKUP[10]},
  {key: 20, label: MAX_ITEMS_LOOKUP[20], value: MAX_ITEMS_LOOKUP[20]},
  {key: 40, label: MAX_ITEMS_LOOKUP[40], value: MAX_ITEMS_LOOKUP[40]},
]

export const MAX_ITEMS_OPTIONS_ALL = JSON.parse(JSON.stringify(MAX_ITEMS_OPTIONS))
MAX_ITEMS_OPTIONS_ALL.push({key: 500, label: MAX_ITEMS_LOOKUP[500], value: MAX_ITEMS_LOOKUP[500]},)

export const MAX_ITEMS_OPTIONS_NUMERIC = [
  {key: 0, label: '0', value: 0},
  {key: 3, label: '3', value: 3},
  {key: 5, label: '5', value: 5},
  {key: 10, label: '10', value: 10},
  {key: 20, label: '20', value: 20},
  {key: 40, label: '40', value: 40},
]

export const MAX_ITEMS_OPTIONS_NUMERIC_ALL = JSON.parse(JSON.stringify( MAX_ITEMS_OPTIONS_NUMERIC ))
MAX_ITEMS_OPTIONS_NUMERIC_ALL.push({key: 500, label: '500', value: 500},)

export const NONE_SELECTED = '---'
export const MAX_ITEMS_OPTIONS_BULK = JSON.parse(JSON.stringify( MAX_ITEMS_OPTIONS_NUMERIC ))
MAX_ITEMS_OPTIONS_BULK.push({key: -1, label: NONE_SELECTED, value: NONE_SELECTED},)

export const MAX_ITEMS_OPTIONS_BULK_ALL = JSON.parse(JSON.stringify( MAX_ITEMS_OPTIONS_NUMERIC_ALL ))
MAX_ITEMS_OPTIONS_BULK_ALL.push({key: -1, label: NONE_SELECTED, value: NONE_SELECTED},)

export const INSIGHTS_BULK_ASSIGN_FREQUENCY = {
  [WEEKDAY_FREQ]: `${INSIGHTS_EMAIL_FREQUENCIES[DAILY_FREQ]} Email`,
  [DAILY_FREQ]: `${INSIGHTS_EMAIL_FREQUENCIES[DAILY_FREQ]} Email`,
  [WEEKLY_FREQ]: `${INSIGHTS_EMAIL_FREQUENCIES[WEEKLY_FREQ]} Email`,
  [NONE_FREQ]: INSIGHTS_EMAIL_FREQUENCIES[NONE_FREQ],
}

export const ESG_BULK_ASSIGN_FREQUENCY = {
  [WEEKDAY_FREQ]: `${ESG_EMAIL_FREQUENCIES[DAILY_FREQ]} Email`,
  [DAILY_FREQ]: `${ESG_EMAIL_FREQUENCIES[DAILY_FREQ]} Email`,
  [WEEKLY_FREQ]: `${ESG_EMAIL_FREQUENCIES[WEEKLY_FREQ]} Email`,
  [NONE_FREQ]: ESG_EMAIL_FREQUENCIES[NONE_FREQ],
}

export const SUMMARY_TEXT_LENGTH_OPTIONS = [
  {key: 50, value: 'Standard'},
  {key: 300, value: 'Extended'},
  {key: 0, value: 'None'},
]

export const INSIGHTS_ARTICLES_PER_FACTOR = [
  {key: 1, label: 1, value: 1},
  {key: 2, label: 2, value: 2},
  {key: 3, label: 3, value: 3},
  {key: 4, label: 4, value: 4},
  {key: 5, label: 5, value: 5},
  {key: 6, label: 6, value: 6},
  {key: 7, label: 7, value: 7},
  {key: 8, label: 8, value: 8},
  {key: 9, label: 9, value: 9},
  {key: 10, label: 10, value: 10},
]

export const NO_ALERT_SPIKE_TYPE = 'none'
export const INTELLIGENT_SPIKE_TYPE = 'intelligent'
export const SPIKE_TYPE_OPTIONS = [
  {key: NO_ALERT_SPIKE_TYPE, label: 'No Alert', value: 'No Alert'},
  {key: INTELLIGENT_SPIKE_TYPE, label: 'Intelligent Alert', value: 'Intelligent Alert'},
]
export const FILTER_BY_NONE = '------'

export const SPIKE_TYPE_LABELS = {
  [NO_ALERT_SPIKE_TYPE]: 'No Alert',
  [INTELLIGENT_SPIKE_TYPE]: 'Intelligent Alert'
}
