import superagent from 'superagent'
import camelCase from 'camel-case'
import is from 'is'
import snakeCase from 'snake-case'
import * as dateFns from 'date-fns'
import {curryN, filter, fromPairs, toPairs, pipe, range} from 'ramda'

import * as urls from 'app/urls'
import { EMAIL_REGEX } from 'app/constants'

/*
 * Wraps the function `func` and returns a new function that takes an event
 * object and first calls `preventDefault()` and `stopPropagation()` on that
 * event. Useful for passing to event handlers to avoid creating a separate
 * function.
 *
 * React example:
 *   <a href="#" onClick={defaultPrevented(actualFunction)}>Click me</a>
 */
export function defaultPrevented(func) {
  return function(e) {
    e.preventDefault()
    e.stopPropagation()
    func(e)
  }
}


/*
 * Takes an array of strings and returns them joined into a single string that
 * makes sense in a sentence. The returned string has one of four formats:
 *
 *   If the array is empty:
 *     ''
 *   If there is one value:
 *     'a'
 *   If there are two values:
 *     'a and b'
 *   If there are three or more values:
 *     'a, b, ..., and c'
 */
export function joinInSentence(strings, additional) {
  if (!strings.length) {
    if (additional > 0) {
      const items = additional > 1 ? 'items' : 'item'
      return `${additional} ${items}`
    }
    return ''
  }
  if (additional > 0) {
    const others = additional > 1 ? 'others' : 'other'
    return `${strings.join(', ')} and ${additional} ${others}`
  }
  if (strings.length === 1) {
    return strings[0]
  }
  if (strings.length === 2) {
    return `${strings[0]} and ${strings[1]}`
  }
  return `${strings.slice(0, -1).join(', ')}, and ${strings[strings.length - 1]}`
}


/**
 * Returns a new array based on `array` with `item` inserted in between each
 * element. If `item` is a function, calls it for every element, passing in the
 * index of the insertion (not the position in the new array), and inserts its
 * return value.
 */
export function insertBetweenItems(array, item) {
  const getItem = is.function(item) ? item : () => item
  const newArray = array.reduce((acc, val) => {
    const index = acc.length / 2 // `| 0` converts to an int
    acc.push(val)
    acc.push(getItem(index))
    return acc
  }, [])
  // This will append `item` to the end as well, which we don't want
  newArray.pop()
  return newArray
}

export function formatNumber(number) {
  if (window.Intl && window.Intl.NumberFormat) {
    return (new Intl.NumberFormat).format(number)
  }
  // If the browser doesn't support the international number format API (IE11),
  // just show the number as-is.
  return number
}

/**
 * Loads static assets in a way that's buffered (maximum of one request at a
 * time). The results are not cached by this class; it is assumed that the
 * browser will cache the response.
 */
export class ResourceLoader {
  buffer = {}

  async load(url) {
    if (!this.buffer[url]) {
      this.buffer[url] = superagent.get(url)
    }
    const response = await this.buffer[url]
    delete this.buffer[url]
    return response.body || response.text
  }
}


/**
 * The reason we're not using the third-party change-case-object library is this
 * long-standing bug:
 * https://github.com/BinaryThumb/change-case-object/issues/7
 */
const makeTransformer = changeCase => {
  const transformObject = obj => {
    if (is.array(obj)) {
      return obj.map(transformObject)
    }
    if (!is.object(obj)) return obj
    return Object.keys(obj).reduce((newObj, key) => {
      const value = obj[key]
      newObj[changeCase(key)] = transformObject(value)
      return newObj
    }, {})
  }
  return transformObject
}

export const changeCaseObject = {
  camelCase: makeTransformer(camelCase),
  snakeCase: makeTransformer(snakeCase),
}


// This should roughly match the logic in
// `apps/mzutils/templatetags/date_tags.py`.
export function formatRelativeDate(oldDate, newDate = new Date) {
  const differenceInSeconds = dateFns.differenceInSeconds(newDate, oldDate)
  if (differenceInSeconds < 90 && differenceInSeconds >= 0) {
    return 'Just now'
  }
  // Future date
  if (differenceInSeconds < 0) {
    const differenceInCalendarDays = Math.abs(
      dateFns.differenceInCalendarDays(newDate, oldDate)
    )
    if (differenceInCalendarDays === 0) {
      return 'Today'
    }
    if (differenceInCalendarDays === 1) {
      return 'Tomorrow'
    }
    return dateFns.format(oldDate, 'LLLL do, yyyy')
  }
  const differenceInCalendarDays = dateFns.differenceInCalendarDays(
    newDate,
    oldDate,
  )
  if (differenceInCalendarDays > 5) {
    return dateFns.format(oldDate, 'LLLL do')
  }
  if (differenceInCalendarDays > 1) {
    return (
      `${dateFns.format(oldDate, 'iiii')} at ${dateFns.format(oldDate, 'h:mm a')}`
    )
  }
  if (dateFns.differenceInHours(newDate, oldDate) > 6) {
    const dayLabel = dateFns.differenceInCalendarDays(newDate, oldDate) === 1
      ? 'Yesterday'
      : 'Today'
    return `${dayLabel} at ${dateFns.format(oldDate, 'h:mm a')}`
  }
  return dateFns.formatDistanceStrict(oldDate, newDate, {addSuffix: true})
}


export function truncateText(text, charCount) {
  let truncated = text.slice(0, charCount)
  if (truncated !== text) {
    truncated += '...'
  }
  return truncated
}


/**
 * This is a workaround for JavaScript Date objects always being in the local
 * timezone. It compensates by subtracting the UTC offset from the date.
 */
export function adjustDateForUtc(date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  )
}


/**
 * A convenience function for creating a new date in UTC.
 */
export function newUtcDate() {
  return adjustDateForUtc(new Date)
}


/**
 * Makes a synchronous POST request, as if you submitted a form with the given
 * data.
 * @param url: String - The URL to post to.
 * @param data: Object<String | Array<String>>
 *     - The form data in key/value form. Array values are treated as multiple
 *       key/value pairs.
 */
export function submitPostRequest({url, data}) {
  const form = document.createElement('form')
  form.action = url
  form.method = 'post'

  const createInput = (key, value) => {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = key
    input.value = value
    form.appendChild(input)
  }

  for (const [key, value] of Object.entries(data)) {
    if (Array.isArray(value)) {
      value.forEach(item => {
        createInput(key, item)
      })
    } else {
      createInput(key, value)
    }
  }

  document.body.appendChild(form)
  form.submit()
}


/**
 * Tests an event object to make sure that it is a click with the left mouse
 * button and no modifier keys are pressed.
 */
export function isLeftClickEvent(event) {
  return (
    event.button === 0
    && !(event.metaKey || event.ctrlKey || event.shiftKey)
  )
}


/**
 * Creates a generator that yields increasing numbers starting at `start`.
 */
export function* indexGenerator(start = 0) {
  let index = start
  while (true) {
    yield index
    index++
  }
}


export function runSearchForQuery(query) {
  // Add `ss:` in front of search IDs.
  query = query
    .split('~|')
    .map(value => {
      if (isNaN(value)) {
        return value
      }
      // It's a search ID
      return `ss:${value}`
    })
    .join('~|')
  window.location.href = urls.tier3Unsaved({query})
}


export function intersectionExists(seq1, seq2) {
  for (const item of seq1) {
    if (seq2.includes(item)) {
      return true
    }
  }
  return false
}


/**
 * Returns a new array with `item` inserted into `array` after every `n`
 * elements. If `item` is a function, calls the function, passing it the count
 * of already inserted items, and inserts the return value.
 */
export function insertEveryN(item, n, array) {
  const getItem = is.function(item) ? item : () => item
  return range(0, array.length + Math.floor(array.length / n))
    .map(index => {
      const alreadyInsertedCount = Math.floor(index / (n + 1))
      if (index > 0 && (index + 1) % (n + 1) === 0) {
        return getItem(alreadyInsertedCount)
      }
      const arrayIndex = index - alreadyInsertedCount
      return array[arrayIndex]
    })
}

export const filterKeys = curryN(2, (predicate, iterable) =>
  pipe(
    toPairs,
    filter(([key]) => predicate(key)),
    fromPairs,
  )(iterable),
)

export function getValidandInvalidEmailList(recipients) {
  const uniqueIds = new Set();
  const uniqueList = [];
  let repeatingIds = [];
  for (const str of recipients) {
    const lowercaseStr = str.toLowerCase();
    if (uniqueIds.has(lowercaseStr)) {
      repeatingIds.push(str);
    } else {
      uniqueIds.add(lowercaseStr);
      uniqueList.push(str);
    }
  }
  let validEmails = [];
  let invalidEmails = [];
  for (const email of uniqueList) {
    if (EMAIL_REGEX.test(email)) {
      validEmails.push(email);
    } else {
      invalidEmails.push(email);
    }
  }
  return [validEmails, invalidEmails, repeatingIds]
}

export function getChunkMessage(ids) {
  const chunks = [];
  let message = "";
  let idList = [...new Set(ids)]
  for (let i = 0; i < idList.length; i += 2) {
    chunks.push(idList.slice(i, i + 2));
  }
  chunks.forEach((chunk, index) => {
    message += `${chunk.join(', ')}`;
    if (index !== chunks.length - 1) {
      message += '\n';
    }
  });
  return message
}
