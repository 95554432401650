import React, { Fragment } from 'react'
import PropType from 'prop-types'
import { LoadingHeadline, LoadingSingleLine } from 'app/esg/loading'

DiligentSection.propTypes = {
  posts: PropType.arrayOf(PropType.shape({
    link: PropType.string.isRequired,
    title: PropType.string.isRequired,
    publishedAt: PropType.string.isRequired,
    content: PropType.string.isRequired,
  })).isRequired
}

function Article({ idx, post }) {
  return (
    <Fragment key={idx}>
      {idx > 0 ? <div className='vertical-separator'/> : null}
      <div className='img-article-section-display'>
        <a href={post.link}><img src={post.articleImage} className='article-image' /></a>
        <article>
          <header>
            <h1>
              <a href={post.link} target='_blank'>
                {post.linkText}
              </a>
            </h1>
            <div>
              <span>Topic: ESG</span>
              <span className='horizontal-separator'>|</span>
              <span>{post.publishedAt}</span>
            </div>
          </header>
          <p>{post.content}</p>
        </article>
      </div>
    </Fragment>)
}

function LoadingArticleSkeletons() {
  return (
    <div className='loading-article-container'>
      <LoadingHeadline />
      <LoadingSingleLine />
      <LoadingSingleLine />
      <LoadingSingleLine />
    </div>
  )
}

function DiligentSection({ posts, isLoading }) {
  return (
    <section className='section diligent-section'>
      <header>
        <h1>Diligent Institute</h1>
        <a className='link-with-arrow' href='https://www.diligent.com/insights/#maintype=ESG%20%26%20Diversity'
           target='_blank'>See more</a>
      </header>
      {isLoading && posts.length === 0 ? (
        <>
          {[...Array(1, 2, 3)].map((i) => {
            return <LoadingArticleSkeletons key={"art-skeletons-" + i} />
          })}
        </>
      ) : (
        posts.map((post, idx) => {
            return <Article idx={idx} post={post}/>
          }
        ))}
    </section>
  )
}

export default DiligentSection
