import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {defaultPrevented} from 'app/utils'

import {getSourcesAdmin} from '../sources-admin-selectors'
import {
  createSources,
  resetCreateProprietaryFeedData,
  setShouldShowSelfAddModal,
} from '../sources-admin-actions'


@connect(
  createSelector(
    [getSourcesAdmin],
    (sourcesAdmin) => {
      return {
        feedIdsForSourcesBeingCreated: sourcesAdmin.feedIdsForSourcesBeingCreated,
        feedIdsForCreatedSources: sourcesAdmin.feedIdsForCreatedSources,
        createProprietaryFeedData: sourcesAdmin.createProprietaryFeedData,
      }
    }
  ),
  {
    createSources,
    resetCreateProprietaryFeedData,
    setShouldShowSelfAddModal,
  }
)
export default class ExistingSourcesSelect extends Component {
  state = {
    sourcePreviewIndexes: [],
  }

  render() {
    // note: `sources` here are actually feed objects
    const {createProprietaryFeedData, feedIdsForSourcesBeingCreated, feedIdsForCreatedSources} = this.props

    const sourceList = createProprietaryFeedData.feeds.map((feed, index) => {
      const showPreview = this.state.sourcePreviewIndexes.includes(index)
      const isBeingAdded = feedIdsForSourcesBeingCreated.includes(feed.id)
      const isAdded = feed.isAdded || feedIdsForCreatedSources.includes(feed.id)

      let preview = null
      if (showPreview) {
        const articles = feed.documents.map(entry => (
          <div className="entry">
            <a className="headline" href={entry.link} target="_blank">
              {entry.headline}
            </a>
          </div>
        ))
        preview = (
          <div className="preview">
            {articles.length > 0 ? articles : "This source does not contain any content."}
          </div>
        )
      }

      const handlePreviewToggle = () =>
        showPreview
          ? this.hidePreviewAtIndex(index)
          : this.showPreviewAtIndex(index)

      let addButtonText
      if (feed.isAdded) {
        addButtonText = 'Already added'
      } else if (isAdded) {
        addButtonText = 'Added!'
      } else if (isBeingAdded) {
        addButtonText = 'Adding...'
      } else {
        addButtonText = 'Add Source'
      }

      return (
        <div key={index}>
          <div className="feed">
            <div className="title-area">
              <span className="name">{feed.name}</span>
              <span className="separator"/>
              <a
                className="preview-toggle"
                onClick={defaultPrevented(handlePreviewToggle)}
              >
                {showPreview ? 'Hide Preview' : 'Preview Source'}
              </a>
            </div>

            <button
              className={classNames('add-source', {success: isAdded})}
              onClick={() => this.addSource(feed)}
              disabled={isBeingAdded || isAdded}
            >
              {addButtonText}
            </button>
          </div>

          {preview}
        </div>
      )
    })

    return (
      <div>
        <p>It looks like we already have sources for that URL. You can find this in Base by searching for:</p>

        <div className="feed-list">
          {sourceList}
        </div>

        <p className="contact-manzama">If this is not what you're looking for, please <a href="mailto:content@manzama.com">contact Support</a>.</p>

        <div className="buttons left">
          <a className="leave button" onClick={() => this.props.setShouldShowSelfAddModal(false)}>
            Back to Admin Dashboard
          </a>
        </div>
      </div>
    )
  }

  // State management

  showPreviewAtIndex(index) {
    this.setState({
      sourcePreviewIndexes: [...this.state.sourcePreviewIndexes, index],
    })
  }

  hidePreviewAtIndex(index) {
    this.setState({
      sourcePreviewIndexes:
        this.state.sourcePreviewIndexes.filter(idx => idx !== index),
    })
  }

  addSource(source) {
    this.props.createSources([source.id])
  }
}
