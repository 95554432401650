import React from 'react'

import Message from 'app/common/Message'

import styles from './SearchQueryComponents.less'


export default function SearchQueryLoading() {
  return (
    <div className={styles.container}>
      <Message type={Message.TYPES.LOADING} text="Loading search query..." />
    </div>
  )
}
