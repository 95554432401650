import React, { Component } from 'react'
import { connect } from 'react-redux'
import Switch from 'react-switch'

import Modal from 'app/common/Modal'
import LoadingOverlay from 'app/common/LoadingOverlay'
import Message from 'app/common/Message'
import Button from 'app/common/Button'
import InputBlock from 'app/common/InputBlock'
import TextBox from 'app/common/TextBox'
import Table from 'app/common/Table'
import ConfirmationModal from 'app/common/modals/ConfirmationModal'

import { getMIS } from './mis-selectors'
import * as misActions from './mis-actions'


/**
 * note: free reports are now called "Illuminations". still using `free` as report type.
 */
@connect(
  // Map state to props
  state => {
    const mis = getMIS(state)
    return {
      recipients: mis.recipients,
      isLoading: mis.isLoading,
      notificationMessage: mis.notificationMessage,
      notificationType: mis.notificationType,
      currentReportId: mis.currentReportId,
      currentReport: mis.reports.hasOwnProperty(mis.currentReportId) ? mis.reports[mis.currentReportId] : null,
      currentRecipient: mis.currentRecipient,
      currentOperation: mis.currentOperation,
      editedRecipient: mis.editedRecipient,
      newEmailAddress: mis.newEmailAddress,
      newFirstName: mis.newFirstName,
      newLastName: mis.newLastName,
      isSubscribeAllConfirmationModalShown: mis.isSubscribeAllConfirmationModalShown,
    }
  },

  // Map dispatch to props
  {
    showNotification: misActions.showNotification,
    hideNotification: misActions.hideNotification,
    setCurrentReportId: misActions.setCurrentReportId,
    setNewEmailAddress: misActions.setNewEmailAddress,
    setNewFirstName: misActions.setNewFirstName,
    setNewLastName: misActions.setNewLastName,
    addRecipient: misActions.addRecipient,
    setCurrentRecipient: misActions.setCurrentRecipient,
    setEditedRecipient: misActions.setEditedRecipient,
    saveRecipient: misActions.saveRecipient,
    deleteRecipient: misActions.deleteRecipient,
    saveAutoSubscribe: misActions.saveAutoSubscribe,
    showSubscribeAllConfirmationModal: misActions.showSubscribeAllConfirmationModal,
    hideSubscribeAllConfirmationModal: misActions.hideSubscribeAllConfirmationModal,
    subscribeAllUsers: misActions.subscribeAllUsers,
  },
)
export default class MIS extends Component {
  state = {
    recipientFilterValue: '',
    subscribeAllType: 'subscribe', // or 'unsubscribe'
  }

  render() {
    const currentReportRecipients = this.props.recipients.hasOwnProperty(this.props.currentReportId) ?
      this.props.recipients[this.props.currentReportId].filter(
        recipient => (
          !this.state.recipientFilterValue ||
          recipient.firstName.toLowerCase().indexOf(this.state.recipientFilterValue.toLowerCase()) !== -1  ||
          recipient.lastName.toLowerCase().indexOf(this.state.recipientFilterValue.toLowerCase()) !== -1  ||
          recipient.emailAddress.toLowerCase().indexOf(this.state.recipientFilterValue.toLowerCase()) !== -1
        )
      )
      : []

    const premiumOptions =
      this.props.currentSection && this.props.currentSection.slug === 'premium-reports' && this.props.currentReport ?
        <div className="premium-options">
          <Switch
            id="is-auto-subscribe"
            onChange={(checked, evt, id) => {this.handleAutoSubscribeToggle(checked)}}
            checked={this.props.currentReport.isAutoSubscribe}
            height={22}
            width={48}
            handleDiameter={16}
            offColor="#ccc"
            onColor="#0074b4"
            uncheckedIcon={
              <div className="switch-icon">OFF</div>
            }
            checkedIcon={
              <div className="switch-icon">ON</div>
            }
          />
          <span className="switch-label">Automatically Subscribe New Firm Users</span>

          <Button
            label="Subscribe All Firm Users"
            onClick={() => this.showSubscribeAllConfirmationModal(true)}
          />

          <Button
            label="Unsubscribe All Firm Users"
            onClick={() => this.showSubscribeAllConfirmationModal(false)}
          />
        </div>
        : null

    const subscribeAllConfirmationModal =
      this.props.isSubscribeAllConfirmationModalShown && (
        <ConfirmationModal
          message={
            <>
              Are you sure you want to <strong>{this.state.subscribeAllType} ALL</strong> users in your firm?
            </>
          }
          secondaryMessage="This process will take some time but you don't have to wait here for it to complete."
          confirmButtonText="Yes &ndash; Subscribe All Users"
          onConfirm={() => {
            this.subscribeAllUsers()
            this.props.hideSubscribeAllConfirmationModal()
          }}
          onClose={() => this.props.hideSubscribeAllConfirmationModal()}
          isDestructive={true}
        />
      )

    const content =
      this.props.currentReport ?
        <div className="modal-container">
          <Modal
            isOpen={!!this.props.currentReport}
            onClose={() => this.closeAdminModal()}
          >
            <div>
              <h1>{this.props.currentReport.title} Administration</h1>

              {premiumOptions}

              {subscribeAllConfirmationModal}

              <div className="section-header modal-section-header-container">
                <h2>Add Recipient</h2>
                {
                  this.props.notificationMessage?
                    <Message
                      type={this.props.notificationType}
                      text={this.props.notificationMessage}
                    />
                    : null
                }
              </div>

              <div>
                <InputBlock label="First Name:">
                  <TextBox
                    value={this.props.newFirstName || ''}
                    onChange={evt => this.handleNewFirstNameInputChange(evt)}
                  />
                </InputBlock>

                <InputBlock label="Last Name:">
                  <TextBox
                    value={this.props.newLastName || ''}
                    onChange={evt => this.handleNewLastNameInputChange(evt)}
                  />
                </InputBlock>

                <InputBlock label="Email Address">
                  <TextBox
                    value={this.props.newEmailAddress || ''}
                    className="email-input"
                    onChange={evt => this.handleNewEmailInputChange(evt)}
                  />
                </InputBlock>

                <Button
                  label="Add"
                  onClick={() => this.handleAddRecipientButtonClick()}
                />
              </div>

              <div className="modal-section-header-container">
                <h2>Recipient Listing</h2>
                <div className="filter-container">
                  <TextBox
                    placeholder="Filter..."
                    value={this.state.recipientFilterValue}
                    onChange={evt => this.handleRecipientFilterInputChange(evt)}
                  />
                </div>
              </div>

              <Table
                data={currentReportRecipients}
                getKeyForItem={(recipient) => recipient.emailAddress}
              >
                <Table.Column
                  name="first-name"
                  baseWidth="22%"
                  label="First Name"
                  isSortable
                  sortBy={recipient => recipient.firstName}
                  cellContents={recipient => {
                    if (this.props.currentOperation === 'edit' && this.props.currentRecipient.emailAddress === recipient.emailAddress) {
                      return (
                        <div>
                          <TextBox
                            value={this.props.editedRecipient.firstName}
                            onChange={evt => this.handleEditFirstNameInputChange(evt)}
                          />
                        </div>
                      )
                    } else {
                      return (
                        <div>{recipient.firstName}</div>
                      )
                    }
                  }}
                />

                <Table.Column
                  name="last-name"
                  baseWidth="22%"
                  label="Last Name"
                  isSortable
                  sortBy={recipient => recipient.lastName}
                  cellContents={recipient => {
                    if (this.props.currentOperation === 'edit' && this.props.currentRecipient.emailAddress === recipient.emailAddress) {
                      return (
                        <div>
                          <TextBox
                            value={this.props.editedRecipient.lastName}
                            onChange={evt => this.handleEditLastNameInputChange(evt)}
                          />
                        </div>
                      )
                    } else {
                      return (
                        <div>{recipient.lastName}</div>
                      )
                    }
                  }}
                />

                <Table.Column
                  name="email-address"
                  baseWidth="40%"
                  label="Email Address"
                  isSortable
                  sortBy={recipient => recipient.emailAddress}
                  cellContents={recipient => {
                    if (this.props.currentOperation === 'edit' && this.props.currentRecipient.emailAddress === recipient.emailAddress) {
                      return (
                        <div>
                          <TextBox
                            value={this.props.editedRecipient.emailAddress}
                            onChange={evt => this.handleEditEmailInputChange(evt)}
                          />
                        </div>
                      )
                    } else {
                      return (
                        <div className="email-address">{recipient.emailAddress}</div>
                      )
                    }
                  }}
                />

                <Table.Column
                  name="actions"
                  baseWidth="16%"
                  cellContents={recipient => {
                    if (this.props.currentOperation === 'edit' && this.props.currentRecipient.emailAddress === recipient.emailAddress) {
                      return (
                        <div>
                          <Button
                            label="Save"
                            onClick={() => this.handleSaveRecipientButtonClick()}
                            className="action-button"
                          />
                          <Button
                            label="Cancel"
                            isPlainText={true}
                            onClick={() => this.handleCancelEditButtonClick()}
                            className="action-button"
                          />
                        </div>
                      )
                    } else if (this.props.currentOperation === 'delete' && this.props.currentRecipient.emailAddress === recipient.emailAddress) {
                      return (
                        <div>
                          <Button
                            label="DELETE"
                            isDestructive={true}
                            onClick={() => this.handleDeleteRecipientButtonClick()}
                            className="action-button"
                          />
                          <Button
                            label="Cancel"
                            isPlainText={true}
                            onClick={() => this.handleCancelDeleteButtonClick()}
                            className="action-button"
                          />
                        </div>
                      )
                    } else {
                      return (
                        <div className="action-buttons">
                          <i
                            className="fa fa-pencil action-button"
                            onClick={() => this.handleEditIconClick(recipient)}
                          />
                          <i
                            className="fa fa-trash-o action-button"
                            onClick={() => this.handleDeleteIconClick(recipient)}
                          />
                        </div>
                      )
                    }
                  }}
                />
              </Table>
            </div>
          </Modal>

          {
            this.props.isLoading ?
              <LoadingOverlay/>
              : null
          }
        </div>
        : null

    return (
      <div>
        {content}
      </div>
    )
  }

  closeAdminModal() {
    this.props.setCurrentReportId(null)
  }

  handleNewFirstNameInputChange(evt) {
    this.props.setNewFirstName(evt.target.value)
  }

  handleNewLastNameInputChange(evt) {
    this.props.setNewLastName(evt.target.value)
  }

  handleNewEmailInputChange(evt) {
    this.props.setNewEmailAddress(evt.target.value)
  }

  handleRecipientFilterInputChange(evt) {
    this.setState({recipientFilterValue: evt.target.value})
  }

  handleAddRecipientButtonClick() {
    const recipient = this.getRecipientByEmailAddress(this.props.newEmailAddress)
    if (recipient) {
      this.props.showNotification({
        type: 'error',
        message: 'Email address already exists. You may edit the recipient in the list below.',
      })
      return
    }
    this.props.addRecipient({
      report: this.props.currentReport,
      recipient: {
        firstName: this.props.newFirstName,
        lastName: this.props.newLastName,
        emailAddress: this.props.newEmailAddress,
      },
    })
  }

  handleEditFirstNameInputChange(evt) {
    const editedRecipient = {...this.props.editedRecipient, firstName: evt.target.value}
    this.props.setEditedRecipient(editedRecipient)
  }

  handleEditLastNameInputChange(evt) {
    const editedRecipient = {...this.props.editedRecipient, lastName: evt.target.value}
    this.props.setEditedRecipient(editedRecipient)
  }

  handleEditEmailInputChange(evt) {
    const editedRecipient = {...this.props.editedRecipient, emailAddress: evt.target.value}
    this.props.setEditedRecipient(editedRecipient)
  }

  handleEditIconClick(recipient) {
    this.props.setCurrentRecipient({recipient, operation: 'edit'})
  }

  handleDeleteIconClick(recipient) {
    this.props.setCurrentRecipient({recipient, operation: 'delete'})
  }

  handleCancelEditButtonClick() {
    this.props.setCurrentRecipient({recipient: null, operation: null})
  }

  handleSaveRecipientButtonClick() {
    if (this.props.editedRecipient.emailAddress !== this.props.currentRecipient.emailAddress) {
      // if the email address is being changed, make sure the new one isn't already on the list
      const recipient = this.getRecipientByEmailAddress(this.props.editedRecipient.emailAddress)
      if (recipient) {
        this.props.showNotification({
          type: 'error',
          message: 'Email address already exists. You may edit the recipient in the list below.',
        })
        return
      }
    }
    this.props.saveRecipient({
      report: this.props.currentReport,
      recipient: this.props.currentRecipient,
      editedRecipient: this.props.editedRecipient,
    })
  }

  handleCancelDeleteButtonClick()  {
    this.props.setCurrentRecipient({recipient: null, operation: null})
  }

  handleDeleteRecipientButtonClick() {
    this.props.deleteRecipient({
      report: this.props.currentReport,
      recipient: this.props.currentRecipient,
    })
  }

  getRecipientByEmailAddress(emailAddress) {
    return this.props.recipients[this.props.currentReportId].find(r => r.emailAddress === emailAddress)
  }

  handleAutoSubscribeToggle(checked) {
    this.props.saveAutoSubscribe({isAutoSubscribe: checked, id: this.props.currentReport.id})
  }

  showSubscribeAllConfirmationModal(isSubscribe) {
    this.setState({subscribeAllType: isSubscribe ? 'subscribe' : 'unsubscribe'}, () => {
      this.props.showSubscribeAllConfirmationModal()
    })
  }

  subscribeAllUsers() {
    this.props.subscribeAllUsers({
      reportId: this.props.currentReport.id,
      isSubscribe: this.state.subscribeAllType === 'subscribe'
    })
  }
}
