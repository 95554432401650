export function urlFromSavedSearch(search) {
  let category = search.category
  if (['trusted', 'trusted-uncategorized'].includes(category)) {
    category = 'trusted-sources'
  }
  else if (category === 'firm') {
    category = 'competitor'
  }
  return `/${category}/${search.slug}/${search.id}/`
}


export function urlFromGroup(group) {
  return `/groups/${group.id}/`
}
