import invariant from 'invariant'
import * as dateFns from 'date-fns'
import is from 'is'
import {filter, flatten, fromPairs, last, map, pipe, prop} from 'ramda'
import {
  all,
  call,
  cancel,
  fork,
  put,
  select,
  take,
  takeLatest,
  delay,
} from 'redux-saga/effects'

import * as globalActions from 'app/actions'
import {ISO_DATE_FORMAT} from 'app/constants'
import * as entitiesActions from 'app/entities/entities-actions'
import * as entitiesSelectors from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import {Document, Feed, SavedSearch, User} from 'app/models'
import * as helpQuestions from 'app/global/help-questions'
import {actions as notificationActions} from 'app/global/notifications'
import * as routing from 'app/global/routing'
import * as globalSelectors from 'app/global/global-selectors'
import {
  exportPDF,
  exportDoc,
  exportExcel,
  exportEmail,
} from 'app/reusable/saga'
import * as dnb from 'app/search-results/dun-and-bradstreet'
import * as urls from 'app/urls'
import {handleSagaError} from 'app/utils/errors'
import {delayImmediate, waitUntilDomLoaded} from 'app/utils/sagas'
import {getFiltersForSearch, filtersByCategory} from 'app/utils/searches'

import * as actions from './search-results-page-actions'
import * as api from './search-results-page-api'
import {
  QUERY_PARAM_NAMES,
  RESULTS_PER_PAGE,
  TIME_FRAME_SEPARATOR,
  ESG_GLOBAL_FILTER_ID
} from './search-results-page-constants'
import * as selectors from './search-results-page-selectors'
import * as advancedSearchSelectors from 'app/advanced-search/advanced-search-selectors'
import * as advancedSearchActions from 'app/advanced-search/advanced-search-actions'
import * as advancedSearchApi from 'app/advanced-search/advanced-search-api'
import * as utils from './search-results-page-utils'
import { RELEVANCE_LOOKUP } from 'app/reusable/RelevanceFilter/SearchRelevanceFilterConstants'
import * as reusableApi from 'app/reusable/api'
import {shouldDisableLineChart} from 'app/comparison-page/comparison-utils'
import {changeCaseObject} from "app/utils"

// Actions that change query params in the current URL. `getValue` is an
// optional function that takes an action and returns the query param value. Its
// default is to return `action.payload`.
const QUERY_PARAM_ACTIONS = [
  {
    actionType: actions.setRelevancyLevel,
    name: QUERY_PARAM_NAMES.RELEVANCE,
    getValue: action => action.payload || undefined,
  },
  {
    actionType: actions.setGroupingLevel,
    name: QUERY_PARAM_NAMES.GROUPING,
  },
  {
    actionType: actions.setSelectedLanguageIds,
    name: QUERY_PARAM_NAMES.LANGUAGE,
    getValue: action => action.payload || undefined,
  },
  {
    actionType: actions.setSortOption,
    name: QUERY_PARAM_NAMES.SORT,
  },
  {
    actionType: actions.setCompareId,
    name: QUERY_PARAM_NAMES.COMPARE_ID,
  },
  {
    actionType: actions.setInsightsArticlesFirst,
    name: QUERY_PARAM_NAMES.INSIGHTS_FIRST,
    getValue: action => action.payload ? 'true' : 'false',
  },
  {
    actionType: actions.setTimeFrame,
    name: QUERY_PARAM_NAMES.TIME_FRAME,
    getValue: action =>
      is.object(action.payload)
        ?
          dateFns.format(action.payload.start, ISO_DATE_FORMAT)
          + TIME_FRAME_SEPARATOR
          + dateFns.format(action.payload.end, ISO_DATE_FORMAT)
        : action.payload,
  },
  {
    actionType: actions.setActivePublicationType,
    name: QUERY_PARAM_NAMES.PUB_TYPE,
  },
  {
    actionType: actions.setAppliedFilterKey,
    name: QUERY_PARAM_NAMES.APPLIED_FILTER_KEY,
  },
  {
    actionType: actions.setTermFrequencyKey,
    name: QUERY_PARAM_NAMES.TERM_FREQUENCY_KEY,
  },
  {
    actionType: actions.setUpcomingEvents,
    name: QUERY_PARAM_NAMES.UPCOMING_EVENTS,
    getValue: action => action.payload ? 'true' : 'false',
  },
  {
    actionType: actions.setQuery,
    name: QUERY_PARAM_NAMES.QUERY,
    getValue: action => is.string(action.payload) ? action.payload : undefined,
  },
  {
    actionType: actions.setResultsPerPage,
    name: QUERY_PARAM_NAMES.RESULTS_PER_PAGE,
  },
]

/**
 * Stores the task responsible for fetching search results, if one is in
 * progress.
 * TODO: Is there a better way to do this than a global variable?
 */
let loadResultsTask = null

/**
 * Stores the time it took to load the HTML page, before any async requests were
 * fired. This is used to update the total load time at the bottom of the page.
 */
let initialPageLoadTime = null


function getLocalStorageItem(key, defaultValue = false) {
  const rawValue = localStorage.getItem(key)
  if (rawValue === null) {
    return defaultValue
  }
  try {
    return JSON.parse(rawValue)
  }
  catch (error) {
    return defaultValue
  }
}
function setLocalStorageItem(key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}


function getSourceFeedId(state) {
  const feedId = selectors.getFeedId(state)
  if (feedId) {
    return feedId
  }
  const search = selectors.getSearch(state)
  return search.aboutFeeds[0].id
}


function* saveDocumentEntities(documents, {searchId}) {
  const entities = {}
  const unnestGroupedDocuments = document => {
    return [
      document,
      ...(document.groupDocs || [])
        .map(groupDoc => ({...groupDoc, parentId: document.id})),
    ]
  }
  const transformDocument = document => ({
    ...document,
    searchId: searchId,
    feedId: document.feed && document.feed.id,
  })
  const flattenedDocuments = pipe(
    map(unnestGroupedDocuments),
    flatten,
  )(documents)
  entities[Document.entityKey] = pipe(
    map(document => [document.id, transformDocument(document)]),
    fromPairs,
  )(flattenedDocuments)
  entities[Feed.entityKey] = pipe(
    filter(document => document.feed),
    map(document => [document.feed.id, document.feed]),
    fromPairs,
  )(flattenedDocuments)
  yield put(entitiesActions.update(entities))
  return entities
}


export function* saveSearchDataEntities(searchData) {
  let searchEntity = {
    // We specify the fields here so that we don't put irrelevant data into
    // the entity store.
    // TODO: Figure out a better way to limit fields than by hard-coding
    // them here.
    id: searchData.id || -1, // -1 = unsaved search
    name: searchData.name,
    isFirmLibrary: searchData.isFirmLibrary,
    isFirmLibraryChild: searchData.isFirmLibraryChild,
    slug: searchData.slug,
    signalsEnabled: searchData.isInsightsEnabled,
    filterGroups: searchData.filterGroups,
    scope: searchData.scope,
    category: searchData.category,
    query: searchData.query,
    userFriendlyQuery: searchData.userFriendlyQuery,
    queryType: searchData.queryType,
    relevancyLevel: searchData.relevancyLevel,
    solrSearchField: searchData.solrSearchField,
    groupingLevel: searchData.groupingLevel,
    languageFilters: searchData.languageFilters,
    selectedLanguageIds: searchData.selectedLanguageIds,
    shouldShowRelevanceFilter: searchData.shouldShowRelevanceFilter,
    mainParentId: searchData.mainParent && searchData.mainParent.id,
    dnbData: searchData.dnbData,
    noticeConfig: searchData.noticeConfig,
    adminEditUrl: searchData.adminEditUrl,
    queryComponents: searchData.queryComponents,
    fullQuery: searchData.results && searchData.results.query,
    fqParams: searchData.results && searchData.results.fqParams,
    resultsOrder: searchData.resultsOrder,
    duration: searchData.duration,
    absoluteUrl: searchData.absoluteUrl,
    validationErrorMessage: searchData.validationErrorMessage,
    termFrequencyFilters: searchData.termFrequencyFilters,
    savedSearchArticlesCount: searchData.savedSearchArticlesCount
  }
  // Don't update any values that are undefined. Otherwise they may overwrite
  // existing values if the results response returns before the search data.
  searchEntity = filter(val => is.defined(val), searchEntity)
  const entities = {
    [SavedSearch.entityKey]: {
      [searchData.id]: searchEntity,
    },
    [User.entityKey]: {},
  }
  if (searchData.termFrequencyFilters && searchData.termFrequencyFilters.length > 0) {
    let pendingTermFrequencyFilters =
      (yield select(advancedSearchSelectors.pendingTermFrequencyFilters)).slice()
    const termFrequencyKey = yield select(selectors.getTermFrequencyKey)
    if (pendingTermFrequencyFilters.length < 1 && !termFrequencyKey) {
      searchData.termFrequencyFilters.map((tff) => (
        pendingTermFrequencyFilters.push(tff)
      ))
      yield put(advancedSearchActions.setPendingTermFrequencyFilters(pendingTermFrequencyFilters))
      yield put(actions.setSaveData({pendingTermFrequencyFilters: pendingTermFrequencyFilters}))
    }
  }
  if (searchData.mainParent) {
    entities[SavedSearch.entityKey][searchData.mainParent.id]
      = searchData.mainParent
  }
  if (searchData.parentSearches) {
    for (const parentSearch of searchData.parentSearches) {
      parentSearch.ownerId = parentSearch.owner.id
      entities[SavedSearch.entityKey][parentSearch.id] = parentSearch
      entities[User.entityKey][parentSearch.owner.id] = parentSearch.owner
    }
  }
  if (searchData.excludedFeeds || searchData.aboutSources) {
    entities[Feed.entityKey] = {}
  }
  if (searchData.excludedFeeds) {
    for (const feed of searchData.excludedFeeds) {
      entities[Feed.entityKey][feed.id] = feed
    }
    searchEntity.excludedFeedsIds = searchData.excludedFeeds.map(prop('id'))
  }
  if (searchData.aboutSources) {
    for (const feed of searchData.aboutSources) {
      entities[Feed.entityKey][feed.id] = feed
    }
    searchEntity.aboutFeedsIds = searchData.aboutSources.map(prop('id'))
  }
  yield put(entitiesActions.update(entities))
  if (searchData.results && searchData.results.documents) {
    yield* saveDocumentEntities(
      searchData.results.documents,
      {searchId: searchData.id},
    )
  }
}


/**
 * Takes an array of documents from the GraphQL API and turns them into an
 * object mapping top-level document IDs to an array of their grouped documents.
 */
export function getGroupedDocumentIds(documents) {
  return pipe(
    filter(document => document.groupDocs.length),
    map(document =>
      [document.id, document.groupDocs.map(prop('id'))],
    ),
    fromPairs,
  )(documents)
}


/**
 * Gets the params passed to the GraphQL API based on the current page state.
 */
function* getSearchApiParams({page = 1} = {}) {
  const searchId = yield select(selectors.getSearchId)
  const feedId = yield select(selectors.getFeedId)
  const query = yield select(selectors.getQuery)
  const queryType = yield select(selectors.getQueryType)
  const name = yield select(selectors.getSearchName)
  const insightsArticlesFirst = yield select(selectors.getInsightsArticlesFirst)
  const compareId = yield select(selectors.getCompareId)
  const activePublicationTab = yield select(selectors.getActivePublicationTab)
  const activePublicationType = yield select(selectors.getActivePublicationType)
  const relevancyLevel = yield select(selectors.getRelevancyLevel)
  const groupingLevel = yield select(selectors.getGroupingLevel)
  const selectedLanguageIds = yield select(selectors.getSelectedLanguageIds)
  const appliedFilterKey = yield select(selectors.getAppliedFilterKey)
  const termFrequencyKey = yield select(selectors.getTermFrequencyKey)
  const sortOption = yield select(selectors.getSortOption)
  const timeFrame = yield select(selectors.getTimeFrame)
  const resultsPerPage = yield select(selectors.getResultsPerPage)
  let startDate, endDate

  if (is.object(timeFrame)) {
    // It's a date range.
    startDate = timeFrame.start
    endDate = timeFrame.end
  }
  else if (is.string(timeFrame)) {
    startDate = utils.dateRangeFromTimeFrameString(timeFrame).start
  }

  const params = {
    startDate: startDate && dateFns.format(startDate, ISO_DATE_FORMAT),
    endDate: endDate && dateFns.format(endDate, ISO_DATE_FORMAT),
    insightsArticlesFirst,
    page,
    appliedFilterKey,
    termFrequencyKey,
  }

  if (searchId) {
    params.searchId = searchId
  }
  else if (feedId) {
    params.feedId = feedId
  }
  if (query !== null) {
    params.query = query
    if (queryType) {
      params.queryType = queryType
    }
    if (name) {
      params.name = name
    }
  }
  if (compareId) {
    params.compareId = compareId
  }
  if (sortOption) {
    params.orderBy = sortOption
  }
  if (relevancyLevel) {
    params.relevancyLevel = relevancyLevel
  }
  if (groupingLevel) {
    params.groupingLevel = groupingLevel
  }
  if (resultsPerPage) {
    params.resultsPerPage = resultsPerPage
  }
  if (selectedLanguageIds) {
    params.selectedLanguageIds = selectedLanguageIds
  }
  if (activePublicationType) {
    params.publicationTypes = [activePublicationType]
  }
  else if (activePublicationTab) {
    params.publicationTypes = Feed.PUBLICATION_TYPES_BY_CATEGORY[activePublicationTab]
  }
  if (activePublicationTab === 'charts') {
    params.includeResultDocuments = false
    params.includeStockData = true
  }
  if (activePublicationTab === Feed.PUBLICATION_TYPE_CATEGORIES.EVENTS) {
    params.upcomingEvents = yield select(selectors.getUpcomingEvents)
  }
  return params
}


function* setDocumentSearchName(name) {
  // Keep the last part of the title.
  const tail = last(document.title.split(' | '))
  if (name) {
    document.title = `${name} News | ${tail}`
  }
  else {
    document.title = `Search Results | ${tail}`
  }
}


function* fetchSearchData(
  {
    includeAvailableFilters = false,
    includeFirmSourceLabels = false,
    includeHelpText = false,
  } = {},
) {
  // Load results in the background so that search data can load more quickly.
  yield* loadResults({includeFilters: false})

  const apiParams = yield* getSearchApiParams()
  const data = yield api.fetchSearchData({
    ...apiParams,
    includeAvailableFilterCategories: includeAvailableFilters,
    includeQuickFilterOptions: includeAvailableFilters,
    includeFirmSourceLabels: includeFirmSourceLabels,
    includeHelpText,
  })

  let pendingTermFrequencyFilters =
      (yield select(advancedSearchSelectors.pendingTermFrequencyFilters)).slice()
  if (!data.search.id && data.search.termFrequencyFilters.length > 0 &&
    pendingTermFrequencyFilters.length === 0) {
    data.search.termFrequencyFilters.map((tff) => (
      pendingTermFrequencyFilters.push(tff)
    ))
    yield put(advancedSearchActions.setPendingTermFrequencyFilters(pendingTermFrequencyFilters))
    yield put(actions.setSaveData({pendingTermFrequencyFilters: pendingTermFrequencyFilters}))
  }

  const {search, availableFilterCategories, helpQuestions: questions} = data
  const selectedLanguageIds = yield select(selectors.getSelectedLanguageIds)
  if (search.languageFilters && search.languageFilters.length > 0) {
    if (selectedLanguageIds === null) {
      yield put(actions.setLanguageFilters(search.languageFilters))
    } else {
      const languageFilterIdArray = selectedLanguageIds.split(',').map(id => parseInt(id))
      const newLanguageFilters = []
      search.languageFilters.forEach(lang => {
        newLanguageFilters.push({
          id: lang.id,
          name: lang.name,
          exclude: !languageFilterIdArray.includes(lang.id)
        })
      })
      yield put(actions.setLanguageFilters(newLanguageFilters))
    }
  }

  yield put(actions.consolidateFilters(getFiltersForSearch(search)))

  if (includeAvailableFilters) {
    const quickFilterOptions = map(
      options => options.map(({id, label}) => [id, label]),
      data.quickFilterOptions,
    )
    yield put(globalActions.setQuickFilterOptions(quickFilterOptions))
    const searches = {}
    const users = {}
    availableFilterCategories.forEach(fc => {
      fc.searches.forEach(search => {
        search.ownerId = search.owner.id
        searches[search.id] = {...search}
        users[search.owner.id] = {...search.owner}
        search.children.forEach(search => {
          search.ownerId = search.owner.id
          searches[search.id] = {...search}
          users[search.owner.id] = {...search.owner}
        })
      })
    })
    const availableFiltersByCategory = filtersByCategory(availableFilterCategories)
    yield put(globalActions.setAvailableFilters(availableFiltersByCategory))
    const response = yield api.fetchFirmLibrarySearches()
    response.savedSearches.forEach(search => {
      search.isFirmLibrary = true
      search.ownerId = search.owner.id
      searches[search.id] = {...search}
      users[search.owner.id] = {...search.owner}
    })
    yield put(entitiesActions.update({searches, users}))
  }

  if (includeFirmSourceLabels) {
    yield put(globalActions.setFirmSourceLabels(data.firmSourceLabels))
  }

  if (includeHelpText) {
    yield put(helpQuestions.actions.addHelpQuestions(questions))
  }

  yield* saveSearchDataEntities({...search, id: search.id || -1})
  yield* setDocumentSearchName(search.id ? search.name : null)
  yield put(actions.setIsLoading(false))
}


function* maybeFetchTrending() {
  const isTrendingOpen = yield select(selectors.getIsTrendingOpen)
  const isRefreshingResults = yield select(selectors.getIsRefreshingResults)
  const trendingData = yield select(selectors.getTrendingData)
  if (!isTrendingOpen || isRefreshingResults || trendingData) return

  let id = yield select(selectors.getSearchId)
  let isFeed = false
  if (!id) {
    id = yield select(selectors.getFeedId)
    isFeed = true
  }
  const search = yield select(selectors.getSearch)
  const topLevelDocuments = yield select(selectors.getTopLevelDocuments)
  const activePublicationTab = yield select(selectors.getActivePublicationTab)
  const activePublicationType = yield select(selectors.getActivePublicationType)
  const filterKey = yield select(selectors.getAppliedFilterKey)
  const frequencyKey = yield select(selectors.getTermFrequencyKey)
  const relevancyLevel = yield select(selectors.getRelevancyLevel)
  const groupingLevel = yield select(selectors.getGroupingLevel)

  if (
    [
      Feed.PUBLICATION_TYPE_CATEGORIES.TWITTER,
      'charts',
      'esg',
    ].includes(activePublicationTab)
    || topLevelDocuments.length <= 3
  ) {
    yield put(actions.setTrendingData({}))
  }
  else {
    yield put(actions.setTrendingData(null))

    const trendingParams = {}
    if (id) {
      trendingParams.id = id
      trendingParams.slug = isFeed ? 'feed' : search.slug
    }
    else {
      trendingParams.query = search.query
      trendingParams.queryType = search.queryType
    }
    if (filterKey) {
      trendingParams.filterKey = filterKey
    }
    if (frequencyKey) {
      trendingParams.frequencyKey = frequencyKey
    }
    if (relevancyLevel) {
      trendingParams.relevancyLevel = relevancyLevel
    }
    if (groupingLevel) {
      trendingParams.groupingLevel = groupingLevel
    }
    if (activePublicationTab) {
      trendingParams.publicationTab = activePublicationTab
    }
    if (activePublicationType) {
      trendingParams.publicationType = activePublicationType
    }

    let trendingData = yield api.fetchTrending(trendingParams)
    const hasData = Object.values(trendingData).some(items => items.length)
    if (!hasData) {
      trendingData = yield api.fetchTrending({
        ...trendingParams,
        useCache: false,
      })
    }
    yield put(actions.setTrendingData(trendingData))
  }
}


function* handleFetchUserEmailSettings(action) {
  const userId = yield select(globalSelectors.getCurrentUserId)
  let response = null
  try {
    response = yield api.fetchUserEmailSettings(userId)
  } catch (error) {
    yield* handleSagaError(error)
    return
  }
  // convert email settings to the format used by search.noticeConfig
  const userDefaultEmailSettings = response.user.baseEmailSettings.categoryDefaults.reduce((result, currentValue) => {
    result[currentValue.category] = {
      frequency: currentValue.noticeFreq,
      maxItems: {
        allFilings: currentValue.filingMaxItems,
        defaultPubTypes: currentValue.noticeMaxStories,
        event: currentValue.eventMaxItems,
        twitter: currentValue.twitterMaxItems,
      }
    }
    return result
  }, {})
  yield put(actions.setUserDefaultEmailSettings(userDefaultEmailSettings))
}


function* handleFetchCachedLargerTimeFrames(action) {
  const searchId = action.payload
  if (!searchId){return}
  try {
    const response = yield api.fetchCachedLargerTimeFrames({searchId})
    yield put(actions.setCachedLargerTimeFrames(
      changeCaseObject.camelCase(response.data)
    ))
  } catch (error) {
    yield* handleSagaError(error)
    return
  }
}


function* locationChanged(action) {
  const {previous} = yield select(routing.selectors.getRoutingState)

  // Make sure the relevance setting gets synced with the edit modal.
  if (
    (previous.query && previous.query[QUERY_PARAM_NAMES.RELEVANCE])
    !== (action.payload.query && action.payload.query[QUERY_PARAM_NAMES.RELEVANCE])
  ) {
    let relevancyLevel = yield select(selectors.getRelevancyLevel)
    if (!relevancyLevel) {
      const search = yield select(selectors.getSearch)
      relevancyLevel = search.relevancyLevel
    }
    if (relevancyLevel) {
      yield put(actions.setSaveData({solrSearchField: RELEVANCE_LOOKUP[relevancyLevel]}))
    }
  }

  // Make sure the grouping setting gets synced with the edit modal.
  if (
    (previous.query && previous.query[QUERY_PARAM_NAMES.GROUPING])
    !== (action.payload.query && action.payload.query[QUERY_PARAM_NAMES.GROUPING])
  ) {
    let groupingLevel = yield select(selectors.getGroupingLevel)
    if (!groupingLevel) {
      const search = yield select(selectors.getSearch)
      if (search.groupingLevel) {
        groupingLevel = search.groupingLevel
      }
    }
    if (groupingLevel) {
      yield put(actions.setSaveData({groupingLevel}))
    }
  }

  if (
    previous.pathname !== action.payload.pathname
    || previous.query !== action.payload.query
  ) {
    const search = yield select(selectors.getSearch)
    let name = null
    if (search && search.name) {
      name = search.name
    }
    yield* setDocumentSearchName(name)
  }
  yield* fetchSearchData()
}


/**
 * Batches actions that set query parameters so that we can set multiple all at
 * once instead of pushing a new location once per query parameter.
 */
function* handleSetQueryParam(pendingQueryParams, action) {
  const {
    name,
    getValue = action => action.payload,
  } = QUERY_PARAM_ACTIONS.find(({actionType}) =>
    actionType.toString() === action.type
  )
  let value = getValue(action)
  if (typeof value === 'undefined') {
    return;
  }
  if (action.type === 'search-results/SET_SELECTED_LANGUAGE_IDS' && value) {
    yield put(actions.setLanguageFilters(value))
    value = value.filter(lang => !lang.exclude).map(lang => lang.id).join(',')
  }
  pendingQueryParams.push({name, value})
  // Delay to allow multiple calls to get batched.
  yield delayImmediate()
  const query = {}
  for (const {name, value} of pendingQueryParams) {
    query[name] = value
  }
  yield put(routing.actions.pushLocation({query, persistQuery: true}))
  pendingQueryParams.splice(0)
}


function* getCompareResults(args) {
  let {apiParams, name} = args
  const {isPagination, publicationCounts, delayTime} = args
  let compareType = null
  if (apiParams.compareId) {
    yield delay(delayTime)
    const search = yield select(selectors.getSearch)
    const compareId = parseInt(apiParams.compareId)
    if (apiParams.feedId && apiParams.feedId !== compareId) {
      apiParams.feedId = compareId
      apiParams.excludeFirmFilter = true
      compareType = 'Feed'
    } else if (apiParams.searchId && apiParams.searchId !== compareId) {
      apiParams.searchId = compareId
      compareType = 'Saved Search'
    }
    let compareSearch = null
    let didError = false
    try {
      compareSearch = yield api.fetchSearchResults(apiParams)
    } catch (error) {
      didError = true
      error.message = `Invalid ${compareType} Compare ID ${compareId}`
      yield* handleSagaError(error)
    }
    if (!didError) {
      const searchId = apiParams.searchId
      const comparePublicationCounts = compareSearch.search.results.publicationCounts
      yield* saveDocumentEntities(compareSearch.search.results.documents, {searchId})
      if (isPagination) {
        // Append the new results to the existing ones.
        const compareTopLevelDocumentIds = yield select(selectors.getCompareTopLevelDocumentIds)
        const compareGroupedDocumentIds = yield select(selectors.getCompareGroupedDocumentIds)
        const mergedCompareTopLevelDocumentIds = [
          ...compareTopLevelDocumentIds,
          ...compareSearch.search.results.documents.map(prop('id')),
        ]
        const mergedCompareGroupedDocumentIds = {
          ...compareGroupedDocumentIds,
          ...getGroupedDocumentIds(compareSearch.search.results.documents),
        }
        yield put(actions.setCompareSearchData({
          compareTopLevelDocumentIds: mergedCompareTopLevelDocumentIds,
          compareGroupedDocumentIds: mergedCompareGroupedDocumentIds,
        }))
      } else {
        yield put(
          actions.setCompareSearchData({
            compareTopLevelDocumentIds: compareSearch.search.results.documents.map(prop('id')),
            compareGroupedDocumentIds: getGroupedDocumentIds(compareSearch.search.results.documents),
            comparePublicationCounts: comparePublicationCounts,
          })
        )
      }
      const compareCountIsGreater =
        yield* isCompareCountGreater(publicationCounts, comparePublicationCounts, search)

      name = name && compareCountIsGreater
        ? `${compareSearch.search.name} <<COMP>> ${name}`
        : name
          ? `${name} <<COMP>> ${compareSearch.search.name}`
          : name
    }
  }
  return name
}


function* loadResults({includeFilters = true} = {}) {
  if (loadResultsTask) {
    yield cancel(loadResultsTask)
  }
  loadResultsTask = yield fork(function*() {
    const startTime = new Date
    const activePublicationTab = yield select(selectors.getActivePublicationTab)
    const searchId = (yield select(selectors.getSearchId)) || -1

    if (activePublicationTab === 'charts') {
      yield put(actions.setChartsAndTrendsData(null))
      const search = yield select(selectors.getSearch)
      const timeFrame = yield select(selectors.getTimeFrame)
      const cachedLargerTimeFrames = yield select(selectors.getCachedLargerTimeFrames)
      const requestOptions = {
        id: search.id,
        slug: search.slug,
      }
      if (timeFrame) {
        if (is.string(timeFrame)) {
          requestOptions.duration = timeFrame
        }
        else {
          const {start, end} = timeFrame
          requestOptions.startDate = dateFns.format(start, ISO_DATE_FORMAT)
          if (end) {
            requestOptions.endDate = dateFns.format(end, ISO_DATE_FORMAT)
          }
        }
      }

      const requests = {
        shareOfVoice: api.chartsAndTrends.fetchShareOfVoiceChartData(requestOptions),
      }

      if (!shouldDisableLineChart(
        timeFrame || search.duration || '2w',
        cachedLargerTimeFrames,
        [search.id]
      )){
        requests.timeline = api.chartsAndTrends.fetchTimelineChartData(requestOptions)
      }
      if (search.stockData) {
        requests.stockTimeline =
          api.chartsAndTrends.fetchStockTimelineChartData(requestOptions)
      }
      const responses = yield all(requests)
      yield put(
        actions.setChartsAndTrendsData({
          timeline: responses.timeline && responses.timeline.body,
          shareOfVoice: responses.shareOfVoice.body,
          stockTimeline: responses.stockTimeline && responses.stockTimeline.body,
        })
      )
    }
    else {
      yield put(actions.setIsRefreshingResults(true))
      yield put(actions.deselectAllDocuments())
      const apiParams = yield* getSearchApiParams()

      const query = apiParams.query || ''
      const match = query.match(/^ss:(\d+)$/)
      const intMatch = match && parseInt(match[1])
      const esgSearchId = apiParams.searchId || intMatch

      // Get EsgData
      if (esgSearchId) {
        const esgResponse = yield api.fetchEsgData(esgSearchId)
        yield put(actions.setEsgData(esgResponse.esgData))
      // } else {
      //   console.log('no search id, not fetching esg data  -------------------------', apiParams)
      }


      // // This adds the ESG filter to the given search, I don't think
      // // This is necessary since we pull ESG data from insights.
      // if (esgResponse.esgData && esgResponse.esgData.companyScores) {
      //   const esgFilter = {
      //     filterField: 'text_all',
      //     isFreeText: false,
      //     searchId: ESG_GLOBAL_FILTER_ID,
      //   }
      //   const activeFilters = {filters: yield select(selectors.getActiveFilters)}
      //   const esgSearchFilter = activeFilters.filters
      //     .filter(f => f.searchId === ESG_GLOBAL_FILTER_ID && f.filterField === 'text_all')
      //   const esgActiveFilterApplied = yield select(selectors.getEsgActiveFilterApplied)
      //
      //   if (activePublicationTab === 'esg') {
      //     if (esgSearchFilter.length < 1 && !esgActiveFilterApplied) {
      //       yield put(actions.addFilters([esgFilter]))
      //       yield put(actions.setEsgActiveFilterApplied(true))
      //     }
      //   } else {
      //     if (esgSearchFilter.length === 1 && esgActiveFilterApplied) {
      //       yield put(actions.removeFilters([esgFilter]))
      //       yield put(actions.setEsgActiveFilterApplied(false))
      //     }
      //   }
      // }
      apiParams.includeFilters = includeFilters

      if (apiParams.compareId && apiParams.feedId) {
        apiParams.excludeFirmFilter = true
      }

      let {
        search: {name, filterGroups, results},
      } = yield api.fetchSearchResults(apiParams)

      name = yield* getCompareResults({
        apiParams: apiParams,
        isPagination: false,
        name: name,
        publicationCounts: results.publicationCounts,
        delayTime: 300,
      })

      // Update the search details in case they've changed.
      const updateData = {
        id: searchId,
        name,
        fullQuery: results.query,
        fqParams: results.fqParams,
      }
      if (includeFilters) {
        updateData.filterGroups = filterGroups
      }
      yield put(entitiesActions.updateByModel(SavedSearch, updateData))

      yield* saveDocumentEntities(results.documents, {searchId})
      yield put(
        actions.setSearchData({
          topLevelDocumentIds: results.documents.map(prop('id')),
          groupedDocumentIds: getGroupedDocumentIds(results.documents),
          publicationCounts: results.publicationCounts,
        })
      )

      yield put(actions.setLastPage(results.numPages))

      yield call(updateLoadTime, (new Date - startTime) / 1000)

      yield* maybeFetchTrending()
    }

    const shouldShowQueryComponents = yield select(selectors.getShouldShowQueryComponents)
    if (shouldShowQueryComponents) {
      yield call(fetchSearchQueryComponents)
    }

    loadResultsTask = null
  })
}


function* handleLoadMoreResults() {
  yield put(actions.setIsLoadingNextPage(true))
  const page = yield select(selectors.getCurrentPage)
  const searchId = (yield select(selectors.getSearchId)) || -1
  const topLevelDocumentIds = yield select(selectors.getTopLevelDocumentIds)
  const groupedDocumentIds = yield select(selectors.getGroupedDocumentIds)
  const apiParams = yield* getSearchApiParams({page})
  apiParams.topLevelDocumentIds = topLevelDocumentIds

  if (apiParams.compareId && apiParams.feedId) {
    apiParams.excludeFirmFilter = true
  }

  const {search: {results}} = yield api.fetchSearchResults(apiParams)

  yield* saveDocumentEntities(results.documents, {searchId})
  // Append the new results to the existing ones.
  const mergedTopLevelDocumentIds = [
    ...topLevelDocumentIds,
    ...results.documents.map(prop('id')),
  ]
  const mergedGroupedDocumentIds = {
    ...groupedDocumentIds,
    ...getGroupedDocumentIds(results.documents),
  }

  yield* getCompareResults({
    apiParams: apiParams,
    isPagination: true,
    name: null,
    publicationCounts: yield select(selectors.getPublicationCounts),
    delayTime: 300
  })

  yield put(actions.setSearchData({
    topLevelDocumentIds: mergedTopLevelDocumentIds,
    groupedDocumentIds: mergedGroupedDocumentIds,
  }))

  yield put(actions.setLastPage(results.numPages))

}


function* createAppliedFilter() {
  yield put(actions.setIsRefreshingResults(true))
  const search = yield select(selectors.getSearch)
  const params = {filters: yield select(selectors.getActiveFilters)}
  if (utils.isSaved(search)) {
    params.searchId = search.id
  }
  const key = yield api.applyFilters(params)
  yield put(actions.setAppliedFilterKey(key))
  yield put(actions.setSaveData(params))
  yield put(actions.setQueryComponentState({}))
}


function* createTermFrequencyFilter() {
  yield put(actions.setIsRefreshingResults(true))
  const search = yield select(selectors.getSearch)
  const pendingTermFrequencyFilters =
      yield select(advancedSearchSelectors.pendingTermFrequencyFilters)
  const termFrequencyKey =
    yield advancedSearchApi.getTermFrequencyFilterKey(pendingTermFrequencyFilters)
  let params = {pendingTermFrequencyFilters: pendingTermFrequencyFilters}
  if (utils.isSaved(search)) {
    params.searchId = search.id
  }

  yield put(actions.setTermFrequencyKey(termFrequencyKey.key))
  yield put(actions.setSaveData(params))
}


function* hideEditSearchFiltersModal() {
  const params = {filters: yield select(selectors.getActiveFilters)}
  yield put(actions.setSaveData(params))
  yield put(actions.setQueryComponentState({}))
}


function* handlePdfExport() {
  const documentIds = yield select(selectors.getSelectedDocumentIds)
  yield* exportPDF(documentIds)
}


function* handleDocxExport() {
  const documentIds = yield select(selectors.getSelectedDocumentIds)
  yield* exportDoc(documentIds)
}


function* handleExcelExport() {
  const documentIds = yield select(selectors.getSelectedDocumentIds)
  const search = yield select(selectors.getSearch)
  const name = search.name
  const category = search.category
  yield* exportExcel(documentIds, name, category)
}


function* handleEmailExport() {
  const documentIds = yield select(selectors.getSelectedDocumentIds)
  yield* exportEmail(documentIds)
}


function* handleDeleteSelectedDocuments() {
  const documentIds = yield select(selectors.getSelectedDocumentIds)
  yield put(actions.hideDeleteModal())
  yield put(actions.setIsRefreshingResults(true))
  let didError = false
  try {
    yield call(api.deleteDocuments, documentIds)
  } catch (error) {
    didError = true
    yield* handleSagaError(error)
  }
  if (!didError) {
    yield put(
      notificationActions.showNotification({
        type: 'success',
        message: "Article marked for deletion. It may take several seconds.",
        duration: 8,
      }),
    )
  }
  yield* loadResults()
}


function* handleSaveSearchSettings(action) {
  const search = yield select(selectors.getSearch)
  const params = {...action.payload}
  let message
  try {
    let response
    if (action.payload.id && action.payload.id !== -1) {
      response = yield api.saveSearch(params)
      message = 'Search settings updated successfully.'
    }
    else {
      const {query, queryType} = yield select(selectors.getSearch)
      const appliedFilterKey = yield select(selectors.getAppliedFilterKey)
      const termFrequencyKey = yield select(selectors.getTermFrequencyKey)
      response = yield api.saveSearch({
        ...params,
        query,
        queryType,
        appliedFilterKey,
        termFrequencyKey,
      })
      message = 'New search saved successfully.'
    }
    const entities = {
      searches: {[response.search.id]: response.search},
    }
    yield put(entitiesActions.update(entities))
    yield put(actions.saveSearchSettingsComplete())
    if (search.id !== response.search.id) {
      yield put(globalActions.addProfileSearchIds([response.search.id]))
    }
    if (
      search.id !== response.search.id
      || search.category !== response.search.category
      || search.slug !== response.search.slug
    ) {
      // If the category or slug has changed, we also want to trigger a redirect
      // to the new URL.
      yield put(actions.setSearchId(response.search.id))
    }
  } catch (error) {
    yield* handleSagaError(error)
    return
  }
  yield put(
    notificationActions.showNotification({
      type: 'success',
      message,
      duration: 8,
    }),
  )
}


function* handleUpdateSearch(action) {
  const {
    searchId,
    feedId,
    userId,
    isMakeChild,
    isMakeCopy,
    name,
    isFirmLibrary,
    scope,
    category,
    queryType,
    query,
    filters,
    noticeConfig,
    solrSearchField,
    duration,
    resultsOrder,
    termFrequencyFilters,
    selectedLanguageIds,
    groupingLevel,
  } = action.payload
  const params = {
    id: searchId || undefined,
    feedId,
    userId,
    isMakeChild,
    isMakeCopy,
    name,
    isFirmLibrary,
    scope,
    category,
    queryType,
    searchPhrase: query,
    filters,
    noticeConfig,
    solrSearchField,
    duration,
    resultsOrder,
    termFrequencyFilters,
    groupingLevel,
  }
  const appliedFilterKey = yield select(selectors.getAppliedFilterKey)
  if (appliedFilterKey) {
    params.appliedFilterKey = appliedFilterKey
  }
  const termFrequencyKey = yield select(selectors.getTermFrequencyKey)
  if (termFrequencyKey) {
    params.termFrequencyKey = termFrequencyKey
  }
  if (selectedLanguageIds) {
    params.selectedLanguageIds = selectedLanguageIds.split(',').map(id => parseInt(id))
  }
  yield put(actions.setIsSaving(true))
  try {
    const response = yield(api.updateSearch(params))
    const currentId = yield select(selectors.getSearchId)
    // even though an entity update is triggered after this, this is needed here before these other actions.
    yield put(entitiesActions.updateByModel(SavedSearch, response.search))
    yield put(actions.resetSaveData())
    yield put(actions.init({url: response.search.absoluteUrl, isLoading: false}))
    yield put(actions.setSearchId(response.search.id))
    let message
    if (currentId !== response.search.id) {
      yield put(globalActions.addProfileSearchIds([response.search.id]))
      message = 'New search saved successfully.'
    }
    else {
      message = 'Search updated successfully.'
    }
    yield put(
      notificationActions.showNotification({
        type: 'success',
        message,
      })
    )
  } catch(error) {
    yield* handleSagaError(error)
  }
  yield put(actions.setIsSaving(false))
}


function* handleAddToTrustedSources() {
  const feedId = yield select(getSourceFeedId)
  yield put(actions.setIsChangingTrustedState(true))
  const searchData = yield api.addFeedToTrustedSources(feedId)
  yield put(entitiesActions.updateByModel(SavedSearch, searchData))

  const search = yield select(selectors.getSearch)
  yield put(
    notificationActions.showNotification({
      type: 'success',
      message: `Added ${search.name} to your trusted sources.`,
    })
  )

  yield put(globalActions.addProfileSearchIds([searchData.id]))
  yield put(actions.setSearchId(searchData.id))
}


function* handleRemoveFromTrustedSources() {
  const feedId = yield select(getSourceFeedId)
  const searchId = yield select(selectors.getSearchId)
  yield put(actions.setIsChangingTrustedState(true))
  try {
    if (utils.isFeedPage()) {
      yield api.removeFeedFromTrustedSources(feedId)
    } else {
      yield api.deleteSearch(searchId)
    }
  } catch (error) {
    yield* handleSagaError(error)
    yield put(actions.setIsChangingTrustedState(false))
    return
  }

  const search = yield select(selectors.getSearch)
  yield put(
    notificationActions.showNotification({
      type: 'success',
      message: `Removed ${search.name} from your trusted sources.`,
    })
  )

  if (utils.isFeedPage()) {
    yield put(
      entitiesActions.updateByModel(Feed, {id: feedId, isTrusted: false}),
    )
    yield put(actions.setIsChangingTrustedState(false))
  }
  else {
    yield put(actions.setFeedId(feedId))
  }
}


function* handleSetSearchId(action) {
  const newSearchId = action.payload
  const search = yield select(state => {
    const entities = entitiesSelectors.getEntities(state)
    const orm = Orm.withEntities(entities)
    return orm.getById(SavedSearch, newSearchId)
  })
  let category = search.category
  if (category === 'trusted-uncategorized') {
    category = 'trusted-sources'
  }
  else if (category === 'firm') {
    category = 'competitor'
  }
  yield put(
    routing.actions.pushLocation(
      urls.tier3Saved({
        id: newSearchId,
        category,
        slug: search.slug,
      }),
    ),
  )
}


function* handleSetFeedId(action) {
  const feedId = action.payload
  const feedUrl = urls.feed(feedId)
  yield put(routing.actions.pushLocation(feedUrl))
}


function getInitialLoadTime() {
  try {
    const el = document.querySelector('#mz-page-stats span:first-of-type')
    return parseFloat(el.innerText.replace('s', ''))
  } catch(error) {} // Ignore errors since it's not vital
}


function updateLoadTime(time) {
  if (!initialPageLoadTime) return
  try {
    const el = document.querySelector('#mz-page-stats span:first-of-type')
    const loadTime = initialPageLoadTime + time
    el.innerText = `${loadTime.toFixed(2)}s`
  } catch(error) {} // Ignore errors since it's not vital
}


/**
 * This is a special case of `setQueryParam` because setting the tab also needs
 * to reset the publication type.
 */
function* handleSetActivePublicationTab(action) {
  const query = {
    [QUERY_PARAM_NAMES.TAB]: action.payload,
    [QUERY_PARAM_NAMES.PUB_TYPE]: undefined,
  }
  yield put(routing.actions.pushLocation({query, persistQuery: true}))
}

function* isCompareCountGreater(publicationCounts, comparePublicationCounts, search) {
  const activePublicationTab = yield select(selectors.getActivePublicationTab)
  const activePublicationType = yield select(selectors.getActivePublicationType)
  const {greaterCount, compareCountIsGreater} = utils.resultCountForActivePublicationTypes({
    activePublicationType, activePublicationTab, search, publicationCounts,
    comparePublicationCounts
  })
  yield put(actions.setCompareCountIsGreater(compareCountIsGreater))
  return compareCountIsGreater
}

function* handleToggleShowQueryComponents(action) {
  const shouldShowQueryComponents = yield select(selectors.getShouldShowQueryComponents)
  if (shouldShowQueryComponents) {
    yield call(fetchSearchQueryComponents)
  } else {
    const search = yield select(selectors.getSearch)
    yield put(
      entitiesActions.updateByModel(
        SavedSearch,
        {id: search.id, queryComponents: null},
      )
    )
  }
}

function* fetchSearchQueryComponents() {
  const search = yield select(selectors.getSearch)
  const feedId = (yield select(selectors.getFeedId)) || undefined
  const appliedFilterKey = (yield select(selectors.getAppliedFilterKey)) || undefined
  const termFrequencyKey = (yield select(selectors.getTermFrequencyKey)) || undefined
  const queryComponents = yield call(
    api.fetchSearchQueryComponents,
    {
      id: utils.isSaved(search) ? search.id : undefined,
      feedId,
      query: search.query || undefined,
      queryType: search.queryType,
      appliedFilterKey,
      termFrequencyKey,
    },
  )
  yield put(
    entitiesActions.updateByModel(
      SavedSearch,
      {id: search.id, queryComponents},
    )
  )
}

function* handleToggleShowFullQuery(action) {
  const shouldShowFullQuery = yield select(selectors.getShouldShowFullQuery)
  if (shouldShowFullQuery) {
    yield call(fetchSearchFullQuery)
  } else {
    const search = yield select(selectors.getSearch)
    yield put(
      entitiesActions.updateByModel(
        SavedSearch,
        {id: search.id, fullQuery: null},
      )
    )
  }
}

function* fetchSearchFullQuery() {
  const search = yield select(selectors.getSearch)
  const feedId = (yield select(selectors.getFeedId)) || undefined
  const appliedFilterKey = (yield select(selectors.getAppliedFilterKey)) || undefined
  const termFrequencyKey = (yield select(selectors.getTermFrequencyKey)) || undefined
  const fullQuery = yield call(
    api.fetchSearchFullQuery,
    {
      id: utils.isSaved(search) ? search.id : undefined,
      feedId,
      query: search.query || undefined,
      queryType: search.queryType,
      appliedFilterKey,
      termFrequencyKey,
    },
  )
  yield put(
    entitiesActions.updateByModel(
      SavedSearch,
      {id: search.id, fullQuery},
    )
  )
}


function* handleShowDnbModal() {
  const search = yield select(selectors.getSearch)
  yield put(
    dnb.actions.showDnbModal({
      searchId: utils.isSaved(search) ? search.id : null,
      searchQuery: search.query,
      moreInfoContact: [
        search.dnbData.moreInfoContact.name,
        search.dnbData.moreInfoContact.email,
      ],
    })
  )
}


function* handleSetIsFiltersBarOpen(action) {
  yield call(setLocalStorageItem, 'filters-open', action.payload)
}

function* handleSetIsTrendingOpen(action) {
  yield call(setLocalStorageItem, 'trending-open', action.payload)
  if (action.payload) {
    yield* maybeFetchTrending()
  }
}

function* handleToggleFilterSectionDisplay(action) {
  // NOTE: The reducer runs before the saga, so the current state has already
  // been updated with the toggle.
  const collapsedFilterSections
    = yield select(selectors.getCollapsedFilterSections)
  yield call(
    setLocalStorageItem,
    'collapsed-filter-sections',
    collapsedFilterSections,
  )
}


/**
 * Saves any unsaved changes to the search. If the saved search does not yet
 * exist, creates a new one.
 */
function* saveSearch({
  name,
  category,
  scope,
  noticeConfig,
  asNew = false,
  asFirmLibrary = false,
  shareUserIds = [],
  shareDepartmentIds = [],
  shareTeamIds = [],
  shareFirmLocationIds = [],
} = {}) {
  const searchId = yield select(selectors.getSearchId)
  invariant(
    searchId || asNew || asFirmLibrary,
    'If the search is unsaved, you must pass `asNew: true` or `asFirmLibrary: true` to `saveSearch`.',
  )
  const search = yield select(selectors.getSearch)
  const feedId = yield select(selectors.getFeedId)
  const saveParams = {}
  if (searchId) {
    saveParams.id = searchId
  }
  if (feedId) {
    saveParams.feedId = feedId
  }
  if (name) {
    saveParams.name = name
  }
  if (category) {
    saveParams.category = category
  }
  if (scope) {
    saveParams.scope = scope
  }
  if (noticeConfig) {
    saveParams.noticeConfig = noticeConfig
  }
  const query = yield select(selectors.getQuery)
  if (query !== null) {
    saveParams.query = query
  }
  const queryType = yield select(selectors.getQueryType)
  if (queryType) {
    saveParams.queryType = queryType
  }
  const timeFrame = yield select(selectors.getTimeFrame)
  if (timeFrame && is.string(timeFrame)) {
    saveParams.duration = timeFrame
  }
  const sortOption = yield select(selectors.getSortOption)
  if (sortOption) {
    saveParams.resultsOrder = sortOption
  }
  const relevancyLevel = yield select(selectors.getRelevancyLevel)
  if (relevancyLevel) {
    saveParams.relevancyLevel = relevancyLevel
  }
  const groupingLevel = yield select(selectors.getGroupingLevel)
  if (groupingLevel) {
    saveParams.groupingLevel = groupingLevel
  }
  /**
   * this is a bit of a hack, but the idea is that if the filters modal is open we need to pass those filters instead
   * of the filter key, because the former will have everything and the latter will only have what existed when the
   * results were refreshed.
   */
  const isEditSearchFiltersModalOpen = yield select(selectors.getIsEditSearchFiltersModalOpen)
  if (isEditSearchFiltersModalOpen) {
    const saveData = yield select(selectors.getSaveData)
    saveParams.filters = saveData.filters
  } else {
    const appliedFilterKey = yield select(selectors.getAppliedFilterKey)
    if (appliedFilterKey) {
      saveParams.appliedFilterKey = appliedFilterKey
    }
  }
  const termFrequencyKey = yield select(selectors.getTermFrequencyKey)
  if (termFrequencyKey) {
    saveParams.termFrequencyKey = termFrequencyKey
  }
  const termFrequencyFilters = yield select(advancedSearchSelectors.pendingTermFrequencyFilters)
  if (termFrequencyFilters) {
    saveParams.termFrequencyFilters = termFrequencyFilters
  }
  const selectedLanguageIds = yield select(selectors.getSelectedLanguageIds)
  if (selectedLanguageIds) {
    saveParams.selectedLanguageIds = selectedLanguageIds
  }
  if (asFirmLibrary) {
    saveParams.isFirmLibrary = true
    saveParams.isChangeOwner = true
    saveParams.scope = SavedSearch.SCOPES.SHARED
  } else if (asNew) {
    if (search.isSource) {
      saveParams.isMakeChild = true
    }
    else if (search.isSaved) {
      saveParams.isMakeCopy = true
    }
  }

  yield put(actions.setIsSaving(true))
  yield put(actions.hideSaveIntoFirmLibraryModal())

  let response = null
  try {
    response = yield api.saveSearch(saveParams)
  }
  catch (error) {
    yield* handleSagaError(error)
    return
  }

  if (asFirmLibrary) {
    window.location.href = urls.adminSearches()
    return
  }

  for (let userId of shareUserIds) {
    const params = {
      id: response.search.id,
      userId,
      isMakeChild: scope !== SavedSearch.SCOPES.PERSONAL,
      isMakeCopy: scope === SavedSearch.SCOPES.PERSONAL,
      noticeConfig,
    }
    params.isMakeChild = !params.isMakeCopy

    // using separate calls so we can capture individual errors without affecting the other actions.
    yield call(createSharedSearch, params)
  }
  if (shareDepartmentIds.length > 0 || shareTeamIds.length > 0 || shareFirmLocationIds.length > 0) {
    const params = {
      searchIds: [response.search.id],
      departmentIds: shareDepartmentIds,
      teamIds: shareTeamIds,
      firmLocationIds: shareFirmLocationIds,
      isMakeChild: scope !== SavedSearch.SCOPES.PERSONAL,
      isMakeCopy: scope === SavedSearch.SCOPES.PERSONAL,
      noticeConfig,
    }
    yield call(shareSearches, params)
  }

  yield put(actions.resetUnsavedFilters())
  const entities = {
    searches: {[response.search.id]: response.search},
  }
  yield put(entitiesActions.update(entities))
  if (asNew) {
    yield put(globalActions.addProfileSearchIds([response.search.id]))
    yield put(actions.setSearchId(response.search.id))
  }
  else {
    yield put(
      notificationActions.showNotification({
        type: 'success',
        message: 'Your changes have been saved successfully.',
      }),
    )
    // Reset query params.
    if (saveParams.query !== null) {
      yield put(actions.setQuery(null))
    }
    if (saveParams.timeFrame) {
      yield put(actions.setTimeFrame(null))
    }
    if (saveParams.sortOption) {
      yield put(actions.setSortOption(null))
    }
    if (saveParams.relevancyLevel) {
      yield put(actions.setRelevancyLevel(null))
    }
    if (saveParams.groupingLevel) {
      yield put(actions.setGroupingLevel(null))
    }
    if (saveParams.selectedLanguageIds) {
      yield put(actions.setSelectedLanguageIds(null))
    }
    if (saveParams.appliedFilterKey) {
      yield put(actions.setAppliedFilterKey(null))
    }
    if (saveParams.resultsPerPage) {
      yield put(actions.setResultsPerPage(null))
    }
  }

  yield put(actions.setIsSaving(false))
}

function* shareSearches(params) {
  try {
    const response = yield reusableApi.shareSearches(params)
  } catch(error) {
    yield* handleSagaError(error)
  }
}


function* createSharedSearch(params) {
  let response = null
  try {
    response = yield api.saveSearch(params)
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleSaveSearch(action) {
  const search = yield select(selectors.getSearch)
  const asFirmLibrary = action.payload && action.payload.asFirmLibrary
  if (
    utils.isSaved(search)
    && !utils.isSource(search)
    && (!action.payload || !action.payload.asNew)
  ) {
    yield* saveSearch({asFirmLibrary})
  }
  else {
    yield put(actions.showNewSearchModal(asFirmLibrary))
  }
}


function* handleNewSaveSearch(action) {
  const {
    name,
    category,
    scope,
    noticeConfig,
    shareUserIds,
    shareDepartmentIds,
    shareTeamIds,
    shareFirmLocationIds,
  } = action.payload
  const newSearchData = yield select(selectors.getNewSearchModalData)
  const isFirmLibrary = newSearchData.isFirmLibrary
  yield* saveSearch({
    name, category, scope, noticeConfig, asNew: !isFirmLibrary, asFirmLibrary: isFirmLibrary, shareUserIds,
    shareDepartmentIds, shareTeamIds, shareFirmLocationIds,
  })
}


function* handleFetchAssignees(action) {
  const isLargeFirm = yield select(globalSelectors.getIsLargeFirm)
  try {
    const userIds = []
    const apiCalls = [
      call(reusableApi.fetchDepartments),
      call(reusableApi.fetchTeams),
      call(reusableApi.fetchFirmLocations),
    ]
    if (!isLargeFirm) {
      apiCalls.push(call(api.fetchUsers, action.payload))
    }
    const [departmentsResponse, teamsResponse, firmLocationsResponse, usersResponse] = yield all(apiCalls)
    if (usersResponse) {
      const users = {}
      usersResponse.users.forEach(u => {
        users[u.id] = {...u}
        userIds.push(u.id)
      })
      const entities = {
        users,
      }
      yield put(entitiesActions.update(entities))
    }
    yield put(actions.fetchAssigneesComplete({
      userIds: userIds,
      departments: departmentsResponse.departments,
      teams: teamsResponse.teams,
      firmLocations: firmLocationsResponse.firmLocations,
    }))
  } catch(error) {
    yield* handleSagaError(error)
    yield put(actions.setIsLoading(false))
  }
}


function* handleFetchUsers(action) {
  /**
   * this is only called when `isLargeFirm` is true, and `areAllUsersFetched` is false.
   * for other firms, users are fetched in `handleFetchAssignees`.
   */
  const nameFilter = action.payload ? action.payload.nameFilter : undefined
  // only fetch all if requested (ie. nameFilter is undefined).
  if (nameFilter === '') {
    // if name filter was removed, reset user ids to none.
    yield put(actions.setUserIds([]))
    return
  }
  if (nameFilter) {
    yield delay(300)
  }
  let response = null
  try {
    response = yield api.fetchUsers(action.payload)
  } catch(error) {
    yield* handleSagaError(error)
    yield put(actions.setIsLoading(false))
    return
  }
  const userIds = []
  const users = {}
  response.users.forEach(u => {
    users[u.id] = {...u}
    userIds.push(u.id)
  })
  const entities = {
    users,
  }
  yield put(entitiesActions.update(entities))
  yield put(actions.fetchUsersComplete({userIds, areAllUsersFetched: !nameFilter}))
}


function* resetUnsavedFilters() {
  const queryParams = [
    QUERY_PARAM_NAMES.APPLIED_FILTER_KEY,
    QUERY_PARAM_NAMES.TERM_FREQUENCY_KEY,
    QUERY_PARAM_NAMES.RELEVANCE,
    QUERY_PARAM_NAMES.GROUPING,
    QUERY_PARAM_NAMES.LANGUAGE,
    QUERY_PARAM_NAMES.SORT,
  ]
  const query = {}
  for (const param of queryParams) {
    query[param] = null
  }
  const timeFrame = yield select(selectors.getTimeFrame)
  if (is.string(timeFrame)) {
    query[QUERY_PARAM_NAMES.TIME_FRAME] = null
  }
  yield put(routing.actions.pushLocation({query, persistQuery: true}))
}


function* handleTrendingOpen(action) {
  const trendingData = yield select(selectors.getTrendingData)
  if (trendingData && Object.values(trendingData).some(data => data.length)) {
    // There is already data so we don't need to load it.
    return
  }
  yield* maybeFetchTrending()
}

function* syncLanguageSaveData(action) {
  yield delay(300)
  const languageFilters = action.payload
  const selectedLanguageIds = yield select(selectors.getSelectedLanguageIds)
  if (selectedLanguageIds) {
    yield put(actions.setSaveData({selectedLanguageIds}))
    if (languageFilters && languageFilters.length > 0) {
      yield put(actions.setSaveData({languageFilters}))
    }
  }
}

function* handleHideFeed(action) {
  /**
   * when excluding a feed from the search (searchId included in payload) we need to re-fetch the excluded feeds
   * to update the edit modal.
   */
  if (action.payload.searchId) {
    let response = null
    try {
      response = yield api.fetchSearchExcludedFeeds(action.payload.searchId)
    } catch (error) {
      yield* handleSagaError(error)
      return
    }
    yield* saveSearchDataEntities(response.savedSearch)
  }
}

function* handleRemoveExcludedFeedsFromSearch(action) {
  const feedIds = action.payload
  const searchId = yield select(selectors.getSearchId)
  let response = null
  try {
    response = yield reusableApi.removeExcludedFeedsFromSearch({feedIds, searchId})
  } catch (error) {
    yield* handleSagaError(error)
    return
  }
  // even though we are re-fetching the results, hiding feeds is handled on the front-end so we need to unset that here.
  for (let feedId of feedIds) {
    yield put(actions.unhideFeed(feedId))
  }
  yield* fetchSearchData()
}


export default function* searchResultsPageSaga() {
  if (!utils.getTier3SearchOrFeedId()) return

  /**
   * Stores an array of query params waiting to get applied to the URL.
   */
  const pendingQueryParams = []

  // Wait until init; otherwise the search data isn't set yet.
  yield take(actions.init)

  yield all([
    takeLatest(actions.fetchUserEmailSettings, handleFetchUserEmailSettings),
    takeLatest(actions.fetchCachedLargerTimeFrames, handleFetchCachedLargerTimeFrames),
    takeLatest(routing.actions.locationChanged, locationChanged),
    takeLatest(
      QUERY_PARAM_ACTIONS.map(prop('actionType')),
      handleSetQueryParam,
      pendingQueryParams,
    ),
    takeLatest(actions.refreshResults, loadResults),
    takeLatest(actions.setLanguageFilters, syncLanguageSaveData),
    takeLatest(actions.loadMoreResults, handleLoadMoreResults),
    takeLatest(actions.exportPdf, handlePdfExport),
    takeLatest(actions.exportDocx, handleDocxExport),
    takeLatest(actions.exportExcel, handleExcelExport),
    takeLatest(actions.exportEmail, handleEmailExport),
    takeLatest(actions.deleteSelectedDocuments, handleDeleteSelectedDocuments),
    takeLatest(actions.updateSearch, handleUpdateSearch),
    takeLatest(actions.addToTrustedSources, handleAddToTrustedSources),
    takeLatest(actions.removeFromTrustedSources, handleRemoveFromTrustedSources),
    takeLatest(actions.setSearchId, handleSetSearchId),
    takeLatest(actions.setFeedId, handleSetFeedId),
    takeLatest(actions.setActivePublicationTab, handleSetActivePublicationTab),
    takeLatest(actions.toggleShowQueryComponents, handleToggleShowQueryComponents),
    takeLatest(actions.toggleShowFullQuery, handleToggleShowFullQuery),
    takeLatest(actions.showDnbModal, handleShowDnbModal),
    takeLatest(actions.setIsFiltersBarOpen, handleSetIsFiltersBarOpen),
    takeLatest(actions.setIsTrendingOpen, handleSetIsTrendingOpen),
    takeLatest(
      actions.toggleFilterSectionDisplay,
      handleToggleFilterSectionDisplay,
    ),
    takeLatest(actions.saveSearch, handleSaveSearch),
    takeLatest(actions.saveNewSearch, handleNewSaveSearch),
    takeLatest(actions.saveSearchSettings, handleSaveSearchSettings),
    takeLatest([actions.addFilters, actions.removeFilters], createAppliedFilter),
    takeLatest(actions.addTermFrequencyFilters, createTermFrequencyFilter),
    takeLatest(actions.resetUnsavedFilters, resetUnsavedFilters),
    takeLatest(actions.hideEditSearchFiltersModal, hideEditSearchFiltersModal),
    takeLatest(
      action => action.type === actions.setIsTrendingOpen && action.payload,
      handleTrendingOpen,
    ),
    takeLatest(actions.hideFeed, handleHideFeed),
    takeLatest(actions.removeExcludedFeedsFromSearch, handleRemoveExcludedFeedsFromSearch),
    takeLatest(actions.fetchAssignees, handleFetchAssignees),
    takeLatest(actions.fetchUsers, handleFetchUsers),
  ])

  yield put(
    actions.setIsFiltersBarOpen(
      yield call(getLocalStorageItem, 'filters-open', true)
    )
  )
  yield put(
    actions.setIsTrendingOpen(
      yield call(getLocalStorageItem, 'trending-open', false)
    )
  )
  yield put(
    actions.setCollapsedFilterSections(
      yield call(getLocalStorageItem, 'collapsed-filter-sections', [])
    )
  )

  yield call(waitUntilDomLoaded)
  initialPageLoadTime = yield call(getInitialLoadTime)

  yield* fetchSearchData({
    includeAvailableFilters: true,
    includeFirmSourceLabels: true,
    includeHelpText: true,
  })
  yield* maybeFetchTrending()
}
