import invariant from 'invariant'


export default class Model {
  constructor(obj) {
    invariant(
      typeof obj == 'object',
      `No valid data was passed into the model constructor for ${this.constructor.name}.`,
    )

    // For now, `id` is a hard-coded (and required) field.
    // TODO: Allow customization of it? Would we ever use that?
    invariant(
      typeof obj.id != 'undefined',
      `No \`id\` field was found on the data passed to the model constructor for ${this.constructor.name}.`,
    )
    this.id = obj.id

    Object.entries(this.constructor.fields).forEach(([key, field]) => {
      if (field.__foreignKey) {
        if (field.__isArray) {
          const idKey = `${key}Ids`
          const values = obj[idKey]
          invariant(
            !(field.__isRequired && typeof values === 'undefined'),
            `${key} is a required foreign key field on ${this.constructor.name}, but no ${idKey} was found.`,
          )
          this[idKey] = values || []
        } else {
          const idKey = `${key}Id`
          const value = obj[idKey]
          invariant(
            !(field.__isRequired && typeof value === 'undefined'),
            `${key} is a required foreign key field on ${this.constructor.name}, but no ${idKey} were found.`,
          )
          this[idKey] = value
        }
      }

      // If it's a hasOne or hasMany, do nothing because the ORM will
      // handle it
      else if (!(field.__hasOne || field.__hasMany)) {
        const value = obj[key]
        // Just a regular field
        invariant(
          !(field.__isRequired && typeof value === 'undefined'),
          `${key} is a required field on ${this.constructor.name}.`,
        )
        this[key] = value
      }
    })
  }
}
