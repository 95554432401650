import React, { Component } from 'react'
import { connect } from 'react-redux'
import './SendSampleEmail.less'
import * as actions from '../email-notifications-actions'
import Modal from '../../common/Modal/index'
import * as constants from "../email-notifications-constants"
import Button from 'app/common/Button'
import TokenInput from 'app/common/TokenInput'
import PropTypes from "prop-types"
import {titleCase} from "title-case"
import * as globalSelectors from "app/global/global-selectors"


@connect(
  state => ({
    insightsSettings: state.emailNotifications.insightsSettings,
    sendSampleModal: state.emailNotifications.sendSampleModal,
    insightsLabel: globalSelectors.getInsightsLabel(state),
  }),
  {
    changeSendSampleTokens: actions.changeSendSampleTokens,
    changeSendSampleModal: actions.changeSendSampleModal,
    changeSendSampleRecipients: actions.changeSendSampleRecipients,
    sendSampleEmail: actions.api.sendSampleEmail.request,
  },
)

export default class SendSampleEmail extends Component {

  static propTypes = {
    notificationType: PropTypes.string.isRequired,
  }

  render() {
    const addTokenParams = {
      noticeFrequency: this.props.sendSampleModal.noticeFrequency,
      notificationType: this.props.notificationType,
      recipients: this.props.sendSampleModal.recipients
    }
    let sendSampleRecipientsSet = new Set()
    this.props.sendSampleModal.recipients.map(email => {
      sendSampleRecipientsSet.add({
        label: email,
        recipients: this.props.sendSampleModal.recipients
      })
    })
    const sendSampleRecipients = Array.from(sendSampleRecipientsSet)

    const notificationTypeDisplay =
      this.props.notificationType === constants.INSIGHTS_NOTIFICATION_TYPE
      ? `${this.props.insightsLabel} `
      : this.props.notificationType === constants.BASE_NOTIFICATION_TYPE
        ? 'Base '
        : titleCase(`${this.props.notificationType} `)
    const modalHeader = this.props.notificationType === constants.INSIGHTS_NOTIFICATION_TYPE
      ? <div className={'send-sample-title'}>
          {`Send Sample ${notificationTypeDisplay}${this.props.sendSampleModal.frequencyHeader}`}
        </div>
      : this.props.notificationType === constants.ESG_NOTIFICATION_TYPE
      ? <div className={'send-sample-title'}>
          {`Send Sample ${notificationTypeDisplay.toUpperCase()}${this.props.sendSampleModal.frequencyHeader}`}
        </div>
      :<div className={'send-sample-title'}>
          {`Send Sample ${notificationTypeDisplay} Email Alert`}
        </div>
    const sendSampleEmailModal = this.props.sendSampleModal
      ? <Modal
          className="notification-send-sample-modal"
          isOpen={this.props.sendSampleModal.show}
          onClose={() => {this.onSendSampleModalClose()}}
        >
          {modalHeader}
          <TokenInput
            updateTokensFunc={this.props.changeSendSampleTokens}
            tokenItems={sendSampleRecipients}
            delimiters={constants.TOKEN_INPUT_DELIMITERS}
            addTokenParams={addTokenParams}
            inputLabel={'Send Sample To'}
            inputWidth={'635px'}
            ignoreSplit={true}
          />
          <div className={'help-text'}>
            Separate emails with a comma.
          </div>
          <div className={'button-container'}>
            <div className={'send-button'}>
              <Button
                label={'Send'}
                className={'button primary'}
                onClick={() => {this.onSendSampleButtonClick()}}
                disabled={!sendSampleRecipients.length}
              />
            </div>
            <div className={'cancel-container'}>
              <a className="cancel cancel-action"
                 onClick={() => {this.onSendSampleModalClose()}}
              >
                Cancel
              </a>
            </div>
          </div>
        </Modal>
      : null

    return (
      <React.Fragment>
        {sendSampleEmailModal}
      </React.Fragment>
    )
  }
  onSendSampleModalClose() {
    this.props.changeSendSampleModal({
      show: false,
      notificationType: null,
      frequencyHeader: null,
      noticeFrequency: null,
      recipients: [],
      editUserId: null,
    })
  }
  onSendSampleButtonClick(){
    this.props.sendSampleEmail(this.props.sendSampleModal)
    this.onSendSampleModalClose()
  }
}

