import PropTypes from 'prop-types'
import React from 'react'

import styles from './PreferencesBlock.less'


export default function PreferencesBlock({title, description, children}) {
  return (
    <div className={styles.preferencesBlock}>
      <h2 className={styles.header}>{title}</h2>
      <div className={styles.description}>{description}</div>
      <div>{children}</div>
    </div>
  )
}
PreferencesBlock.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
