import {
  all,
  put, select,
  takeLatest,
  delay,
  takeEvery, call,
} from 'redux-saga/effects'
import * as actions from 'app/dashboard/dashboard-actions'
import * as api from "app/dashboard/dashboard-api"
import * as entitiesActions from "app/entities/entities-actions"
import {handleSagaError} from "app/utils/errors"
import {getDocuments, getDocumentIds, getSections, getGroupIdIfGroupDashboard} from 'app/dashboard/dashboard-selectors'
import * as entitiesSelectors from "app/entities/entities-selectors"
import Orm from "app/framework/Orm"
import {SavedSearch} from "app/models"
import {CHART_CATEGORIES} from "./dashboard-constants"


function* handleInit(action) {
  const {sections, cachedSections} = action.payload

  // get sections data
  try {
    const groupId = yield select(getGroupIdIfGroupDashboard)
    const sectionsResponse = yield call(api.fetchDashboardSectionsData, sections, groupId)
    yield* handleSectionsResponse(sectionsResponse)
  } catch(error) {
    yield* handleSagaError(error)
  }

  // get user's first section and any other cached sections
  let firstSection = null
  const firstFetchSections = []
  if (sections && sections.length > 0){
    firstSection = sections.shift()
    firstFetchSections.push(firstSection)
  }
  if (cachedSections) {
    cachedSections.forEach(cachedSec => {
      if (!firstFetchSections.includes(cachedSec)) {
        firstFetchSections.push(cachedSec)
      }
    })
  }

  // get first section and cached sections in first results request
  if (firstFetchSections && firstFetchSections.length > 0) {
    try {
      const groupId = yield select(getGroupIdIfGroupDashboard)
      const sectionsResultsResponse =
        yield call(api.fetchDashboardSectionsResults, firstFetchSections, groupId)
      const processSectionResults = []
      sectionsResultsResponse.data.forEach(results => {
        processSectionResults.push(handleSectionResults(results))
      })
      yield all(processSectionResults)
    } catch(error) {
      yield* handleSagaError(error)
    }
  }

  // get all remaining sections results in one request
  if (sections && sections.length > 0) {
    const remainingSections = sections.filter(sec => !firstFetchSections.includes(sec))
    if (remainingSections.length > 0) {
      try {
        const groupId = yield select(getGroupIdIfGroupDashboard)
        const response = yield call(api.fetchDashboardSectionsResults, remainingSections, groupId)
        const processSectionResults = []
        response.data.forEach(results => {
          processSectionResults.push(handleSectionResults(results))
        })
        yield all(processSectionResults)
      } catch(error) {
        yield* handleSagaError(error)
      }
    }
  }

  yield put(actions.setAreAllSectionsLoaded(true))
  yield put(actions.setIsLoading(false))
}

function* handleSectionsResponse(response) {
  if(!response || !response.sections){return}
  const updateSearches = []
  const setSectionsData = []

  response.sections.forEach(sectionObj => {
    const searchEntities = {}
    sectionObj.searches.forEach(ss => {
      searchEntities[ss.id] = ss
    })
    updateSearches.push(put(entitiesActions.update({searches: searchEntities})))
    setSectionsData.push(
      put(actions.fetchDashboardSectionComplete({
      section: {
        section: sectionObj.section,
        sectionName: sectionObj.sectionName,
        chartSectionName: sectionObj.chartSectionName,
        categories: sectionObj.categories,
      },
    })))
  })
  yield all(updateSearches)
  yield all(setSectionsData)
}

function* handleSectionResults(results) {
  if(!results){return}

  const searchIds = []
  const documentIds = []
  const documentEntities = {}
  const feedEntities = {}
  const sections = yield select(getSections)
  const sectionObj = sections.find(sec =>
    sec.section === results.section
  )
  const section = sectionObj.section

  results.searchResults.forEach(sr => {
    searchIds.push(sr.savedSearchId)
    sr.documents.forEach(d => {
      d.feedId = d.feed.id
      documentEntities[d.id] = d
      documentIds.push(d.id)
      feedEntities[d.feed.id] = d.feed
    })
  })

  if (searchIds.length > 0) {
    if (CHART_CATEGORIES.includes(section)){
      yield put(actions.getChartData({section, searchIds}))
    }
  }

  if (documentIds.length > 0) {
    yield put(entitiesActions.update({
      feeds: feedEntities,
      documents: documentEntities
    }))
    yield put(actions.fetchDashboardResultsComplete({
      sectionResults: {
        section: section,
        showChart: results.showChart,
        chartIds: results.chartIds,
        searchData: results.searchResults,
      },
      searchIds: searchIds,
      documentIds: documentIds,
    }))
    yield put(actions.setIsLoading(false))
  }
}

function* handleGetChartData(action) {
  const {section, searchIds} = action.payload
  const requestOptions = {
    category: section,
    ssIds: searchIds.join(),
    alphaSort: 0,
  }
  try {
    console.log('chartResponse --------------', requestOptions)
    const chartResponse = yield call(api.fetchTimelineChartAggregateData, requestOptions)
    console.log('chartResponse', chartResponse)
    if (chartResponse.body.chartData.seriesData &&
      chartResponse.body.chartData.seriesData.length > 0) {
      yield put(actions.setChartData({
        section: section,
        data: chartResponse.body.chartData,
      }))
    }
  } catch(error) {
    yield* handleSagaError(error)
  }
}

function* handleRemoveDocumentsForExcludedFeedId(action) {
  const documents = yield select(getDocuments)
  const removeDocumentIds = documents
    .filter(doc => doc.feed.id === action.payload.feedId.feedId)
    .map(doc => doc.id)
  const documentIds = [...yield select(getDocumentIds)]
  const setDocumentIds = documentIds.filter(id => !removeDocumentIds.includes(id))
  yield put(actions.setDocumentIds(setDocumentIds))
  yield put(entitiesActions.remove({documents: [removeDocumentIds]}))
}

function* handleActiveSidebarNavChange(action) {
  if (action.payload.waypoint === null) {
    const searchId = action.payload.searchId
    const sectionName = action.payload.sectionName
    yield delay(100)
    yield put(actions.setActiveSidebarNav({searchId, sectionName}))
  }
  yield put(actions.setLastSidebarNavChange(action.payload))
}

function* handleSetLastSidebarNavChange(action) {
  yield delay(100)
  yield put(actions.setActiveSidebarNav(action.payload))
}

function* handleChartPointClick(action) {
  const {searchId, category, dateString} = action.payload

  if (!searchId || !category || !dateString) {return}

  const savedSearch = yield select(state => {
    const entities = entitiesSelectors.getEntities(state)
    const orm = Orm.withEntities(entities)
    return orm.getById(SavedSearch, searchId)
  })

  if (!savedSearch) {return}
  window.location.href = `/${category}/${savedSearch.slug}/${savedSearch.id}?time-frame=${dateString};${dateString}&usrc=chart&usrc_s=line`

}

export default function* dashboardSaga() {
  yield all([
    takeLatest(actions.init, handleInit),
    takeLatest(actions.removeDocumentsForExcludedFeedId, handleRemoveDocumentsForExcludedFeedId),
    takeEvery(actions.getChartData, handleGetChartData),
    takeEvery(actions.activeSidebarNavChange, handleActiveSidebarNavChange),
    takeLatest(actions.setLastSidebarNavChange, handleSetLastSidebarNavChange),
    takeLatest(actions.chartPointClick, handleChartPointClick),
  ])
}
