import * as entitiesSelectors from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import {Document, SavedSearch} from 'app/models'

import {STATE_PROP_NAME} from './dashboard-constants'

export const getDashboardState = state => state[STATE_PROP_NAME]

export const getSearchIds = state =>
  getDashboardState(state).fetchedSearchIds
export const getDocumentIds = state =>
  getDashboardState(state).fetchedDocumentIds
export const getAreAllSectionsLoaded = state =>
  getDashboardState(state).areAllSectionsLoaded
export const getSections = state =>
  getDashboardState(state).fetchedSections
export const getGroupIdIfGroupDashboard = state =>
  getDashboardState(state).groupId

// Entity selectors
export const getSearches = state => {
  const searchIds = getSearchIds(state)
  const entities = entitiesSelectors.getEntities(state)
  const orm = Orm.withEntities(entities)
  return orm.getByIds(SavedSearch, searchIds)
}
export const getDocuments = state => {
  const documentIds = getDocumentIds(state)
  const entities = entitiesSelectors.getEntities(state)
  const orm = Orm.withEntities(entities)
  return orm.getByIds(Document, documentIds)
}
