import {searchCategory} from 'app/strings'

import {
  FILTER_CATEGORY_EXCLUSIONS,
  FILTER_CATEGORY_GEOGRAPHY,
  FILTER_CATEGORY_NEWS,
  FILTER_CATEGORY_OTHER,
  FILTER_CATEGORY_POPULAR,
  FILTER_CATEGORY_REGULATORY,
  FILTER_CATEGORY_TOPICS,
} from 'app/constants'

import SavedSearch from 'app/models/SavedSearch'

import {BROWSE_SECTIONS} from './constants'

export function getSearchLabels(currentFirm) {
  const categories = [
    SavedSearch.CATEGORIES.COMPANY,
    SavedSearch.CATEGORIES.INDUSTRY,
    SavedSearch.CATEGORIES.TOPIC,
    SavedSearch.CATEGORIES.COMPETITOR,
    SavedSearch.CATEGORIES.PRACTICE,
    SavedSearch.CATEGORIES.TRUSTED_UNCATEGORIZED,
    SavedSearch.CATEGORIES.COURT,
    SavedSearch.CATEGORIES.STATE,
    SavedSearch.CATEGORIES.TRUSTED,
    SavedSearch.CATEGORIES.PROSPECT
  ]
  return Object.fromEntries(categories.map(c => [c, {
      label: `${searchCategory(c, {plural: true, currentFirm})}`,
      filterLabel: `${searchCategory(c, {currentFirm})} Name`,
    }]))
}

export function getBrowseSections(currentFirmLibraryName) {
  return {
    [BROWSE_SECTIONS.SEARCHES]: {
      label: 'Search Owner\'s Categories',
      filterLabel: 'Categories',
      description: 'Selections from the owner of this search\'s categories.',
      longDescription: 'These are selections pulled from the profile of the search owner. Select a category to browse filters within that category.',
    },
    [BROWSE_SECTIONS.FIRM_LIBRARY_SEARCHES]: {
      label: `${currentFirmLibraryName} Categories`,
      filterLabel: `${currentFirmLibraryName} Categories`,
      description: `Selections from the ${currentFirmLibraryName}.`,
    },
    [BROWSE_SECTIONS.FIRM_SOURCE_LABELS]: {
      label: 'Source Labels',
      filterLabel: 'Source Labels',
      description: `Selections from Source Labels.`,
    },
    [FILTER_CATEGORY_NEWS]: {
      label: 'News Sources',
      filterLabel: 'News Source Filters',
      description: 'Selections from top news resources.',
    },
    [FILTER_CATEGORY_GEOGRAPHY]: {
      label: 'Geography',
      filterLabel: 'Geographic Filters',
      description: 'Selection single or multiple locations.',
    },
    [FILTER_CATEGORY_TOPICS]: {
      label: `${searchCategory('practice')} & ${searchCategory('industry')} Topics`,
      filterLabel: 'Topic Filters',
      description: 'Selections from our Topics Library.',
    },
    [FILTER_CATEGORY_POPULAR]: {
      label: 'Popular Filters',
      filterLabel: 'Popular Filters',
      description: 'Selections from our specialty searches.',
    },
    [FILTER_CATEGORY_REGULATORY]: {
      label: 'Regulatory',
      filterLabel: 'Regulatory Filters',
      description: 'Regulatory by region.',
    },
    [FILTER_CATEGORY_EXCLUSIONS]: {
      label: 'Recommended Exclusions',
      filterLabel: 'Recommended Exclusions',
      description: 'Selections from our specialty searches.',
    },
    /**
     * `other` is for filter categories the user does not have access to (ie. `news` category from the wrong vertical).
     * this usually indicates staff error, but we still want to show all filters applied to the search.
     */
    [FILTER_CATEGORY_OTHER]: {
      label: 'Other',
      filterLabel: 'Other Filters',
      description: 'Other Filters',
    }
  }
}

export function formatDate(string) {
  const date = new Date(string)
  const options = {year: 'numeric', month: 'short', day: 'numeric'}
  return date.toLocaleDateString('en-US', options)
}
