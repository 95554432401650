import React, {useState} from 'react'

import Button from 'app/common/Button'
import ConfirmationModal from 'app/common/modals/ConfirmationModal'

import {ACTIVE_FILTER, SELECT_TYPE, MANAGE_COLUMNS, COLUMN_OPTIONS} from './users-admin-constants'
import Dropdown from 'app/common/Dropdown'
import Option from 'app/common/Dropdown/Option'
import PopoverDropdown from "app/common/PopoverDropdown"
import Tooltip from 'app/common/Tooltip'

export default function Actions({
  isGroupPage,
  currentFirmHasInsights,
  insightsLabel,
  showRefreshDetails,
  selectedUserIds,
  allFilteredUsersAreSelected,
  activeFilter,
  handleSelectAllUsersCheckbox,
  resetPassword,
  sendWelcomeEmail,
  sendRefreshEmail,
  showInsightsModal,
  activateAccounts,
  deactivateAccounts,
  setShowRefreshDetails,
  makeSelectedGroupsPrivate,
  showDeleteUserConfirmationModal,
  totalCount,
  visibleIds,
  showSelectedGroupsColumns,
  selectedColumns,
  setPersonalAlerts,
  setGroupAlerts,
  setDisableAlerts,
  setNlaArticleViewing,
  isNlaFirm,
  showRefreshIntervalModal
}) {
  const [isMakePrivateConfirmationOpen, setIsMakePrivateConfirmationOpen] = useState(false)

  const isMakePrivateConfirmationModal =
    isMakePrivateConfirmationOpen &&
    <ConfirmationModal
      message="Are you sure you want to make the selected groups private?"
      secondaryMessage="This will prevent non-admin users from adding themselves to these groups."
      onConfirm={() => {makeSelectedGroupsPrivate(); setIsMakePrivateConfirmationOpen(false);}}
      onClose={() => setIsMakePrivateConfirmationOpen(false)}
      isDestructive={true}
      confirmButtonText="Yes"
    />

    const handleSelection = (value) => {
      if (value === SELECT_TYPE.ALL || value === SELECT_TYPE.VISIBLE) {
        handleSelectAllUsersCheckbox(true, value)
      } else if (value === SELECT_TYPE.NONE) {
        handleSelectAllUsersCheckbox(false)
      }
    }

  const showNlaButtons = !isGroupPage && isNlaFirm && activeFilter !== ACTIVE_FILTER.INACTIVE;

  return (
    <div className="multi-actions-wrapper">
      {isMakePrivateConfirmationModal}

      <div className="multi-actions" >
        <span className="dropdown">
          <Dropdown onChange={(value) => handleSelection(value)} value={''}>
              <Option
                value=''
                isInvisible={true}
                label={
                  <input
                    type="checkbox"
                    onClick={e => e.preventDefault()}
                    onChange={e => e.preventDefault()}
                    checked={allFilteredUsersAreSelected}
                  />
                }
              />
              <Option label={`Select All (${totalCount})`} value={SELECT_TYPE.ALL} />
              <Option label={`Select Visible (${visibleIds?.length})`} value={SELECT_TYPE.VISIBLE} />
              <Option label="Deselect All" value={SELECT_TYPE.NONE} />
        </Dropdown>
        </span>

        {
          !isGroupPage && activeFilter !== ACTIVE_FILTER.INACTIVE &&
          <span>
            <Button
              label="Password Reset"
              disabled={selectedUserIds.length === 0}
              onClick={resetPassword}
            />

            <Button
              label="Send Welcome Email"
              disabled={selectedUserIds.length === 0}
              onClick={sendWelcomeEmail}
            />

            <Button
              label="Send Refresh Email"
              disabled={selectedUserIds.length === 0}
              onClick={sendRefreshEmail}
            />
          </span>
        }
        {showNlaButtons && <Button
          label="Restrict NLA Articles"
          disabled={selectedUserIds.length === 0}
          onClick={() => setNlaArticleViewing("disable")}
        />
        }

        {showNlaButtons && <Button
          label="Allow NLA Articles"
          disabled={selectedUserIds.length === 0}
          onClick={() => setNlaArticleViewing("enable")}
        />
        }

        {
          currentFirmHasInsights && activeFilter !== ACTIVE_FILTER.INACTIVE &&
          <Button
            label={`Manage ${insightsLabel}`}
            disabled={selectedUserIds.length === 0}
            onClick={showInsightsModal}
          />
        }

        {
          activeFilter !== ACTIVE_FILTER.INACTIVE && <>
            <Tooltip
              label="Manage Refresh Interval limted to 20 users"
              disabled={selectedUserIds.length > 0 && selectedUserIds.length <= 20 ? true : false}
            >
              <Button
                label={`Manage Refresh Interval`}
                disabled={selectedUserIds.length > 0 && selectedUserIds.length <= 20 ? false : true}
                onClick={showRefreshIntervalModal}
              />
            </Tooltip>
          </>
        }

        {
          activeFilter !== ACTIVE_FILTER.INACTIVE &&
          <Button
            label="Deactivate"
            isDestructive={true}
            disabled={selectedUserIds.length === 0}
            onClick={deactivateAccounts}
          />
        }

        {
          activeFilter === ACTIVE_FILTER.INACTIVE &&
          <Button
            label="Activate"
            disabled={selectedUserIds.length === 0}
            onClick={activateAccounts}
          />
        }

        {
          activeFilter === ACTIVE_FILTER.INACTIVE &&
          <Button
            label="Delete"
            isDestructive={true}
            disabled={selectedUserIds.length === 0}
            onClick={(event) => showDeleteUserConfirmationModal(event, selectedUserIds)}
          />
        }

        {
          isGroupPage &&
          <Button
            label="Make Private..."
            isDestructive={true}
            disabled={selectedUserIds.length === 0}
            onClick={() => setIsMakePrivateConfirmationOpen(true)}
          />
        }
        {
          !isGroupPage &&
          <>
            <Tooltip
              label="Bulk Enable of Personal Alerts is limited to 20 users"
              disabled={selectedUserIds.length > 0 && selectedUserIds.length <= 20 ? true : false}
            >
              <Button
                label="Enable Personal Alerts"
                disabled={selectedUserIds.length > 0 && selectedUserIds.length <= 20 ? false : true}
                onClick={() => setPersonalAlerts(true)}
              />
            </Tooltip>
            <Tooltip
              label="Bulk Enable of Group Alerts is limited to 20 users"
              disabled={selectedUserIds.length > 0 && selectedUserIds.length <= 20 ? true : false}
            >
              <Button
                label="Enable Group Alerts"
                disabled={selectedUserIds.length > 0 && selectedUserIds.length <= 20 ? false : true}
                onClick={() => setGroupAlerts(true)}
              />
            </Tooltip>
            <Tooltip
              label="Bulk Disable of Alerts is limited to 20 users"
              disabled={selectedUserIds.length > 0 && selectedUserIds.length <= 20 ? true : false}
            >
              <Button
                label="Disable Alerts"
                disabled={selectedUserIds.length > 0 && selectedUserIds.length <= 20 ? false : true}
                onClick={() => setDisableAlerts(true)}
              />
            </Tooltip>
          </>
        }
      </div>

      {
        !isGroupPage &&
        <div className="show-refresh">
          <input
            type="checkbox"
            name="show_profile_refresh"
            checked={showRefreshDetails}
            onChange={(evt) => setShowRefreshDetails(evt.target.checked)}
          />
          <label className="inline">Show Refresh Details</label>
        </div>
      }
      {
        isGroupPage &&
        <PopoverDropdown
          label="Add Columns"
          options={COLUMN_OPTIONS}
          multiSelection={true}
          enableFilter={true}
          enableTooltip={true}
          showSubtitle={false}
          placeholder="Search"
          containerClassName='manage-columns'
          selectedOptions={selectedColumns}
          onSelection={(items) => showSelectedGroupsColumns(items)}
        >
          <div>{MANAGE_COLUMNS} <img src="/media/img/ManageColumnsIcon.svg" /></div>
        </PopoverDropdown>
      }
    </div>
  )
}
