import * as profileRefreshApi from './profile-refresh-api'
import * as interactionsApi from 'app/global/interactions/api'
import * as profileRefreshActions from './profile-refresh-actions'
import {all, takeLatest, takeEvery, call, put, delay, select} from 'redux-saga/effects'
import { extractErrorMessage } from 'app/utils/errors'
import * as notifications from 'app/global/notifications'
import * as profileRefreshSelectors from './profile-refresh-selectors'
import {flatten, map, pipe} from 'ramda'


function* handleInit(action) {
  const ownerId = yield select(state => state.currentUser)
  try {
    const response = yield profileRefreshApi.fetchUserSearches(ownerId)
    yield put(profileRefreshActions.setExistingUserSearches(response.searches))
  } catch(e) {
    yield put(
      notifications.actions.showNotification({
        type: 'error',
        message: extractErrorMessage(error),
      }),
    )
  }
}

function* handleFetchSearches(action) {
  yield delay(200)
  const { authors, category } = action.payload
  try {
    const response = yield(profileRefreshApi.fetchSearches({authors, categories: [category]}))
    const searches = response.data.items.map(item => item.search)
    yield put(profileRefreshActions.showBrowseModal({searches, category}))
  } catch(e) {
    yield put(
      notifications.actions.showNotification({
        type: 'error',
        message: extractErrorMessage(error),
      }),
    )
  }
}

function* handleSaveUserSearches(action) {
  const userSearchesToRemove = yield select(profileRefreshSelectors.userSearchesToRemove)
  const userSearchesToAdd = yield select(profileRefreshSelectors.userSearchesToAdd)
  const profileRefreshInterval = yield select(profileRefreshSelectors.profileRefreshInterval)
  const removeSearchIds = userSearchesToRemove.map(ss => ss.id)

  const catArrays =
    pipe(
      map(cat => Object.values(cat)),
      flatten,
    )(userSearchesToAdd)

  const addSearches = catArrays.map(ss => {
    return ({
      category: ss.category,
      isFreeText: ss.isFreeText || false,
      name: ss.label,
      value: ss.value,
    })
  })

  const saveData = {
    id: action.payload,
    removeSearches: removeSearchIds,
    searches: addSearches,
    refreshInterval: profileRefreshInterval
  }

  try {
    yield profileRefreshApi.saveUser(saveData)
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Search changes saved successfully.',
      }),
    )
    yield put(profileRefreshActions.saveUserSearchesSuccess())
    yield interactionsApi.addInteraction({type: 'profile refresh page completed'})
    window.location.href = '/profile'
  } catch(error) {
    yield put(
      notifications.actions.showNotification({
        type: 'error',
        message: extractErrorMessage(error),
      }),
    )
  }
}

function* handleSkip(action) {
  window.location.href = '/profile'
}

export default function*() {
  yield all([
    takeEvery(
      profileRefreshActions.init,
      handleInit,
    ),
    takeLatest(
      profileRefreshActions.fetchSearches,
      handleFetchSearches,
    ),
    takeLatest(
      profileRefreshActions.saveUserSearches,
      handleSaveUserSearches,
    ),
    takeLatest(
      profileRefreshActions.skip,
      handleSkip,
    ),
  ])
}
