export const STATE_PROP_NAME = 'searches'

export const MANAGE_LABELS_SECTION = {
    TITLE: 'Manage Labels',
    DESCRIPTION: 'Create and assign one or more labels to help organize and categorize your searches. You can also assign child labels to your labels to further categorize your searches.',
    EMPTY_LABEL_MESSAGE: 'Label name cannot be empty',
    NO_DATA: 'No data to display',
    EXCEEDS_CHARACTER_LIMIT: 'Label name should not exceed 100 characters'
  }


  export const PAGINATION_OPTIONS = {
    10: '10',
    20: '20',
    50: '50',
    100: '100',
  }
  
  export const LABEL_NAMES = {
    ASSIGNED_LABELS: 'assigned labels',
    ALL_LABELS: 'all labels',
  }
  
  export const LABEL_DESCRIPTION = {
    ASSIGNED_LABELS: 'Click X to remove a label. This also clears the associated check box in the All Labels table.',
    ALL_LABELS: 'Select check boxes to add labels and clear check boxes to remove labels.',
  }
  
  export const CHECKBOX_ACTIONS = {
    CHECKBOX_DESCRIPTION:'Select the check boxes to add or remove labels from multiple searches.',
    CHECKBOX_TICK:'-A check box with a tick indicates that the label is assigned to all the searches.',
    CHECKBOX_CLEAR:'-A clear check box indicates that the label is not assigned to any searches.',
    CHECKBOX_LINE:'-A check box with a line indicates that the label is assigned to at least one of the searches.'
  }
  
  export const MANAGE_LABELS_ACTIONS = {
    SAVE:'Save',
    SHOW:'Show',
    EDIT:'Edit',
    DELETE:'Delete',
    CANCEL:'Cancel',
    CREATE:'Create',
  }

  export const COLUMNS = {
    CHECKBOX: 'checkbox',
    NAME: 'name',
    CATEGORY: 'category',
    TYPE: 'type',
    OWNER: 'owner',
    SHARED_WITH: 'sharedWith',
    HAS_FILTERS: 'hasFilters',
    USED_IN_SEARCH: 'usedInSearch',
    ACTIONS: 'actions',
    LOCATION: 'location',
    DEPARTMENT: 'department',
    TEAM: 'team',
    LABELS: 'labels'
  }

  export const COLUMN_OPTIONS = [
    { value: COLUMNS.CATEGORY, label: 'Category'},
    { value: COLUMNS.TYPE, label: 'Privacy Setting'},
    { value: COLUMNS.OWNER, label: 'Owner'},
    { value: COLUMNS.SHARED_WITH, label: 'Number of Profiles'},
    { value: COLUMNS.HAS_FILTERS, label: 'Has Filters?'},
    { value: COLUMNS.USED_IN_SEARCH, label: 'Used in Search'},
    { value: COLUMNS.LOCATION, label: 'Office Location'},
    { value: COLUMNS.DEPARTMENT, label: 'Department'},
    { value: COLUMNS.TEAM, label: 'Team'},
    { value: COLUMNS.LABELS, label: 'Labels'},
   ]

   export const DEFAULT_COLUMNS_VIEW =[COLUMNS.CATEGORY,COLUMNS.OWNER,COLUMNS.LABELS]
   
   export const MANAGE_COLUMNS = 'Manage Columns'

   export const MAX_ROW_COUNT_FOR_LABELS = 50
  

