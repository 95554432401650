import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
  STATE_PROP_NAME as FIRM_ADMIN_PROP_NAME
} from '../../firm-admin-constants'
import {
  STATE_PROP_NAME as SEARCHES_ADMIN_PROP_NAME
} from '../searches-admin-constants'
import CreateSearchQuery from './CreateSearchQuery'
import EnterSearchDetails from './EnterSearchDetails'
import ShareSearch from './ShareSearch'
import AlertSettings from './AlertSettings'

import './style.less'


export default class AddSearchContainer extends Component {
  static propTypes = {
    step: PropTypes.number.isRequired,
  }

  render() {
    let content
    if (this.props.step === 1) {
      content = <CreateSearchQuery/>
    } else if (this.props.step === 2) {
      content = <EnterSearchDetails/>
    } else if (this.props.step === 3) {
      content = <ShareSearch/>
    } else if (this.props.step === 4) {
      content = <AlertSettings/>
    } else {
      throw new Error(
        `In the Redux state, state.${FIRM_ADMIN_PROP_NAME}.${SEARCHES_ADMIN_PROP_NAME}.addNewModalData.step has an invalid value of '${this.props.step}'.`
      )
    }

    return (
      <div id="add-firm-search-container">
        <h1>
          Add a New Search
        </h1>

        {content}
      </div>
    )
  }
}
