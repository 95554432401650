import React from 'react'
import {render} from 'react-dom'
import {Provider} from 'react-redux'

import store from 'app/store'

import Header from './Header'

export function init() {
  const container = document.getElementById('nav-comfort-bar')
  const component = (
    <Provider store={store}>
      <Header />
    </Provider>
  )
  render(component, container)
}

export {Header}
