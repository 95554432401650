import {createAction} from 'redux-actions'

const PREFIX = 'profile-preferences'
const action = name => createAction(`${PREFIX}/${name}`)

export const init = action('INIT')
export const setIsLoading = action('SET_IS_LOADING')
export const setPreferences = action('SET_PREFERENCES')

export const changeLanguagePreferenceRequest = action('CHANGE_LANGUAGE_PREFERENCE_REQUEST')
export const setLanguagePreference = action('SET_LANGUAGE_PREFERENCE')
export const setLanguageLoading = action('SET_LANGUAGE_LOADING')

export const changeBoosterStateRequest = action('CHANGE_BOOSTER_STATE_REQUEST')
export const setBoosterState = action('SET_BOOSTER_STATE')
export const setBoosterLoading = action('SET_BOOSTER_LOADING')
export const setBoosterShowMeModalId = action('SET_BOOSTER_SHOW_ME_MODAL_ID')

export const changeDashboardOrderRequest = action('CHANGE_DASHBOARD_ORDER_REQUEST')
export const setDashboardOrder = action('SET_DASHBOARD_ORDER')
export const setDashboardOrderLoading = action('SET_DASHBOARD_ORDER_LOADING')

export const changeSubscriptionContentSettingRequest = action('CHANGE_SUBSCRIPTION_CONTENT_SETTING_REQUEST')
export const setSubscriptionContentSetting = action('SET_SUBSCRIPTION_CONTENT_SETTING')
export const setSubscriptionContentLoading = action('SET_SUBSCRIPTION_CONTENT_LOADING')

export const changeTimeFrameRequest = action('CHANGE_TIME_FRAME_REQUEST')
export const setTimeFrame = action('SET_TIME_FRAME')
export const setTimeFrameLoading = action('SET_TIME_FRAME_LOADING')

export const changeTimeFrameMobileRequest = action('CHANGE_TIME_FRAME_MOBILE_REQUEST')
export const setTimeFrameMobile = action('SET_TIME_FRAME_MOBILE')
export const setTimeFrameMobileLoading = action('SET_TIME_FRAME_MOBILE_LOADING')

export const changeSearchResultsOrderByRequest = action('CHANGE_SEARCH_RESULTS_ORDER_BY_REQUEST')
export const setSearchResultsOrderBy = action('SET_SEARCH_RESULTS_ORDER_BY')
export const setSearchResultsOrderByLoading = action('SET_SEARCH_RESULTS_ORDER_BY_LOADING')

export const changeRelevanceRequest = action('CHANGE_RELEVANCE_REQUEST')
export const setRelevance = action('SET_RELEVANCE')
export const setRelevanceLoading = action('SET_RELEVANCE_LOADING')
export const applyRelevanceToAll = action('APPLY_RELEVANCE_TO_ALL')
export const setRelevanceApplyingToAll = action('SET_RELEVANCE_APPLYING_TO_ALL')

export const changeGroupingRequest = action('CHANGE_GROUPING_REQUEST')
export const setGrouping = action('SET_GROUPING')
export const setGroupingLoading = action('SET_GROUPING_LOADING')
export const applyGroupingToAll = action('APPLY_GROUPING_TO_ALL')
export const setGroupingApplyingToAll = action('SET_GROUPING_APPLYING_TO_ALL')

export const changeQuickFilterSelectedRequest = action('CHANGE_QUICK_FILTER_SELECTED_REQUEST')
export const setQuickFilterSelected = action('SET_QUICK_FILTER_SELECTED')
export const setQuickFilterLoading = action('SET_QUICK_FILTER_LOADING')

export const changeNumberOfSearchResults = action('CHANGE_NUMBER_OF_SEARCH_RESULTS')
export const setresultsPerPage = action('SET_RESULTS_PER_PAGE')
export const setresultsPerPageLoading = action('SET_RESULTS_PER_PAGE_LOADING')
export const setDefaultResultsPerPage = action('SET_DEFAULT_RESULTS_PER_PAGE')
