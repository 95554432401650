import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import InlineSvg from 'app/common/InlineSvg'

import BareModal from '../BareModal'

import styles from './SimpleModal.less'


export default function SimpleModal({
  title,
  showExit = true,
  onClose,
  children,
  className,
  ...restProps
}) {
  return (
    <BareModal
      onClose={onClose}
      className={classNames(styles.modal, className)}
      {...restProps}
    >
      <div className={styles.header}>
        <h1>{title}</h1>
        {showExit &&
          <InlineSvg
            onClick={() => onClose()}
            className={styles.exit}
            src="/media/img/close-icon.svg"
          />
        }
      </div>
      {children}
    </BareModal>
  )
}
SimpleModal.propTypes = {
  ...BareModal.propTypes,
  title: PropTypes.string.isRequired,
  showExit: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}
