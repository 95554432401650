import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from "classnames"

import * as constants from './search-grouping-filter-constants'
import * as strings from './search-grouping-filter-strings'

import './SearchGroupingFilter.less'

export default class SearchGroupingFilter extends Component {

  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    shouldShowTitle: PropTypes.bool,
  }

  static defaultProps = {
    shouldShowTitle: true,
  }

  render() {
    return (
      <div className="search-grouping-container">
        {this.props.shouldShowTitle &&
          <div className={"slider-label"}>Grouping</div>
        }
        <div className="slider-container">
          {this.getGroupingOption(constants.FOCUS_LEVEL_LIMITED)}
          {this.getGroupingOption(constants.FOCUS_LEVEL_LESS)}
          {this.getGroupingOption(constants.FOCUS_LEVEL_STANDARD)}
          {this.getGroupingOption(constants.FOCUS_LEVEL_MORE)}
        </div>
      </div>
    )
  }

  getGroupingOption(focusLevel) {
    const bar = focusLevel === constants.FOCUS_LEVEL_MORE
      ? null
      : <div className="bar">
          <div className="inner-bar" />
        </div>
    return(
      <div className="grouping-level-container">
        <div className="grouping-level">
          <div className='grouping'>
            <div
              onClick={() => this.props.onChange(focusLevel)}
              className={classNames(
                'circle-container',
                {
                  'active': this.props.value === focusLevel,
                },
              )}
            >
              <div className="circle">
                <span className="inner-circle" />
              </div>
              {bar}
              <div
                className={classNames('grouping-text', {
                  'left-padding': focusLevel === constants.FOCUS_LEVEL_LIMITED
                })}
              >
                {strings.groupingLevel(focusLevel)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
