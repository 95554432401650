import React from 'react'

import Button from 'app/common/Button'
import InputBlock from 'app/common/InputBlock'
import TextBox from 'app/common/TextBox'

export default function AddUser({
  currentUserIsStaff,
  currentUserIsClientAdmin,
  isGroupPage,
  newUser,
  setNewUserFirstName,
  setNewUserLastName,
  setNewUserEmailAddress,
  setNewUserWelcomeEmail,

  saveNewGroup,
  saveNewUser,
}) {
  return (
    (currentUserIsStaff || currentUserIsClientAdmin) &&
    <div>
      <h4 className="group-heading">{isGroupPage ? 'Create Group' : 'Add User'}</h4>

      <div>
        {
          isGroupPage ?
            <div className="flex add-group-container">
              <div className="flex-item-name">
                <InputBlock label="Group Name">
                  <TextBox
                    value={newUser.firstName || ''}
                    onChange={evt => setNewUserFirstName(evt.target.value)}
                  />
                </InputBlock>
              </div>

              <div className="flex-item-button">
                <InputBlock label="&nbsp;">
                  <Button
                    isPrimary={true}
                    label="Create Group"
                    disabled={!newUser.firstName}
                    onClick={saveNewGroup}
                  />
                </InputBlock>
              </div>
            </div>
          : <div className="flex">
              <div className="flex-item-name">
                <InputBlock label="First Name">
                  <TextBox
                    value={newUser.firstName || ''}
                    onChange={evt => setNewUserFirstName(evt.target.value)}
                  />
                </InputBlock>
              </div>

              <div className="flex-item-name">
                <InputBlock label="Last Name">
                  <TextBox
                    value={newUser.lastName || ''}
                    onChange={evt => setNewUserLastName(evt.target.value)}
                  />
                </InputBlock>
              </div>

              <div className="flex-item-email">
                <InputBlock label="Email Address" className="email-address-block">
                  <TextBox
                    value={newUser.emailAddress || ''}
                    onChange={evt => setNewUserEmailAddress(evt.target.value)}
                  />
                </InputBlock>
                <div className="text-right field">
                  <input
                    type="checkbox"
                    name="send_welcome"
                    id="id_send_welcome"
                    checked={newUser.shouldSendWelcomeEmail}
                    onChange={(evt) => setNewUserWelcomeEmail(evt.target.checked)}
                  />
                  <label className="inline">Send Welcome Email</label>
                </div>
              </div>

              <div className="flex-item-button">
                <InputBlock label="&nbsp;">
                  <Button
                    isPrimary={true}
                    label="Add"
                    disabled={
                      !newUser.firstName ||
                      !newUser.lastName ||
                      !newUser.emailAddress
                    }
                    onClick={() => saveNewUser()}
                  />
                </InputBlock>
              </div>

              <div className="flex-item-csv">
                <div>
                  <strong>Adding a large number of users?</strong>
                </div>

                <div>
                  <span>It can be easily done with a CSV file.</span>
                </div>

                <div>
                  <a href="mailto:support@manzama.com?Subject=CSV%20User%20Import">Email Support for more information.</a>
                </div>
              </div>
            </div>
        }
      </div>
    </div>
  )
}
