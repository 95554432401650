import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import Dropdown, {Option} from 'app/common/Dropdown'
import {SORT_OPTIONS} from 'app/search-results-page/search-results-page-constants'

import * as actions from '../../profile-preferences-actions'
import * as selectors from '../../profile-preferences-selectors'

import styles from '../common/dropdown.less'

const DEFAULT_VALUE = SORT_OPTIONS.RELEVANCE


export default function SearchResultsOrder() {
  const value = useSelector(selectors.getSearchResultsOrderBy)
  const isLoading = useSelector(selectors.getSearchResultsOrderByLoading)
  const dispatch = useDispatch()
  const onChange = value => {
    dispatch(actions.changeSearchResultsOrderByRequest(value))
  }
  return (
    <Dropdown
      value={value || DEFAULT_VALUE}
      disabled={isLoading}
      onChange={onChange}
      className={styles.dropdown}
    >
      <Option value={SORT_OPTIONS.RELEVANCE} label="Relevance" />
      <Option value={SORT_OPTIONS.DATE_DESC} label="Newest first" />
      <Option value={SORT_OPTIONS.DATE_ASC} label="Oldest first" />
    </Dropdown>
  )
}
