import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'


export default function WhatsNew({highlight}) {
  return (
    <li className={classNames('pull-right', 'secondary', {highlight})}>
      <a href="/whats-new">What's New</a>
    </li>
  )
}
WhatsNew.propTypes = {
  highlight: PropTypes.bool.isRequired,
}
