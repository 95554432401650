import React, { Component } from 'react'
import PropTypes from 'prop-types'


export class FeedListing extends Component {
  static propTypes = {
    article: PropTypes.object,
    isGrouping: PropTypes.bool,
    groupCount: PropTypes.number,
  }

  render() {
    const {article} = this.props
    return (
      <span>
        {
          article.feed ?
            article.feed.id === 1 ?
              <span>{article.feed.name}</span>
              : this.props.isGrouping ?
                <a href={`/feed_display/${article.feed.pk}`}>
                  {
                    this.props.groupCount > 1 ?
                      <span>{article.feed.name.substring(0, 31)}</span>
                      : <span>{article.feed.name.substring(0, 25)}</span>
                  }
                </a>
                : <span>
                    <a href={`/feed_display/${article.feed.id}`}>{article.feed.name}</a>
                    {
                      article.feed.subscriptionRequired ?
                        <span className="sub-required">(May Require Subscription)</span>
                      : null
                    }
                    {
                      article.feed.credentials && article.feed.proprietary ?
                        <span className="mid">&nbsp;{article.feed.credentials.substring(0, 50)}</span>
                        : null
                    }
                  </span>
            : null
        }
      </span>
    )
  }
}
