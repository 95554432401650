import { createAction } from 'redux-actions'

const PREFIX = 'PROFILE_GROUP_MEMBERS'
const action = name => createAction(`${PREFIX}_${name}`)

export const init = action('INIT')
export const setIsLoading = action('SET_IS_LOADING')
export const setIsFetchingMembers = action('SET_IS_FETCHING_MEMBERS')
export const setIsFetchingUsers = action('SET_IS_FETCHING_USERS')
export const fetchMembers = action('FETCH_MEMBERS')
export const fetchUsers = action('FETCH_USERS')
export const setMemberIds = action('SET_MEMBER_IDS')
export const addUserIds = action('ADD_USER_IDS')
export const setAllUsersAreFetched = action('SET_ALL_USERS_ARE_FETCHED')
export const createMembership = action('CREATE_MEMBERSHIP')
export const deleteMembership = action('DELETE_MEMBERSHIP')
export const setNewEmailAddress = action('SET_NEW_EMAIL_ADDRESS')
