import React, {Component} from 'react'
import {connect} from 'react-redux'
import {hasPath} from 'ramda'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import deepCopy from 'deepcopy'

import Orm from 'app/framework/Orm'
import {SavedSearch, User} from 'app/models'
import InputBlock from 'app/common/InputBlock'
import Dropdown, {Option} from 'app/common/Dropdown'
import TextBox from 'app/common/TextBox'
import Button from 'app/common/Button'
import Table from 'app/common/Table'
import ConfirmationModal from 'app/common/modals/ConfirmationModal'
import SavedSearchInput from 'app/common/SavedSearchInput'
import CategoryDropdown from 'app/common/CategoryDropdown'
import * as globalSelectors from 'app/global/global-selectors'
import {searchCategory} from 'app/strings'
import {MAX_ITEMS_OPTIONS_NUMERIC, MAX_ITEMS_OPTIONS_NUMERIC_ALL} from 'app/email-notifications/email-notifications-constants'
import DjangoCSRFToken from 'django-react-csrftoken'
import {getUsersAdmin} from '../users-admin-selectors'
import {
  fetchSearches,
  showDeleteSearchesConfirmationModal,
  hideDeleteSearchesConfirmationModal,
  setSelectedSearchIds,
  setEditSearchData,
  saveSearch,
  resetEditSearchData,
  setNewSearchData,
  resetNewSearchData,
  createSearch,
  createSource,
  applyBulkSearchUpdates,
  deleteSearches,
} from '../users-admin-actions'

import './SearchesContent.less'


@connect(
  state => {
    const orm = Orm.withEntities(state.entities)
    const usersAdmin = getUsersAdmin(state)
    return {
      isPracticesEnabled: state.currentFirmIsPracticesEnabled,

      userModalData: usersAdmin.userModalData,
      editSearchData: usersAdmin.editSearchData,
      newSearchData: usersAdmin.newSearchData,
      selectedSearchIds: usersAdmin.selectedSearchIds,
      deleteSearchesConfirmationModalData: usersAdmin.deleteSearchesConfirmationModalData,
      isInAddSearchMode: usersAdmin.isInAddSearchMode,
      noticeFrequencies: usersAdmin.noticeFrequencies,
      user: orm.getById(User, usersAdmin.userModalData.userId),
      currentFirm: globalSelectors.getCurrentFirm(state),
    }
  },
  {
    fetchSearches,
    showDeleteSearchesConfirmationModal,
    hideDeleteSearchesConfirmationModal,
    setSelectedSearchIds,
    setEditSearchData,
    saveSearch,
    resetEditSearchData,
    setNewSearchData,
    resetNewSearchData,
    createSearch,
    createSource,
    applyBulkSearchUpdates,
    deleteSearches,
  },
)
export default class SearchesContent extends Component {
  state = {
    filters: {
      name: '',
      category: null,
      privacy: null,
    },
    bulkActions: {
      category: null,
      privacy: null,
      frequency: null,
      maxItems: {},
    }
  }

  componentDidMount() {
    // need to fetch every time to reflect possible changes made on the notifications tab.
    this.props.fetchSearches(this.props.user.id)
  }

  render() {
    const {CATEGORIES} = SavedSearch

    const omittedCategories = [CATEGORIES.TRUSTED, CATEGORIES.TRUSTED_UNCATEGORIZED]

    if (!this.props.isPracticesEnabled) {
      omittedCategories.push(CATEGORIES.PRACTICE)
    }

    const {
      editSearchData, noticeFrequencies, newSearchData, selectedSearchIds,
    } = this.props

    const searches = this.getFilteredSearches()

    const blankOption = {
      label: '--',
      value: null,
    }

    const frequencyOptions = [
      blankOption,
      ...noticeFrequencies.map(
        f => ({value: f[0], label: f[1]})
      ).filter(f => f.value !== 'priority_trigger')
    ]

    const maxItemsOptions = [
      blankOption,
      ...MAX_ITEMS_OPTIONS_NUMERIC,
    ]

    const maxItemsOptionsAll = [
      blankOption,
      ...MAX_ITEMS_OPTIONS_NUMERIC_ALL,
    ]

    const privacyOptions = [
      blankOption,
      {
        value: 'personal',
        label: 'Private',
      },
      {
        value: 'shared',
        label: 'Shared',
      }
    ]

    const deleteConfirmationModalMessage =
      this.props.deleteSearchesConfirmationModalData.ids.length === 1 ?
        'Are you sure you want to delete this search?'
        : `Are you sure you want to delete these ${this.props.deleteSearchesConfirmationModalData.ids.length} searches?`

    const deleteConfirmationModal =
      this.props.deleteSearchesConfirmationModalData.isOpen && (
        <ConfirmationModal
          message={deleteConfirmationModalMessage}
          secondaryMessage="This action cannot be undone!"
          confirmButtonText="Delete"
          onConfirm={() => {
            this.deleteSearches()
            this.props.hideDeleteSearchesConfirmationModal()
          }}
          onClose={() => this.props.hideDeleteSearchesConfirmationModal()}
          isDestructive={true}
        />
      )

    const autocompleteOptions =
      newSearchData.type === 'search' ?
        {
          category: ['client', 'firm', 'industry', 'practice', 'prospect', 'tracker', 'agency'],
          shared_only: true,
        } : {
              category: ['source'],
            }

    const autocompleteUrl = newSearchData.type === 'search'
      ? '/frontend-api/saved-search/autocomplete/'
      : '/frontend-api/feed/autocomplete/'

    const autocompleteInput =
      this.props.isInAddSearchMode ?
        <InputBlock label="&nbsp;">
          <SavedSearchInput
            url={autocompleteUrl}
            options={autocompleteOptions}
            onChange={values => this.handleAutocompleteChange(values)}
            allowFreeText={newSearchData.type === 'search'}
          />
        </InputBlock>
        : null

    const savedSearchAddDropdown =
      this.props.isInAddSearchMode
      ? null
      : <InputBlock label="&nbsp;">
          <Dropdown
            options={[
              {value: '', label: 'Add a new...'},
              {value: 'search', label: 'Search'},
              {value: 'source', label: 'Source'},
            ]}
            value={newSearchData.type || ''}
            onChange={value => this.setNewSearchType(value)}
          />
        </InputBlock>

    const savedSearchCancelButton =
      this.props.isInAddSearchMode
      ? <InputBlock label="&nbsp;">
          <Button
            label="Cancel"
            onClick={() => this.props.resetNewSearchData()}
          />
        </InputBlock>
      : null

    const searchesTrustedSourcesExport = <form id="searches_trusted_sources_export" method="post" action="/profile/firm/user_searches_and_trusted_sources_csv_export/" style={{ marginTop: '22px' }} onSubmit={(e) => this.exportSearchesAndTrustedSources(e)}>
      <DjangoCSRFToken />
      <button
        className="export-button"
        type="submit"
        style={{height:'30px'}}
      >
        <i className="fa icon-off fa-download fa-lg" />
        <span>Export Table</span>
      </button>
      <input id="export_searches" name="user_id" type="hidden" />
    </form>

    return (
      <div
        id="user-searches-content"
        className="users-admin-style"
      >
        {deleteConfirmationModal}

        <div className="header-row justify-left">
          <InputBlock label="Filter by Search Name">
            <TextBox
              value={this.state.filters.name}
              onChange={evt => this.handleNameFilterChange(evt.target.value)}
            />
          </InputBlock>

          <InputBlock label="Filter by Category" className="category-filter-block">
            <CategoryDropdown
              showAllCategories={true}
              omittedCategories={this.props.isPracticesEnabled ? [] : [CATEGORIES.PRACTICE]}
              onChange={value => this.handleCategoryFilterChange(value)}
            />
          </InputBlock>

          <InputBlock label="Filter by Privacy Setting">
            <Dropdown onChange={value => this.handlePrivacyFilterChange(value)}>
              <Option label="Shared & Private" value="" />
              <Option label="Shared" value={SavedSearch.SCOPES.SHARED} />
              <Option label="Private" value={SavedSearch.SCOPES.PERSONAL} />
            </Dropdown>
          </InputBlock>
        </div>

        <div className="header-row">
          <div className="bulk-actions">
            <InputBlock label="&nbsp;">
              <input
                type="checkbox"
                checked={selectedSearchIds.length === searches.length}
                onChange={evt => this.handleSelectAllCheckbox(evt)}
              />
            </InputBlock>

            <InputBlock
              label="Alert Frequency"
              className={classNames('alert-frequency-block', {disabled: selectedSearchIds.length === 0})}
            >
              <Dropdown
                value={this.state.bulkActions.frequency || null}
                options={frequencyOptions}
                autoIncludeBlankOption={true}
                disabled={selectedSearchIds.length === 0}
                onChange={value => this.handleBulkFrequencyChange(value)}
              />
            </InputBlock>

            <InputBlock
              label="Articles"
              className={classNames({disabled: selectedSearchIds.length === 0})}
            >
              <Dropdown
                value={this.bulkActionMaxItemsValue('defaultPubTypes')}
                options={maxItemsOptions}
                autoIncludeBlankOption={true}
                disabled={selectedSearchIds.length === 0}
                onChange={value => this.handleBulkMaxItemsChange('defaultPubTypes', value)}
              />
            </InputBlock>

            <InputBlock
              label="Filings"
              className={classNames({disabled: selectedSearchIds.length === 0})}
            >
              <Dropdown
                value={this.bulkActionMaxItemsValue('allFilings')}
                options={maxItemsOptions}
                autoIncludeBlankOption={true}
                disabled={selectedSearchIds.length === 0}
                onChange={value => this.handleBulkMaxItemsChange('allFilings', value)}
              />
            </InputBlock>

            <InputBlock
              label="Tweets"
              className={classNames({disabled: selectedSearchIds.length === 0})}
            >
              <Dropdown
                value={this.bulkActionMaxItemsValue('twitter')}
                options={maxItemsOptions}
                autoIncludeBlankOption={true}
                disabled={selectedSearchIds.length === 0}
                onChange={value => this.handleBulkMaxItemsChange('twitter', value)}
              />
            </InputBlock>

            <InputBlock
              label="Events"
              className={classNames({disabled: selectedSearchIds.length === 0})}
            >
              <Dropdown
                value={this.bulkActionMaxItemsValue('event')}
                options={maxItemsOptions}
                autoIncludeBlankOption={true}
                disabled={selectedSearchIds.length === 0}
                onChange={value => this.handleBulkMaxItemsChange('event', value)}
              />
            </InputBlock>

            <InputBlock
              label="Privacy"
              className={classNames({disabled: selectedSearchIds.length === 0})}
            >
              <Dropdown
                value={this.state.bulkActions.privacy || null}
                options={privacyOptions}
                autoIncludeBlankOption={true}
                disabled={selectedSearchIds.length === 0}
                onChange={value => this.handleBulkPrivacyChange(value)}
              />
            </InputBlock>

            <InputBlock label="&nbsp;">
              <Button
                label="Apply"
                className="in-line"
                disabled={!this.applyButtonEnabled()}
                onClick={() => this.handleApplyBulkActionsButton()}
              />
            </InputBlock>

            <InputBlock label="&nbsp;">
              <Button
                label="Delete"
                className="destructive delete in-line"
                disabled={selectedSearchIds.length === 0}
                onClick={() => this.showDeleteSearchesConfirmationModal(null, selectedSearchIds)}
              />
            </InputBlock>
          </div>

          <div style={{display:'flex',alignItems:'center'}}>
            {autocompleteInput}

            {savedSearchCancelButton}

            {savedSearchAddDropdown}

            {searchesTrustedSourcesExport}
          </div>
        </div>

        <Table
          data={searches}
          className="table"
        >
          <Table.Column
            name="checkbox"
            baseWidth={45}
            growRatio={0}
            shrinkRatio={0}
            cellContents={search =>
              <input
                className="checkbox"
                type="checkbox"
                onChange={(evt) => this.handleSearchCheckbox(evt, search.id)}
                checked={selectedSearchIds.includes(search.id)}
              />
            }
            className="no-border"
          />

          <Table.Column
            name="name"
            isSortable
            label="Search Name"
            baseWidth={220}
            growRatio={2}
            cellContents={search => {
              if (editSearchData.id === search.id && search.category !== 'trusted-uncategorized') {
                return (
                  <TextBox
                    value={editSearchData.name || search.name}
                    onChange={evt => this.handleNameChange(evt)}
                  />
                )
              } else {
                return search.name
              }
            }}
          />

          <Table.Column
            name="category"
            isSortable
            label="Category"
            baseWidth={20}
            growRatio={2}
            cellContents={search => {
              if (editSearchData.id === search.id && !['trusted', 'trusted-uncategorized'].includes(search.category)) {
                return (
                  <CategoryDropdown
                    value={editSearchData.category || search.category}
                    onChange={value => this.handleCategoryChange(value)}
                    omittedCategories={omittedCategories}
                  />
                )
              } else {
                return searchCategory(
                  search.category,
                  {currentFirm: this.props.currentFirm},
                )
              }
            }}
          />

          <Table.Column
            name="frequency"
            isSortable
            label="Alert Frequency"
            baseWidth={40}
            growRatio={2}
            cellContents={search => {
              if (editSearchData.id === search.id) {
                return (
                  <Dropdown
                    options={frequencyOptions}
                    value={this.getSelectedFrequency(search)}
                    onChange={value => this.handleFrequencyChange(value)}
                  />
                )
              } else {
                return search.noticeConfig.frequencyDisplay
              }
            }}
          />

          <Table.Column
            name="maxArticles"
            isSortable
            label="Articles"
            baseWidth={20}
            growRatio={1}
            cellContents={search => {
              if (editSearchData.id === search.id) {
                return (
                  <Dropdown
                    options={
                      ['trusted', 'trusted-uncategorized'].includes(search.category)
                        ? maxItemsOptionsAll
                        : maxItemsOptions
                    }
                    value={this.currentNoticeConfigMaxItemsValue('defaultPubTypes', search)}
                    onChange={value => this.handleMaxItemsChange('defaultPubTypes', value)}
                  />
                )
              } else {
                return search.noticeConfig.maxItems.defaultPubTypes
              }
            }}
          />

          <Table.Column
            name="maxFilings"
            isSortable
            label="Filings"
            baseWidth={20}
            growRatio={1}
            cellContents={search => {
              if (editSearchData.id === search.id) {
                return (
                  <Dropdown
                    options={
                      ['trusted', 'trusted-uncategorized'].includes(search.category)
                        ? maxItemsOptionsAll
                        : maxItemsOptions
                    }
                    value={this.currentNoticeConfigMaxItemsValue('allFilings', search)}
                    onChange={value => this.handleMaxItemsChange('allFilings', value)}
                  />
                )
              } else {
                return search.noticeConfig.maxItems.allFilings
              }
            }}
          />

          <Table.Column
            name="maxTweets"
            isSortable
            label="Tweets"
            baseWidth={20}
            growRatio={1}
            cellContents={search => {
              if (editSearchData.id === search.id) {
                return (
                  <Dropdown
                    options={
                      ['trusted', 'trusted-uncategorized'].includes(search.category)
                        ? maxItemsOptionsAll
                        : maxItemsOptions
                    }
                    value={this.currentNoticeConfigMaxItemsValue('twitter', search)}
                    onChange={value => this.handleMaxItemsChange('twitter', value)}
                  />
                )
              } else {
                return search.noticeConfig.maxItems.twitter
              }
            }}
          />

          <Table.Column
            name="maxEvents"
            isSortable
            label="Events"
            baseWidth={20}
            growRatio={1}
            cellContents={search => {
              if (editSearchData.id === search.id) {
                return (
                  <Dropdown
                    options={
                      ['trusted', 'trusted-uncategorized'].includes(search.category)
                        ? maxItemsOptionsAll
                        : maxItemsOptions
                    }
                    value={this.currentNoticeConfigMaxItemsValue('event', search)}
                    onChange={value => this.handleMaxItemsChange('event', value)}
                  />
                )
              } else {
                return search.noticeConfig.maxItems.event
              }
            }}
          />

          <Table.Column
            name="scope"
            isSortable
            label="Privacy"
            baseWidth={20}
            growRatio={1}
            cellContents={search => {
              if (editSearchData.id === search.id) {
                return (
                  <span>
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={'scope' in editSearchData ? editSearchData.scope === 'personal' : search.scope === 'personal'}
                      onChange={evt => this.handleScopeChange(evt)}
                    />
                    <span>Private</span>
                  </span>
                )
              } else {
                return search.scope === 'shared' ? 'Shared' : 'Private'
              }
            }}
          />

          <Table.Column
            name="actions"
            baseWidth={100}
            growRatio={0}
            cellContents={search => {
              if (editSearchData.id === search.id) {
                return (
                  <span>
                    <a
                      className="action-link"
                      onClick={evt => this.save(evt)}
                    >
                      <i className="fa fa-check-circle-o" aria-hidden="true" title="Save"/>
                    </a>

                    <a
                      className="action-link"
                      onClick={evt => this.cancelEdit(evt)}
                    >
                      <i className="fa fa-times-circle-o" aria-hidden="true" title="Cancel"/>
                    </a>
                  </span>
                )
              } else {
                return (
                  <span>
                    <a
                      className="action-link"
                      onClick={evt => this.setupEdit(evt, search.id)}
                    >
                      <i className="fa fa-pencil" aria-hidden="true" title="Edit"/>
                    </a>

                    <a
                      className="action-link"
                      onClick={evt => this.showDeleteSearchesConfirmationModal(evt, [search.id])}
                    >
                      <i className="fa fa-trash-o" aria-hidden="true" title="Delete"/>
                    </a>
                  </span>
                )
              }
            }}
          />
        </Table>
      </div>
    )
  }

  exportSearchesAndTrustedSources(e) {
    e.preventDefault()
    const form = e.target
    form.value = this.props.user.id
    form.action = '/profile/firm/user_searches_and_trusted_sources_csv_export/'
    document.getElementById('export_searches').value = this.props.user.id
    form.submit()
  }

  getSelectedFrequency(search) {
    const {editSearchData} = this.props
    let freq = editSearchData.hasOwnProperty('noticeConfig') && editSearchData.noticeConfig.hasOwnProperty('frequency')
      ? editSearchData.noticeConfig.frequency
      : search.noticeConfig.frequency
    if (freq === 'priority_trigger') {
      freq = 'none'
    }
    return freq
  }

  getFilteredSearches() {
    return this.props.userModalData.searches.filter(s => {
      return (
        (!this.state.filters.name || s.name.toLowerCase().includes(this.state.filters.name.toLowerCase())) &&
        (!this.state.filters.category || s.category === this.state.filters.category) &&
        (!this.state.filters.privacy || s.scope === this.state.filters.privacy)
      )
    })
  }

  handleNameFilterChange(value) {
    const filters = {...this.state.filters}
    filters.name = value
    this.setState({filters})
    this.props.setSelectedSearchIds([])
  }

  handleCategoryFilterChange(value) {
    const filters = {...this.state.filters}
    filters.category = value
    this.setState({filters})
    this.props.setSelectedSearchIds([])
  }

  handlePrivacyFilterChange(value) {
    const filters = {...this.state.filters}
    filters.privacy = value
    this.setState({filters})
    this.props.setSelectedSearchIds([])
  }

  currentNoticeConfigMaxItemsValue(key, search) {
    const { editSearchData } = this.props
    if (hasPath(['noticeConfig', 'maxItems', key], editSearchData)) {
      return editSearchData.noticeConfig.maxItems[key]
    }
    return search.noticeConfig.maxItems[key]
  }

  bulkActionMaxItemsValue(key) {
    const maxItems = this.state.bulkActions.maxItems
    return maxItems.hasOwnProperty(key) ? maxItems[key] : null
  }
  applyButtonEnabled() {
    const {selectedSearchIds} = this.props
    const {bulkActions} = this.state
    return (
      selectedSearchIds.length > 0 &&
      (
        bulkActions.frequency ||
        bulkActions.privacy ||
        Object.keys(bulkActions.maxItems).length > 0
      )
    )
  }

  showDeleteSearchesConfirmationModal(evt, ids) {
    if (evt) {
      evt.preventDefault()
    }
    this.props.showDeleteSearchesConfirmationModal(ids)
  }

  deleteSearches() {
    const ids = [...this.props.deleteSearchesConfirmationModalData.ids]
    this.props.deleteSearches({searchIds: ids})
  }

  setupEdit(evt, id) {
    evt.preventDefault()
    this.props.setEditSearchData({id})
  }

  handleNameChange(evt) {
    const { editSearchData } = this.props
    this.props.setEditSearchData({...editSearchData, name: evt.target.value})
  }

  handleCategoryChange(value) {
    const { editSearchData } = this.props
    this.props.setEditSearchData({
      ...editSearchData,
      category: value,
    })
  }

  handleFrequencyChange(value) {
    const { editSearchData } = this.props
    this.props.setEditSearchData({
      ...editSearchData,
      noticeConfig: {
        ...editSearchData.noticeConfig || {},
        frequency: value,
      }
    })
  }

  handleMaxItemsChange(key, value) {
    const editSearchData = {...this.props.editSearchData}
    editSearchData.noticeConfig = {
      ...editSearchData.noticeConfig || {}
    }
    editSearchData.noticeConfig.maxItems = {
      ...editSearchData.noticeConfig.maxItems || {}
    }
    editSearchData.noticeConfig.maxItems[key] = value
    this.props.setEditSearchData(editSearchData)
  }

  handleScopeChange(evt) {
    const { editSearchData } = this.props
    this.props.setEditSearchData({...editSearchData, scope: evt.target.checked ? 'personal' : 'shared'})
  }

  save() {
    const { editSearchData } = this.props
    this.props.saveSearch({...editSearchData, userId: this.props.user.id})
  }

  cancelEdit() {
    this.props.resetEditSearchData()
  }

  setNewSearchType(value) {
    if (value) {
      const newSearchData = {...this.props.newSearchData}
      newSearchData.type = value
      this.props.setNewSearchData(newSearchData)
    } else {
      this.props.resetNewSearchData()
    }
  }

  handleAutocompleteChange(values) {
    if (values.length === 0) {
      return
    }
    // we only allow adding one at a time
    const value = values[0]
    let data = {
      userId: this.props.user.id
    }
    if (this.props.newSearchData.type === 'search') {
      if (value.isFreeText) {
        data.searchPhrase = value.value
      } else {
        data.searchId = value.value
      }
      this.props.createSearch(data)
    } else {
      data.feedId = value.value
      this.props.createSource(data)
    }
  }

  handleSearchCheckbox(evt, id) {
    const selectedSearchIds = [...this.props.selectedSearchIds]
    if (evt.target.checked) {
      selectedSearchIds.push(id)
    } else {
      const idx = selectedSearchIds.indexOf(id)
      selectedSearchIds.splice(idx, 1)
    }
    this.props.setSelectedSearchIds(selectedSearchIds)
  }

  handleSelectAllCheckbox(evt) {
    const searches = this.getFilteredSearches()
    let selectedSearchIds = []
    if (evt.target.checked) {
      selectedSearchIds = searches.map(search => {
        return search.id
      })
    }
    this.props.setSelectedSearchIds(selectedSearchIds)
  }

  handleBulkFrequencyChange(value) {
    const bulkActions = deepCopy(this.state.bulkActions)
    bulkActions.frequency = value
    this.setState({bulkActions})
  }

  handleBulkMaxItemsChange(key, value) {
    const bulkActions = deepCopy(this.state.bulkActions)
    if (value === null) {
      if (bulkActions.maxItems.hasOwnProperty(key)) {
        delete bulkActions.maxItems[key]
      }
    } else {
      bulkActions.maxItems[key] = value
    }
    this.setState({bulkActions})
  }

  handleBulkPrivacyChange(value) {
    const bulkActions = deepCopy(this.state.bulkActions)
    bulkActions.privacy = value
    this.setState({bulkActions})
  }

  handleApplyBulkActionsButton() {
    const updates = {}
    if (this.state.bulkActions.privacy) {
      updates.scope = this.state.bulkActions.privacy
    }
    if (this.state.bulkActions.frequency || Object.keys(this.state.bulkActions.maxItems).length > 0) {
      const noticeConfig = {}
      if (this.state.bulkActions.frequency) {
        noticeConfig.frequency = this.state.bulkActions.frequency
      }
      if (Object.keys(this.state.bulkActions.maxItems).length > 0) {
        noticeConfig.maxItems = this.state.bulkActions.maxItems
      }
      updates.noticeConfig = noticeConfig
    }
    this.props.applyBulkSearchUpdates({
      userId: this.props.user.id,
      searchIds: this.props.selectedSearchIds,
      updates,
    })
    this.setState({
      bulkActions: {
        category: null,
        privacy: null,
        frequency: null,
        maxItems: {},
      }
    })
  }
}
