import React from 'react'
import {render} from 'react-dom'

import AppWrapper from 'app/global/app-wrapper'
import store from 'app/store'
import {changeCaseObject} from 'app/utils'

import * as actions from './comparison-actions'
import * as constants from './comparison-constants'
import reducer from './comparison-reducer'
import saga from './comparison-saga'
import Comparison from './Comparison'


export function init(data) {
  store.dispatch(actions.init(changeCaseObject.camelCase(data)))
  const component = (
    <AppWrapper>
      <Comparison />
    </AppWrapper>
  )
  const container = document.getElementById('comparison-frame')
  render(component, container)
}

export {actions, constants, reducer, saga}
