import React from 'react'

import Dropdown from 'app/common/Dropdown'
import InputBlock from 'app/common/InputBlock'
import TextBox from 'app/common/TextBox'

import { ACTIVE_FILTER } from './users-admin-constants'
import { topCountriesList } from "app/common/utils"

export default function Filters({
  isGroupPage,
  availableGroups,
  firmLocations,
  departments,
  teams,
  activeFilter,
  fetchUsers,
  setActiveFilter,
  fetchGroups,
  countries
}) {
  const nameFilterInputPlaceholder = `${isGroupPage ? 'Group' : ''} Name`
  
  const topCountries = topCountriesList(countries);

  const countryOptions = topCountries.concat(countries).map(c => {return {value: c.id, label: c.name}})

  const nameFilter =
    <div className="name-filter-container">
      <label>Filter by {isGroupPage ? 'Name' : 'User'}</label>
      <TextBox
        placeholder={nameFilterInputPlaceholder}
        className="name-filter-input"
        onChange={(evt) => fetchUsers({name: evt.target.value})}
      />
    </div>

  const privateFilter =
    isGroupPage &&
    <InputBlock label="Private Only" className="private-filter-container" isInline>
      <input
        type="checkbox"
        onChange={evt => fetchUsers({isPrivateGroup: evt.target.checked})}
      />
    </InputBlock>

  const groupFilterOptions = [{value: 0, label: '--------'}].concat(
    availableGroups.map(g => {return {value: g.id, label: g.name}})
  )

  const firmLocationOptions = [{value: 0, label: 'All Locations'}].concat(
    firmLocations.map(l => {return {value: l.id, label: l.description}})
  )

  const departmentOptions = [{value: 0, label: 'All Departments'}].concat(
    departments.map(d => {return {value: d.id, label: d.name}})
  )

  const teamOptions = [{value: 0, label: 'All Teams'}].concat(
    teams.map(t => {return {value: t.id, label: t.name}})
  )

  const groupFilter =
    !isGroupPage &&
    <div className="filter-select-container">
      <label htmlFor="groups">Filter by Group</label>
      <Dropdown
        options={groupFilterOptions}
        className="filter-select"
        onChange={(value) => fetchUsers({groupId: value})}
      />
    </div>

    const firmLocationFilter =
      !isGroupPage &&
      <div className="filter-select-container">
        <label>Filter by Office Location</label>
        <Dropdown
          options={firmLocationOptions}
          className="filter-select"
          onChange={(value) => fetchUsers({firmLocationId: value})}
        />
      </div>

  const groupfirmLocationFilter =
    isGroupPage &&
    <div className="filter-select-container">
      <label>Filter by Office Location</label>
      <Dropdown
        options={firmLocationOptions}
        className="filter-select"
        onChange={(value) => fetchGroups({ firmLocationId: value })}
      />
    </div>

    const groupCountry =
      isGroupPage &&
      <div className="filter-select-container">
        <label>Country</label>
        <Dropdown
          options={countryOptions}
          className="filter-select"
          onChange={(value) => fetchGroups({ locationId: value })}
        />
      </div>

    const departmentFilter =
      !isGroupPage &&
      <div className="filter-select-container">
        <label>Filter by Department</label>
        <Dropdown
          options={departmentOptions}
          className="filter-select"
          onChange={(value) => fetchUsers({departmentId: value})}
        />
      </div>

    const teamFilter =
      !isGroupPage &&
      <div className="filter-select-container">
        <label>Filter by Team</label>
        <Dropdown
          options={teamOptions}
          className="filter-select"
          onChange={(value) => fetchUsers({teamId: value})}
        />
      </div>

  return (
    <div className="filters-container">
      {nameFilter}

      {groupFilter}

      {firmLocationFilter}

      {groupfirmLocationFilter}

      {groupCountry}

      {privateFilter}

      {departmentFilter}

      {teamFilter}

      <div style={{ marginLeft: "auto" }}>
        <div className="stacked">
          <input
            type="radio"
            value={ACTIVE_FILTER.ACTIVE}
            name="user_active"
            checked={activeFilter === ACTIVE_FILTER.ACTIVE}
            onChange={() => setActiveFilter(ACTIVE_FILTER.ACTIVE)}
          /> Active
        </div>

        <div className="stacked">
          <input
            type="radio"
            value={ACTIVE_FILTER.INACTIVE}
            name="user_active"
            checked={activeFilter === ACTIVE_FILTER.INACTIVE}
            onChange={() => setActiveFilter(ACTIVE_FILTER.INACTIVE)}
          /> Inactive
        </div>

        <div className="stacked">
          <input
            type="radio"
            value={ACTIVE_FILTER.ADMIN}
            name="user_active"
            checked={activeFilter === ACTIVE_FILTER.ADMIN}
            onChange={() => setActiveFilter(ACTIVE_FILTER.ADMIN)}
          /> Admin
        </div>
      </div>
    </div>
  )

}

