import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import Dropdown, {Option} from 'app/common/Dropdown'

import * as actions from '../../profile-preferences-actions'
import * as selectors from '../../profile-preferences-selectors'

import styles from '../common/dropdown.less'


export default function SubscriptionContent() {
  const firmDefault = useSelector(selectors.getSubscriptionContentFirmDefault)
  const value = useSelector(selectors.getIncludeSubscriptionContent)
  const isLoading = useSelector(selectors.getSubscriptionContentLoading)
  const dispatch = useDispatch()
  const onChange = value => {
    dispatch(actions.changeSubscriptionContentSettingRequest(value))
  }
  return (
    <Dropdown
      value={value}
      disabled={isLoading}
      onChange={onChange}
      className={styles.dropdown}
    >
      <Option
        value={null}
        label={`Use Firm Default (${firmDefault ? 'Show' : 'Hide'})`}
      />
      <Option value={true} label="Show" />
      <Option value={false} label="Hide" />
    </Dropdown>
  )
}
