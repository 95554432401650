import classNames from 'classnames'
import PropTypes from 'prop-types'
import {range} from 'ramda'
import React, { Component } from 'react'

import { urlFromGroup, urlFromSavedSearch } from './helpers'


const MAX_COLUMNS = 4
const ITEMS_PER_COLUMN = 15


export default function SearchList({category, searches}) {
  if (!searches.length) return null

  // An array of the rendered search items
  let searchItems

  if (category == 'trusted') {
    const categorizedSearches = searches
      .filter(search => search.category == 'trusted')
    const uncategorizedSearches = searches
      .filter(search => search.category == 'trusted-uncategorized')

    if (categorizedSearches.length && uncategorizedSearches.length) {
      searchItems = [
        ...categorizedSearches.map(searchListItem),
        <span className="header" key="header" />,
        ...uncategorizedSearches.map(searchListItem),
      ]
    } else {
      searchItems = [
        ...categorizedSearches.map(searchListItem),
        ...uncategorizedSearches.map(searchListItem),
      ]
    }
  }

  else if (category == 'client') {
    const clientSearches = searches
      .filter(search => search.category == 'client')
    const prospectSearches = searches
      .filter(search => search.category == 'prospect')
    searchItems = [
      ...clientSearches.map(searchListItem),
      <span className="header" key="header">Prospects</span>,
      ...prospectSearches.map(searchListItem),
    ]
    if (!prospectSearches.length) {
      // Remove the "Prospects" header if there are no prospects
      searchItems.pop()
    }
  }

  else {
    searchItems = searches.map(searchListItem)
  }

  const columnCount = Math.min(
    MAX_COLUMNS,
    Math.ceil(searchItems.length / ITEMS_PER_COLUMN),
  )
  const itemsPerColumn = Math.ceil(searchItems.length / columnCount)

  const lists = range(0, columnCount)
    .map(index => (
      <ul key={index}>
        {
          searchItems.slice(
            index * itemsPerColumn,
            (index + 1) * itemsPerColumn,
          )
        }
      </ul>
    ))

  return (
    <div>
      {lists}
    </div>
  )
}
SearchList.propTypes = {
  category: PropTypes.string,
  searches: PropTypes.arrayOf(PropTypes.object).isRequired,
}


function searchListItem(search) {
  const isGroup = !search.category
  const name = isGroup ? search.displayName : search.name
  const url = isGroup ? urlFromGroup(search) : urlFromSavedSearch(search)
  return (
    <li
      key={search.id}
      className={classNames({folder: search.category == 'trusted'})}
    >
      <a
        href={url + '?usrc=nav&usrc_s=primary'}
        className={classNames({current: isCurrentSearch(search)})}
      >
        <span className="search-label">{name}</span>
      </a>
    </li>
  )
}


function isCurrentSearch(search) {
  return urlFromSavedSearch(search) === window.location.pathname
}
