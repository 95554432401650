import classNames from 'classnames'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { Component } from 'react'

import Dropdown from 'app/common/Dropdown'
import Table from 'app/common/Table'


const COLUMNS = {
  CHECKBOX: 'checkbox',
  NAME: 'name',
  ADDED_TYPE: 'addedType',
  ASSIGNMENT_TYPE: 'assignmentType',
  ACTIONS: 'actions',
}

const ADDED_TO_TYPES = {
  PROMOTED_TRUSTED: 'promotedTrusted',
  PROMOTED: 'promoted',
  DEMOTED: 'demoted',
  TRUSTED: 'trusted',
  EXCLUDED: 'excluded',
}


export default class AssignmentsTable extends Component {
  static propTypes = {
    assignments: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedAssignmentIDs: PropTypes.arrayOf(PropTypes.number),
    onSelectStateChange: PropTypes.func,

    // The type signature for all of the below functions is:
    // [Assignment] -> ()

    onPromote: PropTypes.func,
    onDemote: PropTypes.func,
    onTrust: PropTypes.func,
    onPromoteAndTrust: PropTypes.func,
    onExclude: PropTypes.func,
    onRemove: PropTypes.func,
  }

  static defaultProps = {
    selectedAssignmentIDs: [],
    onSelectStateChange() {},
    onPromote() {},
    onDemote() {},
    onTrust() {},
    onPromoteAndTrust() {},
    onExclude() {},
    onRemove() {},
  }

  state = {
    // The ID of the assignment that is currently expanded, or null
    expandedAssignmentId: null,
  }

  // React methods

  render() {
    return (
      <Table
        defaultSort={{column: COLUMNS.NAME, direction: 'asc'}}
        data={this.props.assignments}
      >
        <Table.Column
          name={COLUMNS.CHECKBOX}
          baseWidth={45}
          growRatio={0}
          shrinkRatio={0}
          cellContents={assignment =>
            <input
              type="checkbox"
              className="select-assignment-checkbox"
              onChange={evt => this.onSelectStateChange(assignment, evt)}
            />
          }
        />

        <Table.Column
          name={COLUMNS.NAME}
          label="Name"
          isSortable
          sortBy={
            R.pipe(
              R.path(['assignedTo', 'displayName']),
              R.trim,
              R.toLower,
            )
          }
          baseWidth={150}
          growRatio={2}
          cellContents={assignment => {
            const user = assignment.assignedTo
            const membershipCount = user.isGroup ? user.userMemberships.length : 0
            const memberCount = user.isGroup
              ? `(${membershipCount} user${membershipCount === 1 ? '' : 's'})`
              : null
            const expandButton = assignment.assignmentType === 'user'
              ? null
              : <span
                  className="expand-arrow"
                  onClick={() => this.toggleExpandAssignment(assignment)}
                />
            const expanded = assignment.id === this.state.expandedAssignmentId
            const expandedContent = expanded
              ? <div className="expanded-content">
                  {this.renderExpandedContent(assignment)}
                </div>
              : null

            return (
              <div className={classNames('cell-content', {expanded})}>
                {expandButton}
                {assignment.assignedTo.displayName} {memberCount}
                {expandedContent}
              </div>
            )
          }}
        />

        <Table.Column
          name={COLUMNS.ASSIGNMENT_TYPE}
          label="Assignment Type"
          isSortable
          sortBy={assignment => _addedToType(assignment)}
          baseWidth={120}
          growRatio={1}
          cellContents={assignment => {
            const addedToType = _addedToType(assignment)
            const options = [
              {
                value: ADDED_TO_TYPES.PROMOTED,
                label: 'Promoted',
              },
              {
                value: ADDED_TO_TYPES.DEMOTED,
                label: 'Demoted',
              },
              {
                value: ADDED_TO_TYPES.TRUSTED,
                label: 'Trusted',
              },
              {
                value: ADDED_TO_TYPES.PROMOTED_TRUSTED,
                label: 'Promoted & Trusted',
              },
              {
                value: ADDED_TO_TYPES.EXCLUDED,
                label: 'Excluded',
              },
            ]
            const funcFromType = (type) => {
              return {
                [ADDED_TO_TYPES.PROMOTED]: this.props.onPromote,
                [ADDED_TO_TYPES.DEMOTED]: this.props.onDemote,
                [ADDED_TO_TYPES.TRUSTED]: this.props.onTrust,
                [ADDED_TO_TYPES.PROMOTED_TRUSTED]: this.props.onPromoteAndTrust,
                [ADDED_TO_TYPES.EXCLUDED]: this.props.onExclude,
              }[type]
            }
            return (
              <Dropdown
                options={options}
                value={addedToType}
                // This is a little complicated, but it basically just calls
                // the corresponding callback prop for each addedTo type.
                onChange={type => funcFromType(type)([assignment])}
              />
            )
          }}
        />

        <Table.Column
          name={COLUMNS.ADDED_TYPE}
          label="Type"
          isSortable
          sortBy={assignment => _assignmentTypeLabel(assignment.assignmentType)}
          baseWidth={100}
          growRatio={1}
          cellContents={assignment =>
            _assignmentTypeLabel(assignment.assignmentType)
          }
        />

        <Table.Column
          name={COLUMNS.ACTIONS}
          baseWidth={80}
          growRatio={0}
          cellContents={assignment =>
            <a
              className="action remove"
              onClick={() => this.props.onRemove([assignment])}
            >
              Remove
            </a>
          }
        />
      </Table>
    )
  }

  // Render helpers

  renderExpandedContent(assignment) {
    let names = assignment.assignedTo.userMemberships
      .map(membership => membership.user.displayNameLastFirst)
    if (!names.length) {
      names = ['This group has no members.']
    }
    const sortedNames = R.sortBy(name => name.toLowerCase(), names)
    return sortedNames.map((name, idx) => <div key={idx} className="name">{name}</div>)
  }

  // Event handlers

  onSelectStateChange(assignment, event) {
    this.props.onSelectStateChange(assignment, event.target.checked)
  }

  // State management

  toggleExpandAssignment(assignment) {
    if (assignment.id === this.state.expandedAssignmentId) {
      this.setState({expandedAssignmentId: null})
    } else {
      this.setState({expandedAssignmentId: assignment.id})
    }
  }
}


function _addedToType(assignment) {
  if (assignment.isPromoted && assignment.isTrusted) {
    return ADDED_TO_TYPES.PROMOTED_TRUSTED
  }
  if (assignment.isPromoted) {
    return ADDED_TO_TYPES.PROMOTED
  }
  if (assignment.isDemoted) {
    return ADDED_TO_TYPES.DEMOTED
  }
  if (assignment.isTrusted) {
    return ADDED_TO_TYPES.TRUSTED
  }
  if (assignment.isExcluded) {
    return ADDED_TO_TYPES.EXCLUDED
  }
  throw new Error(`The assignment with ID ${assignment.id} is neither promoted, demoted, trusted, 
    nor excluded.`)
}


function _assignmentTypeLabel(assignmentType) {
  return {
    user: 'User',
    group: 'Group',
    member: 'Individual Members',
  }[assignmentType]
}
