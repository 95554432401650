import ErrorStackParser from 'error-stack-parser'
import is from 'is'
import {omit, path} from 'ramda'
import StackTrace from 'stacktrace-js'

import {request} from 'app/api'
import {HTTP_REQUEST_HEADER_ID_NAME} from 'app/constants'
import {changeCaseObject} from 'app/utils'
import {ignorePromise} from 'app/utils/promises'

/**
 * The names of properties that may be present on standard Error objects that we
 * want to refrain from handling in any special way.
 */
const STANDARD_ERROR_PROPS = [
  'message',
  'description', // alias for `message` in IE
  'stack',
  'fileName',
  'lineNumber',
  'columnNumber',
]

export async function logErrorEvent(error) {
  if (process.env.NODE_ENV !== 'production') return

  // Apparently some code out in the wild (such as Highcharts [now unused]) throws strings
  // as errors without creating an actual Error object, so we need to handle
  // that case.
  if (is.string(error)) {
    return logErrorRequest({message: error})
  }

  let stackFrames
  try {
    stackFrames = await StackTrace.fromError(error)
  }
  catch (parseError) {
    // This error doesn't give us any useful information, so just log it to the
    // console and move on.
    console.error(parseError)
  }
  const data = {
    // The message itself doesn't include the type of error (TypeError,
    // ReferenceError, etc), but `toString` does.
    message: error.message.toString(),
  }
  if (stackFrames) {
    data.stackFrames = stackFrames.map(stackFrame => stackFrame.toString())
  }
  else if (error.stack) {
    // If we couldn't load source maps above, try to parse the `error.stack`
    // property directly, if it exists.
    try {
      data.stackFrames = ErrorStackParser
        .parse(error)
        .map(stackFrame => stackFrame.toString())
    }
    catch (parseError) {
      // If we can't parse that, fall back to just dumping the stack string
      // directly.
      console.error(parseError)
      data.stackFrames = error.stack.split('\n')
      // In IE (and possibly other browsers), the first line of the stack
      // includes the error message, which would be redundant here, so we
      // filter it out and dedent the proceeding lines.
      if (data.stackFrames[0].includes(error.message)) {
        data.stackFrames = data.stackFrames
          .slice(1)
          .map(stackFrame => stackFrame.trim())
      }
    }
  }
  const additionalErrorData = omit(STANDARD_ERROR_PROPS, error)
  if (!is.empty(additionalErrorData)) {
    data.additionalErrorData = additionalErrorData
  }
  return logErrorRequest(data)
}

function logErrorRequest(errorData) {
  if (process.env.NODE_ENV !== 'production') return

  errorData = {
    message: errorData.message,
    stackFrames: errorData.stackFrames,
    stack: errorData.stack,
    additionalErrorData: errorData.additionalErrorData,
    // Add the current page location for easier debugging.
    url: window.location.href,
  }
  request({
    url: '/api/frontend-error/',
    method: 'POST',
    data: changeCaseObject.snakeCase(errorData),
    withCsrfToken: true,
    // If this error was due to a failed request, insert that request's UUID
    // here so we can link the two in our logs.
    uuid: path(
      [
        'response',
        'req',
        'header',
        HTTP_REQUEST_HEADER_ID_NAME.toLowerCase().replace('-', '_'),
      ],
      errorData,
    ),
  })
}

const oldErrorFunc = window.onerror
window.onerror = (messageOrEvent, source, lineNum, colNum, error) => {
  ignorePromise(logErrorEvent(error))
  if (oldErrorFunc) {
    oldErrorFunc(messageOrEvent, source, lineNum, colNum, error)
  }
}
