import invariant from 'invariant'

import {
  GROUPING_LEVELS,
  RELEVANCY_LEVELS,
  NUMBER_OF_SEARCH_RESULTS,
} from 'app/search-results-page/search-results-page-constants'


export function subscriptionContentSettingLabel(value) {
  if (value === null) {
    return 'Firm Default'
  }
  if (value === true) {
    return 'Show'
  }
  if (value === false) {
    return 'Hide'
  }
  throw new Error(`Invalid subscription content setting: '${value}'.`)
}


export function relevanceLabel(relevance) {
  if (!relevance) {
    return 'Firm Default'
  }
  const label = {
    [RELEVANCY_LEVELS.LOW]: 'Low',
    [RELEVANCY_LEVELS.MEDIUM]: 'Medium',
    [RELEVANCY_LEVELS.HIGH]: 'High',
  }[relevance]
  invariant(label, `Invalid relevance level: '${relevance}'.`)
  return label
}


export function groupingLabel(groupingLevel) {
  if (!groupingLevel) {
    return 'Firm Default'
  }
  const label = {
    [GROUPING_LEVELS.LIMITED]: 'Limited',
    [GROUPING_LEVELS.LESS]: 'Less',
    [GROUPING_LEVELS.STANDARD]: 'Standard',
    [GROUPING_LEVELS.MORE]: 'More',
  }[groupingLevel]
  invariant(label, `Invalid grouping level: '${groupingLevel}'.`)
  return label
}


export function dashboardOrderLabel(orderOption) {
  const string = {
    trending: 'Trending Order (Focused on Recent Activity)',
    email: 'Utilize Email Notification Order',
  }[orderOption]
  invariant(string, `Unknown order option '${orderOption}'.`)
  return string
}

export function numberOfSearchResults(number) {
  if (!number) {
    return 'Twenty'
  }
  const label = {
    [NUMBER_OF_SEARCH_RESULTS.TWENTY]: 'Twenty',
    [NUMBER_OF_SEARCH_RESULTS.SIXTY]: 'Sixty',
    [NUMBER_OF_SEARCH_RESULTS.HUNDRED]: 'Hundred',
  }[number]
  invariant(label, `Invalid number of search results: '${number}'.`)
  return label
}
