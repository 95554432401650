import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {titleCase} from "title-case"
import classNames from "classnames"
import * as constants from "app/profile-refresh/profile-refresh-constants"
import InputBlock from "app/common/InputBlock"


export default class ReviewSearchChanges extends Component {
  static propTypes = {
    categories: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    userSearchesToRemove: PropTypes.array.isRequired,
    userSearchesToAdd: PropTypes.array.isRequired,
    currentStep: PropTypes.number.isRequired,
    setCurrentStep: PropTypes.func.isRequired,
    setUserSearchesToRemove: PropTypes.func.isRequired,
    setUserSearchesToAdd: PropTypes.func.isRequired,
    profileRefreshInterval: PropTypes.number.isRequired,
    setProfileRefreshInterval: PropTypes.func.isRequired,
  }

  render() {
    const { user, categories, userSearchesToRemove, userSearchesToAdd } = this.props

    const reviewSearches = (category, addOrRemove) => {
      const categoryName = category.category
      let categorySearches = []
      if (addOrRemove === 'remove') {
        const categoryRemoveSearches = categoryName === 'source'
          ? userSearchesToRemove.filter(ss => ss.category === 'trusted'
            || ss.category === 'trusted-uncategorized')
          : userSearchesToRemove.filter(ss => ss.category === categoryName)
        categoryRemoveSearches.map(search => {
          categorySearches.push({id: search.id, name: search.name, categoryLabel: category.label,
            action: 'deleting'})
        })
      } else if (addOrRemove === 'add') {
        userSearchesToAdd.map(cat => {
          if (cat[categoryName]) {
            cat[categoryName].map(search => {
              categorySearches.push({id: search.value, name: search.label,
                categoryLabel: category.label, action: 'adding'})
            })
          }
        })
      }

      return(
        categorySearches.map(ss =>
          <div
            key={ss.id} className="refresh-existing-searches-row"
            onClick={() => this.onReviewSearchSelectionChange(ss.id, categoryName, ss.action)}
          >
            <div className="row-search">
              <div className="checkbox-container">
                <input
                  id={ss.id}
                  type="checkbox"
                  checked={categorySearches.map(search => search.id)
                    .includes(ss.id)}
                />
              </div>
              <div className={'search-details-container'}>
                <div className="description-container">
                  <label>
                    {ss.name}
                  </label>
                </div>
                <div className={'removing-adding-container'}>
                  <label>
                    {titleCase(ss.categoryLabel)}
                  </label>
                </div>
              </div>
            </div>
            <div className={'divider'}/>
          </div>
        )
      )
    }

    const startOver = <a onClick={() => this.handleStartOverClick()}>Start Over</a>

    const removeSearches = this.props.userSearchesToRemove.length > 0
      ? categories.map(category => {
          const searches = reviewSearches(category, 'remove')
          return (searches)
        })
      : null

    const addSearches = this.props.userSearchesToAdd.length > 0
      ? categories.map((category, idx) => {
          const searches = reviewSearches(category, 'add')
          return (searches)
      })
      : null

    const removeSearchesSection = removeSearches
      ? <div className={'remove-searches'}>
          <h3 className={'add-remove-section-header'}>Searches to be Removed</h3>
          <div className={'refresh-existing-searches-row-container'}>
            {removeSearches}
          </div>
        </div>
      : null

    const addSearchesSection = addSearches
      ? <div className={'add-searches'}>
          <h3 className={'add-remove-section-header'}>Searches to be Added</h3>
          <div className={'refresh-existing-searches-row-container'}>
            {addSearches}
          </div>
        </div>
      : null

    const reviewSearchesSection = removeSearchesSection || addSearchesSection
      ? <React.Fragment>
          {removeSearchesSection}
          {addSearchesSection}
        </React.Fragment>
      : <div className={'no-changes'}>
          No search changes will be made. If this is correct, select a good time for a future
          review and click SAVE CHANGES, otherwise you can {startOver}.
        </div>

      let profileRefreshIntervalOptions = null
      const profileRefreshInterval = this.props.profileRefreshInterval
        profileRefreshIntervalOptions =
          constants.PROFILE_REFRESH_INTERVAL_OPTIONS.map(opt => {
            const selectedInterval = opt.key === profileRefreshInterval
            return (
              <div className={'profile-refresh-interval-option'} key={opt.value}>
                <InputBlock
                  className={classNames(null, {'fade': selectedInterval})}
                  isInline={true}
                  label={opt.value}
                >
                  <input
                    value={opt.key}
                    checked={selectedInterval}
                    type="radio"
                    onChange={() => {this.onProfileRefreshIntervalChange(opt.key)}}
                  />
                </InputBlock>
              </div>
            )
        },)

    return (
      <div className="profile-refresh-body-container">
        <div className="profile-refresh-body">
          <p>Please review and confirm your search changes below.
            Click on any listed item to go back and make further changes. Also choose when you'd like to
            update your searches again in the future.</p>
          <div className={'review-searches-container'}>
            {reviewSearchesSection}
          </div>
          <React.Fragment>
            <div className={'label profile-refresh-interval-label'}>
              Review your searches again in
            </div>
            <div className={'profile-refresh-interval-container'}>
              {profileRefreshIntervalOptions}
            </div>
          </React.Fragment>
        </div>
      </div>
    )
  }

  onReviewSearchSelectionChange(searchId, category, action) {
    if (action === 'adding') {
      const categorySearchesToAdd = this.props.userSearchesToAdd.map(cat => cat[category])[0]
        .filter(search => search.value !== searchId)
      let searchesToAdd = this.props.userSearchesToAdd
        .filter(cat => Object.keys(cat)[0] !== category)
      if (categorySearchesToAdd.length > 0) {
        searchesToAdd.push({[category]: categorySearchesToAdd})
      }
      this.props.setUserSearchesToAdd(searchesToAdd)
      this.props.setCurrentStep(this.props.currentStep - 1)
    } else if (action === 'deleting') {
      this.props.setUserSearchesToRemove(this.props.userSearchesToRemove
        .filter(search => search.id !== searchId))
      this.props.setCurrentStep(this.props.currentStep - 2)
    }
  }

  onProfileRefreshIntervalChange(interval) {
    this.props.setProfileRefreshInterval(interval)
  }

  handleStartOverClick() {
    this.props.setCurrentStep(1)
  }
}
