import React from 'react'

import * as styles from './PillarInfo.less'

function PillarInfo() {
  return (
    <div className={styles.pillarInfo}>
      Click on any pillar to filter news.
    </div>
  )
}

export default PillarInfo
