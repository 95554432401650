import Model from 'app/framework/Model'
import { field } from 'app/framework/Model/fields'


export default class ReportSignup extends Model {
  static entityKey = 'reportSignups'

  static fields = {
    reportDescription: field(),
    contact: field(),
  }
}
