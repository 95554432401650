export const getFirmAdminSelectedConfigIds = state => {
  const configIds = Object.keys(state.emailNotifications.selectedConfigs)
  return configIds.map(id => {return(parseInt(id))})
}
export const getEditUserId = state => {
  return state.emailNotifications.emailDeliveryData.editUserId
}

export const getEsgCCEmails = state => {
  return state.emailNotifications.ccEmailsEsg
}