import React, { Component } from 'react'
import { connect, Provider } from 'react-redux'

import { Membership, User } from 'app/models'
import Orm from 'app/framework/Orm'

import { addUserToGroups, removeUserFromGroups, toggleMembershipIsManager, fetchAvailableMembers } from '../users-admin-actions'
import { getUsersAdmin } from '../users-admin-selectors'
import MemberList from './MemberList'


/**
 * this displays the groups that a user is a member of.
 */

@connect(
  // Map state to props
  state => {
    const orm = Orm.withEntities(state.entities)
    const usersAdmin = getUsersAdmin(state)
    return {
      user: orm.getById(User, usersAdmin.userModalData.userId),
      availableMembers: orm.getByIds(User, usersAdmin.availableMemberIds),
      memberships: orm.getByIds(Membership, usersAdmin.groupMemberships),
      availableMemberCount: usersAdmin.availableMemberCount,
      areAllAvailableMembersFetched: usersAdmin.areAllAvailableMembersFetched,
    }
  },

  // Map dispatch to props
  {
    addUserToGroups,
    removeUserFromGroups,
    toggleMembershipIsManager,
    fetchAvailableMembers,
  },
)
export class GroupMemberships extends Component {
  render() {
    return (
      <MemberList
        type="group"
        availableMembers={this.props.availableMembers}
        memberships={this.props.memberships}
        availableMemberCount={this.props.availableMemberCount}
        areAllAvailableMembersFetched={this.props.areAllAvailableMembersFetched}
        toggleManager={(groupId) => this.toggleManager(groupId)}
        add={(groupIds) => this.addUserToGroups(groupIds)}
        remove={(groupIds) => this.removeUserFromGroups(groupIds)}
        onFetchMembers={this.props.fetchAvailableMembers}
      />
    )
  }

  addUserToGroups(groupIds) {
    this.props.addUserToGroups({userId: this.props.user.id, groupIds})
  }

  removeUserFromGroups(groupIds) {
    this.props.removeUserFromGroups({userId: this.props.user.id, groupIds})
  }

  toggleManager(groupId) {
    this.props.toggleMembershipIsManager({userId: this.props.user.id, groupId})
  }
}
