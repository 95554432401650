import {parseISO} from 'date-fns'
import {titleCase} from 'title-case'

import Model from 'app/framework/Model'
import { field, foreignKey, arrayOf } from 'app/framework/Model/fields'

import User from './User'
import Department from './Department'
import Team from './Team'


export default class Feed extends Model {
  static PUBLICATION_TYPES = {
    NEWS: 'news',
    BLOG: 'blog',
    PRESS_RELEASE: 'press_release',
    PUBLICATION: 'publication',
    TWITTER: 'twitter',
    CASE_FILING: 'case_filing',
    REGULATORY_FILING: 'regulatory_filing',
    PATENT_FILING: 'patent_filing',
    DOJ_FILING: 'doj_filing',
    EVENT: 'event',
    MY_SOURCES: 'sources',
  }

  static PUBLICATION_TYPE_CATEGORIES = {
    NEWS: 'news',
    FILINGS: 'filings',
    EVENTS: 'events',
    TWITTER: 'twitter',
    MY_SOURCES: 'sources',
  }

  static PUBLICATION_TYPES_BY_CATEGORY = {
    [this.PUBLICATION_TYPE_CATEGORIES.NEWS]: [
      this.PUBLICATION_TYPES.NEWS,
      this.PUBLICATION_TYPES.BLOG,
      this.PUBLICATION_TYPES.PRESS_RELEASE,
      this.PUBLICATION_TYPES.PUBLICATION,
    ],
    [this.PUBLICATION_TYPE_CATEGORIES.FILINGS]: [
      this.PUBLICATION_TYPES.CASE_FILING,
      this.PUBLICATION_TYPES.REGULATORY_FILING,
      this.PUBLICATION_TYPES.PATENT_FILING,
      this.PUBLICATION_TYPES.DOJ_FILING,
    ],
    [this.PUBLICATION_TYPE_CATEGORIES.EVENTS]: [
      this.PUBLICATION_TYPES.EVENT,
    ],
    [this.PUBLICATION_TYPE_CATEGORIES.TWITTER]: [
      this.PUBLICATION_TYPES.TWITTER,
    ],
    [this.PUBLICATION_TYPE_CATEGORIES.MY_SOURCES]: [
      this.PUBLICATION_TYPES.MY_SOURCES,
    ],
  }

  static entityKey = 'feeds'

  static fields = {
    /**
     * this model combines the Feed and FirmSource backend models.
     * these fields are on the Feed backend model.
     */
    name: field(),
    url: field({required: false}),
    description: field({required: false}),
    isProprietary: field({required: false}),
    createdAt: field({required: false}),
    createdBy: foreignKey(User, {required: false}),
    updatedAt: field({required: false}),
    updatedBy: foreignKey(User, {required: false}),
    credentials: field({required: false}),
    isSubscriptionRequired: field({required: false}),
    sourceType: field({required: false}),
    absoluteUrl: field({required: false}),
    publicationType: field({required: false}),
    language: field({required: false}),
    lastContentAt: field({required: false}),

    /**
     * these fields are on the FirmSource backend model.
     */
    isLimitedSeat: field({required: false}),
    isPromoted: field({required: false}),
    isPromotedFirmwide: field({required: false}),
    isDemoted: field({required: false}),
    isDemotedFirmwide: field({required: false}),
    isExcluded: field({required: false}),
    featuredForUsers: arrayOf(foreignKey(User, {required: false})),
    featuredForDepartments: arrayOf(foreignKey(Department, {required: false})),
    featuredForTeams: arrayOf(foreignKey(Team, {required: false})),
    labels: field({required: false}),
    sourceNotes: field({required: false}),
    status: field({required: false}),
    reported: field({required: false}),
    reportedOn: arrayOf(field({required: false})),

    /**
     * these fields are added dynamically.
     */
    isAdded: field({required: false}),
    isTrusted: field({required: false}),
    _assignedToIndividualsCount: field({required: false}),
  }

  constructor(data) {
    super(data)
    const {createdAt, updatedAt, lastContentAt} = data
    if (createdAt && createdAt.constructor !== Date) {
      this.createdAt = parseISO(createdAt)
    }
    if (updatedAt && createdAt.constructor !== Date) {
      this.updatedAt = parseISO(updatedAt)
    }
    if (lastContentAt && lastContentAt.constructor !== Date) {
      this.lastContentAt = parseISO(lastContentAt)
    }
  }

  get assignedToIndividualsCount() {
    /**
     * initially `_assignedToIndividualsCount` may be used to avoid having to fetch the related assignments if
     * they are not needed. but if we have `assignments` we use it to get the count.
     */
    if (typeof this.assignments === 'undefined' || this.assignments.length === 0) {
      return this._assignedToIndividualsCount || 0
    }
    return this.assignments.reduce((acc, val) => {
      if (val.assignmentType === 'user') {
        return acc + 1
      }
      return acc + val.assignedTo.userMemberships.length
    }, 0)
  }

  get publicationCategory() {
    const [k, _] = Object.entries(Feed.PUBLICATION_TYPES_BY_CATEGORY).find(
      ([_, v]) => v.includes(this.publicationType)
    ) || [null, null]
    return k? titleCase(k) : null
  }
}
