import React from 'react'
import Dropdown from '../../common/Dropdown'

import * as styles from './style.less'
import InputBlock from '../../common/InputBlock'

export default function Filters({
  pillarFilter,
  pillarOptions,
  setPillarFilter,
  topicFilter,
  topicOptions,
  setTopicFilter,
  sortFilter,
  sortOptions,
  setSortFilter,
  fetchPillarTopicFilterTerms,
  isError
}) {
  const onPillarChange = (value) => {
    setTopicFilter(null)
    setPillarFilter(value)
    if(!isError && topicFilter !== null){
      fetchPillarTopicFilterTerms(value)
    }
  }

  const onTopicChange = (value) => {
    setTopicFilter(value);
    if (!isError) {
      fetchPillarTopicFilterTerms(value);
    }
  };
  return (
    <div className={styles.filters}>
      <div>
        <div className={styles.dropdownWrap}>
          <InputBlock label='Pillar'>
            <Dropdown
              onChange={(value) => { onPillarChange(value) }}
              options={pillarOptions}
              value={pillarFilter}
              className={styles.pillarDropdown}
            />
          </InputBlock>
        </div>

        {pillarFilter != null &&
          <div className={styles.dropdownWrap}>
            <InputBlock label='Topic'>
              <Dropdown
                onChange={onTopicChange}

                value={topicFilter}
                options={topicOptions}
                className={styles.topicDropdown}
              />
            </InputBlock>
          </div>
        }
      </div>
      <div className={styles.dropdownWrap}>
        <InputBlock label={'Sort by'}>
          <Dropdown
            onChange={value => {
                setSortFilter(value)}}
            value={sortFilter}
            options={sortOptions}
            className={styles.sort}
          />
        </InputBlock>
      </div>
    </div>
  )
}
