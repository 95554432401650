import {handleActions} from 'redux-actions'

import * as actions from './cglytics-teaser-actions'

const getInitialState = () => ({
  isOpen: false,
})

export default handleActions(
  {
    [actions.showModal]: () => ({isOpen: true}),
    [actions.hideModal]: getInitialState,
  },
  getInitialState(),
)
