import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import Button from 'app/common/Button'
import Dropdown, {Option} from 'app/common/Dropdown'
import {GROUPING_LEVELS} from 'app/search-results-page/search-results-page-constants'

import * as actions from '../../profile-preferences-actions'
import * as selectors from '../../profile-preferences-selectors'
import {groupingLabel} from '../../profile-preferences-strings'

import styles from '../common/dropdown-with-button.less'


export default function DefaultGrouping() {
  const firmDefault = useSelector(selectors.getGroupingFirmDefault)
  const value = useSelector(selectors.getGrouping)
  const isLoading = useSelector(selectors.getGroupingLoading)
  const isApplyingToAll = useSelector(selectors.getGroupingApplyingToAll)
  const dispatch = useDispatch()
  const onChange = value => {
    dispatch(actions.changeGroupingRequest(value))
  }
  const applyToAll = () => {
    dispatch(actions.applyGroupingToAll(value))
  }
  return (
    <div className={styles.container}>
      <Dropdown
        value={value}
        disabled={isLoading}
        onChange={onChange}
        className={styles.dropdown}
      >
        <Option
          value={null}
          label={`Use Firm Default (${groupingLabel(firmDefault)})`}
        />
        <Option value={GROUPING_LEVELS.LIMITED} label="Limited" />
        <Option value={GROUPING_LEVELS.LESS} label="Less" />
        <Option value={GROUPING_LEVELS.STANDARD} label="Standard" />
        <Option value={GROUPING_LEVELS.MORE} label="More" />
      </Dropdown>
      <Button
        label={isApplyingToAll ? 'Applying...' : 'Apply to All'}
        isPrimary={true}
        disabled={isApplyingToAll}
        onClick={applyToAll}
        className={styles.button}
      />
    </div>
  )
}
