import React from 'react'

import Dropdown from '../../common/Dropdown'
import OverviewEsg from '../esg-graphs/OverviewEsg'
import { useDispatch, useSelector } from 'react-redux'
import * as esgSelectors from '../esg-selectors'
import * as esgActions from '../esg-actions'
import { scrollTargetIds } from 'app/esg/Navigation/NavConfig'

import './style.less'
import LoadingSegment from '../LoadingSegment/LoadingSegment'
import { FACTORS_BY_ID, FACTORS_BY_NAME, SUBFACTORS_BY_ID, SUBFACTORS_BY_NAME } from '../../constants'
import { Waypoint } from 'react-waypoint'
import { IndustryNews as News } from 'app/esg/News'
import EsgScoreInfo from 'app/esg/EsgScoreInfo'
import HealthScoreContainer, { LeftColumn, RightColumn } from 'app/esg/HealthScoreContainer'
import { LoadingHeadline, LoadingSingleLine } from 'app/esg/loading'
import PillarInfo from 'app/esg/PillarInfo'

function index() {
  const pillarFilter = useSelector(esgSelectors.getIndustrySelectedPillar)
  const setPillarFilter = pillar => dispatch(esgActions.setIndustrySelectedPillar(pillar))
  const topicFilter = useSelector(esgSelectors.getIndustrySelectedTopic)
  const setTopicFilter = topic => dispatch(esgActions.setIndustrySelectedTopic(topic))
  const selectedIndustry = useSelector(esgSelectors.getIndustry)
  const defaultIndustry = useSelector(esgSelectors.getDefaultIndustry)
  const sectors = useSelector(esgSelectors.getSectors)
  const defaultIndustryData = !sectors?.isLoading && sectors.find(sector => sector.sectorId == defaultIndustry)
  const esgData = useSelector(esgSelectors.getIndustryEsgData)
  const isLoading = esgData.isLoading
  const dispatch = useDispatch()
  const healthBadge = isLoading ? <LoadingSegment className='loading-health-score-wrapper'/> :
    <OverviewEsg healthData={esgData} selectedFactor={FACTORS_BY_ID[pillarFilter]}
                 selectedSubfactor={SUBFACTORS_BY_ID[topicFilter]}
                 onFactorClick={(factor) => setPillarFilter(FACTORS_BY_NAME[factor])}
                 onSubfactorClick={(subfactor) => setTopicFilter(SUBFACTORS_BY_NAME[subfactor])}/>


  const handleChangeDefaultIndustry = () => {
    if (selectedIndustry.sectorId === defaultIndustry) {
      dispatch(esgActions.updateDefaultIndustry(null))
    } else {
      dispatch(esgActions.updateDefaultIndustry(selectedIndustry.sectorId))
    }
  }

  return (
    <Waypoint
      onEnter={waypoint => {
        if (waypoint.event) dispatch(esgActions.activateNavItem('industry'))
      }}
      onLeave={waypoint => {
        if (waypoint.event) dispatch(esgActions.deactivateNavItem('industry'))
      }}
    >
      <div>
        <div className='esg-industry' id={scrollTargetIds.industry}>
          <h2>ESG Industry</h2>
          {defaultIndustryData && <h3 className='subtitle'>Default Industry: {defaultIndustryData.sectorName}</h3>}
          <HealthScoreContainer>
            <LeftColumn>
              {
                !selectedIndustry ?
                  <div>
                    <LoadingHeadline/>
                    <LoadingSingleLine/>
                    <LoadingSingleLine/>
                  </div>
                  :
                  (<div>
                    <h3>ESG Industry</h3>
                    <Dropdown
                      className='dropdown'
                      value={selectedIndustry.sectorId}
                      options={getIndustryOptions(sectors)}
                      onChange={handleSelectIndustry(dispatch, setPillarFilter, setTopicFilter)}
                    />
                    <div>
                      <label>
                        <input
                          type='checkbox'
                          checked={selectedIndustry.sectorId === defaultIndustry}
                          onChange={handleChangeDefaultIndustry}
                        />
                        Set this industry as default
                      </label>
                    </div>
                  </div>)
              }
              <EsgScoreInfo/>
            </LeftColumn>
            <RightColumn>
              {healthBadge}
              <PillarInfo/>
            </RightColumn>
          </HealthScoreContainer>
          <News {...{
            pillarFilter,
            setPillarFilter,
            topicFilter,
            setTopicFilter
          }} />
        </div>
      </div>
    </Waypoint>
  )
}

export default index

function getIndustryOptions(sectors) {
  const getPlaceholder = label => [{ label, value: null }]

  if (sectors?.isLoading) {
    return getPlaceholder('Loading')
  }

  if (sectors?.length == 0) {
    return getPlaceholder('No industry available')
  }

  return sectors.map(sector => {
    return {
      label: sector.sectorName,
      value: sector.sectorId,
    }
  })
}

const handleSelectIndustry = (dispatch, setPillarFilter, setTopicFilter) => (selected) => {
  dispatch(esgActions.getIndustry(selected))
}
