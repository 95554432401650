import asap from 'asap'
import {changeCaseObject} from 'app/utils'
import {CANCEL} from 'redux-saga'
import {put} from 'redux-saga/effects'

import {FAILURE, SUCCESS} from 'app/actions/utils'


/**
 * Legacy! Avoid in favor of calling `request` directly from the saga.
 */
export function* actionApiRequest(action, func) {
  const baseType = action.type.split('_').slice(0, -1).join('_')
  const request = func(action.payload)

  // This ensures that requests get aborted when the task gets cancelled
  const cancel = request.abort || request.cancel
  if (cancel) {
    request[CANCEL] = () => cancel.bind(request)()
  }

  let response, type, body
  try {
    // We wait until here to `yield` so that we can catch any errors in a
    // generic way
    response = yield request
    type = `${baseType}_${SUCCESS}`
    body = changeCaseObject.camelCase(response.body)
  } catch (error) {
    const type = `${baseType}_${FAILURE}`
    yield put({type, payload: error, error: true})
    return
  }
  yield put({
    type,
    payload: {
      requestData: action.payload,
      response: {...response, body},
    },
  })
}


/**
 * Returns a Promise that resolves when the document is finished loading.
 */
export function waitUntilDomLoaded() {
  return new Promise((resolve, reject) => {
    if (['interactive', 'complete'].includes(document.readyState)) {
      resolve()
    }
    else {
      document.addEventListener('DOMContentLoaded', () => resolve())
    }
  })
}


/**
 * Returns a Promise that resolves as soon as possible, yielding to other queued
 * microtasks. Basically, it yields to whatever code was already running, as
 * well as any code that is already queued, such as event handlers, and attempts
 * to resolve immediately after all of those "microtasks" are finished running.
 * For a detail explanation, see: https://javascript.info/microtask-queue
 */
export function delayImmediate() {
  return new Promise((resolve, reject) => {
    asap(resolve)
  })
}
