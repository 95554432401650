import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import Dropdown, {Option} from 'app/common/Dropdown'

import * as actions from '../../profile-preferences-actions'
import * as selectors from '../../profile-preferences-selectors'

import styles from '../common/dropdown.less'

const DEFAULT_VALUE = '1m'


export default function DefaultTimeFrameMobile() {
  const value = useSelector(selectors.getTimeFrameMobile)
  const isLoading = useSelector(selectors.getTimeFrameMobileLoading)
  const dispatch = useDispatch()
  const onChange = value => {
    dispatch(actions.changeTimeFrameMobileRequest(value))
  }
  return (
    <Dropdown
      value={value || DEFAULT_VALUE}
      disabled={isLoading}
      onChange={onChange}
      className={styles.dropdown}
    >
      <Option value="24h" label="1 Day" />
      <Option value="1w" label="1 Week" />
      <Option value="2w" label="2 Weeks" />
      <Option value="1m" label="1 Month" />
      <Option value="3m" label="3 Months" />
      <Option value="6m" label="6 Months" />
    </Dropdown>
  )
}
