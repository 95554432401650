import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import PropTypes from 'prop-types'

import Orm from 'app/framework/Orm'
import {User} from 'app/models'
import {getEntities} from 'app/entities/entities-selectors'
import ShareSearchBase from 'app/reusable/ShareSearchBase'
import BulkUserAssignment, {ASSIGNEE_TYPES} from 'app/reusable/BulkUserAssignment'
import Button from 'app/common/Button'
import * as globalSelectors from 'app/global/global-selectors'

import * as selectors from '../search-results-page-selectors'
import * as actions from '../search-results-page-actions'

import styles from './NewSearchModal.less'


@connect(
  createSelector(
    [
      getEntities,
      globalSelectors.getIsLargeFirm,
      globalSelectors.getActiveUserCount,
      globalSelectors.getActiveGroupCount,
      selectors.getSearchResultsState,
    ],
    (
      entities,
      isLargeFirm,
      activeUserCount,
      activeGroupCount,
      searchResultsState,
    ) => {
      const orm = Orm.withEntities(entities)
      return {
        isLargeFirm,
        activeUserCount,
        activeGroupCount,
        isLoading: searchResultsState.isLoading,
        users: orm.getByIds(User, searchResultsState.userIds),
        departments: searchResultsState.departments,
        teams: searchResultsState.teams,
        firmLocations: searchResultsState.firmLocations,
        assigneeIdsBySection: searchResultsState.assigneeIdsBySection,
        areAssigneesFetched: searchResultsState.areAssigneesFetched,
        areAllUsersFetched: searchResultsState.areAllUsersFetched,
      }
    }
  ),
  {
    fetchUsers: actions.fetchUsers,
    fetchAssignees: actions.fetchAssignees,
    setSelectedAssigneeIdsBySection: actions.setSelectedAssigneeIdsBySection,
  },
)
export default class NewSearchShare extends ShareSearchBase {
  static propTypes = {
    isSaving: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
  }

  render() {
    if (!this.props.isLoading && !this.props.areAssigneesFetched) {
      this.props.fetchAssignees()
    }

    return (
      <div>
        <h4>Select Users to Add Search To</h4>

        <div className={styles.bulkUserAssignmentArea}>
          <BulkUserAssignment
            users={this.props.users}
            departments={this.props.departments}
            teams={this.props.teams}
            firmLocations={this.props.firmLocations}
            onFetchUsers={this.props.fetchUsers}
            onSectionChange={section => this.setSelectedSection(section)}
            onSelectedIdsChange={ids => this.setSelectedAssigneeIds(ids)}
            selectedIdsBySection={this.props.assigneeIdsBySection}
            showDepartmentMembers={true}
            showTeamMembers={true}
            showFirmLocations={true}
            isLargeFirm={this.props.isLargeFirm}
            activeUserCount={this.props.activeUserCount}
            activeGroupCount={this.props.activeGroupCount}
            areAllUsersFetched={this.props.areAllUsersFetched}
          />
        </div>

        <form onSubmit={(event) => this.save(event)} className={styles.container}>
          <div className={styles.buttons}>
            <Button
              label="Back"
              onClick={() => this.props.onBack()}
              disabled={this.props.isSaving}
              isPlainText={true}
            />
            <Button
              label={this.props.isSaving ? 'Saving...' : this.assigneeCount ? 'Next': 'Save'}
              disabled={this.props.isSaving}
              isPrimary={true}
            />
          </div>
        </form>
      </div>
    )
  }

  get assigneeCount() {
    const {assigneeIdsBySection} = this.props
    return Object.values(assigneeIdsBySection).reduce((total, a) => total + a.length, 0)
  }

  setSelectedAssigneeIds(ids) {
    let assigneeIdsBySection = {...this.props.assigneeIdsBySection}
    assigneeIdsBySection[this.state.selectedSection] = ids
    this.props.setSelectedAssigneeIdsBySection(assigneeIdsBySection)
  }

  save(event) {
    event.preventDefault()
    const {assigneeIdsBySection} = this.props
    const shareUserIds = [
      ...assigneeIdsBySection[ASSIGNEE_TYPES.USER] || [],
      ...assigneeIdsBySection[ASSIGNEE_TYPES.GROUP] || [],
      ...assigneeIdsBySection[ASSIGNEE_TYPES.GROUP_MEMBER] || [],
    ]
    const shareDepartmentIds = [...assigneeIdsBySection[ASSIGNEE_TYPES.DEPARTMENT_MEMBER] || []]
    const shareTeamIds = [...assigneeIdsBySection[ASSIGNEE_TYPES.TEAM_MEMBER] || []]
    const shareFirmLocationIds = [...assigneeIdsBySection[ASSIGNEE_TYPES.FIRM_LOCATION_MEMBER] || []]
    this.props.onSubmit({
      shareUserIds,
      shareDepartmentIds,
      shareTeamIds,
      shareFirmLocationIds,
    })
  }
}
