import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as notifications from 'app/global/notifications'
import { User } from 'app/models'
import Orm from 'app/framework/Orm'
import InputBlock from 'app/common/InputBlock'
import TextBox from 'app/common/TextBox'
import Dropdown from 'app/common/Dropdown'
import Button from 'app/common/Button'

import { getProfileAccount } from './profile-account-selectors'
import {
  setSaveData,
  toggleMembershipIsManager,
} from './profile-account-actions'

import './ProfileAccount.less'
import Tooltip from "app/common/Tooltip"


@connect(
  state => {
    const profileAccount = getProfileAccount(state)
    const orm = Orm.withEntities(state.entities)

    return {
      user: profileAccount.isInitializing ? null : orm.getById(User, state.currentUser),
      locations: profileAccount.locations,
      timezones: profileAccount.timezones,
      saveData: profileAccount.saveData,
    }
  },
  {
    setSaveData,
    toggleMembershipIsManager,
    showNotification: notifications.actions.showNotification,
  },
)
export default class GroupProfile extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
  }

  render() {
    const {
      user,
      locations,
      timezones,
      saveData,
    } = this.props

    const locationOptions =
      [
        {value: 0, label: 'Please Select...'},
        ...locations
          .filter(l => l.favored)
          .map(l => ({value: l.id, label: l.name})),
        {value: '', label: '----------'},
        ...locations.map(l => ({value: l.id, label: l.name})),
      ]

    const favoredTimezoneIds = user ? user.favoredTimezones.map(tz => tz.id) : []

    const timezoneOptions =
      user
        ? [
            ...user.favoredTimezones.map(tz => ({value: tz.id, label: tz.name})),
            {value: '', label: '----------'},
            ...timezones
              .filter(tz => !favoredTimezoneIds.includes(tz.id))
              .map(tz => ({value: tz.id, label: tz.name})),
          ]
        : [{value: '', label: '--'}]

    const authTokenBlock =
      user && user.authToken
        ? <InputBlock label="Authentication Token / API Key">
            <TextBox
              value={user.authToken}
              readOnly
            />
          </InputBlock>
        : null

    const memberList =
      user
        ? user.userMemberships.length === 0
          ? <p>There are currently no members in this group.</p>
          : user.userMemberships
            .map(m => {
              const inputLabel = m.user.isFirmAdmin
                ? <span>
                    {m.user.displayNameLastFirst}
                    <Tooltip
                      label={`${m.user.displayName} already has group manager privileges 
                        as an Admin.`}
                    >
                      <a className="tooltip help" />
                    </Tooltip>
                  </span>
                : <span>{m.user.displayNameLastFirst ?? m.user.username}</span>

              return (
                <InputBlock
                  key={m.id}
                  label={inputLabel}
                  isInline
                  className={classNames({disabled: m.user.isFirmAdmin})}
                >
                  <input
                    type="checkbox"
                    checked={m.isManager}
                    onChange={evt => this.toggleManager(m.user.id)}
                    disabled={m.user.isFirmAdmin}
                  />
                </InputBlock>
              )
            })
        : null

    const firstName = saveData.hasOwnProperty('firstName') ? saveData.firstName : (user ? user.firstName : '')

    const isPrivateGroup =
      saveData.hasOwnProperty('isPrivateGroup')
        ? saveData.isPrivateGroup
        : !!(user && user.isPrivateGroup)

    const locationId = saveData.hasOwnProperty('locationId') ? saveData.locationId : (user ? user.locationId : 0)

    const timezone = saveData.hasOwnProperty('timezone') ? saveData.timezone : (user ? user.timezone : '')

    const groupNotes = saveData.hasOwnProperty('groupNotes') ? saveData.groupNotes : (user ? user.groupNotes : '')

    return (
      <div className="profile-account group-profile">
        <div className="column left">
          <h2 className="header">Group Details</h2>

          <InputBlock label="First Name">
            <TextBox
              value={firstName}
              onChange={evt => this.handleFirstNameChange(evt.target.value)}
            />
          </InputBlock>

          <div className="align-right">
            <InputBlock label="Make this a Private group" isInline>
              <input
                type="checkbox"
                checked={isPrivateGroup}
                onChange={evt => this.handlePrivateCheckbox(evt.target.checked)}
              />
            </InputBlock>
          </div>

          <InputBlock label="Timezone">
            <Dropdown
              options={timezoneOptions}
              value={timezone}
              onChange={value => this.handleTimezoneChange(value)}
            />
          </InputBlock>

          <InputBlock label="Country">
            <Dropdown
              options={locationOptions}
              value={locationId}
              onChange={value => this.handleLocationChange(value)}
            />
          </InputBlock>

          {authTokenBlock}
        </div>

        <div className="column right">
          <h2 className="header">Group Managers</h2>

          <div className="member-list">{memberList}</div>

          <h2 className="header">Notes</h2>

          <InputBlock label="">
            <TextBox
              multiline
              value={groupNotes}
              onChange={evt => this.handleGroupNotesChange(evt.target.value)}
              placeholder="Add notes for the group here..."
              className="group-notes"
            />
          </InputBlock>
        </div>

        <div className="buttons">
          <div>
            <Button
              label="Save"
              onClick={() => this.props.onSave()}
            />
          </div>
        </div>
      </div>
    )
  }

  handleFirstNameChange(value) {
    const saveData = {...this.props.saveData}
    saveData.firstName = value
    this.props.setSaveData(saveData)
  }

  handleLocationChange(value) {
    const saveData = {...this.props.saveData}
    saveData.locationId = value
    this.props.setSaveData(saveData)
  }

  handleTimezoneChange(value) {
    const saveData = {...this.props.saveData}
    saveData.timezone = value
    this.props.setSaveData(saveData)
  }

  handleGroupNotesChange(value) {
    const saveData = {...this.props.saveData}
    saveData.groupNotes = value
    this.props.setSaveData(saveData)
  }

  handlePrivateCheckbox(checked) {
    const saveData = {...this.props.saveData}
    saveData.isPrivateGroup = checked
    this.props.setSaveData(saveData)
  }

  toggleManager(userId) {
    this.props.toggleMembershipIsManager(userId)
  }
}
