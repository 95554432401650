import React from "react";
import {
  FACTORS,
  FACTORS_BY_ID,
  FACTORS_BY_NAME,
  ORDERED_FACTOR_DEPENDENCY_TREE,
  ORDERED_FACTORS,
  SUBFACTORS_BY_ID, SUBFACTORS_BY_NAME
} from '../constants'
import {useLocation} from "react-router-dom";
import { factorDisplayName, subfactorDisplayName } from 'app/strings'

/*
 * Transforms the data returned by the API to a format that is accepted by the HealthBadge.
 */
const transformEsgDataToHealthScoreData = (overallHealthScore, scores) => {
  let healthData = {
    articleVolume: 0,
    healthScore: overallHealthScore,
    negativeVolume: 0,
    neutralVolume: 0,
    positiveVolume: 0,
    factors: {},
    subfactors: {},
  }

  Object.values(FACTORS).forEach(factor => {
    let healthScore = scores.find(score => score.topicId == FACTORS_BY_NAME[factor])?.healthScore
    healthScore = healthScore ? healthScore : 0;
    healthData.factors[factor] = {
      articleVolume: 0,
      healthScore: healthScore,
      negativeVolume: 0,
      neutralVolume: 0,
      positiveVolume: 0,
    }
  });

  scores.map(score => {
    if (SUBFACTORS_BY_ID[score.topicId] !== undefined) {
      const volume = score.positive + score.negative + score.neutral;
      healthData.subfactors[SUBFACTORS_BY_ID[score.topicId]] = {
        articleVolume: volume,
        healthScore: score.healthScore,
        negativeVolume: score.negative,
        neutralVolume: score.neutral,
        positiveVolume: score.positive,
      }
    } else if(FACTORS_BY_ID[score.topicId] !== undefined) {
      const volume = score.positive + score.negative + score.neutral;
      healthData.articleVolume += volume;
      healthData.neutralVolume += score.neutral;
      healthData.positiveVolume += score.positive;
      healthData.negativeVolume += score.negative;
      healthData.factors[FACTORS_BY_ID[score.topicId]] = {
        articleVolume: volume,
        healthScore: score.healthScore,
        negativeVolume: score.negative,
        neutralVolume: score.neutral,
        positiveVolume: score.positive,
      }
    }
  })

  return healthData;
}

const filterEsgEnabledCompaniesFromSavedSearches = (savedSearches) => {
  const savedSearchRegex = /^ss:.+$/

  return savedSearches.filter(company => company.category === "client")
    // We don't want to deal with companies without a savedsearch
    .filter(company => savedSearchRegex.test(company.query))
    // Filter the ones without Insights
    .filter(company => company.isInsightsEnabled);
}

function ScrollToTop() {
  const {pathname} = useLocation();

  React.useEffect(() => {
    document.querySelector('#body').scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [pathname]);

  return null
}

function getPillarOptions() {
  return [
    { label: 'All', value: null },
    ...ORDERED_FACTORS.map(factor => {
      return {
        label: factorDisplayName(factor),
        value: Number(FACTORS_BY_NAME[factor])
      }
    }),
  ]
}

function getTopicOptions(pillarFilter) {
  if (pillarFilter == null) {
    // the topic isn't relevant until user selects pillar
    return [
      {
        label: 'n/a',
        value: null,
      },
    ]
  }
  const stack = ORDERED_FACTOR_DEPENDENCY_TREE[FACTORS_BY_ID[parseInt(pillarFilter)]].map(factor => ({
    factor: factor,
    level: 0
  })).reverse()
  const preorderTraversal = []

  while (stack.length > 0) {
    const currentItem = stack.pop()
    preorderTraversal.push(currentItem)

    if (ORDERED_FACTOR_DEPENDENCY_TREE[currentItem.factor]) {
      for (let i = ORDERED_FACTOR_DEPENDENCY_TREE[currentItem.factor].length - 1; i >= 0; i--) {
        stack.push({factor: ORDERED_FACTOR_DEPENDENCY_TREE[currentItem.factor][i], level: currentItem.level + 1})
      }
    }
  }

  return [
    {
      label: 'All',
      value: null,
    },
    ...preorderTraversal.map(subFactor => {
      return {
        label: subfactorDisplayName(subFactor.factor),
        value: Number(SUBFACTORS_BY_NAME[subFactor.factor]),
        level: subFactor.level,
      }
    })
  ]
}
export {
  filterEsgEnabledCompaniesFromSavedSearches,
  transformEsgDataToHealthScoreData,
  ScrollToTop,
  getPillarOptions,
  getTopicOptions,
};


