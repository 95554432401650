import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import styles from './ComparisonSidebar.less'
import classNames from "classnames"
import TextBox from "app/common/TextBox"
import {useSelector, useDispatch} from 'react-redux'
import * as selectors from "../comparison-selectors"
import Button from "app/common/Button"
import InlineSvg from "app/common/InlineSvg"
import * as actions from '../comparison-actions'

function ComparisonSidebar({
  activeNavChange,
}) {
  const comparisonGroups = useSelector(selectors.getComparisonGroups)
  const categorySearches = useSelector(selectors.getCategorySearches)
  const activeSidebarGroupTitle = useSelector(selectors.getActiveSidebarGroupTitle)
  const activeSidebarSearchId = useSelector(selectors.getActiveSidebarSearchId)
  const pendingComparison = useSelector(selectors.getPendingComparison)
  const newComparisonId = useSelector(selectors.getNewComparisonId)
  const activeComparisonId = useSelector(selectors.getActiveComparisonId)
  const activeTitle = useSelector(selectors.getActiveSidebarGroupTitle)
  const activeFilterKey = useSelector(selectors.getActiveFilterKey)
  const timeFrame = useSelector(selectors.getTimeFrame)
  const displayChartTypes = useSelector(selectors.getDisplayChartTypes)
  const shareOfVoiceChartType = useSelector(selectors.getShareOfVoiceChartType)
  const dispatch = useDispatch()
  const [filterComparisonText, setFilterComparisonText] = useState('')

  useEffect(() => {
    if (newComparisonId) {
      const activeGroup = comparisonGroups.find(cg => cg.id === newComparisonId)
      if (activeGroup !== undefined) {
        const firstSearchId = activeGroup.searchIds
          ? activeGroup.searchIds[0]
          : null
        activeNavChange(null, firstSearchId, activeGroup.title)
        dispatch(actions.setNewComparisonId(null))
        navChange(firstSearchId, activeGroup)
      }
    }
  })

  const navChange = (searchId, group) => {
    dispatch(
      actions.setActiveComparison({
        selectedSearchIds: group.searchIds,
        activeComparisonId: group.id,
        isLoading: true,
        activeComparisonTitle: group.title,
        startNewComparison: false,
        activeFilterKey: group.appliedFilterKey,
        activeFilterItems: group.filterItems,
        timeFrame: group.duration,
        displayChartTypes: group.displayChartTypes,
        shareOfVoiceChartType: group.shareOfVoiceChartType,
        pendingComparison: {
          category: null,
          title: null,
          searchIds: [],
        },
      }),
    )
    activeNavChange(null, searchId, group.title)
  }

  const isSaveButtonDisabled = () => {
    const comparisonGroup = activeComparisonId
      ? comparisonGroups.find(cg => cg.id === activeComparisonId)
      : null
    const hasChanges = comparisonGroup && (
      comparisonGroup.title !== activeTitle ||
      comparisonGroup.appliedFilterKey !== activeFilterKey ||
      comparisonGroup.duration !== timeFrame ||
      JSON.stringify(comparisonGroup.displayChartTypes) !== JSON.stringify(displayChartTypes) ||
      comparisonGroup.shareOfVoiceChartType !== shareOfVoiceChartType
    )
    return comparisonGroup
      ? !hasChanges
      : pendingComparison.searchIds.length < 2
  }

  const searchNames = (searchesForGroup, group) => {
    return(
      searchesForGroup.map(ss =>
      <div
        key={`sidebar-search-${ss.id}`}
        className={classNames(styles.searchName,
          {[styles.activeSearch]: ss.id === activeSidebarSearchId})}
        onClick={() => navChange(ss.id, group)}
      >
        <a className={styles.searchNameAnchor} href={`#${ss.name}`}>{ss.name}</a>
      </div>
      )
    )
  }

  const handleEditComparisonOnClick = group => {
    dispatch(actions.setShowEditComparisonModal({show: true, group: group}))
  }

  const iconActions = (group) => {
    return(
      <span className={styles.actionsContainer}>
        <span>
          <a href="#" className={styles.editAction} title="Edit Saved Comparison">
            <img
              alt={'Edit Saved Comparison'}
              src="/media/img/ico_edit_off.png"
              height="14"
              align="absbottom"
              className="mouseover"
              onClick={() => handleEditComparisonOnClick(group)}
            />
          </a>
        </span>
        <span className={styles.deleteAction}>
          <InlineSvg
            className={styles.deleteIcon}
            src="/media/img/delete.svg"
            onClick={() => dispatch(actions.setDeleteComparison({
              comparisonGroupId: group.id, title: group.title, category: group.category
            }))}
          />
        </span>
      </span>
    )
  }

  const containsFilterText = string => string.toLowerCase()
    .includes(filterComparisonText.toLowerCase())
  const filteredComparisonGroups = filterComparisonText
    ? comparisonGroups.filter(group => containsFilterText(group.title))
    : comparisonGroups

  const sidebarGroups = filteredComparisonGroups.map(group => {
    if (categorySearches.length === 0){return}
    const searchesForGroup = categorySearches.filter(ss => group.searchIds.includes(ss.id))
    const firstSearchId = searchesForGroup.map(ss => ss.id)[0]
    return (
      <div
        key={`sidebar-group-${group.id}`}
        className={styles.sidebar}
        id={`sidebar-group-${group.id}`}
      >
        <div
          className={classNames(styles.comparisonGroupContainer,
            {[styles.activeSidebarGroupTitle]: group.title === activeSidebarGroupTitle},
          )}
        >
          <div className={styles.comparisonGroup}>
            <a
              className={styles.comparisonGroupAnchor}
              href={`#${group.title}`}
              onClick={() => navChange(firstSearchId, group)}
            >
              {group.title}
            </a>
            {iconActions(group)}
          </div>
        </div>
        { group.title === activeSidebarGroupTitle &&(
          <div className={styles.searchNamesContainer}>
            {searchNames(searchesForGroup, group)}
          </div>
        )}
      </div>
    )
  })

  return (
    <div className={styles.sidebarWrapper}>
      <div className={styles.sidebarHeader}>Saved Comparisons</div>
      <div className={styles.sidebarFilterWrapper}>
        <TextBox
          className={styles.sidebarFilter}
          placeholder="Find Comparisons..."
          onChange={(e) => setFilterComparisonText(e.target.value)}
        />
      </div>
      <div className={styles.sidebarGroupsWrapper}>
        {sidebarGroups}
      </div>
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.saveButton}
            label={'Save Comparison'}
            isPrimary={true}
            disabled={isSaveButtonDisabled()}
            onClick={() => dispatch(actions.saveComparisonSearchGroup(pendingComparison))}
          />
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            className={classNames(styles.startNewButton)}
            label={'New Comparison'}
            onClick={() => dispatch(actions.setBuildNewComparison())}
            isPrimary={false}
          />
        </div>
    </div>
  )
}
ComparisonSidebar.propTypes = {
  activeNavChange: PropTypes.func,
}
export default ComparisonSidebar
