import React, {Component} from 'react'

import {ASSIGNEE_TYPES} from 'app/reusable/BulkUserAssignment'


/**
 * this is extended by the share modal and add modal because they share so much functionality
 * but not enough to merge them.
 */
export default class ShareSearchBase extends Component {
  state = {
    selectedSection: ASSIGNEE_TYPES.USER,
  }

  setSelectedSection(section) {
    // section = user or group
    this.setState({selectedSection: section})
  }

  shareWithAssigneeCount() {
    const assigneeIdsBySection = this.props.assigneeIdsBySection
    let count = 0
    Object.keys(assigneeIdsBySection).forEach(key => {
      count += assigneeIdsBySection[key].length
    })
    return count
  }

  shareWithAssigneeNames() {
    const assigneeIdsBySection = this.props.assigneeIdsBySection
    let names = []
    Object.keys(assigneeIdsBySection).forEach(key => {
      assigneeIdsBySection[key].forEach(id => {
        if ([ASSIGNEE_TYPES.USER, ASSIGNEE_TYPES.GROUP, ASSIGNEE_TYPES.GROUP_MEMBER].includes(key)) {
          const user = this.getUserById(id)
          if (user) {
            names.push(this.userDisplayName(user))
          }
        } else if ([ASSIGNEE_TYPES.DEPARTMENT, ASSIGNEE_TYPES.DEPARTMENT_MEMBER].includes(key)) {
          names.push(this.getDepartmentById(id).name)
        } else if ([ASSIGNEE_TYPES.TEAM, ASSIGNEE_TYPES.TEAM_MEMBER].includes(key)) {
          names.push(this.getTeamById(id).name)
        } else if (key === ASSIGNEE_TYPES.FIRM_LOCATION_MEMBER) {
          names.push(this.getFirmLocationById(id).name)
        }
      })
    })
    let nameString = names.join(', ')
    if (nameString.length > 80) {
      nameString = nameString.substr(0, 80) + '...'
    }
    return nameString
  }

  userDisplayName(user) {
    return user.lastName || user.firstName
  }

  getUserById(id) {
    return this.props.users.find(u => u.id === id)
  }

  getDepartmentById(id) {
    return this.props.departments.find(d => d.id === id)
  }

  getTeamById(id) {
    return this.props.teams.find(t => t.id === id)
  }

  getFirmLocationById(id) {
    return this.props.firmLocations.find(l => l.id === id)
  }
}
