import { handleActions } from 'redux-actions'
import * as actions from './profile-account-actions'


const getInitialState = () => ({
  isInitializing: false,
  isLoading: false,
  locations: [],
  firmLocations: [],
  roles: [],
  timezones: [],
  groupIds: [],
  saveData: {},
  passwordConfirmModalOpen: false,
  currentPassword: '',
})

export default handleActions({
  [actions.init]: (state, action) => ({
    ...state,
    isInitializing: true,
  }),
  [actions.initComplete]: (state, action) => ({
    ...state,
    isInitializing: false,
  }),
  [actions.setIsLoading]: (state, action) => ({
    ...state,
    isLoading: action.payload,
  }),
  [actions.fetchUser]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.fetchStaticDataComplete]: (state, action) => ({
    ...state,
    locations: action.payload.locations,
    firmLocations: action.payload.firmLocations,
    roles: action.payload.roles,
    timezones: action.payload.timezones,
    groupIds: action.payload.groupIds,
  }),
  [actions.createMembership]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.deleteMembership]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.setSaveData]: (state, action) => ({
    ...state,
    saveData: action.payload,
  }),
  [actions.save]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.toggleMembershipIsManager]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actions.setPasswordConfirmModalOpen]: (state, action) => ({
    ...state,
    passwordConfirmModalOpen: action.payload,
  }),
  [actions.setCurrentPassword]: (state, action) => ({
    ...state,
    currentPassword: action.payload,
  }),
}, getInitialState())
