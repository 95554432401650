import {graphqlQuery} from 'app/api'
import {args, gql} from 'app/graphql'


export function fetchPreferences() {
  return graphqlQuery(
    gql`
      query {
        currentUser {
          id
          firstName
          lastName
          username
          timezone
          location {
            id
            name
          }
          includeSubscriptionContent
          defaultTimeFrame
          defaultTimeFrameMobile
          defaultSearchResultsOrderBy
          defaultSearchRelevanceLevel
          defaultSearchGroupingLevel
          defaultSearchResultsCount
          enabledQuickFilters {
            id
          }
          dashboardOrder
        }
        
        languages {
          id
          name
          nativeName
          isPreferred
        }
        
        boosters {
          id
          name
          description
          isApplied
          showMe {
            articles {
              headline
              source
              isActive
            }
            header
            description
          }
        }
        
        firmDefaultIncludeSubscriptionContent
        firmDefaultSearchRelevanceLevel
        firmDefaultSearchGroupingLevel

        courtFiltersForCurrentVertical {
          id
          name
          category
        }
        stateFiltersForCurrentVertical {
          id
          name
          category
        }
      }
    `
  ).then(({body: {data}}) => data)
}


export function addPreferredLanguage(id) {
  return graphqlQuery(
    gql`
      mutation {
        addPreferredLanguage(${args({id})})
      }
    `
  )
}
export function removePreferredLanguage(id) {
  return graphqlQuery(
    gql`
      mutation {
        removePreferredLanguage(${args({id})})
      }
    `
  )
}


export function applyBooster(id) {
  return graphqlQuery(
    gql`
      mutation {
        applyBooster(${args({id})})
      }
    `
  )
}
export function unapplyBooster(id) {
  return graphqlQuery(
    gql`
      mutation {
        unapplyBooster(${args({id})})
      }
    `
  )
}


export function setDashboardOrder(value) {
  return graphqlQuery(
    gql`
      mutation {
        saveCurrentUser(dashboardOrder: ${value})
      }
    `
  )
}


export function selectQuickFilter(id) {
  return graphqlQuery(
    gql`
      mutation {
        applyBooster(${args({id})})
      }
    `
  )
}
export function deselectQuickFilter(id) {
  return graphqlQuery(
    gql`
      mutation {
        unapplyBooster(${args({id})})
      }
    `
  )
}


export function setSubscriptionContentSetting(value) {
  return graphqlQuery(
    gql`
      mutation {
        saveCurrentUser(includeSubscriptionContent: ${value})
      }
    `
  )
}


export function setDefaultTimeFrame(value) {
  return graphqlQuery(
    gql`
      mutation {
        saveCurrentUser(defaultTimeFrame: ${value})
      }
    `
  )
}


export function setDefaultTimeFrameMobile(value) {
  return graphqlQuery(
    gql`
      mutation {
        saveCurrentUser(defaultTimeFrameMobile: ${value})
      }
    `
  )
}


export function setSearchResultsOrderBy(value) {
  return graphqlQuery(
    gql`
      mutation {
        saveCurrentUser(defaultSearchResultsOrderBy: ${value})
      }
    `
  )
}


export function setDefaultRelevance(value) {
  return graphqlQuery(
    gql`
      mutation {
        saveCurrentUser(defaultSearchRelevance: ${value})
      }
    `
  )
}
export function applyRelevanceToAllSearches(value) {
  return graphqlQuery(
    gql`
      mutation {
        applyRelevanceLevelToCurrentUserSearches(relevanceLevel: ${value})
      }
    `
  )
}


export function setDefaultGrouping(value) {
  return graphqlQuery(
    gql`
      mutation {
        saveCurrentUser(defaultSearchGrouping: ${value})
      }
    `
  )
}
export function applyGroupingToAllSearches(value) {
  return graphqlQuery(
    gql`
      mutation {
        applyGroupingLevelToCurrentUserSearches(groupingLevel: ${value})
      }
    `
  )
}


export function addQuickFilter(id) {
  return graphqlQuery(
    gql`
      mutation {
        addQuickFilter(id: ${id})
      }
    `
  )
}
export function removeQuickFilter(id) {
  return graphqlQuery(
    gql`
      mutation {
        removeQuickFilter(id: ${id})
      }
    `
  )
}

export function setDefaultResultsPerPage(id) {
  return graphqlQuery(
    gql`
      mutation {
        saveCurrentUser(defaultSearchResultsCount: ${id})
      }
    `
  )
}
