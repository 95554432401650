import classNames from 'classnames'
import is from 'is'
import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {useSelector} from 'react-redux'

import InlineSvg from 'app/common/InlineSvg'
import InputBlock from 'app/common/InputBlock'
import Tooltip from 'app/common/Tooltip'
import * as entitiesSelectors from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import * as globalSelectors from 'app/global/global-selectors'
import {SavedSearch} from 'app/models'
import DateSort from 'app/reusable/DateSort'
import NumberOfSearchResults from 'app/reusable/NumberOfSearchResults'

import SearchRelevanceFilter from 'app/reusable/RelevanceFilter'
import SearchGroupingFilter from "app/reusable/GroupingFilter"
import {doFiltersMatch} from 'app/utils/searches'
import {MY_SEARCHES_FILTER_CATEGORY_ID} from 'app/reusable/SavedSearchFilters/constants'

import {SaveSearchButton} from '../save-search-button'
import {FILTER_SECTIONS} from '../search-results-page-constants'
import TimeFrameSelector, {timeFrameType} from '../time-frame-selector'

import styles from './FiltersBar.less'
import SearchLanguageFilter from "app/reusable/LanguageFilter"


const {CATEGORIES} = SavedSearch

const filterType = PropTypes.shape({
  value: PropTypes.string,
  searchId: PropTypes.number,
  label: PropTypes.string,
  filterField: PropTypes.string,
  specialType: PropTypes.string,
  isFreeText: PropTypes.bool,
  id: PropTypes.number,
  groupId: PropTypes.number,
  firmSourceLabelId: PropTypes.number,
})


function Section({
  label,
  isCollapsed,
  onDisplayToggle,
  className,
  headerClassName,
  children,
  isDisabled,
}) {
  return (
    <div
      className={classNames(
        styles.section,
        className,
        {[styles.collapsed]: isCollapsed},
      )}
    >
      <div className={classNames(styles.header, headerClassName)}>
        <span
          className={classNames(
            styles.label,
            'header-label', // for Pendo
            {[styles.disabled]: isDisabled}
          )}
        >
          {label}
        </span>
        <Tooltip
          label={`${isCollapsed ? 'Expand' : 'Collapse'} section`}
          direction="left"
        >
          <a
            onClick={() => onDisplayToggle()}
            className={classNames(
              styles.toggle,
              // for Pendo:
              'display-toggle',
              {
                'display-toggle-expand': isCollapsed,
                'display-toggle-collapse': !isCollapsed,
              },
            )}
          >
            <InlineSvg src="/media/img/plus.svg" />
          </a>
        </Tooltip>
      </div>
      {!isCollapsed && children}
    </div>
  )
}
Section.propTypes = {
  label: PropTypes.string.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  onDisplayToggle: PropTypes.func.isRequired,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
}


function TimeFrame({timeFrame, onTimeFrameChange}) {
  return (
    <div className={styles.timeFrameSection}>
      <TimeFrameSelector
        value={timeFrame}
        onChange={onTimeFrameChange}
        className={styles.timeFrameSelector}
      />
    </div>
  )
}
TimeFrame.propTypes = {
  timeFrame: timeFrameType,
  onTimeFrameChange: PropTypes.func.isRequired,
}


function SortBy({
  sortDirection,
  onSortDirectionChange,
  shouldShowUpcomingEventsFilter,
  upcomingEvents,
  onUpcomingEventsChange,
  isInsightsEnabled,
  insightsArticlesFirst,
  onInsightsArticlesFirstChange,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const insightsLabel = useSelector(globalSelectors.getInsightsLabel)
  return (
    <div className={styles.sortBySection}>
      <div className={classNames(styles.dateSort, {[styles.open]: isOpen})}>
        <DateSort
          value={sortDirection}
          onChange={onSortDirectionChange}
          onMenuOpenChange={setIsOpen}
        />
      </div>
      {shouldShowUpcomingEventsFilter && (
        <InputBlock
          label="Include upcoming events"
          isInline
          className={classNames(
            styles.booleanSortOption,
            'include-upcoming-events'
          )}
        >
          <input
            type="checkbox"
            checked={upcomingEvents}
            onChange={event => onUpcomingEventsChange(event.target.checked)}
            className={styles.checkbox}
          />
        </InputBlock>
      )}
      {isInsightsEnabled && (
        <InputBlock
          label={`Articles with ${insightsLabel} first`}
          isInline
          className={classNames(
            styles.booleanSortOption,
            'insights-articles-first', // for Pendo
          )}
        >
          <input
            type="checkbox"
            checked={insightsArticlesFirst}
            onChange={event =>
              onInsightsArticlesFirstChange(event.target.checked)
            }
            className={styles.checkbox}
          />
        </InputBlock>
      )}
    </div>
  )
}
SortBy.propTypes = {
  sortDirection: PropTypes.string.isRequired,
  onSortDirectionChange: PropTypes.func.isRequired,
  shouldShowUpcomingEventsFilter: PropTypes.bool.isRequired,
  upcomingEvents: PropTypes.bool.isRequired,
  onUpcomingEventsChange: PropTypes.func.isRequired,
  isInsightsEnabled: PropTypes.bool.isRequired,
  insightsArticlesFirst: PropTypes.bool.isRequired,
  onInsightsArticlesFirstChange: PropTypes.func.isRequired,
}



function NumberOfArticles({
  resultsPerPage,
  onNumberofSearchResultsChange,
}) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className={styles.sortBySection}>
      <div className={classNames(styles.dateSort, {[styles.open]: isOpen})}>
        <NumberOfSearchResults
          value={resultsPerPage}
          onChange={onNumberofSearchResultsChange}
          onMenuOpenChange={setIsOpen}
        />
      </div>
    </div>
  )
}
NumberOfArticles.propTypes = {
  resultsPerPage: PropTypes.number,
  onNumberofSearchResultsChange: PropTypes.func,
}


function Relevance({value, onChange, isDisabled}) {
  return (
    <div className={styles.relevanceSection}>
      <SearchRelevanceFilter
        value={value}
        onChange={onChange}
        shouldShowTitle={false}
        isDisabled={isDisabled}
      />
    </div>
  )
}

function Grouping({value, onChange}) {
  return (
    <div className={styles.groupingSection}>
      <SearchGroupingFilter
        value={value}
        onChange={onChange}
        shouldShowTitle={false}
      />
    </div>
  )
}

function Filter({filter, isSelected, onChange}) {
  const handleChange = event => onChange({
    filter,
    isSelected: event.target.checked,
  })
  return (
    <label
      className={classNames(
        styles.filter,
        'filter', // for Pendo
      )}
    >
      <input
        type="checkbox"
        checked={isSelected}
        onChange={handleChange}
        className={styles.checkbox}
      />
      <span className={styles.label}>{filter.label}</span>
    </label>
  )
}
Filter.propTypes = {
  filter: filterType.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}


class FilterGroup extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    filters: PropTypes.arrayOf(filterType.isRequired).isRequired,
    activeFilters: PropTypes.arrayOf(filterType.isRequired).isRequired,
    defaultIsOpen: PropTypes.bool,
    onFilterStateChange: PropTypes.func.isRequired,
    className: PropTypes.string,
  }

  state = {
    isOpen: is.defined(this.props.defaultIsOpen)
      ? this.props.defaultIsOpen
      : !!this.props.filters.length,
  }

  render() {
    const {isOpen} = this.state
    return (
      <div
        className={classNames(
          styles.filterGroup,
          this.props.className,
          'filter-group', // for Pendo
        )}
      >
        <div
          onClick={this.handleHeaderClick}
          className={classNames(styles.sectionHeader, {[styles.open]: isOpen})}
        >
          <span>{this.props.title}</span>
          <InlineSvg
            src="/media/img/dropdown-arrow.svg"
            className={styles.arrow}
          />
        </div>
        {isOpen && (
          this.props.filters.length ? (
            <div className={styles.filters}>
              {this.props.filters.slice(0, 10).map(filter =>
                <Filter
                  filter={filter}
                  isSelected={
                    this.props.activeFilters.some(selectedFilter =>
                      doFiltersMatch(selectedFilter, filter)
                    )
                  }
                  onChange={this.handleFilterChange}
                  key={
                    filter.specialType
                      ? `special:${filter.specialType}`
                      : filter.firmSourceLabelId
                        ? `firmSourceLabelId:${filter.firmSourceLabelId}`
                        : filter.searchId
                  }
                />
              )}
            </div>
          ) : (
            <div className={styles.noFilters}>
              You have no {this.props.title.toLowerCase().replace(/^my /, '')}
            </div>
          )
        )}
      </div>
    )
  }

  handleHeaderClick = () => {
    this.setState(state => ({...state, isOpen: !state.isOpen}))
  }

  handleFilterChange = ({filter, isSelected}) => {
    this.props.onFilterStateChange({filter, isSelected})
  }
}


function Filters({search, activeFilters, onAdd, onRemove}) {
  const quickFilterOptions =
    useSelector(globalSelectors.getQuickFilterOptions)
    || {categories: [], popular: []}
  const firmSourceLabels = useSelector(globalSelectors.getFirmSourceLabels) || []
  const profileSearchIds = useSelector(globalSelectors.getProfileSearchIds)
  const entities = useSelector(entitiesSelectors.getEntities)
  const orm = Orm.withEntities(entities)
  const profileSearches = orm.getByIds(SavedSearch, profileSearchIds)
  const recommendedFilters = quickFilterOptions.popular.map(
    ([searchId, label]) => ({
      searchId,
      label,
      isFreeText: false,
      filterField: search.defaultFilterField,
    })
  )
  const categoryFilters = quickFilterOptions.categories.map(
    ([specialType, label]) => ({
      label: `All of My ${label}`,
      specialType,
      isFreeText: false,
      filterField: search.defaultFilterField,
      category: MY_SEARCHES_FILTER_CATEGORY_ID,
    })
  )
  const sourceFilters = profileSearches
    .filter(ss => ss.category === CATEGORIES.TRUSTED && ss.id !== search.id)
    .map(ss => ({
      searchId: ss.id,
      label: ss.name,
      isFreeText: false,
      filterField: ss.defaultFilterField,
      category: MY_SEARCHES_FILTER_CATEGORY_ID,
    }))
  const firmSourceLabelFilters = firmSourceLabels.map(fsl => ({
    label: fsl.name,
    isFreeText: false,
    firmSourceLabelId: fsl.id,
    filterField: search.defaultFilterField,
  }))
  const onSearchFilterStateChange = ({filter, isSelected}) => {
    if (isSelected) {
      onAdd(filter)
    }
    else {
      onRemove(filter)
    }
  }
  return (
    <div className={styles.filtersSection}>
      {!!recommendedFilters.length && (
        <FilterGroup
          title="Recommended Filters"
          filters={recommendedFilters}
          activeFilters={activeFilters}
          onFilterStateChange={onSearchFilterStateChange}
          className="recommended" // for Pendo
        />
      )}
      {!!categoryFilters.length && (
        <FilterGroup
          title="My Searches"
          filters={categoryFilters}
          activeFilters={activeFilters}
          onFilterStateChange={onSearchFilterStateChange}
          className="my-searches" // for Pendo
        />
      )}
      <FilterGroup
        title="My Source Folders"
        filters={sourceFilters}
        activeFilters={activeFilters}
        onFilterStateChange={onSearchFilterStateChange}
        className="my-sources" // for Pendo
      />
      {!!firmSourceLabelFilters.length && (
        <FilterGroup
          title="Firm Source Labels"
          filters={firmSourceLabelFilters}
          activeFilters={activeFilters}
          onFilterStateChange={onSearchFilterStateChange}
          className="firm-source-labels" // for Pendo
        />
      )}
    </div>
  )
}
Filters.propTypes = {
  search: PropTypes.object.isRequired,
  activeFilters: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
}


function SaveFilters({
  search,
  isSaving,
  hasUnsavedChanges,
  onSave,
  onViewAdvancedFilters,
}) {
  return (
    <div
      className={classNames(
        styles.applySection,
        'save-filters', // for Pendo
      )}
    >
      <SaveSearchButton
        search={search}
        isSaving={isSaving}
        hasUnsavedChanges={hasUnsavedChanges}
        onClick={(value) => onSave({asNew: value === 'new'})}
      />
      <a
        onClick={() => onViewAdvancedFilters()}
        className={classNames(
          styles.viewAdvanced,
          'view-advanced-filters',
        )}
      >
        View Advanced Filters
      </a>
    </div>
  )
}
SaveFilters.propTypes = {
  search: PropTypes.object.isRequired,
  isSaving: PropTypes.bool.isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onViewAdvancedFilters: PropTypes.func.isRequired,
}


export default function FiltersBar({
  search,
  activeFilters,
  hasUnsavedChanges,
  isSaving,
  onAddFilter,
  onRemoveFilter,
  onSaveFilters,
  viewAdvancedFilters,

  relevancyLevel,
  shouldShowRelevanceFilter,
  onRelevancyLevelChange,

  groupingLevel,
  onGroupingLevelChange,
  languageFilters,
  onLanguageFilterChange,

  timeFrame,
  onTimeFrameChange,

  sortDirection,
  onSortDirectionChange,
  shouldShowUpcomingEventsFilter,
  upcomingEvents,
  onUpcomingEventsChange,

  isInsightsEnabled,
  insightsArticlesFirst,
  onInsightsArticlesFirstChange,

  collapsedFilterSections,
  toggleFilterSectionDisplay,
  onNumberofSearchResultsChange,
  resultsPerPage,
}) {
  return (
    <React.Fragment>
      <Section
        label="Time Range"
        isCollapsed={
          collapsedFilterSections.includes(FILTER_SECTIONS.TIME_RANGE)
        }
        onDisplayToggle={
          () => toggleFilterSectionDisplay(FILTER_SECTIONS.TIME_RANGE)
        }
        headerClassName="time-frame-header"
      >
        <TimeFrame
          timeFrame={timeFrame}
          onTimeFrameChange={onTimeFrameChange}
          isCollapsed={
            collapsedFilterSections.includes(FILTER_SECTIONS.TIME_RANGE)
          }
          onDisplayToggle={
            () => toggleFilterSectionDisplay(FILTER_SECTIONS.TIME_RANGE)
          }
        />
      </Section>

      <Section
        label="Sort by:"
        isCollapsed={
          collapsedFilterSections.includes(FILTER_SECTIONS.SORT_BY)
        }
        onDisplayToggle={
          () => toggleFilterSectionDisplay(FILTER_SECTIONS.SORT_BY)
        }
      >
        <SortBy
          sortDirection={sortDirection}
          onSortDirectionChange={onSortDirectionChange}
          shouldShowUpcomingEventsFilter={shouldShowUpcomingEventsFilter}
          upcomingEvents={upcomingEvents}
          onUpcomingEventsChange={onUpcomingEventsChange}
          isInsightsEnabled={isInsightsEnabled}
          insightsArticlesFirst={insightsArticlesFirst}
          onInsightsArticlesFirstChange={onInsightsArticlesFirstChange}
        />
      </Section>

      <Section
        label="Number of Search Results:"
        isCollapsed={
          collapsedFilterSections.includes(FILTER_SECTIONS.RESULTS_PER_PAGE)
        }
        onDisplayToggle={
          () => toggleFilterSectionDisplay(FILTER_SECTIONS.RESULTS_PER_PAGE)
        }
      >
        <NumberOfArticles
          resultsPerPage={resultsPerPage}
          onNumberofSearchResultsChange={onNumberofSearchResultsChange}
        />
      </Section>

      <Section
        label="Relevance"
        isCollapsed={
          collapsedFilterSections.includes(FILTER_SECTIONS.RELEVANCE)
        }
        onDisplayToggle={
          () => toggleFilterSectionDisplay(FILTER_SECTIONS.RELEVANCE)
        }
        headerClassName="relevance-header" // for Pendo
        isDisabled={!shouldShowRelevanceFilter}
      >
        <Relevance value={relevancyLevel}
                   onChange={onRelevancyLevelChange}
                   isDisabled={!shouldShowRelevanceFilter}
        />
      </Section>

      <Section
        label="Article Grouping"
        isCollapsed={
          collapsedFilterSections.includes(FILTER_SECTIONS.GROUPING)
        }
        onDisplayToggle={
          () => toggleFilterSectionDisplay(FILTER_SECTIONS.GROUPING)
        }
        className="grouping" // for Pendo
        headerClassName="grouping-header" // for Pendo
      >
        <Grouping value={groupingLevel} onChange={onGroupingLevelChange} />
      </Section>

      {languageFilters !== null && languageFilters.length > 0 && (
        <Section
          label="Languages"
          isCollapsed={
            collapsedFilterSections.includes(FILTER_SECTIONS.LANGUAGE)
          }
          onDisplayToggle={
            () => toggleFilterSectionDisplay(FILTER_SECTIONS.LANGUAGE)
          }
          className="search-language" // for Pendo
        >
          <SearchLanguageFilter
            languages={languageFilters}
            onChange={onLanguageFilterChange}
            isSingleColumn={true}
          />
        </Section>
      )}

      <Section
        label="Filter Results by:"
        isCollapsed={
          collapsedFilterSections.includes(FILTER_SECTIONS.QUICK_FILTERS)
        }
        onDisplayToggle={
          () => toggleFilterSectionDisplay(FILTER_SECTIONS.QUICK_FILTERS)
        }
        className="quick-filters" // for Pendo
      >
        <Filters
          search={search}
          activeFilters={activeFilters}
          onAdd={onAddFilter}
          onRemove={onRemoveFilter}
        />
      </Section>

      <SaveFilters
        search={search}
        isSaving={isSaving}
        hasUnsavedChanges={hasUnsavedChanges}
        onSave={onSaveFilters}
        onViewAdvancedFilters={viewAdvancedFilters}
      />
    </React.Fragment>
  )
}
FiltersBar.propTypes = {
  search: PropTypes.object.isRequired,
  activeFilters: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onAddFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
  onSaveFilters: PropTypes.func.isRequired,
  viewAdvancedFilters: PropTypes.func.isRequired,

  relevancyLevel: PropTypes.string,
  groupingLevel: PropTypes.string,
  languageFilters: PropTypes.arrayOf(PropTypes.object),
  shouldShowRelevanceFilter: PropTypes.bool.isRequired,
  onRelevancyLevelChange: PropTypes.func,
  onGroupingLevelChange: PropTypes.func,
  onLanguageFilterChange: PropTypes.func,

  timeFrame: timeFrameType.isRequired,
  onTimeFrameChange: PropTypes.func.isRequired,

  sortDirection: PropTypes.string.isRequired,
  onSortDirectionChange: PropTypes.func.isRequired,
  shouldShowUpcomingEventsFilter: PropTypes.bool,
  upcomingEvents: PropTypes.bool,
  onUpcomingEventsChange: PropTypes.func.isRequired,

  isInsightsEnabled: PropTypes.bool.isRequired,
  insightsArticlesFirst: PropTypes.bool.isRequired,
  onInsightsArticlesFirstChange: PropTypes.func.isRequired,

  collapsedFilterSections: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleFilterSectionDisplay: PropTypes.func.isRequired,
  onNumberofSearchResultsChange: PropTypes.func,
}
