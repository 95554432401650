import {graphqlQuery} from 'app/api'
import {args, gql} from 'app/graphql'


const flaggingPartial = `
  id
  directorId
  creditFeed {
    id
    sourceType
  }
  article {
    headline
    changeUrl
    publishedAt
    groupCount
    snippet
    feed {
      id
      name
      sourceType
    }
  }
`

const categoriesQuery = `
  categories: flagCategories() {
    id
    name
    isShared
    rssKey
    parent {
      id
      name
    }
    flaggings {
      ${flaggingPartial}
    }
  }
`

const promotedSearchesQuery = `
  promotedSources() {
    id
  }
`

export function fetchInitData() {
  return graphqlQuery(
    gql`
      query {
        uncategorizedFlaggings {
          ${() => flaggingPartial}
        }
        ${() => categoriesQuery}
        helpQuestions(slugs: ["sqoop-alert"]) {
          slug
          statement
        }
        ${() => promotedSearchesQuery}
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchPromotedSearches() {
  return graphqlQuery(
    gql`
      query {
        ${() => promotedSearchesQuery}
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchCategories() {
  return graphqlQuery(
    gql`
      query {
        uncategorizedFlaggings {
          ${() => flaggingPartial}
        }
        ${() => categoriesQuery}
      }
    `
  ).then(({body: {data}}) => data)
}

export function createCategory({name, parentId}) {
  return graphqlQuery(
    gql`
      mutation {
        createFlagCategory(${args({name, parentId})})
      }
    `
  ).then(({body: {data}}) => data)
}

export function deleteCategories(ids) {
  return graphqlQuery(
    ids.map(id => {
      return (
        gql`
          mutation {
            deleteFlagCategory(${args({id})})
          }
        `
      )
    })

  ).then(({body: {data}}) => data)
}

export function clearCategories(ids) {
  return graphqlQuery(
    ids.map(id => {
      return (
        gql`
          mutation {
            clearFlagCategory(${args({id})})
          }
        `
      )
    })

  ).then(({body: {data}}) => data)
}

export function saveCategory({id, name, isShared}) {
  return graphqlQuery(
    gql`
      mutation {
        saveFlagCategory(${args({id, name, isShared})})
      }
    `
  ).then(({body: {data}}) => data)
}

export function deleteArticles(ids) {
  return graphqlQuery(
    gql`
      mutation {
        deleteDocuments(${args({ids})})
      }
    `
  ).then(({body: {data}}) => data)
}

export function deleteFlaggings({categoryId, articleIds}) {
  // flaggings are referenced by their article (document) id when removing.
  if (categoryId === 0) {
    categoryId = null
  }
  return graphqlQuery(
    gql`
      mutation {
        unflagDocuments(${args({categoryId, documentIds: articleIds})})
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchInitDataNew() {
  return graphqlQuery(
    gql`
      query {
        uncategorizedFlaggings {
          ${() => flaggingPartial}
        }
        helpQuestions(slugs: ["sqoop-alert"]) {
          slug
          statement
        }
        ${() => promotedSearchesQuery}
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchflaggingsCountNew() {
  return graphqlQuery(
    gql`
      query {
        categories: flagCategories() {
          id
          name
          parent {
            id
            name
          }
          rssKey
          isShared
          flaggingsCount {
            id
          }
        }
        uncategorizedFlaggingsCount
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchCategoryByIdNew(id) {
  return graphqlQuery(
    gql`
      query {
        categories: flagCategoriesSelected(${args({id})}) {
          id
          name
          parent {
            id
            name
          }
          flaggings {
            id
            directorId
            creditFeed {
              id
              sourceType
            }
            article {
              headline
              changeUrl
              publishedAt
              groupCount
              snippet
              feed {
                id
                name
                sourceType
              }
            }
          }
          rssKey
          isShared
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchUncategorizedNew(id) {
  return graphqlQuery(
    gql`
      query {
        uncategorizedFlaggings {
          ${() => flaggingPartial}
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchFlagPagethreshold() {
  return graphqlQuery(
    gql`
      query {
        flagPageThreshold
      }
    `
  ).then(({body: {data}}) => data)
}