import React, { Component } from 'react'
import { connect } from 'react-redux'

import Orm from 'app/framework/Orm'
import { User } from 'app/models'

import { getUsersAdmin } from '../users-admin-selectors'
import { fetchUser } from '../users-admin-actions'
import BaseNotifications from 'app/email-notifications/base-notifications/BaseNotifications'

@connect(
  state => {
    const orm = Orm.withEntities(state.entities)
    const usersAdmin = getUsersAdmin(state)

    return {
      isGroupPage: state.isGroupPage,

      user: orm.getById(User, usersAdmin.userModalData.userId),
      isUserFetched: usersAdmin.isUserFetched,
    }
  },
  {
    fetchUser,
  },
)
export default class NotificationsContent extends Component {
  componentDidMount() {
    if (!this.props.isUserFetched) {
      this.props.fetchUser(this.props.user.id)
    }
  }

  render() {
    const notifications =
      this.props.isUserFetched ?
        <BaseNotifications/>
        : null

    return (
      <div>
        {notifications}
      </div>
    )
  }
}
