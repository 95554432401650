import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import Overlay from 'app/common/Modal'
import AssignSourcesContent from '../AssignSourcesContent'
import { hideAssignModal } from '../sources-admin-actions'
import { getSourcesAdmin } from '../sources-admin-selectors'

import './style.less'


@connect(
  createSelector(
    [getSourcesAdmin],
    (sourcesAdmin) => {
      const modalData = sourcesAdmin.assignModalData
      return {
        isVisible: modalData.isVisible,
        userIds: sourcesAdmin.userIds,
        feedIds: modalData.feedIds,
      }
    }
  ),
  {
    hideAssignModal
  },
)
export default class AssignModal extends Component {
  render() {
    if (!this.props.isVisible || this.props.userIds.length === 0) return null

    return (
      <Overlay
        id="assign-source-overlay"
        isOpen={true}
        onClose={this.props.hideAssignModal}
      >
        <AssignSourcesContent
          feedIds={this.props.feedIds}
        />
      </Overlay>
    )
  }
}
