import React, { Component } from 'react'
import { connect } from 'react-redux'
import EmailDelivery from 'app/email-notifications/email-delivery'
import * as constants from 'app/email-notifications/email-notifications-constants'
import * as actions from 'app/email-notifications/email-notifications-actions'
import AdditionalRecipients from "app/email-notifications/AdditionalRecipients"
import ItemsSortOrder from "app/common/ItemsSortOrder"
import SendZeroResults from "app/email-notifications/SendZeroResults"
import HideDuplicateArticles from "app/email-notifications/HideDuplicateArticles"
import SummaryTextLength from "app/email-notifications/SummaryTextLength"
import Button from "app/common/Button"
import SendSampleEmail from "app/email-notifications/send-sample-email/SendSampleEmail"
import Table from "app/common/Table"
import InlineSvg from "app/common/InlineSvg/InlineSvg"
import classNames from "classnames"
import InputBlock from "app/common/InputBlock"
import Dropdown from "app/common/Dropdown"
import NotificationsBulkActions from "app/email-notifications/NotificationsBulkActions"
import DeliveryDay from "app/email-notifications/DeliveryDay"
import Modal from "app/common/Modal"
import store from "app/store"
import { changeCaseObject } from 'app/utils'
import AppWrapper from "app/global/app-wrapper"
import { render } from "react-dom"
import './BaseNotifications.less'
import Switch from 'react-switch'
import { titleCase } from "title-case"
import { searchCategory } from 'app/strings'
import * as globalSelectors from 'app/global/global-selectors'
import deepCopy from "deepcopy"
import {DELIVERY_GROUP_MEMBER} from "app/email-notifications/email-notifications-constants";

@connect(
  state => ({
    editUserId: state.emailNotifications.emailDeliveryData.editUserId,
    defaultUserEmail: state.emailNotifications.emailDeliveryData.defaultUserEmail,
    quickFilterOptions: state.emailNotifications.emailDeliveryData.quickFilterOptions,
    editUserFullName: state.emailNotifications.emailDeliveryData.editUserFullName,
    isInsightsEnabled: state.emailNotifications.emailDeliveryData.isSignalsEnabled,
    baseSettings: state.emailNotifications.baseSettings,
    baseFrequency: state.emailNotifications.baseFrequency,
    ccEmailsBase: state.emailNotifications.ccEmailsBase,
    baseNoticeConfigs: state.emailNotifications.baseNoticeConfigs,
    notificationSettings: state.emailNotifications.notificationSettings,
    selectedConfigs: state.emailNotifications.selectedConfigs,
    categoryDefaults: state.emailNotifications.baseSettings.categoryDefaults,
    categoryToggle: state.emailNotifications.categoryToggle,
    isProfilePage: state.emailNotifications.isProfilePage,
    insightsLabel: globalSelectors.getInsightsLabel(state),
    currentFirm: globalSelectors.getCurrentFirm(state),
    appName: globalSelectors.getAppName(state),
    isGroupUser: state.emailNotifications.emailDeliveryData.isGroupUser,
    applyCategoryOrderMembers: state.emailNotifications.emailDeliveryData.categoryOrderToGroupMembers,
    applyCategoryDefaultMembers: state.emailNotifications.emailDeliveryData.categoryDefaultToGroupMembers,
    deliveryDayToGroupMembers: state.emailNotifications.emailDeliveryData.deliveryDayToGroupMembers,
    deliveryTimeToGroupMembers: state.emailNotifications.emailDeliveryData.deliveryTimeToGroupMembers,
    duplicateArticleToGroupMembers: state.emailNotifications.emailDeliveryData.duplicateArticleToGroupMembers,
    searchesAlphabeticallyToGroupMembers: state.emailNotifications.emailDeliveryData.searchesAlphabeticallyToGroupMembers,
  }),
  {
    getCCEmailsBase: actions.api.getCCEmailsBase.request,
    getBaseNoticeConfigs: actions.api.getBaseNoticeConfigs.request,
    updateCategoryOrder: actions.api.updateCategoryOrder.request,
    changeSendSampleModal: actions.changeSendSampleModal,
    changeNotificationSettings: actions.api.changeNotificationSettings.request,
    getNotificationSettings: actions.api.getNotificationSettings.request,
    updateContentSettings: actions.api.updateContentSettings.request,
    saveBaseNoticeConfigs: actions.api.saveBaseNoticeConfigs.request,
    changeShowBulkUpdateModal: actions.changeShowBulkUpdateModal,
    changeSelectedConfigs: actions.changeSelectedConfigs,
    changeCategoryDefaultsModalShow: actions.changeCategoryDefaultsModalShow,
    changeCategoryDefaultsModalCategories: actions.changeCategoryDefaultsModalCategories,
    updateCategoryDefaults: actions.api.updateCategoryDefaults.request,
    changeCategoryToggle: actions.changeCategoryToggle,
    changeContentSettingsModalSettings: actions.changeContentSettingsModalSettings,
    changeContentSettingsModalState: actions.changeContentSettingsModalState,
    saveEmailAlertSettings: actions.api.saveEmailAlertSettings.request,
    applyCategoryOrdersSettings: actions.api.applyCategoryOrdersSettings.request,
  },
)
export default class BaseNotifications extends Component {

  componentDidMount() {
    this.props.getCCEmailsBase({
      type: this.props.baseSettings.notificationType,
      noticeFrequency: this.props.baseFrequency,
      editUserId: this.props.editUserId,
    })
    this.props.getBaseNoticeConfigs({
      editUserId: this.props.editUserId,
      type: this.props.baseSettings.notificationType,
      noticeFrequency: this.props.baseFrequency
    })
    this.props.getNotificationSettings({
      editUserId: this.props.editUserId
    })
  }
  state = {
    applyMember: this.props.applyCategoryOrderMembers,
    applyDefaultMember: this.props.applyCategoryDefaultMembers,
    defaultCategoryTemp: this.props.applyCategoryDefaultMembers,
    deliveryDayCheckbox: this.props.deliveryDayToGroupMembers,
    deliveryTimeCheckbox: this.props.deliveryTimeToGroupMembers,
    hideDupArticlesCheckbox: this.props.duplicateArticleToGroupMembers,
    orderAlphabeticallyCheckbox: this.props.searchesAlphabeticallyToGroupMembers,
  }

  render() {
    const emailDeliveryOptions = this.props.editUserId && !this.props.isProfilePage
      ? <div className={'delivery-options'}><EmailDelivery /></div>
      : null
    const categorySortOrderItems = this.props.categoryDefaults
      ? this.props.categoryDefaults.map((cat, idx) => {
        return (
          <div
            className={'order-item-element'}
            key={idx}
          >
            <div className={'descriptive-text'}>{this.getCategoryLabel(cat.category, true)}</div>
            <ItemsSortOrder
              itemIndex={cat.noticeOrder}
              sortOrderFunc={categorySortOrderFunc}
              itemsArray={this.props.categoryDefaults}
              updateOrderFunc={this.props.updateCategoryOrder}
              applyGroupMembers={this.props.applyCategoryOrdersSettings}
              extraParams={{
                editUserId: this.props.editUserId,
                notificationType: this.props.baseSettings.notificationType,
                frequency: this.props.baseFrequency,
                applymember: this.state.applyMember,
              }}
            />
          </div>
        )
      })
      : null

    const applyCategoryOrder = <InputBlock
      className={'input-label alpha-order-container'}
      label={"Apply Category Order to Group Members"} isInline
    >
      <input
        className={'email-delivery-input'}
        type="checkbox"
        checked={this.state.applyMember}
        onChange={(e) => this.applyCategoryOrders(e)}
      />
    </InputBlock>

    const applyCategoryDefault = <InputBlock
      className={'input-label'}
      label={"Apply Category Defaults to Group Members"} isInline
    >
      <input
        className={'email-delivery-input'}
        type="checkbox"
        checked={this.state.defaultCategoryTemp}
        onChange={(e) => this.setState({ defaultCategoryTemp: e.target.checked })}

      />
    </InputBlock>

    const filterByOptions = []
    this.props.quickFilterOptions.map(({ id, label }) => {
      filterByOptions.push({
        key: id,
        editUserId: this.props.editUserId,
        notificationType: this.props.baseSettings.notificationType.toLowerCase(),
        noticeFrequency: this.props.baseFrequency,
        label: <span>{label}</span>,
        value: label,
      })
    })
    const additionalRecipients = this.props.baseFrequency !== constants.NONE_FREQ
      ? <AdditionalRecipients
        noticeFrequency={this.props.baseFrequency}
        notificationType={this.props.baseSettings.notificationType}
        ccEmails={this.props.ccEmailsBase}
        ccEmailInputWidth={this.props.isProfilePage ? '625px' : '685px'}
      />
      : null
    const deliveryDay = this.props.baseFrequency !== constants.NONE_FREQ
      ? <DeliveryDay noticeFrequency={this.props.baseFrequency}
        notificationType={this.props.baseSettings.notificationType}
        groupNotification={this.state.deliveryTimeCheckbox}
      />
      : null

    const alternateFormat = this.props.appName === 'Diligent'
      ? <InputBlock
        className={'input-label alternate-email-format'}
        label='Use alternate email format'
        isInline={true}
      >
        <input
          className={'input-checkbox'}
          type="checkbox"
          checked={this.props.baseSettings.useAlternateEmailTemplate}
          onChange={(e) =>
            this.onUseAlternateEmailTemplateChange(e)}
        />
      </InputBlock>
      : null

    const sendZeroResults = this.props.baseFrequency !== constants.NONE_FREQ
      ? <SendZeroResults
        noticeFrequency={this.props.baseFrequency}
        notificationType={this.props.baseSettings.notificationType}
      />
      : null
    const hideDuplicateArticles = this.props.baseFrequency !== constants.NONE_FREQ
      ? <HideDuplicateArticles
        noticeFrequency={this.props.baseFrequency}
        notificationType={this.props.baseSettings.notificationType}
        groupNotification={this.state.hideDupArticlesCheckbox}
      />
      : null
    const summaryTextLength = this.props.baseFrequency !== constants.NONE_FREQ
      ? <div className={'text-length-container'}>
        <SummaryTextLength
          noticeFrequency={this.props.baseFrequency}
          notificationType={this.props.baseSettings.notificationType}
        />
      </div>
      : null
    const sendSampleEmail = this.props.baseFrequency !== constants.NONE_FREQ
      ? <div className={'send-sample-button-container'}>
        <Button
          className="send-sample-button"
          label={'Send Sample Email'}
          onClick={() => { this.onSendSampleButtonClick() }}
        />
      </div>
      : null
    const updateCategoryDefaults =
      <div className={'update-category-defaults-button-container'}>
        <Button
          className="update-category-defaults-button"
          label={'Update Category Defaults'}
          onClick={() => { this.onUpdateCategoryDefaultsClick() }}
        />
      </div>
    const frequencySelectedConfigs = Object.values(this.props.selectedConfigs)
      .filter(config => config.noticeFrequency === this.props.baseFrequency)
    const frequencyOptions = []
    const bulkFrequencyOptions = []
    this.props.baseSettings.frequencies.forEach(freq => {
      if (freq !== constants.ALL_FREQ) {
        const addOption = {
          key: freq,
          notificationType: this.props.baseSettings.notificationType.toLowerCase(),
          fieldName: constants.FREQUENCY,
          label: <span>{constants.BASE_EMAIL_FREQUENCIES[freq]}</span>,
          value: constants.BASE_EMAIL_FREQUENCIES[freq],
        }
        frequencyOptions.push(addOption)
        bulkFrequencyOptions.push(addOption)
      }
    })
    bulkFrequencyOptions.push({
      key: -1,
      notificationType: this.props.baseSettings.notificationType.toLowerCase(),
      fieldName: constants.FREQUENCY,
      label: <span>{constants.NONE_SELECTED}</span>,
      value: constants.NONE_SELECTED,
    })

    const backToTopLinkId = this.props.isProfilePage
      ? '#page'
      : '#firm-admin-edit-user'

    const categoryConfigs = this.props.categoryDefaults && this.props.baseNoticeConfigs.baseConfigs
      ? this.props.categoryDefaults.map((cat, idx) => {
        const category = cat.category
        const categoryToggle = this.props.categoryToggle
          && this.props.categoryToggle[category] !== undefined
          ? this.props.categoryToggle[category]
          : false
        let rowSettings = null
        if (categoryToggle) {
          const categoryItems = cat.category
            ? this.props.baseNoticeConfigs.baseConfigs[cat.category]
            : null
          const activeSpikeType = this.props.notificationSettings
            && this.props.notificationSettings[cat.category]
            ? constants.SPIKE_TYPE_LABELS[this.props.notificationSettings[cat.category].spikeType]
            : constants.SPIKE_TYPE_LABELS[constants.NO_ALERT_SPIKE_TYPE]
          const spikeType = categoryItems && categoryItems.length > 0 && !this.props.isProfilePage
            ? <div className={'spike-type-container'}>
              <InputBlock
                className={'select-spike-type-label'}
                label="Spike Type"
              >
                <Dropdown
                  className={'select-spike-type-dropdown'}
                  options={constants.SPIKE_TYPE_OPTIONS}
                  value={activeSpikeType}
                  onSelect={
                    this.onSpikeTypeOrFilterBySelect.bind(this, cat.category, 'spike_type')
                  }
                />
              </InputBlock>
            </div>
            : null
          let activeFilterBy = this.props.notificationSettings
            && this.props.notificationSettings[cat.category]
            ? this.props.quickFilterOptions.find(opt =>
              opt.id === this.props.notificationSettings[cat.category].filterBy
            )
            : constants.FILTER_BY_NONE
          if (!activeFilterBy || activeFilterBy.length < 1) {
            activeFilterBy = constants.FILTER_BY_NONE
          }
          if (activeFilterBy !== constants.FILTER_BY_NONE) {
            activeFilterBy = activeFilterBy['label']
          }

          const filterBy = categoryItems && categoryItems.length > 0 && !this.props.isProfilePage
            ? <div className={'filter-by-container'}>
              <InputBlock
                className={'filter-by-label'}
                label="Additional Articles Filtered By"
              >
                <Dropdown
                  className={'select-category-filter-dropdown'}
                  options={filterByOptions}
                  value={activeFilterBy}
                  onSelect={
                    this.onSpikeTypeOrFilterBySelect.bind(this, cat.category, 'filter_by')
                  }
                />
              </InputBlock>
            </div>
            : null
          const categorySelectedConfigs = frequencySelectedConfigs && frequencySelectedConfigs
            .filter(config => config.category === cat.category && config.selected)
          const allSelected = categorySelectedConfigs && categoryItems
            ? categorySelectedConfigs.length === categoryItems.length
            : false
          const bulkActions = categoryItems && categoryItems.length > 0
            ? <NotificationsBulkActions
              category={cat.category}
              notificationType={this.props.baseSettings.notificationType}
              allCategoryConfigs={categoryItems}
              allSelected={allSelected}
              categorySelectedConfigs={categorySelectedConfigs}
              frequencyOptions={bulkFrequencyOptions}
            />
            : null
          const configs = categoryItems
            ? <React.Fragment key={idx}>
              <Table
                className={'config-table'}
                data={categoryItems}
                key={idx}
              >
                <Table.Column
                  name={'checkbox'}
                  baseWidth={this.props.isProfilePage ? 40 : 45}
                  growRatio={0}
                  shrinkRatio={0}
                  cellContents={config =>
                    <input
                      className="checkbox"
                      type="checkbox"
                      onChange={(e) => this.onConfigSelect(e, config.id, cat.category)}
                      checked={this.isConfigSelected(config.id)}
                    />
                  }
                />
                <Table.Column
                  name="savedsearch"
                  label={'Saved Search Name'}
                  baseWidth={this.props.isProfilePage ? 50 : 230}
                  growRatio={1}
                  shrinkRatio={1}
                  isSortable
                  sortBy={config => {
                    return config.searchName
                  }}
                  cellContents={config => this.getSearchName(config)}
                />
                <Table.Column
                  name="contentSettings"
                  label={'Settings'}
                  baseWidth={380}
                  cellContents={config => this.getContentSettings({ config, category, frequencyOptions })}
                />
                <Table.Column
                  name="editActionIcon"
                  label={'Edit'}
                  baseWidth={55}
                  growRatio={0}
                  shrinkRatio={0}
                  cellContents={config =>
                    <div className={'action-icons-container'}>
                      <div
                        className='base-action-icon pad-right'
                        onClick={() => { this.onEditContentSettingsClick(config) }}
                      >
                        <InlineSvg
                          className='base-edit-icon'
                          src="/media/img/edit.svg"
                        />
                      </div>
                    </div>
                  }
                />
                <Table.Column
                  name="orderActionIcons"
                  label={'Order'}
                  baseWidth={this.props.isProfilePage ? 115 : 110}
                  growRatio={0}
                  shrinkRatio={0}
                  cellContents={config =>
                    <div className={'action-icons-container'}>
                      <div
                        className='base-action-icon'
                        onClick={() => {
                          this.onOrderChangeClick(config, 'order_bottom')
                        }}
                      >
                        <InlineSvg
                          className='base-to-bottom-icon'
                          src="/media/img/to-bottom-arrow.svg"
                        />
                      </div>

                      <div
                        className='base-action-icon'
                        onClick={() => {
                          this.onOrderChangeClick(config, 'order_down')
                        }}
                      >
                        <InlineSvg
                          className='base-order-down-icon'
                          src="/media/img/DownArrow.svg"
                        />
                      </div>

                      <div
                        className='base-action-icon'
                        onClick={() => {
                          this.onOrderChangeClick(config, 'order_up')
                        }}
                      >
                        <InlineSvg
                          className='base-order-up-icon'
                          src="/media/img/UpArrow.svg"
                        />
                      </div>

                      <div
                        className='base-action-icon'
                        onClick={() => {
                          this.onOrderChangeClick(config, 'order_top')
                        }}
                      >
                        <InlineSvg
                          className='base-to-top-icon rotate'
                          src="/media/img/to-bottom-arrow.svg"
                        />
                      </div>
                    </div>
                  }
                />
              </Table>
            </React.Fragment>
            : null
          rowSettings =
            <React.Fragment key={idx}>
              <div className={'row-settings more-space'}>
                {spikeType}
                {filterBy}
              </div>
              <div className={'row-settings'}>
                {bulkActions}
              </div>
              {configs}
            </React.Fragment>
        }
        const categoryConfigsContent =
          <React.Fragment key={idx}>
            <div className={'configs-container'}>
              <div className={'category-header-container'}>
                <div className={'category-header-left'}>
                  <div
                    className={classNames('category-arrow',
                      { 'collapsed': !categoryToggle })}
                    onClick={() => {
                      this.toggleCategorySection(cat.category)
                    }}
                  />
                  <div className={'category-header'}>
                    {this.getCategoryLabel(cat.category, true)}
                  </div>
                </div>
                <div
                  className={classNames('collapse-help-text',
                    { 'hide': categoryToggle })}
                >
                  {`Click Arrow to Manage Settings for Each
                    ${this.getCategoryLabel(cat.category)} Search `}
                </div>
              </div>
              {rowSettings}
            </div>

            <div className={'category-action-links'}>
              <div
                className={classNames('back-to-top-link-container',
                  { 'hide': !categoryToggle })}
              >
                <a
                  className={'back-to-top-link'}
                  href={backToTopLinkId}
                >
                  Back to the Top
                </a>
              </div>
            </div>
          </React.Fragment>
        return (
          <React.Fragment key={idx}>
            {categoryConfigsContent}
          </React.Fragment>
        )
      })
      : null
    const categoryDefaultsCategories = this.props.baseNoticeConfigs.categoryDefaultsModalState
      ? deepCopy(this.props.baseNoticeConfigs.categoryDefaultsModalState.categories)
      : []
    const categoryCount = this.props.baseNoticeConfigs
      && this.props.baseNoticeConfigs.categoryDefaultsModalState
      ? categoryDefaultsCategories.length
      : 0
    const defaultCategories = this.props.baseNoticeConfigs
      && this.props.baseNoticeConfigs.categoryDefaultsModalState
      ? <Table
        className={'category-default-table'}
        data={categoryDefaultsCategories}
      >
        <Table.Column
          name="category"
          label={'Saved Search Category'}
          baseWidth={150}
          isSortable
          sortBy={cat => { return this.getCategoryLabel(cat.category) }}
          cellContents={cat => this.getCategoryLabel(cat.category)}
        />
        <Table.Column
          name="frequency"
          label={'Frequency'}
          baseWidth={150}
          cellContents={categorySetting => this.getDefaultFrequencyDropdown(
            { categorySetting, frequencyOptions, categoryCount, categoryDefaultsCategories }
          )}
        />
        <Table.Column
          name="maxArticles"
          label={'Articles'}
          baseWidth={60}
          cellContents={cat => this.getDefaultMaxItemsDropdown({
            categorySetting: cat,
            type: constants.PUBLICATION_TYPE_DEFAULT,
            maxItems: cat.noticeMaxStories,
            categoryCount: categoryCount,
            categoryDefaultsCategories: categoryDefaultsCategories,
          })}
        />
        <Table.Column
          name="maxFilings"
          label={'Filings'}
          baseWidth={60}
          cellContents={cat => this.getDefaultMaxItemsDropdown({
            categorySetting: cat,
            type: constants.PUBLICATION_TYPE_FILINGS,
            maxItems: cat.filingMaxItems,
            categoryCount: categoryCount,
            categoryDefaultsCategories: categoryDefaultsCategories,
          })}
        />
        <Table.Column
          name="maxTweets"
          label={'Tweets'}
          baseWidth={60}
          cellContents={cat => this.getDefaultMaxItemsDropdown({
            categorySetting: cat,
            type: constants.PUBLICATION_TYPE_TWITTER,
            maxItems: cat.twitterMaxItems,
            categoryCount: categoryCount,
            categoryDefaultsCategories: categoryDefaultsCategories,
          })}
        />
        <Table.Column
          name="maxEvents"
          label={'Events'}
          baseWidth={60}
          cellContents={cat => this.getDefaultMaxItemsDropdown({
            categorySetting: cat,
            type: constants.PUBLICATION_TYPE_EVENT,
            maxItems: cat.eventMaxItems,
            categoryCount: categoryCount,
            categoryDefaultsCategories: categoryDefaultsCategories,
          })}
        />
      </Table>
      : null
    const categoryDefaultsModal = this.props.baseNoticeConfigs
      && this.props.baseNoticeConfigs.categoryDefaultsModalState
      ? <Modal
        className="category-defaults-modal"
        isOpen={this.props.baseNoticeConfigs.categoryDefaultsModalState.show}
        onClose={() => { this.onCategoryDefaultsModalClose() }}
      >
        <div className="modal-header">
          Change Category Defaults
        </div>
        <div className={'modal-help-text'}>
          These are the default category settings used when new searches are created.
          Click save to complete changes.
        </div>
        <div className="default-cats-container">{defaultCategories}</div>
        <div className={'button-container'}>
        {this.props.isGroupUser && <div className={'category-default-container'}>{applyCategoryDefault}</div>}
          <div className={'category-default-container'}>
            <div className={'save-button'}>
              <button
                className={'button primary'}
                onClick={() => { this.onSaveCategoryDefaultsClick(categoryDefaultsCategories) }}
              >
                Save Settings
              </button>
            </div>
            <div className={'cancel-container'}>
              <a className="cancel cancel-action"
                onClick={() => { this.onCategoryDefaultsModalClose() }}
              >
                Cancel
              </a>
            </div>
          </div>
        </div>
      </Modal>
      : null
    const freqAllSettings = this.props.baseSettings.settings.find(
      s => s.noticeFrequency === constants.ALL_FREQ)
    const contentSettingsModalState = this.props.baseNoticeConfigs
      && this.props.baseNoticeConfigs.contentSettingsModalState
      ? this.props.baseNoticeConfigs.contentSettingsModalState
      : null
    const modalContentSettings = contentSettingsModalState
      ? contentSettingsModalState.contentSettings
      : null
    const modalContentSettingsConfig = contentSettingsModalState
      ? contentSettingsModalState.searchConfig
      : null
    const modalContentSettingsSearchName = modalContentSettingsConfig
      ? modalContentSettingsConfig.searchName
      : null
    const sendAloneOption = modalContentSettingsConfig
      && modalContentSettingsConfig.frequency !== constants.INTRA_DAY_FREQ
      ? <InputBlock
        className={'input-label send-alone-container'}
        label='Deliver this search in a separate email alert'
        isInline={true}
      >
        <input
          className={'input-checkbox'}
          type="checkbox"
          checked={this.props.baseNoticeConfigs.contentSettingsModalState.sendAlone}
          onChange={(e) => this.onSendAloneModalStateChange(e)}
        />
      </InputBlock>
      : null
    const contentSettingsTable = contentSettingsModalState
      ? <Table
        className={'content-settings-table'}
        data={modalContentSettings}
      >
        <Table.Column
          name="contentType"
          label={'Content Type'}
          baseWidth={200}
          isSortable
          sortBy={setting => {
            return setting.noticePublicationType
          }}
          cellContents={setting =>
            constants.NOTICE_PUBLICATION_TYPE[setting.noticePublicationType]
          }
        />
        <Table.Column
          name="maxItems"
          label={'Number of Items'}
          baseWidth={100}
          isSortable
          sortBy={setting => {
            return setting.maxItems
          }}
          cellContents={setting => this.getMaxItemsDropdown({
            setting: setting,
            config: modalContentSettingsConfig,
            modalContentSettings: modalContentSettings,
          })}
        />
        <Table.Column
          name="order"
          label={'Change Order'}
          baseWidth={80}
          cellContents={setting =>
            <ItemsSortOrder
              itemIndex={setting.order}
              sortOrderFunc={contentSettingsSortOrderFunc}
              itemsArray={modalContentSettings}
              noBorders={true}
              updateOrderFunc={this.props.changeContentSettingsModalSettings}
            />
          }
        />
      </Table>
      : null
    const contentSettingsModal = contentSettingsModalState
      ? <Modal
        className="content-settings-modal"
        isOpen={this.props.baseNoticeConfigs.contentSettingsModalState.show}
        onClose={() => { this.onContentSettingsModalClose() }}
      >
        <div className="modal-header bottom-border">
          Manage Content Settings for {modalContentSettingsSearchName}
        </div>
        <div className="content-settings-table-container">{contentSettingsTable}</div>
        {sendAloneOption}
        <div className={'button-container'}>
          <div className={'save-button'}>
            <button
              className={'button primary'}
              onClick={() => {
                this.onSaveContentSettingsClick()
              }}
            >
              Save Settings
            </button>
          </div>
          <div className={'cancel-container'}>
            <a className="cancel cancel-action"
              onClick={() => { this.onContentSettingsModalClose() }}
            >
              Cancel
            </a>
          </div>
        </div>
      </Modal>
      : null
    const manageHeader = this.props.isProfilePage
      ? <div className={'help-text'}>
        Your Personal Email Alert contains articles that have resulted from your Saved Searches.
        You can change the frequency per Saved Search by interacting with the frequency dropdown
        selector in the frequency column.
      </div>
      : <div className={'header'}>
        {`Manage Email Notifications for ${this.props.editUserFullName}`}
      </div>
    let schedulesColumn1 = []
    Object.keys(constants.SCHEDULE_BLOCKS).forEach(schedule => {
      if (schedule < 7) {
        schedulesColumn1.push(this.getTimeOfDayDeliveryOptions(schedule))
      }
    })
    let schedulesColumn2 = []
    Object.keys(constants.SCHEDULE_BLOCKS).forEach(schedule => {
      if (schedule > 6) {
        schedulesColumn2.push(this.getTimeOfDayDeliveryOptions(schedule))
      }
    })

    const enableInsightsDataLabel = `Enable ${titleCase(this.props.insightsLabel)} Data`
    const healthBadgeHelpText =
      `Include ${titleCase(this.props.insightsLabel)} health scores for 
      applicable companies and industries in email alerts.`

    const enableInsightsDataToggle = this.props.isInsightsEnabled
      ? <div className={'include-insights-data-container'}>
        <div className={'switch-container'}>
          <Switch
            onChange={(checked) => this.onIncludeInsightsToggleChange(checked)}
            checked={freqAllSettings.mergeInsightsIntoBase}
            height={22}
            width={48}
            handleDiameter={16}
            offColor="#ccc"
            onColor="#0074b4"
            uncheckedIcon={
              <div className="switch-icon">NO</div>
            }
            checkedIcon={
              <div className="switch-icon">YES</div>
            }
          />
        </div>
        <div className={'include-insights-text'}>
          <div className={'label'}>
            {enableInsightsDataLabel}
          </div>
          <div className={'help-text'}>
            {healthBadgeHelpText}
          </div>
        </div>
      </div>
      : null
      const a1 =`Apply Delivery Day to Group Members`
    const prop = this.state.deliveryTimeCheckbox
    const applyDeiliveryDay =<div  style={{marginRight:"7.5em"}}> <InputBlock
      className={'input-label'}
      label={a1} isInline
      style={{display:'flex'}}
    >
      <input
        className={'email-delivery-input'}
        type="checkbox"
        checked={freqAllSettings.deliveryDayToGroupMembers}
        onChange={(e) => this.applyDeliverySetting(e)}
      />
    </InputBlock></div>
    const applyDeiliveryTime = <div style={{marginLeft:"5px"}}> <InputBlock
    className={'input-label'}
    label={'Apply Delivery Time of Day to Group Members'} isInline
    style={{display:'flex'}}
  >
    <input
      className={'email-delivery-input'}
      type="checkbox"
      checked={freqAllSettings.deliveryTimeToGroupMembers}
      onChange={(e) => this.applyDeliveryTimeSetting(e)}
    />
  </InputBlock></div>
    
    const applyHideDupArticles = <div> <InputBlock
    className={'input-label'}
    label={'Apply Hide Duplicate Articles to Group Members'} isInline
    style={{display:'flex'}}
  >
    <input
      className={'email-delivery-input'}
      type="checkbox"
      checked={freqAllSettings.duplicateArticleToGroupMembers}
      onChange={(e) => this.applyHideDuplicateSetting(e)}
    />
  </InputBlock></div>

    
    const applyOrderAlphabetically = <div> <InputBlock
    className={'input-label'}
    label={'Apply Order Searches Alphabetically to Group Members'} isInline
    style={{display:'flex'}}
  >
    <input
      className={'email-delivery-input'}
      type="checkbox"
      checked={freqAllSettings.searchesAlphabeticallyToGroupMembers}
      onChange={(e) => this.applyOrderAlphabeticallySetting(e)}
    />
  </InputBlock></div>  
    return (
      <div className={'base-email-notifications-container'}>
        <SendSampleEmail notificationType={this.props.baseSettings.notificationType} />
        {categoryDefaultsModal}
        {contentSettingsModal}
        {manageHeader}
        {emailDeliveryOptions}
        <div className={'frequency-container'}>
          <div id={`all-frequency-container`}>
            <div className={'settings-container'}>
              <div className={'left-settings-container'}>
                <div className={'row-settings'}>
                  <div
                    className={classNames('time-of-day-options-container top-delivery-options',
                      { 'is-profile-page': this.props.isProfilePage })}
                  >
                    <div className={'time-of-day-label'}>Delivery Time of Day</div>
                    <div className={'time-of-day-options-columns'}>
                      <div className={'time-of-day-column-one'}>
                        {schedulesColumn1}
                      </div>
                      <div className={'time-of-day-column-two'}>
                        {schedulesColumn2}
                      </div>
                    </div>
                  </div>
                  <div className={'delivery-options-column top-delivery-options'}>
                    {deliveryDay}
                    {alternateFormat}
                    {sendZeroResults}
                  </div>
                </div>
                {this.props.isGroupUser && 
                <div className={'row-settings'} style={{display:'flex'}} >
                  {applyDeiliveryTime}
                  {applyDeiliveryDay}
                </div>}
                <div className={'row-settings more-space'}>
                  {additionalRecipients}
                </div>
                {enableInsightsDataToggle}
                { this.props.isGroupUser ? <div className={'left-multi-column'} style={{flexDirection: "column"}}>                  <div
                  className={'row-settings'}
                    style={{display:"flex"}}
                  >
                    <div>
                    {hideDuplicateArticles}
                    {this.props.isGroupUser && applyHideDupArticles}
                    </div>                    
                    <div className={'divider'} />
                    <div>                    
                    <InputBlock
                      className={'input-label alpha-order-container'}
                      label='Order Searches Alphabetically'
                      isInline={true}
                    >
                      <input
                        className={'input-checkbox'}
                        type="checkbox"
                        checked={freqAllSettings.alphabeticalOrder}
                        onChange={(e) => this.onAlphabeticalOrderChange(e)}
                      />
                    </InputBlock>
                    {this.props.isGroupUser && applyOrderAlphabetically}
                    </div>                  
                  </div>
                  <div style={{marginTop:"25px"}}>
                  {summaryTextLength}
                  </div>
                  <div className='top-button-container'>
                    {sendSampleEmail}
                    <div className={'divider'} />
                    {updateCategoryDefaults}
                  </div>
                </div> : <div className={'left-multi-column'}>
                  <div
                    className={classNames("column-one", { 'is-profile-page': this.props.isProfilePage })}
                  >
                    {hideDuplicateArticles}
                    <InputBlock
                      className={'input-label alpha-order-container'}
                      label='Order Searches Alphabetically'
                      isInline={true}
                    >
                      <input
                        className={'input-checkbox'}
                        type="checkbox"
                        checked={freqAllSettings.alphabeticalOrder}
                        onChange={(e) => this.onAlphabeticalOrderChange(e)}
                      />
                    </InputBlock>
                    {sendSampleEmail}
                  </div>
                  <div className={'divider'} />
                  <div
                    className={classNames('column-two', { 'is-profile-page': this.props.isProfilePage })}
                  >
                    {summaryTextLength}
                    {updateCategoryDefaults}
                  </div>
                </div>}                  
              </div>
              <div className={'right-settings-container'}>
                <div
                  className={'category-order-label'}
                >
                  Category Order
                </div>
                <div className={"order-items-container"}>{categorySortOrderItems}</div>
                {this.props.isGroupUser && <div>{applyCategoryOrder}</div>}
              </div>
            </div>
            {categoryConfigs}
          </div>
        </div>
      </div>
    )
  }
  onSaveContentSettingsClick() {
    const modalState = this.props.baseNoticeConfigs.contentSettingsModalState
    this.props.updateContentSettings({
      editUserId: this.props.editUserId,
      notificationType: this.props.baseSettings.notificationType,
      contentSettingsData: [{
        searchConfigId: modalState.searchConfig.id,
        contentSettings: modalState.contentSettings
      },]
    })
    this.onSendAloneChange(modalState.sendAlone, modalState.searchConfig.id)
    this.onContentSettingsModalClose()
  }
  onContentSettingsModalClose() {
    this.props.changeContentSettingsModalState({
      show: false,
      searchConfig: {},
      contentSettings: [],
      sendAlone: null,
    })
  }
  onSendSampleButtonClick() {
    this.props.changeSendSampleModal({
      show: true,
      notificationType: this.props.baseSettings.notificationType,
      noticeFrequency: this.props.baseFrequency,
      recipients: [this.props.defaultUserEmail],
      editUserId: this.props.editUserId,
    })
  }
  getFrequencyDropDown({ config, category, frequencyOptions }) {
    return (
      <InputBlock
        className={'max-items-dropdown frequency-dropdown'}
        label={'Frequency'}
        key={config.id}
      >
        <Dropdown
          type={'text'}
          options={frequencyOptions}
          value={constants.BASE_EMAIL_FREQUENCIES[config.frequency]}
          onSelect={this.onFrequencySelect.bind(this, config)}
        />
      </InputBlock>
    )
  }
  getSearchName(config) {
    return (
      <span
        className={classNames({ 'send-alone-search': config.sendAlone })}
      >
        {config.searchName}
      </span>
    )
  }
  getContentSettings({ config, category, frequencyOptions }) {
    const contentSettings = JSON.parse(
      JSON.stringify(this.props.baseNoticeConfigs.baseContentConfigs[config.id])
    )
    const frequencyContainer = this.getFrequencyDropDown({ config, category, frequencyOptions })
    contentSettings.map((item, idx) => { if (item.id === null) { item.id = `null${idx}` } })
    const contentSettingsContainer = contentSettings.map(setting => {
      return (
        this.getMaxItemsDropdown({
          setting: setting,
          config: config,
          modalContentSettings: null,
        })
      )
    })
    return (
      <div className={'settings-dropdowns'}>
        {frequencyContainer}
        {contentSettingsContainer}
      </div>
    )
  }
  onModalMaxItemsSelect(setting, contentSettings, selectedOptionData) {
    const updateItem = contentSettings.find(item => { return item.id === setting.id })
    if (updateItem) {
      updateItem.maxItems = selectedOptionData.key
      this.props.changeContentSettingsModalSettings(contentSettings)
    }
  }
  onMaxItemsSelect(setting, config, selectedOptionData) {
    const contentSettings = this.props.baseNoticeConfigs.baseContentConfigs
      && this.props.baseNoticeConfigs.baseContentConfigs[setting.noticeConfig]
      ? JSON.parse(JSON.stringify(
        this.props.baseNoticeConfigs.baseContentConfigs[setting.noticeConfig]))
      : null
    if (contentSettings) {
      const updateSetting = contentSettings.find(
        s => s.noticePublicationType === setting.noticePublicationType)
      updateSetting.maxItems = selectedOptionData.key
      this.props.updateContentSettings({
        editUserId: this.props.editUserId,
        notificationType: this.props.baseSettings.notificationType,
        contentSettingsData: [{
          searchConfigId: config.id,
          contentSettings: contentSettings
        },]
      })
    }
  }
  getMaxItemsDropdown({ setting, config, modalContentSettings }) {
    let dropUp = false
    let label = constants.NOTICE_PUBLICATION_TYPE[setting.noticePublicationType]
    let displayOptions = config.searchCategory === constants.TRUSTED
      || config.searchCategory === constants.TRUSTED_UNCATEGORIZED
      ? constants.MAX_ITEMS_OPTIONS_NUMERIC_ALL
      : constants.MAX_ITEMS_OPTIONS_NUMERIC
    let activeMaxItems = setting.maxItems
    let onSelectFunc = this.onMaxItemsSelect.bind(this, setting, config)
    if (modalContentSettings) {
      const typeCount = modalContentSettings.length
      const lastItemIndex = typeCount > 0
        ? typeCount - 1
        : 0
      const nextToLastItemIndex = typeCount > 1
        ? typeCount - 2
        : 0
      dropUp = lastItemIndex === setting.order || nextToLastItemIndex === setting.order
      label = ''
      displayOptions = config.searchCategory === constants.TRUSTED
        || config.searchCategory === constants.TRUSTED_UNCATEGORIZED
        ? constants.MAX_ITEMS_OPTIONS_ALL
        : constants.MAX_ITEMS_OPTIONS
      activeMaxItems = constants.MAX_ITEMS_LOOKUP[setting.maxItems]
      onSelectFunc = this.onModalMaxItemsSelect.bind(this, setting, modalContentSettings)
    }
    return (
      <InputBlock
        className={`max-items-dropdown ${setting.noticePublicationType}`}
        label={label}
        key={setting.order}
      >
        <Dropdown
          options={displayOptions}
          value={activeMaxItems}
          dropUp={dropUp}
          onSelect={onSelectFunc}
        />
      </InputBlock>
    )
  }
  onSpikeTypeOrFilterBySelect(category, fieldName, data) {
    this.props.changeNotificationSettings({
      noticeFrequency: this.props.baseFrequency,
      category: category,
      editUserId: this.props.editUserId,
      fieldName: fieldName,
      updateValue: data.key,
    })
  }
  onOrderChangeClick(config, direction) {
    this.props.saveBaseNoticeConfigs({
      editUserId: this.props.editUserId,
      type: this.props.baseSettings.notificationType,
      field: direction,
      value: config.order,
      configIds: [config.id],
    })
  }
  isConfigSelected(configId) {
    const configIsSelected = this.props.selectedConfigs[configId]
      ? this.props.selectedConfigs[configId].selected
      : false
    return (configIsSelected)
  }
  onConfigSelect(event, configId, category) {
    this.props.changeSelectedConfigs({
      configId: configId,
      noticeFrequency: this.props.baseFrequency,
      category: category,
      selected: event.target.checked
    })
  }
  onSaveCategoryDefaultsClick(categoryDefaultsCategories) {
    this.state.applyDefaultMember = this.state.defaultCategoryTemp
    this.props.updateCategoryDefaults({
      editUserId: this.props.editUserId,
      categoryDefaults: categoryDefaultsCategories,
      applyDefaultMember: this.state.applyDefaultMember,
    })
    this.onCategoryDefaultsModalClose()
    
  }
  onCategoryDefaultsModalClose() {
    this.props.changeCategoryDefaultsModalCategories([])
    this.props.changeCategoryDefaultsModalShow(false)

    if (this.state.applyDefaultMember !== this.state.defaultCategoryTemp) {
      this.state.defaultCategoryTemp = this.state.applyDefaultMember
    }
  }
  onUpdateCategoryDefaultsClick() {
    const modalCategories = JSON.parse(
      JSON.stringify(this.props.categoryDefaults)
    )
    this.props.changeCategoryDefaultsModalCategories(modalCategories)
    this.props.changeCategoryDefaultsModalShow(true)
  }
  onDefaultFrequencySelect(categorySetting, categoryDefaultsCategories, data) {
    categorySetting.noticeFreq = data.key
    this.props.changeCategoryDefaultsModalCategories(categoryDefaultsCategories)
  }
  onDefaultMaxItemsSelect(categorySetting, type, categoryDefaultsCategories, data) {
    if (type === constants.PUBLICATION_TYPE_DEFAULT) { categorySetting.noticeMaxStories = data.key }
    else if (type === constants.PUBLICATION_TYPE_FILINGS) { categorySetting.filingMaxItems = data.key }
    else if (type === constants.PUBLICATION_TYPE_TWITTER) { categorySetting.twitterMaxItems = data.key }
    else if (type === constants.PUBLICATION_TYPE_EVENT) { categorySetting.eventMaxItems = data.key }
    this.props.changeCategoryDefaultsModalCategories(categoryDefaultsCategories)
  }
  getDefaultFrequencyDropdown({ categorySetting, frequencyOptions, categoryCount,
    categoryDefaultsCategories }) {
    return (
      <InputBlock
        className={'default-frequency-dropdown'}
        label={''}
        key={categorySetting.category}
      >
        <Dropdown
          type={'text'}
          options={frequencyOptions}
          value={constants.BASE_EMAIL_FREQUENCIES[categorySetting.noticeFreq]}
          dropUp={categorySetting.noticeOrder === categoryCount - 1
            || categorySetting.noticeOrder === categoryCount - 2}
          onSelect={this.onDefaultFrequencySelect.bind(this, categorySetting,
            categoryDefaultsCategories)}
        />
      </InputBlock>
    )
  }
  getDefaultMaxItemsDropdown({ categorySetting, type, maxItems, categoryCount,
    categoryDefaultsCategories }) {
    if (categorySetting.noticeFreq === constants.NONE_FREQ) { return }
    return (
      <div
        className={'settings-dropdowns'}
        key={categorySetting.category + type}
      >
        <InputBlock
          className={'default-max-items-dropdown'}
          label={''}
        >
          <Dropdown
            type={'text'}
            options={categorySetting.category === constants.TRUSTED
              || categorySetting.category === constants.TRUSTED_UNCATEGORIZED
              ? constants.MAX_ITEMS_OPTIONS_NUMERIC_ALL
              : constants.MAX_ITEMS_OPTIONS_NUMERIC}
            value={maxItems}
            dropUp={categorySetting.noticeOrder === categoryCount - 1
              || categorySetting.noticeOrder === categoryCount - 2}
            onSelect={this.onDefaultMaxItemsSelect.bind(
              this, categorySetting, type, categoryDefaultsCategories)}
          />
        </InputBlock>
      </div>
    )
  }
  toggleCategorySection(category) {
    const show = this.props.categoryToggle && this.props.categoryToggle[category] !== undefined
      ? !this.props.categoryToggle[category]
      : true
    this.props.changeCategoryToggle({
      'category': category,
      'show': show,
    })
  }
  onEditContentSettingsClick(config) {
    const searchConfig = Object.assign({}, config)

    // deep copy as it's an array of objects
    const contentSettings = JSON.parse(
      JSON.stringify(this.props.baseNoticeConfigs.baseContentConfigs[config.id])
    )
    contentSettings.forEach((item, idx) => { if (item.id === null) { item.id = `null${idx}` } })
    this.props.changeContentSettingsModalState({
      show: true,
      searchConfig: searchConfig,
      contentSettings: contentSettings,
      sendAlone: searchConfig.sendAlone,
    })

  }
  onAlphabeticalOrderChange(event) {
    this.props.saveEmailAlertSettings({
      editUserId: this.props.editUserId,
      noticeFrequency: this.props.baseFrequency,
      type: this.props.baseSettings.notificationType,
      field: constants.ALPHABETICAL_ORDER,
      value: event.target.checked,
      deliveryOrderSearchesSettings: this.state.orderAlphabeticallyCheckbox? true:null,
    })
  }
  onIncludeInsightsToggleChange(checked) {
    this.props.saveEmailAlertSettings({
      editUserId: this.props.editUserId,
      noticeFrequency: this.props.baseFrequency,
      type: this.props.baseSettings.notificationType,
      field: constants.MERGE_INSIGHTS_INTO_BASE,
      value: checked
    })
  }
  onSendAloneModalStateChange(event) {
    const modalState = this.props.baseNoticeConfigs.contentSettingsModalState
    this.props.changeContentSettingsModalState({
      show: true,
      searchConfig: modalState.searchConfig,
      contentSettings: modalState.contentSettings,
      sendAlone: event.target.checked,
    })
  }
  onSendAloneChange(value, configId) {
    this.props.saveBaseNoticeConfigs({
      editUserId: this.props.editUserId,
      type: this.props.baseSettings.notificationType,
      field: constants.SEND_ALONE,
      value: value,
      configIds: [configId],
    })
  }
  onFrequencySelect(config, data) {
    this.props.saveBaseNoticeConfigs({
      editUserId: this.props.editUserId,
      type: data.notificationType,
      field: data.fieldName,
      value: data.key,
      configIds: [config.id],
    })
  }
  onTimeOfDayDeliveryChange(event, schedule) {
    this.props.saveEmailAlertSettings({
      editUserId: this.props.editUserId,
      noticeFrequency: this.props.baseFrequency,
      type: this.props.baseSettings.notificationType,
      field: constants.EMAIL_SCHEDULES_ARRAY,
      value: event.target.checked,
      emailSchedule: schedule,
      deliveryTimeSettings: this.state.deliveryTimeCheckbox?true:null,
    })
  }
  getTimeOfDayDeliveryOptions(schedule) {
    const settings = this.props.baseSettings.settings.find(
      setting => setting.noticeFrequency === this.props.baseFrequency)
    return (
      <InputBlock
        className={'input-label time-of-day-options'}
        label={constants.SCHEDULE_BLOCKS[schedule]}
        isInline={true}
        key={schedule}
      >
        <input
          className={'input-checkbox'}
          type="checkbox"
          checked={settings.emailSchedule.includes(parseInt(schedule))}
          onChange={(e) => this.onTimeOfDayDeliveryChange(e, schedule)}
        />
      </InputBlock>
    )
  }
  getCategoryLabel(category, plural = false) {
    const default_ = plural ? constants.CATEGORIES[category] : constants.CATEGORIES_SINGULAR[category]
    const { currentFirm } = this.props
    return searchCategory(
      category,
      {
        plural,
        useDefaults: false,
        currentFirm,
      }
    ) || default_
  }

  applyCategoryOrders(event) {
    const isChecked = event.target.checked
    this.setState({ applyMember: isChecked }, () => {
      this.props.applyCategoryOrdersSettings({
        editUserId: this.props.editUserId,
        isSelected: isChecked
      })
    })
  }

  applyDeliverySetting(event) {
    const isChecked = event.target.checked
    this.props.saveEmailAlertSettings({
      editUserId: this.props.editUserId,
      noticeFrequency: this.props.baseFrequency,
      deliveryDaySettings: isChecked,
      type: this.props.baseSettings.notificationType,
      field: constants.DELIVERY_GROUP_MEMBER,
      value: false
    })
  }

  applyDeliveryTimeSetting(event){
    const isChecked = event.target.checked
    this.props.saveEmailAlertSettings({
      editUserId: this.props.editUserId,
      noticeFrequency: this.props.baseFrequency,
      deliveryTimeSettings: isChecked,
      type: this.props.baseSettings.notificationType,
      field: constants.DELIVERY_GROUP_MEMBER,
      value: false
    })
  }

  applyHideDuplicateSetting(event){
    const isChecked = event.target.checked
    this.props.saveEmailAlertSettings({
      editUserId: this.props.editUserId,
      noticeFrequency: this.props.baseFrequency,
      deliveryHideDuplicateSettings: isChecked,
      type: this.props.baseSettings.notificationType,
      field: constants.DELIVERY_GROUP_MEMBER,
      value: false
    })
  }
  
  applyOrderAlphabeticallySetting(event){
    const isChecked = event.target.checked
    this.props.saveEmailAlertSettings({
      editUserId: this.props.editUserId,
      noticeFrequency: this.props.baseFrequency,
      deliveryOrderSearchesSettings: isChecked,
      type: this.props.baseSettings.notificationType,
      field: constants.DELIVERY_GROUP_MEMBER,
      value: false
    })
  }

  onUseAlternateEmailTemplateChange(event) {
    this.props.saveEmailAlertSettings({
      editUserId: this.props.editUserId,
      noticeFrequency: this.props.baseFrequency,
      type: this.props.baseSettings.notificationType,
      field: constants.USE_ALTERNATE_EMAIL_TEMPLATE,
      value: event.target.checked
    })
  }
}
function contentSettingsSortOrderFunc(settings, updateFunc, extraParams) {
  const settingsCopy = deepCopy(settings)
  settingsCopy.map((setting, idx) => {
    setting.order = idx
  })
  updateFunc(settingsCopy)
}

function categorySortOrderFunc(categories, updateFunc, extraParams, applyGroupMembers) {
  const categoriesCopy = deepCopy(categories)
  categoriesCopy.map((cat, idx) => {
    cat.order = idx
  })
  const updateParams = { ...extraParams }
  updateParams.categories = categoriesCopy
  updateFunc(updateParams)
}
export function init(data) {
  const container = document.getElementById('django-base-email-notifications-container')
  if (!container) return
  store.dispatch(actions.baseNotificationsInit(changeCaseObject.camelCase(data)))
  const component =
    <AppWrapper>
      <BaseNotifications />
    </AppWrapper>
  render(component, container)
}
