export const FOCUS_LEVEL_LOW = 'low'
export const FOCUS_LEVEL_MEDIUM = 'medium'
export const FOCUS_LEVEL_HIGH = 'high'

export const RELEVANCE_DEFAULT = 'default'
export const RELEVANCE_INTRO = 'intro'
export const RELEVANCE_HEADLINE = 'headline'

export const RELEVANCE_LOOKUP = {
  [FOCUS_LEVEL_LOW]: RELEVANCE_DEFAULT,
  [FOCUS_LEVEL_MEDIUM]: RELEVANCE_INTRO,
  [FOCUS_LEVEL_HIGH]: RELEVANCE_HEADLINE,
}

export const RELEVANCE_LEVEL_LOOKUP = {
  [RELEVANCE_DEFAULT]: FOCUS_LEVEL_LOW,
  [RELEVANCE_INTRO]: FOCUS_LEVEL_MEDIUM,
  [RELEVANCE_HEADLINE]: FOCUS_LEVEL_HIGH,
}
