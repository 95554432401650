import {flatten, fromPairs, map, pipe, toPairs} from 'ramda'
import snakeCase from 'snake-case'

import {FILTER_CATEGORY_LABELS, FILTER_CATEGORY_OTHER} from 'app/constants'


/**
 * Prepare search filters for saving.
 */
export function flattenFilterData(filterData) {
  return pipe(
    toPairs,
    map(([key, filters]) =>
      filters.map(filter => ({
        id: filter.filterId,
        groupId: filter.filterGroupId,
        filterField: key === 'your' && filter.firmSourceLabelId ? null : snakeCase(key),
        specialType: filter.filterGroupSpecial,
        label: filter.label,
        value: filter.value,
        isFreeText: !filter.searchId && !filter.firmSourceLabelId && !filter.filterGroupSpecial,
        searchId: filter.searchId,
        firmSourceLabelId: filter.firmSourceLabelId,
      }))
    ),
    flatten,
  )(filterData)
}


/**
 * Builds a query string from an array of terms. Terms can be strings,
 * representing free-text terms, or integers, representing search IDs.
 */
export function queryFromTerms(terms) {
  return terms.map(term => isNaN(term) ? term : `ss:${term}`).join('~|')
}


export function getEntityDataFromSearch(search) {
  const users = {}
  const searches = {}
  const memberships = {}
  const feeds = {}
  const departments = {}
  const teams = {}
  const actionLog = {}
  // parent searches are needed for core terms.
  if (search.parentSearches) {
    search.parentSearches.forEach(ss => {
      users[ss.owner.id] = {...ss.owner}
      ss.ownerId = ss.owner.id
      searches[ss.id] = {...ss}
    })
  }
  // child searches are needed for listing profiles and other searches using this search.
  if (search.childSearches) {
    search.childSearches.forEach(ss => {
      users[ss.owner.id] = {...ss.owner}
      ss.ownerId = ss.owner.id
      searches[ss.id] = {...ss}
      if (ss.owner.userMemberships) {
        ss.owner.userMemberships.forEach(m => {
          m.userId = m.user.id
          m.groupId = m.group.id
          memberships[m.id] = {...m}
          users[m.user.id] = {...m.user}
          users[m.group.id] = {...m.group}
        })
      }
    })
  }
  // usedAsFilterBy is needed for listing other searches using this search.
  if (search.usedAsFilterBy) {
    search.usedAsFilterBy.forEach(ss => {
      users[ss.owner.id] = {...ss.owner}
      ss.ownerId = ss.owner.id
      searches[ss.id] = {...ss}
    })
  }
  if (search.owner) {
    if (search.owner.userMemberships) {
      search.owner.userMemberships.forEach(m => {
        m.userId = m.user.id
        m.groupId = m.group.id
        memberships[m.id] = {...m}
        users[m.user.id] = {...m.user}
        users[m.group.id] = {...m.group}
      })
    }
    search.ownerId = search.owner.id
    users[search.owner.id] = {...search.owner}
  }
  if (search.excludedFeeds) {
    search.excludedFeedsIds = []
    search.excludedFeeds.forEach(f => {
      feeds[f.id] = {...f}
      search.excludedFeedsIds.push(f.id)
    })
  }
  if (search.featuredForUsers) {
    search.featuredForUsersIds = []
    search.featuredForUsers.forEach(u => {
      users[u.id] = {...u}
      search.featuredForUsersIds.push(u.id)
    })
  }
  if (search.featuredForDepartments) {
    search.featuredForDepartmentsIds = []
    search.featuredForDepartments.forEach(d => {
      departments[d.id] = {...d}
      search.featuredForDepartmentsIds.push(d.id)
    })
  }
  if (search.featuredForTeams) {
    search.featuredForTeamsIds = []
    search.featuredForTeams.forEach(t => {
      teams[t.id] = {...t}
      search.featuredForTeamsIds.push(t.id)
    })
  }
  if (search.actionLog) {
    search.actionLogIds = []
    search.actionLog.forEach(log => {
      users[log.user.id] = {...log.user}
      users[log.originalUser.id] = {...log.originalUser}
      actionLog[log.id] = {...log, userId: log.user.id, originalUserId: log.originalUser.id}
      search.actionLogIds.push(log.id)
    })
  }
  searches[search.id] = {...search}
  return {
    users,
    searches,
    memberships,
    feeds,
    actionLog,
    departments,
    teams,
  }
}


/**
 * Returns an array of the core terms in the search's query with the shape
 * {value, label, isFreeText}. For terms that refer to other searches, `value`
 * represents the search ID; for free text terms, `value` is the text.
 */
export function coreTermsForSearch(search) {
  let options = []
  /*
   * Treat feeds (`about_sources` on the backend, `aboutFeeds` on the frontend)
   * as core terms for display purposes. These kinds of terms are unique in that
   * they have a null `value`, so you can easily filter them out when needed.
   *
   * Generally, only trusted source folders and trusted sources should have
   * about sources, but there exist a lot of such searches with other
   * categories, presumably for legacy reasons, so we can't just test the
   * category here.
   */
  if (search.aboutFeeds && search.aboutFeeds.length) {
    options = [
      ...options,
      ...search.aboutFeeds.map(feed => ({
        label: feed.name,
        value: null, // The values are null so we can filter these out later.
        isFreeText: false,
        isFeed: true,
        isRemovable: false,
      })),
    ]
  }
  if (search.query) {
    if (search.queryType === 'raw') {
      return []
    }
    options = [
      ...options,
      ...search.query.split('~|').map(term => {
        if (term.startsWith('ss:')) {
          const ssId = parseInt(term.substring(3))
          return {
            value: ssId,
            isFreeText: false,
          }
        }
        return {
          value: term,
          label: term,
          isFreeText: true,
        }
      }),
    ]
  }
  return options
}


/**
 * Returns a flat array of filters for a search object.
 */
export function getFiltersForSearch(search) {
  return pipe(
    map(filterGroup =>
      filterGroup.filters.map(filter => ({
        id: filter.id,
        groupId: filterGroup.id,
        specialType: filterGroup.special,
        filterField: filterGroup.filterField,
        category: filterGroup.category,
        value: filter.search ? null : filter.queryTerm,
        searchId: filter.search && filter.search.id,
        label: filter.displayName,
        isFreeText: !filter.search && !!filter.queryTerm,
        firmSourceLabelId: filter.firmSourceLabel && filter.firmSourceLabel.id,
      }))
    ),
    flatten,
  )(search.filterGroups || [])
}


/**
 * Determines if the two filters are the same for the purposes of matching
 * pre-existing filters to ones selected in the filters bar.
 */
export function doFiltersMatch(filter1, filter2) {
  // NOTE: We need to test for IDs on both filters because if an ID only exists
  // on one side, we still need to check the other conditions.
  if (filter1.id && filter2.id) {
    return filter1.id === filter2.id
  }
  if (filter1.specialType) {
    return filter1.specialType === filter2.specialType
  }
  if (filter1.searchId) {
    return filter1.searchId === filter2.searchId
  }
  if (filter1.firmSourceLabelId) {
    return filter1.firmSourceLabelId === filter2.firmSourceLabelId
  }
  // check `filterField` after `specialType`, `searchId`, and `firmSourceLabelId` since it's
  // irrelevant in those cases (and not used consistently).
  if (filter1.filterField !== filter2.filterField) {
    return false
  }
  return filter1.value === filter2.value
}


/**
 * Returns a flat array of the search's filters with the `unappliedFilters`
 * added in and any in `removedFilters` removed.
 */
export function consolidateSearchFilters(
  search,
  unappliedFilters,
  removedFilters,
) {
  const existingFilters = getFiltersForSearch(search)
  return [
    ...existingFilters.filter(filter =>
      !removedFilters.some(removedFilter =>
        doFiltersMatch(removedFilter, filter)
      )
    ),
    ...unappliedFilters,
  ]
}


export function filtersByCategory(filters) {
  return pipe(
      map(filterCategory => {
        const key = FILTER_CATEGORY_LABELS[filterCategory.label] || FILTER_CATEGORY_OTHER
        return [key, filterCategory]
      }),
      fromPairs,
      map(filterCategory =>
        filterCategory.searches.map(search => {
          return ({
            search: {id: search.id, name: search.name},
            categoryId: filterCategory.id,
            filters: search.children.map(search => {
              return ({
                search,
                ownerId: search.owner.id,
                categoryId: filterCategory.id,
              })
            }),
          })
        })
      ),
    )(filters)
}
