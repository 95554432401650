import classNames from 'classnames'
import React, { Component } from 'react'
import { render } from 'react-dom'
import { connect, Provider } from 'react-redux'
import { createSelector } from 'reselect'

import * as entitiesSelectors from 'app/entities/entities-selectors'
import * as globalSelectors from 'app/global/global-selectors'
import * as routing from 'app/global/routing'
import { SavedSearch, User } from 'app/models'
import Orm from 'app/framework/Orm'
import store from 'app/store'
import * as urls from 'app/urls'

import NavItems from './NavItems'
import SearchBar from './SearchBar'
import SearchList from './SearchList'

import './style.less'
import { screenWidthForFullHamburgerMenu } from 'app/constants'


@connect(
  createSelector(
    [
      entitiesSelectors.getEntities,
      globalSelectors.getProfileSearchIds,
      globalSelectors.getCurrentUser,
      globalSelectors.getCurrentUserGroupIds,
      routing.selectors.getPathname,
      globalSelectors.getHighlightBlog,
      globalSelectors.getAppName,
      globalSelectors.getAppNameFull,
    ],
    (
      entities,
      profileSearchIds,
      currentUser,
      userGroupIds,
      currentPathname,
      highlightBlog,
      appName,
      appNameFull,
    ) => {
      const orm = Orm.withEntities(entities)
      return {
        searches: orm.getByIds(SavedSearch, profileSearchIds)
          .sort((s1, s2) => {
            const name1 = s1.name.trim()
            const name2 = s2.name.trim()
            return name1.localeCompare(name2)
          }),
        user: currentUser,
        groups: orm.getByIds(User, userGroupIds)
          .sort((group1, group2) => {
            const name1 = group1.displayName.trim().toLowerCase()
            const name2 = group2.displayName.trim().toLowerCase()
            return name1.localeCompare(name2)
          }),

        // Misc data
        currentPathname,
        highlightBlog,
        appName,
        appNameFull,
      }
    },
  ),
)
export class NavMenu extends Component {

  updateDimensions = () => {
    const showFullResponsiveMenu = window.innerWidth <= screenWidthForFullHamburgerMenu
    const secondaryNavBar = document.getElementById('nav-primary-bar')
    secondaryNavBar.hidden = showFullResponsiveMenu
  }
  componentDidMount() {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {

    const {groups, currentPathname} = this.props
    const isHomePage = currentPathname === urls.home()
    const siteSection = urls.tier3CategoryFromPathname(currentPathname)

    // This is so we can turn off tier2/Comparison for the portal group user
    const practice_url = this.props.user.isPortalGroup ? '.' : '/practice/'
    const industry_url = this.props.user.isPortalGroup ? '.' : '/industry/'
    const client_url = this.props.user.isPortalGroup ? '.' : '/client/'
    const competitor_url = this.props.user.isPortalGroup ? '.' : '/competitor/'
    const topic_url = this.props.user.isPortalGroup ? '.' : '/tracker/'

    let searchTypes = []
    if (groups.length) {
      searchTypes.push({
        navID: 'groups',
        category: 'group',
        siteSectionID: 'group',
        label: "Groups",
      })
    }
    if (this.props.user.firm.showPracticeAreas) {
        searchTypes.push({
          navID: 'practice-areas',
          category: 'practice',
          siteSectionID: 'practice',
          label: this.props.user.firm.practiceDisplayNamePlural,
          url: practice_url,
        })
    }
    searchTypes = [
      ...searchTypes,
      {
        navID: 'industries',
        category: 'industry',
        siteSectionID: 'industry',
        label: this.props.user.firm.industryDisplayNamePlural,
        url: industry_url,
      },
      {
        navID: 'client',
        category: 'client',
        siteSectionID: 'client',
        label: this.props.user.firm.clientDisplayNamePlural,
        url: client_url,
      },
      {
        navID: 'competitors',
        category: 'firm',
        siteSectionID: 'competitor',
        label: this.props.user.firm.competitorDisplayNamePlural,
        url: competitor_url,
      },
      {
        navID: 'trackers',
        category: 'tracker',
        siteSectionID: 'tracker',
        label: this.props.user.firm.trackerDisplayNamePlural,
        url: topic_url,
      },
      {
        navID: 'trusted',
        category: 'trusted',
        siteSectionID: 'trusted-sources',
        label: "Sources",
        url: '/profile/trusted_sources/',
      },
    ]

    const dropdowns = searchTypes.map(searchType => {
      const {category, label, navID} = searchType
      const categories = [category]
      if (category === 'trusted') {
        categories.push('trusted-uncategorized')
      } else if (category === 'client') {
        categories.push('prospect')
      }

      let searches
      if (category === 'group') {
        searches = groups
      } else {
        searches = this.props.searches
          .filter(search => categories.includes(search.category))
      }

      let manageSearches = null
      let searchMenus = null
      let dropMenu = null
      if (category != 'group') {
        let manageURL
        if (category === 'trusted') {
          manageURL = '/profile/trusted_sources/'
        } else if (category === 'competitor') {
          manageURL = '/profile/firm/'
        } else {
          manageURL = `/profile/${category}/`
        }

        if (searches.length) {
          manageSearches =
            <a href={manageURL}>
              Manage Your <span className="search-label">{category === 'trusted' ? 'Sources' : label}</span>
            </a>
        } else {
          manageSearches =
            <div>
              <p>There are no <span className="search-label">{label}</span> saved in your profile.</p>
              <p><a href={manageURL}>Click here</a> to add some.</p>
            </div>
        }
      }
      if (manageSearches) {
        manageSearches =
          <div
            className={
              classNames('manage-link', {'no-searches': !searches.length})
            }
          >
            {manageSearches}
          </div>
      }
          if (this.props.user.isPortalGroup && manageSearches){
           dropMenu =
             <div className="holder">
              <SearchList category={category} searches={searches} />
            </div>
            searchMenus = <div>{manageSearches}  {dropMenu}</div>
          }
          else {
           dropMenu =
             <div className="holder">
              <SearchList category={category} searches={searches} />
            </div>
            searchMenus = <div>{dropMenu}  {manageSearches}</div>
          }

      return (
        <div
          id={`nav-${navID}-drop-down`}
          className={classNames(
            'nav-drop-down',
            {
              active: searchType.siteSectionID === siteSection,
              'nav-right': (
                (
                  ['tracker', 'trusted'].includes(category)
                  && searches.length > 30
                )
                || searches.length > 45
              ),
            }
          )}
          key={navID}
        >
          {searchMenus}
        </div>
      )
    })

    return (
      <div>
        <NavItems
          currentSiteSection={siteSection}
          highlightBlog={this.props.highlightBlog}
          isHomePage={isHomePage}
          searchTypes={searchTypes}
        />

        {isHomePage ? null : <SearchBar appName={this.props.appName}/>}

        {dropdowns}
      </div>
    )
  }
}

export function init() {
  const container = document.getElementById('nav-primary-content')
  const menu = (
    <Provider store={store}>
      <NavMenu />
    </Provider>
  )
  render(menu, container)
}
