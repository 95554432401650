import { all, take, takeLatest, put, select, join, fork, delay } from 'redux-saga/effects'

import {handleSagaError} from 'app/utils/errors'
import * as notifications from 'app/global/notifications'

import * as api from './profile-group-members-api'
import * as actions from './profile-group-members-actions'
import * as entitiesActions from 'app/entities/entities-actions'
import {getProfileGroupMembers} from './profile-group-members-selectors'


function* handleFetchMembers(action) {
  const {groupProfileId} = yield select(getProfileGroupMembers)
  let response = null
  try {
    response = yield(api.fetchMembers(groupProfileId))
  } catch(error) {
    yield* handleSagaError(error)
    return
  }
  const users = {}
  const userIds = []
  if (response && response.users && response.users.items){
    response.users.items.forEach(u => {
      users[u.id] = {...u}
      userIds.push(u.id)
    })
  }
  yield put(entitiesActions.update({users}))
  yield put(actions.setMemberIds(userIds))
  yield put(actions.addUserIds(userIds))
  yield put(actions.setIsFetchingMembers(false))
}

function* handleFetchUsers(action) {
  const nameFilter = action.payload
  const {areAllUsersFetched} = yield select(getProfileGroupMembers)
  if (nameFilter === '' || areAllUsersFetched) {
    // only fetch all if requested (ie. nameFilter is undefined) and only do once.
    yield put(actions.setIsFetchingUsers(false))
    return
  }
  if (nameFilter) {
    yield delay(300)
  }
  let response = null
  try {
    response = yield(api.fetchUsers({nameFilter}))
  } catch(error) {
    yield* handleSagaError(error)
    yield put(actions.setIsLoading(false))
    return
  }
  const users = {}
  const userIds = []
  if (response && response.users && response.users.items){
    response.users.items.forEach(u => {
      users[u.id] = {...u}
      userIds.push(u.id)
    })
  }
  yield put(entitiesActions.update({users}))
  yield put(actions.addUserIds(userIds))
  if (!nameFilter) {
    yield put(actions.setAllUsersAreFetched())
  }
  yield put(actions.setIsFetchingUsers(false))
}

function* handleCreateMembership(action) {
  const groupMembers = yield select(getProfileGroupMembers)
  const groupId = groupMembers.groupUserId
  const {userId, emailAddress} = action.payload
  let response = null
  try {
    response = yield(api.createMembership({userId, emailAddress, groupId}))
  } catch(error) {
    yield* handleSagaError(error)
    return
  } finally {
    yield put(actions.setIsLoading(false))
  }
  yield put(actions.fetchMembers())
  yield put(actions.setNewEmailAddress(''))
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'User successfully added to group.',
    })
  )
}

function* handleDeleteMembership(action) {
  const groupMembers = yield select(getProfileGroupMembers)
  const groupId = groupMembers.groupUserId
  let response = null
  try {
    response = yield(api.deleteMembership({userId: action.payload, groupId}))
  } catch(error) {
    yield* handleSagaError(error)
    return
  } finally {
    yield put(actions.setIsLoading(false))
  }
  yield put(actions.fetchMembers())
  yield put(
    notifications.actions.showNotification({
      type: 'success',
      message: 'User successfully removed from group.',
    })
  )
}

export default function*() {
  yield all([
    takeLatest(
      actions.fetchMembers,
      handleFetchMembers
    ),
    takeLatest(
      actions.fetchUsers,
      handleFetchUsers
    ),
    takeLatest(
      actions.createMembership,
      handleCreateMembership
    ),
    takeLatest(
      actions.deleteMembership,
      handleDeleteMembership
    ),
  ])
}
