import React from 'react'
import {render} from 'react-dom'

import AppWrapper from 'app/global/app-wrapper'
import store from 'app/store'
import {changeCaseObject} from 'app/utils'

import * as actions from './dashboard-actions'
import * as constants from './dashboard-constants'
import reducer from './dashboard-reducer'
import saga from './dashboard-saga'
import Dashboard from './Dashboard'


export function init(data) {
  store.dispatch(actions.init(changeCaseObject.camelCase(data)))
  const component = (
    <AppWrapper>
      <Dashboard />
    </AppWrapper>
  )
  const container = document.getElementById('dashboard-frame')
  render(component, container)
}

export {actions, constants, reducer, saga}
