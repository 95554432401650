import {all, takeLatest, put, select} from 'redux-saga/effects'

import * as notifications from 'app/global/notifications'
import {handleSagaError} from 'app/utils/errors'
import * as entitiesActions from 'app/entities/entities-actions'

import {ASSIGNEE_TYPES} from 'app/reusable/BulkUserAssignment'

import * as api from './firm-settings-api'
import * as actions from './firm-settings-actions'
import {getSettingsAdmin} from './firm-settings-selectors'


function* handleInit(action) {
  yield put(entitiesActions.update({
    users: action.payload.users.reduce((result, item) => (result[item.id] = item, result), {})
  }))
}

function* handleFetchLanguages(action) {
  try {
    const response = yield api.fetchLanguages()
    yield put(actions.setLanguages(response.firmLanguages))
  } catch(error) {
    yield* handleSagaError(error)
  }
  yield put(actions.setIsLoading(false))
}

function* handleFetchLocations(action) {
  try {
    const response = yield api.fetchFirmLocations()
    yield put(actions.setLocations(response.firmLocations))
  } catch(error) {
    yield* handleSagaError(error)
  }
  yield put(actions.setIsLoading(false))
}

function* handleFetchDepartments(action) {
  try {
    const response = yield api.fetchDepartments()
    yield put(actions.setDepartments(response.departments))
  } catch(error) {
    yield* handleSagaError(error)
  }
  yield put(actions.setIsLoading(false))
}

function* handleFetchTeams(action) {
  try {
    const response = yield api.fetchTeams()
    yield put(actions.setTeams(response.teams))
  } catch(error) {
    yield* handleSagaError(error)
  }
  yield put(actions.setIsLoading(false))
}

function* handleAddLanguage(action) {
  try {
    const response = yield api.addLanguage(action.payload)
    yield put(actions.fetchLanguages())
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Language added successfully.',
      })
    )
  } catch(error) {
    yield* handleSagaError(error)
    yield put(actions.setIsLoading(false))
  }
}

function* handleSaveLocation(action) {
  // this handles both create and update (with id)
  try {
    if (action.payload.id) {
      const response = yield api.saveFirmLocation({...action.payload})
    } else {
      const response = yield api.createFirmLocation({...action.payload})
    }
    yield put(actions.hideEditLocationModal())
    yield put(actions.fetchLocations())
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Location saved successfully.',
      })
    )
  } catch(error) {
    yield* handleSagaError(error)
    yield put(actions.setIsLoading(false))
  }
}

function* handleSaveDepartment(action) {
  // this handles both create and update (with id)
  try {
    if (action.payload.id) {
      const response = yield api.saveDepartment({...action.payload})
    } else {
      const response = yield api.createDepartment({...action.payload})
    }
    yield put(actions.hideEditClassificationModal())
    yield put(actions.fetchDepartments())
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Department saved successfully.',
      })
    )
  } catch(error) {
    yield* handleSagaError(error)
    yield put(actions.setIsLoading(false))
  }
}

function* handleSaveTeam(action) {
  // this handles both create and update (with id)
  try {
    if (action.payload.id) {
      const response = yield api.saveTeam({...action.payload})
    } else {
      const response = yield api.createTeam({...action.payload})
    }
    yield put(actions.hideEditClassificationModal())
    yield put(actions.fetchTeams())
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Team saved successfully.',
      })
    )
  } catch(error) {
    yield* handleSagaError(error)
    yield put(actions.setIsLoading(false))
  }
}

function* handleSaveLocationAssignments(action) {
  const assignModalData = yield select(state => getSettingsAdmin(state).assignModalData)
  const userIds = assignModalData.assigneeIdsBySection[ASSIGNEE_TYPES.USER]
  const groupIdsForMembers = assignModalData.assigneeIdsBySection[ASSIGNEE_TYPES.GROUP_MEMBER]
  try {
    const response = yield api.saveLocationAssignments({
      id: assignModalData.id,
      userIds,
      groupIdsForMembers,
    })
    yield put(actions.resetAssignModalData())
    yield put(actions.fetchLocations())
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Assignments saved successfully.',
      })
    )
  } catch(error) {
    yield* handleSagaError(error)
    yield put(actions.setIsLoading(false))
  }
}

function* handleSaveDepartmentAssignments(action) {
  const assignModalData = yield select(state => getSettingsAdmin(state).assignModalData)
  const userIds = assignModalData.assigneeIdsBySection[ASSIGNEE_TYPES.USER]
  const groupIdsForMembers = assignModalData.assigneeIdsBySection[ASSIGNEE_TYPES.GROUP_MEMBER]
  try {
    const response = yield api.saveDepartmentAssignments({
      id: assignModalData.id,
      userIds,
      groupIdsForMembers,
    })
    yield put(actions.resetAssignModalData())
    yield put(actions.fetchDepartments())
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Assignments saved successfully.',
      })
    )
  } catch(error) {
    yield* handleSagaError(error)
    yield put(actions.setIsLoading(false))
  }
}

function* handleSaveTeamAssignments(action) {
  const assignModalData = yield select(state => getSettingsAdmin(state).assignModalData)
  const userIds = assignModalData.assigneeIdsBySection[ASSIGNEE_TYPES.USER]
  const groupIdsForMembers = assignModalData.assigneeIdsBySection[ASSIGNEE_TYPES.GROUP_MEMBER]
  try {
    const response = yield api.saveTeamAssignments({
      id: assignModalData.id,
      userIds,
      groupIdsForMembers,
    })
    yield put(actions.resetAssignModalData())
    yield put(actions.fetchTeams())
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Assignments saved successfully.',
      })
    )
  } catch(error) {
    yield* handleSagaError(error)
    yield put(actions.setIsLoading(false))
  }
}

function* handleRemoveLanguage(action) {
  try {
    const response = yield api.removeLanguage(action.payload)
    yield put(actions.fetchLanguages())
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Language removed successfully.',
      })
    )
  } catch(error) {
    yield* handleSagaError(error)
    yield put(actions.setIsLoading(false))
  }
}

function* handleDeleteLocations(action) {
  try {
    const response = yield api.deleteFirmLocations(action.payload)
    yield put(actions.hideDeleteLocationModal())
    yield put(actions.setSelectedLocationIds([]))
    yield put(actions.fetchLocations())
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Location(s) deleted successfully.',
      })
    )
  } catch(error) {
    yield* handleSagaError(error)
    yield put(actions.setIsLoading(false))
  }
}

function* handleDeleteDepartments(action) {
  try {
    const response = yield api.deleteDepartments(action.payload)
    yield put(actions.setDeleteClassificationIds([]))
    yield put(actions.setSelectedClassificationIds([]))
    yield put(actions.fetchDepartments())
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Department(s) deleted successfully.',
      })
    )
  } catch(error) {
    yield* handleSagaError(error)
    yield put(actions.setIsLoading(false))
  }
}

function* handleDeleteTeams(action) {
  try {
    const response = yield api.deleteTeams(action.payload)
    yield put(actions.setDeleteClassificationIds([]))
    yield put(actions.setSelectedClassificationIds([]))
    yield put(actions.fetchTeams())
    yield put(
      notifications.actions.showNotification({
        type: 'success',
        message: 'Team(s) deleted successfully.',
      })
    )
  } catch(error) {
    yield* handleSagaError(error)
    yield put(actions.setIsLoading(false))
  }
}

function* handleAddLocationLanguage(action) {
  const {locationId, languageId} = action.payload
  try {
    yield api.addFirmLocationLanguage(locationId, languageId)
    yield put(actions.fetchLocations())
  } catch(error) {
    yield* handleSagaError(error)
    yield put(actions.setIsLoading(false))
  }
}

function* handleRemoveLocationLanguage(action) {
  const {locationId, languageId} = action.payload
  try {
    yield api.removeFirmLocationLanguage(locationId, languageId)
    yield put(actions.fetchLocations())
  } catch(error) {
    yield* handleSagaError(error)
    yield put(actions.setIsLoading(false))
  }
}

export default function*() {
  yield all([
    takeLatest(actions.init, handleInit),
    takeLatest(actions.fetchLanguages, handleFetchLanguages),
    takeLatest(actions.addLanguage, handleAddLanguage),
    takeLatest(actions.removeLanguage, handleRemoveLanguage),

    takeLatest(actions.fetchLocations, handleFetchLocations),
    takeLatest(actions.deleteLocations, handleDeleteLocations),
    takeLatest(actions.saveLocation, handleSaveLocation),
    takeLatest(actions.saveLocationAssignments, handleSaveLocationAssignments),
    takeLatest(actions.addLocationLanguage, handleAddLocationLanguage),
    takeLatest(actions.removeLocationLanguage, handleRemoveLocationLanguage),

    takeLatest(actions.fetchDepartments, handleFetchDepartments),
    takeLatest(actions.deleteDepartments, handleDeleteDepartments),
    takeLatest(actions.saveDepartment, handleSaveDepartment),
    takeLatest(actions.saveDepartmentAssignments, handleSaveDepartmentAssignments),

    takeLatest(actions.fetchTeams, handleFetchTeams),
    takeLatest(actions.deleteTeams, handleDeleteTeams),
    takeLatest(actions.saveTeam, handleSaveTeam),
    takeLatest(actions.saveTeamAssignments, handleSaveTeamAssignments),
  ])
}
