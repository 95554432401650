import { handleActions } from 'redux-actions'
import * as actions from './usage-actions'
import { startOfDay, subWeeks, subDays, startOfToday } from 'date-fns'
import { PAGINATION_OPTIONS, INDIVIDUAL_STATISTICS_LIST } from './usage-constants'


const getInitialState = () => ({
  isLoading: false,
  isNewLoading: false,
  isEmailModalOpen: false,
  defaultEmailAddress: null,
  metrics: [],
  topFeeds: [],
  topArticles: [],
  topPublisherIds: [],
  recentSearchIds: [],
  recentRefreshedProfileUserIds: [],
  currentUserReceiveMonthlyUsageReport: null,
  usageSummary: {
    data: {},
    lastUpdatedDate: ''
  },
  usageSummaryFilters: {
    start: subDays(new Date(), 1),
    end: startOfToday(new Date()),
  },
  usageGrid: {
    data: [],
    totalCount: 0,
    start: '',
    end: ''
  },
  usageGridFilters: {
    sortField: '',
    sortDirection: '',
    firstDimension: '',
    distinctColumn: '',
    columnsToSelect: [],
    whereConditions: [],
    freeTextSearch: '',
    columnFilters: {
      articleIds: [],
      articleTypes: [],
      sourceIds: [],
      // savedSearchIds: [], //will use when search will be implemented in grid
      // savedSearchTypes: [],
      emailStartsWith: '',
      profileStartsWith: ''
    },
    start: subWeeks(new Date(), 1),
    end: startOfDay(new Date()),
    isAdmin: null,
    isGroup: null,
    currentPage: 1,
    pageSize: PAGINATION_OPTIONS[10]
    },
  filtersData: {
    articles: [],
    sources: [],
    articleTypes: [],
    // savedSearches: [],
    // savedSearchTypes: [],
  },
  reportType: 'email',
  currentUserReceiveMonthlyNewUsageReport: null,
  isIndividualStatisticsModalOpen: false,
  usersDropDownList: [],
  individualStatsData: INDIVIDUAL_STATISTICS_LIST,
  individualStatsUser: ''

})

export default handleActions(
  {
    [actions.init]: (state, action) => ({
      ...state,
      isLoading: false,
      defaultEmailAddress: action.payload.defaultEmailAddress,
    }),
    [actions.showLoader]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.hideLoader]: (state, action) => ({
      ...state,
      isLoading: false,
    }),
    [actions.showNewLoader]: (state, action) => ({
      ...state,
      isNewLoading: true,
    }),
    [actions.hideNewLoader]: (state, action) => ({
      ...state,
      isNewLoading: false,
    }),
    [actions.fetchUsageData]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.fetchUsageDataComplete]: (state, action) => ({
      ...state,
      isLoading: false,
      metrics: action.payload.metrics,
      topFeeds: action.payload.topFeeds,
      topArticles: action.payload.topArticles,
      topPublisherIds: action.payload.topPublisherIds,
      recentSearchIds: action.payload.recentSearchIds,
      recentRefreshedProfileUserIds: action.payload.recentRefreshedProfileUserIds,
    }),
    [actions.showEmailModal]: (state, action) => ({
      ...state,
      isEmailModalOpen: true,
    }),
    [actions.hideEmailModal]: (state, action) => ({
      ...state,
      isEmailModalOpen: false,
    }),
    [actions.signupForMonthlyReport]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.signupForMonthlyReportComplete]: (state, action) => ({
      ...state,
      currentUserReceiveMonthlyUsageReport: action.payload,
    }),
    [actions.sendEmail]: (state, action) => ({
      ...state,
      // isLoading: true,
    }),
    [actions.fetchUsageGridData]: (state, action) => ({
      ...state,
      isNewLoading: true,
    }),
    [actions.setUsageGridData]: (state, action) => ({
      ...state,
      // isNewLoading: false,
      usageGrid: { ...action.payload },
    }),
    [actions.setUsageGridFilters]: (state, action) => ({
      ...state,
      usageGridFilters: { ...state.usageGridFilters, ...action.payload },
    }),
    [actions.setUsageGridColumnFilters]: (state, action) => ({
      ...state,
      usageGridFilters: {
        ...state.usageGridFilters,
        columnFilters: { ...state.usageGridFilters.columnFilters, ...action.payload }
      },
    }),
    [actions.setFiltersData]: (state, action) => ({
      ...state,
      // isNewLoading: false,
      filtersData: { ...state.filtersData, ...action.payload },
    }),
    [actions.setSummaryData]: (state, action) => ({
      ...state,
      // isNewLoading: false,
      usageSummary: { ...action.payload },
    }),
    [actions.setSummaryDateOption]: (state, action) => ({
      ...state,
      usageSummaryFilters: {...action.payload}
    }),
    [actions.setReportType]: (state, action) => ({
      ...state,
      reportType: action.payload
    }),
    [actions.signupForNewMonthlyReport]: (state, action) => ({
      ...state,
      isNewLoading: true,
    }),
    [actions.signupForNewMonthlyReportComplete]: (state, action) => ({
      ...state,
      currentUserReceiveMonthlyNewUsageReport: action.payload,
    }),
    [actions.showIndividualStatisticsModal]: (state, action) => ({
      ...state,
      isIndividualStatisticsModalOpen: true,
    }),
    [actions.hideIndividualStatisticsModal]: (state, action) => ({
      ...state,
      isIndividualStatisticsModalOpen: false,
      individualStatsData: getInitialState().individualStatsData,
      usersDropDownList: [],
      individualStatsUser: ''
    }),
    [actions.fetchUsersDropDownList]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.setUsersDropDownList]: (state, action) => ({
      ...state,
      usersDropDownList: action.payload,
    }),
    [actions.getIndividualStatsData]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.setIndividualStatsData]: (state, action) => ({
      ...state,
      individualStatsData: action.payload,
    }),
    [actions.setIndividualStatsUser]: (state, action) => ({
      ...state,
      individualStatsUser: action.payload,
    }),
  },
  getInitialState(),
)
