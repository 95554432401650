import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {findDOMNode} from 'react-dom'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import Message from 'app/common/Message'
import Button from 'app/common/Button'

import {getSourcesAdmin} from '../sources-admin-selectors'
import {
  validateFeed,
  setShouldShowSelfAddModal,
  updateCreateProprietaryFeedData,
} from '../sources-admin-actions'


@connect(
  createSelector(
    [getSourcesAdmin],
    (sourcesAdmin) => {
      return {
        createProprietaryFeedData: sourcesAdmin.createProprietaryFeedData,
      }
    }
  ),
  {
    validateFeed,
    setShouldShowSelfAddModal,
    updateCreateProprietaryFeedData,
  },
)
export default class SourceURLForm extends Component {
  state = {
    url: '',
  }

  componentDidMount() {
    // Immediately focus the URL field
    findDOMNode(this).getElementsByTagName('input')[0].focus()
  }

  render() {
    const {isValidating, errorMessage} = this.props.createProprietaryFeedData
    const {url} = this.state
    const allowSubmitForm = url && !isValidating

    const error = errorMessage && <div className="message error">{errorMessage}</div>

    const loadingMessage = isValidating &&
      <Message
        type={Message.TYPES.LOADING}
        text="We are checking the URL you submitted for content. This might take a few seconds..."
      />

    return (
      <div className="source-url-form">
        {error}

        <div className="no-spacing">
          <p>Add any RSS feed URL, including that of a subscription source. Some examples of RSS feeds are:</p>
          <p>rss.nytimes.com/services/xml/rss/nyt/HomePage.xml</p>
          <p>feeds.feedburner.com/WorldOffshoreBanksNews</p>
        </div>

        <label className="inline margin-r-l">
          <span className="label">RSS URL</span>
          <input
            type="text"
            value={url}
            onChange={(event) => this.handleUrlChange(event.target.value)}
          />
        </label>

        {loadingMessage}

        <div className="buttons">
          <Button
            label="Check Source for Content"
            className="submit"
            disabled={!allowSubmitForm}
            onClick={() => this.validateUrl()}
          />

          <a className="cancel" onClick={() => this.props.setShouldShowSelfAddModal(false)}>
            Cancel
          </a>
        </div>
      </div>
    )
  }

  handleUrlChange(url) {
    this.setState({url})
    this.props.updateCreateProprietaryFeedData({errorMessage: null})
  }

  validateUrl() {
    const {url} = this.state
    this.props.validateFeed(url)
  }
}
