import { graphqlQuery } from 'app/api'
import { args, gql } from 'app/graphql'
import { apiCall } from 'app/api'
import { getCleanObject } from './utils'

const apiUrl = path => `/firmstats${path}?format=json`

// API Calls
export const api = {
  usageGridData: () => apiUrl('/usage_grid_data/'),
  usageGridFiltersData: () => apiUrl('/usage_grid_filter_data/'),
  usageGridSummaryData: () => apiUrl('/email_data/'),
  usageIndividualUserOrGroupStatsData: () => apiUrl('/individual_user_or_group_stats/'),
}

export function fetchUsageData({ start, end }) {
  return graphqlQuery(
    gql`
      query {
        metrics: usageMetrics(${args({ start, end })}) {
          type
          count
        }
        topFeeds: usageTopFeeds(${args({ start, end })}) {
          id
          label
          count
        }
        topArticles: usageTopArticles(${args({ start, end })}) {
          id
          label
          count
        }
        topPublishers: usageTopPublishers(${args({ start, end })}) {
          user {
            id
            firstName
            lastName
          }
          count
        }
        recentSearches: usageRecentSearches(${args({ start, end })}) {
          id
          name
          owner {
            id
            firstName
            lastName
          }
        }
        recentRefreshedProfileUsers: usageRecentRefreshedProfileUsers(${args({ start, end })}) {
          id
          firstName
          lastName
          lastRefreshAt
        }
      }
    `,
  ).then(({ body: { data } }) => data)
}

export function fetchUsageGridData(params, callback) {
  const url = api.usageGridData()
  const paramsJson = JSON.stringify(getCleanObject(params))
  return apiCall({
    url,
    method: 'GET',
    data: { params: paramsJson },
    callback
  })
}

export function fetchUsageGridFiltersData(params, callback) {
  const url = api.usageGridFiltersData()
  return apiCall({
    url,
    method: 'GET',
    data: {...params},
    callback
  })
}


export function usageReportSignup(isSignup) {
  return graphqlQuery(
    gql`
      mutation {
        user: usageReportSignup(signup:${isSignup}) {
          id
          receiveMonthlyUsageReport
        }
      }
    `,
  ).then(({ body: { data } }) => data)
}

export function newUsageReportSignup(isSignup) {
  return graphqlQuery(
    gql`
      mutation {
        user: newUsageReportSignup(signup:${isSignup}) {
          id
          receiveMonthlyNewUsageReport
        }
      }
    `,
  ).then(({ body: { data } }) => data)
}

export function sendEmail({ start, end, emailAddresses,reportType, newReport = false }) {
  return graphqlQuery(
    gql`
      mutation {
        sendUsageEmail(${args({emailAddresses, start, end, newReport, reportType })}) {
          success
        }
      }
    `,
  ).then(({ body: { data } }) => data)
}

export function fetchUsageGridSummaryData(params) {
  const url = api.usageGridSummaryData()
  return apiCall({
    url,
    method: 'GET',
    data: { ...params }
  })
}

export function fetchUsers({is_paged=false, name} = {}) {
  return graphqlQuery(
    gql`
      query {
        users: users(${args({is_paged, name})}) {
          items{
            id
            firmId
            username
            email
            firstName
            lastName
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchGroups({is_paged=false, name} = {}) {
  return graphqlQuery(
    gql`
      query {
        groups: groups(${args({is_paged, name})}) {
          items{
            id
            firmId
            username
            email
            firstName
            lastName
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}


export function fetchIndividualStatsData(params) {
  const url = api.usageIndividualUserOrGroupStatsData()
  return apiCall({
    url,
    method: 'GET',
    data: { ...params }
  })
}