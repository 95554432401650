import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './styles.less'
import { STATS } from '../usage-constants'

const ReportStats = () => {
    const [isVisible, setIsVisible] = useState(true)
    const handleOnClick = () => {
        setIsVisible(!isVisible)
    }
    return (
        <div className={'report-stats-container'}>
            <div className='stats-accordion' onClick={handleOnClick}>
                <div className={isVisible ? 'arrow-down' : 'arrow-up'} />
                {STATS}
            </div>
            {isVisible ? <div className='dummy-svg' /> : <div className='collapsed-state'/>}
        </div >
    )
}

ReportStats.propTypes = {}

export default ReportStats
