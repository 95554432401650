import { graphqlQuery } from 'app/api'
import { args, gql } from 'app/graphql'


export function fetchSearches({
  authors,
  categories,
}) {
  return graphqlQuery(
    gql`
      query {
        data: savedSearchesLookup(${args({
          itemsPerPage: 0,
          authors,
          categories,
        })}) {
          items {
            search {
              id
              name
              isFirmLibrary
            }
          }
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function saveUser(data) {
  return graphqlQuery(
    gql`
      mutation {
        user: saveUser(${args(data)}) {id}
      }
    `
  ).then(({body}) => body.data)
}
