import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import {titleCase} from 'title-case'

import Orm from 'app/framework/Orm'
import { User } from 'app/models'

import { getProfileBuilder } from './profile-builder-selectors'
import * as profileBuilderActions from './profile-builder-actions'

import Modal from 'app/common/Modal'
import LoadingOverlay from 'app/common/LoadingOverlay'
import Button from 'app/common/Button'
import Message from 'app/common/Message'
import InlineSvg from 'app/common/InlineSvg'

import Searches from './Searches'
import ContentPreferences from './ContentPreferences'
import AccountSettings from './AccountSettings'
import Tour from './Tour'
import { getAppLogo } from 'app/constants'


@connect(
  // Map state to props
  state => {
    const orm = Orm.withEntities(state.entities)
    const profileBuilder = getProfileBuilder(state)
    return {
      currentUserIsAdmin: state.currentUserIsStaff || state.currentUserIsClientAdmin,
      currentFirmName: state.currentFirmName,
      currentFirmLibraryName: state.currentFirmLibraryName,
      currentFirmLocationId: state.currentFirmLocationId,
      currentUser: orm.getById(User, state.currentUser),
      logoWhiteUrl: state.logoWhiteUrl,
      supportEmail: state.supportEmail,
      currentFirmRelevancySetting: state.currentFirmRelevancySetting,
      currentUserRole: state.currentUserRole,
      currentUserTimezone: state.currentUserTimezone,
      currentUserFirmLocationId: state.currentUserFirmLocationId,
      currentUserLocationId: state.currentUserLocationId,
      currentUserRelevancySetting: state.currentUserRelevancySetting,
      siteUrl: state.siteUrl,
      isDiligent: state.isDiligent,
      appNameFull: state.appNameFull,

      ...profileBuilder,
    }
  },

  // Map dispatch to props
  {
    ...profileBuilderActions,
  },
)
export default class ProfileBuilder extends Component {
  state = {
    categories: {
      client: {
        label: 'company',
        plural: 'companies',
        searches: [{}, {}, {}],
        authors: ['firm-library'],
      },
      firm: {
        label: 'competitor',
        plural: 'competitors',
        searches: [{}, {}],
        authors: ['firm-library'],
      },
      practice: {
        label: 'practice',
        plural: 'practices',
        searches: [{}, {}],
        authors: ['firm-library', 'manzama'],
      },
      industry: {
        label: 'industry',
        plural: 'industries',
        searches: [{}, {}],
        authors: ['firm-library', 'manzama'],
      },
      tracker: {
        label: 'topic',
        plural: 'topics',
        searches: [{}, {}],
        authors: ['firm-library'],
        categories: ['tracker', 'agency', 'practice'],
      },
    },
    selectedBoosterIds: this.props.selectedBoosterIds,
    relevancySetting: this.props.currentUserRelevancySetting || this.props.currentFirmRelevancySetting,
    password: '',
    confirmPassword: '',
    currentPassword: '',
    firmLocationId: this.props.currentUserFirmLocationId || 0,
    timezone: this.props.currentUserTimezone || this.props.defaultTimezone,
    role: this.props.currentUserRole || '',
    locationId: this.props.currentUserLocationId || this.props.currentFirmLocationId || 0,
  }

  render() {
    const { currentFirmName, currentFirmLibraryName, currentUser, currentStep, isDiligent, supportEmail } = this.props

    const stepPractices = this.props.showPracticeAreas
      ? 'Practices, Industries, Topics'
      : 'Industries and Topics'

    const steps = [
      'Companies and Competitors',
      stepPractices,
      'Content Preferences',
      'Account Settings',
      'Quick Tour',
    ]

    const stepsContent =
      steps.map((step, idx) => {
        const stepNumber = idx + 1
        return (
          <li
            key={idx}
            className={classNames({active: stepNumber === currentStep, accessible: stepNumber < currentStep && currentStep < 5})}
            onClick={() => this.handleStepClick(stepNumber)}
          >
            <div className="step">
              <span className="stepnum">{stepNumber < currentStep ? <i className="fa fa-check"/> : stepNumber}</span>
              <span>{step}</span>
            </div>
          </li>
        )
      })

    const builderContent = this.getContent()

    const currentStepName = steps[currentStep - 1]

    const companyName = isDiligent ? 'Diligent Corporation' : 'Manzama Inc.'

    const currentYear = new Date().getFullYear()

    const haveFirmLibrarySearches = this.props.searches.some(s => s.isFirmLibrary)

    const searchesToList = haveFirmLibrarySearches ?
      this.props.searches.filter(s => s.isFirmLibrary)
      : this.props.searches

    const browseModalMessage =
      haveFirmLibrarySearches ?
        null
        : this.props.searches.length === 0 ?
            <p>No {currentFirmLibraryName} Searches exist. Please use the Input Fields to select a search.</p>
            : <p>No {currentFirmLibraryName} searches exist, but please choose from existing Global Searches.</p>

    const browseModal =
      this.props.browseModalCategory &&
        <Modal
          isOpen={true}
          onClose={() => this.props.hideBrowseModal()}
        >
          <h1>{titleCase(this.state.categories[this.props.browseModalCategory].plural)}</h1>

          {browseModalMessage}

          <div className="search-list">
            {
              searchesToList.map(search => {
                return (
                  <div
                    key={search.id}
                  >
                    <input
                      type="checkbox"
                      checked={this.searchIsSelected(search.id)}
                      onChange={evt => this.handleSearchCheckbox(evt, search.id, search.name)}
                    />
                    <span>{search.name}</span>
                  </div>
                )
              })
            }
          </div>
          <div className="modal-footer">
            <Button
              label="Done"
              onClick={() => this.props.hideBrowseModal()}
            />
          </div>
        </Modal>

    const loader = this.props.isLoading  && <LoadingOverlay/>

    const message =
      this.props.messageData.type &&
        <Message
          type={this.props.messageData.type}
          text={this.props.messageData.text}
        />

    const appLogo = getAppLogo(true)    

    return (
      <div>
        {loader}

        {message}

        {browseModal}

        <div className="header">
          <InlineSvg
            src={appLogo}
            className="logo"
          />
          <a onClick={() => this.handleSkipClick()}>Skip Profile Builder</a>
        </div>

        <div className="wrapper">
          <div className="builder-menu">
            <div className="menu-header">
              <p className="group-name">{currentFirmName}</p>
              <p className="member-name">{currentUser.displayName}</p>
            </div>

            <ul className="menu-content">
              {stepsContent}
            </ul>
          </div>

          <div className="builder-content">
            <div className="builder-content-header">
              <h4>Step {currentStep}</h4> <h5>{currentStepName}</h5>
            </div>

            {builderContent}

            <div className="builder-content-footer">
              <div>&copy; {currentYear} {companyName} | <a href={`mailto:${supportEmail}`} target="_blank">Help</a></div>
              <div
                className="next-button"
                onClick={() => this.handleNextClick()}
              >
                <div>
                  <span>{currentStep === 5 ? `START USING ${this.props.appNameFull.toUpperCase().split('DILIGENT').pop()}` : 'NEXT'}</span>
                </div>

                <div className="next-button-button">
                  <div>
                    <i className="fa fa-angle-right"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getContent() {
    const { currentStep, currentFirmLibraryName } = this.props
    let searchSections = []
    switch (currentStep) {
      case 1:
        searchSections = [
          {category: 'client', ...this.state.categories.client},
          {category: 'firm', ...this.state.categories.firm},
        ]
        return (
          <Searches
            sections={searchSections}
            currentFirmLibraryName={currentFirmLibraryName}
            fetchSearches={category => this.fetchSearches(category)}
            onSelectionChange={(idx, category, values) => this.handleSearchInputChange(idx, category, values)}
          />
        )
      case 2:
        searchSections = this.props.showPracticeAreas
          ? [
              {category: 'practice', ...this.state.categories.practice},
              {category: 'industry', ...this.state.categories.industry},
              {category: 'tracker', ...this.state.categories.tracker},
            ]
          : [
              {category: 'industry', ...this.state.categories.industry},
              {category: 'tracker', ...this.state.categories.tracker},
            ]

        return (
          <Searches
            sections={searchSections}
            currentFirmLibraryName={currentFirmLibraryName}
            fetchSearches={category => this.fetchSearches(category)}
            onSelectionChange={(idx, category, values) => this.handleSearchInputChange(idx, category, values)}
          />
        )
      case 3:
        return (
          <ContentPreferences
            boosters={this.props.boosters}
            selectedBoosterIds={this.state.selectedBoosterIds}
            onBoosterSelectionChange={(id, checked) => this.handleBoosterSelectionChange(id, checked)}
            relevancySetting={this.state.relevancySetting}
            onRelevancyChange={(setting) => this.handleRelevancyChange(setting)}
          />
        )
      case 4:
        return (
          <AccountSettings
            user={this.props.currentUser}
            firmLocations={this.props.firmLocations}
            timezones={this.props.timezones}
            roles={this.props.roles}
            locations={this.props.locations}
            onPasswordChange={value => this.handlePasswordChange(value)}
            onConfirmPasswordChange={value => this.handleConfirmPasswordChange(value)}
            onCurrentPasswordChange={value => this.handleCurrentPasswordChange(value)}
            onFirmLocationChange={value => this.handleFirmLocationChange(value)}
            onTimezoneChange={value => this.handleTimezoneChange(value)}
            onRoleChange={value => this.handleRoleChange(value)}
            onLocationChange={value => this.handleLocationChange(value)}
            password={this.state.password}
            confirmPassword={this.state.confirmPassword}
            currentPassword={this.state.currentPassword}
            firmLocationId={this.state.firmLocationId}
            timezone={this.state.timezone}
            role={this.state.role}
            locationId={this.state.locationId}
          />
        )
      case 5:
        return (
          <Tour
            isDiligent={this.props.isDiligent}
            appNameFull={this.props.appNameFull}
            siteUrl={this.props.siteUrl}
          />
        )
    }
  }

  handlePasswordChange(password) {
    this.setState({password})
  }

  handleConfirmPasswordChange(confirmPassword) {
    this.setState({confirmPassword})
  }

  handleCurrentPasswordChange(currentPassword) {
    this.setState({currentPassword})
  }

  handleFirmLocationChange(firmLocationId) {
    this.setState({firmLocationId})
  }

  handleTimezoneChange(timezone) {
    this.setState({timezone})
  }

  handleRoleChange(role) {
    this.setState({role})
  }

  handleLocationChange(locationId) {
    this.setState({locationId})
  }

  handleBoosterSelectionChange(id, checked) {
    let { selectedBoosterIds } = this.state
    if (checked) {
      selectedBoosterIds.push(id)
    } else {
      selectedBoosterIds = selectedBoosterIds.filter(x => x !== id)
    }
    this.setState({selectedBoosterIds})
  }

  handleRelevancyChange(setting) {
    this.setState({relevancySetting: setting})
  }

  handleSearchCheckbox(evt, id, name) {
    const { categories } = {...this.state}
    const search = {
      value: id,
      name,
    }
    if (evt.target.checked) {
      let done = false
      categories[this.props.browseModalCategory].searches.forEach((s, idx) => {
        if (!done && Object.keys(s).length === 0) {
          categories[this.props.browseModalCategory].searches[idx] = search
          done = true
        }
      })
      if (!done) {
        categories[this.props.browseModalCategory].searches.push(search)
      }
    } else {
      categories.searches = categories[this.props.browseModalCategory].searches.filter(s => s.value !== id)
    }
    this.setState({categories})
  }

  searchIsSelected(id) {
    return this.state.categories[this.props.browseModalCategory].searches.some(s => s.value === id)
  }

  handleSkipClick() {
    this.props.skip(this.props.currentUser.id)
  }

  handleNextClick() {
    const { currentStep } = this.props
    if (currentStep === 4) {
      /**
       * timezone and firm location force a selection so don't need validation.
       */
      if (!this.state.password || !this.state.confirmPassword) {
        this.props.showMessage({
          type: 'error',
          text: 'Passwords are required.'
        })
        return
      }
      if (this.state.password !== this.state.confirmPassword) {
        this.props.showMessage({
          type: 'error',
          text: 'Passwords must match.'
        })
        return
      }
      if (this.state.password.length < 5 ||  this.state.confirmPassword.length < 5) {
        this.props.showMessage({
          type: 'error',
          text: 'Passwords must be at least 5 characters long.'
        })
        return
      }
      if (!this.state.role) {
        this.props.showMessage({
          type: 'error',
          text: 'Role is required.'
        })
        return
      }
      if (!this.state.locationId) {
        this.props.showMessage({
          type: 'error',
          text: 'Country is required.'
        })
        return
      }
      let searches = []
      Object.keys(this.state.categories).forEach(key => {
        const category = this.state.categories[key]
        category.searches.forEach(s => {
          if (Object.keys(s).length) {
            searches.push({
              value: s.value,
              name: s.name,
              category: key,
              isFreeText: s.isFreeText || false,
            })
          }
        })
      })
      this.props.saveUser({
        isProfileBuilder: true,
        id: this.props.currentUser.id,
        searches,
        boosterIds: this.state.selectedBoosterIds,
        relevancySetting: this.state.relevancySetting,
        password: this.state.password,
        currentPassword: this.state.currentPassword,
        role: this.state.role,
        timezone: this.state.timezone,
        firmLocationId: this.state.firmLocationId,
        locationId: this.state.locationId,
      })
      return
    } else if (currentStep === 5) {
      window.location.href = '/'
      return
    }
    this.props.setCurrentStep(currentStep + 1)
  }

  handleStepClick(step) {
    if (step < this.props.currentStep || this.props.currentStep === 5) {
      this.props.setCurrentStep(step)
    }
  }

  handleSearchInputChange(idx, category, values) {
    // we only allow one value per input
    const search = values.length > 0 ? {
      value: values[0].value,
      name: values[0].label,
      isFreeText: values[0].isFreeText,
    } : {}
    const { categories } = {...this.state}
    categories[category]['searches'][idx] = search
    this.setState({categories})
  }

  fetchSearches(category) {
    const authors = this.state.categories[category].authors
    this.props.fetchSearches({category, authors})
  }
}
