import {graphqlQuery} from 'app/api'
import {args, gql} from 'app/graphql'


export function saveChart({
  chartFile,
  chartName,
}) {
  return graphqlQuery(
    gql`
      mutation {
        saveChart(${args({chartFile, chartName})}) 
        {
          id
        }
      }
    `
  ).then(({body: {data}}) => data)
}
