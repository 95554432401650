import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Dropdown, { Option } from 'app/common/Dropdown'
import InputBlock from 'app/common/InputBlock'
import AutocompleteInput from 'app/common/AutocompleteInput'
import CategoryDropdown from 'app/common/CategoryDropdown'
import TeamDropdown from 'app/common/TeamDropdown'
import DepartmentDropdown from 'app/common/DepartmentsDropdown'
import LocationDropdown from 'app/common/LocationDropdown'
import TextBox from 'app/common/TextBox'
import { SavedSearch } from 'app/models'
import Tooltip from 'app/common/Tooltip'
import {getSearchesAdmin} from './searches-admin-selectors'
import {connect} from "react-redux"
import {createSelector} from "reselect"
import {getEntities} from "../../entities/entities-selectors"
import Orm from "../../framework/Orm"

@connect(
  createSelector(
    [getEntities, getSearchesAdmin],
    (entities, searchesAdmin) => {
      const orm = Orm.withEntities(entities)
      return {
        teams: searchesAdmin.teams,
        departments: searchesAdmin.departments,
        firmLocations: searchesAdmin.firmLocations,
      }
    }
  ),
)


export default class SearchesAdminHeader extends Component {
  static propTypes = {
    filterState: PropTypes.object.isRequired,
    onFilterStateChange: PropTypes.func,
  }

  static defaultProps = {
    onFilterStateChange() {},
  }

  render() {
    return (
      <div className="header">
        <div className="filters">
          <InputBlock label="Filter by Search Name">
            <TextBox
              onChange={this.createFilterChangeHandler('name', 'value')}
            />
          </InputBlock>

          <InputBlock label="Filter by User or Group" className="user-autocomplete-input-block">
            <AutocompleteInput
              url='/api/firm-user-autocomplete/'
              maxValues={1}
              value=''
              allowFreeText={false}
              onChange={() => this.userAutocompleteSelectionHandler()}
              ref={input => this.userAutocompleteInput = input}
            />
          </InputBlock>
          <InputBlock label="Filter by Label">
            <TextBox
              onChange={this.createFilterChangeHandler('label', 'value')}
            />
          </InputBlock>
          <InputBlock label="Filter by Privacy Setting">
            <Dropdown onChange={this.changeFilter.bind(this, 'scope')}>
              <Option label="Shared & Private" value="" />
              <Option label="Shared" value={SavedSearch.SCOPES.SHARED} />
              <Option label="Private" value={SavedSearch.SCOPES.PERSONAL} />
            </Dropdown>
          </InputBlock>

          <InputBlock label="Filter by Category">
            <CategoryDropdown
              showAllCategories
              onChange={this.changeFilter.bind(this, 'category')}
            />
          </InputBlock>

          <InputBlock
            label={
              <React.Fragment>
                <span>Include Trusted Sources</span>
                <Tooltip
                  label="Include trusted sources and folders of trusted sources to more easily audit what is in your user or group profiles."
                >
                  <span className="tooltip help"/>
                </Tooltip>
              </React.Fragment>
            }
            isInline
          >
            <input
              type="checkbox"
              checked={this.props.filterState.includeTrustedSources}
              onChange={this.createFilterChangeHandler('includeTrustedSources', 'checked')}
            />
          </InputBlock>
        </div>
        <br />
        <div>
          <InputBlock label="Filter by Department">
            <DepartmentDropdown
              showAllDepartments
              departments={this.props.departments}
              onChange={this.changeFilter.bind(this, 'department')}
            />
          </InputBlock>

          <InputBlock label="Filter by Team">
            <TeamDropdown
              showAllTeams
              teams={this.props.teams}
              onChange={this.changeFilter.bind(this, 'team')}
            />
          </InputBlock>

          {this.props.firmLocations && this.props.firmLocations.length > 0 ?
            <InputBlock label="Filter by Office Location">
              <LocationDropdown
                locations={this.props.firmLocations}
                onChange={this.changeFilter.bind(this, 'firmLocations')}
              />
            </InputBlock>
            : null}
        </div>
      </div>
    )
  }

  // Event handlers

  /*
   * Creates a function that sets the filter property at `filterName` to
   * the value on the `event.target` attribute at `eventAttrName`.
   *
   * For example:
   *
   *   <input onChange={this.createFilterChangeHandler('name', 'value')} />
   *
   * gives the 'name' filter the value `event.target.value`.
   */
  createFilterChangeHandler(filterName, eventAttrName) {
    return event => this.changeFilter(filterName, event.target[eventAttrName])
  }

  userAutocompleteSelectionHandler() {
    let ownerId = ''

    if (this.userAutocompleteInput.values.length) {
      const selected = this.userAutocompleteInput.values[0];
      ownerId = selected.value
    }

    this.props.onFilterStateChange({
      ...this.props.filterState,
      ownerId,
    })
  }


  changeFilter(filterName, value) {
    this.props.onFilterStateChange({
      ...this.props.filterState,
      [filterName]: value,
    })
  }
}
