import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'

import { createDnbAction } from 'app/actions'
import store from 'app/store'

import DandBSearch from "./DandBSearch";
import * as constants from './dandb-search-constants'
import saga from './dandb-search-saga'
import * as actions from './dandb-search-actions'
import reducer from './dandb-search-reducer'
import './style.less'
//import {addInitialData} from './dandb-search-actions'
import {changeCaseObject} from 'app/utils'


export function init(data) {
  console.log(data)
  store.dispatch(actions.addInitialData(data))
  const component = (
    <Provider store={store}>
      <DandBSearch />
    </Provider>
  )
  const container = document.getElementById('dnb-container')
  render(component, container)
}

export {constants, reducer, saga, actions}
