import classNames from 'classnames'
import {range} from 'ramda'
import React from 'react'

import * as documentStyles from 'app/reusable/ResultDocument/style.less'
import * as styles from './DocumentListLoading.less'


function Document() {
  return (
    <div>
      <div className={documentStyles.documentContainer}>
        <div className={documentStyles.document}>
          <div className={documentStyles.checkboxContainer}>
            <div className={styles.checkbox} />
          </div>

          <div className={documentStyles.mainContainer}>
            <div className={classNames(documentStyles.headline, styles.headline)} />
            <div className={classNames(documentStyles.publishedMeta, styles.publishedMeta)} />
            <div className={styles.snippet}>
              <div className={styles.line} />
              <div className={styles.line} />
              <div className={styles.line} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default function DocumentListLoading() {
  return range(0, 5).map(index => <Document key={index} />)
}
