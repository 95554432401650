import {delay, put, takeEvery} from 'redux-saga/effects'

import * as actions from './notifications-actions'

function* handleShowNotification(action) {
  if (action.payload.duration) {
    yield delay(action.payload.duration * 1000)
    yield put(actions.hideNotification(action.payload.id))
  }
}

export default function* notificationsSaga() {
  yield takeEvery(actions.showNotification, handleShowNotification)
}
