import PropTypes from 'prop-types'
import React, { Component } from 'react'
import InputBlock from 'app/common/InputBlock'
import './style.less'
import classNames from 'classnames'


export default class TokenInput extends Component {
  static propTypes = {
    updateTokensFunc: PropTypes.func.isRequired,
    tokenItems: PropTypes.array,
    delimiters: PropTypes.array,
    addTokenParams: PropTypes.object,
    inputLabel: PropTypes.string,
    inputWidth: PropTypes.string,
    disableInput: PropTypes.bool,
    tokenInputStyle: PropTypes.string,
    ignoreSplit: PropTypes.bool
  }

  static defaultProps = {
    tokenItems: [],
    delimiters: [','],
    addTokenParams: {},
    inputLabel: '',
    inputWidth: '500px',
    disableInput: false,
    ignoreSplit:false
  }

  state = {inputValue: ''}

  render() {

    const {tokenItems, inputLabel, disableInput} = this.props

    const tokens = tokenItems.map((token, index) => {
      return(
        <li className={'token inline-flex'} key={index}>
          <span className={'token-label'}>
            {token.label}
          </span>
          <span
            role={'button'}
            className={'token-delete-button'}
            onClick={() => this.onClickRemoveToken(token)}
          >
            x
          </span>
        </li>
      )
    })

    const inputWidth = Math.max(5, this.state.inputValue.length + 1) + 'ex'

    const input = !disableInput
      ? <input
          className={classNames(
            'token-input',
            this.props.tokenInputStyle,
          )}
          role={'combobox'}
          style={{width: inputWidth}}
          onChange={this.onChangeTokenInput.bind(this)}
        />
      : null

    return (
      <InputBlock
        className={'tokenize-input-container'}
        label={inputLabel}
        style={{minWidth: this.props.inputWidth}}
        onKeyDown={this.onKeyDownTokenInput}
        onBlur={(e) => this.onBlurTokenInput(e)}
      >
        <ul className={'tokens-container flex'}>
          {tokens}
          <li className={'inline-flex'}>
            <div className={'token-input-container'}>
              {input}
            </div>
          </li>
        </ul>
      </InputBlock>
    )
  }

  get hasUntokenizedText() {
    return !!this.state.inputValue
  }
  tokenizeFreeText() {
    if (this.hasUntokenizedText) {
      if (this.props.ignoreSplit) {
        this.props.updateTokensFunc({
          ...this.props.addTokenParams,
          label: this.state.inputValue
        })
      } else {
        this.state.inputValue.split(',').forEach((value) => {
          this.props.updateTokensFunc({
            ...this.props.addTokenParams,
            label: value,
          })
        })
      }
      this.setState({inputValue: ''})
    }
  }
  onClickRemoveToken(token) {
    this.props.updateTokensFunc({
      ...token,
      action: 'delete',
    })
  }
  onChangeTokenInput(event) {
    this.setState({inputValue: event.target.value.trim()})
  }
  onKeyDownTokenInput = (event) => {
    const {key} = event
    if (this.props.delimiters.includes(key)) {
        event.preventDefault()
        this.tokenizeFreeText()
        event.target.value = ''
    }
  }
  onBlurTokenInput(e) {
    this.tokenizeFreeText()
    e.target.value = ''
  }
}
