import classNames from 'classnames'
import is from 'is'
import PropTypes from 'prop-types'
import React from 'react'

import FactorIcon from 'app/common/FactorIcon'
import HealthScore from 'app/common/HealthScore'
import Link from 'app/common/NavigationLink'
import FilterIcon from 'app/common/icons/FilterIcon/FilterIcon'
import * as strings from 'app/strings'

import * as styles from './FactorHealthCard.less'
import {ORDERED_FACTOR_DEPENDENCY_TREE} from 'app/constants'

const SubfactorRow = ({healthData, subfactor, level}) => {
  const subfactorHealth = (healthData && healthData.subfactors[subfactor]) || {}
  const score = is.defined(subfactorHealth.healthScore) ? (
    <HealthScore
      score={subfactorHealth.healthScore}
      className={styles.number}
    />
  ) : (
    <span className={classNames(styles.number, styles.na)}>--</span>
  )
  return (
    <div className={styles.row}>
      {score}
      <span className={styles.name} style={{paddingLeft: `${(level - 1) * 10}px`}}>
        {strings.subfactorDisplayName(subfactor)}
      </span>
    </div>
  )
}
SubfactorRow.propTypes = {
  healthData: PropTypes.object.isRequired,
  subfactor: PropTypes.string.isRequired,
}

/*
  Pre-order tree traversal of the factors.
  The ESG pillar is being left out (root node).
 */
const traverseFactorTree = (healthData, factor, level = 0) => {
  let rows = [];

  if (level > 0) {
    rows.push(
      <SubfactorRow
        healthData={healthData}
        subfactor={factor}
        key={factor}
        level={level}
      />
    )
  }

  if (ORDERED_FACTOR_DEPENDENCY_TREE.hasOwnProperty(factor)) {
    let nextLevel = level + 1;
    ORDERED_FACTOR_DEPENDENCY_TREE[factor].forEach(currentFactor => {
      rows = rows.concat(traverseFactorTree(healthData, currentFactor, nextLevel));
    });
  }

  return rows;
}

const BreakdownTable = ({healthData, factor}) => {
  return traverseFactorTree(healthData, factor);
}

BreakdownTable.propTypes = {
  healthData: PropTypes.object.isRequired,
  factor: PropTypes.string.isRequired,
}

const FactorHealthCard = ({
  healthData,
  factor,
  shouldShowFilterIcon,
  filterUrl,
  className,
}) => {
  const factorHealth = healthData.factors[factor] || {}
  const healthScore = factorHealth.healthScore
  const score = is.defined(healthScore) ? (
    <HealthScore score={healthScore} className={styles.healthScore} />
  ) : (
    <span className={classNames(styles.healthScore, styles.na)}>--</span>
  )

  return (
    <div className={classNames(styles.subfactorHealthCard, className)}>
      <div
        className={classNames(styles.header, {
          [styles.withFilter]: shouldShowFilterIcon,
        })}
      >
        <div className={styles.factor}>
          <FactorIcon factor={factor} className={classNames(styles.icon)} />
          {strings.factorDisplayName(factor)}
          {score}
        </div>

        {shouldShowFilterIcon && (
          <Link href={filterUrl} className={styles.filter}>
            <FilterIcon className={styles.icon} />
          </Link>
        )}
      </div>

      <div className={styles.label}>Subfactor Health Scores</div>

      <div className={styles.content}>
        <BreakdownTable healthData={healthData} factor={factor} />
      </div>
    </div>
  )
}
FactorHealthCard.propTypes = {
  healthData: PropTypes.object.isRequired,
  factor: PropTypes.string.isRequired,
  shouldShowFilterIcon: PropTypes.bool,
  filterUrl: PropTypes.string,
  className: PropTypes.string,
}
export default FactorHealthCard
