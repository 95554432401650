import { createAction } from 'redux-actions'

const createProfileRefreshAction = name =>
  createAction(`PROFILE_REFRESH_${name}`)

export const init = createProfileRefreshAction('INIT')
export const setCurrentStep = createProfileRefreshAction('SET_CURRENT_STEP')
export const fetchSearches = createProfileRefreshAction('FETCH_SEARCHES')
export const showBrowseModal = createProfileRefreshAction('SHOW_BROWSE_MODAL')
export const hideBrowseModal = createProfileRefreshAction('HIDE_BROWSE_MODAL')
export const saveUserSearches = createProfileRefreshAction('SAVE_USER_SEARCHES')
export const skip = createProfileRefreshAction('SKIP')
export const saveUserSearchesSuccess = createProfileRefreshAction('SAVE_USER_SEARCHES_SUCCESS')
export const setExistingUserSearches =
  createProfileRefreshAction('SET_EXISTING_USER_SEARCHES')
export const setUserSearchesToRemove =
  createProfileRefreshAction('SET_USER_SEARCHES_TO_REMOVE')
export const setUserSearchesToAdd =
  createProfileRefreshAction('SET_USER_SEARCHES_TO_ADD')
export const setProfileRefreshInterval =
  createProfileRefreshAction('SET_PROFILE_REFRESH_INTERVAL')
