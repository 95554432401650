import PropTypes from 'prop-types'
import React from 'react'

import Button from 'app/common/Button'
import Modal from 'app/common/Modal'
import * as urls from 'app/urls'

import * as styles from './SaveToFirmLibraryModal.less'


export default function SaveToFirmLibraryModal({
  isNewSearch,
  currentFirmLibraryName,
  onContinue,
  onClose,
}) {
  const adminSearchesLink = <a href={urls.adminSearches()} target="_blank">Admin -> Searches</a>

  const message = isNewSearch
    ? <p>This will save the current search into the {currentFirmLibraryName}. Once completed, you will be
      redirected to {adminSearchesLink} where you can add the search to other profiles, or continue to refine it.
      </p>
    : <p>This will move the current search out of your profile and into the {currentFirmLibraryName}. Once completed,
      you will be redirected to {adminSearchesLink} where you can add the search to other profiles, or continue to
      refine it.</p>

  return (
    <Modal
      onClose={onClose}
    >
      <h3 className={styles.modalHeader}>Save into the {currentFirmLibraryName}</h3>

      {message}

      <div className={styles.buttonContainer}>
        <Button
          label={isNewSearch ? 'Next' : 'Save'}
          isPrimary
          onClick={onContinue}
        />
      </div>
    </Modal>
  )
}
