import * as R from 'ramda'

import { STATE_PROP_NAME } from './firm-settings-constants'
import {
  STATE_PROP_NAME as FIRM_ADMIN_PROP_NAME
} from '../firm-admin-constants'

export const getSettingsAdmin = R.path([FIRM_ADMIN_PROP_NAME, STATE_PROP_NAME])
export const getEditLocationModalData = state => getSettingsAdmin(state).editLocationModalData
export const getAssignModalData = state => getSettingsAdmin(state).assignModalData
export const getCountries = state => getSettingsAdmin(state).allCountries
export const getTimezones = state => getSettingsAdmin(state).allTimezones
export const getLanguages = state => getSettingsAdmin(state).allLanguages
export const getUserIds = state => getSettingsAdmin(state).userIds
export const getFirmLocations = state => getSettingsAdmin(state).firmLocations
export const getFirmLanguages = state => getSettingsAdmin(state).firmLanguages
export const getNewLocationDescription = state => getSettingsAdmin(state).newLocationDescription
export const getNewLocationCountryId = state => getSettingsAdmin(state).newLocationCountryId
export const getNewLocationTimezone = state => getSettingsAdmin(state).newLocationTimezone
export const getSelectedLocationIds = state => getSettingsAdmin(state).selectedLocationIds
