import {
  FILTER_CATEGORY_EXCLUSIONS,
  FILTER_CATEGORY_GEOGRAPHY,
  FILTER_CATEGORY_NEWS,
  FILTER_CATEGORY_OTHER,
  FILTER_CATEGORY_POPULAR,
  FILTER_CATEGORY_REGULATORY,
  FILTER_CATEGORY_TOPICS,
} from 'app/constants'

export const BROWSE_SECTIONS = {
  SEARCHES: 'searches',
  FIRM_LIBRARY_SEARCHES: 'firmLibrarySearches',
  FIRM_SOURCE_LABELS: 'firmSourceLabels',
}

export const BROWSE_OPTIONS = [
  BROWSE_SECTIONS.SEARCHES,
  BROWSE_SECTIONS.FIRM_LIBRARY_SEARCHES,
  BROWSE_SECTIONS.FIRM_SOURCE_LABELS,
  FILTER_CATEGORY_NEWS,
  FILTER_CATEGORY_GEOGRAPHY,
  FILTER_CATEGORY_TOPICS,
  FILTER_CATEGORY_POPULAR,
  FILTER_CATEGORY_EXCLUSIONS,
  FILTER_CATEGORY_REGULATORY,
  FILTER_CATEGORY_OTHER,
]

export const BROWSE_FILTERS_TEMPLATE = BROWSE_OPTIONS.reduce((a, b) => (a[b] = [], a), {})

export const FILTER_GROUP_SPECIAL_KEY = 'filterGroupSpecial'
export const FILTER_GROUP_SEARCH_ID_KEY = 'searchId'
export const FILTER_GROUP_FIRM_SOURCE_LABEL_ID_KEY = 'firmSourceLabelId'

export const ADVANCED_FILTER_LABELS = {
  articleAny: 'Article Contents (OR)',
  articleAll: 'Article Contents (AND)',
  articleExclude: 'Article Contents (NOT)',
  articleExact: 'Article Contents (EXACT)',
  headlineAny: 'Headline Contents (OR)',
  headlineAll: 'Headline Contents (AND)',
  headlineExclude: 'Headline Contents (NOT)',
  headlineExact: 'Headline Contents (EXACT)',
  introAny: 'Headline + Leading Text Contents (OR)',
  introAll: 'Headline + Leading Text Contents (AND)',
  introExclude: 'Headline + Leading Text Contents (NOT)',
  introExact: 'Headline + Leading Text Contents (EXACT)',
}

export const ADVANCED_FILTER_KEYS = {
  articleAnySelectedOptions: 'text_any',
  articleAllSelectedOptions: 'text_all',
  articleExcludeSelectedOptions: 'text_exclude',
  articleExactSelectedOptions: 'content_exact',
  headlineAnySelectedOptions: 'headline_any',
  headlineAllSelectedOptions: 'headline_all',
  headlineExcludeSelectedOptions: 'headline_exclude',
  headlineExactSelectedOptions: 'headline_exact',
  introAnySelectedOptions: 'intro_any',
  introAllSelectedOptions: 'intro_all',
  introExcludeSelectedOptions: 'intro_exclude',
  introExactSelectedOptions: 'intro_exact',
}

export const FILTER_OPTION_TEMPLATE = {
  filterGroupCategory: null,
  filterGroupId: null,
  filterId: null,
  filterGroupSpecial: null,
  searchId: null,
  filterField: null,
  firmSourceLabelId: null,
  value: null,
  label: null,
  scope: null,
  notes: null,
  isFirmLibrary: false,
  isFirmLibraryChild: false,
}

export const ADVANCED_FILTER_CATEGORY_ID = 1
export const MY_SEARCHES_FILTER_CATEGORY_ID = 2
