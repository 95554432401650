import { Document, Feed } from 'app/models'
import React, { Component } from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import AppWrapper from "app/global/app-wrapper"
import Orm from 'app/framework/Orm'
import InteractionBar from "app/reusable/InteractionBar"
import * as constants from 'app/django-interaction-bar/django-interaction-bar-constants'
import reducer from 'app/django-interaction-bar/django-interaction-bar-reducer'
import saga from 'app/django-interaction-bar/django-interaction-bar-saga'
import PropTypes from "prop-types"
import { getArePromotedAndExcludedFeedsFetched, getInitializedDocumentIds, getInitializedFeedIds } from
    "app/django-interaction-bar/django-interaction-bar-selectors"
import {truncateWords} from "../utils/strings"
import { getProfileSources } from "app/profile/sources/profile-sources-selectors"
import { fetchPromotedFeeds } from "app/profile/sources/profile-sources-actions"
import { initBar, initPromotedAndExcludedFeeds } from
    "app/django-interaction-bar/django-interaction-bar-actions"


@connect(
  (state, props) => {
    const arePromotedAndExcludedFeedsFetched = getArePromotedAndExcludedFeedsFetched(state)
    const initializedDocumentIds = getInitializedDocumentIds(state)
    const initializedFeedIds = getInitializedFeedIds(state)
    const profileSources = getProfileSources(state)
    const orm = Orm.withEntities(state.entities)
    return {
      document: orm.getById(Document, props.documentId),
      feed: orm.getById(Feed, props.feedId),
      arePromotedAndExcludedFeedsFetched,
      initializedDocumentIds,
      initializedFeedIds,
      profileSources,
    }
  },
  {
    fetchPromotedFeeds,
    initBar,
    initPromotedAndExcludedFeeds,
  },
)
export default class DjangoInteractionBar extends Component {
  static propTypes = {
    documentId: PropTypes.number.isRequired,
    feedId: PropTypes.number.isRequired,
    insightsInterimUrl: PropTypes.string.isRequired,
    isInsightsEnabled: PropTypes.bool.isRequired,
    flaggedIds: PropTypes.array.isRequired,
  }

  state = {
    promotedFeedIds: undefined,
    flaggedIds: undefined,
  }

  componentDidMount() {
    const {feedId, documentId, arePromotedAndExcludedFeedsFetched, initBar,
      initPromotedAndExcludedFeeds, flaggedIds} = this.props

    if (!arePromotedAndExcludedFeedsFetched) {
      initPromotedAndExcludedFeeds()
    }

    initBar({
      feedId: feedId,
      documentId: documentId,
      flaggedIds: flaggedIds,

    })
  }

  render() {
    const{documentId, feedId, document, feed} = this.props
    if (!document || !feed) {return <div/>}

    // Since the shape of the document object that InteractionBar expects is
    // slightly different, we need to transform it as such.
    const articleForInteractionBar = (document, feed) => ({
      ...document,
      directorId: document.id,
      feedId: feedId,
      signals: this.props.isInsightsEnabled,
      signalsInterimUrl: this.props.isInsightsEnabled && this.props.insightsInterimUrl,
      displayHeadlineShort: truncateWords(document.headline, 10),
      source: feed.name
    })

    const isFlagged = this.props.flaggedIds && this.state.flaggedIds !== undefined
      ? this.state.flaggedIds.includes(documentId) || this.props.flaggedIds.includes(documentId)
      : this.props.flaggedIds
        ? this.props.flaggedIds.includes(documentId)
        : false

    const isPromoted = feed && this.state.promotedFeedIds !== undefined
      ? this.state.promotedFeedIds.includes(feed.id)
      : feed && this.props.profileSources.promotedFeedIds
        ? this.props.profileSources.promotedFeedIds.includes(feed.id)
        : false

    return (
      <React.Fragment>
        {document && feed &&
          <InteractionBar
            article={articleForInteractionBar(document, feed)}
            isFlagged={isFlagged}
            isPromoted={isPromoted}
            isSignalsEnabled={this.props.isInsightsEnabled}
            onFlaggingStateChange={this.handleFlaggingStateChange}
            onPromoteStateChange={this.handlePromoteStateChange}
            onExclude={this.handleExclude}
          />
        }
      </React.Fragment>
    )
  }
  handlePromoteStateChange = ({feedId, isPromoted}) => {
    let promotedFeedIds = this.state.promotedFeedIds !== undefined
      ? [...this.state.promotedFeedIds]
      : [...this.props.profileSources.promotedFeedIds]
    if (isPromoted && !promotedFeedIds.includes(feedId)) {
      promotedFeedIds.push(feedId)
    }
    else if (!isPromoted && promotedFeedIds.includes(feedId)) {
      promotedFeedIds = promotedFeedIds.filter(id => id !== feedId)
    }
    this.setState({promotedFeedIds: promotedFeedIds})
  }
  handleFlaggingStateChange = ({documents}) => {
    let flaggedIds = this.state.flaggedIds !== undefined
      ? [...this.state.flaggedIds]
      : [...this.props.flaggedIds]
    for (let {id, isFlagged} of documents) {
      id = parseInt(id, 10)
      if (isFlagged && !flaggedIds.includes(id)) {
        flaggedIds.push(id)
      }
      else if (!isFlagged && flaggedIds.includes(id)) {
        flaggedIds = flaggedIds.filter(flaggedId => flaggedId !== id)
      }
    }
    this.setState({flaggedIds: flaggedIds})
  }
  handleExclude = () => {
    let url = window.location.href
    if (url.indexOf('f-load=0') === -1) {
      if (url.indexOf('?') > -1) {
        url += '&f-load=0'
      } else {
        url += '?f-load=0'
      }
    }
    window.location.href = url
  }
}

export function init(documentId, feedId, isInsightsEnabled, insightsInterimUrl, flaggedIds) {
  const element = document.getElementById(`django-interaction-bar-wrapper-${documentId}`)
  if (!element) return

  const component =
    <AppWrapper>
      <DjangoInteractionBar
        documentId={documentId}
        feedId={feedId}
        isInsightsEnabled={isInsightsEnabled}
        insightsInterimUrl={insightsInterimUrl}
        flaggedIds={flaggedIds}
      />
    </AppWrapper>
  render(component, element)
}

export {constants, reducer, saga}
