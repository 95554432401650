// TODO: comparisonPageTODO remove this once new comparison page has been released
import { changeCaseObject } from 'app/utils'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'

import store from 'app/store'

import Tier2NarrowBy from './Tier2NarrowBy'


export function init(data) {
  data = changeCaseObject.camelCase(data)

  const component = (
    <Provider store={store}>
      <Tier2NarrowBy search={data.search} filters={data.filters}/>
    </Provider>
  )

  const container = document.getElementById('tier2-narrow-by-container')
  render(component, container)
}
