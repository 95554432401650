import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import WhatsNew from './WhatsNew'


export default function NavItems({
  currentSiteSection,
  highlightBlog,
  isHomePage,
  searchTypes,
}) {
  const navItems = searchTypes.map(searchType => {
    const {label, navID} = searchType
    const url = searchType.url
      ? searchType.url + '?usrc=nav&usrc_s=primary&nav_comp=1'
      : undefined

    return (
      <NavItem
        active={currentSiteSection === searchType.siteSectionID}
        label={label}
        navID={navID}
        url={url}
        key={navID}
      />
    )
  })
  if (isHomePage) {
    navItems.push(
      <WhatsNew key="whats-new" highlight={highlightBlog} />
    )
  }

  return <ul>{navItems}</ul>
}
NavItems.propTypes = {
  currentSiteSection: PropTypes.string,
  highlightBlog: PropTypes.bool.isRequired,
  isHomePage: PropTypes.bool.isRequired,
  searchTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
}


function NavItem({active, label, navID, url}) {
  return (
    <li id={`nav-${navID}-li`} className={classNames('primary', {active})}>
      <a id={`nav-${navID}`} href={url}>
        <span className="search-label">{label}</span>
      </a>
    </li>
  )
}
