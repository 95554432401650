import HtmlReactParser from 'html-react-parser'
import PropTypes from 'prop-types'
import React, {cloneElement} from 'react'

import {ResourceLoader} from 'app/utils'

const loader = new ResourceLoader()

/**
 * Loads the SVG file at `src` and inlines it.
 */
export default class InlineSvg extends React.Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
  }

  state = {
    svgText: null,
  }

  _isMounted = false

  async componentDidMount() {
    this._isMounted = true
    const parser = new DOMParser()
    const svgContent = await loader.load(this.props.src)
    const doc = parser.parseFromString(svgContent, 'image/svg+xml')
    const svgText = new XMLSerializer().serializeToString(doc.documentElement)
    if (this._isMounted) {
      this.setState({svgText})
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const {svgText} = this.state
    if (!svgText) {
      return null
    }
    const {src, ...restProps} = this.props
    return cloneElement(HtmlReactParser(svgText), restProps)
  }
}
