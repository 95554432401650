import { createAction } from 'redux-actions'

// Prefix action names with SEARCHES_ADMIN
const createSearchesAdminAction = name => createAction(`SEARCHES_ADMIN_${name}`)

export const init = createSearchesAdminAction('INIT')
export const hideLoader = createSearchesAdminAction('HIDE_LOADER')
export const fetchSearches = createSearchesAdminAction('FETCH_SEARCHES')
export const fetchSearchesComplete = createSearchesAdminAction('FETCH_SEARCHES_COMPLETE')
export const fetchSearch = createSearchesAdminAction('FETCH_SEARCH')
export const fetchSearchComplete = createSearchesAdminAction('FETCH_SEARCH_COMPLETE')
export const fetchFilters = createSearchesAdminAction('FETCH_FILTERS')
export const changeCurrentPage = createSearchesAdminAction('CHANGE_CURRENT_PAGE')
export const selectSearch = createSearchesAdminAction('SELECT_SEARCH')
export const setDeleteSearchIds = createAction('SET_DELETE_SEARCH_IDS')
export const setSelectedSearchIds = createSearchesAdminAction('SET_SELECTED_SEARCH_IDS')
export const deselectSearch = createSearchesAdminAction('DESELECT_SEARCH')
export const fetchAllSearchIds = createAction('FETCH_ALL_SEARCH_IDS')
export const deselectAllSearches = createSearchesAdminAction('DESELECT_ALL_SEARCHES')

export const updateSearch = createSearchesAdminAction('UPDATE_SEARCH')
export const deleteSearches = createSearchesAdminAction('DELETE_SEARCHES')
export const deleteSearchesComplete = createSearchesAdminAction('DELETE_SEARCHES_COMPLETE')
export const createAndShareSearch = createSearchesAdminAction('CREATE_AND_SHARE_SEARCH')
export const createAndShareSearchComplete = createSearchesAdminAction('CREATE_AND_SHARE_SEARCH_COMPLETE')
export const createSearches = createSearchesAdminAction('CREATE_SEARCHES')
export const createSearchesComplete = createSearchesAdminAction('CREATE_SEARCHES_COMPLETE')
export const shareSearches = createSearchesAdminAction('SHARE_SEARCHES')
export const shareSearchesComplete = createSearchesAdminAction('SHARE_SEARCHES_COMPLETE')

export const showAddNewModal = createSearchesAdminAction('SHOW_ADD_NEW_MODAL')
export const addNewModalSetStep = createSearchesAdminAction('ADD_NEW_MODAL_SET_STEP')

export const setSaveData = createSearchesAdminAction('SET_SAVE_DATA')
export const resetSaveData = createSearchesAdminAction('RESET_SAVE_DATA')
export const setQueryComponentState = createSearchesAdminAction('SET_QUERY_COMPONENT_STATE')

export const hideAddNewModal = createSearchesAdminAction('HIDE_ADD_NEW_MODAL')

export const showEditSearchModal = createSearchesAdminAction('SHOW_EDIT_SEARCH_MODAL')
export const hideEditSearchModal = createSearchesAdminAction('HIDE_EDIT_SEARCH_MODAL')

export const showEditSourceFolderModal = createSearchesAdminAction('SHOW_EDIT_SOURCE_FOLDER_MODAL')
export const hideEditSourceFolderModal = createSearchesAdminAction('HIDE_EDIT_SOURCE_FOLDER_MODAL')

export const showAssignModal = createSearchesAdminAction('SHOW_ASSIGN_MODAL')
export const fetchAssignees = createSearchesAdminAction('FETCH_ASSIGNEES')
export const fetchAssigneesComplete = createSearchesAdminAction('FETCH_ASSIGNEES_COMPLETE')
export const fetchUsers = createSearchesAdminAction('FETCH_USERS')
export const fetchUsersComplete = createSearchesAdminAction('FETCH_USERS_COMPLETE')
export const setUserIds = createSearchesAdminAction('SET_USER_IDS')
export const setAssignType = createSearchesAdminAction('SET_ASSIGN_TYPE')
export const hideAssignModal = createSearchesAdminAction('HIDE_ASSIGN_MODAL')

export const changeFilters = createSearchesAdminAction('CHANGE_FILTERS')
export const changeSort = createSearchesAdminAction('CHANGE_SORT')

export const sendSearchesCsvEmail = createSearchesAdminAction('SEND_SEARCHES_CSV_EMAIL')
export const getSearchResults = createSearchesAdminAction('GET_SEARCH_RESULTS')
export const getSearchResultsComplete = createSearchesAdminAction('GET_SEARCH_RESULTS_COMPLETE')

export const setShareModalData = createSearchesAdminAction('SET_SHARE_MODAL_DATA')
export const fetchCanShowRelevanceFilterIds = createAction('FETCH_CAN_SHOW_RELEVANCE_FILTER_IDS')
export const setCanShowRelevanceFilter = createAction('SET_CAN_SHOW_RELEVANCE_FILTER')

export const removeExcludedFeedsFromSearch = createAction('REMOVE_EXCLUDED_FEEDS_FROM_SEARCH')

export const setShouldShowBulkAddFiltersModal = createAction('SET_SHOULD_SHOW_BULK_ADD_FILTERS_MODAL')
export const applyFiltersToSearches = createAction('APPLY_FILTERS_TO_SEARCHES')

export const setFeatureModalData = createAction('SET_FEATURE_MODAL_DATA')
export const addFeaturedSearches = createAction('ADD_FEATURED_SEARCHES')
export const removeFeaturedSearches = createAction('REMOVE_FEATURED_SEARCHES')

export const showSearchesManageLabelsModal = createAction('SHOW_SEARCHES_MANAGE_LABELS_MODAL')
export const setSearchesNewLabelData = createAction('SET_SEARCHES_NEW_LABEL_DATA')
export const setSearchesManageLabelsFilters = createAction('SET_SEARCHES_MANAGE_LABELS_FILTERS')

export const fetchSearchesParentLabels = createAction('FETCH_SEARCHES_PARENT_LABELS')
export const fetchSearchesManageLabels = createAction('FETCH_SEARCHES_MANAGE_LABELS')
export const fetchSearchesParentLabelsComplete = createAction('FETCH_SEARCHES_PARENT_LABELS_COMPLETE')
export const setSearchesManageLabelsTotalCount = createAction('SET_SEARCHES_MANAGE_LABELS_TOTAL_COUNT')
export const fetchSearchesLabelsComplete = createAction('FETCH_SEARCHES_LABELS_COMPLETE')
export const setSearchesManageLabelsModalData = createAction('SET_SEARCHES_MANAGE_LABELS_MODAL_DATA')
export const resetSearchesManageLabelsModalData = createAction('RESET_SEARCHES_MANAGE_LABELS_MODAL_DATA')
export const createSearchesLabel = createAction('CREATE_SEARCHES_LABEL')
export const saveSearchesLabel = createAction('SAVE_SEARCHES_LABEL')
export const deleteSearchesLabel = createAction('DELETE_SEARCHES_LABEL')
export const resetSearchesNewLabelData = createAction('RESET_SEARCHES_NEW_LABEL_DATA')

export const addSearchesLabels = createAction('ADD_SEARCHES_LABELS')
export const removeSearchesLabels = createAction('REMOVE_SEARCHES_LABELS')
export const updateSearchesLabels = createAction('UPDATE_SEARCHES_LABELS')

export const setBulkEditLabelModalData = createAction('SET_BULK_EDIT_LABEL_MODAL_DATA')
export const updateBulkEditSearchesLabels = createAction('UPDATE_BULK_EDIT_SEARCHES_LABELS')
export const resetBulkEditLabelModal = createAction('RESET_BULK_EDIT_LABEL_MODAL')
export const showSelectedColumns = createAction('SHOW_SELECTED_COLUMNS')

export const createCopiedSearch = createAction('CREATE_COPIED_SEARCH')
