import { createAction } from 'redux-actions'

import { createRequestActions } from 'app/actions/utils'

// Prefix action names with DNB_
const createDnbAction = name => createAction(`DNB_${name}`)
const createDnbRequestActions = name => createRequestActions(`DNB_${name}`)

export const showDnbModal = createDnbAction('SHOW_MODAL')
export const fetchDnbData = createDnbRequestActions('FETCH_DATA')
export const showConfirmation = createDnbAction('SHOW_CONFIRMATION')
export const selectCompanies = createDnbAction('SELECT_COMPANIES')
export const deselectCompanies = createDnbAction('DESELECT_COMPANIES')
export const deselectAllCompanies = createDnbAction('DESELECT_ALL_COMPANIES')
export const changeNameFilter = createDnbAction('CHANGE_NAME_FILTER')
export const showAddingIndicator = createDnbAction('SHOW_ADDING_INDICATOR')
export const hideAddingIndicator = createDnbAction('HIDE_ADDING_INDICATOR')
export const toggleCompaniesList = createDnbAction('TOGGLE_COMPANIES_LIST')
