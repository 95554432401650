import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import PaginatedTable from 'app/common/PaginatedTable'
import Table from 'app/common/Table'
import InlineSvg from 'app/common/InlineSvg'
import Tooltip from 'app/common/Tooltip'
import * as globalSelectors from 'app/global/global-selectors'
import { SavedSearch } from 'app/models'
import * as strings from 'app/strings'
import { showAssignModal } from './searches-admin-actions'
import { COLUMNS } from './searches-admin-constants'

@connect(
  createSelector(
    [globalSelectors.getCurrentFirm, globalSelectors.getCurrentFirmLibraryName],
    (currentFirm, currentFirmLibraryName) => ({
      currentFirm,
      currentFirmLibraryName,
    }),
  ),
  { showAssignModal }
)
export default class SearchesTable extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    searches: PropTypes.arrayOf(PropTypes.instanceOf(SavedSearch)).isRequired,
    selectedSearchIds: PropTypes.arrayOf(PropTypes.number),
    currentPage: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,

    // (SavedSearch, Boolean) -> ()
    onSearchSelectStateChange: PropTypes.func,

    // (Number) -> ()
    onPageChange: PropTypes.func,

    // (searches: [SavedSearch], tab: String?) -> ()
    onEdit: PropTypes.func,

    // (searches: [SavedSearch]) -> ()
    onDelete: PropTypes.func,
    selectedColumns: PropTypes.array
  }

  static defaultProps = {
    selectedSearchIds: [],

    onSearchSelectStateChange() { },
    onPageChange() { },
    onEdit() { },
    onDelete() { },
  }

  static COLUMNS = COLUMNS

  getContentWithTooltip(colData, reqLen = 10) {
    const updatedColData = colData ?
      (colData.length > reqLen ?
        colData.substring(0, reqLen) + '...' : colData)
      : ''
    const tooltipData = updatedColData ? <Tooltip
      label={colData}
      style={{lineBreak: "anywhere"}}
    >
      <span className="tooltip-no-image">{updatedColData}</span>
    </Tooltip> : null

    return (
      <>
        {tooltipData}
      </>
    )
  }

  getLabelsString(labels) {
    if (labels) {
      const labelNames = labels.map(label => label.name)
      return labelNames.join(', ')
    } else {
      return ''
    }
  }

  render() {
    const restProps = R.omit(
      Object.keys(this.constructor.propTypes || {}),
      this.props,
    )
    const { selectedColumns } = this.props
    return (
      <div style={{ width: '87em' }}>
        <PaginatedTable
          id="firm-searches-table"
          defaultSort={{ column: COLUMNS.NAME, direction: 'asc' }}
          data={this.props.searches}
          pageCount={this.props.pageCount}
          tab="Searches"
          currentPage={this.props.currentPage}
          onPageChange={this.props.onPageChange}
          {...restProps}
        >
          <Table.Column
            name={COLUMNS.CHECKBOX}
            baseWidth={20}
            growRatio={0}
            shrinkRatio={0}
            cellContents={search =>
              <input
                className="checkbox"
                type="checkbox"
                onChange={this.onCheckboxChange.bind(this, search.id)}
                checked={this.props.selectedSearchIds.includes(search.id)}
              />
            }
          />

          <Table.Column
            name={COLUMNS.NAME}
            label="Search Name"
            isSortable
            sortBy={R.pipe(R.prop('name'), R.trim, R.toLower)}
            baseWidth={80}
            growRatio={2}
            cellContents={search =>
              <span>
                {(search.isFirmLibrary || search.isFirmLibraryChild) &&
                  <span className="firm-library-icon">
                    <Tooltip
                      label={`${search.isFirmLibraryChild ? 'Linked to ' : ''}${this.props.currentFirmLibraryName} Search`}
                    >
                      <InlineSvg src="/media/img/firm-library-icon.svg" />
                    </Tooltip>
                  </span>
                }

                <a className="name" onClick={() => this.editSearch(search)}>
                  {search.name}
                </a>
              </span>
            }
          />

          {selectedColumns && selectedColumns.includes(COLUMNS.CATEGORY) ?
            <Table.Column
              name={COLUMNS.CATEGORY}
              label="Category"
              isSortable
              sortBy={R.prop('category')}
              baseWidth={90}
              growRatio={1}
              cellContents={search =>
                strings.searchCategory(
                  search.category,
                  { currentFirm: this.props.currentFirm },
                )
              }
            /> : null
          }
          {selectedColumns && selectedColumns.includes(COLUMNS.TYPE) ? <Table.Column
            name={COLUMNS.TYPE}
            label="Privacy Setting"
            isSortable
            baseWidth={80}
            maxWidth={90}
            growRatio={1}
            cellContents={search =>
              search.isSource ? '---' : strings.searchScope(search.scope)
            }
          /> : null
          }

          {selectedColumns && selectedColumns.includes(COLUMNS.LABELS) ? <Table.Column
            name={COLUMNS.LABELS}
            label="Labels"
            baseWidth={100}
            growRatio={1}
            cellContents={search => {
              const labelsString = this.getLabelsString(search.labels)
              if (labelsString) {
                return this.getContentWithTooltip(labelsString, 25)
              } else {
                return ''
              }
            }}
          /> : null
          }

          {selectedColumns && selectedColumns.includes(COLUMNS.OWNER) ? <Table.Column
            name={COLUMNS.OWNER}
            label="Owner"
            isSortable
            sortBy={
              R.pipe(
                search => search.owner.displayName || search.owner.username,
                R.trim,
                R.toLower,
              )
            }
            baseWidth={100}
            growRatio={1}
            cellContents={search => search.owner.displayName || search.owner.username}
          /> : null
          }

          {selectedColumns && selectedColumns.includes(COLUMNS.SHARED_WITH) ? <Table.Column
            name={COLUMNS.SHARED_WITH}
            label="Number of Profiles"
            sortBy={search => search.numberOfLinkedProfiles}
            baseWidth={85}
            growRatio={1}
            className="text-align-center"
            cellContents={search => search.numberOfLinkedProfiles}
          /> : null
          }

          {selectedColumns && selectedColumns.includes(COLUMNS.HAS_FILTERS) ? <Table.Column
            name={COLUMNS.HAS_FILTERS}
            label="Has Filters?"
            baseWidth={70}
            growRatio={1}
            className="text-align-center"
            cellContents={search =>
              search.isSource ? 'N/A' : (search.hasFilters ? 'Yes' : 'No')
            }
          /> : null
          }

          {selectedColumns && selectedColumns.includes(COLUMNS.USED_IN_SEARCH) ? <Table.Column
            name={COLUMNS.USED_IN_SEARCH}
            label="Used in Search"
            baseWidth={90}
            growRatio={1}
            className="text-align-center"
            cellContents={search => search.usedCount}
          /> : null
          }

          {selectedColumns && selectedColumns.includes(COLUMNS.DEPARTMENT) ? <Table.Column
            name={COLUMNS.DEPARTMENT}
            label="Department"
            baseWidth={110}

            isSortable
            sortBy={
              R.pipe(
                search => search.owner.firmDepartmentName,
                R.trim,
                R.toLower,
              )
            }
            growRatio={1}
            className="text-align-center"
            cellContents={search => search.owner.firmDepartmentName ? this.getContentWithTooltip(search.owner.firmDepartmentName) : 'N/A'}
          /> : null
          }

          {selectedColumns && selectedColumns.includes(COLUMNS.TEAM) ? <Table.Column
            name={COLUMNS.TEAM}
            label="Team"
            isSortable
            sortBy={R.pipe(R.prop('featured_for_teams'), R.trim, R.toLower)}
            baseWidth={80}
            growRatio={1}
            className="text-align-center"
            cellContents={search => search.owner.firmTeamName ? this.getContentWithTooltip(search.owner.firmTeamName) : 'N/A'}
          /> : null
          }

          {selectedColumns && selectedColumns.includes(COLUMNS.LOCATION) ? <Table.Column
            name={COLUMNS.LOCATION}
            label="Office Location"
            isSortable
            sortBy={
              R.pipe(
                search => search.owner.firmLocationName,
                R.trim,
                R.toLower,
              )
            }
            baseWidth={100}
            growRatio={1}
            className="text-align-center"
            cellContents={search => search.owner.firmLocationName ? this.getContentWithTooltip(search.owner.firmLocationName) : 'N/A'}
          /> : null
          }

          <Table.Column
            name={COLUMNS.ACTIONS}
            baseWidth={160}
            growRatio={1}
            cellContents={search => {
              if (search.category === SavedSearch.CATEGORIES.TRUSTED) {
                return (
                  <span className="actions">
                    <a
                      className="action searches-admin-add-to"
                      onClick={() => this.showAssignModal(search)}
                    >
                      Add to&#8230;
                    </a>
                    <a
                      className="action searches-admin-edit"
                      onClick={() => this.editSearch(search)}
                    >
                      Edit
                    </a>
                  </span>
                )
              }
              if (search.isSource) {
                return (
                  <span className="actions searches-admin-manage-source">
                    <a
                      className="action"
                      onClick={() => this.editSearch(search)}
                    >
                      Manage Source
                    </a>
                  </span>
                )
              }
              return (
                <span className="actions">
                  <a
                    className="action searches-admin-add-to"
                    onClick={() => this.showAssignModal(search)}
                  >
                    Add to&#8230;
                  </a>
                  <a
                    className="action searches-admin-edit"
                    onClick={() => this.editSearch(search)}
                  >
                    Edit
                  </a>
                  <a
                    className="action searches-admin-delete"
                    onClick={() => this.deleteSearch(search)}
                  >
                    Delete
                  </a>
                </span>
              )
            }}
          />
        </PaginatedTable>
        {
          !this.props.isLoading && this.props.searches.length === 0
            ? <div className="no-results-message">No searches match your filter criteria. Try expanding your filters.</div>
            : null
        }
      </div>
    )
  }

  // Event handlers

  onCheckboxChange(id, event) {
    this.props.onSearchSelectStateChange(id, event.target.checked)
  }

  showAssignModal(search) {
    this.props.showAssignModal([search.id])
  }

  editSearch(search) {
    this.props.onEdit(search.id)
  }

  deleteSearch(search) {
    this.props.onDelete(search.id)
  }
}
