import { changeCaseObject } from 'app/utils'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'

import store from 'app/store'

import MIS from './MIS'
import * as constants from './mis-constants'
import * as actions from './mis-actions'
import reducer from './mis-reducer'
import saga from './mis-saga'

import './MIS.less'

export function init(data) {
  // Add the data from this page to the Redux store
  store.dispatch(actions.init(changeCaseObject.camelCase(data)))
  const component = (
    <Provider store={store}>
      <MIS/>
    </Provider>
  )
  const container = document.getElementById('mis-wrapper')
  render(component, container)
}

export {constants, reducer, saga}
