import * as dateFns from 'date-fns'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { Component } from 'react'

import PaginatedTable from 'app/common/PaginatedTable'
import Table from 'app/common/Table'
import Tooltip from 'app/common/Tooltip'
import {DATE_FORMAT} from 'app/constants'

import { TABS as EDIT_FEED_OVERLAY_TABS } from './EditModal/Content'
import Button from 'app/common/Button'
import classNames from 'classnames'
import * as styles from './style.less'
import {COLUMNS} from './sources-admin-constants'

const SOURCE_TYPES = {
  EXCLUDED: 'excluded',
  PROPRIETARY: 'proprietary',
  PUBLIC: 'public',
}


export default class FeedsTable extends Component {
  static propTypes = {
    feeds: PropTypes.arrayOf(PropTypes.object).isRequired,
    firm: PropTypes.object.isRequired,
    selectedFeedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    appName: PropTypes.string.isRequired,
    appNameFull: PropTypes.string.isRequired,
    enableSourceLastContentDisplay: PropTypes.bool.isRequired,
    pageCount: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,

    // (Feed, Boolean) -> ()
    onFeedSelectStateChange: PropTypes.func,

    // (feed: Feed, tab: String?) -> ()
    onEdit: PropTypes.func.isRequired,

    // (Feed) -> ()
    onRemove: PropTypes.func.isRequired,

    // (Feed, Boolean) -> ()
    onPromotedChange: PropTypes.func.isRequired,
    onDemotedChange: PropTypes.func.isRequired,

    onPageChange: PropTypes.func.isRequired,
    onSortChange: PropTypes.func.isRequired,
    selectedColumns: PropTypes.array,
    onReportClick: PropTypes.func.isRequired,
  }

  getContentWithTooltip(colData, reqLen = 10) {
    const updatedColData = colData ?
      (colData.length > reqLen ?
        colData.substring(0, reqLen) + '...' : colData)
      : ''
    const tooltipData = updatedColData ? <Tooltip
      label={colData}
      style={{lineBreak: "anywhere"}}
    >
      <span className="tooltip-no-image">{updatedColData}</span>
    </Tooltip> : null

    return (
      <>
        {tooltipData}
      </>
    )
  }

  getLabelsString(labels) {
    if (labels) {
      const labelNames = labels.map(label => label.name)
      return labelNames.join(', ')
    } else {
      return ''
    }
  }

  render() {
    let {orgType} = this.props.firm

    if (orgType === 'Organization') {
      orgType = 'Org.'
    }

    const { selectedColumns } = this.props
    return (
      <PaginatedTable
        id="firm-sources-table"
        defaultSort={{column: COLUMNS.NAME, direction: 'asc'}}
        data={this.props.feeds}
        pageCount={this.props.pageCount}
        currentPage={this.props.currentPage}
        tab="Sources"
        onPageChange={this.props.onPageChange}
        onSortChange={this.props.onSortChange}
      >
        <Table.Column
          name={COLUMNS.CHECKBOX}
          baseWidth={35}
          growRatio={0}
          shrinkRatio={0}
          cellContents={feed =>
            <input
              className="checkbox"
              type="checkbox"
              onChange={this.onSourceSelect.bind(this, feed.id)}
              checked={this.props.selectedFeedIds.includes(feed.id)}
            />
          }
        />
        {selectedColumns && selectedColumns.includes(COLUMNS.STATUS) ?
          <Table.Column
            name={COLUMNS.STATUS}
            label="Status"
            baseWidth={85}
            growRatio={2}
            cellContents={feed => {
              let btnStatus = feed?.status?.toLowerCase()
              if (btnStatus) {
                if (feed.reported) {
                  return (
                    <>
                      <div className="status-btn-container">
                        <div className={`status-btn ${btnStatus}-status-btn`}>{btnStatus}</div>
                        {
                          (feed.status == ('Dormant')) &&
                          <Tooltip
                            label={"Under Review"}
                            style={{ lineBreak: "anywhere" }}
                          ><span className="tooltip-no-image">
                              <span className='under-review'>
                                <span
                                  className="under-review-source-icon"
                                ></span>
                              </span>
                            </span>
                          </Tooltip>
                        }
                      </div>
                    </>)
                }
                else {
                  return (
                    <div className="status-btn-container">
                      <div className={`status-btn ${btnStatus}-status-btn`}>{btnStatus}</div>
                    </div>)
                }
              }
            }}
          /> : null
        }

        <Table.Column
          name={COLUMNS.NAME}
          label="Name"
          isSortable
          sortBy={R.pipe(R.prop('name'), R.trim, R.toLower)}
          baseWidth={130}
          growRatio={2}
          cellContents={feed =>
            <a className="name" onClick={() => this.editFeed(feed)}>
              {feed.name}
            </a>
          }
        />

        {selectedColumns && selectedColumns.includes(COLUMNS.TYPE) ?
          <Table.Column
            name={COLUMNS.TYPE}
            label="Type"
            className={feed => feed.isExcluded ? 'excluded' : undefined}
            baseWidth={100}
            growRatio={0}
            cellContents={feed => feedSourceTypeString(feed, orgType, this.props.appName, this.props.appNameFull)}
            colSpan={feed => feed.isExcluded ? 7 : 1}
          /> : null
        }

        {selectedColumns && selectedColumns.includes(COLUMNS.PUBLICATION_TYPE) ?
          <Table.Column
            name={COLUMNS.PUBLICATION_TYPE}
            label="Content Type"
            className={feed => feed.isExcluded ? 'excluded' : undefined}
            baseWidth={90}
            growRatio={0}
            cellContents={feed => feed.publicationCategory}
          /> : null
        }
        {selectedColumns && selectedColumns.includes(COLUMNS.LABELS) ?
          <Table.Column
            name={COLUMNS.LABELS}
            label="Labels"
            baseWidth={120}
            growRatio={1}
            cellContents={search => {
              const labelsString = this.getLabelsString(search.labels)
              if (labelsString) {
                return this.getContentWithTooltip(labelsString, 25)
              } else {
                return ''
              }
            }}
          /> : null
        }
        {selectedColumns && selectedColumns.includes(COLUMNS.CREATED_AT) ?
          <Table.Column
            name={COLUMNS.CREATED_AT}
            label="Date Added"
            isSortable
            baseWidth={100}
            growRatio={0}
            cellContents={feed => dateFns.format(feed.createdAt, DATE_FORMAT)}
          /> : null
        }
        {
          this.props.enableSourceLastContentDisplay && selectedColumns && selectedColumns.includes(COLUMNS.LAST_CONTENT_AT) ?
            <Table.Column
              name={COLUMNS.LAST_CONTENT_AT}
              label="Last Content Date"
              isSortable
              baseWidth={100}
              growRatio={0}
              cellContents={feed => feed.lastContentAt && dateFns.format(feed.lastContentAt, DATE_FORMAT)}
            /> : null
        }
        {selectedColumns && selectedColumns.includes(COLUMNS.IS_LIMITED_SEAT) ?
          <Table.Column
            name={COLUMNS.IS_LIMITED_SEAT}
            label="Limited Seat"
            baseWidth={70}
            growRatio={0}
            cellContents={feed => {
              return (
                feed.isLimitedSeat &&
                <i
                  className="checkmark"
                />
              )
            }}
          /> : null
        }
        {selectedColumns && selectedColumns.includes(COLUMNS.IS_PROMOTED) ?

          <Table.Column
            name={COLUMNS.IS_PROMOTED}
            label={`Promoted ${orgType} Wide`}
            baseWidth={85}
            growRatio={0}
            cellContents={feed => {
              if (feed.isLimitedSeat) return '--' // limited seat takes precedence
              return feed.isPromotedFirmwide
                ? <i
                  className="checkmark"
                  onClick={() => this.unpromoteFeed(feed)}
                />
                : <input
                  type="checkbox"
                  onClick={() => this.promoteFeed(feed)}
                />
            }}
          /> : null
        }
        {selectedColumns && selectedColumns.includes(COLUMNS.IS_DEMOTED) ?

          <Table.Column
            name={COLUMNS.IS_DEMOTED}
            label={`Demoted ${orgType} Wide`}
            baseWidth={85}
            growRatio={0}
            cellContents={feed => {
              if (feed.isLimitedSeat) return '--' // limited seat takes precedence
              return feed.isDemotedFirmwide
                ? <i
                  className="checkmark"
                  onClick={() => this.undemoteFeed(feed)}
                />
                : <input
                  type="checkbox"
                  onClick={() => this.demoteFeed(feed)}
                />
            }}
          /> : null
        }
        {selectedColumns && selectedColumns.includes(COLUMNS.ASSIGNMENT_COUNT) ?

          <Table.Column
            name={COLUMNS.ASSIGNMENT_COUNT}
            label="Assigned to Individuals"
            isSortable
            baseWidth={100}
            growRatio={0}
            cellContents={feed => {
              let assignedToCount = feed.assignedToIndividualsCount
              if (assignedToCount === 0) {
                assignedToCount = 'Unassigned'
              }
              return (
                <a
                  className="assignment-count"
                  onClick={
                    () => this.editFeed(feed, EDIT_FEED_OVERLAY_TABS.ASSIGNMENTS)
                  }
                >
                  {assignedToCount}
                </a>
              )
            }}
          /> : null
          }
        <Table.Column
          name={COLUMNS.ACTIONS}
          baseWidth={120}
          growRatio={0}
          cellContents={feed => {
            const reportIcon = (feed.status == ('Dormant') && !feed.reported) ? <Tooltip
              label={"Report Source"}
              style={{ lineBreak: "anywhere" }}
            >
              <span className="tooltip-no-image">
                <span className='action-box'>
                  <span
                    className="report-source-icon"
                    onClick={() => this.onReportClick(feed)}
                  ></span>
                </span>
              </span>
            </Tooltip> : <Tooltip
              label={feed.reported ? "Already Reported" : "You can only report dormant sources"}
              style={{ lineBreak: "anywhere" }}
            >
              <span className="tooltip-no-image">
                <span className='action-box'>
                  <span
                    className="disable-report-source-icon"
                  ></span>
                </span>
              </span>
            </Tooltip> 

            return (
              <div className="actions">
                <Tooltip
                  label={"Edit Source"}
                  style={{ lineBreak: "anywhere" }}
                >
                  <span className="tooltip-no-image">
                    <span className='action-box'>
                      <span
                        className="edit-source-icon"
                        onClick={() => this.editFeed(feed)}
                      ></span>
                    </span>
                  </span>
                </Tooltip>
                {reportIcon}
                <Tooltip
                  label={"Delete Source"}
                  style={{ lineBreak: "anywhere" }}
                >
                  <span className="tooltip-no-image">
                    <span className='action-box'>
                      <span
                        className="remove-source-icon"
                        onClick={() => this.removeFeed(feed)}
                      ></span>
                    </span>
                  </span>
                </Tooltip>
              </div>)
          }
          }
        />
      </PaginatedTable>
    )
  }

  // Event handlers

  onSourceSelect = (id, event) => {
    this.props.onFeedSelectStateChange(id, event.target.checked)
  }

  editFeed(feed, tab = undefined) {
    this.props.onEdit(feed, tab)
  }

  removeFeed(feed) {
    this.props.onRemove(feed)
  }

  promoteFeed = (feed) => {
    this.props.onPromotedChange(feed, true)
  }

  unpromoteFeed = (feed) => {
    this.props.onPromotedChange(feed, false)
  }

  demoteFeed = (feed) => {
    this.props.onDemotedChange(feed, true)
  }

  undemoteFeed = (feed) => {
    this.props.onDemotedChange(feed, false)
  }

  onReportClick = (feed) => {
    this.props.onReportClick(feed)
  }
}


function feedSourceType(feed) {
  if (feed.isExcluded) {
    return SOURCE_TYPES.EXCLUDED
  }
  if (feed.isProprietary) {
    return SOURCE_TYPES.PROPRIETARY
  }
  return SOURCE_TYPES.PUBLIC
}


function feedSourceTypeString(feed, orgType, appName, appNameFull) {
  switch (feedSourceType(feed)) {
    case SOURCE_TYPES.EXCLUDED:
      return `Excluded ${orgType} Wide`
    case SOURCE_TYPES.PROPRIETARY:
      return `${orgType} Only`
    case SOURCE_TYPES.PUBLIC:
      return `${appNameFull}`
  }
}
