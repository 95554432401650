import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Card.less'


export default class Card extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    mainText: PropTypes.string.isRequired,
    numerator: PropTypes.object,  // {name: ..., value: ..., description: ...}
    denominator: PropTypes.object,  // {name: ..., value: ..., description: ...}
    subText: PropTypes.string,
    backTitle: PropTypes.string,
    backValues: PropTypes.array,
  }

  render() {
    const {
      value,
      mainText,
      numerator,
      denominator,
      subText,
      backTitle,
      backValues,
    } = this.props

    let back = null

    if (numerator && denominator) {
      back =
        <div className="usage-card text-left usage-card-back">
          <div>
            <div className="equation">
              <div className="numerator">
                {numerator.name} ({numerator.value})
              </div>

              <div className="denominator">
                {denominator.name} ({denominator.value})
              </div>
            </div>

            <div className="answer">
              = {value}
            </div>
          </div>

          <div className="usage-description">
            <p>
              <strong>{numerator.name}</strong> {numerator.description}
            </p>

            <p>
              <strong>{denominator.name}</strong> {denominator.description}
            </p>
          </div>
        </div>
    }

    if (backTitle) {
      back =
        <div className="usage-card text-left usage-card-back">
          <h3 className="usage-card-back-header">{backTitle}</h3>

          {backValues.length > 0 &&
            <div>
              {backValues.map((v, index) => {
                return (
                  <div key={index} className="usage-card-back-value">{v}</div>
                )
              })}
            </div>
          }
        </div>
    }

    const front =
      <div className={classNames('usage-card', 'usage-card-front', {'has-back': !!back})}>
        <div className="value dark">
          {value}
        </div>

        {
          subText &&
          <div className="sub-text">
            {subText}
          </div>
        }

        <div className="main-text">
          {mainText}
        </div>
      </div>

    return (
      <div className="usage-card-container">
        {front}

        {back}
      </div>
    )
  }
}
