import {graphqlQuery} from 'app/api'
import {args, gql} from 'app/graphql'


export function fetchMembers(groupId) {
  return graphqlQuery(
    gql`
      query {
        users(${args({groupId, isActive: true, isPaged: false})}) {
          items{
            id
            firstName
            lastName
            username
          }
          totalCount
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function fetchUsers({nameFilter}) {
  return graphqlQuery(
    gql`
      query {
        users(${args({name: nameFilter, isPaged: false})}) {
          items{
            id
            firstName
            lastName
            username
          }
          totalCount
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function createMembership({groupId, userId, emailAddress}) {
  return graphqlQuery(
    gql`
      mutation {
        createMembership(${args({groupId, userId, emailAddress})}) {
          id
        }
      }
    `
  ).then(({body: {data}}) => data)
}

export function deleteMembership({groupId, userId}) {
  return graphqlQuery(
    gql`
      mutation {
        deleteMembership(${args({groupId, userId})}) {
          success
        }
      }
    `
  ).then(({body: {data}}) => data)
}
