import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'


/**
 * Standard, generic button component.
 *
 * @param {String} label - The text label
 * @param {Boolean} isPrimary - Applies the styling for a primary call to action button, such as "Save"
 * @param {Boolean} isDestructive - Applies the styling for a destructive button, such as "Delete"
 * @param {Boolean} isPlainText - Applies the styling for a plain text button, such as "Cancel"
 */
export default class Button extends Component {
  static propTypes = {
    label: PropTypes.any,
    isPrimary: PropTypes.bool,
    isDestructive: PropTypes.bool,
    isPlainText: PropTypes.bool,
    isSkinny: PropTypes.bool,
    isNarrow: PropTypes.bool,
    isSmall: PropTypes.bool,
    isGrey: PropTypes.bool,
    isDarkGrey: PropTypes.bool,
  }

  static defaultProps = {
    label: '',
    isPrimary: false,
    isDestructive: false,
    isPlainText: false,
    isSkinny: false,
    isNarrow: false,
    isSmall: false,
    isGrey: false,
    isDarkGrey: false,
  }

  render() {
    const {
      label,
      isPrimary,
      isDestructive,
      isPlainText,
      isSkinny,
      isNarrow,
      isSmall,
      isGrey,
      isDarkGrey,
      className,
      ...restProps
    } = this.props

    const disabled = restProps.disabled
    delete restProps.disabled

    if (disabled) {
      restProps.onClick = event => {
        event.preventDefault()
      }
    }

    return (
      <button
        className={classNames(
          'button',
          className,
          {
            primary: isPrimary,
            destructive: isDestructive,
            'plain-text': isPlainText,
            skinny: isSkinny,
            narrow: isNarrow,
            'small-text': isSmall,
            grey: isGrey,
            darkGrey: isDarkGrey,
            disabled,
          }
        )}
        {...restProps}
      >
        {label}
      </button>
    )
  }
}
