import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import * as flagging from 'app/flagging'
import {getEntities} from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import {User} from 'app/models'

import HeaderGeneric from './HeaderGeneric'


// Selectors
const getCurrentUserId = state => state.currentUser
const getJwt = state => state.jwt
const getOriginalUserIsStaff = state => state.originalUserIsStaff
const getIsGroupManager = state => state.isGroupManager
const getshouldHideInsightsLink = state => state.shouldHideInsightsLink
const getshouldHideMisLink = state => state.shouldHideMisLink
const getIsDiligent = state => state.isDiligent
const getInsightsUrl = state => state.insightsUrl
const getAdminConsoleRoot = state => state.adminConsoleRoot
const getFirmLogoWebHeader = state => state.firmLogoWebHeader

export default connect(
  createSelector(
    [
      getCurrentUserId,
      getEntities,
      flagging.selectors.getFlaggedArticleCount,
      getJwt,
      getOriginalUserIsStaff,
      getIsGroupManager,
      getshouldHideInsightsLink,
      getshouldHideMisLink,
      getIsDiligent,
      getInsightsUrl,
      getAdminConsoleRoot,
      getFirmLogoWebHeader,
    ],
    (
      currentUserId,
      entities,
      flaggedArticleCount,
      jwt,
      originalUserIsStaff,
      isGroupManager,
      shouldHideInsightsLink,
      shouldHideMisLink,
      isDiligent,
      rootInsightsUrl,
      adminConsoleRoot,
      firmLogoWebHeader,
      navBarOrigin
    ) => {
      const orm = Orm.withEntities(entities)
      const currentUser = orm.getById(User, currentUserId)
      return {
        isStaff: currentUser.isStaff,
        isFirmAdmin: currentUser.isFirmAdmin,
        isGroupManager,
        isGroup: currentUser.isGroup,
        isPortalGroup: currentUser.isPortalGroup,
        hasPublishV3Access: currentUser.hasPublishV3Access,
        displayName: currentUser.displayName,
        firmName: currentUser.firm.name,
        flaggedArticleCount,
        jwt,
        originalUserIsStaff,
        shouldHideInsightsLink,
        shouldHideMisLink,
        isDiligent,
        hasPublishAccess: currentUser.hasPublishAccess,
        publishAccessForNonadmins: currentUser.publishAccessForNonadmins,
        rootInsightsUrl,
        adminConsoleRoot,
        firmLogoWebHeader,
        navBarOrigin
      }
    }
  ),
)(HeaderGeneric)
