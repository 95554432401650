import {handleActions} from 'redux-actions'

import * as actions from './help-questions-actions'

const getInitialState = () => ({
  questionsByName: {},
})

export default handleActions(
  {
    [actions.addHelpQuestions]: (state, action) => {
      const questionsByName = {...state.questionsByName}
      for (const question of action.payload) {
        questionsByName[question.slug] = {
          statement: question.statement,
          statementText: question.statementText,
          changeUrl: question.changeUrl,
        }
      }
      return {...state, questionsByName}
    }
  },
  getInitialState(),
)
