import is from 'is'
import classNames from 'classnames'
import invariant from 'invariant'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import TextBox from 'app/common/TextBox'
import Option from './Option'
import './MultiSelectDropdown.less'
import { MULTISELECT_DROPDOWN as constants } from 'app/constants'


/*
 * Generic styled dropdown component
 */
export default class MultiSelectDropdown extends Component {
  static Option = Option

  static propTypes = {
    // Array of Option objects. Each object has one of the following
    // shapes:
    //     {label: String, value: any, isInvisible: Boolean?, level: int?} or
    //     {isGroup: true, label: String, options: [Option], level: int?}
    //
    // Options may also hold any other arbitrary attributes, which will
    // be preserved.
    options: PropTypes.arrayOf(PropTypes.object),

    // An optional function that takes in an Option and returns a
    // rendering of it. Overrides the default renderer.
    optionRenderer: PropTypes.func,

    // What to show as the label for the selected option.
    selectedOptionLabel:
      PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    // Called when an option is selected with the array of Options as an argument
    onSelect: PropTypes.func,

    // Same as above, but is passed the array of Options value
    onChange: PropTypes.func,

    // Called when the menu is opened or closed with a boolean argument
    // representing the open/close state.
    onMenuOpenChange: PropTypes.func,

    // The starting selected value
    defaultValue: PropTypes.any,

    // The currently selected options values
    values: PropTypes.any,

    showSelectAllOption: PropTypes.bool,
    dropUp: PropTypes.bool,

    disabled: PropTypes.bool,
    className: PropTypes.string,
    selectedOptionClassName: PropTypes.string,
    arrowClassName: PropTypes.string,
    menuClassName: PropTypes.string,
    showFilter: PropTypes.bool,

    //this is used to show what Dropdown contains
    label: PropTypes.string,
    isGrouped: PropTypes.bool,
    initiallyAllSelected: PropTypes.bool,
    noSelectionText: PropTypes.string,
  }

  static defaultProps = {
    options: [],
    showSelectAllOption: true,
    dropUp: false,
    disabled: false,
    showFilter: false,
    label: '',
    isGrouped: false,
    initiallyAllSelected: true,
  }

  constructor(props) {
    super(props)

    invariant(
      (props.options && props.options.length) || props.children,
      "A Dropdown must have at least one option.",
    )

    this.state = {
      showMenu: false,
      filterValue: '',
      selectedOptions: [],
      isAllOptionsSelected: false,
      hasRenderedFirstTime: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.initiallyAllSelected && !this.state.hasRenderedFirstTime && this.props.options.length > 0) {
      this.setState({ hasRenderedFirstTime: true })
      this.selectAllOptions(true)
      return
    }
    if (this.props.initiallyAllSelected && !this.state.hasRenderedFirstTime && this.props.children.length > 0) {
      this.setState({ hasRenderedFirstTime: true })
      this.selectAllOptions(true)
      return
    }
    if (this.props.values && this.props.values.length !== this.state.selectedOptions.length) {
      this.setState({ selectedOptions: this.props.values })
    }
    if (!this.props.onMenuOpenChange) return
    if (prevState.showMenu && !this.state.showMenu) {
      this.props.onMenuOpenChange(false)
    }
    if (!prevState.showMenu && this.state.showMenu) {
      this.props.onMenuOpenChange(true)
    }
  }

  // React methods

  render() {
    const {
      className,
      selectedOptionClassName,
      arrowClassName,
      selectedOptionLabel,
    } = this.props
    return (
      <div
        className={classNames(
          'multi-dropdown-component', className,
          { open: this.state.showMenu },
          { disabled: this.props.disabled }
        )}
        onClick={this.handleClick}
      >
        {(
          <div className={classNames('selected', selectedOptionClassName)}>
            {selectedOptionLabel || this.getDropdownLabel()}
          </div>
        )}
        <div className={classNames('arrow', arrowClassName)} />
        {this.renderMenu()}
      </div>
    )
  }

  // Render helpers

  renderMenu() {
    if (!this.state.showMenu) return null

    const options = this.options.map((option, idx) => {
      option = { ...option, uniqueKey: idx }
      return this.renderOption(option)
    })

    if (this.props.showFilter) {
      options.unshift(
        <div key="filter" className="option filter">
          <div className="label">Filter</div>
          <TextBox
            onChange={(event) => this.setState({ filterValue: event.target.value })}
          />
        </div>
      )
    }

    return (
      <div
        className={classNames(
          'options',
          this.props.menuClassName,
          { 'drop-up': this.props.dropUp },
        )}
      >
        {options}
      </div>
    )
  }

  renderOption = (option, { isGrouped = false } = {}) => {
    if (option.isInvisible) return null
    const active = this.isOptionSelected(option)
    const { isGroup } = option
    const filterValue = this.state.filterValue.toLowerCase()
    const level = option.level || 0
    let label;
    try {
      label = (typeof option.label === 'object' ? option.label.props.children : option.label).toLowerCase()
    } catch (e) {
      label = ''
    }

    const renderedOption =
      <div
        className={classNames(
          'option',
          {
            active,
            group: isGroup,
            // We add the 'grouped' class to options that are part of a group
            grouped: isGrouped,
          },
        )}
        style={{ paddingLeft: `calc(0.75em + 10px * ${level})` }}
        key={option.uniqueKey}
      >
        {this.renderOptionContents(option, isGroup)}
      </div>

    if (isGroup) {
      return [
        renderedOption,
        ...option.options.map((option, idx) => {
          option.uniqueKey = `group-${idx}`
          return this.renderOption(option)
        }),
      ]
    }

    if (isGroup || !filterValue || label.includes(filterValue)) {
      return renderedOption
    }

    return null
  }

  renderOptionContents(option, isGrouped = false) {
    if (this.props.optionRenderer) {
      return (
        <div onClick={e => this.selectOption(e, option)}>
          {this.props.optionRenderer(option)}
        </div>
      )
    }

    if (option.label) {
      const isChecked = option.value == constants.SELECT_ALL_ID ? this.state.isAllOptionsSelected : this.isOptionSelected(option)
      return (
        <div className="title">
          <div className='full-cover' onClick={(e) => this.selectOption(e, option)} />
          {/* {(isGrouped || option.value == constants.SELECT_ALL_ID) ? <> */}
          {!isGrouped ? <>
            <input
              type="checkbox"
              checked={isChecked}
            />
            &nbsp;&nbsp;
          </> : null}
          {option.label}
        </div>
      )
    } else {
      return (
        <div className="title" onClick={(e) => this.selectOption(e, option)}>&nbsp;</div>
      )
    }

  }

  // State management

  showMenu() {
    this.setState({ showMenu: true })
    document.addEventListener('click', this.handleGlobalClick)
  }

  hideMenu() {
    this.setState({ showMenu: false })
    document.removeEventListener('click', this.handleGlobalClick)
  }

  isOptionSelected(option) {
    // You can't select a group
    if (option.isGroup) return false

    if (option.value == constants.SELECT_ALL_ID)
      return this.state.isAllOptionsSelected
    else
      return this.state.selectedOptions.some(op => op.value === option.value)
  }

  checkAllOptionsSelected() {
    const selectedOps = this.state.selectedOptions.filter((op) => op.value !== constants.SELECT_ALL_ID)
    let totalOptions = this.props.isGrouped ? this.flatGroupedOptions.length : this.options.length
    if (this.props.showSelectAllOption) {
      totalOptions--
    }
    return totalOptions === selectedOps.length
  }

  getDropdownLabel() {
    const selectionCount = this.state.selectedOptions.length
    const allSelection = `${constants.ALL_TEXT} ${this.props.label}`
    const noSelection = this.props.noSelectionText ? this.props.noSelectionText : constants.NO_SELECTION_TEXT

    if (selectionCount == 0)
      return noSelection
    else if (this.checkAllOptionsSelected())
      return allSelection
    else if (selectionCount == 1)
      return this.state.selectedOptions[0].label
    else
      return `${selectionCount} ${this.props.label}  ${constants.SELECTED_TEXT}`
  }

  selectOption(e, option) {
    // Its required to use event.preventDefault method
    // To prevent the default scrolling behaviour when user selects an option from list
    e.preventDefault()
    // You can't select a group
    if (option.isGroup) return

    // if (typeof this.props.values === 'undefined') {
    if (option.value === constants.SELECT_ALL_ID) {
      this.selectAllOptions(!this.state.isAllOptionsSelected)
    }
    else {
      let updatedSelections = []
      if (this.state.selectedOptions.some(op => op.value == option.value)) {
        updatedSelections = [...this.state.selectedOptions.filter(op => op.value !== option.value)]
      }
      else {
        updatedSelections = [...this.state.selectedOptions, option]
      }

      this.setState({
        isAllOptionsSelected: this.checkAllOptionsSelected(),
        selectedOptions: updatedSelections,
      }, () => this.syncSelectAllOptions())
      this.handleSelectEvent(updatedSelections)
    }
    // }
  }

  selectAllOptions(checked, sendEvent = true) {
    let updatedSelections = []
    if (this.props.isGrouped) {
      updatedSelections = checked ? [...this.flatGroupedOptions] : []
    }
    else {
      updatedSelections = checked ? [...this.options] : []
    }

    this.setState({
      isAllOptionsSelected: checked,
      selectedOptions: updatedSelections
    }, () => this.syncSelectAllOptions())

    if (sendEvent)
      this.handleSelectEvent(updatedSelections)
  }

  syncSelectAllOptions() {
    let updatedSelections = []
    let checked = false
    if (this.checkAllOptionsSelected()) {
      updatedSelections = this.props.isGrouped ? this.flatGroupedOptions : this.options
      checked = true
    }
    else {
      updatedSelections = this.state.selectedOptions.filter((op) => op.value !== constants.SELECT_ALL_ID)
    }
    this.setState({
      isAllOptionsSelected: checked,
      selectedOptions: updatedSelections
    })
  }

  optionFromValue(value) {
    const findOption = (options) => {
      for (const option of options) {
        if (option.value === value) {
          return option
        }
        if (option.isGroup) {
          const subOption = findOption(option.options)
          if (is.defined(subOption)) {
            return subOption
          }
        }
      }
    }
    const option = findOption(this.options)
    invariant(
      option,
      `A value '${value}' was given to a Dropdown, but it has no options with that value.`
      + `\nValid option values are: (${this.options.map(option => `'${option.value}'`).join(', ')}).`,
    )
    return option
  }

  optionFromComponent(component) {
    return {
      label: component.props.label,
      value: component.props.value,
      isInvisible: component.props.isInvisible,
    }
  }

  getOptionsFromComponents(components) {
    return React.Children.toArray(components).map(component => {
      if (component.children) {
        return this.getOptionsFromComponents(component.children)
      }
      return this.optionFromComponent(component)
    })
  }

  get flatGroupedOptions() {
    let listOptions = []
    this.options.forEach(option => {
      if (option.options && option.options.length > 0) {
        listOptions = listOptions.concat(option.options)
      }
      else {
        listOptions.push(option)
      }
    })

    return listOptions
  }

  get options() {
    const selectAllOption = {
      key: constants.SELECT_ALL_ID,
      label: constants.SELECT_ALL_TEXT,
      value: constants.SELECT_ALL_ID
    }
    const optionsFromComponents = this.getOptionsFromComponents(this.props.children)

    const listOptions = ((this.props.options && this.props.options.length > 0) ? [...this.props.options] :
      optionsFromComponents && optionsFromComponents.length > 0 ? optionsFromComponents : [])
    if (this.props.showSelectAllOption && listOptions.length > 0) {
      listOptions.unshift(selectAllOption)
    }
    return listOptions
  }

  handleClick = (event) => {
    if (this.props.disabled) {
      return;
    }
    const { target } = event
    const rootNode = findDOMNode(this)
    const optionsNode = rootNode.getElementsByClassName('options')[0]
    // Only close the menu if we clicked the select box, not an option
    // (selecting an option closes the menu by itself)
    if (this.state.showMenu && !optionsNode.contains(target)) {
      this.hideMenu()
    } else if (!this.state.showMenu) {
      this.showMenu()
    }
  }

  handleGlobalClick = (e) => {
    // Hide the renderMenu if the user clicks outside of it
    let rootNode
    try {
      rootNode = findDOMNode(this)
    } catch (e) {
      // findDOMNode will error if the component has been unmounted, so
      // we just return early if that's the case
      return
    }
    if (!rootNode.contains(e.target)) {
      this.hideMenu()
    }
  }

  handleSelectEvent = (selectedOptions) => {
    let optionsToSend = selectedOptions.filter(op => op.value !== constants.SELECT_ALL_ID)

    if (this.props.onSelect) {
      this.props.onSelect(optionsToSend)
    }
    if (this.props.onChange) {
      this.props.onChange(optionsToSend.map(op => op.value))
    }
  }
}

export { Option }
