import React, {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as R from 'ramda'

import Button from 'app/common/Button'
import Dropdown from 'app/common/Dropdown'
import InputBlock from 'app/common/InputBlock'
import Modal from 'app/common/Modal'
import {TabbedContainer, Tab} from 'app/common/TabbedContainer'
import Table from 'app/common/Table'
import TextBox from 'app/common/TextBox'

import {
  hideEditLocationModal,
  setEditLocationModalData,
  saveLocation,
  addLocationLanguage,
  removeLocationLanguage,
} from '../firm-settings-actions'
import {
  getEditLocationModalData,
  getCountries,
  getTimezones,
  getLanguages,
  getFirmLocations,
} from '../firm-settings-selectors'
import {
  getCountryOptions,
  getTimezoneOptions,
  getLanguageOptions,
  getLanguageDisplayName,
} from '../firm-settings-utils'

import styles from './EditModal.less'

export default function EditModal() {
  const [newLanguageId, setNewLanguageId] = useState(null)
  const dispatch = useDispatch()
  const editLocationModalData = useSelector(getEditLocationModalData)
  const countries = useSelector(getCountries)
  const timezones = useSelector(getTimezones)
  const languages = useSelector(getLanguages)
  const locations = useSelector(getFirmLocations)
  const location = (locations || []).find(location => location.id === editLocationModalData.id)

  const countryOptions = getCountryOptions(countries)
  const timezoneOptions = getTimezoneOptions(timezones)
  const languageOptions = getLanguageOptions(languages)

  const updateData = (key, value) => {
    const data = {...editLocationModalData}
    data[key] = value
    dispatch(setEditLocationModalData(data))
  }

  const handleAddLanguage = () => {
    dispatch(addLocationLanguage({locationId: location.id, languageId: newLanguageId}))
    setNewLanguageId(null)
  }

  const handleRemoveLanguage = (languageId) => {
    dispatch(removeLocationLanguage({locationId: location.id, languageId}))
  }

  const languagesList = location && location.languages.length > 0
    ? <Table
        data={
          location.languages.map(language => {
            return {
              ...language,
              name: getLanguageDisplayName(language),
            }
          })
        }
      >
        <Table.Column
          name="name"
          label="Language"
          baseWidth="80%"
          cellContents={R.prop('name')}
        />

        <Table.Column
          name="actions"
          label=""
          baseWidth="20%"
          cellContents={language =>
            <span className="actions">
              <a
                className="action remove"
                onClick={() => handleRemoveLanguage(language.id)}
              >
                Delete
              </a>
            </span>
          }
        />
      </Table>
    : <h4>There are no assigned languages for this location.</h4>

  return (
    !!location &&
    <Modal
      isOpen={true}
      onClose={() => dispatch(hideEditLocationModal())}
    >
      <TabbedContainer>
        <Tab label="Location Details" name="details">
          <div className={styles.detailsContent}>
            <InputBlock label="Location Name">
              <TextBox
                maxLength="100"
                autoFocus={true}
                value={editLocationModalData.description}
                onChange={event => updateData('description', event.target.value)}
              />
            </InputBlock>

            <InputBlock label="Country">
              <Dropdown
                options={countryOptions}
                className="country-dropdown"
                value={editLocationModalData.countryId}
                onChange={(value) => updateData('countryId', value)}
              />
            </InputBlock>

            <InputBlock label="Timezone">
              <Dropdown
                options={timezoneOptions}
                className="timezone-dropdown"
                value={editLocationModalData.timezone}
                onChange={(value) => updateData('timezone', value)}
              />
            </InputBlock>
          </div>

          <div className="buttons">
            <Button
              label="Cancel"
              isPlainText
              onClick={() => dispatch(hideEditLocationModal())}
            />

            <Button
              label="Save"
              isPrimary
              disabled={!editLocationModalData.description}
              onClick={event => dispatch(saveLocation({...editLocationModalData}))}
            />
          </div>
        </Tab>

        <Tab label="Languages" name="languages">
          <div className={styles.languagesHeader}>
            <InputBlock label="" className={styles.languageDropdown}>
              <Dropdown
                options={languageOptions}
                value={newLanguageId}
                onChange={(value) => setNewLanguageId(value)}
              />
            </InputBlock>

            <Button
              label="Add Language"
              isPrimary
              disabled={!newLanguageId}
              onClick={handleAddLanguage}
            />
          </div>

          {languagesList}
        </Tab>
      </TabbedContainer>
    </Modal>
  )
}
