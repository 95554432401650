import React from 'react';
import * as overviewStyles from './OverviewEsg.less';
import {FactorHealthBadge} from '../../reusable/health/health-badge/factor';
import HealthRangeComparison from '../../reusable/health/health-range-comparison';
import PropTypes from 'prop-types';
import {SubfactorHealthBadge} from '../../reusable/health/health-badge/subfactor';

export default function OverviewEsg({
  healthData,
  selectedFactor,
  selectedSubfactor,
  onFactorClick,
  onSubfactorClick,
  hideHealthComparison,
}) {
  const healthBadge = selectedFactor ? (
    <SubfactorHealthBadge
      healthData={healthData}
      factor={selectedFactor}
      selectedSubfactor={selectedSubfactor}
      onSubfactorClick={onSubfactorClick}
      className={overviewStyles.healthBadge}
    />
  ) : (
    <FactorHealthBadge
      healthData={healthData}
      onFactorClick={onFactorClick}
      className={overviewStyles.healthBadge}
    />
  )

  return (
    <>
      <div className={overviewStyles.container}>
        <div className={overviewStyles.badgeContainer}>
          <div className={overviewStyles.overviewHealth}>{healthBadge}</div>
        </div>
        {!hideHealthComparison && (
          <div className={overviewStyles.range}>
            <HealthRangeComparison
              healthData={healthData}
              factor={selectedFactor}
              subfactor={selectedSubfactor}
              className={overviewStyles.healthRangeComparison}
            />
          </div>
        )}
      </div>
    </>
  )
}

OverviewEsg.propTypes = {
  healthData: PropTypes.object.isRequired,
}
