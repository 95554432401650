import { handleActions } from 'redux-actions'
import * as misActions from './mis-actions'

const getInitialState = () => ({
  currentUserData: {},
  headerDescription: '',
  sections: [],
  imageUrl: '',
  reports: {},

  // report ids for those we have recipients for
  reportsWithFetchedRecipients: [],

  currentUserReportIds: [],

  // key = report id, value = array of archives
  archives: {},

  // key = report id, value = array of recipients (id'ing by email address since id's change in MailChimp)
  recipients: {},

  isLoading: false,
  notificationMessage: null,
  notificationType: 'info', // success, error, info

  // renderedReports: key/value pair of id/html
  renderedReports: {},

  currentRenderedReport: null,
  currentReportId: null,
  currentRecipient: null,
  editedRecipient: null,
  currentOperation: null, // edit, delete
  newEmailAddress: null,
  newFirstName: null,
  newLastName: null,
  isSubscribeAllConfirmationModalShown: false,
})

export default handleActions(
  {
    [misActions.init]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [misActions.getReports]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [misActions.setReports]: (state, action) => ({
      ...state,
      isLoading: false,
      reports: action.payload,
    }),
    [misActions.addReportsWithFetchedRecipients]: (state, action) => ({
      ...state,
      isLoading: false,
      reportsWithFetchedRecipients: [...new Set([...state.reportsWithFetchedRecipients, ...action.payload])],
    }),
    [misActions.setCurrentUserReportIds]: (state, action) => ({
      ...state,
      isLoading: false,
      currentUserReportIds: action.payload,
    }),
    [misActions.getArchives]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [misActions.setArchives]: (state, action) => ({
      ...state,
      archives: {
        ...state.archives,
        ...action.payload,
      },
      isLoading: false,
    }),
    [misActions.getRecipients]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [misActions.setRecipients]: (state, action) => ({
      ...state,
      recipients: {
        ...state.recipients,
        ...action.payload,
      },
      isLoading: false,
    }),
    [misActions.getRenderedReport]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [misActions.downloadRenderedReportPDF]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [misActions.hideLoader]: (state, action) => ({
      ...state,
      isLoading: false,
    }),
    /**
     * setRenderedReportHtml and showRenderedReport are handled separately because once the report
     * has been fetched, we only need to show it on subsequent calls.
     */
    [misActions.setRenderedReportHtml]: (state, action) => ({
      ...state,
      renderedReports: {
        ...state.renderedReports,
        [action.payload.id]: action.payload.html.trim(),
      }
    }),
    [misActions.showRenderedReport]: (state, action) => ({
      ...state,
      isLoading: false,
      currentRenderedReport: action.payload,
    }),
    [misActions.hideRenderedReport]: (state, action) => ({
      ...state,
      currentRenderedReport: null,
    }),
    [misActions.showNotification]: (state, action) => ({
      ...state,
      isLoading: false,
      currentRenderedReport: null,
      notificationMessage: action.payload.message,
      notificationType: action.payload.type,
    }),
    [misActions.hideNotification]: (state, action) => ({
      ...state,
      notificationMessage: null,
      notificationType: 'info',
    }),
    [misActions.setCurrentReportId]: (state, action) => ({
      ...state,
      currentReportId: action.payload,
    }),
    [misActions.setNewFirstName]: (state, action) => ({
      ...state,
      newFirstName: action.payload,
    }),
    [misActions.setNewLastName]: (state, action) => ({
      ...state,
      newLastName: action.payload,
    }),
    [misActions.setNewEmailAddress]: (state, action) => ({
      ...state,
      newEmailAddress: action.payload,
    }),
    [misActions.addRecipient]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [misActions.addRecipientSuccess]: (state, action) => {
      const {reportId, recipient} = action.payload
      const reportRecipients = [
        ...(state.recipients[reportId] || []),
        {...recipient},
      ]
      return ({
        ...state,
        isLoading: false,
        newFirstName: null,
        newLastName: null,
        newEmailAddress: null,
        recipients: {
          ...state.recipients,
          [reportId]: reportRecipients,
        }
      })
    },
    [misActions.setCurrentRecipient]: (state, action) => ({
      ...state,
      currentRecipient: {...action.payload.recipient},
      currentOperation: action.payload.operation,
      editedRecipient: {...action.payload.recipient},
    }),
    [misActions.setEditedRecipient]: (state, action) => ({
      ...state,
      editedRecipient: {...action.payload},
    }),
    [misActions.saveRecipient]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [misActions.saveRecipientSuccess]: (state, action) => {
      const { reportId, oldEmailAddress, recipient } = action.payload
      const reportRecipients = [
        ...(state.recipients[reportId] || []),
      ].filter(r => r.emailAddress !== oldEmailAddress)
      reportRecipients.push({...recipient})
      return ({
        ...state,
        isLoading: false,
        currentRecipient: null,
        currentOperation: null,
        // creates can result in updates if the recipient already existed, so reset the create values.
        newFirstName: null,
        newLastName: null,
        newEmailAddress: null,
        recipients: {
          ...state.recipients,
          [reportId]: reportRecipients,
        }
      })
    },
    [misActions.deleteRecipient]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [misActions.deleteRecipientSuccess]: (state, action) => {
      const { reportId, recipient } = action.payload
      const reportRecipients = [
        ...(state.recipients[reportId] || []),
      ].filter(r => r.emailAddress !== recipient.emailAddress)
      const newState = {
        ...state,
        isLoading: false,
        currentRecipient: null,
        currentOperation: null,
        recipients: {
          ...state.recipients,
          [reportId]: reportRecipients,
        }
      }
      return (newState)
    },
    [misActions.saveAutoSubscribe]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [misActions.setAutoSubscribe]: (state, action) => {
      const { id, isAutoSubscribe } = action.payload
      const report = {...state.reports[id], isAutoSubscribe: isAutoSubscribe}
      return ({
        ...state,
        isLoading: false,
        reports: {
          ...state.reports,
          [id]: report,
        },
      })
    },
    [misActions.showSubscribeAllConfirmationModal]: (state, action) => ({
      ...state,
      isSubscribeAllConfirmationModalShown: true,
    }),
    [misActions.hideSubscribeAllConfirmationModal]: (state, action) => ({
      ...state,
      isSubscribeAllConfirmationModalShown: false,
    }),
    [misActions.subscribeAllUsers]: (state, action) => ({
      ...state,
      isSubscribeAllConfirmationModalShown: false,
    }),
  },
  getInitialState(),
)
