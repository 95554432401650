import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as R from 'ramda'

import Table from 'app/common/Table'

import {
  addSelectedLocationId,
  removeSelectedLocationId,
  setEditLocationModalData,
  updateAssignModalData,
  setSelectedLocationIds,
  showDeleteLocationModal,
} from '../firm-settings-actions'
import {
  getFirmLocations,
  getSelectedLocationIds,
  getCountries,
} from '../firm-settings-selectors'


export default function LocationsTable() {
  const dispatch = useDispatch()
  const firmLocations = useSelector(getFirmLocations)
  const selectedLocationIds = useSelector(getSelectedLocationIds)
  const countries = useSelector(getCountries)

  const handleLocationSelection = (event, location) => {
    if (event.target.checked) {
      dispatch(addSelectedLocationId(location.id))
    } else {
      dispatch(removeSelectedLocationId(location.id))
    }
  }

  const handleEditLocation = (location) => {
    dispatch(setEditLocationModalData({...location}))
  }

  const showAssignModal = (location) => {
    dispatch(updateAssignModalData({id: location.id, description: location.description}))
  }

  const handleRemoveLocation = (location) => {
    dispatch(setSelectedLocationIds([location.id]))
    dispatch(showDeleteLocationModal())
  }

  const getCountryNameFromId = (id) => {
    if (id) {
      return countries.find(c => c.id === id).name
    } else {
      return '--'
    }
  }

  return (
    <div className="firm-locations-table-container">
      <Table
        data={firmLocations || []}
        defaultSort={{column: 'description', direction: 'asc'}}
      >
        <Table.Column
          name="checkbox"
          baseWidth={45}
          growRatio={0}
          shrinkRatio={0}
          cellContents={location =>
            <input
              className="checkbox"
              type="checkbox"
              onChange={event => handleLocationSelection(event, location)}
              checked={selectedLocationIds.includes(location.id)}
            />
          }
        />

        <Table.Column
          name="description"
          label="Location Name"
          isSortable
          baseWidth={160}
          growRatio={1}
          className="location-description"
          cellContents={location =>
            <a onClick={() => handleEditLocation(location)}>{location.description}</a>
          }
        />

        <Table.Column
          name="country"
          label="Country"
          isSortable
          baseWidth={100}
          growRatio={1}
          cellContents={location => getCountryNameFromId(location.countryId)}
        />

        <Table.Column
          name="timezone"
          label="Timezone"
          isSortable
          baseWidth={100}
          growRatio={1}
          cellContents={R.prop('timezone')}
        />

        <Table.Column
          name="users"
          label="Number of Users"
          isSortable
          baseWidth={50}
          growRatio={1}
          className="users"
          cellContents={location => location.users.length}
        />

        <Table.Column
          name="actions"
          label=""
          baseWidth={150}
          growRatio={1}
          cellContents={location =>
            <span className="actions">
              <a
                className="action edit"
                onClick={() => handleEditLocation(location)}
              >
                Edit
              </a>
              <a
                className="action assign"
                onClick={() => showAssignModal(location)}
              >
                Assign
              </a>
              <a
                className="action remove"
                onClick={() => handleRemoveLocation(location)}
              >
                Delete
              </a>
            </span>
          }
        />
      </Table>
    </div>
  )
}
