import classNames from 'classnames'
import is from 'is'
import PropTypes from 'prop-types'
import {filter, fromPairs, map, pipe, sum} from 'ramda'
import React, {useEffect, useState} from 'react'
import {Portal} from 'react-portal'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import Button from 'app/common/Button'
import InlineSvg from 'app/common/InlineSvg'
import LoadingSpinner from 'app/common/LoadingSpinner'
import TextSeparator from 'app/common/text-separator'
import Tooltip from 'app/common/Tooltip'
import ConfirmationModal from 'app/common/modals/ConfirmationModal'
import TabbedContainer, {Tab} from 'app/common/TabbedContainer'
import * as entitiesActions from 'app/entities/entities-actions'
import * as entitiesSelectors from 'app/entities/entities-selectors'
import Orm from 'app/framework/Orm'
import * as globalSelectors from 'app/global/global-selectors'
import * as notifications from 'app/global/notifications'
import {Document, Feed, SavedSearch} from 'app/models'
import EditSearchSettingsModal from 'app/reusable/EditSearchSettingsModal'
import EditSearchFiltersModal from 'app/reusable/EditSearchModal'
import FlagModal from 'app/reusable/FlagModal'
import { RELEVANCE_LEVEL_LOOKUP } from 'app/reusable/RelevanceFilter/SearchRelevanceFilterConstants'
import * as cglyticsActions from 'app/search-results/cglytics-teaser/cglytics-teaser-actions'
import DunAndBradstreetModal from 'app/search-results/dun-and-bradstreet/Modal'
import {searchCategory} from 'app/strings'
import * as urls from 'app/urls'
import {isLeftClickEvent} from 'app/utils'
import {doFiltersMatch} from 'app/utils/searches'

import SearchFullQuery from './meta/SearchFullQuery'
import SearchQueryComponents from './meta/SearchQueryComponents'
import SearchQueryLoading from './meta/SearchQueryLoading'
import SearchResultsContent from './tabs/SearchResultsContent'
import ChartsAndTrends from './charts-and-trends'
import NewSearchModal from './new-search-modal'
import SaveToFirmLibraryModal from './save-to-firm-library-modal'
import {SaveSearchButton} from './save-search-button'
import SearchQuery from './search-query'
import * as actions from './search-results-page-actions'
import * as constants from './search-results-page-constants'
import * as selectors from './search-results-page-selectors'
import * as searchesAdminSelectors from 'app/firm-admin/searches/searches-admin-selectors'
import * as strings from './search-results-page-strings'
import * as utils from './search-results-page-utils'

import * as styles from './SearchResultsPage.less'
import * as loadingStyles from './SearchResultsPageLoading.less'
import {urlStringToLocation} from 'app/global/routing/routing-utils'
import {RSS_FACETS, RSS_QUERY_PARAMS} from "./search-results-page-constants"
import EsgInsights from "./esg-insights/EsgInsights"
import * as routing from '../global/routing'

// Load more results if we're within this many pixels of the end of the page.
const INFINITE_SCROLL_THRESHOLD = 150
// The distance you need to scroll before the "Back to Top" button appears.
const BACK_TO_TOP_SCROLL_THRESHOLD = 700


function IndicatorTooltip({children, ...props}) {
  return (
    <Tooltip
      className={styles.indicatorTooltip}
      containerClassName={styles.indicatorContainer}
      direction="top-left"
      {...props}
    >
      {children}
    </Tooltip>
  )
}

function InsightsIndicator({appName, insightsLabel}) {
  const imageSource = appName === 'Diligent'
    ? "/media/img/diligent-insights-icon.svg"
    : "/media/img/signals-a-icon.svg"
  return (
    <IndicatorTooltip
      label={`This company is being analyzed by ${appName} ${insightsLabel}.`}
    >
      <InlineSvg
        src={imageSource}
        className={classNames(
          styles.indicator,
          styles.insights,
          'insights-indicator-icon', // for Pendo
        )}
      />
    </IndicatorTooltip>
  )
}

function DnbIndicatorRaw({search, showModal}) {
  const {dnbData} = search
  const onClick = event => {
    if (isLeftClickEvent(event)) {
      showModal()
    }
  }
  return (
    <IndicatorTooltip
      label={`${dnbData.familyCount} ${dnbData.familyCount > 1 ? dnbData.associationPlural : dnbData.association} detected with D&B Data. Click here for ${dnbData.association} information.`}
    >
      <InlineSvg
        src="/media/img/subsidiaries-icon-black.svg"
        onClick={onClick}
        className={classNames(
          styles.indicator,
          styles.dnb,
          'dnb-button', // for Pendo
        )}
      />
    </IndicatorTooltip>
  )
}
const DnbIndicator = connect(
  null,
  {showModal: actions.showDnbModal},
)(DnbIndicatorRaw)

function FirmLibraryIndicator() {
  return (
    <IndicatorTooltip
      label="This search has been created and is curated by your administrator."
    >
      <InlineSvg
        src="/media/img/firm-library-icon.svg"
        className={classNames(
          styles.indicator,
          styles.firmLibrary,
          'firm-library-indicator-icon', // for Pendo
        )}
      />
    </IndicatorTooltip>
  )
}

class CglyticsIndicatorRaw extends React.PureComponent {
  static propTypes = {
    showModal: PropTypes.func.isRequired,
  }

  render() {
    return (
      <IndicatorTooltip label="Governance Analytics — Click to learn more">
        <span
          onClick={this.handleClick}
          className={classNames(styles.indicator, styles.cglytics)}
        />
      </IndicatorTooltip>
    )
  }

  handleClick = event => {
    if (isLeftClickEvent(event)) {
      this.props.showModal()
    }
  }
}
const CglyticsIndicator = connect(
  null,
  {showModal: cglyticsActions.showModal},
)(CglyticsIndicatorRaw)

function Indicators({search, appName, insightsLabel}) {
  return (
    <div className={styles.indicators}>
      {search.isInsightsEnabled &&
        <InsightsIndicator appName={appName} insightsLabel={insightsLabel} />
      }
      {search.dnbData && <DnbIndicator search={search} />}
      {(search.isFirmLibrary || (search.isFirmLibraryChild && !search.isSaved)) &&
        <FirmLibraryIndicator />
      }
      {['client', 'prospect'].includes(search.category) &&
        <CglyticsIndicator />
      }
    </div>
  )
}

function RssSubscribe({search}) {
  const id = search.id
  const slug = search.slug
  const queryObj = urlStringToLocation(window.location.search)
  const queryParams = {}
  if (queryObj && queryObj.query) {
    Object.entries(queryObj.query).forEach(([key, val]) => {
      const newKey = RSS_QUERY_PARAMS[key] || key
      let newValue = val
      if (newKey === 'facet') {
        newValue = RSS_FACETS[val] || val
      }
      if (newKey !== 'exclude_param') {
        queryParams[newKey] = newValue
      }
    })
  }
  return (
    <a
      href={urls.searchRssFeed({id, slug, queryParams})}
      className="subscribe-rss" // for Pendo
    >
      Subscribe via RSS
    </a>
  )
}
RssSubscribe.propTypes = {
  search: PropTypes.object.isRequired,
}

function SearchDetailsButtons({
  isLoading,
  isSaving,
  isChangingTrustedState,
  search,
  isFeed,
  relevancyLevel,
  hasUnsavedChanges,
  saveSearch,
  showSaveIntoFirmLibraryModal,
  currentUserIsClientAdmin,
  currentFirmLibraryName,
  showEditSearchFiltersModal,
  addToTrustedSources,
  removeFromTrustedSources,
}) {
  let saveButton
  if (utils.isSource(search) && search.category !== 'trusted') {
    if (isFeed) {
      saveButton = (
        <Button
          label={
            isChangingTrustedState
              ? 'Adding to Trusted Sources...'
              : 'Add to Trusted Sources'
          }
          isPrimary={true}
          disabled={isChangingTrustedState}
          onClick={() => addToTrustedSources()}
          className="add-source-button" // for Pendo
        />
      )
    }
    else {
      saveButton = (
        <Button
          label={
            isChangingTrustedState
              ? 'Removing from Trusted Sources...'
              : 'Remove from Trusted Sources'
          }
          isDestructive={true}
          disabled={isChangingTrustedState}
          onClick={() => removeFromTrustedSources()}
          className="remove-source-button" // for Pendo
        />
      )
    }
  }
  else {
    saveButton = (
      <SaveSearchButton
        search={search}
        isSaving={isSaving}
        hasUnsavedChanges={hasUnsavedChanges}
        onClick={(value) => value === 'firm-library' ? showSaveIntoFirmLibraryModal() : saveSearch({asNew: value === 'new'})}
        includeFirmLibraryOption={currentUserIsClientAdmin}
        currentFirmLibraryName={currentFirmLibraryName}
      />
    )
  }
  if (isLoading) {
    return null
  }
  return (
    <div className={styles.buttonsContainer}>
      <div className={styles.buttons}>
        <Button
          label="Refine Search"
          onClick={() => showEditSearchFiltersModal()}
          className="filters-additional-button" // for Pendo
        />
        {saveButton}
      </div>
      {utils.isSaved(search) && (
        <div className={styles.rssSubscribe}>
          <RssSubscribe
            search={search}
          />
        </div>
      )}
    </div>
  )
}


function MetaLinks(
  {
    search,
    hasRawQueryAccess,
    onQueryToggle,
    onFullQueryToggle,
  },
) {
  if (!(hasRawQueryAccess || search.adminEditUrl)) {
    return null
  }
  return (
    <div className={styles.metaLinks}>
      {hasRawQueryAccess && !constants.TRUSTED_SOURCE_CATEGORY_TYPES.includes(search.category) && (
        <a
          onClick={onQueryToggle}
          className="show-query-toggle" // for Pendo
        >
          Query
        </a>
      )}
      {hasRawQueryAccess && search.fqParams && (
        <a
          onClick={onFullQueryToggle}
          className="show-full-query-toggle" // for Pendo
        >
          Full Query
        </a>
      )}
      {search.adminEditUrl && (
        <a href={search.adminEditUrl}>Edit in Admin</a>
      )}
    </div>
  )
}
MetaLinks.propTypes = {
  search: PropTypes.object.isRequired,
  hasRawQueryAccess: PropTypes.bool.isRequired,
  onQueryToggle: PropTypes.func.isRequired,
  onFullQueryToggle: PropTypes.func.isRequired,
}


function SearchSettings({
  isLoading,
  search,
  currentFirm,
  showEditSearchSettingsModal,
}) {
  return (
    <div
      className={classNames(
        styles.searchSettings,
        'search-settings', // for Pendo
      )}
    >
      <div className={styles.summary}>
        {
          // This is conditional so that it doesn't break when a search is first
          // loading and we don't have a category yet.
          search.category && (
          `${searchCategory(search.category, {currentFirm})} Search`
        )}
        {search.noticeConfig && (
          <>
            <TextSeparator />
            {search.noticeConfig.frequency === 'none'
              ? 'No Email Alert'
              : `Sent ${search.noticeConfig.frequencyDisplay}`
            }
          </>
        )}
      </div>
      {
        !isLoading &&
        <a
          onClick={() => showEditSearchSettingsModal()}
          className={styles.settingsLink}
        >
          Edit Search Settings
        </a>
      }
    </div>
  )
}
SearchSettings.propTypes = {
  isLoading: PropTypes.bool,
  search: PropTypes.object.isRequired,
  currentFirm: PropTypes.object.isRequired,
  showEditSearchSettingsModal: PropTypes.func.isRequired,
}


function BackToTopLink() {
  const [shouldShowBackToTop, setShouldShowBackToTop] = useState(false)
  const onScroll = event => {
    const el = event.target
    if (!shouldShowBackToTop && el.scrollTop > BACK_TO_TOP_SCROLL_THRESHOLD) {
      setShouldShowBackToTop(true)
    }
    else if (
      shouldShowBackToTop
      && el.scrollTop <= BACK_TO_TOP_SCROLL_THRESHOLD
    ) {
      setShouldShowBackToTop(false)
    }
  }
  useEffect(
    () => {
      document
        .getElementById('body')
        .addEventListener('scroll', onScroll)
      return () => {
        document
          .getElementById('body')
          .removeEventListener('scroll', onScroll)
      }
    },
    [shouldShowBackToTop],
  )
  const goToTop = () => {
    document.getElementById('body').scrollTo(0, 0)
  }
  return (
    <Portal>
      <div
        className={classNames(
          styles.backToTop,
          {[styles.visible]: shouldShowBackToTop},
        )}
        onClick={goToTop}
      >
        Back to Top
      </div>
    </Portal>
  )
}


class SearchResultsPage extends React.Component {
  componentDidMount() {
    document
      .getElementById('body')
      .addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    document
      .getElementById('body')
      .removeEventListener('scroll', this.handleScroll)
  }

  render() {
    const {
      isLoading,
      isSaving,
      isSavingSearchSettings,
      isChangingTrustedState,

      feedId,
      search,
      topLevelDocuments,
      groupedDocuments,
      publicationCounts,
      compareTopLevelDocuments,
      compareGroupedDocuments,
      compareCountIsGreater,
      comparePublicationCounts,
      relevancyLevel,
      groupingLevel,
      insightsArticlesFirst,
      upcomingEvents,
      isRefreshingResults,
      activeFilters,
      hasUnsavedChanges,
      activePublicationTab,
      activePublicationType,
      selectedDocumentIds,
      isEditSearchSettingsModalOpen,
      isEditSearchFiltersModalOpen,
      editModalDefaultTab,
      isLoadingNextPage,
      isFiltersBarOpen,
      collapsedFilterSections,
      toggleFilterSectionDisplay,
      trending,
      flagModal,
      isDeleteModalOpen,
      shouldShowQueryComponents,
      shouldShowFullQuery,
      chartsAndTrends,
      appName,
      insightsLabel,
      hasRawQueryAccess,
      shouldShowDnbModal,
      queryComponentState,
      saveData,
      newSearchModalData,
      currentFirm,
      currentFirmLibraryName,
      currentUserIsFirmLibraryGroup,
      currentUserIsClientAdmin,
      shouldShowSaveIntoFirmLibraryModal,
      hasFetchedRelevanceIds,
      canShowRelevanceFilter,
      selectAllState,
      cachedLargerTimeFrames,
      userDefaultEmailSettings,
      esgData,
      resultsPerPage,

      // Actions
      setResultsPerPage,
      showEditSearchSettingsModal,
      hideEditSearchSettingsModal,
      showEditSearchFiltersModal,
      hideEditSearchFiltersModal,
      hideNewSearchModal,
      hideSaveIntoFirmLibraryModal,
      addToTrustedSources,
      removeFromTrustedSources,
      setRelevancyLevel,
      setGroupingLevel,
      setSelectedLanguageIds,
      setSortOption,
      setCompareId,
      setInsightsArticlesFirst,
      setUpcomingEvents,
      setTimeFrame,
      setActivePublicationTab,
      setActivePublicationType,
      hideFeed,
      selectDocumentId,
      deselectDocumentId,
      selectAllTopLevelDocuments,
      selectAllDocuments,
      deselectAllDocuments,
      exportPdf,
      exportDocx,
      exportExcel,
      exportEmail,
      showFlagModal,
      hideFlagModal,
      showDeleteModal,
      hideDeleteModal,
      deleteSelectedDocuments,
      toggleShowQueryComponents,
      toggleShowFullQuery,
      updateDocuments,
      updateFeeds,
      saveSearchSettings,
      hideDnbModal,
      saveSearch,
      saveNewSearch,
      showSaveIntoFirmLibraryModal,
      addFilters,
      setQueryComponentState,
      setSaveData,
      removeExcludedFeedsFromSearch,
    } = this.props
    const sortOption =
      this.props.sortOption
      || constants.REVERSE_SORT_OPTIONS[search.resultsOrder]
    const timeFrame = this.props.timeFrame || search.duration
    const languageFilters = this.props.languageFilters || search.languageFilters

    const pubTabs = (counts, column, isCompareSearch) =>
      constants.PUBLICATION_TABS_ORDERED
        // First calculate the values we're interested in
        .map(pubTypeTab => {
          const publicationTypes = Feed.PUBLICATION_TYPES_BY_CATEGORY[pubTypeTab]
          return {
            pubTypeTab,
            publicationTypes,
            count: pipe(
              filter(pubCount => publicationTypes.includes(pubCount.name)),
              map(pubCount => pubCount.count),
              sum(),
            )(counts),
          }
        })
        // Remove any that don't have results, and remove all other tabs
        // if the search is a trusted source.
        .filter(({pubTypeTab, count}) =>
            // If this is not a source, we should always show "News".
          (
            pubTypeTab === Feed.PUBLICATION_TYPE_CATEGORIES.NEWS
            && !utils.isSource(search)
          )
          || pubTypeTab === activePublicationTab
          || (count > 0 && (
            // If it's a source, don't show the "My Sources" tab.
            !utils.isSource(search)
            || pubTypeTab !== Feed.PUBLICATION_TYPE_CATEGORIES.MY_SOURCES
          ))
        )
        .map(({pubTypeTab, publicationTypes, count}) =>
          <Tab
            label={
              isCompareSearch
                ? `COMP ${strings.publicationTabLabel(pubTypeTab)}${isRefreshingResults ? '' : ` (${count})`}`
                : `${strings.publicationTabLabel(pubTypeTab)}${isRefreshingResults ? '' : ` (${count})`}`
            }
            name={pubTypeTab}
            key={`${pubTypeTab}-${column}`}
            count={count}
          >
            {() => (
              <SearchResultsContent
                isLoading={isLoading}
                isSaving={isSaving}
                search={search}
                topLevelDocuments={topLevelDocuments}
                groupedDocuments={groupedDocuments}
                compareTopLevelDocuments={compareTopLevelDocuments}
                compareGroupedDocuments={compareGroupedDocuments}
                compareCountIsGreater={compareCountIsGreater}
                shouldShowRelevanceFilter={search.shouldShowRelevanceFilter}
                sortOption={sortOption}
                insightsArticlesFirst={insightsArticlesFirst}
                upcomingEvents={upcomingEvents}
                isRefreshingResults={isRefreshingResults}
                isFiltersBarOpen={isFiltersBarOpen}
                collapsedFilterSections={collapsedFilterSections}
                toggleFilterSectionDisplay={toggleFilterSectionDisplay}
                toggleFiltersDisplay={this.handleToggleFiltersDisplay}
                viewAdvancedFilters={this.handleViewAdvancedFilters}
                activeFilters={activeFilters}
                hasUnsavedChanges={hasUnsavedChanges}
                addFilter={filter => addFilters([filter])}
                removeFilter={this.handleRemoveFilter}
                saveSearch={this.props.saveSearch}
                relevancyLevel={relevancyLevel}
                groupingLevel={groupingLevel}
                languageFilters={languageFilters}
                setRelevancyLevel={setRelevancyLevel}
                setGroupingLevel={setGroupingLevel}
                setSelectedLanguageIds={setSelectedLanguageIds}
                timeFrame={timeFrame}
                setTimeFrame={setTimeFrame}
                trending={trending}
                toggleTrendingDisplay={this.handleToggleTrendingDisplay}
                applyTrendingTerm={this.handleApplyTrendingTerm}
                publicationTypeInfos={
                  publicationTypes.map(pubType => ({
                    key: pubType,
                    label: isCompareSearch
                      ? `COMP ${strings.publicationTypeLabel(pubType)}`
                      : `${strings.publicationTypeLabel(pubType)}`,
                    count: isRefreshingResults ? null : (
                      counts
                        .find(pubCount => pubCount.name === pubType)
                      || {count: 0} // default to 0
                    ).count,
                  }))
                }
                setCompareId={setCompareId}
                activePublicationTab={activePublicationTab}
                activePublicationType={activePublicationType}
                selectedDocumentIds={selectedDocumentIds}
                hasFetchedRelevanceIds={hasFetchedRelevanceIds}
                canShowRelevanceFilter={canShowRelevanceFilter}
                selectAllState={selectAllState}
                isLoadingNextPage={isLoadingNextPage}
                areAllResultsDisplayed={this.areAllResultsDisplayed()}
                insightsLabel={insightsLabel}
                setSortOption={setSortOption}
                setInsightsArticlesFirst={setInsightsArticlesFirst}
                setUpcomingEvents={setUpcomingEvents}
                setActivePublicationType={setActivePublicationType}
                hideFeed={hideFeed}
                selectDocumentId={selectDocumentId}
                deselectDocumentId={deselectDocumentId}
                selectAllTopLevelDocuments={selectAllTopLevelDocuments}
                selectAllDocuments={selectAllDocuments}
                deselectAllDocuments={deselectAllDocuments}
                exportPdf={exportPdf}
                exportDocx={exportDocx}
                exportExcel={exportExcel}
                exportEmail={exportEmail}
                showFlagModal={showFlagModal}
                showDeleteModal={showDeleteModal}
                updateDocuments={updateDocuments}
                updateFeeds={updateFeeds}
                setResultsPerPage={setResultsPerPage}
                resultsPerPage={resultsPerPage}
              />
            )}
          </Tab>
        )

    const {companyScores} = esgData
    const siteSection = urls.tier3CategoryFromPathname(this.props.currentPathname)
    // Hide Esg tab unless there is data and we are on the company page
    const hideEsgTab = (companyScores === null) || (siteSection !== 'client' && siteSection !== null)
    let saveSearchId = search.query ? search.query.split(':')[1] : search.id
    if (saveSearchId && typeof saveSearchId == "string") {
      if (saveSearchId.includes('~|')) {
        saveSearchId = saveSearchId.split('~')[0]
      }
    }
    return (
      <div
        className={classNames(
          styles.searchResultsPage,
          loadingStyles.skeleton,
          'search-results-page', // for Pendo
        )}
      >
        <div className={styles.header}>
          <h1 className={classNames(styles.heading, loadingStyles.heading)}>
            <span>
              {utils.isSaved(search) || !!feedId
                ? `${search.name} News`
                : 'Search Results'
              }
            </span>
            {isLoading && <LoadingSpinner className={loadingStyles.spinner} />}
            <Indicators
              search={search}
              appName={appName}
              insightsLabel={insightsLabel}
            />
            <MetaLinks
              search={search}
              hasRawQueryAccess={hasRawQueryAccess}
              onQueryToggle={toggleShowQueryComponents}
              onFullQueryToggle={toggleShowFullQuery}
            />
          </h1>

          {utils.isSaved(search) && (
            <SearchSettings
              isLoading={isLoading}
              search={search}
              currentFirm={currentFirm}
              showEditSearchSettingsModal={showEditSearchSettingsModal}
            />
          )}
        </div>

        {is.string(search.validationErrorMessage) && (
          <div className={styles.queryValidationError}>
            <div className={styles.inner}>
              <p>
                The boolean query for this search is invalid. Please{' '}
                <a onClick={() => showEditSearchFiltersModal()}>edit the search</a>{' '}
                to fix the query.
              </p>
              {search.validationErrorMessage && (
                <p>Full error message: {search.validationErrorMessage}.</p>
              )}
            </div>
          </div>
        )}

        {shouldShowQueryComponents && (
          // The query components are loaded async, so if the data isn't
          // present, we assume that it is loading.
          search.queryComponents ? (
            <SearchQueryComponents
              baseQuery={search.queryComponents.baseQuery}
              andComponents={search.queryComponents.andComponents}
              orComponents={search.queryComponents.orComponents}
            />
          ) : (
            <SearchQueryLoading />
          )
        )}
        {shouldShowFullQuery && (
          search.fullQuery ? (
            <SearchFullQuery
              query={search.fullQuery}
              fqParams={search.fqParams}
            />
          ) : (
            <SearchQueryLoading />
          )
        )}

        <div className={classNames(
          styles.searchDetails,
          'search-details', // for Pendo
        )}>
          <div className={styles.searchDetailsInner}>
            <SearchQuery search={search} isLoading={isLoading} />
            <SearchDetailsButtons
              isLoading={isLoading}
              isSaving={isSaving}
              isChangingTrustedState={isChangingTrustedState}
              search={search}
              isFeed={!!feedId}
              relevancyLevel={relevancyLevel}
              hasUnsavedChanges={hasUnsavedChanges}
              currentUserIsClientAdmin={currentUserIsClientAdmin}
              currentFirmLibraryName={currentFirmLibraryName}
              saveSearch={saveSearch}
              showSaveIntoFirmLibraryModal={showSaveIntoFirmLibraryModal}
              showEditSearchFiltersModal={showEditSearchFiltersModal}
              showEditSearchSettingsModal={showEditSearchSettingsModal}
              addToTrustedSources={addToTrustedSources}
              removeFromTrustedSources={removeFromTrustedSources}
            />
          </div>
        </div>

        <div className={styles.mainContainer}>
          <TabbedContainer
            tabName={activePublicationTab}
            onTabChange={(tabName) => tabName === 'esg' ? window.open(`/esg/company/${saveSearchId}`, '_blank')
              : setActivePublicationTab(tabName)}
            className={styles.tabContainer}
            tabsClassName={styles.tabs}
            contentClassName={styles.content}
            useUpdatedCss
          >
            { compareCountIsGreater && comparePublicationCounts.length > 0
              ? pubTabs(comparePublicationCounts, '1', true)
              : pubTabs(publicationCounts, '1', false)
            }
            { !compareCountIsGreater && comparePublicationCounts.length > 0
              ? pubTabs(comparePublicationCounts, '2', true)
              : comparePublicationCounts.length > 0
                ? pubTabs(publicationCounts, '2', false)
                : null
            }
            {!utils.isSource(search)
              && utils.isSaved(search)
              && !(comparePublicationCounts.length > 0)
              && (
              <Tab
                label="Charts & Trends"
                name="charts"
              >
                {() => (
                  <ChartsAndTrends
                    savedSearch={search}
                    timelineData={chartsAndTrends.timeline}
                    shareOfVoiceData={chartsAndTrends.shareOfVoice}
                    stockTimelineData={chartsAndTrends.stockTimeline}
                    cachedLargerTimeFrames={cachedLargerTimeFrames}
                    timeFrame={timeFrame}
                  />
                )}
              </Tab>
            )}
            <Tab
              className={classNames({'hidden-tab': hideEsgTab})}
              name={'esg'}
              label={'ESG'}
            >
              {() => (
                <EsgInsights
                  savedSearch={search}
                  updateEsgDocuments={this.props.updateEsgDocuments}
                />
              )}
            </Tab>
          </TabbedContainer>
        </div>

        {flagModal.isOpen && (
          <FlagModal
            documentIds={flagModal.documentIds}
            onFlaggingStateChange={this.handleFlaggingStateChange}
            onClose={hideFlagModal}
            feedId={feedId}
          />
        )}

        {isDeleteModalOpen && (
          <ConfirmationModal
            message="Are you sure you want to completely delete these articles?"
            confirmButtonText="Delete"
            isDestructive={true}
            onConfirm={deleteSelectedDocuments}
            onClose={hideDeleteModal}
          />
        )}

        {newSearchModalData.shouldShow && (
          <NewSearchModal
            search={search}
            userDefaultEmailSettings={userDefaultEmailSettings}
            isSaving={isSaving}
            isFirmLibrary={newSearchModalData.isFirmLibrary}
            currentUserIsFirmLibraryGroup={currentUserIsFirmLibraryGroup}
            onSave={saveNewSearch}
            onClose={hideNewSearchModal}
          />
        )}

        {shouldShowSaveIntoFirmLibraryModal && (
          <SaveToFirmLibraryModal
            isNewSearch={!search.isSaved}
            currentFirmLibraryName={currentFirmLibraryName}
            onContinue={() => saveSearch({asFirmLibrary: true})}
            onClose={hideSaveIntoFirmLibraryModal}
          />
        )}

        {isEditSearchSettingsModalOpen && (
          <Portal>
            <EditSearchSettingsModal
              savedSearch={search}
              onSave={saveSearchSettings}
              onHide={hideEditSearchSettingsModal}
              isSaving={isSavingSearchSettings}
            />
          </Portal>
        )}

        {isEditSearchFiltersModalOpen &&
          <Portal>
            <EditSearchFiltersModal
              defaultTab={editModalDefaultTab}
              savedSearch={search}
              saveData={saveData}
              queryComponentState={queryComponentState}
              hide={hideEditSearchFiltersModal}
              setQueryComponentState={setQueryComponentState}
              onSave={this.handleModalSave}
              onSaveAs={() => saveSearch({asNew: true})}
              setSaveData={setSaveData}
              onUpdateSearchResults={this.handleModalUpdateSearchResults}
              onRemoveExcludedFeeds={removeExcludedFeedsFromSearch}
              isTier3={true}
            />
          </Portal>
        }

        {shouldShowDnbModal && (
          <Portal>
            <DunAndBradstreetModal
              searchName={search.name}
              onAddEntities={this.handleAddDnbEntities}
              onRemoveEntities={this.handleRemoveDnbEntities}
              onClose={hideDnbModal}
            />
          </Portal>
        )}

        <BackToTopLink
          search={search}
          isLoading={isLoading}
          isRefreshingResults={isRefreshingResults}
          isLoadingNextPage={isLoadingNextPage}
          activePublicationTab={activePublicationTab}
          areAllResultsDisplayed={this.areAllResultsDisplayed()}
          loadMoreResults={this.props.loadMoreResults}
        />
      </div>
    )
  }

  // Event handlers

  handleScroll = event => {
    const el = event.target
    const scrollFromBottom = el.scrollHeight - el.clientHeight - el.scrollTop
    if (
      !this.props.isLoading
      && !this.props.isRefreshingResults
      && !this.props.isLoadingNextPage
      && this.props.activePublicationTab !== 'charts'
      && this.props.activePublicationTab !== 'esg'
      && scrollFromBottom <= INFINITE_SCROLL_THRESHOLD
      && !this.areAllResultsDisplayed()
    ) {
      this.props.loadMoreResults()
    }
  }

  handleApplyFilters = data => {
    const newFilters = data.filters.filter(filter =>
      !filter.id
      && !this.props.activeFilters.some(
        activeFilter => doFiltersMatch(activeFilter, filter)
      )
    )
    if (newFilters.length) {
      this.props.addFilters(newFilters)
    }

    if (data.pendingTermFrequencyFilters) {
      this.props.addTermFrequencyFilters(data.pendingTermFrequencyFilters)
    }

    const filtersToRemove = this.props.activeFilters.filter(filter =>
      !data.filters.some(newFilter => doFiltersMatch(newFilter, filter))
    )
    if (filtersToRemove.length) {
      this.props.removeFilters(filtersToRemove)
    }
  }

  handleApplyTrendingTerm = ({label, value}) => {
    const newFilter = {
      filterField: 'text_any',
      isFreeText: is.string(value),
      label,
    }
    if (newFilter.isFreeText) {
      newFilter.value = value
    }
    else {
      newFilter.searchId = value
    }
    this.props.addFilters([newFilter])
  }

  handleAddDnbEntities = entities => {
    const currentQuery = this.props.search.query
    const newQueryPart = entities
      .map(entity => `ss:${entity.searchId}`)
      .join('~|')
    // Make sure the new searches are in the entity store so their names show up
    this.props.updateSearches(
      entities.map(entity => ({id: entity.searchId, name: entity.companyName}))
    )
    this.props.setQuery(`${currentQuery}~|${newQueryPart}`)
  }

  handleRemoveDnbEntities = entities => {
    const currentQuery = this.props.search.query
    const entitySearchIds = entities.map(entity => entity.searchId)
    const newQuery = currentQuery
      .split('~|')
      .filter(part => {
        if (!(part.startsWith('ss:'))) {
          return false
        }
        const id = parseInt(part.split(':')[1], 10)
        if (isNaN(id)) {
          return false
        }
        return !entitySearchIds.includes(id)
      })
      .join('~|')
    this.props.setQuery(newQuery)
  }

  handleFlaggingStateChange = ({documents}) => {
    this.props.updateDocuments(documents)
  }

  handleToggleFiltersDisplay = () => {
    this.props.setIsFiltersBarOpen(!this.props.isFiltersBarOpen)
  }

  handleToggleTrendingDisplay = () => {
    this.props.setIsTrendingOpen(!this.props.trending.isOpen)
  }

  handleViewAdvancedFilters = () => {
    this.props.showEditSearchFiltersModal()
  }

  handleModalSave = searchData => {
    this.props.updateSearch(searchData)
  }

  handleModalUpdateSearchResults = searchData => {
    this.props.hideEditSearchFiltersModal()
    this.handleApplyFilters(searchData)
    if (searchData.relevancyLevel !== this.props.search.relevancyLevel) {
      this.props.setRelevancyLevel(RELEVANCE_LEVEL_LOOKUP[searchData.solrSearchField])
    }
    if (searchData.groupingLevel !== this.props.search.groupingLevel) {
      this.props.setGroupingLevel(searchData.groupingLevel)
    }
    if (searchData.languageFilters !== this.props.search.languageFilters){
      this.props.setSelectedLanguageIds(searchData.languageFilters)
    }
    // Check to see if any core terms have changed.
    if (searchData.query !== this.props.search.query) {
      this.props.setQuery(searchData.query)
    }
  }

  handleRemoveFilter = filter => {
    // If the search has no core terms and this is the last filter, prevent it
    // and show a notification to the user.
    // Excluding source searches since their core term is faked from the feed name.
    if (!this.props.search.query && this.props.activeFilters.length === 1 && !utils.isSource(this.props.search)) {
      this.props.showLastFilterNotification()
      return
    }
    this.props.removeFilters([filter])
  }

  // Helpers

  areAllResultsDisplayed() {
    const {
      activePublicationType,
      activePublicationTab,
      search,
      publicationCounts,
      comparePublicationCounts,
      page,
      lastPage,
      resultsPerPage
    } = this.props
    if (activePublicationTab === 'charts' || activePublicationTab === 'esg') {
      return false
    }
    const {greaterCount} = utils.resultCountForActivePublicationTypes({
      activePublicationType, activePublicationTab, search, publicationCounts,
      comparePublicationCounts
    })

    return (page * constants.RESULTS_PER_PAGE >= greaterCount || (!!lastPage && page >= lastPage))
  }
}
export default connect(
  createSelector(
    [
      selectors.getSearchResultsState,
      selectors.getActiveFilters,
      selectors.getHasUnsavedChanges,
      selectors.getUserDefaultEmailSettings,
      entitiesSelectors.getEntities,
      globalSelectors.getAvailableFilters,
      globalSelectors.getAppName,
      globalSelectors.getInsightsLabel,
      globalSelectors.getCurrentUserIsClientAdmin,
      globalSelectors.getCurrentUserHasRawQueryAccess,
      globalSelectors.getCurrentFirm,
      globalSelectors.getCurrentFirmLibraryName,
      globalSelectors.getCurrentUserIsFirmLibraryGroup,
      searchesAdminSelectors.canShowRelevanceFilter,
      routing.selectors.getPathname,
    ],
    (
      searchResultsState,
      activeFilters,
      hasUnsavedChanges,
      userDefaultEmailSettings,
      entities,
      availableFilters,
      appName,
      insightsLabel,
      currentUserIsClientAdmin,
      currentUserHasRawQueryAccess,
      currentFirm,
      currentFirmLibraryName,
      currentUserIsFirmLibraryGroup,
      canShowRelevanceFilter,
      currentPathname,
    ) => {
      const orm = Orm.withEntities(entities)
      const {hiddenFeedIds} = searchResultsState
      const topLevelDocs = (docIds) => {
        return(
          orm.getByIds(Document, docIds)
          .filter(doc => !doc.feed || !hiddenFeedIds.includes(doc.feed.id))
        )
      }
      const groupedDocs = (docIds) => {
        const grouped = map(
          ids =>
            orm.getByIds(Document, ids)
              .filter(doc => !doc.feed || !hiddenFeedIds.includes(doc.feed.id)),
          docIds,
        )
        return(grouped)
      }
      const topLevelDocuments = topLevelDocs(searchResultsState.topLevelDocumentIds)
      const groupedDocuments = groupedDocs(searchResultsState.groupedDocumentIds)
      const compareTopLevelDocuments = topLevelDocs(searchResultsState.compareTopLevelDocumentIds)
      const compareGroupedDocuments = searchResultsState.compareGroupedDocumentIds
        ? groupedDocs(searchResultsState.compareGroupedDocumentIds)
        : {}
      const ormId = searchResultsState.searchId || -1
      const search =
        orm.getById(SavedSearch, ormId)
        || new SavedSearch({id: ormId, name: 'Search Results'})
      return {
        isLoading: searchResultsState.isLoading,
        isSaving: searchResultsState.isSaving,
        isSavingSearchSettings: searchResultsState.isSavingSearchSettings,
        isChangingTrustedState: searchResultsState.isChangingTrustedState,
        feedId: searchResultsState.feedId,
        search,
        topLevelDocuments,
        groupedDocuments,
        publicationCounts: searchResultsState.publicationCounts,
        compareTopLevelDocuments,
        compareGroupedDocuments,
        compareCountIsGreater: searchResultsState.compareCountIsGreater,
        comparePublicationCounts: searchResultsState.comparePublicationCounts,
        relevancyLevel:
          searchResultsState.relevancyLevel || search.relevancyLevel,
        groupingLevel: (
          searchResultsState.groupingLevel
          || search.groupingLevel
          || constants.GROUPING_LEVELS.STANDARD
        ),
        languageFilters: searchResultsState.languageFilters,
        sortOption: searchResultsState.sortOption,
        insightsArticlesFirst: searchResultsState.insightsArticlesFirst,
        upcomingEvents: searchResultsState.upcomingEvents,
        timeFrame: searchResultsState.timeFrame,
        isRefreshingResults: searchResultsState.isRefreshingResults,
        activeFilters,
        hasUnsavedChanges,
        activePublicationTab:
          searchResultsState.activePublicationTab
          || utils.defaultActivePublicationTab(search),
        activePublicationType: searchResultsState.activePublicationType,
        selectedDocumentIds: searchResultsState.selectedDocumentIds,
        hasFetchedRelevanceIds: searchResultsState.hasFetchedRelevanceIds,
        canShowRelevanceFilter: canShowRelevanceFilter,
        selectAllState: searchResultsState.selectAllState,
        availableFiltersByCategory: availableFilters,
        isEditSearchSettingsModalOpen: searchResultsState.isEditSearchSettingsModalOpen,
        isEditSearchFiltersModalOpen: searchResultsState.isEditSearchFiltersModalOpen,
        editModalDefaultTab: searchResultsState.editModalDefaultTab,
        page: searchResultsState.page,
        lastPage: searchResultsState.lastPage,
        isLoadingNextPage: searchResultsState.isLoadingNextPage,
        isFiltersBarOpen: searchResultsState.isFiltersBarOpen,
        collapsedFilterSections: searchResultsState.collapsedFilterSections,
        trending: searchResultsState.trending,
        flagModal: searchResultsState.flagModal,
        isDeleteModalOpen: searchResultsState.isDeleteModalOpen,
        shouldShowQueryComponents: searchResultsState.shouldShowQueryComponents,
        shouldShowFullQuery: searchResultsState.shouldShowFullQuery,
        chartsAndTrends: searchResultsState.chartsAndTrends,
        appName,
        insightsLabel,
        hasRawQueryAccess: currentUserHasRawQueryAccess,
        shouldShowDnbModal: searchResultsState.shouldShowDnbModal,
        newSearchModalData: searchResultsState.newSearchModalData,
        currentFirm,
        currentFirmLibraryName,
        currentUserIsFirmLibraryGroup,
        queryComponentState: searchResultsState.queryComponentState,
        saveData: searchResultsState.saveData,
        currentUserIsClientAdmin,
        shouldShowSaveIntoFirmLibraryModal: searchResultsState.shouldShowSaveIntoFirmLibraryModal,
        cachedLargerTimeFrames: searchResultsState.cachedLargerTimeFrames,
        userDefaultEmailSettings,
        esgData: searchResultsState.esgData,
        currentPathname: currentPathname,
        resultsPerPage: searchResultsState.resultsPerPage || search.savedSearchArticlesCount,
      }
    },
  ),
  {
    setResultsPerPage: actions.setResultsPerPage,
    showEditSearchSettingsModal: actions.showEditSearchSettingsModal,
    hideEditSearchSettingsModal: actions.hideEditSearchSettingsModal,
    showEditSearchFiltersModal: actions.showEditSearchFiltersModal,
    hideEditSearchFiltersModal: actions.hideEditSearchFiltersModal,
    hideNewSearchModal: actions.hideNewSearchModal,
    showSaveIntoFirmLibraryModal: actions.showSaveIntoFirmLibraryModal,
    hideSaveIntoFirmLibraryModal: actions.hideSaveIntoFirmLibraryModal,
    addToTrustedSources: actions.addToTrustedSources,
    removeFromTrustedSources: actions.removeFromTrustedSources,
    setRelevancyLevel: actions.setRelevancyLevel,
    setGroupingLevel: actions.setGroupingLevel,
    setSelectedLanguageIds: actions.setSelectedLanguageIds,
    setSortOption: actions.setSortOption,
    setCompareId: actions.setCompareId,
    setInsightsArticlesFirst: actions.setInsightsArticlesFirst,
    setUpcomingEvents: actions.setUpcomingEvents,
    setTimeFrame: actions.setTimeFrame,
    setActivePublicationTab: actions.setActivePublicationTab,
    setActivePublicationType: actions.setActivePublicationType,
    toggleFilterSectionDisplay: actions.toggleFilterSectionDisplay,
    hideFeed: actions.hideFeed,
    selectDocumentId: actions.selectDocumentId,
    deselectDocumentId: actions.deselectDocumentId,
    selectAllTopLevelDocuments: actions.selectAllTopLevelDocuments,
    selectAllDocuments: actions.selectAllDocuments,
    deselectAllDocuments: actions.deselectAllDocuments,
    exportPdf: actions.exportPdf,
    exportDocx: actions.exportDocx,
    exportExcel: actions.exportExcel,
    exportEmail: actions.exportEmail,
    showFlagModal: actions.showFlagModal,
    hideFlagModal: actions.hideFlagModal,
    showDeleteModal: actions.showDeleteModal,
    hideDeleteModal: actions.hideDeleteModal,
    deleteSelectedDocuments: actions.deleteSelectedDocuments,
    loadMoreResults: actions.loadMoreResults,
    setIsFiltersBarOpen: actions.setIsFiltersBarOpen,
    setIsTrendingOpen: actions.setIsTrendingOpen,
    addFilters: actions.addFilters,
    addTermFrequencyFilters: actions.addTermFrequencyFilters,
    removeFilters: actions.removeFilters,
    setAppliedFilterKey: actions.setAppliedFilterKey,
    toggleShowQueryComponents: actions.toggleShowQueryComponents,
    toggleShowFullQuery: actions.toggleShowFullQuery,
    saveSearchSettings: actions.saveSearchSettings,
    setQuery: actions.setQuery,
    setQueryComponentState: actions.setQueryComponentState,
    setSaveData: actions.setSaveData,
    updateSearch: actions.updateSearch,
    showDnbModal: actions.showDnbModal,
    hideDnbModal: actions.hideDnbModal,
    saveSearch: actions.saveSearch,
    saveNewSearch: actions.saveNewSearch,
    removeExcludedFeedsFromSearch: actions.removeExcludedFeedsFromSearch,
    updateEsgDocuments: actions.updateEsgDocuments,

    updateSearches: searches => entitiesActions.update({
      [SavedSearch.entityKey]: pipe(
        map(search => [search.id, search]),
        fromPairs,
      )(searches),
    }),
    updateDocuments: documents => entitiesActions.update({
      [Document.entityKey]: pipe(
        map(document => [document.id, document]),
        fromPairs,
      )(documents),
    }),
    updateFeeds: feeds => entitiesActions.update({
      [Feed.entityKey]: pipe(
        map(feed => [feed.id, feed]),
        fromPairs,
      )(feeds),
    }),

    showLastFilterNotification: () => notifications.actions.showNotification({
      type: 'error',
      message: "You cannot remove the last term or filter from a search.",
      duration: 10,
    }),
  },
)(SearchResultsPage)
