import {Redirect, Route, Switch} from 'react-router-dom'
import {EsgCompanyPage, EsgIndustryDashboardPage} from 'app/esg/Pages'
import React from 'react'

export default function ESG() {
  return (
    <Switch>
      <Route path="/" exact>
        <EsgIndustryDashboardPage />
      </Route>
      <Route path="/company/:companyId">
        <EsgCompanyPage />
      </Route>
      <Route path="">
        <Redirect to="/" />
      </Route>
    </Switch>
  )
}
