import React, {Component} from 'react'
import {connect} from 'react-redux'
import * as actions from './email-notifications-actions'
import * as constants from './email-notifications-constants'
import InputBlock from 'app/common/InputBlock'
import './SendZeroResults.less'
import PropTypes from 'prop-types'

@connect(
  state => ({
    baseSettings: state.emailNotifications.baseSettings,
    insightsSettings: state.emailNotifications.insightsSettings,
    esgSettings: state.emailNotifications.esgSettings,
    editUserId: state.emailNotifications.emailDeliveryData.editUserId,
  }),
  {
    saveEmailAlertSettings: actions.api.saveEmailAlertSettings.request,
  },
)
export default class SendOnWeekends extends Component {
  static propTypes = {
    noticeFrequency: PropTypes.string.isRequired,
    notificationType: PropTypes.string.isRequired,
  }
  render() {
    const activeSettings =
      this.props.notificationType === constants.BASE_NOTIFICATION_TYPE
        ? this.props.baseSettings
        : this.props.notificationType === constants.INSIGHTS_NOTIFICATION_TYPE
        ? this.props.insightsSettings
        : this.props.notificationType === constants.ESG_NOTIFICATION_TYPE
        ? this.props.esgSettings
        : null
    let sendOnWeekendOption = null
    if (activeSettings) {
      const sendOnWeekendResults = activeSettings
        ? activeSettings.deliverOnWeekends
        : null
      sendOnWeekendOption = this.props.noticeFrequency === constants.DAILY_FREQ ||
        this.props.noticeFrequency === constants.WEEKDAY_FREQ ? (
          <InputBlock
            className={'input-label email-send-zero-container'}
            label={'Deliver on Weekends'}
            isInline={true}
          >
            <input
              className={'input-checkbox'}
              type="checkbox"
              checked={sendOnWeekendResults}
              onChange={e => this.onDeliverOnWeekendsChange(e, activeSettings)}
            />
          </InputBlock>
        ) : null
    }
    return <React.Fragment>{sendOnWeekendOption}</React.Fragment>
  }
  onDeliverOnWeekendsChange(event, activeSettings) {
    this.props.saveEmailAlertSettings({
      editUserId: this.props.editUserId,
      noticeFrequency: constants.WEEKDAY_FREQ,
      type: activeSettings.notificationType,
      field: constants.DELIVER_ON_WEEKENDS,
      value: event.target.checked,
    })
  }
}
