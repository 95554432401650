import { handleActions } from 'redux-actions'
import * as actions from './flagged-items-actions'

const getInitialState = () => ({
  isLoading: false,
  createCategoryModalData: {
    isOpen: false,
  },
  deleteCategoryId: null,
  editCategoryData: {
    id: null,
    name: undefined,
    isShared: undefined,
  },
  categories: [],
  helpQuestions: [],
  promotedFeedIds: [],
  sortDirection: 'desc',
  selectedCategoryIds: [0],
  bulkAction: null,
  deleteModalCategoryId: null,
  pageVersion: 'old',
  sideBarCategories: [],
  foldersOpened: [],
  newSidebarId: null
})

export default handleActions(
  {
    [actions.init]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.setIsLoading]: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
    [actions.setHelpQuestions]: (state, action) => ({
      ...state,
      helpQuestions: action.payload,
    }),
    [actions.fetchPromotedSearches]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.setPromotedFeedIds]: (state, action) => ({
      ...state,
      promotedFeedIds: action.payload,
    }),
    [actions.fetchCategories]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.setCategories]: (state, action) => ({
      ...state,
      categories: action.payload,
    }),
    [actions.showCreateCategoryModal]: (state, action) => ({
      ...state,
      createCategoryModalData: {
        ...state.createCategoryModalData,
        isOpen: true,
        parentId: action.payload || null,
      }
    }),
    [actions.hideCreateCategoryModal]: (state, action) => ({
      ...state,
      createCategoryModalData: getInitialState().createCategoryModalData,
    }),
    [actions.exportPDF]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.exportDoc]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.exportExcel]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.exportEmail]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.createCategory]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.setDeleteCategoryId]: (state, action) => ({
      ...state,
      deleteCategoryId: action.payload,
    }),
    [actions.deleteCategories]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.updateEditCategoryData]: (state, action) => ({
      ...state,
      editCategoryData: {
        ...state.editCategoryData,
        ...action.payload,
      },
    }),
    [actions.resetEditCategoryData]: (state, action) => ({
      ...state,
      editCategoryData: getInitialState().editCategoryData,
    }),
    [actions.saveCategory]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.setSelectedCategoryIds]: (state, action) => ({
      ...state,
      selectedCategoryIds: action.payload,
    }),
    [actions.setBulkAction]: (state, action) => ({
      ...state,
      bulkAction: action.payload,
    }),
    [actions.setDeleteModalCategoryId]: (state, action) => ({
      ...state,
      deleteModalCategoryId: action.payload,
    }),
    [actions.deleteArticles]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.deleteFlaggings]: (state, action) => ({
      ...state,
      isLoading: true,
    }),   
    [actions.setFlagPageVersion]: (state, action) => ({
      ...state,
      pageVersion: action.payload,
    }),
    [actions.setSideBarCategories]: (state, action) => ({
      ...state,
      sideBarCategories: action.payload,
    }),
    [actions.getSelectedCategoryIds]: (state, action) => ({
      ...state,
      selectedCategoryIds: action.payload,
    }),
    [actions.setFoldersOpened]: (state, action) => ({
      ...state,
      foldersOpened: action.payload,
    }),
    [actions.setNewSidebarId]: (state, action) => ({
      ...state,
      newSidebarId: action.payload,
    }),
  },
  getInitialState(),
)