import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {ADD_PROPRIETARY_FEED_STEPS as STEPS} from '../sources-admin-constants'
import {getSourcesAdmin} from '../sources-admin-selectors'
import {
  updateCreateProprietaryFeedData,
} from '../sources-admin-actions'

@connect(
  createSelector(
    [getSourcesAdmin],
    (sourcesAdmin) => {
      return {
        createProprietaryFeedData: sourcesAdmin.createProprietaryFeedData,
      }
    }
  ),
  {
    updateCreateProprietaryFeedData,
  }
)
export default class NewFeedSelect extends Component {
  state = {
    feedPreviewIndexes: [],
  }

  render() {
    return (
      <div className="select-feed">
        <p>We found the following feed at the URL you provided:</p>

        <div className="feed-list">
          {this.renderFeedList()}
        </div>

        <p className="contact-manzama">
          If this is not what you're looking for, please <a href="mailto:content@manzama.com">contact Support</a>.
        </p>
      </div>
    )
  }

  // Render helpers

  renderFeedList() {
    // We're treating this as a list because at some point we may want
    // to suggest multiple feeds. For now, this is only ever going to be
    // one feed.
    return this.props.createProprietaryFeedData.feeds.map((feed, index) => {
      const showPreview = this.state.feedPreviewIndexes.includes(index)

      let preview = null
      if (showPreview) {
        const articles = feed.documents.map(entry => (
          <div className="entry" key={entry.link}>
            <a className="headline" href={entry.link} target="_blank">
              {entry.headline}
            </a>
          </div>
        ))
        preview = (
          <div className="preview" key={index}>
            {articles || "This feed does not contain any content."}
          </div>
        )
      }

      const handlePreviewToggle = () => showPreview
        ? this.hidePreviewAtIndex(index)
        : this.showPreviewAtIndex(index)

      return (
        <div key={index}>
          <div className="feed">
            <div className="title-area">
              <span className="name">{feed.name}</span>
              <span className="separator"/>
              <a className="preview-toggle" onClick={handlePreviewToggle}>
                {showPreview ? 'Hide Preview' : 'Preview Feed'}
              </a>
            </div>

            <a className="add-source" onClick={() => this.continue(feed)}>
              Add as Source
            </a>
          </div>

          {preview}
        </div>
      )
    })
  }

  showPreviewAtIndex(index) {
    this.setState({
      feedPreviewIndexes: [...this.state.feedPreviewIndexes, index],
    })
  }

  hidePreviewAtIndex(index) {
    this.setState({
      feedPreviewIndexes:
        this.state.feedPreviewIndexes.filter(idx => idx !== index),
    })
  }

  continue(feed) {
    this.props.updateCreateProprietaryFeedData({
      currentStep: STEPS.SELF_ADD,
      selectedFeed: feed,
    })
  }
}
