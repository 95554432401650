import classNames from 'classnames'
import invariant from 'invariant'
import PropTypes from 'prop-types'
import React from 'react'

import InlineSvg from 'app/common/InlineSvg'
import Tooltip from 'app/common/Tooltip'
import {ACTIONS} from 'app/reusable/SearchResultsActions'
import {Feed} from 'app/models'

import FiltersBar from '../filters-bar'
import * as constants from '../search-results-page-constants'
import * as strings from '../search-results-page-strings'
import {timeFrameType} from '../time-frame-selector'
import TrendingBar from '../trending-bar'
import ActionBar from './action-bar'
import DocumentList from './document-list'
import DocumentListLoading from './document-list/DocumentListLoading'

import * as styles from './SearchResultsContent.less'

const NoDocuments = ({publicationTypeName}) => (
  <div className={styles.noDocuments}>
    <h3 className={styles.head}>
      No "{publicationTypeName}" results were found for this search.
    </h3>
    <p className={styles.description}>
      Increase the date range or expand your search to get some results.
    </p>
  </div>
)

function SearchResultsContent({
  isLoading,
  isSaving,

  search,
  topLevelDocuments,
  groupedDocuments,
  compareTopLevelDocuments,
  compareGroupedDocuments,
  compareCountIsGreater,
  sortOption,
  insightsArticlesFirst,
  upcomingEvents,
  isRefreshingResults,
  isFiltersBarOpen,
  collapsedFilterSections,
  toggleFilterSectionDisplay,
  toggleFiltersDisplay,
  viewAdvancedFilters,
  relevancyLevel,
  groupingLevel,
  languageFilters,
  setRelevancyLevel,
  setGroupingLevel,
  setSelectedLanguageIds,
  timeFrame,
  setTimeFrame,
  trending,
  toggleTrendingDisplay,
  applyTrendingTerm,
  publicationTypeInfos = [],
  activePublicationTab,
  activePublicationType = null,
  selectedDocumentIds,
  hasFetchedRelevanceIds,
  canShowRelevanceFilter,
  selectAllState,
  isLoadingNextPage,
  areAllResultsDisplayed,
  setSortOption,
  setInsightsArticlesFirst,
  setUpcomingEvents,
  setActivePublicationType,
  hideFeed,
  selectDocumentId,
  deselectDocumentId,
  selectAllTopLevelDocuments,
  selectAllDocuments,
  deselectAllDocuments,
  exportPdf,
  exportDocx,
  exportExcel,
  exportEmail,
  showFlagModal,
  showDeleteModal,
  updateDocuments,
  updateFeeds,
  hasUnsavedChanges,
  activeFilters,
  saveSearch,
  addFilter,
  removeFilter,
  setResultsPerPage,
  resultsPerPage,
}) {
  const handleAction = action => {
    const reduxAction = {
      [ACTIONS.EXPORT_PDF]: exportPdf,
      [ACTIONS.EXPORT_DOCX]: exportDocx,
      [ACTIONS.EXPORT_EXCEL]: exportExcel,
      [ACTIONS.EMAIL]: exportEmail,
      [ACTIONS.FLAG]: () => showFlagModal(selectedDocumentIds),
      [ACTIONS.DELETE]: showDeleteModal,
    }[action]
    invariant(reduxAction, `Invalid action dropdown value: '${action}'`)
    reduxAction()
  }

  const showRelevanceFilter = hasFetchedRelevanceIds
    ? canShowRelevanceFilter
    : search.shouldShowRelevanceFilter || false

  const groupedDocumentCount = Object.values(groupedDocuments)
    .reduce((count, docs) => count + docs.length, 0)

  return (
    <div className="search-results-content">
      <div className={classNames(styles.headerBar, 'header-bar')}>
        <div className="publication-types">
          {publicationTypeInfos
            .filter(({key}) => key !== 'twitter')
            .map(pubTypeInfo => (
              <a
                className={classNames(
                  styles.publicationType,
                  // For Pendo:
                  'publication-type',
                  `publication-type-${pubTypeInfo.key}`,
                )}
                onClick={() => setActivePublicationType(pubTypeInfo.key)}
                key={pubTypeInfo.key}
              >
                <span
                  className={classNames(
                    styles.label,
                    {[styles.active]: pubTypeInfo.key === activePublicationType},
                  )}
                >
                  {pubTypeInfo.label}
                </span>
                {pubTypeInfo.count !== null && ` (${pubTypeInfo.count})`}
              </a>
            )
          )}
        </div>
      </div>

      <div className={styles.mainContainer}>
        <div
          className={classNames(
            styles.filtersContainer,
            {[styles.collapsed]: !isFiltersBarOpen},
          )}
        >
          <a
            onClick={toggleFiltersDisplay}
            className={classNames(
              styles.displayToggle,
              // For Pendo:
              'filters-display-toggle',
              {
                'show-filters-display': !isFiltersBarOpen,
                'hide-filters-display': isFiltersBarOpen,
              },
            )}
          >
            <Tooltip
              label="Click to Expand Filters"
              direction="left"
              containerClassName={
                !isFiltersBarOpen ? styles.collapsedLabelContainer : null
              }
              disabled={isFiltersBarOpen}
            >
              <span className={styles.arrow}>
                <InlineSvg src="/media/img/dropdown-arrow.svg" />
              </span>
              <span className={styles.label}>
                {isFiltersBarOpen && 'Hide'} Filters
              </span>
            </Tooltip>
          </a>

          <div
            className={classNames(
              styles.filtersBar,
              'filters-bar', // for Pendo
            )}
          >
            <FiltersBar
              search={search}
              activeFilters={activeFilters}
              hasUnsavedChanges={hasUnsavedChanges}
              isSaving={isSaving}
              onAddFilter={addFilter}
              onRemoveFilter={removeFilter}
              onSaveFilters={saveSearch}
              viewAdvancedFilters={viewAdvancedFilters}

              relevancyLevel={relevancyLevel || constants.RELEVANCY_LEVELS.LOW}
              shouldShowRelevanceFilter={showRelevanceFilter}
              onRelevancyLevelChange={setRelevancyLevel}

              groupingLevel={
                groupingLevel || constants.GROUPING_LEVELS.STANDARD
              }
              onGroupingLevelChange={setGroupingLevel}

              languageFilters={languageFilters || null}
              onLanguageFilterChange={setSelectedLanguageIds}

              timeFrame={timeFrame || '2w'}
              onTimeFrameChange={setTimeFrame}

              sortDirection={sortOption || constants.SORT_OPTIONS.RELEVANCE}
              onSortDirectionChange={setSortOption}

              shouldShowUpcomingEventsFilter={
                activePublicationTab === Feed.PUBLICATION_TYPE_CATEGORIES.EVENTS
              }
              upcomingEvents={upcomingEvents}
              onUpcomingEventsChange={setUpcomingEvents}

              isInsightsEnabled={
                !!search.isInsightsEnabled
                && activePublicationTab === Feed.PUBLICATION_TYPE_CATEGORIES.NEWS
              }
              insightsArticlesFirst={insightsArticlesFirst}
              onInsightsArticlesFirstChange={setInsightsArticlesFirst}

              collapsedFilterSections={collapsedFilterSections}
              toggleFilterSectionDisplay={toggleFilterSectionDisplay}
              onNumberofSearchResultsChange={setResultsPerPage}
              resultsPerPage={resultsPerPage}
            />
          </div>
        </div>

        <div className={styles.mainContent}>
          <div className={styles.content}>
            <div className={styles.articleSection}>
              <ActionBar
                isActionDropdownDisabled={selectedDocumentIds.length === 0}
                areAllDocumentsSelected={
                  [
                    constants.SELECT_ALL_STATES.TOP_LEVEL,
                    constants.SELECT_ALL_STATES.INCLUDING_GROUPED
                  ].includes(selectAllState)
                }
                topLevelDocumentCount={topLevelDocuments.length}
                totalDocumentCount={
                  topLevelDocuments.length + groupedDocumentCount
                }
                onSelectAllTopLevel={selectAllTopLevelDocuments}
                onSelectAll={selectAllDocuments}
                onDeselectAll={deselectAllDocuments}
                onActionSelect={handleAction}
                className={styles.actionBar}
              />
              {isLoading || isRefreshingResults ? (
                <DocumentListLoading />
              ) : topLevelDocuments.length ? (
                <DocumentList
                  search={search}
                  topLevelDocuments={topLevelDocuments}
                  groupedDocuments={groupedDocuments}
                  compareTopLevelDocuments={compareTopLevelDocuments}
                  compareGroupedDocuments={compareGroupedDocuments}
                  compareCountIsGreater={compareCountIsGreater}
                  isLoadingNextPage={isLoadingNextPage}
                  areAllResultsDisplayed={areAllResultsDisplayed}
                  selectedIds={selectedDocumentIds}
                  onSelectChange={({id, selected}) =>
                    selected ? selectDocumentId(id) : deselectDocumentId(id)
                  }
                  onFlaggingStateChange={({documents}) =>
                    updateDocuments(documents)
                  }
                  onPromoteStateChange={({feedId, isPromoted}) =>
                    updateFeeds([{id: feedId, isPromoted}])
                  }
                  onExclude={hideFeed}
                />
              ) : (
                <NoDocuments
                  publicationTypeName={
                    strings.publicationTabLabel(activePublicationTab)
                  }
                />
              )}
            </div>
          </div>
        </div>

        <div
          className={classNames(
            styles.trendingContainer,
            {[styles.collapsed]: !trending.isOpen},
          )}
        >
          <a
            onClick={toggleTrendingDisplay}
            className={classNames(
              styles.displayToggle,
              // For Pendo:
              'trending-display-toggle',
              {
                'show-trending-display': !trending.isOpen,
                'hide-trending-display': trending.isOpen,
              },
            )}
          >
            <Tooltip
              label="Click to Expand Trending"
              direction="right"
              containerClassName={
                !trending.isOpen ? styles.collapsedLabelContainer : null
              }
              disabled={trending.isOpen}
            >
              <span className={styles.arrow}>
                <InlineSvg src="/media/img/dropdown-arrow.svg" />
              </span>
              <span className={styles.label}>
                {trending.isOpen && 'Hide'} Trending
              </span>
            </Tooltip>
          </a>
          <div className={styles.trending}>
            <TrendingBar
              data={trending.data}
              timeFrame={timeFrame}
              onApplyTerm={applyTrendingTerm}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
SearchResultsContent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  search: PropTypes.object.isRequired,
  topLevelDocuments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  groupedDocuments: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
  ).isRequired,
  compareTopLevelDocuments: PropTypes.arrayOf(PropTypes.object.isRequired),
  compareGroupedDocuments: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.object.isRequired).isRequired),
  compareCountIsGreater: PropTypes.bool,
  sortOption: PropTypes.string,
  insightsArticlesFirst: PropTypes.bool.isRequired,
  upcomingEvents: PropTypes.bool.isRequired,
  isRefreshingResults: PropTypes.bool.isRequired,
  resultsPerPage: PropTypes.number.isRequired,

  // Filters
  isFiltersBarOpen: PropTypes.bool.isRequired,
  collapsedFilterSections: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleFilterSectionDisplay: PropTypes.func.isRequired,
  toggleFiltersDisplay: PropTypes.func.isRequired,
  viewAdvancedFilters: PropTypes.func.isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  activeFilters: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  saveSearch: PropTypes.func.isRequired,
  addFilter: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,

  relevancyLevel: PropTypes.string,
  groupingLevel: PropTypes.string,
  languageFilters: PropTypes.arrayOf(PropTypes.object),
  setRelevancyLevel: PropTypes.func.isRequired,
  setGroupingLevel: PropTypes.func.isRequired,
  setSelectedLanguageIds: PropTypes.func,
  timeFrame: timeFrameType,
  setTimeFrame: PropTypes.func.isRequired,
  trending: PropTypes.object.isRequired,
  toggleTrendingDisplay: PropTypes.func.isRequired,
  applyTrendingTerm: PropTypes.func.isRequired,
  publicationTypeInfos: PropTypes.arrayOf(PropTypes.object.isRequired),
  activePublicationTab: PropTypes.string.isRequired,
  activePublicationType: PropTypes.string,
  selectedDocumentIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  selectAllState: PropTypes.oneOf(Object.values(constants.SELECT_ALL_STATES)),
  isLoadingNextPage: PropTypes.bool.isRequired,
  areAllResultsDisplayed: PropTypes.bool.isRequired,

  // Functions

  // Search options
  setActivePublicationType: PropTypes.func.isRequired,
  setSortOption: PropTypes.func.isRequired,
  setInsightsArticlesFirst: PropTypes.func.isRequired,
  setUpcomingEvents: PropTypes.func.isRequired,
  hideFeed: PropTypes.func.isRequired,
  setResultsPerPage: PropTypes.func.isRequired,

  // Selection
  selectDocumentId: PropTypes.func.isRequired,
  deselectDocumentId: PropTypes.func.isRequired,
  selectAllTopLevelDocuments: PropTypes.func.isRequired,
  selectAllDocuments: PropTypes.func.isRequired,
  deselectAllDocuments: PropTypes.func.isRequired,

  // Export
  exportPdf: PropTypes.func.isRequired,
  exportDocx: PropTypes.func.isRequired,
  exportExcel: PropTypes.func.isRequired,
  exportEmail: PropTypes.func.isRequired,

  // Flagging
  showFlagModal: PropTypes.func.isRequired,

  // Deleting
  showDeleteModal: PropTypes.func.isRequired,

  // Entities
  updateDocuments: PropTypes.func.isRequired,
  updateFeeds: PropTypes.func.isRequired,
}
export default SearchResultsContent
