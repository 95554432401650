import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { titleCase } from 'title-case'
import { joinInSentence } from 'app/utils'
import SavedSearchInput from 'app/common/SavedSearchInput'
import Button from 'app/common/Button'


export default class AddSearches extends Component {
  static propTypes = {
    categories: PropTypes.array.isRequired,
    currentFirmLibraryName: PropTypes.string.isRequired,
    showOnlyFirmLibrarySearches: PropTypes.bool.isRequired,
    fetchSearches: PropTypes.func.isRequired,
    userSearchesToAdd: PropTypes.array.isRequired,
    setUserSearchesToAdd: PropTypes.func.isRequired,
  }

  render() {
    const {currentFirmLibraryName, showOnlyFirmLibrarySearches} = this.props
    const addCategorySearch = (category) => {
      const categoryName = category.category
      const categorySearches =
        this.props.userSearchesToAdd.find(cat => cat[categoryName]) || {[categoryName]: []}

      let selectedValuesSet = new Set()
      categorySearches[categoryName].map(search => {
        selectedValuesSet.add({
            category: categoryName,
            value: search.value,
            label: search.name || search.label,
            isFreeText: search.isFreeText || false,
        })
      })

      return(
        <div className="profile-refresh-add-searches-row">
          <SavedSearchInput
            options={{
              category: category.categories || categoryName,
              shared_only: true,
              firm_library_only: showOnlyFirmLibrarySearches,
            }}
            selectedValues={Array.from(selectedValuesSet)}
            onChange={values => this.handleSearchInputChange(categoryName, values)}
            allowFreeText={!showOnlyFirmLibrarySearches}
          />
        </div>
      )
    }

    const addSearchContent =
      this.props.categories.map((category, idx) => {
        const addSearches = addCategorySearch(category)
        return (
          <div key={idx} className={'search-category-container'}>
            <div className="search-category-header">
              <h3 className={'category-name'}>{titleCase(category.plural)}</h3>
              <Button
                className="select-list-button"
                isSmall={true}
                isSkinny={true}
                isPrimary={true}
                onClick={() => this.props.fetchSearches(category.category)}
                label={`Select from ${currentFirmLibraryName}`}
              />
            </div>
            {addSearches}
          </div>
        )
      })

    const searchesDescription = this.getSearchesDescription()

    return (
      <div className="profile-refresh-body-container">
        <div className="profile-refresh-body">
          <p>Add any {searchesDescription} that you’d like to track. You can always add
            more searches and refine your results within the platform.
          </p>
          <div className={'add-searches-container'}>
            {addSearchContent}
          </div>
        </div>
      </div>
    )
  }

  handleSearchInputChange(category, values) {
    let searchesToAdd = this.props.userSearchesToAdd.filter(cat => Object.keys(cat)[0] !== category)
    const updateIndex = values.findIndex(val => val.category === null)
    if (updateIndex !== -1) {
      values[updateIndex]['category'] = category
    }

    searchesToAdd.push({[category]: values})
    this.props.setUserSearchesToAdd(searchesToAdd)
  }

  getSearchesDescription() {
    const a = this.props.categories.map(s => {
      return (
        titleCase(s.plural)
      )
    })
    return joinInSentence(a)
  }
}
