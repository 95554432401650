import { createAction } from 'redux-actions'

// Prefix action names with ESG
const createEsgAction = name => createAction(`ESG/${name}`)

export const init = createEsgAction('INIT')
export const getCompanyEsgData = createEsgAction('GET_COMPANY_ESG_DATA')
export const setCompanyEsgData = createEsgAction('SET_COMPANY_ESG_DATA')
export const getIndustryEsgData = createEsgAction('GET_INDUSTRY_ESG_DATA')
export const setIndustryEsgData = createEsgAction('SET_INDUSTRY_ESG_DATA')
export const getSavedSearches = createEsgAction('GET_SAVED_SEARCHES')
export const setSavedSearches = createEsgAction('SET_SAVED_SEARCHES')
export const getCompany = createEsgAction('GET_COMPANY')
export const setCompany = createEsgAction('SET_COMPANY')
export const hideNotification = createEsgAction('HIDE_NOTIFICATION')
export const showNotification = createEsgAction('SHOW_NOTIFICATION')
export const setIndustry = createEsgAction('SET_INDUSTRY')
export const getIndustry = createEsgAction('GET_INDUSTRY')
export const getEsgCompanyTableData = createEsgAction('GET_ESG_COMPANY_TABLE_DATA')
export const setEsgCompanyTableData = createEsgAction('SET_ESG_COMPANY_TABLE_DATA')
export const setShowSpinnerOnFeedbackSent = createEsgAction('SET_SHOW_SPINNER_ON_FEEDBACK_SENT')
export const setSectors = createEsgAction('SET_SECTORS')
export const getSectors = createEsgAction('GET_SECTORS')
export const updateDocumentFlaggedState = createEsgAction('UPDATE_DOCUMENT_FLAGGED_STATE')
export const updateDocumentPromotedState = createEsgAction('UPDATE_DOCUMENT_PROMOTED_STATE')
export const updateDocumentExcludedState = createEsgAction('UPDATE_DOCUMENT_EXCLUDE_STATE')
export const getSidebarContent = createEsgAction('GET_SIDEBAR_CONTENT')
export const setSidebarContent = createEsgAction('SET_SIDEBAR_CONTENT')
export const setIsLoading = createEsgAction('SET_IS_LOADING')
export const activateNavItem = createEsgAction('ACTIVATE_NAV_ITEM')
export const deactivateNavItem = createEsgAction('DEACTIVATE_NAV_ITEM')
export const setIndustryTerms = createEsgAction('SET_INDUSTRY_TERMS')
export const setCompanyTerms = createEsgAction('SET_COMPANY_TERMS')
export const getDefaultIndustry = createEsgAction('GET_DEFAULT_INDUSTRY')
export const setDefaultIndustry = createEsgAction('SET_DEFAULT_INDUSTRY')
export const updateDefaultIndustry = createEsgAction('UPDATE_DEFAULT_INDUSTRY')

export const fetchCompanyStories = createEsgAction('FETCH_COMPANY_STORIES')
export const fetchCompanyStoriesSuccess = createEsgAction('FETCH_COMPANY_STORIES_SUCCESS')
export const fetchCompanyStoriesError = createEsgAction('FETCH_COMPANY_STORIES_ERROR')
export const setCompanyStoriesLoading = createEsgAction('SET_COMPANY_STORIES_LOADING')

export const fetchIndustryStories = createEsgAction('FETCH_INDUSTRY_STORIES')
export const fetchIndustryStoriesSuccess = createEsgAction('FETCH_INDUSTRY_STORIES_SUCCESS')
export const fetchIndustryStoriesError = createEsgAction('FETCH_INDUSTRY_STORIES_ERROR')
export const setIndustryStoriesLoading = createEsgAction('SET_INDUSTRY_STORIES_LOADING')

export const setCompanySelectedTerm = createEsgAction('SET_COMPANY_SELECTED_TERM')
export const setCompanyStorySortFilter = createEsgAction('SET_COMPANY_STORY_SORT_FILTER')
export const setCompanySelectedPillar = createEsgAction('SET_COMPANY_SELECTED_PILLAR')
export const setCompanySelectedTopic = createEsgAction('SET_COMPANY_SELECTED_TOPIC')
export const setCompanyStoryPage = createEsgAction('SET_COMPANY_STORY_PAGE')
export const fetchCompanyFilterTerms = createEsgAction('FETCH_COMPANY_FILTER_TERMS')

export const setIndustrySelectedTerm = createEsgAction('SET_INDUSTRY_SELECTED_TERM')
export const setIndustryStorySortFilter = createEsgAction('SET_INDUSTRY_STORY_SORT_FILTER')
export const setIndustrySelectedPillar = createEsgAction('SET_INDUSTRY_SELECTED_PILLAR')
export const setIndustrySelectedTopic = createEsgAction('SET_INDUSTRY_SELECTED_TOPIC')
export const setIndustryStoryPage = createEsgAction('SET_INDUSTRY_STORY_PAGE')
export const fetchIndustryFilterTerms = createEsgAction('FETCH_INDUSTRY_FILTER_TERMS')

export const getAllEsgCompaniesDataOnSearch = createEsgAction('GET_All_ESG_COMPANIES_DATA_ON_SEARCH')
export const getAllEsgCompaniesOnSearch = createEsgAction('GET_ALL_ESG_COMPANIES_ON_SEARCH')
export const setAllEsgCompaniesDataOnSearch = createEsgAction('SET_All_COMPANIES_ESG_DATA_ON_SEARCH')
export const setEsgCompaniesSearchString = createEsgAction('SET_ESG_COMPANIES_SEARCH_STRING')

export const clearAllEsgCompaniesDataOnSearch = createEsgAction('CLEAR_All_COMPANIES_ESG_DATA_ON_SEARCH')

export const deleteSearches = createEsgAction('DELETE_SEARCHES')
export const getAllEsgCompaniesData = createEsgAction('GET_ALL_ESG_COMPANIES_DATA')
export const setAllEsgCompaniesData = createEsgAction('SET_ALL_ESG_COMPANIES_DATA')
export const createSearches = createEsgAction('CREATE_SEARCHES')
export const totalEsgCompaniesData = createEsgAction('TOTAL_ESG_COMPANIES_DATA')
