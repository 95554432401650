import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, {useState, useRef} from 'react'
import {titleCase} from 'title-case'
import {CHART_COLORS, EXCLUDED_COLOR, DEFAULT_BAR_WIDTH, CHART_COLORS_HOVER,
  DEFAULT_TOOL_TIP, CHART_TYPES} from "./constants"
import styles from './MzChart.less'
import {
  CartesianGrid,
  Bar,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  Rectangle,
} from "recharts"
import is from "is"
import ExportChart from "./ExportChart"
import CustomXAxisTick from "./CustomXAxisTick"
import LoadingSpinner from "app/common/LoadingSpinner"


export default function MzBarChart({
  chartData,
  className,
  legendMargin,
  chartMargin,
  defaultBarWidth,
  isStaggeredLoading,
  toolTipText,
  showPercentage,
  isVerticalLayout
}) {
  const barWidthInit = (segmentKeys) => (
    segmentKeys.reduce((acc, key) => (acc[key] = defaultBarWidth, acc), {})
  )
  const legendColorInit = (chartData) => {
    const {segmentKeys, segmentData} = chartData
    const index = (pk) => {
      const item = segmentData.find(d => d.segmentKey === pk)
      return item.segmentIndex
    }
    return (
        segmentKeys.reduce((acc, key, _) => (acc[key] = CHART_COLORS[index(key)], acc), {})
      )
  }

  const [barWidth, setBarWidth] = useState(barWidthInit(chartData.segmentKeys))
  const [legendColor, setLegendColor] = useState(legendColorInit(chartData))
  const [showLegend, setShowLegend] = useState(false)
  const [chartRange, setChartRange] = useState([0, chartData.initMaxRange])
  const [activeData, setActiveData] = useState(chartData.segmentData)
  const activeChart = useRef(null)

  const segmentCount = chartData.segmentKeys && chartData.segmentKeys.length > 0
    ? chartData.segmentKeys.length
    : 0

  const barShape = (props) => {
    const {segmentKey} = props
    const customBar = isVerticalLayout
      ? <Rectangle
          {...props}
          fill={legendColor[segmentKey]}
        />
      : <Rectangle
          {...props}
          fill={legendColor[segmentKey]}
          width={barWidth[segmentKey]}
        />
    return(customBar)
  }

  const handleLegendMouseEvent = (item, isHover) => {
    const color = {...legendColor}
    if (barWidth[item.segmentKey] !== 0){
      color[item.segmentKey] = isHover
        ? CHART_COLORS_HOVER[item.segmentIndex]
        : CHART_COLORS[item.segmentIndex]
      setLegendColor(color)
    }
  }

  const handleLegendOnClick = (segmentItem) => {
    const {segmentKey, segmentIndex} = segmentItem
    const width = {...barWidth}
    const color = {...legendColor}

    // disallow setting all labels to inactive
    if (barWidth[segmentKey] !== 0 &&  activeData.length === 1) {
      return
    }

    // set bar color and width
    width[segmentKey] = width[segmentKey] === 0
      ? defaultBarWidth
      : 0
    color[segmentKey] = width[segmentKey] === 0
      ? EXCLUDED_COLOR
      : CHART_COLORS[segmentIndex]
    setBarWidth(width)
    setLegendColor(color)

    // set active bar data for re-draw
    const excludedKeys = chartData.segmentKeys.filter(pk => width[pk] === 0)
    const newActiveData = chartData.segmentData.filter(bd => !excludedKeys.includes(bd.segmentKey))
    setActiveData(newActiveData)

    // set chart range when active bars change
    const maxRanges = Object.entries(newActiveData).map(([_, val]) => val.maxRange)
    const newMaxRange = maxRanges && maxRanges.length > 0
      ? Math.max.apply(Math, maxRanges)
      : 0
    setChartRange([0, newMaxRange])
  }

  const renderLegend = (props) => {
    const { payload } = props

    // Timeout needed to render legend without any shifting, otherwise it renders the
    // legend up in the chart area and shifts down moments after the chart fully renders.
    setTimeout(() => {setShowLegend(true)}, 100)

    const legendContent = isStaggeredLoading
      ? <div className={styles.spinnerWrapper}>
          <LoadingSpinner className={styles.spinner}/>
        </div>
      : <ul
          className={classNames(styles.legendWrapper, {[styles.show]: showLegend})}
          style={legendMargin}
        >
          {
            payload.map(item => (
              <li
                className={styles.legendItem} key={`item-${item.segmentKey}`}
                onClick={() => handleLegendOnClick(item)}
                onMouseEnter={() => handleLegendMouseEvent(item, true)}
                onMouseLeave={() => handleLegendMouseEvent(item, false)}
              >
                <span
                  style={{backgroundColor: legendColor[item.segmentKey]}}
                  className={classNames(styles.dot,
                    {[styles.exclude]: legendColor[item.segmentKey] === EXCLUDED_COLOR})}
                />
                <span
                  className={classNames(styles.label,
                    {[styles.exclude]: legendColor[item.segmentKey] === EXCLUDED_COLOR})}
                >
                  {titleCase(item.xAxis)}
                </span>
              </li>
            ))
          }
          {activeChart && activeChart.current &&
            <ExportChart currentChart={activeChart.current} chartType={CHART_TYPES.BAR}/>
          }
        </ul>

    return (legendContent)
  }

  const renderTooltip = (props) => {
    const {active, payload} = props

    if (active) {
      const item = payload.reduce((acc, key) => (acc, key.payload), {})
      const barCounts = Object.entries(activeData).map(([_, val]) => val.count)
      const totalCounts = barCounts.reduce((acc, c) => acc + c, 0)
      const percentage = totalCounts
        ? Math.round((item.count / totalCounts) * 100)
        : 0

      const toolTip = showPercentage
        ? `${percentage}% ${toolTipText}`
        : toolTipText

      return (
        <div className={styles.toolTipWrapper}>
          <span className={styles.toolTipHeader}>
            {toolTip}
          </span>
          <span className={styles.toolTip}>
            <span
              className={styles.toolTipDot}
              style={{backgroundColor: legendColor[item.segmentKey]}}
            />
            <span className={styles.toolTipText}>
              {`${item.xAxis} : ${item.count}`}
            </span>
          </span>
        </div>
      )
    } else {
      return null
    }
  }

  const containerWidthClass = activeData.length < 3 && !isVerticalLayout
    ? styles.twoBars
    : activeData.length < 4 && !isVerticalLayout
      ? styles.threeBars
      : styles.moreBars

  const xAxis = isVerticalLayout
    ? <XAxis type="number" interval={0}/>
    : <XAxis
        dataKey="xAxis"
        axisLine={true}
        tickLine={false}
        interval={0}
        tick={
          <CustomXAxisTick
            dataCount={segmentCount}
            isStaggeredLoading={isStaggeredLoading}
          />
        }
      />

  const yAxis = isVerticalLayout
    ? <YAxis
        dataKey="xAxis"
        type="category"
        scale="band"
        axisLine={true}
        tickLine={false}
    />
    : <YAxis
        axisLine={true}
        tickLine={false}
        domain={chartRange}
      />

  const barChart = !is.empty(chartData)
    ? <div className={classNames(styles.mzChart, className)}>
        <ResponsiveContainer
          className={classNames(styles.barChartContainer, containerWidthClass)}
          height='100%'
        >
          <BarChart
            className={classNames({'vertical-bar-chart': isVerticalLayout})}
            layout={isVerticalLayout ? 'vertical' : 'horizontal'}
            ref={(chart) => activeChart.current = chart}
            data={activeData}
            margin={chartMargin}
          >
            {xAxis}
            {yAxis}
            <Legend
              content={renderLegend}
              verticalAlign="bottom"
              wrapperStyle={{bottom: 0}}
              payload={chartData.segmentData}
            />
            <CartesianGrid strokeDasharray="1 5" vertical={false} />
            <Tooltip
              content={renderTooltip}
              cursor={{fill: '#fafafa'}}
            />
            <Bar
              background={{fill: '#F2F2F2'}}
              dataKey={'count'}
              maxBarSize={defaultBarWidth}
              shape={barShape}
              radius={isVerticalLayout ? [0, 5, 5, 0] : [10, 10, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    : null

  return (
    <React.Fragment>
      { barChart }
    </React.Fragment>
  )
}
MzBarChart.propTypes = {
  chartData: PropTypes.object.isRequired,
  className: PropTypes.string,
  legendMargin: PropTypes.object,
  chartMargin: PropTypes.object,
  defaultBarWidth: PropTypes.number,
  isStaggeredLoading: PropTypes.bool,
  toolTipText: PropTypes.string,
  showPercentage: PropTypes.bool,
  isVerticalLayout: PropTypes.bool,

}
MzBarChart.defaultProps = {
  className: 'mz-bar-chart',
  legendMargin: {margin: '10px 10px 0 60px'},
  chartMargin: {top: 40, right: 40, bottom: 20, left: 20},
  defaultBarWidth: DEFAULT_BAR_WIDTH,
  isStaggeredLoading: false,
  toolTipText: DEFAULT_TOOL_TIP,
  showPercentage: true,
  isVerticalLayout: false
}
