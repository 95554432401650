import {handleActions} from 'redux-actions'

import * as actions from './flagging-actions'

const getInitialState = () => ({
  flaggedArticleCount: 0,
})

export default handleActions(
  {
    [actions.adjustFlaggedArticleCount]: (state, action) => ({
      flaggedArticleCount: state.flaggedArticleCount + action.payload,
    }),
    [actions.setFlaggedArticleCount]: (state, action) => ({
      flaggedArticleCount: action.payload,
    })
  },
  getInitialState(),
)
