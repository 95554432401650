export const STATE_PROP_NAME = 'users'

export const ACTIVE_FILTER = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ADMIN: 'admin',
}

export const SELECT_TYPE = {
  ALL: 'all',
  VISIBLE: 'visible',
  NONE: 'none',
}

export const MANAGE_COLUMNS = 'Manage Columns'

export const COLUMNS = {
  CHECKBOX: 'checkbox',
  NAME: 'name',
  INSIGHTS: 'insights',
  PRIVATE: 'private',
  LAST_REFRESH_EMAIL: 'lastRefreshEmail',
  REFRESH_COMPLETED: 'refreshCompleted',
  NEXT_REFRESH_EMAIL: 'nextRefreshEmail',
  OFFICE_LOCATION: 'officeLocation',
  COUNTRY: 'country',
  RECURRING_PUBLICATIONS: 'recurringPubCount',
  TEMPLATE_PUBLICATIONS: 'templatePubCount',
  EMAIL_NOTIFICATIONS: 'emailNotificationCount',
  ACTIONS: 'actions',
  REFRESH_INTERVAL: 'refreshInterval'
}

export const COLUMN_OPTIONS = [
  { value: COLUMNS.OFFICE_LOCATION, label: 'Office Location' },
  { value: COLUMNS.COUNTRY, label: 'Country' },
  { value: COLUMNS.RECURRING_PUBLICATIONS, label: 'Recurring Publications' },
  { value: COLUMNS.TEMPLATE_PUBLICATIONS, label: 'Template Publications' },
  { value: COLUMNS.EMAIL_NOTIFICATIONS, label: 'Email Notifications' },
]

export const PUBLICATIONS_ITEMS_PER_PAGE = 10

