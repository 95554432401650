import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from "classnames"

import styles from "app/reusable/LanguageFilter/SearchLanguageFilter.less"


export default class SearchLanguageFilter extends Component {
  static propTypes = {
    languages: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    isSingleColumn: PropTypes.bool.isRequired,
  }

  render() {
    const isSingleColumn = this.props.isSingleColumn
    const header = !isSingleColumn
      ? <div className={styles.header}>Languages</div>
      : null

    return (
      <div
        className={classNames(styles.languageFilterOptionsContainer,
          {[styles.multiColumn]: !isSingleColumn})}
      >
        { header }
        <ul
          className={classNames(styles.languageFilters,
            {[styles.multiColumn]: !isSingleColumn})}
        >
          {this.props.languages.map(this.renderLanguage)}
        </ul>
      </div>
    )
  }
  renderLanguage = (language) => {
    return (
      <li
        className={classNames('language')} // for Pendo
        key={language.id}
      >
        <label className={styles.label}>
          <input
            type="checkbox"
            className={styles.checkbox}
            checked={!language.exclude}
            onChange={(evt) => this.handleChange(evt, language.id)}
          />
          <span className={classNames(styles.name)}>
            { language.name }
          </span>
        </label>
      </li>
    )
  }
  handleChange = (event, id) => {
    const languageFilters = JSON.parse(JSON.stringify(this.props.languages))
    let languageFilter = languageFilters.find(lang => lang.id === id)
    languageFilter.exclude = !event.target.checked
    const selectedLanguageIds = languageFilters.filter(lang => lang.exclude === false)
      .map(lang => lang.id)

    if (selectedLanguageIds.length > 0) {
      return (this.props.onChange(languageFilters))
    } else {
      return (this.props.onChange(null))
    }
  }
}
